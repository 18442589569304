import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class RemesaAPI {
    
    static get(id) {
        return middleware.hasAuth(() => request.get(`/remesa/GetRemesa/${id}`));
    }

    static post(remesa) {
        return middleware.hasAuth(() => request.post(`/remesa/ProcesarRemesa`, remesa));
    }

    static getWarehouses() {
        return middleware.hasAuth(() => request.get(`/remesa/GetAlmacenes`));
    }
}