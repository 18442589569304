import React from 'react';
import NoItems from '../../../../util/noitems/NoItems';
import Loading from '../../../../util/loading/Loading';
import './style.css'

export default function TablaMenuApp({data, edit, details, loading, feature}){
    const columnas = ["Orden","Imagen","Nombre","Destacado","Acciones"];


    const actions = (classification) => {
        return (
            <td>
                <button className="button is-small tableAction is-link tooltip" data-tooltip="Ver detalle" onClick={(e) => {
                    e.stopPropagation();
                    details(classification)
                }}>
                    <span className="icon">
                        <i className="fa fa-eye" />
                    </span>
                </button>
                <button className="button is-small tableAction is-warning tooltip" data-tooltip="Editar" onClick={(e) => {
                    e.stopPropagation();
                    edit(classification)
                }}>
                    <span className="icon">
                        <i className="fa fa-edit" />
                    </span>
                </button>
            </td>
        )
        
    }

    return (
        <div>
            {data.length > 0  ? <table className="table is-fullwidth is-striped">
                <thead>
                    {columnas.map(c => <th key={c}>{c}</th>)}
                </thead>
                <tbody>
                    {data.map(classification => {
                        return <tr style={{height: 100}}>
                            <td>{classification.OrderIndex}</td>
                            <td>
                                {classification.Icon ? <img className="img" src={classification.UrlIcon}/> : "n/a"}
                            </td>
                            <td>{classification.Name}</td>
                            <td>
                                <div className={`control`}>
                                    <input
                                        type="checkbox"
                                        name="status"
                                        className="switch"
                                        checked={classification.Featured}
                                        onChange={() => { }}
                                    />
                                    <label disabled htmlFor="status" onClick={(e) => {
                                        feature(classification.ClassificationId)
                                        e.stopPropagation();
                                    }} />
                                </div>
                            </td>
                            {actions(classification)}
                        </tr>
                    })}
                </tbody>
            </table> : !loading ? <NoItems itemName="clasificaciones"/> :  
            <Loading isLoading={loading} width='100px' height='100px'/>}
        </div>
    )
}