import React, { useState } from 'react';
import { useEffect } from 'react';
import to from '../../../../../util/to';
import NotificacionAPI from '../../../../../services/Caffenio App/Notificacion';
import { toast } from 'react-toastify';

export default function ResumenNotificacion({notification}){
    const [data, setData] = useState({});
    const [_notification, setNotification] = useState({...notification});

    useEffect(() => {
        if(notification && notification.NotificationTypeId){
            (async () => {
                let err, categories, actions, screens, types;
                [err, categories] = await to(NotificacionAPI.getCategoriesByType(notification.NotificationTypeId));
                [err, actions] = await to(NotificacionAPI.getActions());
                [err, screens] = await to(NotificacionAPI.getScreens());
                [err, types] = await to(NotificacionAPI.getNotificationTypes());
                if(err){
                    toast.error(err.response.data.Message);
                    return;
                } 
                setData({categories: categories, actions: actions, screens: screens, types: types});
                const type = types.find(t => t.NotificationTypeId === notification.NotificationTypeId);
                const cat = categories.find(c => c.NotificationCategoryId === notification.NotificationCategoryId);
                const act = actions.find(a => a.NotificationActionId === notification.NotificationActionId);

                var screen;
                if(notification.NotificationScreenId) screen = screens.find(s => s.NotificationScreenId === notification.NotificationScreenId);

                setNotification({...notification,typeLabel: type.Name, categoryLabel: cat.Name, actionLabel: act.Name, screenLabel: screen ? screen.Name : ""});
                
            })();
        }
    }, [notification]);

    useEffect(() => {
        console.log(data);
    }, [data])
    
    const replaceTags = (str) => {
        var preview = str
        
        preview = preview.split('#[NOMBRE CLIENTE]').join('José');
        preview = preview.split('#[SALDO PREPAGO]').join('12');
        preview = preview.split('#[SALDO MONEDERO]').join('0');
        preview = preview.split('#[NIVEL LEALTAD]').join('FAN');
        
        return preview;
    }

    return (
        <div>
            <div className="columns is-multiline">
                <div className="column is-4 has-text-left">
                    <label className="label">Título</label>
                    <div className="control">
                        <p>{_notification.Title}</p>
                    </div>
                </div>
                <div className="column is-8 has-text-left">
                    <label className="label">Descripción</label>
                    <div className="control">
                        <p>{_notification.Description}</p>
                    </div>
                </div>
                <div className="column is-4 has-text-left">
                    <label className="label">Tipo</label>
                    <div className="control">
                        <p>{_notification.typeLabel}</p>
                    </div>
                </div>
                <div className="column is-4 has-text-left">
                    <label className="label">Categoría</label>
                    <div className="control">
                        <p>{_notification.categoryLabel}</p>
                    </div>
                </div>
                {_notification.NotificationCategoryId === 1 && <div className="column is-4 has-text-left">
                    <label className="label">Regla</label>
                    <div className="control">
                        <p>{_notification.BalanceInfo.Description}</p>
                    </div>
                </div>}
                {_notification.NotificationCategoryId === 3 && <div className="column is-4 has-text-left">
                    <label className="label">Regla</label>
                    <div className="control">
                        <p>{_notification.VisitInfo.Description}</p>
                    </div>
                </div>}
                <div className="column is-4 has-text-left">
                    <label className="label">Acción</label>
                    <div className="control">
                        <p>{_notification.actionLabel}</p>
                    </div>
                </div>
                {notification.NotificationActionId === 1 && <div className="column is-4 has-text-left">
                    <label className="label">Pantalla</label>
                    <div className="control">
                        <p>{_notification.screenLabel}</p>
                    </div>
                </div>}
                {notification.NotificationActionId === 2 && <div className="column is-4 has-text-left">
                    <label className="label">Enlace</label>
                    <div className="control">
                        <p>{_notification.Link}</p>
                    </div>
                </div>}
                {_notification.DetailDescription && <div className="column is-12">
                    <div className="columns">
                        {notification.DetailDescription && <div className="column is-4 has-text-left">
                            <label className="label">Detalle descripción</label>
                            <div className="control" style={{'word-wrap': 'break-word'}}>
                                <p>{_notification.DetailDescription}</p>
                            </div>
                        </div>}
                        {notification.UrlImageNotification && <div className="column is-4 has-text-left">
                            <label className="label">Imagen</label>
                            <div className="control">
                                <img  src={_notification.UrlImageNotification} style={{height: 300}}/>
                            </div>
                        </div>}
                    </div>
                </div>}
            </div>
        </div>
    )
}