import { apiUrl }  from "../constants";
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class GrainsAPI {
    static getAll() {
        return request.get('/GrainType');
    }

    static get(id) {
        return request.get(`/GrainType/${id}`);
    }

    static getByClasification(ids){
        return request.post(`/GrainType/GetByClassification`, ids);
    }

    static create(GrainType) {
        let fd = new FormData();
        fd.append("Description", GrainType.Description);
        fd.append("NombreApp", GrainType.NombreApp);
        fd.append("Position", GrainType.Position);
        fd.append("RecipeName", GrainType.RecipeName);
        fd.append("Activo", GrainType.Activo);
        fd.append("SegmentId", GrainType.SegmentationId.SegmentId)
        GrainType.Image && fd.append("Image", GrainType.Image);

        return request.post('/GrainType',fd);
    }

    static edit(GrainType){
        let fd = new FormData();

        fd.append("Description", GrainType.Description);
        fd.append("NombreApp", GrainType.NombreApp);
        fd.append("Position", GrainType.Position);
        fd.append("RecipeName", GrainType.RecipeName);
        fd.append("GrainTypeId", GrainType.GrainTypeId);
        fd.append("Activo", GrainType.Activo);
        GrainType.idSegmento && fd.append("SegmentId", GrainType.idSegmento)

        GrainType.Image instanceof File && fd.append("Image", GrainType.Image);

        return request.put(`/GrainType`,fd);
    }

    static delete(id){
        return request.delete(`GrainType/${id}`);
    }
}