import React, { Component } from 'react';
import PriceDriveAPI from '../../services/PriceDrive';
import { toast } from 'react-toastify';
import './PricesDrive.css';
import TableTemplate from '../util/tabletemplate/TableTemplate';
import Modal from '../util/modal/Modal';
import SearchForm from "../util/searchform/SearchForm";
import NoItems from "../util/noitems/NoItems";
import AcceptModal from '../util/acceptModal/AcceptModal';
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading';
import PriceListForm from '../pricesDrives/PriceListForm';
import SelectDriveForm from './components/SelectDriveForm';
import WarehouseAPI from '../../services/Warehouse';
import RecipesAPI from '../../services/Recipe';

import BtnModoGuiado from '../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../util/validation-error-messages';
import to from '../../util/to';
import RecipeFilters from '../util/recipeFilters/recipeFilters';
import Field from '../util/field/Field';
import ExpresionesRegulares from '../../util/expresiones-regulares';

class PricesDrive extends Component {
    pricesList = [];

    constructor(props) {
        super(props);
        this.state = {
            pricesList: [],
            pagePricesList: [],
            filterText: "",
            priceList: null,
            showModal: false,
            removeModal: false,
            isLoading: false,
            activeList: [],

            //Filtros
            Caffenios: [],
            selectedOption: [],
            selectedOptionC: [],
            selectedOptionD: [],
            //filtros receta
            selectedRecipeTypesList: [],
            selectedPresentationsList: [],
            selectedMilksList: [],
            selectedGrainsList: [],
            selectedFlavorsList: [],
            selectedClassificationList: [],
            //actualizar masivo
            recipes: [],
            filterRecipes: [],
            showRecipeFilters: true,
            checkAll: false,
            buttonSelectAll: false,
            confirmModalPriceChange: false,
            enabledApply: false,
            priceMassive: null,
        };
    }

    componentWillMount() {
        this.setState({ isLoading: true });
        PriceDriveAPI.get()
            .then(response => {
                let state = { ...this.state };
                state.pricesList = response.data
                state.activeList = this.fncActiveList(response.data);
                this.setState(state, () => this.getDrives());

                this.pricesList = response.data;
                return RecipesAPI.getAll();
            }).then(response => {
                if (response.data) {
                    this.setState({ recipes: response.data })
                }
            })
            .catch(err => {
                this.setState({ isLoading: false })
                if (err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
    }

    fncActiveList = (listas) => {
        let state = this.state;
        let arrListActive = [];
        listas.map((lista) => {
            if (lista.Active) {
                arrListActive.push({ "Key": lista.PriceListId, "Label": lista.Description });
            }
        })
        return arrListActive;
    }

    /*Función para obtener estados ciudades y drives */
    getDrives = async () => {
        let err, caffenios;
        [err, caffenios] = await to(WarehouseAPI.getCaffenios());

        if (err) {
            this.setState({ isLoading: false })
            if (err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        } else {
            this.setState({
                isLoading: false,
                Caffenios: caffenios.map(item => {
                    item.value = item.StateId;
                    item.label = item.State;
                    return item;
                })
            })
        }
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let pricesList = this.pricesList.filter(p => p.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        let state = this.state;
        state.filterText = text;
        state.pricesList = pricesList;
        this.setState(state);
    }

    /*
    * Funcion seleccionar o des seleccionar todos las lista de precios  
    * de la pagina actual.
    */
    selectAllCheckBoxPage = () => {
        let state = { ...this.state };
        let _checkAll = !state.checkAll

        state.pagePricesList.forEach(priceDetail => {
            priceDetail.statusCheck = _checkAll
            let indexOri = state.pricesList.findIndex(item => item.PriceListId === priceDetail.PriceListId)
            state.pricesList[indexOri].statusCheck = _checkAll
            return priceDetail;
        })

        this.setState({
            priceMassive:null,
            pagePricesList: state.pagePricesList,
            pricesList: state.pricesList,
            checkAll: _checkAll,
            buttonSelectAll: state.pricesList.some(l => l.statusCheck)
        })
    }

    /*
    * Funcion que selecciona o des selecciona todas las 
    * listas de precio de todas las paginas.
    */
    selectAllCheckBox = () => {
        let state = { ...this.state };
        state.checkAll = !state.checkAll
        state.buttonSelectAll = !state.buttonSelectAll;

        state.pricesList.forEach(priceDetail => {
            priceDetail.statusCheck = state.buttonSelectAll;
            return priceDetail;
        });

        this.setState({
            priceMassive : null,
            pricesList: state.pricesList,
            checkAll: state.checkAll,
            buttonSelectAll: state.buttonSelectAll
        })
    }

    /**Función para actualizar los parametros de búsqueda */
    handleSearchParamsChange = (field, value) => {
        let state = { ...this.state };
        state[field] = value;
        state.isLoading = true;

        this.setState(state, () => {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                let pricesList = this.pricesList.filter(p => {
                    if (
                        (this.state.selectedOption.length === 0 || this.state.selectedOption.some(dr => dr.idListaPrecio === p.PriceListId)) &&
                        (this.state.selectedOptionC.length === 0 || this.state.selectedOptionC.some(dr => dr.idListaPrecio === p.PriceListId)) &&
                        (this.state.selectedOptionD.length === 0 || this.state.selectedOptionD.some(dr => dr.idListaPrecio === p.PriceListId)) &&
                        (this.state.filterText.length === 0 || p.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                    ) {
                        return p;
                    }
                });
                this.setState({ isLoading: false, pricesList: pricesList });
            }, 800);
        });


    }

    /**Setea al state los valores de los filtros de recetas  */
    handleSelectChange = (filters) => {
        let state = { ...this.state };

        state.selectedClassificationList = filters.selectedClassificationList && filters.selectedClassificationList.length > 0 ? filters.selectedClassificationList : [];
        state.selectedRecipeTypesList = filters.selectedRecipeTypesList && filters.selectedRecipeTypesList.length > 0 ? filters.selectedRecipeTypesList : [];
        state.selectedFlavorsList = filters.selectedFlavorsList && filters.selectedFlavorsList.length > 0 ? filters.selectedFlavorsList : [];
        state.selectedMilksList = filters.selectedMilksList && filters.selectedMilksList.length > 0 ? filters.selectedMilksList : [];
        state.selectedGrainsList = filters.selectedGrainsList && filters.selectedGrainsList.length > 0 ? filters.selectedGrainsList : [];
        state.selectedPresentationsList = filters.selectedPresentationsList && filters.selectedPresentationsList.length > 0 ? filters.selectedPresentationsList : [];

        state.enabledApply = (filters.selectedClassificationList && filters.selectedClassificationList.length > 0) ||
        (filters.selectedRecipeTypesList && filters.selectedRecipeTypesList.length > 0) ||
        (filters.selectedFlavorsList && filters.selectedFlavorsList.length > 0) ||
        (filters.selectedMilksList && filters.selectedMilksList.length > 0 )||
        (filters.selectedGrainsList && filters.selectedGrainsList.length > 0) ||
        (filters.selectedPresentationsList && filters.selectedPresentationsList.length > 0);

        this.setState(state);

    }

    /**
     * Funcion para validar la entrada de datos en el input
     * que modifica todos los precios de las recetas 
     * selecionadas.
     */
    handleChange = (e) => {
        let state = { ...this.state };
        state.priceMassive = e.target.value;

        if (e.target.value == Math.floor(e.target.value)) {
            if (e.target.value < 0 || e.target.value > 9999999) {
                toast.error("El Precio debe ser mayor a 0 y menor a 9999999");
                return;
            }
        } else {
            if (!this.isNumericDecimal(e.target.value, 2)) {
                toast.error("El Precio debe contenedor dos decimales solamente");
                return;
            } else if (e.target.value < 0.00 || e.target.value > 9999999.99) {
                toast.error("El Precio debe ser mayor a 0.00 y menor a 9999999.99");
                return;
            }
        }
        this.setState(state);
    }
    /**Valuda valor numerico */
    isNumericDecimal = (val, decimalPlaces) => {
        // If the last digit is a . then add a 0 before testing so if they type 25. it will be accepted
        var lastChar = val.substring(val.length - 1);
        if (lastChar == ".") val = val + "0";

        var objRegExp = new RegExp("^\\s*-?(\\d+(\\.\\d{1," + decimalPlaces + "})?|\\.\\d{1," + decimalPlaces + "})\\s*$", "g");
        if (decimalPlaces == -1)
            objRegExp = new RegExp("^\\s*-?(\\d+(\\.\\d{1,25})?|\\.\\d{1,25})\\s*$", "g");

        return objRegExp.test(val);
    };

    /**
     * Funcion para actualizar masivamente el precio 
     * de las recetas para los cambios de precio seleccionados.
     */
    updateMasive = () => {
        this.setState({ isLoading: true }, () => {
            let pricesList = this.state.pricesList.filter(i => {
                if (i.statusCheck) {
                    i.completed = false
                    return i;
                }
            });
            pricesList.forEach(item => {
                var data = {
                    PriceListId: item.PriceListId,
                    RecipeId: this.state.filterRecipes.map(r => { return r.RecipeId }),
                    SalePrice: this.state.priceMassive
                }

                PriceDriveAPI.updateMasivePrices(data)
                    .then(response => {
                        item.completed = true;
                        if (!pricesList.some(i => i.completed === false)) {
                            this.setState({
                                isLoading: false,
                                filterRecipes: [],
                                confirmModalPriceChange: false,
                                priceMassive: null,
                                selectedRecipeTypesList: [],
                                selectedPresentationsList: [],
                                selectedMilksList: [],
                                selectedGrainsList: [],
                                selectedFlavorsList: [],
                                selectedClassificationList: [],
                                enabledApply: false,
                                checkAll: false,
                                buttonSelectAll: false,
                                pricesList:this.state.pricesList.map(i => {
                                    i.statusCheck=false;
                                    return i;
                                })
                            }, () => {
                                toast.dismiss();
                                toast.success("Listas de precio actualizadas.")
                            })
                        }
                    })
                    .catch(error => {
                        this.setState({
                            isLoading: false,
                            filterRecipes: [],
                            confirmModalPriceChange: false,
                            priceMassive: null
                        }, () =>
                            toast.error("Ha ocurrido un error al actualizar precios"))
                    });

            });
        });
    }

    /*
    * Funcion para saber si hay que mostrar
    * un switch y boton para aplicar cambios a las 
    * recetas que hemos marcado. 
    */
    showView = (index) => {
        
        let state = { ...this.state };

        state.pagePricesList[index].statusCheck = state.pagePricesList[index].statusCheck 
            ? !state.pagePricesList[index].statusCheck : true;
        let indexOri = state.pricesList.findIndex(item => item.PriceListId === state.pagePricesList[index].PriceListId)
        state.pricesList[indexOri].statusCheck = state.pricesList[indexOri].statusCheck 
            ? !state.pricesList[indexOri].statusCheck : true;
           
    

        this.setState({
            priceMassive: state.pricesList.some(p => p.statusCheck) ? state.priceMassive : null,
            pagePricesList: state.pagePricesList,
            pricesList: state.pricesList,
            
        })
    }

    create = () => {
        this.props.history.push({ pathname: '/lista-precios/nuevo', state: { pricesList: this.state.pricesList } });
    }

    edit = (priceList) => {
        priceList.Description = priceList.Description.trim();
        if (priceList.Description.length < 3) {
            toast.error("Descripción debe de tener más de 2 caracteres");
            return;
        }

        this.setState({ isLoading: true });
        PriceDriveAPI.put(priceList).then(response => {
            let state = { ...this.state };
            state.pricesList[state.pricesList.findIndex(l => l.PriceListId === priceList.PriceListId)] = priceList;
            state.pagePricesList[state.pagePricesList.findIndex(l => l.PriceListId === priceList.PriceListId)] = priceList;
            this.pricesList[this.pricesList.findIndex(l => l.PriceListId === priceList.PriceListId)] = priceList;
            state.showModal = false;
            state.isLoading = false;
            this.setState(state, () => {
                toast.success("Datos actualizados");
            });
        }).catch(err => {
            this.setState({ isLoading: false })
            if (err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    }

    render() {
        return (
            <div>
                <h1 className="title is-2">Lista de precios
                <i className="fa-5x is-small"
                        style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px", fontSize: "22px" }}
                        className="fa fa-question-circle"
                        onClick={() => this.setState({ showModalInfoPricesList: !this.state.showModalInfoPricesList })}
                    ></i>
                </h1>
                <div className="columns is-vcentered is-multiline">
                    <div className="column is-12">
                        <div className="card">
                            <div className="card-content">
                                <div className="columns is-multiline">
{/*                                     <div className="column is-12">
                                        <div className="control">
                                            <SearchForm
                                                filterText={this.state.filterText}
                                                onInput={(t) => this.handleSearchParamsChange("filterText", t)}
                                                itemName={'Lista de Precios'}
                                                placeholderText={"Ingrese nombre de lista de precio"}
                                            />
                                        </div>
                                    </div> */}

                                    <SelectDriveForm
                                        Caffenios={this.state.Caffenios}
                                        setStateFunc={(value) => this.handleSearchParamsChange("selectedOption", value)}
                                        setCityFunc={(value) => this.handleSearchParamsChange("selectedOptionC", value)}
                                        setDriveFunc={(value) => this.handleSearchParamsChange("selectedOptionD", value)}
                                        create={() => this.create()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Filtrado de recetas */}
                    {this.state.pricesList.some(i => i.statusCheck) &&
                        <div className="column is-12">
                            <div className="card">
                                <div className="card-header">
                                <div className="card-header-title">
                                    Filtros de receta
                                        <div className="tooltip" data-tooltip={"Cambia el precio de las recetas masivamente."} >
                                        <i className="fa-5x"
                                            style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }}
                                            className="fa fa-question-circle"
                                            onClick={() => this.setState({ showRecipeFilters: !this.state.showRecipeFilters })}
                                        ></i>
                                    </div>
                                </div>
                                    
                                    <div class="card-header-icon">
                                        <span class="icon" id={"btnOpenRecipeFilters"} onClick={() => this.setState({ showRecipeFilters: !this.state.showRecipeFilters })}>
                                            <i class="fa fa-angle-down"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className={`card-content ${this.state.showRecipeFilters ? "is-hidden" : ""} `}>
                                    <div className="columns is-multiline">
                                        <RecipeFilters
                                            onChange={(objFilters) => { this.handleSelectChange(objFilters) }}
                                        />

                                        {this.state.enabledApply &&
                                            <div className="column is-12">
                                                <div className="columns">
                                                    <div className="column is-4">
                                                    </div>
                                                    <div className="column is-4">
                                                        <Field label="Precio">
                                                            <input
                                                                type="number"
                                                                min="0"
                                                                step="0.01"
                                                                className="input"
                                                                label="Gramos a descontar"
                                                                maxLength="8"
                                                                name="PriceMasive"
                                                                value={this.state.priceMassive}
                                                                onChange={(e) => this.handleChange(e)}
                                                                onKeyPress={(e) => {
                                                                    const keyCode = e.keyCode || e.which;
                                                                    const keyValue = String.fromCharCode(keyCode);
                                                                    if (ExpresionesRegulares.ErrorInputNumDecimal.test(keyValue) || e.target.value.length >= e.target.getAttribute('maxLength'))
                                                                        e.preventDefault();
                                                                }}
                                                                onPaste={(e) => {
                                                                    if (ExpresionesRegulares.ErrorInputNumDecimal.test(e.clipboardData.getData("Text"))
                                                                        || (/\./.test(e.target.value) && /\./.test(e.clipboardData.getData("Text")))) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </Field>
                                                    </div>
                                                    <div className="column is-4">
                                                        <Field label='&nbsp;' >
                                                            <a className="button is-success is-fullwidth" onClick={() => {
                                                                if (this.state.priceMassive) {
                                                                    let filterRecipes = this.state.recipes.filter(r => r.Active
                                                                        && (this.state.selectedClassificationList.length === 0 || this.state.selectedClassificationList.includes(r.ClassificationId))
                                                                        && (this.state.selectedRecipeTypesList.length === 0 || this.state.selectedRecipeTypesList.includes(r.RecipeTypeId))
                                                                        && (this.state.selectedFlavorsList.length === 0 || this.state.selectedFlavorsList.includes(r.FlavorTypeId))
                                                                        && (this.state.selectedMilksList.length === 0 || this.state.selectedMilksList.includes(r.MilkTypeId))
                                                                        && (this.state.selectedGrainsList.length === 0 || this.state.selectedGrainsList.includes(r.GrainTypeId))
                                                                        && (this.state.selectedPresentationsList.length === 0 || this.state.selectedPresentationsList.includes(r.PresentationId))
                                                                    );
                                                                    if(filterRecipes.length > 0 ){
                                                                        this.setState({ confirmModalPriceChange: true, filterRecipes: filterRecipes })
                                                                    }else{
                                                                        toast.dismiss();
                                                                        toast.error("No hay recetas que aplique a los criterios seleccionados.")
                                                                    }                                                                    
                                                                }
                                                                else {
                                                                    toast.dismiss();
                                                                    toast.error("Debe ingresar precio.")
                                                                }
                                                            }}>
                                                                <span className="icon is-small">
                                                                    <i className="fa fa-plus"></i>
                                                                </span>
                                                                <span>Aplicar</span>
                                                            </a>
                                                        </Field>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>


                <div className="card">
                    <div className="card-content">
                        <div className="columns">
                            <div className="column" style={{ display: "flex" }}>
                                <a className="button is-link" onClick={() => this.selectAllCheckBox()}>
                                    <span className="icon is-small">
                                        <i className={!this.state.buttonSelectAll ? "fa fa-check-square" : "fa fa-square"}></i>
                                    </span>
                                    <span>{!this.state.buttonSelectAll ? "Seleccionar todos los resultados" : "Deseleccionar todos los resultados"}</span>
                                </a>
                            </div>
                        </div>

                        {this.state.pricesList.length != 0
                            ? <TableTemplate
                                columns={["PriceListId", "Description", "Active"]}
                                columnsNames={["ID", "Nombre", "Activo"]}
                                disableCheck={true}
                                data={this.state.pagePricesList}
                                canEdit
                                canDelete={false}
                                edit={(priceList) => this.setState({ showModal: true, priceList: priceList })}
                                remove={(priceList) => this.setState({ removeModal: true, priceList: priceList })}
                                rowClickable={true}
                                details={(priceList) => this.props.history.push(`/lista-precios/${priceList.PriceListId}/detalle`)}
                                checkbox={true}
                                checkAll={this.state.checkAll}
                                selectAllCheck={this.selectAllCheckBoxPage}
                                showView={this.showView}
                            />
                            : <NoItems itemName="lista de precios" />}

                        <Pagination items={this.state.pricesList} onChangePage={(pricesList) => this.setState({ pagePricesList: pricesList })} />


                    </div>
                </div>
                {this.state.showModal &&
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Lista de Precios"}
                        cancel={() => this.setState({ showModal: false, priceList: null })}
                    >
                        <PriceListForm
                            priceList={this.state.priceList}
                            save={this.edit}
                            pricesList={this.pricesList}
                        />
                    </Modal>
                }
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove(this.state.priceList)}
                    cancel={() => this.setState({ removeModal: false, priceList: null })}
                    modalTitle={"Eliminar Lista de Precios"}
                />}
                {this.state.confirmModalPriceChange &&
                    <AcceptModal
                        isActive={this.state.confirmModalPriceChange}
                        done={() => this.updateMasive()}
                        modalTitle={`Confirmar recetas a actualizar $${this.state.priceMassive}`}
                        cancel={() => this.setState({ confirmModalPriceChange: false, filterRecipes: [] })}
                        modalBody={
                            <div>
                                <TableTemplate
                                    columns={["RecipeId", "Description"]}
                                    columnsNames={["ID", "Nombre"]}
                                    disableCheck={true}
                                    noActions={true}
                                    data={this.state.pageRecipesList}
                                />
                                <Pagination items={this.state.filterRecipes} onChangePage={(recipes) => this.setState({ pageRecipesList: recipes })} />
                            </div>

                        }
                    />
                }

                {/* Modal para visualizar la información del módulo */}
                {this.state.showModalInfoPricesList &&
                    <Modal
                        showModal={this.state.showModalInfoPricesList}
                        modalTitle={"Información de Lista de Precio"}
                        cancel={() => this.setState({ showModalInfoPricesList: false })}
                    >
                        <div className="has-text-justified">                        
                            Cada Lista de precios esta conformada por un conjunto de recetas, su función es definir un precio para cada producto que conforme la lista de precio.
                            <br/>
                            <br/>
                            Las listas de precios son asignadas a los puntos de venta, un Drive solo puede tener una lista de precio asignada, los precios dados de alta serán los precios de los productos en la caja, pantalla y tablet del punto de venta.
                            <br/>
                            <br/>
                            <strong>En el módulo se podrá realizar:</strong>
                            <ol type="1">
                                <li>Búsquedas ingresando textualmente el Nombre de una Lista de Precios o filtrando por punto de venta.</li>                                
                                <li>Cambios de precio masivo. <br/> 
                                <div class="message is-info">
                                    <div className="message-body">
                                    <strong>NOTA IMPORTANTE: </strong>
Para visualizar los filtros de recetas y actualizar precios de recetas de manera masiva es importante seleccionar una o varias Listas de Precios.
                                    </div>
                                
                                </div>
                                </li>
                            </ol>                       
                        </div>
                    </Modal>
                }

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
                <BtnModoGuiado />
            </div>
        );
    }

}

export default PricesDrive;