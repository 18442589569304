import React, { Component } from 'react';
import  SearchForm  from "../util/searchform/SearchForm";
import  TableTemplate  from "../util/tabletemplate/TableTemplate";
import  NoItems  from "../util/noitems/NoItems";
import Modal from '../util/modal/Modal';
import InventoryTypeForm from './InventoryTypeForm';
import AcceptModal from '../util/acceptModal/AcceptModal';
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading';
import InventoryTypesAPI from '../../services/InventoryType';
import { toast } from 'react-toastify';

import ModoGuiado from "../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../util/validation-error-messages';
import lodash from 'lodash';
import roles from '../../util/strings/roles';

class InventoryTypes extends Component {
    inventoryTypes = [];

    constructor(props){
        super(props);
        this.state = {
            inventoryTypes: [],
            pageInventoryTypes: [],
            filterText: "",
            inventoryType: null,
            showModal: false,
            editing: false,
            removeModal: false,
            isLoading: false
        }
    }

    componentWillMount(){
        this.setState({isLoading: true});
        //this.inputCode.focus();
        InventoryTypesAPI.getAll().then(response => {
            this.setState({inventoryTypes: response.data, isLoading: false});
            this.inventoryTypes = response.data;
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        });
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let inventoryTypes = this.inventoryTypes.filter(inventoryType => inventoryType.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        let state = this.state;
        state.filterText = text;
        state.inventoryTypes = inventoryTypes;
        this.setState(state);
    }

    create = () => {         
        this.props.history.push('/tipo-inventario/nuevo');
    }

    edit = (inventoryType) => {
        inventoryType.Description = inventoryType.Description.trim();
        if(inventoryType.Description.length < 3){ 
            toast.error("Descripción debe de tener más de 2 caracteres");
            return;
        }

        this.setState({isLoading: true});
        InventoryTypesAPI.edit(inventoryType/*, inventoryType.InventoryTypeId*/).then(response => {
            let state = {...this.state};
            state.inventoryTypes[state.inventoryTypes.findIndex(u => u.InventoryTypeId === inventoryType.InventoryTypeId)] = inventoryType;
            state.pageInventoryTypes[state.pageInventoryTypes.findIndex(u => u.InventoryTypeId === inventoryType.InventoryTypeId)] = inventoryType;
            this.inventoryTypes[this.inventoryTypes.findIndex(u => u.InventoryTypeId === inventoryType.InventoryTypeId)] = inventoryType;
            state.showModal = false;
            state.isLoading = false;
            this.setState(state, () => {
                toast.success("Tipo Inventario actualizado");
            });
        }).catch(err => {
            this.setState({isLoading: false}, () => {toast.error(err.response.data.Message)})
        }) 
    }

    remove = (inventoryType) => {        
        let state = {...this.state};
        const loadingToast = toast.info("Cargando...", 
            {   autoClose: false, 
                closeButton: false, 
                className: "has-background-primary has-text-weight-bold"
            });
        inventoryType.Description = inventoryType.Description.trim();
        InventoryTypesAPI.delete(inventoryType).then(response => {
            
            state.inventoryTypes.splice(state.inventoryTypes.findIndex(u => u === inventoryType),1);
            state.pageInventoryTypes.splice(state.pageInventoryTypes.findIndex(u => u === inventoryType),1);
            this.inventoryTypes.splice(this.inventoryTypes.findIndex(u => u === inventoryType),1);

            state.removeModal = false;
            this.setState(state, () => {
                toast.dismiss(loadingToast);
                toast.success("Tipo Inventario eliminado");
            })
        }).catch(err => {
            toast.dismiss(loadingToast);
            state.removeModal = false;
            this.setState(state);
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        
        })
    }

    render() {
        return (
            <div>

                <ModoGuiado 
                    ruta="tipo-inventario"
                    tipoModoGuiado="articulo"
                    anterior="unidades"
                    siguiente='items-inventario' 
                    mensaje="Grupo de inventario al que pertenece artículo" />

                <h1 className="title is-2">Tipo inventario / Clasificación Artículo</h1>
                <div className="columns is-vcentered">
                    <div className="column is-5">
                        <div className="card">
                            <div className="card-content">
                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'Tipo Inventario'}
                                            placeholderText = {"Ingrese nombre de tipo inventario"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-7">
                        <div className="field">
                            <div className="control has-text-right">
                                {lodash.intersection(localStorage.getItem('role').split(","), [roles.ADMIN, roles.ADMINDRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES]).length > 0 &&
                                    <a id="btnAddInventoryType" tabIndex="0" className="button is-success" onClick={(e) => this.create()}>
                                        <span className="icon is-small">
                                            <i className="fa fa-plus"></i>
                                        </span>
                                        <span>Agregar Tipo Inventario</span>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-content">
                        {this.state.inventoryTypes.length !== 0 
                        ? <TableTemplate
                            columns={["InventoryTypeId","Description"]}
                            columnsNames={["ID", "Nombre"]}
                            data={this.state.pageInventoryTypes}
                            rowClickable={true}
                            details={(inventoryType) => this.setState({showModal: true, inventoryType: inventoryType})}
                            canEdit canDelete 
                            edit={(inventoryType) => this.setState({showModal: true, inventoryType: inventoryType})}
                            /*edit={(inventoryType) => this.props.history.push(`/tipo-inventario/${inventoryType.InventoryTypeId}/editar`)} */
                            remove={(inventoryType) => this.setState({removeModal: true, inventoryType: inventoryType})}
                        /> 
                        : <NoItems itemName="tipos de inventario"/>}
                        <Pagination items={this.state.inventoryTypes} onChangePage={(inventoryTypes) => this.setState({ pageInventoryTypes: inventoryTypes })} />
                    </div>
                </div>
                {this.state.showModal && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Tipo Inventario"}
                        cancel={() => this.setState({showModal: false, inventoryType: null})}
                    >
                        <InventoryTypeForm
                            inventoryType={this.state.inventoryType}
                            save={this.edit}
                            
                        />   
                    </Modal>
                }
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove(this.state.inventoryType)}
                    cancel={() => this.setState({removeModal: false})}
                    modalTitle={"Eliminar Tipo Inventario"}
                    modalBody={"¿Está seguro(a) de eliminar Tipo Inventario?"}
                />}
                
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />                

            </div>
        );
    } 
}

export default InventoryTypes;
