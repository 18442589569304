import {apiUrl} from '../constants';
import axios from 'axios';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class PagosBancosAPI {

    static GetPagosNetPay = () => {
        return request.get('PagoBanco/GetPagosNetPay')
    }

    static GetFilterPagoNetPay = (filtro) => {
        return request.post('PagoBanco/FilterPagoNetPay', filtro)
    }

    static GetSucursales = () => {
        return request.get('PagoBanco/GetSucursales')
    }

    static ReintentarFolioPago = (pago) => {
        return request.post('PagoBanco/ReintentarFolioPago', pago)
    }
}