import * as React from 'react';
import Field from '../../../../util/field/Field';
import FilterLoading from '../../components/util/FilterLoading';
import Dropdown from '../../../../util/dropdown/Dropdown';
import RadioButton from '../../../../util/radioButton/RadioButton';
import ErrorMessages from '../../../../../util/validation-error-messages';
import Validators from '../../../../../util/validators';
import Checkbox from '../../../../util/checkbox/Checkbox';
import Select from 'react-select';
import SegmentacionAPI from '../../../../../services/Caffenio App/Segmentacion';
import EmpresasAPI from '../../../../../services/CaffenioEmpresas/Empresas';
import { toast } from 'react-toastify';
import AcceptModal from '../../../../util/acceptModal/AcceptModal';
import to from '../../../../../util/to';


class SegmentacionForm extends React.Component {
    temperaturas = []
    ciudades = []
    clasificacionesC = []
    clasificacionesA = []
    nivelLealtadC = []
    nivelLealtadA = []
    

    constructor(props){
        super(props);
        
        this.state = {
            ...this.props,
            segmentacion: {
                IdSegmentation: undefined,
                nombre: "",
                estado: [],
                ciudad: [],
                rangoEdad: [],
                genero: [],
                nivelLealtad: [],
                horario: [],
                diasSemana: [],
                clasificacion: null,
                temperatura: [],
                refill: false,
                membresias:[],
                SegmentId: undefined
            },
            clasificaciones: [],
            ciudades: [],
            temperaturas: [],
            validated: this.props.isValid,
            clients: [1,1],
            totalClients: 0,
            editing: false,
            confirmModal: false,
            invalidName: false,
            working: false,
            segments: [],
            invalidSegment: false,
            Segment: [],
            bandera: false,
        }
    }

    // Método para verificar que la segmentación no tiene ningún filtro seleccionado
    segmentationHasNoFilters = () => {
        return this.state.segmentacion.estado.length == 0 &&
        this.state.segmentacion.ciudad.length == 0 &&
        this.state.segmentacion.rangoEdad.length == 0 &&
        this.state.segmentacion.genero.length == 0 &&
        this.state.segmentacion.nivelLealtad.length == 0 &&
        this.state.segmentacion.horario.length == 0 &&
        this.state.segmentacion.diasSemana.length == 0 &&
        this.state.segmentacion.clasificacion == null &&
        this.state.segmentacion.temperatura.length == 0
    }

    // Método para verificar si la segmentación es válida
    dataIsValid = () => {
        return this.state.segmentacion.nombre.length >= 3 && this.state.clients.length > 0
    }

    // Callback para cuando se selecciona un día, se agrega o remueve de la selección con base al estatus original.
    // @param d: Objeto para react-select del día de la semana seleccionado
    daySelected = (d) => {
        let seg = {...this.state.segmentacion};
        let index = this.state.segmentacion.diasSemana.findIndex(dia => dia.IdFilterPurchaseDay === d.IdFilterPurchaseDay);
        if (index === -1){
            seg.diasSemana.push(d);
        }else{
            seg.diasSemana.splice(index, 1);
        }
        this.props.invalidate();
        this.setState({segmentacion: seg, validated: false});
    }

    // Se establece la Familia seleccionada en la segmentación
    setClassification = () => {
        let classification = this.props.clasificaciones.find(c => c.IdFilterFamilyProduct === this.state.segmentacion.IdClassification);
        let segmentacion = {...this.state.segmentacion};
        segmentacion.clasificacion = {value: classification.IdFilterFamilyProduct, label: classification.Name};
        this.setState({segmentacion: segmentacion}, () => this.getTemperaturas());
    }

    setLealtad = () => {
            SegmentacionAPI.getNivelesLealtadPorSegmento(this.state.segmentacion.SegmentId).then(response => {
                this.props.getCuentaLealtad(response.data);
                this.nivelLealtadA = response.data;
                this.nivelLealtadC = response.data;
                let state = {...this.state};
                this.setState(state);
                let segmentacion = { ...this.state.segmentacion };
            this.setState({ segmentacion});
            console.log(segmentacion);
            }).catch(err => {
                toast.error(err.response.data.Message);
            });
    }


    getActualizacionBySegmento = () => {
        console.log("entró hijo:" + this.state.segmentacion.SegmentId);

        SegmentacionAPI.getNivelesLealtadPorSegmento(this.state.segmentacion.SegmentId).then(response => {
            this.props.getCuentaLealtad(response.data);
            this.nivelLealtadA = response.data;
            this.nivelLealtadC = response.data;
            let state = {...this.state};
            this.state.segmentacion.nivelLealtad  = [];
            this.setState(state);
            console.log("terminó");
            let segmentacion = { ...this.state.segmentacion };
        segmentacion.estado = [];
        segmentacion.ciudad = [];
        this.setState({ segmentacion, ciudades: [] });
        console.log(segmentacion);
        }).catch(err => {
            toast.error(err.response.data.Message);
        });

        SegmentacionAPI.getEstadosPorSegmentacion(this.state.segmentacion.SegmentId).then(response => {
            this.props.getEstados(response.data);
            let state = {...this.state};
            //this.segmentacion.estado = this.segmentacion.estado.map(estado => this.props.estados.find(e => e.StateId === estado.value));
            this.setState(state);
            console.log("terminó");
        }).catch(err => {
            toast.error(err.response.data.Message);
        });

        
        SegmentacionAPI.getFamiliasPorSegmentacion(this.state.segmentacion.SegmentId).then(response => {
            this.props.getFamilias(response.data);
            let state = {...this.state};
            //this.segmentacion.clasificacion && response.data.find(e => e.IdFilterFamilyProduct === this.segmentacion.clasificacion.value);
            this.setState(state);
            console.log("terminó");
        }).catch(err => {
            toast.error(err.response.data.Message);
        });
        

        // Reiniciar el Select de estado
        let segmentacion = { ...this.state.segmentacion };
        segmentacion.estado = [];
        segmentacion.ciudad = [];
        this.setState({ segmentacion, ciudades: [] });
        

    }



    // Se obtienen las ciudades con base al estado seleccionado.
    getCiudades = () => {
        SegmentacionAPI.GetCiudades([...new Set(this.state.segmentacion.estado.map(e => e.value))]).then(response => {
            var ciudades = [];
            
            response.data.forEach(state => {
                ciudades.push({label: state.State.Name, value: null});
                state.Cities.forEach(city => {
                    ciudades.push({label: city.Name, value: city.CityId, state: state.State.StateId});
                    this.ciudades.push(city);
                })
            })
            
            let state = {...this.state};
            // Se quitan las ciudades seleccionadas de estados que ya no están seleccionados.
            if(!this.props.editing) state.segmentacion.ciudad = state.segmentacion.ciudad.filter(c => response.data.find(s => s.State.StateId === c.state));
            state.ciudades = ciudades;
            this.setState(state);
            
        }).catch(err => {
            toast.error(err.response.data.Message);
        })
    }

    getCuentaLealtad = () => {
        SegmentacionAPI.getNivelesLealtadPorSegmento(this.state.segmentacion.SegmentId).then(response => {
            this.props.getCuentaLealtad(response.data);
            this.nivelLealtadA = response.data;
            this.nivelLealtadC = response.data;
            let state = {...this.state};
            this.setState(state);
            let segmentacion = { ...this.state.segmentacion };
        this.setState({ segmentacion});
        console.log(segmentacion);
        }).catch(err => {
            toast.error(err.response.data.Message);
        });
    }

    // Se obtienen las temperaturas o tipos de producto con base a la Familia seleccionada.
    getTemperaturas = () => {
        SegmentacionAPI.getTemperaturas(this.state.segmentacion.clasificacion.value).then(response => {
            let state = {...this.state};
            state.temperaturas = response.data.map(t => {return {value: t.IdFilterTypeProductModel, label: t.Description}});
            // Se eliminan las temperaturas seleccionadas previamente.
            state.segmentacion.temperatura = [];
            state.segmentacion.TypesProducts = [];
            this.temperaturas = response.data;
            this.setState(state);
        }).catch(err => {
            toast.error(err.response.data.Message);
        })
    }

    // Método para preparar el objeto segmentación para su envío al API, se ajusta al modelo.
    prepareSegmentacion = () => {
        var segmentacion = {...this.state.segmentacion};
        
        // Se mapean los arreglos de filtros seleccionados a los modelos del API.
        segmentacion.estado = segmentacion.estado.map(estado => this.props.estados.find(e => e.StateId === estado.value));
        segmentacion.ciudad = segmentacion.ciudad.map(ciudad => this.ciudades.find(e => e.CityId === ciudad.value));
        segmentacion.rangoEdad = segmentacion.rangoEdad.map(rangoEdad => this.props.rangoEdades.find(e => e.IdFilterAge === rangoEdad.value));
        segmentacion.horario = segmentacion.horario.map(horario => this.props.horarios.find(e => e.IdFilterPurchaseSchedule === horario.value));
        segmentacion.clasificacion = segmentacion.clasificacion && this.props.clasificaciones.find(e => e.IdFilterFamilyProduct === segmentacion.clasificacion.value);
        segmentacion.temperatura = segmentacion.temperatura.map(temperatura => this.temperaturas.find(e => e.IdFilterTypeProductModel === temperatura.value));
        

        // Se crea la segmentación con todos los datos ya preparados
        var seg = {
            IdSegmentation: segmentacion.IdSegmentation,
            Name: segmentacion.nombre,
            SegmentId: segmentacion.SegmentId,
            IdClassification: segmentacion.clasificacion ? segmentacion.clasificacion.IdFilterFamilyProduct : null,
            Refill: segmentacion.refill,
            TotalClients: this.state.totalClients, 
            LoyaltyAccounts: segmentacion.nivelLealtad,
            States: segmentacion.estado,
            Cities: segmentacion.ciudad,
            PurchaseDays: segmentacion.diasSemana,
            PurchaseSchedules: segmentacion.horario,
            AgeRanges: segmentacion.rangoEdad,
            Genders: segmentacion.genero,
            TypesProducts: segmentacion.temperatura,
            Status: segmentacion.Status,
            MembershipTypes: segmentacion.membresias,
        };
        return seg;
    }

    // Si las props cambian se verifica si es por una validación de la segmentación para actualizar sus datos.
    componentDidUpdate(prevProps) {
        this.clasificacionesA = this.props.clasificaciones;
        this.clasificacionesC = this.props.clasificaciones;
        this.nivelLealtadC = this.props.nivelesLealtad;
        this.nivelLealtadA = this.props.nivelesLealtad;
        console.log(this.nivelLealtadC);

        if(this.props.isValid !== prevProps.isValid){
            if (this.props.isValid){
                this.setState({validated: this.props.isValid, totalClients: this.props.totalClients});
            }else{
                this.setState({totalClients: 0});
            }
        }

        // Si se está editando, se realiza el llenado de las ciudades seleccionadas en su respectivo campo.
        if(this.props.editing && this.props.segmentacion && this.state.segmentacion.IdSegmentation != this.props.segmentacion.IdSegmentation){
            this.setState({segmentacion: this.props.segmentacion}, () => {
                this.getCiudades();
                this.getCuentaLealtad();
            });
        }
        // Si se está editando, se realiza establece el valor de Familia seleccionada en su respectivo campo.
        if(this.props.editing && this.props.clasificaciones.length > 0 && !this.state.segmentacion.clasificacion && this.state.segmentacion.IdClassification){
            this.setClassification();
        }

        // Si se está editando, se establece el valor del Segmento seleccionada en su respectivo campo.
        if(this.props.editing && this.props.segmentacion && !this.state.segmentacion.SegmentId && this.props.segmentacion.SegmentId){
            let seg = this.state.segments.find(c => c.value === this.props.segmentacion.SegmentId);
            this.state.Segment = seg;
        }

    }

    componentDidMount() {
        setTimeout(() => {
            //let seg = this.state.segments.find(c => c.value === this.props.segmentacion.SegmentId);
            this.setState({bandera: true});
        }, 3000);
    }

    componentWillMount() {
        this.setState({ isLoading: true }, () => {
            EmpresasAPI.getAllFilterSegments().then(response => {
                let tempSegmentsList = []
                response.data.map(obj => {
                    tempSegmentsList.push({
                        value: obj.SegmentId,
                        label: this.getName(obj.Name)
                    })
                })
                this.setState({ segments: tempSegmentsList,  });
            }).catch(err => {
                this.setState({ isLoading: false })
                if (err.response)
                toast.error(err.response.data.Message)
                else
                toast.error(ErrorMessages.SinConexion)
            })
        })
    }

    getName(Name) {
        switch (Name) {
            case "Andatti drive":
                return "Andatti"
            case "Drive":
                return "Caffenio"
            case "momento espresso":
                return "Momento Espresso"
            default:
                return Name
        }
    }

    
    render(){
        const segmentSel = 1;
        return (
            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="columns">
                        <div className="column is-4">
                            <label className="label">Segmentación</label>
                            <div className="control">
                                
                                <Select
                                    placeholder={"Seleccionar..."}
                                    name={"SegmentoOption"}
                                    value={this.state.Segment}
                                    autoFocus={true}
                                    onChange={(e) => {
                                        let segmentacion = {...this.state.segmentacion};
                                        segmentacion.SegmentId = -1;
                                        let seg = {}
                                        
                                        if( e !== null) {
                                            segmentacion.SegmentId = e.value;
                                            seg = e
                                        }
                                        
                                        segmentacion.clasificacion = null;
                                        segmentacion.IdClassification = null;
                                        segmentacion.nivelLealtad = [];
                                        segmentacion.temperatura = [];
                                        segmentacion.TypesProducts = [];
                                        this.props.invalidate();
                                        this.setState({segmentacion: segmentacion, temperaturas: [], Segment: seg, validated: false}, () => {

                                            if(segmentacion.SegmentId !== undefined && segmentacion.SegmentId !== -1){
                                                this.getActualizacionBySegmento();
                                            }
                                        });
                                        if(segmentacion.SegmentId !== undefined && segmentacion.SegmentId !== -1){
                                            this.setState({invalidSegment: false});
                                            //this.state.invalidSegment = true;
                                        }
                                    }}
                                    onBlur={(val) => {
                                        if(this.state.segmentacion.SegmentId === undefined || this.state.segmentacion.SegmentId === -1){
                                            this.setState({invalidSegment: true});
                                        }
                                    }}
                                    isClearable={true}
                                    id="idSegments"
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={this.state.segments}
                                />


                                {this.state.invalidSegment && <p class="help title is-6 is-danger">Segmentación es requerido.</p>}
                            </div>
                        </div>
                    </div>
                    <div class="is-divider"></div>
                </div>

                <div className="column is-4 has-text-left">
                    <Field label="Nombre de segmentación">
                        <input
                            type="text"
                            className={`input ${this.state.invalidName && "is-danger"}` }
                            maxLength={50}
                            placeholder={`Nombre de la segmentación`}
                            value={this.state.segmentacion.nombre}
                            onBlur={(e) => {
                                // Si el usuario cambia a otro campo o da click fuera se verifica si hay un valor ingresado válido.
                                if(this.state.segmentacion.nombre.length == 0){
                                    this.setState({invalidName: true});
                                }
                            }}
                            onChange={(e) => {
                                let segmentacion = {...this.state.segmentacion};
                                segmentacion.nombre = e.currentTarget.value;
                                // Se remueven los espacios en blanco en exceso.
                                segmentacion.nombre = segmentacion.nombre.replace(/\s{2,}/g, ' ');
                                this.setState({segmentacion: segmentacion, invalidName: segmentacion.nombre.length == 0});
                            }}
                        />
                    </Field>
                    {this.state.invalidName && <p class="help title is-6 is-danger">Nombre requerido</p>}
                </div>
                <div className="column is-8">
                </div>
                <div className="column is-12">
                    <div class="is-divider"></div>
                    <h1 className="title is-3">Demografía</h1>
                    <div className="columns is-multiline">
                        <div className="column is-5">
                            <label className="label">Plaza</label>
                                <div className="control">
                                <Select
                                    isMulti
                                    placeholder={"Seleccionar..."}
                                    name={"selectedOption"}
                                    value={this.state.segmentacion.estado}
                                    onChange={(e) => {
                                        let segmentacion = {...this.state.segmentacion};
                                        segmentacion.estado = e;
                                        // Por si acaso, quitar duplicados
                                        segmentacion.estado = [...new Set(segmentacion.estado)];
                                        this.props.invalidate();
                                        this.setState({segmentacion: segmentacion, validated: false}, () => {
                                            this.getCiudades();
                                        });
                                    }}
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={this.props.estados.map(e => { return {value: e.StateId, label: e.Name}})}
                                />
                            </div>
                        </div>
                        <div className="column is-5">
                            {this.state.ciudades.length > 0 && 
                                <div>
                                    <label className="label">Ciudad</label>
                                    <div className="control">
                                        <Select
                                            isMulti
                                            placeholder={"Seleccionar..."}
                                            name={"selectedOption"}
                                            value={this.state.segmentacion.ciudad}
                                            onChange={(e) => {
                                                let segmentacion = {...this.state.segmentacion};
                                                segmentacion.ciudad = e;
                                                // Por si acaso, quitar duplicados
                                                segmentacion.ciudad = [...new Set(segmentacion.ciudad)];
                                                this.props.invalidate();
                                                this.setState({segmentacion: segmentacion, validated: false});
                                            }}
                                            noOptionsMessage={() => "No hay opciones"}
                                            isOptionDisabled={(option) => !option.value}
                                            options={this.state.ciudades}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="column is-2"/>
                        <div className="column is-3">
                            <label className="label">Rango de edad</label>
                            <div className="control">
                                <Select
                                    isMulti
                                    placeholder={"Seleccionar..."}
                                    name={"selectedOption"}
                                    value={this.state.segmentacion.rangoEdad}
                                    onChange={(e) => {
                                        let segmentacion = {...this.state.segmentacion};
                                        segmentacion.rangoEdad = e;
                                        // Se quitan duplicados en caso de existir.
                                        segmentacion.rangoEdad = [...new Set(segmentacion.rangoEdad)];
                                        this.props.invalidate();
                                        this.setState({segmentacion: segmentacion, validated: false});
                                    }}
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={this.props.rangoEdades.map(e => { return {value: e.IdFilterAge, label: e.Description}})}
                                />
                            </div>
                        </div>
                        <div className="column is-9"/>
                        <div className="column is-12">
                        <h1 className="title is-4">Género</h1>
                            <div className="columns">
                                {this.props.generos.map((genero, index) => {
                                    return (
                                        <div className="column is-4" key={`genero${genero.IdGender}`}>
                                            <Checkbox 
                                                name={`genero${genero.IdGender}`}
                                                value={this.state.segmentacion.genero.findIndex(n => n.IdGender == genero.IdGender) !== -1}
                                                data={genero}
                                                labelKey={"Name"}
                                                onChange={(e) => {
                                                    let segmentacion = {...this.state.segmentacion}
                                                    let generoIndex = this.state.segmentacion.genero.findIndex(n => n.IdGender === genero.IdGender)
                                                    if(generoIndex !== -1 ){
                                                        segmentacion.genero.splice(generoIndex, 1);
                                                    }else{
                                                        segmentacion.genero.push(genero);
                                                    }
                                                    this.setState({segmentacion: segmentacion, validated: false}, () => {
                                                        this.props.invalidate();
                                                    });
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-12">
                    <div class="is-divider"></div>
                    <h1 className="title is-3">Nivel de lealtad</h1>

                    {
                    this.state.segmentacion.SegmentId == 1 ?
                    <div className="columns is-multiline">
                        {this.nivelLealtadC.map((nivel, index) => {
                            return (
                                <div className="column is-4" key ={`nivel${nivel.IdTypeLoyaltyAccount}`}>
                                    <Checkbox 
                                        name={`nivel${nivel.IdTypeLoyaltyAccount}`}
                                        value={this.state.segmentacion.nivelLealtad.findIndex(n => n.IdTypeLoyaltyAccount === nivel.IdTypeLoyaltyAccount) !== -1}
                                        data={nivel}
                                        labelKey={"Description"}
                                        onChange={(e) => {
                                            let segmentacion = {...this.state.segmentacion}
                                            let nivelIndex = this.state.segmentacion.nivelLealtad.findIndex(n => n.IdTypeLoyaltyAccount === nivel.IdTypeLoyaltyAccount)
                                            if(nivelIndex !== -1 ){
                                                segmentacion.nivelLealtad.splice(nivelIndex, 1);
                                            }else{
                                                segmentacion.nivelLealtad.push(nivel);
                                            }
                                            this.props.invalidate();
                                            this.setState({segmentacion: segmentacion, validated: false});
                                        }}
                                    />
                                </div>
                            )
                        })} </div>
                        :
                        (<div className="columns is-multiline">
                            {this.nivelLealtadA.map((nivel, index) => {
                                return (
                                    <div className="column is-4" key ={`nivel${nivel.IdTypeLoyaltyAccount}`}>
                                        <Checkbox 
                                            name={`nivel${nivel.IdTypeLoyaltyAccount}`}
                                            value={this.state.segmentacion.nivelLealtad.findIndex(n => n.IdTypeLoyaltyAccount === nivel.IdTypeLoyaltyAccount) !== -1}
                                            data={nivel}
                                            labelKey={"Description"}
                                            onChange={(e) => {
                                                let segmentacion = {...this.state.segmentacion}
                                                let nivelIndex = this.state.segmentacion.nivelLealtad.findIndex(n => n.IdTypeLoyaltyAccount === nivel.IdTypeLoyaltyAccount)
                                                if(nivelIndex !== -1 ){
                                                    segmentacion.nivelLealtad.splice(nivelIndex, 1);
                                                }else{
                                                    segmentacion.nivelLealtad.push(nivel);
                                                }
                                                this.props.invalidate();
                                                this.setState({segmentacion: segmentacion, validated: false});
                                            }}
                                        />
                                    </div>
                                )
                            })}                        
                        </div>)
                        }
    
                    </div>
                    
                <div className="column is-12">
                    <div class="is-divider"></div>
                    <h1 className="title is-3">Membresías</h1>
                    <div className="columns is-multiline">
                        

                        {this.props.membresias.map((mem, index) => {
                            return (
                                <div className="column is-4" key ={`nivel${mem.IdMembresia}`}>
                                    <Checkbox 
                                        name={`nivel${mem.IdMembresia}`}
                                        value={this.state.segmentacion.membresias.findIndex(n => n.IdMembresia === mem.IdMembresia) !== -1}
                                        data={mem}
                                        labelKey={"Membresia"}
                                        onChange={(e) => {
                                            let segmentacion = {...this.state.segmentacion}
                                            let memIndex = this.state.segmentacion.membresias.findIndex(n => n.IdMembresia === mem.IdMembresia)
                                            if(memIndex !== -1 ){
                                                segmentacion.membresias.splice(memIndex, 1);
                                            }else{
                                                segmentacion.membresias.push(mem);
                                            }
                                            this.props.invalidate();
                                            this.setState({segmentacion: segmentacion, validated: false});
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="column is-12">
                    <div class="is-divider"></div>
                    <h1 className="title is-3">Horarios y hábitos</h1>
                    <div className="columns">
                        <div className="column is-3">
                            <label className="label">Horario</label>
                            <div className="control">
                                <Select
                                    isMulti
                                    placeholder={"Seleccionar..."}
                                    name={"selectedOption"}
                                    value={this.state.segmentacion.horario}
                                    onChange={(e) => {
                                        let segmentacion = {...this.state.segmentacion};
                                        segmentacion.horario = e;
                                        // Por si acaso, quitar duplicados
                                        segmentacion.horario = [...new Set(segmentacion.horario)];
                                        this.props.invalidate();
                                        this.setState({segmentacion: segmentacion, validated: false});
                                    }}
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={this.props.horarios.map(e => { return {value: e.IdFilterPurchaseSchedule, label: e.Description}})}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="column is-12">
                    <div className="columns">
                        <div className="column is-2"/>
                        {this.props.diasSemana.map((dia, index) => {
                            return (
                                <div className="column is-1" key={`dia${index}`}>
                                    <Checkbox 
                                        name={`dia${index}`}
                                        value={this.state.segmentacion.diasSemana.findIndex(n => n.IdFilterPurchaseDay === dia.IdFilterPurchaseDay) !== -1}
                                        data={dia}
                                        labelKey={"Name"}
                                        onChange={(e) => {
                                            this.daySelected(dia);
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
                
                <div className="column is-12">
                    <div class="is-divider"></div>
                    <h1 className="title is-3">Productos</h1>
                    <div className="columns">
                        <div className="column is-3">
                            <label className="label">Familia</label>
                            <div className="control">
                            {
                                segmentSel !== 1 ?
                                <Select
                                    placeholder={"Seleccionar..."}
                                    name={"selectedOption"}
                                    value={this.state.segmentacion.clasificacion}
                                    isClearable
                                    onChange={(e) => {
                                        let segmentacion = {...this.state.segmentacion};
                                        if (e){
                                            segmentacion.clasificacion = e;
                                            this.props.invalidate();
                                            this.setState({segmentacion: segmentacion, validated: false}, () => this.getTemperaturas());
                                        }else{
                                            segmentacion.clasificacion = null;
                                            segmentacion.IdClassification = null;
                                            segmentacion.temperatura = []
                                            segmentacion.TypesProducts = [];
                                            this.props.invalidate();
                                            this.setState({segmentacion: segmentacion, validated: false, temperaturas: []});
                                        }
                                        
                                    }}
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={this.clasificacionesA.map(e => { return {value: e.IdFilterFamilyProduct, label: e.Name}})}
                                />
                                :
                                (
                                    <Select
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOption"}
                                        value={this.state.segmentacion.clasificacion}
                                        isClearable
                                        onChange={(e) => {
                                            let segmentacion = {...this.state.segmentacion};
                                            if (e){
                                                segmentacion.clasificacion = e;
                                                this.props.invalidate();
                                                this.setState({segmentacion: segmentacion, validated: false}, () => this.getTemperaturas());
                                            }else{
                                                segmentacion.clasificacion = null;
                                                segmentacion.IdClassification = null;
                                                segmentacion.temperatura = []
                                                segmentacion.TypesProducts = [];
                                                this.props.invalidate();
                                                this.setState({segmentacion: segmentacion, validated: false, temperaturas: []});
                                            }
                                            
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.clasificacionesC.map(e => { return {value: e.IdFilterFamilyProduct, label: e.Name}})}
                                    />
                                )
                            }
                            </div>
                        </div>
                        {this.state.temperaturas.length > 0 && <div className="column is-3">
                            <label className="label">Tipo de Producto</label>
                            <div className="control">
                                <Select
                                    isMulti
                                    placeholder={"Seleccionar..."}
                                    name={"selectedOption"}
                                    value={this.state.segmentacion.temperatura}
                                    onChange={(e) => {
                                        let segmentacion = {...this.state.segmentacion};
                                        segmentacion.temperatura = e;
                                        // Por si acaso, quitar duplicados
                                        segmentacion.temperatura = [...new Set(segmentacion.temperatura)];
                                        this.props.invalidate();
                                        this.setState({segmentacion: segmentacion, validated: false});
                                    }}
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={this.state.temperaturas}
                                />
                            </div>
                        </div>}
                        <div className="column is-3">
                            <FilterLoading>
                                <Field label="Refill">
                                    <input   
                                        type="checkbox"
                                        name="isRefill"
                                        className="switch"
                                        checked={this.state.segmentacion.refill}
                                        onChange={() => {}}
                                    />
                                    <label htmlFor="isRefill" className="inputCenter" onClick={(e) => {
                                        e.stopPropagation();
                                        let segmentacion = {...this.state.segmentacion};
                                        segmentacion.refill = !segmentacion.refill;
                                        this.props.invalidate();
                                        this.setState({segmentacion: segmentacion, validated: false});
                                    }}/>
                                </Field>
                            </FilterLoading>
                        </div>
                    </div>
                </div>

                <div className="column is-12">
                    
                    <div className="field is-grouped is-grouped-right">
                        <p className="control is-expanded">
                        {this.state.totalClients > 0 && <h1 className="title is-4">{`Total de clientes: ${this.state.totalClients}`}</h1>}
                        </p>
                        <p className="control">
                            <button
                                className="button"
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: '/app/segmentaciones',
                                        state: this.props.location.state ? {currentPage: this.props.location.state.currentPage || 0, name: this.props.location.state.name || ""} : {currentPage: 1, name: ""}
                                    });
                                }}>
                                {this.state.validated ? "Cancelar" : "Regresar"}
                            </button>
                        </p>
                        
                        {this.state.validated && this.state.clients.length > 0 && <p className="control">
                            <button
                                className="button is-success"
                                disabled={!this.dataIsValid()}
                                onClick={() => {
                                    if(!this.state.working){
                                        if (this.segmentationHasNoFilters()){
                                            this.setState({confirmModal: true});
                                        }else
                                            this.props.save(this.prepareSegmentacion(), this.props.editing)    
                                    }
                                    // Si la segmentación no tiene filtros seleccionados, se preguntará al usuario si está seguro de continuar
                                    
                                }}>
                                Guardar
                            </button>
                        </p>}
                        
                        {!this.state.validated && <p className="control">
                            <button
                                className="button is-success"
                                disabled={this.state.segmentacion.nombre.length < 3 || this.state.segmentacion.SegmentId === undefined || this.state.segmentacion.SegmentId === -1}
                                onClick={() => {
                                    this.props.validate(this.prepareSegmentacion())
                                }}>
                                Validar segmentación
                            </button>
                        </p>}
                        
                    </div>
                </div>
                {this.state.confirmModal &&
                    <AcceptModal 
                        modalTitle="Confirmar"
                        modalBody={<p>¿Está seguro que desea guardar la segmentación sin filtros?</p>}
                        isActive={this.state.confirmModal}
                        willSave
                        done={() => {
                            if(!this.state.working){
                                this.setState({working: true}, () => this.props.save(this.prepareSegmentacion(), this.props.editing));
                            }
                            
                        }}
                        cancel={() => this.setState({confirmModal: false})}
                    />
                }
            </div>
        )
    }
}

SegmentacionForm.defaultProps = {
    estados: [],
    ciudades: [],
    rangoEdades: [],
    generos: [],
    nivelesLealtad: [],
    horarios: [],
    diasSemana: [],
    clasificaciones: [],
    temperaturas: [],
    membresias:[],
    segments: []
}

export default SegmentacionForm;