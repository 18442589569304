import React, { Component } from 'react';
import { toast } from '../../../node_modules/react-toastify';
import InventoryTypeForm from './InventoryTypeForm';
import InventoryTypesAPI from '../../services/InventoryType';
import '../cssComponents.css';
import Loading from '../util/loading/Loading';
import ErrorMessages from '../../util/validation-error-messages';
import Modal from '../util/modal/Modal';

class NewInventoryType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventoryType: {},            
            isLoading: false
        }
    }

    componentDidMount() {        
        if (this.props.match.params.id) {
            this.setState({ isLoading: true });
            InventoryTypesAPI.get(this.props.match.params.id).then(response => {
                this.setState({ isLoading: false, inventoryType: response.data }, () => console.log(this.state));
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        }
    }

    save = (inventoryType) => {
        let { DateUpdate, ...body } = inventoryType;
        if (inventoryType.InventoryTypeId) {
            inventoryType.Description = inventoryType.Description.trim();
            if (inventoryType.Description.length < 3) {
                toast.error("Descripción debe de tener más de 2 caracteres");
                return;
            }

            this.setState({isLoading: true}, () => {
                InventoryTypesAPI.edit(body).then(response => {
                    this.props.history.goBack();
                    toast.success("Tipo Inventario actualizado");
                }).catch(err => {
                    this.setState({isLoading: false})
                    if(err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                });
            })
        } else {
            if (inventoryType.hasOwnProperty('Description')) {
                inventoryType.Description = inventoryType.Description.trim();
                if (inventoryType.Description.length < 3) {
                    toast.error("Descripción debe de tener más de 2 caracteres");
                    return;
                }
            } else {
                toast.error("Descripción debe de tener más de 2 caracteres");
                return;
            }

            this.setState({isLoading: true}, () => {
                InventoryTypesAPI.create(inventoryType).then(response => {
                    this.props.history.goBack();
                    toast.success("Tipo Inventario creado");
                }).catch(err => {
                    this.setState({isLoading: false})
                    if(err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                });
            })
        }
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{ position: "absolute", left: 25 }} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{ cursor: "pointer", color: "#91BA43" }} onClick={() => {this.props.history.goBack()}} className="fa fa-arrow-left"></i>
                            </span>
                            <div>
                                {this.state.inventoryType.InventoryTypeId ? "Editar Tipo Inventario" : "Nuevo Tipo Inventario"}
                                <i className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }} onClick={() => this.setState({ showModal: true })} className="fa fa-question-circle"></i>
                            </div>
                        </div>
                    </div>
                    <div className="card-content">
                        <InventoryTypeForm 
                            save={this.save} 
                            inventoryType={this.state.inventoryType} 
                        />
                    </div>
                </div>
                {this.state.showModal &&
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Información de Tipo Inventario"}
                        cancel={() => this.setState({ showModal: false })}
                    >
                        <div className="has-text-justified">
                        
                            El tipo inventario se relaciona directamente con los artículos, impacta a las herramientas de inventarios en los puntos de venta.
                            El tipo de inventario solo se podrá eliminar en caso de no estar vinculada con ningún artículo.

                            <hr/>
                            <p className="has-text-justified">
                                <strong>Nombre</strong>: es un campo requerido, 
                                debe tener una longitud mínima de 2 a 50 caracteres máximo
                                 y no se debe duplicar.                       
                            </p>
                            
                        </div>
                       

                    </Modal>
                }
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
            </div>
        );
    }
}

export default NewInventoryType;
