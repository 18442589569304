let roles = {
    ADMIN: "Administrador",
    SUPERADMIN: "SuperAdmin",
    MKT: "MKT",
    MKT_OS:"Oficina Soporte Mercadotecnia",
    MKT_REGIONAL:"Regional Mercadotecnia",
    COORDINADOR_DISTRIBUCION: "Coordinador de Distribución",
    SUPERVISOR_VENTA_DRIVE: "Supervisor de punto de venta drive",
    ESPECIALISTA_DE_PRODUCTO : "Especialista de producto",
    ESTRATEGIA_DIGITAL : "Estrategia Digital",
    ESPECIALISTA_DE_GESTION: "Especialista de Gestión",
    COORDINADOR_GESTION_OPERACIONES: "Coordinador de Gestión de Operaciones",
    CRM : "CRM",
    USUARIO: "Usuario",
    OPERADOR: "Operador",
    CDS: "CDS",
    DRIVE: "Drive",
    SUCURSAL: "Sucursal",
    SOPORTE: "Soporte",
    COORDINADOR_SERVICIOS: "Coordinador de Servicios",
    ASESOR: "Asesor",
    ADMINDRIVE: "AdminDrive",
    ADMINBARRA: "AdminBarra",
    SAP: "SAP",
    CAT: "CAT",
    CCH: "CCH",
    FAT_OS: "FAT-OS",
    FAT_R: "FAT-R",
    ADMIN_APP: "Administrador App Movil",
    ADMIN_UBER_EATS: "Admin UberEats",
    REPORTES: "Reportes",
    MICAFFENIO_EMPRESAS: "CRM Empresarial",
    LIDER_DRIVE: "LiderDrive", 
    ENCARGADO_DE_TURNO: "Encargado de Turno",
    CONFIGURACION_VP: "ConfiguracionVP",
    CRM_EMPRESARIAL: "CRM Empresarial",
    COORD_OPER_DRIVE: "Coordinador de Operaciones Drive"

}

export default roles;