import React from 'react';

import {Link} from 'react-router-dom'

class ModoGuiado extends React.Component {
    constructor() {
        super();
        this.state = {
            display: null,
            type: null
        }
    }

    componentDidMount() {
        this.setState({
            display: localStorage.getItem("modoGuiado"),
            type: localStorage.getItem("tipoModoGuiado")
        }, () => {
            if (this.props.tipoModoGuiado && this.props.tipoModoGuiado === this.state.type) {
                if(this.props.ruta) {
                    localStorage.setItem("ultimaRuta", this.props.ruta)
                }
            }
            
        })



    }

    render() {
        if (this.state.display === 'true' && 
            this.props.tipoModoGuiado && 
            this.props.tipoModoGuiado === this.state.type) {
                
            return (
                <div className="componenteModoGuiado">

                    <div className="card">
                        <div className="card-content">
                            <div className="columns is-vcentered">
                                <div className="column is-2">
                                    {
                                        this.props.anterior &&
                                        <Link to={this.props.anterior}>
                                            <button className="button is-success">Anterior</button>
                                        </Link>
                                    }
                                </div>
        
                                <div className="column is-8">
                                    {
                                        this.props.mensaje &&
                                        <p>{this.props.mensaje}</p>
                                    }
                                </div>
        
                                <div className="column is-2">
                                    {
                                        this.props.siguiente &&
                                        <Link to={this.props.siguiente}>
                                            <button className="button is-success">Siguiente</button>
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>

            )
        }else {
            return (
                null
            )
        }
    }

} 

export default ModoGuiado;