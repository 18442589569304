import React, { Component } from 'react';
import Select from 'react-select';
import Field from '../util/field/Field';
import lodash from 'lodash';

import { Formik } from 'formik';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip'

import {PromoDetailReembolsoProducto} from './components/PromoDetailReembolsoProducto';
import {PromoDetailReembolsoRecarga} from './components/PromoDetailReembolsoRecarga';
import {PromoDetailDescuento} from './components/PromoDetailDescuento';
import {optionsTipoCantidad, RecursividadDescuento, Recursividad, aplicaPara, tipos } from '../../util/strings/tipo-promos';

const subtitle = {
    textAlign: 'initial',
    padding: '10px 10px 10px 0',
    fontSize: '16px',
    color: 'gray'
}

class DetallePromo extends Component {

    constructor(props) {
        super(props);
        this.state = {...this.props, ByProduct: true }      
        this.typePromotion = isNaN(this.props.values.promotion.idTipoPromo)
            ? this.props.values.promotion.idTipoPromo.label 
            : this.props.values.listTipoPromos.filter(item => item.value === this.props.values.promotion.idTipoPromo)[0].label      
        this.detalle = this.props.values.detalle
        this.listReembolsoTipo = this.props.values.listReembolsoTipo
        this.listTipoDescuento = this.props.values.listTipoDescuento
        this.tipoRegalos = this.props.values.tipoRegalos
        this.selectedApp = this.props.values.detalle.aplicaPara && this.props.values.detalle.aplicaPara.findIndex(item => item.value == '2') >= 0 ? true :false
        this.promotion = this.props.values.promotion
        this.detalle.idTipoReembolso = this.detalle.idTipoReembolso && Object.keys(this.detalle.idTipoReembolso).length === 0 ? null : this.detalle.idTipoReembolso
        this.detalle.idTipoRegalo = this.detalle.idTipoRegalo && Object.keys(this.detalle.idTipoRegalo).length === 0 ? null : this.detalle.idTipoRegalo
        this.detalle.idTipoDescuento = this.detalle.idTipoDescuento && Object.keys(this.detalle.idTipoDescuento).length === 0 ? null : this.detalle.idTipoDescuento        
    }    

    VALIDATION_SCHEMA_REEMBOLSO_RECARGA = Yup.object({
        idTipoReembolso: Yup.string().required("Tipo reembolso es requerido.").nullable(),
        idTipoRegalo: Yup.string().required("Tipo de regalo es requerido.").nullable(),
        aplicaPara: Yup.string().required("Debe seleccionar dónde aplicará la promoción.").nullable(),
        quantityToRecharge: Yup.number()
            .min(1, "Ingrese valores mayores a 0.")
            .max(999999999, 'Ha excedido el límite de caracteres.')
            .required("El campo cantidad a recargar es requerido."),
        quantity: Yup.number()
            .min(1, "Ingrese valores mayores a 0.")
            .max(999999999, 'Ha excedido el límite de caracteres.')
            .required("El campo cantidad es requerido."),
        isRecursiveSelected : Yup.string().required("Campo recursividad es requerido.").nullable(),
        isPercentageSelected : Yup.string().required("Campo tipo de cantidad a reembolsar es requerido.").nullable(),  
    });

    VALIDATION_SCHEMA_REEMBOLSO_PRODUCTO  = Yup.object({
        idTipoReembolso: Yup.string().required("Tipo reembolso es requerido.").nullable(),
        idTipoRegalo: Yup.string().required("Tipo de regalo es requerido.").nullable(),
        aplicaPara: Yup.string().required("Debe seleccionar dónde aplicará la promoción.").nullable(),
        quantity: Yup.number()
            .min(1, "Ingrese valores mayores a 0.")
            .max(999999999, 'Ha excedido el límite de caracteres.')
            .required("El campo cantidad es requerido."),
        isPercentageSelected : Yup.string().required("Campo tipo de cantidad a reembolsar es requerido.").nullable(),  
    });

    VALIDATION_SCHEMA_DESCUENTO = Yup.object({
        idTipoDescuento: Yup.string().required("Tipo descuento es requerido.").nullable(),
        aplicaPara: Yup.string().required("Debe seleccionar dónde aplicará la promoción.").nullable(),
        quantity: Yup.number()
            .min(1, "Ingrese valores mayores a 0.")
            .max(999999999, 'Ha excedido el límite de caracteres.')
            .required("El campo cantidad es requerido."),
        isRecursiveDescuentoSelected :  Yup.string().required("Campo recursividad es requerido.").nullable(),
    });

    VALIDATION_SCHEMA_QR_CUSTOM = Yup.object({
        idTipoDescuento: Yup.string().required("Tipo descuento es requerido.").nullable(),
        aplicaPara: Yup.string().required("Debe seleccionar dónde se aplicará la promoción.").nullable(),
        quantity: Yup.number()
            .min(1, "Ingrese valores mayores a 0.")
            .max(999999999, 'Ha excedido el límite de caracteres.')
            .required("El campo cantidad es requerido."),
        isRecursiveDescuentoSelected :  Yup.string().required("Campo recursividad es requerido.").nullable(),
    });

    VALIDATION_SCHEMA_QR_REFUND = Yup.object({
        aplicaPara: Yup.string().required("Debe seleccionar dónde aplicará la promoción.").nullable(),
    });

    // Solo permite números, sin signos ni exponenciales
    onKeyPressNumber = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/\+|-|e|E|\./.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
        event.preventDefault();
    }

    setByProducto = (value) => {
        this.setState({ByProduct:value})
        // this.ByProduct = value;
    }

    render() {

        return (

            <Formik
                enableReinitialize={true}
                initialValues={this.state.values.detalle}
                validationSchema={this.typePromotion === tipos.reembolso 
                    ? ( this.state.ByProduct ? this.VALIDATION_SCHEMA_REEMBOLSO_PRODUCTO : this.VALIDATION_SCHEMA_REEMBOLSO_RECARGA )
                    : (this.typePromotion === tipos.descuento || this.typePromotion === tipos.Combo) ?  this.VALIDATION_SCHEMA_DESCUENTO :
                    this.typePromotion === tipos.qrCustom ? this.VALIDATION_SCHEMA_QR_CUSTOM :
                    this.typePromotion === tipos.qrRefund ? this.VALIDATION_SCHEMA_QR_REFUND : null 
                }
                onSubmit={(values) => {             
                    
                    this.state.setValues(values)
                    this.state.handleNextStep();
                }}>

                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                    <form onSubmit={handleSubmit} id="frmBase" className="has-text-left" style={{ width: '100%' }}>

                        <div className='form-group'>

                        
                        {
                            this.typePromotion === tipos.reembolso &&
                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-6'>                             
                                        <label className='label'>Tipo de reembolso</label>
                                        <div className='control'>
                                            <Select                                                
                                                id="idTipoReembolso"
                                                name="idTipoReembolso"
                                                value={values.idTipoReembolso}
                                                placeholder="Seleccionar..."
                                                noOptionsMessage={() => "No hay opciones"}
                                                options={this.listReembolsoTipo}
                                                onChange={(val) => {                                                     
                                                    this.setByProducto(val && val.label ==='Por producto');
                                                    setFieldValue('idTipoReembolso', val)
                                                }}
                                                onBlur={() => setFieldTouched('idTipoReembolso', true)}
                                            />
                                        </div>
                                        <div className="is-centered">
                                            {(errors.idTipoReembolso || touched.idTipoReembolso) &&
                                                <div style={{ 'fontSize': '12px', 'color': '#cc0b19' }}>
                                                    {errors.idTipoReembolso ? 
                                                    errors.idTipoReembolso : ""}
                                                </div>
                                            }
                                        </div>  
                                    </div>

                                    <div className='column is-6'>
                                    <label className='label'>Tipo de regalo</label>
                                        <div className='control'>
                                            <Select
                                                id="idTipoRegalo"
                                                name="idTipoRegalo"
                                                value={values.idTipoRegalo}
                                                placeholder="Seleccionar..."
                                                noOptionsMessage={() => "No hay opciones"}
                                                options={this.tipoRegalos}
                                                onChange={(val) => {
                                                    setFieldValue('idTipoRegalo', val)
                                                }}
                                                onBlur={() => setFieldTouched('idTipoRegalo', true)}
                                            />
                                            <div className="is-centered">
                                            {(errors.idTipoRegalo || touched.idTipoRegalo) &&
                                                <div style={{ 'fontSize': '12px', 'color': '#cc0b19' }}>
                                                    {errors.idTipoRegalo ? errors.idTipoRegalo : ""}
                                                </div>
                                            }
                                            </div> 
                                        </div>                        
                                    </div>

                                </div>
                            </div>
                        }
                        {
                            ( values.idTipoReembolso && values.idTipoReembolso.label == 'Por producto') &&
                            <PromoDetailReembolsoProducto 
                                values={values} 
                                errors = {errors} 
                                touched = {touched}
                                optionsTipoCantidad={optionsTipoCantidad} 
                                setFieldValue = {setFieldValue}
                                setFieldTouched ={setFieldTouched}
                                handleChange = {handleChange}                                
                                onKeyPressNumber ={this.onKeyPressNumber}/>
                                          
                        }            
                        
                        {
                            (values.idTipoReembolso && values.idTipoReembolso.label == 'Por recarga') &&
                            <PromoDetailReembolsoRecarga
                                values={values} 
                                errors = {errors} 
                                touched = {touched}
                                optionsTipoCantidad={optionsTipoCantidad} 
                                setFieldValue = {setFieldValue}
                                setFieldTouched ={setFieldTouched}
                                handleChange = {handleChange}                                
                                onKeyPressNumber ={this.onKeyPressNumber}
                                Recursividad = {Recursividad}/>
                                          
                        }
            
            
                        {
                            (this.typePromotion === tipos.descuento || this.typePromotion === tipos.Combo || this.typePromotion === tipos.qrCustom ) ?
                            <PromoDetailDescuento 
                                values={values} 
                                errors = {errors} 
                                touched = {touched}
                                setFieldValue = {setFieldValue}
                                setFieldTouched ={setFieldTouched}
                                handleChange = {handleChange}                                
                                onKeyPressNumber ={this.onKeyPressNumber}
                                RecursividadDescuento = {RecursividadDescuento}
                                listTipoDescuento = {this.listTipoDescuento}
                            />:''
                            
                        
                        }
            
                        {
                            values.checkCantidadMinima &&
                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-6'>

                                        <Field label={<div>Cantidad mínima
                                                <i data-tip data-for="minProductsTip" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                                            </div>}                                         
                                            alingLefth = {true}
                                            hasError={values.minProducts == 0}
                                            msgError={values.minProducts == 0? "Ingrese valores mayores a 0.": 
                                                values.minProducts > 999999999 ? "Ha excedido el límite de caracteres." : ""}>
                                            <input
                                                className="input"
                                                type="number"
                                                label="minProducts"                                                
                                                placeholder='Cantidad mínima'
                                                name="minProducts"
                                                maxLength="10"
                                                min="1"
                                                value={values.minProducts}
                                                onChange={(e) => handleChange(e)}
                                                onKeyPress={this.onKeyPressNumber}

                                            />
                                        </Field> 

                                        <ReactTooltip id="minProductsTip" place="top" effect="solid">
                                            Se necesita esta cantidad mínima de productos <br />
                                            para poder aplicar la promoción<br />
                                        </ReactTooltip>                   
                                    </div>
                                </div>
                            </div>
                        } 

                        <div className='row'>
                            <div className='columns'>
                                <div className='column is-6'>
                                <label className='label'>Medios donde aplica</label>
                                    <div className='control'>
                                        <Select
                                            id="aplicaPara"
                                            name={"aplicaPara"}
                                            value={values.aplicaPara}
                                            placeholder={"Seleccionar..."}
                                            isMulti={true}
                                            noOptionsMessage={() => "No hay opciones"}
                                            options={lodash.sortBy(aplicaPara, [ts => ts.label.toLowerCase()], ['asc'])}
                                            onChange={(val) => {
                                                this.selectedApp = val.findIndex(item => item.value == '2') >= 0 ? true :false
                                                setFieldValue('aplicaPara', val)
                                            }}
                                            onBlur={() => setFieldTouched('aplicaPara', true)}
                                        />
                                    </div>   
                                    <div className="is-centered">
                                        {(errors.aplicaPara || touched.aplicaPara) &&
                                            <div style={{ 'fontSize': '12px', 'color': '#cc0b19' }}>
                                                {errors.aplicaPara ? errors.aplicaPara : ""}
                                            </div>
                                        }
                                    </div>   
                                </div>    
                            </div>
                        </div>
                        
            
                        {
                            this.selectedApp &&
                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-6'>        
                                        <Field label={<div>Descripción en APP
                                                <i data-tip data-for="descripcionAPPTip" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                                            </div>}
                                            alingLefth = {true}                                
                                            hasError={values.descripcionAPP == ""}
                                            msgError={values.descripcionAPP == "" ? "Descripción es requerida" : ""}
                                            showLeftSpaces={true}
                                            childInfo={values.descripcionAPP && !errors.descripcionAPP ? `Te quedan ${250 - values.descripcionAPP.trim().length} caracteres para la descripción.` : ""}
                                        >
                                            <input
                                                id="descripcionAPP"
                                                name={"descripcionAPP"}                                                
                                                className={`input ${errors.descripcionAPP && touched.descripcionAPP ? "is-danger" : ""}`}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                maxLength="250"
                                                value={values.descripcionAPP}

                                            />
                                        </Field>
                                        <ReactTooltip id="descripcionAPPTip" place="top" effect="solid">
                                            Nombre que aparece al ver el detalle de una promoción <br />
                                            en el módulo de promociones de la aplicación móvil
                                        </ReactTooltip>   
                                    </div>
                                           
                                </div>
                            </div>
                        }
            
                            <br/>
                            <div className='row' style={{ padding: '10px' }}>
                                <div className='columns'>
                                    <div className='column is-12'>
                                        <div className="has-text-left buttons-modal" id="stepActions">
                                            <div className="pull-left">
                                                <button
                                                    className='button is-light'
                                                    style={{ marginRight: 10 }}
                                                    onClick={ () => {
                                                        this.state.setValues(values)
                                                        this.state.handleLastStep() }                                                        
                                                    }
                                                >
                                                    Anterior
                                                </button>                                                

                                            </div>
                                        </div>
                                        <div className="has-text-right buttons-modal" id="stepActions">
                                            <div className="pull-right">
                                                <button className='button is-success' type="submit">
                                                    Continuar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                        </div>                        
                    </form>
                )}      
        </Formik>
        )
    }
}


export default DetallePromo;
