import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './caffenioStyle.css';
import lodash from 'lodash';
import moment from 'moment';

// Components
import { toast } from 'react-toastify';
//import { CaffenioForm } from './CaffenioForm';
import Loading from '../util/loading/Loading';
import StepInfoDrive from './driveSteps/StepInfoDrive';
import StepFacturacionDrive from './driveSteps/StepFacturacionDrive';
import StepLocationDrive from './driveSteps/StepLocationDrive';
import Modal from '../util/modal/Modal';
import AcceptModal from '../util/acceptModal/AcceptModal';

// Setvices
import CaffenioDriveAPI from '../../services/caffenioDrive';
import ComboDatosAPI from '../../services/ComboDatos';
import PlazaAPI from '../../services/PlazaAdmin';
import SucursalAPI from '../../services/SucursalAdmin';

import ErrorMessages from '../../util/validation-error-messages';

/**
 * Formulario para nuevo Drive.
 * @returns Component
 */
export const NewCaffenio = (props) => {
    if (!props.location.state) {
        props.history.push(`/recetas`);
        return;
    }
     const [state, setState] = useState({
        isLoading: false,
        isEditing: false,
        showInfoModal:false,
        caffenio: {},
        stepInfoDrive: true,
        stepFacturacionDrive: false,
        stepLocationDrive: false,
        stepInfoDriveComplete: false,
        stepFacturacionDriveComplete: false,
        stepLocationDriveComplete: false,
        arrDatosFact: [],
        arrTasaImp: [],
        arrSegmento: [],
        arrPlazas:[],
        arrSucursales:[],
    });

    /**Carga información del form */
    useEffect(() => {
        
        setState({
            ...state,
            isLoading: true
        });

        let arrDatosFact = [];
        let arrTasaImp = [];
        let arrSegmento = [];
        let caffenio={};
        let arrPlazas = [];
        let arrSucursales = [];
        let isEditing =  props.location.state.isEditing;

        Promise.all([
            ComboDatosAPI.getFactData().then(response => arrDatosFact = response.data.filter(d=>d.FactDataId === 11)),
            ComboDatosAPI.getTaxRate().then(response => arrTasaImp = lodash.sortBy( response.data,  [ts => ts.Description.toLowerCase()], ['asc'])),
            ComboDatosAPI.getSegment().then(response => arrSegmento =lodash.sortBy( response.data,  [ts => ts.Name.toLowerCase()], ['asc'])),          
            PlazaAPI.getPlazas().then(response => arrPlazas = lodash.sortBy( response.data.map(p => {
                p.label = p.Nombre;
                p.value = p.idPlaza;
                return p;
            }),  [ts => ts.Nombre.toLowerCase()], ['asc'])),
            SucursalAPI.getSucursales().then(response => arrSucursales = lodash.sortBy( response.data.map(s=>{
                s.label = s.Nombre;
                s.value = s.idSucursal;
                return s;
            }),  [ts => ts.Nombre.toLowerCase()], ['asc'])),
            (props.location.state.caffenio && props.location.state.caffenio.idCaffenio ?
                CaffenioDriveAPI.getCaffenio(props.location.state.caffenio.idCaffenio).then(response => {
                    caffenio = response.data
                    caffenio.CloseDate = caffenio.CloseDate ? new Date(caffenio.CloseDate) :  '';
                    caffenio.OpenDate = caffenio.OpenDate ?  new Date(caffenio.OpenDate) : '';  
                    caffenio.Colonia = {label:caffenio.Colonia, value:caffenio.Colonia} ;                   
                    if(caffenio.idPlazaAdmin){
                        let plz = arrPlazas.filter(p => p.idPlaza === caffenio.idPlazaAdmin);
                        let suc = plz ? arrSucursales.filter(s => s.idSucursal === plz[0].idSucursal) : null;
                        caffenio.Plaza = plz ? plz[0] : null;
                        caffenio.Sucursal = suc ? suc[0] : null;                        
                    }
                })
            :'')
        ]).catch(err => {
            setState({
                ...state,
                isLoading: false
            })
            if (err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        }).finally(() => setState({
            ...state,
            arrDatosFact,
            arrTasaImp,
            arrSegmento,
            caffenio,
            isLoading: false,
            arrPlazas,
            arrSucursales,
            isEditing
        }));
    }, []);

    /**
     * Guardado de los datos de caffenio.
     * @param { Object } caffenio - Datos del Drive a guardar.
     */
    const handleSave = (caffenio, callback) => {
        setState({
            ...state,
            isLoading: true
        });
        let caffenioUpdated = {...caffenio};
        caffenioUpdated.OpenDate = moment(new Date(caffenioUpdated.OpenDate)).format("YYYY-MM-DDT00:00:00.000")+"Z"; 

        if (caffenioUpdated.isClosed && caffenioUpdated.CloseDate){
            caffenioUpdated.CloseDate= moment(new Date(caffenioUpdated.CloseDate)).format("YYYY-MM-DDT00:00:00.000")+"Z";
        }else{                    
            caffenioUpdated.CloseDate= '';
        }
        CaffenioDriveAPI.createCaffenio(caffenioUpdated)
        .then(res => {
            toast.success('Drive guardado');
            window.history.back();
        })
        .catch(err => {
            let message = 'Ocurrio un error';
            try {
                message = err.response.data.Message;
            } catch (error) {
                message = 'Ocurrió un error al crear PDV.';
            } finally {
                toast.error(message);
                setState({ ...state, isLoading: false });
            }
        })
        .finally(() => {                
            setState({
                ...state,
                isLoading: false
            });
            callback();
            //handleGoBack();
        })
        
    }

    /***
     * Edita un PDV
     */
    const handleUpdate = (caffenio)=>{
        setState({
            ...state,
            showInfoConfirm: true,
            caffenio: caffenio,
            stepLocationDriveComplete: true
        });       
        
    }

    /**
     * Regresa a la ruta anterior.
     */
    const handleGoBack = () => {
        window.history.back();
    }

    return (
        <div>
            {
                state.isLoading &&
                <Loading
                    isFullscreen={true}
                    isLoading={state.isLoading}
                    width='100px'
                    height='100px'
                />
            }
            <div className="card">
                <div className="card-header">
                    <div className="card-header-title is-size-4 center-text">
                        <span style={{ position: "absolute", left: 25 }} className="is-primary tooltip" data-tooltip="Ir Atrás">
                            <i
                                className="fa fa-arrow-left"
                                style={{ cursor: "pointer", color: "#91BA43" }}
                                onClick={handleGoBack}
                            />
                        </span>
                        
                        <div>
                            {`${state.isEditing ? "Editar Drive": "Nuevo Drive" }`}
                            <i className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }} onClick={() => setState({...state, showInfoModal:true})} className="fa fa-question-circle"></i>
                        </div>
                    </div>
                </div>
                <div className="card-content">
                    <div>
                        <div className="steps" id="stepsRecipes">
                            <div className={`step-item ${state.stepInfoDrive ? "is-active" : ""} ${state.stepInfoDriveComplete ? "is-success" : ""}`} id="stepInfoDrive">
                                <div className="step-marker">1</div>
                                <div className="step-details">
                                    <p className="step-title">Datos generales</p>
                                    <p>Información descriptiva.</p>
                                </div>
                            </div>
                            <div className={`step-item ${state.stepFacturacionDrive ? "is-active" : ""} ${state.stepFacturacionDriveComplete ? "is-success" : ""}`} id="stepFacturacionDrive">
                                <div className="step-marker">2</div>
                                <div className="step-details">
                                    <p className="step-title">Facturación</p>
                                    <p>Información descriptiva.</p>
                                </div>
                            </div>
                            <div className={`step-item ${state.stepLocationDrive ? "is-active" : ""} ${state.stepLocationDriveComplete ? "is-success" : ""}`} id="stepLocationDrive">
                                <div className="step-marker">3</div>
                                <div className="step-details">
                                    <p className="step-title">Ubicación y Dirección</p>
                                    <p>Información descriptiva.</p>
                                </div>
                            </div>

                            <div className="steps-content" style={{ width: 100 }} id="stepDriveContent">
                                <div className={`step-content has-text-centered ${state.stepInfoDrive ? "is-active" : ""}`} id="stepInfoDrive">
                                    {!state.isLoading &&
                                        <StepInfoDrive
                                            isEditing={state.isEditing}
                                            caffenio={state.caffenio}
                                            menus={props.location.state.menusList}
                                            priceList={props.location.state.pricesList}
                                            save={(caffenioUpdated) => {
                                                
                                                setState({
                                                    ...state,
                                                    stepInfoDriveComplete: true,
                                                    stepInfoDrive: false,
                                                    stepFacturacionDrive: true,
                                                    caffenio: caffenioUpdated
                                                });
                                            }}
                                            
                                        />
                                  } 
                                </div>
                                <div className={`step-content has-text-centered ${state.stepFacturacionDrive ? "is-active" : ""}`} id="stepFacturacionDrive">
                                    {!state.isLoading && 
                                    <StepFacturacionDrive
                                        isEditing={state.isEditing}
                                        caffenio={state.caffenio}
                                        DatosFact={state.arrDatosFact}
                                        TasaImp={state.arrTasaImp}
                                        Segmento={state.arrSegmento}
                                        save={(caffenioUpdated) => {
                                            setState({
                                                ...state,
                                                stepFacturacionDriveComplete: true,
                                                stepFacturacionDrive: false,
                                                stepLocationDrive: true,
                                                caffenio: caffenioUpdated,
                                            });
                                        }}
                                        back = {(caffenioUpdated) => {                                            
                                            setState({
                                                ...state,                                                
                                                stepFacturacionDrive: false,
                                                stepInfoDrive: true,
                                                caffenio: caffenioUpdated
                                            });                                           
                                        }}
                                    />
                                     }  
                                </div>
                                <div className={`step-content has-text-centered ${state.stepLocationDrive ? "is-active" : ""}`} id="stepLocationDrive">
                                    {!state.isLoading && 
                                    <StepLocationDrive
                                        isEditing={state.isEditing}
                                        caffenio={state.caffenio}
                                        arrPlazas ={state.arrPlazas}
                                        arrSucursales={state.arrSucursales}
                                        save={(caffenioUpdated) => {                                           
                                            
                                            if(state.isEditing){
                                                handleUpdate(caffenioUpdated)
                                            }else{
                                                handleSave(caffenioUpdated, ()=> setState({
                                                    ...state,
                                                    caffenio: caffenioUpdated,
                                                    stepLocationDriveComplete: true
                                                }));
                                            }
                                            
                                            
                                        }}
                                        back = {(caffenioUpdated) => {
                                            setState({
                                            ...state,                                            
                                            stepFacturacionDrive: true,
                                            stepLocationDrive: false,
                                            caffenio: caffenioUpdated
                                        })
                                        }} />
                                          } 
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {state.showInfoModal &&
                    <Modal
                        showModal={state.showInfoModal}
                        modalTitle={"Información de Artículo Inventario"}
                        cancel={() => setState({...state, showInfoModal:false})}
                    >
                        <div className="has-text-justified">

                            ¿Que dirá aquí?
                            <hr />
                            
                        </div>


                    </Modal>
                }
            {state.showInfoConfirm &&
                    <AcceptModal
                        showModal={state.showInfoConfirm}
                        modalTitle="Confirmar"
                        modalBody={<p>¿Está seguro que desea guardar la información?</p>}
                        isActive={state.showInfoConfirm}
                        willSave
                        done={() => {
                            setState({
                                ...state,
                                isLoading: true
                            });
                            let caffenioUpdated = {...state.caffenio};
                            caffenioUpdated.OpenDate = moment(new Date(caffenioUpdated.OpenDate)).format("YYYY-MM-DDT00:00:00.000")+"Z"; 
                
                            if (caffenioUpdated.isClosed && caffenioUpdated.CloseDate){
                                caffenioUpdated.CloseDate= moment(new Date(caffenioUpdated.CloseDate)).format("YYYY-MM-DDT00:00:00.000")+"Z";
                            }else{                    
                                caffenioUpdated.CloseDate= '';
                            }
                            CaffenioDriveAPI.updateCaffenio(caffenioUpdated.CaffenioId, caffenioUpdated)
                            .then(res => {
                                toast.success('Drive actualizado');
                                window.history.back();
                            })
                            .catch(err => {
                                let message = 'Ocurrio un error';
                                try {
                                    message = err.response.data.Message;
                                } catch (error) {
                                    message = 'Ocurrió un error al actualizar PDV.';
                                } finally {
                                    toast.error(message);
                                    setState({ ...state, isLoading: false });
                                }
                            })
                            .finally(() => {                
                                setState({
                                    ...state,
                                    isLoading: false
                                });
                            })
                                
                            
                            
                        }}
                        cancel={() => setState({...state, showInfoConfirm: false, isLoading:false})}
                    />                       
                }
        </div>
    );
}

// Definición de los tipos y su dependencia de las distintas props que recibe el componente.
NewCaffenio.propTypes = {
    caffenio: PropTypes.object,
    isEditing: PropTypes.bool
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
NewCaffenio.defaultProps = {
    isEditing: false,
    caffenio: {
        ShortName: "",
        Name: "",
        SatCode: ""
    }
}

export default NewCaffenio;