import { values } from 'lodash';
import React, { Component } from 'react';
import Select from 'react-select';
import Field from '../../util/field/Field';
import ReactTooltip from 'react-tooltip'


/**
 * Componente para pintar el detalle de una promoción de tipo reembolso por producto 
 * @param {*} props 
 * @returns componente
 */
export const PromoDetailReembolsoProducto = (props) => {
    console.log("ReembolsoProducto props >>> ", props)

    return (
        <div className='row'>
            <div className='columns'>
                <div className='column is-6'>
                    <label className='label'>
                        <div>Tipo de Cantidad a reembolsar
                            <i data-tip data-for="isPercentageTip" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                        </div>
                    </label>
                    <div className='control'>
                        <Select
                            id="isPercentageSelected"
                            name="isPercentageSelected"
                            value={props.values.isPercentageSelected}
                            placeholder="Seleccionar..."
                            noOptionsMessage={() => "No hay opciones"}
                            options={props.optionsTipoCantidad}
                            onChange={(val) => {
                                props.setFieldValue('isPercentage', val.value)
                                props.setFieldValue('isPercentageSelected', val)
                            }}
                            onBlur={() => props.setFieldTouched('isPercentageSelected', true)}
                        />
                    </div>
                    <div className="is-centered">
                        {(props.errors.isPercentageSelected) &&
                            <div style={{ 'fontSize': '12px', 'color': '#cc0b19' }}>
                                Campo tipo de cantidad a reembolsar es requerido
                            </div>
                        }
                    </div>
                    <ReactTooltip id="isPercentageTip" place="top" effect="solid">
                          ¿Es porcentaje?
                        </ReactTooltip>
                </div>

                <div className='column is-6'>
                    <Field label= {<div>{`${props.values.isPercentageSelected ?  props.values.isPercentageSelected.label : ''  } a reembolsar`}
                                                <i data-tip data-for="qytReembolso" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                                            </div>
                        }
                        alingLefth={true}
                        hasError={props.errors.quantity || props.touched.quantity}
                        msgError={props.errors.quantity ? props.errors.quantity : ""}>
                        <input
                            className="input"
                            type="number"
                            label="quantity"
                            name="quantity"
                            maxLength="10"
                            min="1"
                            value={props.values.quantity}
                            onChange={(e) => props.handleChange(e)}
                            onKeyPress={props.onKeyPressNumber}
                            disabled={!props.values.isPercentageSelected}

                        />
                    </Field>
                    <ReactTooltip id="qytReembolso" place="top" effect="solid">
                           {`${props.values.isPercentage ?"Porcentaje" : "Cantidad"}  de monedero reembolsado`}
                        </ReactTooltip>
                </div>
            </div>
        </div>
    )
}