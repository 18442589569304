import React, { Component } from 'react';
//import TextField from '../util/textfield/TextField';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import PriceListForm from './PriceListForm';
import PriceDriveAPI from '../../services/PriceDrive';
import '../cssComponents.css';
import ErrorMessages from '../../util/validation-error-messages';
import Loading from '../util/loading/Loading';

class NewPriceList extends Component {
    constructor(props){
        super(props);
        this.state = {
            pricesList:[],
            priceList: {...this.props.priceList},
            isLoading: false
        };
    }
    componentDidMount(){
        if (!this.props.location.state) {
            this.props.history.push(`/lista-precios`);
            return;
        }
        this.setState({ pricesList: this.props.location.state.pricesList })
    }

    save = (priceList) => {
        priceList.Description = priceList.Description.trim();
        if(priceList.Description.length < 3){ 
            toast.error("Descripción debe de tener más de 2 caracteres");
            return;
        }
        
        this.setState({isLoading: true}, () => {
            PriceDriveAPI.post(priceList).then(response => {
                this.setState({isLoading: false})
                toast.success("Lista de Precios creada");
                this.props.history.goBack();
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        })
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{cursor: "pointer", color: "#91BA43" }} onClick={() => {this.props.history.goBack()}} className="fa fa-arrow-left"></i>
                            </span>
                            Nueva Lista de Precios
                        </div>
                    </div>
                    <div className="card-content">
                        <PriceListForm save={this.save} isEditing={this.props.isEditing} pricesList={this.state.pricesList}/>
                    </div>
                </div>

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
            </div>
        );
    }
}

// Definición de los tipos y su dependencia de las distintas props que recibe el componente.
NewPriceList.propTypes = {
    priceList: PropTypes.object,
    isEditing: PropTypes.bool
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
NewPriceList.defaultProps = {
    isEditing: false,
    priceList: {
        Description: "",
        Active: false
    }
}

export default NewPriceList;
