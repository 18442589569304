import React, { Component } from 'react';
import UberEatsAPI from '../../services/ubereats';
import { toast } from 'react-toastify';
import Loading from '../util/loading/Loading';
import TextField from '../util/textfield/TextField';
import Field from '../util/field/Field';
import Dropdown from '../util/dropdown/Dropdown';

import ErrorMessages from '../../util/validation-error-messages';

class NewRestaurant extends Component {

    constructor(props){
        super(props);
        this.state = {
            drivesAvalaibles: [],
            isLoading: false,
            driveSelected: null,
            restaurant: {
                DriveId: 0,
                StoreId: ""
            }
        }
    }

    componentDidMount(){
        this.setState({isLoading: true});
        UberEatsAPI.getDrivesAvailables().then(response => {
            this.setState({drivesAvalaibles: response.data, isLoading: false});
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        });
    }

    driveChangeOption = (index) => {
        let restaurant = {...this.state.restaurant};
        restaurant.DriveId = index === 0 ? 0 : this.state.drivesAvalaibles[index - 1].idCaffenio;
        this.setState({restaurant: restaurant});
    }

    handleChange = (e) => {
        let state = {...this.state};
        state.restaurant[e.target.name] = e.target.value;
        this.setState(state);
    }

    render() {
        return (
            <div>
                
                <Field label="Caffenio" isHorizontal>
                    <Dropdown 
                        single 
                        items={this.state.drivesAvalaibles} 
                        value={this.state.driveSelected}
                        id="idCaffenio"
                        onSelect={this.driveChangeOption}
                        labelKey={"Nombre"}
                    />
                </Field>

                <label for="ShortName" id="labelNombreCorto"></label>
                <TextField label="ID del restaurante" required maxLength="36" name="StoreId" value={this.state.restaurant.StoreId} onChange={(e) => this.handleChange(e)}/>

                <div className="has-text-right buttons-modal">
                    <div className="column">
                        <button
                            className="button is-success"
                            onClick={() => this.props.save(this.state.restaurant)}>
                            Guardar
                        </button>
                    </div>
                </div>
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
            </div>
        );
    }

}

NewRestaurant.defaultProps = {
    restaurant: {
        DriveId: 0,
        StoreId: ""
    },
    driveSelected: null
}

export default NewRestaurant;