import React, { useEffect, useState } from 'react';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import MenuAppAPI from '../../../../../services/Caffenio App/Menu';
import Loading from '../../../../util/loading/Loading';
import { toast } from 'react-toastify';
import ClassificationCard from './components/ClassificationCard';

const SortableItem = SortableElement(({value}) =>{
    return <li>{value.Name}</li>
});

const SortableList = SortableContainer(({items}) => {
    return (
        <ul>
        {items.map((value, index) => (
            <SortableItem key={`item-${value.ClassificationId}`} index={index} value={value} />
        ))}
        </ul>
    );
});

const gridStyles = {
    display: 'grid',
    gridTemplateColumns: '25% 25% 25%',//'repeat(3, 1fr)',
    gridGap: '16px',
    height: '50px'
};

const gridItemStyles = {
    height: '3px',
    // backgroundColor: '#e5e5e5',
};

const GridItem = SortableElement(({ value }) =>
    // <div style={gridItemStyles}>
        <ClassificationCard style={gridItemStyles} classification={value}/>
    // </div>
);

const Grid = SortableContainer(({ items }) =>
    <div style={gridStyles}>
        {items.map((value, index) =>
        <GridItem
            style={{height: '50px'}}
            key={`item-${index}`}
            index={index}
            value={value}
        />
        )}
    </div>
,  {disableAutoscroll: true});

export default function OrdenarMenu({history}){
    const [classifications, setClassifications] = useState([])
    const [loading, setLoading] = useState(false)
    const [working, setWorking] = useState(false)

    useEffect(() => {
        setLoading(true);
        MenuAppAPI.getClasificacionesMenu().then(res => {
            setClassifications(res.data);
            setLoading(false);
        }).catch(err => {
            toast.error(err.response.data.Message);
            setLoading(false);
        })
    }, [])

    const onSortEnd = ({oldIndex, newIndex}) => {
        setClassifications(arrayMove(classifications, oldIndex, newIndex));
    };

    const saveOrder = () => {
        setWorking(true);
        MenuAppAPI.saveMenuOrder(classifications).then(res => {
            toast.success(res.data.Message);
            history.push('/app/menu/orden');
        }).catch(err => toast.error(err.response.data.Message));
    }

    return (
        <div>
            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-header-title is-size-4 center-text">
                                <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                    <i className="fa-5x" style={{cursor: "pointer", color: "#000000" }} onClick={() => {
                                        history.push({
                                            pathname: '/app/menu/orden',
                                        });
                                        }} className="fa fa-arrow-left"></i>
                                    </span>
                                    {`Editar orden de menú`}
                                <span style={{position:"absolute", right: 25}} className="is-primary tooltip" data-tooltip="Guardar orden de menú">
                                    <button className={`button is-success`} disabled={working} onClick={() => saveOrder()}>Guardar cambios</button>
                                </span>
                            </div>    
                        </div>
                    </div>
                </div>

                {/* <button className={`button is-success`} onClick={() => saveOrder()}>Guardar cambios</button> */}
                <div className="column is-2"/>
                <div className="column is-9">
                    {classifications.length > 0 ? <Grid disableAutoscroll={true} axis="xy" items={classifications} onSortEnd={onSortEnd} /> : null}
                </div>
            </div>
            <Loading isLoading={loading} isFullScreen={true}/>
        </div>
    )
}