import React, { useEffect, useState } from 'react';

// Components
import Pagination from '../util/pagination/Pagination';
import NoItems from '../util/noitems/NoItems';
import Loading from '../util/loading/Loading';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ModalEditUsuario } from './ModalEditUsuario';

import SearchFormV5 from '../util/searchform/SearchFormV5';

// Servicios
import UsersAPI from '../../services/Users';


/**
 * Modulo de administración de usuarios con acceso desde Auth a la plataforma SAG.
 * @returns Component
 */
export const AdminUsuarios = ({history}) => {

    const [ state, setState ] = useState({
        filters: {},
        users: [],
        userSelected: {},
        plazas: [],
        roles: [],
        pages: [],
        detailVisible: false,
        isLoading: false
    });
    const [nameSearch, setNameSearch] = useState("");
    const [activate,setActivate] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        // Carga de datos iniciales
        let listUsers = [];
        setState({
            ...state,
            isLoading: true
        });

        UsersAPI.getUsers()
            .then(res => listUsers = res.data)
            .catch(err =>{
                let message = 'Ocurrio un error';
                try {
                    message = err.response.data.Message;
                } catch (error) {
                    message = 'Ocurrio un error al obtener a los empleados';
                } finally {
                    toast.error(message);
                    setState({ ...state, isLoading: false });
                }
            })
            .finally( () => {
                setState({
                    ...state,
                    isLoading: false,
                    users: listUsers
                });
            });
    },[]);

    /**
     * Muestra modal de detalle de usuario.
     * @param {Object} userSelected - Info del usuario seleccionado
     */
    const selectUser = userSelected => {
        setState({
            ...state,
            detailVisible: true,
            userSelected
        });
    }

    /**
     * Manejo de cambio en el input.
     * @param {Event} e - Evento de cambio en el input.
     */
    const handleChangeName = e => {
        setState({
            ...state,
            filters: {
                ...state.filters,
                name: e.target.value
            }
        });
    }

    /**
     * Filtra los usuarios segun los parametros mandados.
     */
    const handleFilterUsers = (name) => {
        setState({...state, isLoading: true});
        let listUsers = [];
        let filters = {};
        if ( name )
            filters.name = name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
        else if ( name === "" )
            delete filters.name

        UsersAPI.getUsers({ ...filters })
            .then(res => listUsers = res.data)
            .catch(err =>{
                let message = 'Ocurrio un error';
                try {
                    message = err.response.data.Message;
                } catch (error) {
                    message = 'Ocurrio un error al obtener a los empleados';
                } finally {
                    toast.error(message);
                    setState({ ...state, isLoading: false });
                }
            })
            .finally( () => {
                setState({
                    ...state,
                    isLoading: false,
                    users: listUsers
                });
            });
    }

    /**
     * Limpiado de los filtros.
     */
    const handleCleanFilters = () => {
        if (Object.toString(state.filters) === '{}')
            return;
        setState({...state, isLoading: true});
        let listUsers = [];

        UsersAPI.getUsers()
            .then(res => listUsers = res.data)
            .catch(err =>{
                let message = 'Ocurrio un error';
                try {
                    message = err.response.data.Message;
                } catch (error) {
                    message = 'Ocurrio un error al obtener a los empleados';
                } finally {
                    toast.error(message);
                    setState({ ...state, isLoading: false });
                }
            })
            .finally( () => {
                setState({
                    ...state,
                    isLoading: false,
                    users: listUsers,
                    filters: {
                        name: ''
                    }
                });
            });
    }

    /**
     * Actualiza la informacion de los usuarios con los datos nuevos
     * al asignar plazas.
     * @param {Object} infoUpdated - Info del usuario actualizado.
     */
    const updateUsersList = infoUpdated => {
        // Carga de datos iniciales
        let listUsers = [];
        setState({
            ...state,
            isLoading: true
        });

        UsersAPI.getUsers()
            .then(res => listUsers = res.data)
        .catch(err =>{
            let message = 'Ocurrio un error';
            try {
                message = err.response.data.Message;
            } catch (error) {
                message = 'Ocurrio un error al obtener a los empleados';
            } finally {
                toast.error(message);
                setState({ ...state, isLoading: false });
            }
        })
        .finally( () => {
            setState({
                ...state,
                isLoading: false,
                users: listUsers,
                detailVisible: false
            });
            toast.success("Información actualizada");
        });
    }

    return(
        <div>
            <h1 className='title is-2'>Administración de Usuarios</h1>
            { state.isLoading &&
                <Loading
                    isFullscreen={true}
                    isLoading={ state.isLoading }
                    width='100px'
                    height='100px'
                />
            }
            <br/>
            {/* Filtros */}            
            <SearchFormV5
                search={(text) => handleFilterUsers(text)}
                name={nameSearch}
                tooltip={"Crear una nueva promoción"}
                newBtnTitle={"Nueva promoción"}
                activate={activate}
                activateSearch={(value)  => setActivate(value)}
            />
            <br />
            {/* Tabla de Usuarios */}
            <div className='card'>
                <div className='card-content'>
                {
                    state.users.length !== 0 ?
                    <table className='table is-fullwidth'>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Correo</th>
                                <th>Rol</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                state.pages.map(user =>
                                    <tr key={ user.idUser }>
                                        <td>{`${user.Name} ${user.LastName}` }</td>
                                        <td>{ user.Email }</td>
                                        <td>{ user.Roles }</td>
                                        <td>
                                        <button className="button is-small tableAction is-warning tooltip is-tooltip-left" data-tooltip="Editar usuario" onClick={() => selectUser( user )}>
                                            <span className="icon is-small">
                                                <i className="fa fa-edit" />
                                            </span>
                                        </button>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    :  <NoItems itemName="usuarios" />
                }
                <Pagination
                    items={ state.users }
                    pageSize={ 10 }
                    onChangePage={users => setState({ ...state, pages: users })}
                />
                </div>
            </div>
            {state.detailVisible &&
                <ModalEditUsuario
                    userInfo={ state.userSelected }
                    listPlazas={ state.listPlazas }
                    onSave={ updatedInfo => updateUsersList( updatedInfo ) }
                    onClose={() => setState({...state, detailVisible: false})}
                />
            }
        </div>
    )
}