import React, { useState, useEffect } from 'react';
import ResumenNotificacion from './components/ResumenNotificacion';
import AgendarNotificacion from './components/AgendarNotificacion';
import TablaEventosAgenda from './components/components/TablaEventosAgenda';
import AgendarHeader from './components/components/AgendarHeader';
import Modal from '../../../util/modal/Modal';
import useForm from '../../../../util/hooks/useForm';
import PruebaNotificacion from './components/components/PruebaNotificacion';
import TablaHistorial from './components/components/TablaHistorial';
import moment from 'moment';
import NotificacionAPI from '../../../../services/Caffenio App/Notificacion';
import { toast } from 'react-toastify';
import to from '../../../../util/to';
require('moment/locale/es');

export default function DetalleNotificacion(props){
    const [notification, setNotification] = useState(null);
    const [working, setWorking] = useState(false);

    useEffect(() => {
        (async () => {
            let err, notification;
            [err, notification] = await to(NotificacionAPI.getNotification(props.match.params.id))
            if(err) toast.error(err.response.data.Message);
            setNotification(notification);
        })();
    }, [])

    const {inputs, setInput, cleanInputs, errors, replaceInputs, handleInputChange, handleSelectChange, handleSubmit} = useForm(() => {
        if(!working){
            setWorking(true);
            let body = {
                NotificationId: notification.NotificationId,
                NotificationScheduleTypeId: inputs.type.value,
            };
            // Única
            if(inputs.type.value === 1){
                body = {
                    ...body,
                    DateHour: moment(inputs.date).format('YYYY-MM-DD HH:mm:ss')
                }
                NotificacionAPI.ScheduleUniqueEvent(body).then(response => {
                    toast.success(response.data.Message);
                    setAgendando(false);
                    setWorking(false);
                }).catch(err => {
                    toast.error(err.response.data.Message)
                    setAgendando(false);
                    setWorking(false);
                });    
            }else{ // Frecuente
                body = {
                    ...body, 
                    NotificationScheduleFrequencyTypeId: inputs.frecuency.value,
                    DateStart: moment(inputs.startDate).format('YYYY-MM-DD'),
                    DateEnd: moment(inputs.endDate).format('YYYY-MM-DD'),
                }
                switch(inputs.frecuency.value){
                    case 1: // Diaria
                        body = {
                            ...body,
                            Hour: moment(inputs.hour).format('HH:mm ')
                        }
                        NotificacionAPI.ScheduleDailyEvent(body).then(response => {
                            toast.success(response.data.Message);
                            setAgendando(false);
                            setWorking(false);
            
                        }).catch(err => {
                            toast.error(err.response.data.Message)
                            setAgendando(false);
                            setWorking(false);
                        });    
                        break;
                    case 2: // Semanal
                        const hoursDays = inputs.hours.filter(h => h);
                        body = {
                            ...body,
                            Days: inputs.days.map((day, index) => ({DayId: day.IdFilterPurchaseDay, Hour: moment(hoursDays[index]).format('HH:mm')}))
                        }
                        NotificacionAPI.ScheduleWeeklyEvent(body).then(response => {
                            toast.success(response.data.Message);
                            setAgendando(false);
                            setWorking(false);
            
                        }).catch(err => {
                            toast.error(err.response.data.Message)
                            setAgendando(false);
                            setWorking(false);
                        });    
                        break;
                    case 3: // Mensual
                        body = {
                            ...body,
                            NotificationScheduleMonthlyTypeId: inputs.dayOfMonth.value,
                            Hour: moment(inputs.hour).format('HH:mm')
                        }
                        NotificacionAPI.ScheduleMonthlyEvent(body).then(response => {
                            toast.success(response.data.Message);
                            setAgendando(false);
                            setWorking(false);
            
                        }).catch(err => {
                            toast.error(err.response.data.Message)
                            setAgendando(false);
                            setWorking(false);
                        });    
                        break;
                    default: break;
                }
            }
            setNotification(notification);
        }
        
    });

    const [agendando, setAgendando] = useState(false);
    const [probando, setProbando] = useState(false);

    const dataIsValid = () => {
        return inputs.type && ((inputs.date && inputs.date2) || inputs.frecuency && inputs.startDate && inputs.endDate && ((inputs.dayOfMonth && inputs.hour) || (inputs.hour && inputs.frecuency.value !== 3) || (inputs.days && inputs.days.length > 0 && inputs.days.length == inputs.hours.filter(d => d).length)))
    }

    if (!notification) return null;

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="card-header-title is-size-4 center-text">
                        <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                            <i className="fa-5x" style={{cursor: "pointer", color: "#000000" }} onClick={() => {
                                props.history.push({
                                    pathname: '/app/notificaciones',
                                    state: props.location.state ? {currentPage: props.location.state.currentPage || 0, name: props.location.state.name || ""} : {currentPage: 1, name: ""}
                                });
                                }} className="fa fa-arrow-left"></i>
                        </span>
                        {notification.Name}
                    </div>
                </div>
                <div className="card-content">
                    <ResumenNotificacion notification={notification}/>
                </div>
            </div>
            <br/>
            <AgendarHeader notification={notification} setAgendando={setAgendando} setProbando={setProbando}/>

            <TablaEventosAgenda notification={notification} agendando={agendando}/>
            <br/>
            <h1 className="title is-4">Historial</h1>
            <TablaHistorial notification={notification} probando={probando}/>

            {agendando && 
            <Modal
                footer
                footerButtons={                        
                    <div className="has-text-right modal-detalle-buttons">
                        <button className="button" onClick={() => setAgendando(false)}>Cancelar</button>
                        <button className="button is-success" disabled={!dataIsValid()} onClick={() => handleSubmit()}>Guardar</button>
                    </div>
                }
                cancel={() => setAgendando(false)}
                showModal={agendando}
                modalTitle={`Agendar notificación`}
            >
                <AgendarNotificacion replaceInputs={replaceInputs} inputs={inputs} cleanInputs={cleanInputs} setInput={setInput} errors={errors} handleSelectChange={handleSelectChange} handleInputChange={handleInputChange}/>
            </Modal>}

            {probando && 
            <PruebaNotificacion probando={probando} setProbando={setProbando} notification={notification}/>
            }
        </div>
    )
}