import React, { Component } from 'react';

export const PromoProductsTable = (props) => {
    return (
        <>
            {props.selectedProducts.map((item, index) => {
                return (
                    <div key={`tblPromoProducts_${index}`} className="column is-6">
                        <div className="card" style={{ minHeight:'65px' }}>
                            {props.canDelete && 
                            <span className="tooltip icon is-small pull-right" data-tooltip="Eliminar" style={{ marginRight: '10px', marginTop: '5px' }} >
                                <i className="fa-5x" style={{ cursor: "pointer", color: 'red' }} onClick={() => props.handleDeleteProducto(index)} className="fa fa-trash"></i>
                            </span>
                            }
                            

                            {props.isBaseApply && <div className="tooltip is-primary pull-right" data-tooltip="¿Es producto base?" style={{ marginRight: '10px' }} >
                                <input type="checkbox" name="isBase" checked={item.isBase} onChange={() => props.setIsBase(index)}></input>
                            </div>}

                            {props.showImage && 
                            <figure className="image container-image" style={{ float: 'left', marginLeft: 5, marginRight: 5, marginBottom: 5, maxWidth: '50px', maxHeight: '50px' }}>
                                {item.UrlImage ? <img className="card-img-left " src={item.UrlImage} style={{ width: 'auto', height: 'auto' , maxHeight:'65px' }} /> : "n/a"}
                            </figure>}
                            <div className="card-body has-text-left">
                                <h6 style={{ fontWeight: 600 }}>{item.producto}</h6>
                                <p style={{ fontSize: 14 }} className="card-text">
                                    {`${item.idTipoReceta && item.tipoReceta.toLowerCase() !== 'sin tipo' ? item.tipoReceta  : ''}
                                    ${item.idTipoSabor && item.Sabor.toLowerCase() !== 'sin tipo' ? '-'+ item.Sabor  : ''}
                                    ${item.idTipoGrano && item.Grano.toLowerCase() !== 'sin tipo' ? '-'+item.Grano  : ''}
                                    ${item.idTipoLeche && item.Leche.toLowerCase() !== 'sin tipo' ? '-'+item.Leche  : ''}
                                    ${item.idPresentacion && item.Presentacion.toLowerCase() !== 'sin tipo' && item.Presentacion.toLowerCase() !== 'sin presentación' ? '-'+item.Presentacion : ''}`}
                                </p>
                            </div>
                            {props.getCadRecipesNumber != null ? <div className="is-primary pull-right"  style={{ marginRight: '10px', bottom:0 }} >
                                <p style={{ fontSize: 14 }} className="card-text">
                                    {`${props.getCadRecipesNumber(item)} recetas`}
                                </p>
                            </div>:''}
                        </div>
                    </div>
                )
            })}
        </>
        )
}