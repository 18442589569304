import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';
import { stringify } from "querystring";

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
});

/**
 * Servicio para el recurso de plazas
 */
export default class PlazasAPI {
    /**
     * Obtiene a las plazas.
     * @returns Promise
     */
    static getPlazas(filters = {}) {
        return middleware.hasAuth(() => request.get(`/Plazas?${stringify(filters)}`));
    }

    /**
     * Actualización de una plaza.
     * @param {Object} plaza - Datos de la plaza
     * @returns Promise
     */
     static updatePlaza( plaza ) {
        return middleware.hasAuth(() => request.put('/Plazas/', plaza));
    }

    /**
     * Creación de una plazas.
     * @param {Object} plazas - Datos de la plazas
     * @returns Promise
     */
    static createPlaza( plaza ) {
        return middleware.hasAuth(() => request.post('/Plazas/', plaza));
    }

    /**
     * Obtiene las plazas del usuario dado.
     * @param { Number } idUser - Id del usuario.
     * @returns Promise
     */
    static getUserPlazas( idUser, asign = false ) {
        return middleware.hasAuth(() => request.get(`/Plazas/User/${ idUser }/${ asign }`));
    }

    /**
     * Actualiza la lista de plazas de un usuario.
     * @param { Object } data - Datos a guardar.
     * @returns Promise
     */
    static updateUserPlazas( data ) {
        return middleware.hasAuth(() => request.post(`/Plazas/User/`, data));
    }

    /**
     * Elimina una de las plazas de un usuario.
     * @param { Object } data - Datos a eliminar.
     * @returns 
     */
    static deleteUserPlaza( data ) {
        return middleware.hasAuth(() => request.post('/Plazas/User/Desasign/', data));
    }
}