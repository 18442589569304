import React, { Component } from 'react';
import  SearchForm  from "../util/searchform/SearchForm";
import  TableTemplate  from "../util/tabletemplate/TableTemplate";
import  NoItems  from "../util/noitems/NoItems";
import UberEatsAPI from '../../services/ubereats';
import { toast } from 'react-toastify';
import Modal from '../util/modal/Modal';
import NewRestaurant from './NewRestaurant';
import AcceptModal from '../util/acceptModal/AcceptModal';
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading';

import ErrorMessages from '../../util/validation-error-messages';
import * as moment from 'moment';

class Restaurantes extends Component {

    restaurants = [];

    constructor(props){
        super(props);
        this.state = {
            restaurants: [],
            pageRestaurants: [],
            filterText: "",
            restaurant: null,
            showModal: false,
            removeModal: false,
            isLoading: false
        }
    }

    componentWillMount(){
        this.getRestaurantes();
    }

    getRestaurantes = () => {
        this.setState({isLoading: true, filterText: ''}, () => {
            UberEatsAPI.getRestaurantes().then(response => {
                this.setState({restaurants: response.data, isLoading: false});
                this.restaurants = response.data;
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message);
                else
                    toast.error(ErrorMessages.SinConexion);
            })
        });
    }

    handleSearchInput = (text) => {
        let restaurants = this.restaurants.filter(restaurant => (restaurant.Nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + " " + restaurant.Restaurante.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")).includes(text.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        let state = this.state;
        state.filterText = text;
        state.restaurants = restaurants;
        this.setState(state);
    }

    remove = (restaurant) => {
        //console.log(unit)
        let state = {...this.state};
        const loadingToast = toast.info("Cargando...", {autoClose: false, closeButton: false, className: "has-background-primary has-text-weight-bold"});
        UberEatsAPI.deleteRestaurante(restaurant.CaffenioRelRestauranteId).then(response => {
            //console.log(response);
            state.restaurants.splice(state.restaurants.findIndex(u => u.CaffenioRelRestauranteId === restaurant.CaffenioRelRestauranteId),1);
            state.pageRestaurants.splice(state.pageRestaurants.findIndex(u => u.CaffenioRelRestauranteId === restaurant.CaffenioRelRestauranteId),1);
            state.removeModal = false;
            this.setState(state, () => {
                toast.dismiss(loadingToast);
                toast.success("Restaurante eliminado");
            })
        }).catch(err => {
            toast.dismiss(loadingToast);
            state.removeModal = false;
            this.setState(state);
            toast.error(err.response.data.Message);
        })
    }

    create = () => {
        this.setState({showModal: true, restaurant: null});
    }

    download = (restaurant) => {

        this.setState({isLoading: true}, () => {
            UberEatsAPI.downloadMenuReport(restaurant.CaffenioRelRestauranteId).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let nombre = restaurant.Restaurante; //.toLowerCase().replace(' ', '_');
                let name = `REPORTE MENU ${moment().format('DD MM YYYY h mm ss')} ${nombre}`;
                name = name.replace(/ /g,"_");
                link.setAttribute('download', `${name.toUpperCase()}.xlsx`);
                document.body.appendChild(link);
                link.click();
                this.setState({isLoading: false});
            }).catch(err => {
                this.setState({isLoading: false});
                toast.error(err.response.data.Message)
            })
        })

    }

    changeStatusOrderRelease = (restaurant) => {
        let data = {
            CaffenioRelRestauranteId: restaurant.CaffenioRelRestauranteId,
            EnableOrderRelease: !restaurant.EnableOrderRelease
        }

        this.setState({isLoading: true}, () => {
            UberEatsAPI.changeStatusOrderRelease(data).then(response => {

                let state = { ...this.state };

                let index = state.restaurants.findIndex(r => r.CaffenioRelRestauranteId === restaurant.CaffenioRelRestauranteId);
                let Restaurant = state.restaurants[index];
                Restaurant.EnableOrderRelease = data.EnableOrderRelease
                state.restaurants[index] = Restaurant;

                let indexP = state.pageRestaurants.findIndex(r => r.CaffenioRelRestauranteId === restaurant.CaffenioRelRestauranteId);
                let RestaurantP = state.pageRestaurants[indexP];
                RestaurantP.EnableOrderRelease = data.EnableOrderRelease
                state.pageRestaurants[indexP] = RestaurantP;

                state.isLoading = false;

                this.setState(state);
                toast.success(response.data.Message)
            }).catch(err => {
                this.setState({isLoading: false});
                toast.error(err.response.data.Message)
            })
        })
    }

    changeStatusCancelNotification = (restaurant) => {
        let data = {
            CaffenioRelRestauranteId: restaurant.CaffenioRelRestauranteId,
            EnableCancelNotification: !restaurant.EnableCancelNotification
        }

        this.setState({isLoading: true}, () => {
            UberEatsAPI.changeStatusCancelNotification(data).then(response => {

                let state = { ...this.state };

                let index = state.restaurants.findIndex(r => r.CaffenioRelRestauranteId === restaurant.CaffenioRelRestauranteId);
                let Restaurant = state.restaurants[index];
                Restaurant.EnableCancelNotification = data.EnableCancelNotification
                state.restaurants[index] = Restaurant;

                let indexP = state.pageRestaurants.findIndex(r => r.CaffenioRelRestauranteId === restaurant.CaffenioRelRestauranteId);
                let RestaurantP = state.pageRestaurants[indexP];
                RestaurantP.EnableCancelNotification = data.EnableCancelNotification
                state.pageRestaurants[indexP] = RestaurantP;

                state.isLoading = false;

                this.setState(state);
                toast.success(response.data.Message)
            }).catch(err => {
                this.setState({isLoading: false});
                toast.error(err.response.data.Message)
            })
        })
    }

    save = (restaurant) => {

        console.log(restaurant);
        
        if(!restaurant || !restaurant.DriveId || restaurant.DriveId == 0)
        {
            toast.error('Selecciona un drive');
            return;
        }

        if(!restaurant || !restaurant.StoreId || restaurant.StoreId.length == 0)
        {
            toast.error('Indique el identificador del restaurante');
            return;
        }

        this.setState({showModal: false, isLoading: true}, () => {
            UberEatsAPI.createRestaurante(restaurant).then(response => {
                this.getRestaurantes();
                toast.success('Nuevo restaurante agregado exitosamente');
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message);
                else
                    toast.error(ErrorMessages.SinConexion);
            })
        });
    }

    toDetails = (restaurant) => {
        this.props.history.push(`/restaurantes/${restaurant.DriveId}`)
    }

    render() {
        return (
            <div>
                <h1 className="title is-2">Restaurantes - Uber Eats</h1>     
                <div className="columns is-vcentered">
                    <div className="column is-5">
                        <div className="card">
                            <div className="card-content">
                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'Restaurante'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-7">
                        <div className="field">
                            <div className="control has-text-right">
                                <a className="button is-success" tabIndex="0" onClick={(e) => this.create()}>
                                    <span className="icon is-small">
                                        <i className="fa fa-plus"></i>
                                    </span>
                                    <span>Agregar restaurante</span>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>         

                <div className="card">
                    <div className="card-content" >
                        {this.state.restaurants.length !== 0 
                        ? <TableTemplate
                            columns={["DriveId","Nombre","StoreId", "Restaurante", "EnableCancelNotification", "EnableOrderRelease"]}
                            columnsNames={["Drive", "CAFFENIO","Uber Eats", "Restaurante", "Notificaciones de cancelación", "Liberar orden repartidor"]}
                            data={this.state.pageRestaurants}
                            rowClickable={false}
                            details={(restaurant) => this.setState({showModal: true, restaurant: restaurant})}
                            canDelete 
                            canDownload
                            rowClickable
                            details={(restaurant) => this.toDetails(restaurant)}
                            disableCheck={false}
                            changeStatus={(restaurant) => this.changeStatusCancelNotification(restaurant)}
                            changeOrderRelease={(restaurant) => this.changeStatusOrderRelease(restaurant)}
                            download={(restaurant) => this.download(restaurant) }
                            remove={(restaurant) => this.setState({removeModal: true, restaurant: restaurant})}
                            extraButtons={(row) => {
                                // Creamos un botón que lanza un modal con información de que menus esta la receta seleccionada
                                return <button className="button tableAction is-info tooltip" data-tooltip="Ver detalle"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.toDetails(row);
                                }}
                                >
                                            <i className="fa fa-info"></i>
                                        </button>
                            }}
                        /> 
                        : <NoItems itemName="restaurantes"/>}
                        
                        <Pagination items={this.state.restaurants} onChangePage={(restaurants) => this.setState({ pageRestaurants: restaurants })} />
                        

                        {this.state.showModal && 
                            <Modal
                                showModal={this.state.showModal}
                                modalTitle={"Nuevo restaurante"}
                                cancel={() => this.setState({showModal: false, restaurant: null})}
                            >
                                <NewRestaurant 
                                restaurant={{ DriveId: 0, StoreId: "" }}
                                save={(restaurant => this.save(restaurant))} />
                            </Modal>
                        }

                        {this.state.removeModal && <AcceptModal
                            isActive={this.state.removeModal}
                            done={() => this.remove(this.state.restaurant)}
                            cancel={() => this.setState({removeModal: false, restaurant: null})}
                            modalTitle={"Eliminar Restaurante"}
                        />}

                        <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
                        
                    </div>
                </div>

            </div>
        );
    }

}

export default Restaurantes;