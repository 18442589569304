import { apiUrl }  from "../constants";
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class MilksAPI {
    static getAll() {
        return request.get('/MilkType');
    }

    static get(id) {
        return request.get(`/MilkType/${id}`);
    }

    static getByClasification(ids){
        return request.post(`/MilkType/GetByClassification`, ids);
    }

    static create(MilkType) {
        let fd = new FormData();
        
        fd.append("Description", MilkType.Description);
        fd.append("Position", MilkType.Position);
        fd.append("RecipeName", MilkType.RecipeName);
        fd.append("Activo", MilkType.Activo);
        fd.append("SegmentId", MilkType.SegmentationId.SegmentId)
        MilkType.Image && fd.append("Image", MilkType.Image);

        return request.post('/MilkType',fd);
    }

    static edit(MilkType){
        let fd = new FormData();
        
        fd.append("Description", MilkType.Description);
        fd.append("Position", MilkType.Position);
        fd.append("RecipeName", MilkType.RecipeName);
        fd.append("MilkTypeId", MilkType.MilkTypeId);
        fd.append("Activo", MilkType.Activo);
        MilkType.idSegmento && fd.append("SegmentId", MilkType.idSegmento)
        
        MilkType.Image instanceof File && fd.append("Image", MilkType.Image);

        return request.put(`/MilkType`,fd);
    }

    static delete(id){
        return request.delete(`MilkType/${id}`);
    }
}