import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Field from '../util/field/Field';
import ExpresionesRegulares from '../../util/expresiones-regulares';
import InventoryTypesAPI from '../../services/InventoryType';
import ErrorMessages from '../../util/validation-error-messages';
import { Formik } from 'formik';
import * as Yup from 'yup';

let INVENTORY_TYPES_LIST = [];



class InventoryTypeForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inventoryType: { ...this.props.inventoryType },
            inventoryTypes: [],
            isEditing: false,
            isLoading: false
        }
    }

    componentDidMount() {        
        this.setState({ isLoading: true }, () => {
            let state = { ...this.state };
            InventoryTypesAPI.getAll().then(response => {
                state.inventoryTypes = response.data;
                INVENTORY_TYPES_LIST = response.data;
                if(!this.props.inventoryType){
                    state.inventoryType = {Description:null}
                }
                this.setState({ isLoading: false });
            }).catch(err => {
                this.setState({ isLoading: false }, () => {
                    if (err.response){
                        console.log(err.response.data.Message)
                        toast.error(ErrorMessages.ErrorInesperado);
                    }else
                        toast.error(ErrorMessages.SinConexion)
                });
            })
        })
    }

    VALIDATION_SCHEMA = Yup.object({
        Description: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .required("Campo Nombre es requerido.")
            .test("validate-name", "El Nombre de tipo inventario ya existe, favor de ingresar otro.", (value) => {
                let isValid = true;
                let description = value ? value.toLowerCase().trim() : "";
                if (INVENTORY_TYPES_LIST.some(u => u.Description.toLowerCase().trim() === description && u.InventoryTypeId !== this.state.inventoryType.InventoryTypeId)) {
                    isValid = false;
                }
                return isValid;
            }),
    });

    render() {
        return (
            <Formik
                initialValues={this.state.inventoryType}
                validationSchema={this.VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    let formatValues = {...values};
                    formatValues.Description = formatValues.Description.trim();
                    this.props.save(formatValues)
                }}>
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                    <form onSubmit={handleSubmit}>
                        <Field label="Nombre"
                            isHorizontal
                            hasError={errors.Description || touched.Description}
                            msgError={errors.Description || touched.Description ? errors.Description : ""}
                            showLeftSpaces={true}
                            childInfo={values.Description && !errors.Description ? `Te quedan ${50 - values.Description.trim().length} caracteres para la descripción.` : ""}
                        >
                            <input
                                id="tipoDescripcion"
                                name={"Description"}
                                className={`input ${errors.Description && touched.Description   ? "is-danger" : ""}`}
                                placeholder={"Ingrese nombre tipo inventario"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Description}

                            />
                        </Field>
                        <div className="has-text-right buttons-modal">
                            <div className="column">
                                <button className="button is-success" type="submit">
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
InventoryTypeForm.defaultProps = {
    isEditing: false,
    inventoryType: {
        Description: "",
        InventoryTypeId: 0
    }
}

export default InventoryTypeForm;



