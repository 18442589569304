import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';


import CaffenioDriveAPI from '../../services/caffenioDrive';

import Pagination from '../util/pagination/Pagination';
import NoItems from '../util/noitems/NoItems';

import Select from 'react-select';

import ErrorMessages from '../../util/validation-error-messages';
import Loading from '../util/loading/Loading';
import lodash from 'lodash';
import EmpresasAPI from '../../services/CaffenioEmpresas/Empresas';

const subtitle = {
    textAlign: 'initial',
    padding: '10px 10px 10px 0',
    fontSize: '16px',
    color: 'gray'
}

export const DrivesPromo = ({ values, handleNextStep, handleLastStep }) => {

    const [state, setState] = useState({
        isLoading: false,
        pageDrives: [],
        selectedOptionS: [],
        selectedOptionE: [],
        selectedOptionC: [],
        selectedOptionD: [],
        OptionSelectState: [],
        OptionSelectCity: [],
        OptionSelectDrive: [],
        optionSegmentacion: [],
        optionState: [],
        optionCity: [],
        optionDrive: [],
        Caffenios: [],
        CaffeniosFilter: [],
        activeAll: false,
        arrListMenu: [],
        arrListPrices: [],
        selectedMenuAll: null,
        tempAllMenu: null,
        tempAllList: null,
        selectedPricesAll: null,
        filterText: "",
        childVisible: false,
        checkAll: false,
        switchAllActive: false,
        showModal: false,
        caffenio: null,
        drivesAsignados: [],
        segmentaciones: []
    });

    useEffect(() => {
        let CaffeniosActivos = []
        let segmentacionesAux = []
        setState({ ...state, isLoading: true })
        CaffenioDriveAPI.getCaffenios().then(response => {
            CaffeniosActivos = response.data.filter(x => x.Active === true)
            state.Caffenios = CaffeniosActivos;
            state.Caffenios.map(producto => {
                producto.statusCheck = false;
                return producto
            })
            return EmpresasAPI.getAllSegments()
        })
            .then(response => {
                state.segmentaciones = response.data
                setValue();
                setState({
                    ...state,
                    CaffeniosFilter: CaffeniosActivos,
                    optionSegmentacion: select_segmentacion(state.Caffenios),
                    optionState: select_state(state.Caffenios),
                    optionCity: select_city(state.Caffenios),
                    optionDrive: select_Drive(state.Caffenios),
                    segmentaciones: segmentacionesAux
                })
            })
            .catch(err => {
                console.log(err)
                setState({ ...state, isLoading: false })
                if (err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            })

        setState({ ...state, isLoading: false })

    }, []);

    const handleSearchInput = (text) => {
        state.filterText = text;
        FilterProcess();
    }

    const select_segmentacion = (Caffenios) => { // Llenar el combo 'Segmentación' con todas las opciones
        var newArray = [];
        let OptionSelectSegmentacion = [];
        var lookupObject = {};
        let nombreAux = ''
        Caffenios.map((item) => {
            state.segmentaciones.map(x => {
                if (x.SegmentId === item.idSegmento) {
                    nombreAux = x.Name
                }
            })
            OptionSelectSegmentacion.push({ value: item.idSegmento, label: nombreAux });
        });

        for (var i in OptionSelectSegmentacion) {
            lookupObject[OptionSelectSegmentacion[i]["value"]] = OptionSelectSegmentacion[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label], ['asc']);
    }

    const select_state = (Caffenios) => { // Llenar el combo 'Estados' con todas las opciones 
        var newArray = [];
        let OptionSelectState = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectState.push({ value: item.StateId, label: item.State });
        });

        for (var i in OptionSelectState) {
            lookupObject[OptionSelectState[i]["value"]] = OptionSelectState[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label.toLowerCase()], ['asc']);
    }

    const select_city = (Caffenios) => { // Llenar el combo 'Ciudades' con todas las opciones 
        var newArray = [];
        let OptionSelectCity = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectCity.push({ value: item.Municipalityid, label: item.Municipality });
        });

        for (var i in OptionSelectCity) {
            lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label.toLowerCase()], ['asc']);
    }

    const select_Drive = (Caffenios) => { // Llenar el combo 'Drives' con todas las opciones 
        var newArray = [];
        let OptionSelectDrive = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectDrive.push({ value: item.idCaffenio, label: item.NombreNuevo });
        });

        for (var i in OptionSelectDrive) {
            lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label.toLowerCase()], ['asc']);
    }

    const changeSelectSegmentacion = (obj) => {
        state.selectedOptionS = obj
        let idSegmento = obj.map(segmento => { return segmento.value })
        let objCaffenios = state.Caffenios
        let listaEstados = []
        let listaDrives = []

        //Cargar estados
        objCaffenios.map(item => {
            for (var i = 0; i < idSegmento.length; i++) {
                if (item.idSegmento === idSegmento[i]) {
                    listaEstados.push({ "value": item.StateId, "label": item.State });
                }
            }
        })

        //Eliminar duplicados de estados
        let eliminarDuplicadosEstado = eliminarObjDuplicados(listaEstados, "value");

        //Lista drives
        objCaffenios.map(item => {
            for (var i = 0; i < idSegmento.length; i++) {
                if (item.idSegmento === idSegmento[i]) {
                    listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                }
            }
        })

        state.selectedOptionE = []
        state.selectedOptionC = []
        state.selectedOptionD = []

        if (obj.length === 0) {
            state.optionState = select_state(state.Caffenios)
            state.optionCity = select_city(state.Caffenios)
            state.optionDrive = select_Drive(state.Caffenios)
        } else {
            state.optionState = lodash.sortBy(eliminarDuplicadosEstado, [ts => ts.label.toLowerCase()], ['asc']);
            state.optionDrive = lodash.sortBy(listaDrives, [ts => ts.label.toLowerCase()], ['asc']);
        }

        FilterProcess();
    }

    const changeSelectEstado = (obj) => {
        state.selectedOptionE = obj;
        let idEstado = obj.map(estado => { return estado.value });
        let objCaffenios = state.Caffenios;
        let listaCiudades = [];
        let listaDrives = [];
        // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
        objCaffenios.map(item => {
            for (var e = 0; e < idEstado.length; e++) {
                for (var i = 0; i < state.selectedOptionS.length; i++) {
                    if (item.StateId === idEstado[e] && item.idSegmento === state.selectedOptionS[i].value) {
                        listaCiudades.push({ "value": item.Municipalityid, "label": item.Municipality });
                    }
                }
            }
        })



        //Se eliminan las ciudades duplicadas
        let eliminarDuplicados = eliminarObjDuplicados(listaCiudades, "value");
        //Se obtiene un arreglo con los id de las ciudades
        let idCiudad = eliminarDuplicados.map(ciudad => { return ciudad.value });

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item => {
            for (var e = 0; e < idCiudad.length; e++) {
                for (var i = 0; i < state.selectedOptionS.length; i++) {
                    if (item.Municipalityid === idCiudad[e] && item.idSegmento === state.selectedOptionS[i].value) {
                        listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                    }   
                }
            }
        })

        //Se limpian los combos 'Ciudades' y 'Drives'

        state.selectedOptionC = []
        state.selectedOptionD = []

        //Se valida que haya una opción seleccionada en combo 'Estados'
        if (obj.length === 0) {
            changeSelectSegmentacion(state.selectedOptionS);
        } else {

            state.optionCity = lodash.sortBy(eliminarDuplicados, [ts => ts.label.toLowerCase()], ['asc']);
            state.optionDrive = lodash.sortBy(listaDrives, [ts => ts.label.toLowerCase()], ['asc']);
        }

        FilterProcess();
    }

    const changeSelectCiudad = (obj) => {
        let idCiudad = obj.map(ciudad => { return ciudad.value });
        let objCaffenios = state.Caffenios;
        let listaDrives = [];

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item => {
            for (var e = 0; e < idCiudad.length; e++) {
                for (var i = 0; i < state.selectedOptionS.length; i++) {
                    if (item.Municipalityid === idCiudad[e] && item.idSegmento === state.selectedOptionS[i].value) {
                        listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                    }
                }
            }
        })

        //Se limpia el combo 'Drives'
        state.selectedOptionD = []

        //Se valida que haya una opción seleccionada en combo 'Ciudades'
        if (obj.length > 0) {
            state.selectedOptionC = obj
            state.optionDrive = lodash.sortBy(listaDrives, [ts => ts.label.toLowerCase()], ['asc']);
        } else {
            changeSelectEstado(state.selectedOptionE);
        }

        FilterProcess();
    }

    const changeSelectDrive = (obj) => {
        let Caffenios = state.Caffenios;

        //Valida cada select (Estados,Ciudades,Drives) y realiza la acción correspondiente
        if (obj.length > 0) {
            state.selectedOptionD = obj
        } else if (state.selectedOptionC.length === 0) {
            changeSelectEstado(state.selectedOptionE);
        } else if (state.selectedOptionC.length === 0 && state.selectedOptionE.length === 0) {
            select_Drive(Caffenios);
        } else {
            changeSelectCiudad(state.selectedOptionC);
        }

        FilterProcess();
    }

    const FilterProcess = () => {
        let Caffenios = state.Caffenios;
        var status_combo; // Vacios
        let driveIds;

        if (state.selectedOptionD.length === 0) {
            driveIds = state.optionDrive.map(obj => {
                return obj.value;
            })
        } else {
            driveIds = state.selectedOptionD.map(obj => {
                return obj.value;
            })
        }

        status_combo = validarCombos();

        if (state.filterText.trim() !== '') {
            Caffenios = Caffenios.filter(obj => {
                return obj.NombreNuevo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || obj.NombreNuevo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            });
        }

        Caffenios = Caffenios.filter((obj2) => {
            if (driveIds.filter((item) => {
                return item === obj2.idCaffenio
            }).length > 0) { return obj2 }
        })
        setState({ ...state, CaffeniosFilter: Caffenios });
    }

    const eliminarObjDuplicados = (arr, prop) => {
        var nuevoArray = [];
        var lookup = {};

        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }

        for (i in lookup) {
            nuevoArray.push(lookup[i]);
        }

        return nuevoArray;
    }

    const validarCombos = () => {
        var status_combo = false; // Vacios

        if (state.selectedOptionE.length > 0) {
            status_combo = true; // Alguno con valor
        }

        if (state.selectedOptionC.length > 0) {
            status_combo = true; // Alguno con valor
        }

        if (state.selectedOptionD.length > 0) {
            status_combo = true; // Alguno con valor
        }

        return status_combo;
    }

    const selectAllCheck = () => {
        let elementsPage = state.pageDrives;

        elementsPage.map(element => {
            state.checkAll ? element.statusCheck = false : element.statusCheck = true
            return element
        })

        state.checkAll = !state.checkAll;
        state.childVisible = state.checkAll
        state.pageDrives = elementsPage;
        if (state.checkAll === false) {
            state.switchAllActive = false
        }

        setState({
            ...state,
            state
        });
    }

    const handleSelectDrives = () => {

        let drivesFiltered = state.CaffeniosFilter;
        let drivesAll = state.Caffenios;
        let pageDrives = []

        state.drivesAsignados = [...state.drivesAsignados, ...drivesFiltered];

        drivesFiltered.map((element, index) => {
            /* let index2 = drivesAll.indexOf(element)
            drivesAll.splice(index2, 1); */
            values.push(element)
        });
        drivesFiltered = [];
        state.selectedOptionE = [];
        state.selectedOptionC = [];
        state.selectedOptionD = [];

        pageDrives = drivesFiltered.slice(0, 6).map(item => {
            return item
        })

        state.pagedrivesAsignados = state.drivesAsignados.slice(0, 6).map(item => {
            return item
        })

        setState({
            ...state,
            CaffeniosFilter: drivesFiltered,
            Caffenios: drivesAll,
            pageDrives: pageDrives,
            drivesAsignados: state.drivesAsignados
        });


        return;

    }

    const handleRemoveDrives = (element) => {

        let drivesAllAssignados = state.drivesAsignados;


        var index1 = drivesAllAssignados.indexOf(element)
        drivesAllAssignados.splice(index1, 1);

        //Se cambio ya que siempre daba -1 y no eliminaba correctamente
        /* var index2= values.indexOf(element)
        values.splice(index2, 1) */

        var index2 = values.findIndex(e => e.idCaffenio === element.idCaffenio)
        values.splice(index2, 1)

        state.CaffeniosFilter = [...state.CaffeniosFilter, element];
        state.Caffenios = [...state.Caffenios, element];

        state.pageDrives = state.CaffeniosFilter.slice(0, 6).map(item => {
            return item
        })

        state.pagedrivesAsignados = state.drivesAsignados.slice(0, 6).map(item => {
            return item
        })


        setState({
            ...state,
            CaffeniosFilter: state.CaffeniosFilter,
            Caffenios: state.Caffenios,
            pageDrives: state.pageDrives,
            drivesAsignados: state.drivesAsignados
        });


        return;

    }

    const setValue = () => {

        let elementsPage = [];
        let drivesFiltered = state.CaffeniosFilter;
        let drivesAll = state.Caffenios;
        let pageDrives = []

        if (values.length == 0)
            return;


        values.map(item => {
            let drive = drivesAll.filter(drive => drive.idCaffenio == item.idCaffenio)
            if(drive[0] !== undefined){
                elementsPage.push(drive[0])
            }
        })


        
        elementsPage.map(element => {
            
            var index1 = drivesFiltered.indexOf(element)
            drivesFiltered.splice(index1, 1);

            var index2 = drivesAll.indexOf(element)
            drivesAll.splice(index2, 1);

            element.statusCheck = false
            state.drivesAsignados = [...state.drivesAsignados, element];
        })

        pageDrives = drivesFiltered.slice(0, 6).map(item => {
            return item
        })

        state.pagedrivesAsignados = state.drivesAsignados.slice(0, 6).map(item => {
            return item
        })

        setState({
            ...state,
            CaffeniosFilter: drivesFiltered,
            Caffenios: drivesAll,
            pageDrives: pageDrives,
            drivesAsignados: state.drivesAsignados
        });


        return;

    }

    /**
     * 
     * @returns Obtiene el número de drives que aplican a la selección
     */
    const getDrivesNumber = () => {

        let counter = !state.selectedOptionE || !state.CaffeniosFilter ? 0 : state.CaffeniosFilter.length;

        return counter
    }

    return (
        <div className='form-group'>

            <div className='row'>

                <div className="columns is-multiline">

                    <div className="column is-3">
                        <label className="label">Segmentación</label>
                        <div className="control">
                            <Select
                                isMulti
                                placeholder={"Seleccionar..."}
                                name={"selectedOptionS"}
                                value={state.selectedOptionS}
                                onChange={changeSelectSegmentacion}
                                noOptionsMessage={() => "No hay opciones"}
                                options={state.optionSegmentacion}
                            />
                        </div>
                    </div>

                    <div className="column is-3">
                        <label className="label">Estados</label>
                        <div className="control">
                            <Select
                                isMulti
                                placeholder={"Seleccionar..."}
                                name={"selectedOptionE"}
                                value={state.selectedOptionE}
                                onChange={changeSelectEstado}
                                noOptionsMessage={() => "No hay opciones"}
                                options={state.optionState}
                                isDisabled={!state.selectedOptionS || state.selectedOptionS.length === 0}

                            />
                        </div>
                    </div>
                    <div className="column is-3">
                        <label className="label">Ciudades</label>
                        <div className="control">
                            <Select
                                isMulti
                                placeholder={"Seleccionar..."}
                                name={"selectedOptionC"}
                                value={state.selectedOptionC}
                                onChange={changeSelectCiudad}
                                noOptionsMessage={() => "No hay opciones"}
                                options={state.optionCity}
                                isDisabled={!state.selectedOptionE || state.selectedOptionE.length === 0}

                            />
                        </div>
                    </div>
                    <div className="column is-3">
                        <label className="label">Drives</label>
                        <div className="control">
                            <Select
                                isMulti
                                placeholder={"Seleccionar..."}
                                name={"selectedOptionD"}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={state.selectedOptionD}
                                onChange={changeSelectDrive}
                                noOptionsMessage={() => "No hay opciones"}
                                options={state.optionDrive}
                                isDisabled={!state.selectedOptionC || state.selectedOptionC.length === 0}

                            />
                        </div>
                    </div>
                    <div className="column is-12">
                        <label className="label"></label>
                        <button className='button is-success' onClick={handleSelectDrives} disabled={getDrivesNumber() === 0}>
                            {`Agregar ${getDrivesNumber()} Drives`}
                        </button>
                    </div>
                </div>
            </div>

            <br />

            <div className="row">


                {state.drivesAsignados.length !== 0
                    ?
                    <table className="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Ciudad</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.pagedrivesAsignados.map((item, index) => {
                                return (
                                    <tr key={index}>

                                        <td>{item.NombreNuevo}</td>
                                        <td>{item.Municipality}</td>
                                        <td>
                                            <button id="btnQuitarDrive" className="button tableAction is-danger tooltip" data-tooltip="Quitar"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleRemoveDrives(item)
                                                }}>
                                                <span className="icon">
                                                    <i className="fa fa-close" />
                                                </span>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    : <NoItems itemName="drives" />}
                <Pagination items={state.drivesAsignados} pageSize={6} onChangePage={(drives) => setState({ ...state, pagedrivesAsignados: drives })} /*onChangePage={(items) => handlePageChange(items)}*/ />

            </div>


            <br />
            <div className='row' style={{ padding: '10px' }}>
                <div className='columns'>
                    <div className='column is-12'>
                        <div className="has-text-left buttons-modal" id="stepActions">
                            <div className="pull-left">
                                <button
                                    className='button'
                                    style={{ marginRight: 10 }}
                                    onClick={handleLastStep}
                                >
                                    Anterior
                                </button>

                            </div>
                        </div>
                        <div className="has-text-right buttons-modal" id="stepActions">
                            <div className="pull-right">
                                <button
                                    className='button  is-success'
                                    style={{ marginLeft: 10 }}
                                    onClick={() => {

                                        if (values && values.length > 0) {
                                            handleNextStep()
                                        } else {
                                            toast.error("Seleccione al menos un punto de venta donde aplique la promoción.")
                                        }
                                    }}
                                >
                                    Continuar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Loading isFullscreen={true} isLoading={state.isLoading} width='100px' height='100px' />
        </div>
    )
}
