import React, { useState, useEffect } from 'react';
import useForm from '../../../../../util/hooks/useForm';
import DetalleClasificacionApp from '../DetalleClasificacionApp/DetalleClasificacionApp';
import MenuAppAPI from '../../../../../services/Caffenio App/Menu';
import { toast } from 'react-toastify';

export default function EditarClasificacionApp({history, match}){
    const [classification, setClassification] = useState(null);
    const [working, setWorking] = useState(false);

    useEffect(() => {
        MenuAppAPI.getClasificacionApp(match.params.id).then(res => {
            setClassification(res.data);
        }).catch(err => toast.error(err.response.data.Message));
    }, [])

    const {inputs, setInput, errors, setError, replaceInputs , handleInputChange, handleSelectChange, handleSubmit} = useForm(() => {
        edit()
    });

    const edit = () => {
        setWorking(true);
        MenuAppAPI.updateClasificacionApp(inputs.ClassificationId, inputs).then(res => {
            toast.success("Cambios realizados correctamente.");
            history.push('/app/menu/orden');
        }).catch(err => toast.error(err.response.data.Message));
    }

    const inputsAreValid = () => {
        return (inputs.Name && inputs.DescriptionApp) && (inputs.Name.length > 0 && inputs.DescriptionApp.length > 0)
    }

    return (
        <div>
            {/* <h1 className="title is-2">Editar {classification ? classification.Name : ""}</h1> */}
            <div className="card">
                <div className="card-header">
                    <div className="card-header-title is-size-4 center-text">
                        <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                            <i className="fa-5x" style={{cursor: "pointer", color: "#000000" }} onClick={() => {
                                history.push({
                                    pathname: '/app/menu/orden',
                                });
                                }} className="fa fa-arrow-left"></i>
                        </span>
                        {`Editar ${classification ? classification.Name : ""}`}
                    </div>
                </div>
                <div className="card-content">
                    <DetalleClasificacionApp setClassification={setClassification} inputs={inputs} setInput={setInput} editing={true} classification={classification}/>
                    <div className="column is-12">
                        <div className="field is-grouped is-grouped-right">
                            <p className="control">
                                <button
                                    className="button"
                                    type="button"
                                    onClick={() => {
                                        history.push({
                                            pathname: '/app/menu/orden'
                                        });
                                    }}>
                                    Regresar
                                </button>
                            </p>
                            
                            <p className="control">
                                <button
                                    type="submit"
                                    className="button is-success"
                                    disabled={!inputsAreValid() || working}
                                    onClick={handleSubmit}
                                >
                                    Guardar
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}