import React, { useState, useEffect } from 'react';

export default function NuevoPrefijoCampanaDataForm({ disabled, inputs, setInput,setError, errors, handleInputChange }) {
    const [maxDateCupon, setMaxDateCupon] = useState([''])

    // Efecto a realizar cuando cambia el valor de la prop disabled
    useEffect(() => {
        if (!disabled) {
            // getData();
        }
    }, [disabled])

   

    const validateMinCharecterPrefixCampaign = async (e) =>{
        if(e.target.value && e.target.value.length>0 && e.target.value.length<3){
            setError(e.target.name,true);
        }else if(e.target.value.length>=3){
            setError(e.target.name,false);
        }
    }

    const handleKeyDown = e => {
        if(e.key === " "){
            e.preventDefault();
        }
    }

    // const getData = async () => {
    //     if(inputs.category && inputs.category.value){
    //         let res = await PromocionAPI.getTags(inputs.category.value);
    //         if(res.data){
    //             setTags(res.data);
    //         }
    //     }
    // }

    return (
        <div className="columns is-multiline">
            <div className="column is-12">
                <label className="label">Prefijo de la campaña</label>
                <div className={`control`}>
                    <input
                        type="text"
                        name="prefixCampaign"
                        className={`input ${errors.prefixCampaign && "is-danger"}`}
                        maxLength={10}
                        minLength={3}
                        placeholder={`Prefijo de la campaña`}
                        value={inputs.prefixCampaign}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                            handleInputChange(e, true);validateMinCharecterPrefixCampaign(e);}
                        }
                        required
                    />
                </div>
                {errors.prefixCampaign && <p className="help title has-text-left is-12 is-danger">{inputs.prefixCampaign && inputs.prefixCampaign.length>0 && inputs.prefixCampaign.length < 3?`El prefijo debe de tener 3 o más caracteres`:`Prefijo requerido`}</p>}
            </div>
        </div>


    )
}