import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import WarehouseAPI from '../../../services/Warehouse';
import lodash from 'lodash';
import InventoryItemsAPI from '../../../services/InventoryItem';
import { toast } from 'react-toastify';
import Loading from '../../util/loading/Loading';
import TableTemplate from '../../util/tabletemplate/TableTemplate';
import NoItems from '../../util/noitems/NoItems';
import Pagination from '../../util/pagination/Pagination';

const SyncInventoryItem = ({ inventoryItem, close }) => {

    const [state, setState] = useState({
        Caffenios: [], //Todas las sucursales
        selectedOptionS: null, //Segmentos seleccionados
        optionSegmento: [], //Los segmentos a partir de las sucursales
        selectedOption: [], //Estados seleccionados
        optionState: [], //Los estados a partir de las sucursales
        selectedOptionC: [], //Ciudades seleccionadas
        optionCity: [], //Las ciudades a partir de las sucursales
        selectedOptionD: [], //Drives seleccionados
        optionDrive: [], // Los drives seleccionados a partir de los criterios establecidos
        listaSyncDrives: [], //Lista de drives que se van a mandar a sincronizar
        pageSyncDrives: [], //Paginado para la tabla de los drives seleccionados
        isLoading: true
    })

    useEffect(() => {
        WarehouseAPI.getCaffenios().then(response => {
            let CaffeniosAux = response.data
            let optionSegmentoAux = lodash.sortBy(select_segmento(CaffeniosAux), [ts => ts.label.toLowerCase()], ['asc'])
            let optionStateAux = lodash.sortBy(select_state(CaffeniosAux), [ts => ts.label.toLowerCase()], ['asc'])
            let optionCityAux = lodash.sortBy(select_city(CaffeniosAux, state.selectedOptionS), [ts => ts.label.toLowerCase()], ['asc'])
            let optionDriveAux = lodash.sortBy(select_Drive(CaffeniosAux, null), [ts => ts.label.toLowerCase()], ['asc'])

            setState({
                ...state,
                Caffenios: CaffeniosAux,
                optionSegmento: optionSegmentoAux,
                optionState: optionStateAux,
                optionCity: optionCityAux,
                optionDrive: optionDriveAux,
                isLoading: false
            })
        })
            .catch(x => setState({ ...state, isLoading: false }))
    }, [])

    const select_segmento = (Caffenios) => { //Llenar el combo 'Segmentación' con todas las opciones
        var newArray = [];
        let OptionSelectSegmento = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectSegmento.push({ value: item.idSegmento, label: item.Segmento });
        });

        for (var i in OptionSelectSegmento) {
            lookupObject[OptionSelectSegmento[i]["value"]] = OptionSelectSegmento[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    const select_state = (Caffenios) => { // Llenar el combo 'Estados' con todas las opciones 
        var newArray = [];
        let OptionSelect = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelect.push({ value: item.StateId, label: item.State });
        });

        for (var i in OptionSelect) {
            lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    const select_city = (Caffenios, segmentacion) => { // Llenar el combo 'Ciudades' con todas las opciones 
        var newArray = [];
        let OptionSelectCity = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            if (segmentacion === null) { //Se valida si hay segmentación seleccionada para prefiltar
                OptionSelectCity.push({ value: item.Municipalityid, label: item.Municipality });
            } else {
                if (item.idSegmento === segmentacion.value) {
                    OptionSelectCity.push({ value: item.Municipalityid, label: item.Municipality });
                }
            }
        });

        for (var i in OptionSelectCity) {
            lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    const select_Drive = (Caffenios, segmentacion) => { // Llenar el combo 'Drives' con todas las opciones 
        var newArray = [];
        let OptionSelectDrive = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            if (segmentacion === null) { //Se valida si hay segmentación seleccionada para prefiltar
                OptionSelectDrive.push({ value: item.idCaffenio, label: item.NombreNuevo });
            } else {
                if (item.idSegmento === segmentacion.value) {
                    OptionSelectDrive.push({ value: item.idCaffenio, label: item.NombreNuevo });
                }
            }
        });

        for (var i in OptionSelectDrive) {
            lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    const changeSelectSegmento = (obj) => {
        let stateAux = { ...state }
        stateAux["selectedOptionS"] = obj;
        if (obj !== null) {
            let idSegmento = obj.value
            let objCaffenios = [...stateAux.Caffenios];
            let listaEstados = [];
            let listaCiudades = [];
            let listaDrives = [];
            // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
            objCaffenios.map(item => {
                if (item.idSegmento === idSegmento) {
                    listaEstados.push({ "value": item.StateId, "label": item.State });
                }
            })

            //Se eliminan los estados duplicados
            let eliminarDuplicados = eliminarObjDuplicados(listaEstados, "value");
            //Se obtiene un arreglo con los id de los estados
            let idEstado = eliminarDuplicados.map(estado => { return estado.value });
            //Se buscan coincidencias entre estado y ciudad y se genera la lista de drives
            objCaffenios.map(item => {
                for (var i = 0; i < idEstado.length; i++) {
                    if (item.StateId === idEstado[i] && item.idSegmento === idSegmento) {
                        listaCiudades.push({ "value": item.Municipalityid, "label": item.Municipality });
                    }
                }
            })
            //Se eliminan duplicados de ciudad
            let eliminarDuplicadosCiudad = eliminarObjDuplicados(listaCiudades, "value");
            //Se obtien un arreglo con los id de las ciudades
            let idCiudad = eliminarDuplicadosCiudad.map(ciudad => { return ciudad.value });

            //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
            objCaffenios.map(item => {
                for (var i = 0; i < idCiudad.length; i++) {
                    if (item.Municipalityid === idCiudad[i] && item.idSegmento === idSegmento) {
                        listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                    }
                }
            })
            stateAux["optionState"] = eliminarDuplicados;
            stateAux["optionCity"] = eliminarDuplicadosCiudad;
            stateAux["optionDrive"] = listaDrives;
        } else {
            //En caso que se haya borrado la segmentación del select, se reinician
            //los demas filtros
            stateAux["optionState"] = select_state(stateAux.Caffenios);
            stateAux["optionCity"] = select_city(stateAux.Caffenios, stateAux["selectedOptionS"]);
            stateAux["optionDrive"] = select_Drive(stateAux.Caffenios, stateAux["selectedOptionS"]);
        }
        //Se limpian las opciones del select 'Estado', 'Ciudades' y 'Drives'
        stateAux["selectedOption"] = [];
        stateAux["selectedOptionC"] = [];
        stateAux["selectedOptionD"] = [];

        setState(stateAux);
    }

    const changeSelectEstado = (obj) => {
        let stateAux = { ...state }
        stateAux["selectedOption"] = obj;
        let idEstado = obj.map(estado => { return estado.value });
        let objCaffenios = [...stateAux.Caffenios];
        let listaCiudades = [];
        let listaDrives = [];

        // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
        objCaffenios.map(item => {
            for (var i = 0; i < idEstado.length; i++) {
                if (item.StateId === idEstado[i]) {
                    if (stateAux.selectedOptionS === null) { //Se valida si hay segmentación seleccionada para prefiltar
                        listaCiudades.push({ "value": item.Municipalityid, "label": item.Municipality });
                    } else {
                        if (item.idSegmento === stateAux.selectedOptionS.value) {
                            listaCiudades.push({ "value": item.Municipalityid, "label": item.Municipality });
                        }
                    }
                }
            }
        })

        //Se eliminan las ciudades duplicadas
        let eliminarDuplicados = eliminarObjDuplicados(listaCiudades, "value");
        //Se obtiene un arreglo con los id de las ciudades
        let idCiudad = eliminarDuplicados.map(ciudad => { return ciudad.value });

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item => {
            for (var i = 0; i < idCiudad.length; i++) {
                if (item.Municipalityid === idCiudad[i]) {
                    if (stateAux.selectedOptionS === null) { //Se valida si hay segmentación seleccionada para prefiltar
                        listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                    } else {
                        if (item.idSegmento === stateAux.selectedOptionS.value) {
                            listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                        }
                    }
                }
            }
        })

        //Se limpian los combos 'Ciudades' y 'Drives'
        stateAux["selectedOptionC"] = [];
        stateAux["selectedOptionD"] = [];

        //Se valida que haya una opción seleccionada en combo 'Estados'
        if (obj.length === 0) {
            stateAux["optionCity"] = select_city(stateAux.Caffenios, stateAux["selectedOptionS"]);
            stateAux["optionDrive"] = select_Drive(stateAux.Caffenios, stateAux["selectedOptionS"]);

        } else {
            stateAux["optionCity"] = eliminarDuplicados;
            stateAux["optionDrive"] = listaDrives;
        }

        setState(stateAux);
    }

    const changeSelectCiudad = (obj) => {
        let stateAux = { ...state }
        let idCiudad = obj.map(ciudad => { return ciudad.value });
        let objCaffenios = [...stateAux.Caffenios];
        let listaDrives = [];

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item => {
            for (var i = 0; i < idCiudad.length; i++) {
                if (item.Municipalityid === idCiudad[i]) {
                    if (stateAux.selectedOptionS === null) { //Se valida si hay segmentación seleccionada para prefiltar
                        listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                    } else {
                        if (item.idSegmento === stateAux.selectedOptionS.value) {
                            listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                        }
                    }
                }
            }
        })

        //Se limpia el combo 'Drives'
        stateAux["selectedOptionD"] = [];

        //Se valida que haya una opción seleccionada en combo 'Ciudades'
        if (obj.length > 0) {
            stateAux["selectedOptionC"] = obj;
            stateAux["optionDrive"] = listaDrives;
            setState(stateAux);
        } else {
            changeSelectEstado(stateAux["selectedOption"]);
        }
    }

    const changeSelectDrive = (obj) => {
        let stateAux = { ...state };
        let Caffenios = [...stateAux.Caffenios];

        //Valida cada select (Estados,Ciudades,Drives) y realiza la acción correspondiente
        if (obj.length > 0) {
            stateAux["selectedOptionD"] = obj;
            //state["optionDrive"] = obj;
            setState(stateAux);
        } else if (stateAux["selectedOptionC"].length === 0) {
            changeSelectEstado(stateAux["selectedOption"]);
        } else if (stateAux["selectedOptionC"].length === 0 && stateAux["selectedOption"].length === 0) {
            select_Drive(Caffenios, stateAux["selectedOptionS"]);
            select_Drive(Caffenios, stateAux["selectedOptionS"]);
            select_Drive(Caffenios, stateAux["selectedOptionS"]);
        } else {
            changeSelectCiudad(stateAux["selectedOptionC"]);
        }
    }

    const eliminarObjDuplicados = (arr, prop) => {
        var nuevoArray = [];
        var lookup = {};

        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }

        for (i in lookup) {
            nuevoArray.push(lookup[i]);
        }

        return nuevoArray;
    }


    return (
        <div className="card">
            <div className="card-content">

                <div className="columns">
                    <div className="column is-3">
                        <label className="label">Segmentación</label>
                        <div className="control">
                            <Select
                                isClearable
                                placeholder={"seleccionar..."}
                                name={"selectedOptionS"}
                                value={state.selectedOptionS}
                                onChange={(x) => changeSelectSegmento(x)}
                                noOptionsMessage={() => "No hay opciones"}
                                options={state.optionSegmento}
                            />
                        </div>
                    </div>

                    <div className="column is-3">
                        <label className="label">Estados</label>
                        <div className="control">
                            <Select
                                isMulti
                                placeholder={"seleccionar..."}
                                name={"selectedOption"}
                                value={state.selectedOption}
                                onChange={(x) => changeSelectEstado(x)}
                                noOptionsMessage={() => "No hay opciones"}
                                options={state.optionState}
                            />
                        </div>
                    </div>

                    <div className="column is-3">
                        <label className="label">Ciudades</label>
                        <div className="control">
                            <Select
                                isMulti
                                placeholder={"seleccionar..."}
                                name={"selectedOptionC"}
                                value={state.selectedOptionC}
                                onChange={(x) => changeSelectCiudad(x)}
                                noOptionsMessage={() => "No hay opciones"}
                                options={state.optionCity}
                            />
                        </div>
                    </div>

                    <div className="column is-3">
                        <label className="label">Drives</label>
                        <div className="control">
                            <Select
                                isMulti
                                placeholder={"seleccionar..."}
                                name={"selectedOptionD"}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={state.selectedOptionD}
                                onChange={(x) => changeSelectDrive(x)}
                                noOptionsMessage={() => "No hay opciones"}
                                options={state.optionDrive}
                            />
                        </div>
                    </div>

                </div>

                <div className='columns'>
                    <div className="column is-3"></div>
                    <div className="column is-6">
                        <button
                            id={"btnAgregarDrives"}
                            onClick={() => {
                                if (state.selectedOptionD.length > 0) {
                                    setState({ ...state, listaSyncDrives: state.selectedOptionD, pageSyncDrives: state.selectedOptionD })
                                    console.log('state.optionDrive', state.selectedOptionD)
                                } else {
                                    setState({ ...state, listaSyncDrives: state.optionDrive, pageSyncDrives: state.optionDrive })
                                    console.log('state.optionDrive', state.optionDrive)
                                }

                            }}
                            className="button is-success"
                            style={{ marginRight: 5 }}
                        >
                            Seleccionar Drives
                        </button>
                        <button
                            id={"btnEliminarDrives"}
                            onClick={() => setState({ ...state, listaSyncDrives: [] })}
                            className="button is-danger"
                        >
                            Eliminar
                        </button>
                    </div>
                </div>

                <div className='columns'>
                    <div className="column is-3"></div>
                    <div className="column is-6">
                        {
                            (state.listaSyncDrives && state.listaSyncDrives.length !== 0) ?
                                <button
                                    id={"btnAceptarSync"}
                                    onClick={() => {
                                        if (state.listaSyncDrives.length > 0) {
                                            setState({ ...state, isLoading: true })
                                            let listaArticuloDrive = state.listaSyncDrives.map(x => ({
                                                "IdArticuloInventario": inventoryItem.InventoryItemId,
                                                "IdCaffenio": x.value
                                            }))

                                            InventoryItemsAPI.syncDrives(listaArticuloDrive)
                                                .then(() => {
                                                    toast.success('Artículo inventario sincronizado')
                                                    setState({ ...state, isLoading: false })
                                                    close()
                                                })
                                                .catch((x) => {
                                                    setState({ ...state, isLoading: false })
                                                    toast.error('Error al sincronizar artículo inventario')
                                                })
                                        } else {
                                            toast.error('No hay drives seleccionados')
                                        }
                                    }}
                                    className="button is-success"
                                >
                                    Aceptar
                                </button>
                                : <></>
                        }

                    </div>
                </div>

                <div className='columns'>
                    {
                        (state.listaSyncDrives && state.listaSyncDrives.length !== 0) ?
                            <div className="column is-12">
                                <TableTemplate
                                    columns={["value", "label"]}
                                    columnsNames={["Id", "Nombre"]}
                                    canDelete
                                    remove={(inventoryItem) => {
                                        let listaSyncDrivesAux = [...state.listaSyncDrives]
                                        listaSyncDrivesAux.splice(listaSyncDrivesAux.findIndex(x => 
                                            x.value === inventoryItem.value), 1)
                                        setState({...state, listaSyncDrives: listaSyncDrivesAux})
                                    }}
                                    data={state.pageSyncDrives}
                                    rowClickable={false}
                                />
                                <Pagination
                                    items={state.listaSyncDrives}
                                    onChangePage={(listaSyncDrives) => setState({ ...state, pageSyncDrives: listaSyncDrives })}
                                />
                            </div>
                            :
                            <></>
                    }
                </div>
            </div>
            <Loading isFullscreen={true} isLoading={state.isLoading} width='100px' height='100px' />
        </div>
    )
}

export default SyncInventoryItem