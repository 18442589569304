import React, { Component } from 'react';
import Field from '../../../util/field/Field';
import Dropdown from '../../../util/dropdown/Dropdown';
import { Formik } from 'formik';
import * as Yup from 'yup';

const SUPPORTED_FORMATS = [
    "image/png"
];
/**
 * Formulario para cargar y validar PT asignado a artículo inventario
 */
class InventoryItemEndForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventoryItem: { ...this.props.inventoryItem },
            isEditing: false,
            isLoading: false
        }
    }

    VALIDATION_SCHEMA = Yup.object({
        IsPerecedero: Yup.bool(),
        IsTopping: Yup.bool()
    });

    VALIDATION_SCHEMA_WITH_TOPPING = Yup.object({
        IsPerecedero: Yup.bool(),
        IsTopping: Yup.bool(),
       // ToppingPrice: Yup.number().min(0, "Ingrese valores mayores a 0.").required("El campo Precio es requerido."),
        ToppingWeight: Yup.number().min(0, "Ingrese valores mayores a 0.").required("El campo Gramos a descontar es requerido."),
        CodeSat: Yup.string().required("Campo Código SAT es requerido.").nullable(),
        Image: Yup.mixed()
            .required("El campo Icono/Imagen es requerido.")            
            .test(
                "fileFormat",
                "Solo se permite formato png.",
                value => value && SUPPORTED_FORMATS.includes(value.type) 
                    || (this.state.inventoryItem.URLimagen !== null && this.state.inventoryItem.URLimagen.length > 0)
            )
    });
    VALIDATION_SCHEMA_WITH_TOPPINGPRICE = Yup.object({
        IsPerecedero: Yup.bool(),
        IsTopping: Yup.bool(),
        ToppingPrice: Yup.number().min(0, "Ingrese valores mayores a 0.").required("El campo Precio es requerido."),
        ToppingWeight: Yup.number().min(0, "Ingrese valores mayores a 0.").required("El campo Gramos a descontar es requerido."),
        CodeSat: Yup.string().required("Campo Código SAT es requerido.").nullable(),
        Image: Yup.mixed()
            .required("El campo Icono/Imagen es requerido.")            
            .test(
                "fileFormat",
                "Solo se permite formato png.",
                value => value && SUPPORTED_FORMATS.includes(value.type) 
                    || (this.state.inventoryItem.URLimagen !== null && this.state.inventoryItem.URLimagen.length > 0)
            )
    });

    render() {
        return (
            <Formik
                initialValues={this.state.inventoryItem}
                validationSchema={this.state.inventoryItem.IsTopping 
                    ? this.state.inventoryItem.ShowToppingPrice
                        ? this.VALIDATION_SCHEMA_WITH_TOPPINGPRICE
                        : this.VALIDATION_SCHEMA_WITH_TOPPING 
                    : this.VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    this.props.save(values)
                }}>
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                    <form onSubmit={handleSubmit} id="frmCompleteInventoryItem">
                        <div className="columns">
                            <div className="column is-6">
                                <Field label=" Es Perecedero"
                                    className="has-text-centered"
                                    isHorizontal>
                                    <input
                                        id="chkIsPerecedero"
                                        type="checkbox"
                                        name="isPerecedero"
                                        className="switch"
                                        value={values.IsPerecedero}
                                        checked={values.IsPerecedero}
                                    />
                                    <label htmlFor="IsPerecedero" onClick={(e) => {
                                        e.stopPropagation();
                                        setFieldValue('IsPerecedero', !values.IsPerecedero)
                                    }} />
                                </Field>
                            </div>

                            <div className="column is-6">
                                <Field label=" Es Topping" className="has-text-centered" isHorizontal>
                                    <input
                                        id="chkIsTopping"
                                        type="checkbox"
                                        name="isTopping"
                                        className="switch"
                                        checked={values.IsTopping}
                                        value={values.IsTopping}
                                    />
                                    <label htmlFor="isTopping" onClick={(e) => {
                                        e.stopPropagation();
                                        let this_inventoryItem = { ...values };
                                        this_inventoryItem.IsTopping = !values.IsTopping;
                                        this.setState({ inventoryItem: this_inventoryItem })
                                        setFieldValue('IsTopping', !values.IsTopping)
                                    }} />
                                </Field>
                            </div>
                        </div>
                        {
                            this.state.inventoryItem.IsTopping &&

                            <div className="columns is-multiline">
                                {this.state.inventoryItem.ShowToppingPrice &&
                                    <div className="column is-4">
                                        <Field
                                            label="Precio"
                                            hasError={errors.ToppingPrice || touched.ToppingPrice}
                                            msgError={errors.ToppingPrice || touched.ToppingPrice ? errors.ToppingPrice : ""}
                                        >
                                            <input
                                                id="txtPrecio"
                                                className={`input ${errors.ToppingPrice && touched.ToppingPrice ? "is-danger" : ""}`}
                                                type="number"
                                                label="Precio"
                                                name="ToppingPrice"
                                                value={values.ToppingPrice}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder={"Ingrese precio topping"}
                                            />
                                        </Field>
                                    </div>
                                }
                                <div className={`column ${this.state.inventoryItem.ShowToppingPrice ? "is-4" : "is-6"} `}>
                                    <Field
                                        label={"Cantidad a descontar como topping" +
                                            ((this.props.inventoryItem && this.props.inventoryItem.UnitSale && this.props.units.length > 0)
                                                ? " (" + this.props.units.find(item => item.UnitsId === this.props.inventoryItem.UnitSale).Name + ")"
                                                : "")}
                                        hasError={errors.ToppingWeight || touched.ToppingWeight}
                                        msgError={errors.ToppingWeight || touched.ToppingWeight ? errors.ToppingWeight : ""}
                                    >
                                        <input
                                            id="txtToppingWeight"
                                            className={`input ${errors.ToppingWeight && touched.ToppingWeight ? "is-danger" : ""}`}
                                            type="number"
                                            label="Gramos a descontar"
                                            name="ToppingWeight"
                                            value={values.ToppingWeight}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Cantidad a descontar como topping."}
                                        />
                                    </Field>
                                </div>

                                <div className={`column ${this.state.inventoryItem.ShowToppingPrice ? "is-4" : "is-6"} `}>
                                    <Field label="Código SAT para facturar"
                                        hasError={errors.CodeSat || touched.CodeSat}
                                        msgError={errors.CodeSat || touched.CodeSat ? errors.CodeSat : ""}
                                    >
                                        <Dropdown
                                            single
                                            items={this.props.satCodes.map(item => {return {...item, Description: `${item.Code} - ${item.Description}`}} )}
                                            value={values.CodeSat}
                                            id="Code"
                                            labelKey={"Description"}
                                            onSelect={(val) => {
                                                if (val > 0) {

                                                    let selected = this.props.satCodes[val - 1].Code.trim();
                                                    setFieldValue('CodeSat', selected)

                                                } else {
                                                    setFieldValue('CodeSat', null)
                                                }
                                            }}
                                            onBlur={() => setFieldTouched('CodeSat', true)}
                                            hasError={errors.CodeSat && touched.CodeSat}
                                        />
                                    </Field>
                                </div>


                                <div className="column is-12">
                                    <Field label="Icono/Imagen" isHorizontal
                                        hasError={errors.Image || touched.Image}
                                        msgError={errors.Image || touched.Image ? errors.Image : ""}
                                    >
                                        <div className={`file ${errors.Image && touched.Image ? "is-danger" : "is-info"} has-name`}>
                                            <label className="file-label">
                                                <input
                                                    className={`input file-input ${errors.Image && touched.Image ? "is-danger" : ""}`}
                                                    id="fileInventpryItem"
                                                    type="file"
                                                    name="Image"
                                                    accept="image/x-png"
                                                    name="Image"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        let files = e.target.files
                                                        let file = files[0]
                                                        e.stopPropagation();
                                                        e.target.value = null;
                                                        if (file) {
                                                            setFieldValue('Image', file);
                                                            setFieldValue('URLimagen', "");                                                          
                                                            var reader = new FileReader();
                                                            reader.readAsDataURL(file);
                                                            var reader = new FileReader();
                                                            reader.onload = function (e) {
                                                                let previewControl = document.getElementById("imgPreview");
                                                                if (previewControl) {
                                                                    document.getElementById("imgPreview").setAttribute('src', e.target.result);
                                                                }
                                                            }
                                                            reader.readAsDataURL(file);

                                                        } else {
                                                            setFieldValue('URLimagen', "")
                                                            setFieldValue('Image', null)

                                                        }
                                                    }}
                                                />
                                                <span className="file-cta">
                                                    <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo .PNG</i></span>
                                                </span>
                                                <span className="file-name">{values.Image == null ? "Archivo" : values.Image.name}</span>
                                            </label>
                                        </div>
                                        {values.Image !== null &&
                                            <Field>
                                                <button className="button tableAction is-danger trash-right"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.target.value = null;
                                                        setFieldValue('Image', null)
                                                        setFieldValue('URLimagen', "")                                                        
                                                    }
                                                    }>
                                                    <span className="icon">
                                                        <i className="fa fa-trash" />
                                                    </span>
                                                </button>

                                            </Field>
                                        }
                                    </Field>


                                </div>



                            </div>
                        }
                        <br />

                        {values.Image !== null
                            //&& (values.URLimagen && (values.URLimagen === null || values.URLimagen.length === 0))
                            && <Field>
                                <div className="column">
                                    <figure className="image container-image " >
                                        <img id={"imgPreview"}
                                            src="#"
                                            style={{ width: 'auto', height: '50px' }}
                                            alt="" />
                                    </figure>
                                </div>
                            </Field>

                        }
                        {values.URLimagen && <Field>
                            <div className="column">
                                <figure className="image container-image ">
                                    <img src={values.URLimagen.toString() + "?" + new Date().getTime()}
                                        style={{ width: 'auto', height: '50px' }}
                                        alt="" />
                                </figure>
                            </div>
                        </Field>}
                        <div className="has-text-left buttons-modal" id="stepActions">
                            <div className="pull-left">
                                <button id="btnBackStepTopping" type="button" className="button is-light" onClick={() => this.props.back()} >Anterior</button>

                            </div>
                        </div>
                        <div className="has-text-right buttons-modal" id="stepActions">
                            <div className="pull-right">
                                <button id="btnSaveInventoryItem" type="submit" className="button is-success"  >Guardar</button>
                            </div>
                        </div>

                    </form>
                )}

            </Formik>

        )
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
InventoryItemEndForm.defaultProps = {
    isEditing: false,
    inventoryItem: {
        CodeProvider: "",
        InventoryCode: "",
        Name: "",
        ShortName: "",
        UnitPurchase: 0,
        StorageUnit: 0,
        UnitSale: 0,
        ConvFactorBuy: "",
        ConvFactorSale: "",
        FactorBuyMin: 0.95,
        AmountMin: 0,
        AmountMax: 0,
        InventoryTypeId: 0,
        CodeSat: null,
        IsTopping: false,
        ToppingPrice: 0,
        ToppingWeight: 0,
        Image:null,
        URLimagen: ""
    }
}

export default InventoryItemEndForm;