import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";
import moment from 'moment';

export default function AgendarNotificacionDataForm({ inputs, setInput, errors, handleInputChange }) {
    const [minDateNotification, setMinDateNotification] = useState(null);
    const onChangeCheckbox = async () => {
        setInput('scheduleNotification', !inputs.scheduleNotification);
        if (!inputs.scheduleNotification) {
            setInput('notificationDate', null);
            setInput('titleNotification', null);
            setInput('contentNotification', null);
        }
    }


    useEffect(() => {
        (async () => {

            if (moment(inputs.startDateCupon).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')) {
                var date = new Date();
                date.setDate(date.getDate() + 1);
                setMinDateNotification(date);
            } else {
                setMinDateNotification(inputs.startDateCupon);
            }

        })()
    }, [inputs.startDateCupon])

    return (
        <div className="columns is-multiline">
            <div className="column is-6">
                <label className="label">Agendar notificación</label>
                <input
                    type="checkbox"
                    name="scheduleNotification"
                    className="switch"
                    checked={inputs.scheduleNotification}
                    onChange={() => { }}
                />
                <label disabled htmlFor="scheduleNotification" onClick={(e) => {
                    e.stopPropagation();
                    onChangeCheckbox();
                }} />
            </div>
            {inputs.scheduleNotification && <div className="column is-12">
                <div className="columns is-multiline">
                    <div className="column is-6">
                        <label className="label">Fecha de notificación</label>
                        <div className="control has-text-centered">
                            <DatePicker
                                placeholderText="Seleccionar fecha de notificación..."
                                className="input"
                                disabled={!(inputs.startDateCupon && inputs.endDateCupon)}
                                selected={inputs.notificationDate}
                                minDate={minDateNotification}
                                onChange={date => {
                                    setInput('notificationDate', date)
                                }}
                                maxDate={inputs.endDateCupon}
                                onChangeRaw={(e) => e.preventDefault()}
                                dateFormat="dd/MMMM/yyyy"
                                locale={es}
                                required={inputs.scheduleNotification}
                            />
                        </div>
                        {errors.notificationDate && <p className="help title has-text-left is-6 is-danger">Fecha de notificación requerida</p>}
                    </div>
                    <div className="column is-6">
                        <label className="label">Título</label>
                        <div className={`control`}>
                            <input
                                type="text"
                                name="titleNotification"
                                className={`input ${errors.title && "is-danger"}`}
                                maxLength={80}
                                placeholder={`Título`}
                                value={inputs.titleNotification}
                                onChange={(e) => handleInputChange(e, true)}
                                required={inputs.scheduleNotification}
                            />
                        </div>
                        {errors.titleNotification && <p className="help title has-text-left is-6 is-danger">Título requerido</p>}
                    </div>
                    <div className="column is-6">
                        <label className="label">Contenido</label>
                        <div className={`control`}>
                            <input
                                type="text"
                                name="contentNotification"
                                className={`input ${errors.content && "is-danger"}`}
                                maxLength={200}
                                placeholder={`Contenido`}
                                value={inputs.contentNotification}
                                onChange={(e) => handleInputChange(e, true)}
                                required={inputs.scheduleNotification}
                            />
                        </div>
                        {errors.contentNotification && <p className="help title has-text-left is-6 is-danger">Contenido requerido</p>}
                    </div>

                </div>
            </div>}
        </div>
    );
}