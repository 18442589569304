import React, { Component } from 'react'
import PropTypes from 'prop-types';

export default class Field extends Component {

    static propTypes = {
        isHorizontal: PropTypes.bool,
        alingRight : PropTypes.bool,
        isVertical: PropTypes.bool,
        label: PropTypes.string,
        showTooltip: PropTypes.bool,
        textTooltip: PropTypes.string,
        hasError: PropTypes.bool,
        msgError: PropTypes.string,
        showLeftSpaces: PropTypes.bool,
        childInfo: PropTypes.string,
        
    }

    static defaultProps = {
        isHorizontal: false,
        alingLefth : false,
        isVertical: false,
        label: "",
        showTooltip: false,
        textTooltip: '',
        hasError: false,
        msgError: "",
        showLeftSpaces: false,
        childInfo: "",
        
    }

    render() {
        return (
            <div>
                
                <div className={`field ${this.props.isHorizontal ? "FieldHorizontal is-horizontal" : this.props.alingLefth ? "has-text-left" : "has-text-centered"} ${this.props.showTooltip ? 'tooltip' : ''}  `} data-tooltip={this.props.textTooltip}>
                    {this.props.label !== "" &&

                        <div className={`${this.props.isHorizontal ? "field-label" : ""}`}>
                            <label className="label">{this.props.label}</label>
                        </div>
                    }
                    <div className="field-body" style = {this.props.isVertical ? {"display" : "inline-flex"}: {}}>
                        {this.props.children}
                    </div>

                </div>
                {
                    this.props.hasError 
                    ? <p className="help is-danger" style={{ marginTop: "-10px" }}>{this.props.msgError}</p>
                    : (this.props.showLeftSpaces
                        ? <p className="help" style={{ marginTop: "-10px" }}>{this.props.childInfo}</p>
                        : "" )
                }
            </div>

        )
    }
}
