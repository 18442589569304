import React, { useState } from 'react';
const useForm = (callback) => {
    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({});

    // Método para el cual invocar el callback desde cualquier component donde se use el hook
    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        callback();
    }

    // Método para procesar el cambio de un input, el valor se guarda en el atributo con el mismo nombre que el campo
    const handleInputChange = (event, required = false) => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
        if(required) setErrors(errors => ({...errors, [event.target.name]: !!!event.target.value}));
    }

    // Método para procesar el cambio de un select (react-select), el valor se guarda en el atributo con el mismo nombre que el campo
    const handleSelectChange = (value, event, required = false) => {
        setInputs(inputs => ({...inputs, [event.name]: value}));
        if (required) setErrors(errors => ({...errors, [event.name]: !!!value}));
    }

    // Método para obtener un input dado su nombre, si no se propociona nombre se regresan todos.
    const getInputs = (name = "") => {
        if (name.length > 0){
            return inputs[name];
        }
        return inputs;
    }

    // Método para settear un valor de manera manual
    const setInput = (name, value) => {
        setInputs(inputs => ({...inputs, [name]:value}));
    }

    // Método para settear error de un atributo
    const setError = (name, value) => {
        setErrors(errors => ({...errors, [name]: value}));
    }

    // Método para limpiar cualquier dato ingresado al formulario.
    const cleanInputs = () => {
        setInputs({});
    }

    // Método para reemplazar los valores en el formulario
    const replaceInputs = (body) => {
        setInputs(body);
    }

    return {
        handleSubmit,
        handleInputChange,
        handleSelectChange,
        replaceInputs,
        inputs,
        setInput,
        setError,
        getInputs,
        cleanInputs,
        errors
    };
}
export default useForm;