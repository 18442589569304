import { apiUrl }  from "../constants";
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class WarehouseAPI {
    static getAll() {
        return request.get('/AlmArt');
    }

    static GetByIdArticulo(textoFiltro) {
        return request.get(`/AlmArt/GetByNombreCodigo/${textoFiltro}`);
    }

    static edit(warehouse){
        return request.put('/Almart',warehouse);
    }

    static editAll(arrObjects){
        return request.put('/Almarts',arrObjects);
    }

    static getCaffenios(){
        return request.get('/Caffenios');
    }

    /*static get(id) {
        return request.get(`/inventoryType/${id}`);
    } */

    /*static edit(inventoryType){
        return request.put(`/inventoryType`,inventoryType);
    } */

}