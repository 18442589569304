import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types';
import lodash from 'lodash';

/**
 * 
 * Componente de utilería para el manejo de las rutas dentro de la aplicación
 * Se valida el token del usuario en sesión y sus permisos para así ver si es 
 * posible o no entrar a ciertas escenas.
 */

const PrivateRoute = ({ component: Component, ...rest }) => {
  let expiration = localStorage.getItem("expires_in");
  let strToken = localStorage.getItem("token");
  let isAbletoEnter = false;
  //console.log(expiration > Date.now()/1000);
  if(strToken && parseInt(expiration,10) > Date.now()/1000){
    let roles = localStorage.getItem('role').split(',');
    let permissions = localStorage.getItem('permissions').split(',');
    //console.log(roles, permissions, rest.roles, rest.permissions);
    isAbletoEnter = lodash.intersection(rest.roles, roles).length > 0 || lodash.intersection(rest.permissions, permissions).length > 0;
  }else{
    localStorage.clear();
    window.location.reload();
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAbletoEnter ? (
          <Component {...props} />
        ) :
        (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  permissions: PropTypes.arrayOf(PropTypes.string)
}

PrivateRoute.defaultProps = {
  roles: [],
  permissions: []
}

export default PrivateRoute