import React from 'react';
import PropTypes from 'prop-types';

/**
 * Componente de utilería para la utilización de un campo de texto dentro de un Field
 * con el fin de mantener consistencia y a su vez facilitar uso y legibilidad de la
 * etiqueta <input/>
 */

class TextField extends React.Component{

    render(){
        return (
            <div className="FieldHorizontal field is-horizontal">
                {this.props.label !== "" && <div className="field-label">
                    <label className="label">{this.props.label}</label>
                </div>}
                <div className="field-body">
                    <input 
                        className="input" 
                        {...this.props}
                    />
                </div>
            </div>
        )
    }
}

// Definición de los tipos y su dependencia de las distintas props que recibe el componente.
TextField.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    autoFocus: PropTypes.bool,
    value: PropTypes.any
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
TextField.defaultProps = {
    type: "text",
    placeholder: "",
    label: "",
    autoFocus: false,
    value: ""
}

export default TextField;