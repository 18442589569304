import React, { Component } from 'react';
import RecipeCard from '../components/RecipeCard';


/**
 * Formulario para cargar la información general de la receta
 */
class RecipesConfigStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipe: { ...this.props.recipe },
            cardsOpen: true,
            selectedCard: 0,
            isEditing: false,
            isLoading: false
        }
    }
    
    render() {
        return (
            this.props.cards.length > 0 &&

            <div>

                {this.state.cardsOpen && <React.Fragment>
                    <div className="tabs is-toggle is-centered">
                        <ul>
                            {this.props.cards.map((tarjeta, indiceTarjeta) => {
                                let tituloCard = []
                                if (tarjeta.Propiedades.ClassificationId.ClassificationId != 15) tituloCard.push(tarjeta.Nombres.Clasificacion)
                                if (tarjeta.Nombres.TipoPresentacion && tarjeta.Nombres.TipoPresentacion.toLowerCase() != "sin presentación") tituloCard.push(tarjeta.Nombres.TipoPresentacion)
                                if (tarjeta.Nombres.TipoReceta && tarjeta.Nombres.TipoReceta.toLowerCase() != "sin tipo") tituloCard.push(tarjeta.Nombres.TipoReceta)

                                return <li key={indiceTarjeta + tituloCard.join("")} className={this.state.selectedCard == indiceTarjeta ? "is-active" : ""} style={{width:'fit-content'}}>
                                    <a onClick={() => {
                                        this.setState({ selectedCard: indiceTarjeta })
                                    }}>
                                        {tituloCard.join(" ")}
                                    </a>
                                </li>
                            })}
                        </ul>
                    </div>

                    <div className="columns is-multiline">
                        {
                            <RecipeCard
                                Caffenios={this.props.Caffenios}
                                optionState={this.props.optionState}
                                receta={this.props.cards[this.state.selectedCard]}
                                onDetailChange={(detallesActualizados, tipoLabel, indice) => {
                                    this.props.onDetailChange(detallesActualizados, tipoLabel, indice, this.state.selectedCard)
                                }
                                }
                                onToppingChange={(toppingsActualizados, tipoLabel, indice) => {
                                    this.props.onToppingChange(toppingsActualizados, tipoLabel, indice, this.state.selectedCard)
                                }}
                            />

                        }
                    </div>

                    <div className="has-text-left buttons-modal" id="stepActions">
                        <div className="pull-left">
                            <button id="btnBackStepRecipesConfig" type="button" className="button is-light" onClick={() => this.props.back()} >Anterior</button>

                        </div>
                    </div>
                    <div className="has-text-right buttons-modal" id="stepActions">
                        <div className="pull-right">
                            <button id="btnSaveStepRecipesConfig" type="button" className="button is-success" 
                            onClick={() => {
                                this.props.save()}} >Guardar recetas</button>
                        </div>
                    </div>

                </React.Fragment>}
            </div>


        )
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
RecipesConfigStep.defaultProps = {
    isEditing: false,
    recipe: {
        ShortName: "",
        Description: "",
        ClassificationId: 0,
        PresentationId: 0,
        FlavorTypeId: 0,
        RecipeTypeId: 0,
        IVAApply: true,
        KiwiId: 0,
        MilkTypeId: 0,
        GrainTypeId: 0,
        Active: true,
        MobileApply: true,
        WelcomeGiftApply: true,
        ClassificationSatId: 0,
        CodeSatId: 0,
        Details: [],
        GiftWelcomeActive: true
    }
}

export default RecipesConfigStep;