import React from 'react';
import './SaldoRegalo.css';

export default class SaldoRegaloGuideFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableHeaders: [
                'NumeroCliente',
                'FechaInicio',
                'FechaFinal',
                'Monto',
                'Motivo',
                'Estatus'
            ],
            menuOption: [
                'Cabeceras',
                'Primer Columna',
                'Segunda Columna',
                'Tercer Columna',
                'Cuarta Columna',
                'Quinta Columna',
                'Sexta Columna'
            ],
            selectedMenu: 0,
            menuOptionDescription: [
                [   // Sin selección
                    'Seleccionar una opción del menú para ver instrucciones'
                ],
                [   // Cabeceras
                    'Deben tener el nombre exacto (incluyendo mayúsculas y minúsculas)',
                    'No debe tener espacios al inicio o al final del texto',
                    'La primera columna debe tener uno de los siguientes nombres [CuentaLealtad] o [NumeroCliente]',
                    'La segunda columna debe poseer el nombre de [FechaInicio]',
                    'La tercera columna debe poseer el nombre de [FechaFinal]',
                    'La cuarta columna debe poseer el nombre de [Monto]',
                    'La quinta columna debe poseer el nombre de [Motivo]',
                    'La sexta columna debe poseer el nombre de [Estatus]'
                ],
                [   // Primer Columna
                    'No debe tener espacios al inicio o al final del texto',
                    'Los campos deben ser numéricos',
                    'Los campos deben ser mayores a cero',
                    'La cabecera puede tener el nombre [NumeroCliente] o [CuentaLealtad]',
                    'Si es [CuentaLealtad] los campos deben tener el formato 12419xxxxxxxxxx donde x es un número'
                ],
                [   // Segunda Columna
                    'No debe tener espacios al inicio o al final del texto',
                    'Debe contener una fecha con el formato día/mes/año',
                    'Debe tener una fecha mayor al día actual',
                    'Debe ser menor a FechaInicio',
                ],
                [   // Tercer Columna
                    'No debe tener espacios al inicio o al final del texto',
                    'Debe contener una fecha con el formato día/mes/año',
                    'Debe tener una fecha mayor al día actual',
                    'Debe ser mayor a FechaInicio',
                ],
                [
                    // Cuarta Columna
                    'No debe tener espacios al inicio o al final del texto',
                    'Los campos deben ser numéricos',
                    'Los campos deben ser mayores a cero'
                ],
                [
                    // Quinta Columna
                    'No debe tener espacios al inicio o al final del texto',
                    'Debe ser texto mínimo 4 caracteres y como máximo 30',
                    'No debe de estar compuesto de puros espacios vacíos'
                ],
                [
                    // Sexta Columna
                    'No debe tener espacios al inicio o al final del texto',
                    'Si se encuentra algún error se mostrará en esa columna',
                    'No se considera el contenido por lo tanto, puede estar vacía'
                ]

            ]
        };
    }

    leftMenuOptions = () => {
        return (
            <ul className="menu-list">
                {
                    this.state.menuOption.map((item, index) =>
                        <li className={this.changeColorSelectedOption(index + 1)}>
                            <a onClick={() => this.setState({ selectedMenu: index + 1 })}>{item}</a>
                        </li>
                    )
                }
            </ul>)
    }


    changeColorSelectedOption = (indexOption) => {
        return this.state.selectedMenu === indexOption ? "has-background-info" : "";
    }

    getSelectedMenuDescription = () => {
        return (
            <li>
                {
                    this.state.menuOptionDescription[this.state.selectedMenu].map(item => <h1 className="title is-6">{item}</h1>)
                }
            </li>)
    }


    getTableHeaders = () => {
        return <thead className="table-example">
            <tr className={this.changeColorSelectedOption(1)}>
                {
                    this.state.tableHeaders.map((header, index) => <th onClick={() => this.setState({ selectedMenu: index + 2 })} className={this.changeColorSelectedOption(index + 2)}>{header}</th>)
                }
            </tr>
        </thead>
    }

    render() {
        return (
            <div>
                <div className="columns">
                    <div className="column is-12">
                        <div className="table__wrapper">
                            <table className="table is-fullwidth">
                                {this.getTableHeaders()}
                                <tbody>
                                    <tr>
                                        <td>236800</td>
                                        <td>09/04/2019</td>
                                        <td>10/04/2019</td>
                                        <td>50</td>
                                        <td>Sin Motivo</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="columns is-vcentered">
                    <div className="column is-4">
                        <aside className="menu">
                            {this.leftMenuOptions()}
                        </aside>
                    </div>
                    <div className="column is-8 has-text-left">
                        <aside className="menu" >
                            <ul className="menu-list">
                                <li>
                                    <ul>
                                        {this.getSelectedMenuDescription()}
                                    </ul>
                                </li>

                            </ul>
                        </aside>
                    </div>
                </div>

            </div>

        )
    }
}