import { apiUrl }  from "../../constants";
import axios from 'axios';
import middleware from '../../middleware/auth';
import moment from "moment";

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class NotificacionAPI {

    static getNotificaciones(name = "", page = 1){
        return middleware.hasAuth(() => request.get(`Notification?name=${name}&page=${page}`));
    }

    static getSegmentaciones(){
        return middleware.hasAuth(() => request.get(`/Notification/Segmentations/`));
    }

    static getNotification(id){
        return middleware.hasAuth(() => request.get(`Notification/${id}`));
    }

    static updateNotification(body){
        return middleware.hasAuth(() => request.put(`Notification/`, body));
    }

    static saveNotification(body){
        return middleware.hasAuth(() => request.post(`Notification/`, body));
    }

    static changeNotificationStatus(id, value){
        return middleware.hasAuth(() => request.post(`Notification/Active`, {NotificationId: id, Status: value}));
    }

    static uploadImage(file){
        var fd = new FormData();
        fd.append("Image", file);
        return middleware.hasAuth(() => request.post(`/Notification/Upload/Image/`, fd));
    }

    static uploadImageBySegment(file, segment){
        var fd = new FormData();
        fd.append("Image", file);
        return middleware.hasAuth(() => request.post(`/Notification/Upload/Image/${segment}`, fd));
    }

    static getNotificationTypes() {
        return middleware.hasAuth(() => request.get(`/Notification/Types/`));
    }

    static getComparators(){
        return middleware.hasAuth(() => request.get(`/Notification/FilterTypes/`));
    }

    static getCategoriesByType(typeId){
        return middleware.hasAuth(() => request.get(`/Notification/Categories/${typeId}`));
    }

    static getBalanceTypes(){
        return middleware.hasAuth(() => request.get(`/Notification/BalanceTypes`));
    }

    static getVisitPeriods(){
        return middleware.hasAuth(() => request.get(`/Notification/VisitPeriodsTime`));
    }

    static getActions(){
        return middleware.hasAuth(() => request.get(`/Notification/Actions`));
    }

    static getScreens(){
        return middleware.hasAuth(() => request.get(`/Notification/Screens`));
    }

    static getScreensBySegment(segment){
        return middleware.hasAuth(() => request.get(`/Notification/Screens/${segment}`));
    }

    static getTags(category){
        return middleware.hasAuth(() => request.get(`/Notification/Tag/${category}`));
    }

    static deleteNotification(id){
        return middleware.hasAuth(() => request.delete(`/Notification/${id}`));
    }

    // Agenda
    static getEvents(starDate, endDate){
        let start = moment(starDate).format('YYYY-MM-DD')
        let end = moment(endDate).format('YYYY-MM-DD')
        return middleware.hasAuth(() => request.get(`/Notification/Schedule/Calendar?dateStart=${start}&dateEnd=${end}`));
    }

    static getNotificationEvents(notificationId, page = 1){
        return middleware.hasAuth(() => request.get(`/Notification/${notificationId}/Schedule?page=${page}`));
    }

    static changeNotificationEventStatus(notifScheduleId, status){
        return middleware.hasAuth(() => request.post(`/Notification/Schedule/${notifScheduleId}`, {NotificationScheduleId: notifScheduleId, Active: status}));
    }

    static getNotificationEventsHistory(notificationId, page = 1){
        return middleware.hasAuth(() => request.get(`/Notification/${notificationId}/History?page=${page}`));
    }

    static getFrequencyEventTypes(){
        return middleware.hasAuth(() => request.get(`/Notification/Schedule/Types`));
    }

    static getFrequentTypes(){
        return middleware.hasAuth(() => request.get(`/Notification/Schedule/FrequentTypes`));
    }

    static getFrequentMonthlyTypes(){
        return middleware.hasAuth(() => request.get(`/Notification/Schedule/FrequentTypes/Monthly`));
    }

    static ScheduleUniqueEvent(body){
        return middleware.hasAuth(() => request.post(`/Notification/Schedule/UniqueEvent`, body));
    }

    static ScheduleDailyEvent(body){
        return middleware.hasAuth(() => request.post(`/Notification/Schedule/FrequentEvent/Daily`, body));
    }

    static ScheduleWeeklyEvent(body){
        return middleware.hasAuth(() => request.post(`/Notification/Schedule/FrequentEvent/Weekly`, body));
    }

    static ScheduleMonthlyEvent(body){
        return middleware.hasAuth(() => request.post(`/Notification/Schedule/FrequentEvent/Monthly`, body));
    }

    static SendTestNotification(id, identifier){
        return middleware.hasAuth(() => request.post(`/Notification/${id}/Schedule/Test/${identifier}`));
    }

    static deleteEvent(id){
        return middleware.hasAuth(() => request.delete(`/Notification/Schedule/${id}`));
    }
}