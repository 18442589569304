import React from 'react';
import NuevoPrefijoCampanaForm from './components/NuevoPrefijoCampanaForm';

function NuevoPrefijoCampana(props){

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="card-header-title is-size-4 center-text">
                        <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                            <i className="fa fa-arrow-left" style={{cursor: "pointer", color: "#000000" }} onClick={() => {
                                props.history.push({
                                    pathname: '/app/promociones',
                                    state: props.location.state ? {currentPage: props.location.state.currentPage || 0, name: props.location.state.name || ""} : {currentPage: 1, name: ""}
                                });
                                }}></i>
                        </span>
                        <p style={{marginLeft:50,marginRight:50, wordBreak:'break-word'}}>{"Asignar prefijo a campaña"}</p>
                    </div>
                </div>
                <div className="card-content">
                    <NuevoPrefijoCampanaForm
                        {...props}
                        id={props.match.params.id}
                    />
                </div>
            </div>
        </div>
    )
}

export default NuevoPrefijoCampana;