import {apiUrl} from '../constants';
import axios from 'axios';
import { format } from 'date-fns';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class ComunicadosVPAPI {

    static GetComunicadosData(tituloParam,estatusParam,drivesParam){
        return request.post('ComunicadoVP/GetComunicados',{ Titulo: tituloParam, Estatus: estatusParam, Drives: drivesParam });
    }

    static GetMaxComunicadosDrives(fechaInicio,fechaFin,drives){
        return request.post('ComunicadoVP/GetMaxComunicadosDrives',{ FechaInicio: fechaInicio, FechaFin: fechaFin, Drives: drives });
    }

    static CreateComunicadoData(comunicadoData, imagen){
        let fd = new FormData();
        imagen && fd.append("Image", imagen);
        fd.append("modelo", JSON.stringify(comunicadoData));
        // console.log("El valor de comunicadoData >> ", comunicadoData);
        return request.post('/ComunicadoVP/NewComunicado', fd);
    }

    static UpdateComunicadoData(comunicadoData, imagen){
        let fd = new FormData();
        imagen && fd.append("Image", imagen);
        fd.append("modelo", JSON.stringify(comunicadoData));
        // console.log("El valor de actualizar comunicadoData >> ", comunicadoData);
        return request.put('/ComunicadoVP/UpdateComunicado', fd);
    }

    static UpdateEncComunicado(comunicadoData){
        return request.put('/ComunicadoVP/UpdateEncComunicado', comunicadoData);
    }

    static GetDrivesComunicado(idComunicado){
        return request.get('ComunicadoVP/GetDrivesComunicado?idComunicado=' + idComunicado);
    }

    static GetActiveCaffenios(){
        return request.get('ComunicadoVP/GetCaffeniosComunicados');
    }

    static GetPosiciones(){
        let posiciones  = [
            {
                label: "Al inicio",
                value: 1
            },
            {
                label: "Al mitad de turno",
                value: 2
            },
            {
                label: "Al final",
                value: 3
            }
        ]

        return posiciones;
    }

    static FormatDate(strDate){
        return format(Date.parse(strDate),"dd/MM/yyyy");
    }

    static GetEstatus(comunicado){
        let now = new Date(Date.now());
        now.setHours(0,0,0,0);
        let ini = new Date(comunicado.FechaInicio);
        let fin = new Date(comunicado.FechaFin);

        if(now.getTime() > fin.getTime()){
            return "Obsoleto";
        }
        else if( now.getTime() < ini.getTime()){
            return "Programado";
        }
        else{
            return "Vigente";
        }
    }

    static Estatus = [
        {
            value : 1,
            label : "Obsoleto"
        },
        {
            value : 2,
            label : "Programado"
        },
        {
            value : 3,
            label : "Vigente"
        }
    ]
}
