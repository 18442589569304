import * as React from 'react';
import BuscarSegmentacion from './components/BuscarSegmentacion';
import TablaSegmentaciones from './components/TablaSegmentaciones';
import SegmentacionAPI from '../../../services/Caffenio App/Segmentacion';
import Modal from '../../util/modal/Modal';
import { toast } from 'react-toastify';
import Field from '../../util/field/Field';
import * as moment from 'moment';

import Loading from '../../util/loading/Loading';
import AcceptModal from '../../util/acceptModal/AcceptModal';
import createHistory from 'history/createBrowserHistory'



export default class Segmentaciones extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            segmentaciones: [],
            currentPage: 1,
            totalPages: 1,
            totalSegmentaciones: 0,
            name: "",
            showDuplicateModal: false,
            duplicateName: "",
            duplicateNameValid: true,
            currentSeg: null,
            fileToDownload: null,
            downloading: false,
            confirmModal: false,
            tableLoading: false
        }
    }

    // Se obtienen las segmentaciones cuando se monta el componente
    componentWillMount(){
        let sstate = this.props.location.state;
        if(sstate){
            let state = {...this.state};
            state.name = sstate.name;
            state.currentPage = sstate.currentPage;
            this.setState(state, () => {
                this.getSegmentaciones(sstate.name, sstate.currentPage)
                const history = createHistory();
                // Si ya existe un parametro state en el history, se elimina.
                if (history.location.state) {
                    let state = { ...history.location.state };
                    delete state.currentPage;
                    delete state.name;
                    history.replace({ ...history.location, state });
                }
            })
            
        }else{
            this.getSegmentaciones();
        }
        
    }

    // Método para obtener las segmentaciones
    // @param text: texto para la búsqueda por nombre
    // @param page: la página que se mostrará
    getSegmentaciones = (text = "", page = 1) => {
        this.setState({tableLoading: true}, () => {
            SegmentacionAPI.getSegmentaciones(text, page).then(response => {
                let state = {...this.state};
                state.segmentaciones = response.data.Results;
                state.name = text;
                state.totalPages = response.data.TotalPages;
                state.currentPage = page;
                state.totalSegmentaciones = response.data.TotalResults;
                state.tableLoading = false;
                this.setState(state);
            }).catch(err => {
                toast.error(err.response.data.Message)
            })
        })
    }

    // Búsqueda de segmentaciones por nombre
    // @param text: texto para la búsqueda por nombre
    buscarSegmentaciones = (text) => {
        this.getSegmentaciones(text);
    }

    // Método para cambiarse de página
    // @param page: página que se mostrará
    goToPage = (page) => {
        this.getSegmentaciones(this.state.name, page);
    }

    // Método para ir al formulario de nueva segmentación.
    nuevaSegmentacion = () => {
        // Se agrego el atributo state al history para poder regresar a la misma página y búsqueda.
        this.props.history.push({
            pathname: '/app/segmentaciones/nueva',
            state: {currentPage: this.state.currentPage, name: this.state.name}
        });
    }

    // Método para ir al formulario de nueva segmentación por usuario.
    nuevaSegmentacionUsuario = () => {
        // Se agrego el atributo state al history para poder regresar a la misma página y búsqueda.
        this.props.history.push({
            pathname: '/app/segmentaciones/nuevaSeg',
            state: {currentPage: this.state.currentPage, name: this.state.name}
        });
    }

    // Método para ir al formulario de editar una segmentación 
    // @param id: identificador único de la segmentación
    editarSegmentacion = (id) => {
        // Se agrego el atributo state al history para poder regresar a la misma página y búsqueda.
        this.props.history.push({
            pathname: `/app/segmentaciones/editar/${id}`,
            state: {currentPage: this.state.currentPage, name: this.state.name}
        });
    }

    // Método para eliminar una segmentación
    eliminarSegmentacion = () => {
        SegmentacionAPI.removeSegmentation(this.state.currentSeg.IdSegmentation).then(response => {
            toast.success(response.data.Message)
            this.setState({confirmModal: false, currentSeg: null}, () => {
                this.getSegmentaciones(this.state.name, this.state.currentPage);
            })
        }).catch(err => toast.error(err.response.data.Message));
    }

    // Método para cambiar el estatus de una segmentación
    // @param id: identificador único de la segmentación
    // @param currentStatus: Estatus actual de la segmentación
    changeStatusSegmentacion = (id, currentStatus) => {
        SegmentacionAPI.changeStatus(id, currentStatus).then(response => {
            toast.success(response.data.Message);
            this.getSegmentaciones(this.state.name, this.state.currentPage);
        }).catch(err => toast.error(err.response.data.Message));
    }

    // Método para mostrar el modal para duplicar segmentación
    openDuplicateModal = (segmentacion) => {
        this.setState({showDuplicateModal: true, currentSeg: segmentacion});
    }

    // Método para duplicar una segmentación
    duplicate = () => {
        SegmentacionAPI.copySegmentacion(this.state.duplicateName, this.state.currentSeg.IdSegmentation).then(response => {
            toast.success("Segmentación duplicada correctamente.")
            this.setState({showDuplicateModal: false, currentSeg: null}, () => this.getSegmentaciones(this.state.name, this.state.currentPage))
        }).catch(err => {
            toast.error(err.response.data.Message)
        })
    }

    // Método para descargar el Excel con la información de los clientes de una segmentación.
    // @param id: identificador único de la segmentación
    // @param nombre: Nombre de la segmentación
    downloadFile = (id, nombre) => {
        this.setState({downloading: true}, () => {
            SegmentacionAPI.downloadClientsInfo(id).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let name = `REPORTE SEGMENTACION ${moment().format('DD MM YYYY h mm ss')} ${nombre}`;
                name = name.replace(/ /g,"_");
                link.setAttribute('download', `${name.toUpperCase()}.xlsx`);
                document.body.appendChild(link);
                link.click();
                this.setState({downloading: false});
            }).catch(err => {
                toast.error(err.response.data.Message)
            })
        })
    }

    render(){
        return (
            <div>
                <h1 className="title is-2">Segmentaciones</h1>
                <BuscarSegmentacion
                    nuevaSegmentacion={() => this.nuevaSegmentacion()}
                    nuevaSegmentacionUsuario={() => this.nuevaSegmentacionUsuario()}
                    search={(text) => this.buscarSegmentaciones(text)}
                    name={this.state.name}
                />
                <br/>
                <TablaSegmentaciones 
                    segmentaciones={this.state.segmentaciones}
                    totalPages={this.state.totalPages}
                    currentPage={this.state.currentPage}
                    changeStatus={(id, val) => this.changeStatusSegmentacion(id,val) }
                    goToPage={this.goToPage}
                    edit={(id) => this.editarSegmentacion(id)}
                    duplicate={this.openDuplicateModal}
                    delete={(seg) => this.setState({confirmModal: true, currentSeg: seg})}
                    downloadFile={this.downloadFile}
                    loading={this.state.tableLoading}
                />
                {this.state.showDuplicateModal && 
                <Modal
                    footer
                    footerButtons={                        
                        <div className="has-text-right modal-detalle-buttons">
                            <button className="button" onClick={() => this.setState({showDuplicateModal: false, duplicateNameValid: true, duplicateName: ""})}>Cancelar</button>
                            <button className="button is-success" disabled={this.state.duplicateName.length < 3} onClick={() => this.duplicate()}>Guardar</button>
                        </div>
                    }
                    cancel={() => this.setState({showDuplicateModal: false, duplicateName: "", duplicateNameValid: true})}
                    showModal={this.state.showDuplicateModal}
                    modalTitle={`Duplicar Segmentación`}
                >
                    <Field isHorizontal label="Nombre">
                        <input
                            type="text"
                            className={`input ${!this.state.duplicateNameValid && "is-danger"}` }
                            maxLength={50}
                            placeholder={`Nombre de la segmentación`}
                            value={this.state.duplicateName}
                            autoFocus={true}
                            onBlur={(e) => {
                                if(this.state.duplicateName.length == 0) this.setState({duplicateNameValid: false})
                            }}
                            onChange={(e) => {
                                let name = e.currentTarget.value;
                                name = name.replace(/\s{2,}/g, ' ');
                                this.setState({duplicateName: name, duplicateNameValid: name.length > 0});
                            }}
                        />
                    </Field>
                    {!this.state.duplicateNameValid && <p class="help is-danger">Nombre requerido</p>}
                </Modal>}
                {this.state.confirmModal &&
                    <AcceptModal 
                        modalTitle="Confirmar"
                        modalBody={<p>¿Está seguro que desea eliminar la segmentación?</p>}
                        isActive={this.state.confirmModal}
                        done={() => this.eliminarSegmentacion()}
                        cancel={() => this.setState({confirmModal: false, currentSeg: null})}
                    />
                }
                <Loading isFullscreen={true} isLoading={this.state.downloading} width='100px' height='100px'/>
            </div>
        )
    }
}