import React, { useEffect, useState } from 'react';
import useForm from '../../../../../util/hooks/useForm';
import Select from 'react-select';
import NotificacionDataForm from './components/NotificacionDataForm';
import DetalleDescriptionForm from './components/DetalleDescriptionForm';
import TipoNotificacionForm from './components/TipoNotificacionForm';
import InfoGeneralForm from './components/InfoGeneralForm';
import AccionNotificacionFrom from './components/AccionNotificacionForm';
import NotificacionAPI from '../../../../../services/Caffenio App/Notificacion';
import { toast } from 'react-toastify';

export default function NuevaNotificacionForm({history, location, segmentations, types, notification, setNotification}){
    const {inputs, setInput, errors, setError, replaceInputs , handleInputChange, handleSelectChange, handleSubmit} = useForm(() => {
        saveNotification();
    });

    const [working, setWorking] = useState(false);
    const [categories, setCategories] = useState([]);
    const [idSegment, setIdSegment] = useState(0);
    const [screens, setScreens] = useState([]);

    const saveNotification = async () => {
        if(!working){
            setWorking(true);
            let data = {...inputs};
            var imageUrl = inputs.urlImg;

            if(data.image){
                let res = await NotificacionAPI.uploadImageBySegment(data.image, inputs.segmentation.segment)
                //let res = await NotificacionAPI.uploadImage(data.image);
                imageUrl = res.data.ImagePath
            }

            let body = {
                NotificationId: inputs.NotificationId,
                Name: data.Name,
                IdSegmentation: data.segmentation.value,
                NotificationTypeId: data.type.value,
                NotificationCategoryId: data.category.value,
                Title: data.Title,
                Description: data.Description,
                NotificationActionId: data.action.value,
                Link: data.link && [1,2].includes(data.action.value) ? data.link : null,
                NotificationScreenId: data.screen && data.action.value === 1 ? data.screen.value : null,
                DetailDescription: data.action.value === 3 ? data.detailDescription : null,
                ImagePath: data.action.value === 3 ? imageUrl : null,
                VisitInfo: data.VisitInfo ? ({
                    NotificationFilterTypeId: data.VisitInfo.NotificationFilterTypeId.value,
                    Visits: data.VisitInfo.Visits,
                    NotificationVisitPeriodTimeId: data.VisitInfo.NotificationVisitPeriodTimeId.value
                }) : null,
                BalanceInfo: data.BalanceInfo ? ({
                    NotificationFilterTypeId: data.BalanceInfo.NotificationFilterTypeId.value,
                    Amount: data.BalanceInfo.Amount,
                    NotificationBalanceTypeId: data.BalanceInfo.NotificationBalanceTypeId.value
                }) : null
            }

            var response = null;
            if(body.NotificationId){
                response = NotificacionAPI.updateNotification(body);
            }else{
                response = NotificacionAPI.saveNotification(body);
            }
            response.then(res => {
                if (body.NotificationId) toast.success("Datos actualizados correctamente.");
                else toast.success("Notificación creada.");
                history.push({
                    pathname: '/app/notificaciones',
                    state: location.state ? {currentPage: location.state.currentPage || 0, name: location.state.name || ""} : {currentPage: 1, name: ""}
                });
            }).catch(err => {
                toast.error(err.response.data.Message)
            }).finally(() => setWorking(false))
        }
    }

    useEffect(() => {
        if(notification){
            setInput('NotificationId', notification.NotificationId);
            setInput('Name', notification.Name)
            setInput('segmentation', segmentations.map(s => ({value: s.IdSegmentation, label: s.Name, segment: s.NombreSegmento})).find(s => s.value === notification.IdSegmentation));
        }
    }, [segmentations])

    useEffect(() => {
        //Aquí hacer el cambio de la pantalla
        (async () => {
            if(inputs.segmentation){
                let res = await NotificacionAPI.getScreensBySegment(inputs.segmentation.segment);
                inputs.screen = res.data
                setScreens(inputs.screen);
                console.log(inputs.screen);
            }else{
                setIdSegment(0)
            }
        })();

    }, [inputs.segmentation])



    useEffect(() => {
        (async () => {
            if(inputs.type && inputs.type.value){
                let res = await NotificacionAPI.getCategoriesByType(inputs.type.value);
                setCategories(res.data);
            }else{
                setInput('category', null);
                setInput('action', null);
            }
        })();
    }, [inputs.type])

    useEffect(() => {
        if(notification && !inputs.category){
            let selectedType = types.map(t => ({value: t.NotificationTypeId, label: t.Name})).find(t => t.value === notification.NotificationTypeId);
            setInput('type', selectedType);
            if(categories.length > 0){
                let selectedCategorie = categories.map(c => ({value: c.NotificationCategoryId, label: c.Name})).find(c => c.value === notification.NotificationCategoryId);
                setInput('category', selectedCategorie);
            }
        }
    }, [inputs.segmentation, categories])

    useEffect(() => {
        if(notification && inputsAreValid()){
            setInput('NotificationId', notification.NotificationId);
            setInput('urlImg', notification.ImagePath);
            setNotification(null);
        }
    },[inputs])

    const inputsAreValid = () => {
        const infoGeneralValid = inputs.Name && inputs.segmentation
        const tipoNotificacionValid = inputs.type && inputs.category 
        const extraTipoNotifDataValid = inputs.category && (
            inputs.category.value == 1 && 
            inputs.BalanceInfo &&
            inputs.BalanceInfo.NotificationFilterTypeId &&
            inputs.BalanceInfo.Amount &&
            inputs.BalanceInfo.NotificationBalanceTypeId
        || 
            inputs.category.value == 3 &&
            inputs.VisitInfo &&
            inputs.VisitInfo.NotificationFilterTypeId &&
            inputs.VisitInfo.Visits &&
            inputs.VisitInfo.NotificationVisitPeriodTimeId
        || inputs.category.value == 2
        || inputs.category.value && inputs.category.value > 3
        )
        const accionNotifValid = inputs.action && (
            inputs.action.value == 1 &&
            inputs.screen &&
            inputs.link
        ||
            inputs.action.value == 2 &&
            inputs.link
        ||
            inputs.action.value == 3 &&
            inputs.detailDescription && inputs.detailDescription.length > 3 &&
            (inputs.image || inputs.urlImg)

        )
        const dataNotifValid = inputs.Title && inputs.Description
        return infoGeneralValid && tipoNotificacionValid && extraTipoNotifDataValid && accionNotifValid && dataNotifValid && Object.values(errors).filter(c => c).length === 0;
    }

    return (
        <form onSubmit={handleSubmit} className="has-text-left" style={{width: '100%'}}>
            <div className="columns is-multiline">
                <div className="column is-12">
                    <InfoGeneralForm segmentaciones={segmentations} inputs={inputs} errors={errors} handleSelectChange={handleSelectChange} handleInputChange={handleInputChange}/>
                </div>

                {inputs.segmentation && <div class="is-divider" style={{width: "100%"}}/>}

                {inputs.segmentation && <TipoNotificacionForm categories={categories} types={types} setInput={setInput} notification={notification} inputs={inputs} errors={errors} handleSelectChange={handleSelectChange}  handleInputChange={handleInputChange}/>}

                {inputs.category && <div className="is-divider" style={{width: '100%'}}/>}                

                {inputs.category && <div className="column is-12">
                    <NotificacionDataForm disabled={!inputs.category} notification={notification} inputs={inputs} errors={errors} setInput={setInput} handleInputChange={handleInputChange} />
                </div>}

                {inputs.category && <div className="is-divider" style={{width: '100%'}}/>}
                
                <div className="column is-12">
                    <AccionNotificacionFrom setInput={setInput} setError={setError} notification={notification} inputs={inputs} errors={errors} handleSelectChange={handleSelectChange} handleInputChange={handleInputChange} screens={screens}/>
                </div>

                {inputs.action && inputs.action.value === 3 &&<div className="column is-12">
                    <DetalleDescriptionForm notification={notification} inputs={inputs} errors={errors} setError={setError} setInput={setInput} handleInputChange={handleInputChange} />
                </div>}


                
            </div>
            <div className="column is-12">
                <div className="field is-grouped is-grouped-right">
                    <p className="control">
                        <button
                            className="button"
                            type="button"
                            onClick={() => {
                                history.push({
                                    pathname: '/app/notificaciones',
                                    state: location.state ? {currentPage: location.state.currentPage || 0, name: location.state.name || ""} : {currentPage: 1, name: ""}
                                });
                            }}>
                            Regresar
                        </button>
                    </p>
                    
                    <p className="control">
                        <button
                            type="submit"
                            className="button is-success"
                            disabled={!inputsAreValid()}
                        >
                            Guardar notificación
                        </button>
                    </p>
                </div>
            </div>
        </form>
    )
}

