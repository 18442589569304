import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';
import { stringify } from "querystring";

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
});

/**
 * Servicio para el recurso de sucursales
 */
export default class SucursalesAPI {
    /**
     * Obtiene a las sucursales.
     * @returns Promise
     */
    static getSucursales() {
        return middleware.hasAuth(() => request.get(`/Sucursales/`));
    }

    /**
     * Obtiene todos los datos de las sucursales.
     * @returns Promise
     */
    static getAllSucursales(params) {
        return middleware.hasAuth(() => request.get(`/AllSucursales?${stringify(params)}`));
    }

    /**
     * Actualización de una sucursal.
     * @param {Object} sucursal - Datos de la sucursal
     * @returns Promise
     */
    static updateSucursal( sucursal ) {
        return middleware.hasAuth(() => request.put('/AllSucursales/', sucursal));
    }

    /**
     * Creación de una sucursal.
     * @param {Object} sucursal - Datos de la sucursal
     * @returns 
     */
    static createSucursal( sucursal ) {
        return middleware.hasAuth(() => request.post('/AllSucursales/', sucursal));
    }
}