import React from 'react';
import Select from 'react-select';

export default function InfoGeneralForm({segmentaciones, inputs, errors, handleInputChange, handleSelectChange}){
    return (
        <div className="columns">
            <div className="column is-6">
                <label className="label">Nombre</label>
                <div className={`control`}>
                    <input
                        type="text"
                        name="Name"
                        className={`input ${errors.Name && "is-danger"}`}
                        maxLength={50}
                        placeholder={`Nombre de la notificación`}
                        value={inputs.Name}
                        autoFocus={true}
                        onChange={(e) => handleInputChange(e, true)}
                        required
                    />
                </div>
                {errors.Name && <p className="help title has-text-left is-6 is-danger">Nombre requerido</p>}
            </div>
            <div className="column is-6">
                <label className="label">Segmentación</label>
                <div className={`control`}>
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"segmentation"}
                        value={inputs.segmentation}
                        isClearable
                        onChange={(value, event) => handleSelectChange(value, event, true)}
                        noOptionsMessage={() => "No hay opciones"}
                        options={segmentaciones.map(s => {return {value: s.IdSegmentation, label: s.Name, segment: s.NombreSegmento}})}
                    />
                </div>
                {errors.segmentation && <p className="help title has-text-left is-6 is-danger">Segmentación requerida</p>}
            </div>
        </div>
    )
}