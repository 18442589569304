import React, { Component } from 'react';
import Modal from '../../../util/modal/Modal';
import Dropdown from '../../../util/dropdown/Dropdown';
import Field from '../../../util/field/Field';
//import { toast } from 'react-toastify';
import Loading from '../../../util/loading/Loading';
import RecipeDetails from './RecipeDetails';
import NewRecipeDetail from './NewRecipeDetail';
import { toast } from 'react-toastify';
import AcceptModal from '../../../util/acceptModal/AcceptModal';
import './recipeFormStyle.css';
import WarehouseAPI from '../../../../services/Warehouse';
import to from '../../../../util/to'
import RecipeToppings from './RecipeToppings';
import Select from 'react-select';
import RecipeDrivesDetail from './RecipeDrivesDetail';

class RecipeForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recipe: { ...this.props.recipe },
            isEditing: false,
            classifications: { ...this.props.recipe },
            flavors: { ...this.props.flavors },
            grains: { ...this.props.grains },
            milks: { ...this.props.milks },
            presentations: { ...this.props.presentations },
            recipeTypes: { ...this.props.recipeTypes },
            satClassifications: { ...this.props.satClassifications },
            satCodes: { ...this.props.satCodes },
            isLoading: false,
            detailModal: false,
            removeModal: false,
            details: [],
            units: [],
            idRecipeDetail: null,
            mostrarDrivesModal: false,
            recipeDetail: {},
            toppingModal: false,
            // Estados para el modal de drives
            Caffenios: { ...this.props.Caffenios },
            optionState: Object.values({ ...this.props.optionState }),
            optionCity: Object.values({ ...this.props.optionCity }),
            optionDrive: [],
            selectedOption: [],
            selectedOptionC: [],
            selectedOptionD: [],
        }
        //console.log(this.props);
    }

    componentDidMount() {
        this.getDrives()
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.recipe.RecipeId && this.props.recipe.RecipeId) {
            let state = { ...this.state };
            state.recipe = { ...this.props.recipe }
            this.setState(state);
        }
    }

    handleDropdownChange = (index, itemName, propName) => {

        let item = this.props[itemName][index];
        let state = { ...this.state };
        state.recipe[propName] = index === 0 ? 0 : item[propName];
        this.setState(state/*, () => console.log(this.state.recipe)*/);
    }

    // Método para realizar los cambios en el estado del campo correspondiente a un <input>
    handleChange = (e) => {
        let state = { ...this.state };
        state.recipe[e.target.name] = e.target.value;
        this.setState(state);

        if (e.target.name == "ShortName") {
            let diferencia = 30 - e.target.value.length;
            if (diferencia == 30) {
                document.getElementById("labelShortName").style.display = "none";
            } else {
                document.getElementById("labelShortName").style.display = "block";
                document.getElementById("labelShortName").innerHTML = "Te quedan " + diferencia.toString() + " caracteres para el nombre corto";
            }
        }
    }

    onKeyPressNumber(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/'/.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
            event.preventDefault();
    }

    /** Quita articulo inventario de todos los drives */
    removeDetail = () => {
        let state = { ...this.state };
        let index = state.idRecipeDetail;
        let itemToRemove = state.recipe.DetailsGroupBy[index];

        state.recipe.DetailsGroupBy.splice(index, 1);

        if(itemToRemove && itemToRemove.driveList){
            itemToRemove.driveList.forEach(element => {
                state.recipe.Details = state.recipe.Details.filter(d => d.RecipeDetailId !== element.RecipeDetailId);
            });
        }        

        state.removeModal = false;
        state.idRecipeDetail = null;
        this.setState(state);
        toast.success("Detalle eliminado");
    }

    /** Quita articulo inventario de los drive's seleccionados */
    removeDriveDetail = (recipeDetailId) => {
    
        let state = { ...this.state };
        state.recipe.Details = state.recipe.Details.filter(d => d.RecipeDetailId !== recipeDetailId);
        state.recipe.DetailsGroupBy = state.recipe.DetailsGroupBy.map(group =>{
            group.driveList = group.driveList.filter(dl => dl.RecipeDetailId !== recipeDetailId);

            return group;
        })
        this.setState(state);        
    }

    /** Función para cambiar la cantidad de un articulo inventario a la receta  */
    quantityChanged = (nuevaCantidad) => {
        let state = { ...this.state };
        let index = state.idRecipeDetail;
        let itemsToUpdate = state.recipe.DetailsGroupBy[index];

        state.recipe.DetailsGroupBy[index].Amount = nuevaCantidad;
        if (nuevaCantidad != null && nuevaCantidad.trim().length > 0 && parseFloat(nuevaCantidad.trim()) > 0) {    
            if(itemsToUpdate && itemsToUpdate.driveList){
                state.recipe.Details.map(d => {
                    if (itemsToUpdate.driveList.some(i => i.RecipeDetailId === d.RecipeDetailId)) {
                        d.Amount = nuevaCantidad
                    }
                    return d;
                });    
            }
            
            state.idRecipeDetail = null;
            this.setState(state);  
            toast.success("Cantidad modificada");              
        } else if (nuevaCantidad === null || nuevaCantidad.trim().length === 0){
            this.setState(state);  
        }
    }

    /*Funcion para obtenr las ciudades que se pone en el set stae */
    getDrives = async () => {
        let err, caffenios;
        [err, caffenios] = await to(WarehouseAPI.getCaffenios());

        this.setState({
            optionDrive: this.select_Drive(caffenios),
        })
    }

    select_state(Caffenios) { // Llenar el combo 'Estados' con todas las opciones 
        var newArray = [];
        let OptionSelect = [];
        var lookupObject = {};

        Caffenios.map((item) => {
            OptionSelect.push({ value: item.StateId, label: item.State });
        });

        for (var i in OptionSelect) {
            lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    select_city(Caffenios) { // Llenar el combo 'Ciudades' con todas las opciones 
        var newArray = [];
        let OptionSelectCity = [];
        var lookupObject = {};

        Caffenios.map((item) => {
            OptionSelectCity.push({ value: item.Municipalityid, label: item.Municipality });
        });

        for (var i in OptionSelectCity) {
            lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    select_Drive(Caffenios) { // Llenar el combo 'Drives' con todas las opciones 
        var newArray = [];
        let OptionSelectDrive = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectDrive.push({ value: item.idCaffenio, label: item.NombreNuevo });
        });

        for (var i in OptionSelectDrive) {
            lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    changeSelectEstado = (obj) => {

        let state = { ...this.state }
        state["selectedOption"] = obj;
        let idEstado = obj.map(estado => { return estado.value });
        let objCaffenios = Object.values(state.Caffenios);
        let listaCiudades = [];
        let listaDrives = [];

        // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
        objCaffenios.map(item => {
            for (var i = 0; i < idEstado.length; i++) {
                if (item.StateId === idEstado[i]) {
                    listaCiudades.push({ "value": item.Municipalityid, "label": item.Municipality });
                }
            }
        })
        //Se eliminan las ciudades duplicadas
        let eliminarDuplicados = this.eliminarObjDuplicados(listaCiudades, "value");
        //Se obtiene un arreglo con los id de las ciudades
        let idCiudad = eliminarDuplicados.map(ciudad => { return ciudad.value });

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item => {
            for (var i = 0; i < idCiudad.length; i++) {
                if (item.Municipalityid === idCiudad[i]) {
                    listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                }
            }
        })

        //Se limpian los combos 'Ciudades' y 'Drives'
        state["selectedOptionC"] = [];
        state["selectedOptionD"] = [];

        //Se valida que haya una opción seleccionada en combo 'Estados'
        if (obj.length === 0) {
            state["optionCity"] = this.select_city(Object.values(state.Caffenios));
            state["optionDrive"] = this.select_Drive(Object.values(state.Caffenios));

        } else {
            state["optionCity"] = eliminarDuplicados;
            state["optionDrive"] = listaDrives;
        }
        this.setState(state);
        /*
        if(state["selectedOptionD"].length===0){
            driveIds = state["optionDrive"].map(obj => {
                return obj.value;
            })
        } else{
            driveIds = state["selectedOptionD"].map(obj => {
                return obj.value;
            })
        }*/

        //this.setState(state, () => this.FilterProcess());

    }

    changeSelectCiudad = (obj) => {
        let state = { ...this.state }
        let idCiudad = obj.map(ciudad => { return ciudad.value });
        let objCaffenios = Object.values(state.Caffenios);
        let listaDrives = [];

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item => {
            for (var i = 0; i < idCiudad.length; i++) {
                if (item.Municipalityid === idCiudad[i]) {
                    listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                }
            }
        })

        //Se limpia el combo 'Drives'
        state["selectedOptionD"] = [];

        //Se valida que haya una opción seleccionada en combo 'Ciudades'
        if (obj.length > 0) {
            state["selectedOptionC"] = obj;
            state["optionDrive"] = listaDrives;
            //this.setState(state, () => this.FilterProcess());
        } else {
            this.changeSelectEstado(state["selectedOption"]);
        }
        this.setState(state);

    }

    changeSelectDrive = (obj) => {
        let state = { ...this.state };
        let Caffenios = Object.values(state.Caffenios);
        //Valida cada select (Estados,Ciudades,Drives) y realiza la acción correspondiente
        if (obj.length > 0) {
            state["selectedOptionD"] = obj;
            //this.setState(state, () => this.FilterProcess());
        } else if (state["selectedOptionC"].length === 0) {
            this.changeSelectEstado(state["selectedOption"]);
            state["selectedOptionD"] = [];
        } else if (state["selectedOptionC"].length === 0 && state["selectedOption"].length === 0) {
            this.select_Drive(Caffenios);
            this.select_Drive(Caffenios);
            this.select_Drive(Caffenios);
            state["selectedOptionD"] = [];
        } else {
            this.changeSelectCiudad(state["selectedOptionC"]);
            state["selectedOptionD"] = [];
        }
        this.setState(state)
    }

    eliminarObjDuplicados = (arr, prop) => {
        var nuevoArray = [];
        var lookup = {};

        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }

        for (i in lookup) {
            nuevoArray.push(lookup[i]);
        }

        return nuevoArray;
    }

    render() {
        return (
            <div>
                <div className="columns is-multiline">
                    <div className="column is-6">
                        <Field label="Descripción">
                            <input className="input" maxLength="80" name="Description" value={this.state.recipe.Description} onChange={(e) => this.handleChange(e)} />
                        </Field>
                    </div>
                    <div className="column is-6">
                        <label htmlFor="ShortName" id="labelShortName"></label>
                        {/* <Field label="Nombre Corto">
                            <input className="input" maxLength="30" name="ShortName" value={this.state.recipe.ShortName} onChange={(e) => this.handleChange(e)}/>
                        </Field> */}
                    </div>
                    <div className="column is-4">
                        <label className="label">Familia</label>
                        <div className="control">
                            <Select
                                options={this.props.classifications.sort((itemA, itemB) => itemA.Name === itemB.Name ? 0 : itemA.Name > itemB.Name ? 1 : -1)}
                                value={this.props.classifications.find(clas => clas.ClassificationId === this.state.recipe.ClassificationId)}
                                getOptionLabel={option => option.Name}
                                getOptionValue={option => option.ClassificationId}
                                noOptionsMessage={() => "No hay opciones"}
                                onChange={(clas) => this.handleDropdownChange(this.props.classifications.findIndex(item => item.ClassificationId === clas.ClassificationId), "classifications", "ClassificationId")}
                            />
                        </div>
                    </div>
                    <div className="column is-4">
                        <label className="label">Tipo de receta</label>
                        <div className="control">
                            <Select
                                options={this.props.recipeTypes.sort((itemA, itemB) => itemA.Description === itemB.Description ? 0 : itemA.Description > itemB.Description ? 1 : -1)}
                                onChange={(value) => this.handleDropdownChange(this.props.recipeTypes.findIndex(item => item.RecipeTypeId === value.RecipeTypeId), "recipeTypes", "RecipeTypeId")}
                                id="RecipeTypeId"
                                value={this.props.recipeTypes.find(item => item.RecipeTypeId === this.state.recipe.RecipeTypeId)}
                                getOptionLabel={option => option.Description}
                                getOptionValue={option => option.RecipeTypeId}
                                noOptionsMessage={() => "No hay opciones"}
                            />
                        </div>
                    </div>
                    <div className="column is-4">
                        <label className="label">Tipo de sabor</label>
                        <div className="control">
                            <Select
                                options={this.props.flavors.sort((itemA, itemB) => itemA.Name === itemB.Name ? 0 : itemA.Name > itemB.Name ? 1 : -1)}
                                onChange={(value) => this.handleDropdownChange(this.props.flavors.findIndex(item => item.FlavorTypeId === value.FlavorTypeId), "flavors", "FlavorTypeId")}
                                id="FlavorTypeId"
                                value={this.props.flavors.find(item => item.FlavorTypeId === this.state.recipe.FlavorTypeId)}
                                getOptionLabel={option => option.Name}
                                getOptionValue={option => option.FlavorTypeId}
                                noOptionsMessage={() => "No hay opciones"}
                            />
                        </div>
                    </div>
                    <div className="column is-4">
                        <label className="label">Tipo de grano</label>
                        <div className="control">
                            <Select
                                options={this.props.grains.sort((itemA, itemB) => itemA.Description === itemB.Description ? 0 : itemA.Description > itemB.Description ? 1 : -1)}
                                onChange={(value) => this.handleDropdownChange(this.props.grains.findIndex(item => item.GrainTypeId === value.GrainTypeId), "grains", "GrainTypeId")}
                                id="GrainTypeId"
                                value={this.props.grains.find(item => item.GrainTypeId === this.state.recipe.GrainTypeId)}
                                getOptionLabel={option => option.Description}
                                getOptionValue={option => option.GrainTypeId}
                                noOptionsMessage={() => "No hay opciones"}
                            />
                        </div>
                    </div>
                    <div className="column is-4">
                        <label className="label">Tipo de leche</label>
                        <div className="control">
                            <Select
                                options={this.props.milks.sort((itemA, itemB) => itemA.Description === itemB.Description ? 0 : itemA.Description > itemB.Description ? 1 : -1)}
                                onChange={(value) => this.handleDropdownChange(this.props.milks.findIndex(item => item.MilkTypeId === value.MilkTypeId), "milks", "MilkTypeId")}
                                id="MilkTypeId"
                                value={this.props.milks.find(item => item.MilkTypeId === this.state.recipe.MilkTypeId)}
                                getOptionLabel={option => option.Description}
                                getOptionValue={option => option.MilkTypeId}
                                noOptionsMessage={() => "No hay opciones"}
                            />
                        </div>
                    </div>
                    <div className="column is-4">
                        <label className="label">Presentación</label>
                        <div className="control">
                            <Select
                                options={this.props.presentations.sort((itemA, itemB) => itemA.Name === itemB.Name ? 0 : itemA.Name > itemB.Name ? 1 : -1)}
                                onChange={(value) => this.handleDropdownChange(this.props.presentations.findIndex(item => item.PresentationId === value.PresentationId), "presentations", "PresentationId")}
                                id="PresentationId"
                                value={this.props.presentations.find(item => item.PresentationId === this.state.recipe.PresentationId)}
                                getOptionLabel={option => option.Name}
                                getOptionValue={option => option.PresentationId}
                                noOptionsMessage={() => "No hay opciones"}
                            />
                        </div>
                    </div>
                </div>
                <div className="columns is-multiline">
                    <div className="column is-4">
                        <Field label="Aplica IVA">
                            <input
                                type="checkbox"
                                name="iva"
                                className="switch inputCenter"
                                checked={this.state.recipe.IVAApply}
                                onChange={() => { }}
                            />
                            <label htmlFor="iva" className="inputCenter" onClick={(e) => {
                                e.stopPropagation();
                                let state = { ...this.state };
                                state.recipe.IVAApply = !state.recipe.IVAApply;
                                this.setState(state);
                            }} />
                        </Field>
                    </div>
                    <div className="column is-4">
                        <Field label="Aplica en App Móvil">
                            <input
                                type="checkbox"
                                name="status"
                                className="switch"
                                checked={this.state.recipe.MobileActive}
                                onChange={() => { }}
                            />
                            <label htmlFor="status" className="inputCenter" onClick={(e) => {
                                e.stopPropagation();
                                let state = { ...this.state };
                                state.recipe.MobileActive = !state.recipe.MobileActive;
                                this.setState(state);
                            }} />
                        </Field>
                    </div>
                    <div className="column is-4">
                        <Field label="Aplica Regalo Bienvenida" className="has-text-centered">
                            <input
                                type="checkbox"
                                name="status"
                                className="switch"
                                checked={this.state.recipe.GiftWelcomeActive}
                                onChange={() => { }}
                            />
                            <label htmlFor="status" className="inputCenter" onClick={(e) => {
                                e.stopPropagation();
                                let state = { ...this.state };
                                state.recipe.GiftWelcomeActive = !state.recipe.GiftWelcomeActive;
                                this.setState(state);
                            }} />
                        </Field>
                    </div>
                </div>

                <br />
                <div className="columns is-multiline is-centered">
                    <div className="column is-6">
                        <label className="label">Clasificación</label>
                        <div className="control">
                            <Select
                                options={this.props.satClassifications.sort((itemA, itemB) => itemA.Description === itemB.Description ? 0 : itemA.Description > itemB.Description ? 1 : -1)}
                                onChange={(value) => this.handleDropdownChange(this.props.satClassifications.findIndex(item => item.ClassificationSatId === value.ClassificationSatId), "satClassifications", "ClassificationSatId")}
                                id="ClassificationSatId"
                                value={this.props.satClassifications.find(item => item.ClassificationSatId === this.state.recipe.ClassificationSatId)}
                                getOptionLabel={option => option.Description}
                                getOptionValue={option => option.ClassificationSatId}
                                noOptionsMessage={() => "No hay opciones"}
                            />
                        </div>
                    </div>
                    <div className="column is-6">
                        <label className="label">Código del SAT</label>
                        <div className="control">
                            <Select
                                options={this.props.satCodes.sort((itemA, itemB) => itemA.Description === itemB.Description ? 0 : itemA.Description > itemB.Description ? 1 : -1)}
                                onChange={(value) => {
                                    let recipe = { ...this.state.recipe };
                                    let index = this.props.satCodes.findIndex(item => item.Code === value.Code)
                                    recipe.CodeSatId = index === -1 ? 0 : this.props.satCodes[index].Code;
                                    this.setState({ recipe: recipe });
                                }}
                                value={this.props.satCodes.find(item => item.Code === this.state.recipe.CodeSatId)}
                                getOptionLabel={option => option.Description}
                                getOptionValue={option => option.Code}
                                noOptionsMessage={() => "No hay opciones"}
                            />
                        </div>
                    </div>
                    <div className="column is-12">
                        <h1 className="subtitle has-text-weight-bold">
                            Artículo inventario
                            &nbsp;
                            <a className="has-text-black" onClick={() => this.setState({ modalDrive: true })}>
                                <span className="icon is-small">
                                    <i className="fa fa-plus"></i>
                                </span>
                            </a>
                        </h1>
                    </div>
                    <div className="column is-10">
                        <RecipeDetails
                            details={this.state.recipe.DetailsGroupBy.filter(gp => gp.driveList && gp.driveList.length > 1)}
                            quantityChanged={(value, index) => {
                                /*let recipe = {...this.state.recipe};
                                recipe.Details[index].Amount = value;
                                this.setState({recipe: recipe});*/

                                this.setState({ idRecipeDetail: index }, () => this.quantityChanged(value));
                            }}
                            removeDetail={(recipe) => {
                                this.setState({ removeModal: true, idRecipeDetail: recipe })
                            }}
                            viewDrivesDetail={(recipe) => {
                                this.setState({ detailDrivesModal: true, recipeDetailDrives: recipe })
                            }}
                            mostrarDrives={true}
                            onMostrarDrives={(recipe) => {
                                this.setState({ mostrarDrivesModal: true, recipeDetail: recipe })
                            }}
                        />
                    </div>

                    {/* Modulo de toppings */}
                    <div className="column is-12">
                        <h1 className="subtitle has-text-weight-bold">
                            Toppings generales
                            &nbsp;
                            <a className="has-text-black" onClick={() => this.setState({ toppingModal: true })}>
                                <span className="icon is-small">
                                    <i className="fa fa-plus"></i>
                                </span>
                            </a>
                        </h1>
                    </div>
                    <div className="column is-10">
                        <RecipeToppings
                            details={this.state.recipe.Toppings}
                            quantityChanged={(value, index, labelQuantity) => {
                                let recipe = { ...this.state.recipe };
                                recipe.Toppings[index][labelQuantity] = value;

                                this.setState({ recipe: recipe });
                            }}
                            removeDetail={(recipe) => {
                                let state = { ...this.state };
                                state.recipe.Toppings.splice(recipe, 1);
                                this.setState(state);
                                toast.success("Topping eliminado");
                            }}
                        />
                    </div>

                </div>

                {/* Botones de guardado */}
                <div className="botones-guardado">
                    <div className="has-text-right buttons-modal">
                        <div className="column">
                            <button
                                className="button is-success"
                                onClick={() => this.props.save(this.state.recipe, false)}>
                                Guardar
                            </button>
                        </div>
                    </div>

                    {this.props.recipe.HasMenuAssigned && <div className="has-text-right buttons-modal">
                        <div className="column">
                            <button
                                className="button is-success"
                                onClick={() => this.props.save(this.state.recipe, true)}>
                                Guardar y Sincronizar
                            </button>
                        </div>
                    </div>
                    }
                </div>
                {/* Modales */}
                {/* Modal para agregar un articulo inventario */}
                {this.state.detailModal &&
                    <Modal
                        showModal={this.state.detailModal}
                        modalTitle={"Agregar artículo inventario"}
                        cancel={() => this.setState({
                            detailModal: false,
                            selectedOption: [],
                            selectedOptionC: [],
                            selectedOptionD: [],
                            optionCity: this.select_city(Object.values(this.state.Caffenios)),
                            optionDrive: this.select_Drive(Object.values(this.state.Caffenios))
                        })}
                        width="75%"
                    >
                        <NewRecipeDetail
                            // addDetail={(detail) => {
                            //     let recipe = {...this.state.recipe};
                            //     recipe.Details.push(detail);
                            //     this.setState({recipe: recipe});
                            // }}
                            addDetail={(detail) => {
                                let recipe = { ...this.state.recipe };
                                detail.DriveId = this.state.selectedOptionD.length === 0 ? [...this.state.optionDrive] : [...this.state.selectedOptionD]
                                detail.DriveNombre = this.state.selectedOptionD.length > 0 ? detail.DriveId.map(item => item.label).join(", ")
                                    : (this.state.selectedOptionC.length > 0 ? this.state.selectedOptionC.map(item => item.label).join(", ")
                                        : (this.state.selectedOption.length > 0 ? this.state.selectedOption.map(item => item.label).join(", ")
                                            : "Todos"))
                                recipe.Details.push(detail);
                                recipe.DetailsGroupBy.push(detail);
                                this.setState({ recipe: recipe });
                            }}
                            // currentDetails={this.state.recipe.Details.map(d => d.InventoryItemId)}
                            currentDetails={this.state.recipe.Details.filter(det => {
                                let caffenios = this.state.selectedOptionD.length === 0 ? [...this.state.optionDrive] : [...this.state.selectedOptionD]
                                return caffenios.findIndex(dri => {
                                    // Si el articulo ya existia en la receta entonces checamos su idDrive
                                    if (det.idDrive) {
                                        return det.idDrive == dri.value
                                    }
                                    // Si el articulo se agregó en la misma sesión entonces checamos la propiedad DriveId 
                                    // (que contiene una colección de los drives en donde se asigno el articulo)
                                    if (det.DriveId) {
                                        return det.DriveId.findIndex(id => id.value == dri.value) != -1
                                    } else {
                                        return false
                                    }
                                }) != -1
                            }).map(det => det.InventoryItemId)}
                            isDetail={true}
                        />
                    </Modal>
                }

                {/* Modal para eliminar un articulo inventario */}
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.removeDetail()}
                    cancel={() => this.setState({ removeModal: false })}
                    modalTitle={"Eliminar artículo inventario"}
                />}

                {/* Modal para seleccionar drive */}
                {this.state.modalDrive &&
                    <Modal
                        showModal={this.state.modalDrive}
                        modalTitle={"Seleccionar Drive(s)"}
                        customclassName="modalDrives"
                        cancel={() => this.setState({
                            modalDrive: false,
                            selectedOption: [],
                            selectedOptionC: [],
                            selectedOptionD: [],
                            optionCity: this.select_city(Object.values(this.state.Caffenios)),
                            optionDrive: this.select_Drive(Object.values(this.state.Caffenios))
                        })
                        }
                    >
                        <div className="columns is-multiline is-centered">
                            <div className="column is-12">
                                <label className="label">Estados</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"seleccionar..."}
                                        name={"selectedOption"}
                                        value={this.selectedOption}
                                        onChange={this.changeSelectEstado}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.props.optionState}
                                        menuPosition="fixed"
                                    />
                                </div>
                            </div>
                            <div className="column is-12">
                                <label className="label">Ciudades</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"seleccionar..."}
                                        name={"selectedOptionC"}
                                        value={this.selectedOptionC}
                                        onChange={this.changeSelectCiudad}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.optionCity}
                                        menuPosition="fixed"
                                    />
                                </div>
                            </div>
                            <div className="column is-12">
                                <label className="label">Drives</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"seleccionar..."}
                                        name={"selectedOptionD"}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={this.state.selectedOptionD}
                                        onChange={this.changeSelectDrive}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.optionDrive}
                                        menuPosition="fixed"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="has-text-right buttons-modal">
                            <div className="column">
                                <button
                                    className="button is-success"
                                    onClick={() => {

                                        this.setState({ modalDrive: false, detailModal: true })
                                    }
                                    }>
                                    Siguiente
                            </button>
                            </div>
                        </div>


                    </Modal>
                }

                {<Modal
                    showModal={this.state.mostrarDrivesModal}
                    modalTitle={"Drives asignados"}
                    cancel={() => this.setState({ mostrarDrivesModal: false })}
                >
                    {
                        this.state.recipeDetail.DriveId != null ? this.state.recipeDetail.DriveId.map(drive => {
                            return <div>{drive.label}</div>
                        }) : <div>Todos</div>
                    }
                </Modal>}

                {/* Modal para el modulo de toppings */}
                {this.state.toppingModal &&
                    <Modal
                        showModal={this.state.toppingModal}
                        modalTitle={"Agregar topping"}
                        cancel={() => this.setState({ toppingModal: false })}
                        width="75%"
                    >
                        <NewRecipeDetail
                            addDetail={(detail) => {
                                let recipe = { ...this.state.recipe };

                                recipe.Toppings.push({
                                    // CodeProvider: detail.CodeProvider,
                                    // Name: detail.Name,
                                    // InventoryItemId: detail.InventoryItemId,
                                    ...detail,
                                    MinQuantity: detail.MinQuantity || 0,
                                    MaxQuantity: detail.MaxQuantity || 0,
                                    // UnitSaleStr: detail.UnitSaleStr
                                });
                                this.setState({ recipe: recipe });
                            }}
                            currentDetails={this.state.recipe.Toppings.map(det => det.InventoryItemId)}
                            soloToppings={true}
                            isTopping={true}
                        />
                    </Modal>
                }

                {/**Modal para ver el detalle de drives */}
                {this.state.detailDrivesModal &&
                    <Modal
                        showModal={this.state.detailDrivesModal}
                        modalTitle={"Listado de Drives"}
                        cancel={() => this.setState({
                            detailDrivesModal: false
                        })}
                        width="75%"
                    >
                        <RecipeDrivesDetail
                            detail={this.state.recipeDetailDrives.driveList}
                            removeDetail={(recipe) => this.removeDriveDetail(recipe)}
                        />
                    </Modal>
                }

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
            </div>
        );
    }
}

RecipeForm.defaultProps = {
    isEditing: false,
    recipe: {
        ShortName: "",
        Description: "",
        ClassificationId: 0,
        PresentationId: 0,
        FlavorTypeId: 0,
        RecipeTypeId: 0,
        IVAApply: true,
        KiwiId: 0,
        MilkTypeId: 0,
        GrainTypeId: 0,
        Active: true,
        MobileApply: true,
        WelcomeGiftApply: true,
        ClassificationSatId: 0,
        CodeSatId: 0,
        Details: []
    }
}

export default RecipeForm;
