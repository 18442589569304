import React, { Component } from 'react';
import TextField from '../util/textfield/TextField';
import Field from '../util/field/Field';
import Loading from '../util/loading/Loading';
import MenuDriveAPI from '../../services/MenuDrive';
import ErrorMessages from '../../util/validation-error-messages';
import ExpresionesRegulares from '../../util/expresiones-regulares';
import { Formik } from 'formik';
import * as Yup from 'yup';
import lodash from 'lodash';
import Select from 'react-select';


class MenuForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            menu: {...this.props.menu},
            activeMenu: [],
            selectedOption: null,
            isEditing: false,
            isLoading: false
        }
    }    

    componentDidMount(){
        MenuDriveAPI.get().then(response => {
            this.setState({activeMenu: response.data.map(item=> {
                item.label = item.Description;
                item.value= item.MenuId;
                return item;
            })})
        })
    }

    VALIDATION_SCHEMA = Yup.object({
        Description: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoConCaracteres, { message: ErrorMessages.AlfanumericoConCaracteresError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(30, "Ha excedido el límite de 50 caracteres.")
            .required("Campo Descripción es requerido.")
            .test("validate-name", "La Descripción ya existe, favor de ingresar otro.", (value) => {
                let isValid = true;
                let name = value ? value.toLowerCase().trim() : "";
                if (this.props.menus.some(u => u.Description.toLowerCase().trim() === name && u.MenuId !== this.state.menu.MenuId)) {
                    isValid = false;
                }
                return isValid;
            }),
        BaseOn: Yup.string().nullable(),
        Active: Yup.bool(),


    });

    render() {
        return (

            <Formik
            initialValues={this.state.menu}
            validationSchema={this.VALIDATION_SCHEMA}
            onSubmit={(values) => {
                let formatValues = { ...values }
                formatValues.MenuId = this.state.menu.MenuId === null ? 0 : this.state.menu.MenuId;
                formatValues.Description = formatValues.Description.trim();
                formatValues.BaseMenuId =  values.BaseOn ? values.BaseOn.value : 0;
                this.props.save(formatValues)
            }}
        >
            {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }) => (
                <form onSubmit={handleSubmit} id={"frmListaPrecios"}>
                    <Field

                        isHorizontal
                        hasError={errors.Description && touched.Description}
                        msgError={errors.Description && touched.Description ? errors.Description : ""}
                        showLeftSpaces={true}
                        childInfo={values.Description && !errors.Description
                            ? `Te quedan ${50 - values.Description.trim().length} caracteres para la descripción.`
                            : ""}
                    >
                        <TextField
                            id="MenuId"
                            autoFocus={true}
                            required
                            maxLength="50"
                            label="Descripción"
                            name="Description"
                            value={values.Description}
                            placeholder={"Ingrese descripción del menú"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Field>
                    <br />
                    {this.state.menu.MenuId === null  &&
                        <Field
                            isHorizontal
                            showTooltip={true}
                            textTooltip={"Selecciona si quieres las mismas recetas en nuevo menú."}>
                            <div className="column is-2">
                                <label className="label">Basar Menú en</label>
                            </div>
                            <div className="column is-10">
                                <Select
                                    id={"ddlBaseOn"}
                                    placeholder={"seleccionar..."}
                                    name={"BaseOn"}
                                    value={values.BaseOn}
                                    onChange={(val) => {
                                        setFieldValue('BaseOn', val);
                                    }}
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={lodash.sortBy(this.state.activeMenu, [ts => ts.label.toLowerCase()], ['asc'])}
                                />
                            </div>


                        </Field>}

                    <br />
                    <Field label="Activo" isHorizontal>
                        <input
                            id="chkActivoLista"
                            type="checkbox"
                            name="activo"
                            className="switch"
                            checked={values.Active}
                            onChange={() => { }}
                        />
                        <label htmlFor="iva" onClick={(e) => {
                            e.stopPropagation();
                            setFieldValue("Active", !values.Active)
                        }} />
                    </Field>
                    <br />
                    <div className="has-text-right buttons-modal">
                        <div className="column">
                            <button
                                type="submit"
                                className="button is-success"
                            >
                                Guardar
                    </button>
                        </div>
                    </div>
                    <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
                </form>
            )}
        </Formik>
        );
    }
}

MenuForm.defaultProps = {
    isEditing: false,
    isLoading: false,
    menu: {
        MenuId: null,
        Description: "",
        Active: false
    }
}

export default MenuForm;
