import { apiUrl }  from "../constants";
import axios from 'axios';
import { stringify } from 'querystring';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json','Access-Control-Allow-Origin' : '*',}
})
export default class RecipesAPI {
    static getAll() {
        return request.get('/Recipe');
    }

    static get(id) {
        return request.get(`/Recipe/${id}`);
    }

    static create(Recipe) {
        return request.post('/Recipe',Recipe);
    }

    /** Crea request con formdata, incluye si existe la imagen de la receta */
    static createWithFormData(Recipe, imagen){
        let fd = new FormData();      
        fd.append("modelo", JSON.stringify(Recipe));
        imagen && fd.append("Image", imagen);

        return request.post('/Recipe/multimedia', fd);
    }
    
    /**Crea recetas en conjunto */
    static createMulti(Recipe) {
        return request.post('/Recipe/Multi',Recipe);
    }


    static edit(Recipe){
        return request.put(`/Recipe`,Recipe);
    }

     /** Edita request con formdata, incluye si existe la imagen de la receta */
     static editWithFormData(Recipe, imagen){
        let fd = new FormData();   
        if(imagen !== null ){
            Recipe.Image = "";
        }   
        fd.append("modelo", JSON.stringify(Recipe));
        imagen && fd.append("Image", imagen);


        return request.put('/Recipe/multimedia', fd);
    }

    /** Edita request con formdata, incluye si existe la imagen de la receta */
    static editTipoSaborWithFormData(Recipe, imagen){
        let fd = new FormData();   
        if(imagen !== null ){
            Recipe.Image = "";
        }   
        fd.append("modelo", JSON.stringify(Recipe));
        imagen && fd.append("Image", imagen);


        return request.put('/Recipe/multimedia/tiposabor', fd);
    }

     /** Edita request con formdata, incluye si existe la imagen del tipo receta */
    static editTipoRecetaWithFormData(Recipe, imagen){
        let fd = new FormData();   
        if(imagen !== null ){
            Recipe.Image = "";
        }   
        fd.append("modelo", JSON.stringify(Recipe));
        imagen && fd.append("Image", imagen);


        return request.put('/Recipe/multimedia/tiporeceta', fd);
    }

     /** Edita request con formdata, incluye si existe la imagen del tipo receta */
     static editPresentacionWithFormData(Recipe, imagen){
        let fd = new FormData();   
        if(imagen !== null ){
            Recipe.Image = "";
        }   
        fd.append("modelo", JSON.stringify(Recipe));
        imagen && fd.append("Image", imagen);


        return request.put('/Recipe/multimedia/presentacion', fd);
    }

    static delete(id){
        return request.delete(`Recipe/${id}`);
    }

    static sync(id){
        return request.post(`/Recipe/Sync/${id}`);
    }

    static syncMulti(lstIds){
        return request.post(`/Recipe/SyncMulti/`, lstIds);
    }

    static addToppings(Recipes){
        return request.post(`/Recipe/AddToppings`, Recipes)
    }

    /**Función para agragar y/o actualizar cantidades de artículos inventarios de una receta  */
    static addInventoryItems(Recipe){
        return request.post(`Recipe/AddInventoryItems`, Recipe)
    }

    static getMenu(recipeId){
        return request.get(`/Recipe/GetMenu/${recipeId}`)
    }

    static newFeatured(recipeId){
        return request.post(`/Featured/FeaturedSave`, recipeId)
    }

    /**
     * Obtienen listado de recetas por parametros
     */
     static getByParams(params){        
        return request.get(`/Recipe?${stringify({...params})}`);
    }
    
    /**
     * Actualiza la imagen y el nombre corto de la receta 
     * @param {*} Recipe 
     * @param {*} imagen 
     * @returns 
     */
    static editImageRecipe(Recipe){
        let fd = new FormData();
        for(var prop in Recipe){
            if(Recipe[prop] != null && Recipe[prop] != undefined)
                fd.append(prop, Recipe[prop])
        }


        return request.patch('/RecipeHeader/multimedia', fd);
    }
}