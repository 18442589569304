import React, { Component } from 'react';
import  SearchForm  from "../../util/searchform/SearchForm";
import  TableTemplate  from "../../util/tabletemplate/TableTemplate";
import  NoItems  from "../../util/noitems/NoItems";
import Pagination from '../../util/pagination/Pagination';
import RecipesAPI from '../../../services/Recipe';
import { toast } from 'react-toastify';
import Loading from '../../util/loading/Loading';
import RecipeTypesAPI from '../../../services/RecipeType';
import PresentationsAPI from '../../../services/Presentations';
import MilksAPI from '../../../services/Milk';
import GrainsAPI from '../../../services/Grain';
import FlavorsAPI from '../../../services/Flavor';
import ClasificacionesAPI from '../../../services/Classification';

import lodash from 'lodash';

import ModoGuiado from "../../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../../util/BtnModoGuiado/BtnModoGuiado'

import ErrorMessages from '../../../util/validation-error-messages';
import RecipeFilters from '../../util/recipeFilters/recipeFilters';

class RecipeExtractos extends Component {
    recipes = [];
    origRecipeTypesList = []; //Donde se va a guardar la lista original de tipos de receta
    origFlavorsList = []; //Donde se va a guardar la lista original de sabores
    origGrainsList = []; //Donde se va a guardar la lista original de granos
    origMilksList = []; //Donde se va a guardar la lista original de leches
    origPresentationList = []; //Donde se va a guardar la lista original de presentaciones

    // Variables donde se almacenaran los valores seleccionados en el select multi
    tempRecipeTypesList;
    tempPresentationList;
    tempMilkList;
    tempGrainList;
    tempFlavorsList;
    tempClassificationList;

    constructor(props){
        super(props);
        this.state = {
            recipes: [],
            filterText: "",
            recipe: null,
            pageRecipes: [],
            showModal: false,
            editing: false,
            isLoading: false,
            syncModal: false,
            selectedRecipes: [...this.props.selectedRecipes],
            checkAll: false,
            syncMultiModal: false,
            // Variables sonde se almacenaran los criterios de busqueda definitivos
            recipeTypeList: [],
            selectedRecipeTypesList: [],
            presentationsList: [],
            selectedPresentationsList: [],
            milksList: [],
            selectedMilksList: [],
            grainsList: [],
            selectedGrainsList: [],
            flavorsList: [],
            selectedFlavorsList: [],
            classificationList: [],
            selectedClassificationList: [],
            manageToppingModal: false,
            addToppingModal: false,
            toppings: [],
            toppingError: false,
            menuRecetaModal: false,
            menuRecetaModalTitle: "",
            menuRecetaModalBody: "",
            menuRecetaCurrent: null,
            //Agregar artículos
            manageInventoryItemsModal:false,
            addInventoryItems:false,
            inventoryItems: [], 
            inventoryItemsTemp: [], 
            recipesToAddItems:[],
            inventoryItemsError:false,
            showInventoryItemsInfo:false
        }
    }

    componentDidMount(){
        this.setState({isLoading: true})
        let state = {...this.state};
        RecipesAPI.getAll().then(response => {

            state.recipes = response.data
            let auxCount = 0;
            state.recipes.findIndex(obj => {
                let isSelected = this.state.selectedRecipes.findIndex(selectedRecipe => selectedRecipe.RecipeId === obj.RecipeId)
                if (isSelected != -1) {
                    obj.statusCheck = true;
                    auxCount++;
                }
                return auxCount == this.state.selectedRecipes.length;
            })
            this.recipes = lodash.cloneDeep(response.data);
            
            // Obtener todos los tipos de recetas
            return RecipeTypesAPI.getAll()
        }).then(response => {
            let temp = [];
            
            response.data.map(obj => {
                if(obj.RecipeName) {
                    obj.value= obj.RecipeTypeId;
                    obj.label= obj.RecipeName;
                    
                }else{
                    obj.value= obj.RecipeTypeId;
                    obj.label= obj.Description;
                    
                }
                
                temp.push(obj)
                return obj;
            })
            temp.sort(this.compare); // Ordenar los labels para elegir
            state.recipeTypeList = temp;
            this.origRecipeTypesList = lodash.cloneDeep(temp)
            
            // Obtener todas las presentaciones
            return PresentationsAPI.getAll()
        }).then(response => {
            let tempPresentationList = [];
            response.data.map(obj => {
                if(obj.Active){
                    obj.value= obj.PresentationId;
                    obj.label= obj.Name;
                    tempPresentationList.push(obj)
                    return obj
                    //
                }                
            })
            tempPresentationList.sort(this.compare); // Ordenar los labels
            state.presentationsList = tempPresentationList
            this.origPresentationList = lodash.cloneDeep(tempPresentationList)

            // Obtener los tipos de leche
            return MilksAPI.getAll()
        }).then(response => {
            let tempMilkList = [];
            response.data.map(obj => {
                obj.value= obj.MilkTypeId;
                obj.label= obj.Description;
                tempMilkList.push(obj)
                return obj;
            })
            tempMilkList.sort(this.compare); // Ordenar los labels
            state.milksList = tempMilkList
            this.origMilksList = tempMilkList
            
            // Obtener los tipos de grano
            return GrainsAPI.getAll()
        }).then(response => {
            let tempGrainList = [];
            response.data.map(obj => {
                obj.value= obj.GrainTypeId;
                obj.label= obj.Description;
                tempGrainList.push(obj)
                return obj;
            
            })
            tempGrainList.sort(this.compare); // Ordenar los labels
            state.grainsList = tempGrainList
            this.origGrainsList = lodash.cloneDeep(tempGrainList)
            
            // Obtener los sabores
            return FlavorsAPI.getAll()
        }).then(response => {
            let tempFlavorsList = [];
            response.data.map(obj => {
                obj.value= obj.FlavorTypeId;
                obj.label= obj.Name;
                tempFlavorsList.push(obj)
                return obj;
                
            })
            tempFlavorsList.sort(this.compare); // Ordenar los labels        
            state.flavorsList = tempFlavorsList
            this.origFlavorsList = tempFlavorsList
            
            // Obtener las clasificaciones
            return ClasificacionesAPI.getAll()
        }).then(response => {
            let tempClassificationList = [];
            response.data.map(obj => {
                obj.value= obj.ClassificationId;
                obj.label= obj.Name;
                tempClassificationList.push(obj)
                return obj;
            })
            tempClassificationList.sort(this.compare); // Ordenar los labels
            state.classificationList = tempClassificationList
            
            console.log("El state recipes extractos >> ", state);
            // Guardar el estado
            this.setState(state);
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    }

    /**
     * Funcion para ordenar lexicograficamente
     * @param objA. primer operando
     * @param objB. segundo operando
     */
    compare = (objA, objB) => {
        // Use toUpperCase() to ignore character casing
        const labelA = objA.label.toUpperCase();
        const labelB = objB.label.toUpperCase();

        let comparison = 0;
        if (labelA > labelB) {
            comparison = 1;
        } else if (labelA < labelB) {
            comparison = -1;
        }
        return comparison;
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let state = this.state;
        state.filterText = text;
        this.setState(state, this.handleSearchMultipleFieldInput);
    }

    /**
     *Realizar el filtrado sobre las recetas para desplegar solo los elementos
     *que cumplen con las caracteristicas que el usuario ingreso.
     * 
     *@param: nada
     *@return: nada
     */
    handleSearchMultipleFieldInput = () => {
        let recipes = this.recipes.filter(
            recipe => ( 
                (this.state.selectedRecipeTypesList.length > 0 ? 
                this.state.selectedRecipeTypesList.includes(recipe.RecipeTypeId) : 
                true) &&
                (this.state.selectedFlavorsList.length > 0 ? 
                this.state.selectedFlavorsList.includes(recipe.FlavorTypeId) : 
                true) &&
                (this.state.selectedPresentationsList.length > 0 ? 
                this.state.selectedPresentationsList.includes(recipe.PresentationId) : 
                true) &&
                (this.state.selectedMilksList.length > 0 ? 
                this.state.selectedMilksList.includes(recipe.MilkTypeId) : 
                true) &&
                (this.state.selectedGrainsList.length > 0 ? 
                this.state.selectedGrainsList.includes(recipe.GrainTypeId) : 
                true) &&
                (this.state.selectedClassificationList.length > 0 ? 
                this.state.selectedClassificationList.includes(recipe.ClassificationId) : 
                true) &&
                (this.state.filterText.trim() !== '' ?
                ( (recipe.KiwiId 
                    ? recipe.KiwiId.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) 
                    : false) 
                    || recipe.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                : true)
            )
        );
        
        let state = {...this.state};
        state.recipes = lodash.cloneDeep(recipes);
        let auxCount = 0;
        state.recipes.findIndex(obj => {
            let isSelected = this.state.selectedRecipes.findIndex(selectedRecipe => selectedRecipe.RecipeId === obj.RecipeId)
            if (isSelected != -1) {
                obj.statusCheck = true;
                auxCount++;
            }
            return auxCount == this.state.selectedRecipes.length;
        })
        state.checkAll = false;
        this.setState(state);
    }


    handleSelectChange = (objFilters) => {
        this.setState(objFilters, () => {this.handleSearchMultipleFieldInput();})
    }

    /**
     * Funcion que se ejecuta al darle click al checkbox del header
     */
    selectAllCheck = () => {
        let _checkAll = !this.state.checkAll
        let _selectedRecipes = [...this.state.selectedRecipes]
        let _pageRecipes = this.state.pageRecipes
        _pageRecipes.forEach(recipe => {
            let indexRecipe = _selectedRecipes.findIndex(item => item.RecipeId === recipe.RecipeId)
            recipe.statusCheck = _checkAll
            if(_checkAll){
                if(indexRecipe === -1) _selectedRecipes.push(recipe)
            }else{
                if(indexRecipe !== -1) _selectedRecipes.splice(indexRecipe, 1)
            }

        })

        this.setState({checkAll: _checkAll, selectedRecipes: _selectedRecipes})
    }

    /**
     * Funcion que se ejecuta al cambiar la pagina del tablero
     */
    changeRecipePage = (recipes) => {
        let recipesChecked = 0
        let checkAll = false
        recipes.forEach(item => {
            if(this.state.selectedRecipes.findIndex(id => id.RecipeId === item.RecipeId) !== -1){
                recipesChecked++
                item.statusCheck = true;
            }
            else{
                item.statusCheck = false;
            }
                
        })
        if(recipesChecked === recipes.length) checkAll = true 

        this.setState({ pageRecipes: recipes, checkAll: checkAll })
    }

    /*
    * Funcion que selecciona o des selecciona todas las 
    * recetas de todas las paginas.
    */
    selectAllCheckBox = () => {
        let state = {...this.state};
        let elementsPage = state.recipes
        let selectedRecipes = []
        // Le asignamos el valor inverso de checkAll a todas las recetas actuales
        elementsPage.map(element=>{
            if(state.checkAll){
                element.statusCheck = false 
            }else{
                element.statusCheck = true
                selectedRecipes.push(element)
            }

            return element
        })
        state.checkAll = !state.checkAll;
        state.recipes = elementsPage;
        state.selectedRecipes = selectedRecipes
        this.setState(state);
    }


    render() {
        return (
            <div>

                <ModoGuiado 
                    ruta="recetas"
                    tipoModoGuiado="receta"
                    anterior="toppings"
                    mensaje="Configuración de un producto terminado agrupado por diferentes variables" />

                <div className="columns is-vcentered">
                    <div className="column is-5">
                        <div className="card">
                            <div className="card-content">
                                <div className="field">
                                <label className="label">Búsqueda</label>
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'Receta'}
                                            placeholderText={"Ingrese SKU o descripción de receta"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Filtro multiple para recetas */}
                <div className="card">
                    <div className="card-content">
                        <div className="columns is-multiline">
                            <RecipeFilters
                                onChange = {(objFilters) => {this.handleSelectChange(objFilters)}}
                            />

                        </div>
                    </div>
                </div>

                <br />
                
                <div className="card">
                    <div className="card-content">
                        {/* Botones del header de la tabla */}
                        <div className="columns">
                            {/* Todos los resultados */}
                            <div className="column" style={{display: "flex"}}>
                                <a className="button is-link" onClick={this.selectAllCheckBox}>
                                    <span className="icon is-small">
                                        <i className={!this.state.checkAll ? "fa fa-check-square" : "fa fa-square"}></i>
                                    </span>
                                    <span>{!this.state.checkAll ? "Seleccionar todos los resultados" : "Deseleccionar todos los resultados"}</span>
                                </a>
                            </div>

                        </div>
                        {this.state.recipes.length !== 0 
                        ? <TableTemplate
                            columns={["KiwiId", "Description"]}
                            columnsNames={["SKU", "Descripción"]}
                            noActions={true}
                            data={this.state.pageRecipes}
                            checkbox={true}
                            //Controlar checkboxes 
                            showView={(index) => {
                                let _selectedRecipes = [...this.state.selectedRecipes]
                                let indexRecipe = this.state.selectedRecipes.findIndex(id => id.RecipeId === this.state.pageRecipes[index].RecipeId);
                                if( indexRecipe === -1)
                                _selectedRecipes.push(this.state.pageRecipes[index]);
                                else
                                _selectedRecipes.splice(indexRecipe, 1)
                                this.setState({selectedRecipes: _selectedRecipes})
                            }}
                            checkAll={this.state.checkAll}
                            //Controlador del checkbox del header
                            selectAllCheck={this.selectAllCheck}
                            rowClickable={false}
                        /> 
                        : <NoItems itemName="recetas"/>}
                        <Pagination items={this.state.recipes} onChangePage={(recipes) => this.changeRecipePage(recipes) } />
                    </div>
                </div>

                <footer className="modal-card-foot is-justify-end">

                         <button
                            className='button is-grey'
                            onClick={() =>  this.props.cancelRecipesModal()}
                        >
                            Cancelar
                        </button>

                        <button 
                            disabled={false}
                            className='button is-success' 
                            onClick={() => this.props.saveRecipes(this.state.selectedRecipes.map(e => {
                                return {
                                    RecipeId : e.RecipeId,
                                    KiwiId: e.KiwiId,
                                    Description : e.Description,
                                    statusCheck: e.statusCheck
                                }
                            }))}
                        >
                            Guardar cambios
                        </button>


                        
                    </footer>                  
                
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />                

            </div>
        );
    }
}

export default RecipeExtractos;
