import React, { useEffect } from 'react';
import useForm from '../../../../../util/hooks/useForm';
import AgendarNotificacionForm from './components/AgendarNotificacionForm';

export default function AgendarNotificacion({inputs, replaceInputs, setInput, cleanInputs, errors, handleInputChange, handleSelectChange}){

    useEffect(() => {
        return () => {
            cleanInputs();
        }
    }, [])

    return (
        <div>
            <AgendarNotificacionForm 
                inputs={inputs} 
                cleanInputs={cleanInputs} 
                setInput={setInput} 
                errors={errors} 
                handleSelectChange={handleSelectChange} 
                handleInputChange={handleInputChange}
                replaceInputs={replaceInputs}
            />
        </div>
    )
}