import React from 'react';
import './SaldoRegalo.css';

export default class SaldoRegaloErrorFile extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    var tableHeaders = (<thead>
      <tr>
        <th>{this.props.byLoyaltyAccount ? "CuentaLealtad": "NumeroCliente"}</th>
        <th>FechaInicio</th>
        <th>FechaFinal</th>
        <th>Monto</th>
        <th>Motivo</th>
        <th>Errores</th>
      </tr>
    </thead>);

    var tableBody = this.props.data.map((obj, index) => {
      return (
        <tr key={index}>
          <td>{obj.Data.Identifier}</td>
          <td>{obj.Data.StarDate}</td>
          <td>{obj.Data.EndDate}</td>
          <td>{obj.Data.Deposit}</td>
          <td>{obj.Data.ReasonDeposit}</td>
          <td className="is-danger">{obj.Error}</td>
        </tr>
      );
    });

    return (<table className="table" width="100%">
      {tableHeaders}
      <tbody key="ErrorRows">
        {tableBody}
      </tbody>
    </table>)
  }

}
