import React, { useState, useEffect } from 'react';
import SearchFormV2 from '../../util/searchform/SearchFormV2';
import TablaNotificaciones from './components/TablaNotificaciones';
import SegmentacionAPI from '../../../services/Caffenio App/Segmentacion';
import { toast } from 'react-toastify';
import NotificacionAPI from '../../../services/Caffenio App/Notificacion';
import useModal from '../../../util/hooks/useModal';
import PortalModal from '../../../util/portals/PortalModal';

export default function Notificaciones({history}){
    const statusModal = useModal();
    const deleteModal = useModal();

    const [nameSearch, setNameSearch] = useState("");
    const [notificaciones, setNotificaciones] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [currentNotif, setCurrentNotif] = useState(null);

    const getNotificaciones = async (name = "", page = 1) => {
        setLoading(true);
        const res = await NotificacionAPI.getNotificaciones(name, page);
        console.log(res);
        if(res){
            setNotificaciones(res.data.Results);
            setTotalPages(res.data.TotalPages)
            setCurrentPage(res.data.CurrentPage);
        }else{
            toast.error("Hubo un error al obtener las notificaciones.")
        }
        
        setLoading(false);
    }

    const changeStatus = async () => {
        let res = await NotificacionAPI.changeNotificationStatus(currentNotif.NotificationId, !currentNotif.Status);
        if(res.data && res.data.Success){
            statusModal.toggle();
            toast.success("Estatus actualizado.")
            getNotificaciones(nameSearch, currentPage);
        }else{
            toast.error("No se pudo actualizar el estatus de la notificación, intente más tarde.")
        }
    }

    const deleteNotification = () => {
        NotificacionAPI.deleteNotification(currentNotif.NotificationId).then(response => {
            toast.success(response.data.Message);
            getNotificaciones();
        }).catch(err => {
            toast.error(err.response.data.Message);
        }).finally(() => {
            setCurrentNotif(null);
            deleteModal.toggle();
        })
    }

    useEffect(() => {
        getNotificaciones(nameSearch, currentPage);
    },[nameSearch, currentPage])

    return (
        <div>
            <h1 className="title is-2">Notificaciones</h1>
            <SearchFormV2
                create={() => {
                    history.push({
                        pathname: '/app/notificaciones/nueva',
                        state: {currentPage: currentPage, name: nameSearch}
                    });
                }}
                search={(text) => setNameSearch(text)}
                name={nameSearch}
                tooltip={"Crear una nueva notificación"}
                newBtnTitle={"Nueva notificación"}
            />
            <br/>
            <TablaNotificaciones
                notificaciones={notificaciones}
                totalPages={totalPages}
                currentPage={currentPage}
                changeStatus={(notif) => {
                    setCurrentNotif(notif);
                    statusModal.toggle();
                } }
                goToPage={(page) => setCurrentPage(page)}
                edit={(id) => history.push({
                    pathname: `/app/notificaciones/editar/${id}`,
                    state: {currentPage: currentPage, name: nameSearch}
                })}
                delete={(notif) => {
                    setCurrentNotif(notif)
                    deleteModal.toggle();
                }}
                loading={loading}
                history={history}
            />
            <PortalModal
                isShowing={statusModal.isShowing}
                hide={statusModal.toggle}
                title="Confirmar"
                body={() => {
                    return (
                        <span>
                            <p>{`¿Estás seguro que deseas ${currentNotif.Status ? "desactivar" : "activar"} la notificación?`}</p>
                            {currentNotif.Status && <p>Cualquier evento en la agenda de la notificación no será procesado durante el envío de notificaciones.</p>}
                        </span>
                    )
                }}
                footer
                footerButtons={() => {
                    return (
                        <div className="has-text-right modal-detalle-buttons">
                            <button className="button" onClick={() => {
                                statusModal.toggle();
                                setCurrentNotif(null);
                            }}>Cancelar</button>
                            <button className={`button ${false ? "is-danger" : "is-success"}`} onClick={() => changeStatus()}>Guardar</button>
                        </div>
                    )
                }}
            />
            <PortalModal
                isShowing={deleteModal.isShowing}
                hide={deleteModal.toggle}
                title="Confirmar"
                body={() => {
                    return (
                        <span>
                            <p>{`¿Estás seguro que deseas eliminar la notificación?`}</p>
                        </span>
                    )
                }}
                footer
                footerButtons={() => {
                    return (
                        <div className="has-text-right modal-detalle-buttons">
                            <button className="button" onClick={() => {
                                deleteModal.toggle();
                                setCurrentNotif(null);
                            }}>Cancelar</button>
                            <button className={`button ${false ? "is-danger" : "is-success"}`} onClick={() => deleteNotification()}>Confirmar</button>
                        </div>
                    )
                }}
            />
        </div>
    )
}