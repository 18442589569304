import {apiUrl} from '../constants';
import axios from 'axios';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

var requestfile = axios.create({
    responseType: 'blob',
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class CierreDiaAPI {

    static FiltrosBusquedaCierreDia = (filtros) => {
        return request.post('/CierreDia/Filtros', filtros)
    }

    static DescargarArchivo = (archivo) => {
        return requestfile.post(`/CierreDia/DescargarArchivo/`, archivo)
    }

}