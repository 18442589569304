import { apiUrl }  from "../constants";
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class PriceDriveAPI {
    
    static get() {
        return request.get(`/PriceList`);
    }

    static getById(id) {
        return request.get(`/PriceList/${id}`);
    }

    static post(priceList) {
        return request.post(`/PriceList`, priceList);
    }

    static put(priceList) {
        return request.put(`/PriceList`, priceList);
    }

    static getPrices(id) {
        return request.get(`/PriceDetail/${id}`)
    }

    static updatePrice(priceDetail) {
        return request.post(`/PriceDetail/PriceDetail`, priceDetail);
    }

    static updatePrices(priceDetails) {
        return request.post(`/PriceDetail/PriceDetails`, priceDetails);
    }

    /**Actualiza masivamente los precios de un conjunto de recetas */
    static updateMasivePrices(data){
        return request.put(`/PriceList/ChangePrice`, data);
    }

}