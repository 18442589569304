import React, { Component } from 'react';
import RemesaAPI from '../../services/Remesa';
import {toast} from 'react-toastify';
import './Remesas.css';
import TableTemplate from '../util/tabletemplate/TableTemplate';
import Modal from '../util/modal/Modal';
import Dropdown from '../util/dropdown/Dropdown';
import HasAccess from '../util/hasAccess/HasAccess';

import BtnModoGuiado from '../util/BtnModoGuiado/BtnModoGuiado'

class Remesas extends Component {

    constructor(props){
        super(props);
        this.state = {
            docNumberSearch: "",
            remesa: [],
            details: [],
            warehouses: [],
            showModal: false,
            status: "",
            actions: [],
            selectedAction: null,
            selectedDrive: null,
            isWebServiceLoading: false
        }
    }

    componentWillMount(){
        RemesaAPI.getWarehouses().then(response => {
            this.setState({
                warehouses: response.data
            });
        }).catch(err => {
            //console.log(err);
            if(err && err.response && err.response.data && err.response.data.Message){
                toast.error(err.response.data.Message);
            }else if(err && err.response && err.response.data){
                toast.error(err.response.data);
            } else {
                toast.error('Ocurrió un error al cargar los almacenes');
            }
        });
    }

    search = () => {
        this.setState({
            isWebServiceLoading: true
        });

        const loadingToast = toast.info("Cargando...", {autoClose: false, closeButton: false, className: "has-background-primary has-text-weight-bold"});
        RemesaAPI.get(this.state.docNumberSearch).then(response => {
            toast.dismiss(loadingToast);
            //console.log(response.data);
            this.setState({
                remesa: [response.data.Remesa], 
                details: response.data.Detalle, 
                status: response.data.Remesa.Status,
                isWebServiceLoading: false
            });

            if(response.data.Remesa.Status !== "" && response.data.Remesa.Status === "Pendiente")
            {
                let actionsOnRemesa = [];
                if(response.data.Remesa && response.data.Remesa.Caffenio.trim() === "No asignado")
                {
                    actionsOnRemesa.push( { Id: 1, Accion: 'Asignar a almacén' } );
                }else{
                    actionsOnRemesa.push( { Id: 2, Accion: 'Cambiar de almacén' } );
                    actionsOnRemesa.push( { Id: 3, Accion: 'Desasignar de almacén' } );
                }
                this.setState({ 
                    actions: actionsOnRemesa,
                    selectedAction: null,
                    selectedDrive: null
                });
            }
            else
            {
                this.setState({
                    actions: [],
                    selectedAction: null,
                    selectedDrive: null
                });
            }
        }).catch(err => {
            toast.dismiss(loadingToast);
            this.setState({
                remesa: [],
                details: [],
                status: "",
                isWebServiceLoading: false
            });
            if(err && err.response && err.response.data && err.response.data.Message){
                toast.error(err.response.data.Message);
            }
        })
    }

    handleSelectedAction = (index) => {
        if(index !== 0) {
            this.setState({ selectedAction: this.state.actions[ index -1 ] });
        } else {
            this.setState({ selectedAction: null });
        }
    }

    handleSelectedDrive = (index) => {
        if(index !== 0) {
            this.setState({ selectedDrive: this.state.warehouses[ index -1 ] });
        } else {
            this.setState({ selectedDrive: null });
        }
    }

    doAction = () => {
        if(this.state.selectedAction){
            //Si es Asignar o Cambiar
            if(this.state.selectedAction.Id === 1 || this.state.selectedAction.Id === 2)
            {
                if(!this.state.selectedDrive)
                {
                    toast.error("Indique un almacén");
                    return;
                }
            }

            this.setState({
                showModal: false,
                isWebServiceLoading: true
            });

            let remesa = {
                DOCNUMBR: this.state.remesa[0].DOCNUMBR.trim(),
                idGPSucursal: (this.state.selectedDrive) ? this.state.selectedDrive.IdGPWarehouse : '',
                Warehouse : this.state.selectedDrive,
                Accion: this.state.selectedAction.Id
            }

            const loadingToast = toast.info("Procesando remesa...", {autoClose: false, closeButton: false, className: "has-background-primary has-text-weight-bold"});
            RemesaAPI.post(remesa).then(response => {
                
                toast.dismiss(loadingToast);
                if(response && response.data && response.data.Message){
                    toast.success(response.data.Message);
                }else if(response && response.data){
                    toast.success(response.data);
                }

                this.setState({
                    docNumberSearch: remesa.DOCNUMBR,
                    isWebServiceLoading: false
                });
                this.search();

            }).catch(err => {
                toast.dismiss(loadingToast);
                //console.log(err.response.data);

                this.setState({
                    isWebServiceLoading: false
                });

                if(err && err.response && err.response.data && err.response.data.Message){
                    toast.error(err.response.data.Message);
                }else if(err && err.response && err.response.data){
                    toast.error(err.response.data);
                }
            });

        }else{
            toast.error("Indica una acción");
        }
    }

    render() {
        return (
            <div>
                <h1 className="title is-2">Remesas</h1>
                <div className="columns">
                    <div className="column is-3">
                        <div className="card">
                            <div className="card-header is-primary">
                                <p className="card-header-title has-text-white has-background-primary">Buscar Remesa</p>
                            </div>
                            <div className="card-content">
                                <div className="field has-text-left">
                                    <label className="label">Remesa</label>
                                    <div className="control">
                                        <input 
                                            value={this.state.docNumberSearch} 
                                            className="input" 
                                            type="text" 
                                            placeholder="Número de documento"
                                            onChange={(e) => this.setState({docNumberSearch: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="has-text-right">
                                        <button
                                            disabled={this.state.isWebServiceLoading}
                                            className="button is-success"
                                            onClick={() => this.search()}>
                                            Buscar
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-9">
                        <div className="card">
                            <div className="card-header is-primary">
                                <p className="card-header-title has-text-white has-background-primary">
                                    Detalles Remesa &nbsp; &nbsp;
                                    <HasAccess roles={['Administrador', 'Coordinador de Distribución']}>
                                        {this.state.status !== "" && this.state.status === "Pendiente" && !this.state.isWebServiceLoading && <span className="icon is-small editBtn" onClick={() => this.setState({showModal: true})}>
                                            <i className="fa fa-2x fa-edit"/>
                                        </span>}
                                    </HasAccess>
                                </p>
                            </div>
                            <div className="card-content">
                                <TableTemplate
                                    columns={["Caffenio", "DOCNUMBR", "Status"]}
                                    columnsNames={["Almacén", "Documento", "Estatus"]}
                                    data={this.state.remesa}
                                    noActions
                                />
                                <br/><br/>
                                <TableTemplate
                                    columns={["Codigo", "Articulo", "Cantidad", "Unidad"]}
                                    columnsNames={["Código", "Artículo", "Cantidad", "Unidad"]}
                                    data={this.state.details}
                                    noActions
                                />
                            </div>
                        </div>
                    </div>
                </div>
                { (this.state.showModal) ?
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Acción sobre remesa"}
                        cancel={() => this.setState({showModal: false})}
                    >
                        <div className="FieldHorizontal field is-horizontal">
                            <div className="field-label">
                                <label className="label">Acción</label>
                            </div>
                            <div className="field-body">
                                <Dropdown
                                    single
                                    items={this.state.actions}
                                    labelKey="Accion"
                                    onSelect={(index) => this.handleSelectedAction(index)}
                                />
                            </div>
                        </div>
                        <div className="FieldHorizontal field is-horizontal">
                            <div className="field-label">
                                <label className="label">Almacén</label>
                            </div>
                            <div className="field-body">
                                <Dropdown
                                    single
                                    items={this.state.warehouses}
                                    labelKey="Name"
                                    onSelect={(index) => this.handleSelectedDrive(index)}
                                />
                            </div>
                        </div>
                        <div className="has-text-right">
                            <button
                                className="button is-success"
                                onClick={() => this.doAction()}>
                                Procesar
                            </button>
                        </div>
                    </Modal> : ''
                }
                <BtnModoGuiado />                
            </div>
        );
    }
}

export default Remesas;
