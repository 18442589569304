import React, {useState, useEffect} from 'react';
import MenuAppAPI from '../../../../../services/Caffenio App/Menu';
import { validateExtensionFile } from '../../../../../util/util';
import { toast } from 'react-toastify';
import Loading from '../../../../util/loading/Loading';
import "../components/style.css"
import Field from '../../../../util/field/Field';

export default function DetalleClasificacionApp({classification,  editing, inputs, setInput, reloadData}){
    const [fileIcon, setFileIcon] = useState(null);
    const [fileImage, setFileImage] = useState(null);
    const [featured, setFeatured] = useState(false);

    useEffect(() => {
        if (classification != null && editing){
            setInput("Name", classification.Name);
            setInput("Featured", classification.Featured);
            setInput("DescriptionApp", classification.DescriptionApp);
            setInput("ClassificationId", classification.ClassificationId);
            setInput("IconFile", null);
            setInput("ImageFile", null);
            setInput("Icon", classification.Icon);
            setInput("Image", classification.Image);
            setFeatured(classification.Featured);
        }
    }, [classification])

    return (
        <div>
            {classification ?
            <div className="columns is-multiline">
                <div className="column is-6 has-text-left">
                    <label className="label">Nombre</label>
                    <div className={`control`}>
                        <input
                            type="text"
                            name="Name"
                            className={`input`}
                            placeholder={`Nombre de la Familia`}
                            maxLength="50"
                            value={inputs.Name}
                            onChange={(e) => setInput('Name', e.currentTarget.value)}
                            disabled={!editing}
                        />
                    </div>
                </div>
                <div className="column is-6 has-text-left">
                    <label className="label">Descripción App</label>
                    <div className={`control`}>
                        <textarea
                            name="DescriptionApp"
                            className="textarea"
                            rows="4"
                            maxLength="50"
                            placeholder={`Descripción de la Familia en App`}
                            value={inputs.DescriptionApp}
                            onChange={(e) => setInput('DescriptionApp', e.currentTarget.value)}
                            disabled={!editing}
                        />
                    </div>
                </div>
                <div className="column is-6 has-text-left">
                    <label className="label">Icono</label>
                    <div className={`control`}>
                        {classification.Icon ? <img className="img200" src={`${classification.UrlIcon}?${new Date().getTime()}`}/> : "n/a"}
                    </div>
                </div>
                {editing && <div className="column is-6 has-text-left">
                    <Field label="Nuevo icono">
                        <div className="file is-info has-name">
                            <label className="file-label">
                                <input
                                    className="input file-input"
                                    id="file"
                                    type="file"
                                    name="Icon"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    name="Icon"
                                    onChange={({target}) => {
                                        const file = target.files[0];
                                        console.log(file)
                                        if (typeof file === 'undefined'){
                                            return;
                                        }
                                        if(validateExtensionFile(file.name)){
                                            setInput('IconFile', file);
                                            setFileIcon(URL.createObjectURL(file));
                                        }
                                    }}
                                />
                                <span className="file-cta">
                                    <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo .PNG o .JPG</i></span>
                                </span>
                                <span className="file-name">{inputs.IconFile == null ? "Archivo" : inputs.IconFile.name}</span>
                            </label>
                        </div>
                        {inputs.IconFile &&
                            <button className="button tableAction is-danger trash-right" disabled={!inputs.IconFile} onClick={(e) => {
                                e.stopPropagation();
                                setInput('IconFile', null);
                                setFileIcon("");
                            }
                            }>
                                <span className="icon">
                                    <i className="fa fa-trash" />
                                </span>
                            </button>
                        }
                    </Field>
                    {fileIcon && <img className="img200" src={fileIcon}/>}
                </div>}
                <div className="column is-6 has-text-left">
                    <label className="label">Imagen</label>
                    <div className={`control`}>
                        {classification.Image ? <img className="img300" src={`${classification.UrlImage}?${new Date().getTime()}`}/> : "n/a"}
                    </div>
                </div>
                {editing && <div className="column is-6 has-text-left">
                    <Field label="Nueva imagen">
                        <div className="file is-info has-name">
                            <label className="file-label">
                                <input
                                    className="input file-input"
                                    id="file"
                                    type="file"
                                    name="Image"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    name="Image"
                                    onChange={({target}) => {
                                        const file = target.files[0];
                                        if (typeof file === 'undefined'){
                                            return;
                                        }
                                        if(validateExtensionFile(file.name)){
                                            setInput('ImageFile', file);
                                            setFileImage(URL.createObjectURL(file));
                                        }
                                    }}
                                />
                                <span className="file-cta">
                                    <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo .PNG o .JPG</i></span>
                                </span>
                                <span className="file-name">{inputs.ImageFile == null ? "Archivo" : inputs.ImageFile.name}</span>
                            </label>
                        </div>
                        {inputs.ImageFile &&
                            <button className="button tableAction is-danger trash-right" disabled={!inputs.ImageFile} onClick={(e) => {
                                e.stopPropagation();
                                setInput('ImageFile', null);
                                setFileImage("");
                            }
                            }>
                                <span className="icon">
                                    <i className="fa fa-trash" />
                                </span>
                            </button>
                        }
                    </Field>
                    {fileImage && <img  src={fileImage} className="img200"/>}
                </div>}
                <div className="column is-2 has-text-left">
                    <label className="label">Posición</label>
                    <div className={`control`}>
                    <input
                            type="text"
                            className={`input`}
                            value={classification.OrderIndex}
                            disabled={!editing}
                        />
                    </div>
                </div>
                <div className="column is-2 has-text-left">
                    <label className="label">Destacado</label>
                    <div className={`control tooltip`} data-tooltip={`${classification.Featured ? "Desmarcar" : "Marcar"} producto como destacado`}>
                        <input
                            type="checkbox"
                            name="status"
                            className="switch"
                            checked={featured}
                            disabled={!editing}
                            onChange={() => { }}
                        />
                        <label disabled htmlFor="status" onClick={(e) => {
                            setFeatured(!classification.Featured);
                            setInput('Featured', !classification.Featured);
                            e.stopPropagation();
                        }} />
                    </div>
                </div>
            </div>
            : /*loading ? <Loading isLoading={loading} width='100px' height='100px'/> :*/ null}
            </div>
    )
}