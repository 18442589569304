import React, { useEffect, useState } from 'react';

// Components
import TextField from '../util/textfield/TextField';
import Field from '../util/field/Field';
import Dropdown from '../util/dropdown/Dropdown';
import Loading from '../util/loading/Loading';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

// Services
import MenuDriveAPI from '../../services/MenuDrive';
import PriceDriveAPI from '../../services/PriceDrive';
import ComboDatosAPI from '../../services/ComboDatos';

// Utils
import { toast } from 'react-toastify';
import ErrorMessages from '../../util/validation-error-messages';

export const CaffenioForm = ({ save }) => {

    const [ caffenio, setCaffenio ] = useState({
        GPSucursalId: null,
        Name: "",
        ShortName: "",
        NewName: "",
        OpenDate: "",
        CloseDate: "",
        WarehouseGPCode: "",
        Active: false,
        TaxRateId: null,
        PriceListId: null,
        PromoListId: null,
        MenuId: null,
        FactDataId: null,
        RailsNumber: "",
        MobileSales: false,
        RefillRestrictions: false,
        idMunicipio: "",
        Municipio: "",
        idColonia: null,
        idEstado: "",
        Estado: "",
        BetStreets: "",
        ExtNumber: "",
        MainStreet: "",
        NumAlmacen: "",
        PostalCode: "",
        Segment: null,
        Suburb: "",
    });

    const [ lists, setLists ] = useState({
        arrMenus: [],
        arrListasPrecios: [],
        arrListaPromo: [],
        arrDatosFact: [],
        arrTasaImp: [],
        arrSegmento: [],
        arrState: [],
        arrCities: [],
        arrColonias: [],
        arrPostalCode: [],
        selectedMenu: null,
        selectedListaPrecio: null,
        isEditing: false,
        isLoading: false
    })

    useEffect(() => {
        setLists({
            ...lists,
            isLoading: true
        });
        let arrMenus = [];
        let arrListasPrecios = [];
        let arrListaPromo = [];
        let arrDatosFact = [];
        let arrTasaImp = [];
        let arrSegmento = [];
        let arrPostalCode = [];
        let arrState = [];
        let arrCities = [];
        let arrColonias = [];

        Promise.all([
            MenuDriveAPI.get().then(response => arrMenus = response.data),
            PriceDriveAPI.get().then(response => arrListasPrecios = response.data),
            ComboDatosAPI.getPromoList().then(response => arrListaPromo = response.data),
            ComboDatosAPI.getFactData().then(response => arrDatosFact = response.data),
            ComboDatosAPI.getTaxRate().then(response => arrTasaImp = response.data),
            ComboDatosAPI.getSegment().then(response => arrSegmento = response.data),
            ComboDatosAPI.getDirPostalCode().then(response => arrPostalCode = response.data),
            ComboDatosAPI.getDirState().then(response => arrState = response.data),
            ComboDatosAPI.getDirCity().then(response => arrCities = response.data),
            ComboDatosAPI.getDirSuburb().then(response => arrColonias = response.data)
        ]).catch( err=> {
            setLists({
                ...lists,
                isLoading: false
            })
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        }).finally(() => setLists({
            ...lists,
            arrMenus,
            arrListasPrecios,
            arrListaPromo,
            arrDatosFact,
            arrTasaImp,
            arrSegmento,
            arrPostalCode,
            arrState,
            arrCities,
            arrColonias,
            isLoading: false,
        }));
    }, []);

    const handleChange = ({ target }) => {
        setCaffenio({
            ...caffenio,
            [target.name]: target.value
        });
    }

    // Solo permite números, sin signos ni exponenciales
    const onKeyPressNumber = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/\+|-|e|E|\./.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
        event.preventDefault();
    }

    const handleDateChange = (date, stringDate) => {
        setCaffenio({
            ...caffenio,
            [stringDate]: date
        })
    }

    return (
        <div>
            <div className="columns is-multiline">
            <div className="column is-2">
                    <Field label="# Drive Suc.">
                        <input
                            className="input"
                            type="number"
                            min="0"
                            label="GPSucursalId"
                            name="GPSucursalId"
                            maxLength="3"
                            max="9"
                            onKeyPress={onKeyPressNumber}
                            value={caffenio.GPSucursalId}
                            onChange={(e) => handleChange(e)}
                        />
                    </Field>
                </div>
                <div className="column is-5">
                    <Field label="Nombre">
                        <input
                            className="input"
                            maxLength="50"
                            label="Nombre"
                            name="Name"
                            value={caffenio.Name}
                            onChange={(e) => handleChange(e)}
                        />
                    </Field>
                </div>
                <div className="column is-5">
                    <Field label="Nombre Corto">
                        <input
                            className="input"
                            label="Nombre Corto"
                            maxLength="30"
                            name="ShortName"
                            value={caffenio.ShortName}
                            onChange={(e) => handleChange(e)}
                        />  
                    </Field>
                </div>
                <div className="column is-2">
                    <Field label="# Almacén GP">
                        <input
                            className="input"
                            type="number"
                            min="0"
                            label="NumAlmacenGP"
                            name="WarehouseGPCode"
                            maxLength="3"
                            max="9"
                            onKeyPress={onKeyPressNumber}
                            value={caffenio.WarehouseGPCode}
                            onChange={(e) => handleChange(e)}
                        />
                    </Field>
                </div>
                <div className="column is-5">
                    <Field label="Nombre Nuevo">
                        <input
                            className="input"
                            maxLength="50"
                            label="Nombre"
                            name="NewName"
                            value={caffenio.NewName}
                            onChange={(e) => handleChange(e)}
                        />
                    </Field>
                </div>
                <div className="column is-5">
                    <Field label="Lista Precio">
                        {<Dropdown 
                            single 
                            items={lists.arrListasPrecios} 
                            value={caffenio.PriceListId}
                            id="PriceListId"
                            disabled={ lists.arrListasPrecios.length === 0 ? true : false }
                            onSelect={value => setCaffenio({
                                ...caffenio,
                                PriceListId: value
                            })}
                            labelKey={"Description"}
                        />}
                    </Field>
                </div>
                <div className="column is-2">
                    <Field label="# Carriles">
                        <input
                            className="input"
                            type="number" 
                            min="0"
                            label="NumLanes"
                            name="RailsNumber"
                            maxLength="3"
                            max="9"
                            onKeyPress={onKeyPressNumber}
                            value={caffenio.numCarriles}
                            onChange={(e) => handleChange(e)}
                        />
                    </Field>
                </div>
                <div className="column is-5">
                    <Field label="Menú">
                        {<Dropdown 
                            single 
                            items={lists.arrMenus} 
                            value={caffenio.MenuId}
                            disabled={ lists.arrMenus.length === 0 ? true : false }
                            id="MenuId"
                            onSelect={value => setCaffenio({
                                ...caffenio,
                                MenuId: value
                            })}
                            labelKey={"Description"}
                        />}
                    </Field>
                </div>
                <div className="column is-5">
                    <Field label="Lista Promo">
                        {<Dropdown 
                            single 
                            items={lists.arrListaPromo} 
                            value={caffenio.PromoListId}
                            disabled={ lists.arrListaPromo.length === 0 ? true : false }
                            id="PromoListId"
                            onSelect={value => setCaffenio({
                                ...caffenio,
                                PromoListId: value
                            })}
                            labelKey={"Description"}
                        />}
                    </Field>
                </div>
                <div className="column is-4 margin-bottom">
                    <Field label="Activo" isHorizontal={false} >
                        <input   
                            type="checkbox"
                            name="Active"
                            className="switch"
                            checked={caffenio.Active}
                            onChange={() => {}}
                        />
                        <label htmlFor="Active" className="inputCenter" onClick={(e) => {
                            e.stopPropagation();
                            setCaffenio({
                                ...caffenio,
                                Active: !caffenio.Active
                            });
                        }}/>
                    </Field>
                </div>
                <div className="column is-4 margin-bottom">
                    <Field label="Ventas Móviles" isHorizontal={false} >
                        <input   
                            type="checkbox"
                            name="MobileSales"
                            className="switch"
                            checked={caffenio.MobileSales}
                            onChange={() => {}}
                        />
                        <label htmlFor="MobileSales" className="inputCenter" onClick={(e) => {
                            e.stopPropagation();
                            setCaffenio({
                                ...caffenio,
                                MobileSales: !caffenio.MobileSales
                            });
                        }}/>
                    </Field>
                </div>
                <div className="column is-4 margin-bottom">
                    <Field label="Restr. Refill" isHorizontal={false} >
                        <input   
                            type="checkbox"
                            name="RestRefill"
                            className="switch"
                            checked={caffenio.RefillRestrictions}
                            onChange={() => {}}
                        />
                        <label htmlFor="RestRefill" className="inputCenter" onClick={(e) => {
                            e.stopPropagation();
                            setCaffenio({
                                ...caffenio,
                                RefillRestrictions: !caffenio.RefillRestrictions
                            });
                        }}/>
                    </Field>
                </div>
                <div className="column is-4">
                    <Field label="# Almacén">
                        <input
                            className="input"
                            type="number"
                            min="0"
                            label="NumAlmacen"
                            name="NumAlmacen"
                            maxLength="3"
                            max="9"
                            onKeyPress={onKeyPressNumber}
                            value={caffenio.NumAlmacen}
                            onChange={(e) => handleChange(e)}
                        />
                    </Field>
                </div>
                <div className="column is-4">
                    <div className="field">
                        <div className="field-label has-text-centered">
                            <label className="label">Fecha Apertura</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control">
                                        <DatePicker
                                            name={"OpeningDate"}
                                            selected={caffenio.OpenDate}
                                            onChange={date => handleDateChange(date, 'OpenDate')}
                                            popperPlacement="bottom"
                                            popperModifiers={{
                                                offset: {
                                                    enabled: true,
                                                    offset: '5px, 20px',
                                                },
                                                preventOverflow: {
                                                    enabled: true,
                                                    escapeWithReference: false,
                                                    boundariesElement: 'viewport'
                                                }
                                            }}
                                            todayButton="Hoy"
                                            dateFormat="dd/MM/yyyy"
                                            className="input"
                                            autoComplete={"off"}
                                            maxDate={caffenio.CloseDate}
                                        />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="column is-4">
                    <div className="field">
                        <div className="field-label has-text-centered">
                            <label className="label">Fecha Clausura</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control">
                                        <DatePicker
                                            name={"ClosingDate"}
                                            selected={caffenio.CloseDate}
                                            onChange={date => handleDateChange(date, 'CloseDate')}
                                            popperPlacement="bottom"
                                            minDate={caffenio.OpenDate}
                                            popperModifiers={{
                                                offset: {
                                                    enabled: true,
                                                    offset: '50px, 10px'
                                                },
                                                preventOverflow: {
                                                    enabled: true,
                                                    escapeWithReference: false,
                                                    boundariesElement: 'viewport'
                                                }
                                            }}
                                            todayButton="Hoy"
                                            dateFormat="dd/MM/yyyy"
                                            className="input"
                                            autoComplete={"off"}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <label className="label">Datos Fact</label>
                    <div className="control">
                        <Select
                            placeholder={"Seleccionar..."}
                            name={"selectedOptionC"}
                            isDisabled={ lists.arrDatosFact.length === 0 ? true : false }
                            onChange={ values => setCaffenio({
                                ...caffenio,
                                FactDataId: values.value
                            })}
                            noOptionsMessage={() => "No hay opciones"}
                            options={lists.arrDatosFact.map(ci => { return {
                                ...ci,
                                label: ci.SocialReason,
                                value: ci.FactDataId
                            } })}
                        />
                    </div>
                </div>
                <div className="column is-4">
                    <label className="label">Tasa Impuesto</label>
                    <div className="control">
                        <Select
                            placeholder={"Seleccionar..."}
                            name={"selectedOptionC"}
                            isDisabled={ lists.arrTasaImp.length === 0 ? true : false }
                            onChange={ values => setCaffenio({
                                ...caffenio,
                                TaxRateId: values.value
                            })}
                            noOptionsMessage={() => "No hay opciones"}
                            options={lists.arrTasaImp.map(ci => { return {
                                ...ci,
                                label: ci.Description,
                                value: ci.TaxRateId
                            } })}
                        />
                    </div>
                </div>
                <div className="column is-4">
                    <label className="label">Segmento</label>
                    <div className="control">
                        <Select
                            placeholder={"Seleccionar..."}
                            name={"selectedOptionC"}
                            isDisabled={ lists.arrSegmento.length === 0 ? true : false }
                            onChange={ values => setCaffenio({
                                ...caffenio,
                                SegmentId: values.value
                            })}
                            noOptionsMessage={() => "No hay opciones"}
                            options={lists.arrSegmento.map(ci => { return {
                                ...ci,
                                label: ci.Name,
                                value: ci.SegmentId
                            } })}
                        />
                    </div>
                </div>
                <div className="column is-4">
                    <label className="label">CP</label>
                    <div className="control">
                        <Select
                            placeholder={"Seleccionar..."}
                            name={"selectedOptionC"}
                            isDisabled={ lists.arrPostalCode.length === 0 ? true : false }
                            onChange={ values => setCaffenio({
                                ...caffenio,
                                idPostalCode: values.value
                            })}
                            noOptionsMessage={() => "No hay opciones"}
                            options={lists.arrPostalCode.map(ci => { return {
                                ...ci,
                                label: ci.Code,
                                value: ci.postalCodeId
                            } })}
                        />
                    </div>
                </div>
                <div className="column is-4">
                    <label className="label">Estado</label>
                    <div className="control">
                        <Select
                            placeholder={"Seleccionar..."}
                            name={"selectedOptionC"}
                            isDisabled={ lists.arrState.length === 0 ? true : false }
                            onChange={ values => setCaffenio({
                                ...caffenio,
                                State: values.label
                            })}
                            noOptionsMessage={() => "No hay opciones"}
                            options={lists.arrState.map(ci => { return {
                                ...ci,
                                label: ci.Name,
                                value: ci.StateId
                            } })}
                        />
                    </div>
                </div>
                <div className="column is-4">
                    <label className="label">Ciudad</label>
                    <div className="control">
                        <Select
                            placeholder={"Seleccionar..."}
                            name={"selectedOptionC"}
                            isDisabled={ lists.arrCities.length === 0 ? true : false }
                            onChange={ values => setCaffenio({
                                ...caffenio,
                                idMunicipio: values.value
                            })}
                            noOptionsMessage={() => "No hay opciones"}
                            options={lists.arrCities.map(ci => { return {
                                ...ci,
                                label: ci.Name,
                                value: ci.CityId
                            } })}
                        />
                    </div>
                </div>
                <div className="column is-4">
                    <label className="label">Colonia</label>
                    <div className="control">
                        <Select
                            placeholder={"Seleccionar..."}
                            name={"selectedOptionC"}
                            onChange={ values => setCaffenio({
                                ...caffenio,
                                idColonia: values.value
                            })}
                            noOptionsMessage={() => "No hay opciones"}
                            options={ lists.arrColonias.map(ci => { return {
                                ...ci,
                                label: ci.Name,
                                value: ci.SuburbId
                            }})}
                        />
                    </div>
                </div>
                <div className="column is-8">
                    <Field label="Calle Principal">
                        <input className="input some-top" maxLength="100" label="MainStreet" name="MainStreet" value={caffenio.MainStreet} onChange={(e) => handleChange(e)}/>
                    </Field>
                </div>
                <div className="column is-2">
                    <Field label="Núm. Ext.">
                        <input className="input" type="number" min="0" label="ExtNumber" name="ExtNumber" maxLength="5" max="" onKeyPress={onKeyPressNumber} value={caffenio.ExtNumber} onChange={(e) => handleChange(e)}/>
                    </Field>
                </div>
                <div className="column is-10">
                    <Field label="Entre Calles">
                        <input className="input" maxLength="150" label="BetStreets" name="BetStreets" value={caffenio.BetStreets} onChange={(e) => handleChange(e)}/>
                    </Field>
                </div>

                <div className="has-text-right buttons-modal">
                    <div className="column">
                        <button
                            className="button is-success"
                            onClick={() => save(caffenio)}>
                            Guardar
                        </button>
                    </div>
                </div>
                
                <Loading isFullscreen={true} isLoading={lists.isLoading} width='100px' height='100px'/>
            </div>
        </div>
        
    );
}

CaffenioForm.defaultProps = {
    isEditing: false,
    caffenio: {
        GPSucursalId: null,
        Name: "",
        ShortName: "",
        NumAlmacenGP: "",
        NewName: "",
        PriceListId: 0,
        NumLanes: "",
        Menu: 0,
        PromoList: 0,
        Active: false,
        MobileSales: false,
        RestRefill: false,
        NumAlmacen: "",
        OpeningDate: "",
        ClosingDate: "",
        FactData: 0,
        TaxRateId: 0,
        Segment: 0,
        State: "",
        City: "",
        Suburb: "",
        ExtNumber: "",
        MainStreet: "",
        PostalCode: "",
        BetStreets: ""
    }
}

export default CaffenioForm;