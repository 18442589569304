import React from 'react';
import PropTypes from 'prop-types';
import './TableTemplate.css';

/**
 * Componente de utilería de una tabla para su reutilización que recibe qué datos y qué columnas
 * mostrará, así como las acciones que puede realizar (editar, eliminar, detalles, más).
 */
class TableTemplate extends React.Component {

    render() {
        return (
            <div className="table__wrapper">
                <table className="table is-fullwidth ">
                    <thead>
                        
                        <tr>
                            {/* Se revisa si el componente padre asignó el nombre de las columnas
                            en caso de que no, se utiliza el nombre de la llave del objeto
                            */}
                            { 
                                this.props.checkbox && 
                                <th>
                                    <span >
                                        <input 
                                            className="is-primary tooltip" 
                                            data-tooltip="Elementos de vista actual" 
                                            checked={this.props.checkAll} 
                                            onChange={this.props.selectAllCheck} 
                                            type="checkbox" 
                                        />
                                    </span>
                                </th>
                            }
                            { !this.props.columnsNames ? this.props.columns.map(column => {

                                return (
                                    <th key={column}>{column}</th>
                                )
                            })
                                :
                                this.props.columnsNames.map(column => {
                                    let objColumnStyle = this.props.columnStyle && this.props.columnStyle.find(obj => obj.column === column)
                                    return (
                                        <th key={column} style={objColumnStyle && objColumnStyle.style}>{column}</th>
                                    )
                                })
                            }
                            {!this.props.noActions && <th>Acciones</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {/* 
                            Se mapea a través de los objetos dentro del arreglo para generar un elemento
                            <tr> por cada uno de ellos
                         */}
                        {this.props.data.map((row, index) => {
                            return (
                                // Si el componente padre lo desea, el elemento <tr> será clickable y se ejecutará
                                // lo que la prop details() indique.

                                <tr key={`row${index}`} className={this.props.rowClickable ? "trClickable" : ""} style={(row.style) ? row.style :{}} onClick={() => this.ontrClick(row)}>
                                    
                                    { 
                                        this.props.checkbox && 
                                        <th>
                                            <input 
                                                type="checkbox" 
                                                checked={row.statusCheck} 
                                                onClick={(e) => {
                                                        e.stopPropagation();
                                                        row.statusCheck = !row.statusCheck;
                                                        this.props.showView(index);
                                                    }
                                                }
                                            />
                                        </th> 
                                    }

                                    {this.props.columns.map((column, j) => {
                                        //Si el objeto llega a tener un atributo de activo, se muestra un
                                        //switch para el cambio rápido de estatus mediante la prop changeStatus().
                                        if (column.toLowerCase() === "active" || column.toLowerCase() === "isactive" || column.toLowerCase() === "enablecancelnotification" || column.toLowerCase() === "enableorderrelease") {
                                            return (<td key={`${index}column${j}`}>
                                                <input
                                                    type="checkbox"
                                                    name="status"
                                                    disabled={this.props.disableCheck}
                                                    className="switch"
                                                    checked={row[column]}
                                                    onChange={() => { }}
                                                />
                                                <label disabled={this.props.disableCheck} htmlFor="status" onClick={(e) => {
                                                    e.stopPropagation(); //Se evita la propagación del click al elemento <tr>
                                                    if(column.toLowerCase() === "enableorderrelease"){
                                                        this.props.changeOrderRelease(row)
                                                    }else{
                                                        this.props.changeStatus(row)
                                                    }
                                                }
                                                } />
                                            </td>)
                                        }
                                        return (
                                            column === "name" && row.lastName !== undefined
                                                ? <td key={`${index}column${j}`}>{`${row[column]} ${row.lastName}`}</td>
                                                : <td key={`${index}column${j}`} className={this.props.rowClickable ? "tooltip" : ""} data-tooltip={"Ver detalle"}>{row[column]}</td>

                                        )
                                    })}
                                    {/* 
                                    Se insertan las acciones que lleguen desde props 
                                    Siendo editar y eliminar unas ya preestablecidas que se pueden activar
                                    mediante las props canEdit y canDelete respectivamente
                                */}
                                    {!this.props.noActions && <td>
                                        {this.props.canEdit &&
                                            <button className="button tableAction is-warning tooltip" data-tooltip="Editar" onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.edit(row)
                                            }
                                            }>
                                                <span className="icon">
                                                    <i className="fa fa-edit" />
                                                </span>
                                            </button>
                                        }
                                        {this.props.canDelete &&
                                            <button className="button tableAction is-danger tooltip" data-tooltip="Eliminar" onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.remove(row)
                                            }
                                            }>
                                                <span className="icon">
                                                    <i className="fa fa-trash" />
                                                </span>
                                            </button>
                                        }
                                        {this.props.canSync &&
                                            <button className="button tableAction is-success tooltip" data-tooltip="Sincronizar" onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.sync(row)
                                            }
                                            }>
                                                <span className="icon">
                                                    <i className="fa fa-refresh" />
                                                </span>
                                            </button>
                                        }
                                        {this.props.canDownload &&
                                            <button className="button tableAction is-success tooltip" data-tooltip="Descargar menú" onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.download(row)
                                            }
                                            }>
                                                <span className="icon">
                                                    <i className="fa fa-download" />
                                                </span>
                                            </button>
                                        }

                                        {this.props.extraButtons(row, index)}
                                    </td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    ontrClick = (row) => {
        if (this.props.rowClickable) {
            this.props.details(row)
        }
    }
}

// Definición de los tipos y su dependencia de las distintas props que recibe el componente.
TableTemplate.defaultProps = {
    columns: [],
    data: [],
    canEdit: false,
    canDelete: false,
    rowClickable: false,
    noActions: false,
    disableCheck: false,
    checkbox:false,
    canDownload:false,
    extraButtons: ()=>{}
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
TableTemplate.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columnsNames: PropTypes.arrayOf(PropTypes.string),
    canEdit: PropTypes.bool,
    canDelete: PropTypes.bool,
    canSync: PropTypes.bool,
    edit: PropTypes.func,
    remove: PropTypes.func,
    sync: PropTypes.func,
    rowClickable: PropTypes.bool,
    disableCheck: PropTypes.bool,
    details: PropTypes.func,
    changeStatus: PropTypes.func,
    changeOrderRelease: PropTypes.func,
    checkbox:PropTypes.bool,
    canDownload:PropTypes.bool,
    download: PropTypes.func,
}

export default TableTemplate;