import React, { Component } from 'react';
import Field from '../../util/field/Field';
import ErrorMessages from '../../../util/validation-error-messages';
import ExpresionesRegulares from '../../../util/expresiones-regulares';
import '../../cssComponents.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import NoItems from "../../util/noitems/NoItems";

/***
 * Formulario para vsualizar los comentarios de una promoción
 */
class PromoCommentsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            promo: this.props.promo,  
            readOnly: this.props.readOnly 
        }
    }

    VALIDATION_SCHEMA = Yup.object({
        CurrentComment: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoConCaracteres, { message: ErrorMessages.AlfanumericoConCaracteresError })
            .min(3, "El comentario debe tener más de 2 caracteres.")
            .max(200, "Ha excedido el límite de 200 caracteres.")
            .required("Campo comentario es requerido.")
    });

    loadComments=()=>{
        return(
            this.state.promo && this.state.promo.Comments.length > 0
                ?
                <table className="table is-fullwidth">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Observación</th>
                            <th>Fecha</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.promo.Comments.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item.Description}</td>
                                    <td>{`${item.CreatedAt.split('T')[0]}`}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                : <NoItems itemName="observaciones" />
        )
    }

    render() {
        return (
            !this.state.readOnly ?
                <Formik
                initialValues={{CurrentComment:""}}
                validationSchema={this.VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    let today = new Date();
                    let formatValues = {...values}
                    formatValues.IdPromo = this.state.promo.IdPromocion;
                    formatValues.IdUser = localStorage.getItem("user") ;
                    formatValues.Active = true;
                    formatValues.CreatedAt = today.toISOString();
                    formatValues.Description = values.CurrentComment;
                    this.props.save(formatValues)
                }}
            >
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }) => (
                    <form onSubmit={handleSubmit} id={"frmPromoCopy"}>
                        <Field
                            type={"textarea"}
                            label="Comentario: "
                            isHorizontal
                            hasError={errors.CurrentComment && touched.CurrentComment}
                            msgError={errors.CurrentComment && touched.CurrentComment ? errors.CurrentComment : ""}
                            showLeftSpaces={true}
                            childInfo={values.CurrentComment && !errors.CurrentComment
                                ? `Te quedan ${200 - values.CurrentComment.trim().length} caracteres para la observación.`
                                : ""}
                        >
                            <textarea
                                id="txtDescriptionComment"
                                name={"CurrentComment"}
                                className={`input ${errors.CurrentComment && touched.CurrentComment ? "is-danger" : ""}`}
                                placeholder={"Ingrese observación"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.CurrentComment}
                                style={{boxSizing: "border-box",
                                    //border: "none",
                                    borderRadius: "3px",
                                    resize: "none",
                                    fontSize: "20px",
                                    //lineHeight: "50px",
                                    overflow: "auto",
                                    height: "auto",
                                    minHeight:"100px",
                                    padding: "8px",
                                    boxShadow: "0px 4px 10px -8px black"}}
                            />
                        </Field>
                        <hr />                        
                        {this.loadComments()}
                        
                        <hr/>
                        <div className="modal-card-foot">
                        <div className="has-text-right" style={{width: "100%"}}>
                            <button
                                className="button"
                                onClick={() => this.props.cancel()}
                            >
                                Cancelar
                            </button>
                            
                            <button id={"btnSubmitCommentsPromo"} 
                            type="submit" className="button is-success">
                                Guardar
                            </button>
                        </div>
                        </div>
                            
                            
                    </form>
                )}

            </Formik>
            :this.loadComments()
        );
    }
}



export default PromoCommentsForm;
