import { apiUrl } from "../../constants";
import axios from "axios";
var request = axios.create({
  baseURL: apiUrl,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "application/json",
  },
});

export default class ReportesCancelacionAPI {
  static getByDate(fechas) {
    return request.post("/ReporteCancelado/GetByDate", fechas);
  }

  static getDetailCancelationReportsByDate(fechas) {
    return request.post("/ReporteCancelado/GetByDateDetail", fechas);
  }
}
