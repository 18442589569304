import {apiUrl} from '../constants';
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class ProyeccionesAPI {

    static GetProyeccionesData(){
        return request.get('Proyeccion/GetProyecciones')
    }

    static CreateProyeccionData(proyeccionData){
        return request.post('/Proyeccion/NewProyeccion', proyeccionData);
    }

    static UpdateProyeccionData(proyeccionData){
        return request.put('/Proyeccion/UpdateProyeccion', proyeccionData);
    }

    static GetActiveCaffenios(){
        return request.get('Proyecciones/GetActiveCaffenios')
    } 
}
