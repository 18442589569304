import * as React from 'react';
import './Login.css';
import logo from '../../assets/images/logo-caffenio-nuevo.png';
import { Auth } from '../../services/auth';
import decode from 'jwt-decode';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom'
import Index from '../index';
import AppContainer from '../app-container/app-container';

/**
 * Componente encargado de mostrar el formulario para realizar un inicio de sesión en el sistema.
 */
export default class Login extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            email: "",
            password: "",
            logueado: false
        }
    }

    // Método a ejecutar si se presiona una tecla en el componente
    enterFunc = (event) => {
        if(event.keyCode === 13) { // Si la tecla presionada fue Enter se realiza el bloque
            if (this.state.password && this.state.email) this.login();
        }
    }

    // Se inicia un escuchador en el componente para el uso de alguna tecla
    componentDidMount(){
        document.addEventListener("keydown", this.enterFunc, false);
    }
    // Se elimina el escuchador en el componente para el uso de alguna tecla
    componentWillUnmount(){
        document.removeEventListener("keydown", this.enterFunc, false);
    }

    // Método encargado de realizar la petición al API para el inicio de sesión
    // y el guardado de las credenciales correspondientes en caso de ser exitoso
    login(){
        Auth.login(this.state.email, this.state.password).then(response => {
            let token = response.data.access_token;
            let tokenObj = decode(token);
            let today = new Date();

            localStorage.setItem("expires_in", (today.getTime() / 1000) + response.data.expires_in)
            localStorage.setItem("token", token);
            localStorage.setItem("tokenExpiration", tokenObj.exp);
            localStorage.setItem("role", tokenObj.role);
            localStorage.setItem("permissions", tokenObj.permissions);
            localStorage.setItem("user", tokenObj.uid);
            if(window.caches != undefined){
                window.caches.keys().then(function(names){
                    for(let name of names){
                        window.caches.delete(name);
                    }
                })
            }

            // window.location.reload();
            this.setState({logueado: true})
        }).catch(err => {
            toast.error("Credenciales incorrectas, favor de verificarlas");
        })
    }

    render(){
        if(this.state.logueado === true){
            window.location.reload();
            return <Redirect to={{ pathname: '/'}} />
        }

        return (
            <div className="columns">
                <div className="column is-3"/>
                <div className="column is-6">
                    <br/><br/><br/>
                    <div className="card ">
                        <div className="card-content">
                            <div className="has-text-centered">
                                <figure className="image container-image">
                                    <img className="image-logo" src={logo} alt=""/>
                                </figure>
                            </div>
                            <br/>
                            <div className="has-text-centered">
                                <h2 className="title is-3">SAG - Sistema de Autogestión de Recetas</h2>
                            </div>
                            <br/>
                            <br/>
                            <div className="FieldHorizontal field is-horizontal">
                                <div className="field-label width-min">
                                    <label className="label">Correo/Usuario</label>
                                </div>
                                <div className="field-body">
                                    <input
                                        id="txtUserOrEmail"
                                        className="input"
                                        type={'email'}
                                        value={this.state.email}
                                        placeholder={'Ingrese correo o usuario'}
                                        onChange={(e) => {
                                            let state = {...this.state};
                                            state.email = e.currentTarget.value;
                                            this.setState(state);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="FieldHorizontal field is-horizontal">
                                <div className="field-label width-min">
                                    <label className="label">Contraseña</label>
                                </div>
                                <div className="field-body">
                                    <input
                                        id="txtPassword"
                                        className="input"
                                        type={'password'}
                                        placeholder={'Ingrese contraseña'}
                                        value={this.state.password}
                                        onChange={(e) => {
                                            let state = {...this.state};
                                            state.password = e.currentTarget.value;
                                            this.setState(state);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="columns is-vcentered">
                                <div className="column is-8">
                                    <a href="https://auth.caffenio.com/change-password">Recuperar contraseña</a>
                                </div>
                                <div className="has-text-right column is-4">
                                    <button
                                        className="is-success button"
                                        disabled={!this.state.password || !this.state.email}
                                        onClick={() => this.login()}
                                    >
                                        Iniciar sesión
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}