import React, { Component } from 'react';
import {toast} from 'react-toastify';
import ClassificationForm from './ClassificationForm';
import ClasificacionesAPI from '../../../services/Classification';
import '../../cssComponents.css';
import Loading from '../../util/loading/Loading';
import Modal from '../../util/modal/Modal';
import ErrorMessages from '../../../util/validation-error-messages';

class NewClassification extends Component {
    constructor(props){
        super(props);
        this.state = {
            classification: {...this.props.classification},
            classifications :[],
            isLoading: false
        }
    }
    componentDidMount(){
        if (!this.props.location.state) {
            this.props.history.push(`/clasificaciones`);
            return;
        }
        this.setState({ classifications: this.props.location.state.classifications })
    }
    save = (classification) => {
        this.setState({isLoading: true}, () => {
                classification.Activo = true;
                classification.RecipeName = classification.ShortName;
                ClasificacionesAPI.create(classification).then(response => {
                    this.setState({isLoading: false})
                    this.props.history.goBack();
                    toast.success("Familia creada");
                }).catch(err => {
                    this.setState({isLoading: false})
                    if(err.response)
                        toast.error(err.response.data.Message);
                    else
                        toast.error(ErrorMessages.SinConexion)
                })
        })
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{cursor: "pointer", color: "#91BA43" }} onClick={() => {this.props.history.goBack()}} className="fa fa-arrow-left"></i>
                            </span>
                            <div>Nueva Familia
                                <i className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }} onClick={() => this.setState({ showModal: true })} className="fa fa-question-circle"></i>
                            </div>
                        </div>
                    </div>
                    <div className="card-content">
                        <ClassificationForm save={this.save} classifications = {this.state.classifications}/>
                    </div>
                </div>
                {this.state.showModal &&
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Información de Familia"}
                        cancel={() => this.setState({ showModal: false })}
                    >
                        <div className="has-text-justified">
                        
                            Las clasificaciones están directamente relacionadas a las recetas, se usa para clasificar por familias 
                            las recetas.
                            Según la familia asignada, se agrupan en los dispositivos del punto de venta, caja, pantalla drive y tablet.
                            <hr/>
                            <p className="has-text-justified">
                                <strong>Nombre</strong>: es un campo requerido, 
                                debe tener una longitud mínima de 2 a 30 caracteres máximo
                                 y no se debe duplicar.                     
                            </p>
                            <p className="has-text-justified">
                                <strong>Nombre corto</strong>: es un campo requerido, 
                                debe tener una longitud mínima de 2 a 15 caracteres máximo 
                                y no se debe duplicar.
                            </p>
                            <p className="has-text-justified">
                                <strong>Posición en Menú</strong>: valor numérico que representa el orden de visualización en los dispositivos de izquierda a derecha.
                                Debe ser mayor a 0, y no se aceptan duplicados.
                            </p>

                            <p className="has-text-justified">
                                <strong>Imagen</strong>: la imagen será la que se muestre en los puntos de venta, debe ser formato png y es requerido.
                            </p>
                        </div>
                       

                    </Modal>
                }
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
            </div>
        );
    }
}

export default NewClassification;
