import React, { useState, useEffect } from "react";
import SearchFormV6 from "../util/searchform/SearchFormV6";
import TablaEmpresas from "../Caffenio App/Empresas/TablaEmpresas";
import { toast } from "react-toastify";
import EmpresasAPI from "../../services/CaffenioEmpresas/Empresas";
import useModal from "../../util/hooks/useModal";
import PortalModal from "../../util/portals/PortalModal";
import { ModalEditarEmpresas } from "./ModalEditarEmpresa";

export default function Empresas({ history }) {
  const statusModal = useModal();
  const deleteModal = useModal();

  // Variables de filtos de la lista.
  const [nameSearch, setNameSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [segmentSearch, setSegmentSearch] = useState(0);
  const [activeSearch, setActiveSearch] = useState(true);
  const [empresas, setEmpresas] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [actualEmpresa, setActualEmpresa] = useState(null);
  const [segmentos, setSegmentos] = useState([]);
  const [listSegmentos, setListaSegmentos] = useState([]);
  const [detailVisible, setDetailVisible] = useState(false);
  const [isCreated, setCreated] = useState(false);
  const [isEditable, setEditable] = useState(false);

  const getEmpresas = async (
    name = "",
    page = 1,
    segmento = 0,
    activo = 1
  ) => {
    setLoading(true);
    const res = await EmpresasAPI.getAllBusiness(name, page, segmento, activo);
    if (res) {
      setEmpresas(res.data.Results);
      setTotalPages(res.data.TotalPages);
      setCurrentPage(res.data.CurrentPage);
      getSegmentos();
    } else {
      toast.error("Hubo un error al obtener las empresas.");
      setLoading(false);
    }
  };

  const getSegmentos = async () => {
    const res = await EmpresasAPI.getAllSegments();
    if (res) {
      setSegmentos(res.data);
    } else {
      toast.error("Hubo un error al obtener los segmentos.");
    }
    setLoading(false);
  };

  /**
   * Refrescar la tabla con la nueva empresa creada.
   * @param {*} businessUpdated - Info de la empresa actualizada.
   */
  const createBusiness = async () => {
    getEmpresas();
    toast.success("Empresa creada");
  };

  const changeStatus = async () => {
    let res = await EmpresasAPI.changeBusinessStatus(
      actualEmpresa.idEmpresa,
      !actualEmpresa.Activo
    );
    if (res.data && res.data.Success) {
      statusModal.toggle();
      toast.success("Estado actualizado.");
      getEmpresas(nameSearch, currentPage, segmentSearch, activeSearch);
    } else {
      toast.error(
        "No se pudo actualizar el estado de la empresa, intente más tarde."
      );
    }
  };

  /**
   * Eliminar una empresa de la tabla.
   * @param {*} businessDeleted - Info de la empresa a eliminar.
   */
  const deleteBusiness = async (businessDeleted) => {
    let res = await EmpresasAPI.deleteBusiness(businessDeleted);
    if (res.data && res.data.Success) {
      statusModal.toggle();
      toast.success("Empresa Eliminada.");
      getEmpresas(nameSearch, currentPage, segmentSearch, activeSearch);
    } else {
      toast.error("No se puedo eliminar la empresa seleccionada.");
    }
  };

  const createNewBusiness = () => {
    let listSegmentos = segmentos.map((item) => {
      return {
        value: item.SegmentId,
        label: item.Name,
      };
    });
    setListaSegmentos(listSegmentos);
    setCreated(true);
    setEditable(false);
    setDetailVisible(true);
  };

  useEffect(() => {
    getEmpresas(nameSearch.trim(), currentPage, segmentSearch, activeSearch);
  }, [nameSearch, currentPage, segmentSearch, activeSearch]);

  return (
    <div>
      <h1 className="title is-2">Administrar Empresas</h1>
      <SearchFormV6
        create={() => {
          createNewBusiness();
        }}
        segment={segmentSearch}
        segmentSearch={(idSegmento) => setSegmentSearch(idSegmento)}
        activate={activeSearch}
        activeSearch={(activeBusiness) => setActiveSearch(activeBusiness)}
        search={(text) => setNameSearch(text)}
        name={nameSearch}
        tooltip={"Crear una nueva empresa"}
        newBtnTitle={"Nueva empresa"}
      />
      <br />
      <TablaEmpresas
        empresas={empresas}
        segmentos={segmentos}
        totalPages={totalPages}
        currentPage={currentPage}
        changeStatus={(empresa) => {
          setActualEmpresa(empresa);
          statusModal.toggle();
        }}
        deleteBusiness={(empresa) => {
          setActualEmpresa(empresa);
          statusModal.toggle();
        }}
        goToPage={(page) => setCurrentPage(page)}
        delete={(empresa) => {
          setActualEmpresa(empresa);
          deleteModal.toggle();
        }}
        loading={loading}
        history={history}
      />
      <PortalModal
        isShowing={statusModal.isShowing}
        hide={statusModal.toggle}
        title="Confirmar"
        body={() => {
          return (
            <span>
              <p>{`¿Estás seguro que deseas ${
                actualEmpresa.Activo ? "desactivar" : "activar"
              } la empresa?, Una vez desactivada la empresa, 
              todos los empleados registrados dentro de esta empresa, ya no gozarán del beneficio del programa MI CAFFENIO Empresas. `}</p>
            </span>
          );
        }}
        footer
        footerButtons={() => {
          return (
            <div className="has-text-right modal-detalle-buttons">
              <button
                className="button"
                onClick={() => {
                  statusModal.toggle();
                  setActualEmpresa(null);
                }}
              >
                Cancelar
              </button>
              <button
                className={`button ${false ? "is-danger" : "is-success"}`}
                onClick={() => {
                  statusModal.toggle();
                  changeStatus();
                }}
              >
                Confirmar
              </button>
            </div>
          );
        }}
      />
      <PortalModal
        isShowing={statusModal.isShowing}
        hide={statusModal.toggle}
        title="Confirmar"
        body={() => {
          return (
            <span>
              <p>{`¿Estás seguro que deseas eliminar la empresa?`}</p>
            </span>
          );
        }}
        footer
        footerButtons={() => {
          return (
            <div className="has-text-right modal-detalle-buttons">
              <button
                className="button"
                onClick={() => {
                  statusModal.toggle();
                  setActualEmpresa(null);
                }}
              >
                Cancelar
              </button>
              <button
                className={`button ${false ? "is-danger" : "is-success"}`}
                onClick={() => {
                  statusModal.toggle();
                  deleteBusiness(actualEmpresa);
                }}
              >
                Confirmar
              </button>
            </div>
          );
        }}
      />
      {detailVisible && (
        <ModalEditarEmpresas
          isEditable={isEditable}
          isCreated={isCreated}
          empresa={actualEmpresa}
          segmentos={listSegmentos}
          onClose={() => setDetailVisible(false)}
          onSave={(businessCreated) => createBusiness()}
        />
      )}
    </div>
  );
}
