import * as React from "react";
import FieldAddons from "../field/Field+Addons";
import Select from "react-select";
import EmpresasAPI from "../../../services/CaffenioEmpresas/Empresas";
import { toast } from "react-toastify";

class SearchFormV6 extends React.Component {
  timer = null;
  propName = null;
  segmentos = [];

  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      nameSearch: "",
      activate: this.props.activate,
      segment: this.props.segment,
    };
    this.propName = this.props.name;
    this.getSegmentos();
  }

  getSegmentos = async () => {
    const res = await EmpresasAPI.getAllSegments();
    if (res) {
      let listSegmentos = res.data.map((item) => {
        return {
          value: item.SegmentId,
          label: item.Name,
        };
      });
      this.segmentos = listSegmentos;
      let defaultSegment = { value: 0, label: "Sin seleccionar" };
      this.segmentos.splice(0,0, defaultSegment);
    } else {
      toast.error("Hubo un error al obtener los segmentos.");
    }
  };

  // Se registra un escuchador del input de tipo Search
  componentDidMount() {
    document.addEventListener(
      "search",
      (e) => {
        this.setState({ nameSearch: e.target.value }, () => {
          this.props.search(e.target.value);
        });
      },
      false
    );
  }
  // Se elimina el escuchador.
  componentWillUnmount() {
    document.removeEventListener("search", () => {}, false);
  }

  onChangeCheckbox() {
    var activate = !this.state.activate;
    this.setState({ activate: activate }, () => {
      this.props.activeSearch(activate);
    });
  }

  // Change value of the segment of list.
  handleChangeSegmento = (segment) => {
    this.setState({ segment: segment }, () => {
      this.props.segmentSearch(segment.value);
    });
    // setState({ ...state, segmentSelected: segment });
  };

  render() {
    // Manipulación del nombre recibido por props en caso de ser necesario la restauración de búsqueda y página actual en tabla
    if (this.propName && this.state.nameSearch !== this.propName) {
      this.setState(
        { nameSearch: this.propName },
        () => (this.propName = null)
      );
    }
    return (
      <div>
        <div className="card">
          <div className="card-content">
            <div className="columns is-multiline">
              <div className="column is-12">
                <div className="field is-grouped is-grouped-right">
                  <p className="control is-expanded">
                    <FieldAddons isHorizontal>
                      <input
                        type="search"
                        className="input"
                        maxLength={50}
                        placeholder={`Buscar por Nombre`}
                        value={this.state.nameSearch}
                        autoFocus={true}
                        onChange={(e) => {
                          let nameSearch = { ...this.state.nameSearch };
                          nameSearch = e.currentTarget.value;
                          nameSearch = nameSearch.replace(/\s{2,}/g, " ");
                          // Se elimina el timer en caso de que el usuario siga tecleando
                          if (this.timer) clearTimeout(this.timer);
                          this.setState({ nameSearch: nameSearch }, () => {
                            // Cuando el usuario termina de escribir se hace la petición de búsqueda
                            this.timer = setTimeout(
                              () => this.props.search(nameSearch),
                              500
                            );
                          });
                        }}
                      />
                    </FieldAddons>
                  </p>
                  <p className="control">
                    <button
                      className={"button is-success tooltip"}
                      data-tooltip={this.props.tooltip}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.create();
                      }}
                    >
                      <span class="icon">
                        <i class="fa fa-plus"></i>
                      </span>
                      <span>{this.props.newBtnTitle}</span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div className="columns is-multiline">
              <div className="column is-4">
                <label className="label">Buscar por segmento</label>
                <Select
                  placeholder={"Seleccionar..."}
                  name={"selectedOption"}
                  value={this.state.segmentSelected}
                  onChange={(item) => this.handleChangeSegmento(item)}
                  noOptionsMessage={() => "No hay opciones"}
                  options={this.segmentos}
                />
              </div>
              <div className="column is-4" style={{ marginBlock: "auto" }}>
                <label className="label">
                  {this.state.activate
                    ? "Empresas activas"
                    : "Empresas inactivas"}
                </label>
                <input
                  type="checkbox"
                  name="activate"
                  className="switch"
                  checked={this.state.activate}
                  onChange={() => {}}
                />
                <label
                  disabled
                  htmlFor="activate"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onChangeCheckbox();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchFormV6;
