import {apiUrl} from '../constants';
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class ReporteProduccionAdelantadoAPI {

    static GetCaffeniosWithCalculations(){
        return request.get('ProyeccionesCalculo/GetCaffeniosWithCalculations')
    } 

    static GetCaffeniosListCalculations(filterData){
        console.log(`filterData`, filterData)
        return request.post('ProduccionAdelantadoCalculo/CaffeniosListCalculations', filterData)
    }

    // static CreateFormulaData(formulaData){
    //     return request.post('/FormulaExtracto/NewFormulaExtracto', formulaData);
    // }

    // static UpdateFormulaData(formulaData){
    //     return request.put('/FormulaExtracto/UpdateFormulaExtracto', formulaData);
    // }
}
