import { apiUrl }  from "../constants";
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class ClasificacionesAPI {
    static getAll() {
        return request.get('/Classification');
    }

    static get(id) {
        return request.get(`/Classification/${id}`);
    }

    static create(Classification) {
        let fd = new FormData();
        fd.append("Name", Classification.Name);
        fd.append("ShortName", Classification.ShortName);
        fd.append("Position", Classification.Position);
        fd.append("RecipeName", Classification.RecipeName);
        fd.append("IVAApply", Classification.IVAApply);
        fd.append("Activo", Classification.Activo);
        fd.append("SegmentId", Classification.SegmentationId.SegmentId)
        Classification.clasificacionGrupo && fd.append("IdClasificacionGrupo", Classification.clasificacionGrupo.value)

        Classification.Image && fd.append("Image", Classification.Image);
        Classification.Background && fd.append("Background", Classification.Background);
        Classification.Icon && fd.append("Icon", Classification.Icon);

        return request.post('/Classification',fd);
    }

    static edit(Classification){
        let fd = new FormData();
        console.log(Classification)
        fd.append("Name", Classification.Name);
        fd.append("ShortName", Classification.ShortName);
        fd.append("Position", Classification.Position);
        fd.append("RecipeName", Classification.RecipeName);
        fd.append("IVAApply", Classification.IVAApply);
        fd.append("ClassificationId", Classification.ClassificationId);
        fd.append("Activo", Classification.Activo);
        Classification.idSegmento && fd.append("SegmentId", Classification.idSegmento)
        Classification.clasificacionGrupo && fd.append("IdClasificacionGrupo", Classification.clasificacionGrupo.value)

        Classification.Image instanceof File && fd.append("Image", Classification.Image);
        Classification.Background instanceof File && fd.append("Background", Classification.Background);
        Classification.Icon instanceof File && fd.append("Icon", Classification.Icon);

        return request.put(`/Classification`,fd);
    }

    static delete(id){
        return request.delete(`Classification/${id}`);
    }
    static getAllClasificacionGrupo(){
        return request.get('/ClasificacionGrupo')
    }
}