import React, { Component } from 'react';
import { toast } from 'react-toastify'
import RecipeForm from './components/RecipeForm';
import RecipesAPI from '../../../services/Recipe';
import RecipesTypesAPI from '../../../services/RecipeType';
import ClasificacionesAPI from '../../../services/Classification';
import to from '../../../util/to';
import PresentationsAPI from '../../../services/Presentations';
import FlavorsAPI from '../../../services/Flavor';
import MilksAPI from '../../../services/Milk';
import GrainsAPI from '../../../services/Grain';
import SatAPI from '../../../services/Sat';
import '../../cssComponents.css';
import Loading from '../../util/loading/Loading';
import ErrorMessages from '../../../util/validation-error-messages';
import WarehouseAPI from '../../../services/Warehouse';

class NewRecipe extends Component {
    constructor(props){
        super(props);
        this.state = {
            recipe: {},
            classifications: [],
            presentations: [],
            flavors: [],
            recipeTypes: [],
            milks: [],
            grains: [],
            satClassifications: [],
            satCodes: [],
            dataLoaded: false,
            units: [],
            isLoading: false,
            //Estados para obtener estados, ciudades y drives
            optionState: [],
            optionCity: [],
            optionDrive: [],
            Caffenios: [],
        };
    }

    componentWillMount(){
        this.setState({isLoading: true})
        if(this.props.match.params.id){
            RecipesAPI.get(this.props.match.params.id).then(response => {
                
                let detailRecipe = {...response.data};
                detailRecipe.Details = detailRecipe.Details.map((item)=>{
                    let newItem = {...item.InventoryItem};
                    newItem.Amount = item.Amount;
                    newItem.RecipeDetailId = item.RecipeDetailId;
                    return newItem
                });
                detailRecipe.DetailsGroupBy = this.groupByRecipeDetail(detailRecipe.Details);
                
                // detailRecipe.Toppings = [];

                if(detailRecipe.CodeSatId){
                    detailRecipe.CodeSatId = detailRecipe.CodeSatId.trim();
                }
                
                
                this.setState({recipe: detailRecipe}, () => {this.getData(); this.getDrives()})
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        }else{
            this.setState({recipe: {
                ShortName: "",
                Description: "",
                ClassificationId: 0,
                PresentationId: 0,
                FlavorTypeId: 0,
                RecipeTypeId: 0,
                IVAApply: true,
                KiwiId: 0,
                MilkTypeId: 0,
                GrainTypeId: 0,
                Active: true,
                MobileApply: true,
                WelcomeGiftApply: true,
                ClassificationSatId: 0,
                CodeSatId: 0,
                Details: [],
                Toppings: []}
            }, () => {this.getData(); this.getDrives()});
            
        }
        
    }


    /**
     * Agrupa por aticulo y cantidad el listado de articulos asignados a la receta 
     */
    groupByRecipeDetail = (arrDetail) => {
        var resultArray = [];
        try {
            resultArray = arrDetail.reduce((listado , item) => {
                let drive = {
                    RecipeDetailId : item.RecipeDetailId,
                    idDrive : item.idDrive,
                    DriveNombre : item.DriveNombre,
                    DriveEstado : item.DriveEstado,
                    DriveMunicipio : item.DriveMunicipio,
                    idEstado : item.idEstado,
                    idMunicipio: item.idMunicipio
                    
                }
    
                let city = {
                    DriveEstado : item.DriveEstado,
                    DriveMunicipio : item.DriveMunicipio,
                    idEstado : item.idEstado,
                    idMunicipio: item.idMunicipio
                }                
                
                let existe = listado.filter(a => {
                    if(a.Amount === item.Amount &&  a.InventoryItemId === item.InventoryItemId){                    
                        a.driveList.push(drive);

                        if(a.driveList.length > 1){
                            let filtraDrives = a.driveList.reduce((lstDrives, dr) => {
                                if(!lstDrives.includes(dr.DriveNombre)){
                                    lstDrives.push(dr.DriveNombre)
                                }
                                return lstDrives;
                            }, []);

                            if(filtraDrives.length <= 4){
                                a.DriveNombre = filtraDrives.join(", ");
                            }else{
                                let filtraCiudades = a.driveList.reduce((lstCiudades, city) => {
                                    if(!lstCiudades.includes(city.DriveMunicipio)){
                                        lstCiudades.push(city.DriveMunicipio)
                                    }
                                    return lstCiudades;
                                }, []);
    
                                if(filtraCiudades.length > 4){
                                    let filtraEstados = a.driveList.reduce((lstEstados, city) => {
                                        if(!lstEstados.includes(city.DriveEstado)){
                                            lstEstados.push(city.DriveEstado)
                                        }
                                        return lstEstados;
                                    }, []);
                                    a.DriveNombre = filtraEstados.join(", ");                                
                                }else{
                                    a.DriveNombre = filtraCiudades.join(", ");
                                }
                            }                            
                        }
                        else{
                            a.DriveNombre = item.DriveNombre;
                        }

                        if(!a.cities.some(c=> c.idEstado === item.idEstado && c.idMunicipio === item.idMunicipio))
                            a.cities.push(city); 
                        
                        return a;
                    }               
                });            
                          
                if (existe.length === 0) {
                    item.driveList = [drive];   
                    item.cities = [city];             
                    listado.push(item);
                }          
    
                return listado;
            }, []);
        } catch (error) {
            resultArray = arrDetail;
        }
        return resultArray;
    }

    getData = async () => {
        if (!this.props.location.state) {
            this.props.history.push(`/recetas`);
            return;
        }
        //let err, classifications, presentations, flavors, milks, grains, recipeTypes, satClassifications, satCodes;
        let err,  satClassifications, satCodes;
        // [err,classifications] = await to(ClasificacionesAPI.getAll());
        // [err, presentations] = await to(PresentationsAPI.getAll());
        // [err, flavors] = await to(FlavorsAPI.getAll());
        // [err, milks] = await to(MilksAPI.getAll());
        // [err, grains] = await to(GrainsAPI.getAll());
        // [err, recipeTypes] = await to(RecipesTypesAPI.getAll());
        [err, satClassifications] = await to(SatAPI.getClassifications());
        [err, satCodes] = await to(SatAPI.getCodes());

        this.setState({
            classifications: this.props.location.state.classifications,
            presentations: this.props.location.state.presentations.filter(p=>p.Active),
            flavors: this.props.location.state.flavors,
            milks: this.props.location.state.milks,
            grains: this.props.location.state.grains,
            recipeTypes: this.props.location.state.recipeTypes,
            satClassifications: satClassifications,
            satCodes: satCodes,
            dataLoaded: true,
            isLoading: false
        })
    }

    /**
     * Valida y guarda la receta
     * @param recipe Objeto. La receta que se va a guardar
     * @param applySync Bool. Indica si se va a sincronizar la receta despues de guardarla.
     */
    save = (recipe, applySync) => {
        if(applySync === undefined) applySync = false;
        let data = {...recipe};
        let recipeInventoryItems = []
        data.Details.forEach(d => {
            if(d.DriveId){
                d.DriveId.forEach(driveId => {
                    recipeInventoryItems.push({Amount: d.Amount, InventoryItem: {...d, idDrive: driveId.value, DriveId: []}});
                })
            }else{
                recipeInventoryItems.push({Amount: d.Amount, InventoryItem: {...d}});
            }
        })
        data.Details = recipeInventoryItems
        data.Description = data.Description.trim();
        data.ShortName = data.ShortName.trim();
        if(data.Description.length < 3){
            toast.error("Descripción debe de tener más de 2 caracteres");
            return;
        }
        // if(data.ShortName.length < 3){
        //     toast.error("Nombre Corto debe de tener más de 2 caracteres");
        //     return;
        // }
        if(data.ClassificationId === 0){
            toast.error("Selecciona una Familia");
            return;
        }
        if(data.PresentationId === 0){
            toast.error("Selecciona una Presentación");
            return;
        }
        if(data.FlavorTypeId === 0){
            toast.error("Selecciona un Tipo de Sabor");
            return;
        }
        if(data.RecipeTypeId === 0){
            toast.error("Selecciona un Tipo de Receta");
            return;
        }
        if(data.MilkTypeId === 0){
            toast.error("Selecciona un Tipo de Leche");
            return;
        }
        if(data.GrainTypeId === 0){
            toast.error("Selecciona un Tipo de Grano");
            return;
        }
        if(data.ClassificationSatId === 0){
            toast.error("Selecciona una Clasificación del SAT");
            return;
        }
        if(data.CodeSatId === 0){
            toast.error("Selecciona un Código del SAT");
            return;
        }
        if(data.Details.length>0){
            let validar = true;
            data.Details.map((item)=>{
                if(item.Amount == undefined){
                    toast.error("Ingrese una Cantidad a detalle de "+item.InventoryItem.Name)
                    validar = false;
                }
            })

            if(validar===false){
                return;
            }
        }

        if(data.Toppings.length > 0){
            let validar = true;
            data.Toppings.map(item => {
                if(Number(item.MinQuantity) > Number(item.MaxQuantity)){
                    toast.error("Cantidades incorrectas en Topping '" + item.Name + "'")
                    validar = false;
                }
            })

            if(validar === false){
                return;
            }
        }

        this.setState({isLoading: true}, () => {
            if(data.RecipeId){
                // Indicar si se va a sincronizar
                data.ApplySync = applySync
                RecipesAPI.edit(data).then(response => {
                    this.setState({isLoading: false})
                    toast.success("Receta actualizada");
                    this.props.history.goBack();
                }).catch(err => {
                    this.setState({isLoading: false})
                    if(err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                });
            } else{
                RecipesAPI.create(data).then(response => {
                    this.setState({isLoading: false})
                    toast.success("Receta creada");
                    this.props.history.goBack();
                }).catch(err => {
                    this.setState({isLoading: false})
                    if(err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                });
            }
        })
    }

    /*Función para obtener las ciudades que se pone en el set state */
    getDrives = async () =>{
        
        let err, caffenios;
        [err,caffenios] = await to(WarehouseAPI.getCaffenios());

        if(err){
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        }else{
            this.setState({
                optionState: this.select_state(caffenios),
                optionCity: this.select_city(caffenios),
                optionDrive:this.select_Drive(caffenios),
                Caffenios:caffenios,
                isLoading: false
            })
        }
    }

    select_state(Caffenios) { // Llenar el combo 'Estados' con todas las opciones 
        var newArray = [];
        let OptionSelect = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelect.push({value:item.StateId,label:item.State});
        });

        for(var i in OptionSelect) {
            lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        
        return newArray;
    }

    select_city(Caffenios) { // Llenar el combo 'Ciudades' con todas las opciones 
        var newArray = [];
        let OptionSelectCity = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelectCity.push({value:item.Municipalityid,label:item.Municipality});
        });

        for(var i in OptionSelectCity) {
            lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    select_Drive(Caffenios) { // Llenar el combo 'Drives' con todas las opciones 
        var newArray = [];
        let OptionSelectDrive = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelectDrive.push({value:item.idCaffenio,label:item.NombreNuevo});
        });

        for(var i in OptionSelectDrive) {
            lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{cursor: "pointer", color: "#91BA43" }} onClick={() => {this.props.history.goBack()}} className="fa fa-arrow-left"></i>
                            </span>
                            {this.state.recipe.RecipeId ? "Editar Receta" : "Nueva Receta"}
                        </div>
                    </div>
                    <div className="card-content">
                        {this.state.dataLoaded && <RecipeForm 
                            recipe={this.state.recipe} 
                            save={this.save}
                            classifications={this.state.classifications}
                            presentations={this.state.presentations}
                            flavors={this.state.flavors}
                            milks={this.state.milks}
                            grains={this.state.grains}
                            recipeTypes={this.state.recipeTypes}
                            satClassifications={this.state.satClassifications}
                            satCodes={this.state.satCodes}
                            optionState={this.state.optionState}
                            optionCity={this.state.optionCity}
                            optionDrive={this.state.optionDrive}
                            Caffenios={this.state.Caffenios}
                        />}
                    </div>
                </div>

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
            </div>
        );
    }
}

export default NewRecipe;
