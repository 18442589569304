import React, { Component } from 'react';
import Field from '../../util/field/Field';
import Validators from '../../../util/validators';
import ErrorMessages from '../../../util/validation-error-messages';
import ExpresionesRegulares from '../../../util/expresiones-regulares';
import { Formik } from 'formik';
import * as Yup from 'yup';
import lodash from 'lodash';
import Select from 'react-select';
import EmpresasAPI from '../../../services/CaffenioEmpresas/Empresas';
import { toast } from 'react-toastify';

class MilkForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            milk: { ...this.props.milk },
            isEditing: this.props.isEditing,
            segments: []
        }
    }

    componentWillMount() {
        this.setState({ isLoading: true }, () => {
            EmpresasAPI.getAllFilterSegments().then(response => {
                let tempSegmentsList = []
                response.data.map(obj => {
                    tempSegmentsList.push(obj)
                })
                this.setState({ segments: tempSegmentsList });
            }).catch(err => {
                this.setState({ isLoading: false })
                if (err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            })
        })
    }

    /**Obtiene la posición máxima para recomendar la siguiente */
    getNextMaxPosition = () => {
        let maxPosition = lodash.maxBy(this.props.milks, function (o) { return o.Position; });

        return maxPosition ? maxPosition.Position : 1;
    }

    VALIDATION_SCHEMA = Yup.object({
        Description: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .required("Campo Nombre es requerido."),
        // .test("validate-name", "El Nombre de tipo grano ya existe, favor de ingresar otro.", (value) => {
        //     let isValid = true;
        //     let name = value ? value.toLowerCase().trim() : "";
        //     if (this.props.milks.some(u => u.Description.toLowerCase().trim() === name && u.MilkTypeId !== this.state.milk.MilkTypeId)) {
        //         isValid = false;
        //     }
        //     return isValid;
        // }),
        Position: Yup.number()
            .min(1, "Ingrese valores mayores a 0.")
            .max(999, "Ha excedido el límite de caracteres.")
            .required("Campo Posición es requerido."),
        Image: Yup.mixed()
            .required("Campo Imagen es requerido.")
            // .test(
            //     "fileSize",
            //     "El archivo es muy grade.",
            //     value => value && value.size >= FILE_SIZE
            // )
            .test(
                "fileFormat",
                ErrorMessages.PngFormatError,
                value => value && Validators.SUPPORTED_FORMATS.includes(value.type) || (this.state.milk.UrlImage !== null && this.state.milk.UrlImage.length > 0)
            ),
            SegmentationId: Yup.string()
            .when([], {
                is: () => !this.state.isEditing,
                then: Yup.string().required("Segmentación es requerido.").nullable(),
                otherwise: Yup.string().notRequired(),
            }),
    });

    /**
     * Previene el submit al dar enter
     * @param {*} keyEvent 
     */
    onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }
    render() {
        return (
            <Formik
                initialValues={this.state.milk}
                validationSchema={this.VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    let formatValues = { ...values };
                    formatValues.Description = formatValues.Description.trim();
                    this.props.save(formatValues)
                }}
            >
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                    <form onSubmit={handleSubmit} id="frmMilkType" onKeyDown={this.onKeyDown}>
                        {!this.state.isEditing &&
                            <Field
                                label="Segmentación"
                                hasError={errors.SegmentationId || touched.SegmentationId}
                                msgError={errors.SegmentationId || touched.SegmentationId ? errors.SegmentationId : ""}
                                isHorizontal
                            >
                                <div className="control" style={{ width: "100%" }}>
                                    <Select
                                        id="ddlSegmentation"
                                        name={"SegmentationId"}
                                        value={values.SegmentationId}
                                        placeholder={"Seleccionar..."}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={(val) => {
                                            setFieldValue('SegmentationId', val)
                                        }}
                                        onBlur={() => setFieldTouched('SegmentationId', true)}
                                        options={this.state.segments}
                                        getOptionLabel={(objSegment) => {
                                            switch (objSegment.Name) {
                                                case "Andatti drive":
                                                    return "Andatti"
                                                case "Drive":
                                                    return "Caffenio"
                                                case "momento espresso":
                                                    return "Momento Espresso"
                                                default:
                                                    return objSegment.Name
                                            }
                                        }}

                                        getOptionValue={(objSegment) => objSegment.SegmentId}
                                    />
                                </div>
                            </Field>
                        }   
                        <br />
                        <Field
                            label="Nombre"
                            isHorizontal
                            hasError={errors.Description && touched.Description}
                            msgError={errors.Description && touched.Description ? errors.Description : ""}
                            showLeftSpaces={true}
                            childInfo={values.Description && !errors.Description
                                ? `Te quedan ${50 - values.Description.trim().length} caracteres para el nombre.`
                                : ""}
                        >
                            <input
                                id="txtDescriptionMilkType"
                                name={"Description"}
                                className={`input ${errors.Description && touched.Description ? "is-danger" : ""}`}
                                placeholder={"Ingrese nombre de tipo leche"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Description}

                            />
                        </Field>
                        <br />
                        <Field

                            isHorizontal
                            label={"Posición en Menú"}
                            hasError={errors.Position || touched.Position}
                            msgError={errors.Position || touched.Position ? errors.Position : ""}
                        >
                            <input
                                id={"txtPositionMilkType"}
                                className={`input ${errors.Position && touched.Position ? "is-danger" : ""}`}
                                type="number"
                                label="Posición en Menú"
                                name="Position"
                                value={values.Position}
                                onChange={handleChange}
                                onKeyDown={event => {
                                    if (event.keyCode === 69 || event.keyCode === 109 || event.keyCode === 110 || event.keyCode === 107
                                        || event.keyCode === 189 ||
                                        event.keyCode === 187 ||
                                        event.keyCode === 190) {
                                        event.preventDefault();
                                        setFieldValue("Position", values.Position);
                                        return false
                                    } else {
                                        return true
                                    }
                                }}
                                onBlur={handleBlur}
                                placeholder={`La última posición registrada es ${this.getNextMaxPosition()}`}
                            />
                        </Field>
                        <br />
                        <Field label="Imagen" isHorizontal
                            hasError={errors.Image && touched.Image}
                            msgError={errors.Image && touched.Image ? errors.Image : ""}>
                            <div className={`file ${errors.Image && touched.Image ? "is-danger" : "is-info"} has-name`}>
                                <label className="file-label">
                                    <input
                                        className={`input file-input ${errors.Image && touched.Image ? "is-danger" : ""}`}
                                        id="fileMilkType"
                                        type="file"
                                        name="Image"
                                        accept="image/x-png"
                                        name="Image"
                                        onChange={(e) => {
                                            let files = e.target.files
                                            let file = files[0]
                                            e.stopPropagation();
                                            e.target.value = null;
                                            if (file) {
                                                let item = { ...this.state.milk }
                                                item.UrlImage = "";
                                                this.setState({
                                                    milk: item
                                                }, () => {
                                                    setFieldValue('Image', file);
                                                    setFieldValue('UrlImage', "")
                                                    var reader = new FileReader();
                                                    reader.onload = function (e) {
                                                        let previewControl = document.getElementById("imgPreview");
                                                        if (previewControl) {
                                                            document.getElementById("imgPreview").setAttribute('src', e.target.result);
                                                        }
                                                    }
                                                    reader.readAsDataURL(file);
                                                })
                                            } else {
                                                e.target.value = null;
                                                setFieldValue('Image', null)
                                                setFieldValue('UrlImage', "")

                                            }

                                        }}

                                    />
                                    <span className="file-cta">
                                        <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo .PNG</i></span>
                                    </span>
                                    <span className="file-name">{values.Image == null ? "Archivo" : values.Image.name}</span>
                                </label>
                            </div>
                            {values.Image !== null &&

                                <Field>
                                    <button className="button tableAction is-danger trash-right"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.target.value = null;
                                            let item = { ...this.state.milk }
                                            item.UrlImage = "";
                                            this.setState({
                                                milk: item
                                            }, () => {
                                                setFieldValue('Image', null)
                                                setFieldValue('UrlImage', "")
                                            })

                                        }
                                        }>
                                        <span className="icon">
                                            <i className="fa fa-trash" />
                                        </span>
                                    </button>
                                </Field>
                            }
                        </Field>
                        {values.Image !== null
                            && (values.UrlImage === null || values.UrlImage.length === 0)
                            && <Field>
                                <div className="column">
                                    <figure className="image container-image">
                                        <img id={"imgPreview"} src="#" style={{ width: 'auto', height: '50px' }} alt="" />
                                    </figure>
                                </div>
                            </Field>
                        }
                        {values.UrlImage && <Field>
                            <div className="column">
                                <figure className="image container-image">
                                    <img
                                        src={values.UrlImage.toString() + "?" + new Date().getTime()}
                                        style={{ width: 'auto', height: '50px' }} alt="" />
                                </figure>
                            </div>
                        </Field>}

                        <div className="has-text-right buttons-modal">
                            <div className="column">
                                <button
                                    id={"btnSubmitMilkType"}
                                    className="button is-success"
                                    type={"submit"}>
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                )}

            </Formik>
        );
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
MilkForm.defaultProps = {
    isEditing: false,
    milk: {
        Description: "",
        Position: "",
        UrlImage: null,
        RecipeName: "",
        Image: null
    },
    segments: []
}

export default MilkForm;
