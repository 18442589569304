import { apiUrl } from "../../constants";
import axios from "axios";
import middleware from "../../middleware/auth";
var request = axios.create({
  baseURL: apiUrl,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "application/json",
  },
});

export default class EmpresasAPI {
  // Get all business with pagination.
  static getAllBusiness(search = "", page = 1, segmento = 0, activo = true) {
    return middleware.hasAuth(() =>
      request.get(
        `/CaffenioEmpresas/GetAllBusiness?search=${search}&page=${page}&segment=${segmento}&active=${activo}`
      )
    );
  }

  // Get All segments actives.
  static getAllSegments() {
    return middleware.hasAuth(() => request.get(`/GetAllSegments`));
  }

  static getAllFilterSegments() {
    return middleware.hasAuth(() => request.get(`/GetAllFilterSegments`));
  }

  // Get business by ID.
  static getBusiness(idBusiness) {
    return middleware.hasAuth(() => request.get(`Empresa/${idBusiness}`));
  }

  // Change status of business.
  static changeBusinessStatus(id, value) {
    return middleware.hasAuth(() =>
      request.put(`/CaffenioEmpresas/ActiveOrDeactivateBusiness`, {
        idEmpresa: id,
        Activo: value,
      })
    );
  }

  // Update information of the business.
  static updateInfoToBusiness(infoUpdated) {
    return middleware.hasAuth(() =>
      request.patch("/CaffenioEmpresas/UpdateBusiness", infoUpdated)
    );
  }

  static createNewBusiness(businessCreated) {
    return middleware.hasAuth(() =>
      request.post("/CaffenioEmpresas/CreateBusiness", businessCreated)
    );
  }

  // Delete business.
  static deleteBusiness(businessDeleted) {
    return middleware.hasAuth(() =>
      request.delete("CaffenioEmpresas/DeleteBusiness", {
        data: businessDeleted,
      })
    );
  }
}
