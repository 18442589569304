import React, { Component } from 'react';
import TextField from '../util/textfield/TextField';
import Field from '../util/field/Field';
import Loading from '../util/loading/Loading';
import PriceDriveAPI from '../../services/PriceDrive';
import ErrorMessages from '../../util/validation-error-messages';
import ExpresionesRegulares from '../../util/expresiones-regulares';
import { Formik } from 'formik';
import * as Yup from 'yup';
import lodash from 'lodash';
import Select from 'react-select';

class PriceListForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            priceList: { ...this.props.priceList },
            isEditing: false,
            isLoading: false,
            activeList: [],
            selectedOption: null
        }
    }

    componentDidMount() {
        PriceDriveAPI.get().then(response => {
            let state = { ...this.state };
            state.activeList = this.fncActiveList(response.data);
            this.setState(state);

        }).catch(err => {
            this.props.history.push(`/lista-precios`);
        });
    }

    VALIDATION_SCHEMA = Yup.object({
        Description: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(30, "Ha excedido el límite de 50 caracteres.")
            .required("Campo Descripción es requerido.")
            .test("validate-name", "La Descripción ya existe, favor de ingresar otro.", (value) => {
                let isValid = true;
                let name = value ? value.toLowerCase().trim() : "";
                if (this.props.pricesList.some(u => u.Description.toLowerCase().trim() === name && u.PriceListId !== this.state.priceList.PriceListId)) {
                    isValid = false;
                }
                return isValid;
            }),
        BaseOn: Yup.string().nullable(),
        Active: Yup.bool(),


    });

    fncActiveList = (listas) => {
        let state = this.state;
        let arrListActive = [];
        listas.map((lista) => {
            if (lista.Active) {
                arrListActive.push({ "value": lista.PriceListId, "label": lista.Description });
            }
        })
        return arrListActive;
    }

    

    render() {
        return (
            <Formik
                initialValues={this.state.priceList}
                validationSchema={this.VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    let formatValues = { ...values }
                    formatValues.Description = formatValues.Description.trim();
                    formatValues.PriceListBasedId =  values.BaseOn ? values.BaseOn.value : 0;
                    this.props.save(formatValues)
                }}
            >
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }) => (
                    <form onSubmit={handleSubmit} id={"frmListaPrecios"}>
                        <Field

                            isHorizontal
                            hasError={errors.Description && touched.Description}
                            msgError={errors.Description && touched.Description ? errors.Description : ""}
                            showLeftSpaces={true}
                            childInfo={values.Description && !errors.Description
                                ? `Te quedan ${50 - values.Description.trim().length} caracteres para la descripción.`
                                : ""}
                        >
                            <TextField
                                id={"txtDescipcionListaPrecio"}
                                autoFocus={true}
                                required
                                maxLength="50"
                                label="Descripción"
                                name="Description"
                                value={values.Description}
                                placeholder={"Ingrese descripción de la lista de precio"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Field>
                        <br />
                        {this.state.priceList.PriceListId == null &&
                            <Field
                                isHorizontal
                                showTooltip={true}
                                textTooltip={"Selecciona si desea los mismos precios y artículos."}>
                                <div className="column is-2">
                                    <label className="label">Basar Lista en</label>
                                </div>
                                <div className="column is-10">
                                    <Select
                                        id={"ddlBaseOn"}
                                        placeholder={"seleccionar..."}
                                        name={"BaseOn"}
                                        value={values.BaseOn}
                                        onChange={(val) => {
                                            setFieldValue('BaseOn', val);
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={lodash.sortBy(this.state.activeList, [ts => ts.label.toLowerCase()], ['asc'])}
                                    />
                                </div>


                            </Field>}

                        <br />
                        <Field label="Activo" isHorizontal>
                            <input
                                id="chkActivoLista"
                                type="checkbox"
                                name="activo"
                                className="switch"
                                checked={values.Active}
                                onChange={() => { }}
                            />
                            <label htmlFor="iva" onClick={(e) => {
                                e.stopPropagation();
                                setFieldValue("Active", !values.Active)
                            }} />
                        </Field>
                        <br />
                        <div className="has-text-right buttons-modal">
                            <div className="column">
                                <button
                                    type="submit"
                                    className="button is-success"
                                >
                                    Guardar
                        </button>
                            </div>
                        </div>
                        <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
                    </form>
                )}
            </Formik>
        );
    }
}

PriceListForm.defaultProps = {
    isEditing: false,
    isLoading: false,
    priceList: {
        Description: "",
        Active: false
    }
}

export default PriceListForm;
