
import React , {Component} from 'react';
import CaffenioDriveAPI from '../../services/caffenioDrive';
import PriceDriveAPI from '../../services/PriceDrive';
import MenuDriveAPI from '../../services/MenuDrive';
import ConfigurationVPAPI from '../../services/ConfigurationVP';
import  NoItems  from "../util/noitems/NoItems";
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading';
import Dropdown from '../util/dropdown/Dropdown';
import CaffenioForm from './CaffenioForm';
import {ConfiguracionVP} from '../ConfiguracionVP';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Field from '../util/field/Field';
import Modal from '../util/modal/Modal';
import HasAccess from '../util/hasAccess/HasAccess';
import './caffenioStyle.css';
import lodash from 'lodash';

import BtnModoGuiado from '../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../util/validation-error-messages';
import roles from '../../util/strings/roles';

class CaffenioDrive extends Component {

    state={
        isLoading: false,
        pageDrives: [],
        selectedOption: [],
        selectedOptionC: [],
        selectedOptionD: [],
        OptionSelect: [],
        OptionSelectCity: [],
        OptionSelectDrive: [],
        optionState: [],
        optionCity: [],
        optionDrive: [],
        Caffenios: [],
        CaffeniosFilter: [],
        activeAll: false,
        arrListMenu: [],
        arrListPrices: [],
        selectedMenuAll: null,
        tempAllMenu: null,
        tempAllList: null,
        selectedPricesAll: null,
        filterText: "",
        childVisible: false,
        checkAll: false,
        switchAllActive: false,
        showModal: false,
        caffenio: null,
        showCaffenio: false,
        configVP: null,
        showConfigVP: false,
        tipoDeSolicitudes: [],

    }

    cancel = () => {
        this.setState({showModal: false,  showConfigVP: false, configVP: null})
    }


    componentDidMount(){
        this.setState({isLoading: true}, () => {
            let state = {...this.state}
            CaffenioDriveAPI.getCaffenios().then(response => {
                //console.log("Caffenios result >>", response);
                state.Caffenios = response.data;
                state.CaffeniosFilter = response.data;
                state.Caffenios.map(producto => {
                    producto.statusCheck = false;
                    return producto
                })
                state.optionState = this.select_state(state.Caffenios);
                state.optionCity = this.select_city(state.Caffenios);
                state.optionDrive = this.select_Drive(state.Caffenios);
                return MenuDriveAPI.get();
            }).then(response => {
                let data = response.data;
                let finalData = [];
                data.map((item)=>{
                    if(item.Active){
                        finalData.push(item)
                    }
                })
                state.arrListMenu = lodash.sortBy( finalData,  [ts => ts.Description.toLowerCase()], ['asc']);
                return PriceDriveAPI.get();
            }).then(response => {
                let data = response.data;
                let finalData = [];
                data.map((item)=>{
                    if(item.Active){
                        finalData.push(item)
                    }
                })
                state.arrListPrices = lodash.sortBy( finalData,  [ts => ts.Description.toLowerCase()], ['asc']);;
                state.isLoading = false;
                return ConfigurationVPAPI.GetTipoSolicitudes();
            }).then(response => {
                let data = response.data;
                let finalData = [];
                data.map((item)=>{
                    finalData.push(item);
                    
                })
                state.tipoDeSolicitudes = finalData;
                this.setState(state);
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        })
        
    }

    handlePageChange = (drives) => { // Hacer la paginación en la tabla
        let listDrives = [...this.state.Caffenios];
        let listProduct = drives.map(obj => { 
            let driveid = obj.DriveId;            
            let objCaffDrive = listDrives.find(obj => obj.idCaffenio === driveid)                   
            obj["nombreDrive"] = objCaffDrive ? objCaffDrive.NombreNuevo : "Sin asignar";            
            return obj
        } );

        this.setState({pageInventoryItems:listProduct});
    }

    handleSearchInput = (text) => {
        let state = {...this.state};
        state.filterText = text;
        this.setState(state, () => this.FilterProcess());
    }

    select_state(Caffenios) { // Llenar el combo 'Estados' con todas las opciones 
        var newArray = [];
        let OptionSelect = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelect.push({value:item.StateId,label:item.State});
        });

        for(var i in OptionSelect) {
            lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        
        return newArray;
    }

    select_city(Caffenios) { // Llenar el combo 'Ciudades' con todas las opciones 
        var newArray = [];
        let OptionSelectCity = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelectCity.push({value:item.Municipalityid,label:item.Municipality});
        });

        for(var i in OptionSelectCity) {
            lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    select_Drive(Caffenios) { // Llenar el combo 'Drives' con todas las opciones 
        var newArray = [];
        let OptionSelectDrive = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelectDrive.push({value:item.idCaffenio,label:item.NombreNuevo});
        });

        for(var i in OptionSelectDrive) {
            lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    eliminarObjDuplicados = (arr,prop) => {
        var nuevoArray = [];
        var lookup  = {};
    
        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }
    
        for (i in lookup) {
            nuevoArray.push(lookup[i]);
        }
        
        return nuevoArray;
    }

    changeSelectEstado = (obj) => {
        let state = {...this.state}
        state["selectedOption"] = obj;
        let idEstado = obj.map(estado => {return estado.value});
        let objCaffenios = [...state.Caffenios];
        let listaCiudades = [];
        let listaDrives = [];

        // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
        objCaffenios.map(item =>{
            for(var i=0; i<idEstado.length; i++){
                if(item.StateId===idEstado[i]){
                    listaCiudades.push({"value":item.Municipalityid,"label":item.Municipality});
                }
            }
        })

        //Se eliminan las ciudades duplicadas
        let eliminarDuplicados = this.eliminarObjDuplicados(listaCiudades,"value");
        //Se obtiene un arreglo con los id de las ciudades
        let idCiudad = eliminarDuplicados.map(ciudad => {return ciudad.value});

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item =>{
            for(var i=0; i<idCiudad.length; i++){
                if(item.Municipalityid===idCiudad[i]){
                    listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
                }
            }
        })

        //Se limpian los combos 'Ciudades' y 'Drives'
        state["selectedOptionC"] = [];
        state["selectedOptionD"] = [];

        //Se valida que haya una opción seleccionada en combo 'Estados'
        if(obj.length===0){
            state["optionCity"] = this.select_city(state.Caffenios);
            state["optionDrive"] = this.select_Drive(state.Caffenios);
            
        } else{
            state["optionCity"] = eliminarDuplicados;
            state["optionDrive"] = listaDrives;
        }

        this.setState(state, () => this.FilterProcess());
    }

    changeSelectCiudad = (obj) =>{
        let state = {...this.state}
        let idCiudad = obj.map(ciudad => {return ciudad.value});
        let objCaffenios = [...state.Caffenios];
        let listaDrives = [];

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item =>{
            for(var i=0; i<idCiudad.length; i++){
                if(item.Municipalityid===idCiudad[i]){
                    listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
                }
            }
        })

        //Se limpia el combo 'Drives'
        state["selectedOptionD"] = [];

        //Se valida que haya una opción seleccionada en combo 'Ciudades'
        if(obj.length>0){
            state["selectedOptionC"] = obj;
            state["optionDrive"] = listaDrives;
            this.setState(state, () => this.FilterProcess());
        } else{
            this.changeSelectEstado(state["selectedOption"]);
        }
    }

    changeSelectDrive = (obj) =>{
        let state = {...this.state};
        let Caffenios = [...state.Caffenios];
        
        //Valida cada select (Estados,Ciudades,Drives) y realiza la acción correspondiente
        if(obj.length>0){
            state["selectedOptionD"] = obj;
            //state["optionDrive"] = obj;
            this.setState(state, () => this.FilterProcess());
        } else if(state["selectedOptionC"].length===0){
            this.changeSelectEstado(state["selectedOption"]);
        } else if(state["selectedOptionC"].length===0 && state["selectedOption"].length===0){
            this.select_Drive(Caffenios);
            this.select_Drive(Caffenios);
            this.select_Drive(Caffenios);
        } else{
            this.changeSelectCiudad(state["selectedOptionC"]);
        }
    }

    handleDropdown = (nuevoValor, attr, item) => {
        if(attr === "idMenu"){
            let indexMenu = this.state.arrListMenu.findIndex(menu => menu.MenuId === nuevoValor.MenuId)
            item[attr] = indexMenu === -1 ? 0 : this.state.arrListMenu[indexMenu].MenuId;
        } else{
            let indexLista = this.state.arrListPrices.findIndex(lista => lista.PriceListId === nuevoValor.PriceListId)
            item[attr] = indexLista === -1 ? 0 : this.state.arrListPrices[indexLista].PriceListId;
        }
        
        let copyItem = {...item};
        delete copyItem.statusCheck;

        CaffenioDriveAPI.updateInformation(copyItem).then(response => {
            toast.success("Proceso actualizado");
            let pageDrives = [...this.state.pageDrives]
            let indexDrive = pageDrives.findIndex(drive => drive.idCaffenio === item.idCaffenio)
            if(indexDrive !== -1){
                pageDrives[indexDrive] = item
                this.setState({pageDrives: pageDrives})
            }

        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        });
    }

    btnApply = () => {
        let state = {...this.state};
        let valueMenuAll = null;
        let valueListAll = null;

        console.log(state.tempAllMenu)
        //console.log(state.selectedMenuAll,state.selectedPricesAll,state.switchAllActive)

        let newPageCaffenios = state.pageDrives.map((item)=>{
            //console.log(item)
            if(item.statusCheck){
                if(state.tempAllMenu > 0) item.idMenu = state.tempAllMenu
                if(state.tempAllList > 0) item.idListaPrecio = state.tempAllList
                item.Active = state.switchAllActive
            }
            return item            
        })

        //console.log(newPageCaffenios);

        let tempNewPageCaff = [...newPageCaffenios];

        let finalPageCaffenios = tempNewPageCaff.map((item)=>{
            let tempItem = {...item};
            delete tempItem.statusCheck;
            return tempItem;
        })

        console.log(finalPageCaffenios)

        CaffenioDriveAPI.updatesInformation(finalPageCaffenios).then(response => {
            //console.log(response);
            toast.success("Procesos actualizados");
            this.setState({pageCaffenios: newPageCaffenios,selectedMenuAll: null,tempAllMenu:null, selectedPricesAll: null, tempAllList:null, switchAllActive: false, childVisible: false, checkAll: true},this.selectAllCheck);
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        });

    }

    validarCombos = (state) => {
        var status_combo = false; // Vacios

        if(state["selectedOption"].length>0){
            status_combo = true; // Alguno con valor
        }

        if(state["selectedOptionC"].length>0){
            status_combo = true; // Alguno con valor
        }

        if(state["selectedOptionD"].length>0){
            status_combo = true; // Alguno con valor
        }

        return status_combo;
    }

    FilterProcess = () => {
        let state = {...this.state}
        let Caffenios = state.Caffenios;
        var status_combo; // Vacios
        let driveIds;

        if(state["selectedOptionD"].length===0){
            driveIds = state["optionDrive"].map(obj => {
                return obj.value;
            })
        } else{
            driveIds = state["selectedOptionD"].map(obj => {
                return obj.value;
            })
        }

        status_combo = this.validarCombos(state);

        if(state.filterText.trim() !== ''){
            Caffenios = Caffenios.filter(obj => {
                return obj.NombreNuevo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || obj.NombreNuevo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            });
        }

        Caffenios = Caffenios.filter((obj2)=>{
            if(driveIds.filter((item)=>{
                return item === obj2.idCaffenio
            }).length > 0){ return obj2 }
        }) 

        state.CaffeniosFilter = Caffenios;
        this.setState(state);
    }

    selectAllCheck = () => {
        let state = {...this.state};
        let elementsPage = state.pageDrives;

        elementsPage.map(element=>{
            state.checkAll ? element.statusCheck = false : element.statusCheck = true
            return element
        })

        state.checkAll = !state.checkAll;
        state.childVisible = state.checkAll
        state.pageDrives = elementsPage;
        if(state.checkAll === false){
            state.switchAllActive=false 
        } 
        
        this.setState(state);
    }

    showView = () => {
        let state = {...this.state};
        let cheked = false;
        let checkAllTemp = true;
        state.pageDrives.map(item=>{
            if(item.statusCheck){
                cheked = true;
            } else {
                checkAllTemp = false;
            }
        })

        this.setState({childVisible: cheked, checkAll: checkAllTemp});
    }

    create = () => {        
        this.props.history.push({pathname: '/drive/nuevo', state:{
            menusList: this.state.arrListMenu, 
            pricesList: this.state.arrListPrices, 
            caffenio: {},
            isEditing : false
        }});
    }

    edit = (caffenio) => {
        this.props.history.push({pathname: '/drive/nuevo', state:{
            menusList: this.state.arrListMenu, 
            pricesList: this.state.arrListPrices, 
            caffenio: caffenio,
            isEditing : true
        }});
    }

    render(){
        return(
            <div>
                <h1 className="title is-2">Caffenio Drive</h1>
                <div className="columns">
                    <div className="column is-12">
                        <div className="field">
                            <div className="control has-text-right">
                                <a className="button is-success" onClick={(e) => this.create()}>
                                    <span className="icon is-small">
                                        <i className="fa fa-plus"></i>
                                    </span>
                                    <span>Agregar Drive</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-content">
                        <div className="columns is-multiline">
                            <div className="column is-6">
                                {/*<label className="label">Nombre Drive</label>*/}
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        placeholder={`Ingrese nombre o nombre corto de drive`}
                                        value={this.state.filterText}
                                        autoFocus={true}
                                        onChange={(e) => this.handleSearchInput(e.currentTarget.value)}
                                    />
                                </div>
                            </div>
                            <div className="column is-6">
                                {/*  */}
                            </div>
                            <div className="column is-4">
                                <label className="label">Estados</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOption"}
                                        value={this.selectedOption}
                                        onChange={this.changeSelectEstado}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.optionState}
                                    />
                                </div>
                            </div>
                            <div className="column is-4">
                                <label className="label">Ciudades</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOptionC"}
                                        value={this.state.selectedOptionC}
                                        onChange={this.changeSelectCiudad}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.optionCity}
                                    />
                                </div>
                            </div>
                            <div className="column is-4">
                                <label className="label">Drives</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOptionD"}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={this.state.selectedOptionD}
                                        onChange={this.changeSelectDrive}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.optionDrive}
                                    />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <br/>

                {
                    this.state.childVisible &&
                    <div className="card">
                        <div className="card-content">
                            <div className="columns">
                                <div className="column is-2">
                                    <label htmlFor="">Modificar Drives</label>
                                </div>
                                
                                <HasAccess roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE]} >                    
                                    <div className="column is-3">
                                        <Field label="Menú">
                                            {<Dropdown 
                                                single 
                                                items={this.state.arrListMenu} 
                                                value={this.state.selectedMenuAll}
                                                id="MenuIdAll"
                                                onSelect={(i) => {
                                                    let selecMenuAll = {...this.state.selectedMenuAll};
                                                    selecMenuAll = i === 0 ? 0 : this.state.arrListMenu[i - 1].MenuId;
                                                    this.setState({selecMenuAll:selecMenuAll,tempAllMenu:selecMenuAll});
                                                }/*this.handleDropdownAll(i, "MenuId")*/}
                                                labelKey={"Description"}
                                            />}
                                        </Field>
                                    </div>
                                </HasAccess>

                                <HasAccess roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE]} >
                                    <div className="column is-3">
                                        <Field label="Lista Precio">
                                            {<Dropdown 
                                                single 
                                                items={this.state.arrListPrices} 
                                                value={this.state.selectedPricesAll}
                                                id="PriceListIdAll"
                                                onSelect={(i) => {
                                                    let selecListAll = {...this.state.selectedPricesAll};
                                                    selecListAll = i === 0 ? 0 : this.state.arrListPrices[i - 1].PriceListId;
                                                    this.setState({selectedPricesAll:selecListAll,tempAllList:selecListAll});
                                                }}
                                                labelKey={"Description"}
                                            />}
                                        </Field>
                                    </div>
                                </HasAccess>

                                
                                <HasAccess roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE]} >
                                    <div className="column is-2">
                                        <Field label="Activo" isHorizontal={false} >
                                            <input   
                                                type="checkbox"
                                                name="isActiveAll"
                                                className="switch"
                                                checked={this.state.switchAllActive}
                                                onChange={() => {}}
                                            />
                                            <label htmlFor="isActiveAll" className="inputCenter" onClick={(e) => {
                                                e.stopPropagation();
                                                let switchAllActiveTemp = this.state.switchAllActive;
                                                switchAllActiveTemp = !switchAllActiveTemp;
                                                this.setState({switchAllActive: switchAllActiveTemp});
                                            }}/>
                                        </Field>
                                    </div>
                                </HasAccess>



                                <div className="has-text-right buttons-modal">
                                    <div className="column is-2">
                                        <Field label=" ">    
                                        <button
                                            className="button is-success btn-apply"
                                            onClick={() => this.btnApply()}>
                                            Aplicar
                                        </button>
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                <br/>
                <div className="card">
                    <div className="card-content">
                    {this.state.CaffeniosFilter.length !== 0 
                        ? 
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <HasAccess roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE]} > 
                                    <th><input className="is-primary tooltip" data-tooltip="Elementos de vista actual" onChange={this.selectAllCheck} checked={this.state.checkAll} type="checkbox" /></th>
                                    </HasAccess>

                                    <th>Nombre</th>
                                    <th>Nombre Corto</th>
                                    <HasAccess roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE]} >
                                        <th>Menú</th>
                                        <th>Lista Precio</th>
                                        <th>Activo</th>
                                    </HasAccess>
                                    
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.pageDrives.map((item, index) => {
                                    return (
                                        <tr key={index}>

                                            <HasAccess roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE]} >
                                                    <th><input type="checkbox" checked={item.statusCheck} onClick={(e) => {
                                                        e.stopPropagation();
                                                        let pageCaffenios = this.state.Caffenios[index];
                                                        item.statusCheck = !item.statusCheck;
                                                        this.setState({pageCaffenios: item.statusCheck}, this.showView);
                                                    }}/></th>
                                            </HasAccess>
                                            
                                            <td>{item.NombreNuevo}</td>
                                            <td>{item.NombreNuevo}</td>

                                            <HasAccess roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE]} >
                                                <td>
                                                    <Select 
                                                        classNamePrefix="drive-select"
                                                        options={this.state.arrListMenu} 
                                                        value={this.state.arrListMenu.find(menu => menu.MenuId === item.idMenu)}
                                                        getOptionLabel={option => option.Description}
                                                        getOptionValue={option => option.MenuId}
                                                        noOptionsMessage={() => "No hay opciones"}
                                                        id="MenuId"
                                                        placeholder="Seleccionar..."
                                                        onChange={(menu) => this.handleDropdown(menu, "idMenu", item)}
                                                        />
                                                </td>
                                                <td>
                                                    <Select 
                                                        classNamePrefix="drive-select"
                                                        options={this.state.arrListPrices}
                                                        value={this.state.arrListPrices.find(lista => lista.PriceListId === item.idListaPrecio)}
                                                        getOptionLabel={option => option.Description}
                                                        getOptionValue={option => option.PriceListId}
                                                        noOptionsMessage={() => "No hay opciones"}
                                                        id="PriceListId"
                                                        placeholder="Seleccionar..."
                                                        onChange={(listaPrecio) => this.handleDropdown(listaPrecio, "idListaPrecio", item)}
                                                    />
                                                </td>
                                                <td>
                                                    <Field label="" isHorizontal>
                                                        <input   
                                                            type="checkbox"
                                                            name="Active"
                                                            className="switch"
                                                            checked={item.Active}
                                                            onChange={() => {}}
                                                        />
                                                        <label htmlFor="isActive" onClick={(e) => {
                                                            e.stopPropagation();
                                                            let ItemCaffenio = this.state.Caffenios[item];
                                                            item.Active = !item.Active;
                                                            let copyItem = {...item};
                                                            delete copyItem.statusCheck;
                                                            CaffenioDriveAPI.updateInformation(copyItem).then(response => {
                                                                toast.success("Proceso actualizado");
                                                                this.setState({ItemCaffenio: item.Active});
                                                            }).catch(err => {
                                                                this.setState({isLoading: false})
                                                                if(err.response)
                                                                    toast.error(err.response.data.Message)
                                                                else
                                                                    toast.error(ErrorMessages.SinConexion)
                                                            });
                                                        }}/>
                                                    </Field>
                                                </td>
                                            </HasAccess>

                                            <td>
                                                    <HasAccess roles={['Administrador','Lider','LiderDrive','Encargado de Turno','Supervisor de punto de venta drive','Supervisor','ConfiguracionVP']}>
                                                    <button className="button is-small tableAction is-warning tooltip" data-tooltip="Configurar VP" onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.setState({showModal: true, showConfigVP: true, caffenio: item})
                                                    }
                                                    }>
                                                        <span className="icon">
                                                        <i class="fa fa-desktop"></i>
                                                        </span>
                                                    </button>
                                                    </HasAccess>
                                            </td>
                                            <td>
                                                    <button className="button is-small tableAction is-success tooltip" data-tooltip="Editar" onClick={(e) => {
                                                        e.stopPropagation();
                                                        console.log(item);
                                                        //this.setState({showModal: true, caffenio: item})
                                                        this.edit(item)
                                                    }
                                                    }>
                                                        <span className="icon">
                                                            <i className="fa fa-edit" />
                                                        </span>
                                                    </button>
                                            </td>
                                            
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table> 

                        : <NoItems itemName="drives"/>}
                        <Pagination items={this.state.CaffeniosFilter} onChangePage={(drives) => this.setState({ pageDrives: drives })} /*onChangePage={(items) => this.handlePageChange(items)}*/ />
                    </div>
                </div>

                {this.state.showModal && this.state.showCaffenio && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Drive"}
                        width="80%"
                        cancel={this.cancel}
                    >
                        <CaffenioForm
                            caffenio={this.state.caffenio}
                            save={this.edit}
                        />   
                    </Modal>
                }

                {this.state.showModal && this.state.showConfigVP && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Configuración VP"}
                        width="85%"
                        cancel = {this.cancel}
                    >
                        <ConfiguracionVP
                            caffenio={this.state.caffenio}
                            save={this.edit}
                            cancel = {this.cancel}
                            tipoDeSolicitudes = {this.state.tipoDeSolicitudes.map(obj => ({...obj, Activo: false}))}
                        />
                    </Modal>
                }

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
                <BtnModoGuiado />                
            </div>

        );
    }

}

export default CaffenioDrive;