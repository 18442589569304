import { apiUrl }  from "../../constants";
import axios from 'axios';
import middleware from '../../middleware/auth';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})

var requestfile = axios.create({
    responseType: 'blob',
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class SegmentacionAPI {
    

    static getEstados() {
        return middleware.hasAuth(() => request.get(`/Segmentation/Filter/States/`));
    }

    static getEstadosPorSegmentacion(idSegmento) {
        return middleware.hasAuth(() => request.get(`/Segmentation/Filter/States/${idSegmento}`));
    }

    static GetCiudades(estados) {
        let body = {States: estados}
        return middleware.hasAuth(() => request.post(`/Segmentation/Filter/Cities/`, body));
    }

    static templateExcel(){
        return middleware.hasAuth(() => requestfile.get(`/Segmentation/Excel/Template`));
    }

    static validateExcel(file){
        var fd = new FormData();
        fd.append("Excel", file);
        return middleware.hasAuth(() => request.post(`/Segmentacion/ValidateExcel`, fd));
    }

    static validateExcelBySegment(file, segment){
        var fd = new FormData();
        fd.append("Excel", file);
        return middleware.hasAuth(() => request.post(`/Segmentacion/ValidateExcel/${segment}`, fd));
    }

    static createFile(validate) {
        let body = validate
        return middleware.hasAuth(() => request.post(`/Segmentation/Excel/CreateFile`, body));
    }

    static getRangoEdades() {
        return middleware.hasAuth(() => request.get(`/Segmentation/Filter/RangeAge/`));
    }

    static getGeneros() {
        return middleware.hasAuth(() => request.get(`/Segmentation/Filter/Gender/`));
    }

    static getNivelesLealtad() {
        return middleware.hasAuth(() => request.get(`/Segmentation/Filter/TypeLoyaltyAccount/`));
    }

    static getNivelesLealtadPorSegmento(idSegmento) {
        return middleware.hasAuth(() => request.get(`/Segmentation/Filter/TypeLoyaltyAccount/${idSegmento}`));
    }

    static getHorarios() {
        return middleware.hasAuth(() => request.get(`/Segmentation/Filter/PurchaseSchedule/`));
    }

    static getDiasSemana() {
        return middleware.hasAuth(() => request.get(`/Segmentation/Filter/PurchaseDay/`));
    }

    static getFamilias() {
        return middleware.hasAuth(() => request.get(`/Segmentation/Filter/FamilyProduct/`));
    }

    static getFamiliasPorSegmentacion(idSegmento) {
        return middleware.hasAuth(() => request.get(`/Segmentation/Filter/FamilyProduct/${idSegmento}`));
    }

    static getTemperaturas(idFilterFamilyProduct) {
        return middleware.hasAuth(() => request.get(`/Segmentation/Filter/TypeProduct?idFilterFamilyProduct=${idFilterFamilyProduct}`));
    }

    static getSegmentaciones(name = "", page = 1){
        return middleware.hasAuth(() => request.get(`Segmentation?name=${name}&page=${page}`));
    }

    static getSegmentacion(id){
        return middleware.hasAuth(() => request.get(`Segmentation/${id}`));
    }

    static getMembresias() {
        return middleware.hasAuth(() => request.get(`/Segmentation/Filter/TypeMemberships/`));
    }

    static createSegmentacion(segmentacion){
        return middleware.hasAuth(() => request.post(`Segmentation/`,segmentacion));
    }

    static editSegmentacion(segmentacion){
        return middleware.hasAuth(() => request.put(`Segmentation/`,segmentacion));
    }

    static copySegmentacion(name, IdSegmentation){
        return middleware.hasAuth(() => request.post(`Segmentation/Duplicate`, {Name: name, IdSegmentation: IdSegmentation}));
    }

    static changeStatus(id, status){
        return middleware.hasAuth(() => request.post(`Segmentation/Active`, {Status: status, IdSegmentation: id}));
    }

    static countClients(segmentacion){
        return middleware.hasAuth(() => request.post(`Segmentation/Count`, segmentacion));
    }

    static downloadClientsInfo(id){
        return middleware.hasAuth(() => requestfile.get(`Segmentation/Excel/${id}`));
    }

    static removeSegmentation(id){
        return middleware.hasAuth(() => request.delete(`Segmentation/${id}`));
    }
}