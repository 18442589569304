import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import AcceptModal from '../../../../util/acceptModal/AcceptModal';
import Field from '../../../../util/field/Field';
import FilterLoading from '../../components/util/FilterLoading';
import Dropdown from '../../../../util/dropdown/Dropdown';
import RadioButton from '../../../../util/radioButton/RadioButton';
import ErrorMessages from '../../../../../util/validation-error-messages';
import Validators from '../../../../../util/validators';
import Checkbox from '../../../../util/checkbox/Checkbox';
import SegmentacionAPI from '../../../../../services/Caffenio App/Segmentacion';
import EmpresasAPI from '../../../../../services/CaffenioEmpresas/Empresas';
import Loading from '../../../../util/loading/Loading';
import useModal from '../../../../../util/hooks/useModal';
import TablaValidarClientes from '../../components/TablaValidarClientes';
import PortalModal from '../../../../../util/portals/PortalModal';

function SegmentacionFormExcel(props) {
    const clasificacionesC = [];
    const clasificacionesA = [];
    const nivelLealtadC = [];
    const nivelLealtadA = [];

    const [segmentacion, setSegmentacion] = useState({
        IdSegmentation: undefined,
        nombre: "",
        estado: [],
        ciudad: [],
        rangoEdad: [],
        genero: [],
        nivelLealtad: [],
        horario: [],
        diasSemana: [],
        clasificacion: null,
        temperatura: [],
        refill: false,
        membresias: [],
        SegmentId: undefined
    });

    const [clasificaciones, setClasificaciones] = useState([]);
    const [validated, setValidated] = useState(props.isValid);
    const [clients, setClients] = useState([1, 1]);
    const [totalClients, setTotalClients] = useState(0);
    const [editing, setEditing] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [invalidName, setInvalidName] = useState(false);
    const [working, setWorking] = useState(false);
    const [segments, setSegments] = useState([]);
    const [invalidSegment, setInvalidSegment] = useState(false);
    const [Segment, setSegment] = useState([]);
    const [bandera, setBandera] = useState(false);
    const [validateExcel, setValidateExcel] = useState(null);
    const [validClients, setValidClients] = useState(null);
    const [amountCodes, setAmountCodes] = useState(null);
    const [excelFile, setExcelFile] = useState(null);
    const [loadingFullScreen, setLoadingFullScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [validData, setValidData] = useState(false);

    const clientValidatedModal = useModal();
    const [showClientTable, setShowClientTable] = useState(false);


    const handleExcelFileChange = (e) => {
        if(excelFile){
            console.log("se va a reset");
            resetInputs();
             }
        const file = e.target.files[0];
        setExcelFile(file);
    };

    useEffect(() => {
        
    }, [excelFile])

    useEffect(() => {
        resetInputs();
    }, [segmentacion.SegmentId])

    const handleShowClientTable = () => {
        setShowClientTable(true);
    };

    const handleHideClientTable = () => {
        setShowClientTable(false);
    };

     // Método para verificar si la segmentación es válida
    const dataIsValid = () => {
        return this.state.segmentacion.nombre.length >= 3 && this.state.clients.length > 0
    }
    
    const onClicValidarExcel = async () => {
        setLoadingFullScreen(true);
        try {
            const res = await SegmentacionAPI.validateExcelBySegment(excelFile, segmentacion.SegmentId);
    
            console.log(segmentacion.SegmentId);
            if (res.status === 200) {
                const dataV = res.data.Validation;
                const validData = dataV.filter(item => item.IsValid === true);
                const data = res.data;
                data.Validation = validData;
                //data.Validation = dataClients;
                setValidateExcel(data);
                setValidClients(res.data.Clients);
                setAmountCodes(res.data.TotalValidClients);
                setValidData(true);
            } else {
                resetInputs();
                toast.error('validateExcel - ' + res.data.Message);
                setValidData(false);
            }
        } catch (err) {
            toast.error('validateExcel - ' + err.response.data.Message);
            resetInputs();
        } finally {
            setLoadingFullScreen(false);
        }
    }
    
    const resetInputs = () => {
        console.log("nuevo");
        setValidateExcel(null);
        setValidClients(null);
        setAmountCodes(null);
        setExcelFile(null);
        setValidData(false);
    }

    
    
    const onClicBorrarExcel = () => {
        setExcelFile(null);
        resetInputs();
    };
    
    const downloadTemplateExcel = async () => {
            SegmentacionAPI.templateExcel().then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let name = `PlantillaCargaPorUsuario`;
                link.setAttribute('download', `${name}.xlsx`);
                document.body.appendChild(link);
                link.click();
            }).catch(err => {
                toast.error(err.response.data.Message)
            }).finally(() => {
            });
        }

    // Método para verificar que la segmentación no tiene ningún filtro seleccionado
    const segmentationHasNoFilters = () => {
        return this.state.segmentacion.estado.length == 0 &&
        this.state.segmentacion.ciudad.length == 0 &&
        this.state.segmentacion.rangoEdad.length == 0 &&
        this.state.segmentacion.genero.length == 0 &&
        this.state.segmentacion.nivelLealtad.length == 0 &&
        this.state.segmentacion.horario.length == 0 &&
        this.state.segmentacion.diasSemana.length == 0 &&
        this.state.segmentacion.clasificacion == null &&
        this.state.segmentacion.temperatura.length == 0
    }

    useEffect(() => {
        setTimeout(() => {
          // let seg = segments.find(c => c.value === this.props.segmentacion.SegmentId);
          // this.setState({ bandera: true });
        }, 3000);
      }, []);
    
      useEffect(() => {

        console.log("entró");
        setIsLoading(true);
        EmpresasAPI.getAllFilterSegments()
          .then((response) => {
            const tempSegmentsList = response.data.map((obj) => ({
              value: obj.SegmentId,
              label: getName(obj.Name),
            }));
            setSegments(tempSegmentsList);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            if (err.response) toast.error(err.response.data.Message);
            else toast.error(ErrorMessages.SinConexion);
          });
      }, []);
    


    const getName = (Name) => {
        switch (Name) {
          case 'Andatti drive':
            return 'Andatti';
          case 'Drive':
            return 'Caffenio';
          case 'momento espresso':
            return 'Momento Espresso';
          default:
            return Name;
        }
      };
    

    
    


    // Método para preparar el objeto segmentación para su envío al API, se ajusta al modelo.
    const prepareSegmentacion = () => {
        var validExcel = validateExcel;
        
        const clientsArray = validExcel.Validation.map((client) => {
            return {
              ClientId: client.ClientId.toString(), // Asegúrate de que sea una cadena
              IsValid: true, // Aquí siempre se establece como true según tu ejemplo
            };
          });

          
        // Se crea la segmentación con todos los datos ya preparados
        var seg = {
            IdSegment: segmentacion.SegmentId,
            Name: segmentacion.nombre,
            SegmentId: segmentacion.SegmentId,
            Status: true,
            TotalClients: validExcel.TotalValidClients,
            Clients: clientsArray,  
            IdTipoSegmentacion: segmentacion.SegmentId
        };

        console.log("valid");
        console.log(seg);
        console.log("excel");
        return seg;
    }


    return (
        <div className="columns is-multiline">
            <div className="column is-12">
                <div className="columns">
                    <div className="column is-4">
                        <label className="label">Segmentación</label>
                        <div className="control">

                            <Select
                                placeholder="Seleccionar..."
                                name="SegmentoOption"
                                value={Segment}
                                autoFocus={true}
                                onChange={(e) => {
                                  const updatedSegmentacion = { ...segmentacion };
                                  updatedSegmentacion.SegmentId = -1;
                                  let seg = {};
                
                                  if (e !== null) {
                                    updatedSegmentacion.SegmentId = e.value;
                                    seg = e;
                                  }
                                  setValidated(false);
                                  setSegmentacion(updatedSegmentacion);
                                  setSegment(seg);
                                  if (updatedSegmentacion.SegmentId !== undefined && updatedSegmentacion.SegmentId !== -1) {
                                    setInvalidSegment(false);
                                  }
                                }}
                                onBlur={(val) => {
                                  if (segmentacion.SegmentId === undefined || segmentacion.SegmentId === -1) {
                                    setInvalidSegment(true);
                                  }
                                }}
                                isClearable={true}
                                id="idSegments"
                                noOptionsMessage={() => "No hay opciones"}
                                options={segments}
                              />

                            {invalidSegment && <p className="help title is-6 is-danger">Segmentación es requerido.</p>}
                        </div>
                    </div>
                </div>
                <div className="is-divider"></div>
            </div>

            <div className="column is-4 has-text-left">
                <Field label="Nombre de segmentación">
                    <input
                        type="text"
                        className={`input ${invalidName && "is-danger"}`}
                        maxLength={50}
                        placeholder={`Nombre de la segmentación`}
                        value={segmentacion.nombre}
                        onBlur={(e) => {
                            // Si el usuario cambia a otro campo o da click fuera se verifica si hay un valor ingresado válido.
                            if (segmentacion.nombre.length === 0) {
                                setInvalidName(true);
                            }
                        }}
                        onChange={(e) => {
                            let updatedSegmentacion = { ...segmentacion };
                            updatedSegmentacion.nombre = e.currentTarget.value;
                            // Se remueven los espacios en blanco en exceso.
                            updatedSegmentacion.nombre = updatedSegmentacion.nombre.replace(/\s{2,}/g, ' ');
                            setSegmentacion(updatedSegmentacion);
                            setInvalidName(updatedSegmentacion.nombre.length === 0);
                        }}
                    />
                </Field>
                {invalidName && <p className="help title is-6 is-danger">Nombre requerido</p>}
            </div>

            
            {segmentacion.SegmentId > 0 && (
                <>
                    <div className="column is-8">
                    {/* Your content for SegmentId > 0 */}
                    </div>
                    <div className="column is-12">
                    <div className="is-divider"></div>
                    <h1 className="title is-3">EXCEL</h1>
                    <div className="column is-12">
                        <div className="column is-12">
                        <label className="label">Archivo Excel</label>
                        <div className="file is-info has-name">
                            <label className="file-label">
                            <input
                                className="input file-input"
                                type="file"
                                name="excelFile"
                                accept=".xls,.xlsx"
                                onChange={handleExcelFileChange}
                            />
                            <span className="file-cta">
                                <span className="file-label ">
                                <i className="fa fa-upload"> Seleccionar archivo Excel</i>
                                </span>
                            </span>
                            <span className="file-name">
                                {excelFile ? excelFile.name : "Archivo"}
                            </span>
                            </label>
                        </div>
                        </div>
                        <div className="column is-6 displayFlex">
                        <button
                            type="button"
                            className="button is-success"
                            disabled={!excelFile}
                            onClick={onClicValidarExcel}
                        >
                            Validar excel
                        </button>
                        <button
                            type="button"
                            className="button is-danger"
                            disabled={!excelFile}
                            onClick={onClicBorrarExcel}
                        >
                            Borrar excel
                        </button>
                        <button
                            type="button"
                            className="button is-link"
                            onClick={downloadTemplateExcel}
                        >
                            Descargar plantilla
                        </button>
                        </div>
                    </div>
                    </div>
                </>
                )}

            {validateExcel && (
            <div className="column is-12">
                <div className="columns is-multiline">
                    <div className="column is-4 has-text-left">
                        <label className="label">Total de clientes</label>
                        <div className="control">
                            <p>{validateExcel.TotalClients}</p>
                        </div>
                    </div>
                    <div className="column is-4 has-text-left">
                        <label className="label">Clientes válidos</label>
                        <div className="control">
                            <p>{validateExcel.TotalValidClients}</p>
                        </div>
                    </div>
                    <div className="column is-4 has-text-left">
                        <label className="label">Clientes no válidos</label>
                        <div className="control">
                            <p>{validateExcel.TotalNotValidClients}</p>
                        </div>
                    </div>
                    <div className="column is-4">
                        <div className="field is-grouped">
                            <p className="control">
                                <button
                                    type="button"
                                    className="button is-success"
                                    disabled={!excelFile}
                                    onClick={() => { clientValidatedModal.toggle(); }}
                                >
                                    Mostrar tabla de clientes
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )}

            <div className="column is-12">
                <div className="field is-grouped is-grouped-right">
                    <p className="control is-expanded">
                        {totalClients > 0 && <h1 className="title is-4">{`Total de clientes: ${totalClients}`}</h1>}
                    </p>
                    <p className="control">
                        <button
                            className="button"
                            onClick={() => {
                                props.history.push({
                                    pathname: '/app/segmentaciones',
                                    state: props.location.state
                                        ? {
                                            currentPage: props.location.state.currentPage || 0,
                                            name: props.location.state.name || ""
                                        }
                                        : { currentPage: 1, name: "" }
                                });
                            }}>
                            {validated ? "Cancelar" : "Regresar"}
                        </button>
                    </p>

                    {validated && clients.length > 0 && <p className="control">
                        <button
                            className="button is-success"
                            disabled={!dataIsValid()}
                            onClick={() => {
                                if (!working) {
                                    if (segmentationHasNoFilters()) {
                                        setConfirmModal(true);
                                    } else props.save(prepareSegmentacion(), editing);
                                }
                            }}>
                            Guardar
                        </button>
                    </p>}

                    {!validated && <p className="control">
                        <button
                            className="button is-success"
                            disabled={segmentacion.nombre.length < 3 || segmentacion.SegmentId === undefined || segmentacion.SegmentId === -1 || !validData}
                            onClick={() => {
                                props.validate(prepareSegmentacion());
                            }}>
                            Guardar
                        </button>
                    </p>}
                </div>
            </div>
            {confirmModal && <AcceptModal
                modalTitle="Confirmar"
                modalBody={<p>¿Está seguro que desea guardar la segmentación sin filtros?</p>}
                isActive={confirmModal}
                willSave
                done={() => {
                    if (!working) {
                        setWorking(true);
                        props.save(prepareSegmentacion(), editing);
                    }
                }}
                cancel={() => setConfirmModal(false)}
            />}


                <PortalModal
                isShowing={clientValidatedModal.isShowing}
                hide={clientValidatedModal.toggle}
                title="Clientes"
                body={() => {
                    return (
                        <div className="column is-12">
                            <TablaValidarClientes
                                validClients={validateExcel.Validation}
                            />
                        </div>
                    )
                }}
                footer
                footerButtons={() => {
                    return (
                        <div className="has-text-right modal-detalle-buttons">
                            <button className={`button is-success`} onClick={() => clientValidatedModal.toggle()}>Aceptar</button>
                        </div>
                    )
                }}
            />
            <Loading isFullscreen={true} isLoading={loadingFullScreen} width='100px' height='100px' />
        </div>
    );
}

SegmentacionFormExcel.defaultProps = {
    estados: [],
    ciudades: [],
    rangoEdades: [],
    generos: [],
    nivelesLealtad: [],
    horarios: [],
    diasSemana: [],
    clasificaciones: [],
    temperaturas: [],
    membresias: [],
    segments: []
}

export default SegmentacionFormExcel;
