import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import ErrorMessages from '../../util/validation-error-messages';
import { Link } from 'react-router-dom';
import BasePromo from './BasePromo';
import DetallePromo from './DetallePromo';
import { ProductosPromo } from './ProductosPromo';
import { DrivesPromo } from './DrivesPromo';
import { RestriccionesPromo } from './RestriccionesPromo';
import { ResumenPromo } from './ResumenPromo';
import { PromoProductsTable } from './components/PromoProductsTable';

import DrivePromotionsRefundTypeAPI from '../../services/DrivePromotionsRefundType';
import DrivePromotionsTypesAPI from '../../services/DrivePromotionsType';
import DrivePromotionsTipoRegalosAPI from '../../services/DrivePromotionsTipoRegalos';
import DrivePromoDiscountTypesAPI from '../../services/DrivePromoDiscountTypes';

import {tipos} from '../../util/strings/tipo-promos';
import Modal from '../util/modal/Modal';
import Loading from '../util/loading/Loading';
import DrivePromotionsAPI from '../../services/DrivePromotions';
import AcceptModal from '../util/acceptModal/AcceptModal';
import Pagination from '../util/pagination/Pagination';
import NoItems from '../util/noitems/NoItems';

export const NuevaPromo = ({history}) => {

    const steps = {
        'base': 'base',
        'detalle': 'detalle',
        'productos': 'productos',
        'drives': 'drives',
        'restricciones': 'restricciones',
        // 'resumen': 'resumen'
    }

    const [ state, setState ] = useState({
        currentStep: steps.base,
        promotion:  {
            Name : "",
            ShortName : "",
            Description : "",
            InitDate : "",
            EndDate : "",
            Auto : false,
            idTipoPromo : 0,
        },
        detalle: {
            idTipoReembolso : {},
            idTipoRegalo : {},
            isPercentage : 0,
            quantity : 0,
            isRecursive : null,
            idTipoDescuento : {},
            checkCantidadMinima : false,
            minProducts : 0,
            aplicaPara : [],
            descripcionAPP  : "",
        },
        productos:[],
        drives :[],
        restricciones : {
            restrictionsSelected : [],
            initDateRestriction : "",
            endDateRestriction : ""
        },
        listTipoPromos : [],
        listTipoDescuento : [],
        listReembolsoTipo : [],
        typePromotion : [],
        tipoRegalos : [],
        isEditing:false,
        isLoading:true,
        showModal:false,
        modalConfirm : false,
        show_info_confirm:true
    });

    useEffect(() => {
        let promoTypeData = [];
        let reembolsoTypeData = [];
        let listTipoDescuentoData = [];
        let tipoRegaloData = [];
        let promotion = state.promotion;
        let detalle = state.detalle;
        let productos = state.productos;
        let drives = state.drives;
        let pagedrivesAsignados = drives.slice(0, 6).map(item => {
            return item
        })
        let restricciones = state.restricciones;
        let isEditing = false;
        let isLoading= false;
        if(history.location.pathname.includes('editar') && !history.location.state){
            history.push(`/promos`);
            return;
        }
        DrivePromotionsTypesAPI.getAll()
            .then(response => {
                promoTypeData = response.data.map(item => {
                    if(item.Active)
                    {
                        return {
                            value: item.IdTypePromo,
                            label: item.Name
                        }
                    }
                    return null;                   
                })

                promoTypeData = promoTypeData.filter(type => type != null)
                return DrivePromotionsRefundTypeAPI.getAll()
            })
            .then(response => {
                reembolsoTypeData = response.data.map(item => {
                    if(item.Active)
                    {
                        return {
                            value: item.RefundTypeId,
                            label: item.Name
                        }
                    }
                    return null;                   
                })

                reembolsoTypeData = reembolsoTypeData.filter(type => type != null)
                return DrivePromoDiscountTypesAPI.getAll()
            })
            .then(response => {
                listTipoDescuentoData = response.data.map(item => {
                    if(item.Active)
                    {
                        return {
                            value: item.PromoDiscountTypeId,
                            label: item.Name
                        }
                    }
                    return null;                   
                })

                listTipoDescuentoData = listTipoDescuentoData.filter(type => type != null)

                return DrivePromotionsTipoRegalosAPI.getAll()
            })
            .then(response => {
                tipoRegaloData = response.data.map(item => {
                    if(item.Activo)
                    {
                        return {
                            value: item.idTipoRegalo,
                            label: item.Descripcion
                        }
                    }
                    return null;                   
                })
    
                tipoRegaloData = tipoRegaloData.filter(type => type != null)
                if(history.location.state){
                    return DrivePromotionsAPI.get(history.location.state.IdPromocion);
                }else{
                    return true;
                }

    
            })
            .then(response => {
                if(history.location.state){
                    if (!response.data){
                        toast.error("Ocurrión un error al cargar promoción");
                        history.goBack();
                    }
                    response.data.Promotion.InitDate= new Date(response.data.Promotion.InitDate);
                    response.data.Promotion.EndDate= new Date(response.data.Promotion.EndDate);
                    promotion = response.data.Promotion;
                    detalle = response.data.Detalle;
                    productos = response.data.Productos;
                    drives = response.data.drives;
                    restricciones = response.data.Restricciones;
                    isEditing=true;
                }
            })
            .catch(err => {
                if (err.response)
                    toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                else
                    toast.error(ErrorMessages.SinConexion)
            }).finally(() => setState({
                ...state,
                listReembolsoTipo : reembolsoTypeData,
                listTipoDescuento : listTipoDescuentoData,
                listTipoPromos : promoTypeData,
                tipoRegalos : tipoRegaloData,  
                promotion,
                detalle,
                productos,
                drives,
                restricciones,
                isEditing,
                isLoading,
                pagedrivesAsignados
            }))
    },[]); 

    /***
     * Obtiene el tipo de promoción seleccionado 
     */
    const esTipoCombo = () => {        
        let tipoPromo = state.promotion && state.promotion.idTipoPromo ? state.promotion.idTipoPromo.label : "";

        return tipos.Combo === tipoPromo || tipos.qrCustom === tipoPromo
    }
    /***
     * Obtiene el tipo de promoción seleccionado 
     */
    const esTipoDescuento = () => {        
        let tipoPromo = state.promotion && state.promotion.idTipoPromo ? state.promotion.idTipoPromo.label : "";

        return tipos.descuento === tipoPromo 
    }

    /***
     * Obtiene el tipo de promoción seleccionado 
     */
     const esReembolso = () => {        
        let tipoPromo = state.promotion && state.promotion.idTipoPromo ? state.promotion.idTipoPromo.label : "";

        return tipos.reembolso === tipoPromo 
    }

    const PromoStep = () => {
        switch (state.currentStep) {
            case steps.base:
                return <BasePromo values={state.promotion} listTipoPromos = {state.listTipoPromos} setValues ={ (values) => {state.promotion = values}} changeTypePromo = {changeTypePromo} handleNextStep = {handleNextStep}/>;
            case steps.detalle:
                return <DetallePromo values = {state} setValues ={ (values) => {state.detalle = values}} handleNextStep = {handleNextStep} handleLastStep = {handleLastStep}/>;
            case steps.productos:
                return <ProductosPromo values = {state.productos} handleNextStep = {handleNextStep} handleLastStep = {handleLastStep} isBaseApply={esTipoCombo()} />;
            case steps.drives:
                return <DrivesPromo values = {state.drives} handleNextStep = {handleNextStep} handleLastStep = {handleLastStep}/>;
            case steps.restricciones:
                return <RestriccionesPromo values = {state.restricciones} handleNextStep = {(sendToValidation) => {                                        
                    state.restriccionesResum = state.restricciones && state.restricciones.restrictionsSelected 
                    ? state.restricciones.restrictionsSelected.reduce((lst, obj) => {
                        let temp = lst.filter(l=> l.type === obj.type)
                        if(temp.length === 0) {
                            lst.push({
                                type: obj.type,
                                title: obj.title,
                                restrictions: [obj.description]
                            })
                        }else{
                            lst.map(l => {
                                if(l.type === obj.type){
                                    l.restrictions.push(obj.description)
                                }
                            })
                            
                        }
                        return lst;
                    },[]):[]
                    state.promotion.sendPromo = sendToValidation;
                    if(state.restricciones.endDateRestriction != "" && state.restricciones.initDateRestriction != ""){
                        state.restriccionesResum.push({
                            type: 5,
                            title: 'Horario',
                            restrictions: ["Promocion valida de " + state.restricciones.initDateRestriction + " a " + state.restricciones.endDateRestriction]
                        })
                    }
                    setState({...state, modalConfirm: true})
                }} handleLastStep = {handleLastStep} />;
            // case steps.resumen:
            //     return <ResumenPromo values = {state} handleLastStep = {handleLastStep} savePromotion = {savePromotion}/>;
            default:
                return <BasePromo />;
        }
    }

    const changeTypePromo = () => {
        state.detalle= {
            idTipoReembolso : {},
            idTipoRegalo : {},
            isPercentage : 0,
            quantity : 0,
            isRecursive : false,
            idTipoDescuento : {},
            checkCantidadMinima : false,
            minProducts : 0,
            aplicaPara : [],
            descripcionAPP  : "",
        }
    }


    const handleLastStep = () => {        
        let isRembolsoRecarga = state.currentStep === 'drives' &&  esReembolso() && state.detalle && state.detalle.idTipoReembolso && state.detalle.idTipoReembolso.label == 'Por recarga';
        let index = Object.values(steps).indexOf(state.currentStep) - (isRembolsoRecarga ? 1 : 0);
        if ( index === -1 )
            return;
        setState({
            ...state,
            currentStep: Object.values(steps)[--index]
        });
    }

    const handleNextStep = () => {
        let isRembolsoRecarga = state.currentStep === 'detalle' &&  esReembolso() && state.detalle && state.detalle.idTipoReembolso && state.detalle.idTipoReembolso.label == 'Por recarga';
        let index = Object.values(steps).indexOf(state.currentStep) + (isRembolsoRecarga ? 1 : 0);
        if ( index === -1 )
            return;
        setState({
            ...state,
            currentStep: Object.values(steps)[++index]
        });
    }

    const savePromotion = () => {  
        
        setState({...state, isLoading:true});
        state.promotion.InitDate = moment(state.promotion.InitDate).format("YYYY/MM/DD HH:mm")
        state.promotion.EndDate = moment(state.promotion.EndDate).format("YYYY/MM/DD HH:mm")
        let dataToSend = {
            promotion: state.promotion,
            detalle: state.detalle,
            productos:state.productos,
            drives :state.drives,
            restricciones : state.restricciones
        }

        dataToSend.promotion.description = dataToSend.detalle.descripcionAPP ? dataToSend.detalle.descripcionAPP :dataToSend.promotion.description;

        dataToSend.promotion.idTipoPromo = isNaN(state.promotion.idTipoPromo)?dataToSend.promotion.idTipoPromo.value : dataToSend.promotion.idTipoPromo;

        if(!state.isEditing){
            DrivePromotionsAPI.create(dataToSend).then(response => {
                toast.success("La promoción ha sido creada correctamente.");                                  
            }).catch(err => {
                toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
            }).finally(()=>{
                history.goBack() 
            })
        }
        else{
            DrivePromotionsAPI.update(dataToSend.promotion.IdPromocion, dataToSend).then(response => {
                toast.success("La promoción ha sido actualizada correctamente.");                                  
            }).catch(err => {
                toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
            })
            .finally(()=>{
                history.goBack() 
            })
        }
        

    }

    return (
        <div>
            <div className='card'>
                {/* HEADER */}
                <div className='card-header'>
                    <div className="card-header-title is-size-4 center-text">
                        <span style={{ position: "absolute", left: 25 }} className="is-primary tooltip" data-tooltip="Ir Atrás">
                            <i className="fa fa-arrow-left" style={{ cursor: "pointer", color: "#91BA43" }} onClick={() => { history.goBack() }}></i>
                        </span>
                        
                        <div>
                            {state.isEditing ? "Editar Promoción" : "Nueva Promoción"}
                            <i className="fa fa-question-circle" 
                            style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }} 
                            onClick={() => setState({...state, showModal: true })}></i>
                        </div>
                    </div>                    
                </div>
                <br />
                {/* STEPS */}
                <div className='card-content'>
                    <div className='steps'>
                        <div className={`step-item ${ state.currentStep === steps.base ? 'is-active' : '' }`} >
                            <div className='step-marker'>1</div>
                            <div className='step-details'>
                                <p className='step-title'>Información básica</p>
                                <p>{ state.currentStep === steps.base  ? 'Información básica': ''}</p>
                            </div>
                        </div>
                        <div className={`step-item ${ state.currentStep === steps.detalle ? 'is-active' : '' }`}>
                            <div className='step-marker'>2</div>
                            <div className='step-details'>
                                <p className='step-title'>Detalle</p>
                                <p>{state.currentStep === steps.detalle && `Detalles de la promoción ${state.promotion && state.promotion.idTipoPromo 
                                                            ? `${state.promotion.idTipoPromo.label}` : ''}`}</p>
                            </div>
                        </div>
                        {!(esReembolso() && state.detalle && state.detalle.idTipoReembolso && state.detalle.idTipoReembolso.label == 'Por recarga') && 
                            <div className={`step-item ${ state.currentStep === steps.productos ? 'is-active' : '' }`}>
                                <div className='step-marker'>3</div>
                                <div className='step-details'>
                                    <p className='step-title'>Productos</p>
                                    <p>{state.currentStep === steps.productos ? 'Seleccionar productos' : ''}</p>
                                </div>
                            </div>
                        }
                        <div className={`step-item ${ state.currentStep === steps.drives ? 'is-active' : '' }`}>
                            <div className='step-marker'>{(esReembolso() && state.detalle && state.detalle.idTipoReembolso && state.detalle.idTipoReembolso.label == 'Por recarga') ? 3 : 4}</div>
                            <div className='step-details'>
                                <p className='step-title'>Drives</p>
                                <p>{state.currentStep === steps.drives ? 'Seleccionar puntos de venta donde aplica.' : ''}</p>
                            </div>
                        </div>
                        <div className={`step-item ${ state.currentStep === steps.restricciones ? 'is-active' : '' }`} >
                            <div className='step-marker'>{(esReembolso() && state.detalle && state.detalle.idTipoReembolso && state.detalle.idTipoReembolso.label == 'Por recarga') ? 4 : 5}</div>
                            <div className='step-details'>
                                <p className='step-title'>Restricciones</p>
                                <p>{state.currentStep === steps.restricciones ? 'Seleccionar restricciones' : ''}</p>
                            </div>
                        </div>
                        {/* <div className={`step-item ${ state.currentStep === steps.resumen ? 'is-active' : '' }`}>
                            <div className='step-marker'>{(esReembolso() && state.detalle && state.detalle.idTipoReembolso && state.detalle.idTipoReembolso.label == 'Por recarga') ? 5 : 6}</div>
                            <div className='step-details'>
                                <p className='step-title'>Resúmen</p>
                                <p>{state.currentStep === steps.resumen ? 'Confirme la información antes de guardar.':''}</p>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* STEPS CONTENT */}
                <br />
                <div
                    className='steps-content'
                    style={{
                        margin: '10px 20px'
                    }}
                >
                   {!state.isLoading && <PromoStep />}
                   {state.showModal 
                    ? <Modal
                        showModal={state.showModal}
                        modalTitle={"Promociones"}
                        cancel={() => setState({...state, showModal: false })}
                    >
                        <div className="has-text-justified">
                            
                            Módulo para crear y editar promociones del punto de venta.
                        
                            <hr />
                            <section className="accordions">
                                <article className={`accordion is-info ${state.show_info ? "is-active" : ""}`}
                                    onClick={() => setState({...state, show_info: !state.show_info, show_info_equivalencias: false, show_info_clasificacion: false, show_info_end: false })}>
                                    <div className="accordion-header toggle">
                                        <p>1. Información básica</p>
                                    </div>
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            <p className="has-text-justified">
                                                Sección para configurar la información base de la promoción:<br/>
                                                <strong >Nombre POS -</strong> Campo alfanumérico con logitud máxima de 50 caracteres, representa el nombre de la promoción que aparece en la POS.
                                                <br/>
                                                <strong >Nombre Ticket -</strong> Campo alfanumérico con logitud máxima de 50 caracteres, descripción de la promoción que aparece en el ticket.
                                                <br/>
                                                <strong >Fecha lanzamiento -</strong> Campo que representa la fecha en que entra en vigencia la promoción.
                                                <br/>
                                                <strong >Fecha fin -</strong> Campo que representa la fecha en que expira la promoción.
                                                <br/>
                                                <strong >Tipo promoción -</strong> Campo seleccionable que representa el tipo de promoción: Combo, Descuento, QR Custom, Reembolso. A partir de la selección cambia el detalle solicitado para la promoción.
                                                <br/>
                                                <strong >Promoción automática-</strong> Al especificar que la promoción es automática, no es necesario que la operadora ingrese al módulo de promociones ya que si se cumplen las reestricciones será aplicada automáticamente.
                                                <br/>
                                            </p>
                                        </div>
                                    </div>
                                </article>
                                <article className={`accordion is-info ${state.show_detail ? "is-active" : ""}`}
                                    onClick={() => setState({...state, show_detail: !state.show_detail, show_info:false, show_info_equivalencias: false, show_info_clasificacion: false, show_info_end: false })}>
                                    <div className="accordion-header toggle">
                                        <p>2. Detalle</p>
                                    </div>
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            {esTipoCombo() || esTipoDescuento()
                                            ?<p className="has-text-justified">
                                            Detalle de la promoción de tipo <strong> DESCUENTO </strong>:  <br />
                                            <strong >Tipo de descuento - </strong> Campo seleccionable que representa la modalidad del descuento por Porcentaje, Cantidad o Precio Fijo. <br/>
                                            <strong >Porcentaje, Cantidad o Precio fijo a descontar - </strong><br/> Campo numérico que representa el descuento según el tipo de descuento seleccionado.<br/>
                                            <strong >Recursividad - </strong>Campo seleccionable, <i>Descuento múltiple</i> el descuento se aplica una vez por cada producto válido; <i>Un descuento por orden</i> un solo descuento por orden.<br/>
                                            
                                            <strong >¿Cantidad mínima de producto? - </strong> Condiciona el descuento a cumplir con el número registrado en <i>Cantidad miníma</i> <br/>
                                            <strong >Cantidad mínima - </strong> Cantidad minima de producto para aplicar el descuento.<br/>

                                            <strong >Medios donde aplica - </strong>Campo seleccionable con los dispositivos donde se podrá hacer el canje de la promoción.<br/>
                                            <strong >Descripción en APP - </strong>En caso de seleccionar que la promoción aplica para la APP, este campo será requerido.<br/>
                                            </p>
                                            : <p className="has-text-justified">
                                            Detalle de la promoción de tipo <strong> REEMBOLSO </strong>:  <br />
                                            <strong >Tipo de reembolso - </strong> Campo seleccionable que representa la modalidad del rembolso por recarga o por producto. <br/>
                                            <strong >Tipo de regalo - </strong><br/> Al ser reembolso, este siempre es en monedero.<br/>
                                            {esReembolso() && state.detalle && state.detalle.idTipoReembolso && state.detalle.idTipoReembolso.label == 'Por recarga' 
                                                ? <>
                                                    <strong >Recursividad - </strong>Campo seleccionable, <i>Multiplos de</i> El reembolso es acumulable de acuerdo a la cantidad recargada. 
                                                    <i>Mayor igual a </i>  El reembolso se aplica una sola vez.<br/>
                                                    <strong >Cantidad a recargar - </strong> Monto de recarga requerido para hacer valida a promoción.
                                                    <strong >Tipo de Cantidad a reembolsar - </strong> Se refiere a la cantidad reembolsada será <i>Porcentaje</i> o <i>Cantidad fija</i>.<br/>
                                                    <strong >Cantidad a reembolsar - </strong> cantidad de reembolso en función del <i>Tipo de Cantidad a reembolsar</i>.<br/>
                                                </>
                                                : <>
                                                    <strong >Tipo de Cantidad a reembolsar - </strong> Se refiere a la cantidad reembolsada será <i>Porcentaje</i> o <i>Cantidad fija</i>.<br/>
                                                    <strong >Cantidad a reembolsar - </strong> cantidad de reembolso en función del <i>Tipo de Cantidad a reembolsar</i>.<br/>
                                                </>
                                            }
                                            <strong >Medios donde aplica - </strong>Campo seleccionable con los dispositivos donde se podrá hacer el canje de la promoción.<br/>
                                            <strong >Descripción en APP - </strong>En caso de seleccionar que la promoción aplica para la APP, este campo será requerido.<br/>
                                            </p> }
                                            
                                        </div>
                                    </div>
                                </article>
                                <article className={`accordion is-info ${state.show_info_equivalencias ? "is-active" : ""}`}
                                    onClick={() => setState({...state, show_info_equivalencias: !state.show_info_equivalencias, show_info: false, show_info_clasificacion: false, show_info_end: false })}>
                                    <div className="accordion-header toggle">
                                        <p>3. Productos</p>
                                    </div>
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            <p className="has-text-justified">
                                                Sección para seleccionar los productos que aplican en la promoción. Si la promoción es Combo o QR se deberá especificar al menos una colección de productos base.

                                            </p>
                                            

                                        </div>
                                    </div>
                                </article>
                                <article className={`accordion is-info ${state.show_info_clasificacion ? "is-active" : ""}`}
                                    onClick={() => setState({...state, show_info_clasificacion: !state.show_info_clasificacion, show_info: false, show_info_end: false, show_info_equivalencias: false })}>
                                    <div className="accordion-header toggle">
                                        <p>4. Drives</p>
                                    </div>
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            <p className="has-text-justified">
                                                En esta sección debe seleccionar los puntos de ventas que aplican en la promoción.
                                            </p>
                                        </div>
                                    </div>
                                </article>
                                <article className={`accordion is-info ${state.show_info_end ? "is-active" : ""}`} 
                                onClick={() => setState({ ...state, show_info_end: !state.show_info_end, show_info: false, show_info_clasificacion: false, show_info_equivalencias: false })}>
                                    <div className="accordion-header toggle">
                                        <p>5. Restricciones </p>
                                    </div>
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            Seleccione las restricciones de la promoción
                                        </div>
                                    </div>
                                </article>
                                
                            </section>
                        </div>
                    </Modal>
                    :""
                    }

                    {/* Modal preview */}
                {state.modalConfirm &&
                <AcceptModal
                isActive={state.modalConfirm}
                done={() => savePromotion()}
                cancel={() => setState({...state,  modalConfirm: false})}
                modalTitle={"Resumen"}
                width="75%"
                modalBody={
                    <section className="accordions">
                        <article className={`accordion is-light ${state.show_info_confirm ?'is-active':'' }  `}>
                            <div className="accordion-header toggle" onClick={() => setState({ ...state, show_info_confirm: !state.show_info_confirm})}>
                                <p> Información básica</p>
                            </div>
                            <div className="accordion-body">
                                <div className="accordion-content">
                                    <p className="has-text-justified">

                                        <strong >Nombre POS -</strong> {state.promotion && state.promotion.Name ? state.promotion.Name : ''}
                                        <br />
                                        <strong >Nombre Ticket -</strong> {state.promotion && state.promotion.ShortName ? state.promotion.ShortName : ''}
                                        <br />
                                        <strong >Fecha lanzamiento -</strong> {state.promotion && state.promotion.InitDate ? moment(state.promotion.InitDate).format("YYYY/MM/DD HH:mm") : ''}
                                        <br />
                                        <strong >Fecha fin -</strong> {state.promotion && state.promotion.EndDate ? moment(state.promotion.EndDate).format("YYYY/MM/DD HH:mm")   : ''}
                                        <br />
                                        <strong >Tipo promoción -</strong> {state.promotion && state.promotion.idTipoPromo ? state.promotion.idTipoPromo.label : ""}
                                        <br />
                                        <strong >Promoción automática-</strong> {state.promotion && state.promotion.Auto ? 'SI' : "NO"}
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article className={`accordion is-light ${state.detail_confirm ?'is-active':'' }  `}>
                            <div className="accordion-header toggle" onClick={(e) => {e.preventDefault(); setState({ ...state, detail_confirm: !state.detail_confirm})}}>
                                <p> Detalle promoción</p>
                            </div>
                            <div className="accordion-body">
                                <div className="accordion-content">
                                    {esTipoCombo() || esTipoDescuento() ?
                                    <p className="has-text-justified">
                                        <strong >Tipo descuento -</strong> {state.detalle && state.detalle.idTipoDescuento ? state.detalle.idTipoDescuento.label : ''}
                                        <br />
                                        <strong >Cantidad -</strong> {state.detalle && state.detalle.quantity ? state.detalle.quantity : ''}
                                        <br />
                                        <strong >Recursividad -</strong> {state.detalle && state.detalle.isRecursiveDescuentoSelected ? state.detalle.isRecursiveDescuentoSelected .label : ''}
                                        <br />
                                        <strong >¿Cantidad mínima de producto?  - </strong> {state.detalle && state.detalle.checkCantidadMinima ? 'SI' : 'NO'}
                                        <br />
                                        {state.detalle && state.detalle.checkCantidadMinima && <p>
                                            <strong >Cantidad mínima - </strong> {state.detalle && state.detalle.checkCantidadMinima && state.detalle.minProducts ? state.detalle.minProducts : ""}
                                            <br />
                                        </p>}
                                        
                                        <strong >Medios donde aplica -</strong> {state.detalle && state.detalle.aplicaPara ?  state.detalle.aplicaPara.map((l,inx)=>`${l.label}${inx+1 ===state.detalle.aplicaPara.length ?'':',' } `) : ""}
                                        <br />
                                        {
                                            state.detalle && state.aplicaPara && state.aplicaPara.some(s=>s.label ==='App') 
                                            && 
                                            <p><strong >Descripción en APP  -</strong> {state.detalle.descripcionAPP}</p>
                                        }
                                        
                                    </p> 
                                    : <p className="has-text-justified">
                                            
                                            <strong >Tipo de reembolso - </strong> {state.detalle && state.detalle.idTipoReembolso ? state.detalle.idTipoReembolso.label: ''} <br/>
                                            <strong >Tipo de regalo - </strong>{state.detalle && state.detalle.idTipoRegalo ? state.detalle.idTipoRegalo.label: ''} <br/>
                                            {esReembolso() && state.detalle && state.detalle.idTipoReembolso && state.detalle.idTipoReembolso.label == 'Por recarga' 
                                                ? <>
                                                    <strong >Recursividad - </strong>{state.detalle && state.detalle.isRecursiveSelected ? state.detalle.isRecursiveSelected.label: ''}<br/>
                                                    <strong >Cantidad a recargar - </strong> {state.detalle && state.detalle.quantityToRecharge ? state.detalle.quantityToRecharge : ''}<br/>
                                                    <strong >Tipo de Cantidad a reembolsar - </strong> {state.detalle && state.detalle.isPercentageSelected ? state.detalle.isPercentageSelected.label : ''}<br/>
                                                    <strong >Cantidad a reembolsar - </strong> {state.detalle && state.detalle.quantity ? state.detalle.quantity : ''} <br/>
                                                </>
                                                : <>
                                                    <strong >Tipo de Cantidad a reembolsar - </strong> {state.detalle && state.detalle.isPercentageSelected ? state.detalle.isPercentageSelected.label : ''}<br/>
                                                    <strong >Cantidad a reembolsar - </strong> {state.detalle && state.detalle.quantity ? state.detalle.quantity : ''} <br/>
                                                </>
                                            }  
                                            <br />
                                            <strong >Medios donde aplica -</strong> {state.detalle && state.detalle.aplicaPara ?  state.detalle.aplicaPara.map(l=>l.label +', ') : ""}
                                            <br />
                                            {
                                                state.detalle && state.aplicaPara && state.aplicaPara.some(s=>s.label ==='App') 
                                                && 
                                                <p><strong >Descripción en APP  -</strong> {state.detalle.descripcionAPP}</p>
                                            }
                                            </p> }
                                </div>
                            </div>
                        </article>

                        {state.productos && 
                        <article className={`accordion is-light ${state.products_confirm ?'is-active':'' }  `}>
                            <div className="accordion-header toggle" onClick={(e) => {e.preventDefault(); setState({ ...state, products_confirm: !state.products_confirm})}}>
                                <p> Productos</p>
                            </div>
                            <div className="accordion-body">
                                <div className="accordion-content">
                                    <div className='row'>
                                        <div className="columns is-multiline">
                                        <PromoProductsTable 
                                            selectedProducts={state.productos}   
                                            showImage                                  
                                            setIsBase={() => {}}
                                            isBaseApply={false}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                        }
                        {state.drives && 
                        <article className={`accordion is-light ${state.drives_confirm ?'is-active':'' }  `}>
                            <div className="accordion-header toggle" onClick={(e) => {e.preventDefault(); setState({ ...state, drives_confirm: !state.drives_confirm})}}>
                                <p> Puntos de venta</p>
                            </div>
                            <div className="accordion-body">
                                <div className="accordion-content">
                                    <p className="has-text-justified">
                                    {state.drives.length !== 0 
                        ? 
                                    <table className="table is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Ciudad</th>                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.pagedrivesAsignados && state.pagedrivesAsignados.map((item, index) => {
                                            return (
                                                <tr key={index}>                                                    
                                                    <td>{item.NombreNuevo}</td>
                                                    <td>{item.Municipality}</td>                                                                                                  
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                :<NoItems itemName="drives"/>}
                                <Pagination items={state.drives} pageSize={ 6 } onChangePage={(drives) => setState({...state ,pagedrivesAsignados: drives })} />
                                    </p>
                                </div>
                            </div>
                        </article>
                        }
                        <article className={`accordion is-light ${state.restrictions_confirm ?'is-active':'' }  `}>
                            <div className="accordion-header toggle" onClick={(e) => {e.preventDefault(); setState({ ...state, restrictions_confirm: !state.restrictions_confirm})}}>
                                <p> Restricciones</p>
                            </div>
                            <div className="accordion-body">
                                <div className="accordion-content">
                                    <p className="has-text-justified">
                                        {state.restriccionesResum && state.restriccionesResum.length > 0
                                        ? state.restriccionesResum.map(res => {
                                            return(  <div> {res.title && res.title.length > 0
                                                        ? <p><label>{res.title} {res.restrictions.map((r, index) => r + `${(index+1) === res.restrictions.length ? '.':', '}` )}</label><br/></p>
                                                        : res.restrictions.map(r => { return <p><label> {r}</label><br/></p> })
                                                    }  </div>)  })
                                        : 'Ninguno'}
                                    </p>
                                </div>
                            </div>
                        </article>
                    
                    </section>


                }
            />
                    }
                
                </div>
                <Loading isFullscreen={true} isLoading={state.isLoading} width='100px' height='100px' />
            </div>
        </div>
    )
}
