export const nombresSinTipo=['sin tipo', 'n/a', 'n / a', 'na', '', 'no aplica',
            'sin sabor',
            'sin tipo sabor',
            'Sin sabor',
            'Sin Sabor',
            's/s',
            's / s',
            'sin grano',
            'sin tipo grano',
            's/g',
            's / g',
            'sin leche',
            'sin tipo leche',
            's/l',
            's / l',
            'sin presentación',
            'sin presentacion',
            'sin tipo presentación',
            'sin tipo presentacion',
            's/p',
            's / p',
            'sin clasificación',
            'sin clasificacion',
            'sin tipo clasificación',
            'sin tipo clasificacion',
            's/c',
            's / c',
            'sin receta',
            'sin tipo receta',
            's/r',
            's / r'
        ]