import * as React from 'react';
import PropTypes from 'prop-types'

/**
 * Componente de utilería con el único fin de mostrar un mensaje indicando que
 * alguna lista de elementos está vacía.
 */

class NoItems extends React.Component {

    render(){
        return (
            <div className="has-text-centered has-text-black-bis">
                <h2>{`No se encontraron ${this.props.itemName}`}</h2>
            </div>
        )
    }
}

// Definición de los tipos y su dependencia de las distintas props que recibe el componente.
NoItems.propTypes = {
    itemName: PropTypes.string.isRequired
};

export default NoItems;