import React, { useState } from "react";

// Components
import Modal from "../util/modal/Modal";
import Select from "react-select";
import Loader from "../util/loading/Loading";
import { toast } from "react-toastify";

// Service
import EmpresasAPI from "../../services/CaffenioEmpresas/Empresas";

export const ModalEditarEmpresas = ({
  isEditable,
  isCreated,
  empresa = {},
  segmentos = {},
  onClose,
  onSave,
}) => {
  const [state, setState] = useState({
    listaEmpresas: null,
    isLoading: false,
    popupVisible: false,
    showEditable: isEditable || isCreated ? true : false,
    segmentSelected:
      isEditable && !isCreated
        ? segmentos.find((seg) => seg.value === empresa.idSegmento)
        : null,
    NombreEmpresa: isEditable && !isCreated ? empresa.Nombre : "",
    GiroEmpresa: isEditable && !isCreated ? empresa.Giro : "",
    ContactoEmpresa: isEditable && !isCreated ? empresa.Contacto : "",
    DireccionEmpresa: isEditable && !isCreated ? empresa.Direccion : "",
  });

  // Change value of the segment of list.
  const handleChangeSegmento = (segment) => {
    setState({ ...state, segmentSelected: segment });
  };

  // Change value of the inputs.
  const handleChange = ({ target }) => {
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  // Save changes of the business.
  const handleSaveInfo = async () => {
    var nombre = document.getElementById("NombreEmpresa").value;
    var giro = document.getElementById("GiroEmpresa").value;
    var contacto = document.getElementById("ContactoEmpresa").value;
    var direccion = document.getElementById("DireccionEmpresa").value;
    setState({
      ...state,
      NombreEmpresa: nombre,
      GiroEmpresa: giro,
      ContactoEmpresa: contacto,
      DireccionEmpresa: direccion,
    });

    if (state.NombreEmpresa === null || !state.NombreEmpresa.trim()) {
      return toast.warn("No se ha asignado un Nombre a la empresa.");
    }
    if (
      (isCreated && state.segmentSelected === null) ||
      (isCreated && !state.segmentSelected)
    ) {
      return toast.warn("No se ha asignado un segmento a la empresa.");
    }
    var businessData = null;
    if (isEditable) {
      businessData = {
        idEmpresa: empresa.idEmpresa,
        idSegmento: state.segmentSelected.value,
        Nombre: state.NombreEmpresa,
        Giro: state.GiroEmpresa,
        Direccion: state.DireccionEmpresa,
        Contacto: state.ContactoEmpresa,
      };
    } else {
      businessData = {
        idSegmento: state.segmentSelected.value,
        Nombre: state.NombreEmpresa,
        Giro: state.GiroEmpresa,
        Direccion: state.DireccionEmpresa,
        Contacto: state.ContactoEmpresa,
      };
    }
    setState({ ...state, isLoading: true });
    var res = null;
    if (isEditable) {
      res = EmpresasAPI.updateInfoToBusiness(businessData);
    } else {
      res = EmpresasAPI.createNewBusiness(businessData);
    }
    res
      .then((response) => {
        if (response.status == 200) {
          onSave(response.data);
          onClose();
        }
      })
      .catch((err) => toast.error(err.response.data.Message))
      .finally(() => {
        setState({ ...state, isLoading: false });
      });
  };

  // Solo permite números, sin signos ni exponenciales
  const onKeyPressNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (
      /\+|-|e|E|\./.test(keyValue) ||
      event.target.value.length >= event.target.getAttribute("maxLength")
    )
      event.preventDefault();
  };

  return (
    <Modal
      showModal={true}
      modalTitle={
        isEditable && !isCreated
          ? "Editar empresa"
          : isCreated && !isEditable
          ? "Crear una empresa"
          : "Información de la empresa"
      }
      width="60%"
      cancel={() => onClose()}
    >
      {state.isLoading && (
        <Loader
          isFullscreen={true}
          isLoading={state.isLoading}
          width="100px"
          height="100px"
        />
      )}
      <div>
        <label className="label" style={{ textAlign: "left" }}>
          Datos de la empresa
        </label>
      </div>
      <br />
      {state.showEditable && (
        <div className="row">
          <div className="columns">
            <div className="column is-4">
              <label className="label">Segmentos</label>
              <Select
                placeholder={"Seleccionar..."}
                name={"selectedOption"}
                value={state.segmentSelected}
                onChange={(item) => handleChangeSegmento(item)}
                noOptionsMessage={() => "No hay opciones"}
                options={segmentos}
              />
            </div>
            <div className="column is-4">
              <label className="label">Nombre de la empresa</label>
              <input
                className="input"
                maxLength="50"
                label="Nombre de la empresa"
                name="NombreEmpresa"
                id="NombreEmpresa"
                value={state.NombreEmpresa}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="column is-4">
              <label className="label">Giro</label>
              <input
                className="input"
                maxLength="100"
                label="Giro de la empresa"
                name="GiroEmpresa"
                id="GiroEmpresa"
                value={state.GiroEmpresa}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
      )}
      {!state.showEditable && (
        <div className="row">
          <div className="columns">
            <div className="column is-4">
              <label className="label">Segmento</label>
              <p>{empresa.Segmento ? empresa.Segmento : "Sin información"}</p>
            </div>
            <div className="column is-4">
              <label className="label">Nombre de la empresa</label>
              <p>{empresa.Nombre ? empresa.Nombre : "Sin información"}</p>
            </div>
            <div className="column is-4">
              <label className="label">Giro</label>
              <p>{empresa.Giro ? empresa.Giro : "Sin información"}</p>
            </div>
          </div>
        </div>
      )}
      <br />
      <div>
        <label className="label" style={{ textAlign: "left" }}>
          Ubicación y Contacto
        </label>
      </div>
      <br />
      <div className="row">
        <div className="columns">
          {state.showEditable && (
            <div className="column is-4">
              <label className="label">Dirección</label>
              <input
                className="input"
                maxLength="200"
                label="Direccion de la empresa"
                name="DireccionEmpresa"
                id="DireccionEmpresa"
                value={state.DireccionEmpresa}
                onChange={(e) => handleChange(e)}
              />
            </div>
          )}
          {state.showEditable && (
            <div className="column is-4">
              <label className="label">Contacto</label>
              <input
                className="input"
                maxLength="10"
                type="number"
                label="Contacto"
                name="ContactoEmpresa"
                id="ContactoEmpresa"
                onKeyPress={onKeyPressNumber}
                value={state.ContactoEmpresa}
                onChange={(e) => handleChange(e)}
              />
            </div>
          )}
          {!state.showEditable && (
            <div className="column is-4">
              <label className="label">Dirección</label>
              <p>{empresa.Direccion ? empresa.Direccion : "Sin información"}</p>
            </div>
          )}
          {!state.showEditable && (
            <div className="column is-4">
              <label className="label">Contacto</label>
              <p>{empresa.Contacto ? empresa.Contacto : "Sin información"}</p>
            </div>
          )}
        </div>
      </div>
      {state.showEditable && (
        <div className="row">
          <div className="columns">
            <div
              className="column is-12 is-flex"
              style={{ justifyContent: "flex-end" }}
            >
              <button onClick={handleSaveInfo} className="button is-success">
                {isEditable ? "Actualizar empresa" : "Crear empresa"}
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
