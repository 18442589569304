import React, { Component} from 'react';

export default class UberEatsMenuRestaurantTable extends Component {

    columnas = ["Categoría", "Descripción", "Estatus", ""];

    constructor(props){
        super(props);
        this.state = {
            data: props.data,
            collapsed: true,
            loadingChangingStatus: this.props.loadingChangingStatus
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({collapsed: true});
        }
    }

    render(){
        console.log(this.props.data);
        return (
            <div>
                <div className="columns">
                    <div className="column is-1">
                        <span className="icon" onClick={() => this.setState({collapsed: !this.state.collapsed})} style={{cursor: 'pointer'}}>
                            <i className={`fa ${ this.state.collapsed ? 'fa-arrow-down' : 'fa-arrow-up'}`}/>
                        </span>
                    </div>
                    <div className="column is-3">
                        <label className="label">Menú Uber Eats</label>
                    </div>
                </div>
                {!this.state.collapsed && <table className="table is-fullwidth is-striped">
                    <thead>
                        {this.columnas.map(c => <th key={c}>{c}</th>)}
                    </thead>
                    <tbody>
                        {this.props.data.map((row, index) => {
                            return (
                                <tr key={`uberEatsMenu${index}`}>
                                    <td>{row.Type}</td>
                                    <td style={{fontWeight: `${row.Bold ? 'bold' : 'normal'}`}}>{row.Name}</td>
                                    <td>{row.IsValid ? row.Active ? "Activo" : "Inactivo" : ""}</td>
                                    <td>
                                        {row.IsValid && <button className={`button is-small tableAction ${row.Active ? 'is-danger' : 'is-success'} tooltip`} data-tooltip={row.Active ? "Desactivar" : "Activar"} onClick={(e) => {
                                            e.stopPropagation();
                                            this.props.activateUberEatsItem(row, index);
                                        }}>
                                            <span className="icon is-small">
                                                <i className={`fa ${index === this.props.loadingChangingStatus ? "fa-spinner fa-spin" : `${row.Active ? 'fa-times' : 'fa-check'}`}`} />
                                            </span>
                                        </button>}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>}
            </div>
        )
    }
}