import React, { Component } from 'react';
import  SearchForm  from "../../util/searchform/SearchForm";
import  TableTemplate  from "../../util/tabletemplate/TableTemplate";
import  NoItems  from "../../util/noitems/NoItems";
import Pagination from '../../util/pagination/Pagination';
import RecipesAPI from '../../../services/Recipe';
import { toast } from 'react-toastify';
import Loading from '../../util/loading/Loading';
import RecipeTypesAPI from '../../../services/RecipeType';
import PresentationsAPI from '../../../services/Presentations';
import MilksAPI from '../../../services/Milk';
import GrainsAPI from '../../../services/Grain';
import FlavorsAPI from '../../../services/Flavor';
import ClasificacionesAPI from '../../../services/Classification';
import Field from '../../util/field/Field';

import lodash, { forEach } from 'lodash';

import BtnModoGuiado from '../../util/BtnModoGuiado/BtnModoGuiado'
import Modal from '../../util/modal/Modal';
import RecipeImageForm from './RecipeImageForm';
import ErrorMessages from '../../../util/validation-error-messages';
import RecipeImageFilter from './RecipeImageFilter';

class RecipesImage extends Component {
    recipes = [];
    origRecipeTypesList = []; //Donde se va a guardar la lista original de tipos de receta
    origFlavorsList = []; //Donde se va a guardar la lista original de sabores
    origGrainsList = []; //Donde se va a guardar la lista original de granos
    origMilksList = []; //Donde se va a guardar la lista original de leches
    origPresentationList = []; //Donde se va a guardar la lista original de presentaciones

    // Variables donde se almacenaran los valores seleccionados en el select multi
    tempRecipeTypesList;
    tempPresentationList;
    tempMilkList;
    tempGrainList;
    tempFlavorsList;
    tempClassificationList;

    constructor(props){
        super(props);
        this.state = {
            recipes: [],
            filterText: "",
            recipe: null,
            pageRecipes: [],
            showModal: false,
            editing: false,
            removeModal: false,
            isLoading: false,
            syncModal: false,
            selectedRecipes: [],
            checkAll: false,
            syncMultiModal: false,
            // Variables sonde se almacenaran los criterios de busqueda definitivos
            recipeTypeList: [],
            selectedRecipeTypesList: [],
            presentationsList: [],
            selectedPresentationsList: [],
            milksList: [],
            selectedMilksList: [],
            grainsList: [],
            selectedGrainsList: [],
            flavorsList: [],
            selectedFlavorsList: [],
            classificationList: [],
            selectedClassificationList: [],
            selectedSegmentationList: [],
            manageToppingModal: false,
            addToppingModal: false,
            toppings: [],
            toppingError: false,
            menuRecetaModal: false,
            menuRecetaModalTitle: "",
            menuRecetaModalBody: "",
            menuRecetaCurrent: null,
            //Agregar artículos
            manageInventoryItemsModal:false,
            addInventoryItems:false,
            inventoryItems: [], 
            inventoryItemsTemp: [], 
            recipesToAddItems:[],
            inventoryItemsError:false,
            showInventoryItemsInfo:false
        }
    }

    componentDidMount(){
        this.setState({isLoading: true})
        let state = {...this.state};
        RecipesAPI.getAll().then(response => {
            state.recipes = response.data
            this.recipes = lodash.cloneDeep(response.data);

            // Obtener todos los tipos de recetas
            return RecipeTypesAPI.getAll()
        }).then(response => {
            let temp = [];
            
            response.data.map(obj => {
                if(obj.RecipeName) {
                    obj.value= obj.RecipeTypeId;
                    obj.label= obj.RecipeName;
                    
                }else{
                    obj.value= obj.RecipeTypeId;
                    obj.label= obj.Description;
                    
                }
                temp.push(obj)
                return obj;
            })
            temp.sort(this.compare); // Ordenar los labels para elegir
            state.recipeTypeList = temp;
            this.origRecipeTypesList = lodash.cloneDeep(temp)
            
            // Obtener todas las presentaciones
            return PresentationsAPI.getAll()
        }).then(response => {
            let tempPresentationList = [];
            response.data.map(obj => {
                if(obj.Active){
                    obj.value= obj.PresentationId;
                    obj.label= obj.Name;
                    tempPresentationList.push(obj)
                    return obj
                    //
                }                
            })
            tempPresentationList.sort(this.compare); // Ordenar los labels
            state.presentationsList = tempPresentationList
            this.origPresentationList = lodash.cloneDeep(tempPresentationList)           
            
            // Obtener los sabores
            return FlavorsAPI.getAll()
        }).then(response => {
            let tempFlavorsList = [];
            response.data.map(obj => {
                obj.value= obj.FlavorTypeId;
                obj.label= obj.Name;
                tempFlavorsList.push(obj)
                return obj;
                
            })
            tempFlavorsList.sort(this.compare); // Ordenar los labels        
            state.flavorsList = tempFlavorsList
            this.origFlavorsList = tempFlavorsList
            
            // Obtener las clasificaciones
            return ClasificacionesAPI.getAll()
        }).then(response => {
            let tempClassificationList = [];
            response.data.map(obj => {
                obj.value= obj.ClassificationId;
                obj.label= obj.Name;
                tempClassificationList.push(obj)
                return obj;
            })
            tempClassificationList.sort(this.compare); // Ordenar los labels
            state.classificationList = tempClassificationList
            
            // Guardar el estado
            this.setState(state)
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    }

    /**
     * Funcion para ordenar lexicograficamente
     * @param objA. primer operando
     * @param objB. segundo operando
     */
    compare = (objA, objB) => {
        // Use toUpperCase() to ignore character casing
        const labelA = objA.label.toUpperCase();
        const labelB = objB.label.toUpperCase();

        let comparison = 0;
        if (labelA > labelB) {
            comparison = 1;
        } else if (labelA < labelB) {
            comparison = -1;
        }
        return comparison;
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let state = this.state;
        state.filterText = text;
        this.setState(state, this.handleSearchMultipleFieldInput);
    }

    /**
     *Realizar el filtrado sobre las recetas para desplegar solo los elementos
     *que cumplen con las caracteristicas que el usuario ingreso.
     * 
     *@param: nada
     *@return: nada
     */
    handleSearchMultipleFieldInput = () => {
        let recipes = this.recipes.filter(
            recipe => ( 
                (this.state.selectedRecipeTypesList.length > 0 ? 
                this.state.selectedRecipeTypesList.includes(recipe.RecipeTypeId) : 
                true) &&
                (this.state.selectedFlavorsList.length > 0 ? 
                this.state.selectedFlavorsList.includes(recipe.FlavorTypeId) : 
                true) &&
                (this.state.selectedPresentationsList.length > 0 ? 
                this.state.selectedPresentationsList.includes(recipe.PresentationId) : 
                true) &&
                (this.state.selectedMilksList.length > 0 ? 
                this.state.selectedMilksList.includes(recipe.MilkTypeId) : 
                true) &&
                (this.state.selectedGrainsList.length > 0 ? 
                this.state.selectedGrainsList.includes(recipe.GrainTypeId) : 
                true) &&
                (this.state.selectedClassificationList.length > 0 ? 
                this.state.selectedClassificationList.includes(recipe.ClassificationId) : 
                true) &&
                (this.state.selectedSegmentationList.length > 0 ?
                this.state.selectedSegmentationList.includes(recipe.idSegmento) :
                true ) &&
                (this.state.filterText.trim() !== '' ?
                ( (recipe.ShortName 
                    ? recipe.ShortName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) 
                    : false) 
                    || recipe.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                : true)
            )
        );
        
        let state = {...this.state};
        state.recipes = lodash.cloneDeep(recipes);
        state.recipes.map(r => {
            r.statusCheck =false
        });
        state.selectedRecipes = [];
        state.checkAll = false;
        this.setState(state);
    }

    edit = (recipe) => { 
        this.props.history.push({pathname: `/recetas/editar/${recipe.RecipeId}`, state:{
            classifications: this.state.classificationList,
            presentations: this.state.presentationsList,
            flavors: this.state.flavorsList,
            milks: this.state.milksList,
            grains: this.state.grainsList,
            recipeTypes: this.state.recipeTypeList,
            lstRecipes:this.recipes
        }});   
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de receta
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleRecipeTypeChange = (arr) => {
        let state = {...this.state}
        let tempSelectedRecipeTypesList = [];
        arr.map(obj => {
            tempSelectedRecipeTypesList.push(obj.value);
        })

        state.selectedRecipeTypesList = tempSelectedRecipeTypesList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de sabores
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleFlavorChange = (arr) => {
        let state = {...this.state}
        let tempSelectedFlavorList = [];
        arr.map(obj => {
            tempSelectedFlavorList.push(obj.value);
        })

        state.selectedFlavorsList = tempSelectedFlavorList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre las presentaciones
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handlePresentationChange = (arr) => {
        let state = {...this.state}
        let tempPresentationList = [];
        arr.map(obj => {
            tempPresentationList.push(obj.value);
        })

        state.selectedPresentationsList = tempPresentationList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de leche
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleMilkChange = (arr) => {
        let state = {...this.state}
        let tempMilksList = [];
        arr.map(obj => {
            tempMilksList.push(obj.value);
        })

        state.selectedMilksList = tempMilksList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de granos.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleGrainChange = (arr) => {
        let state = {...this.state}
        let tempGrainList = [];
        arr.map(obj => {
            tempGrainList.push(obj.value);
        })

        state.selectedGrainsList = tempGrainList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre las clasificaciones.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleClassificationChange = (arr) => {
        let state = {...this.state}
        let tempClassificationList = [];
        arr.map(obj => {
            tempClassificationList.push(obj.value);
        })

        state.selectedClassificationList = tempClassificationList;
        state.selectedRecipes = [];
        // state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
            this.filterOptionsByClasification();
        });
    }

    /**
     * Filtra las opciones de los filtros(hehe) segun la clasificacion seleccionada.
     * Por ejemplo, seleccionó la Familia "Chocolate" entonces en el filtro de tipo de receta solo me tiene que aparecer "Caliente" y "Frio"
     */
    filterOptionsByClasification = () => {
        let clasificaciones = this.state.selectedClassificationList
        if(clasificaciones.length > 0 ){
            let stateFiltros = {}
            // Filtrar los tipos de receta
            RecipeTypesAPI.getByClasification(clasificaciones).then(response => {
                let temp = response.data.map(obj => {
                    return {value: obj.RecipeTypeId, label: obj.RecipeName ? obj.RecipeName : obj.Description}
                })
                stateFiltros.recipeTypeList = temp
                
                // Filtrar los sabores
                return FlavorsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempFlavorsList = response.data.map(obj => {
                    return {value: obj.FlavorTypeId, label:obj.Name}
                })
                tempFlavorsList.sort(this.compare); // Ordenar los labels        
                stateFiltros.flavorsList = tempFlavorsList
                
                // Filtrar los granos de cafe
                return GrainsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempGrainList = response.data.map(obj => {
                    return {value: obj.GrainTypeId, label: obj.Description}
                })
                tempGrainList.sort(this.compare); // Ordenar los labels
                stateFiltros.grainsList = tempGrainList
                
                // Filtrar los tipos de leche
                return MilksAPI.getByClasification(clasificaciones)
            })
            .then(response => {
                let tempMilkList = response.data.map(obj => {
                    return {value: obj.MilkTypeId, label: obj.Description}
                })
                tempMilkList.sort(this.compare); // Ordenar los labels
                stateFiltros.milksList = tempMilkList
                
                // Filtrar las presentaciones
                return PresentationsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempPresentationList = response.data.map(obj => {
                    return {value: obj.PresentationId, label: obj.Name}
                })
                tempPresentationList.sort(this.compare); // Ordenar los labels
                stateFiltros.presentationsList = tempPresentationList

                this.setState(stateFiltros)
            })
        }else{
            this.setState({
                recipeTypeList: lodash.cloneDeep(this.origRecipeTypesList),
                flavorsList: lodash.cloneDeep(this.origFlavorsList),
                grainsList: lodash.cloneDeep(this.origGrainsList),//Donde se va a guardar la lista original de granos
                milksList: lodash.cloneDeep(this.origMilksList),//Donde se va a guardar la lista original de leches
                presentationList: lodash.cloneDeep(this.origPresentationList)//Donde se va a guardar la lista original de presentaciones
            })
        }
    }

    handleSelectChange = (objFilters) => {
        this.setState(objFilters, () => {this.handleSearchMultipleFieldInput();})
    }

    /**
     * Funcion que se ejecuta al cambiar la pagina del tablero
     */
    changeRecipePage = (recipes) => {
        let recipesChecked = 0
        let checkAll = false
        // recipes.forEach(item => {
        //     if(this.state.selectedRecipes.findIndex(id => id === item.RecipeId) !== -1)
        //         recipesChecked++
        // })
        // if(recipesChecked === recipes.length) checkAll = true 

        this.setState({ pageRecipes: recipes, checkAll: checkAll })
    }
    
    render() {
        return (
            <div>                
                <h1 className="title is-2">Administrar Imágenes</h1>
                <div className="columns is-vcentered">
                    <div className="column is-12">
                        {/* <div className="card">
                            <div className="card-content">
                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'Receta'}
                                            placeholderText={"Ingrese descripción y nombre corto de receta"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* Filtro multiple para recetas */}
                <div className="card">
                    <div className="card-content">
                        <div className="columns is-multiline">
                            <div className="column is-1"></div>   
                            <div className="column is-10 has-text-justify" >
                                <label className="label" style={{color:'#808088'}}> <i className="fa fa-question-circle"></i> Seleccione una Familia y un Tipo de Receta para poder agregar imágenes en Tipo Receta, Tipo Sabor y Presentación, por favor.</label>
                            </div>
                            <div className="column is-1"></div>   
                            <br/>                         
                            <RecipeImageFilter
                                noMulti={true}
                                noGrainType
                                noMilkType
                                disabledByFamily
                                onChange = {(objFilters) => {this.handleSelectChange(objFilters)}}
                            />

                        </div>
                    </div>
                </div>

                <br />
                
                <div className="card">
                    <div className="card-content">
                        {/* Botones del header de la tabla */}
                        <div className="columns">
                            {/* Todos los resultados */}
                            <div className="column" style={{display: "flex"}}>
                            <span>{this.state.selectedClassificationList.length > 0 ? `Total de registros: ${this.state.recipes.length} Receta(s)` :''}</span>
                            </div>

                        {this.state.recipes.length  > 0 &&
                            <div className="column " style={{display: "flex", justifyContent: "flex-end", flexWrap: "wrap"}}>
                                {/* Imagenes por tamaño, sabor, tipo de receta */}
                                {  this.state.selectedClassificationList.length === 1
                                && this.state.selectedRecipeTypesList.length > 0 
                                && this.state.selectedPresentationsList.length > 0
                                && <a className="button is-primary tooltip" style={{marginRight: "0.5rem"}}
                                        onClick={(e) => this.setState({imageFormModal: true, action: "Presentación"})}
                                        data-tooltip="+ Imágenes presentación"
                                    >
                                        <span className="icon is-small">
                                            <i className="fa fa-image"></i>
                                        </span>
                                        <span>Presentación</span>
                                    </a>
                                }
                                { this.state.selectedClassificationList.length === 1 
                                && this.state.selectedRecipeTypesList.length > 0 
                                && this.state.selectedFlavorsList.length > 0
                                && <a className="button is-warning tooltip" style={{marginRight: "0.5rem"}}
                                        onClick={(e) => this.setState({imageFormModal: true,  action: "Tipo Sabor"})}
                                        data-tooltip="+ Imágenes tipo sabor"
                                    >
                                        <span className="icon is-small">
                                            <i className="fa fa-image"></i>
                                        </span>
                                        <span>Tipo sabor</span>
                                    </a>
                                }
                                { this.state.selectedClassificationList.length === 1 
                                    && this.state.selectedRecipeTypesList.length > 0
                                    && <a className="button is-info tooltip" style={{marginRight: "0.5rem"}}
                                            onClick={(e) => this.setState({imageFormModal: true,  action: "Tipo Receta"})}
                                            data-tooltip="+ Imágenes tipo receta"
                                        >
                                            <span className="icon is-small">
                                                <i className="fa fa-image"></i>
                                            </span>
                                            <span>Tipo Receta</span>
                                        </a>
                                }
                                 

                                

                                
                            </div>
                        }
                        </div>
                        {this.state.recipes.length !== 0 
                        ? <TableTemplate
                            columns={["RecipeId", "Description", "ShortName", "NombreSegmento"]}
                            columnsNames={["ID", "Descripción", "Nombre Corto", "Segmento"]}
                            data={this.state.pageRecipes}
                            noActions      
                            rowClickable={true}
                            details={(recipe) => this.setState({imageViewModal:true, selectedRecipes:recipe})}              
                        /> 
                        : <NoItems itemName="recetas"/>}
                        <Pagination items={this.state.recipes} onChangePage={(recipes) => this.changeRecipePage(recipes) } />
                    </div>
                </div>
                
                {/* Modales */}


                {/* Modal para editar imagen del sabor de la receta y el nombre corto */}
                {this.state.imageFormModal && 
                    <Modal
                        showModal={this.state.imageFormModal}
                        modalTitle={`
                            ${this.state.selectedClassificationList.length > 0 
                                ? this.state.classificationList.filter(c => c.ClassificationId == this.state.selectedClassificationList[0])[0].label 
                                :''
                            }  
                            ${this.state.selectedRecipeTypesList.length > 0 
                                ? this.origRecipeTypesList.filter(r => r.RecipeTypeId === this.state.selectedRecipeTypesList[0])[0].label : ''} 
                            ${this.state.selectedFlavorsList.length > 0
                                ? this.origFlavorsList.filter(f => f.FlavorTypeId === this.state.selectedFlavorsList[0])[0].label:''}
                            ${this.state.selectedPresentationsList.length > 0
                                ? this.origPresentationList.filter(p => p.PresentationId === this.state.selectedPresentationsList[0] )[0].label: ''}`}
                        cancel={() => this.setState({ imageFormModal: false })}
                        width="50%"
                    >
                        <RecipeImageForm
                            recipe={this.state.recipes} 
                            columna={this.state.action} 
                            back={() => this.setState({imageFormModal: false, isLoading: false, action:""})}                          
                            save = {(image) => {
                                this.setState({isLoading:true}, () => {
                                    switch(this.state.action){
                                        case "Presentación":
                                            RecipesAPI.editPresentacionWithFormData(this.state.recipes, image)
                                            .then(response => {
                                                
                                                // replace recipes , page recipes 
                                                if (response.data) {                                                    
                                                    let state = {...this.state};
                                                    state.pageRecipes = response.data;
                                                    state.recipes = response.data;

                                                    response.data.forEach(item => {                                                        
                                                        let index = -1;
                                                        this.recipes.map((element, indx) => {
                                                            if(element.RecipeId === item.RecipeId){
                                                                index = indx
                                                            }});                                                        
                                                        if(index > -1){
                                                            this.recipes[index] = item;
                                                        }                                                        
                                                    })
                                                    this.setState(state)
                                                }
                                                
                                                toast.dismiss();
                                                toast.success("Imágenes actualizada(s) correctamente.");
                                                
                                            })
                                            .catch(err => {
                                                toast.dismiss();
                                                if (err.response)
                                                    toast.error(err.response.data.Message)
                                                else
                                                    toast.error(ErrorMessages.SinConexion)

                                            })
                                            .finally(() => {
                                                this.setState({ imageFormModal: false, isLoading: false, action:"" })
                                            })
                                            break;
                                        case "Tipo Receta":
                                            RecipesAPI.editTipoRecetaWithFormData(this.state.recipes, image)
                                            .then(response => {
                                                // replace recipes , page recipes 
                                                if (response.data) {                                                    
                                                    let state = {...this.state};
                                                    state.pageRecipes = response.data;
                                                    state.recipes = response.data;

                                                    response.data.forEach(item => {                                                        
                                                        let index = -1;
                                                        this.recipes.map((element, indx) => {
                                                            if(element.RecipeId === item.RecipeId){
                                                                index = indx
                                                            }});                                                        
                                                        if(index > -1){
                                                            this.recipes[index] = item;
                                                        }                                                        
                                                    })
                                                    this.setState(state)
                                                }
                                                toast.dismiss();
                                                toast.success("Imágenes actualizada(s) correctamente.");
                                                
                                            })
                                            .catch(err => {
                                                toast.dismiss();
                                                if (err.response)
                                                    toast.error(err.response.data.Message)
                                                else
                                                    toast.error(ErrorMessages.SinConexion)

                                            })
                                            .finally(() => {
                                                this.setState({ imageFormModal: false, isLoading: false, action:"" })
                                            })
                                            break;
                                        case "Tipo Sabor":
                                            RecipesAPI.editTipoSaborWithFormData(this.state.recipes, image)
                                                .then(response => {
                                                    // replace recipes , page recipes 
                                                    if (response.data) {                                                    
                                                        let state = {...this.state};
                                                        state.pageRecipes = response.data;
                                                        state.recipes = response.data;

                                                        response.data.forEach(item => {                                                        
                                                            let index = -1;
                                                            this.recipes.map((element, indx) => {
                                                                if(element.RecipeId === item.RecipeId){
                                                                    index = indx
                                                                }});                                                        
                                                            if(index > -1){
                                                                this.recipes[index] = item;
                                                            }                                                        
                                                        })
                                                        this.setState(state)
                                                    }
                                                    toast.dismiss();
                                                    toast.success("Imágenes actualizada(s) correctamente.");
                                                    
                                                })
                                                .catch(err => {
                                                    toast.dismiss();
                                                    if (err.response)
                                                        toast.error(err.response.data.Message)
                                                    else
                                                        toast.error(ErrorMessages.SinConexion)

                                                })
                                                .finally(() => {
                                                    this.setState({ imageFormModal: false, isLoading: false, action:"" })
                                                })
                                            break;
                                    }
                                    

                                })
                                

                            }} 
                        />
                    </Modal>
                }

                {this.state.imageViewModal && 
                    <Modal
                        showModal={this.state.imageViewModal}
                        modalTitle={`${this.state.selectedRecipes ? this.state.selectedRecipes.Description: ''}`}
                        cancel={() => this.setState({ imageViewModal: false, selectedRecipes:null })}
                        width="50%"
                    >
                        <div className="columns">

                            <div className ="column is-4  " style={{textAlign: 'center'}}>
                            {this.state.selectedRecipes.UrlImage && this.state.selectedRecipes.UrlImage.length > 0
                            ? <Field
                                label="Tipo Sabor"
                                >
                                <div className="column">
                                    <figure className="image container-image ">
                                        <img src={this.state.selectedRecipes.UrlImage.toString() + "?" + new Date().getTime()}
                                            style={{ width: 'auto', height: '50px', display:'block', marginLeft:'auto', marginRight:'auto' }}
                                            alt="" />
                                    </figure>
                                </div>
                            </Field>
                            : <label style={{fontWeight:'200'}} >
                                <span className="icon is-large">
                                    <i className="fa fa-image"></i>
                                </span><br/>
                                Sin imagen sabor
                            </label>}
                            </div>
                            <div className ="column is-4">
                            {this.state.selectedRecipes.URLTipoReceta && this.state.selectedRecipes.URLTipoReceta.length > 0
                            ? <Field label="Tipo Receta">
                                <div className="column">
                                    <figure className="image container-image ">
                                        <img src={this.state.selectedRecipes.URLTipoReceta.toString() + "?" + new Date().getTime()}
                                            style={{ width: 'auto', height: '50px', display:'block', marginLeft:'auto', marginRight:'auto'  }}
                                            alt="" />
                                    </figure>
                                </div>
                            </Field>
                            : <label style={{fontWeight:'200'}} >
                                <span className="icon is-large">
                                    <i className="fa fa-image"></i>
                                </span><br/>
                                Sin imagen tipo receta
                            </label>}
                            </div>
                            <div className ="column is-4">
                                {this.state.selectedRecipes.URLPresentacion && this.state.selectedRecipes.URLPresentacion.length > 0
                                ? <Field label="Presentación">
                                <div className="column">
                                    <figure className="image container-image ">
                                        <img src={this.state.selectedRecipes.URLPresentacion.toString() + "?" + new Date().getTime()}
                                            style={{ width: 'auto', height: '50px', display:'block', marginLeft:'auto', marginRight:'auto' }}
                                            alt="" />
                                    </figure>
                                </div>
                                </Field>
                                : <label style={{fontWeight:'200'}} >
                                <span className="icon is-large">
                                    <i className="fa fa-image"></i>
                                </span><br/>
                                Sin imagen presentación
                            </label>}
                            </div>
                        </div>
                    </Modal>
                }
                
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />                

            </div>
        );
    }
}

export default RecipesImage;
