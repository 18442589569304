import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import MenuForm from './MenuForm';
import MenuDriveAPI from '../../services/MenuDrive';
import './MenusDrive.css'

class NewMenu extends Component {
    constructor(props){
        super(props);
        this.state = {
            menu: {...this.props.menu}
            ,menus:[]
        };
    }

    componentDidMount(){
        if (!this.props.location.state) {
            this.props.history.push(`/menus`);
            return;
        }
        this.setState({ menus: this.props.location.state.menus })
    }

    save = (menu) => {
        menu.Description = menu.Description.trim();
        if(menu.Description.length < 3){ 
            toast.error("Descripción debe de tener más de 2 caracteres");
            return;
        }

        // Checar si menú está relacionado con Caffenio Drive, si es true no poder desactivar..
        
        const loadingToast = toast.info("Cargando...", {autoClose: false, closeButton: false, className: "has-background-primary has-text-weight-bold"});
        MenuDriveAPI.post(menu).then(response => {
            //console.log(response);
            toast.dismiss(loadingToast);
            toast.success("Menú creado");
            this.props.history.goBack();
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{cursor: "pointer", color: "#91BA43" }} onClick={() => {this.props.history.goBack()}} className="fa fa-arrow-left"></i>
                            </span>
                            Nuevo Menú
                        </div>
                    </div>
                    <div className="card-content">
                        <MenuForm save={this.save} isEditing={this.props.isEditing}
                        menus={this.state.menus}/>
                    </div>
                </div>
            </div>
        );
    }
}

// Definición de los tipos y su dependencia de las distintas props que recibe el componente.
NewMenu.propTypes = {
    menu: PropTypes.object,
    isEditing: PropTypes.bool
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
NewMenu.defaultProps = {
    isEditing: false,
    menu: {
        Description: "",
        Active: false
    }
}

export default NewMenu;
