import * as React from 'react';
/**
 * Componente de utilería para un <input> que su único fin sea la búsqueda de algún elemento en una lista
 */
export default class SearchForm extends React.Component{

    render(){
        return (
            <form onSubmit={(e)=> e.preventDefault()}>
                <input
                    type="text"
                    className="input"
                    placeholder={this.props.placeholderText ? this.props.placeholderText : `Buscar ${this.props.itemName}`}
                    value={this.props.filterText}
                    ref="filterTextInput"
                    autoFocus={true}
                    onKeyUp={this.props.keyPressEnter}
                    onChange={(e) => this.props.onInput(e.currentTarget.value)}
                />
            </form>
        )
    }
}