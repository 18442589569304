export const validateExtensionFile = (fileName) => {

    // Versión chila y funcional de sacar la extensión de un archivo. Se deja como comentario nomas por fachoso. (8
    // let ext = fileName.split("").reverse().join("").split('.')[0].split('').reverse().join("");
    let splittedName = fileName.split(".")
    let ext = `${splittedName[splittedName.length - 1]}`.toLowerCase();
    var extensiones_permitidas = ["png", "jpg", "jpeg", "gif"];
    if(extensiones_permitidas.indexOf(ext) == -1){
        return false;
    }

    return true;
}

function arrayMoveMutate(array, from, to) {
	const startIndex = to < 0 ? array.length + to : to;
	const item = array.splice(from, 1)[0];
	array.splice(startIndex, 0, item);
};

function arrayMove (array, from, to) {
	array = array.slice();
	arrayMoveMutate(array, from, to);
	return array;
};

/**
 * Descarga automatica del achivo en el navegador.
 * @param {String} filename - Nombre del archivo.
 * @param {Data} text - Datos a convertir.
 * @param {String} mimeType - Extension del archivo.
 */
export const autoDownload = (filename, text, mimeType = 'text/plain') => {
    let element = document.createElement('a');
    element.setAttribute('href', `data:${mimeType};charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
}