import { apiUrl }  from "../constants";
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class FlavorsAPI {
    static getAll() {
        return request.get('/FlavorType');
    }

    static get(id) {
        return request.get(`/FlavorType/${id}`);
    }
    
    static getByClasification(ids){
        return request.post(`/FlavorType/GetByClassification`, ids);
    }

    static create(FlavorType) {
        let fd = new FormData();
        fd.append("Name", FlavorType.Name);
        fd.append("ShortName", FlavorType.ShortName);
        fd.append("Position", FlavorType.Position);
        fd.append("RecipeName", FlavorType.RecipeName);
        fd.append("Activo", FlavorType.Activo);
        fd.append("SegmentId", FlavorType.SegmentationId.SegmentId)
        FlavorType.Image && fd.append("Image", FlavorType.Image);

        return request.post('/FlavorType',fd);
    }

    static edit(FlavorType){
        let fd = new FormData();

        fd.append("Name", FlavorType.Name);
        fd.append("ShortName", FlavorType.ShortName);
        fd.append("Position", FlavorType.Position);
        fd.append("RecipeName", FlavorType.RecipeName);
        fd.append("FlavorTypeId", FlavorType.FlavorTypeId);
        fd.append("Activo", FlavorType.Activo);
        FlavorType.idSegmento && fd.append("SegmentId", FlavorType.idSegmento)
        
        FlavorType.Image instanceof File && fd.append("Image", FlavorType.Image);

        return request.put(`/FlavorType`,fd);
    }

    static delete(id){
        return request.delete(`FlavorType/${id}`);
    }
}