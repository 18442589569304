import React, { Component } from "react";
import Select from "react-select";
import NoItems from "../../util/noitems/NoItems";
import PaginationReports from "../../util/pagination/PaginationReports";
import Modal from "../../util/modal/Modal";
import Loading from "../../util/loading/Loading";
import DatePicker from "react-datepicker";
import moment from "moment";
import ReporteCorte from "../reporteCorte";
import { toast } from "react-toastify";
import CaffenioDriveAPI from "../../../services/caffenioDrive";
import ExportExcel from "react-export-excel";
import ReportesRetiroAPI from "../../../services/Reportes/ReportesRetiro";

// Creamos instancias para el package export excel.
// Variable que indica el archivo de excel que se crea.
const ExcelFile = ExportExcel.ExcelFile;
// Variable que indica una hoja de excel.
const ExcelSheet = ExportExcel.ExcelSheet;
// Variable que indica una columna de excel.
const ExcelColumn = ExportExcel.ExcelColumn;

class ReporteRetiros extends Component {
  state = {
    isLoading: false,
    retiros: [],
    retirosFilter: [],
    retiroSelect: null,
    fechaInicio: null,
    fechaFin: null,
    pageRetiros: [],
    retirosChecked: [],
    // States relacionados a los dropdowns de los drives
    selectedOption: [],
    selectedOptionC: [],
    selectedOptionB: [],
    selectedOptionD: [],
    OptionSelect: [],
    OptionSelectCity: [],
    OptionSelectBranch: [],
    OptionSelectDrive: [],
    optionState: [],
    optionCity: [],
    optionBranch: [],
    optionDrive: [],
    Caffenios: [],
    childVisible: false,
    checkAll: false,
    showModal: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true }, () => {
      let state = { ...this.state };
      CaffenioDriveAPI.getCaffenios()
        .then((response) => {
          state.Caffenios = response.data;
          state.Caffenios.map((producto) => {
            producto.statusCheck = false;
            return producto;
          });
          state.optionState = this.select_state(state.Caffenios);
          state.optionCity = this.select_city(state.Caffenios);
          state.optionBranch = this.select_branch(state.Caffenios);
          state.optionDrive = this.select_Drive(state.Caffenios);
          state.isLoading = false;
          this.setState(state);
        })
        .catch((err) => toast.error(err.response.data.Message));
    });
  }

  handleDateChange = (date, tipoFecha) => {
    let state = { ...this.state };
    state[tipoFecha] = date;
    this.setState(state, () => this.ConsultarRetirosPorFecha());
  };

  //#region Funciones para los dropdowns relacionados a los drives (Es copypaste de los dropdowns de la configuracion de drives)
  select_state(Caffenios) {
    // Llenar el combo 'Estados' con todas las opciones
    var newArray = [];
    let OptionSelect = [];
    var lookupObject = {};
    Caffenios.map((item) => {
      OptionSelect.push({ value: item.StateId, label: item.State });
    });

    for (var i in OptionSelect) {
      lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  }

  select_city(Caffenios) {
    // Llenar el combo 'Ciudades' con todas las opciones
    var newArray = [];
    let OptionSelectCity = [];
    var lookupObject = {};
    Caffenios.map((item) => {
      OptionSelectCity.push({
        value: item.Municipalityid,
        label: item.Municipality,
      });
    });

    for (var i in OptionSelectCity) {
      lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  }

  select_branch(Caffenios) {
    // Llenar el combo 'Sucursales' con todas las opciones
    var newArray = [];
    let OptionSelectBranch = [];
    var lookupObject = {};
    Caffenios.map((item) => {
      OptionSelectBranch.push({ value: item.Branch, label: item.Branch });
    });

    for (var i in OptionSelectBranch) {
      lookupObject[OptionSelectBranch[i]["value"]] = OptionSelectBranch[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  }

  select_Drive(Caffenios) {
    // Llenar el combo 'Drives' con todas las opciones
    var newArray = [];
    let OptionSelectDrive = [];
    var lookupObject = {};
    Caffenios.map((item) => {
      OptionSelectDrive.push({
        value: item.idCaffenio,
        label: item.NombreNuevo,
      });
    });

    for (var i in OptionSelectDrive) {
      lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  }

  eliminarObjDuplicados = (arr, prop) => {
    var nuevoArray = [];
    var lookup = {};

    for (var i in arr) {
      lookup[arr[i][prop]] = arr[i];
    }

    for (i in lookup) {
      nuevoArray.push(lookup[i]);
    }

    return nuevoArray;
  };

  changeSelectEstado = (obj) => {
    let state = { ...this.state };
    state["selectedOption"] = obj;
    let idEstado = obj.map((estado) => {
      return estado.value;
    });
    let objCaffenios = [...state.Caffenios];
    let listaCiudades = [];
    let listaDrives = [];

    // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
    objCaffenios.map((item) => {
      for (var i = 0; i < idEstado.length; i++) {
        if (item.StateId === idEstado[i]) {
          listaCiudades.push({
            value: item.Municipalityid,
            label: item.Municipality,
          });
        }
      }
    });

    //Se eliminan las ciudades duplicadas
    let eliminarDuplicados = this.eliminarObjDuplicados(listaCiudades, "value");
    //Se obtiene un arreglo con los id de las ciudades
    let idCiudad = eliminarDuplicados.map((ciudad) => {
      return ciudad.value;
    });

    //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
    objCaffenios.map((item) => {
      for (var i = 0; i < idCiudad.length; i++) {
        if (item.Municipalityid === idCiudad[i]) {
          listaDrives.push({ value: item.idCaffenio, label: item.NombreNuevo });
        }
      }
    });

    //Se limpian los combos 'Ciudades' y 'Drives'
    state["selectedOptionC"] = [];
    state["selectedOptionD"] = [];

    //Se valida que haya una opción seleccionada en combo 'Estados'
    if (obj.length === 0) {
      state["optionCity"] = this.select_city(state.Caffenios);
      state["optionDrive"] = this.select_Drive(state.Caffenios);
    } else {
      state["optionCity"] = eliminarDuplicados;
      state["optionDrive"] = listaDrives;
    }

    this.setState(state, () => this.FilterProcess());
  };

  changeSelectCiudad = (obj) => {
    let state = { ...this.state };
    let idCiudad = obj.map((ciudad) => {
      return ciudad.value;
    });
    let objCaffenios = [...state.Caffenios];
    let listaDrives = [];

    //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
    objCaffenios.map((item) => {
      for (var i = 0; i < idCiudad.length; i++) {
        if (item.Municipalityid === idCiudad[i]) {
          listaDrives.push({ value: item.idCaffenio, label: item.NombreNuevo });
        }
      }
    });

    //Se limpia el combo 'Drives'
    state["selectedOptionD"] = [];

    //Se valida que haya una opción seleccionada en combo 'Ciudades'
    if (obj.length > 0) {
      state["selectedOptionC"] = obj;
      state["optionDrive"] = listaDrives;
      this.setState(state, () => this.FilterProcess());
    } else {
      this.changeSelectEstado(state["selectedOption"]);
    }
  };

  changeSelectSucursal = (objSucursal, objDrive) => {
    let state = { ...this.state };
    let idSucursal = objSucursal.map((sucursal) => {
      return sucursal.value;
    });
    let objCaffenios = [...state.Caffenios];
    let listaDrives = [];

    //Se buscan coincidencias entre sucursal y drive y se genera la lista de drives
    objCaffenios.map((item) => {
      for (var i = 0; i < idSucursal.length; i++) {
        if (item.Branch === idSucursal[i]) {
          listaDrives.push({ value: item.idCaffenio, label: item.NombreNuevo });
        }
      }
    });

    // Validar si no existen drives seleccionados, resetear el filtro de drives.
    if (objDrive.length === 0) {
      //Se limpia el combo 'Drives'
      state["selectedOptionD"] = [];
    }

    //Se valida que haya una opción seleccionada en combo 'Sucursales'
    state["selectedOptionB"] = objSucursal;
    if (objSucursal.length > 0) {
      state["optionDrive"] = listaDrives;
    } else {
      //Se limpia el combo 'Drives'
      state["selectedOptionD"] = [];
      state["optionDrive"] = this.select_Drive(state.Caffenios);
    }
    this.setState(state, () => this.FilterProcess());
  };

  changeSelectDrive = (obj) => {
    let state = { ...this.state };
    let Caffenios = [...state.Caffenios];

    //Valida cada select (Estados,Ciudades,Drives) y realiza la acción correspondiente
    if (obj.length > 0) {
      state["selectedOptionD"] = obj;
      //state["optionDrive"] = obj;
      this.setState(state, () => this.FilterProcess());
    } else if (state["selectedOptionC"].length === 0) {
      this.changeSelectEstado(state["selectedOption"]);
      // resetear lista de drives con el primer filtro.
      this.changeSelectSucursal(state["selectedOptionB"], obj);
    } else if (
      state["selectedOptionC"].length === 0 &&
      state["selectedOption"].length === 0
    ) {
      this.select_Drive(Caffenios);
      this.select_Drive(Caffenios);
      this.select_Drive(Caffenios);
    } else {
      this.changeSelectCiudad(state["selectedOptionC"]);
    }
  };

  validarCombos = (state) => {
    var status_combo = false; // Vacios

    if (state["selectedOption"].length > 0) {
      status_combo = true; // Alguno con valor
    }

    if (state["selectedOptionC"].length > 0) {
      status_combo = true; // Alguno con valor
    }

    if (state["selectedOptionD"].length > 0) {
      status_combo = true; // Alguno con valor
    }

    return status_combo;
  };
  //#endregion

  /**
   * Se ejecuta al seleccionar un rango de fechas.
   * Hace una petición a la API para obtener los reportes de conciliación que están entre las fechas seleccionadas
   */
  ConsultarRetirosPorFecha = () => {
    if (this.state.fechaInicio != null && this.state.fechaFin != null) {
      this.setState({ isLoading: true }, () => {
        let body = {
          FechaInicio: this.state.fechaInicio,
          FechaFin: this.state.fechaFin,
        };
        let state = { ...this.state };
        ReportesRetiroAPI.getByDate(body)
          .then((response) => {
            state.retiros = response.data;
            state.retirosChecked = [];
            state.isLoading = false;
            this.setState(state, () => {
              this.FilterProcess();
            });
          })
          .catch((err) => {
            state.isLoading = false;
            state.retirosChecked = [];
            toast.error(err.response.data.Message);
            this.setState(state);
          });
      });
    }
  };

  /**
   * Se ejecuta al seleccionar un valor de los dropdowns relacionados a los drives (estados, ciudades, drives)
   * Lo que hace es filtrar los drives segun los valores de los dropdowns y tambien filtra los resultados de los reportes de retiros.
   * OJO. Esta función no hace peticiones a la API, solo filtra valores que estan en los states
   */
  FilterProcess = () => {
    let state = { ...this.state };
    let Caffenios = state.Caffenios;
    var status_combo; // Vacios
    let driveIds;
    let drivesSelected = [];

    if (state["selectedOptionD"].length === 0) {
      driveIds = state["optionDrive"].map((obj) => {
        return obj.value;
      });
    } else {
      driveIds = state["selectedOptionD"].map((obj) => {
        return obj.value;
      });
    }

    // Validar si existen drives seleccionados para comparar entre las
    // sucursales y drives y obtener la lista actual de drives seleccinados.
    if (state["selectedOptionD"].length > 0) {
      state["optionDrive"].map((obj) => {
        for (var i = 0; i < state["selectedOptionD"].length; i++) {
          if (obj.value === state["selectedOptionD"][i].value) {
            drivesSelected.push(state["selectedOptionD"][i]);
          }
        }
      });
      // Actualizar los drives seleccionados por sucursales seleccionadas.
      state["selectedOptionD"] = drivesSelected;
    }

    status_combo = this.validarCombos(state);

    Caffenios = Caffenios.filter((obj2) => {
      if (
        driveIds.filter((item) => {
          return item === obj2.idCaffenio;
        }).length > 0
      ) {
        return obj2;
      }
    });

    state.retirosFilter = state.retiros.filter((retiro) => {
      return (
        driveIds.findIndex((driveId) => {
          return driveId === retiro.idCaffenio;
        }) !== -1
      );
    });
    this.setState(state);
  };

  selectAllCheck = () => {
    let state = { ...this.state };

    state.checkAll = !state.checkAll;
    // Insertar o quitar los ids de la pagina actual
    state.pageRetiros.findIndex((item) => {
      let indexCheck = state.retirosChecked.findIndex(
        (retiro) => item.idTipoMovimientoCaja === retiro
      );
      if (state.checkAll) {
        if (indexCheck === -1)
          state.retirosChecked.push(item.idTipoMovimientoCaja);
      } else {
        if (indexCheck !== -1) {
          state.retirosChecked.splice(indexCheck, 1);
        }
      }
    });
    state.childVisible = state.retirosChecked.length > 0;
    this.setState(state);
  };

  /**
   * Se ejecuta al darle click a un checkbox de un renglón.
   * Lo que hace es determinar si el checkbox del header se tiene que marcar o no
   */
  showView = () => {
    let state = { ...this.state };
    let cheked = false;
    let checkAllTemp = true;
    state.pageRetiros.map((item) => {
      if (
        state.retirosChecked.findIndex(
          (retiro) => item.DepositSlipId === retiro
        ) !== -1
      ) {
        cheked = true;
      } else {
        checkAllTemp = false;
      }
    });

    this.setState({
      childVisible: state.retirosChecked.length > 0,
      checkAll: checkAllTemp,
    });
  };

  // Select all branch offices of the list and add to filter.
  selectAllBranchOffices = () => {
    let state = { ...this.state };
    state["selectedOptionB"] = this.state.optionBranch;
    this.setState(state, () => this.FilterProcess());
  };

  // Select all drives of the list and add to filter.
  selectAllDrives = () => {
    let state = { ...this.state };
    state["selectedOptionD"] = this.state.optionDrive;
    this.setState(state, () => this.FilterProcess());
  };

  render() {
    let fechaHoy = new Date();
    return (
      <div>
        <h1 className="title is-2">Retiros</h1>
        <div className="card">
          <div className="card-content">
            <div className="columns is-multiline">
              <div className="column is-6">
                <label className="label">Fecha Inicio</label>
                <DatePicker
                  name={"ReportDateStart"}
                  selected={this.state.fechaInicio}
                  onChange={(e) => this.handleDateChange(e, "fechaInicio")}
                  startDate={this.state.fechaInicio}
                  endDate={this.state.fechaFin}
                  maxDate={this.state.fechaFin ? this.state.fechaFin : fechaHoy}
                  popperPlacement="bottom"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 20px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                  locale="es"
                  todayButton="Hoy"
                  dateFormat="dd/MM/yyyy"
                  className="input"
                  autoComplete={"off"}
                />
              </div>
              <div className="column is-6">
                <label className="label">Fecha Fin</label>
                <DatePicker
                  name={"ReportDateEnd"}
                  selected={this.state.fechaFin}
                  onChange={(e) => this.handleDateChange(e, "fechaFin")}
                  disabled={this.state.fechaInicio == null}
                  startDate={this.state.fechaInicio}
                  endDate={this.state.fechaFin}
                  minDate={this.state.fechaInicio}
                  maxDate={fechaHoy}
                  popperPlacement="bottom"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 20px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                  locale="es"
                  todayButton="Hoy"
                  dateFormat="dd/MM/yyyy"
                  className="input"
                  autoComplete={"off"}
                />
              </div>

              <div className="column is-5">
                <label className="label">Sucursales</label>
                <div className="control">
                  <Select
                    isMulti
                    placeholder={"Seleccionar..."}
                    name={"selectedOptionB"}
                    value={this.state.selectedOptionB}
                    onChange={this.changeSelectSucursal}
                    noOptionsMessage={() => "No hay opciones"}
                    options={this.state.optionBranch}
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>

              <div id="buttonSelectAllFilter" className="column is-1">
                <button id="buttonSelect" className="button is-info is-medium btn-apply"
                onClick={() => {this.selectAllBranchOffices()}}>
                  <span className="icon">
                    <i class="fa fa-check-square"></i>
                  </span>
                  {/* <span></span> */}
                </button>
              </div>

              <div className="column is-5">
                <label className="label">Drives</label>
                <div className="control">
                  <Select
                    isMulti
                    placeholder={"Seleccionar..."}
                    name={"selectedOptionD"}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={this.state.selectedOptionD}
                    onChange={this.changeSelectDrive}
                    noOptionsMessage={() => "No hay opciones"}
                    options={this.state.optionDrive}
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>

              <div id="buttonSelectAllFilter" className="column is-1">
                <button id="buttonSelect" className="button is-info is-medium btn-apply"
                onClick={() => {this.selectAllDrives()}}>
                  <span className="icon">
                    <i class="fa fa-check-square"></i>
                  </span>
                  {/* <span></span> */}
                </button>
              </div>

            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <div className="card-content">
            {this.state.retiros.length > 0 ? (
              <React.Fragment>
                <div className="columns is-multiline">
                  <div className="column is-6 is-offset-3">
                    {/* ====== EXPORT DATA TO EXCEL ======= */}
                    <ExcelFile
                      filename={
                        "Retiros_" +
                        fechaHoy.toLocaleDateString().replace(/\//g, "_")
                      }
                      element={
                        <button className="button is-success is-medium is-fullwidth btn-apply">
                          <span className="icon">
                            <i className="fa fa-file" />
                          </span>
                          <span>Exportar a EXCEL</span>
                        </button>
                      }
                    >
                      <ExcelSheet
                        data={this.state.retirosFilter}
                        name="Retreats"
                      >
                        <ExcelColumn
                          label="Fecha"
                          value={(dateTime) =>
                            moment(dateTime.Fecha).format("DD/MM/YYYY")
                          }
                        />
                        <ExcelColumn
                          label="Hora"
                          value={(dateTime) =>
                            moment(dateTime.Fecha).format("HH:mm:ss")
                          }
                        />
                        <ExcelColumn label="Drive" value="Drive" />
                        <ExcelColumn label="Caja" value="Caja" />
                        <ExcelColumn
                          label="Empleado"
                          value={(rowName) =>
                            rowName.Nombre +
                            " " +
                            rowName.ApellidoP +
                            " " +
                            rowName.ApellidoM
                          }
                        />
                        <ExcelColumn
                          label="Monto de retiro"
                          value={(row) =>
                            "$" + parseFloat(row.Cantidad).toFixed(2)
                          }
                        />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>

                <table className="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Drive</th>
                      <th>Efectivo</th>
                      <th>Empleado</th>
                      <th>Caja</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.pageRetiros.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{moment(item.Fecha).format("DD/MM/YYYY")}</td>
                          <td>{item.Drive}</td>
                          <td>${item.Cantidad}</td>
                          <td>
                            {item.Nombre +
                              " " +
                              item.ApellidoP +
                              " " +
                              item.ApellidoM}
                          </td>
                          <td>{item.Caja}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </React.Fragment>
            ) : this.state.fechaInicio == null ||
              this.state.fechaFin == null ? (
              "Inserte fechas para iniciar la busqueda"
            ) : (
              <NoItems itemName="reportes de retiro" />
            )}
            <PaginationReports
              items={this.state.retirosFilter}
              onChangePage={(retirosPag) =>
                this.setState({ pageRetiros: retirosPag }, () =>
                  this.showView()
                )
              }
            />
          </div>
        </div>

        {this.state.showModal && (
          <Modal
            showModal={this.state.showModal}
            modalTitle={"Vista previa"}
            width="80%"
            cancel={() => this.setState({ showModal: false })}
          >
            <ReporteCorte
              caffenio={this.state.retiroSelect.DriveName}
              fecha={this.state.retiroSelect.Date}
              numConvenio={this.state.retiroSelect.AgreementNumber}
              numRef={this.state.retiroSelect.ReferenceNumber}
              totalEfectivo={this.state.retiroSelect.Cash}
              totalDeposito={this.state.retiroSelect.Cash}
            />
          </Modal>
        )}

        <Loading
          isFullscreen={true}
          isLoading={this.state.isLoading}
          width="100px"
          height="100px"
        />
      </div>
    );
  }
}

export default ReporteRetiros;
