import React, { Component } from 'react';
import { toast } from 'react-toastify';
import RecipeDetails from '../components/RecipeDetails';
import SelectDriveForm from '../components/SelectDriveForm';
import RecipeToppings from '../components/RecipeToppings';
import NewRecipeDetail from '../components/NewRecipeDetail';
import RecipeDrivesDetail from '../components/RecipeDrivesDetail';
import Modal from '../../../util/modal/Modal';
import AcceptModal from '../../../util/acceptModal/AcceptModal';



/**
 * Formulario para cargar la información general de la receta
 */
class RecipeItemsAndToppingStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipe: { ...this.props.recipe },
            isEditing: false,
            isLoading: false,
            //modal drive, artículos
            modalDrive: false,
            detailModal: false,
            selectedOption: [],
            selectedOptionC: [],
            selectedOptionD: [],
            // modal eliminar 
            removeModal: false,
            idRecipeDetail: 0,
            // modal para ver listado de drives
            detailDrivesModal: false,
            recipeDetail: {},
            // modales para toppingModal
            toppingModal: false,

        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ recipe: { ...nextProps.recipe }, isEditing: nextProps.isEditing });
    }


    /**Elimina articulo inventario de la receta */
    removeDetail = () => {
        let state = { ...this.state };
        state.recipe.Details.splice(state.idRecipeDetail, 1);
        state.removeModal = false;
        state.idRecipeDetail = null;
        this.setState(state);
        toast.success("Detalle eliminado");
    }

    /** Quita articulo inventario de los drive's seleccionados */
    removeDriveDetail = (itemToRemove) => {        
        let state = { ...this.state };
        let detalleToEdit = state.recipe.Details[state.detailIndex];
        detalleToEdit.DriveId = detalleToEdit.DriveId
            ? detalleToEdit.DriveId.filter(d => d.idDrive !== itemToRemove.idCaffenio)
            : this.props.Caffenios.filter(c => {
                if (c.idCaffenio !== itemToRemove.idCaffenio) {
                    c.value = c.idCaffenio;
                    c.label = c.NombreNuevo;
                    c.DriveMunicipio = c.Municipality;
                    c.DriveEstado = c.State;
                    c.idDrive = c.idCaffenio;
                    detalleToEdit.idDrive = c.idCaffenio;
                    return c;
                }
            });
        detalleToEdit.driveList = detalleToEdit.driveList.filter(val => val !== itemToRemove.idCaffenio);
        if(detalleToEdit.driveList.length > 0){
            let cities = detalleToEdit.DriveId.reduce((cities, item) => {
                if (!cities.includes(item.DriveMunicipio) && !cities.includes(item.Municipality)) {
                    cities.push(item.DriveMunicipio ? item.DriveMunicipio : item.Municipality)
                }
                return cities;
            }, []);
            let estados = detalleToEdit.DriveId.reduce((estados, item) => {
                if (!estados.includes(item.DriveEstado) && !estados.includes(item.State)) {
                    estados.push(item.DriveEstado ? item.DriveEstado : item.State)
                }
                return estados;
            }, []);
            detalleToEdit.DriveNombre = detalleToEdit.driveList.length >= this.props.Caffenios.length
                ? "Todos"
                : detalleToEdit.DriveId.length <= 4
                    ? detalleToEdit.DriveId.map(d => d.DriveNombre ? d.DriveNombre : d.Name).join(', ')
                    : cities.length > 4
                        ? estados.join(', ')
                        : cities.join(', ');
    
            state.recipe.Details[state.detailIndex] = detalleToEdit;
        }else{
            state.recipe.Details.splice(state.detailIndex, 1);
        }
        

        this.setState(state);
    }

    render() {
        return (
            <div>
                <div className="columns is-multiline is-centered">
                    <div className="column is-12">
                        <button className="button is-rounded is-medium is-info" onClick={() => this.setState({ modalDrive: true, detailModal: false })}>
                            <span className="icon">
                                <i className="fa fa-plus"></i>
                            </span>
                            <span>Artículo inventario</span>
                        </button>
                    </div>
                    <div className="column is-10">
                        <RecipeDetails
                            details={this.state.recipe.Details}
                            quantityChanged={(value, index) => {
                                
                                let recipe = { ...this.state.recipe };
                                recipe.Details[index].Amount = value;

                                this.setState({ recipe: recipe });
                                if (!value || value <= 0) {
                                    toast.warn("La cantidad debe ser mayor a cero.")
                                }

                            }}
                            removeDetail={(recipe) => {
                                this.setState({ removeModal: true, idRecipeDetail: recipe })
                            }}
                            viewDrivesDetail={(recipe, index) => {
                                this.setState({ detailDrivesModal: true, recipeDetail: recipe, detailIndex: index })
                            }}
                            mostrarDrives={true}
                            onMostrarDrives={(recipe) => {
                                this.setState({ mostrarDrivesModal: true, recipeDetail: recipe })
                            }}
                        />
                    </div>

                    {/* Modulo de toppings */}
                    <div className="column is-12">
                        <button className="button is-rounded is-medium is-info" onClick={() => this.setState({ toppingModal: true })}>
                            <span className="icon">
                                <i className="fa fa-plus"></i>
                            </span>
                            <span>Toppings generales</span>
                        </button>
                    </div>
                    <div className="column is-10">
                        <RecipeToppings
                            details={this.state.recipe.Toppings}
                            quantityChanged={(value, index, labelQuantity) => {
                                                                
                                let recipe = { ...this.state.recipe };
                                let newValue = value && value.length > 0 ? parseInt(value) : '';
                                if(newValue !== ''){
                                    if(labelQuantity =='MinQuantity'){
                                        if(newValue > recipe.Toppings[index]['MaxQuantity'] && recipe.Toppings[index]['MaxQuantity'] !==''){
                                            toast.error("La cantidad miníma no puede ser mayor a la cantidad máxima.")
                                            return;
                                        }
                                    }
                                    else if(labelQuantity =='MaxQuantity'){
                                        if(newValue < recipe.Toppings[index]['MinQuantity'] && recipe.Toppings[index]['MinQuantity'] !== ''){
                                            toast.error("La cantidad máxima no puede ser menor a la cantidad mínima.")
                                            return;
                                        }
                                    } 
                                }                                         
                                recipe.Toppings[index][labelQuantity] = newValue;                             
                                this.setState({ recipe: recipe });
                                    
                                

                            }}
                            removeDetail={(recipe) => {
                                let state = { ...this.state };
                                state.recipe.Toppings.splice(recipe, 1);
                                this.setState(state);
                                toast.success("Topping eliminado");
                            }}
                        />
                    </div>
                </div>
                <br />

                <div className="has-text-left buttons-modal" id="stepActions">
                    <div className="pull-left">
                        <button id="btnBackStepItemsAndToppings" type="button" className="button is-light" onClick={() => this.props.back()} >Anterior</button>

                    </div>
                </div>
                <div className="has-text-right buttons-modal" id="stepActions">

                    <div className="pull-right">
                        {
                            this.state.recipe.HasMenuAssigned && this.state.isEditing &&
                            <button
                                style={{ marginRight: "15px" }}
                                className="button is-success"
                                onClick={() => {
                                    let recipe = { ...this.state.recipe };
                                    // Valida todos los artículo inventario tengan valor mayo a 0
                                    if (recipe.Details.some(d => !d.Amount || d.Amount <= 0)){
                                        toast.dismiss()
                                        toast.error("Todos los artículos inventarios deben tener cantidad mayor a cero.")
                                        return;
                                    }
                                    // Válida todos los toppings tengan valor
                                    if(recipe.Toppings.some(d => 
                                        (d.MaxQuantity && d.MaxQuantity < 0) || 
                                        (d.MinQuantity && d.MinQuantity < 0) || 
                                        d.MinQuantity.length === 0 ||
                                        d.MaxQuantity.length === 0) ){
                                        toast.dismiss()
                                        toast.error("Todos los toppings deben tener cantidad mínima y máxima mayor o igual a cero.")
                                        return;
                                    }
                                    this.props.save(this.state.recipe, true)
                                }}>
                                Actualizar y Sincronizar
                            </button>
                        }
                        <button id="btnSaveStepItemsAndToppings" type="button" className="button is-success" 
                        onClick={() => {
                            let recipe = { ...this.state.recipe };
                            // Valida todos los artículo inventario tengan valor mayo a 0
                            if (recipe.Details.some(d => !d.Amount || d.Amount <= 0)){
                                toast.dismiss()
                                toast.error("Todos los artículos inventarios deben tener cantidad mayor a cero.")
                                return;
                            }
                            // Válida todos los toppings tengan valor
                            if(recipe.Toppings.some(d => 
                                (d.MaxQuantity && d.MaxQuantity < 0) || 
                                (d.MinQuantity && d.MinQuantity < 0) || 
                                d.MinQuantity.length === 0 ||
                                d.MaxQuantity.length === 0) ){
                                toast.dismiss()
                                toast.error("Todos los toppings deben tener cantidad mínima y máxima mayor o igual a cero.")
                                return;
                            }
                            
                            this.props.save(this.state.recipe, false)}} >{this.state.isEditing ? "Actualizar receta" : "Generar recetas"}</button>
                    </div>
                </div>

                {/* modales */}

                {/* Modal para seleccionar articulo inventario por drive */}
                {(this.state.modalDrive || this.state.detailModal) &&
                    <Modal
                        showModal={this.state.modalDrive || this.state.detailModal}
                        modalTitle={this.state.detailModal ? "Agregar artículo inventario" : "Seleccionar Drive(s)"}
                        customclassName="modalDrives"
                        cancel={() => this.setState({
                            modalDrive: false,
                            detailModal: false,
                            selectedOption: [],
                            selectedOptionC: [],
                            selectedOptionD: [],
                        })}
                        width="75%"
                    >
                        <div className="columns is-multiline is-centered">
                            {this.state.detailModal
                                ? <NewRecipeDetail
                                    addDetail={(detail) => {
                                        // Agrega artículo inventario a la receta  
                                        let recipe = { ...this.state.recipe };
                                        let exists = recipe.Details.filter(d => d.InventoryItemId === detail.InventoryItemId && Number(d.Amount) === Number(detail.Amount));
                                        if (exists.length > 0) {
                                            toast.warn("No se puede agregar el mismo artículo con la misma cantidad.")
                                        } else {
                                            detail.DriveId = [...this.state.selectedOptionD.map(selected => {
                                                if (!selected.idDrive) {
                                                    selected.idDrive = selected.idCaffenio;
                                                }
                                                return selected;
                                            })];

                                            let totalDrives = this.props.Caffenios.length;
                                            let pushStates = [];
                                            let pushCities = [];

                                            detail.DriveNombre = this.state.selectedOptionD.length > 4
                                                ? this.state.selectedOptionD.length === totalDrives
                                                    ? "Todos"
                                                    : this.state.selectedOptionD.reduce((list, item) => {
                                                        if (!list.some(l => l.Municipalityid === item.Municipalityid)) {
                                                            list.push(item);
                                                            pushCities.push(item.Municipality);
                                                        }
                                                        return list
                                                    }, []).length > 4
                                                        ? this.state.selectedOptionD.reduce((list, item) => {
                                                            if (!list.some(l => l.StateId === item.StateId)) {
                                                                list.push(item);
                                                                pushStates.push(item.State);
                                                            }
                                                            return list;
                                                        }, []).length > 1
                                                            ? pushStates.join(", ")
                                                            : this.state.selectedOptionD[0].State
                                                        : pushCities.join(", ")
                                                : this.state.selectedOptionD.map(d => { return d.NombreNuevo }).join(", ");

                                            detail.driveList = detail.DriveId.map(d => d.idCaffenio);
                                            recipe.Details.push(detail);

                                            this.setState({ recipe: recipe }, () => toast.success("Detalle agregado"));
                                        }
                                    }}
                                    currentDetails={
                                        []
                                        // this.state.recipe.Details.filter(det => {
                                        //     let caffenios = [...this.state.selectedOptionD];
                                        //     return caffenios.findIndex(dri => {
                                        //         return det.DriveId.findIndex(id => id.value === dri.value) != -1
                                        //     }) != -1
                                        // }).map(det => det.InventoryItemId)
                                    }

                                    isDetail={true}
                                />
                                : <SelectDriveForm
                                    optionState={this.props.optionState}
                                    Caffenios={this.props.Caffenios}
                                    closeModal={() => this.setState({ modalDrive: false, detailModal: true })}
                                    setStateFunc={(value) => this.setState({ selectedOption: value })}
                                    setCityFunc={(value) => this.setState({ selectedOptionC: value })}
                                    setDriveFunc={(value => this.setState({ selectedOptionD: value }))}
                                />}
                        </div>
                    </Modal>
                }

                {/**Modal para ver e listado de drives que comparten el artículo inventario */}
                {this.state.detailDrivesModal &&
                    <Modal
                        showModal={this.state.detailDrivesModal}
                        modalTitle={"Listado de Drives"}
                        cancel={() => this.setState({
                            detailDrivesModal: false
                        })}
                        width="75%"
                    >
                        <RecipeDrivesDetail
                            detail={this.state.recipeDetail.driveList}
                            removeDetail={(caffenioId) => this.removeDriveDetail(caffenioId)}
                            caffenios={this.props.Caffenios}

                        />
                    </Modal>
                }

                {/* Popup para confirmar la eliminación de un articulo de la receta */}
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.removeDetail()}
                    cancel={() => this.setState({ removeModal: false })}
                    modalTitle={"Eliminar artículo inventario"}
                />}

                {/* Modal para el modulo de toppings */}
                {this.state.toppingModal &&
                    <Modal
                        showModal={this.state.toppingModal}
                        modalTitle={"Agregar topping"}
                        cancel={() => this.setState({ toppingModal: false })}
                        width="75%"
                    >
                        <NewRecipeDetail
                            addDetail={(detail) => {                                
                                let recipe = { ...this.state.recipe };                                
                                recipe.Toppings.push({
                                    ...detail,
                                    MinQuantity: detail.MinQuantity || 0,
                                    MaxQuantity: detail.MaxQuantity || 0,
                                });
                                this.setState({ recipe: recipe });                               
                                
                            }}
                            currentDetails={this.state.recipe.Toppings.map(det => det.InventoryItemId)}
                            soloToppings={true}
                            isTopping={true}
                        />
                    </Modal>
                }
            </div>
        )
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
RecipeItemsAndToppingStep.defaultProps = {
    isEditing: false,
    recipe: {
        ShortName: "",
        Description: "",
        ClassificationId: 0,
        PresentationId: 0,
        FlavorTypeId: 0,
        RecipeTypeId: 0,
        IVAApply: true,
        KiwiId: 0,
        MilkTypeId: 0,
        GrainTypeId: 0,
        Active: true,
        MobileApply: true,
        WelcomeGiftApply: true,
        ClassificationSatId: 0,
        CodeSatId: 0,
        Details: [],
        GiftWelcomeActive: true
    }
}

export default RecipeItemsAndToppingStep;