import * as React from 'react';
import * as moment from 'moment';
import ServerPagination from '../../../util/pagination/ServerPagination';
import NoItems from '../../../util/noitems/NoItems';
import Loading from '../../../util/loading/Loading';
// Conversión de fechas de moment a español
require('moment/locale/es');

export default class TablaSegmentaciones extends React.Component {

    // Encabezado de columnas en tabla
    columnas = ["Nombre", "Fecha creación", "Última actualización", "Estatus", "#Clientes", "Segmento", "Tipo", "Acciones"]

    constructor(props){
        super(props);
        this.state = {
            totalPages: 0,
            currentPage: 0,
            loading: false
        }
    }

    // En caso de que las props cambien, verificamos que son por un cambio de la página actual o 
    // el total de páginas (ocasionado por una búsqueda)
    componentDidUpdate(prevProps) {
        if(this.props.currentPage !== this.state.currentPage || this.props.totalPages !== this.state.totalPages){
            this.setState({currentPage: this.props.currentPage, totalPages: this.props.totalPages});
        }

        if(this.props.loading != this.state.loading){
            this.setState({loading: this.props.loading});
        }
    }

    render(){

        return (
            <div className="card">
                <div className="card-content">
                    {this.props.segmentaciones.length > 0 ? <table className="table is-fullwidth is-striped">
                        <thead>
                            {this.columnas.map(c => <th key={c}>{c}</th>)}
                        </thead>
                        <tbody>
                            {this.props.segmentaciones.map((row, index) => {
                                return (
                                    <tr key={`seg${index}`}>
                                        <td>{row.Name}  {/*row.IdTipoSegmentacion != 2 ? null : (<i className="fa fa-file-excel-o"/>)*/}</td>
                                        <td>{moment(row.CreationDate).format('DD/MM/YY HH:mm')}</td>
                                        <td>{moment(row.UpdateDate).format('DD/MM/YY HH:mm')}</td>
                                        <td className="tooltip" data-tooltip={row.Status ? "Desactivar segmentación" : "Activar segmentación"}>
                                            <input
                                                type="checkbox"
                                                name="status"
                                                className="switch"
                                                
                                                checked={row.Status}
                                                onChange={() => { }}
                                            />
                                            <label disabled htmlFor="status" onClick={(e) => {
                                                this.props.changeStatus(row.IdSegmentation, !row.Status);
                                            }} />
                                        </td>
                                        <td>{row.TotalClients}</td>
                                        <td>{row.NombreSegmento}</td>
                                        <td>{row.IdTipoSegmentacion != 2 ? ("Filtro") : ("Archivo")}</td>
                                        {this.actions(row)}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table> : this.state.loading ? <Loading isLoading={this.state.loading} width='100px' height='100px'/> : <NoItems itemName="segmentaciones"/>} 
                    <ServerPagination 
                        totalPages={this.state.totalPages} 
                        currentPage={this.state.currentPage}
                        goToPage={this.props.goToPage}
                    />  
                </div>
            </div>
        )
    }

    actions = (segmentacion) => {

        const segmentacionVisible = segmentacion.IdTipoSegmentacion === 2;

        return (
            <td>
                {segmentacionVisible ? null : (
                <button disabled={!segmentacion.Status} className="button is-small tableAction is-link tooltip" data-tooltip="Duplicar" onClick={(e) => {
                    e.stopPropagation();
                    this.props.duplicate(segmentacion);
                }}>
                    <span className="icon is-small">
                        <i className="fa fa-clone" />
                    </span>
                </button>
                )}
                
                <button disabled={!segmentacion.Status} className="button is-small tableAction is-success tooltip" data-tooltip="Descargar" onClick={(e) => {
                    e.stopPropagation();
                    this.props.downloadFile(segmentacion.IdSegmentation, segmentacion.Name);
                }}>
                    <span className="icon is-small">
                        <i className="fa fa-download" />
                    </span>
                </button>

                
                {segmentacionVisible ? null : (
                <button disabled={!segmentacion.Status} className="button is-small tableAction is-warning tooltip" data-tooltip="Editar" onClick={(e) => {
                    e.stopPropagation();
                    this.props.edit(segmentacion.IdSegmentation)
                }}>
                    <span className="icon is-small">
                        <i className="fa fa-edit" />
                    </span>
                </button>
                )}
                

                <button disabled={!segmentacion.Status} className="button tableAction is-danger tooltip" data-tooltip="Eliminar" onClick={(e) => {
                    e.stopPropagation();
                    this.props.delete(segmentacion)
                }}>
                    <span className="icon">
                        <i className="fa fa-trash" />
                    </span>
                </button>

            </td>
        )
        
    }
}