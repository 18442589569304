import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import SaldoForm from './SaldoForm';
import NumVisitasForm from './NumVisitasForm';
import NotificacionAPI from '../../../../../../services/Caffenio App/Notificacion';

export default function TipoNotificacionForm({inputs, errors, setInput, handleInputChange, handleSelectChange, types, categories, notification}){


    return (
        <span style={{width: '100%'}}>
            <div className="column is-6">
                <label className="label">Tipo</label>
                <div className={`control`}>
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"type"}
                        value={inputs.type}
                        isClearable
                        onChange={(value, event) => {
                            handleSelectChange(value, event, true)
                            setInput('category', null);
                        }}
                        noOptionsMessage={() => "No hay opciones"}
                        options={types.map(t => {return {value: t.NotificationTypeId, label: t.Name}})}
                    />
                </div>
                {errors.type && <p className="help title has-text-left is-6 is-danger">Tipo requerido</p>}
            </div>
            <div className="column is-6">
                {inputs.type && <span>
                    <label className="label">Categoría</label>
                    <div className={`control`}>
                        <Select
                            placeholder={"Seleccionar..."}
                            name={"category"}
                            value={inputs.category}
                            isClearable
                            onChange={(value, event) => handleSelectChange(value, event, true)}
                            noOptionsMessage={() => "No hay opciones"}
                            options={categories.map(c => {return {value: c.NotificationCategoryId, label: c.Name}})}
                        />
                    </div>
                    {errors.category && <p className="help title has-text-left is-6 is-danger">Categoría requerida</p>}
                </span>}
            </div>
            {inputs.category && <div className="column is-12">
                <span>
                    {(inputs.category.value == 3 || inputs.category.value) == 1 && <label className="label"> Regla </label>}
                    <div className="control">
                        {inputs.category.value === 1 && <SaldoForm notification={notification} inputs={inputs} setInput={setInput} errors={errors} handleSelectChange={handleSelectChange} handleInputChange={handleInputChange}/>}
                        {inputs.category.value === 3 && <NumVisitasForm notification={notification} inputs={inputs} setInput={setInput} errors={errors} handleSelectChange={handleSelectChange} handleInputChange={handleInputChange}/>}
                    </div>
                </span>
            </div>}
        </span>
    )
}