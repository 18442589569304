import React, { Component } from 'react';
import TextField from '../util/textfield/TextField';
import Field from '../util/field/Field';
import Loading from '../util/loading/Loading';

class PriceListFormDetail extends Component {

    constructor(props){
        super(props);
        this.state = {
            priceDetail: {...this.props.priceDetail},
            isEditing: false,
            isLoading: false
        }
    }

    componentDidMount(){
    }

    handleChange = (e) => {
        let state = {...this.state};
        state.priceDetail[e.target.name] = e.target.value;
        this.setState(state);
    }

    render() {
        return (
            <div>
                <TextField autoFocus={true} type="number" min="0" max="999" maxLength="3" required label="Precio" name="SalePrice" value={this.state.priceDetail.SalePrice} onChange={(e) => this.handleChange(e)}
                onKeyPress= {(event) => {
                    const keyCode = event.keyCode || event.which;
                    const keyValue = String.fromCharCode(keyCode);
                    if (/\+|-|e|E|\./.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
                    event.preventDefault();
                }
                }/>
                <Field label="Receta" isHorizontal>
                    <input 
                        className="input"
                        type="text"
                        value={this.state.priceDetail.Description}
                        disabled={true}
                    />
                </Field>
                <Field label="Familia" isHorizontal>
                    <input 
                        className="input"
                        type="text"
                        value={this.state.priceDetail.ClassificationName}
                        disabled={true}
                    />
                </Field>
                <Field label="Presentación" isHorizontal>
                    <input 
                        className="input"
                        type="text"
                        value={this.state.priceDetail.PresentationName}
                        disabled={true}
                    />
                </Field>
                <Field label="Tipo Sabor" isHorizontal>
                    <input 
                        className="input"
                        type="text"
                        value={this.state.priceDetail.FlavorTypeName}
                        disabled={true}
                    />
                </Field>
                <Field label="Tipo Receta" isHorizontal>
                    <input 
                        className="input"
                        type="text"
                        value={this.state.priceDetail.RecipeTypeName}
                        disabled={true}
                    />
                </Field>
                <Field label="Tipo Leche" isHorizontal>
                    <input 
                        className="input"
                        type="text"
                        value={this.state.priceDetail.MilkTypeName}
                        disabled={true}
                    />
                </Field>
                <Field label="Tipo Grano" isHorizontal>
                    <input 
                        className="input"
                        type="text"
                        value={this.state.priceDetail.GrainTypeName}
                        disabled={true}
                    />
                </Field>
                <div className="has-text-right buttons-modal">
                    <div className="column">
                        <button
                            className="button is-success"
                            onClick={() => this.props.save(this.state.priceDetail)}>
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

PriceListFormDetail.defaultProps = {
    isEditing: false,
    isLoading: false,
    priceDetail: {
        Description: "",
        SalePrice: 0.0,
        ClassificationName: "",
        PresentationName: "",
        FlavorTypeName: "",
        RecipeTypeName: "",
        GrainTypeName: "",
        MilkTypeName: ""
    }
}

export default PriceListFormDetail;
