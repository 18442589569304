import React from "react"
import { toast } from 'react-toastify';
import { ReporteProyeccionComponent } from "./ReporteProyeccionComponent";
import ErrorMessages from '../../util/validation-error-messages';
import update from 'immutability-helper';
import lodash from 'lodash';
import ReporteProduccionAdelantadoAPI from "../../services/ReporteProduccionAdelantado";
import ProyeccionesAPI from "../../services/ProductionProyection";
import moment from 'moment';
import { format, formatDistance, formatRelative, subDays, setHours, setMinutes} from 'date-fns'

class ReporteProyeccionContainer extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            pageDrives: [],
            selectedOption: [],
            selectedOptionCity: [],
            selectedOptionDrive: [],
            selectedOptionProyeccion: [],
            OptionSelect: [],
            OptionSelectCity: [],
            OptionSelectDrive: [],
            OptionSelectProyeccion: [],
            optionState: [],
            optionCity: [],
            optionDrive: [],
            optionProyeccion: [],

            proyecciones: [],
            caffenios: [],
            caffeniosFilter: [],

            selectedDate: this.props.selectedDate,
            startHour: this.props.startHour,
            endingHour: this.props.endingHour,

            minStartHourLimit: this.props.minStartHourLimit,
            maxStartHourLimit: this.props.maxStartHourLimit,

            minEndingHourLimit: this.props.minEndingHourLimit,
            maxEndingHourLimit:  this.props.maxEndingHourLimit,

            // minStartHourLimit: setHours(new Date(), 5),
            // maxStartHourLimit: setHours(new Date(), 21),

            // minEndingHourLimit: setHours(new Date(), 5),
            // maxEndingHourLimit: setHours(new Date(), 21),

            filterIsActive: false,
            filterData: [],

            calculationList: [],
            pageCalculationList: [],
            calculationListFlatened: [],

            filterText: "",
            caffenio: null,

        }
        //this.initialFormula = []

        
    }
    
    componentDidMount(){
        this.changeLoading();
        this.getCaffenios();
    }


    getCaffenios = async() => {

        try{
            let response = await ReporteProduccionAdelantadoAPI.GetCaffeniosWithCalculations();
            let resProyecciones = await ProyeccionesAPI.GetProyeccionesData();

            let newState = {...this.state}
            newState.caffenios = response.data;
            newState.caffeniosFilter = response.data;
            newState.optionState = this.select_state(newState.caffenios);
            newState.optionCity = this.select_city(newState.caffenios);
            newState.optionDrive = this.select_Drive(newState.caffenios);
            newState.isLoading = false;

            newState.proyecciones = resProyecciones.data;
            newState.optionProyeccion = this.select_Proyeccion(newState.proyecciones);

            this.setState(newState);

        } catch (err) {
            let newState = update(this.state, {
                isLoading: {$set: false},
            });
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
            this.setState(newState);
        }   
    }

    getCaffenioListCalculations = async() => {

        await this.changeLoading();

        try{

            let filterData = [];

            this.state.caffeniosFilter.forEach(caf => {
                filterData.push({
                    idCaffenio: caf.idCaffenio,
                    Fecha: format((this.state.selectedDate), "yyyy-MM-dd"),
                    HoraInicio: format((this.state.startHour), "HH:mm:ss"),
                    HoraFin: format((this.state.endingHour), "HH:mm:ss"),
                    LstIdProducionAdelantado: this.state.selectedOptionProyeccion.map( x => x.value)
                })
            });

            //console.log('newState.filterData getCaffenioListCalculations:>> ', filterData);

            let response = await ReporteProduccionAdelantadoAPI.GetCaffeniosListCalculations(filterData);

            let newState = {...this.state} 
            newState.isLoading = false;
            newState.calculationList = response.data;
            newState.calculationListFlatened = lodash.flatten(response.data);
            newState.filterIsActive = true;
            this.setState(newState);

        } catch (err) {
            let newState = update(this.state, {
                isLoading: {$set: false},
            });
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
            this.setState(newState);
        } 
    }

    activateFilter = () => { 
        let newState = update(this.state, {
            filterIsActive: {$set: true},
        });
        this.setState(newState);
    }

    /**
     * Función que se encarga de cambiar el loading del state a su estado opuesto
     */

     changeLoading = () => {
        let newState = {...this.state}
        newState.isLoading = !this.state.isLoading
        newState.calculationList = []
        newState.calculationListFlatened = []
        this.setState(newState);  
    }

    handleSearchInput = (text) => {
        let state = {...this.state};
        state.filterText = text;
        this.setState(state, () => this.FilterProcess());
    }


    select_state(caffenios) { // Llenar el combo 'Estados' con todas las opciones 
        var newArray = [];
        let OptionSelect = [];
        var lookupObject  = {};
        caffenios.map((item) => {
            OptionSelect.push({value:item.StateId,label:item.State});
        });

        for(var i in OptionSelect) {
            lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        
        return newArray;
    }

    select_city(caffenios) { // Llenar el combo 'Ciudades' con todas las opciones 
        var newArray = [];
        let OptionSelectCity = [];
        var lookupObject  = {};
        caffenios.map((item) => {
            OptionSelectCity.push({value:item.Municipalityid,label:item.Municipality});
        });

        for(var i in OptionSelectCity) {
            lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    select_Drive(caffenios) { // Llenar el combo 'Drives' con todas las opciones 
        var newArray = [];
        let OptionSelectDrive = [];
        var lookupObject  = {};
        caffenios.map((item) => {
            OptionSelectDrive.push({value:item.idCaffenio,label:item.NombreNuevo});
        });

        for(var i in OptionSelectDrive) {
            lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    select_Proyeccion(proyecciones) { // Llenar el combo 'Proyeccion' con todas las opciones 
        var newArray = [];
        // let OptionSelectProyeccion = [];
        // var lookupObject  = {};
        // console.log("Proyeciones en proyecciones", proyecciones);
        proyecciones.map((item) => {
            // OptionSelectProyeccion.push({value:item.IdProduccionAdelantado,label:item.Nombre});
            newArray.push({value:item.IdProduccionAdelantado,label:item.Nombre});
        });

        // for(var i in OptionSelectProyeccion) {
        //     lookupObject[OptionSelectProyeccion[i]["value"]] = OptionSelectProyeccion[i];
        // }
    
        // for(i in lookupObject) {
        //     newArray.push(lookupObject[i]);
        // }

        // console.log("NewArray", newArray);
        return newArray;
    }

    changeSelectDrive = (obj) =>{
        let state = {...this.state};
        let caffenios = [...state.caffenios];
        
        //Valida cada select (Estados,Ciudades,Drives) y realiza la acción correspondiente
        if(obj.length>0){
            state["selectedOptionDrive"] = obj;
            //state["optionDrive"] = obj;
            this.setState(state, () => this.FilterProcess());
        } else if(state["selectedOptionCity"].length===0){
            this.changeSelectEstado(state["selectedOption"]);
            console.log("Entro aqui estado >> ", )
        } else if(state["selectedOptionCity"].length===0 && state["selectedOption"].length===0){
            this.select_Drive(caffenios);
            this.select_Drive(caffenios);
            this.select_Drive(caffenios);
        } else{
            this.changeSelectCiudad(state["selectedOptionCity"]);
            console.log("Entro aqui ciudad");
        }
    }

    changeSelectProyeccion = (obj) =>{
        let state = {...this.state};
        let proyecciones = [...state.proyecciones];
        console.log("Objeto obj changeProyeccion",obj);

        state["selectedOptionProyeccion"] = obj;
        this.setState(state, () => this.FilterProcess());
    }

    eliminarObjDuplicados = (arr,prop) => {
        var nuevoArray = [];
        var lookup  = {};
    
        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }
    
        for (i in lookup) {
            nuevoArray.push(lookup[i]);
        }
        
        return nuevoArray;
    }

    changeSelectEstado = (obj) => {
        let state = {...this.state}

        console.log("Las opciones >> ", state.optionState);
        console.log("El estado seleccionado >> ", state.selectedOption);
        console.log("El objeto changeSelectEstado >> ", obj);

        state["selectedOption"] = obj;
        let idEstado = obj.map(estado => {return estado.value});
        let objCaffenios = [...state.caffenios];
        let listaCiudades = [];
        let listaDrives = [];

        // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
        objCaffenios.map(item =>{
            for(var i=0; i<idEstado.length; i++){
                if(item.StateId===idEstado[i]){
                    listaCiudades.push({"value":item.Municipalityid,"label":item.Municipality});
                }
            }
        })

        //Se eliminan las ciudades duplicadas
        let eliminarDuplicados = this.eliminarObjDuplicados(listaCiudades,"value");
        //Se obtiene un arreglo con los id de las ciudades
        let idCiudad = eliminarDuplicados.map(ciudad => {return ciudad.value});

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item =>{
            for(var i=0; i<idCiudad.length; i++){
                if(item.Municipalityid===idCiudad[i]){
                    listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
                }
            }
        })

        //Se limpian los combos 'Ciudades' y 'Drives'
        state["selectedOptionCity"] = [];
        state["selectedOptionDrive"] = [];

        //Se valida que haya una opción seleccionada en combo 'Estados'
        if(obj.length===0){
            state["optionCity"] = this.select_city(state.caffenios);
            state["optionDrive"] = this.select_Drive(state.caffenios);
            
        } else{
            state["optionCity"] = eliminarDuplicados;
            state["optionDrive"] = listaDrives;
        }

        this.setState(state, () => this.FilterProcess());
    }

    changeSelectCiudad = (obj) =>{
        let state = {...this.state}
        let idCiudad = obj.map(ciudad => {return ciudad.value});
        let objCaffenios = [...state.caffenios];
        let listaDrives = [];

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item =>{
            for(var i=0; i<idCiudad.length; i++){
                if(item.Municipalityid===idCiudad[i]){
                    listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
                }
            }
        })

        //Se limpia el combo 'Drives'
        state["selectedOptionDrive"] = [];

        //Se valida que haya una opción seleccionada en combo 'Ciudades'
        if(obj.length>0){
            state["selectedOptionCity"] = obj;
            state["optionDrive"] = listaDrives;
            this.setState(state, () => this.FilterProcess());
        } else{
            this.changeSelectEstado(state["selectedOption"]);
        }
    }

    validarCombos = (state) => {
        var status_combo = false; // Vacios

        if(state["selectedOption"].length>0){
            status_combo = true; // Alguno con valor
        }

        if(state["selectedOptionCity"].length>0){
            status_combo = true; // Alguno con valor
        }

        if(state["selectedOptionDrive"].length>0){
            status_combo = true; // Alguno con valor
        }

        if(state["selectedOptionProyeccion"].length>0){
            status_combo = true; // Alguno con valor
        }

        return status_combo;
    }

    cleanFilters = () => {
        let nState = {...this.state}

        nState.selectedOption = []
        nState.selectedOption= []
        nState.selectedOptionCity= []
        nState.selectedOptionDrive= []
        nState.selectedOptionProyeccion = []
        nState.OptionSelect= []
        nState.OptionSelectCity= []
        nState.OptionSelectDrive= []
        nState.OptionSelectDrive = []
        nState.selectedDate= null
        nState.startHour= null
        nState.endingHour= null
        nState.calculationList= []
        nState.calculationListFlatened= []
        nState.filterIsActive= false
        nState.minStartHourLimit= this.props.minStartHourLimit
        nState.maxStartHourLimit= this.props.maxStartHourLimit
        nState.minEndingHourLimit= this.props.minEndingHourLimit
        nState.maxEndingHourLimit=  this.props.maxEndingHourLimit
        // let newState =  update(this.state, {
        //     selectedOption: {$set: []},
        //     selectedOptionCity: {$set: []},
        //     selectedOptionDrive: {$set: []},
        //     OptionSelect: {$set: []},
        //     OptionSelectCity: {$set: []},
        //     OptionSelectDrive: {$set: []},
        //     selectedDate: {$set: null},
        //     startHour: {$set: null},
        //     endingHour: {$set: null},
        //     calculationList: {$set: []},
        //     filterIsActive: {$set: false}

        // })
        this.setState(nState);
    }

    FilterProcess = () => {
        let state = {...this.state}
        let caffenios = state.caffenios;
        var status_combo; // Vacios
        let driveIds;

        if(state["selectedOptionDrive"].length===0){
            driveIds = state["optionDrive"].map(obj => {
                return obj.value;
            })
        } else{
            driveIds = state["selectedOptionDrive"].map(obj => {
                return obj.value;
            })
        }

        status_combo = this.validarCombos(state);

        if(state.filterText.trim() !== ''){
            caffenios = caffenios.filter(obj => {
                return obj.NombreNuevo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || obj.NombreNuevo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            });
        }

        caffenios = caffenios.filter((obj2)=>{
            if(driveIds.filter((item)=>{
                return item === obj2.idCaffenio
            }).length > 0){ return obj2 }
        })

        state.caffeniosFilter = caffenios;
        this.setState(state);
    }

    handlePageChange = (calculations) => { // Hacer la paginación en la tabla
        // console.log('calculations :>> ', calculations);
        // let newState = update(this.state, {
        //     calculationListFlatened: {$set: calculations},
        // });
        // this.setState(newState);
        this.setState({pageCalculationList: [...calculations]})
    }

    handleDateChange = (date, attr) => {
        let state = { ...this.state };
        state[attr] = date;
        this.setState(state);
    }

    handleStartTimeChange = (date, attr) => {
        if(date !== null){
            let state = { ...this.state };
            state[attr] = date;
            state.minEndingHourLimit = date;
            this.setState(state);
        }else{
            date = moment()
            date = date.set({
                hour: 6,
                minute: 0,
                second: 0,
                millisecond: 0,
              });
            let state = { ...this.state };
            state[attr] = date.toDate();
            state.minEndingHourLimit = date.toDate();
            this.setState(state);
        }
    }

    handleEndingTimeChange = (date, attr) => {
        // var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(date);
        // if(!!!isValid){
        //     date="06:00"
        // }
        if(date !== null){
            let state = { ...this.state };
            state[attr] = date;
            state.maxStartHourLimit = date;
            this.setState(state);
        }else{
            date = moment()
            date = date.set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 0,
            });
            let state = { ...this.state };
            state[attr] = date.toDate();
            state.maxStartHourLimit = date.toDate();
            this.setState(state);


            // let state = { ...this.state };
            // state[attr] = state.minEndingHourLimit;
            // state.maxStartHourLimit = state.minEndingHourLimit;
            // this.setState(state);
        }
    }

    checkHasEmptyFields = () => {
        if(this.state.selectedOptionProyeccion.length == 0) return true;
        if(this.state.selectedDate == null) return true;
        if(this.state.startHour == null) return true;
        if(this.state.endingHour == null) return true;
        return false;
    }




    render(){
        
        return (
            <ReporteProyeccionComponent
                {...this.state}
                changeSelectProyeccion = {this.changeSelectProyeccion}
                changeSelectDrive = {this.changeSelectDrive}
                changeSelectCiudad = {this.changeSelectCiudad}
                changeSelectEstado = {this.changeSelectEstado}
                handlePageChange = {this.handlePageChange}
                handleSearchInput = {this.handleSearchInput}
                handleDateChange = {this.handleDateChange}
                handleStartTimeChange = {this.handleStartTimeChange}
                handleEndingTimeChange = {this.handleEndingTimeChange}
                activateFilter = {this.activateFilter}
                getCaffenioListCalculations = {this.getCaffenioListCalculations}
                cleanFilters = {this.cleanFilters}
                checkHasEmptyFields = {this.checkHasEmptyFields}
            />
        );
    }


}

ReporteProyeccionContainer.defaultProps = {
    
    isLoading: false,
    pageDrives: [],
    selectedOption: [],
    selectedOptionCity: [],
    selectedOptionDrive: [],
    OptionSelect: [],
    OptionSelectCity: [],
    OptionSelectDrive: [],
    optionState: [],
    optionCity: [],
    optionDrive: [],

    caffenios: [],
    caffeniosFilter: [],

    selectedDate: subDays(new Date(), 1),

    startHour: new Date().setHours(6,0,0,0),
    endingHour: new Date().setHours(22,0,0,0),

    minStartHourLimit: new Date().setHours(6,0,0,0),
    maxStartHourLimit: new Date().setHours(21,0,0,0),

    minEndingHourLimit: new Date().setHours(6,0,0,0),
    maxEndingHourLimit: new Date().setHours(22,0,0,0),

    filterIsActive: false,
    filterData: [],

    calculationList: [],
    calculationListFlatened: [],

    filterText: "",
    caffenio: null,
}



export { ReporteProyeccionContainer };