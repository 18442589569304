import React, { useState, useEffect } from "react";
import NoItems from "../../util/noitems/NoItems";
import ServerPagination2 from "../../util/pagination/ServerPagination2";
import Loading from "../../util/loading/Loading";
import { ModalEditarEmpresas } from "../../miCaffenioEmpresas/ModalEditarEmpresa";
import "./style.css";
import { toast } from "react-toastify";

export default function TablaEmpresas(props) {
  const columnas = [
    "Segmento",
    "Nombre de la empresa",
    "Giro o Descripción de la empresa",
    "Número de empleados",
    "Estatus",
    "Acciones",
  ];

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [actualEmpresa, setActualEmpresa] = useState(null);
  const [listSegmentos, setListaSegmentos] = useState([]);
  const [detailVisible, setDetailVisible] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const [isCreated, setCreated] = useState(false);

  useEffect(() => {
    if (props.currentPage !== currentPage || props.totalPages !== totalPages) {
      setCurrentPage(props.currentPage);
      setTotalPages(props.totalPages);
    }

    if (props.loading !== loading) setLoading(props.loading);
  }, [props.totalPages, props.currentPage]);

  /**
   * Muestra en un modal la información de la empresa.
   * @param {Object} empresa - Info de la empresa seleccionada
   */
  const reviewOfBusiness = (empresa) => {
    let listSegmentos = props.segmentos.map((item) => {
      return {
        value: item.SegmentId,
        label: item.Name,
      };
    });
    setEditable(false);
    setCreated(false);
    setActualEmpresa(empresa);
    setListaSegmentos(listSegmentos);
    setDetailVisible(true);
  };

  /**
   * Motrar un modal para actualizar la información de la empresa.
   * @param {Object} empresa - Info de la empresa seleccionada
   */
  const editBusiness = (empresa) => {
    let listSegmentos = props.segmentos.map((item) => {
      return {
        value: item.SegmentId,
        label: item.Name,
      };
    });
    setEditable(true);
    setCreated(false);
    setActualEmpresa(empresa);
    setListaSegmentos(listSegmentos);
    setDetailVisible(true);
  };

  /**
   * Actualizar la informacion de la tabla, de la empresa actualizada.
   * @param {*} businessUpdated - Info de la empresa actualizada.
   */
  const updateBusiness = (businessUpdated) => {
    let indexBusinessToReplace = null;
    props.empresas.map((business, indexBusiness) => {
      if (business.idEmpresa === businessUpdated.idEmpresa) {
        indexBusinessToReplace = indexBusiness;
      }
    });
    props.empresas.splice(indexBusinessToReplace, 1, { ...businessUpdated });
    toast.success("Información de la empresa actualizada");
  };

  const actions = (empresa) => {
    return (
      <td>
        <button
          disabled={!empresa.Activo}
          className="button is-small tableAction is-warning tooltip"
          data-tooltip="Editar"
          onClick={(e) => {
            e.stopPropagation();
            editBusiness(empresa);
          }}
        >
          <span className="icon is-small">
            <i className="fa fa-edit" />
          </span>
        </button>
        {empresa.TotalEmpleados == 0 && (
          <button
            className="button is-small tableAction is-danger tooltip"
            data-tooltip="Eliminar"
            onClick={(e) => {
              e.stopPropagation();
              props.deleteBusiness(empresa);
            }}
          >
            <span className="icon">
              <i className="fa fa-trash" />
            </span>
          </button>
        )}
      </td>
    );
  };

  return (
    <div className="card">
      <div className="card-content">
        {props.empresas.length > 0 ? (
          <table className="table is-fullwidth">
            <thead>
              {columnas.map((c) => (
                <th key={c}>{c}</th>
              ))}
            </thead>
            <tbody>
              {props.empresas.map((row, index) => {
                return (
                  <tr
                    className="trClickable"
                    key={`empre${index}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      reviewOfBusiness(row);
                    }}
                  >
                    <td>{row.Segmento}</td>
                    <td>{row.Nombre}</td>
                    <td>{row.Giro}</td>
                    <td>{row.TotalEmpleados}</td>
                    <td
                      className="tooltip"
                      data-tooltip={
                        row.Activo ? "Desactivar Empresa" : "Activar Empresa"
                      }
                    >
                      <input
                        type="checkbox"
                        name="status"
                        className="switch"
                        checked={row.Activo}
                      />
                      <label
                        disabled
                        htmlFor="status"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.changeStatus(row);
                        }}
                      />
                    </td>
                    {actions(row)}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : props.loading ? (
          <Loading isLoading={props.loading} width="100px" height="100px" />
        ) : (
          <NoItems itemName="empresas" />
        )}
        <ServerPagination2
          totalPages={totalPages}
          currentPage={currentPage}
          loading={loading}
          goToPage={props.goToPage}
        />
      </div>
      {detailVisible && (
        <ModalEditarEmpresas
          isEditable={isEditable}
          isCreated={isCreated}
          empresa={actualEmpresa}
          segmentos={listSegmentos}
          onClose={() => setDetailVisible(false)}
          onSave={(businessUpdated) => updateBusiness(businessUpdated)}
        />
      )}
    </div>
  );
}
