import React, { Component } from 'react';
import Field from '../../util/field/Field';
import Dropdown from '../../util/dropdown/Dropdown';
import { toast } from 'react-toastify';
import Loading from '../../util/loading/Loading';
import SatAPI from '../../../services/Sat';
import ErrorMessages from '../../../util/validation-error-messages';
import ExpresionesRegulares from '../../../util/expresiones-regulares';
import { Formik } from 'formik';
import * as Yup from 'yup';

let UNITS_LIST = []; 

class UnitForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unit: { ...this.props.unit },
            isEditing: false,
            satCodes: [],
            isLoading: false,
            showModal: false
        }
    }

    componentDidMount() {   
        this.setState({ isLoading: true });
        SatAPI.getUnitCodes().then(response => {  
            UNITS_LIST = this.props.units;         
            this.setState({ satCodes: response.data, isLoading: false });
        }).catch(err => {
            this.setState({ isLoading: false })
            if (err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        });
    }

    VALIDATION_SCHEMA = Yup.object({
        Name: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(20, "Ha excedido el límite de 20 caracteres.")
            .required("Campo Nombre es requerido.")
            .test("validate-name", "El Nombre de unidad ya existe, favor de ingresar otro.", (value) => {
                let isValid = true;
                let name = value ? value.toLowerCase().trim() : "";
                if (UNITS_LIST.some(u => u.Name.toLowerCase().trim() === name && u.UnitsId !== this.state.unit.UnitsId)) {
                    isValid = false;               
                }
                return isValid;
            }),
        ShortName: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(1, "Nombre Corto debe tener más de un caracter.")
            .max(10, "Ha excedido el límite de 10 caracteres.")
            .required("Campo Nombre Corto es requerido.")
            .test("validate-shorname", "El Nombre Corto de unidad ya existe, favor de ingresar otro.", (value) => {
                let isValid = true;
                let shortName = value ? value.toLowerCase().trim() : "";
                if (UNITS_LIST.some(u => u.ShortName.toLowerCase().trim() === shortName && u.UnitsId !== this.state.unit.UnitsId)) {
                    isValid = false;               
                }
                return isValid;
            }),
        SatCode: Yup.string().required("Campo Código SAT de unidad es requerido.").nullable(),
    });

    render() {
        return (
            <Formik
                initialValues={this.state.unit}
                validationSchema={this.VALIDATION_SCHEMA}
                onSubmit={(values) => {     
                    let formatValues = {...values}
                    formatValues.Name =  formatValues.Name.trim();
                    formatValues.ShortName = formatValues.ShortName.trim();              
                    this.props.save(formatValues)                    
                }}
            >
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                    <form onSubmit={handleSubmit}>
                        <div>
                            <Field 
                                
                                label="Nombre"
                                isHorizontal
                                hasError = {errors.Name && touched.Name}
                                msgError = {errors.Name && touched.Name ? errors.Name : ""}
                                showLeftSpaces = {true}                                
                                childInfo = {values.Name && !errors.Name ? `Te quedan ${20 - values.Name.trim().length} caracteres para el nombre.`  : ""}
                            >
                                <input
                                    id="unidadNombre"
                                    name={"Name"}
                                    className={`input ${errors.Name && touched.Name ? "is-danger" : ""}`}
                                    placeholder={"Ingrese nombre de unidad"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.Name}
                                />
                            </Field>
                            <br/>
                            <Field label="Nombre corto"
                                isHorizontal
                                hasError = {errors.ShortName && touched.ShortName}
                                msgError = {errors.ShortName && touched.ShortName ? errors.ShortName : ""}
                                showLeftSpaces = {true}
                                childInfo = {values.ShortName && !errors.ShortName ? `Te quedan ${10 - values.ShortName.trim().length} caracteres para el nombre corto.`  : ""}
                            >
                                <input
                                    id="unidadNombreCorto"
                                    name={"ShortName"}
                                    className={`input ${errors.ShortName && touched.ShortName ? "is-danger" : ""}`}
                                    placeholder={"Ingrese nombre corto de unidad"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.ShortName}

                                />
                            </Field>
                            <br/>
                            <Field 
                                label="Código SAT"
                                isHorizontal
                                hasError={errors.SatCode && touched.SatCode}
                                msgError={errors.SatCode && touched.SatCode ? errors.SatCode : ""}
                                showTooltip={true}
                                textTooltip={"Seleccione código de facturación SAT."}
                            >
                                <Dropdown
                                    single
                                    items={this.state.satCodes}
                                    value={values.SatCode}
                                    id="Key"
                                    onSelect={(val) => {
                                        let selected = val === 0 ? 0 : this.state.satCodes[val - 1].Key;
                                        setFieldValue('SatCode', val === 0 ? null : selected)
                                    }}
                                    onBlur={() => setFieldTouched('SatCode', true)}
                                    labelKey={"Label"}
                                    hasError={errors.SatCode && touched.SatCode}
                                />
                            </Field>

                            <div className="has-text-right buttons-modal">
                                <div className="column">
                                    <button type="submit" className="button is-success" >
                                        Guardar
                                </button>
                                </div>
                            </div>
                        </div>
                        
                        <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
                    </form>
                )}

            </Formik>            
        );
    }
}

UnitForm.defaultProps = {
    isEditing: false,
    unit: {
        ShortName: "",
        Name: "",
        SatCode: null
    }
}

export default UnitForm;
