import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';
import { stringify } from "querystring";

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
});

/**
 * Servicio para el recurso de sucursales
 */
export default class SucursalAdminAPI {
    /**
     * Obtiene a las sucursales administrativa.
     * @returns Promise
     */
    static getSucursales() {
        return middleware.hasAuth(() => request.get(`/Sucursal`));
    }


    
}