import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';
import { stringify } from "querystring";

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
});

/**
 * Servicio para el recurso de Usuarios
 */
export default class UsersAPI {
    /**
     * Obtiene a los usuarios de Caffenio Auth con acceso a SAG
     * @returns Promise
     */
    static getUsers( filters = {} ) {
        return middleware.hasAuth(() => request.get(`/Users?${stringify(filters)}`));
    }
}