import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Field from '../../util/field/Field';
import Dropdown from '../../util/dropdown/Dropdown';


const SCHEMA = Yup.object({
    FactDataId: Yup.string().required("El campo 'Datos facturación' es requerido.").nullable(),
    TaxRateId: Yup.string().required("El campo 'Tasa impuesto' es requerido.").nullable(),
    SegmentId: Yup.string().required("El campo 'Segmento' es requerido.").nullable(),
});

/**
 * Formulario con la información general del folio
 */
export const StepFacturacionDrive = ({ ...props }) => {    
    
    return (
        <Formik
            enableReinitialize={true}
            initialValues={props.isEditing ? props.caffenio : {FactDataId: 11, TaxRateId:[], SegmentId:[] }}
            validationSchema={SCHEMA}
            onSubmit={(values) => {
                let caffenioUpdated = {...props.caffenio};
                caffenioUpdated.FactDataId = values.FactDataId;
                caffenioUpdated.TaxRateId = values.TaxRateId;
                caffenioUpdated.SegmentId = values.SegmentId;

                props.save(caffenioUpdated)
            }}>
            {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                <form onSubmit={handleSubmit} id="frmFacturacionDriveStep">
                    <div className="columns is-multiline">
                        <div className="column is-12">

                            <Field label="Datos Facturación" isHorizontal
                                hasError={errors.FactDataId || touched.FactDataId}
                                msgError={errors.FactDataId || touched.FactDataId ? errors.FactDataId : ""}>
                                {<Dropdown
                                    single
                                    items={props.DatosFact}
                                    value={values.FactDataId}
                                    disabled={true}
                                    id="FactDataId"
                                    onSelect={(val) => {
                                        if (val > 0) {
                                            let selected = props.DatosFact[val - 1].FactDataId;
                                            setFieldValue('FactDataId', selected)

                                        } else {
                                            setFieldValue('FactDataId', null)
                                        }
                                    }}
                                    labelKey={"SocialReason"}
                                    hasError={errors.FactDataId && touched.FactDataId}
                                />}
                            </Field>                            
                        </div>
                        <div className="column is-12">
                            <Field label="Tasa Impuesto" isHorizontal
                                hasError={errors.TaxRateId || touched.TaxRateId}
                                msgError={errors.TaxRateId || touched.TaxRateId ? errors.TaxRateId : ""}>
                                {<Dropdown
                                    single
                                    items={props.TasaImp}
                                    value={values.TaxRateId}
                                    id="TaxRateId"
                                    onSelect={(val) => {
                                        if (val > 0) {
                                            let selected = props.TasaImp[val - 1].TaxRateId;
                                            setFieldValue('TaxRateId', selected)

                                        } else {
                                            setFieldValue('TaxRateId', null)
                                        }
                                    }}
                                    labelKey={"Description"}
                                    hasError={errors.TaxRateId && touched.TaxRateId}
                                />}
                            </Field>
                        </div>
                        <div className="column is-12">
                            <Field label="Segmento" isHorizontal
                                hasError={errors.SegmentId || touched.SegmentId}
                                msgError={errors.SegmentId || touched.SegmentId ? errors.SegmentId : ""}>
                                {<Dropdown
                                    single
                                    items={props.Segmento}
                                    value={values.SegmentId}
                                    id="SegmentId"
                                    onSelect={(val) => {
                                        if (val > 0) {
                                            let selected = props.Segmento[val - 1].SegmentId;
                                            setFieldValue('SegmentId', selected)

                                        } else {
                                            setFieldValue('SegmentId', null)
                                        }
                                    }}
                                    labelKey={"Name"}
                                    hasError={errors.SegmentId && touched.SegmentId}
                                />}
                            </Field>                            
                        </div>

                    </div>

                    <br />

                    <div className="has-text-left buttons-modal" id="stepActions">
                        <div className="pull-left">
                            <button id="btnBackStepFacturacionDrive" type="button" className="button is-light" onClick={() => {
                                let caffenioUpdated = {...props.caffenio};
                                caffenioUpdated.FactDataId = values.FactDataId;
                                caffenioUpdated.TaxRateId = values.TaxRateId;
                                caffenioUpdated.SegmentId = values.SegmentId;
                                props.back(caffenioUpdated)}} >Anterior</button>

                        </div>
                    </div>
                    <div className="has-text-right buttons-modal" id="stepActions">
                        <div className="pull-right">
                            <button id="btnSaveStepFacturacionDrive" type="submit" className="button is-success"  >Continuar</button>
                        </div>
                    </div>

                </form>
            )}

        </Formik>
    );

}

export default StepFacturacionDrive;