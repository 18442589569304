import React from 'react';
import PropTypes from 'prop-types';

/**
 * Componente de utilería que sirve para el desplegado de un input dropdown para la selección de uno 
 * o más elemento de un arreglo.
 */

class Dropdown extends React.Component{
    render(){
        return (
            <select
                id={this.props.id || 'no_id'}
                disabled={this.props.disabled}
                className={`input ${this.props.hasError ? "is-danger" : ""}`}
                onChange={(e) => {
                    let index = e.target.selectedIndex;
                    if(!this.props.single) e.target.selectedIndex = 0;
                    this.props.onSelect(index);
                }}>
                <option disabled={this.props.disabledFirstSelect} selected={true}>Seleccionar...</option>
                {this.props.items && this.props.items.map((item, index) => {
                    return (
                        <option id={`${this.props.id || 'no_id'}_${index}`} key={index} selected={this.props.value && this.props.value === item[this.props.id]}>
                            {item[this.props.labelKey]}
                        </option>
                    )
                })}
            </select>
        )
    }
}

// Definición de los tipos y su dependencia de las distintas props que recibe el componente.
Dropdown.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    labelKey: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    id: PropTypes.string,
    single: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledFirstSelect: PropTypes.bool
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
Dropdown.defaultProps = {
    items: [],
    labelKey: "name",
    id: "id",
    single: false,
    disabled: false,
    disabledFirstSelect: false
}

export default Dropdown;
