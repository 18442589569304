import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import TextField from '../util/textfield/TextField';
import Dropdown from '../util/dropdown/Dropdown';
import { toast } from 'react-toastify';

export default class SaldoRegaloForm extends Component {
    static propTypes = {
        CustomerNumber: PropTypes.number,
        LoyalAccount: PropTypes.string,
        StarDate: PropTypes.string,
        EndDate: PropTypes.string,
        Deposit: PropTypes.number,
        ReasonDeposit: PropTypes.string,
        save: PropTypes.func.isRequired
    }

    static defaultProps = {
        CustomerNumber: 0,
        LoyalAccount: 0,
        StarDate: moment().add(1, 'days'),
        EndDate: moment().add(7, 'days'),
        Deposit: 0,
        ReasonDeposit: ""
    }

    constructor(props) {
        super(props);
        debugger
        this.state = { ...props, accountSelection: -1,StarDate: moment().add(1, 'days'),EndDate: moment().add(7, 'days') };
    }


    handleChange = (e) => {
        let state = { ...this.state };
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    handleDateChange = (date, attr) => {
        let state = { ...this.state };
        state[attr] = date;
        this.setState(state);
    }

    validationsIdentifier = (identifier) => {
        const regex = /\d+$/;

        if (typeof identifier === "undefined" || identifier.toString().replace(/ /g, '') === '' || identifier.toString().match(regex) === null) {
            return { error: `El campo TarjetaLealtad/Cliente no debe estar vacío y debe ser un valor numérico.`, isValid: false }
        }

        console.warn(identifier);
        if (identifier <= 0) {
            return { error: ` El campo TarjetaLealtad/Cliente debe ser mayor a cero.`, isValid: false }
        }

        return { isValid: true };
    }

    validationsLoyaltyAccount = (account) => {
        const validRegEx = /((1)|(7))(2419)([0-9]{9})$/g;
        if (account.toString().match(validRegEx) === null || account.toString().length > 30) {
            return { isValid: false };
        }
        return { isValid: true };
    }

    validationsDate = (date, is_date_end = false) => {
        let text_date = is_date_end === false ? "FechaInicial" : "FechaFinal";
        const current_date = moment();

        if (typeof date === "undefined" || date.replace(/ /g, '') === '' || typeof date !== 'string') {
            return { error: ` | El campo ${text_date} no debe estar vacío y debe ser un formato de fecha.`, isValid: false };
        }


        if (date.replace(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i) === '') {
            return { error: ` | El campo ${text_date} no es un valor valido formato: DD/MM/YYYY`, isValid: false }
        }

        date = moment(date, 'DD/MM/YYYY');
        if (date.isBefore(current_date)) {

            return { error: `| El campo de ${text_date} debe ser mayor al día de hoy`, isValid: false }
        }

        return { isValid: true, date: date };
    }


    validationsAmount = (amount) => {
        if (typeof amount === "undefined" || amount.toString().replace(/ /g, '') === '') {
            return false;
        }

        if (isNaN(amount) || amount <= 0) {
            return false
        }

        return true;
    }

    validationsReason = (reason) => {
        if (typeof reason === "undefined" || reason.toString().replace(/ /g, '') === '') {
            return false;
        }

        const validRegEx = /[A-Za-z]{4,30}/g;
        if (reason.match(validRegEx) === null || reason.length > 30) {
            return false;
        }

        return true;
    }


    buildSaldo=()=>{
        return { 
            "Deposit":parseFloat(this.state.Deposit),
            "Identifier":parseInt(this.state.accountSelection == 0 ? this.state.CustomerNumber : this.state.LoyalAccount, 10),
            "StarDate":this.state.StarDate.format('DD/MM/YYYY'),
            "EndDate":this.state.EndDate.format('DD/MM/YYYY'),
            "ReasonDeposit": this.state.ReasonDeposit
        };
    }

    validate = () => {
        var isValid = true;
        var validation;

        if (this.state.accountSelection === -1) {
            toast.error("Selecciona con qué valor se asignará el usuario.");
            isValid = false;
            return false;
        }

        validation = this.validationsIdentifier(this.state.accountSelection === 0 ? this.state.CustomerNumber : this.state.LoyalAccount);
        if (this.state.accountSelection === 0 && validation.isValid === false) {
            toast.error(`Ingresa un número de cliente válido: ${validation.error}`);
            isValid = false;
            return false;
        }


        validation = this.validationsLoyaltyAccount(this.state.LoyalAccount);
        if (this.state.accountSelection === 1 && validation.isValid === false) {
            toast.error("Ingresa una cuenta lealtad valida: formato [ 12419xxxxxxxxxx] donde x es un número");
            isValid = false;
            return false;
        }

        if (this.state.StarDate >= this.state.EndDate) {
            toast.error("Ingresa un rango de fechas válido para el regalo");
            isValid = false;
            return false;
        }

        if (this.validationsAmount(this.state.Deposit) === false) {
            toast.error(`Ingresa una cantidad válida para el regalo`);
            isValid = false;
            return false;
        }

        if (this.validationsReason(this.state.ReasonDeposit) === false) {
            toast.error(`Ingresa un motivo válido para el regalo minimo 4 letras y maximo 30(contando espacios)`);
            isValid = false;
            return false;
        }

        return isValid;
    }

    onKeyPressNumber(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/\+|-|e|E|\./.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
            event.preventDefault();
    }

    render() {
        let accountOptions = [{ name: "Por número de cliente" }, { name: "Por cuenta lealtad" }];
        return (
            <div>

                <div className="columns is-multiline">
                    <div className="column is-full">
                        <div className="FieldHorizontal field is-horizontal">
                            <div className="field-label">
                                <label className="label">Identificador</label>
                            </div>
                            <div className="field-body tooltip is-tooltip-bottom" data-tooltip="Identificar al cliente por su Número de Cliente o Cuenta Lealtad">
                                <Dropdown
                                    single
                                    items={accountOptions}
                                    onSelect={(index) => {
                                        let state = { ...this.state };
                                        state.accountSelection = index - 1;

                                        if (index - 1 === 0) {
                                            state.CustomerNumber = 0;
                                        } else {
                                            state.LoyalAccount = 0;
                                        }
                                        this.setState(state);
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                    <div className={`column is-full ${this.state.accountSelection === -1 ? " invisible" : ""}`}>
                        {
                            this.state.accountSelection === 0 && <div className="tooltip" data-tooltip="Número de Cliente">
                                <TextField
                                    min="0"
                                    required
                                    type="number"
                                    onKeyPress={this.onKeyPressNumber}
                                    label="# de Cliente"
                                    maxLength={"7"}
                                    name="CustomerNumber"
                                    value={this.state.CustomerNumber || ""}
                                    onChange={(e) => this.handleChange(e)} /></div>
                        }
                        {
                            this.state.accountSelection === 1 && <div className="tooltip" data-tooltip="Ingresar Cuenta Lealtad del cliente: 14 números">
                                <TextField
                                    label="Cuenta lealtad"
                                    min="0"
                                    required
                                    onKeyPress={this.onKeyPressNumber}
                                    type="number"
                                    maxLength={"14"}
                                    name="LoyalAccount"
                                    value={this.state.LoyalAccount || ""} onChange={(e) => this.handleChange(e)} />
                            </div>
                        }
                    </div>


                    <div className="column is-full">
                        <div className="FieldHorizontal field is-horizontal">
                            <div className="field-label">
                                <label className="label">Fecha inicial</label>
                            </div>
                            <div className="field-body">
                                <span className="tooltip" data-tooltip="Fecha inicial donde se empezará a agregar el saldo">
                                    <DatePicker
                                        name={"StarDate"}
                                        selected={moment(this.state.StarDate)}
                                        onChange={e => this.handleDateChange(e, 'StarDate')}
                                        popperPlacement="right"
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: '5px, 10px'
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: 'viewport'
                                            }
                                        }}
                                        withPortal
                                        dateFormat="DD/MM/YYYY"
                                        className="input"
                                        minDate={moment(new Date()).add(1, 'days')}
                                        maxDate={
                                            moment(this.state.EndDate).isValid()?
                                            moment(this.state.EndDate).subtract(1, 'days'):
                                            ""
                                        }
                                    />
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className="column is-full">
                        <div className="FieldHorizontal field is-horizontal">
                            <div className="field-label">
                                <label className="label">Fecha final</label>
                            </div>
                            <div className="field-body">
                                <span className="tooltip" data-tooltip="Fecha final donde se finalizará el saldo del cliente">
                                    <DatePicker
                                        name={"EndDate"}
                                        selected={moment(this.state.EndDate)}
                                        onChange={e => this.handleDateChange(e, 'EndDate')}
                                        popperPlacement="right"
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: '5px, 10px'
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: 'viewport'
                                            }
                                        }}
                                        withPortal
                                        dateFormat="DD/MM/YYYY"
                                        className="input"
                                        minDate={
                                            moment(this.state.StarDate).isValid()?
                                            moment(this.state.StarDate).add(1, 'days'):
                                            ""
                                        }
                                    />
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className="column is-full">
                        <div className="tooltip" data-tooltip="Cantidad de saldo regalo a programar">
                            <TextField
                                min="0"
                                required
                                type="number"
                                label="Cantidad"
                                name="Deposit"
                                onKeyPress={this.onKeyPressNumber}
                                value={this.state.Deposit || ""}
                                maxLength={"4"}
                                onChange={(e) => this.handleChange(e)} />
                        </div>
                    </div>

                    <div className="column is-full">
                        <div className="tooltip" data-tooltip="Descripción del motivo por el cual se regalará saldo">
                            <TextField maxLength="30" required label="Motivo" name="ReasonDeposit" value={this.state.ReasonDeposit} onChange={(e) => this.handleChange(e)} />
                        </div>

                    </div>
                </div>

                <div className="has-text-right buttons-modal">
                    <button
                        className="button is-success"
                        onClick={() => {
                            if (this.validate()) {
                                let { save, ...saldo } = { ...this.state };
                                this.props.save([this.buildSaldo()], this.state.accountSelection === 1);
                            }
                        }}>
                        Guardar
                        </button>

                </div>
            </div>
        )
    }
}