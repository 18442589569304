import React from 'react';
import RecipeTypesAPI from '../../../services/RecipeType';
import PresentationsAPI from '../../../services/Presentations';
import MilksAPI from '../../../services/Milk';
import GrainsAPI from '../../../services/Grain';
import FlavorsAPI from '../../../services/Flavor';
import ClasificacionesAPI from '../../../services/Classification';
import { toast } from 'react-toastify';

import Select from 'react-select';
import lodash from 'lodash';
import ErrorMessages from '../../../util/validation-error-messages';
import EmpresasAPI from '../../../services/CaffenioEmpresas/Empresas';

export default class RecipeImageFilter extends React.Component{
    oringClassificationList = []; //Donde se va a guardar la lista original de las clasificaciones
    origRecipeTypesList = []; //Donde se va a guardar la lista original de tipos de receta
    origFlavorsList = []; //Donde se va a guardar la lista original de sabores
    origGrainsList = []; //Donde se va a guardar la lista original de granos
    origMilksList = []; //Donde se va a guardar la lista original de leches
    origPresentationList = []; //Donde se va a guardar la lista original de presentaciones

    constructor(props){
        super(props)
        this.state = {
            // Variables sonde se almacenaran los criterios de busqueda definitivos
            recipeTypeList: [],
            selectedRecipeTypesList: [],
            presentationsList: [],
            selectedPresentationsList: [],
            milksList: [],
            selectedMilksList: [],
            grainsList: [],
            selectedGrainsList: [],
            flavorsList: [],
            selectedFlavorsList: [],
            classificationList: [],
            selectedClassificationList: [],
            segmentationList: [],
            selectedSegmentationList: [],
            noMulti: this.props.noMulti || false,
            noRecipeType: this.props.noRecipeType || false,
            noFlavorType: this.props.noFlavorType || false,
            noGrainType: this.props.noGrainType || false,
            noMilkType: this.props.noMilkType || false,
            noPresentation: this.props.noPresentation || false,
            disabledByFamily: this.props.disabledByFamily || false,
        }
    }

    componentDidMount = () => {
        let state = {...this.state};
        RecipeTypesAPI.getAll().then(response => {
            let temp = [];
            
            response.data.map(obj => {
                if(obj.RecipeName) {
                    temp.push({value: obj.RecipeTypeId, label: obj.RecipeName})
                }else{
                    temp.push({value: obj.RecipeTypeId, label: obj.Description})
                }
            })
            temp.sort(this.compare); // Ordenar los labels para elegir
            state.recipeTypeList = temp;
            this.origRecipeTypesList = lodash.cloneDeep(temp)
            
            // Obtener todas las presentaciones
            return PresentationsAPI.getAll()
        }).then(response => {
            let tempPresentationList = [];
            response.data.map(obj => {
                if(obj.Active){
                    tempPresentationList.push({value: obj.PresentationId, label: obj.Name})
                }                
            })
            tempPresentationList.sort(this.compare); // Ordenar los labels
            state.presentationsList = tempPresentationList
            this.origPresentationList = lodash.cloneDeep(tempPresentationList)

            // Obtener los tipos de leche
            return MilksAPI.getAll()
        }).then(response => {
            let tempMilkList = [];
            response.data.map(obj => {
                tempMilkList.push({value: obj.MilkTypeId, label: obj.Description})
            })
            tempMilkList.sort(this.compare); // Ordenar los labels
            state.milksList = tempMilkList
            this.origMilksList = tempMilkList
            
            // Obtener los tipos de grano
            return GrainsAPI.getAll()
        }).then(response => {
            let tempGrainList = [];
            response.data.map(obj => {
                tempGrainList.push({value: obj.GrainTypeId, label: obj.Description})
            })
            tempGrainList.sort(this.compare); // Ordenar los labels
            state.grainsList = tempGrainList
            this.origGrainsList = lodash.cloneDeep(tempGrainList)
            
            // Obtener los sabores
            return FlavorsAPI.getAll()
        }).then(response => {
            let tempFlavorsList = [];
            response.data.map(obj => {
                tempFlavorsList.push({value: obj.FlavorTypeId, label:obj.Name})
            })
            tempFlavorsList.sort(this.compare); // Ordenar los labels        
            state.flavorsList = tempFlavorsList
            this.origFlavorsList = tempFlavorsList
            
            // Obtener las clasificaciones
            return ClasificacionesAPI.getAll()
        }).then(response => {
            let tempClassificationList = [];
            response.data.map(obj => {
                tempClassificationList.push({value: obj.ClassificationId, label: obj.Name})
            })
            tempClassificationList.sort(this.compare); // Ordenar los labels
            state.classificationList = tempClassificationList
            this.oringClassificationList = tempClassificationList
            
            return EmpresasAPI.getAllFilterSegments()
        }).then(response => {
            let tempSegmentsList = []
            response.data.map(obj => {
                tempSegmentsList.push({value: obj.SegmentId, label: obj.Name})
            })
            state.segmentationList = tempSegmentsList
            
            // Guardar el estado
            this.setState(state)
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre las clasificaciones.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleClassificationChange = (arr) => {
        let state = {...this.state}
        let tempClassificationList = [];
        arr.map(obj => {
            tempClassificationList.push(obj.value);
        })

        state.selectedClassificationList = tempClassificationList;
        state.selectedRecipes = [];        

        this.setState(state, () => {            
            this.filterOptionsByClasification();
        });
    }
    
    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de granos.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleSelectChange = (arr, selectedListLabel) => {
        let state = {...this.state}
        state[selectedListLabel] = arr.map(obj => obj.value);
        this.setState(state, () => {
            this.sendFilters();
        });
    }

        /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de granos.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
     handleSelectChangeSegmentation = (arr, selectedListLabel) => {
        if(arr.length > 0){
            let state = {...this.state}
            state[selectedListLabel] = arr.map(obj => obj.value);
            this.setState(state, () => {
                this.sendFilters();
            });
        }else{
            this.tempClassificationList = [];
            this.tempRecipeTypesList = [];
            this.tempFlavorsList = [];
            this.tempGrainList = [];
            this.tempMilkList = [];
            this.tempPresentationList = [];
            this.setState({
                classificationList: lodash.cloneDeep(this.oringClassificationList),
                recipeTypeList: lodash.cloneDeep(this.origRecipeTypesList),
                flavorsList: lodash.cloneDeep(this.origFlavorsList),
                grainsList: lodash.cloneDeep(this.origGrainsList),//Donde se va a guardar la lista original de granos
                milksList: lodash.cloneDeep(this.origMilksList),//Donde se va a guardar la lista original de leches
                presentationsList: lodash.cloneDeep(this.origPresentationList),//Donde se va a guardar la lista original de presentaciones
                selectedRecipeTypesList: [],
                selectedFlavorsList: [],
                selectedGrainsList: [],
                selectedMilksList: [],
                selectedPresentationsList: [],
                selectedClassificationList: []

            }, () => this.sendFilters())
        }
    }

    sendFilters = () => {
        let selectedFilters = {
            selectedRecipeTypesList: this.state.selectedRecipeTypesList,
            selectedPresentationsList: this.state.selectedPresentationsList,
            selectedMilksList: this.state.selectedMilksList,
            selectedGrainsList: this.state.selectedGrainsList,
            selectedFlavorsList: this.state.selectedFlavorsList,
            selectedClassificationList: this.state.selectedClassificationList,
            selectedSegmentationList: this.state.selectedSegmentationList,
        }
        /* console.log(this.state.selectedSegmentationList) */
        this.props.onChange(selectedFilters)
    }

    /**
     * Filtra las opciones de los filtros(hehe) según la Familia seleccionada.
     * Por ejemplo, seleccionó la Familia "Chocolate" entonces en el filtro de tipo de receta solo me tiene que aparecer "Caliente" y "Frio"
     */
    filterOptionsByClasification = () => {
        let clasificaciones = this.state.selectedClassificationList
        if(clasificaciones.length > 0 ){
            let stateFiltros = {}
            // Filtrar los tipos de receta
            RecipeTypesAPI.getByClasification(clasificaciones).then(response => {                
                let temp = response.data.map(obj => {
                    return {value: obj.RecipeTypeId, label: obj.RecipeName ? obj.RecipeName : obj.Description}
                })
                temp.sort(this.compare);
                stateFiltros.recipeTypeList = temp;
                this.tempRecipeTypesList = [];
                
                // Filtrar los sabores
                return FlavorsAPI.getByClasification(clasificaciones)
            }).then(response => { 
                let tempFlavorsList = response.data.map(obj => {
                    return {value: obj.FlavorTypeId, label:obj.Name}
                })
                tempFlavorsList.sort(this.compare); // Ordenar los labels        
                stateFiltros.flavorsList = tempFlavorsList;
                this.tempFlavorsList = [];
                
                // Filtrar los granos de cafe
                return GrainsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempGrainList = response.data.map(obj => {
                    return {value: obj.GrainTypeId, label: obj.Description}
                })
                tempGrainList.sort(this.compare); // Ordenar los labels
                stateFiltros.grainsList = tempGrainList;
                this.tempGrainList = [];
                
                // Filtrar los tipos de leche
                return MilksAPI.getByClasification(clasificaciones)
            })
            .then(response => {
                let tempMilkList = response.data.map(obj => {
                    return {value: obj.MilkTypeId, label: obj.Description}
                })
                tempMilkList.sort(this.compare); // Ordenar los labels
                stateFiltros.milksList = tempMilkList;
                this.tempMilkList = [];
                
                // Filtrar las presentaciones
                return PresentationsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempPresentationList = response.data.map(obj => {
                    return {value: obj.PresentationId, label: obj.Name}
                })
                tempPresentationList.sort(this.compare); // Ordenar los labels
                stateFiltros.presentationsList = tempPresentationList;
                this.tempPresentationList = [];

                this.setState(stateFiltros, () => this.sendFilters())
            }).catch(error => {
                this.setState(stateFiltros, () => this.sendFilters())
            });
        }else{    
            this.tempRecipeTypesList = [];
            this.tempFlavorsList = [];
            this.tempGrainList = [];
            this.tempMilkList = [];
            this.tempPresentationList = []; 
            this.setState({
                recipeTypeList: lodash.cloneDeep(this.origRecipeTypesList),
                flavorsList: lodash.cloneDeep(this.origFlavorsList),
                grainsList: lodash.cloneDeep(this.origGrainsList),//Donde se va a guardar la lista original de granos
                milksList: lodash.cloneDeep(this.origMilksList),//Donde se va a guardar la lista original de leches
                presentationsList: lodash.cloneDeep(this.origPresentationList),//Donde se va a guardar la lista original de presentaciones
                selectedRecipeTypesList:[],
                selectedFlavorsList:[],
                selectedGrainsList:[],
                selectedMilksList:[],
                selectedPresentationsList:[],
                
            }, () => this.sendFilters())
        }
    }

    /**
     * Funcion para ordenar lexicograficamente
     * @param objA. primer operando
     * @param objB. segundo operando
     */
    compare = (objA, objB) => {
        // Use toUpperCase() to ignore character casing
        const labelA = objA.label.toUpperCase();
        const labelB = objB.label.toUpperCase();

        let comparison = 0;
        if (labelA > labelB) {
            comparison = 1;
        } else if (labelA < labelB) {
            comparison = -1;
        }
        return comparison;
    }

    render(){
        return(
            <React.Fragment>

                <div className="column is-4">
                    <label className="label">Segmentación</label>
                    <Select
                        isMulti={!this.state.noMulti}
                        id={"ddlSegmentation"}
                        placeholder={"Seleccionar..."}
                        name={"bus_7"}
                        value={this.tempSegmentationList}
                        onChange={arr => {
                            if (this.state.noMulti) {
                                arr = arr ? [arr] : []
                            }
                            this.tempSegmentationList = arr;
                            this.handleSelectChangeSegmentation(arr, "selectedSegmentationList")
                        }}
                        noOptionsMessage={() => "No hay opciones"}
                        options={this.state.segmentationList}
                        isClearable={true}
                        getOptionLabel={(segment) => segment.value === 1 ? "Caffenio"
                            : segment.value === 9 ? "Andatti"
                                : segment.value === 10 ? "Momento Espresso"
                                    : segment.value}
                    />
                </div>

                <div className="column is-4">
                    <label className="label">Familia</label>
                    <Select
                        id={"ddlClasificacion"}
                        isMulti={!this.state.noMulti}
                        placeholder={"Seleccionar..."}
                        name={"bus_1"}
                        value={this.tempClassificationList}
                        onChange={arr => {                            
                            if(this.state.noMulti){
                                arr = arr ? [arr] : []
                            }
                            this.tempClassificationList = arr;
                            this.handleClassificationChange(arr)
                        }}
                        noOptionsMessage={() => "No hay opciones"}
                        options={this.state.classificationList}
                        isClearable={true}
                        isDisabled={!this.tempSegmentationList || this.tempSegmentationList.length === 0}
                    />
                </div>

                <div className="column is-4">
                    <label className="label">Tipo Receta</label>
                    <Select
                        isMulti={!this.state.noMulti}
                        id={"ddlTipoReceta"}
                        placeholder={"Seleccionar..."}
                        name={"bus_4"}
                        value={this.tempRecipeTypesList}
                        onChange={arr => { 
                            if(this.state.noMulti){
                                arr = arr ? [arr] : []
                            }
                            this.tempRecipeTypesList = arr;
                            this.handleSelectChange(arr, "selectedRecipeTypesList") }}
                        noOptionsMessage={() => "No hay opciones"}
                        options={this.state.recipeTypeList}
                        isClearable={true}
                        isDisabled = { this.state.disabledByFamily &&  (!this.tempClassificationList || this.tempClassificationList.length === 0)}
                    />
                </div>

                {!this.state.noRecipeType && <div className="column is-4">
                    <label className="label">Tipo Sabor</label>
                    <Select
                        isMulti={!this.state.noMulti}
                        id={"ddlTipoSabor"}
                        placeholder={"Seleccionar..."}
                        name={"bus_3"}
                        value={this.tempFlavorsList}
                        onChange={arr => { 
                            if(this.state.noMulti){
                                arr = arr ? [arr] : []
                            }
                            this.tempFlavorsList = arr;
                            this.handleSelectChange(arr, "selectedFlavorsList") }}
                        noOptionsMessage={() => "No hay opciones"}
                        options={this.state.flavorsList}
                        isClearable={true}
                        isDisabled = { this.state.disabledByFamily &&  (!this.tempClassificationList || this.tempClassificationList.length === 0)}
                    />
                </div>}
                
                {!this.state.noGrainType && <div className="column is-4">
                    <label className="label">Tipo Grano</label>
                    <Select
                        isMulti={!this.state.noMulti}
                        id={"ddlTipoGrano"}
                        placeholder={"Seleccionar..."}
                        name={"bus_6"}
                        value={this.tempGrainList}
                        onChange={arr => { 
                            if(this.state.noMulti){
                                arr = arr ? [arr] : []
                            }
                            this.tempGrainList = arr;
                            this.handleSelectChange(arr, "selectedGrainsList") }}
                        noOptionsMessage={() => "No hay opciones"}
                        options={this.state.grainsList}
                        isClearable={true}
                        isDisabled = { this.state.disabledByFamily &&  (!this.tempClassificationList || this.tempClassificationList.length === 0)}
                    />
                </div>}
                
                {!this.state.noMilkType && <div className="column is-4">
                    <label className="label">Tipo Leche</label>
                    <Select
                        isMulti={!this.state.noMulti}
                        id={"ddlTipoLeche"}
                        placeholder={"Seleccionar..."}
                        name={"bus_5"}
                        value={this.tempMilkList}
                        onChange={arr => { 
                            if(this.state.noMulti){
                                arr = arr ? [arr] : []
                            }
                            this.tempMilkList = arr;
                            this.handleSelectChange(arr, "selectedMilksList") }}
                        noOptionsMessage={() => "No hay opciones"}
                        options={this.state.milksList}
                        isClearable={true}
                        isDisabled = { this.state.disabledByFamily &&  (!this.tempClassificationList || this.tempClassificationList.length === 0)}
                    />
                </div>}

                {!this.state.noPresentation && <div className="column is-4">
                    <label className="label">Presentación</label>
                    <Select
                        isMulti={!this.state.noMulti}
                        id={"ddlTamano"}
                        placeholder={"Seleccionar..."}
                        name={"bus_2"}
                        value={this.tempPresentationList}
                        onChange={arr => {
                            if(this.state.noMulti){
                                arr = arr ? [arr] : []
                            } 
                            this.tempPresentationList = arr;
                            this.handleSelectChange(arr, "selectedPresentationsList") }}
                        noOptionsMessage={() => "No hay opciones"}
                        options={this.state.presentationsList}
                        isClearable={true}
                        isDisabled = { this.state.disabledByFamily &&  (!this.tempClassificationList || this.tempClassificationList.length === 0)}
                    />
                </div>}

            </React.Fragment>
        )
    }
}