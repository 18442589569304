import React from 'react';
import SaldoRegaloAPI from '../../services/SaldoRegalo';
import Loading from '../util/loading/Loading';
import SearchForm from '../util/searchform/SearchForm';
import Pagination from '../util/pagination/Pagination';
import TableTemplate from "../util/tabletemplate/TableTemplate";
import DatePicker from 'react-datepicker';
import NoItems from "../util/noitems/NoItems";
import Modal from '../util/modal/Modal';
import AcceptModal from '../util/acceptModal/AcceptModal';
import SaldoRegaloFormFile from './SaldoRegaloFormFile';
import SaldoRegaloForm from './SaldoRegaloForm';
import SaldoRegaloErrorFile from './SaldoRegaloErroFile';
import SaldoRegaloGuideFile from './SaldoRegaloGuideFile';
import SaldoRegaloCheckFile from './SaldoRegaloCheckFile'
import { toast } from 'react-toastify';

import './SaldoRegalo.css';
import es from 'moment/locale/es-us';

import BtnModoGuiado from '../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../util/validation-error-messages';

export default class SaldosRegalo extends React.Component {
    saldos = [];
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            saldos: [],
            saldosBackUp: [],
            pageSaldos: [],
            filterText: "",
            saldo: null,
            isLoading: false,
            byLoyaltyAccount: false,
            widthModal: "initial",
            showModal: false,
            typeModal: 0,
            fileExcel: "",
            fileErrors: [],
            filters: {
                CustomerNumber: "",
                LoyaltyAccount: "",
                StarDate: null,
                EndDate: null,
                ReasonDeposit: "",
                IsActive: -1
            }
        }
    }

    renderSwitch(typeModal) {
        switch (typeModal) {
            case 1:
                return <SaldoRegaloForm save={this.save} saldo={this.state.saldo} />;
            case 2:
                return <SaldoRegaloFormFile save={this.save} />;
            case 3:
                return <SaldoRegaloErrorFile data={this.state.fileErrors} byLoyaltyAccount={this.state.byLoyaltyAccount} />;
            case 4:
                return <SaldoRegaloGuideFile />;
            case 5:
                return <SaldoRegaloCheckFile saldo={this.state.rows} byLoyaltyAccount={this.state.byLoyaltyAccount} save={this.save} />;
            default:
                return '';
        }

    }

    renderModalTitle(typeModal) {
        switch (typeModal) {
            case 1:
                return "Programar saldo individual a un cliente";
            case 2:
                return "Programar saldo a varios clientes";
            case 3:
                return "Errores encontrados en el archivo";
            case 4:
                return "Guía del formato del archivo de programación";
            default:
                return '';
        }

    }

    componentWillMount() {
        this.setState({ isLoading: true });
        SaldoRegaloAPI.getAll().then(response => {
            this.setState({ isLoading: false, saldos: response.data , saldosBackUp:response.data });
            console.log(this.state.saldos)
            this.saldos = response.data;
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    }

    handleSearchInput = (text) => {
        let saldos = this.saldos.filter(saldo => saldo.ReasonDeposit.toLowerCase().includes(text.toLowerCase()));
        let state = this.state;
        state.filterText = text;
        state.saldos = saldos;
        this.setState(state);
    }

    handleFilterChange = (e) => {
        let state = { ...this.state };
        state.filters[e.target.name] = e.target.value;
        this.setState(state);
    }

    handleStatusChange = (e) => {
        let state = { ...this.state };
        state.filters.IsActive = e.target.value;
        this.setState(state);
    }

    handleDateChange = (date, attr) => {
        let state = { ...this.state };
        state.filters[attr] = date;
        this.setState(state);
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    create = (singleModalForm = 1) => {
        this.setState({ showModal: true, typeModal: singleModalForm, widthModal: singleModalForm === 4 ? "80%" : "" });
    }

    validate_dateMayor = (fechaInicial,fechaFinal) => {
        let AnioI = fechaInicial.split("-",1);
        let MesI = fechaInicial.split("-",2);
        let DiaI = fechaInicial.split("-",3);
        let AnioF = fechaFinal.split("-",1);
        let MesF = fechaFinal.split("-",2);
        let DiaF = fechaFinal.split("-",3);

        //console.log(MesI)
        //console.log(MesF)

        if(parseInt(AnioF[0],10) < parseInt(AnioI[0],10)){
            //console.log("Fecha Inicial es mayor a Fecha Final (año)");
            return false;
        } else if(parseInt(AnioF[0],10) == parseInt(AnioI[0],10) && parseInt(MesI[1],10) > parseInt(MesF[1],10)){
            //console.log("LFecha Inicial es mayor a Fecha Final (mes)");	
            return false;		
        } else if (parseInt(AnioF[0],10) == parseInt(AnioI[0],10) && parseInt(MesF[1],10) == parseInt(MesI[1],10) && parseInt(DiaI[2],10) > parseInt(DiaF[2],10)){
            //console.log("Fecha Inicial es mayor a Fecha Final (día)");
            return false;
        }
        
        return true;

    }
        

    getSaldosFiltered = () => {
        //console.clear()
        //this.setState({ isLoading: true });
        let body = { ...this.state.filters };
        body.StarDate = body.StarDate !== null && body.StarDate !== "" ? body.StarDate.format('YYYY-MM-DD') : "";
        body.EndDate = body.EndDate !== null && body.EndDate !== "" ? body.EndDate.format('YYYY-MM-DD') : "";
        body.CustomerNumber ? body.CustomerNumber = parseInt(body.CustomerNumber, 10) : body.CustomerNumber = '' ;
        let state = { ...this.state };

        if(body.StarDate !== "" && body.EndDate !== ""){
            let validarFechas = this.validate_dateMayor(body.StarDate,body.EndDate)
            if(!validarFechas){
                toast.error("Fecha Inicio no puede ser mayor a Fecha Fin");
                return;
            }
        }

        //console.log(body)
        SaldoRegaloAPI.getFiltered(body).then(response => {
            state.saldos = response.data;
            this.saldos = response.data;
            //console.log(response.data)
            state.isLoading = false;
            state.filterText = '';
            this.setState(state);
        }).catch(err => {
            console.error(err);
            state.isLoading = false;
            state.filterText = '';
            this.setState(state);
            toast.error("Datos faltantes en filtros para búsqueda");

        })
    }

    cleanFilters = () =>{
        let state = {...this.state};
        state.filters.CustomerNumber = "";
        state.filters.LoyaltyAccount = "";
        state.filters.StarDate = null;
        state.filters.EndDate = null;
        //console.log(state.filters.IsActive);
        state.filters.IsActive = "-1";
        state.filters.ReasonDeposit = "";
        state.saldos = state.saldosBackUp;
        this.setState(state);
        
        //console.log(state.filters.IsActive);
        //console.log(state.saldos)
    }

    save = (saldo, loyaltyAccount, confirm = false) => {
        if (confirm === false) {
            toast.info("Registros encontrados en el documento");
            this.setState({ showModal: true, typeModal: 5, rows: saldo, widthModal: "70%", byLoyaltyAccount: loyaltyAccount });
            return;
        }
        if (this.state.typeModal === 1) {
            SaldoRegaloAPI.create_list(saldo, loyaltyAccount).then(response => {
                let state = { ...this.state };

                if (response.data.length > 0 && response.data[0].Error !== "") {
                    toast.error(`Se encontraron errores en el registro: ${response.data[0].Error}`);
                    this.setState(state);
                } else {
                    toast.success("Registros agregados")
                    this.setState(state, () => {
                        state.showModal = false;
                        state.isLoading = false;
                        state.typeModal = 0;
                        this.setState(state);
                        SaldoRegaloAPI.getAll().then(response => {
                            state.saldos = response.data;
                        }).catch(err => {
                            this.setState({isLoading: false})
                            if(err.response)
                                toast.error(err.response.data.Message)
                            else
                                toast.error(ErrorMessages.SinConexion)
                        });
                    });
                }

            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            })
        } else {
            SaldoRegaloAPI.create_list(saldo, loyaltyAccount).then(response => {
                let state = { ...this.state };

                this.setState(state, () => {
                    state.showModal = false;
                    state.typeModal = 0;
                    this.setState(state);
                })

                if (response.data.length > 0 && response.data[0].Error !== "") {
                    let ErrorModal = 3;
                    toast.error("Se encontraron errores en algunos registros del archivo.");

                    this.setState({ showModal: true, typeModal: ErrorModal, fileErrors: response.data, widthModal: "70%", byLoyaltyAccount: loyaltyAccount });
                } else {
                    toast.success("Registros agregados")
                }
            });
        }
        return;
    }

    remove = () => {
        SaldoRegaloAPI.remove(this.state.saldo.Identifier).then(response => {

            let state = { ...this.state };
            let saldoIndex = state.saldos.findIndex(s => s.Identifier === state.saldo.Identifier);

            state.saldos[saldoIndex].IsActive = false;
            state.pageSaldos[saldoIndex].IsActive = false;

            state.saldo = null;
            state.isLoading = false;
            this.setState(state, () => toast.success("Saldo regalo desactivado."));
        }).catch(err => {
            console.error(err)
            toast.error("Ocurrió un error al procesar la información, intenta más tarde.");
            this.setState({ isLoading: false, saldo: null });
        })
    }


    patch = () => {
        SaldoRegaloAPI.patch(this.state.saldo.Identifier).then(response => {
            let state = { ...this.state };
            let saldoIndex = state.saldos.findIndex(s => s.Identifier === state.saldo.Identifier);
            state.saldos[saldoIndex].IsActive = state.saldos[saldoIndex].IsActive === true ? false : true;
            //this.state.pageSaldos[saldoIndex].IsActive = state.pageSaldos[saldoIndex].IsActive === true ? true : false
            state.isLoading = false;
            this.setState(state, () => toast.success(`Se le ha cambiado el estatus al saldo de regalo satisfactoriamente.`));
        }).catch(err => {
            console.error(err)
            toast.error("Ocurrió un error al procesar la información, intenta más tarde.");
            this.setState({ isLoading: false, saldo: null });
        })
    }

    onKeyPressNumber(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/\+|-|e|E|\./.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
            event.preventDefault();
    }

    render() {
        return (
            <div>

                <div className="card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Agregar nuevo saldo de regalo</p>
                    </header>
                    <div className="card-content">
                        <div className="columns is-multiline">
                            <div className="column is-half-desktop is-full-tablet is-full-mobile is-one-third-widescreen">
                                <div className="field">
                                    <div className="control">
                                        <a className="button button-custom  is-fullwidth is-large" onClick={(e) => this.create(1)}><p className="is-size-6 ">Agregar saldo regalo individual</p></a>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half-desktop is-full-tablet is-full-mobile is-one-third-widescreen">
                                <div className="field">
                                    <div className="control">
                                        <a className="button button-custom  is-fullwidth is-large" onClick={(e) => this.create(2)}><p className="is-size-6 ">Agregar saldos de regalo múltiples</p></a>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half-desktop is-full-tablet is-full-mobile is-one-third-widescreen">
                                <div className="field">
                                    <div className="control">
                                        <a className="button button-custom is-fullwidth is-large" onClick={(e) => this.create(4)}>
                                            <p className="is-size-6" >Guía del formato del archivo masivo</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Buscar que ya se encuentran programados</p>
                    </header>
                    <div className="card-content">
                        <div className="columns is-multiline">
                            <div className="column is-6">
                                <div className="field">
                                    <div className="field-label has-text-centered center-block">
                                        <label className="label is-centered">Número de Cliente</label>
                                    </div>
                                    <div className="field-body tooltip" data-tooltip="Valor numérico con un máximo de 14 dígitos">
                                        <input
                                            className="input"
                                            data-tooltip="Número de cliente"
                                            type="number"
                                            min="0"
                                            maxLength="8"
                                            name="CustomerNumber"
                                            value={this.state.filters.CustomerNumber || ""}
                                            onChange={(e) => { this.handleFilterChange(e) }}
                                            onKeyPress={this.onKeyPressNumber}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="column is-6">
                                <div className="field">
                                    <div className="field-label has-text-centered center-block">
                                        <label className="label is-centered">Cuenta Lealtad</label>
                                    </div>
                                    <div className="field-body tooltip" data-tooltip="Valor numérico con un máximo de 14 dígitos">
                                        <input
                                            className="input"
                                            min="0"
                                            max="99999999999999"
                                            type="number"
                                            maxLength="14"
                                            name="LoyaltyAccount"
                                            value={this.state.filters.LoyaltyAccount || ""}
                                            onChange={(e) => { this.handleFilterChange(e) }}
                                            onKeyPress={this.onKeyPressNumber}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="column is-3">
                                <div className="field">
                                    <div className="field-label has-text-left center-block">
                                        <label className="label is-left">Fecha Inicio</label>
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control">
                                                <span className="tooltip" data-tooltip="Fecha de inicio de búsqueda">
                                                    <DatePicker
                                                        name={"StartDate"}
                                                        selected={this.state.filters.StarDate}
                                                        onChange={e => this.handleDateChange(e, 'StarDate')}
                                                        popperPlacement="bottom"
                                                        popperModifiers={{
                                                            offset: {
                                                                enabled: true,
                                                                offset: '5px, 20px',
                                                            },
                                                            preventOverflow: {
                                                                enabled: true,
                                                                escapeWithReference: false,
                                                                boundariesElement: 'viewport'
                                                            }
                                                        }}
                                                        locale="es"
                                                        todayButton="Hoy"
                                                        dateFormat="DD/MM/YYYY"
                                                        className="input"
                                                        autoComplete={"off"}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="column is-3">
                                <div className="field">
                                    <div className="field-label has-text-left center-block">
                                        <label className="label is-left">Fecha Fin</label>
                                    </div>

                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control">
                                                <span className="tooltip" data-tooltip="Fecha límite de búsqueda">
                                                    <DatePicker
                                                        name={"EndDate"}
                                                        selected={this.state.filters.EndDate}
                                                        onChange={e => this.handleDateChange(e, 'EndDate')}
                                                        popperPlacement="bottom"
                                                        popperModifiers={{
                                                            offset: {
                                                                enabled: true,
                                                                offset: '50px, 10px'
                                                            },
                                                            preventOverflow: {
                                                                enabled: true,
                                                                escapeWithReference: false,
                                                                boundariesElement: 'viewport'
                                                            }
                                                        }}
                                                        locale="es"
                                                        todayButton="Hoy"
                                                        dateFormat="DD/MM/YYYY"
                                                        className="input"
                                                        //moment(this.state.filters.StarDate)
                                                        autoComplete={"off"}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className="field">
                                    <div className="field-label has-text-centered center-block">
                                        <label className="label is-centered">Motivo</label>
                                    </div>
                                    <div className="field-body tooltip" data-tooltip="Búsqueda por descripción del motivo">
                                        <input
                                            className="input"
                                            name="ReasonDeposit"
                                            value={this.state.filters.ReasonDeposit || ""}
                                            onChange={(e) => this.handleFilterChange(e)}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className="field">
                                    <div className="field-label has-text-centered center-block">
                                        <label className="label is-centered">Activo</label>
                                    </div>
                                    <div className="field-body left-block tooltip" data-tooltip="Filtrará si el saldo se encuentra en estatus activos o no">
                                        <div className="field">
                                            <div className="control">
                                                <div className="select is-rounded is-fullwidth">
                                                    <select name="status" onChange={(e) => this.handleStatusChange(e)}>
                                                        <option value="-1">Seleccionar estatus</option>
                                                        <option value="1">Activo</option>
                                                        <option value="0">Inactivo</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-3">
                                <div className="field">
                                    <div className="field-label">
                                        <label className="label">&nbsp;</label>
                                    </div>
                                    <div className="control buttonCenter">
                                        <a className="button is-outlined is-success" onClick={(e) => this.cleanFilters()}>Limpiar filtros</a>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-3">
                                <div className="field">
                                    <div className="field-label">
                                        <label className="label">&nbsp;</label>
                                    </div>
                                    <div className="control buttonCenter">
                                        <a className="button is-outlined is-success" onClick={(e) => this.getSaldosFiltered()}>Aplicar filtros</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <br />
                <div className="card">
                    <header className="modal-card-head">
                        <p className="modal-card-title"></p>
                        <div className="column is-12">
                            <div className="field">
                                <div className="field-label is-pulled-left	">
                                    <label className="label">Filtro por motivo</label>
                                </div>
                                <div className="control" >
                                    {this.state.saldos.length > 0 || this.state.filterText !== ''
                                        ?
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'por motivo p. ej: Mes de café gratis'}
                                        />
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="card-content">
                        {this.state.saldos.length !== 0
                            ? <TableTemplate
                                columns={["Identifier", "CustomerNumber", "LoyaltyAccount", "Deposit", "ReasonDeposit", "StarDate", "EndDate", "IsActive"]}
                                columnStyle={[{column: "# Cliente", style:{minWidth: "100px"}}]}
                                columnsNames={["ID", "# Cliente", "Cuenta Lealtad", "Cantidad", "Motivo", "Inicio", "Fin", "Activo"]}
                                data={this.state.pageSaldos}
                                canDelete remove={(saldo) => {
                                    this.setState({ saldo: saldo, isLoading: true })
                                    this.patch();
                                }}
                                changeStatus={(saldo) => {
                                    this.setState({ saldo: saldo, isLoading: true }, () => this.patch())

                                }}
                                noActions

                            />
                            : <NoItems itemName="saldos regalo registrados" />}

                        <Pagination items={this.state.saldos} onChangePage={(saldos) => this.setState({ pageSaldos: saldos })} />


                    </div>
                </div>
                {this.state.showModal &&
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={this.renderModalTitle(this.state.typeModal)}
                        width={this.state.widthModal}
                        cancel={() => this.setState({ showModal: false })}
                    >
                        {this.renderSwitch(this.state.typeModal)}
                    </Modal>
                }
                {this.state.removeModal &&
                    <AcceptModal
                        isActive={this.state.removeModal}
                        done={() => this.remove()}
                        cancel={() => {
                            let state = { ...this.state };
                            state.removeModal = false;
                            this.setState(state);
                        }}
                        modalTitle={"Eliminar registro de saldo regalo."}
                    />
                }
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
                <BtnModoGuiado />                
            </div>
        )
    }
}