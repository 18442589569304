
import {apiUrl} from '../constants';
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class DrivePromotionCommentsAPI{

    /**
     * Obtienen todo el listado de promociones
     */
    static getAll(idPromo){
        return request.get(`/PromotionComment/${idPromo}`);
    }

    /**
     * Crea una promoción.
     * @param { Object } data - Informacion de la promoción 
     * @returns Promise
     */
    static create( data ) {
        return request.post( '/PromotionComment/', data );
    }

    /**
     * Obtiene promocion por id
     * @param { Object } idPromo - Id de la promoción
     * @param { Object } idComment - Id del comentario
     * @returns Promise
     */
    static get( idPromo, idComment) {
        return request.get( `/PromotionComment/${idPromo}/${idComment}` );
    }

    /**
     * Actualiza promocion id 
     * @param { Object } id - identificador de la promocion
     * @returns Promise
     */
     static update( newData) {
        return request.put( `/PromotionComment`, newData  );
    }
}