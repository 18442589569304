import React, { useEffect, useState } from 'react';

// Components
import NoItems from '../util/noitems/NoItems';
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading';
import Field from '../util/field/Field';
import { ModalPlaza } from './ModalPlaza';
import Select from 'react-select';

// Utils
import {toast} from 'react-toastify';

// Services
import PlazaAPI from '../../services/Plazas';
import SucursalAPI from '../../services/Sucursales';

/**
 * Componente para modulo de Plazas.
 * @returns Component
 */
export const CatalogoPlazas = () => {
    const [ state, setState ] = useState({
        pages: [],
        titleModal: '',
        isLoading: false,
        listPlazas: [],
        plazaSelected: {},
        modalVisible: false,
        listSucursales: [],
        filters: {
            nombre: '',
            estatus: null,
            sucursal: null
        }
    });

    // Carga inicial de los datos.
    useEffect(() => {
        setState({ ...state, isLoading: true });
        let plazas = [];
        let allPages = [];
        let sucursales = [];

        Promise.all([
            PlazaAPI.getPlazas()
                .then(res => {
                    allPages = res.data;
                    plazas = res.data;
                }),
            SucursalAPI.getSucursales()
                .then( res => sucursales = res.data.map(suc => {
                    return {
                        value: suc.idSucursal,
                        label: suc.Nombre
                    }
                }) ),
        ]).catch(err => {
            toast.error('Ocurrio un error al consulatar las plazas.');
            console.error(err);
        })
        .finally(() => setState({
            ...state,
            isLoading: false,
            pages: allPages,
            listPlazas: plazas,
            listSucursales: sucursales
        }));
    }, []);

    /**
     * Limpia los filtros y trae los valores iniciales para la tabla.
     */
    const handleCleanFilters = () => {
        setState({...state, isLoading: true});
        let plazas = [];
        PlazaAPI.getPlazas({})
            .then(res => plazas = res.data)
            .catch(() => toast.error("Ocurrio un problema al filtrar las plazas."))
            .finally(() => setState({
                ...state,
                filters: {
                    nombre: '',
                    sucursal: null,
                    estatus: null
                },
                isLoading: false,
                listPlazas: plazas,
                pages: plazas
            }));
    }

    /**
     * Filtrado de las plazas.
     */
    const handleFilterPlazas = () => {
        setState({...state, isLoading: true});
        let plazas = [];

        let filters = {};

        if ( state.filters.sucursal )
            filters.sucursal = state.filters.sucursal.value
        if ( state.filters.estatus )
            filters.estatus = state.filters.estatus
        if ( state.filters.nombre )
            filters.nombre = state.filters.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
        else if ( state.filters.nombre === "" )
            delete filters.nombre

        PlazaAPI.getPlazas({ ...filters })
            .then(res => plazas = res.data)
            .catch(() => toast.error("Ocurrio un problema al filtrar las Plazas."))
            .finally(() => setState({
                ...state,
                isLoading: false,
                listPlazas: plazas,
                pages: plazas
            }));
    }

    /**
     * Actualiza la lista con los datos nuevos.
     * @param {Object} data - Datos a actualizar
     */
     const handleUpdateList = data => {
        setState({ ...state, isLoading: true });

        const oldList = [...state.listPlazas];
        let startIndex = null;
        oldList.find((pla, ixd) => {
            if ( pla.idPlaza === data.idPlaza )
                startIndex = ixd;
        });

        oldList.splice(startIndex, 1, data);

        setState({
            ...state,
            modalVisible: false,
            plazaSelected: {},
            listPlazas: [...oldList],
            isLoading: false
        });

        toast.success('Plaza actualizada');
    }

    /**
     * Actualización de la lista.
     */
    const handleInsertInfo = () => {
        setState({
            ...state,
            isLoading: true,
            filters: {
                nombre: '',
                estatus: null,
                sucursal: null
            }
        });
        let plazas = [];
        PlazaAPI.getPlazas({})
            .then(res => plazas = res.data)
            .catch(() => toast.error("Ocurrio un problema al filtrar las Plazas."))
            .finally(() => setState({
                ...state,
                isLoading: false,
                listPlazas: plazas,
                pages: plazas,
                modalVisible: false
            }));
        
        toast.success('Plaza agregada');
    }

    return (
        <div>
            {
                state.isLoading &&
                <Loading
                    isFullscreen={true}
                    isLoading={state.isLoading}
                    width='100px'
                    height='100px'
                />
            }
            <h1 className='title is-2'>Catálogo de Plazas</h1>
            <br />
            <div className='row'>
                <div className='columns'>
                    <div className='column is-12 is-flex' style={{justifyContent: 'flex-end'}}>
                        <button
                            className='button is-success'
                            onClick={() => setState({...state, modalVisible: true, titleModal: 'Agregar Plaza'})}
                        >
                            Agregar Plaza
                        </button>
                    </div>
                </div>
            </div>
            <br />
            {/* Filtros */}
            <div className='card'>
                <div className='card-content'>
                    <div className="row">
                        <div className='columns is-multiline'>
                            <div className='column is-3'>
                                <label className='label'>Nombre</label>
                                <div className='control'>
                                    <input
                                        type='text'
                                        className='input'
                                        maxLength={50}
                                        value={state.filters.nombre}
                                        placeholder='Ingrese el nombre'
                                        onChange={ ({target}) => setState({
                                            ...state,
                                            filters: {
                                                ...state.filters,
                                                nombre: target.value
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                            <div className='column is-3'>
                                <label className='label'>Sucursal</label>
                                <div className='control'>
                                    <Select
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOption"}
                                        value={state.filters.sucursal}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={item => setState({
                                            ...state,
                                            filters: {
                                                ...state.filters,
                                                sucursal: item
                                            }
                                        })}
                                        options={state.listSucursales}
                                    />
                                </div>
                            </div>
                            <div className='column is-3'>
                            </div>
                            <div className='column is-3'>
                                <button
                                    className='button is-success'
                                    onClick={handleFilterPlazas}
                                >
                                    Filtrar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="columns">
                            <div className="column is-3"></div>
                            <div className="column is-3"></div>
                            <div className="column is-3"></div>
                            <div className="column is-3">
                                <button
                                    className="button"
                                    onClick={handleCleanFilters}
                                >
                                    Limpiar filtros
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {/* Tabla de resultados */}
            <div className='card'>
                <div className='card-content'>
                    {
                        state.listPlazas.length !== 0 ?
                        <table className='table is-fullwidth'>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Sucursal</th>
                                    <th>Estatus</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    state.pages.map(pla =>
                                        <tr key={pla.idPlaza}>
                                            <td>{pla.Nombre}</td>
                                            <td>{
                                                state.listSucursales.length !== 0 
                                                ? state.listSucursales.find(suc => suc.value === pla.Sucursal)
                                                    ? state.listSucursales.find(suc => suc.value === pla.Sucursal).label
                                                    : "Sin Sucursal"
                                                : "Sin Sucursal"
                                            }</td>
                                            <td>
                                                <Field isHorizontal={false}>
                                                    <input
                                                        type='checkbox'
                                                        name='isActive'
                                                        className='switch'
                                                        checked={pla.Estatus}
                                                        disabled
                                                    />
                                                    <label />
                                                </Field>
                                            </td>
                                            <td>
                                                <button
                                                    className='button is-warning'
                                                    onClick={() => setState({...state, modalVisible: true, plazaSelected: pla, titleModal: 'Editar Plaza'})}
                                                >
                                                    Editar
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        : <NoItems itemName='Plazas' />
                    }
                    <Pagination
                        items={state.listPlazas}
                        pageSize={10}
                        onChangePage={pla => setState({...state, pages: pla})}
                    />
                </div>
            </div>
            {
                state.modalVisible &&
                <ModalPlaza
                    titleModal={ state.titleModal }
                    onInsert={handleInsertInfo}
                    plaza={state.plazaSelected}
                    sucursales={state.listSucursales}
                    onUpdate={data => handleUpdateList( data )}
                    onClose={() => setState({...state, modalVisible: false, plazaSelected: {}})}
                />
            }
        </div>
    )
}