import React, { useState, useEffect } from 'react';
import NoItems from '../../../util/noitems/NoItems';
import ServerPagination from '../../../util/pagination/ServerPagination';
import Loading from '../../../util/loading/Loading';
import './style.css';
import moment from 'moment';

export default function TablaNotificaciones(props){
    const columnas = ["Nombre","Tipo","Categoría", "Fecha creación", "Estatus", "Acciones"];
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(props.currentPage !== currentPage || props.totalPages !== totalPages){
            setCurrentPage(props.currentPage);
            setTotalPages(props.totalPages);
        }

        if(props.loading !== loading) setLoading(props.loading);
    },[props.totalPages, props.currentPage])

    const actions = (notificacion) => {
        return (
            <td>
                <button disabled={!notificacion.Status} className="button is-small tableAction is-warning tooltip" data-tooltip="Editar" onClick={(e) => {
                    e.stopPropagation();
                    props.edit(notificacion.NotificationId)
                }}>
                    <span className="icon is-small">
                        <i className="fa fa-edit" />
                    </span>
                </button>
                <button disabled={!notificacion.Status} className="button is-small tableAction is-danger tooltip" data-tooltip="Eliminar" onClick={(e) => {
                    e.stopPropagation();
                    props.delete(notificacion)
                }}>
                    <span className="icon">
                        <i className="fa fa-trash" />
                    </span>
                </button>
            </td>
        )
        
    }

    return (
        <div className="card">
            <div className="card-content">
                {props.notificaciones.length > 0 ?
                <table className="table is-fullwidth">
                    <thead>
                        {columnas.map(c => <th key={c}>{c}</th>)}
                    </thead>
                    <tbody>
                    {props.notificaciones.map((row, index) => {
                        return (
                            <tr className="trClickable" key={`notif${index}`} onClick={(e) => {
                                e.stopPropagation();
                                props.history.push(`/app/notificaciones/${row.NotificationId}/detalle`)
                            }}>
                                <td>{row.Name}</td>
                                <td>{row.TypeName}</td>
                                <td>{row.CategoryName}</td>
                                <td>{moment(row.CreationDate).format('DD/MM/YYYY HH:mm')}</td>
                                <td className="tooltip" data-tooltip={row.Status ? "Desactivar notificación" : "Activar notificación"}>
                                    <input
                                        type="checkbox"
                                        name="status"
                                        className="switch"
                                        
                                        checked={row.Status}
                                        onChange={() => { }}
                                    />
                                    <label disabled htmlFor="status" onClick={(e) => {
                                        e.stopPropagation();
                                        props.changeStatus(row);
                                    }} />
                                </td>
                                {actions(row)}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                : props.loading ? <Loading isLoading={props.oading} width='100px' height='100px'/> : <NoItems itemName="notificaciones"/>}
                <ServerPagination 
                        totalPages={totalPages} 
                        currentPage={currentPage}
                        goToPage={props.goToPage}
                    />
            </div>
        </div>
    )
}