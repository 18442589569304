import React, { useEffect, useState } from 'react';
import useForm from '../../../../util/hooks/useForm';
import { toast } from 'react-toastify';
import PromocionDataForm from './components/PromocionDataForm';
import TipoPromocionDataForm from './components/TipoPromocionDataForm';
import TipoQRDataForm from './components/TipoQRDataForm';
import SegmentoDataForm from './components/SegmentoDataForm';
import MostrarAppDataForm from './components/MostrarAppDataForm';
import AgendarNotificacionDataForm from './components/AgendarNotificacionDataForm';
import CantidadCuponesDataForm from './components/CantidadCuponesDataForm';
import PromocionesAPI from '../../../../services/Promotions';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";

export default function NuevaPromocionForm({ history, location, promotions, typesPromotion, typesQR, isLoadingPromotions }) {
    const { inputs, setInput, errors, setError, replaceInputs, handleInputChange, handleSelectChange, handleSubmit } = useForm(() => {
        savePromotion();
    });
    const [typesQRTemp, setTypesQRTemp] = useState([]);
    const [working, setWorking] = useState(false);
    const [maxDateCupon, setMaxDateCupon] = useState([''])
    const [minDateCupon, setMinDateCupon] = useState([moment().toDate()])

    const savePromotion = async () => {
        if (!working) {
            setWorking(true);
            let data = { ...inputs };

            if (inputs.showApp) {

                var responseUploadImage = PromocionesAPI.uploadImage(inputs.appImage);
                responseUploadImage.then(res => {
                    if (res.status === 200) {
                        var imageUrl = res.data.ImagePath;
                        let body = getBodySave(data, imageUrl);
                        console.log(body);
                        saveUpdateCall(body)

                    } else {

                        toast.error('Error' + res.status);
                        setWorking(false);
                    }
                }).catch(err => {
                    toast.error(err.message);
                    setWorking(false);
                });
            } else {
                let body = getBodySave(data, null);
                console.log(body);
                saveUpdateCall(body);
            }
        }
    }

    function saveUpdateCall(body) {
        var response = null;
        if (body.PromoQRId) {
            // response = PromocionesAPI.updatePromotion(body);
        } else {
            response = PromocionesAPI.savePromotion(body);
        }
        response.then(res => {
            if (body.PromoQRId) toast.success("Datos actualizados correctamente.");
            else toast.success("Promoción creada.");
            history.push({
                pathname: '/app/promociones',
                state: location.state ? { currentPage: location.state.currentPage || 0, name: location.state.name || "" } : { currentPage: 1, name: "" }
            });
        }).catch(err => {
            toast.error(err.response.data.Message)
        }).finally(() => setWorking(false))
    }

    function getBodySave(data, imageUrl) {
        return {
            promotionId: data.promotion.value,
            name: data.namePromotion,
            dateStart: moment(data.startDateCupon).format('YYYY-MM-DD'),
            dateEnd: moment(data.endDateCupon).format('YYYY-MM-DD'),
            promoTypeId: data.typePromotion.value,
            qrTypeId: data.QRType.value,
            promoTypeSegmentationId: data.QRType && data.QRType.value === 3 ? data.typeSegmentation.value : null,
            idSegmentation: data.typeSegmentation && data.typeSegmentation.value === 1 ? data.segmentation.value : null,
            showApp: data.showApp ? data.showApp : false,
            promoDescriptionApp: data.descriptionApp,
            promoRestrictionApp: data.restrictionsApp,
            notificationApp: data.scheduleNotification ? data.scheduleNotification : false,
            notificationDateSend: data.scheduleNotification ? moment(data.notificationDate).format('YYYY-MM-DD') : null,
            notificationTitle: data.scheduleNotification ? data.titleNotification : null,
            notificationDescription: data.scheduleNotification ? data.contentNotification : null,
            imagePathApp: data.showApp ? imageUrl : null,
            clients: data.typeSegmentation && data.typeSegmentation.value === 2 ? data.validClients : null,
            amountCodes: parseInt(data.amountCodes)
        };
    }

    const inputsAreValid = () => {
        const promotionData = inputs.promotion && inputs.namePromotion && inputs.namePromotion.length >= 3 && inputs.startDateCupon && inputs.endDateCupon && inputs.descriptionApp &&
            inputs.descriptionApp.length > 0 &&
            inputs.restrictionsApp &&
            inputs.restrictionsApp.length > 0;
        const typePromotionData = inputs.typePromotion && inputs.typePromotion.value;
        const typeQRDataForm = inputs.QRType && (inputs.QRType.value === 1 ||
            (inputs.QRType.value === 2 && inputs.amountCodes && inputs.amountCodes.length > 0) ||
            (inputs.QRType.value === 3 &&
                (inputs.typeSegmentation && inputs.typeSegmentation.value === 1 && inputs.segmentation)
                || inputs.typeSegmentation && inputs.typeSegmentation.value === 2 && inputs.validClients));
        const showAppData = (inputs.showApp === undefined || inputs.showApp === false) ||
            (inputs.showApp &&
                inputs.showApp === true
                && inputs.appImage);

        const scheduleNotificationData = (inputs.scheduleNotification === undefined || inputs.scheduleNotification === false) ||
            (inputs.scheduleNotification &&
                inputs.scheduleNotification === true &&
                inputs.notificationDate &&
                inputs.titleNotification &&
                inputs.titleNotification.length > 0 &&
                inputs.contentNotification &&
                inputs.contentNotification.length > 0)
        return promotionData && typePromotionData && typeQRDataForm && showAppData && scheduleNotificationData;
    }

    useEffect(() => {
        (async () => {

            setInput('QRType', null)

            if (typesQR && inputs.typePromotion) {

                if (inputs.typePromotion.value === 1) {
                    const tempObject = typesQR.filter((Qr) => Qr.QRTypeId === 1);
                    setTypesQRTemp(tempObject);
                } else {
                    const tempObject = typesQR.filter((Qr) => Qr.QRTypeId !== 1);
                    setTypesQRTemp(tempObject);
                }
            }
        })();
    }, [inputs.typePromotion])

    useEffect(() => {
        (async () => {
            setInput('amountCodes', null)
            if (!inputs.QRType) {
                setInput('typeSegmentation', null)
                setInput('segmentation', null)
                setInput('excelFile', null)
                setInput('validClients', null)

            } else
                if (inputs.QRType && inputs.QRType.value !== 3) {
                    setInput('typeSegmentation', null)
                    setInput('segmentation', null)
                    setInput('excelFile', null)
                    setInput('validClients', null)
                    if (inputs.QRType && inputs.QRType.value === 1) {
                        setInput('amountCodes', 1)
                    }
                }


        })()
    }, [inputs.QRType])

    useEffect(() => {
        (async () => {
            if (inputs != {}) {
                if (inputs.segmentation !== null && inputs.segmentation !== undefined) {

                    if (inputs.segmentation.value === 180 || inputs.segmentation.value === 181 || inputs.segmentation.value === 182) {
                        setMinDateCupon([''])
                    } else {
                        setMinDateCupon(moment().toDate())
                        setInput('startDateCupon', null)
                        setInput('endDateCupon', null)
                    }
                } else {
                    setMinDateCupon(moment().toDate())
                    setInput('startDateCupon', null)
                    setInput('endDateCupon', null)
                }
            }
        })();
    }, [inputs.segmentation])

    return (
        <form onSubmit={handleSubmit} className="has-text-left" style={{ width: '100%' }}>
            <div className="columns is-multiline">
                <div className="column is-12">
                    <PromocionDataForm isLoadingPromotions={isLoadingPromotions} promotions={promotions} typesQR={typesQR} inputs={inputs} errors={errors} setInput={setInput} setError={setError} handleInputChange={handleInputChange} handleSelectChange={handleSelectChange} />
                </div>
                {inputs.promotion && <div className="column is-12">
                    <TipoPromocionDataForm typesPromotion={typesPromotion} inputs={inputs} errors={errors} setInput={setInput} handleSelectChange={handleSelectChange} />
                </div>}
                {inputs.typePromotion && <div className="column is-12">
                    <div class="is-divider" style={{ width: "100%" }} />
                    <TipoQRDataForm typesQR={typesQRTemp} inputs={inputs} errors={errors} setInput={setInput} handleSelectChange={handleSelectChange} />
                </div>}
                {(inputs.QRType && inputs.QRType.value === 2) && <div className="column is 12">
                    <div class="is-divider" style={{ width: "100%" }} />
                    <CantidadCuponesDataForm inputs={inputs} setInput={setInput} errors={errors} handleInputChange={handleInputChange} />
                </div>}
                {(inputs.QRType && inputs.QRType.value === 3) && <div className="column is-12">
                    <div class="is-divider" style={{ width: "100%" }} />
                    <SegmentoDataForm inputs={inputs} errors={errors} setInput={setInput} handleSelectChange={handleSelectChange} />
                </div>}

                {inputs.QRType !== null && inputs.typePromotion !== null && <div className="column is-12">
                    
                    <div className="column is-12">
                        <div class="is-divider" style={{ width: "100%" }}/>
                        <label className="label">Fecha inicio cupón</label>
                        <div className="control has-text-centered">
                            <DatePicker
                                className={`input ${errors.startDateCupon && "is-danger"}`}
                                placeholderText="Seleccionar fecha de inicio..."
                                selected={inputs.startDateCupon}
                                disabled={!(inputs.promotion && inputs.promotion.value)}
                                minDate={moment(minDateCupon).toDate()}
                                onChange={date => {
                                    setInput('startDateCupon', date)
                                }}
                                maxDate={(inputs.promotion && inputs.endDateCupon)?inputs.endDateCupon : moment(maxDateCupon).toDate()}
                                onChangeRaw={(e) => e.preventDefault()}
                                dateFormat="dd/MMMM/yyyy"
                                locale={es}
                                required
                            />
                        </div>
                        {errors.startDateCupon && <p className="help title has-text-left is-6 is-danger">Fecha de inicio de cupón requerida</p>}
                    </div>
                    <div className="column is-12">
                        <label className="label">Fecha final cupón</label>
                        <div className="control has-text-centered">
                            <DatePicker
                                className={`input ${errors.endDateCupon && "is-danger"}`}
                                placeholderText="Seleccionar fecha de final..."
                                selected={inputs.endDateCupon}
                                minDate={inputs.startDateCupon ? inputs.startDateCupon : moment().toDate()}
                                onChange={date => {
                                    setInput('endDateCupon', date)
                                }}
                                disabled={!(inputs.startDateCupon)}
                                maxDate={inputs.promotion && moment(maxDateCupon).toDate()}
                                onChangeRaw={(e) => e.preventDefault()}
                                dateFormat="dd/MMMM/yyyy"
                                locale={es}
                                required
                            />
                        </div>
                        {errors.endDateCupon && <p className="help title has-text-left is-6 is-danger">Fecha final de cupón requerida</p>}
                    </div>
                </div>}
                

                {inputs.promotion && <div className="column is-12">
                    <div class="is-divider" style={{ width: "100%" }} />
                    <MostrarAppDataForm inputs={inputs} errors={errors} setInput={setInput} handleInputChange={handleInputChange} />
                </div>}
                {inputs.promotion && <div className="column is-12">
                    <div class="is-divider" style={{ width: "100%" }} />
                    <AgendarNotificacionDataForm inputs={inputs} errors={errors} setInput={setInput} handleInputChange={handleInputChange} />
                </div>}
            </div>
            <div className="column is-12">
                <div className="field is-grouped is-grouped-right">
                    <p className="control">
                        <button
                            className="button"
                            type="button"
                            onClick={() => {
                                history.push({
                                    pathname: '/app/promociones',
                                    state: location.state ? { currentPage: location.state.currentPage || 0, name: location.state.name || "" } : { currentPage: 1, name: "" }
                                });
                            }}>
                            Regresar
                        </button>
                    </p>

                    <p className="control">
                        <button
                            type="submit"
                            className="button is-success"
                            disabled={!inputsAreValid() || working}
                        >
                            Guardar promoción
                        </button>
                    </p>
                </div>
            </div>
        </form>
    );
}