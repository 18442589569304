import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import lodash from 'lodash';

import Field from '../../util/field/Field';
import Dropdown from '../../util/dropdown/Dropdown';
import ErrorMessages from '../../../util/validation-error-messages';
//Services
import ComboDatosAPI from '../../../services/ComboDatos';
import PlazaAPI from '../../../services/PlazaAdmin';
import SucursalAPI from '../../../services/SucursalAdmin';
import Loading from '../../util/loading/Loading';


const SCHEMA = Yup.object({
    Pais: Yup.string().required("El campo 'País' es requerido.").nullable(),
    Estado: Yup.string().required("El campo 'Estado' es requerido.").nullable(),
    Municipio: Yup.string().required("El campo 'Ciudad' es requerido.").nullable(),
    Colonia: Yup.string().required("El campo 'Colonia' es requerido.").nullable(),
    idPostalCode: Yup.string().max(5, 'Ha excedido el límite de 5 caracteres.').required("El campo 'Código postal' es requerido."),
    MainStreet: Yup.string().trim().max(50, 'Ha excedido el límite de 50 caracteres.').required("El campo 'Calle principal' es requerido."),
    ExtNumber: Yup.string().trim().max(8, "Ha excedido el límite de 8 caracteres.")
    .matches(/^[a-zA-Z0-9 \-_]*$/, { message: "Formato incorrecto, se permiten solo - _ y números." })
    .required("El campo 'Número exterior' es requerido."),
    BetStreets: Yup.string().trim().max(80, 'Ha excedido el límite de 80 caracteres.').required("El campo 'Entre calles' es requerido."),
    Sucursal:  Yup.string().nullable(),
    Plaza:  Yup.string().nullable(),
    // Sucursal:  Yup.string().required("El campo 'Sucursal' es requerido").nullable(),
    // Plaza:  Yup.string().required("El campo 'Plaza' es requerido").nullable(),
});

const hrWithLegend = (text, bold, textAlign) => {
    return (
        <div style={{
            "marginTop": 20,
            "marginBottom": 20,
            "marginLeft": textAlign && textAlign !== 'center' ? 20 : 0,
            "border": 0,
            "borderTop": '1px solid #eee',
            "textAlign": textAlign || 'center',
            "height": 0,
            "lineHeight": 0
        }} className="hr">
            {
                text &&
                <span className="category" style={{
                    "backgroundColor": '#fff',
                    "paddingLeft": 20,
                    "paddingRight": 20,
                    "fontSize": 16,
                    "fontWeight": bold ? "bold" : "normal"
                }}>
                    {
                        text
                    }</span>
            }

        </div>
    )
}

/**
 * Formulario con la información general del folio
 */
export const StepLocationDrive = ({ ...props }) => {
    const [state, setState] = useState({
        isLoading: false,
        arrCountries: [],
        arrStates: [],
        arrCities: [],
        arrSuburbios: [],
        arrZipCodes: [],
        arrSucursales: [],
        arrPlazas: []
    })

    /**Carga información del form */
    useEffect(() => {
        setState({
            ...state,
            isLoading: true
        });
        let arrCountries = [];
        let arrStates = [];
        let arrCities = [];
        let arrSuburbios = [];
        let arrSucursales = props.arrSucursales;
        let arrPlazas = props.arrPlazas;        
        if (props.isEditing) {
            ComboDatosAPI.getDirCountry()
                .then(response => {
                    arrCountries = lodash.sortBy(response.data, [ts => ts.Name.toLowerCase()], ['asc'])
                    return ComboDatosAPI.getDirState(props.caffenio.Pais);
                })
                .then(response => {
                    arrStates = lodash.sortBy(response.data, [ts => ts.Name.toLowerCase()], ['asc'])
                    return ComboDatosAPI.getDirCity(props.caffenio.Estado);
                })
                .then(response => {
                    arrCities = lodash.sortBy(response.data, [ts => ts.Name.toLowerCase()], ['asc'])
                    return ComboDatosAPI.getDirSuburb(props.caffenio.Municipio);
                })
                .then(response => {
                    arrSuburbios = lodash.sortBy(response.data.map(d => { return { label: d.Name, value: d.Name } }), [ts => ts.label.toLowerCase()], ['asc']);
                    setState({
                        ...state,
                        isLoading: false,
                        arrCountries,
                        arrStates,
                        arrCities,
                        arrSuburbios,
                        arrSucursales,
                        arrPlazas,
                    })                    
                })
                .catch(err => {
                    setState({
                        ...state,
                        isLoading: false
                    })
                    if (err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                })

        } else {
            ComboDatosAPI.getDirCountry()
                .then(response => {
                    arrCountries = lodash.sortBy(response.data, [ts => ts.Name.toLowerCase()], ['asc']);
                    setState({
                        ...state,
                        isLoading: false,
                        arrCountries,
                        arrStates,
                        arrCities,
                        arrSuburbios,
                        arrSucursales,
                        arrPlazas
                    })
                })
                .catch(err => {
                    setState({
                        ...state,
                        isLoading: false
                    })
                    if (err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                })
        }
    }, []);

    /**Obtiene estados a partir de pais */
    const getStates = (country) => {
        setState({
            ...state,
            isLoading: true
        });

        let arrStates = [];
        Promise.all([
            ComboDatosAPI.getDirState(country).then(response => arrStates = response.data)
        ]).catch(err => {
            setState({
                ...state,
                isLoading: false
            })
            if (err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        }).finally(() => {
            setState({
                ...state,
                arrStates,
                arrCities: [],
                arrSuburbios: [],
                isLoading: false,
            });
        });
    }

    /**Obtiene ciudades a partir de estado */
    const getCities = (stateName) => {
        setState({
            ...state,
            isLoading: true
        });

        let arrCities = [];
        Promise.all([
            ComboDatosAPI.getDirCity(stateName).then(response => arrCities = response.data)
        ]).catch(err => {
            setState({
                ...state,
                isLoading: false
            })
            if (err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        }).finally(() => {
            setState({
                ...state,
                arrCities,
                arrSuburbios: [],
                isLoading: false,
            });
        });
    }

    /**Obtiene colonias a partir de ciudad */
    const getSuburbios = (cityName) => {
        setState({
            ...state,
            isLoading: true
        });

        let arrSuburbios = [];
        Promise.all([
            ComboDatosAPI.getDirSuburb(cityName).then(response => arrSuburbios = response.data.map(d => { return { label: d.Name, value: d.Name } }))
        ]).catch(err => {
            setState({
                ...state,
                isLoading: false
            })
            if (err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        }).finally(() => {
            setState({
                ...state,
                arrSuburbios,
                isLoading: false,
            });
        });
    }

    /**Filtra coincidencias  */
    const filterColonias = (inputValue) => {
        return state.arrSuburbios.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    /***
     * Funciónpara llamar función que filtra coincidencias en colonias
     */
    const promiseOptions = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(filterColonias(inputValue));
            }, 1000);
        });

    return (
        <Formik
            enableReinitialize={true}
            initialValues={props.isEditing ? props.caffenio : {}}
            validationSchema={SCHEMA}
            onSubmit={(values) => {       
                debugger         
                let caffenioUpdated = { ...props.caffenio };
                caffenioUpdated.Pais = values.Pais.trim();
                caffenioUpdated.MainStreet = values.MainStreet.trim();
                caffenioUpdated.ExtNumber = values.ExtNumber.trim();
                caffenioUpdated.BetStreets = values.BetStreets.trim();
                caffenioUpdated.Estado = values.Estado.trim();
                caffenioUpdated.Municipio = values.Municipio.trim();
                caffenioUpdated.Colonia = values.Colonia.value.trim();
                caffenioUpdated.idPostalCode = values.idPostalCode;
                caffenioUpdated.PromoListId = 1;
                caffenioUpdated.idPlazaAdmin = values.Plaza ? values.Plaza.value : null;
                
                //Setea a 0 AddressId para que guarde nueva dirección
                if( props.isEditing && ((caffenioUpdated.Pais !== props.caffenio.Pais )
                || (caffenioUpdated.Estado !== props.caffenio.Estado)
                || (caffenioUpdated.Municipio !== props.caffenio.Municipio)
                || (caffenioUpdated.Colonia !== props.caffenio.Colonia.value.trim())
                || (caffenioUpdated.MainStreet !== props.caffenio.MainStreet)
                || (caffenioUpdated.ExtNumber !== props.caffenio.ExtNumber)
                || (caffenioUpdated.BetStreets !== props.caffenio.BetStreets)
                || (caffenioUpdated.idPostalCode !== props.caffenio.idPostalCode))){
                    caffenioUpdated.AddressId = 0
                }

                props.save(caffenioUpdated)
            }}>
            {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                <form onSubmit={handleSubmit} id="frmLocationDriveStep">
                    {hrWithLegend('Ubicación', false, 'left')}
                    <div className="columns is-multiline">
                    <div className="column is-6">
                        <div>
                            <label className="label" style={{ marginBottom: 0 }}>Sucursal</label>
                            <div className={`control`}>
                            <Select
                                placeholder={"Seleccionar..."}
                                name={"Sucursal"}
                                id={"ddlSucursal"}
                                style={{ width: '100%' }}
                                value={values.Sucursal}
                                onChange={(value, e) => {                                    
                                    setFieldValue("Sucursal", value)
                                    setFieldValue("Plaza", null)
                                }}
                                noOptionsMessage={() => "No hay opciones"}
                                options={state.arrSucursales.filter(s=>s.Status)}
                                isSearchable={true}
                                isClearable={true}
                                hasError={errors.Sucursal || touched.Sucursal}                                
                            />

                            </div>
                        
                            <p className="help is-danger">{errors.Sucursal}</p>
                        </div>
                            
                        </div>
                        <div className="column is-6">
                        <div>
                            <label className="label" style={{ marginBottom: 0 }}>Plaza</label>
                            <div className={`control`}>
                            <Select
                                placeholder={"Seleccionar..."}
                                name={"Plaza"}
                                id={"ddlPlaza"}
                                style={{ width: '100%' }}
                                value={values.Plaza}
                                onChange={(value, e) => {            
                                    setFieldValue("Plaza", value)
                                }}
                                noOptionsMessage={() => "No hay opciones"}
                                options={state.arrPlazas.filter(p=> p.Status && values.Sucursal && p.idSucursal === values.Sucursal.value )}
                                isSearchable={true}
                                isClearable={true}
                                isDisabled={!values.Sucursal}
                                hasError={errors.Plaza || touched.Plaza}                                
                            />

                            </div>
                        
                            <p className="help is-danger">{errors.Plaza}</p>
                        </div>
                            
                        </div>
                        
                        <div className="column is-6">
                            <Field label="País"
                                hasError={errors.Pais || touched.Pais}
                                msgError={errors.Pais || touched.Pais ? errors.Pais : ""}>
                                {<Dropdown
                                    single
                                    items={state.arrCountries}
                                    value={values.Pais}
                                    id="Name"
                                    onSelect={(val) => {                                        
                                        if (val > 0) {
                                            let selected = state.arrCountries[val - 1];
                                            getStates(selected.Name);
                                            setFieldValue('Pais', selected.Name);
                                        } else {
                                            setFieldValue('Pais', null)
                                        }
                                        setFieldValue('Estado', null);
                                        setFieldValue('Municipio', null);
                                        setFieldValue('Colonia', null);                                        
                                    }}
                                    labelKey={"Name"}
                                    hasError={errors.Pais && touched.Pais}
                                />}
                            </Field>
                        </div>
                        <div className="column is-6">
                            <Field label="Estado"
                                hasError={errors.Estado || touched.Estado}
                                msgError={errors.Estado || touched.Estado ? errors.Estado : ""}>
                                {<Dropdown
                                    single
                                    items={state.arrStates}
                                    value={values.Estado}
                                    id="Name"
                                    disabled={!values.Pais}
                                    onSelect={(val) => {
                                        if (val > 0) {
                                            let selected = state.arrStates[val - 1];
                                            getCities(selected.Name);
                                            setFieldValue('Estado', selected.Name);
                                        } else {
                                            setFieldValue('Estado', null)
                                        }
                                        setFieldValue('Municipio', null);
                                        setFieldValue('Colonia', null);
                                    }}
                                    labelKey={"Name"}
                                    hasError={errors.Estado && touched.Estado}
                                />}
                            </Field>
                        </div>
                        <div className="column is-6">
                            <Field label="Ciudad"
                                hasError={errors.Municipio || touched.Municipio}
                                msgError={errors.Municipio || touched.Municipio ? errors.Municipio : ""}>
                                {<Dropdown
                                    single
                                    items={state.arrCities}
                                    value={values.Municipio}
                                    disabled={!values.Estado}
                                    id="Name"
                                    onSelect={(val) => {
                                        if (val > 0) {
                                            let selected = state.arrCities[val - 1];
                                            getSuburbios(selected.Name);
                                            setFieldValue('Municipio', selected.Name);
                                        } else {
                                            setFieldValue('Municipio', null)
                                        }
                                        setFieldValue('Colonia', null);
                                    }}
                                    labelKey={"Name"}
                                    hasError={errors.Municipio && touched.Municipio}
                                />}
                            </Field>
                        </div>
                        <div className="column is-6">
                            <div>
                                <label className="label" style={{ marginBottom: 0 }}>Colonia</label>
                                <div className={`control`}>
                                <Select
                                    placeholder={"Seleccionar..."}
                                    name={"Colonia"}
                                    id={"Colonia"}
                                    style={{ width: '100%' }}
                                    value={values.Colonia}
                                    onChange={(value, e) => {
                                        let val = value ? { label: value.value, value: value.value } : null;
                                        setFieldValue("Colonia", val)
                                    }}
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={state.arrSuburbios}
                                    onInputChange={(inputValue, { action }) => {
                                        if (action === "input-change") {
                                            setFieldValue("Colonia", inputValue ? { label: inputValue, value: inputValue } : null)
                                        } else {
                                            let val = values.Colonia ? { label: values.Colonia.value, value: values.Colonia.value } : null;
                                            setFieldValue("Colonia", val)
                                        }
                                    }}
                                    isSearchable={true}
                                    isDisabled={!values.Municipio}
                                    hasError={errors.Colonia || touched.Colonia}
                                    
                                />

                                </div>
                            
                                <p className="help is-danger">{errors.Colonia}</p>
                            </div>
                        </div>
                    </div>
                    {hrWithLegend('Dirección', false, 'left')}
                    <div className="columns is-multiline">
                        <div className="column is-4">
                            <Field label="CP"
                                hasError={errors.idPostalCode || touched.idPostalCode}
                                msgError={errors.idPostalCode || touched.idPostalCode ? errors.idPostalCode : ""}>
                                <input
                                    className="input some-top"
                                    type="number"
                                    min="0"
                                    max="99999"
                                    label="Código Postal"
                                    name="idPostalCode"
                                    value={values.idPostalCode}
                                    onChange={(e) => handleChange(e)} />
                            </Field>
                        </div>
                        <div className="column is-8">
                            <Field label="Calle Principal"
                                hasError={errors.MainStreet || touched.MainStreet}
                                msgError={errors.MainStreet || touched.MainStreet ? errors.MainStreet : ""}>
                                <input
                                    className="input some-top"
                                    maxLength="100"
                                    label="MainStreet"
                                    name="MainStreet"
                                    value={values.MainStreet}
                                    onChange={(e) => handleChange(e)} />
                            </Field>
                        </div>
                        <div className="column is-4">
                            <Field label="Número exterior"
                                hasError={errors.ExtNumber || touched.ExtNumber}
                                msgError={errors.ExtNumber || touched.ExtNumber ? errors.ExtNumber : ""}>
                                <input 
                                    className="input"                                     
                                    label="ExtNumber"                                    
                                    name="ExtNumber"
                                    value={values.ExtNumber}
                                    onChange={(e) => handleChange(e)} />
                            </Field>
                        </div>
                        <div className="column is-8">
                            <Field label="Entre Calles"
                                hasError={errors.BetStreets || touched.BetStreets}
                                msgError={errors.BetStreets || touched.BetStreets ? errors.BetStreets : ""}>
                                <input className="input" maxLength="150" label="BetStreets"
                                    name="BetStreets"
                                    value={values.BetStreets}
                                    onChange={(e) => handleChange(e)} />
                            </Field>
                        </div>
                    </div>

                    <br />

                    <div className="has-text-left buttons-modal" id="stepActions">
                        <div className="pull-left">
                            <button id="btnBackStepLocationDrive" type="button" className="button is-light" onClick={() => {
                                let caffenioUpdated = { ...props.caffenio };
                                caffenioUpdated.Pais = values.Pais ? values.Pais.trim() : '';
                                caffenioUpdated.MainStreet = values.MainStreet ? values.MainStreet.trim() : '';
                                caffenioUpdated.ExtNumber = values.ExtNumber ? values.ExtNumber.trim() : '';
                                caffenioUpdated.BetStreets = values.BetStreets ? values.BetStreets.trim() : '';
                                caffenioUpdated.Estado = values.Estado ? values.Estado.trim() : '';
                                caffenioUpdated.Municipio = values.Municipio ? values.Municipio.trim() : '';
                                caffenioUpdated.Colonia = values.Colonia.value ? values.Colonia.value.trim() : '';
                                caffenioUpdated.idPostalCode = values.idPostalCode;
                                caffenioUpdated.PromoListId = 1;
                                
                                //Setea a 0 AddressId para que guarde nueva dirección
                                if( props.isEditing && ((caffenioUpdated.Pais !== props.caffenio.Pais )
                                || (caffenioUpdated.Estado !== props.caffenio.Estado)
                                || (caffenioUpdated.Municipio !== props.caffenio.Municipio)
                                || (caffenioUpdated.Colonia !== props.caffenio.Colonia.value.trim())
                                || (caffenioUpdated.MainStreet !== props.caffenio.MainStreet)
                                || (caffenioUpdated.ExtNumber !== props.caffenio.ExtNumber)
                                || (caffenioUpdated.BetStreets !== props.caffenio.BetStreets)
                                || (caffenioUpdated.idPostalCode !== props.caffenio.idPostalCode))){
                                    caffenioUpdated.AddressId = 0
                                }
                
                                props.back(caffenioUpdated)}} >Anterior</button>

                        </div>
                    </div>
                    <div className="has-text-right buttons-modal" id="stepActions">
                        <div className="pull-right">
                            <button id="btnSaveStepLocationDrive" type="submit" className="button is-success"  >Continuar</button>
                        </div>
                    </div>
                    {
                        state.isLoading &&
                        <Loading
                            isFullscreen={true}
                            isLoading={state.isLoading}
                            width='100px'
                            height='100px'
                        />
                    }
                </form>
            )}

        </Formik>
    );

}

export default StepLocationDrive;