import React, { Component } from 'react';
import { toast } from 'react-toastify'
import RecipesAPI from '../../../services/Recipe';
import to from '../../../util/to';
import SatAPI from '../../../services/Sat';
import '../../cssComponents.css';
import './NewRecipeMulti.css'

import WarehouseAPI from '../../../services/Warehouse';
import Loading from '../../util/loading/Loading';
import AcceptModal from '../../util/acceptModal/AcceptModal';
import ErrorMessages from '../../../util/validation-error-messages';

//Steps 
import RecipeConfigurationStep from './stepsItems/RecipeConfigurationStep';
import RecipeComplementInfoStep from './stepsItems/RecipeComplementInfoStep';
import RecipeItemsAndToppingStep from './stepsItems/RecipeItemsAndToppingStep';
import RecipesConfigStep from './stepsItems/RecipesConfigStep';
import Modal from '../../util/modal/Modal';

/** Clase para crear o editar recetas */
class NewRecipeMulti extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipe: {},
            classifications: [],
            presentations: [],
            flavors: [],
            recipeTypes: [],
            milks: [],
            segments: [],
            grains: [],
            satClassifications: [],
            satCodes: [],
            dataLoaded: false,
            units: [],
            cards: [],
            selectedOption: [],
            selectedOptionC: [],
            selectedOptionD: [],
            optionState: [],
            warehouseArr: [],
            Caffenios: [],
            warehouseFilter: [],
            isLoading: false,
            selectedCard: 0,
            modalConfirm: false,
            cardsPreview: [], //Arreglo que representa las recetas que se pondrán en el modal de confirmación
            truncatedName: false, //Booleano que indica si se cambió el nombre de una receta porque era muy largo
            baseFormOpen: true, //Booleano que indica si se puede ver el formulario de la información base
            cardsOpen: true, //Booleano que indica si se pueden ver las tarjetas generadas
            modalGeneric: false, //Booleano que indica si el modal generico se tiene que mostrar
            modalGenericDone: () => { }, //Funcion que se ejecuta al darle click al boton de aceptar en el modal generico
            modalGenericTitle: "", //Titulo del modal generico
            modalGenericBody: "", //Contenido del modal generico
            //steps
            stepConfigRecipe: true,
            stepComplementInfo: false,
            stepCommonItemsAndToppings: false,
            stepCombineRecipes: false,

            stepConfigRecipeCompleted: false,
            stepComplementInfoCompleted: false,
            stepCommonItemsAndToppingsCompleted: false,
            stepCombineRecipesCompleted: false,

            lstRecipes:[],
            isEditing: false,
            nombresSinTipo:['sin tipo', 'n/a', 'n / a', 'na', '', 'no aplica',
            'sin sabor',
            'sin tipo sabor',
            'Sin sabor',
            'Sin Sabor',
            's/s',
            's / s',
            'sin grano',
            'sin tipo grano',
            's/g',
            's / g',
            'sin leche',
            'sin tipo leche',
            's/l',
            's / l',
            'sin presentación',
            'sin presentacion',
            'sin tipo presentación',
            'sin tipo presentacion',
            's/p',
            's / p',
            'sin clasificación',
            'sin clasificacion',
            'sin tipo clasificación',
            'sin tipo clasificacion',
            's/c',
            's / c',
            'sin receta',
            'sin tipo receta',
            's/r',
            's / r'
        ]
        };
    }
    select_state(Caffenios) { // Llenar el combo 'Estados' con todas las opciones 
        var newArray = [];
        let OptionSelect = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelect.push({ value: item.StateId, label: item.State });
        });

        for (var i in OptionSelect) {
            lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push(`/recetas`);
            return;
        }
        this.setState({ isLoading: true }, () => {
            this.getDrives();

        });

    }

    /*Función para obtener las ciudades que se pone en el set state */
    getDrives = async () => {
        let err, caffenios;
        [err, caffenios] = await to(WarehouseAPI.getCaffenios());

        if (err) {
            this.setState({ isLoading: false })
            if (err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        } else {
            this.setState({
                optionState: this.select_state(caffenios),
                Caffenios: caffenios
            }, () => this.getData())
        }
    }

    /**Obtiene y setea la información de los combos */
    getData = async () => {
        let err, satClassifications, satCodes;
        [err, satClassifications] = await to(SatAPI.getClassifications());
        [err, satCodes] = await to(SatAPI.getCodes());

        if (err) {
            this.setState({ isLoading: false })
            if (err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        } else {
            this.setState({
                classifications: this.props.location.state.classifications,
                presentations: this.props.location.state.presentations.filter(p => p.Active),
                flavors: this.props.location.state.flavors,
                milks: this.props.location.state.milks,
                segments: this.props.location.state.segments,
                grains: this.props.location.state.grains,
                recipeTypes: this.props.location.state.recipeTypes,
                lstRecipes: this.props.location.state.lstRecipes,
                satClassifications: satClassifications,
                satCodes: satCodes,
                dataLoaded: true,
            }, () => this.getRecipe())
        }

    }

    /**Obtiene y da formato a la receta si es editar o inicializa si es crear nueva  */
    getRecipe = () => {
        if (this.props.match.params.id) {
            RecipesAPI.get(this.props.match.params.id).then(response => {
                if(response.data){    
                    let detailRecipe = { ...response.data };                
                    //Agrupa el detalle de la receta 
                    detailRecipe.Details = detailRecipe.Details.reduce((list, item) => {
                        if(!list.some(l => l.Amount === item.Amount && l.InventoryItemId === item.InventoryItem.InventoryItemId )){
                            let commonItems = detailRecipe.Details.filter(d => d.Amount === item.Amount && d.InventoryItem.InventoryItemId === item.InventoryItem.InventoryItemId);
                            let totalDrives = this.state.Caffenios.length;
                            let pushStates = [];
                            let pushCities = [];

                            let newItem = { ...item.InventoryItem };
                            newItem.Amount = item.Amount;
                            newItem.RecipeDetailId = item.RecipeDetailId;
                            newItem.driveList = item.InventoryItem.idDrive
                                ? commonItems.map(c => {
                                    if(!pushStates.some(s => s.idEstado === c.InventoryItem.idEstado)){
                                        pushStates.push( c.InventoryItem.DriveEstado )
                                    }
                                        
                                    if(!pushCities.some(ct => ct.idMunicipio === c.InventoryItem.idMunicipio)){
                                        pushCities.push( c.InventoryItem.DriveMunicipio)
                                    }
                                    return c.InventoryItem.idDrive
                                })
                                : this.state.Caffenios.map(dr => { return dr.idCaffenio });
                            newItem.DriveId = item.InventoryItem.idDrive 
                                ? commonItems.map(c => {                                    
                                    return {
                                        ...c.InventoryItem,
                                        Amount: c.Amount,
                                        RecipeDetailId :c.RecipeDetailId
                                    }
                                })
                                : null;                                
                            pushCities = pushCities.filter(this.onlyUnique)
                            pushStates = pushStates.filter(this.onlyUnique)
                            newItem.DriveNombre = newItem.driveList.length > 4
                                ? newItem.driveList.length >= totalDrives
                                    ? "Todos"
                                    : pushCities.length > 4
                                        ? pushStates.length > 1
                                            ? pushStates.join(", ")
                                            : pushStates[0]
                                        : pushCities.join(", ")
                                : newItem.DriveId.map(d => d.DriveNombre).join(", ");

                            list.push(newItem) ;
                        }
                        return list;                    
                    }, []);

                    //formato a la data 
                    if(response.data.Classification !== null){
                        response.data.Classification.value = response.data.Classification ? response.data.Classification.ClassificationId : null;
                        response.data.Classification.label = response.data.Classification ? response.data.Classification.Name : null;
                    }
                    if(response.data.RecipeType !== null){
                        response.data.RecipeType.value = response.data.RecipeType ? response.data.RecipeType.RecipeTypeId : null;
                        response.data.RecipeType.label = response.data.RecipeType ? response.data.RecipeType.Description : null;
                    }
                    if(response.data.FlavorType !== null){
                        response.data.FlavorType.value = response.data.FlavorType ? response.data.FlavorType.FlavorTypeId : null;
                        response.data.FlavorType.label = response.data.FlavorType ? response.data.FlavorType.Name : null;
                    }
                    if(response.data.GrainType !== null){
                        response.data.GrainType.value = response.data.GrainType ? response.data.GrainType.GrainTypeId : null;
                        response.data.GrainType.label = response.data.GrainType ? response.data.GrainType.Description : null;
                    }
                    if(response.data.MilkType !== null){
                        response.data.MilkType.value = response.data.MilkType ? response.data.MilkType.MilkTypeId : null;
                        response.data.MilkType.label = response.data.MilkType ? response.data.MilkType.Description : null;
                    }
                    if(response.data.Presentation !== null){
                        response.data.Presentation.value = response.data.Presentation ? response.data.Presentation.PresentationId : null;
                        response.data.Presentation.label = response.data.Presentation ? response.data.Presentation.Name : null;
                    }
                    if(response.data.ClassificationSat !== null){
                        response.data.ClassificationSat.value = response.data.ClassificationSat ? response.data.ClassificationSat.ClassificationSatId : null;
                        response.data.ClassificationSat.label = response.data.ClassificationSat ? response.data.ClassificationSat.Description : null;
                    }
                    if(response.data.CodeSat !== null){
                        response.data.CodeSat.value = response.data.CodeSat ? response.data.CodeSat.Code : null;
                        response.data.CodeSat.label = response.data.CodeSat ? response.data.CodeSat.Description : null;
                    }
                    detailRecipe.ClassificationId = response.data.Classification;
                    detailRecipe.RecipeTypeId = [response.data.RecipeType];
                    detailRecipe.FlavorTypeId = [response.data.FlavorType];
                    detailRecipe.GrainTypeId = [response.data.GrainType];
                    detailRecipe.MilkTypeId = [response.data.MilkType];
                    detailRecipe.PresentationId = [response.data.Presentation];
                    detailRecipe.ClassificationSatId = response.data.ClassificationSat;
                    detailRecipe.CodeSatId = response.data.CodeSat;
                    this.setState({ recipe: detailRecipe, isLoading: false, isEditing: true })
                }else{
                    toast.error("Ocurrió un error al cargar información de la receta");
                    this.props.history.goBack();
                }                
            }).catch(err => {
                this.setState({ isLoading: false })
                if (err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        } else {
            this.setState({
                isLoading: false,
                recipe: {
                    ShortName: "",
                    Description: "",
                    ClassificationId: 0,
                    PresentationId: 0,
                    FlavorTypeId: 0,
                    RecipeTypeId: 0,
                    IVAApply: true,
                    KiwiId: "",
                    MilkTypeId: 0,
                    GrainTypeId: 0,
                    Active: true,
                    MobileApply: true,
                    WelcomeGiftApply: true,
                    GiftWelcomeActive: true,
                    ClassificationSatId: 0,
                    CodeSatId: 0,
                    Details: [],
                    Toppings: [],
                }
            });

        }
    }

    /**
     * Función que se ejecuta al darle click al botón de "Generar tarjetas". Revisa los datos del formulario y si todo esta correcto genera las tarjetas
     * @param recipe Objeto. Valor que devuelve el componente de RecipeFormMulti al darle click al botón de generar tarjetas
     */
    save = (recipe) => {
        let data = { ...recipe };
        data.Details = data.Details.map(d => {
            return { Amount: d.Amount, InventoryItem: d };
        });

        let clasificacion = this.state.classifications.find(item => item.ClassificationId === data.Propiedades.ClassificationId.ClassificationId)
        data.Nombres = {
            Clasificacion: clasificacion.ShortName,
        }

        if (this.state.cards.length > 0) {
            this.setState({
                modalGeneric: true,
                modalGenericTitle: "¿Volver a generar las tarjetas?",
                modalGenericBody: "Se perderán los cambios hechos en las tarjetas.",
                modalGenericDone: () => this.crearTarjetas(data)
            })
        } else {
            this.crearTarjetas(data)
        }
    }

    /**
     * Función que genera varias tarjetas tomando como base la informacion del formulario de recetas
     * @param datosReceta Objeto. Objeto con informacion de la receta
     */
    crearTarjetas = (datosReceta) => {
        let objBase = {
            ...datosReceta
        }

        let lstTarjetasFinal = [objBase]
        let lstTarjetasTemp = []
        let objTemp = {}
        let baseFormOpen = true
        //Generando tarjetas por presentacion
        if (datosReceta.TipoPresentacion != null || datosReceta.TipoPresentacion.length > 0) {
            lstTarjetasTemp = []
            for (let j = 0; j < lstTarjetasFinal.length; j++) {
                for (let i = 0; i < datosReceta.TipoPresentacion.length; i++) {
                    if (datosReceta.TipoPresentacion[i].Nombre.toLowerCase() !== "sin presentación") {
                        objTemp = {
                            ...lstTarjetasFinal[j],
                            Propiedades: { ...lstTarjetasFinal[j].Propiedades },
                            Details: lstTarjetasFinal[j].Details.map(item => { return { Amount: item.Amount, InventoryItem: { ...item.InventoryItem } } }),
                            Toppings: lstTarjetasFinal[j].Toppings.map(item => { return { ...item } }),
                            TipoSabor: lstTarjetasFinal[j].TipoSabor.map(item => { return { ...item, Details: [], Toppings: [] } }),
                            TipoLeche: lstTarjetasFinal[j].TipoLeche.map(item => { return { ...item, Details: [], Toppings: [] } }),
                            TipoGrano: lstTarjetasFinal[j].TipoGrano.map(item => { return { ...item, Details: [], Toppings: [] } })
                        }
                        objTemp.Propiedades.PresentationId = datosReceta.TipoPresentacion[i].PresentationId
                        objTemp.Nombres = { ...objTemp.Nombres, TipoPresentacion: datosReceta.TipoPresentacion[i].Nombre }
                        lstTarjetasTemp.push(objTemp)
                    }
                    else {
                        objTemp = {
                            ...lstTarjetasFinal[j],
                            Propiedades: { ...lstTarjetasFinal[j].Propiedades },
                            Details: lstTarjetasFinal[j].Details.map(item => { return { Amount: item.Amount, InventoryItem: { ...item.InventoryItem } } }),
                            Toppings: lstTarjetasFinal[j].Toppings.map(item => { return { ...item } }),
                            TipoSabor: lstTarjetasFinal[j].TipoSabor.map(item => { return { ...item, Details: [], Toppings: [] } }),
                            TipoLeche: lstTarjetasFinal[j].TipoLeche.map(item => { return { ...item, Details: [], Toppings: [] } }),
                            TipoGrano: lstTarjetasFinal[j].TipoGrano.map(item => { return { ...item, Details: [], Toppings: [] } })
                        }
                        objTemp.Propiedades.PresentationId = datosReceta.TipoPresentacion[i].PresentationId                        
                        lstTarjetasTemp.push(objTemp)
                                               
                    }
                }
            }
            if (lstTarjetasTemp.length > 0)
                lstTarjetasFinal = lstTarjetasTemp
        }

        //Generando tarjetas por tipo de receta
        if (datosReceta.TipoReceta != null || datosReceta.TipoReceta.length > 0) {
            lstTarjetasTemp = []
            for (let j = 0; j < lstTarjetasFinal.length; j++) {
                for (let i = 0; i < datosReceta.TipoReceta.length; i++) {
                    objTemp = {
                        ...lstTarjetasFinal[j],
                        Propiedades: { ...lstTarjetasFinal[j].Propiedades },
                        Details: lstTarjetasFinal[j].Details.map(item => { return { Amount: item.Amount, InventoryItem: { ...item.InventoryItem } } }),
                        Toppings: lstTarjetasFinal[j].Toppings.map(item => { return { ...item } }),
                        TipoSabor: lstTarjetasFinal[j].TipoSabor.map(item => { return { ...item, Details: [], Toppings: [] } }),
                        TipoLeche: lstTarjetasFinal[j].TipoLeche.map(item => { return { ...item, Details: [], Toppings: [] } }),
                        TipoGrano: lstTarjetasFinal[j].TipoGrano.map(item => { return { ...item, Details: [], Toppings: [] } })
                    }
                    if (datosReceta.TipoReceta[i].Nombre.toLowerCase() !== "sin tipo") {
                        
                        objTemp.Propiedades.RecipeTypeId = datosReceta.TipoReceta[i].RecipeTypeId
                        objTemp.Nombres = { ...objTemp.Nombres, TipoReceta: datosReceta.TipoReceta[i].Nombre }
                        lstTarjetasTemp.push(objTemp)
                    } 
                    else {
                        if(objTemp.Propiedades){
                            objTemp.Propiedades.RecipeTypeId = datosReceta.TipoReceta[i].RecipeTypeId
                            //objTemp.Nombres = { ...objTemp.Nombres, TipoReceta: datosReceta.TipoReceta[i].Nombre }
                            lstTarjetasTemp.push(objTemp)
                        }                       
                    }
                }
            }

            if (lstTarjetasTemp.length > 0) {
                lstTarjetasFinal = lstTarjetasTemp
                baseFormOpen = false
            }
        }

        this.setState({ cards: lstTarjetasFinal, baseFormOpen: baseFormOpen }, () => {
            if (this.state.isEditing) {
                this.setState({ modalConfirm: true, cardsPreview: this.crearRecetasDesdeTarjetas() });
            }
        })

    }

    /**
     * Funcion que se ejecuta cada vez que se modifica un detalle en la tarjeta. Agrega/actualiza los detalles.
     * @param detallesActualizados Array. Lista de objetos que representan los detalles actualizados
     * @param tipoLabel String. Etiqueta que indica a que tipo de dato se va a modificar los detalles. Ejemplo: TipoSabor, TipoLeche
     * @param indice Int. Indice en el tipo de dato donde estan los detalles a modificar
     * @param indiceTarjeta Int. Indice donde esta la tarjeta en el arreglo de cards.
     */
    onTarjetaDetailChange = (detallesActualizados, tipoLabel, indice, indiceTarjeta) => {
        // let tarjetas = [...this.state.cards]
        let tarjeta = { ...this.state.cards[indiceTarjeta] }
        if (tipoLabel.toLowerCase() === "details") {
            tarjeta.Details = [...detallesActualizados]
        } else {
            tarjeta[tipoLabel][indice].Details = [...detallesActualizados]
        }
        let tarjetas = [...this.state.cards]
        tarjetas[indiceTarjeta] = tarjeta
        this.setState({ cards: tarjetas })
    }

    /**
     * Funcion que se ejecuta cada vez que se modifica un topping en la tarjeta. Agrega/actualiza los toppings.
     * @param toppingsActualizados Array. Lista de objetos que representan los toppings actualizados
     * @param tipoLabel String. Etiqueta que indica a que tipo de dato se va a modificar los toppings. Ejemplo: TipoSabor, TipoLeche
     * @param indice Int. Indice en el tipo de dato donde estan los toppings a modificar
     * @param indiceTarjeta Int. Indice donde esta la tarjeta en el arreglo de cards.
     */
    onTarjetaToppingChange = (toppingsActualizados, tipoLabel, indice, indiceTarjeta) => {
        let tarjeta = { ...this.state.cards[indiceTarjeta] }
        if (tipoLabel.toLowerCase() === "toppings") {
            tarjeta.Toppings = [...toppingsActualizados]
        } else {
            tarjeta[tipoLabel][indice].Toppings = [...toppingsActualizados]
        }
        let tarjetas = [...this.state.cards]
        tarjetas[indiceTarjeta] = tarjeta
        this.setState({ cards: tarjetas })
    }

    /**
     * Crea varias recetas a la vez tomando como base la lista de objetos tipo Tarjeta en this.state.Cards
     * @returns Una lista de recetas
     */
    crearRecetasDesdeTarjetas = () => {
        let objBase = {}
        let lstRecetasFinal = []
        let lstRecetasTarjeta = []
        let lstRecetasTemp = []
        let objTemp = {}
        let objTarjeta = {}
        let lstTarjetas = [...this.state.cards]
        let truncatedName = false

        for (let indexTarjeta = 0; indexTarjeta < lstTarjetas.length; indexTarjeta++) {
            objTarjeta = lstTarjetas[indexTarjeta]
            objBase = {
                ...objTarjeta.Propiedades
                , Details: []
                , Nombres: { ...objTarjeta.Nombres }
                , Toppings: [...objTarjeta.Toppings]
            }

            objTarjeta.Details.forEach(detalle => {
                if (detalle.InventoryItem.DriveId != null) {
                    detalle.InventoryItem.DriveId.forEach(driveId => {
                        objBase.Details.push({ Amount: detalle.Amount, InventoryItem: { ...detalle.InventoryItem, idDrive: driveId.idDrive, DriveId: [], driveList: [] } })
                    })
                }
                else {
                    objBase.Details.push({ Amount: detalle.Amount, InventoryItem: { ...detalle.InventoryItem, idDrive: null, DriveId: [], driveList: [] } })
                }
            })

            lstRecetasTarjeta = [objBase]
            lstRecetasTemp = []
            objTemp = {}

            //Agregando los detalles de los sabores
            if (objTarjeta.TipoSabor != null || objTarjeta.TipoSabor.length > 0) {
                lstRecetasTemp = []
                for (var j = 0; j < lstRecetasTarjeta.length; j++) {
                    for (var i = 0; i < objTarjeta.TipoSabor.length; i++) {
                        objTemp = {
                            ...lstRecetasTarjeta[j],
                            FlavorTypeId: objTarjeta.TipoSabor[i].FlavorTypeId,
                            Toppings: [
                                ...objTarjeta.TipoSabor[i].Toppings,
                                ...objTarjeta.Toppings
                            ]
                        }
                        objTemp.Details = [...objTemp.Details]
                        if (objTarjeta.TipoSabor[i].Details != null) {
                            for (let d = 0; d < objTarjeta.TipoSabor[i].Details.length; d++) {
                                if (objTarjeta.TipoSabor[i].Details[d].InventoryItem.DriveId != null) {
                                    objTarjeta.TipoSabor[i].Details[d].InventoryItem.DriveId.forEach(driveId => {
                                        objTemp.Details.push({
                                            Amount: objTarjeta.TipoSabor[i].Details[d].Amount,
                                            InventoryItem: {
                                                ...objTarjeta.TipoSabor[i].Details[d].InventoryItem,
                                                idDrive: driveId.idDrive, DriveId: []
                                            }
                                        })
                                    })
                                } else {
                                    objTemp.Details.push({ Amount: objTarjeta.TipoSabor[i].Details[d].Amount, InventoryItem: { ...objTarjeta.TipoSabor[i].Details[d].InventoryItem, idDrive: null, DriveId: [] } })
                                }
                            }
                        }                    
                        objTemp.Nombres = { ...objTemp.Nombres, TipoSabor: objTarjeta.TipoSabor[i].Nombre }
                        lstRecetasTemp.push(objTemp)
                    }
                }
                if (lstRecetasTemp.length > 0)
                    lstRecetasTarjeta = lstRecetasTemp
            }

            //Agregando los detalles del tipo de leche
            if (objTarjeta.TipoLeche != null || objTarjeta.TipoLeche.length > 0) {
                lstRecetasTemp = []
                for (var j = 0; j < lstRecetasTarjeta.length; j++) {
                    for (var i = 0; i < objTarjeta.TipoLeche.length; i++) {
                        objTemp = {
                            ...lstRecetasTarjeta[j],
                            MilkTypeId: objTarjeta.TipoLeche[i].MilkTypeId
                        }
                        objTemp.Details = [...objTemp.Details]
                        if (objTarjeta.TipoLeche[i].Details != null) {
                            for (let d = 0; d < objTarjeta.TipoLeche[i].Details.length; d++) {
                                for (let d = 0; d < objTarjeta.TipoLeche[i].Details.length; d++) {
                                    if (objTarjeta.TipoLeche[i].Details[d].InventoryItem.DriveId != null) {
                                        objTarjeta.TipoLeche[i].Details[d].InventoryItem.DriveId.forEach(driveId => {
                                            objTemp.Details.push({ Amount: objTarjeta.TipoLeche[i].Details[d].Amount, InventoryItem: { ...objTarjeta.TipoLeche[i].Details[d].InventoryItem, idDrive: driveId.idDrive, DriveId: [] } })
                                        })
                                    } else {
                                        objTemp.Details.push({ Amount: objTarjeta.TipoLeche[i].Details[d].Amount, InventoryItem: { ...objTarjeta.TipoLeche[i].Details[d].InventoryItem, idDrive: null, DriveId: [] } })
                                    }
                                }
                            }
                        }
                        objTemp.Nombres = { ...objTemp.Nombres, TipoLeche: objTarjeta.TipoLeche[i].Nombre }
                        lstRecetasTemp.push(objTemp)
                    }
                }
                if (lstRecetasTemp.length > 0)
                    lstRecetasTarjeta = lstRecetasTemp
            }

            //Agregando los detalles del tipo de grano
            if (objTarjeta.TipoGrano != null || objTarjeta.TipoGrano.length > 0) {
                lstRecetasTemp = []
                for (var j = 0; j < lstRecetasTarjeta.length; j++) {
                    for (var i = 0; i < objTarjeta.TipoGrano.length; i++) {
                        objTemp = {
                            ...lstRecetasTarjeta[j],
                            GrainTypeId: objTarjeta.TipoGrano[i].GrainTypeId
                        }
                        objTemp.Details = [...objTemp.Details]
                        if (objTarjeta.TipoGrano[i].Details != null) {
                            for (let d = 0; d < objTarjeta.TipoGrano[i].Details.length; d++) {
                                for (let d = 0; d < objTarjeta.TipoGrano[i].Details.length; d++) {
                                    if (objTarjeta.TipoGrano[i].Details[d].InventoryItem.DriveId != null) {
                                        objTarjeta.TipoGrano[i].Details[d].InventoryItem.DriveId.forEach(driveId => {
                                            objTemp.Details.push({ Amount: objTarjeta.TipoGrano[i].Details[d].Amount, InventoryItem: { ...objTarjeta.TipoGrano[i].Details[d].InventoryItem, idDrive: driveId.idDrive, DriveId: [] } })
                                        })
                                    } else {
                                        objTemp.Details.push({ Amount: objTarjeta.TipoGrano[i].Details[d].Amount, InventoryItem: { ...objTarjeta.TipoGrano[i].Details[d].InventoryItem, idDrive: null, DriveId: [] } })
                                    }
                                }
                            }
                        }
                        objTemp.Nombres = { ...objTemp.Nombres, TipoGrano: objTarjeta.TipoGrano[i].Nombre }
                        lstRecetasTemp.push(objTemp)
                    }
                }
                if (lstRecetasTemp.length > 0)
                    lstRecetasTarjeta = lstRecetasTemp
            }

            //Generando nombres
            for (var i = 0; i < lstRecetasTarjeta.length; i++) {
                lstRecetasTarjeta[i].Description = !this.state.isEditing 
                ? lstRecetasTarjeta[i].Nombres.Clasificacion.substr(0, 4)
                    + ((lstRecetasTarjeta[i].Nombres.TipoReceta && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoReceta)) ? " " + lstRecetasTarjeta[i].Nombres.TipoReceta.trim().substr(0, 10) : "")
                    + ((lstRecetasTarjeta[i].Nombres.TipoSabor && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoSabor)) && lstRecetasTarjeta[i].Nombres.TipoReceta !== lstRecetasTarjeta[i].Nombres.TipoSabor ? " " + lstRecetasTarjeta[i].Nombres.TipoSabor.trim().substr(0, 10) : "")
                    + ((lstRecetasTarjeta[i].Nombres.TipoGrano && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoGrano)) ? " " + lstRecetasTarjeta[i].Nombres.TipoGrano.trim().substr(0, 5) : "")
                    + ((lstRecetasTarjeta[i].Nombres.TipoLeche && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoLeche)) ? " " + lstRecetasTarjeta[i].Nombres.TipoLeche.toLowerCase().replace('leche', '').replace('de ','').trim().substr(0, 5) : "")
                    + ((lstRecetasTarjeta[i].Nombres.TipoPresentacion && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoPresentacion)) ? " " + lstRecetasTarjeta[i].Nombres.TipoPresentacion.trim().substr(0, 5) : "")
                
                : lstRecetasTarjeta[i].ShortName ;

                if (lstRecetasTarjeta[i].Description.length > 50) {
                    lstRecetasTarjeta[i].Description = (
                        !this.state.isEditing ?
                        lstRecetasTarjeta[i].Nombres.Clasificacion.substr(0, 4)
                            + ((lstRecetasTarjeta[i].Nombres.TipoReceta && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoReceta)) ? " " + lstRecetasTarjeta[i].Nombres.TipoReceta.trim().substr(0, 10) : "")
                            + ((lstRecetasTarjeta[i].Nombres.TipoSabor && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoSabor)) && lstRecetasTarjeta[i].Nombres.TipoReceta !== lstRecetasTarjeta[i].Nombres.TipoSabor ? " " + lstRecetasTarjeta[i].Nombres.TipoSabor.trim().substr(0, 10) : "")
                            + ((lstRecetasTarjeta[i].Nombres.TipoGrano && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoGrano)) ? " " + lstRecetasTarjeta[i].Nombres.TipoGrano.trim().substr(0, 5) : "")
                            + ((lstRecetasTarjeta[i].Nombres.TipoLeche && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoLeche)) ? " " + lstRecetasTarjeta[i].Nombres.TipoLeche.toLowerCase().replace('leche', '').replace('de ','').trim().substr(0, 5) : "")
                            + ((lstRecetasTarjeta[i].Nombres.TipoPresentacion && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoPresentacion)) ? " " + lstRecetasTarjeta[i].Nombres.TipoPresentacion.trim().substr(0, 5) : "")
                        
                        : lstRecetasTarjeta[i].ShortName 
                    ).substr(0, 50)

                    lstRecetasTarjeta[i].TruncatedName = true
                    truncatedName = true
                }

                lstRecetasTarjeta[i].ShortName = this.state.isEditing 
                ? lstRecetasTarjeta[i].ShortName 
                : lstRecetasTarjeta[i].Nombres.Clasificacion.substr(0, 4)
                + ((lstRecetasTarjeta[i].Nombres.TipoReceta && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoReceta)) ? " " + lstRecetasTarjeta[i].Nombres.TipoReceta.trim().substr(0, 10) : "")
                + ((lstRecetasTarjeta[i].Nombres.TipoSabor && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoSabor)) && lstRecetasTarjeta[i].Nombres.TipoReceta !== lstRecetasTarjeta[i].Nombres.TipoSabor ? " " + lstRecetasTarjeta[i].Nombres.TipoSabor.trim().substr(0, 10) : "")
                + ((lstRecetasTarjeta[i].Nombres.TipoGrano && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoGrano)) ? " " + lstRecetasTarjeta[i].Nombres.TipoGrano.trim().substr(0, 5) : "")
                + ((lstRecetasTarjeta[i].Nombres.TipoLeche && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoLeche)) ? " " + lstRecetasTarjeta[i].Nombres.TipoLeche.toLowerCase().replace('leche', '').replace('de ','').trim().substr(0, 5) : "")
                + ((lstRecetasTarjeta[i].Nombres.TipoPresentacion && !this.nombreEsSinTipo(lstRecetasTarjeta[i].Nombres.TipoPresentacion)) ? " " + lstRecetasTarjeta[i].Nombres.TipoPresentacion.trim().substr(0, 5) : "")
                ;
            }

            lstRecetasFinal = [...lstRecetasFinal, ...lstRecetasTarjeta]
        }

        if (truncatedName) this.setState({ truncatedName: truncatedName })

        return lstRecetasFinal
    }

    /**
     * Función que analiza el nombre dado y determina si representa a la clasificacion sin tipo
     * @param nombre String. Nombre que se va analizar
     * @returns True si el nombre representa a la clasificación sin tipo. False en caso contrario
     */
    nombreEsSinTipo = (nombre) => {    
        return this.state.nombresSinTipo.indexOf((nombre.trim().toLowerCase())) !== -1
    }

    /**
     * Genera recetas desde tarjetas y manda a llamar al endpoint de la API para guardar multiples recetas
     */
    guardarRecetasMulti = () => {
        this.setState({ isLoading: true }, () => {
            let lstRecetas = this.state.cardsPreview;
            if (lstRecetas.length > 0) {
                // Agrupa artículos con listado de drives, el listado es [] sí aplica para todos
                lstRecetas = lstRecetas.map(item => {
                    item.ClassificationId = item.ClassificationId.ClassificationId;
                    item.ClassificationSatId = item.ClassificationSatId.ClassificationSatId;
                    item.CodeSatId = item.CodeSatId.Code;
                    item.Details = item.Details.reduce((list, detail, index) => {

                        if (!list.some(l => l.InventoryItemId === detail.InventoryItem.InventoryItemId && l.Amount === detail.Amount)) {
                            let formatItem = {};
                            formatItem.Amount = detail.Amount;
                            formatItem.InventoryItemId = detail.InventoryItem.InventoryItemId;
                            let totalInventory = item.Details.filter(d => d.InventoryItem.InventoryItemId === formatItem.InventoryItemId && d.Amount === formatItem.Amount);
                            
                            if (!detail.InventoryItem.idDrive || totalInventory.length >= this.state.Caffenios.length) {
                                //poner para todos
                                formatItem.idDrive = [];
                            } else {
                                let drivesIds = totalInventory.map(d => {                                    
                                    return d.InventoryItem.idDrive ? d.InventoryItem.idDrive : d.InventoryItem.idCaffenio
                                })
                                //valores unicos
                                formatItem.idDrive = drivesIds.filter(this.onlyUnique)
                            }
                            list.push(formatItem);
                        }
                        return list;
                    }, []);
                    return item;
                });

                if (this.state.isEditing) {
                    let lastIndex = lstRecetas.length;
                    //Edita receta por receta en teoría solo es una 
                    lstRecetas.forEach((receta, index) => {
                        RecipesAPI.editWithFormData(receta, receta.Image instanceof File ? receta.Image : null)
                            .then(response => {
                                if(index === (lastIndex-1)){
                                    toast.success("Receta actualizada.");
                                    this.setState({ isLoading: false, modalConfirm: false, cardsPreview: []  });                                
                                    this.props.history.goBack();
                                }                                
                            })
                            .catch(error => {
                                toast.error("Error al edita receta")
                                this.setState({ isLoading: false, modalConfirm: false, cardsPreview: [] });
                            
                            });

                    });
                    
                } else {          
                    let recipesByFlavor = lstRecetas.reduce((lista, item) => {
                        if(!lista.some(l => l.FlavorTypeId === item.FlavorTypeId)){
                            //let imageFlavor = item.flavorsImages.filter(i => i.FlavorTypeId === item.FlavorTypeId); 
                            let totalRecipes = lstRecetas.filter(f => f.FlavorTypeId === item.FlavorTypeId);
                            lista.push({ 
                                FlavorTypeId: item.FlavorTypeId, 
                                //ImgResource: imageFlavor && imageFlavor.length > 0 ? imageFlavor[0].Image : null , 
                                Recipes: totalRecipes,
                                Completed:false
                            });
                        }
                        return lista
                    }, []);
                    recipesByFlavor.forEach((receta) => {
                        RecipesAPI.createWithFormData(receta.Recipes, receta.ImgResource)
                        .then(response => {
                            receta.Completed = true;
                            if(!recipesByFlavor.some(r => r.Completed === false)){
                                this.setState({ isLoading: false, modalConfirm: false, cardsPreview: [] }, 
                                () => {
                                    toast.dismiss();
                                    toast.success("Receta(s) creada(s)");
                                    this.props.history.goBack();
                                });                                
                            }
                            
                        })
                        .catch(error => {
                            this.setState({ isLoading: false, modalConfirm: false, cardsPreview: [] }, 
                                () => {
                                    toast.dismiss(); 
                                    toast.error("Error al crear recetas"); 
                                });                    
                        });

                    });
                }

            } else {
                toast.error("No hay recetas para procesar.")
            }
        })
    }

    /**Función para validar valores unicos en el array */
    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{ position: "absolute", left: 25 }} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{ cursor: "pointer", color: "#91BA43" }} onClick={() => { this.props.history.goBack() }} className="fa fa-arrow-left"></i>
                            </span>
                            <div>
                                {this.state.recipe.RecipeId ? "Editar Receta" : "Nueva Receta"}
                                <i className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }} onClick={() => this.setState({ showModal: true })} className="fa fa-question-circle"></i>
                            </div>

                        </div>
                    </div>
                    <div className="card-content">
                        <div>
                            <div className="steps" id="stepsRecipes">
                                <div className={`step-item ${this.state.stepConfigRecipe ? "is-active" : ""} ${this.state.stepConfigRecipeCompleted ? "is-success" : ""}`} id="stepConfigRecipe">
                                    <div className="step-marker">1</div>
                                    <div className="step-details">
                                        <p className="step-title">Configuración</p>
                                        <p>Configuración de la receta.</p>
                                    </div>
                                </div>
                                <div className={`step-item ${this.state.stepComplementInfo ? "is-active" : ""} ${this.state.stepComplementInfoCompleted ? "is-success" : ""}`} id="stepComplementInfoRecipe">
                                    <div className="step-marker">2</div>
                                    <div className="step-details">
                                        <p className="step-title">Información complementaria</p>
                                        <p>Información complementaria de la receta.</p>
                                    </div>
                                </div>
                                <div className={`step-item ${this.state.stepCommonItemsAndToppings ? "is-active" : ""} ${this.state.stepCommonItemsAndToppingsCompleted ? "is-success" : ""}`} id="stepCommonItemsAndToppings">
                                    <div className="step-marker">3</div>
                                    <div className="step-details">
                                        <p className="step-title">Artículos y Toppings</p>
                                        <p>Agrega los artículos y toppings que tienen en común todas las combinaciones.</p>
                                    </div>
                                </div>
                                {!this.state.isEditing && <div className={`step-item ${this.state.stepCombineRecipes ? "is-active" : ""} ${this.state.stepCombineRecipesCompleted ? " is-success" : ""}`} id="stepCombineRecipes">
                                    <div className="step-marker">4</div>
                                    <div className="step-details">
                                        <p className="step-title">Recetas generadas</p>
                                        <p>Complementa los artículos y toppings que varían por tipo receta, sabor, grano, leche y/o tamaño.</p>
                                    </div>
                                </div>}

                                <div className="steps-content" style={{width:100}}>
                                    <div className={`step-content has-text-centered ${this.state.stepConfigRecipe ? "is-active" : ""}`} id="stepContentConfigRecipe">
                                        <RecipeConfigurationStep
                                            recipe={this.state.recipe}
                                            sintiposArray={this.state.nombresSinTipo}
                                            isEditing={this.state.isEditing}
                                            classifications={this.state.classifications}
                                            presentations={this.state.presentations}
                                            flavors={this.state.flavors}
                                            milks={this.state.milks}
                                            segments={this.state.segments}
                                            grains={this.state.grains}
                                            recipeTypes={this.state.recipeTypes}
                                            save={(recipe) => {
                                                let currentItem = { ...this.state.recipe }
                                                currentItem.flavorsImages = recipe.flavorsImages;
                                                currentItem.TiposDetalle = {};
                                                currentItem.ClassificationId = recipe.ClassificationId;
                                                currentItem.RecipeTypeId = recipe.RecipeTypeId;
                                                if(this.state.isEditing){
                                                    currentItem.SegmentationId = recipe.idSegmento;
                                                }else{
                                                    currentItem.SegmentationId = recipe.SegmentationId.SegmentId;
                                                }
                                                
                                                currentItem.TiposDetalle.TipoReceta = recipe.RecipeTypeId.map(item => {
                                                    return {
                                                        RecipeTypeId: item.value,
                                                        Nombre: item.label
                                                    }
                                                });
                                                currentItem.FlavorTypeId = recipe.FlavorTypeId;
                                                currentItem.TiposDetalle.TipoSabor = recipe.FlavorTypeId.map(item => {
                                                    return {
                                                        FlavorTypeId: item.value,
                                                        Nombre: item.label
                                                    }
                                                });                                                
                                                currentItem.GrainTypeId = recipe.GrainTypeId;
                                                currentItem.TiposDetalle.TipoGrano = recipe.GrainTypeId.map(item => {
                                                    return {
                                                        GrainTypeId: item.value,
                                                        Nombre: item.label
                                                    }
                                                });
                                                currentItem.MilkTypeId = recipe.MilkTypeId;
                                                currentItem.TiposDetalle.TipoLeche = recipe.MilkTypeId.map(item => {
                                                    return {
                                                        MilkTypeId: item.value,
                                                        Nombre: item.label

                                                    }
                                                });
                                                currentItem.PresentationId = recipe.PresentationId;
                                                currentItem.TiposDetalle.TipoPresentacion = recipe.PresentationId.map(item => {
                                                    return {
                                                        PresentationId: item.value,
                                                        Nombre: item.label
                                                    }
                                                });
                                                currentItem.Image = recipe.Image;
                                                currentItem.UrlImage = recipe.UrlImage;
                                                currentItem.ShortName = recipe.ShortName;
                                                if(recipe.KiwiId){
                                                    currentItem.KiwiId = recipe.KiwiId;
                                                }else{
                                                    currentItem.KiwiId = null;
                                                }
                                                this.setState({ recipe: currentItem, stepConfigRecipeCompleted: true, stepConfigRecipe: false, stepComplementInfo: true },
                                                    ()=> console.log("STEP 1", this.state.recipe))
                                            }}
                                            lstRecipes={this.state.lstRecipes}
                                        />
                                    </div>
                                    <div className={`step-content has-text-centered ${this.state.stepComplementInfo ? "is-active" : ""}`} id="stepContentComplementInfo">
                                        <RecipeComplementInfoStep
                                            recipe={this.state.recipe}
                                            isEditing={this.state.isEditing}
                                            satClassifications={this.state.satClassifications}
                                            satCodes={this.state.satCodes}
                                            save={(recipe) => {
                                                let currentItem = { ...this.state.recipe }
                                                currentItem.IVAApply = recipe.IVAApply;
                                                currentItem.MobileActive = recipe.MobileActive;
                                                currentItem.GiftWelcomeActive = recipe.GiftWelcomeActive;
                                                currentItem.ClassificationSatId = recipe.ClassificationSatId;
                                                currentItem.CodeSatId = recipe.CodeSatId;
                                                
                                                this.setState({ recipe: currentItem, stepComplementInfoCompleted: true, stepComplementInfo: false, stepCommonItemsAndToppings: true }, 
                                                    ()=>console.log("STEP 2", currentItem))
                                            }}
                                            back={() => {
                                                this.setState({ stepComplementInfo: false, stepConfigRecipe: true })
                                            }}
                                        />
                                    </div>
                                    <div className={`step-content has-text-centered ${this.state.stepCommonItemsAndToppings ? "is-active" : ""}`} id="stepContentCommonItemsAndToppings">
                                        <RecipeItemsAndToppingStep
                                            recipe={this.state.recipe}
                                            isEditing={this.state.recipe.RecipeId}
                                            Caffenios={this.state.Caffenios}
                                            optionState={this.state.optionState}
                                            save={(recipe, sync) => {
                                                let currentItem = { ...this.state.recipe }
                                                currentItem.Details = recipe.Details;
                                                currentItem.Toppings = recipe.Toppings;
                                                let objTarjetaBase = {
                                                    Propiedades: currentItem,
                                                    Details: currentItem.Details,
                                                    Toppings: currentItem.Toppings,
                                                    ...currentItem.TiposDetalle
                                                }
                                                currentItem.ApplySync = sync;
                                                console.log("STEP 3", currentItem);
                                                this.setState({ recipe: currentItem, stepCommonItemsAndToppingsCompleted: true, stepCommonItemsAndToppings: this.state.isEditing, stepCombineRecipes: !this.state.isEditing },
                                                    () => { this.save(objTarjetaBase) }//
                                                )
                                            }}
                                            back={() => {
                                                this.setState({ stepCommonItemsAndToppings: false, stepComplementInfo: true })
                                            }}
                                        />
                                    </div>
                                    {!this.state.isEditing && <div className={`step-content has-text-centered ${this.state.stepCombineRecipes ? "is-active" : ""}`} id="stepContentCombineRecipes">
                                        <RecipesConfigStep
                                            recipe={this.state.recipe}
                                            isEditing={this.state.recipe.RecipeId}
                                            cards={this.state.cards}
                                            Caffenios={this.state.Caffenios}
                                            optionState={this.state.optionState}
                                            onDetailChange={(detallesActualizados, tipoLabel, indice, selectedCard) => {
                                                this.onTarjetaDetailChange(detallesActualizados, tipoLabel, indice, selectedCard)
                                            }
                                            }
                                            onToppingChange={(toppingsActualizados, tipoLabel, indice, selectedCard) => {
                                                this.onTarjetaToppingChange(toppingsActualizados, tipoLabel, indice, selectedCard)
                                            }}
                                            save={(recipe) => {
                                                
                                                //Validar toppings
                                                let lstTarjetas = [...this.state.cards]
                                                for (let indexTarjeta = 0; indexTarjeta < lstTarjetas.length; indexTarjeta++) {
                                                    let objTarjeta = lstTarjetas[indexTarjeta]
                                                    let toppings = [...objTarjeta.Toppings]
                                                    for (let i = 0; i < toppings.length; i++) {
                                                        if(toppings[i]["MinQuantity"] === '' || toppings[i]["MaxQuantity"] ===''){
                                                            toast.error("Ingrese los valores mínimos y máximos para " + toppings[i]["Name"]);
                                                            return;
                                                        }
                                                    }
                                                    
                                                }
                                                this.setState({ stepComplementInfoCompleted: true, modalConfirm: true, cardsPreview: this.crearRecetasDesdeTarjetas() })

                                            }}
                                            back={() => {
                                                this.setState({ stepCombineRecipes: false, stepCommonItemsAndToppings: true })
                                            }}
                                        />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Modal preview */}
                <AcceptModal
                    isActive={this.state.modalConfirm}
                    done={() => this.guardarRecetasMulti()}
                    cancel={() => this.setState({ modalConfirm: false, cardsPreview: [] })}
                    modalTitle={this.state.isEditing ? "Actualizar receta" : "¿Crear las siguientes recetas?"}
                    modalBody={<div className="preview-card-group">
                        {this.state.truncatedName &&
                            <article className="message is-warning name-warning">
                                <div className="message-header">
                                    <p>Aviso</p>
                                </div>
                                <div className="message-body">
                                    Hay una o mas recetas cuyos nombres han sido modificados para que tengan una longitud de menor o igual a 50 caracteres. Puedes identificar las recetas afectadas si empiezan con el siguiente icono:
                                <span className="icon">
                                        <i className="fa fa-exclamation-circle "></i>
                                    </span>
                                </div>
                            </article>}

                        {this.state.cardsPreview.map((receta, index) => {
                            // return <p><strong>{item.Description}</strong></p>
                            // Creamos un arreglo de articulos para no repetir los que estan asignados a todos
                            let articulos = []
                            receta.Details.findIndex(detalle => {
                                if (articulos.findIndex(item => item.InventoryItemId === detalle.InventoryItem.InventoryItemId && detalle.InventoryItem.DriveNombre === item.DriveNombre) === -1) {
                                    articulos.push(detalle.InventoryItem)
                                }
                                return false
                            })

                            return <div key={index} className="card preview-card">
                                {/* Titulo y boton de expanción */}
                                <header
                                    className={"card-header" + (receta.TruncatedName ? " has-background-warning" : "")}
                                    onClick={() => {
                                        let cardsPreview = [...this.state.cardsPreview]
                                        cardsPreview[index].Open = cardsPreview[index].Open === undefined ? true : !cardsPreview[index].Open
                                        this.setState({ cardsPreview: cardsPreview })
                                    }}
                                >
                                    {receta.TruncatedName &&
                                        <span className="card-header-icon">
                                            <span className="icon">
                                                <i className="fa fa-exclamation-circle "></i>
                                            </span>
                                        </span>}
                                    <p className="card-header-title">{receta.Description}</p>
                                    <span
                                        className="card-header-icon"
                                    >
                                        <span className="icon">
                                            <i className={(receta.Open ? "fa fa-caret-up" : "fa fa-caret-down")}></i>
                                        </span>
                                    </span>
                                </header>
                                {/* Información de la receta */}
                                {receta.Open &&
                                    <div className="card-content">
                                        {/* Datos de la receta */}
                                        <table className="table is-fullwidth is-narrow">
                                            <tbody>
                                                <tr><td><strong>Tipo receta</strong></td><td>{receta.Nombres.TipoReceta && receta.Nombres.TipoReceta.trim().length > 0 ? receta.Nombres.TipoReceta : "No Aplica"}</td></tr>
                                                <tr><td><strong>Tipo sabor</strong></td><td>{receta.Nombres.TipoSabor}</td></tr>
                                                <tr><td><strong>Tipo leche</strong></td><td>{receta.Nombres.TipoLeche}</td></tr>
                                                <tr><td><strong>Tipo grano</strong></td><td>{receta.Nombres.TipoGrano}</td></tr>
                                                <tr><td><strong>Tipo presentación</strong></td><td>{receta.Nombres.TipoPresentacion && receta.Nombres.TipoPresentacion.trim().length > 0 ? receta.Nombres.TipoPresentacion : "No Aplica"}</td></tr>
                                                <tr><td><strong>Segmento</strong></td><td>{
                                                    receta.SegmentationId  == 1 ? "Caffenio" 
                                                    : receta.SegmentationId  == 9 ?"Andatti"
                                                    : receta.SegmentationId  == 10 ?"Momento Espresso"
                                                    : receta.SegmentationId
                                                }</td></tr>
                                            </tbody>
                                        </table>

                                        {/* Artículos inventario */}
                                        <h1 className="subtitle">Artículos inventario</h1>
                                        <table className="table is-fullwidth is-narrow">
                                            <thead>
                                                <tr>
                                                    <th>Código</th>
                                                    <th>Nombre</th>
                                                    <th>Cantidad</th>
                                                    <th>Unidad</th>
                                                    <th>Drive</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {articulos.length > 0 && articulos.map(detalle => {
                                                    return <tr key={`trArticulo_${detalle.InventoryCode}`}>
                                                        <td key={`tdCode_${detalle.InventoryCode}`}>{detalle.InventoryCode}</td>
                                                        <td key={`tdName_${detalle.InventoryCode}`}>{detalle.Name}</td>
                                                        <td key={`tdAmount_${detalle.InventoryCode}`}>{detalle.Amount}</td>
                                                        <td key={`tdUnits_${detalle.InventoryCode}`}>{detalle.UnitSaleStr}</td>
                                                        <td key={`tdDrive_${detalle.InventoryCode}`}>{detalle.DriveNombre}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>

                                        {/* Toppings */}
                                        <h1 className="subtitle">Toppings</h1>
                                        <table className="table is-fullwidth is-narrow">
                                            <thead>
                                                <tr>
                                                    <th>Código</th>
                                                    <th>Nombre</th>
                                                    <th>Min</th>
                                                    <th>Max</th>
                                                    <th>Unidad</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {receta.Toppings.map(topping => {
                                                    return <tr>
                                                        <td>{topping.CodeProvider}</td>
                                                        <td>{topping.Name}</td>
                                                        <td>{topping.MinQuantity}</td>
                                                        <td>{topping.MaxQuantity}</td>
                                                        <td>{topping.UnitSaleStr}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        })}
                    </div>
                    }
                />

                {/* Accept Modal generico */}
                <AcceptModal
                    isActive={this.state.modalGeneric}
                    done={() => {
                        this.state.modalGenericDone()
                        this.setState({ modalGeneric: false })
                    }}
                    cancel={() => this.setState({ modalGeneric: false, modalGenericTitle: '', modalGenericBody: '' })}
                    modalTitle={this.state.modalGenericTitle}
                    modalBody={this.state.modalGenericBody}
                />

                {/* Modal de información */}
                {this.state.showModal && !this.state.isEditing
                    ?
                    <Modal
                        showModal={this.state.showModal && !this.state.isEditing}
                        modalTitle={"Información de Receta"}
                        cancel={() => this.setState({ showModal: false })}
                    >
                        <div className="has-text-justified">
                            La recetas son los productos que se venden tanto en puntos de venta como en la aplicación móvil, están compuestas de
                            artículos y toppings.
                        <br /> A partir de la configuración se generarán todas las combinaciones de la receta.
                            <hr />
                            <section className="accordions">
                                <article className={`accordion is-info ${this.state.show_info ? "is-active" : ""}`}
                                    onClick={() => this.setState({ show_info: !this.state.show_info, show_info_equivalencias: false, show_info_clasificacion: false, show_info_end: false })}>
                                    <div className="accordion-header toggle">
                                        <p>1. Configuración</p>
                                    </div>
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            <p className="has-text-justified">
                                                En la sección de configuración, se define la familia,
                                                tipo receta, tipo sabor, tipo grano, tipo leche y presentación.
                                                Toda la información es requerida, en caso de no aplicar seleccione la opción Sin tipo.
                                            </p>
                                        </div>
                                    </div>
                                </article>
                                <article className={`accordion is-info ${this.state.show_info_equivalencias ? "is-active" : ""}`}
                                    onClick={() => this.setState({ show_info_equivalencias: !this.state.show_info_equivalencias, show_info: false, show_info_clasificacion: false, show_info_end: false })}>
                                    <div className="accordion-header toggle">
                                        <p>2. Información Complementaria</p>
                                    </div>
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            <p className="has-text-justified">
                                                <strong>Clasificación del SAT</strong>: es la clasificación del producto ante el SAT
                                            </p>
                                            <p className="has-text-justified">
                                                <strong>Código del SAT</strong>: es el código de facturación a la venta del producto.
                                            </p>
                                            <p className="has-text-justified">
                                                <strong>Aplica IVA</strong>: al seleccionar esta opción se considera el IVA en el precio.
                                            </p>
                                            <p className="has-text-justified">
                                                <strong>Aplica en App Móvil</strong>: al seleccionar esta opción la receta estará disponible en la aplicación móvil.
                                            </p>
                                            <p className="has-text-justified">
                                                <strong>Aplica Regalo Bienvenida</strong>: al seleccionar esta opción la receta se configura como regalo de bienvenida.
                                            </p>

                                        </div>
                                    </div>
                                </article>
                                <article className={`accordion is-info ${this.state.show_info_clasificacion ? "is-active" : ""}`}
                                    onClick={() => this.setState({ show_info_clasificacion: !this.state.show_info_clasificacion, show_info: false, show_info_end: false, show_info_equivalencias: false })}>
                                    <div className="accordion-header toggle">
                                        <p>3. Artículos y Toppings</p>
                                    </div>
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            <p className="has-text-justified">
                                                En esta sección debe seleccionar los artículos y toppings que
                                                aplicarán para todas las combinaciones de receta generadas.
                                            </p>
                                        </div>
                                    </div>
                                </article>
                                <article className={`accordion is-info ${this.state.show_info_end ? "is-active" : ""}`} onClick={() => this.setState({ show_info_end: !this.state.show_info_end, show_info: false, show_info_clasificacion: false, show_info_equivalencias: false })}>
                                    <div className="accordion-header toggle">
                                        <p>4. Recetas </p>
                                    </div>
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            Configure los artículos y toppings que aplican por tamaño, tipo receta, sabor, grano, tipo de leche.
                                        </div>
                                    </div>
                                </article>
                            </section>
                        </div>
                    </Modal>

                    :
                    <Modal
                        showModal={this.state.showModal && this.state.isEditing}
                        modalTitle={"Información de Receta"}
                        cancel={() => this.setState({ showModal: false })}
                    >
                        <div className="has-text-justified">
                            La recetas son los productos que se venden tanto en puntos de venta como en la aplicación móvil, están compuestas de
                            artículos y toppings.
                <br /> A partir de la configuración se generarán todas las combinaciones de la receta.
                    <hr />
                            <section className="accordions">
                                <article className={`accordion is-info ${this.state.show_info ? "is-active" : ""}`}
                                    onClick={() => this.setState({ show_info: !this.state.show_info, show_info_equivalencias: false, show_info_clasificacion: false, show_info_end: false })}>
                                    <div className="accordion-header toggle">
                                        <p>1. Configuración</p>
                                    </div>
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            <p className="has-text-justified">
                                                En la sección de configuración, se define la familia,
                                                tipo receta, tipo sabor, tipo grano, tipo leche y presentación.
                                                Toda la información es requerida, en caso de no aplicar seleccione la opción Sin tipo.
                                    </p>
                                        </div>
                                    </div>
                                </article>
                                <article className={`accordion is-info ${this.state.show_info_equivalencias ? "is-active" : ""}`}
                                    onClick={() => this.setState({ show_info_equivalencias: !this.state.show_info_equivalencias, show_info: false, show_info_clasificacion: false, show_info_end: false })}>
                                    <div className="accordion-header toggle">
                                        <p>2. Información Complementaria</p>
                                    </div>
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            <p className="has-text-justified">
                                                <strong>Clasificación del SAT</strong>: es la clasificación del producto ante el SAT
                                    </p>
                                            <p className="has-text-justified">
                                                <strong>Código del SAT</strong>: es el código de facturación a la venta del producto.
                                    </p>
                                            <p className="has-text-justified">
                                                <strong>Aplica IVA</strong>: al seleccionar esta opción se considera el IVA en el precio.
                                    </p>
                                            <p className="has-text-justified">
                                                <strong>Aplica en App Móvil</strong>: al seleccionar esta opción la receta estará disponible en la aplicación móvil.
                                    </p>
                                            <p className="has-text-justified">
                                                <strong>Aplica Regalo Bienvenida</strong>: al seleccionar esta opción la receta se configura como regalo de bienvenida.
                                    </p>

                                        </div>
                                    </div>
                                </article>
                                <article className={`accordion is-info ${this.state.show_info_clasificacion ? "is-active" : ""}`}
                                    onClick={() => this.setState({ show_info_clasificacion: !this.state.show_info_clasificacion, show_info: false, show_info_end: false, show_info_equivalencias: false })}>
                                    <div className="accordion-header toggle">
                                        <p>3. Artículos y Toppings</p>
                                    </div>
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            <p className="has-text-justified">
                                                En esta sección debe seleccionar los artículos y toppings que
                                                aplicarán para todas las combinaciones de receta generadas.
                                    </p>
                                        </div>
                                    </div>
                                </article>

                            </section>
                        </div>
                    </Modal>
                }

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
            </div>
        );
    }
}

export default NewRecipeMulti;
