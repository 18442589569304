import React from 'react';

export default function AgendarHeader({notification, setAgendando, setProbando}){
    return (
        <div className="columns is-multiline">
            <div className="column is-1">
                <p className="control">
                    <button className={"button tooltip"} disabled={!notification.Status} data-tooltip={'Agendar la notificación'} onClick={(e) => {
                            e.stopPropagation();
                            setAgendando(true);
                        }}>
                            <span class="icon">
                                <i class="fa fa-plus"></i>
                            </span>
                            <span>Agendar</span>
                    </button>
                </p>
            </div>
            <div className="column is-10">
                <h1 className="title is-4">Eventos agendados</h1>
            </div>
            <div className="column is-1 has-text-right">
                <div className="field is-grouped is-grouped-right">
                <p className="control">
                    <button className={"button tooltip"} data-tooltip={'Mandar notificación de prueba'} onClick={(e) => {
                            e.stopPropagation();
                            setProbando(true);
                        }}>
                            <span class="icon">
                                <i class="fa fa-paper-plane"></i>
                            </span>
                            <span>Prueba</span>
                    </button>
                </p>
                </div>
            </div>
        </div>
    )
}