import React, { Component } from 'react';
import MenuDriveAPI from '../../services/MenuDrive';
import {toast} from 'react-toastify';
import './MenusDrive.css';
import TableTemplate from '../util/tabletemplate/TableTemplate';
import Modal from '../util/modal/Modal';
import SearchForm  from "../util/searchform/SearchForm";
import NoItems  from "../util/noitems/NoItems";
import AcceptModal from '../util/acceptModal/AcceptModal';
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading';
import MenuForm from './MenuForm';
import BtnModoGuiado from '../util/BtnModoGuiado/BtnModoGuiado'
import Select from 'react-select';
import ErrorMessages from '../../util/validation-error-messages';
import CaffenioDriveAPI from '../../services/caffenioDrive';
import Field from '../util/field/Field';
import lodash from 'lodash';
import RecipesAPI from '../../services/Recipe';
import MenuFilters from './MenuFilters';
import EmpresasAPI from '../../services/CaffenioEmpresas/Empresas';

class MenusDrive extends Component {
    menus = [];

    estadoAsignacion = {
        Todos: 1,
        ConDrive: 2,
        SinDrive: 3
    }

    constructor(props){
        super(props);
        this.state = {
            menus: [], // Lista de menus que utiliza el paginador y el filtro de texto.
            pageMenus: [], // Lista de menus de una pagina
            menusFilter: [], // Lista de menus filtrados utilizando los dropdowns
            filterText: "",
            filterSegmento: null,
            filterActivo: true,
            listaSegmentos: [],
            menu: null,
            showModal: false,
            removeModal: false,
            isLoading: false,
            checkAll: false,//para seleccionar todos los de una pagina (1er checkBox)
            activateMasive: false,//Activar o desactivar masivo (Apartado que se muestra o no dependiendo del estado)
            switchAll:false,//switch para activar o desactivar
            seeSwitch: false,//variable par mostrar switch
            syncModal: false,//modal que muestra mensaje de sincronizacion de un drice
            syncAllModal: false,//modal que muestra mensaje de sincronizacion de varios drives
            menuDrivesModal: false, //modal que muestra que drives tienen el menu seleccionado
            menuDrivesModalBody: "", //Cuerpo del modal de drives
            menuDrivesModalTitle: "", //Titulo del modal de drives
            selectedMenus: [],
            
            //Estados para los filtros
            selectedAsignacion: this.estadoAsignacion.Todos,
            caffenios: [],
            optionsEstado: [],
            selectedEstado: [],
            optionsCiudad: [],
            selectedCiudad: [],
            optionsDrive: [],
            selectedDrive: [],
            //Ocultar/Mostrar filtros
            showPVFilters: false,
            showRecipeFilters: false,
            showModalInfoManu:false,
            modalRecipesChange:false,
            //filtros de receta
            //filtros receta
            selectedRecipeTypesList: [],
            selectedPresentationsList: [],
            selectedMilksList: [],
            selectedGrainsList: [],
            selectedFlavorsList: [],
            selectedClassificationList: [],
            //actualizar masivo
            recipes: [],
            filterRecipes: [],
        };
    }

    /**
     * Función que se ejecuta al iniciar el componente
     */
    componentDidMount(){
        this.setState({isLoading: true});
        MenuDriveAPI.getMenusWithSegmentation().then(response => {
            //Paso para quitar duplicados por menus que traigan varios segmentos
            let menusFiltrados = response.data.filter((value, index, array) =>
            array.findIndex(value2 => (value2.MenuId === value.MenuId)) === index)

            //Mapaemaos los datos para agregar un estado de checado
            var info = menusFiltrados.map((item, index) => {
                return { 
                    MenuId: item.MenuId,
                    Description: item.Description,
                    DateUpdate: item.DateUpdate,
                    Active: item.Active,
                    IdSegmento: item.IdSegmento, //Se agregó el segmento para validación 
                    statusCheck:false//agregamos un estado para cada fila de si ha sido seleccionado no
                };
            });

            this.setState({menus: info, menusFilter: info});
            this.menus = info;
            
            return CaffenioDriveAPI.getCaffenios();
        })
        .then(responseCaffenio => {
            let state = {...this.state}
            state.Caffenios = responseCaffenio.data;
            state.Caffenios.map(producto => {
                producto.statusCheck = false;
                return producto
            })

            state.optionsEstado = this.select_state(state.Caffenios);
            state.optionsCiudad = this.select_city(state.Caffenios);
            state.optionsDrive = this.select_Drive(state.Caffenios);
            this.setState(state);
            return RecipesAPI.getAll();
        })
        .then(response =>{
            if (response.data) {
                this.setState({ recipes: response.data })
            }
            return EmpresasAPI.getAllSegments()
        })
        .then(response => {
            this.setState({ listaSegmentos: response.data, isLoading: false })
        })
        .catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        });
    }

    //#region Configuracion de dropdowns
    /**
     * Determina los estados basandose en la lista de caffenios dada
     * @param Caffenios List<Object>. Lista de caffenios
     * @returns Una lista de estados
     */
    select_state(Caffenios) { // Llenar el combo 'Estados' con todas las opciones 
        var newArray = [];
        let OptionSelect = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelect.push({value:item.StateId,label:item.State});
            return item
        });

        for(var i in OptionSelect) {
            lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        
        return newArray;
    }

    /**
     * Determina las ciudades basandose en la lista de caffenios dada
     * @param Caffenios List<Object>. Lista de caffenios
     * @returns Una lista de ciudades
     */
    select_city(Caffenios) { // Llenar el combo 'Ciudades' con todas las opciones 
        var newArray = [];
        let OptionSelectCity = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelectCity.push({value:item.Municipalityid,label:item.Municipality});
            return item
        });

        for(var i in OptionSelectCity) {
            lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    /**
     * Determina los drives basandose en la lista de caffenios dada
     * @param Caffenios List<Object>. Lista de caffenios
     * @returns Una lista de drives
     */
    select_Drive(Caffenios) { // Llenar el combo 'Drives' con todas las opciones 
        var newArray = [];
        let OptionSelectDrive = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelectDrive.push({value:item.idCaffenio,label:item.NombreNuevo});
            return item
        });

        for(var i in OptionSelectDrive) {
            lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    eliminarObjDuplicados = (arr,prop) => {
        var nuevoArray = [];
        var lookup  = {};
    
        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }
    
        for (i in lookup) {
            nuevoArray.push(lookup[i]);
        }
        
        return nuevoArray;
    }

    /**
     * Función que se ejecuta al seleccionar un estado en el dropdown
     * @param obj Objeto que devuelve la propiedad onChange del componente Select
     */
    changeSelectEstado = (obj) => {
        let state = {...this.state}
        state["selectedEstado"] = obj;
        let idEstado = obj.map(estado => {return estado.value});
        let objCaffenios = [...state.Caffenios];
        let listaCiudades = [];
        let listaDrives = [];

        // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
        objCaffenios.map(item =>{
            for(var i=0; i<idEstado.length; i++){
                if(item.StateId===idEstado[i]){
                    listaCiudades.push({"value":item.Municipalityid,"label":item.Municipality});
                }
            }
            return item
        })

        //Se eliminan las ciudades duplicadas
        let eliminarDuplicados = this.eliminarObjDuplicados(listaCiudades,"value");
        //Se obtiene un arreglo con los id de las ciudades
        let idCiudad = eliminarDuplicados.map(ciudad => {return ciudad.value});

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item =>{
            for(var i=0; i<idCiudad.length; i++){
                if(item.Municipalityid===idCiudad[i]){
                    listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
                }
            }

        })

        //Se limpian los combos 'Ciudades' y 'Drives'
        state["selectedCiudad"] = [];
        state["selectedDrive"] = [];

        //Se valida que haya una opción seleccionada en combo 'Estados'
        if(obj.length===0){
            state["optionsCiudad"] = this.select_city(state.Caffenios);
            state["optionsDrive"] = this.select_Drive(state.Caffenios);
            
        } else{
            state["optionsCiudad"] = eliminarDuplicados;
            state["optionsDrive"] = listaDrives;
        }

        this.setState(state, () => this.FilterProcess());
    }

    changeSelectCiudad = (obj) =>{
        let state = {...this.state}
        let idCiudad = obj.map(ciudad => {return ciudad.value});
        let objCaffenios = [...state.Caffenios];
        let listaDrives = [];

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item =>{
            for(var i=0; i<idCiudad.length; i++){
                if(item.Municipalityid===idCiudad[i]){
                    listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
                }
            }
        })

        //Se limpia el combo 'Drives'
        state["selectedDrive"] = [];

        //Se valida que haya una opción seleccionada en combo 'Ciudades'
        if(obj.length>0){
            state["selectedCiudad"] = obj;
            state["optionsDrive"] = listaDrives;
            this.setState(state, () => this.FilterProcess());
        } else{
            this.changeSelectEstado(state["selectedEstado"]);
        }
    }

    changeSelectDrive = (obj) =>{
        let state = {...this.state};
        let Caffenios = [...state.Caffenios];
        
        //Valida cada select (Estados,Ciudades,Drives) y realiza la acción correspondiente
        if(obj.length>0){
            state["selectedDrive"] = obj;
            //state["optionsDrive"] = obj;
            this.setState(state, () => this.FilterProcess());
        } else if(state["selectedCiudad"].length===0){
            this.changeSelectEstado(state["selectedEstado"]);
        } else if(state["selectedCiudad"].length===0 && state["selectedEstado"].length===0){
            this.select_Drive(Caffenios);
            this.select_Drive(Caffenios);
            this.select_Drive(Caffenios);
        } else{
            this.changeSelectCiudad(state["selectedCiudad"]);
        }
    }

    FilterProcess = () => {
        let state = {...this.state}
        state.selectedMenus = []
        state.checkAll = false
        let Caffenios = state.Caffenios;
        let driveIds;

        switch (this.state.selectedAsignacion) {
            case this.estadoAsignacion.SinDrive:
                this.setState(state, () => {
                    if(this.state.filterText.trim !== "")
                        this.handleSearchInput(this.state.filterText)
                })    
                break;

            case this.estadoAsignacion.Todos:
                if(state["selectedEstado"].length == 0 && state["selectedCiudad"].length == 0 && state["selectedDrive"].length == 0){
                    state["menusFilter"] = this.menus
                    this.setState(state, () => {
                        if(this.state.filterText.trim !== "")
                            this.handleSearchInput(this.state.filterText)
                    })
                    break;
                }
        
            case this.estadoAsignacion.ConDrive:
                if(state["selectedDrive"].length===0){
                    driveIds = state["optionsDrive"].map(obj => {
                        return obj.value;
                    })
                } else{
                    driveIds = state["selectedDrive"].map(obj => {
                        return obj.value;
                    })
                }
        
                this.setState(state, () => {
                    MenuDriveAPI.getMenusByIdDrives(driveIds).then(response => {
                        if(response.data){
                            this.setState({menusFilter: response.data}, () => {
                                if(this.state.filterText.trim !== "")
                                    this.handleSearchInput(this.state.filterText)
                            })
                        }
                    })
                });
                break;
        }
        
    }
    //#endregion

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        // let menuSource = this.state.selectedAsignacion === this.estadoAsignacion.Todos ? [...this.state.menus] : [...this.state.menusFilter]
        let menus = this.state.menusFilter.filter(menu => menu.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        //Se agregan los nuevos filtros para que se considere el segmento y el activo junto con el nombre
        if (this.state.filterSegmento !== null && this.state.filterSegmento !== undefined) {
            menus = menus.filter(menu => menu.IdSegmento === this.state.filterSegmento.SegmentId)
        }
        if (this.state.filterActivo !== null && this.state.filterActivo !== undefined) {
            menus = menus.filter(menu => menu.Active === this.state.filterActivo)
        }
        
        let state = this.state;
        state.filterText = text;
        state.menus = menus;
        this.setState(state);
    }
    /**
     * Método para filtrar mediante segmento o activo/inactivo
     */
    handleFilterSegmentoActivo = (activo, segmento) => {
        let state = this.state;
        //Primero se filtra por activo o inactivo
        let menus = this.state.menusFilter.filter(menu => menu.Active === activo)
        //Luego se filtra por el segmento seleccionado
        if (segmento !== null && segmento !== undefined) {
            menus = menus.filter(menu => menu.IdSegmento === segmento.SegmentId)
        }
        //Luego se filtra por el texto SOLO en caso de tener
        if(this.state.filterText !== null && this.state.filterText !== undefined){
            menus = menus.filter(menu => menu.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        }
        //Se limpian todos los checks de los menús en caso de tener
        menus.map(element=>{
            element.statusCheck = false 
        })
        //Se pone en false para que se reinicien todos los checks y se vacian los seleccionados
        state.checkAll = false
        state.selectedMenus = []
        state.filterActivo = activo;
        state.filterSegmento = segmento
        state.menus = menus;
        this.setState(state);
    }
    
    create = () => {
        this.props.history.push({pathname:'/menus/nuevo', state: { menus: this.menus } });
    }

    edit = (menu) => {

        menu.Description = menu.Description.trim();
        if(menu.Description.length < 3){ 
            toast.error("Descripción debe de tener más de 2 caracteres");
            return;
        }
        
        this.setState({isLoading: true});
        MenuDriveAPI.put(menu).then(response => {
            let state = {...this.state};
            state.menus[state.menus.findIndex(m => m.MenuId === menu.MenuId)] = menu;
            state.pageMenus[state.pageMenus.findIndex(m => m.MenuId === menu.MenuId)] = menu;
            this.menus[this.menus.findIndex(m => m.MenuId === menu.MenuId)] = menu;
            state.showModal = false;
            state.isLoading = false;
            this.setState(state, () => {
                toast.success("Menú actualizado");
            });
        }).catch(err => {
            this.setState({isLoading: false});
            if(err.response)
                toast.error(err.response.data.Message);
            else
                toast.error(ErrorMessages.SinConexion);
        })
    }

    remove = (menu) => {
        const loadingToast = toast.info("Cargando...", {autoClose: false, closeButton: false, className: "has-background-primary has-text-weight-bold"});
        MenuDriveAPI.delete(menu.MenuId).then(response => {
            let state = {...this.state};
            state.menus.splice(state.menus.findIndex(m => m.MenuId === menu.MenuId),1);
            state.pageMenus.splice(state.pageMenus.findIndex(m => m.MenuId === menu.MenuId),1);
            this.menus.splice(this.menus.findIndex(m => m.MenuId === menu.MenuId),1);
            state.removeModal = false;
            this.setState(state, () => {
                toast.dismiss(loadingToast);
                toast.success("Menú eliminado");
            })
        }).catch(err => {
            console.log(err);
        });
    }

    /*
    * Funcion seleccionar o des seleccionar todas las recetas 
    * de la pagina actual.
    */
    selectAllCheck = () => {
        //console.log('Seleccionar todos por tabla')
        if(this.validarMenuSegmentacion(this.state.pageMenus)){
            return
        }
        
        let state = {...this.state};
        let elementsPage = state.pageMenus
        let indexMenu = 0
        let pageCheck = this.getCurrentPageCheck()
        elementsPage.map(element=>{
            indexMenu = state.selectedMenus.findIndex(menu => menu.MenuId === element.MenuId)
            if(pageCheck){
                element.statusCheck = false
                if(indexMenu !== -1)    state.selectedMenus.splice(indexMenu, 1) 
            }else{
                element.statusCheck = true
                if(indexMenu === -1) state.selectedMenus.push(element)
            }
            return element
        })

        state.checkAll = !pageCheck;
        state.activateMasive = state.checkAll
        // state.pageMenus = elementsPage;
        if(state.checkAll === false){
            state.switchAll=false 
            state.seeSwitch=false
        } 
        
        this.setState(state);
    }

    /*
    * Funcion para saber si hay que mostrar
    * un switch y boton para aplicar cambios a las 
    * recetas que hemos marcado. 
    */
    showView = (index) => {
        let state = {...this.state};
        let cheked = false;
        let checkAllTemp = true;
        let selectedMenus = [...state.selectedMenus]
        // Agregar/Quitar IdMenu del estado selectedMenus
        let indexMenu = selectedMenus.findIndex(menu => menu.MenuId === state.pageMenus[index].MenuId)
        if(indexMenu === -1){
            //Validación de checkbox para no permitir seleccionar menús de diferentes segmentos
            if (state.pageMenus[index].IdSegmento === 2 ||
                state.pageMenus[index].IdSegmento === 3 ||
                state.pageMenus[index].IdSegmento === 8) {
                state.pageMenus[index].IdSegmento = 1
            }
            if(selectedMenus.some(x => x.IdSegmento !== state.pageMenus[index].IdSegmento)){
                state.pageMenus[index].statusCheck = false
                toast.error("Solamente se pueden seleccionar Menús del mismo segmento")
            }else{
                selectedMenus.push(state.pageMenus[index])
            }
        }else{
            selectedMenus.splice(indexMenu, 1)
        }

        state.pageMenus
        .map(item=>{
            if(item.statusCheck){
                cheked = true;
            } else {
                checkAllTemp = false;
            }
        });

        //console.log("StatusCheck => ", state.pageMenus[index].statusCheck)
        //console.log(selectedMenus)

        state.activateMasive = cheked
        state.checkAll = checkAllTemp
        state.selectedMenus = selectedMenus
        this.setState(state);
    }
    

    /** 
     * Funcion que se ejecuta al cambiar de pagina
     * para marcar o des marcar el checkbox de checkAll
     * y que su valor sea por cada pagina.
    */
    changePage = (menus) =>{
        let cheked = false;
        let checkAllTemp = true;
        menus
        .map(item=>{
            if(item.statusCheck){
                cheked = true;
            }
            item.statusCheck = this.state.selectedMenus.findIndex(menu => menu.MenuId === item.MenuId) !== -1
        });

        this.setState({pageMenus: menus, activateMasive: cheked});
        
    }
    /**
     * Funcion para sincronizar las recetas de un drive en especfico
     */
    syncMenu = () =>{
        this.setState({isLoading: true});
        let menus = []; //lista de menus para sincronizar
        menus.push(this.state.menu)
        
        let state = {...this.state};
            
        MenuDriveAPI.postSync(menus).then(response => {
            state.showModal = false;
            state.syncModal = false;
            state.isLoading = false;
            state.activateMasive = false;
            this.setState(state, () => {
                toast.success("Menús sincronizados");
            });
        }).catch(err => {
            state.isLoading = false;
            this.setState(state, () => {
                toast.error("Error al sincronizar menús");
            });
        })
    }

    /**
     * Funcion para sincronizar las recetas de todos los drives
     */
    syncAll = () =>{

        if(this.validarMenuSegmentacion(this.state.selectedMenus)){
            return
        }
        
        this.setState({isLoading: true});
        let state = {...this.state};
        let menusSeleccionadosActivos = this.state.selectedMenus.filter(menu => menu.Active === true)
        MenuDriveAPI.postSync(menusSeleccionadosActivos).then(response => {
            state.showModal = false;
            state.syncAllModal = false;
            state.isLoading = false;
            this.cleanCheckBoxs();
            state.activateMasive = false;
            this.setState(state, () => {
                toast.success("Menús sincronizados");
            });
        }).catch(err => {
            state.isLoading = false;
            this.setState(state, () => {
                toast.error("Error al sincronizar menús");
            });
        })
    }
    /**
     * Funcion encargada de des seleccionar los checkbox
     */
    cleanCheckBoxs = () =>{
        //console.log('test5')
        this.state.menus.map(item=>{
            if(item.statusCheck){
                item.statusCheck = false
            } 
        });
    }

    /**
     * Función que consulta a la API los drives que tienen el menu dado y luego los muestra en una tabla en el modal de drives.
     */
    handleMenuDriveModalBody = (menuId) => {
        MenuDriveAPI.getDrives(menuId)
        .then(result => {
            let tablaDrives = <TableTemplate 
            columns={["idCaffenio", "ShortName", "Active"]}
            columnsNames={["ID", "Descripción", "Drive Activo"]}
            data={result.data}
            noActions={true}
            disableCheck={true}
            />
            this.setState({menuDrivesModalBody:tablaDrives, isLoading: false})
        })
        .catch(error => {
            if(error){
                toast.error(error.response ? error.response.data.Message : "Error al cargar los drives.");
            }
            this.setState({menuDrivesModalBody:"Error al cargar los drives.", isLoading: false})
        })
    }

    /**
     * Función que selecciona todos los resultados de la busqueda
     */
    selectAllResults = () => {
        //console.log('Seleccionar todos los resultados botón')
        let state = {...this.state};
        let selectedMenus = []

        if(this.validarMenuSegmentacion(state.menus)){
            return
        }
        // Le asignamos el valor inverso de checkAll a todas las recetas actuales
        state.menus.map(element=>{
            if(state.checkAll){
                element.statusCheck = false 
            }else{
                element.statusCheck = true
                selectedMenus.push(element)
            }

            return element
        })
        state.checkAll = !state.checkAll;
        state.selectedMenus = selectedMenus
        console.log(selectedMenus)
        this.setState(state);
    }

    /**
     * Función que analiza los registros de la pagina actual para ver si todos estan seleccionados
     * @returns Bool. True si todos estan seleccionados y false en caso contrario.
     */
    getCurrentPageCheck = () => {
        return this.state.pageMenus.findIndex(menu => {
            return !menu.statusCheck
        }) === -1
    }

    /**
     * Función para manejar los radiobuttons para el filtro de asignacion
     */
    handleFiltroAsignacion = (evento) => {
        if(this.state.selectedAsignacion !== Number(evento.currentTarget.value)){
            if(Number(evento.currentTarget.value) === this.estadoAsignacion.SinDrive){
                this.setState({selectedAsignacion: Number(evento.currentTarget.value)}, () => {
                    MenuDriveAPI.getMenusWithoutDrives().then(response => {
                        this.setState({menusFilter: response.data}, this.FilterProcess)
                    })
                })

            }else{
                this.setState({selectedAsignacion: Number(evento.currentTarget.value)}, this.FilterProcess)
            }
        }
    }

    /**Setea al state los valores de los filtros de recetas  */
    handleSelectChange = (filters) => {
    let state = { ...this.state };

    state.selectedClassificationList = filters.selectedClassificationList && filters.selectedClassificationList.length > 0 ? filters.selectedClassificationList : [];
    state.selectedRecipeTypesList = filters.selectedRecipeTypesList && filters.selectedRecipeTypesList.length > 0 ? filters.selectedRecipeTypesList : [];
    state.selectedFlavorsList = filters.selectedFlavorsList && filters.selectedFlavorsList.length > 0 ? filters.selectedFlavorsList : [];
    state.selectedMilksList = filters.selectedMilksList && filters.selectedMilksList.length > 0 ? filters.selectedMilksList : [];
    state.selectedGrainsList = filters.selectedGrainsList && filters.selectedGrainsList.length > 0 ? filters.selectedGrainsList : [];
    state.selectedPresentationsList = filters.selectedPresentationsList && filters.selectedPresentationsList.length > 0 ? filters.selectedPresentationsList : [];

    state.enabledApply = (filters.selectedClassificationList && filters.selectedClassificationList.length > 0) ||
    (filters.selectedRecipeTypesList && filters.selectedRecipeTypesList.length > 0) ||
    (filters.selectedFlavorsList && filters.selectedFlavorsList.length > 0) ||
    (filters.selectedMilksList && filters.selectedMilksList.length > 0 )||
    (filters.selectedGrainsList && filters.selectedGrainsList.length > 0) ||
    (filters.selectedPresentationsList && filters.selectedPresentationsList.length > 0);

    this.setState(state);

    }

    /**
     * Función para actualizar masivamente las recetas activas o inactivas para los menus seleccionados
     */
    updateMasive = () => {
        
        // Ponemos icono de cargando
        this.setState({ isLoading: true }, () => {
            console.log("Menús seleccionados", this.state.selectedMenus);
            let selectedMenus = this.state.selectedMenus.map(s => {s.completed = false; return s});

            selectedMenus.forEach(item => {
                debugger
                let data = this.state.filterRecipes.map(f => {
                    return {
                        MenuId: item.MenuId,
                        RecipeId: f.RecipeId,
                        Active: f.Active
                    }
                });

                MenuDriveAPI.postDetailUpdate(data).then(response => {
                    item.completed = true;
                    if(!selectedMenus.some(s => !s.completed)){
                        this.setState({isLoading:false, modalRecipesChange: false, filterRecipes: [] },
                            ()=>{
                                toast.dismiss();
                                toast.success("Recetas actualizadas. ")
                            });        
                    }
                }).catch(err => {
                    this.setState({isLoading:false, modalRecipesChange: false, filterRecipes: [] });   
                    toast.dismiss()     
                    toast.error("Ocurrió un error al actualizar la Receta");
                });
            });
        });      
    }

    /**
     * Método que valida si se quiere seleccionar un menú con diferente
     * segmentación a los demas
     * @param {*} arregloAux - lista de menús
     * @returns Verdadero en caso de encontrar segmentación diferente, falso en caso contrario
     */
    validarMenuSegmentacion = (arregloAux) =>{
        let filtroMenuAux = []
        for (let index = 0; index < arregloAux.length; index++) {
            const element = arregloAux[index]
            if (element.IdSegmento === 2 ||
                element.IdSegmento === 3 ||
                element.IdSegmento === 8) {
                //console.log(element.IdSegmento)
                element.IdSegmento = 1
            }
            if (filtroMenuAux.some(x => x.IdSegmento !== element.IdSegmento)) {
                toast.error("Solo se pueden sincronizar Menús del mismo segmento")
                return true
            } else {
                filtroMenuAux.push(element)
            }
        }
        return false
    }

    render() {
        let pageCheck = this.getCurrentPageCheck()
        return (
            <div>
                <h1 className="title is-2">
                    Menú
                    <i className="fa-5x is-small" 
                            style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px", fontSize:"22px" }} 
                            className="fa fa-question-circle"
                            onClick={() => this.setState({ showModalInfoManu: !this.state.showModalInfoManu })}
                            ></i>
                </h1>
                
                <div className="columns is-vcentered">

                    <div className="column is-4">
                        <div className="card">
                            <div className="card-content">
                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'Menú'}
                                            placeholderText={"Ingrese nombre de menú"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-6">
                        <div className="card">
                            <div className="card-content">
                                <div className="columns">
                                    <div className="column is-10">
                                        <Field label="Segmento">
                                            <div className="control" style={{ width: "100%" }}>
                                                <Select
                                                    placeholder={"Seleccione un segmento"}
                                                    name={"filterSegmento"}
                                                    value={this.state.filterSegmento}
                                                    onChange={value => {
                                                        this.handleFilterSegmentoActivo(this.state.filterActivo, value)
                                                    }}
                                                    noOptionsMessage={() => "No hay opciones"}
                                                    options={this.state.listaSegmentos}
                                                    getOptionLabel={(segmento) => segmento.Name}
                                                    getOptionValue={(segmento) => segmento.SegmentId}
                                                />
                                            </div>
                                        </Field>
                                    </div>
                                    <div className="column is-2">
                                        <Field label="Activo">
                                            <input
                                                id="chkSearchSwitch"
                                                type="checkbox"
                                                name="filterActivo"
                                                className="switch inputCenter"
                                                value={this.state.filterActivo}
                                                checked={this.state.filterActivo}
                                            />
                                            <label
                                                htmlFor="chkSearchSwitch"
                                                className="inputCenter"
                                                onClick={(e) => {
                                                    this.handleFilterSegmentoActivo(!this.state.filterActivo, this.state.filterSegmento)
                                                }}
                                            />
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="column is-2">
                        <div className="field">
                            <div className="control has-text-right">
                                <a className="button is-success" onClick={(e) => this.create()}>
                                    <span className="icon is-small">
                                        <i className="fa fa-plus"></i>
                                    </span>
                                    <span>Agregar Menú</span>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>

                {/* Filtros */}
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title">                                                                              
                            Filtros de punto de venta
                            <div className="tooltip" data-tooltip={"Filtra menús por punto de venta, y estatus de asignación."} >
                                <i className="fa-5x"
                                    style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }}
                                    className="fa fa-question-circle"
                                    onClick={() => this.setState({ showPVFilters: !this.state.showPVFilters })}
                                ></i>
                            </div>
                        </div>
                        <div className="card-header-icon">
                            <span className="icon" id={"btnOpenPvFilters"} onClick={() => this.setState({ showPVFilters: !this.state.showPVFilters })}>
                                <i className="fa fa-angle-down"></i>
                            </span>
                        </div>
                    </div>
                    <div className={`card-content ${!this.state.showPVFilters ? "is-hidden" : ""} `}>
                        <div className="columns is-multiline">
                            {/* Activo */}
                            <div className="column is-12">
                                <label className="label">Estatus Asignación</label>
                                <div className="control" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="drives"
                                            value={this.estadoAsignacion.Todos}
                                            checked={this.state.selectedAsignacion === this.estadoAsignacion.Todos}
                                            onClick={this.handleFiltroAsignacion}
                                        />
                                        &nbsp;Todos
                                    </label>
                                    
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="drives"
                                            value={this.estadoAsignacion.ConDrive}
                                            checked={this.state.selectedAsignacion === this.estadoAsignacion.ConDrive}
                                            onClick={this.handleFiltroAsignacion}
                                        />
                                        &nbsp;Con Drive
                                    </label>

                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="drives"
                                            value={this.estadoAsignacion.SinDrive}
                                            checked={this.state.selectedAsignacion === this.estadoAsignacion.SinDrive}
                                            onClick={this.handleFiltroAsignacion}
                                        />
                                        &nbsp;Sin Drive
                                    </label>
                                </div>
                            </div>
                            
                            {this.state.selectedAsignacion !== this.estadoAsignacion.SinDrive && <React.Fragment>
                            {/* Estados */}
                            <div className="column is-4">
                                <label className="label">Estados</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder="Seleccionar..."
                                        options={lodash.sortBy(this.state.optionsEstado, [ts => ts.label.toLowerCase()], ['asc'])}
                                        name="label"
                                        noOptionsMessage={() => "No hay opciones"}
                                        value={this.state.selectedEstado}
                                        onChange={this.changeSelectEstado}
                                    />
                                </div>
                            </div>

                            {/* Ciudades */}
                            <div className="column is-4">
                                <label className="label">Ciudades</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder="Seleccionar..."
                                        options={lodash.sortBy(this.state.optionsCiudad, [ts => ts.label.toLowerCase()], ['asc'])}
                                        name="label"
                                        noOptionsMessage={() => "No hay opciones"}
                                        value={this.state.selectedCiudad}
                                        onChange={this.changeSelectCiudad}
                                    />
                                </div>
                            </div>

                            {/* Drives */}
                            <div className="column is-4">
                                <label className="label">Drives</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder="Seleccionar..."
                                        options={lodash.sortBy(this.state.optionsDrive, [ts => ts.label.toLowerCase()], ['asc'])}
                                        name="label"
                                        noOptionsMessage={() => "No hay opciones"}
                                        value={this.state.selectedDrive}
                                        onChange={this.changeSelectDrive}
                                    />
                                </div>
                            </div>
                        
                            </React.Fragment>}
                        </div>
                    </div>
                </div>
                <br/>
                {this.state.menus.some(i => i.statusCheck) &&
                    <div className="card">
                        <div className="card-header">
                        <div className="card-header-title">
                            Filtros de receta
                                <div className="tooltip" data-tooltip={"Activa o inactiva recetas masivamente."} >
                                <i className="fa-5x"
                                    style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }}
                                    className="fa fa-question-circle"
                                    onClick={() => this.setState({ showRecipeFilters: !this.state.showRecipeFilters })}
                                ></i>
                            </div>
                        </div>
                            <div className="card-header-icon">
                                <span className="icon" id={"btnOpenRecipeFilters"} onClick={() => this.setState({ showRecipeFilters: !this.state.showRecipeFilters })}>
                                    <i className="fa fa-angle-down"></i>
                                </span>
                            </div>
                        </div>
                        <div className={`card-content ${this.state.showRecipeFilters ? "is-hidden" : ""} `}>
                            <div className="columns is-multiline">
                                <MenuFilters
                                    onChange={(objFilters) => { this.handleSelectChange(objFilters) }}
                                    menuAux={this.state.selectedMenus}
                                />

                                {this.state.enabledApply &&
                                    <div className="column is-12">
                                        <div className="columns">
                                            <div className="column is-4">
                                            </div>
                                            <div className="column is-4">
                                                
                                            </div>
                                            <div className="column is-4">
                                                <Field label='&nbsp;' >
                                                    <a className="button is-success is-fullwidth" onClick={() => {
                                                        let filterRecipes = this.state.recipes.filter(r => 
                                                            (this.state.selectedClassificationList.length === 0 || this.state.selectedClassificationList.includes(r.ClassificationId))
                                                            && (this.state.selectedRecipeTypesList.length === 0 || this.state.selectedRecipeTypesList.includes(r.RecipeTypeId))
                                                            && (this.state.selectedFlavorsList.length === 0 || this.state.selectedFlavorsList.includes(r.FlavorTypeId))
                                                            && (this.state.selectedMilksList.length === 0 || this.state.selectedMilksList.includes(r.MilkTypeId))
                                                            && (this.state.selectedGrainsList.length === 0 || this.state.selectedGrainsList.includes(r.GrainTypeId))
                                                            && (this.state.selectedPresentationsList.length === 0 || this.state.selectedPresentationsList.includes(r.PresentationId))
                                                        );
                                                        
                                                        if(filterRecipes.length > 0){
                                                            filterRecipes.map(f => {f.Active = false; return f;});
                                                            this.setState({ modalRecipesChange: true, filterRecipes: filterRecipes })
                                                        }else{
                                                            toast.dismiss();
                                                            toast.error("No hay recetas que aplique a los criterios seleccionados.")
                                                        }
                                                    }}>
                                                        <span className="icon is-small">
                                                            <i className="fa fa-eye"></i>
                                                        </span>
                                                        <span>Ver recetas</span>
                                                    </a>
                                                </Field>
                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                }

                <br/>
                <div className="card">
                    <div className="card-content">
                        <div className="columns">
                            {/* Todos los resultados */}
                            <div className="column" style={{display: "flex"}}>
                                <a className="button is-link" onClick={this.selectAllResults}>
                                    <span className="icon is-small">
                                        <i className={!this.state.checkAll ? "fa fa-check-square" : "fa fa-square"}></i>
                                    </span>
                                    <span>{!this.state.checkAll ? "Seleccionar todos los resultados" : "Deseleccionar todos los resultados"}</span>
                                </a>
                            </div>

                            {this.state.selectedMenus.length > 0 &&
                                <div className="column" style={{display: "flex", justifyContent: "end", flexWrap: "wrap"}}>
                                    {/* Sincronizar seleccionados */}
                                    <button 
                                        disabled={this.state.filterSegmento === null}
                                        className="button is-success" 
                                        data-tooltip="Sincronizar menús seleccionados"
                                        onClick={(e) => this.setState({syncAllModal: true})}>
                                        <span className="icon is-small">
                                            <i className="fa fa-refresh"></i>
                                        </span>
                                        <span>Sincronizar seleccionados</span>
                                    </button>
                                </div>
                            }
                        </div>

                        {this.state.menus.length !== 0 
                        ? <TableTemplate
                            columns={["MenuId","Description","Active"]}
                            columnsNames={["ID", "Nombre","Activo"]}
                            disableCheck={true}
                            data={this.state.pageMenus}
                            canEdit
                            canSync//boton de sincronizar
                            canDelete={false} 
                            edit={(menu) => this.setState({showModal: true, menu: menu})} 
                            remove={(menu) => this.setState({removeModal: true, menu: menu})}
                            rowClickable={true}
                            details={(menu) => this.props.history.push(`/menus/${menu.MenuId}/detalle`)}
                            checkbox = {true}//para mostar los checkBoxs en la tabla
                            selectAllCheck={this.selectAllCheck}
                            checkAll= {pageCheck}
                            showView={this.showView}
                            sync={(menu) => this.setState({syncModal: true, menu: menu})}//modal para sincronizar
                            extraButtons={(row) => {
                                // Creamos un botón que lanza un modal con información de que menus esta la receta seleccionada
                                return <button className="button tableAction is-info tooltip" data-tooltip="Consultar drives"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                        menuDrivesModal: true,
                                        isLoading: true,
                                        menuDrivesModalBody: "Cargando drives que contienen '" + row.Description + "'...",
                                        menuDrivesModalTitle: "Drives con el menú '" + row.Description + "'",
                                    }, this.handleMenuDriveModalBody(row.MenuId))
                                }}
                                >
                                            <i className="fa fa-info"></i>
                                        </button>
                            }}
                        /> 
                        : <NoItems itemName="menús"/>}
                        <Pagination items={this.state.menus} onChangePage={(menus) => this.changePage(menus)} />
                    </div>
                </div>
                {this.state.showModal && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Menú"}
                        cancel={() => this.setState({showModal: false, menu: null})}
                    >
                        <MenuForm
                            menu={this.state.menu}
                            save={this.edit}
                            menus={this.menus}
                        />   
                    </Modal>
                }
                {/*Modal para sincronizar un menu en especifico*/}
                {this.state.syncModal && <AcceptModal 
                    isActive={this.state.syncModal}
                    done={() => this.syncMenu()}
                    cancel={() => this.setState({syncModal: false})}
                    modalTitle={"¿Sincronizar " + this.state.menu.Description + "?"}
                />}
                {/*Modal para sincronizar multiples menus */}
                {this.state.syncAllModal && <AcceptModal 
                    isActive={this.state.syncAllModal}
                    done={() => this.syncAll()}
                    cancel={() => this.setState({syncAllModal: false})}
                    modalTitle={"¿Sincronizar todos los menús seleccionados?"}
                />}

                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove(this.state.menu)}
                    cancel={() => this.setState({removeModal: false, menu: null})}
                    modalTitle={"Eliminar Menú"}
                />}

                {/* Modal para consultar drives */}
                {this.state.menuDrivesModal && <Modal 
                    showModal={this.state.menuDrivesModal}
                    cancel={() => this.setState({menuDrivesModal: false})}
                    modalTitle={this.state.menuDrivesModalTitle}
                >
                    {this.state.menuDrivesModalBody}
                </Modal>}
                {/* Modal para visualizar la información del módulo */}
                {this.state.showModalInfoManu &&
                    <Modal
                        showModal={this.state.showModalInfoManu}
                        modalTitle={"Información de Menú"}
                        cancel={() => this.setState({ showModalInfoManu: false })}
                    >
                        <div className="has-text-justified">
                        Cada Menú esta conformado por un conjunto de recetas, su función es definir los productos que estarán disponibles en el punto de venta que los tenga asignado.

                            <br/>
                            <br/>
                        Los menús son asignados a los puntos de venta, un Drive solo puede tener un menú asignado.
                            <br/>
                            <br/>
                        <strong>En el módulo se podrá realizar: </strong>
                            <ol type="1">
                                <li>Búsquedas ingresando textualmente el Nombre de un Menú o filtrando por punto de venta.</li>
                                <li>Activar o Desactivar recetas masivamente.
                                <div className="message is-info">
                                    <div className="message-body">
                                        <strong>NOTA IMPORTANTE: </strong>
                                        Debe seleccionar al menos un menú, para visualizar los filtros de recetas.
                                    </div>
                                
                                </div>

                                </li>
                           </ol> 
                        </div>
                       

                    </Modal>
                }
                {/* Modal para visualizar recetas que serán activadas o desactivadas masivamente */}
                {this.state.modalRecipesChange &&
                    <AcceptModal
                        isActive={this.state.modalRecipesChange}
                        done={() => this.updateMasive()}
                        modalTitle={`Confirmar recetas a actualizar`}
                        cancel={() => this.setState({ modalRecipesChange: false, filterRecipes: [] })}
                        modalBody={                            
                            <div>
                                <div className="notification is-warning">
                                Asegurarse de dejar en el estatus correcto <strong> todas las recetas</strong>, ya que al aceptar los cambios las recetas
                                se desactivarán o activarán en los menús seleccionados.
                                </div>
                                <TableTemplate
                                    columns={["RecipeId", "Description", "Active"]}
                                    columnsNames={["ID", "Nombre", "Estatus"]}
                                    disableCheck={true}
                                    noActions={true}
                                    disableCheck={false}
                                    changeStatus={(row) => {
                                        row.Active = !row.Active;
                                        let state = {...this.state}
                                        let index = state.filterRecipes.indexOf(row);
                                        state.filterRecipes[index].Active = row.Active;
                                        this.setState(state);
                                        
                                    }}                                    
                                    data={this.state.pageRecipesList}
                                />
                                <Pagination items={this.state.filterRecipes} onChangePage={(recipes) => this.setState({ pageRecipesList: recipes })} />
                            </div>

                        }
                    />
                }
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
                <BtnModoGuiado />                
            </div>
        );
    }

}

export default MenusDrive;