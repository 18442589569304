import React, { Component } from 'react';
import { toast } from 'react-toastify';
import TextField from '../util/textfield/TextField';
import Field from '../util/field/Field';
import Loading from '../util/loading/Loading';
import ErrorMessages from '../../util/validation-error-messages';
import ExpresionesRegulares from '../../util/expresiones-regulares';
import { Formik } from 'formik';
import * as Yup from 'yup';
import update from 'immutability-helper';
import  TableTemplate  from "../util/tabletemplate/TableTemplate";
import Modal from '../util/modal/Modal';
import AcceptModal from '../util/acceptModal/AcceptModal';
import  NoItems  from "../util/noitems/NoItems";
import Pagination from '../util/pagination/Pagination';
import DrivesComunicado from './DrivesComunicado';
import DatePicker, { registerLocale } from "react-datepicker/dist/es"
import { format, subDays} from 'date-fns'
import es from "date-fns/locale/es";
import Select from 'react-select';
import ComunicadosVPAPI from '../../services/ComunicadosVP';
registerLocale("es", es);

const SUPPORTED_FORMATS = [
    "image/png",
    "image/jpeg"
];

class ComunicadoForm extends Component {

    styleDriveMax = {
        "backgroundColor": "rgb(255, 221, 87)"
    }

    stylePreviewImage = {
        "width" : "100%",
        "height" : "70vh" 
    }

    constructor(props) {
        super(props);
        this.state = {
            comunicado: { ...this.props.comunicado },

            isLoading: false,

            pageDrives: [],
            showModalEditDrives: false,
            showModalRemoveDrive: false,
            driveToRemove: {},
            positionOptions: ComunicadosVPAPI.GetPosiciones(),

            showModalDrive: false,
            showModalRemoveComunicado: false,
            comunicadoToEdit: {},
            messageRemove: "¿Está seguro que desea eliminar el comunicado?"
        }
    }

    componentDidMount() {
        // console.log("Proyeccion en form >> ", this.props);
        // console.log("state >> ", this.state);
    }

    /**
     * Función que se ejecuta para mostrar el modal para agregar o eliminar drives a incluir
     *  @param e event
     */
    toggleEditDrivesModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let newState = update(this.state, {
            showModalEditDrives: {$set: true},
            showModalDrive: {$set: true}
        });
        this.setState(newState);
    }

    /**
     * Funcion que muestra el modal de confirmación para remover una receta de la lista de recetas a excluir
     * @param drive drive que se quiere eliminar de la lista
     */
     toggleModalRemoveDrive = (drive) => {
        let newState = update(this.state, {
            showModalRemoveDrive: {$set: true},
            driveToRemove: {$set: drive}
        })
        this.setState(newState);
    }

    /**
    * Funcion que elimina un elemento de la lista de drives a incluir
    */
    removeDriveFromList = () => {
        let state = {...this.state};
        let _drives = this.state.comunicado.Drives.filter( d => {
            return d.idCaffenio !== this.state.driveToRemove.idCaffenio
        });

        state.comunicado.Drives = _drives;
        state.showModalRemoveDrive = false;
        this.setState(state, () => {
            toast.success(`Drive y/o Kiosko eliminado con éxito.`);
        });
    }

    /**
     * Funcion que cierra el modal de confirmación para eliminar un drive de la lista
     */
     closeModalRemoveDrive = () => {
        let newState = update(this.state, {
            showModalRemoveDrive: {$set: false},
        })
        this.setState(newState);
    }

    /**
     * Funcion que actualiza los elementos que se muestran en la paginación de los drives a incluir
     * @param drives arreglo con drives de la página actual
     */
     changeDrivePage = (drives) => {
        this.setState({pageDrives: [...drives]});
    }

    /**
     * Función que se ejecuta para mostrar el modal para agregar/eliminar drives de la
     * lista de drives a incluir
     */
     cancelDrivesModal = () => {
        let newState = update(this.state, {
            showModalDrive: {$set: false},
            showModalEditDrives: {$set: false},
        });
        this.setState(newState);
    }

    handleDateChange = (date, attr) => {
        let state = { ...this.state };
        state.comunicado[attr] = date;
        this.setState(state);
    }

    changeSelectPosicion = (obj) => {
        let state = {...this.state}

        // console.log("Las opciones >> ", state.optionState);
        // console.log("El estado seleccionado >> ", state.selectedOption);
        // console.log("El objeto changeSelectEstado >> ", obj);

        state.comunicado.Posicion = obj;
        this.setState(state);
    }

    /**
     * Funcion que guarda los drives que se quieren incluir
     * @param checkedDrives drives que fueron seleccionadas
     */

    saveDrives = (checkedDrives) => {
        let state = {...this.state};
        state.showModalDrive = false;
        state.showModalEditDrives = false;
        state.comunicado.Drives = checkedDrives;
        this.setState(state, () => {
            toast.success(`Los drives y/o kioskos se actualizaron`);
        });
        this.validateMaxComunicados();
    }

    validateMaxComunicados = () =>{
        if(this.state.comunicado.FechaInicio && this.state.comunicado.FechaFin){
            this.setState({isLoading:true}, () =>{
                ComunicadosVPAPI.GetMaxComunicadosDrives(this.state.comunicado.FechaInicio, this.state.comunicado.FechaFin, this.state.comunicado.Drives.map(x => x.idCaffenio))
                .then( responseDrives =>{
                    let state = {...this.state};
                    console.log("Response drives >> ", responseDrives);
                    responseDrives.data.forEach( val =>{
                        let idx = state.comunicado.Drives.findIndex( x => x.idCaffenio == val);
                        console.log("Index: ",idx)
                        if(idx >= 0){
                            state.comunicado.Drives[idx].style = this.styleDriveMax;
                        }
                    });
                    if(responseDrives.data.length > 0){
                        state.showModalMessageMaxComunicados = true;
                    }
                    state.isLoading = false;
                    this.setState(state);
                })
                .catch( errDrives =>{
                    console.log("Error drives >> ", errDrives);
                    this.setState({isLoading:false});
                });
            });
        }
    }

    /**
     * Funcion que muestra el modal de confirmación para remover una receta de la lista de recetas a excluir
     * @param drive drive que se quiere eliminar de la lista
     */
     toggleModalRemoveComunicado = (comunicado) => {
        let msg = ComunicadosVPAPI.GetEstatus(comunicado) == "Vigente" ? "El comunicado se encuentra vigente, este se dejará de publicar y se eliminará del listado de comunicados. ¿Está seguro que desea eliminarlo?" : "El comunicado se eliminará del listado de comunicados. ¿Está seguro que desea eliminarlo?";
        let newState = update(this.state, {
            showModalRemoveComunicado: {$set: true},
            comunicadoToEdit: {$set: {...comunicado}},
            messageRemove : {$set: msg}
        })
        this.setState(newState);
    }

    /**
     * Funcion que cierra el modal de confirmación para eliminar un drive de la lista
     */
     closeModalRemoveComunicado = () => {
        let state = {...this.state};
        state.showModalRemoveComunicado = false;
        state.comunicado.Posicion = ComunicadosVPAPI.GetPosiciones().find( y => y.value == state.comunicado.Posicion);
        this.setState(state);
    }

    removeComunicado = () => {
        this.props.save(this.state.comunicadoToEdit,null,"Comunicado eliminado con éxito")
    }

    VALIDATION_SCHEMA = Yup.object({
        Titulo: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Titulo debe tener más de 2 caracteres.")
            .max(150, "Ha excedido el límite de 150 caracteres.")
            .required("Campo Título es requerido.")
            .test("validate-name", "El título ya existe, favor de ingresar otro.", (value) => {
                let isValid = true;
                let name = value ? value.toLowerCase().trim() : "";
                if (this.props.comunicadoList.some(u => u.Titulo.toLowerCase().trim() === name && u.IdComunicado !== this.state.comunicado.IdComunicado)) {
                    isValid = false;
                }
                return isValid;
            }),
        Image: Yup.mixed()        
            .test("imageRequire", "Debe selecionar una imagen", function(Image) {
                const { UrlImagen } = this.parent;
                if ((!UrlImagen && UrlImagen != "") && Image == null ) {
                  return false; 
                }
                return true;
            })
            .when(['UrlImagen'], {
                is: UrlImagen => { return (!UrlImagen && UrlImagen != "");},
                then: Yup.mixed().required('Imagen requerida').test(
                    "fileFormat",
                    ErrorMessages.PngJpegFormatError,
                    value => value && SUPPORTED_FORMATS.includes(value.type) 
                ).test('fileSize',"tamaño de imagen sobrepasa 3mb", value => value && value.size <= 3145728)
              }),
        FechaInicio: Yup.date().required('Fecha inicio es requerido.'),
        FechaFin: Yup.date().required('Fecha fin es requerida.')
            .min(
                Yup.ref("FechaInicio"),
                "Fecha fin debe ser posterior a fecha inicio."
            ),
        Posicion: Yup.number()
                    .min(1, "Debe seleccionar una posición")
                    .required("Debe seleccionar una posición")
    },[ [ 'UrlImagen' ] ]);

    

    render() {

        return (
            <div>
                <Formik
                    // initialValues={this.state.comunicado}
                    initialValues={{
                        IdComunicado: this.state.comunicado.IdComunicado,
                        Titulo: this.state.comunicado.Titulo,
                        UrlImagen: this.state.comunicado.UrlImagen,
                        Image: null,
                        FechaInicio: this.state.comunicado.FechaInicio == "" ? this.state.comunicado.FechaInicio : new Date(this.state.comunicado.FechaInicio),
                        FechaFin: this.state.comunicado.FechaFin == "" ? this.state.comunicado.FechaFin : new Date(this.state.comunicado.FechaFin),
                        Posicion: this.state.comunicado.Posicion != 0 ? this.state.comunicado.Posicion.value : 0,
                    }}
                    validationSchema={this.VALIDATION_SCHEMA}
                    onSubmit={(values) => {
                        let formatValues = { ...values }
                        formatValues.Pausado = this.state.comunicado.Pausado;
                        formatValues.Titulo = formatValues.Titulo.trim();
                        formatValues.Drives = this.state.comunicado.Drives;
                        formatValues.FechaInicio = format(formatValues.FechaInicio,"yyyy/MM/dd");
                        formatValues.FechaFin = format(formatValues.FechaFin,"yyyy/MM/dd");
                        delete formatValues.Image
                        this.props.save(formatValues, values.Image);
                    }}
                >
                    {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }) => (
                        <form onSubmit={handleSubmit} id={"frmComunicado"}>
                            <div className="columns is-multiline">
                                {this.props.isEditing &&
                                    <div className="column is-6">
                                        <label className="label">Fecha creación: {ComunicadosVPAPI.FormatDate(this.state.comunicado.FechaCreacion)}</label>
                                    </div>
                                }
                                {this.props.isEditing &&
                                    <div className="column is-6">
                                        <label className="label">Fecha actualización: {ComunicadosVPAPI.FormatDate(this.state.comunicado.FechaModificacion)}</label>
                                    </div>
                                }

                                <div className="column is-6">
                                    <Field label="Título"
                                    isHorizontal
                                    hasError={errors.Titulo && touched.Titulo}
                                    msgError={errors.Titulo && touched.Titulo ? errors.Titulo : ""}
                                    showLeftSpaces={true}
                                    childInfo={values.Titulo && !errors.Titulo
                                        ? `Te quedan ${150 - values.Titulo.trim().length} caracteres para el Título.`
                                        : ""}
                                    >
                                        <TextField
                                            autoFocus={true}
                                            required
                                            maxLength="150"
                                            name="Titulo"
                                            value={values.Titulo}
                                            placeholder={"Ingrese nombre de el comunicado"}
                                            onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Field>
                                </div>

                                <div className="column is-6">
                                    {this.props.isEditing && 
                                        <div>
                                            <Field
                                                label="Estatus"
                                                isHorizontal
                                            >
                                                <span className={`tag is-medium ${ComunicadosVPAPI.GetEstatus(this.state.comunicado) == "Vigente" ? "is-success" : ComunicadosVPAPI.GetEstatus(this.state.comunicado) == "Obsoleto" ? "is-light" : "is-info"}`}>
                                                    {ComunicadosVPAPI.GetEstatus(this.state.comunicado)}</span>
                                            </Field>
                                        </div>
                                    }
                                </div>
                                
                                <div className="column is-6">
                                    <span className="tooltip" data-tooltip="Fecha inicio comunicado">
                                        <Field
                                            label="Fecha Inicio"
                                            isHorizontal
                                            hasError={errors.FechaInicio && touched.FechaInicio}
                                            msgError={errors.FechaInicio && touched.FechaInicio ? errors.FechaInicio : ""}
                                        >
                                            <DatePicker
                                                locale="es"
                                                name={"FechaInicio"}
                                                placeholderText="MM/DD/YYYY"
                                                selected = {values.FechaInicio}
                                                onChange={ (e) =>{
                                                    setFieldValue('FechaInicio',e);
                                                    let state = {...this.state};
                                                    state.comunicado.FechaInicio = e;
                                                    this.setState(state);
                                                }}
                                                popperPlacement="right"
                                                popperModifiers={{
                                                    offset: {
                                                        enabled: true,
                                                        offset: '5px, 10px'
                                                    },
                                                    preventOverflow: {
                                                        enabled: true,
                                                        escapeWithReference: false,
                                                        boundariesElement: 'viewport'
                                                    }
                                                }}
                                                
                                                // withPortal
                                                dateFormat="dd/MM/yyyy"
                                                className="input"
                                                minDate={subDays(new Date(), 1095)}
                                                
                                            />
                                        </Field>
                                    </span>
                                </div>

                                <div className="column is-6">
                                    <span className="tooltip" data-tooltip="Fecha fin comunicado">
                                        <Field
                                            label="Fecha Fin"
                                            isHorizontal
                                            hasError={errors.FechaFin && touched.FechaFin}
                                            msgError={errors.FechaFin && touched.FechaFin ? errors.FechaFin : ""}
                                        >
                                            <DatePicker
                                                locale="es"
                                                name={"FechaFin"}
                                                placeholderText="DD/MM/YYYY"
                                                selected = {values.FechaFin}
                                                onChange={ (e) =>{
                                                    setFieldValue('FechaFin',e);
                                                    console.log("valor >> ",e);
                                                    let state = {...this.state};
                                                    state.comunicado.FechaFin = e;
                                                    this.setState(state);
                                                }}
                                                popperPlacement="right"
                                                popperModifiers={{
                                                    offset: {
                                                        enabled: true,
                                                        offset: '5px, 10px'
                                                    },
                                                    preventOverflow: {
                                                        enabled: true,
                                                        escapeWithReference: false,
                                                        boundariesElement: 'viewport'
                                                    }
                                                }}
                                                
                                                // withPortal
                                                dateFormat="dd/MM/yyyy"
                                                className="input"
                                                minDate={values.FechaInicio}
                                            />
                                        </Field>
                                    </span>
                                </div>

                                <div className="column is-6">
                                    <Field
                                        label="Posición"
                                        isHorizontal
                                        hasError={errors.Posicion && touched.Posicion}
                                        msgError={errors.Posicion && touched.Posicion ? errors.Posicion : ""}
                                    >
                                        <div className="control" style={{width: "100%"}}>
                                            <Select
                                                placeholder={"Seleccionar..."}
                                                name={"selectedOption"}
                                                value={this.state.comunicado.Posicion}
                                                onChange={(e) => {
                                                    let state = {...this.state}
                                                    state.comunicado.Posicion = e;
                                                    setFieldValue("Posicion",e.value);
                                                    this.setState(state);
                                                 }}
                                                noOptionsMessage={() => "No hay opciones"}
                                                options={this.state.positionOptions}
                                            />
                                        </div>
                                    </Field>
                                </div>

                                <div className="column is-6"></div>

                                <div className="column is-6">
                                    <Field
                                        label="Imagen"
                                        isHorizontal
                                        hasError={(errors.Image && touched.Image)}
                                        msgError={(errors.Image && touched.Image)  ? errors.Image : ""}
                                    >
                                        <div className={`file ${errors.Image && touched.Image ? "is-danger" : "is-info"} has-name`}>
                                            <label className="file-label">
                                                <input
                                                    className={`input file-input ${errors.Image || touched.Image ? "is-danger" : ""}`}
                                                    id="fileRecipeItem"
                                                    type="file"
                                                    name="Image"
                                                    accept="image/x-png, .jpg, .jpeg"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        let files = e.target.files
                                                        let file = files[0]
                                                        e.stopPropagation();
                                                        e.target.value = null;
                                                        if (file) {
                                                            setFieldValue('Image', file);
                                                            setFieldValue('UrlImagen', "");
                                                            this.setState({ imageStr: URL.createObjectURL(file)})
                                                            var reader = new FileReader();
                                                            reader.readAsDataURL(file);
                                                            var reader = new FileReader();
                                                            reader.onload = function (e) {
                                                                let previewControl = document.getElementById("imgPreview");
                                                                if (previewControl) {
                                                                    document.getElementById("imgPreview").setAttribute('src', e.target.result);
                                                                }
                                                            }
                                                            reader.readAsDataURL(file);
                                                        } else {
                                                            setFieldValue('UrlImagen', "")
                                                            setFieldValue('Image', null)
                                                        }
                                                    }}
                                                />
                                                <div>
                                                    <span className="file-cta">
                                                        <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo .PNG o .JPEG</i></span>
                                                    </span>
                                                    { values.Image != null &&
                                                        <span className="file-name">{values.Image == null ? "Archivo" : values.Image.name}</span>
                                                    }
                                                </div>
                                            </label>
                                        </div>
                                        {values.Image !== null &&
                                            <Field>
                                                <button className="button tableAction is-danger trash-right"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        e.target.value = null;
                                                        setFieldValue('Image', null)
                                                        setFieldValue('UrlImagen', "")
                                                    }
                                                    }>
                                                    <span className="icon">
                                                        <i className="fa fa-trash" />
                                                    </span>
                                                </button>

                                            </Field>
                                        }
                                    </Field>
                                </div>
                                <div className="column is-6">
                                    {values.Image !== null
                                        && <Field>
                                            <div className="column">
                                                <figure className="image container-image " >
                                                    <img id={"imgPreview"}
                                                        src="#"
                                                        style={{ width: 'auto', height: "100px"}}
                                                        alt="" 
                                                        onClick={() =>{ this.setState({showModalImage: true})}}
                                                        />
                                                </figure>
                                            </div>
                                        </Field>
                                    }
                                    {values.UrlImagen && <Field>
                                        <div className="column">
                                            <figure className="image container-image ">
                                                <img src={values.UrlImagen.toString() + "?" + new Date().getTime()}
                                                    style={{ width: 'auto', height: "100px" }}
                                                    alt="No se pudo obtener la imagen." 
                                                    onClick={() =>{ this.setState({showModalImage: true})}}
                                                    />
                                            </figure>
                                        </div>
                                    </Field>}
                                </div>
                            </div>             

                            <br />
                            
                            {/* 
                                Drives
                            */}

                            <div className="columns is-multiline">
                                <div className="column is-12">

                                    <p className="card-header-title has-text-white has-background-primary title is-4">Drives y/o Kioskos a incluir</p>
                                    <div className="has-text-left">
                                            <button 
                                                type='button'
                                                id='btn-edit-drives'
                                                className="button is-info"
                                                onClick={this.toggleEditDrivesModal}>
                                                Editar
                                            </button>    
                                    </div>

                                    <br />

                                    <div className="card">
                                        <div className="card-header is-primary">
                                            <div className="column is-12">
                                                {/* 
                                                    TABLA DE Drives
                                                */}

                                                {(this.state.comunicado.Drives && this.state.comunicado.Drives.length !== 0) 
                                                ? <TableTemplate
                                                        columns={["idCaffenio", "NombreNuevo"]}
                                                        columnsNames={["Id", "Nombre"]}
                                                        
                                                        data={this.state.pageDrives}
                                                        showView={(index) => {}}
                                                        rowClickable={false}
                                                        extraButtons={(drive) => {
                                                            return <button type='button' className="button tableAction is-danger tooltip" data-tooltip="Eliminar drive y/o kiosko"
                                                            onClick={(e) => {e.preventDefault(); this.toggleModalRemoveDrive(drive);}}
                                                            >
                                                                <span className="icon">
                                                                    <i className="fa fa-trash" />
                                                                </span>
                                                            </button>
                                                        }}
                                                /> 
                                                
                                                : <NoItems itemName="drives"/>}

                                                {(this.state.showModalRemoveDrive) &&
                                                    <AcceptModal 
                                                    modalTitle="Confirmar"
                                                    modalBody={<p>¿Está seguro que desea eliminar el drive y/o kiosko de la lista a incluir?</p>}
                                                    isActive={this.state.showModalRemoveDrive}
                                                    willSave={true}
                                                    done={() => this.removeDriveFromList()}
                                                    cancel={() => this.closeModalRemoveDrive()}
                                                    />
                                                }  

                                                <Pagination items={this.state.comunicado.Drives} pageSize={5} keepPage={true} onChangePage={(drives) => this.changeDrivePage(drives)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="has-text-right buttons-modal">
                                <div className="column">
                                    <button 
                                        className="button is-light"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.props.history.goBack();
                                        }}
                                        style={{ marginRight: "5px" }}
                                    >
                                        Cancelar
                                    </button>
                                    <button type="submit" className="button is-success" >
                                        Guardar
                                    </button>
                                </div>
                            </div>

                            <TextField
                                disabled={true}
                                type = "hidden"
                                name="UrlImagen"
                                value={values.UrlImagen}
                                onChange={handleChange}
                            />

                            {this.state.showModalImage && 
                                <Modal
                                    showModal={this.state.showModalImage}
                                    modalTitle={""}
                                    width="60%"
                                    cancel = {() => this.setState({showModalImage:false})}
                                >
                                    {values.Image !== null && 
                                        <Field>
                                            <figure className="image container-image " style={this.stylePreviewImage} >
                                                <img id={"imgPreview2"}
                                                    src={this.state.imageStr}
                                                    style={this.stylePreviewImage}
                                                    alt="" 
                                                />
                                            </figure>
                                        </Field>

                                    }
                                    {values.UrlImagen && 
                                        <Field>
                                            <figure className="image container-image " style={this.stylePreviewImage}>
                                                <img 
                                                    src={values.UrlImagen.toString() + "?" + new Date().getTime()}
                                                    style={this.stylePreviewImage}
                                                    alt="No se pudo obtener la imagen." 
                                                />
                                            </figure>
                                        </Field>
                                    }
                                </Modal>
                            }
                        </form>
                    )}
                </Formik>
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
                {/* MODAL DE DRIVES */}

                {this.state.showModalEditDrives && this.state.showModalDrive && 
                    <Modal
                        showModal={this.state.showModalDrive}
                        modalTitle={"Agregar/Eliminar drives y/o kioskos"}
                        width="90%"
                        cancel = {() => this.cancelDrivesModal()}
                    >
                        <DrivesComunicado
                            selectedDrives = {this.state.comunicado.Drives}
                            saveDrives = {this.saveDrives}
                            cancelDrivesModal = {this.cancelDrivesModal}
                        />
                        <br />
                    </Modal>
                }
                {(this.state.showModalRemoveComunicado) &&
                    <AcceptModal 
                    modalTitle="Confirmar"
                    modalBody={<p>{this.state.messageRemove}</p>}
                    isActive={this.state.showModalRemoveComunicado}
                    willSave={true}
                    done={() => this.removeComunicado()}
                    cancel={() => this.closeModalRemoveComunicado()}
                    />
                }
                {this.state.showModalMessageMaxComunicados && 
                    <Modal
                        showModal={this.state.showModalMessageMaxComunicados}
                        modalTitle={"Importante"}
                        width="40%"
                        cancel = {() => this.setState({showModalMessageMaxComunicados: false})}
                    >
                        <b>Existen Drives y/o Kioskos con más de 4 comunicados dentro del periodo seleccionado, revise la lista.</b>
                        <br />
                    </Modal>
                }
            </div>
        );
    }
}

ComunicadoForm.defaultProps = {
    isLoading: false,
    comunicado: {
        IdComunicado: 0,
        Titulo: "",
        UrlImagen: "",
        FechaInicio: "",
        FechaFin: "",
        Posicion: 0,
        Drives: []
    }
}

export default ComunicadoForm;
