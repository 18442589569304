import React, { Component } from 'react';
import WarehouseAPI from '../../services/Warehouse';
import  NoItems  from "../util/noitems/NoItems";
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Field from '../util/field/Field';

import ModoGuiado from "../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../util/validation-error-messages';

import lodash from 'lodash';


class Warehouse extends Component {

    warehouseArr = [];
    constructor(props){
        super(props);
        this.state = {
            warehouseArr: [],
            warehouseFilter: [],
            isLoading: false,
            selectedOptionS: null,
            selectedOption: [],
            selectedOptionC: [],
            selectedOptionD: [],
            valueEstado: [],
            pageInventoryItems: [],
            Caffenios: [],
            OptionSelect: [],
            OptionSelectCity: [],
            OptionSelectDrive: [],
            filterText: "",
            optionSegmento: [],
            optionState: [],
            optionCity: [],
            optionDrive: [],
            childVisible: false,
            checkAll: false,
            switchAllSug: false,
            switchAllInv: false,
            seeSwitchSug: false,
            seeSwitchInv: false,
            buttonSelecAll: false,
            buttonMesagge:false
        }
    }

    /**
     * Método para filtrar por base de datos los Almart que coincidan con el nombre y/o código
     * de proveedor (ya no se carga en el useEffect todo, ahora primero se hace un prefiltrado)
     */
    buscarPorCodigoNombre = () => {
        if (this.state.filterText === null
            || this.state.filterText === undefined
            || this.state.filterText === '') {
            toast.error('Se debe ingresar el nombre o código de proveedor para la búsqueda')
        } else if (this.state.filterText.length < 3) {
            toast.error('Se debe ingresar un texto de por lo menos 3 caracteres')
        }
        else {
            this.setState({ isLoading: true }, () => {
                let state = { ...this.state }
                WarehouseAPI.getCaffenios()
                    .then(response => {
                        state.Caffenios = response.data;
                        state.optionSegmento = lodash.sortBy(this.select_segmento(state.Caffenios), [ts => ts.label.toLowerCase()], ['asc'])
                        state.optionState = lodash.sortBy(this.select_state(state.Caffenios), [ts => ts.label.toLowerCase()], ['asc']);
                        state.optionCity = lodash.sortBy(this.select_city(state.Caffenios, state["selectedOptionS"]), [ts => ts.label.toLowerCase()], ['asc']);
                        state.optionDrive = lodash.sortBy(this.select_Drive(state.Caffenios, state["selectedOptionS"]), [ts => ts.label.toLowerCase()], ['asc']);
                        return WarehouseAPI.GetByIdArticulo(this.state.filterText.trim())
                    }).then(response => {
                        state.warehouseArr = response.data;
                        state.warehouseFilter = state.warehouseArr;
                        state.isLoading = false;
                        state.warehouseFilter.map(producto => {
                            producto.statusCheck = false;
                            return producto
                        })
                        //Se limpian los filtros de localización
                        /* state["selectedOptionS"] = null;
                        state["selectedOption"] = [];
                        state["selectedOptionC"] = [];
                        state["selectedOptionD"] = []; */
                        this.setState(state, () => {
                            if (state["selectedOptionS"] !== null) {
                                this.changeSelectSegmento(state["selectedOptionS"])
                            }
                            if (state["selectedOption"].length > 0) {
                                this.changeSelectEstado(state["selectedOption"])
                            }
                            if (state["selectedOptionC"].length > 0) {
                                this.changeSelectCiudad(state["selectedOptionC"])
                            }
                            if (state["selectedOptionD"].length > 0) {
                                this.changeSelectDrive(state["selectedOptionD"])
                            }
                        });
                    }).catch(err => {
                        this.setState({ isLoading: false })
                        if (err.response)
                            toast.error(err.response.data)
                        else
                            toast.error(ErrorMessages.SinConexion)
                    });
            })
        }
    }

    select_segmento(Caffenios) { //Llenar el combo 'Segmentación' con todas las opciones
        var newArray = [];
        let OptionSelectSegmento = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectSegmento.push({ value: item.idSegmento, label: item.Segmento });
        });

        for (var i in OptionSelectSegmento) {
            lookupObject[OptionSelectSegmento[i]["value"]] = OptionSelectSegmento[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    select_state(Caffenios) { // Llenar el combo 'Estados' con todas las opciones 
        var newArray = [];
        let OptionSelect = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelect.push({value:item.StateId,label:item.State});
        });

        for(var i in OptionSelect) {
            lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        
        return newArray;
    }

    select_city(Caffenios, segmentacion) { // Llenar el combo 'Ciudades' con todas las opciones 
        var newArray = [];
        let OptionSelectCity = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            if (segmentacion === null) { //Se valida si hay segmentación seleccionada para prefiltar
                OptionSelectCity.push({value:item.Municipalityid,label:item.Municipality});
            } else {
                if (item.idSegmento === segmentacion.value) {
                    OptionSelectCity.push({value:item.Municipalityid,label:item.Municipality});
                }
            }
        });

        for(var i in OptionSelectCity) {
            lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    select_Drive(Caffenios, segmentacion) { // Llenar el combo 'Drives' con todas las opciones 
        var newArray = [];
        let OptionSelectDrive = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            if (segmentacion === null) { //Se valida si hay segmentación seleccionada para prefiltar
                OptionSelectDrive.push({value:item.idCaffenio,label:item.NombreNuevo});
            } else {
                if (item.idSegmento === segmentacion.value) {
                    OptionSelectDrive.push({value:item.idCaffenio,label:item.NombreNuevo});
                }
            }
        });

        for(var i in OptionSelectDrive) {
            lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let state = {...this.state};
        state.filterText = text;
        this.setState(state);
        /* this.setState(state, () => this.FilterProcess());
        this.cleanCheckBoxs();  */
    }

    ChangeStatus = (WarehouseObj) => { // Cambiar el estado activo/inactivo para 'Sugestible' e 'Inventariable'
        WarehouseAPI.edit(WarehouseObj).then(response => {
            toast.success("Estatus actualizado");
        }).catch(err => {
            // toast.error(err.response.data.Message)
            if(err.response.status) {
                toast.error("Error en la configuración del artículo")
            }
        });
    }

    handlePageChange = (items) => { // Hacer la paginación en la tabla
        let checkAllTemp = true;
        let cheked = false;

        let listDrives = [...this.state.Caffenios];
        let listProduct = items.map(obj => { 
            let driveid = obj.DriveId;            
            let objCaffDrive = listDrives.find(obj => obj.idCaffenio === driveid)                   
            obj["nombreDrive"] = objCaffDrive ? objCaffDrive.NombreNuevo : "Sin asignar"; 
            
            if(obj.statusCheck){
                cheked = true;
            } else {
                checkAllTemp = false;
            }           
            return obj
        } );

        this.setState({pageInventoryItems:listProduct, checkAll: checkAllTemp});
    }

    eliminarObjDuplicados = (arr,prop) => {
        var nuevoArray = [];
        var lookup  = {};
    
        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }
    
        for (i in lookup) {
            nuevoArray.push(lookup[i]);
        }
        
        return nuevoArray;
    }

    changeSelectSegmento = (obj) => {
        let state = { ...this.state }
        state["selectedOptionS"] = obj;
        if (obj !== null) {
            let idSegmento = obj.value
            let objCaffenios = [...state.Caffenios];
            let listaEstados = [];
            let listaCiudades = [];
            let listaDrives = [];
            // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
            objCaffenios.map(item => {
                if (item.idSegmento === idSegmento) {
                    listaEstados.push({ "value": item.StateId, "label": item.State });
                }
            })

            //Se eliminan los estados duplicados
            let eliminarDuplicados = this.eliminarObjDuplicados(listaEstados, "value");
            //Se obtiene un arreglo con los id de los estados
            let idEstado = eliminarDuplicados.map(estado => { return estado.value });
            //Se buscan coincidencias entre estado y ciudad y se genera la lista de drives
            objCaffenios.map(item => {
                for (var i = 0; i < idEstado.length; i++) {
                    if (item.StateId === idEstado[i] && item.idSegmento === idSegmento) {
                        listaCiudades.push({ "value": item.Municipalityid, "label": item.Municipality });
                    }
                }
            })
            //Se eliminan duplicados de ciudad
            let eliminarDuplicadosCiudad = this.eliminarObjDuplicados(listaCiudades, "value");
            //Se obtien un arreglo con los id de las ciudades
            let idCiudad = eliminarDuplicadosCiudad.map(ciudad => { return ciudad.value });
        
            //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
            objCaffenios.map(item => {
                for (var i = 0; i < idCiudad.length; i++) {
                    if (item.Municipalityid === idCiudad[i] && item.idSegmento === idSegmento) {
                        listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                    }
                }
            })
            state["optionState"] = eliminarDuplicados;
            state["optionCity"] = eliminarDuplicadosCiudad;
            state["optionDrive"] = listaDrives;
        } else {
            //En caso que se haya borrado la segmentación del select, se reinician
            //los demas filtros
            state["optionState"] = this.select_state(state.Caffenios);
            state["optionCity"] = this.select_city(state.Caffenios, state["selectedOptionS"]);
            state["optionDrive"] = this.select_Drive(state.Caffenios, state["selectedOptionS"]);
        }
        //Se limpian las opciones del select 'Estado', 'Ciudades' y 'Drives'
        state["selectedOption"] = [];
        state["selectedOptionC"] = [];
        state["selectedOptionD"] = [];


        this.setState(state, () => this.FilterProcess());
    }

    changeSelectEstado = (obj) => {
        let state = {...this.state}
        state["selectedOption"] = obj;
        let idEstado = obj.map(estado => {return estado.value});
        let objCaffenios = [...state.Caffenios];
        let listaCiudades = [];
        let listaDrives = [];

        // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
        objCaffenios.map(item =>{
            for(var i=0; i<idEstado.length; i++){
                if(item.StateId===idEstado[i]){
                    if (state.selectedOptionS === null) { //Se valida si hay segmentación seleccionada para prefiltar
                        listaCiudades.push({ "value": item.Municipalityid, "label": item.Municipality });
                    } else {
                        if (item.idSegmento === state.selectedOptionS.value) {
                            listaCiudades.push({ "value": item.Municipalityid, "label": item.Municipality });
                        }
                    }
                }
            }
        })

        //Se eliminan las ciudades duplicadas
        let eliminarDuplicados = this.eliminarObjDuplicados(listaCiudades,"value");
        //Se obtiene un arreglo con los id de las ciudades
        let idCiudad = eliminarDuplicados.map(ciudad => {return ciudad.value});

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item =>{
            for(var i=0; i<idCiudad.length; i++){
                if(item.Municipalityid===idCiudad[i]){
                    if (state.selectedOptionS === null) { //Se valida si hay segmentación seleccionada para prefiltar
                        listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
                    } else {
                        if (item.idSegmento === state.selectedOptionS.value) {
                            listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
                        }
                    }
                }
            }
        })

        //Se limpian los combos 'Ciudades' y 'Drives'
        state["selectedOptionC"] = [];
        state["selectedOptionD"] = [];

        //Se valida que haya una opción seleccionada en combo 'Estados'
        if(obj.length===0){
            state["optionCity"] = this.select_city(state.Caffenios, state["selectedOptionS"]);
            state["optionDrive"] = this.select_Drive(state.Caffenios, state["selectedOptionS"]);
            
        } else{
            state["optionCity"] = eliminarDuplicados;
            state["optionDrive"] = listaDrives;
        }

        this.setState(state, () => this.FilterProcess());
    }

    changeSelectCiudad = (obj) =>{
        let state = {...this.state}
        let idCiudad = obj.map(ciudad => {return ciudad.value});
        let objCaffenios = [...state.Caffenios];
        let listaDrives = [];

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item =>{
            for(var i=0; i<idCiudad.length; i++){
                if(item.Municipalityid===idCiudad[i]){
                    if (state.selectedOptionS === null) { //Se valida si hay segmentación seleccionada para prefiltar
                        listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
                    } else {
                        if (item.idSegmento === state.selectedOptionS.value) {
                            listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
                        }
                    }
                }
            }
        })

        //Se limpia el combo 'Drives'
        state["selectedOptionD"] = [];

        //Se valida que haya una opción seleccionada en combo 'Ciudades'
        if(obj.length>0){
            state["selectedOptionC"] = obj;
            state["optionDrive"] = listaDrives;
            this.setState(state, () => this.FilterProcess());
        } else{
            this.changeSelectEstado(state["selectedOption"]);
        }
    }

    changeSelectDrive = (obj) =>{
        let state = {...this.state};
        let Caffenios = [...state.Caffenios];

        //Valida cada select (Estados,Ciudades,Drives) y realiza la acción correspondiente
        if(obj.length>0){
            state["selectedOptionD"] = obj;
            //state["optionDrive"] = obj;
            this.setState(state, () => this.FilterProcess());
        } else if(state["selectedOptionC"].length===0){
            this.changeSelectEstado(state["selectedOption"]);
        } else if(state["selectedOptionC"].length===0 && state["selectedOption"].length===0){
            this.select_Drive(Caffenios, state["selectedOptionS"]);
            this.select_Drive(Caffenios, state["selectedOptionS"]);
            this.select_Drive(Caffenios, state["selectedOptionS"]);
        } else{
            this.changeSelectCiudad(state["selectedOptionC"]);
        }
    }

    validarCombos = (state) => {
        var status_combo = false; // Vacios

        if(state["selectedOption"].length>0){
            status_combo = true; // Alguno con valor
        }

        if(state["selectedOptionC"].length>0){
            status_combo = true; // Alguno con valor
        }

        if(state["selectedOptionD"].length>0){
            status_combo = true; // Alguno con valor
        }

        return status_combo;
    }

    FilterProcess = () => {
        let state = {...this.state}
        let warehouseArr = state.warehouseArr;
        var status_combo; // Vacios
        let driveIds;

        if(state["selectedOptionD"].length===0){
            driveIds = state["optionDrive"].map(obj => {
                return obj.value;
            })
        } else{
            driveIds = state["selectedOptionD"].map(obj => {
                return obj.value;
            })
        }

        status_combo = this.validarCombos(state);

        if(state.filterText.trim() !== ''){
            warehouseArr = warehouseArr.filter(obj => {
                return obj.InventoryItem.CodeProvider.toLowerCase().includes(state.filterText.trim().toLowerCase()) || obj.InventoryItem.Name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            });
        }

        warehouseArr = warehouseArr.filter((obj2)=>{
            if(driveIds.filter((item)=>{
                return item === obj2.DriveId
            }).length > 0){ return obj2 }
        })

        state.warehouseFilter = warehouseArr;
        this.setState(state); 
        this.cleanCheckBoxs();
    }

    /**
     * Function que se utiliza para marcar todos los elementos de una pagina
     */
    selectAllCheck = () => {
        let state = {...this.state};
        let elementsPage = state.pageInventoryItems;

        elementsPage.map(element=>{
            state.checkAll ? element.statusCheck = false : element.statusCheck = true
            return element
        })

        // Revisar la cantida de elementos seleccionados en total
        let elements = state.warehouseArr;
        let counter = 0;
        elements.map(element=>{
            if (element.statusCheck){
                counter++;
            }
        })

        state.checkAll = !state.checkAll;
        state.childVisible = state.checkAll
        state.pageInventoryItems = elementsPage;
        if(state.checkAll === false){
            state.switchAllInv=false 
            state.switchAllSug=false
            state.seeSwitchInv=false
            state.seeSwitchSug=false
        } 
        state.buttonMesagge = counter === this.state.warehouseArr.length
        state.childVisible = counter !== 0
        this.setState(state);
    }

    /**
     * Función que se utiliza para marcar un elemento en particular
     */
    showView = () => {

        let state = {...this.state};
        let cheked = false;
        let checkAllTemp = true;
        state.pageInventoryItems.map(item=>{
            if(item.statusCheck){
                cheked = true;
            } else {
                checkAllTemp = false;
            }
        })

        // Revisar la cantida de elementos seleccionados en total
        let elements = state.warehouseArr;
        let counter = 0;
        elements.map(element=>{
            if (element.statusCheck){
                counter++;
            }
        })

        this.setState({childVisible: cheked, checkAll: checkAllTemp, buttonMesagge: counter === this.state.warehouseArr.length});
    }

    /**
     * Funcion que se comunica con la API para modificar el estatus de los items
     */
    applySwitchAll = (estatus) => {
        //Estatus 1 = Sugerible
        //Estatus 2 = Inventariable
        let state = {...this.state};
        let arrProductosChange = [];

        this.state.warehouseFilter.map(item=>{
            if(item.statusCheck) arrProductosChange.push(item)
        })

        if(estatus === 1){
            for(var i=0; i<arrProductosChange.length; i++){
                arrProductosChange[i].Suggestible = state.switchAllSug;
            }
        }

        if(estatus === 2){
            for(var i=0; i<arrProductosChange.length; i++){
                arrProductosChange[i].Inventariable = state.switchAllInv;
            }
        }
        
        WarehouseAPI.editAll(arrProductosChange).then(response => {
            toast.success("Estatus actualizados");
            this.setState({checkAll: true, switchAllInv:false, switchAllSug:false, seeSwitchInv:false,seeSwitchSug:false},this.cleanCheckBoxs)
        }).catch(err => {
            if(err.response.status) {
                toast.error("Error en la configuración del artículo")
            }
        });
        
    }

    /**
     * Funcion para seleccionar todos los articulos
     * de todas las paginas
     */
    selectAllPages = () =>{
        let state = {...this.state};
        let elements = state.warehouseArr;

        elements.map(element=>{
            state.buttonMesagge ? element.statusCheck = false : element.statusCheck = true
            return element
        })

        // state.checkAll = !state.checkAll;
        state.checkAll = !state.buttonMesagge;
        state.childVisible = state.checkAll
        state.warehouseArr = elements;
        state.buttonMesagge = !state.buttonMesagge;
        if(state.checkAll === false){
            state.switchAllInv=false 
            state.switchAllSug=false
            state.seeSwitchInv=false
            state.seeSwitchSug=false
        } 
        
        this.setState(state);
    }
    /**
     * Funcion para des seleccionar todos los checkbox
     */
    cleanCheckBoxs = () =>{
        this.state.warehouseFilter.map(item=>{
            if(item.statusCheck){
                item.statusCheck = false
            }
        });
        
        this.setState({
            checkAll:false,
            childVisible:false,
            buttonMesagge:false,
            switchAllInv:false,
            switchAllSug:false,
            seeSwitchInv:false,
            seeSwitchSug:false
        })
    }
    render(){
        return(
            <div>

                <ModoGuiado 
                    ruta="almacen"
                    tipoModoGuiado="articulo"
                    anterior="items-inventario"
                    mensaje="Asignar artículos a un almacén (drive)" 
                />
                
                <h1 className="title is-2">Almacén</h1>
                <div className="card">
                    <div className="card-content">
                        <div className='columns'>
                        <div className="column is-3"></div>
                            <div className="column is-6">
                                <label className="label">Código Proveedor</label>
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        placeholder={`Ingrese Nombre o Código de proveedor`}
                                        value={this.state.filterText}
                                        autoFocus={true}
                                        onChange={(e) => this.handleSearchInput(e.currentTarget.value)}
                                        onKeyDown={evt => {
                                            if (evt.key === "Enter") {
                                                this.buscarPorCodigoNombre()
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="column is-2">
                                {/* Se pone invisible para hacer el salto del label */}
                                <label className="label">ㅤ</label> 
                                <button
                                    className="button is-success"
                                    onClick={() => this.buscarPorCodigoNombre()}>
                                    Buscar
                                </button>
                            </div>
                        </div>
                        {this.state.warehouseArr.length > 0 && <div className="columns">
                            <div className="column is-3">
                                <label className="label">Segmentación</label>
                                <div className="control">
                                    <Select
                                        isClearable
                                        placeholder={"seleccionar..."}
                                        name={"selectedOptionS"}
                                        value={this.state.selectedOptionS}
                                        onChange={this.changeSelectSegmento}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.optionSegmento}
                                    />
                                </div>
                            </div>
                            <div className="column is-3">
                                <label className="label">Estados</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"seleccionar..."}
                                        name={"selectedOption"}
                                        value={this.state.selectedOption}
                                        onChange={this.changeSelectEstado}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.optionState}
                                    />
                                </div>
                            </div>
                            <div className="column is-3">
                                <label className="label">Ciudades</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"seleccionar..."}
                                        name={"selectedOptionC"}
                                        value={this.state.selectedOptionC}
                                        onChange={this.changeSelectCiudad}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.optionCity}
                                    />
                                </div>
                            </div>
                            <div className="column is-3">
                                <label className="label">Drives</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"seleccionar..."}
                                        name={"selectedOptionD"}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={this.state.selectedOptionD}
                                        onChange={this.changeSelectDrive}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.optionDrive}
                                    />
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <br/>

                {
                    this.state.childVisible &&
                    <div className="card">
                        <div className="card-content">
                            <div className="columns">
                                <div className="column is-3">
                                    <label htmlFor="">Modificar productos seleccionados</label>
                                </div>
                                <div className="column is-2">
                                    <Field label="" >
                                        <label htmlFor="" style={{marginRight: 15}}>Pedido</label>
                                        <input   
                                            type="checkbox"
                                            name="isSuggestibleAll"
                                            className="switch"
                                            checked={this.state.switchAllSug}
                                            onChange={() => {}}
                                        />
                                        <label htmlFor="isSuggestibleAll" onClick={(e) => {
                                            e.stopPropagation();
                                            let switchAllSugtemp = this.state.switchAllSug;
                                            //let seeSwitchSugTemp = this.state.seeSwitchSug;
                                            //seeSwitchSugTemp = !seeSwitchSugTemp;
                                            switchAllSugtemp = !switchAllSugtemp;
                                            this.setState({switchAllSug: switchAllSugtemp,seeSwitchSug:true});
                                        }}/>
                                    </Field>
                                </div>
                                <div className="column is-2">
                                    <div className="has-text-center buttons-modal">
                                        <button
                                            className="button is-success"
                                            onClick={() => this.applySwitchAll(1)}>
                                            Aplicar
                                        </button>
                                    </div>
                                </div>
                                {/*  */}
                                <div className="column is-2">
                                    <Field label="" >
                                        <label htmlFor="" style={{marginRight: 15}}>Inventario</label>
                                        <input   
                                            type="checkbox"
                                            name="isInventariableAll"
                                            className="switch"
                                            checked={this.state.switchAllInv}
                                            onChange={() => {}}
                                        />
                                        <label htmlFor="isInventariableAll" onClick={(e) => {
                                            e.stopPropagation();
                                            let switchAllInvTemp = this.state.switchAllInv;
                                            //let seeSwitchInvTemp = this.state.seeSwitchInv;
                                            switchAllInvTemp = !switchAllInvTemp;
                                            //seeSwitchInvTemp = !switchAllInvTemp;
                                            this.setState({switchAllInv: switchAllInvTemp,seeSwitchInv:true});
                                        }}/>
                                    </Field>
                                </div>
                                <div className="column is-3">
                                    <div className="has-text-center buttons-modal">
                                        <button
                                            className="button is-success"
                                            onClick={() => this.applySwitchAll(2)}>
                                            Aplicar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <br/>
                <div className="card">
                    <div className="card-content">
                        {this.state.warehouseFilter.length !== 0 
                        ? 
                        <div>
                            <div className="columns">
                                <div className="column is-3">
                                    <div className="has-text-center">
                                        <a className="button is-link" onClick={() => this.selectAllPages()}>
                                            <span className="icon is-small">
                                                <i className={!this.state.buttonMesagge ? "fa fa-check-square" : "fa fa-square"}></i>
                                            </span>
                                            <span>{!this.state.buttonMesagge ? "Seleccionar todos los resultados" : "Deseleccionar todos los resultados"}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <table className="table is-fullwidth">
                                <thead>
                                    <tr>
                                        <th><input className="is-primary tooltip" data-tooltip="Elementos de vista actual" onChange={this.selectAllCheck} checked={this.state.checkAll} type="checkbox" /*checked={this.state.isChecked}*/ /></th>
                                        <th>Código Proveedor</th>
                                        <th>Nombre</th>
                                        <th>Pedido</th>
                                        <th>Inventario</th>
                                        <th>Drive</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.pageInventoryItems.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <th><input type="checkbox" checked={item.statusCheck} onClick={(e) => {
                                                            e.stopPropagation();
                                                            let pageItems = this.state.pageInventoryItems[index];
                                                            item.statusCheck = !item.statusCheck;
                                                            this.setState({pageItems: item.statusCheck}, this.showView);
                                                        }}/></th>
                                                <td>{item.InventoryItem.CodeProvider}</td>
                                                <td>{item.InventoryItem.Name}</td>
                                                <td>
                                                    <Field label="" isHorizontal>
                                                        <input   
                                                            type="checkbox"
                                                            name="isSuggestible"
                                                            className="switch"
                                                            checked={item.Suggestible}
                                                            onChange={() => {}}
                                                        />
                                                        <label htmlFor="isSuggestible" onClick={(e) => {
                                                            e.stopPropagation();
                                                            let wareSugges = this.state.warehouseArr[item];
                                                            item.Suggestible = !item.Suggestible;
                                                            this.setState({wareSugges: item.Suggestible});
                                                            this.ChangeStatus(item);
                                                        }}/>
                                                    </Field>
                                                </td>
                                                <td>
                                                    <Field label="" isHorizontal>
                                                        <input   
                                                            type="checkbox"
                                                            name="isInventariable"
                                                            className="switch"
                                                            checked={item.Inventariable}
                                                            onChange={() => {}}
                                                        />
                                                        <label htmlFor="isInventariable" onClick={(e) => {
                                                            e.stopPropagation();
                                                            let wareInven = this.state.warehouseArr[item];
                                                            item.Inventariable = !item.Inventariable;
                                                            this.setState({wareInven: item.Suggestible});
                                                            this.ChangeStatus(item);
                                                        }}/>
                                                    </Field>
                                                </td>
                                                <td>{item.nombreDrive}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table> 
                        </div>
                        : <NoItems itemName="productos en almacén"/>}
                        <Pagination 
                            items={this.state.warehouseFilter} 
                            onChangePage={
                                (items) => {
                                    this.handlePageChange(items)
                                }
                            } />
                        
                    </div>
                </div>
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />                

            </div>

        );
    }
}

export default Warehouse;