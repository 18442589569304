import { apiUrl }  from "../constants";
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class InventoryItemsAPI {
    static getAll() {
        return request.get('/inventoryItem');
    }

    static get(id) {
        return request.get(`/inventoryItem/${id}`);
    }
    static getByList(id,lista) {
        return request.get(`/inventoryItem/${id}/${lista}`);
    }
    static getByCodeProvider(codeProvider){
        return request.get(`/InventoryItemByCode/${codeProvider}`)
    }
    static create(inventoryItem) {
        let fd = new FormData();
        for(var prop in inventoryItem){
            fd.append(prop, inventoryItem[prop])
        }

        return request.post('/inventoryItem', fd);
    }

    static edit(inventoryItem){
        let fd = new FormData();
        for(var prop in inventoryItem){
            if(inventoryItem[prop] != null && inventoryItem[prop] != undefined)
                fd.append(prop, inventoryItem[prop])
        }
        
        return request.put(`/inventoryItemUpdate`, fd);
    }

    static editImage(inventoryItem){
        let fd = new FormData();
        for(var prop in inventoryItem){
            if(inventoryItem[prop] != null && inventoryItem[prop] != undefined)
                fd.append(prop, inventoryItem[prop])
        }
        
        return request.post(`/InventoryItemUpdate/Image`, fd);
    }

    static delete(id){
        return request.delete(`inventoryItem/${id}`);
    }

    static getInventoryTypes(){
        return request.get(`/InventoryType`);
    }

    static getOrderTypes(){
        return request.get(`/OrderType`);
    }

    static getToppings(){
        return request.get(`/InventoryItem/toppings`)
    }
    static getAllToppings(){
        return request.get(`/InventoryItem/GetAllToppings`)
    }
    static setAllPriceToppings(data){
        return request.post(`/InventoryItem/setAllPriceToppings`,data)
    }
    static deleteTopping(id){
        return request.put(`/InventoryItem/DeleteTopping/${id}`)
    }
    static removeToppingFromPriceList(id, priceListId){
        return request.put(`/InventoryItem/RemoveToppingFromPriceList/${id}/${priceListId}`)
    }

    static search(data){
        return request.post(`/inventoryItem/Search/`, data)
    }
    static GetAllDrivesExcluded(data){
        return request.get(`/InventoryItem/GetDrivesExclude/${data}`)
    }
    static ExcludeToppings(data){
        return request.post(`/InventoryItem/ExcludeToppings`,data)
    }
    static RemoveExcludeToppings(data){
        return request.post(`/InventoryItem/RemoveExcludeToppings`,data)
    }
    static syncDrives(listaArticuloDrive) {
        return request.post('/inventoryItem/SyncDrives', listaArticuloDrive);
    }
}