import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Loading from '../../../../../util/loading/Loading';
import NoItems from '../../../../../util/noitems/NoItems';
import ServerPagination from '../../../../../util/pagination/ServerPagination';
import NotificacionAPI from '../../../../../../services/Caffenio App/Notificacion';
import to from '../../../../../../util/to';
import { toast } from 'react-toastify';
import useModal from '../../../../../../util/hooks/useModal';
import PortalModal from '../../../../../../util/portals/PortalModal';

export default function TablaEventosAgenda({notification, agendando}){
    const [loading, setLoading] = useState(false);
    const [paginationInfo, setPaginationInfo] = useState({totalPages: 0, currentPage: 1})
    const [columnNames, setColumnNames] = useState(["Fecha creación", "Tipo", "Detalle", "Frecuencia", "Periodo", "Estatus", "Acciones"])
    const [events, setEvents] = useState([]);
    const [currentEvent, setCurrentEvent] = useState(null);
    const confirmModal = useModal();
    const deleteModal = useModal();

    useEffect(() => {
        if (notification && !agendando){
            getEvents();
        }
    }, [notification, agendando])

    const getEvents = async () => {
        var err, data;
        [err, data] = await to(NotificacionAPI.getNotificationEvents(notification.NotificationId, paginationInfo.currentPage));
        setEvents(data.Results);
        setPaginationInfo(info => ({...info, totalPages: data.TotalPages}));
    }

    useEffect(() => {
        getEvents();
    }, [paginationInfo.currentPage])

    const changeStatus = () => {
        NotificacionAPI.changeNotificationEventStatus(currentEvent.NotificationScheduleId, !currentEvent.Active).then(response => {
            toast.success(response.data.Message)
            confirmModal.toggle();
            getEvents();
        }).catch(err => {
            toast.error(err.response.data.Message)
            confirmModal.toggle();
            setCurrentEvent(null);
        });
    }

    const deleteEvent = () => {
        NotificacionAPI.deleteEvent(currentEvent.NotificationScheduleId).then(response => {
            toast.success(response.data.Message);
            deleteModal.toggle();
            setCurrentEvent(null);
            getEvents();
        }).catch(err => {
            toast.error(err.response.data.Message)
            confirmModal.toggle();
            setCurrentEvent(null);
        })
    }

    return (
        <div className="card">
            <div className="card-content">
                {events.length > 0 ? <table className="table is-fullwidth">
                    <tr>
                        {columnNames.map(c => <th>{c}</th>)}
                    </tr>
                    {events.map(d => <tr>
                        <td>{moment(d.DateCreation).format('DD/MMMM/YYYY HH:mm')}</td>
                        <td>{d.DescriptionScheduleType}</td>
                        <td>{d.DescriptionEvent}</td>
                        <td>{d.DescriptionScheduleFrequencyType}</td>
                        <td>{d.DateStart && d.DateEnd ? `${moment(d.DateStart).format('DD/MMMM/YYYY')} - ${moment(d.DateEnd).format('DD/MMMM/YYYY')}` : ''}</td>
                        <td className="tooltip" data-tooltip={d.Active ? "Desactivar evento" : "Activar evento"}>
                            <input
                                type="checkbox"
                                name="status"
                                className="switch"
                                
                                checked={d.Active}
                                onChange={() => { }}
                            />
                            <label disabled htmlFor="status" onClick={(e) => {
                                setCurrentEvent(d);
                                confirmModal.toggle();
                            }} />
                        </td>
                        <td>
                            <button className="button is-small tableAction is-danger tooltip" data-tooltip="Eliminar" onClick={(e) => {
                                e.stopPropagation();
                                // deleteEvent(d.NotificationScheduleId);
                                setCurrentEvent(d);
                                deleteModal.toggle();
                            }}>
                                <span className="icon">
                                    <i className="fa fa-trash" />
                                </span>
                            </button>
                        </td>
                    </tr>)}
                </table>
                : loading ? <Loading isLoading={loading} width='100px' height='100px'/>
                : <NoItems itemName="eventos"/>}
                <ServerPagination
                    totalPages={paginationInfo.totalPages}
                    currentPage={paginationInfo.currentPage}
                    goToPage={(page) => setPaginationInfo((info) => ({...info, currentPage: page}))}
                />
            </div>
            <PortalModal
                isShowing={confirmModal.isShowing}
                hide={confirmModal.toggle}
                title="Confirmar"
                body={() => {
                    return (
                        <span>
                            <p>{`¿Estás seguro que deseas ${currentEvent.Active ? "desactivar" : "activar"} el evento?`}</p>
                            {currentEvent.Active && <p>El evento ya no será tomado en cuenta al momento de agendar notificaciones.</p>}
                        </span>
                    )
                }}
                footer
                footerButtons={() => {
                    return (
                        <div className="has-text-right modal-detalle-buttons">
                            <button className="button" onClick={() => {
                                confirmModal.toggle();
                                setCurrentEvent(null);
                            }}>Cancelar</button>
                            <button className={`button ${false ? "is-danger" : "is-success"}`} onClick={() => changeStatus()}>Guardar</button>
                        </div>
                    )
                }}
            />
            <PortalModal
                isShowing={deleteModal.isShowing}
                hide={deleteModal.toggle}
                title="Confirmar"
                body={() => {
                    return (
                        <span>
                            <p>{`¿Estás seguro que deseas eliminar el evento?`}</p>
                        </span>
                    )
                }}
                footer
                footerButtons={() => {
                    return (
                        <div className="has-text-right modal-detalle-buttons">
                            <button className="button" onClick={() => {
                                deleteModal.toggle();
                                setCurrentEvent(null);
                            }}>Cancelar</button>
                            <button className={`button ${ false ? "is-danger" : "is-success"}`} onClick={() => deleteEvent()}>Confirmar</button>
                        </div>
                    )
                }}
            />
        </div>
    )
}