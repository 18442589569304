import React from "react";
import Field from "../util/field/Field";
import Loading from "../util/loading/Loading";
import Modal from '../util/modal/Modal';
import AcceptModal from '../util/acceptModal/AcceptModal';
import HasAccess from '../util/hasAccess/HasAccess';
import  NoItems  from "../util/noitems/NoItems";
import Select from 'react-select';
import Pagination from '../util/pagination/Pagination';

import BtnModoGuiado from '../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../util/validation-error-messages';
import { size, truncate } from "lodash";

import  TableTemplate  from "../util/tabletemplate/TableTemplate";
import DatePicker, { registerLocale } from "react-datepicker/dist/es"
import TimePicker from 'rc-time-picker';
import { format, formatDistance, formatRelative, addDays, subDays, setHours, setMinutes, parseISO} from 'date-fns'
import es from "date-fns/locale/es";
registerLocale("es", es);


export function ReporteExtractosComponent({
    changeSelectDrive,
    changeSelectCiudad,
    changeSelectEstado,
    handlePageChange,
    handleSearchInput,
    handleDateChange,
    handleStartTimeChange,
    handleEndingTimeChange,
    activateFilter,
    getCaffenioListCalculations,
    cleanFilters,
    checkHasEmptyFields,
    ...props
}) {
    return(
        <div>

            <h1 className="title is-2">Reporte Extractos Café
            
            {/* <i className="fa-5x is-small"
                style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px", fontSize: "22px" }}
                className="fa fa-question-circle"
                onClick={() => {}}
            ></i> */}

            </h1>


            {/* 
                FILTRO DE BUSQUEDA
             */}

            <div className="card">
                <div className="card-content">
                    <div className="columns is-multiline">
                        <div className="column is-6">
                            {/*<label className="label">Nombre Drive</label>*/}
                            
                        </div>
                        <div className="column is-6">
                            {/*  */}
                        </div>
                        <div className="column is-4">
                            <label className="label">Estados</label>
                            <div className="control">
                                <Select
                                    isMulti
                                    placeholder={"Seleccionar..."}
                                    name={"selectedOption"}
                                    value={props.selectedOption}
                                    onChange={(e) => changeSelectEstado(e)}
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={props.optionState}
                                />
                            </div>
                        </div>
                        <div className="column is-4">
                            <label className="label">Ciudades</label>
                            <div className="control">
                                <Select
                                    isMulti
                                    placeholder={"Seleccionar..."}
                                    name={"selectedOptionCity"}
                                    value={props.selectedOptionCity}
                                    onChange={(e) => changeSelectCiudad(e)}
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={props.optionCity}
                                />
                            </div>
                        </div>
                        <div className="column is-4">
                            <label className="label">Drives</label>
                            <div className="control">
                                <Select
                                    isMulti
                                    placeholder={"Seleccionar..."}
                                    name={"selectedOptionDrive"}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={props.selectedOptionDrive}
                                    onChange={(e) => changeSelectDrive(e)}
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={props.optionDrive}
                                />
                            </div>
                        </div>

                        
                        <div className="column is-2">
                            <label className="label">Fecha</label>
                            <div className="control">
                                <span className="tooltip" data-tooltip="Fecha para filtrar calculos">
                                    <DatePicker
                                        locale="es"
                                        name={"selectedDate"}
                                        placeholderText="MM/DD/YYYY"
                                        selected={props.selectedDate}
                                        onChange={e => handleDateChange(e, 'selectedDate')}
                                        popperPlacement="right"
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: '5px, 10px'
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: 'viewport'
                                            }
                                        }}
                                        
                                        // withPortal
                                        dateFormat="dd/MM/yyyy"
                                        className="input"
                                        minDate={subDays(new Date(), 1095)}
                                        maxDate={subDays(new Date(), 0)}
                                    />
                                </span>
                            </div>
                        </div>

                        <div className="column is-2">
                            <label className="label">Hora Inicio</label>
                            <div className="control">
                                <span className="tooltip" data-tooltip="Hora inicio para filtrar calculos">
                                    <DatePicker
                                        locale="es"
                                        name={"startHour"}
                                        placeholderText="HH:mm"
                                        selected={props.startHour}
                                        onChange={e => handleStartTimeChange(e, 'startHour')}
                                        minTime={props.minStartHourLimit}
                                        maxTime={props.maxStartHourLimit}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        // onChangeRaw={(e) => e.preventDefault()}
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        dateFormat="HH:mm"
                                        className="input"
                                        timeCaption="Hora"
                                    />
                                </span>
                            </div>
                        </div>

                        <div className="column is-2">
                            <label className="label">Hora Fin</label>
                            <div className="control">
                                <span className="tooltip" data-tooltip="Hora fin para filtrar calculos">
                                    <DatePicker
                                        locale="es"
                                        name={"endingHour"}
                                        placeholderText="HH:mm"
                                        selected={props.endingHour}
                                        onChange={e => handleEndingTimeChange(e, 'endingHour')}
                                        minTime={props.minEndingHourLimit}
                                        maxTime={props.maxEndingHourLimit}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        dateFormat="HH:mm"
                                        className="input"
                                        timeCaption="Hora"
                                        // onChangeRaw={(e) => e.preventDefault()}
                                    />
                                </span>
                            </div>
                        </div>
                        
                            <div className='column is-2'>
                            <label className="label">  </label>
                            <br />
                                    <button
                                        disabled={checkHasEmptyFields() || props.isSubmitting}
                                        className={
                                            checkHasEmptyFields() ? 'button is-light' : 'button is-success'
                                        }
                                        onClick={() => getCaffenioListCalculations()}
                                    >
                                        Filtrar
                                    </button>
                            </div>
                            <div className='column is-2'>
                                <label className="label"> </label>
                                <br />
                                        <button
                                            className='button'
                                            onClick={() => cleanFilters()}
                                        >
                                            Limpiar filtros
                                        </button>
                            </div>
                        
                    </div>
                </div>
            </div>

            <br/>

            { props.filterIsActive &&
                <div className="card">
                    <div className="card-content">
                    {/* {props.calculationList[0].length !== 0 */}
                    {props.calculationListFlatened.length !== 0
                        ? 
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Nombre Drive</th>
                                    <th>Fecha</th>
                                    <th>Hora 24hr</th>
                                    <th>Cantidad proyectada</th>
                                    <th>Cantidad Vendida</th>
                                    <th>Diferencia</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {props.calculationList.map(calcList => {
                                    return calcList.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.NombreNuevo}</td>
                                                <td>{format(parseISO(item.Fecha), "dd/MM/yyyy")}</td>
                                                <td>{item.HoraInicio} - {item.HoraFin}</td>
                                                <td>{item.Calculo}</td>
                                                <td>{(item.CantidadVendida !== 0 || item.CantidadVendida == null) ? item.CantidadVendida : "-"}</td>
                                                <td>{(item.CantidadVendida !== 0 || item.CantidadVendida == null) ? (item.Calculo - item.CantidadVendida) : "-"}</td>
                                            </tr>
                                            
                                    ))
                                })
                                } */}

                                { props.pageCalculationList.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.NombreNuevo}</td>
                                            <td>{format(parseISO(item.Fecha), "dd/MM/yyyy")}</td>
                                            <td>{item.HoraInicio} - {item.HoraFin}</td>
                                            <td>{item.Calculo}</td>
                                            <td>{(item.CantidadVendida !== 0 || item.CantidadVendida == null) ? item.CantidadVendida : "-"}</td>
                                            <td>{(item.CantidadVendida !== 0 || item.CantidadVendida == null) ? (item.Calculo - item.CantidadVendida) : "-"}</td>
                                        </tr>
                                    )
                                })

                                }
                                
                            </tbody>
                        </table> 

                        : <NoItems itemName="cálculos"/>}
                        <Pagination items={props.calculationListFlatened} pageSize = {10} keepPage={true} onChangePage={(calculations) =>  handlePageChange(calculations) }  /*onChangePage={(items) => this.handlePageChange(items)}*/ />
                    </div>
                </div>
            }


            

            <Loading isFullscreen={true} isLoading={props.isLoading} width='100px' height='100px'/>
            <BtnModoGuiado />
        </div>

    );
}