import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class SaldoRegaloAPI {
    
    static getAll() {
        return middleware.hasAuth(() => request.get(`/SaldoRegalo`));
    }

    static create_list(list_clients, byLoyaltyAccount){
        return middleware.hasAuth(() => request.post(`/SaldoRegaloLista/${byLoyaltyAccount}`, list_clients));
    }

    static getFiltered(body){
        return middleware.hasAuth(() => request.post(`/SaldoRegalo/`, body));
    }

    static patch(id){
        return middleware.hasAuth(() => request.patch(`/SaldoRegalo/${id}`));
    }
    static remove(id){
        return middleware.hasAuth(() => request.delete(`/SaldoRegalo/${id}`));
    }
    
}