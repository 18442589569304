import React, { Component } from 'react';
import  SearchForm  from "../../util/searchform/SearchForm";
import  TableTemplate  from "../../util/tabletemplate/TableTemplate";
import  NoItems  from "../../util/noitems/NoItems";
import UnitsAPI from '../../../services/Units';
import { toast } from 'react-toastify';
import Modal from '../../util/modal/Modal';
import UnitForm from './UnitForm';
import AcceptModal from '../../util/acceptModal/AcceptModal';
import Pagination from '../../util/pagination/Pagination';
import Loading from '../../util/loading/Loading';

import ModoGuiado from "../../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../../util/validation-error-messages';
import lodash from 'lodash';
import roles from '../../../util/strings/roles';


class Units extends Component {
    units = [];

    constructor(props){
        super(props);
        this.state = {
            units: [],
            pageUnits: [],
            filterText: "",
            unit: null,
            showModal: false,
            removeModal: false,
            isLoading: false
        }
    }

    componentWillMount(){
        this.setState({isLoading: true}, () => {
            UnitsAPI.getAll().then(response => {
                this.setState({units: response.data, isLoading: false});
                this.units = response.data;
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message);
                else
                    toast.error(ErrorMessages.SinConexion);
            })
        });
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let units = this.units.filter(unit => unit.Name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        let state = this.state;
        state.filterText = text;
        state.units = units;
        this.setState(state);
    }

    create = () => {
        this.props.history.push({pathname: '/unidades/nueva', state: { units: this.state.units }});
    }

    edit = (unit) => {
        // unit.Name = unit.Name.trim();
        // unit.ShortName = unit.ShortName.trim();
        // if(unit.Name.length < 3){ 
        //     toast.error("Nombre debe de tener más de 2 caracteres");
        //     return;
        // }
        // if(unit.ShortName.length < 2){
        //     toast.error("Nombre Corto debe de tener más de 1 carácter");
        //     return;
        // }
        // if(unit.SatCode===null || unit.SatCode===0){
        //     toast.error("Selecciona un Código SAT");
        //     return;
        // }
        this.setState({isLoading: true});
        UnitsAPI.edit(unit, unit.UnitsId).then(response => {
            let state = {...this.state};
            state.units[state.units.findIndex(u => u.UnitsId === unit.UnitsId)] = unit;
            state.pageUnits[state.pageUnits.findIndex(u => u.UnitsId === unit.UnitsId)] = unit;
            state.showModal = false;
            state.isLoading = false;
            this.setState(state, () => {
                toast.success("Unidad actualizada");
            });
        }).catch(err => {
            this.setState({isLoading: false});
            if (err.response) {
                console.log(err.response.data.Message)
                toast.error(ErrorMessages.ErrorInesperado)
            } else {
                toast.error(ErrorMessages.SinConexion);
            }
        })
    }

    remove = (unit) => {
        let state = {...this.state};
        const loadingToast = toast.info("Cargando...", {autoClose: false, closeButton: false, className: "has-background-primary has-text-weight-bold"});
        UnitsAPI.delete(unit.UnitsId).then(response => {
            state.units.splice(state.units.findIndex(u => u.UnitsId === unit.UnitsId),1);
            state.pageUnits.splice(state.pageUnits.findIndex(u => u.UnitsId === unit.UnitsId),1);
            this.units.splice(this.units.findIndex(u => u.UnitsId === unit.UnitsId),1);
            state.removeModal = false;
            this.setState(state, () => {
                toast.dismiss(loadingToast);
                toast.success("Unidad eliminada");
            })
        }).catch(err => {
            toast.dismiss(loadingToast);
            state.removeModal = false;
            if (err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)           
            
            this.setState(state);
        })
    }

    render() {
        return (
            <div>

                <ModoGuiado 
                    ruta="unidades"
                    tipoModoGuiado="articulo"
                    siguiente='tipo-inventario' 
                    mensaje="Unidad de medida para recibir producto, almacenamiento y venta de artículos" />

                <h1 className="title is-2">Unidades</h1>
                <div className="columns is-vcentered">
                    <div className="column is-5">
                        <div className="card">
                            <div className="card-content">
                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'Unidad'}
                                            placeholderText = {"Ingrese nombre de unidad"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-7">
                        <div className="field">
                            <div className="control has-text-right">
                                {lodash.intersection(localStorage.getItem('role').split(","), [roles.ADMIN, roles.ADMINDRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES]).length > 0 &&
                                    <a id="btnAddUnit" className="button is-success" tabIndex="0" onClick={(e) => this.create()}>
                                        <span className="icon is-small">
                                            <i className="fa fa-plus"></i>
                                        </span>
                                        <span>Agregar Unidad</span>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>

                </div>

                <div className="card">
                    <div className="card-content" >
                        {this.state.units.length !== 0 
                        ? <TableTemplate
                            columns={["UnitsId","Name","ShortName", "SatCode"]}
                            columnsNames={["ID", "Nombre","Nombre Corto", "Código SAT"]}
                            data={this.state.pageUnits}
                            rowClickable={true}
                            details={(unit) => this.setState({showModal: true, unit: unit})}
                            canEdit canDelete 
                            edit={(unit) => this.setState({showModal: true, unit: unit})} 
                            remove={(unit) => this.setState({removeModal: true, unit: unit})}
                            
                        /> 
                        : <NoItems itemName="unidades"/>}
                        
                        <Pagination items={this.state.units} onChangePage={(units) => this.setState({ pageUnits: units })} />
                        
                        
                    </div>
                </div>
                {this.state.showModal && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Unidad"}
                        cancel={() => this.setState({showModal: false, unit: null})}
                    >
                        <UnitForm
                            unit={this.state.unit}
                            save={this.edit}
                            units={this.state.units}
                        />   
                    </Modal>
                }
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove(this.state.unit)}
                    cancel={() => this.setState({removeModal: false, unit: null})}
                    modalTitle={"Eliminar Unidad"}
                    modalBody={"¿Está seguro(a) de eliminar Unidad?"}
                />}
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />                

            </div>
        );
    }
}

export default Units;
