import React, { useEffect, useState } from 'react';

// Components
import NoItems from '../util/noitems/NoItems';
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

// Services
import HistorialAPI from '../../services/HistorialMovimientos';
import { toast } from 'react-toastify';

// Utils
import { jsonToXML } from '../../util/jsonToXML';
import { autoDownload } from '../../util/util';
import ExportExcel from "react-export-excel";

// Creamos instancias para el package export excel.
// Variable que indica el archivo de excel que se crea.
const ExcelFile = ExportExcel.ExcelFile;
// Variable que indica una hoja de excel.
const ExcelSheet = ExportExcel.ExcelSheet;
// Variable que indica una columna de excel.
const ExcelColumn = ExportExcel.ExcelColumn;

// Constants
const DATE_OPTIONS = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
const MOVIMIENTOS = [
    {
        value: 1,
        label: 'CREACION'
    },
    {
        value: 2,
        label: 'EDICION'
    },
    {
        value: 3,
        label: 'ELIMINACION'
    },
];
const MODULOS = [
    {
        value: 1,
        label: 'RECETAS',
    },
    {
        value: 2,
        label: 'LISTA DE PRECIOS',
    },
    {
        value: 3,
        label: 'MENU',
    },
];

/**
 * Módulo de historial de movimientos.
 * @returns Component
 */
export const HistorialMovimientos = () => {

    const [ state, setState ] = useState({
        pages: [],
        filters: {},
        isLoading: false,
        listHistorial: [],
    });

    useEffect(() => {
        setState({...state, isLoading: true});
        
        let historial = [];
        let pages = [];
        let filters = {};

        
        HistorialAPI.getHistorialMovimientos({...filters})
            .then(res => {
                pages = res.data;
                historial = res.data.map(hist => {
                    return {
                        ...hist,
                        Fecha: new Date(hist.Fecha).toLocaleDateString('es-MX', DATE_OPTIONS)
                    }
                });
            })
            .catch(() => toast.error("Ocurrio un error al obtener el historial"))
            .finally(() => setState({
                ...state,
                pages,
                isLoading: false,
                listHistorial: historial
            }));
    }, []);

    /**
     * Regresa la fecha con formato correcto para filtrar el historial.
     * @param {Date} dateToFormat - Fecha a formatear.
     * @returns String
     */
    const getFormatedDate = (dateToFormat = new Date()) => {
        let month = dateToFormat.getMonth()+1 //+1 para manejarlo los meses desde 1-12
        let day = dateToFormat.getDate()
        if ((month) < 10) {
            month = `0${month}`
        }
        if (day < 10) {
            day = `0${day}`
        }
        return `${dateToFormat.getFullYear()}${month}${day}`;
    }

    /**
     * Filtra el historial de movimientos.
     */
    const handlerFilterHistorial = () => {
        setState({...state, isLoading: true});
        let listHistorial = [];
        let filters = {};
        let pages = [];

        if (state.filters.modulo)
            filters.modulo = state.filters.modulo.label;
        if (state.filters.movimiento)
            filters.movimiento = state.filters.movimiento.label;
        if (state.filters.usuario)
            filters.usuario = state.filters.usuario.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
        if (state.filters.fechaInicio)
            filters.fechaInicio = getFormatedDate(state.filters.fechaInicio);
        if (!state.filters.fechaFin)
            filters.fechaFin = getFormatedDate();
        else
            filters.fechaFin = getFormatedDate(state.filters.fechaFin);

        if(filters.usuario === '' && filters.usuario !== undefined){
            delete filters.usuario
        }
        HistorialAPI.getHistorialMovimientos({ ...filters })
            .then(res => {
                pages = res.data;
                listHistorial = res.data.map(hist => {
                    return {
                        ...hist,
                        Fecha: new Date(hist.Fecha).toLocaleDateString('es-MX', DATE_OPTIONS)
                    }
                });
            })
            .catch(() => toast.error("Ocurrio un problema al filtrar el historial."))
            .finally(() => setState({
                ...state,
                isLoading: false,
                listHistorial,
                pages
            }));
    }

    /**
     * Reset de los filtros.
     */
    const handleCleanFilters = () => {
        setState({...state, isLoading: true});
        let listHistorial = [];
        let filters = {};
        let pages = [];

        HistorialAPI.getHistorialMovimientos({ ...filters})
            .then(res => {
                pages = res.data;
                listHistorial = res.data.map(hist => {
                    return {
                        ...hist,
                        Fecha: new Date(hist.Fecha).toLocaleDateString('es-MX', DATE_OPTIONS)
                    }
                });
            })
            .catch(() => toast.error("Ocurrio un problema al filtrar el historial."))
            .finally(() => setState({
                ...state,
                isLoading: false,
                listHistorial,
                filters: {
                    modulo: '',
                    usuario: '',
                    movimiento: '',
                    fechaInicio: null,
                    fechaFin: null
                },
                pages
            }));
    }

    /**
     * Maneja el cambio en el input de filtros.
     * @param {Event} e - Evento de input
     */
    const handleChangeInputFilters = e => {
        setState({
            ...state,
            filters: {
                ...state.filters,
                [e.target.name]: e.target.value
            }
        });
    }

    /**
     * Manejo de las fechas en los inputs.
     * @param {Date} date - Fecha a setear en los filtros
     * @param {Boolean} fechaInicio - Parametro para asignar a fecha inicio el valor.
     */
    const handleChangeDate = (date, fechaInicio = false) => {
        if ( fechaInicio ) {
            setState({
                ...state,
                filters: {
                    ...state.filters,
                    fechaInicio: date
                }
            });
        } else {
            setState({
                ...state,
                filters: {
                    ...state.filters,
                    fechaFin: date
                }
            });
        }
    }

    /**
     * Descarga el historial en un archivo XML.
     */
    const handleHistoryDownload = () => {
        const history = state.listHistorial;
        try {
            const [xmlFile, fileName] = jsonToXML(
                history,
                `Historial-${new Date().toLocaleDateString('es-MX', DATE_OPTIONS)}`
            );
            autoDownload(fileName, xmlFile, 'text/xml');
            toast.success("Archivo generado.");
        } catch (error) {
            toast.error("No se pudo generar el archivo XML.");
            console.error(error);
        }
    }

    return (
        <div>
            {
                state.isLoading &&
                <Loading
                    isFullscreen={true}
                    isLoading={state.isLoading}
                    width='100px'
                    height='100px'
                />
            }
            <h1 className='title is-2'>Historial de Movimientos</h1>
            <br />
            {/* Filtros */}
            <div className='card'>
                <div className='card-content'>
                    <div className='row'>
                        <div className='columns is-multiline'>
                            <div className='column is-4'>
                                <label className='label'>Módulo</label>
                                <div className='control'>
                                    <Select
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOption"}
                                        value={state.filters.modulo}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={item => setState({
                                            ...state,filters: {...state.filters, modulo: item}
                                        })}
                                        options={MODULOS}
                                    />
                                </div>
                            </div>
                            <div className='column is-4'>
                                <label className='label'>Usuario</label>
                                <div className='control'>
                                    <input
                                        name='usuario'
                                        type='text'
                                        className='input'
                                        value={state.filters.usuario}
                                        placeholder='Ingrese el nombre del usuario'
                                        onChange={handleChangeInputFilters}
                                    />
                                </div>
                            </div>
                            <div className='column is-4'>
                                <label className='label'>Movimiento</label>
                                <div className='control'>
                                    <Select
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOption"}
                                        value={state.filters.movimiento}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={item => setState({
                                            ...state,filters: {...state.filters, movimiento: item}
                                        })}
                                        options={MOVIMIENTOS}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='columns is-multiline'>
                            <div className='column is-3'>
                                <label className='label'>Fecha Inicio</label>
                                <DatePicker
                                    placeholderText='Seleccionar...'
                                    className='control'
                                    selected={state.filters.fechaInicio}
                                    maxDate={state.filters.fechaFin}
                                    onChange={date => handleChangeDate(date, true)}
                                    popperPlacement="bottom"
                                />
                            </div>
                            <div className='column is-3'>
                                <label className='label'>Fecha Fin</label>
                                <DatePicker
                                    placeholderText='Seleccionar...'
                                    className='control'
                                    selected={state.filters.fechaFin}
                                    minDate={state.filters.fechaInicio}
                                    onChange={date => handleChangeDate(date)}
                                    popperPlacement="bottom"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="has-text-right buttons-modal">
                        <div className="column">
                            <button
                                className='button is-success'
                                onClick={handlerFilterHistorial}
                                style={{ marginRight: "5px" }}
                            >
                                Filtrar
                            </button>
                                        
                            <button
                                className='button'
                                onClick={handleCleanFilters}
                                style={{ marginRight: "5px" }}
                            >
                                Limpiar Filtros
                            </button>

                            {state.listHistorial.length > 0 &&
                                <ExcelFile
                                    filename={`Historial-${new Date().toLocaleDateString('es-MX', DATE_OPTIONS)}`}
                                    element={
                                        <button className="button">
                                            <span className="icon">
                                                <i className="fa fa-file" />
                                            </span>
                                            <span>Exportar a EXCEL</span>
                                        </button>
                                    }
                                >
                                    <ExcelSheet data={state.listHistorial} name="listaHistorial" >
                                        <ExcelColumn label="Movimiento" value={(historial) => historial.Movimiento} />
                                        <ExcelColumn label="Fecha" value={(historial) => historial.Fecha} />
                                        <ExcelColumn label="Módulo" value={(historial) => historial.Modulo} />
                                        <ExcelColumn label="Usuario" value={(historial) => historial.Usuario} />
                                        <ExcelColumn label="Descripción" value={(historial) => historial.Descripcion} />
                                    </ExcelSheet>
                                </ExcelFile>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {/* Tabla de resultados */}
            <div className='card'>
                <div className='card-content'>
                    {
                        state.listHistorial.length !== 0 ?
                        <table className='table is-fullwidth'>
                            <thead>
                                <tr>
                                    <th>Movimiento</th>
                                    <th>Fecha</th>
                                    <th>Módulo</th>
                                    <th>Usuario</th>
                                    <th>Descripción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    state.pages.map(movimiento =>
                                        <tr key={movimiento.idMovimiento}>
                                            <td>{movimiento.Movimiento}</td>
                                            <td>{movimiento.Fecha}</td>
                                            <td>{movimiento.Modulo}</td>
                                            <td>{movimiento.Usuario}</td>
                                            <td>{movimiento.Descripcion}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        : <NoItems itemName='movimientos' />
                    }
                    <Pagination
                        items={state.listHistorial}
                        onChangePage={ movimientos  => setState({ ...state, pages: movimientos })}
                    />
                </div>
            </div>
        </div>
    )
}