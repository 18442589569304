import React, { Component } from 'react';
import  SearchForm  from "../../util/searchform/SearchForm";
import  TableTemplate  from "../../util/tabletemplate/TableTemplate";
import  NoItems  from "../../util/noitems/NoItems";
import Modal from '../../util/modal/Modal';
import AcceptModal from '../../util/acceptModal/AcceptModal';
import ClassificationForm from './ClassificationForm';
import Pagination from '../../util/pagination/Pagination';
import ClasificacionesAPI from '../../../services/Classification';
import { toast } from 'react-toastify';
import Loading from '../../util/loading/Loading';
import Validators from '../../../util/validators';


import ModoGuiado from "../../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../../util/validation-error-messages';
import roles from '../../../util/strings/roles';
import lodash from 'lodash';

class Classifications extends Component {
    classifications = [];

    constructor(props){
        super(props);
        this.state = {
            classifications: [],
            pageClassifications: [],
            filterText: "",
            classification: null,
            showModal: false,
            editing: false,
            removeModal: false,
            isLoading: false,
            
        }
    }

    componentWillMount(){
        this.setState({isLoading: true});
        ClasificacionesAPI.getAll().then(response => {
            this.setState({classifications: response.data, isLoading: false });
            this.classifications = response.data;
        }).catch(err => {
            toast.error("Ocurrió un error al obtener la información. Intenta más tarde");
            this.setState({isLoading: false});
        })
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let classifications = this.classifications.filter(classification => classification.Name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                .includes(text.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        let state = this.state;
        state.filterText = text;
        state.classifications = classifications;
        this.setState(state);
    }

    create = () => {
        this.props.history.push({pathname: '/clasificaciones/nueva', 
        state: { classifications: this.classifications }});        
    }

    edit = (classification) => {
        
            this.setState({isLoading: true}, ()=>{
                ClasificacionesAPI.edit(classification).then(response => {
                    let state = {...this.state};
                    //console.log(response);
                    state.classifications[state.classifications.findIndex(c => c.ClassificationId === classification.ClassificationId)] = response.data;
                    state.pageClassifications[state.pageClassifications.findIndex(c => c.ClassificationId === classification.ClassificationId)] = response.data;
                    this.classifications[this.classifications.findIndex(c => c.ClassificationId === classification.ClassificationId)] = response.data;
    
                    state.isLoading = false;
                    state.showModal = false;
                    state.classification = null;
        
                    this.setState(state, () => toast.success("Familia actualizada"));
                }).catch(err => {
                    this.setState({isLoading: false});
                    if(err.response)
                        toast.error(err.response.data.Message);
                    else
                        toast.error(ErrorMessages.SinConexion);
                })
            });
            
        
    }

    remove = () => {
        this.setState({isLoading: true});
        ClasificacionesAPI.delete(this.state.classification.ClassificationId).then(response => {
            let state = {...this.state};

            state.classifications.splice(state.classifications.findIndex(c => c.ClassificationId === state.classification.ClassificationId), 1);
            state.pageClassifications.splice(state.pageClassifications.findIndex(c => c.ClassificationId === state.classification.ClassificationId), 1);
            this.classifications.splice(this.classifications.findIndex(c => c.ClassificationId === state.classification.ClassificationId), 1);
            state.isLoading = false;
            state.removeModal = false;
            state.classification = null;
            toast.success("Familia eliminada");
            this.setState(state);
        }).catch(err => {
            toast.error(err.response.data.Message);
            this.setState({isLoading: false, classification: null, removeModal: false});
        })
    }

    pressEnter = (e) => {
        //debugger
        e.preventDefault();
        if(e.keyCode == 13){return false;}
        console.log(e);
    }

    render() {
        return (
            <div>

                <ModoGuiado 
                    ruta="clasificaciones"
                    tipoModoGuiado="receta"
                    siguiente='tipos-receta' 
                    mensaje="Familia a la que pertenece un producto terminado" />

                <h1 className="title is-2">Familia</h1>
                <div className="columns is-vcentered">
                    <div className="column is-5">
                        <div className="card">
                            <div className="card-content">

                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            keyPressEnter={this.pressEnter}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'Familia'}
                                            placeholderText ={"Ingrese nombre de Familia"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-7">
                        <div className="field">
                            <div className="control has-text-right">
                                {/* {localStorage.getItem('role').split(",").includes([roles.ADMIN, roles.ADMINDRIVE, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE]) && */}
                                {lodash.intersection(localStorage.getItem('role').split(","), [roles.ADMIN, roles.ADMINDRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES]).length > 0 &&
                                    <a id="btnAddClassification" className="button is-success" onClick={(e) => this.create()}>
                                        <span className="icon is-small">
                                            <i className="fa fa-plus"></i>
                                        </span>
                                        <span>Agregar Familia</span>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card">
                    <div className="card-content">
                        {this.state.classifications.length !== 0 
                        ? <TableTemplate
                            columns={["ClassificationId","Name","ShortName", "Position", "NombreSegmento"]}
                            columnsNames={["ID", "Nombre", "Nombre Corto", "Posición en Menú", "Segmento"]}
                            data={this.state.pageClassifications}
                            rowClickable={true}
                            details={(classification) => this.setState({showModal: true, classification: classification})}
                            canEdit canDelete 
                            edit={(classification) => this.setState({showModal: true, classification: classification})} 
                            remove={(classification) => this.setState({removeModal: true, classification: classification})}
                        /> 
                        : <NoItems itemName="clasificaciones"/>}
                        <Pagination items={this.state.classifications} onChangePage={(classifications) => this.setState({ pageClassifications: classifications })} />
                    </div>
                </div>
                {this.state.showModal && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Familia"}
                        cancel={() => this.setState({showModal: false, unit: null})}
                    >
                        <ClassificationForm
                            classification={this.state.classification}
                            classifications={this.state.classifications}
                            save={this.edit}
                            isEditing={true}
                        />   
                    </Modal>
                }
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove()}
                    cancel={() => this.setState({removeModal: false})}
                    modalTitle={"Eliminar Familia"}
                />}
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />                

            </div>
        );
    }
}

export default Classifications;
