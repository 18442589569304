import { isThisSecond } from 'date-fns/esm';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import UberEatsAPI from '../../services/ubereats';
import Loading from '../util/loading/Loading';
import RecipesRestaurantTable from './components/RecipesRestaurantTable';
import ToppingsRestaurantTable from './components/ToppingsRestaurantTable';
import UberEatsMenuRestaurantTable from './components/UberEatsMenuRestaurantTable';

export default class RestaurantDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            restaurant: {
                CaffenioRelRestauranteId:null,
                DriveId:null,
                Nombre:null,
                StoreId:null,
                Restaurante:null,
                idMenu:null,
                EnableCancelNotification:null,
                EnablePOSIntegration:null,
                Menu:null,
            },
            loading: false,
            categories: [],
            selectedTab: 0,
            loadingChangeIntegrationStatus: false,
            loadingUberEatsItemChange: -1,
            loadingCaffenioRecipeChange: -1
        }
    }

    componentDidMount(){
        this.getRestaurantInfo(this.props.match.params.id);
    }

    getRestaurantInfo = (id) => {
        this.setState({loading: true}, () => {
            let state = {...this.state}
            UberEatsAPI.getRestaurante(id).then(res => {
                state.restaurant = res.data;
                return UberEatsAPI.getUberEatsRestaurantMenu(id);
            }).then(res => {
                state.categories = res.data;
                state.loading = false;
                this.setState(state);
            }).catch(err => console.log(err));
        })
    }

    updateRestaurantInfo = () => {
        UberEatsAPI.getRestaurante(this.props.match.params.id).then(res => {
            this.setState({restaurant: res.data, loadingChangeIntegrationStatus: false});
        }).catch(err => console.log(err));
    }

    changeStatus = () => {
        this.setState({loadingChangeIntegrationStatus: true}, () => {
            if (!this.state.restaurant.EnablePOSIntegration){
                UberEatsAPI.activateRestaurant(this.props.match.params.id).then(res => {
                    toast.success("Restaurante activado correctamente");
                    this.updateRestaurantInfo()
                }).catch(err => console.log(err));
            }else{
                UberEatsAPI.inactivateRestaurant(this.props.match.params.id).then(res => {
                    toast.success("Restaurante desactivado correctamente");
                    this.updateRestaurantInfo()
                }).catch(err => console.log(err));
            }
        })
    }

    activateUberEatsItem = (item, index) => {
        this.setState({loadingUberEatsItemChange: index})
        if (item.Active) {
            UberEatsAPI.inactivateUberEatsItem(this.props.match.params.id, item.ItemId).then(res => {
                toast.success("Opción de Uber Eats desactivada correctamente");
                let state = {...this.state};
                var newItem = item;
                newItem.Active = false;
                state.categories[this.state.selectedTab].UberEatsMenu[index] = newItem;
                state.loadingUberEatsItemChange = -1;
                this.setState(state)
            }).catch(err => {
                toast.error("Ocurrió un error al desactivar la opción de Uber Eats");
                this.setState({loadingUberEatsItemChange: -1});
            });
        }else{
            UberEatsAPI.activateUberEatsItem(this.props.match.params.id, item.ItemId).then(res => {
                toast.success("Opción de Uber Eats activada correctamente");
                let state = {...this.state};
                var newItem = item;
                newItem.Active = true;
                state.categories[this.state.selectedTab].UberEatsMenu[index] = newItem;
                state.loadingUberEatsItemChange = -1;
                this.setState(state)
            }).catch(err => {
                toast.error("Ocurrió un error al activar la opción de Uber Eats");
                this.setState({loadingUberEatsItemChange: -1});
            });
        }
    }

    activateCaffenioRecipe = (recipe, index) => {
        this.setState({loadingCaffenioRecipeChange: index})
        if (recipe.Active){
            UberEatsAPI.inactivateCaffenioRecipe(this.props.match.params.id, recipe.RecipeId).then(res => {
                toast.success("Receta de Caffenio desactivada correctamente");
                let state = {...this.state};
                var newItem = recipe;
                newItem.Active = false;
                state.categories[this.state.selectedTab].RecipesCaffenio[index] = newItem;
                state.loadingCaffenioRecipeChange = -1;
                this.setState(state);
            }).catch(err => {
                toast.error("Ocurrió un error al desactivar la receta de Caffenio");
                this.setState({loadingCaffenioRecipeChange: -1});
            })
        }else{
            UberEatsAPI.activateCaffenioRecipe(this.props.match.params.id, recipe.RecipeId).then(res => {
                toast.success("Receta de Caffenio activada correctamente");
                let state = {...this.state};
                var newItem = recipe;
                newItem.Active = true;
                state.categories[this.state.selectedTab].RecipesCaffenio[index] = newItem;
                state.loadingCaffenioRecipeChange = -1;
                this.setState(state);
            }).catch(err => {
                toast.error("Ocurrió un error al activar la receta de Caffenio");
                this.setState({loadingCaffenioRecipeChange: -1});
            })
        }
    }

    render() {
        return (
            <div>
                <h1 className="title is-2">{this.state.restaurant.Restaurante}</h1>
                {!this.state.loading ? <span>
                    <div className="card">
                        <div className="card-content" >
                            <div className="columns is-multiline">
                                <div className="column is-3">
                                    <label className="label">Restaurante:</label>
                                    {this.state.restaurant.Restaurante}
                                </div>
                                <div className="column is-3"/>
                                <div className="column is-3">
                                    <label className="label">Menú:</label>
                                    {this.state.restaurant.idMenu}
                                </div>
                                <div className="column is-3"/>
                                <div className="column is-3">
                                    <label className="label">Drive:</label>
                                    {this.state.restaurant.Nombre}
                                </div>
                                <div className="column is-3"/>
                                <div className="column is-3">
                                    <label className="label">Integración en POS UberEats</label>
                                </div>
                                <div className="column is-3">
                                    <label className="label">{this.state.restaurant.EnablePOSIntegration ? "Activa" : "Inactiva"}</label>
                                    <button 
                                        className={`button ${this.state.loadingChangeIntegrationStatus ? 'is-loading' : ''} ${this.state.restaurant.EnablePOSIntegration ? "is-danger" : "is-success"}`}
                                        onClick={() => this.changeStatus()}
                                    >
                                        {this.state.restaurant.EnablePOSIntegration ? "Desactivar" : "Activar"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div className="tabs is-boxed">
                        <ul>
                            {this.state.categories.map((c, index) => <li key={`category${index}`} className={this.state.selectedTab == index ? "is-active" : ""}><a onClick={() => this.setState({selectedTab: index})}>{c.CategoryName}</a></li>)}
                        </ul>
                    </div>

                    <div className="card">
                        <div className="card-content">
                            <UberEatsMenuRestaurantTable 
                                loadingChangingStatus={this.state.loadingUberEatsItemChange}
                                activateUberEatsItem={this.activateUberEatsItem} 
                                data={this.state.categories[this.state.selectedTab] ? this.state.categories[this.state.selectedTab].UberEatsMenu : []}
                            />
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-content">
                            <RecipesRestaurantTable 
                                loadingChangingStatus={this.state.loadingCaffenioRecipeChange} 
                                activateCaffenioRecipe={this.activateCaffenioRecipe} 
                                data={this.state.categories[this.state.selectedTab] ? this.state.categories[this.state.selectedTab].RecipesCaffenio : []}
                            />
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-content">
                            <ToppingsRestaurantTable data={this.state.categories[this.state.selectedTab] ? this.state.categories[this.state.selectedTab].ToppingsCaffenio : []}/>
                        </div>
                    </div>
                </span> : <Loading isFullscreen={true} isLoading={this.state.downloading} width='100px' height='100px'/>}
            </div>
        )
    }
}