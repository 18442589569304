import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

var requestfile = axios.create({
    responseType: 'blob',
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class UberEatsAPI {

    static getRestaurantes(){
        return middleware.hasAuth(() => request.get(`UberEats/Restaurants`));
    }

    static getRestaurante(id){
        return middleware.hasAuth(() => request.get(`UberEats/Restaurants/${id}`));
    }

    static activateRestaurant(id){
        return middleware.hasAuth(() => request.put(`UberEats/Restaurants/${id}/ActivePOSIntegration`));
    }

    static inactivateRestaurant(id){
        return middleware.hasAuth(() => request.put(`UberEats/Restaurants/${id}/InactivePOSIntegration`));
    }

    static getUberEatsRestaurantMenu(id){
        return middleware.hasAuth(() => request.get(`UberEats/Restaurants/${id}/Menu`));
    }

    static activateUberEatsItem(id, itemId){
        var body = {ItemId: itemId};
        return middleware.hasAuth(() => request.post(`/UberEats/Menu/${id}/ActiveItem/`, body));
    }

    static inactivateUberEatsItem(id, itemId){
        var body = {ItemId: itemId};
        return middleware.hasAuth(() => request.post(`/UberEats/Menu/${id}/InactiveItem/`, body));
    }

    static activateCaffenioRecipe(id, recipeId){
        var body = {IsRecipe: true, RecipeId: Number.parseInt(recipeId)};
        return middleware.hasAuth(() => request.post(`/UberEats/CentralMenu/${id}/ActiveRecipe/`, body));
    }

    static inactivateCaffenioRecipe(id, recipeId){
        var body = {IsRecipe: true, RecipeId: Number.parseInt(recipeId)};
        return middleware.hasAuth(() => request.post(`/UberEats/CentralMenu/${id}/InactiveRecipe/`, body));
    }

    static getDrivesAvailables(){
        return middleware.hasAuth(() => request.get(`UberEats/Restaurants/DrivesAvailables`));
    }

    static deleteRestaurante(id){
        return middleware.hasAuth(() => request.delete(`UberEats/Restaurants/${id}`));
    }

    static createRestaurante(restaurante){
        return middleware.hasAuth(() => request.post(`UberEats/Restaurants/`,restaurante));
    }

    static downloadMenuReport(id){
        return middleware.hasAuth(() => requestfile.get(`UberEats/Restaurants/ValidateMenu/${id}`));
    }

    static changeStatusCancelNotification(data) {
        return middleware.hasAuth(() => request.put('UberEats/Restaurants/CancelNotification', data));
    }

    static changeStatusOrderRelease(data) {
        return middleware.hasAuth(() => request.put('UberEats/Restaurants/OrderReleaseNotification', data));
    }
 
}