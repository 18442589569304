import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
// Components
import Modal from '../util/modal/Modal';
import Field from '../util/field/Field';

const SCHEMA = Yup.object({
    Description: Yup.string().trim()
        .min(3, 'Requiere al menos 3 caracteres')
        .max(40, 'Ha excedido el límite de 40 caracteres.')
        .required("El campo 'Nombre' es requerido."),
    Percentage: Yup.number()
        .min(0, "Ingrese valores mayores a 0.")
        .max(1, 'Solo puede ingresar valores entre 0 a 1.')
        .required("El campo 'Porcentaje' es requerido."),
    TaxGP:Yup.string().trim()
    .min(3, 'Requiere al menos 3 caracteres')
    .max(15, 'Ha excedido el límite de 15 caracteres.')
    .required("El campo 'GP' es requerido."),
})

// Solo permite números, sin signos ni exponenciales
const onKeyPressNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-|e|E/.test(keyValue))
        event.preventDefault();
}

/**
 * Componente modal para editar o crear tasa de impuestos.
 * @param { Function } onClose - Funcion para cerrar el modal 
 * @returns 
 */
export const ModalTasaImpuesto = ({ impuesto, onClose, isEditing, onSave }) => {

    const [state, setState] = useState({
        isLoading: false,
    });

    return (
        <Modal
            showModal={true}
            modalTitle={`${isEditing ? "Editar tasa de impuesto" : "Nueva tasa de impuesto"}`}
            cancel={() => onClose()}
        >
            <Formik
                enableReinitialize={true}
                initialValues={impuesto || { Description: '', Percentage: '', TaxGP:'' }}
                validationSchema={SCHEMA}
                onSubmit={(values) => {
                    let valuesToSave = {...values};
                    valuesToSave.Description = values.Description.trim();
                    valuesToSave.TaxGP = values.TaxGP.trim();
                    onSave(isEditing, { ...valuesToSave })
                }}>
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                    <form onSubmit={handleSubmit} id="frmTasaImpuesto">
                        <div className="columns is-multiline">
                            <div className="column is-12">
                                <Field label="Nombre" isHorizontal
                                    hasError={errors.Description || touched.Description}
                                    msgError={errors.Description ? errors.Description : ""}
                                >
                                    <input
                                        className="input"
                                        label="Nombre"
                                        name="Description"
                                        value={values.Description}
                                        onChange={(e) => handleChange(e)}

                                    />
                                </Field>
                            </div>
                            <div className="column is-12">
                                <Field label="Porcentaje(%)" isHorizontal
                                    hasError={errors.Percentage || touched.Percentage}
                                    msgError={errors.Percentage ? errors.Percentage : ""}>
                                    <input
                                        className="input"
                                        type="number"
                                        min="0"
                                        max="1"
                                        step="0.01"
                                        label="Percentage"
                                        name="Percentage"
                                        onKeyPress={onKeyPressNumber}
                                        value={values.Percentage}
                                        onChange={(e) => handleChange(e)}

                                    />
                                </Field>
                            </div>

                            <div className="column is-12">
                                <Field label="GP" isHorizontal
                                    hasError={errors.TaxGP || touched.TaxGP}
                                    msgError={errors.TaxGP ? errors.TaxGP : ""}
                                >
                                    <input
                                        className="input"
                                        label="TaxGP"
                                        name="TaxGP"
                                        value={values.TaxGP}
                                        onChange={(e) => handleChange(e)}

                                    />
                                </Field>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='columns'>
                                <div className='column is-12 is-flex' style={{ justifyContent: 'flex-end' }}>
                                    <button type="submit" className='button is-success'>
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                )}

            </Formik>
        </Modal>

    )
}