import React, { useEffect, useState } from 'react';
import RecipeTypesAPI from '../../services/RecipeType';
import PresentationsAPI from '../../services/Presentations';
import MilksAPI from '../../services/Milk';
import GrainsAPI from '../../services/Grain';
import FlavorsAPI from '../../services/Flavor';
import ClasificacionesAPI from '../../services/Classification';
import RecipesAPI from '../../services/Recipe';
import { toast } from 'react-toastify';

import NoItems from '../util/noitems/NoItems';

import Select from 'react-select';
import lodash from 'lodash';

import ErrorMessages from '../../util/validation-error-messages';
import Loading from '../util/loading/Loading';
import {PromoProductsTable} from './components/PromoProductsTable';
import EmpresasAPI from '../../services/CaffenioEmpresas/Empresas';


const subtitle = {
    textAlign: 'initial',
    padding: '10px 10px 10px 0',
    fontSize: '16px',
    color: 'gray'
}

export function ProductosPromo({values, handleNextStep, handleLastStep , isBaseApply}) {
 
    const [ state, setState ] = useState({
        selectedRecipeTypes: null,
        selectedPresentations: null,
        selectedMilks: null,
        selectedGrains: null,        
        selectedFlavors: null,
        recipes:[],
        recipeTypeList: [],
        milksList: [],
        grainsList: [],
        flavorsList: [],
        presentationsList: [],
        classificationList: [],
        selectedClassification: null,
        origClassificationList: [],
        origRecipeTypesList : [],
        origFlavorsList : [],
        origGrainsList : [],
        origMilksList : [],
        origPresentationList : [],
        selectedProducts : values,
        isLoading: false,
        segmentos: [],
        segmentoSeleccionado: null,
    });

    useEffect(() => {
        
        setState({...state,isLoading: true})
        RecipeTypesAPI.getAll().then(response => {
            let temp = [];
            
            response.data.map(obj => {
                if(obj.RecipeName) {
                    temp.push({value: obj.RecipeTypeId, label: obj.RecipeName, ...obj})
                }else{
                    temp.push({value: obj.RecipeTypeId, label: obj.Description, ...obj})
                }
            })
            temp.sort(compare); // Ordenar los labels para elegir
            state.recipeTypeList = temp;
            state.origRecipeTypesList = lodash.cloneDeep(temp)
            
            // Obtener todas las presentaciones
            return PresentationsAPI.getAll()
        }).then(response => {
            let tempPresentationList = [];
            response.data.map(obj => {
                if(obj.Active){
                    tempPresentationList.push({value: obj.PresentationId, label: obj.Name, ...obj})
                }                
            })
            tempPresentationList.sort(compare); // Ordenar los labels
            state.presentationsList = tempPresentationList
            state.origPresentationList = lodash.cloneDeep(tempPresentationList)

            // Obtener los tipos de leche
            return MilksAPI.getAll()
        }).then(response => {
            let tempMilkList = [];
            response.data.map(obj => {
                tempMilkList.push({value: obj.MilkTypeId, label: obj.Description, ...obj})
            })
            tempMilkList.sort(compare); // Ordenar los labels
            state.milksList = tempMilkList
            state.origMilksList = tempMilkList
            
            // Obtener los tipos de grano
            return GrainsAPI.getAll()
        }).then(response => {
            let tempGrainList = [];
            response.data.map(obj => {
                tempGrainList.push({value: obj.GrainTypeId, label: obj.Description, ...obj})
            })
            tempGrainList.sort(compare); // Ordenar los labels
            state.grainsList = tempGrainList
            state.origGrainsList = lodash.cloneDeep(tempGrainList)
            
            // Obtener los sabores
            return FlavorsAPI.getAll()
        }).then(response => {
            let tempFlavorsList = [];
            response.data.map(obj => {
                tempFlavorsList.push({value: obj.FlavorTypeId, label:obj.Name, ...obj})
            })
            tempFlavorsList.sort(compare); // Ordenar los labels        
            state.flavorsList = tempFlavorsList
            state.origFlavorsList = tempFlavorsList
            
            // Obtener las clasificaciones
            return ClasificacionesAPI.getAll()
        }).then(response => {
            let tempClassification = [];
            response.data.map(obj => {
                tempClassification.push({value: obj.ClassificationId, label: obj.Name, ...obj})
            })
            tempClassification.sort(compare); // Ordenar los labels
            state.classificationList = tempClassification;
            state.origClassificationList = lodash.cloneDeep(tempClassification)
            state.selectedProducts.map(prod => {
                let fam = state.classificationList.filter(c=>c.ClassificationId === prod.idClasificacion);                
                prod.UrlImage = fam ? fam[0].UrlImage : '';
                return prod
            })
            return RecipesAPI.getAll()
            
        }).then(response => {
            state.recipes = response.data
            return EmpresasAPI.getAllFilterSegments()
        }).then(response => {
            let segmentosAux = []
            response.data.map(obj => {
                segmentosAux.push({value: obj.SegmentId, label: obj.Name})
            })
            state.segmentos = segmentosAux
            state.isLoading = false;
            // Guardar el estado
            setState(state)
        })
        .catch(err => {
            setState({...state,isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    },[]);

    const filterOptionsByClasification = () => {
        let clasificaciones = state.selectedClassification ? [state.selectedClassification.value] : []
        let recipeTypeListByClass = [];
        let flavorsListByClass = [];
        let grainsListByClass = [];
        let milksListByClass = [];
        let presentationsListByClass = [];
        state.isLoading = false;
        state.selectedRecipeTypes = [];
        state.selectedPresentations = [];
        state.selectedMilks = [];
        state.selectedGrains = [];        
        state.selectedFlavors = [];

        if(clasificaciones.length > 0 ){
            // Filtrar los tipos de receta
            RecipeTypesAPI.getByClasification(clasificaciones).then(response => {                
                let temp = response.data.map(obj => {
                    return {value: obj.RecipeTypeId, label: obj.RecipeName ? obj.RecipeName : obj.Description, ...obj}
                })
                recipeTypeListByClass = temp
                
                // Filtrar los sabores
                return FlavorsAPI.getByClasification(clasificaciones)
            }).then(response => { 
                let tempFlavorsList = response.data.map(obj => {
                    return {value: obj.FlavorTypeId, label:obj.Name, ...obj}
                })
                tempFlavorsList.sort(compare); // Ordenar los labels        
                flavorsListByClass = tempFlavorsList
                
                // Filtrar los granos de cafe
                return GrainsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempGrainList = response.data.map(obj => {
                    return {value: obj.GrainTypeId, label: obj.Description, ...obj}
                })
                tempGrainList.sort(compare); // Ordenar los labels
                grainsListByClass = tempGrainList
                
                // Filtrar los tipos de leche
                return MilksAPI.getByClasification(clasificaciones)
            })
            .then(response => {
                let tempMilkList = response.data.map(obj => {
                    return {value: obj.MilkTypeId, label: obj.Description, ...obj}
                })
                tempMilkList.sort(compare); // Ordenar los labels
                milksListByClass = tempMilkList
                
                // Filtrar las presentaciones
                return PresentationsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempPresentationList = response.data.map(obj => {
                    return {value: obj.PresentationId, label: obj.Name, ...obj}
                })
                tempPresentationList.sort(compare); // Ordenar los labels
                presentationsListByClass = tempPresentationList

                setState({...state, 
                    recipeTypeList : recipeTypeListByClass,
                    flavorsList : flavorsListByClass,
                    grainsList : grainsListByClass,
                    milksList : milksListByClass,
                    presentationsList : presentationsListByClass
                })
            }).catch(error => {
                setState({...state, 
                    recipeTypeList : recipeTypeListByClass,
                    flavorsList : flavorsListByClass,
                    grainsList : grainsListByClass,
                    milksList : milksListByClass,
                    presentationsList : presentationsListByClass

                })
            });
        }else{            
            setState({...state,
                recipeTypeList: lodash.cloneDeep(state.origRecipeTypesList),
                flavorsList: lodash.cloneDeep(state.origFlavorsList),
                grainsList: lodash.cloneDeep(state.origGrainsList),//Donde se va a guardar la lista original de granos
                milksList: lodash.cloneDeep(state.origMilksList),//Donde se va a guardar la lista original de leches
                presentationsList: lodash.cloneDeep(state.origPresentationList),//Donde se va a guardar la lista original de presentaciones
            })
        }

        
    }

    const handleSelectProduct = () => {
        if(!state.selectedClassification){
            toast.error("No se ha seleccionado ninguna familia.")
            return;
        }

        if(state.selectedProducts.some(p => p.idClasificacion === state.selectedClassification.value 
            && (!p.idTipoReceta || (state.selectedRecipeTypes && state.selectedRecipeTypes.value === p.idTipoReceta))
            && (!p.idTipoSabor || (state.selectedFlavors && state.selectedFlavors.value === p.idTipoSabor))
            && (!p.idTipoGrano || (state.selectedGrains && state.selectedGrains.value === p.idTipoGrano))
            && (!p.idTipoLeche || (state.selectedMilks && state.selectedMilks.value === p.idTipoLeche))
            && (!p.idPresentacion ||(state.selectedPresentations && state.selectedPresentations.value === p.idPresentacion))
                )){
                toast.error("La selección ya esta incluida.")
                return;
            }

        let value = {
            UrlImage : state.selectedClassification ? state.selectedClassification.UrlImage : null,
            producto : state.selectedClassification ? state.selectedClassification.label : '',
            tipoReceta : state.selectedRecipeTypes ? state.selectedRecipeTypes.label : '',
            Sabor : state.selectedFlavors ? state.selectedFlavors.label : '',
            Grano : state.selectedGrains ? state.selectedGrains.label : '',
            Leche : state.selectedMilks ? state.selectedMilks.label: '',
            Presentacion :  state.selectedPresentations ? state.selectedPresentations.label : '',
            idClasificacion : state.selectedClassification ? state.selectedClassification.value : null,
            idTipoReceta : state.selectedRecipeTypes ? state.selectedRecipeTypes.value : null,
            idTipoSabor : state.selectedFlavors ? state.selectedFlavors.value : null,
            idTipoGrano : state.selectedGrains ? state.selectedGrains.value : null,
            idTipoLeche : state.selectedMilks ? state.selectedMilks.value : null,
            idPresentacion : state.selectedPresentations ? state.selectedPresentations.value : null,
            isBase:false
        }

        values.push(value)
        setState({...state, 
            selectedProducts : values,
            recipeTypeList: lodash.cloneDeep(state.origRecipeTypesList),
            flavorsList: lodash.cloneDeep(state.origFlavorsList),
            grainsList: lodash.cloneDeep(state.origGrainsList),//Donde se va a guardar la lista original de granos
            milksList: lodash.cloneDeep(state.origMilksList),//Donde se va a guardar la lista original de leches
            presentationsList: lodash.cloneDeep(state.origPresentationList),//Donde se va a guardar la lista original de presentaciones
            selectedRecipeTypes: null,
            selectedPresentations: null,
            selectedMilks: null,
            selectedGrains: null,        
            selectedFlavors: null,
            selectedClassification : null
        }) 
          
        
    }

    /**
     * Marca registro is base
     * @param {*} index 
     */
    const setIsBase = (index) => {
        let tmp = state.selectedProducts;

        tmp[index].isBase = !tmp[index].isBase 

        setState({
            ...state,
            selectedProducts:tmp})
    
    }

    /**
     * Quita del listado de productos seleccionados
     * @param {*} index 
     */
    const handleDeleteProducto = (index) => {        
        values.splice(index,1)
        setState({...state, selectedProducts : values})
    }    

    /**
     * Funcion para ordenar lexicograficamente
     * @param objA. primer operando
     * @param objB. segundo operando
     */
    const compare = (objA, objB) => {
        // Use toUpperCase() to ignore character casing
        const labelA = objA.label.toUpperCase();
        const labelB = objB.label.toUpperCase();

        let comparison = 0;
        if (labelA > labelB) {
            comparison = 1;
        } else if (labelA < labelB) {
            comparison = -1;
        }
        return comparison;
    }

    /**
     * Setea los filtros seleccionados y consulta las recetas que cumplen 
     * @param {*} val 
     * @param {*} event 
     */
    const handleInputChange = (val, event) => {
        state[event.name] = val;
        state.isLoading = event.name === 'selectedClassification' && val;
        setState({
            ...state,            
        })
        if(event.name === 'segmentoSeleccionado'){
            let classificationListAux = state.origClassificationList.filter(x => x.idSegmento === state.segmentoSeleccionado.value)
            setState({...state,
                 classificationList: classificationListAux,
                 selectedClassification: null,
                 selectedRecipeTypes: null,
                 selectedFlavors: null,
                 selectedGrains: null,
                 selectedGrains: null,
                 selectedPresentations: null
                })
        }   
        if(event.name === 'selectedClassification'){
            filterOptionsByClasification()
        }        
    }

    /**
     * 
     * @returns Obtiene el número de recetas que aplican a la selección
     */
    const getRecipesNumber = () => {
        let anySelected = state.selectedClassification && state.selectedClassification.value;
        let counter = 0;
        if(anySelected)
        {
            let recipes = state.recipes.filter(x => x.idSegmento === state.segmentoSeleccionado.value)
            recipes = recipes.filter(
            recipe => ( 
                (state.selectedRecipeTypes && state.selectedRecipeTypes.value ? 
                state.selectedRecipeTypes.value === recipe.RecipeTypeId : 
                true) &&
                (state.selectedFlavors && state.selectedFlavors.value ? 
                state.selectedFlavors.value === recipe.FlavorTypeId : 
                true) &&
                (state.selectedPresentations && state.selectedPresentations.value ? 
                state.selectedPresentations.value === recipe.PresentationId : 
                true) &&
                (state.selectedMilks && state.selectedMilks.value ? 
                state.selectedMilks.value === recipe.MilkTypeId : 
                true) &&
                (state.selectedGrains && state.selectedGrains.value ? 
                state.selectedGrains.value === recipe.GrainTypeId: 
                true) &&
                (state.selectedClassification ? 
                state.selectedClassification.value === recipe.ClassificationId : 
                true) 
            )
        );
        counter = recipes.length
        }
        
        return counter
    }

    /**
     * Obtiene el número de recetas de la CARD 
     * @param {*} selection 
     * @returns 
     */
    const getCadRecipesNumber = (selection) => {
        let counter = 0;
        let recipes = state.recipes.filter(
            recipe => ( 
                (selection.idClasificacion ?  selection.idClasificacion === recipe.ClassificationId : true) &&
                (selection.idTipoReceta  ? selection.idTipoReceta === recipe.RecipeTypeId : true) &&
                (selection.idPresentacion ? selection.idPresentacion === recipe.PresentationId : true) &&
                (selection.idTipoLeche ? selection.idTipoLeche === recipe.MilkTypeId : true) &&
                (selection.idTipoGrano ? selection.idTipoGrano === recipe.GrainTypeId: true) &&
                (selection.idTipoSabor ? selection.idTipoSabor === recipe.FlavorTypeId : true) 
            )
        );
        counter = recipes.length
        return counter
    }

    return (
        <div className='form-group'>
            {/* Filtro multiple para recetas */}
            
            <div className="row">
                <div className="columns is-multiline">
                    <div className="column is-4"></div>
                    <div className="column is-4">
                        <label className="label">Segmentación</label>
                        <Select
                            id={"ddlSegmentation"}
                            placeholder={"Seleccionar..."}
                            value={state.segmentoSeleccionado}
                            name={"segmentoSeleccionado"}
                            onChange={(value, e) => handleInputChange(value, e)}
                            noOptionsMessage={() => "No hay opciones"}
                            options={state.segmentos}
                            getOptionLabel={(segment) => segment.value === 1 ? "Caffenio"
                                : segment.value === 9 ? "Andatti"
                                    : segment.value === 10 ? "Momento Espresso"
                                        : segment.value}
                        />
                    </div>
                    <div className="column is-4"></div>
                    <div className="column is-4">
                        <label className="label">Familia</label>
                        <Select
                            id={"ddlClasificacion"}
                            placeholder={"Seleccionar..."}
                            value={state.selectedClassification}
                            name={"selectedClassification"}
                            onChange={(value, e) => handleInputChange(value, e)}
                            noOptionsMessage={() => "No hay opciones"}
                            options={state.classificationList}
                            isClearable={true}
                            isDisabled={!state.segmentoSeleccionado}
                        />
                    </div>

                    <div className="column is-4">
                        <label className="label">Tipo Receta</label>
                        <Select
                            id={"ddlTipoReceta"}
                            placeholder={"Seleccionar..."}
                            name={"selectedRecipeTypes"}
                            noOptionsMessage={() => "No hay opciones"}
                            value={state.selectedRecipeTypes}
                            options={state.recipeTypeList}
                            onChange={(value, e) => handleInputChange(value, e)} 
                            isDisabled ={!state.selectedClassification}                           
                        />
                    </div>

                    <div className="column is-4">
                        <label className="label">Tipo Sabor</label>
                        <Select
                            id={"ddlTipoSabor"}
                            placeholder={"Seleccionar..."}
                            name={"selectedFlavors"}
                            noOptionsMessage={() => "No hay opciones"}
                            value={state.selectedFlavors}
                            options={state.flavorsList}
                            onChange={(value, e) => handleInputChange(value, e)}
                            isDisabled ={!state.selectedClassification}
                            
                        />
                    </div>
                    
                    <div className="column is-4">
                        <label className="label">Tipo Grano</label>
                        <Select
                            id={"ddlTipoGrano"}
                            placeholder={"Seleccionar..."}
                            name={"selectedGrains"}
                            noOptionsMessage={() => "No hay opciones"}
                            options={state.grainsList}
                            value={state.selectedGrains}
                            onChange={(value, e) => handleInputChange(value, e)}
                            isDisabled ={!state.selectedClassification}
                            
                        />
                    </div>
                    
                    <div className="column is-4">
                        <label className="label">Tipo Leche</label>
                        <Select
                            id={"ddlTipoLeche"}
                            placeholder={"Seleccionar..."}
                            name={"selectedMilks"}
                            noOptionsMessage={() => "No hay opciones"}
                            options={state.milksList}
                            value={state.selectedMilks}
                            onChange={(value, e) => handleInputChange(value, e)}
                            isDisabled ={!state.selectedClassification}
                            
                        />
                    </div>

                    <div className="column is-4">
                        <label className="label">Presentación</label>
                        <Select
                            id={"ddlTamano"}
                            placeholder={"Seleccionar..."}
                            name={"selectedPresentations"}
                            noOptionsMessage={() => "No hay opciones"}
                            options={state.presentationsList}
                            value={state.selectedPresentations}
                            onChange={(value, e) => handleInputChange(value, e)}
                            isDisabled ={!state.selectedClassification}
                            
                        />
                    </div>

                </div>
            </div>
            <br />
            <div className='row'>
                <div className='column is-12 is-flex' style={{justifyContent: 'flex-end'}}>
                    <button className='button is-success' onClick={handleSelectProduct} disabled={getRecipesNumber() === 0}>
                       {`Agregar ${getRecipesNumber()} Recetas`}  
                    </button>
                </div>
            </div>
            <div className='row'>
                <div className="columns is-multiline">
                    {
                        state.selectedProducts && state.selectedProducts.length > 0 
                        ? <PromoProductsTable 
                        canDelete={true}
                        showImage={true}
                        selectedProducts={state.selectedProducts} 
                        handleDeleteProducto={(index) => handleDeleteProducto(index )}
                        setIsBase={(index) => setIsBase(index)}
                        getCadRecipesNumber={getCadRecipesNumber}
                        isBaseApply={isBaseApply}/> 
                        : <div className="column is-12"><NoItems itemName="Productos agregados" /></div>
                    }
                </div>                        
            </div>
            <br/>
            <div className='row' style={{ padding: '10px' }}>
                <div className='columns'>
                    <div className='column is-12'>
                        <div className="has-text-left buttons-modal" id="stepActions">
                            <div className="pull-left">
                                <button
                                    className='button'
                                    style={{ marginRight: 10 }}
                                    onClick={handleLastStep}
                                >
                                    Anterior
                                </button>

                            </div>
                        </div>
                        <div className="has-text-right buttons-modal" id="stepActions">
                            <div className="pull-right">
                                <button
                                    className='button  is-success'
                                    style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        if (values && values.length > 0) {
                                            handleNextStep();
                                        } else {
                                            toast.error("Seleccione al menos un producto donde aplique la promoción.")
                                        }
                                    }}
                                // disabled={ `${ state.currentStep === steps.resumen ? true : false }` }
                                >
                                    Continuar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
           
            <Loading isFullscreen={true} isLoading={state.isLoading} width='100px' height='100px' />
        </div>
        
    )
}
