import React, { Component } from 'react';
import {toast} from 'react-toastify';
import MilkForm from './MilkForm';
import Validators from '../../../util/validators';
import MilksAPI from '../../../services/Milk';
import '../../cssComponents.css';
import Loading from '../../util/loading/Loading';
import ErrorMessages from '../../../util/validation-error-messages';
import Modal from '../../util/modal/Modal';

class NewMilk extends Component {
    constructor(props){
        super(props);
        this.state = {
            milks:[],
            milk: {...this.props.milk},
            isLoading: false
        };
    }

    componentDidMount(){
        if (!this.props.location.state) {
            this.props.history.push(`/leches`);
            return;
        }
        this.setState({ milks: this.props.location.state.milks })
    }

    /**Función para guardar nuevo tipo de leche  */
    save = (milk) => {      
        milk.Activo = true;
        milk.RecipeName = milk.Description;  
        this.setState({isLoading: true}, () => {
            MilksAPI.create(milk).then(response => {
                this.setState({isLoading: false})
                this.props.history.goBack();
                toast.success("Tipo Leche creado");
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        })
      
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{cursor: "pointer", color: "#91BA43" }} onClick={() => {this.props.history.goBack()}} className="fa fa-arrow-left"></i>
                            </span>
                            <div>  Nuevo Tipo Leche
                                <i className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }} onClick={() => this.setState({ showModal: true })} className="fa fa-question-circle"></i>
                            </div>
                           
                        </div>
                    </div>
                    <div className="card-content">
                        <MilkForm save={this.save} milks={this.state.milks}/>
                    </div>
                </div>
                {this.state.showModal &&
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Información de Tipo Leche"}
                        cancel={() => this.setState({ showModal: false })}
                    >
                        <div className="has-text-justified">
                        
                        Los tipos de leche están directamente relacionadas a las recetas, se usa para identificar las 
                        recetas por tipo de leche en caso de que se requiera.
                        <br/>
                        En los dispositivos del punto de venta se visualizan como una selección más antes de agregar el producto a la orden.
                            <hr/>
                            <p className="has-text-justified">
                                <strong>Nombre</strong>: es un campo requerido, 
                                debe tener una longitud mínima de 2 a 30 caracteres máximo
                                 y no se debe duplicar.                     
                            </p>
                            
                            <p className="has-text-justified">
                                <strong>Posición en Menú</strong>: valor numérico que representa el orden de visualización en los dispositivos de izquierda a derecha.
                                Debe ser mayor a 0, y no se aceptan duplicados.
                            </p>

                            <p className="has-text-justified">
                                <strong>Imagen</strong>: la imagen será la que se muestre en los puntos de venta, debe ser formato png y es requerido.
                            </p>
                        </div>
                       

                    </Modal>
                }
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
            </div>
        );
    }
}

export default NewMilk;
