import React, { Component } from 'react';
import  SearchForm  from "../../util/searchform/SearchForm";
import  TableTemplate  from "../../util/tabletemplate/TableTemplate";
import  NoItems  from "../../util/noitems/NoItems";
import Modal from '../../util/modal/Modal';
import AcceptModal from '../../util/acceptModal/AcceptModal';
import Pagination from '../../util/pagination/Pagination';
import Loading from '../../util/loading/Loading';
import InventoryItemsAPI from '../../../services/InventoryItem';
import { toast } from 'react-toastify';
import ModoGuiado from "../../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../../util/BtnModoGuiado/BtnModoGuiado'
import Select from 'react-select';
import CaffenioAPI from '../../../services/Warehouse';//Obtenemos los Drives
import Field from '../../util/field/Field';
import ErrorMessages from '../../../util/validation-error-messages';
import TextField from '../../util/textfield/TextField';
import lodash from 'lodash';


class ToppingsExtractos extends Component {
    inventoryItems = [];

    constructor(props){
        super(props);
        this.state = {
            inventoryItems: [],
            pageInventoryItems: [],
            filterText: "",
            inventoryItem: null,
            showModal: false,
            editing: false,
            removeModal: false,
            isLoading: false,
            Caffenios: [],
            selectedOption: [],
            selectedOptionC: [],
            selectedOptionD: [],
            OptionSelect: [],
            OptionSelectCity: [],
            OptionSelectDrive: [],
            drivesArr: [],
            checkAll: false,
            updateMasive: false,
            confirmModalUpdatePrice: false,
            priceMassive: null,
            buttonSelectAll: false,
            selectedToppingsObjList: [...this.props.selectedToppings],
            //modal para actualizar precio individualmente 
            modalUpdatePrice:false
        }
    }

    componentWillMount(){

        this.setState({isLoading: true});
        /**
         * Obtenemos todos los toppings
         */

         let state = {...this.state};
        InventoryItemsAPI.getToppings().then(response => {
            
            state.inventoryItems = response.data;
            state.inventoryItems.map(item => {
                item.statusCheck = false;
            })

            let auxCount = 0;
            state.inventoryItems.findIndex(obj => {
                let isSelected = this.state.selectedToppingsObjList.findIndex(selectedTopping => selectedTopping.InventoryItemId === obj.InventoryItemId)
                if (isSelected != -1) {
                    obj.statusCheck = true;
                    auxCount++;
                }
                // else {
                //     obj.statusCheck = false;
                // }
                return auxCount == this.state.selectedToppingsObjList.length;
            })
            this.inventoryItems = lodash.cloneDeep(response.data);

            console.log("El state despues de la carga >>", JSON.parse(JSON.stringify(state)));
            this.setState(state);



        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        });
    }
    /**
     * Método encargado de recibir el texto en el input de búsqueda
     * y filtrar los elementos en la tabla
     */
    handleSearchInput = (text) => {
        let state = {...this.state};
        state.filterText = text;
        this.setState(state, () => this.FilterProcess());
    }

    /**
     * Funcion para filtrar los articulos inventarios mediante
     * Estados, Ciudad y Drive
     */
    FilterProcess = () => {
        let state = {...this.state}

        let inventoryItemsArray = this.inventoryItems.filter(
            item => ( 
                (this.state.filterText.trim() !== '' ?
                ( (item.Name 
                    ? item.Name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) 
                    : false) 
                    || item.InventoryCode.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                : true)
            )
        );

        state.inventoryItems = lodash.cloneDeep(inventoryItemsArray);

        let auxCount = 0;
        state.inventoryItems.findIndex(obj => {
            let isSelected = this.state.selectedToppingsObjList.findIndex(selectedTopping => selectedTopping.InventoryItemId === obj.InventoryItemId)
            if (isSelected != -1) {
                obj.statusCheck = true;
                auxCount++;
            }
            return auxCount == this.state.selectedToppingsObjList.length;
        })
        //state.selectedRecipes = [];
        state.buttonSelectAll = false;
        this.setState(state);
    }

     /**
     * Funcion que se ejecuta al darle click al checkbox del header
     */
      selectAllCheck = () => {
        let _checkAll = !this.state.checkAll
        let _selectedRecipes = [...this.state.selectedRecipes]
        let _pageRecipes = this.state.pageRecipes
        _pageRecipes.forEach(recipe => {
            let indexRecipe = _selectedRecipes.findIndex(item => item.RecipeId === recipe.RecipeId)
            recipe.statusCheck = _checkAll
            if(_checkAll){
                if(indexRecipe === -1) _selectedRecipes.push(recipe)
            }else{
                if(indexRecipe !== -1) _selectedRecipes.splice(indexRecipe, 1)
            }

        })

        this.setState({checkAll: _checkAll, selectedRecipes: _selectedRecipes})
    }

    /*
    * Funcion seleccionar o des seleccionar todos las recetas
    * de la pagina actual.
    */
   selectAllCheckBoxPage = () => {
        let _checkAll = !this.state.checkAll
        let _selectedToppingsObjList = [...this.state.selectedToppingsObjList]
        let _pageToppings = this.state.pageInventoryItems

        _pageToppings.forEach(topping => {

            let indexTopping = _selectedToppingsObjList.findIndex(item => item.InventoryItemId === topping.InventoryItemId);
            topping.statusCheck = _checkAll;
            if (_checkAll) {
                if (indexTopping === -1) _selectedToppingsObjList.push(topping)
            }else {
                if (indexTopping !== -1) _selectedToppingsObjList.splice(indexTopping , 1)
            }
        })

        this.setState({
            selectedToppingsObjList: _selectedToppingsObjList, 
            updateMasive: _selectedToppingsObjList.length > 0,
            checkAll: _checkAll,
            buttonSelectAll: _selectedToppingsObjList.length === this.state.inventoryItems.length
        })
    }
    /*
    * Funcion para saber si hay que mostrar
    * un switch y boton para aplicar cambios a las
    * recetas que hemos marcado.
    */
    showView = (index) => {
        // Utilizar indice compuesto InventoryItemId + "-" + idListaPrecio
        let _selectedToppingsObjList = [...this.state.selectedToppingsObjList];
        // let indexTopping = this.state.selectedToppingsObjList.findIndex(id => id === String(this.state.pageInventoryItems[index].InventoryItemId) + "-" + String(this.state.pageInventoryItems[index].idListaPrecio))
        let indexTopping = this.state.selectedToppingsObjList.findIndex(id => id.InventoryItemId === this.state.pageInventoryItems[index].InventoryItemId);

        if (indexTopping === -1) {
            //_selectedToppingsObjList.push(String(this.state.pageInventoryItems[index].InventoryItemId) + "-" + String(this.state.pageInventoryItems[index].idListaPrecio))
            _selectedToppingsObjList.push(this.state.pageInventoryItems[index]);
        } else {
            _selectedToppingsObjList.splice(indexTopping, 1)
        }

        let counter = 0; // variable para comparar los elementos seleccionados en la pagina con la cantidad de elementos por pagina
        this.state.pageInventoryItems.map(obj => {
            if (obj.statusCheck) counter++
        })

        this.setState({
            selectedToppingsObjList: _selectedToppingsObjList, 
            updateMasive: _selectedToppingsObjList.length > 0,
            buttonSelectAll: _selectedToppingsObjList.length === this.state.inventoryItems.length,
            checkAll: counter === this.state.pageInventoryItems.length
        })
    }
    /*
    * Funcion que selecciona o des selecciona todas las
    * recetas de todas las paginas.
    */
    selectAllCheckBox = () => {
        let _selectedToppingsObjList = [...this.state.selectedToppingsObjList]
        let _toppings = this.state.inventoryItems
        let _buttonSelectAll = !this.state.buttonSelectAll;

        _toppings.forEach(topping => {
            // Ver si el elemento ya esta en la lista de seleccioandos
            let indexTopping = _selectedToppingsObjList.findIndex(item => item.InventoryItemId === topping.InventoryItemId)
            // Meter el elemento a la lista si no estaba previamente
            if (_buttonSelectAll && indexTopping === -1) {
                _selectedToppingsObjList.push(topping)
            // Sacar al elemento de la lista si ya estaba
            } else if (!_buttonSelectAll && indexTopping !== -1) {
                _selectedToppingsObjList.splice(indexTopping, 1)
            }

            topping.statusCheck = _buttonSelectAll;
        })

        let _checkAll       = _buttonSelectAll;

        this.setState({
            selectedToppingsObjList: _selectedToppingsObjList, 
            updateMasive: _selectedToppingsObjList.length > 0,
            checkAll: _checkAll,
            buttonSelectAll: _buttonSelectAll
        })
    }


    /**
     * Checa si es decimal un numero
     * @param {strin}  val - precio a actualizar
     * @param {int}  decimalPlaces - numero de decimales
     */
    isNumericDecimal = (val, decimalPlaces) => {
        // If the last digit is a . then add a 0 before testing so if they type 25. it will be accepted
        var lastChar = val.substring(val.length - 1);
        if (lastChar == ".") val = val + "0";

        var objRegExp = new RegExp("^\\s*-?(\\d+(\\.\\d{1," + decimalPlaces + "})?|\\.\\d{1," + decimalPlaces + "})\\s*$", "g");
        if (decimalPlaces == -1)
            objRegExp = new RegExp("^\\s*-?(\\d+(\\.\\d{1,25})?|\\.\\d{1,25})\\s*$", "g");

        return objRegExp.test(val);
    };

    /**
     * Actualiza el objeto donde tenemos todos los drives seleccionados
     * @param {Object}  obj - Contiene toda la informacion del select drive
     */
    updateTable = (precioVenta)=>{
        let state = {...this.state};
        this.state.inventoryItems.map(item=>{
            if(item.statusCheck){
                item.statusCheck = false;
                item.PrecioVenta = precioVenta;
            }
        });
        this.setState(state);
    }

    render() {
        return (
            <div>

                <ModoGuiado
                    ruta="toppings"
                    tipoModoGuiado="receta"
                    anterior="presentaciones"
                    siguiente='recetas'
                    mensaje="Información, agrupación y asignación del producto terminado (PT) o externo  (PE)" />

                    <div className="card">
                            <div className="card-content">
                            <div className="columns">
                                <div className="column is-5">
                                <label className="label">Búsqueda</label>
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'por Nombre'}
                                            placeholderText={"Ingrese código artículo o nombre de topping"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                <br/>


                <div className="card">
                    <div className="card-content">
                        {this.state.inventoryItems.length !== 0
                        ?
                        <div>

                            <div className="columns">
                                <div className="column" style={{display: "flex"}}>
                                    <a className="button is-link" onClick={() => this.selectAllCheckBox()}>
                                        <span className="icon is-small">
                                            <i className={!this.state.buttonSelectAll ? "fa fa-check-square" : "fa fa-square"}></i>
                                        </span>
                                        <span>{!this.state.buttonSelectAll ? "Seleccionar todos los resultados" : "Deseleccionar todos los resultados"}</span>
                                    </a>
                                </div>
                            </div>

                            <TableTemplate className="scroll"
                                columns={["InventoryCode", "Name"]}
                                columnsNames={["Código Artículo", "Nombre"]}
                                data={this.state.pageInventoryItems}
                                rowClickable={false}
                                canEdit canDelete
                                checkbox = {true}
                                noActions={true}
                                checkAll= {this.state.checkAll}
                                
                                selectAllCheck={this.selectAllCheckBoxPage}
                                
                                showView={this.showView}
                            />
                        </div>

                        : <NoItems itemName="artículos en inventario"/>}
                        <Pagination
                            items={this.state.inventoryItems}
                            onChangePage={
                                (inventoryItems) => {
                                    let checkAllTemp = true;
                                    let cheked = false;

                                    inventoryItems.map(item => {
                                        if(item.statusCheck){
                                            cheked = true;
                                        } else {
                                            checkAllTemp = false;
                                        }
                                    })

                                    this.setState({ pageInventoryItems: inventoryItems, checkAll: checkAllTemp, updateMasive: this.state.selectedToppingsObjList.length > 0 })
                                }
                            }
                        />
                    </div>
                </div>
                

                    <footer class="modal-card-foot is-justify-end">

                        <button
                            className='button is-grey'
                            onClick={() =>  this.props.cancelToppingsModal()}
                        >
                            Cancelar
                        </button>

                        <button 
                            className='button is-success' 
                            onClick={() => this.props.saveToppings(this.state.selectedToppingsObjList.map(e => {
                                return {
                                    InventoryCode: e.InventoryCode,
                                    InventoryItemId: e.InventoryItemId,
                                    Name: e.Name,
                                    statusCheck: e.statusCheck
                                }
                            }))}
                        >
                            Guardar cambios
                        </button>
                        
                    </footer>                 

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />

            </div>
        );
    }
}

export default ToppingsExtractos;
