import React, { Component } from 'react';
import  SearchForm  from "../../util/searchform/SearchForm";
import  TableTemplate  from "../../util/tabletemplate/TableTemplate";
import  NoItems  from "../../util/noitems/NoItems";
import Modal from '../../util/modal/Modal';
import AcceptModal from '../../util/acceptModal/AcceptModal';
import GrainForm from './GrainForm';
import Pagination from '../../util/pagination/Pagination';
import GrainsAPI from '../../../services/Grain';
import { toast } from 'react-toastify';
import Loading from '../../util/loading/Loading';
import Validators from '../../../util/validators';

import ModoGuiado from "../../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../../util/validation-error-messages';
import lodash from 'lodash';
import roles from '../../../util/strings/roles';

class Grains extends Component {
    grains = [];

    constructor(props){
        super(props);
        this.state = {
            grains: [],
            pageGrains: [],
            filterText: "",
            grain: null,
            showModal: false,
            editing: false,
            removeModal: false,
            isLoading: true
        }
    }

    componentWillMount(){
        this.setState({isLoading: true})
        GrainsAPI.getAll().then(response => {
            this.setState({isLoading: false, grains: response.data});
            this.grains = response.data;
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        });
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let grains = this.grains.filter(grain => grain.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        let state = this.state;
        state.filterText = text;
        state.grains = grains;
        this.setState(state);
    }

    /**
     * Redirecciona a vista para crear un nuevo tipo de grano 
     */
    create = () => {
        this.props.history.push({pathname:'/granos/nuevo', state:{ grains : this.grains }});
    }

    /** Función para editar un tipo de grano  */
    edit = (grain) => {        
        this.setState({isLoading: true}, () => {
            GrainsAPI.edit(grain).then(response => {
                let state = {...this.state};
                state.grains[state.grains.findIndex(c => c.GrainTypeId === grain.GrainTypeId)] = response.data;
                state.pageGrains[state.pageGrains.findIndex(c => c.GrainTypeId === grain.GrainTypeId)] = response.data;
                this.grains[this.grains.findIndex(c => c.GrainTypeId === grain.GrainTypeId)] = response.data;
                state.isLoading = false;
                state.showModal = false;
                state.grain = null;
                this.setState(state, () => toast.success("Tipo Grano actualizado"));
            }).catch(err => {
                this.setState({isLoading: false});
                if(err.response)
                    toast.error(err.response.data.Message);
                else
                    toast.error(ErrorMessages.SinConexion);
            })
        });
        
    }

    remove = () => {
        this.setState({isLoading: true});
        GrainsAPI.delete(this.state.grain.GrainTypeId).then(response => {
            let state = {...this.state};

            state.grains.splice(state.grains.findIndex(c => c.GrainTypeId === state.grain.GrainTypeId), 1);
            state.pageGrains.splice(state.pageGrains.findIndex(c => c.GrainTypeId === state.grain.GrainTypeId), 1);
            this.grains.splice(this.grains.findIndex(c => c.GrainTypeId === state.grain.GrainTypeId), 1);
            state.isLoading = false;
            state.removeModal = false;
            state.grain = null;

            this.setState(state, () => toast.success("Tipo Grano eliminado"));
        }).catch(err => {
            this.setState({isLoading: false, grain: null, removeModal: false});
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    }

    render() {
        return (
            <div>

                <ModoGuiado 
                    ruta="granos"
                    tipoModoGuiado="receta"
                    anterior="sabores"
                    siguiente="leches" 
                    mensaje="Agrupación de grano café que pertenece un producto terminado" />

                <h1 className="title is-2">Tipo de grano</h1>
                <div className="columns is-vcentered">
                    <div className="column is-5">
                        <div className="card">
                            <div className="card-content">
                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'Tipo Grano'}
                                            placeholderText={"Ingrese nombre de tipo de grano."}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-7">
                        <div className="field">
                            <div className="control has-text-right">
                                {lodash.intersection(localStorage.getItem('role').split(","), [roles.ADMIN, roles.ADMINDRIVE, roles.COORDINADOR_GESTION_OPERACIONES, roles.ESPECIALISTA_DE_PRODUCTO]).length > 0 &&
                                    <a id="btnAddGrain" className="button is-success" onClick={(e) => this.create()}>
                                        <span className="icon is-small">
                                            <i className="fa fa-plus"></i>
                                        </span>
                                        <span>Agregar Tipo Grano</span>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="card">
                    <div className="card-content">
                        {this.state.grains.length !== 0 
                        ? <TableTemplate
                            columns={["GrainTypeId","Description","Position", "NombreSegmento"]}
                            columnsNames={["ID", "Nombre", "Posición en Menú", "Segmento"]}
                            data={this.state.pageGrains}
                            rowClickable={true}
                            details={(grain) => this.setState({showModal: true, grain: grain})}
                            canEdit canDelete 
                            edit={(grain) => this.setState({showModal: true, grain: grain})} 
                            remove={(grain) => this.setState({removeModal: true, grain: grain})}
                        /> 
                        : <NoItems itemName="granos"/>}
                        <Pagination items={this.state.grains} onChangePage={(grains) => this.setState({ pageGrains: grains })} />
                    </div>
                </div>
                {this.state.showModal && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Tipo Grano"}
                        cancel={() => this.setState({showModal: false, unit: null})}
                    >
                        <GrainForm
                            grain={this.state.grain}
                            grains={this.grains}
                            save={this.edit}
                            isEditing={true}
                        />   
                    </Modal>
                }
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove()}
                    cancel={() => this.setState({removeModal: false})}
                    modalTitle={"Eliminar Tipo Grano"}
                />}
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />                

            </div>
        );
    }
}

export default Grains;
