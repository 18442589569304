export const tipos = {
    'reembolso': 'Reembolso',
    'descuento': 'Descuento',
    'qrCustom': 'QR Custom',
    'qrRefund':'QR_Refund',
    'Combo': 'Combo'
}

export const optionsTipoCantidad = 
[
    {
        value: false,
        label: 'Cantidad fija' 
    },{
        value: true,
        label: 'Porcentaje'
    }
]

export const RecursividadDescuento = 
    [
        {
            value: true,
            label: 'Descuento múltiple'
        },{
            value: false,
            label: 'Un descuento por orden'
        }
    ]

export const Recursividad = 
    [
        {
            value: false,
            label: 'Mayor o igual a'
        },{
            value: true,
            label: 'Multiplos de'
        }
    ]

export const     aplicaPara = [
    {
        value: '1',
        label: 'Web'
    },{
        value: '2',
        label: 'App'
    }
    ,{
        value: '3',
        label: 'Pos'
    }
]

export const estatusPromosShortName = {
    Validación: 'V',
    Cancelada: 'C',
    Vencida: 'R',
    Activa:'A',
    Borrador : 'B',
}
