import React, { useState, useEffect } from 'react';
import PromocionesAPI from '../../../services/Promotions';
import ResumenPromocion from './components/ResumenPromocion'
import to from '../../../util/to';
import { toast } from 'react-toastify';
import TablaCodigoRelacionado from './components/components/TablaCodigoRelacionado'

export default function DetallePromocion(props){
    const [promotion, setPromotion] = useState(null);
    const [codesPromotion,setCodesPromotion] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    
    // const [working, setWorking] = useState(false);

    useEffect(() => {
        (async () => {
            let err, promotion;
            [err, promotion] = await to(PromocionesAPI.getPromotionDetails(props.match.params.id))
            if(err) toast.error(err.response.data.Message);
            setPromotion(promotion);
        })();
    }, [])

    useEffect(() => {
        (async () => {
            let err, codesPromotion;
            setLoading(true);
            [err, codesPromotion] = await to(PromocionesAPI.getCodes(props.match.params.id,currentPage),)
            if(err) toast.error(err.response.data.Message);
            setLoading(false);
            setCodesPromotion(codesPromotion.Results);
            setTotalPages(codesPromotion.TotalPages);

//             CurrentPage:1
// Name:null
// OffSet:0
// Results:(1) [{…}]
// TotalPages:1
// TotalResults:1
        })()
    },[currentPage])

    if (!promotion) return null;

    return(
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="card-header-title is-size-4 center-text">
                        <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                            <i className="fa fa-arrow-left" style={{cursor: "pointer", color: "#000000" }} onClick={() => {
                                props.history.push({
                                    pathname: '/app/promociones',
                                    state: props.location.state ? {currentPage: props.location.state.currentPage || 0, name: props.location.state.name || ""} : {currentPage: 1, name: ""}
                                });
                                }} ></i>
                        </span>
                        <p style={{marginLeft:50,marginRight:50, wordBreak:'break-word'}}>{promotion.Name}</p>
                        
                    </div>
                </div>
                <div className="card-content">
                    <ResumenPromocion promotion={promotion} />
                </div>
            </div>
            <br/>

            
            <h1 className="title is-4">Código(s)</h1>
            <TablaCodigoRelacionado codesPromotion={codesPromotion} totalPages={totalPages} currentPage={currentPage} loading={loading} goToPage={(page) => setCurrentPage(page)}/>
        </div>
    )

}