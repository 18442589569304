
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import ProyeccionesAPI from '../../services/ProductionProyection';
import '../cssComponents.css';
import ErrorMessages from '../../util/validation-error-messages';
import Loading from '../util/loading/Loading';
import ProyeccionForm from './ProyeccionForm';
import { isThisSecond } from 'date-fns';

class NewProyeccion extends Component {
    constructor(props){
        super(props);
        this.state = {
            proyeccionList:[],
            proyeccion: null,
            isLoading: false
        };
    
    }
    componentDidMount(){
        if (!this.props.location.state) {
            this.props.history.push(`/productos-proyeccion`);
            return;
        }
        let state = {...this.state};
        state.proyeccionList = this.props.location.state.proyeccionList;
        state.isEditing = this.props.location.state.isEditing;
        if(state.isEditing){
            state.proyeccion = this.props.location.state.proyeccion;
        }
        else{
            state.proyeccion = {...this.props.proyeccion}
        }
        this.setState( state, () =>{ console.log("El state de proyeccion >> ", this.state); });
    }

    save = (proyeccion) => {
        console.log("La proyección a guardar >>", proyeccion);

        console.log("isEditing >>", this.state.isEditing);
        
        this.setState({isLoading: true}, () => {
            if(this.state.isEditing){
                ProyeccionesAPI.UpdateProyeccionData(proyeccion).then( response => {
                    let state = {...this.state};
                    state.isLoading = false;
                    this.setState(state, () => {
                        toast.success("Proyección actualizada");
                    });
                    this.props.history.goBack();
                }).catch(err => {
                    this.setState({isLoading: false})
                    if(err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                });
            }
            else{
                ProyeccionesAPI.CreateProyeccionData(proyeccion).then( response => {
                    this.setState({isLoading: false})
                    toast.success("Proyección creada");
                    this.props.history.goBack();
                }).catch(err => {
                    this.setState({isLoading: false})
                    if(err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                });
            }
        })
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i style={{cursor: "pointer", color: "#91BA43" }} onClick={(e) => { e.preventDefault(); this.props.history.goBack();}} className="fa fa-arrow-left"></i>
                            </span>
                            {this.state.isEditing ? "Actualizar Proyección" : "Nueva Proyección"}
                        </div>
                    </div>
                    <div className="card-content">
                        { this.state.proyeccion &&
                            <ProyeccionForm save={this.save} proyeccionList={this.state.proyeccionList} proyeccion={this.state.proyeccion} />
                        }
                    </div>
                </div>

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
            </div>
        );
    }
}

// Definición de los tipos y su dependencia de las distintas props que recibe el componente.
NewProyeccion.propTypes = {
    proyeccion: PropTypes.object,
    isEditing: PropTypes.bool
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
NewProyeccion.defaultProps = {
    isEditing: false,
    proyeccion: {
        Nombre: "",
        Activo: true,
        NombreCorto: "",
        Orden: 1,
        Color: "#FFC300",
        ColorLetra : "#000000",
        Drives: []
    }
}

export default NewProyeccion;