import React, { Component } from 'react';
import { toast } from 'react-toastify';
import InventoryItemForm from './InventoryItemForm';
import InventoryItemsAPI from '../../../services/InventoryItem';
import '../../cssComponents.css';
import PriceDriveAPI from '../../../services/PriceDrive';
import Loading from '../../util/loading/Loading';
import ErrorMessages from '../../../util/validation-error-messages';

import Modal from '../../util/modal/Modal';

class NewInventoryItem extends Component {

    listaPrecios = [];

    constructor(props) {
        super(props);
        this.state = {

            inventoryItem: {},
            inventoryItems: this.props.location && this.props.location.state ? this.props.location.state.inventoryItems : [],
            isLoading: true
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({ isLoading: true });

            if (this.props.match.params.lista) {
                InventoryItemsAPI.getByList(this.props.match.params.id, this.props.match.params.lista).then(response => {
                    this.setState({ isLoading: false, inventoryItem: response.data });
                }).catch(err => toast.error(err.response.data.Message));
            } else {
                InventoryItemsAPI.get(this.props.match.params.id).then(response => {
                    this.setState({ isLoading: false, inventoryItem: response.data });
                }).catch(err => toast.error(err.response.data.Message));
            }
        } else {
            this.setState({ isLoading: false })
        }

        // Obtener las listas de precios para asignarlas en caso que un articulo inventario
        // no las posea.
        PriceDriveAPI.get().then(response => {
            response.data.map(obj => {
                if (this.props.match.params.lista) {
                    if (obj.PriceListId == this.props.match.params.lista) {
                        this.listaPrecios.push({ value: obj.PriceListId, label: obj.Description })
                    }
                } else {
                    this.listaPrecios.push({ value: obj.PriceListId, label: obj.Description })
                }

            })
        }).catch(err => {
            console.log(err);
        });
    }

    save = (inventoryItem) => {
        if (inventoryItem.InventoryItemId) {


            inventoryItem.InventoryCode = inventoryItem.CodeProvider;
            inventoryItem.AmountMax = parseFloat(inventoryItem.AmountMax);
            inventoryItem.AmountMin = parseFloat(inventoryItem.AmountMin);
            inventoryItem.FactorBuyMin = parseFloat(inventoryItem.FactorBuyMin);
            inventoryItem.ConvFactorSale = parseInt(inventoryItem.ConvFactorSale, 10);
            inventoryItem.ConvFactorBuy = parseInt(inventoryItem.ConvFactorBuy, 10);
            
            if (inventoryItem.hasOwnProperty('IsTopping')) {
                if (inventoryItem.IsTopping) { // ES UN TOPPING
                    inventoryItem.ToppingPrice = parseFloat(inventoryItem.ToppingPrice);
                    inventoryItem.ToppingWeight = parseFloat(inventoryItem.ToppingWeight);
                }else{
                    inventoryItem.ToppingPrice = "";
                    inventoryItem.ToppingWeight = ""; 
                    inventoryItem.CodeSat="";  
                }
            }

            let { CodeSatTopping, DateUpdate, ...body } = inventoryItem;
            this.setState({ isLoading: true });
            InventoryItemsAPI.edit(body).then(response => {
                this.props.history.goBack();
                this.setState({ isLoading: false });
                toast.success("Artículo inventario actualizado");
            }).catch(err => {
                this.setState({ isLoading: false });
                if (err.response) {
                    toast.error(err.response.data.Message)
                } else {
                    toast.error(ErrorMessages.SinConexion)
                }
            });
        } else {

            inventoryItem.InventoryCode = inventoryItem.CodeProvider;
            inventoryItem.ShowToppingPrice = inventoryItem.IsTopping;
            inventoryItem.AmountMin = parseFloat(inventoryItem.AmountMin);
            inventoryItem.AmountMax = parseFloat(inventoryItem.AmountMax);
            inventoryItem.FactorBuyMin = parseFloat(inventoryItem.FactorBuyMin);
            inventoryItem.ConvFactorSale = parseInt(inventoryItem.ConvFactorSale, 10);
            inventoryItem.ConvFactorBuy = parseInt(inventoryItem.ConvFactorBuy, 10);
            inventoryItem.Segmentos = inventoryItem.Segmentos.map(x => x.SegmentId)
            // Si es un topping hacer la conversion explicita de su precio y cantidad a descontar
            // a cantidades flotantes. 
            if (inventoryItem.hasOwnProperty('IsTopping')) {
                if (inventoryItem.IsTopping) { // ES UN TOPPING
                    inventoryItem.ToppingPrice = parseFloat(inventoryItem.ToppingPrice);
                    inventoryItem.ToppingWeight = parseFloat(inventoryItem.ToppingWeight);
                }else{
                    inventoryItem.ToppingPrice = "";
                    inventoryItem.ToppingWeight = ""; 
                    inventoryItem.CodeSat="";  
                }
            }

            this.setState({ isLoading: true });
            InventoryItemsAPI.create(inventoryItem).then(response => {
                this.setState({ isLoading: false });
                this.props.history.goBack();
                toast.success("Artículo inventario creado");
            }).catch(err => {
                this.setState({ isLoading: false });
                if (err.response) {
                    console.log(err.response.data.Message)
                    toast.error(err.response.data.Message)
                } else {
                    toast.error(ErrorMessages.SinConexion);
                }


            });
        }
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{ position: "absolute", left: 25 }} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{ cursor: "pointer", color: "#91BA43" }} onClick={() => { this.props.history.goBack() }} className="fa fa-arrow-left"></i>
                            </span>
                            <div>
                                {this.state.inventoryItem.InventoryItemId ? "Editar Artículo Inventario" : "Nuevo Artículo Inventario"}
                                <i className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }} onClick={() => this.setState({ showModal: true })} className="fa fa-question-circle"></i>
                            </div>

                        </div>
                    </div>
                    <div className="card-content">
                        {this.state.isLoading
                            ? "" :
                            (this.state.inventoryItem.InventoryItemId
                                ?
                                <InventoryItemForm save={this.save} inventoryItem={this.state.inventoryItem} inventoryItems={this.state.inventoryItems} />
                                :
                                <InventoryItemForm save={this.save} inventoryItem={{}} inventoryItems={this.state.inventoryItems} />)
                        }

                    </div>
                </div>
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
                {this.state.showModal &&
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Información de Artículo Inventario"}
                        cancel={() => this.setState({ showModal: false })}
                    >
                        <div className="has-text-justified">

                            Un artículo es una materia prima que compone una receta en el segmento Drive, este artículo requiere la siguiente información:
                            <hr />
                            <section class="accordions">
                                <article class={`accordion is-info ${this.state.show_info ? "is-active" : ""}`} 
                                onClick={() => this.setState({ show_info: !this.state.show_info, show_info_equivalencias:false , show_info_clasificacion:false, show_info_end : false})}>
                                    <div class="accordion-header toggle">
                                        <p>1. Datos generales</p>
                                    </div>
                                    <div class="accordion-body">
                                        <div class="accordion-content">
                                            <p className="has-text-justified">
                                                <strong>Código de proveedor</strong>: es el PT o PE a asociar, debe tener configuradas
                                                    de lado de GP su unidad de medida, de lo contrario no podrá continuar.
                                                    El campo requiere al menos 8 caracteres, es obligatorio y no se debe haber creado previamente.
                                                </p>
                                            <p className="has-text-justified">
                                                <strong>Nombre</strong>: es un campo requerido,
                                                    debe tener una longitud mínima de 1 a 50 caracteres máximo
                                                    y no se debe duplicar.
                                                </p>
                                            <p className="has-text-justified">
                                                <strong>Nombre corto (Topping)</strong>: es un campo requerido,
                                                    debe tener una longitud mínima de 2 a 30 caracteres máximo
                                                    y no se debe duplicar, en caso de marcar artículo como topping este será el nombre que
                                                    aparezca en el punto de venta.
                                                </p>
                                        </div>
                                    </div>
                                </article>
                                <article class={`accordion is-info ${this.state.show_info_equivalencias ? "is-active" : ""}`} 
                                onClick={() => this.setState({ show_info_equivalencias: !this.state.show_info_equivalencias, show_info:false, show_info_clasificacion:false, show_info_end : false})}>
                                    <div class="accordion-header toggle">
                                        <p>2. Equivalencias</p>
                                    </div>
                                    <div class="accordion-body">
                                        <div class="accordion-content">

                                            <p className="has-text-justified">
                                                <strong>Unidad pedido sucursal</strong>: unidad con la que se solicitará a sucursal y se surtirá al punto de venta,
                                las unidades son cargadas desde GP.
                            </p>
                                            <p className="has-text-justified">
                                                <strong>Unidad almacenamiento externo pd</strong>: unidad con la cual el producto que se recibe de sucursal.
                            </p>
                                            <p className="has-text-justified">
                                                <strong>Unidad para venta</strong>: unidad con la cual se va a vender un artículo en almacén interno.
                            </p>
                                            <p className="has-text-justified">
                                                <strong>Múltiplos para pedido sucursal</strong>: múltiplos de equivalencia almacén para pedido a sucursal,
                                esto significa la cantidad con la cual se puede solicitar a sucursal con cada producto.
                            </p>
                                            <p className="has-text-justified">
                                                <strong>Equivalencia almacén</strong>: cantidad equivalente a una unidad para almacenamiento externo.
                            </p>
                                            <p className="has-text-justified">
                                                <strong>Equivalencia venta</strong>: cantidad equivalente a una unidad de almacén externo a interno.
                            </p>
                                        </div>
                                    </div>
                                </article>
                                <article class={`accordion is-info ${this.state.show_info_clasificacion ? "is-active" : ""}`} 
                                onClick={() => this.setState({ show_info_clasificacion: !this.state.show_info_clasificacion , show_info:false, show_info_end : false, show_info_equivalencias:false})}>
                                    <div class="accordion-header toggle">
                                        <p>3. Tipo Inventario</p>
                                    </div>
                                    <div class="accordion-body">
                                        <div class="accordion-content">
                                            <p className="has-text-justified">
                                            <strong>Clasificación Pedido</strong>: clasificación del artículo para el sistema de pedidos. 
                                            </p>
                                            <p className="has-text-justified">
                                            <strong>Cantidad máxima para solicitar</strong>: cantidad máxima que se permite solicitar de producto a sucursal 
                                            (basado en la Unidad de Medida asignada al producto).
                                            </p>
                                            <p className="has-text-justified">
                                            <strong>Cantidad Mínima para solicitar</strong>: cantidad máxima que se permite solicitar de producto a sucursal 
                                            (basado en la Unidad de Medida asignada al producto).
                                            </p>
                                            <p className="has-text-justified">
                                            <strong>Clasificación Inventario</strong>: clasificación del artículo para el sistema de inventarios.
                                            </p>
                                        </div>
                                    </div>
                                </article>
                                <article class={`accordion is-info ${this.state.show_info_end ? "is-active" : ""}`} onClick={() => this.setState({ show_info_end: !this.state.show_info_end, show_info:false, show_info_clasificacion: false, show_info_equivalencias:false })}>
                                    <div class="accordion-header toggle">
                                        <p>4. Información complementaria</p>
                                    </div>
                                    <div class="accordion-body">
                                        <div class="accordion-content">
                                        <p className="has-text-justified">
                                            <strong>Es Perecedero</strong>:  Se debe activar si el artículo tiene fecha de vencimiento.
                                            </p> 
                                        <p className="has-text-justified">
                                        <strong>Es Topping</strong>: Se debe activar en caso que el artículo que estemos registrando aplique como topping.
                                        </p>
                                        <div className="is-divider" data-content="Información para topping"></div>
                                        <p className="has-text-justified">
                                        <strong>Precio</strong>: precio por cada topping que se ingrese a una receta.
                                        </p>
                                        <p className="has-text-justified">
                                        <strong>Cantidad a descontar</strong>: cada que se agregue un topping a una receta entonces va descontar la cantidad especificada (basado en la unidad de venta).
                                        </p>
                                        <p className="has-text-justified">
                                        <strong>Código SAT para facturar</strong>: código de facturación que aparece en la factura del cliente.
                                        </p>
                                        <p className="has-text-justified">
                                        <strong>Icono/Imagen</strong>: icono que se va mostrar en la pantalla drive al seleccionar el extra topping.
                                        </p>
                                    </div>
                                    </div>
                                </article>
                            </section>




                        </div>


                    </Modal>
                }
            </div>
        );
    }
}

export default NewInventoryItem;
