
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import lodash from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DatePicker, {registerLocale} from 'react-datepicker';
import es from 'date-fns/locale/es';
// Components
import AcceptModal from '../util/acceptModal/AcceptModal';
import NoItems from "../util/noitems/NoItems";
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading';
import ErrorMessages from '../../util/validation-error-messages';
import Modal from '../util/modal/Modal';
import Select from 'react-select';
import HasAccess from '../util/hasAccess/HasAccess';
import PromoCopyForm from './components/PromoCopyForm';
import PromoCommentsForm from './components/PromoComentsForm';

// Services
import DrivePromotionsAPI from '../../services/DrivePromotions';
import DrivePromotionsStatusAPI from '../../services/DrivePromotionsStatus';
import DrivePromotionCommentsAPI from '../../services/DrivePromotionComments';
import roles from '../../util/strings/roles';
import DrivePromotionsTypeAPI from '../../services/DrivePromotionsType';
import { select } from 'async';

registerLocale("es", es); 
/**
 * Vista del módulo de promociones 
 */
class PromosDrive extends Component {
    state = {
        isLoading: false,
        page: [],
        promotions: [],
        filterPromotions: [],
        selectedPromotion: {},
        arrStatusPromos: [],
        arrTypePromos:[],
        showModal: false,
        filterText:'',
        filters:{},
        isEditing:false,
        //Modal de confirmación
        showModalConfirm: false,
        actionTitle: '',
        actionBody: '',
        actionOnConfirm: () => { },
        actionOnCancel: () => { },
        isAdmin:false
    }

    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            let rolesStorage = localStorage.getItem('role').split(',');
            let state = { ...this.state }
            state.isAdmin = rolesStorage.includes(roles.ADMIN);
            if(!state.isAdmin){
                state.isAdmin = rolesStorage.includes(roles.MKT_OS);
            }
            console.log(state.isAdmin)
            DrivePromotionsStatusAPI.getAll()
                .then(response => {
                    state.arrStatusPromos = response.data.map(d=>{
                        d.label = d.Name;
                        d.value = d.IdStatusPromo;
                        return d;
                    });

                    return DrivePromotionsTypeAPI.getAll();
                    

                })
                .then(response=>{
                    state.arrTypePromos = response.data.map(d=>{
                        d.label = d.Name;
                        d.value = d.IdTypePromo;
                        return d;
                    });
                    this.setState(state, () => this.getPromotionsData())
                })
                .catch(err => {
                    if (err.response)
                        toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                    else
                        toast.error(ErrorMessages.SinConexion)
                }).finally(() => this.setState({ isLoading: false }));
        })
    }

    /***
     * Carga listado de promosiones 
     */
    getPromotionsData = (filters = null) => {
        this.setState({ isLoading: true }, () => {
            let today =  moment(new Date()).toISOString();
            console.log("today >>> ", today)
            let statusVencido = this.state.arrStatusPromos.filter(x => x.ShortName === 'R');
            if (filters){
                DrivePromotionsAPI.getByParams(filters)
                .then(response => {
                    let state = { ...this.state }                    
                    let formatData = response.data.map(d => {
                        let status = state.arrStatusPromos.filter(x => x.IdStatusPromo === d.idEstatus)
                        if (status.length > 0) {
                            status = status[0]
                        }
                        let isVencido = d.EndDate ? moment(new Date(d.EndDate)).isBefore(moment(today)) : false;
                        d.InitDate = d.InitDate ? moment(new Date(d.InitDate)).format("YYYY/MM/DD") : '';
                        d.EndDate = d.EndDate ? moment(new Date(d.EndDate)).format("YYYY/MM/DD") : '';
                        if(isVencido){
                            status = statusVencido[0]
                        }
                        
                        d.Status = status ? status.Name : '';
                        d.StatusColor = status ? status.Color : '';
                        d.StatusShortName = status ? status.ShortName : '';
                        
                        return d
                    })
                    state.promotions = formatData;
                    state.filterPromotions = formatData;
                    state.page = formatData;
                    this.setState(state)
                })
                .catch(err => {
                    if (err.response)
                        toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                    else
                        toast.error(ErrorMessages.SinConexion)
                }).finally(() => this.setState({ isLoading: false }));

            }else{
                DrivePromotionsAPI.getAll()
                .then(response => {
                    let state = { ...this.state }
                    state.filters = {};
                    let formatData = response.data.map(d => {                        
                        let status = state.arrStatusPromos.filter(x => x.IdStatusPromo === d.idEstatus)
                        if (status.length > 0) {
                            status = status[0]
                        }
                        let isVencido = d.EndDate ? moment(new Date(d.EndDate)).isBefore(moment(today)) : false;
                        d.InitDate = d.InitDate ? moment(new Date(d.InitDate)).format("YYYY/MM/DD") : '';
                        d.EndDate = d.EndDate ? moment(new Date(d.EndDate)).format("YYYY/MM/DD") : '';
                        
                        if(isVencido){
                            status = statusVencido[0]
                        }
                        
                        d.Status = status ? status.Name : '';
                        d.StatusColor = status ? status.Color : '';
                        d.StatusShortName = status ? status.ShortName : '';
                        
                        return d
                    })
                    state.promotions = formatData;
                    state.filterPromotions = formatData;
                    state.page = formatData;
                    this.setState(state)
                })
                .catch(err => {
                    if (err.response)
                        toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                    else
                        toast.error(ErrorMessages.SinConexion)
                }).finally(() => this.setState({ isLoading: false }));
            }
            
        })

    }

    /**
     * Filtra coincidencias
     * @param {*} text 
     */
    handleSearchInput = (text) => {
        let state = { ...this.state };
        state.filterText = text || '';
        state.filterPromotions = state.promotions.filter(p =>
            p.Name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            || state.filterText.trim().length === 0);
        state.page = state.promotions.filter(p =>
            p.Name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            || state.filterText.trim().length === 0);
        this.setState(state);
    }

    /**
     * Función para cambiar de estatus
     * @param {*} obj 
     * @param {*} newStatus 
     */
    changeStatus = (obj) => {
        this.setState({ isLoading: false })
        DrivePromotionsAPI
            .update(obj.IdPromocion, obj)
            .then(response => {
                //TODO: update promoción 
                toast.success("Promoción actualizada conrrectamente");

            })
            .catch(err => {
                if (err.response)
                    toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                else
                    toast.error(ErrorMessages.SinConexion)
            })
            .finally(() => this.resetConfirmParams)
    }

    /**
     * Limpia los valores de editar una promoción
     */
    resetConfirmParams = () => {        
        this.setState({
            selectedPromotion: {},
            showModalConfirm: false,
            actionTitle: '',
            actionBody: '',
            actionOnConfirm: () => { },
            actionOnCancel: () => { },
            isLoading: false
        })
    }

    /**
     * Configura vista para el estatus seleccionado
     * @param {*} e evento click
     * @param {*} action acción 
     * @param {*} obj row
     */
    onActionSelect = (e, action, obj) => {
        e.stopPropagation();
        switch (action) {
            case 'Envíar':
                let estatusActivo = this.state.arrStatusPromos.filter(s => s.ShortName === 'V');
                if(estatusActivo && estatusActivo.length > 0){
                    DrivePromotionsAPI.updateStatus(obj.IdPromocion, estatusActivo[0].IdStatusPromo).then(response => {
                        toast.success("La promoción ha sido envíada a validación correctamente.");
                        this.setState({isLoading:true}, ()=>this.getPromotionsData());
                        
                    }).catch(err => {
                        toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                    })
                }else{
                    toast.error("Estatus error.")
                }

                
                break;
            case 'Validar':
                let estatusValidacion = this.state.arrStatusPromos.filter(s => s.ShortName === 'A');
                if(estatusValidacion && estatusValidacion.length > 0){
                    DrivePromotionsAPI.updateStatus(obj.IdPromocion, estatusValidacion[0].IdStatusPromo).then(response => {
                        toast.success("La promoción ha sido validada correctamente.");
                        this.setState({isLoading:true}, ()=>this.getPromotionsData());
                    }).catch(err => {
                        toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                    })
                }else{
                    toast.error("Estatus error.")
                }


                break;
            case 'SolCambios':
                this.setState({isLoading:true, selectedPromotion:{...obj}, isEditing:false },
                    () => {
                        DrivePromotionCommentsAPI.getAll(this.state.selectedPromotion.IdPromocion)
                        .then(response => {
                            if(response.data){
                                let selectedPromotion = {...this.state.selectedPromotion}
                                selectedPromotion.Comments = response.data || [];
                                selectedPromotion.CurrentComment ="";
                                this.setState({
                                    showModalComments:true,
                                    selectedPromotion: selectedPromotion,
                                    isLoading:false
                                })
                            }            
                        })
                        .catch(err => {
                            if (err.response)
                                toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                            else
                                toast.error(ErrorMessages.SinConexion)
                        })
                        
                    });
                
                break
            case 'Cancelar':
                this.setState({
                    showModalConfirm:true,
                    actionTitle:"Confirmación requerida",
                    actionBody: `La promoció ${obj.Name} será cancelada, ¿ Desea continuar ?`,
                    actionOnConfirm : () => {
                        
                        let estatusCancelado = this.state.arrStatusPromos.filter(s => s.ShortName === 'C');                
                        if(estatusCancelado && estatusCancelado.length > 0){
                            DrivePromotionsAPI.updateStatus(obj.IdPromocion, estatusCancelado[0].IdStatusPromo).then(response => {
                                toast.success("La promoción ha sido cancelada conrrectamente.");
                                this.resetConfirmParams();
                                this.setState({isLoading:true}, () => this.getPromotionsData());
                            }).catch(err => {
                                toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                            })
                        }else{
                            toast.error("Estatus error.")
                        }
                    },
                    actionOnCancel : () => this.resetConfirmParams()

                })
                
                break;
            case 'Copiar':
                let selected = {...obj}
                // let estatusCopy = this.state.arrStatusPromos.filter(s => s.ShortName === 'B');
                // selected.IdPromocion = 0;
                // selected.Name = `${obj.Name} Copy`;
                // selected.idEstatus = estatusCopy[0].IdStatusPromo;
                this.setState({
                    showModal:true,
                    selectedPromotion: {...selected}
                })
                break
            case 'Ver':
                this.setState({isLoading:true, selectedPromotion:{...obj}, isEditing:true },
                    () => {
                        DrivePromotionCommentsAPI.getAll(this.state.selectedPromotion.IdPromocion)
                        .then(response => {
                            if(response.data){
                                let selectedPromotion = {...this.state.selectedPromotion}
                                selectedPromotion.Comments = response.data || [];
                                selectedPromotion.CurrentComment ="";
                                this.setState({
                                    showModalComments:true,
                                    selectedPromotion: selectedPromotion,
                                    isLoading:false
                                })
                            }            
                        })
                        .catch(err => {
                            if (err.response)
                                toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                            else
                                toast.error(ErrorMessages.SinConexion)
                        })
                        
                    });
                    break
            default:
                toast.error("Acción sin configurar, contacte a soporte.")
        }
    }

    /**
     * Función que actualiza la información de los filtros 
     * @param {*} e evento
     * @param {*} name nombre del filtro que cambio 
     */
    handleFilterChange = (val, name) => {
        
        let state = {...this.state}
        let filtersNotNULL = {};

        if(name === "fromDatePromo"){
            let formatDt = {};
            if (val)
                formatDt.value = val.toISOString();
            state.filters[name] = val ? formatDt.value : null;
        }else{
            state.filters[name] = val ? val.value : null;
        }
        
        state.filters[`${name}_selected`] = val;

        if(state.filters.statusPromo)
            filtersNotNULL.statusPromo = state.filters.statusPromo;
        if(state.filters.typePromo)
            filtersNotNULL.typePromo = state.filters.typePromo;
        if(state.filters.fromDatePromo)
            filtersNotNULL.fromDatePromo = state.filters.fromDatePromo;
        if(state.filterText.length > 0)
            filtersNotNULL.filterText = state.filterText
        
        this.setState(state, () => this.getPromotionsData(filtersNotNULL) )

    }

    /**
     * Redirige al formulario para editar promoción
     */
    EditPromo = (promo) => {    
        this.setState({isLoading:true}, () => {
            
            this.props.history.push({pathname:`/promos/editar-promo`, state: { 
                isEditing:true, 
                IdPromocion: promo.IdPromocion
                
            } });
                
        });    
        
    }

    render() {
        return (
            <div>
                <h1 className="title is-2">Promociones Drive</h1>               

                <div className="card">
                    <div className="card-content">
                        <div className="columns is-multiline">
                            
                            <div className="column is-4">
                                <label className="label">Estatus</label>
                                <div className="control">
                                    <Select
                                        id="ddlEstatusPromocion"
                                        placeholder={"Seleccionar..."}
                                        name={"statusPromo"}
                                        value={this.state.filters.statusPromo_selected}
                                        onChange={e => this.handleFilterChange(e, 'statusPromo')}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.arrStatusPromos}
                                        isClearable={true}
                                    />
                                </div>
                            </div>
                            <div className="column is-4">
                                <label className="label">Tipo</label>
                                <div className="control">
                                    <Select                                        
                                        id ="ddlTipoPromocion"
                                        placeholder={"Seleccionar..."}
                                        name={"typePromo"}
                                        value={this.state.filters.typePromo_selected}
                                        onChange={e => this.handleFilterChange(e, 'typePromo')}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.arrTypePromos.filter(t=>t.Active)}
                                        isClearable={true}
                                    />
                                </div>
                            </div>
                            <div className="column is-4">
                                <label className="label">A partir</label>
                                <div className="control">
                                <DatePicker
                                    id={"dtFromDatePromo"}
                                    name={"fromDatePromo"}
                                    selected={this.state.filters.fromDatePromo_selected}
                                    onChange={val => this.handleFilterChange(val, 'fromDatePromo')}
                                    popperPlacement="bottom"
                                    locale="es"
                                    todayButton="Hoy"
                                    dateFormat="dd/MM/yyyy"
                                    className="input"
                                    autoComplete={"off"}
                                    isClearable={true}
                                />
                                </div>
                            </div>
                            
                            <div className="column is-8">
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        placeholder={`Ingrese nombre o código de la promoción`}
                                        value={this.state.filterText}
                                        autoFocus={true}
                                        onChange={(e) => this.handleSearchInput(e.currentTarget.value)}
                                    />
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="field">
                                    <div className="control has-text-right">
                                        <Link
                                            to='/promos/nueva-promo'
                                            className='button is-success'
                                        >
                                            Nueva Promoción
                                        </Link>
                                        {/* <a className="button is-success" onClick={(e) => { }}>
                                            <span className="icon is-small">
                                                <i className="fa fa-plus"></i>
                                            </span>
                                            <span>Nueva Promoción</span>
                                            
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="card">
                    <div className="card-content">
                        {this.state.filterPromotions.length !== 0
                            ?
                            <table className="table is-fullwidth">
                                <thead>
                                    <tr>

                                        <th>Promoción</th>
                                        <th>Automática</th>
                                        <th>Vigencia</th>
                                        <th>Estatus</th>
                                        <th>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.page.map((item, index) => {
                                        return (
                                            <tr key={index} onClick={() => item.StatusShortName === 'B' || this.state.isAdmin ? this.EditPromo(item) : {}} style={{cursor:item.StatusShortName === 'B' || this.state.isAdmin  ? 'pointer' : 'auto'}}>
                                                <td>{item.Name}</td>
                                                <td>{item.Auto ? "SI" : "NO"}</td>
                                                <td>{`${item.InitDate} - ${item.EndDate}`}</td>
                                                <td>
                                                    <button className="button" style={{ color: `${item.StatusColor}`, borderColor: `${item.StatusColor}`, cursor: "not-allowed" }} onClick={(e) => { e.stopPropagation() }}>
                                                        {item.Status}
                                                    </button>
                                                </td>
                                                <td>
                                                <HasAccess roles={[roles.MKT_REGIONAL, roles.MKT_OS, roles.ADMIN]}>                                                     
                                                    <button id="btnCopiarPromo" className="button tableAction is-default tooltip" data-tooltip="Copiar promoción"
                                                        onClick={(e) => this.onActionSelect(e, "Copiar", { ...item })}>
                                                        <span className="icon">
                                                            <i className="fa fa-copy" />
                                                        </span>
                                                    </button>
                                                </HasAccess>
                                                <HasAccess roles={[roles.MKT_REGIONAL, roles.ADMIN, roles.MKT_OS]}>   
                                                    { ( item.StatusShortName === 'B' || item.UserId === localStorage.getItem("user") )  && 
                                                    <button id="btnEnviarPromo" className="button tableAction is-success tooltip" data-tooltip="Envíar promoción"
                                                        onClick={(e) => this.onActionSelect(e, "Envíar", { ...item })}>
                                                        <span className="icon">
                                                            <i className="fa fa-upload" />
                                                        </span>
                                                    </button>
                                                    }
                                                </HasAccess>
                                                { item.StatusShortName === 'V' && 
                                                    <HasAccess roles={[roles.ADMIN, roles.MKT_OS]}>   
                                                        
                                                        <button id="btnValidarPromo" className="button tableAction is-success tooltip" data-tooltip="Validar promoción"
                                                            onClick={(e) => this.onActionSelect(e, "Validar", { ...item })}>
                                                            <span className="icon">
                                                                <i className="fa fa-check-circle" />
                                                            </span>
                                                        </button>
                                                        
                                                    
                                                        <button id="btnSoCambiosPromo" className="button tableAction is-warning tooltip" data-tooltip="Solicitar cambios"
                                                            onClick={(e) => this.onActionSelect(e, "SolCambios", { ...item })}>
                                                            <span className="icon">
                                                                <i className="fa fa-comments-o" />
                                                            </span>
                                                        </button>
                                                    
                                                    </HasAccess>
                                                 }
                                                 <HasAccess roles={[roles.MKT_REGIONAL, roles.ADMIN, roles.MKT_OS]}> 
                                                    { item.StatusShortName === 'B' && item.UserId == localStorage.getItem("user") &&
                                                        <button id="btnVerCommentsPromo" className="button tableAction is-warning tooltip" data-tooltip="Ver promoción"
                                                            onClick={(e) => this.onActionSelect(e, "Ver", { ...item })}>
                                                            <span className="icon">
                                                                <i className="fa fa-eye" />
                                                            </span>
                                                        </button>
                                                        
                                                    }
                                                    
                                                </HasAccess>
                                                <HasAccess roles={[roles.MKT_REGIONAL]}> 
                                                { item.StatusShortName === 'B' && 
                                                    <button id="btnCancelarPromo" className="button tableAction is-danger tooltip" data-tooltip="Cancelar promoción"
                                                        onClick={(e) => this.onActionSelect(e, "Cancelar", { ...item })}>
                                                        <span className="icon">
                                                            <i className="fa fa-close" />
                                                        </span>
                                                    </button>
                                                    
                                                }
                                                    
                                                </HasAccess>                                                
                                                <HasAccess roles={[ roles.MKT_OS, roles.ADMIN]}> 
                                                { item.StatusShortName !== 'C'&& item.StatusShortName !== 'R' &&
                                                    <button id="btnCancelarPromo" className="button tableAction is-danger tooltip" data-tooltip="Cancelar promoción"
                                                        onClick={(e) => this.onActionSelect(e, "Cancelar", { ...item })}>
                                                        <span className="icon">
                                                            <i className="fa fa-close" />
                                                        </span>
                                                    </button>                                              
                                                }    
                                                </HasAccess>
                                                
                                                </td>

                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                            : <NoItems itemName="promociones" />}
                        <Pagination items={this.state.filterPromotions} onChangePage={(drives) => this.setState({ page: drives })} /*onChangePage={(items) => this.handlePageChange(items)}*/ />
                    </div>
                </div>

                {this.state.showModal &&
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Copiar Promoción"}
                        width="60%"
                        cancel={() => this.setState({ showModal: false, selectedPromotion: null })}
                    >
                        <PromoCopyForm 
                            promo={this.state.selectedPromotion}
                            promotions={this.state.promotions}
                            save={(obj) => {
                                let objToSave = {};
                                
                                this.setState({isLoading:true}, () => {
                                    DrivePromotionsAPI.get(obj.IdPromocion).then(response => {
                                        obj.InitDate= moment(new Date(response.data.Promotion.InitDate)).format("YYYY/MM/DD HH:mm");
                                        obj.EndDate= moment(new Date(response.data.Promotion.EndDate) ).format("YYYY/MM/DD HH:mm") ;
                                        
                                        objToSave = response.data;
                                        objToSave.Promotion = {...obj}
                                        objToSave.drives = []
                                        return DrivePromotionsAPI.create(objToSave);
                                    })
                                    .then(response => {
                                        console.log(response.data);
                                        toast.success("La promoción ha sido creada correctamente.");
                                        this.getPromotionsData();                                        
                                    }).catch(err => {
                                        toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                                    })
                                    .finally(()=> {
                                        this.setState({isLoading:false, showModal: false, selectedPromotion: null})
                                    })
                                })
                                
                            }}
                            />
                    </Modal>
                }

                {this.state.showModalComments &&
                    <Modal
                        showModal={this.state.showModalComments}
                        modalTitle={"Observaciones"}
                        width="60%"
                        cancel={() => this.setState({ showModalComments: false, selectedPromotion: null })}
                    >
                        <PromoCommentsForm 
                            promo = {this.state.selectedPromotion} 
                            readOnly = {this.state.isEditing}
                            cancel={() => this.setState({ showModalComments: false, selectedPromotion: null, isEditing:false })}                           
                            save = {(obj) => {
                                this.setState({isLoading:true}, ()=>{
                                    let selectedpromo = {...this.state.selectedPromotion}
                                    DrivePromotionCommentsAPI.create(obj)
                                    .then(response => {                                    
                                        let estatusBorrador = this.state.arrStatusPromos.filter(s => s.ShortName === 'B');
                                        return DrivePromotionsAPI.updateStatus(selectedpromo.IdPromocion, estatusBorrador[0].IdStatusPromo);                                    
                                    }).then(response =>{
                                        toast.success("La solicitud de cambios se ha enviado correctamente.");
                                        this.setState({isLoading:true}, () => this.getPromotionsData());
                                    })
                                    .catch(err=>{
                                        toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                                    }) 
                                    .finally(() => {
                                        this.setState({isLoading:false, showModalComments: false, selectedPromotion: null})
                                    }) 
                                })
                            }}
                            />
                    </Modal>
                }

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
                {this.state.showModalConfirm &&
                    <AcceptModal
                        showModal={this.state.showModalConfirm}
                        modalTitle={`${this.state.actionTitle}`}
                        modalBody={<p>{`${this.state.actionBody}`}</p>}
                        isActive={this.state.showModalConfirm}
                        willSave
                        done={this.state.actionOnConfirm}
                        cancel={this.state.actionOnCancel}
                    />
                }
            </div>

        );
    }

}

export default PromosDrive;