import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import PromocionesAPI from '../../../../../services/Promotions';
import Loading from '../../../../util/loading/Loading';
import useModal from '../../../../../util/hooks/useModal';
import TablaValidarClientes from './components/TablaValidarClientes';
import PortalModal from '../../../../../util/portals/PortalModal';
import './style.css';

export default function SegmentoDataForm({ inputs, setInput, errors, handleSelectChange }) {
    
    const [typesSegmentation, setTypesSegmentation] = useState([]);
    const [segmentations, setSegmentations] = useState([]);
    const [isLoadingTypesSegmentation, setIsLoadingTypesSegmentation] = useState(false);
    const [isLoadingSegmentation, setIsLoadingSegmentation] = useState(false);
    const [validateExcel, setValidateExcel] = useState(null);
    const [loadingFullScreen, setLoadingFullScreen] = useState(false);
    const clientValidatedModal = useModal();
    const [selectedSegmentation, setSelectedSegmentation] = useState(null);

    const getTypesSegmentation = async () => {
        try {
            setIsLoadingTypesSegmentation(true)
            let typeSegmentationRes = await PromocionesAPI.getPromoTypeSegmentation()
            setIsLoadingTypesSegmentation(false)
            setTypesSegmentation(typeSegmentationRes.data)
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getTypesSegmentation()
    }, [])

    useEffect(() => {
        (async () => {
            if (inputs.typeSegmentation && inputs.typeSegmentation.value && inputs.typeSegmentation.value === 1) {
                setIsLoadingSegmentation(true)
                let segmentationsRes = await PromocionesAPI.getSegmentations()
                setIsLoadingSegmentation(false)
                setSegmentations(segmentationsRes.data)
                setInput('excelFile', null)
            } else {
                setInput('segmentation', null)
            }
            if (!inputs.typeSegmentation) {
                setInput('excelFile', null)
                setInput('segmentation', null)
                setInput('amountCodes', null)
            }
        })()
    }, [inputs.typeSegmentation])

    useEffect(() => {
        (async () => {
            if (inputs.segmentation && inputs.segmentation.value) {
                var segmentation = segmentations.find(s => s.IdSegmentation === inputs.segmentation.value);
                setInput('amountCodes', segmentation.TotalClients);
                setSelectedSegmentation(segmentation)

            } else {
                setInput('amountCodes', null);
            }
        })()
    }, [inputs.segmentation])

    const onClicValidarExcel = async () => {
        setLoadingFullScreen(true);
        var validateExcelResult = PromocionesAPI.validateExcel(inputs.excelFile);
        validateExcelResult.then(res => {
            console.log(res)
            if (res.status === 200) {
                setValidateExcel(res.data);
                setInput('validClients', res.data.Clients);
                setInput('amountCodes', res.data.TotalValidClients);
            } else {
                setValidateExcel(null);
                setInput('validClients', null);
                setInput('amountCodes', null);
                setInput('excelFile', null)
                toast.error('validateExcel - ' + res.data.Message);
            }

        }).catch(err => {
            toast.error('validateExcel - ' + err.response.data.Message);
            setValidateExcel(null);
            setInput('amountCodes', null);
            setInput('validClients', null);
            setInput('excelFile', null)
        }).finally(() => {
            setLoadingFullScreen(false);
        });
    }

    const onClicBorrarExcel = async () => {

        setInput('excelFile', null)
        setValidateExcel(null);
        setInput('validClients', null);

    }

    // Método para descargar plantilla de Excel.
    const downloadTemplateExcel = async () => {
        setLoadingFullScreen(true);
        PromocionesAPI.templateExcel().then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let name = `PlantillaCargaMasivaClientesPromoQR`;
            link.setAttribute('download', `${name}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            toast.error(err.response.data.Message)
        }).finally(() => {
            setLoadingFullScreen(false);
        });
    }

    /**
     * Función que se ejecuta al validar el archivo de excel
     */
    const handleSelectedFile = (e) => {

        let files = e.target.files
        let file = files[0]
        e.target.value = "";
        if (typeof file === 'undefined') {
            e.target.value = null;
            return false;
        }

        const EXTENSION = (file.name.split('.')[1]).toLowerCase();

        if (validateExtensionFile(EXTENSION) === false) {
            toast.error("Es necesario seleccionar un archivo válido.");
            e.target.value = null;
            return false;
        } else {
            setInput(e.target.name, file);
        }
    }

    /**
     * Valida que solo permita los tipos deseados
     */
    const validateExtensionFile = (extension1) => {

        var extensiones_permitidas = ["xls", "xlsx"];
        if (extensiones_permitidas.indexOf(extension1) == -1) {
            return false;
        }
        return true;
    }

    return (
        <div className="columns is-multiline">
            <div className="column is-12">
                <div className="columns is-multiline">
                    <div className="column is-6">
                        <label className="label">Tipo de segmentación</label>
                        <div className={`control`}>
                            <Select
                                placeholder={"Seleccionar..."}
                                name={"typeSegmentation"}
                                value={inputs.typeSegmentation}
                                isClearable
                                onChange={(value, event) => handleSelectChange(value, event, true)}
                                noOptionsMessage={() => "No hay opciones"}
                                isLoading={isLoadingTypesSegmentation}
                                options={typesSegmentation.map(ts => { return { value: ts.PromoTypeSegmentationId, label: ts.Description } })}
                            />
                        </div>
                        {errors.typeSegmentation && <p className="help title has-text-left is-6 is-danger">Tipo de segmentación requerido</p>}
                    </div>
                    {(inputs.typeSegmentation && inputs.typeSegmentation.value === 1) && <div className="column is-6">
                        <label className="label">Segmentación</label>
                        <div className={`control`}>
                            <Select
                                placeholder={"Seleccionar..."}
                                name={"segmentation"}
                                value={inputs.segmentation}
                                isClearable
                                onChange={(value, event) => handleSelectChange(value, event, true)}
                                noOptionsMessage={() => "No hay opciones"}
                                isLoading={isLoadingSegmentation}
                                options={segmentations.map(s => { return { value: s.IdSegmentation, label: s.Name } })}
                            />
                        </div>
                        {errors.segmentation && <p className="help title has-text-left is-6 is-danger">Segmentación requerido</p>}
                    </div>}
                    {inputs.segmentation && selectedSegmentation && <div className="column is-4 has-text-left">
                        <label className="label">Total de clientes</label>
                        <div className="control">
                            <p>{selectedSegmentation.TotalClients}</p>
                        </div>
                    </div>
                    }

                </div>

            </div>
            {(inputs.typeSegmentation && inputs.typeSegmentation.value === 2) && <div className="column is-12">
                <div className="columns is-multiline">
                    <div className="column is-6">
                        <label className="label">Archivo excel</label>
                        <div className="file is-info has-name">
                            <label className="file-label">
                                <input
                                    className="input file-input"
                                    id="file"
                                    type="file"
                                    name="excelFile"
                                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={(e) => handleSelectedFile(e)}
                                />
                                <span className="file-cta">
                                    <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo excel</i></span>
                                </span>
                                <span className="file-name">{inputs.excelFile && inputs.excelFile !== null && inputs.excelFile.name ? inputs.excelFile.name : "Archivo"}</span>
                            </label>
                        </div>
                    </div>
                    <div className="column is-6 displayFlex">
                        <div className="field is-grouped alignBottom" >
                            <p className="control">
                                <button
                                    type="button"
                                    className="button is-success"
                                    disabled={!(inputs.excelFile && inputs.excelFile !== null)}
                                    onClick={onClicValidarExcel}
                                >
                                    Validar excel
                            </button>
                            </p>
                            <p className="control">
                                <button
                                    type="button"
                                    className="button is-danger"
                                    disabled={!(inputs.excelFile && inputs.excelFile !== null)}
                                    onClick={onClicBorrarExcel}
                                >
                                    Borrar excel
                            </button>
                            </p>
                            <p className="control">
                                <button
                                    type="button"
                                    className="button is-link"
                                    onClick={downloadTemplateExcel}
                                >
                                    Descargar plantilla
                            </button>
                            </p>
                        </div>
                    </div>
                    {validateExcel && <div className="column is 12">
                        <div className="columns is-multiline">
                            <div className="column is-4 has-text-left">
                                <label className="label">Total de clientes</label>
                                <div className="control">
                                    <p>{validateExcel.TotalClients}</p>
                                </div>
                            </div>
                            <div className="column is-4 has-text-left">
                                <label className="label">Clientes válidos</label>
                                <div className="control">
                                    <p>{validateExcel.TotalValidClients}</p>
                                </div>
                            </div>
                            <div className="column is-4 has-text-left">
                                <label className="label">Clientes no válidos</label>
                                <div className="control">
                                    <p>{validateExcel.TotalNotValidClients}</p>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="field is-grouped" >
                                    <p className="control">
                                        <button
                                            type="button"
                                            className="button is-success"
                                            disabled={!(inputs.excelFile && inputs.excelFile !== null)}
                                            onClick={() => { clientValidatedModal.toggle(); }}
                                        >
                                            Mostrar tabla de clientes
                            </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>}


                </div>

            </div>}

            <PortalModal
                isShowing={clientValidatedModal.isShowing}
                hide={clientValidatedModal.toggle}
                title="Clientes"
                body={() => {
                    return (
                        <div className="column is-12">
                            <TablaValidarClientes
                                validClients={validateExcel.Validation}
                            />
                        </div>
                    )
                }}
                footer
                footerButtons={() => {
                    return (
                        <div className="has-text-right modal-detalle-buttons">
                            <button className={`button is-success`} onClick={() => clientValidatedModal.toggle()}>Aceptar</button>
                        </div>
                    )
                }}
            />
            <Loading isFullscreen={true} isLoading={loadingFullScreen} width='100px' height='100px' />
        </div>
    );
}