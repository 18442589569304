import React, { Component } from 'react';
import MenuDriveAPI from '../../services/MenuDrive';
import ClasificacionesAPI from '../../services/Classification';
import PresentationsAPI from '../../services/Presentations';
import FlavorsAPI from '../../services/Flavor';
import MilksAPI from '../../services/Milk';
import GrainsAPI from '../../services/Grain';
import RecipesTypesAPI from '../../services/RecipeType';
import to from '../../util/to';
import { toast } from 'react-toastify';
import './MenusDrive.css';
import Field from '../util/field/Field';
import TableTemplate from '../util/tabletemplate/TableTemplate';
import Dropdown from '../util/dropdown/Dropdown';
import SearchForm from "../util/searchform/SearchForm";
import NoItems from "../util/noitems/NoItems";
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading';
import Select from 'react-select';
import '../cssComponents.css';
import RecipeFilters from '../util/recipeFilters/recipeFilters';


class MenuDetail extends Component {
    menuDetailsFull = [];
    menuDetails = [];
    recipes = [];
    estatusReceta = {
        Todos: 1,
        Activo: 2,
        Inactivo: 3
    }

    // Variables donde se almacenaran los valores seleccionados en el select multi
    tempRecipeTypesList;
    tempPresentationList;
    tempMilkList;
    tempGrainList;
    tempFlavorsList;
    tempClassificationList;

    constructor(props) {
        super(props);
        this.state = {
            menu: {
                Description: "",
                NombreSegmento: ""
            },
            filters: {
                ClassificationId: 0,
                PresentationId: 0,
                FlavorTypeId: 0,
                RecipeTypeId: 0,
                MilkTypeId: 0,
                GrainTypeId: 0
            },
            menuDetails: [],
            pageMenuDetails: [],
            filterText: "",
            classifications: [],
            presentations: [],
            flavors: [],
            recipeTypes: [],
            milks: [],
            grains: [],
            isLoading: false,
            dataLoaded: false,
            checkAll: false,//para seleccionar todos los de una pagina (1er checkBox)
            activateMasive: false,//Activar o desactivar masivo (Apartado que se muestra o no dependiendo del estado)
            switchAll: false,//switch para activar o desactivar
            seeSwitch: false,//variable par mostrar switch
            selectedEstatus: this.estatusReceta.Todos,
            buttonMesagge: false,//nos ayuda a mostrar un texto personalizado en el boton Seleccionar todo
            // Variables sonde se almacenaran los criterios de busqueda definitivos
            recipeTypeList: [],
            selectedRecipeTypesList: [],
            presentationsList: [],
            selectedPresentationsList: [],
            milksList: [],
            selectedMilksList: [],
            grainsList: [],
            selectedGrainsList: [],
            flavorsList: [],
            selectedFlavorsList: [],
            classificationList: [],
            selectedClassificationList: [],
            selectedMenuDetails: [],
            selectedSegmentationList: []
        };
    }

    componentWillMount() {
        this.setState({ isLoading: true });
        if (this.props.match.params.id) {
            MenuDriveAPI.getDetails(this.props.match.params.id).then(response => {
                this.menuDetailsFull = response.data;
                var info = response.data.map((item, index) => {
                    return {
                        DetailMenuId: index,
                        MenuId: item.MenuId,
                        RecipeId: item.RecipeId,
                        Active: item.Active,
                        ClassificationId: item.Recipe.ClassificationId,
                        PresentationId: item.Recipe.PresentationId,
                        FlavorTypeId: item.Recipe.FlavorTypeId,
                        RecipeTypeId: item.Recipe.RecipeTypeId,
                        MilkTypeId: item.Recipe.MilkTypeId,
                        GrainTypeId: item.Recipe.GrainTypeId,
                        Description: item.Recipe.Description,
                        NombreSegmento: item.Recipe.NombreSegmento,
                        idSegmento: item.Recipe.idSegmento,
                        statusCheck: false//agregamos un estado para cada fila de si ha sido seleccionado no
                    };
                });

                this.menuDetails = info;
                this.setState({ menuDetails: info }, () => this.getData());

            }).catch(err => toast.error(err.response.data));
        } else {
            this.menuDetailsFull = [];
            this.setState({
                menuDetails: []
            }, () => this.getData());
        }
    }


    getData = async () => {
        let err, classifications, presentations, flavors, milks, grains, recipeTypes, menu;
        [err, classifications] = await to(ClasificacionesAPI.getAll());
        [err, presentations] = await to(PresentationsAPI.getAll());
        [err, flavors] = await to(FlavorsAPI.getAll());
        [err, milks] = await to(MilksAPI.getAll());
        [err, grains] = await to(GrainsAPI.getAll());
        [err, recipeTypes] = await to(RecipesTypesAPI.getAll());
        [err, menu] = await to(MenuDriveAPI.getById(this.props.match.params.id));

        let tempClassificationList = [];
        classifications.map(obj => {
            tempClassificationList.push({ value: obj.ClassificationId, label: obj.ShortName })
        })
        tempClassificationList.sort(this.compare); // Ordenar los labels

        let temp = [];
        recipeTypes.map(obj => {
            if (obj.RecipeName) {
                temp.push({ value: obj.RecipeTypeId, label: obj.RecipeName })
            } else {
                temp.push({ value: obj.RecipeTypeId, label: obj.Description })
            }
        })
        temp.sort(this.compare); // Ordenar los labesl para elegir

        let tempFlavorsList = [];
        flavors.map(obj => {
            tempFlavorsList.push({ value: obj.FlavorTypeId, label: obj.Name })
        })
        tempFlavorsList.sort(this.compare); // Ordenar los labels     

        let tempGrainList = [];
        grains.map(obj => {
            tempGrainList.push({ value: obj.GrainTypeId, label: obj.Description })
        })
        tempGrainList.sort(this.compare); // Ordenar los labels

        let tempMilkList = [];
        milks.map(obj => {
            tempMilkList.push({ value: obj.MilkTypeId, label: obj.Description })
        })
        tempMilkList.sort(this.compare); // Ordenar los labels

        let tempPresentationList = [];
        presentations.map(obj => {
            tempPresentationList.push({ value: obj.PresentationId, label: obj.Name })
        })
        tempPresentationList.sort(this.compare); // Ordenar los labels

        this.setState({
            classificationList: tempClassificationList,
            recipeTypeList: temp,
            flavorsList: tempFlavorsList,
            grainsList: tempGrainList,
            milksList: tempMilkList,
            presentationsList: tempPresentationList,
            menu: menu,
            dataLoaded: true,
            isLoading: false
        })
    }

    handleDropdownChange = (index, itemName, propName) => {
        if (index != 0) {
            let item = this.state[itemName][index - 1];
            let state = { ...this.state };
            state.filters[propName] = item[propName];
            this.setState(state, () => this.filterDataOnPages());
        } else {
            let state = { ...this.state };
            state.filters[propName] = 0;
            this.setState(state, () => this.filterDataOnPages());
        }
    }


    filterDataOnPages = () => {

        let details = this.menuDetails;

        if (this.state.filters.ClassificationId) {
            details = details.filter(d => d.ClassificationId == this.state.filters.ClassificationId);
        }

        if (this.state.filters.PresentationId) {
            details = details.filter(d => d.PresentationId == this.state.filters.PresentationId);
        }

        if (this.state.filters.FlavorTypeId) {
            details = details.filter(d => d.FlavorTypeId == this.state.filters.FlavorTypeId);
        }

        if (this.state.filters.RecipeTypeId) {
            details = details.filter(d => d.RecipeTypeId == this.state.filters.RecipeTypeId);
        }

        if (this.state.filters.MilkTypeId) {
            details = details.filter(d => d.MilkTypeId == this.state.filters.MilkTypeId);
        }

        if (this.state.filters.GrainTypeId) {
            details = details.filter(d => d.GrainTypeId == this.state.filters.GrainTypeId);
        }

        if (this.state.filterText) {
            details = details.filter(d => d.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        }

        this.setState({ menuDetails: details });
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        //let menus = this.menus.filter(menu => menu.Description.toLowerCase().includes(text.toLowerCase()));
        //state.menus = menus;

        let state = this.state;
        state.filterText = text;
        this.setState(state);
        this.handleSearchMultipleFieldInput();
        // this.filterDataOnPages();
    }

    //Funcion para marcar todos los checkbox que hay en la pagina actual
    selectAllCheck = () => {
        let _selectedMenuDetails = [...this.state.selectedMenuDetails];
        let _menuDetails = this.state.pageMenuDetails
        let _checkAll = !this.state.checkAll

        _menuDetails.forEach(menuDetail => {
            let indexMenuDetail = _selectedMenuDetails.findIndex(item => item.RecipeId === menuDetail.RecipeId)
            menuDetail.statusCheck = _checkAll
            if(_checkAll){
                if(indexMenuDetail === -1) _selectedMenuDetails.push({
                    MenuId: menuDetail.MenuId,
                    RecipeId: menuDetail.RecipeId,
                    DetailMenuId: menuDetail.DetailMenuId
                })
            }else{
                if(indexMenuDetail !== -1) _selectedMenuDetails.splice(indexMenuDetail, 1)
            }
        })

        this.setState({
            selectedMenuDetails: _selectedMenuDetails, 
            checkAll: _checkAll,
            buttonSelectAll: _selectedMenuDetails.length === this.state.menuDetails.length,
            activateMasive: _selectedMenuDetails.length > 0
        })
    }

    //Funcion que selecciona o des selecciona todos los checkbox de todas las paginas
    selectAllCheckBox = () => {
        let _selectedMenuDetails = [...this.state.selectedMenuDetails]
        let _menuDetails = this.state.menuDetails;
        let _buttonSelectAll = !this.state.buttonSelectAll;

        _menuDetails.forEach(menuDetail => {
            // Ver si el elemento ya esta en la lista de seleccioandos
            let indexMenuDetail = _selectedMenuDetails.findIndex(item => item.RecipeId === menuDetail.RecipeId)

            // Meter el elemento a la lista si no estaba previamente
            if (_buttonSelectAll && indexMenuDetail === -1) {
                _selectedMenuDetails.push({
                    MenuId: menuDetail.MenuId,
                    RecipeId: menuDetail.RecipeId,
                    DetailMenuId: menuDetail.DetailMenuId
                })
            // Sacar al elemento de la lista si ya estaba
            } else if (!_buttonSelectAll && indexMenuDetail !== -1) {
                _selectedMenuDetails.splice(indexMenuDetail, 1)
            }
            menuDetail.statusCheck = _buttonSelectAll;
        })

        let _checkAll = _buttonSelectAll;

        this.setState({
            selectedMenuDetails: _selectedMenuDetails, 
            checkAll: _checkAll,
            buttonSelectAll: _buttonSelectAll,
            activateMasive: _selectedMenuDetails.length > 0
        })
    }

    //Funcion que sirve cuando damos clic a un check de una columna 
    //para que nos muestre la opcion de aplicar cambios global
    showView = (index) => {
        let _selectedMenuDetails = [...this.state.selectedMenuDetails];
        let indexMenuDetail = this.state.selectedMenuDetails.findIndex(id => id.RecipeId === this.state.pageMenuDetails[index].RecipeId)
        if (indexMenuDetail === -1) {
            // Meter todo el objeto al arreglo de seleccionados
            _selectedMenuDetails.push({
                MenuId: this.state.pageMenuDetails[index].MenuId,
                RecipeId: this.state.pageMenuDetails[index].RecipeId,
                DetailMenuId: this.state.pageMenuDetails[index].DetailMenuId
            })
        } else {
            _selectedMenuDetails.splice(indexMenuDetail, 1)
        }

        let counter = 0; // variable para comparar los elementos seleccionados en la pagina con la cantidad de elementos por pagina
        this.state.pageMenuDetails.map(obj => {
            if (obj.statusCheck) counter++
        })

        this.setState({
            selectedMenuDetails: _selectedMenuDetails, 
            buttonSelectAll: _selectedMenuDetails.length === this.state.menuDetails.length,
            checkAll: counter === this.state.pageMenuDetails.length,
            activateMasive: _selectedMenuDetails.length > 0
        })
    }

    //Funcion para guardar masivamente los cambios hechos
    applySwitchAll = () => {
        // Ponemos icono de cargando
        this.setState({ isLoading: true });

        this.state.selectedMenuDetails.map(obj => {
            obj.Active = this.state.switchAll
        })

        MenuDriveAPI.postDetailUpdate(this.state.selectedMenuDetails).then(response => {
            let state = { ...this.state };

            for (var i = 0; i < this.state.selectedMenuDetails.length; i++) {
                state.menuDetails[state.menuDetails.findIndex(md => md.DetailMenuId === this.state.selectedMenuDetails[i].DetailMenuId)].Active = this.state.switchAll;
                // state.pageMenuDetails[state.pageMenuDetails.findIndex(md => md.DetailMenuId === detail[i].DetailMenuId)].Active = this.state.switchAll;
            }
            //detail.Active = !detail.Active;

            state.menuDetails.map(obj => {
                obj.statusCheck = false;
            })
            state.activateMasive = false;

            state.showModal = false;
            state.isLoading = false;
            state.checkAll = false;
            state.selectedMenuDetails = []
            state.switchAll = false;
            state.buttonSelectAll = false;
            this.setState(state, () => {
                toast.success("Datos actualizados");
            });
        }).catch(err => {
            let state = { ...this.state };
            state.showModal = false;
            state.isLoading = false;
            this.setState(state);

            toast.error("Ocurrió un error al actualizar la Receta");
        });
    }

    changeStatusDetail = (detail) => {

        this.setState({ isLoading: true });
        let infoService = [
            {
                MenuId: detail.MenuId,
                RecipeId: detail.RecipeId,
                Active: !detail.Active
            }
        ];

        MenuDriveAPI.postDetailUpdate(infoService).then(response => {
            let state = { ...this.state };

            detail.Active = !detail.Active;
            state.menuDetails[state.menuDetails.findIndex(md => md.DetailMenuId === detail.DetailMenuId)] = detail;
            state.pageMenuDetails[state.pageMenuDetails.findIndex(md => md.DetailMenuId === detail.DetailMenuId)] = detail;

            state.showModal = false;
            state.isLoading = false;
            this.setState(state, () => {
                toast.success("Datos actualizados");
            });
        }).catch(err => {
            let state = { ...this.state };
            state.showModal = false;
            state.isLoading = false;
            this.setState(state);

            toast.error("Ocurrió un error al actualizar la Receta.");
        });
    }

    /**
     * Funcion para ordenar lexicograficamente
     * @param objA. primer operando
     * @param objB. segundo operando
     */
    compare = (objA, objB) => {
        // Use toUpperCase() to ignore character casing
        const labelA = objA.label.toUpperCase();
        const labelB = objB.label.toUpperCase();

        let comparison = 0;
        if (labelA > labelB) {
            comparison = 1;
        } else if (labelA < labelB) {
            comparison = -1;
        }
        return comparison;
    }

    /**
     *Realizar el filtrado sobre las recetas para desplegar solo los elementos
     *que cumplen con las caracteristicas que el usuario ingreso.
     * 
     *@param: nada
     *@return: nada
     */
    handleSearchMultipleFieldInput = () => {

        let state = this.state;

        let menuDetails = this.menuDetails;

        switch (this.state.selectedEstatus) {
            case this.estatusReceta.Activo:
                menuDetails = menuDetails.filter(d => d.Active === true);
                break;
                
            case this.estatusReceta.Inactivo:
                menuDetails = menuDetails.filter(d => d.Active === false);
                break;
        }

        if (this.state.filterText) {
            menuDetails = menuDetails.filter(d => d.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        }

        if (this.state.selectedRecipeTypesList.length > 0) {
            menuDetails = menuDetails.filter(d => this.state.selectedRecipeTypesList.includes(d.RecipeTypeId))
        }

        if (this.state.selectedFlavorsList.length > 0) {
            menuDetails = menuDetails.filter(d => this.state.selectedFlavorsList.includes(d.FlavorTypeId))
        }

        if (this.state.selectedPresentationsList.length > 0) {
            menuDetails = menuDetails.filter(d => this.state.selectedPresentationsList.includes(d.PresentationId))
        }

        if (this.state.selectedMilksList.length > 0) {
            menuDetails = menuDetails.filter(d => this.state.selectedMilksList.includes(d.MilkTypeId));
        }

        if (this.state.selectedGrainsList.length > 0) {
            menuDetails = menuDetails.filter(d => this.state.selectedGrainsList.includes(d.GrainTypeId))
        }

        if (this.state.selectedClassificationList.length > 0) {
            menuDetails = menuDetails.filter(d => this.state.selectedClassificationList.includes(d.ClassificationId));
        }

        if (this.state.selectedSegmentationList.length > 0) {
            menuDetails = menuDetails.filter(d => this.state.selectedSegmentationList.includes(d.idSegmento));
        }

        menuDetails.map(item=>{
            if(item.statusCheck){
                item.statusCheck = false;
            } 
        });

        state.menuDetails = menuDetails;
        state.activateMasive = false;
        state.buttonSelectAll = false;
        state.selectedMenuDetails = []

        this.setState(state);
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre las clasificaciones.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleClassificationChange = (arr) => {
        let state = { ...this.state }
        let tempClassificationList = [];
        arr.map(obj => {
            tempClassificationList.push(obj.value);
        })

        state.selectedClassificationList = tempClassificationList;
        state.selectedRecipes = [];

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de receta
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleRecipeTypeChange = (arr) => {
        let state = { ...this.state }
        let tempSelectedRecipeTypesList = [];
        arr.map(obj => {
            tempSelectedRecipeTypesList.push(obj.value);
        })

        state.selectedRecipeTypesList = tempSelectedRecipeTypesList;
        state.selectedRecipes = [];

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de sabores
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleFlavorChange = (arr) => {
        let state = { ...this.state }
        let tempSelectedFlavorList = [];
        arr.map(obj => {
            tempSelectedFlavorList.push(obj.value);
        })

        state.selectedFlavorsList = tempSelectedFlavorList;
        state.selectedRecipes = [];

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de granos.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleGrainChange = (arr) => {
        let state = { ...this.state }
        let tempGrainList = [];
        arr.map(obj => {
            tempGrainList.push(obj.value);
        })

        state.selectedGrainsList = tempGrainList;
        state.selectedRecipes = [];

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de leche
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleMilkChange = (arr) => {
        let state = { ...this.state }
        let tempMilksList = [];
        arr.map(obj => {
            tempMilksList.push(obj.value);
        })

        state.selectedMilksList = tempMilksList;
        state.selectedRecipes = [];

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre las presentaciones
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handlePresentationChange = (arr) => {
        let state = { ...this.state }
        let tempPresentationList = [];
        arr.map(obj => {
            tempPresentationList.push(obj.value);
        })

        state.selectedPresentationsList = tempPresentationList;
        state.selectedRecipes = [];

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     * Función para manejar el onChange del componente de RecipeFilters
     */
    handleSelectChange = (objFilters) => {
        this.setState(objFilters, () => {this.handleSearchMultipleFieldInput();})
    }

    /**
     * Función para manejar los radiobuttons para el filtro de estatus
     */
    handleFiltroEstatus= (evento) => {
        if(this.state.selectedEstatus !== Number(evento.currentTarget.value)){
            this.setState({selectedEstatus: Number(evento.currentTarget.value)}, this.handleSearchMultipleFieldInput)
        }
    }

    render() {
        return (
            <div>
                <h1 className="title is-2">Activar/Desactivar recetas</h1>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{cursor: "pointer", color: "#91BA43" }} onClick={() => {this.props.history.goBack()}} className="fa fa-arrow-left"></i>
                            </span>
                            {this.state.menu.Description}
                        </div>
                    </div>
                    <div className="card-content">

                        {/* Buscador de contenido o nombre de recetas */}
{/*                         <div className="columns">
                            <div className="column is-12">
                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'en Recetas'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* Filtrado de todo */}
                        <div className="columns is-multiline">
                            {/* Filtrar por activo/inactivo/todos */}
                            <div className="column is-12">
                                <label className="label">Estatus de la receta en el menú</label>
                                <div className="control" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="drives"
                                            value={this.estatusReceta.Todos}
                                            checked={this.state.selectedEstatus === this.estatusReceta.Todos}
                                            onClick={this.handleFiltroEstatus}
                                        />
                                        &nbsp;Todos
                                    </label>
                                    
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="drives"
                                            value={this.estatusReceta.Activo}
                                            checked={this.state.selectedEstatus === this.estatusReceta.Activo}
                                            onClick={this.handleFiltroEstatus}
                                        />
                                        &nbsp;Activo
                                    </label>

                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="drives"
                                            value={this.estatusReceta.Inactivo}
                                            checked={this.state.selectedEstatus === this.estatusReceta.Inactivo}
                                            onClick={this.handleFiltroEstatus}
                                        />
                                        &nbsp;Inactivo
                                    </label>
                                </div>
                            </div>

                            <RecipeFilters 
                                onChange={(objFilters) => {
                                    this.handleSelectChange(objFilters)
                                }}
                            />                            
                        </div>

                    </div>
                </div>
                <br />
                {/*Mostramos cuando a seleccionado un checkbox*/}
                {
                    this.state.activateMasive &&
                    <div className="card">
                        <div className="card-content">
                            <div className="columns">
                                <div className="column is-4">
                                    <label htmlFor="">Activar productos seleccionados</label>
                                </div>
                                <div className="column is-3">
                                    <Field label="" >
                                        <label htmlFor="" style={{ marginRight: 15 }}>Activar</label>
                                        <input
                                            type="checkbox"
                                            name="ActiveAll"
                                            className="switch"
                                            checked={this.state.switchAll}
                                            onChange={() => { }}
                                        />
                                        <label htmlFor="ActiveAll" onClick={(e) => {
                                            e.stopPropagation();
                                            let switchAllTemp = this.state.switchAll;
                                            let seeSwitchTemp = this.state.seeSwitch;
                                            switchAllTemp = !switchAllTemp;
                                            seeSwitchTemp = !switchAllTemp;
                                            this.setState({ switchAll: switchAllTemp, seeSwitch: true });
                                        }} />
                                    </Field>
                                </div>
                                <div className="column is-3">
                                    <div className="has-text-center buttons-modal">
                                        <button
                                            className="button is-success"
                                            onClick={() => this.applySwitchAll()}>
                                            Aplicar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <br />

                <div className="card">
                    <div className="card-content">
                        <div className="columns">
                            <div className="column is-3">
                                <div className="has-text-center">
                                    <a className="button is-link" onClick={() => this.selectAllCheckBox()}>
                                        <span className="icon is-small">
                                            <i className={!this.state.buttonSelectAll ? "fa fa-check-square" : "fa fa-square"}></i>
                                        </span>
                                        <span>{!this.state.buttonSelectAll ? "Seleccionar todos los resultados" : "Deseleccionar todos los resultados"}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-content">
                        {this.state.menuDetails.length != 0
                            ? <TableTemplate
                                columns={["Description", "NombreSegmento", "Active"]}
                                columnsNames={["Receta", "Segmento", "Activo"]}
                                disableCheck={false}
                                data={this.state.pageMenuDetails}
                                noActions={true}
                                checkbox={true}
                                checkAll={this.state.checkAll}
                                showView={this.showView}
                                selectAllCheck={this.selectAllCheck}
                                changeStatus={this.changeStatusDetail}
                            />
                            : <NoItems itemName="detalles de menú" />}

                        <Pagination 
                            items={this.state.menuDetails} 
                            onChangePage={
                                (menuDetails) => {
                                    let checkAllTemp = true;
                                    let cheked = false;

                                    menuDetails.map(item => {
                                        if(item.statusCheck){
                                            cheked = true;
                                        } else {
                                            checkAllTemp = false;
                                        }
                                    })
                                    this.setState({ pageMenuDetails: menuDetails, checkAll: checkAllTemp, seeSwitch: this.state.selectedClassificationList.length > 0 })
                                }
                            } 
                        />


                    </div>
                </div>
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
            </div>
        );
    }

}

export default MenuDetail;