import React from "react"
import { toast } from 'react-toastify';
import { ProyeccionFormulaComponent } from "./ProyeccionFormulaComponent";
import ErrorMessages from '../../util/validation-error-messages';
import update from 'immutability-helper';
import ProyeccionFormulaAPI from "../../services/ProyeccionFormula";
import lodash from 'lodash';

class ProyeccionFormulaContainer extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isEditing: false,
            isLoading: false, 
            isSubmitting: false,
            hasInvalidFields: false,
            showModalEditFormula: false,
            showModalEditRecipes: false,
            showModalEditToppings: false,

            showModalRemoveRecipe: false,
            recipeToRemove: {},

            showModalRemoveElementFormula: false,
            indexToRemoveFormula: 0,

            showModalRemoveTopping: false,
            toppingToRemove: {},

            showModalInfo: false,

            showModal: false,
            formulaWasModified: false, 
            formulaIsValid: false,

            tiempoVidaIsEmpty: false,
            tiempoVidaIsZero: false,
            tiempoVidaIsMax: false,

            frecuenciaIsEmpty: false,
            frecuenciaIsZero: false,
            frecuenciaIsMax: false,

            invalidFieldTiempoVida: false,
            invalidFieldFrecuencia: false,

            addElementFormulaIsDisabled: false,
            removeElementFormulaIsDisabled: false,

            // formulaData: {...this.props.formulaData},
            formula: [...this.props.formula],
            // proyeccion: {},
            pageRecipes: [],
            pageToppings: [],

            isFinishLoading : false

            // formula: [
            //     {id: "1", isDraggableDisabled: false, text: "TiempoVida"  },
            //     {id: "2", isDraggableDisabled: true, text: "/" },
            //     {id: "3", isDraggableDisabled: true, text: "(" },
            //     {id: "4", isDraggableDisabled: false, text: "Frecuencia"},
            //     {id: "5", isDraggableDisabled: true, text: "/" },
            //     {id: "6", isDraggableDisabled: false, text: "VentaHistorica" },
            //     {id: "7", isDraggableDisabled: true, text: ")" },
            // ],
        
            // formulaData : {
            //             IdFormulaExtractos: 1,
            //             FormulaText: "",
            //             Frecuencia: 1,
            //             TiempoVida: 1,
            //             Recetas: [],
            //             Toppings: []
            // // }

        }
        this.initialFormula = []
        this.onDragEnd = this.onDragEnd.bind(this);
        this.baseState = this.state;
        this.grid = 6;
        
    }
    
    componentDidMount(){
        this.changeLoading();
        // this.getFormulaData();
        if (!this.props.location.state) {
            this.props.history.push(`/productos-proyeccion`);
            return;
        }
        this.setFormulaData();
        // this.setState({ proyeccionList: this.props.location.state.proyeccionList }, () =>{ console.log("El state de proyeccion >> ", this.state); });
    }

    /**
     * Función que se encarga de guardar los cambios que se hacen el modal para editar la formula
     */


    saveFormula = () => {
        this.initialFormula = lodash.cloneDeep(this.state.formula);
        let newState = update(this.state, {
            showModal: {$set: false},
            showModalEditFormula: {$set: false},
            formulaWasModified: {$set: false},
            formulaIsValid: {$set: false},
        });
        this.setState(newState, () => {
            toast.success(`Se editó la fórmula con éxito`);
        });
    }

    /**
     * Funcion para agregar elemento a la formula cuando el usuario da click en botón "Agregar elemento"
     */

    addElementFormula = () => {

        if (this.state.formula.length > 10){
            toast.error("La formúla no puede tener más de 11 elementos")
            let newState = update(this.state, {
                addElementFormulaIsDisabled: {$set: true}
            })
            this.setState(newState);
        }
        else {
            let _formula = this.state.formula;

            _formula.map((f,index) => {
                f.id = (index+1).toString();
            })

            _formula.push(
                {
                    id: (this.state.formula.length+1).toString(),
                    isDraggableDisabled: false,
                    text: ""
                }
            )
            let newState = update(this.state, {
                formula: {$set: _formula},
                formulaWasModified: {$set: true},
                formulaIsValid: {$set: false},
                removeElementFormulaIsDisabled: {$set: false} 
            })
            this.setState(newState);
        }
    }

    /**
     * Funcion para agregar eliminar un elemento de la fórmula
     */

    removeElementFormula = () => {

        if(this.state.formula[this.state.indexToRemoveFormula].isDraggableDisabled == true){
            toast.error("No se pueden eliminar las variables predeterminadas")
        }
        else {
            let _formula = this.state.formula;

            _formula.splice(this.state.indexToRemoveFormula,1);

            _formula.map((f,index) => {
                f.id = (index+1).toString();
            })

            let newState = update(this.state, {
                formula: {$set: _formula},
                formulaWasModified: {$set: true},
                formulaIsValid: {$set: false},
                addElementFormulaIsDisabled: {$set: false},
                showModalRemoveElementFormula: {$set: false}
            })
            this.setState(newState, () => {
                toast.success(`Elemento de la fórmula eliminado con éxito.`);
            });
        }

    }

    /**
     * Funcion para mostrar el modal de confirmación para eliminar un elemento de la fórmula
     * @param index índice del elemento en el arreglo
     */

    toggleModalRemoveElementFormula = (index) => {
        let newState = update(this.state, {
            showModalRemoveElementFormula: {$set: true},
            indexToRemoveFormula: {$set: index}
        })
        this.setState(newState);
    }

    /**
     * Funcion para cerrar el modal de confirmación para eliminar un elemento de la fórmula
     */

    cancelModalRemoveElementFormula = () => {
        let newState = update(this.state, {
            showModalRemoveElementFormula: {$set: false},
        })
        this.setState(newState);
    }


    checkDraggableStatus = (e, index) => {
        e.stopPropagation();
    }

    /**
     * Funcion para obtener la información del módulo mediante un servicio
     */

    // getFormulaData = async() => {
    //     try{
    //         let formulaData = await ExtraccionesCafeAPI.GetFormulaData();
    //         let newState = update(this.state, {
    //             isLoading: {$set: false},
    //             formulaData: {$set: formulaData.data},
    //         });
    //         this.setState(newState, () => {

    //             let auxFormulaString = this.state.formulaData.FormulaText.split(' ');
    //             let auxFormulaObject = []

    //             auxFormulaString.forEach((element, index) => {
    //                 auxFormulaObject[index] = {...this.state.formula[index], text: element}
    //             })

    //             auxFormulaString.forEach((element, index) => {
    //                 auxFormulaObject[index].id = (index + 1).toString();
    //                 if(auxFormulaObject[index].text.length == 1 || isNaN(auxFormulaObject[index].text) == false ){
    //                     auxFormulaObject[index].isDraggableDisabled = false;
    //                 }
    //                 else{
    //                     auxFormulaObject[index].isDraggableDisabled = true;
    //                 }

    //             })

    //             let newState = update(this.state, {
    //                 formula: {$set: auxFormulaObject},
    //                 isFinishLoading: {$set: true}
    //             });

    //             this.setState(newState, () => {
    //                 this.initialFormula = lodash.cloneDeep(this.state.formula);
    //             });            
    //         });

    //     } catch (err) {
    //         let newState = update(this.state, {
    //             isLoading: {$set: false},
    //         });
    //         this.setState(newState, async() => {
    //             try{
    //                 //En caso que no exista registro de formula, lo crea
    //                 //let newFormulaData = await ExtraccionesCafeAPI.CreateFormulaData(this.state.formulaData);
    //                 let newState = update(this.state, {
    //                     isLoading: {$set: false},
    //                 });
    //                 this.setState(newState);
    //             }catch (err){
    //                 if(err.response)
    //                     toast.error(err.response.data.Message)
    //                 else
    //                     toast.error(ErrorMessages.SinConexion)
    //             }
    
    //             let newState2 = update(this.state, {
    //                 isLoading: {$set: false}
    //             });
    //             this.setState(newState2); 
                
    //         });
    
    //     }   

    //     let newState = update(this.state, {
    //         isLoading: {$set: false}
    //     });
    //     this.setState(newState); 
        
    // }

    setFormulaData = () => {
        try{
            console.log("la proyeccion recibida >>", this.props.location.state.proyeccion);
            let formulaDataTmp = {};
            if(this.props.location.state.proyeccion.Formula){
                formulaDataTmp = this.props.location.state.proyeccion.Formula;
            }
            else{
                formulaDataTmp = {
                    IdFormulaProduccionAdelantado: 0,
                    IdProduccionAdelantado: this.props.location.state.proyeccion.IdProduccionAdelantado,
                    FormulaText: "TiempoVida / ( Frecuencia - VentaHistorica )",
                    Frecuencia: 1,
                    TiempoVida: 1,
                    Recetas: [],
                    Toppings: []
                }
            }
            let newState = update(this.state, {
                isLoading: {$set: false},
                formulaData: {$set: formulaDataTmp},
                proyeccion: {$set: this.props.location.state.proyeccion}
            });
            console.log("NEWSTATE >> ", newState);
            this.setState(newState, () => {
                console.log("la formulaData >>", this.state.formulaData);

                let auxFormulaString = this.state.formulaData.FormulaText.split(' ');
                let auxFormulaObject = []

                auxFormulaString.forEach((element, index) => {
                    auxFormulaObject[index] = {...this.state.formula[index], text: element}
                })

                auxFormulaString.forEach((element, index) => {
                    auxFormulaObject[index].id = (index + 1).toString();
                    if(auxFormulaObject[index].text.length == 1 || isNaN(auxFormulaObject[index].text) == false ){
                        auxFormulaObject[index].isDraggableDisabled = false;
                    }
                    else{
                        auxFormulaObject[index].isDraggableDisabled = true;
                    }

                })

                let newState = update(this.state, {
                    formula: {$set: auxFormulaObject},
                    isFinishLoading: {$set: true}
                });

                this.setState(newState, () => {
                    this.initialFormula = lodash.cloneDeep(this.state.formula);
                });            
            });

        } catch (err) {
            console.log("Error catch");
            let newState = update(this.state, {
                isLoading: {$set: false},
            });
            this.setState(newState, async() => {
                try{
                    //En caso que no exista registro de formula, lo crea
                    //let newFormulaData = await ExtraccionesCafeAPI.CreateFormulaData(this.state.formulaData);
                    let newState = update(this.state, {
                        isLoading: {$set: false},
                    });
                    this.setState(newState);
                }catch (err){
                    if(err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                }
    
                let newState2 = update(this.state, {
                    isLoading: {$set: false}
                });
                this.setState(newState2); 
                
            });
    
        }   

        let newState = update(this.state, {
            isLoading: {$set: false}
        });
        this.setState(newState); 
        
    }


    /**
     * Funcion que se ejecuta para guardar toda la información del módulo en base de datos
     */

    saveModule = () => {

        if((!this.state.invalidFieldTiempoVida) && (!this.state.invalidFieldFrecuencia)){
            let auxFormula = this.state.formula.map(e => e.text).join(' ');
            let newState = update(this.state, {
                isLoading: {$set: true},     
                formulaData: {
                    FormulaText: {$set: auxFormula}
                }
            });
            console.log("la formula a guardar >> ", this.state.formulaData );
            console.log("la proyección >> ", this.state.proyeccion );
            this.setState(newState, async() => {
                try{
                    if(this.state.formulaData.IdFormulaProduccionAdelantado > 0){
                        let newFormulaData = await ProyeccionFormulaAPI.UpdateFormulaData(this.state.formulaData);
                        let newState = update(this.state, {
                            isLoading: {$set: false},
                        });
                        this.setState(newState, () => {
                            toast.success(`Se guardó la información correctamente`);
                        });
                    }
                    else{
                        let newFormulaData = await ProyeccionFormulaAPI.CreateFormulaData(this.state.formulaData);
                        let newState = update(this.state, {
                            isLoading: {$set: false},
                            formulaData: {$set: newFormulaData.data}
                        });
                        this.setState(newState, () => {
                            toast.success(`Se guardó la información correctamente`);
                        });

                    }
                }catch (err){
                    if(err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                }

                let newState2 = update(this.state, {
                    isLoading: {$set: false}
                });
                this.setState(newState2); 
                
            });
        }
        else{
            toast.error("Verifica que todos los campos sean válidos antes de guardar")
        }

    }


    /**
     * Funcion que actualiza los elementos que se muestran en la paginación de las recetas a excluir
     * @param recipes arreglo con recetas de la página actual
     */

    changeRecipePage = (recipes) => {
        // let newState = update(this.state, {
        //     pageRecipes: {$set: recipes}
        // })
        
        // this.setState(newState);

        this.setState({pageRecipes: [...recipes]})
    }

    /**
     * Funcion que actualiza los elementos que se muestran en la paginación de los toppings a excluir
     * @param recipes arreglo con toppings de la página actual
     */

    changeToppingPage = (toppings) => {
        // let newState = update(this.state, {
        //     pageToppings: {$set: toppings}
        // })
        // this.setState(newState);
        this.setState({pageToppings: [...toppings]})
    }

    /**
     * Funcion que elimina un elemento de la lista de recetas a excluir
     */

    removeRecipeFromList = () => {
        let _recipes = this.state.formulaData.Recetas.filter( r => {
            return r.RecipeId !== this.state.recipeToRemove.RecipeId
        });

        let newState = update(this.state, {
            formulaData: {
                Recetas: {$set: _recipes},
            },
            showModalRemoveRecipe: {$set: false}
        });
        this.setState(newState, () => {
            toast.success(`Receta eliminada con éxito.`);
        });

    }

    /**
     * Funcion que muestra el modal de confirmación para remover una receta de la lista de recetas a excluir
     * @param recipe receta que se quiere eliminar de la lista
     */

    toggleModalRemoveRecipe = (recipe) => {
        let newState = update(this.state, {
            showModalRemoveRecipe: {$set: true},
            recipeToRemove: {$set: recipe}
        })
        this.setState(newState);
    }

    /**
     * Funcion que cierra el modal de confirmación para eliminar una receta de la lista
     */

    closeModalRemoveRecipe = () => {
        let newState = update(this.state, {
            showModalRemoveRecipe: {$set: false},
        })
        this.setState(newState);
    }

    /**
     * Funcion que elimina un elemento de la lista de toppings a excluir
     */

    removeToppingFromList = () => {
        let _toppings = this.state.formulaData.Toppings.filter( t => {
            return t.InventoryItemId !== this.state.toppingToRemove.InventoryItemId
        });
        let newState = update(this.state, {
            formulaData: {
                Toppings: {$set: _toppings}
            },
            showModalRemoveTopping: {$set: false}
        });
        this.setState(newState, () => {
            toast.success(`Topping eliminado con éxito.`)
        });

    }

     /**
     * Funcion que muestra el modal de confirmación para remover un topping de la lista de toppings a excluir
     * @param topping topping que se quiere eliminar de la lista
     */

    toggleModalRemoveTopping = (topping) => {
        let newState = update(this.state, {
            showModalRemoveTopping: {$set: true},
            toppingToRemove: {$set: topping}
        })
        this.setState(newState);
    }

    /**
     * Funcion que cierra el modal de confirmación para eliminar una receta de la lista
     */

    closeModalRemoveTopping = () => {
        let newState = update(this.state, {
            showModalRemoveTopping: {$set: false},
        })
        this.setState(newState);
    }

    /**
     * Funcion que guarda las recetas que se quieren excluir
     * @param checkedRecipes recetas que fueron seleccionadas
     */

    saveRecipes = (checkedRecipes) => {
        let newState = update(this.state, {
            showModal: {$set: false},
            showModalEditRecipes: {$set: false},
            formulaData: {
                Recetas: {$set: checkedRecipes}
            },
            
        });
        this.setState(newState, () => {
            toast.success(`Las recetas se actualizaron`);
        });
    }

    /**
     * Funcion que guarda las recetas que se quieren excluir
     * @param checkedToppings toppings que fueron seleccionados
     */

    saveToppings = (checkedToppings) => {
        let newState = update(this.state, {
            showModal: {$set: false},
            showModalEditToppings: {$set: false},
            formulaData: {
                Toppings: {$set: checkedToppings}
            },
            
        });
        this.setState(newState, () => {
            toast.success(`Los toppings se actualizaron`);
        });
    }

    /**
     * Funcion que guarda las recetas que se quieren excluir
     * @param e event
     * @param index índice del elemento de la fórmula que fue modificado
     */

    handleChangeFormula = (e, index) => {

        let filter = /^((\+|-|\/|\*|\(|\)){1}|\b([1-9]|[1-9][0-9]|100)\b){0,1}$/;

        if(!filter.test(e.target.value)){
            return;
        }

        if(this.state.formula[index].isDraggableDisabled == true){
             return;
        }

        let newState = update(this.state, {

            formula: {
                [index]: {
                    text : {$set: e.target.value}
                }
            },
            formulaWasModified: {$set: true},
            formulaIsValid: {$set: false}
            
        });
        this.setState(newState);
    }

     /**
     * Función que es llamada cuando se da cancel en el modal para editar la fórmula
     */

    cancelEditFormulaModal = () => {
        let newState = update(this.state, {
            showModal: {$set: false},
            showModalEditFormula: {$set: false},
            formulaWasModified: {$set: false},
            formulaIsValid: {$set: false},
            formula: {$set: this.initialFormula},
            addElementFormulaIsDisabled: {$set: false},
        });
        this.setState(newState);
    }

    /**
     * Función que se ejecuta para mostrar el modal para agregar/eliminar recetas de la
     * lista de recetas a excluir
     */

    cancelRecipesModal = () => {
        let newState = update(this.state, {
            showModal: {$set: false},
            showModalEditRecipes: {$set: false},
        });
        this.setState(newState);
    }

    /**
     * Función que se ejecuta para cancelar el modal para agregar/eliminar toppings de la
     * lista de toppings a excluir
     */

    cancelToppingsModal = () => {
        let newState = update(this.state, {
            showModal: {$set: false},
            showModalEditToppings: {$set: false},
        });
        this.setState(newState);
    }

    /**
     * Función que se encarga de regresar el estado a su estado inicial
     */
    resetState = () => {
        this.setState(this.baseState)
    }

    /**
     * Función que se encarga de checar si hay campos vacios en el modal
     */

    checkHasInvalidFields = () => {
        return !this.state.hasInvalidFields;
    }

    /**
     * Función que se encarga de cambiar el loading del state a su estado opuesto
     */

    changeLoading = () => {
        let newState = update(this.state, {
            isLoading: {$set : !this.state.isLoading}
        });
        this.setState(newState);
    }

    /**
     * Función que se encarga de hacer de manejar cambios en campo de Tiempo Vida
     */
    handleChangeTiempoVida = (e) => {  

        let auxTiempoVidaIsEmpty = false, auxTiempoVidaIsZero = false,  auxTiempoVidaIsMax = false, auxInvalidFieldTiempoVida = false;
        if ((isNaN(e.target.value)) || !(e.target.value)) { 
            auxTiempoVidaIsEmpty = true;
            auxInvalidFieldTiempoVida = true;
        }
        if(e.target.valueAsNumber === 0){
            auxTiempoVidaIsZero = true;
            auxInvalidFieldTiempoVida = true;
        }
        if(e.target.valueAsNumber > 480){
            auxTiempoVidaIsMax = true;
            auxInvalidFieldTiempoVida = true;
        }

        let newState = update(this.state, {
            formulaData: {
                [e.target.name] : {$set: e.target.valueAsNumber},
            },
            tiempoVidaIsEmpty: {$set: auxTiempoVidaIsEmpty},
            tiempoVidaIsZero: {$set: auxTiempoVidaIsZero},
            tiempoVidaIsMax: {$set: auxTiempoVidaIsMax},
            invalidFieldTiempoVida: {$set: auxInvalidFieldTiempoVida} 
        });
        this.setState(newState);
    }

    /**
     * Función que se encarga de hacer de manejar cambios en campo de Frecuencia
     */
    handleChangeFrecuencia = (e) => {  

        let auxFrecuenciaIsEmpty = false, auxFrecuenciaIsZero = false, auxFrecuenciaIsMax = false ,auxInvalidFieldFrecuencia = false;
        if ((isNaN(e.target.value)) || !(e.target.value)) { 
            auxFrecuenciaIsEmpty = true;
            auxInvalidFieldFrecuencia = true;
        }
        if(e.target.valueAsNumber === 0){
            auxFrecuenciaIsZero = true;
            auxInvalidFieldFrecuencia = true;
        }
        if(e.target.valueAsNumber > 480){
            auxFrecuenciaIsMax = true;
            auxInvalidFieldFrecuencia = true;
        }

        let newState = update(this.state, {
            formulaData: {
                [e.target.name] : {$set: e.target.valueAsNumber},
            },
            frecuenciaIsEmpty: {$set: auxFrecuenciaIsEmpty},
            frecuenciaIsZero: {$set: auxFrecuenciaIsZero},
            frecuenciaIsMax: {$set: auxFrecuenciaIsMax},
            invalidFieldFrecuencia: {$set: auxInvalidFieldFrecuencia} 

        });
        this.setState(newState);
    }


    // Solo permite números, sin signos ni exponenciales
    onKeyPressNumber(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/\+|-|e|E|\./.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
        event.preventDefault();
    }

    // No permite escribir en el ccampo
    onKeyDown(event) {
        event.preventDefault();
    } 

    /**
     * Función que se ejecuta para mostrar el modal para editar la fórmula
     *  @param e event
     */

    toggleEditFormulaModal = (e) => {
        e.stopPropagation();
        this.initialFormula = lodash.cloneDeep(this.state.formula);
        let newState = update(this.state, {
            showModalEditFormula: {$set: true},
            showModal: {$set: true}
        })
        this.setState(newState);
    }

    /**
     * Función que se ejecuta para mostrar el modal para agregar o eliminar recetas a excluir
     *  @param e event
     */

    toggleEditRecipesModal = (e) => {
        e.stopPropagation();
        let newState = update(this.state, {
            showModalEditRecipes: {$set: true},
            showModal: {$set: true}

        })
        this.setState(newState);
    }

    /**
     * Función que se ejecuta para mostrar el modal para agregar o eliminar toppings a excluir 
     *  @param e event
     */

    toggleEditToppingsModal = (e) => {
        e.stopPropagation();
        let newState = update(this.state, {
            showModalEditToppings: {$set: true},
            showModal: {$set: true}
        })
        this.setState(newState);
    }

     /**
     * Función que se ejecuta para mostrar el modal que da información acerca del módulo
     *  @param e event
     */

    toggleModalInfo = (e) => {
        e.stopPropagation();
        let newState = update(this.state, {
            showModalInfo: {$set: true}
        })
        this.setState(newState);
    }

     /**
     * Función que se ejecuta para mostrar el modal para cancelar el modal de información del módulo
     */

    cancelModalInfo = () => {
        let newState = update(this.state, {
            showModalInfo: {$set: false}
        })
        this.setState(newState);
    }

    /**
     * Función que se ejecuta al dar "cancelar" en el modal de 
     * "Confirmar desactivar dos lineas producción"
     */
    closeAcceptModal = () => {
        let newState = update(this.state, {
            showModalConfirmationMessage: {$set: false}

        })
        this.setState(newState);
    }

    /**
     * Función que se encarga de cerrar el modal de la configuraciónVP
     */

    closeModal = () => {
        toast.success("Éxito");
        this.props.cancel();
    }
    
    /**
     * Función que se ejecuta para verificar que la formula es válida
     */

    checkFormula = () => {
        let _newState = update(this.state, {
            isLoading: {$set : true}
        });
        this.setState(_newState, () => {
            let auxFormula = this.state.formula.map(e => e.text).join(' ');
            let TiempoVida = this.state.tiempoVida;
            let Frecuencia = this.state.frecuencia;
            let VentaHistorica = 1;
            
            try {
                let result = eval(auxFormula);
                let newState = update(this.state, {
                    formulaIsValid: {$set: true},
                    isLoading: {$set: false}
                })
                this.setState(newState, () => {
                    toast.success(`La fórmula es válida`);
                });

            } catch (err) {
                let newState = update(this.state, {
                    isLoading: {$set: false}
                })
                this.setState(newState, () => {
                    toast.error(`La fórmula no es válida, revise los elementos de la fórmula`);
                });
            }

        }); 

    }

        /**
     * Función que se ejecuta al soltar un elemento en el drag n drop
     *  @param result objeto del drag n drop
     */

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
    
        var items = this.reorder(
          this.state.formula,
          result.source.index,
          result.destination.index
        );

        let newState = update(this.state, {

            formula: {$set: items},
            formulaWasModified: {$set: true},
            formulaIsValid: {$set: false}
        })
        this.setState(newState);

      }

    // a little function to help us with reordering the result
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        // var b = result[y];
        // result[y] = result[x];
        // result[x] = b;
    
        return result;
    };
    
    getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: this.grid * 2,
        margin: `0 ${this.grid}px 0 0`,
      
        // change background colour if dragging
        background: isDragging ? 'lightgreen' : 'lightgrey',
      
        // styles we need to apply on draggables
        ...draggableStyle,
    });
      
    getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'lightblue' : 'white',
        display: 'flex',
        padding: this.grid,
        overflow: 'auto',
    });

    /**
     * Regresa a la ruta anterior.
     */
     handleGoBack = () => {
        window.history.back();
    }


    render(){
        
        return (
            this.state.isFinishLoading &&
            <ProyeccionFormulaComponent
                {...this.state}
                checkHasInvalidFields={this.checkHasInvalidFields}
                onKeyPressNumber={this.onKeyPressNumber}
                onKeyDown={this.onKeyDown}
                handleChange={this.handleChange}
                submit={this.submit}
                cancelEditFormulaModal={this.cancelEditFormulaModal}
                cancelRecipesModal = {this.cancelRecipesModal}
                cancelToppingsModal = {this.cancelToppingsModal}
                toggleEditFormulaModal = {this.toggleEditFormulaModal}
                reorder = {this.reorder}
                getItemStyle = {this.getItemStyle}
                getListStyle = {this.getListStyle}
                onDragEnd = {this.onDragEnd}
                checkFormula = {this.checkFormula}
                checkDraggableStatus = {this.checkDraggableStatus}
                handleChangeFormula = {this.handleChangeFormula}
                saveFormula = {this.saveFormula}
                saveModule = {this.saveModule}
                toggleEditRecipesModal = {this.toggleEditRecipesModal}
                toggleEditToppingsModal = {this.toggleEditToppingsModal}
                saveRecipes = {this.saveRecipes}
                saveToppings = {this.saveToppings}
                handleChangeTiempoVida = {this.handleChangeTiempoVida}
                handleChangeFrecuencia = {this.handleChangeFrecuencia}
                addElementFormula = {this.addElementFormula}
                removeElementFormula = {this.removeElementFormula}
                changeRecipePage = {this.changeRecipePage}
                changeToppingPage = {this.changeToppingPage}
                removeRecipeFromList = {this.removeRecipeFromList}
                removeToppingFromList = {this.removeToppingFromList}
                toggleModalRemoveRecipe = {this.toggleModalRemoveRecipe}
                closeModalRemoveRecipe = {this.closeModalRemoveRecipe}
                toggleModalRemoveTopping = {this.toggleModalRemoveTopping}
                closeModalRemoveTopping = {this.closeModalRemoveTopping}
                toggleModalRemoveElementFormula = {this.toggleModalRemoveElementFormula}
                cancelModalRemoveElementFormula = {this.cancelModalRemoveElementFormula}
                toggleModalInfo = {this.toggleModalInfo}
                cancelModalInfo = {this.cancelModalInfo}
                handleGoBack = {this.handleGoBack}
            />
        );
    }


}

ProyeccionFormulaContainer.defaultProps = {
    
    formula: [

    ],

    formulaData : {
            IdFormulaProduccionAdelantado: 0,
            IdProduccionAdelantado: 0,
            FormulaText: "",
            Frecuencia: 1,
            TiempoVida: 1,
            Recetas: [],
            Toppings: []
    }
}



export { ProyeccionFormulaContainer };