import React, { Component } from 'react';
import  SearchForm  from "../../util/searchform/SearchForm";
import  TableTemplate  from "../../util/tabletemplate/TableTemplate";
import  NoItems  from "../../util/noitems/NoItems";
import AcceptModal from '../../util/acceptModal/AcceptModal';
import Pagination from '../../util/pagination/Pagination';
import RecipesAPI from '../../../services/Recipe';
import { toast } from 'react-toastify';
import Loading from '../../util/loading/Loading';
import RecipeTypesAPI from '../../../services/RecipeType';
import PresentationsAPI from '../../../services/Presentations';
import MilksAPI from '../../../services/Milk';
import GrainsAPI from '../../../services/Grain';
import FlavorsAPI from '../../../services/Flavor';
import ClasificacionesAPI from '../../../services/Classification';

import lodash from 'lodash';

import ModoGuiado from "../../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../../util/BtnModoGuiado/BtnModoGuiado'
import Modal from '../../util/modal/Modal';
import RecipeToppings from './components/RecipeToppings';
import NewRecipeDetail from './components/NewRecipeDetail';
import RecipeImageForm from './RecipeImageForm';
import ErrorMessages from '../../../util/validation-error-messages';
import RecipeFilters from '../../util/recipeFilters/recipeFilters';
import RecipeDetails from './components/RecipeDetails';
import roles from '../../../util/strings/roles';
import {nombresSinTipo} from '../../../util/strings/sin-tipo-receta';
import EmpresasAPI from '../../../services/CaffenioEmpresas/Empresas';

class Recipes extends Component {
    recipes = [];
    origRecipeTypesList = []; //Donde se va a guardar la lista original de tipos de receta
    origFlavorsList = []; //Donde se va a guardar la lista original de sabores
    origGrainsList = []; //Donde se va a guardar la lista original de granos
    origMilksList = []; //Donde se va a guardar la lista original de leches
    origPresentationList = []; //Donde se va a guardar la lista original de presentaciones

    // Variables donde se almacenaran los valores seleccionados en el select multi
    tempRecipeTypesList;
    tempPresentationList;
    tempMilkList;
    tempGrainList;
    tempFlavorsList;
    tempClassificationList;

    constructor(props){
        super(props);
        this.state = {
            recipes: [],
            filterText: "",
            recipe: null,
            pageRecipes: [],
            showModal: false,
            editing: false,
            removeModal: false,
            isLoading: false,
            syncModal: false,
            selectedRecipes: [],
            checkAll: false,
            syncMultiModal: false,
            // Variables sonde se almacenaran los criterios de busqueda definitivos
            recipeTypeList: [],
            selectedRecipeTypesList: [],
            presentationsList: [],
            selectedPresentationsList: [],
            milksList: [],
            segmentsList: [],
            selectedSegmentationList: [],
            selectedMilksList: [],
            grainsList: [],
            selectedGrainsList: [],
            flavorsList: [],
            selectedFlavorsList: [],
            classificationList: [],
            selectedClassificationList: [],
            manageToppingModal: false,
            addToppingModal: false,
            toppings: [],
            toppingError: false,
            menuRecetaModal: false,
            menuRecetaModalTitle: "",
            menuRecetaModalBody: "",
            menuRecetaCurrent: null,
            //Agregar artículos
            manageInventoryItemsModal:false,
            addInventoryItems:false,
            inventoryItems: [], 
            inventoryItemsTemp: [], 
            recipesToAddItems:[],
            inventoryItemsError:false,
            showInventoryItemsInfo:false
        }
    }

    componentDidMount(){
        this.setState({isLoading: true})
        let state = {...this.state};
        RecipesAPI.getAll().then(response => {
            state.recipes = response.data
            this.recipes = lodash.cloneDeep(response.data);
            console.log("recetas en recetas >> ", response);
            // Obtener todos los tipos de recetas
            return RecipeTypesAPI.getAll()
        }).then(response => {
            let temp = [];
            
            response.data.map(obj => {
                if(obj.RecipeName) {
                    obj.value= obj.RecipeTypeId;
                    obj.label= obj.RecipeName;
                    
                }else{
                    obj.value= obj.RecipeTypeId;
                    obj.label= obj.Description;
                    
                }
                temp.push(obj)
                return obj;
            })
            temp.sort(this.compare); // Ordenar los labels para elegir
            state.recipeTypeList = temp;
            this.origRecipeTypesList = lodash.cloneDeep(temp)
            
            // Obtener todas las presentaciones
            return PresentationsAPI.getAll()
        }).then(response => {
            let tempPresentationList = [];
            response.data.map(obj => {
                if(obj.Active){
                    obj.value= obj.PresentationId;
                    obj.label= obj.Name;
                    tempPresentationList.push(obj)
                    return obj
                    //
                }                
            })
            tempPresentationList.sort(this.compare); // Ordenar los labels
            state.presentationsList = tempPresentationList
            this.origPresentationList = lodash.cloneDeep(tempPresentationList)

            // Obtener los tipos de leche
            return MilksAPI.getAll()
        }).then(response => {
            let tempMilkList = [];
            response.data.map(obj => {
                obj.value= obj.MilkTypeId;
                obj.label= obj.Description;
                tempMilkList.push(obj)
                return obj;
            })
            tempMilkList.sort(this.compare); // Ordenar los labels
            state.milksList = tempMilkList
            this.origMilksList = tempMilkList
            
            // Obtener los tipos de grano
            return GrainsAPI.getAll()
        }).then(response => {
            let tempGrainList = [];
            response.data.map(obj => {
                obj.value= obj.GrainTypeId;
                obj.label= obj.Description;
                tempGrainList.push(obj)
                return obj;
            
            })
            tempGrainList.sort(this.compare); // Ordenar los labels
            state.grainsList = tempGrainList
            this.origGrainsList = lodash.cloneDeep(tempGrainList)
            
            // Obtener los sabores
            return FlavorsAPI.getAll()
        }).then(response => {
            let tempFlavorsList = [];
            response.data.map(obj => {
                obj.value= obj.FlavorTypeId;
                obj.label= obj.Name;
                tempFlavorsList.push(obj)
                return obj;
                
            })
            tempFlavorsList.sort(this.compare); // Ordenar los labels        
            state.flavorsList = tempFlavorsList
            this.origFlavorsList = tempFlavorsList
            
            // Obtener las clasificaciones
            return ClasificacionesAPI.getAll()
        }).then(response => {
            let tempClassificationList = [];
            response.data.map(obj => {
                obj.value= obj.ClassificationId;
                obj.label= obj.Name;
                tempClassificationList.push(obj)
                return obj;
            })
            tempClassificationList.sort(this.compare); // Ordenar los labels
            state.classificationList = tempClassificationList
            
            // Guardar el estado
            return EmpresasAPI.getAllFilterSegments()
        }).then(response => {
            let tempSegmentsList = []
            response.data.map(obj => {
                tempSegmentsList.push(obj)
            })
            state.segmentsList = tempSegmentsList
            
            // Guardar el estado
            this.setState(state)
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    }

    /**
     * Funcion para ordenar lexicograficamente
     * @param objA. primer operando
     * @param objB. segundo operando
     */
    compare = (objA, objB) => {
        // Use toUpperCase() to ignore character casing
        const labelA = objA.label.toUpperCase();
        const labelB = objB.label.toUpperCase();

        let comparison = 0;
        if (labelA > labelB) {
            comparison = 1;
        } else if (labelA < labelB) {
            comparison = -1;
        }
        return comparison;
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let state = this.state;
        state.filterText = text;
        this.setState(state, this.handleSearchMultipleFieldInput);
    }

    /**
     *Realizar el filtrado sobre las recetas para desplegar solo los elementos
     *que cumplen con las caracteristicas que el usuario ingreso.
     * 
     *@param: nada
     *@return: nada
     */
    handleSearchMultipleFieldInput = () => {
        let recipes = this.recipes.filter(
            recipe => ( 
                (this.state.selectedRecipeTypesList.length > 0 ? 
                this.state.selectedRecipeTypesList.includes(recipe.RecipeTypeId) : 
                true) &&
                (this.state.selectedFlavorsList.length > 0 ? 
                this.state.selectedFlavorsList.includes(recipe.FlavorTypeId) : 
                true) &&
                (this.state.selectedPresentationsList.length > 0 ? 
                this.state.selectedPresentationsList.includes(recipe.PresentationId) : 
                true) &&
                (this.state.selectedMilksList.length > 0 ? 
                this.state.selectedMilksList.includes(recipe.MilkTypeId) : 
                true) &&
                (this.state.selectedGrainsList.length > 0 ? 
                this.state.selectedGrainsList.includes(recipe.GrainTypeId) : 
                true) &&
                (this.state.selectedClassificationList.length > 0 ? 
                this.state.selectedClassificationList.includes(recipe.ClassificationId) : 
                true) &&
                (this.state.selectedSegmentationList.length > 0 ?
                this.state.selectedSegmentationList.includes(recipe.idSegmento) :
                true )&&
                (this.state.filterText.trim() !== '' ?
                ( (recipe.ShortName 
                    ? recipe.ShortName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) 
                    : false) 
                    || recipe.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                : true)
            )
        );
        
        let state = {...this.state};
        state.recipes = lodash.cloneDeep(recipes);
        state.recipes.map(r => {
            r.statusCheck =false
        });
        state.selectedRecipes = [];
        state.checkAll = false;
        this.setState(state);
    }

    create = () => {
        this.props.history.push({pathname: '/recetas/nueva',  state:{
            classifications: this.state.classificationList,
            presentations: this.state.presentationsList,
            flavors: this.state.flavorsList,
            milks: this.state.milksList,
            segments: this.state.segmentsList,
            grains: this.state.grainsList,
            recipeTypes: this.state.recipeTypeList,
            lstRecipes:this.recipes
        }});
    }

    edit = (recipe) => { 
        this.props.history.push({pathname: `/recetas/editar/${recipe.RecipeId}`, state:{
            classifications: this.state.classificationList,
            presentations: this.state.presentationsList,
            flavors: this.state.flavorsList,
            milks: this.state.milksList,
            grains: this.state.grainsList,
            recipeTypes: this.state.recipeTypeList,
            lstRecipes:this.recipes
        }});   
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de receta
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleRecipeTypeChange = (arr) => {
        let state = {...this.state}
        let tempSelectedRecipeTypesList = [];
        arr.map(obj => {
            tempSelectedRecipeTypesList.push(obj.value);
        })

        state.selectedRecipeTypesList = tempSelectedRecipeTypesList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de sabores
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleFlavorChange = (arr) => {
        let state = {...this.state}
        let tempSelectedFlavorList = [];
        arr.map(obj => {
            tempSelectedFlavorList.push(obj.value);
        })

        state.selectedFlavorsList = tempSelectedFlavorList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre las presentaciones
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handlePresentationChange = (arr) => {
        let state = {...this.state}
        let tempPresentationList = [];
        arr.map(obj => {
            tempPresentationList.push(obj.value);
        })

        state.selectedPresentationsList = tempPresentationList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de leche
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleMilkChange = (arr) => {
        let state = {...this.state}
        let tempMilksList = [];
        arr.map(obj => {
            tempMilksList.push(obj.value);
        })

        state.selectedMilksList = tempMilksList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de granos.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleGrainChange = (arr) => {
        let state = {...this.state}
        let tempGrainList = [];
        arr.map(obj => {
            tempGrainList.push(obj.value);
        })

        state.selectedGrainsList = tempGrainList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre las clasificaciones.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleClassificationChange = (arr) => {
        let state = {...this.state}
        let tempClassificationList = [];
        arr.map(obj => {
            tempClassificationList.push(obj.value);
        })

        state.selectedClassificationList = tempClassificationList;
        state.selectedRecipes = [];
        // state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
            this.filterOptionsByClasification();
        });
    }

    /**
     * Filtra las opciones de los filtros(hehe) segun la clasificacion seleccionada.
     * Por ejemplo, seleccionó la Familia "Chocolate" entonces en el filtro de tipo de receta solo me tiene que aparecer "Caliente" y "Frio"
     */
    filterOptionsByClasification = () => {
        let clasificaciones = this.state.selectedClassificationList
        if(clasificaciones.length > 0 ){
            let stateFiltros = {}
            // Filtrar los tipos de receta
            RecipeTypesAPI.getByClasification(clasificaciones).then(response => {
                let temp = response.data.map(obj => {
                    return {value: obj.RecipeTypeId, label: obj.RecipeName ? obj.RecipeName : obj.Description}
                })
                stateFiltros.recipeTypeList = temp
                
                // Filtrar los sabores
                return FlavorsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempFlavorsList = response.data.map(obj => {
                    return {value: obj.FlavorTypeId, label:obj.Name}
                })
                tempFlavorsList.sort(this.compare); // Ordenar los labels        
                stateFiltros.flavorsList = tempFlavorsList
                
                // Filtrar los granos de cafe
                return GrainsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempGrainList = response.data.map(obj => {
                    return {value: obj.GrainTypeId, label: obj.Description}
                })
                tempGrainList.sort(this.compare); // Ordenar los labels
                stateFiltros.grainsList = tempGrainList
                
                // Filtrar los tipos de leche
                return MilksAPI.getByClasification(clasificaciones)
            })
            .then(response => {
                let tempMilkList = response.data.map(obj => {
                    return {value: obj.MilkTypeId, label: obj.Description}
                })
                tempMilkList.sort(this.compare); // Ordenar los labels
                stateFiltros.milksList = tempMilkList
                
                // Filtrar las presentaciones
                return PresentationsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempPresentationList = response.data.map(obj => {
                    return {value: obj.PresentationId, label: obj.Name}
                })
                tempPresentationList.sort(this.compare); // Ordenar los labels
                stateFiltros.presentationsList = tempPresentationList

                this.setState(stateFiltros)
            })
        }else{
            this.setState({
                recipeTypeList: lodash.cloneDeep(this.origRecipeTypesList),
                flavorsList: lodash.cloneDeep(this.origFlavorsList),
                grainsList: lodash.cloneDeep(this.origGrainsList),//Donde se va a guardar la lista original de granos
                milksList: lodash.cloneDeep(this.origMilksList),//Donde se va a guardar la lista original de leches
                presentationList: lodash.cloneDeep(this.origPresentationList)//Donde se va a guardar la lista original de presentaciones
            })
        }
    }

    handleSelectChange = (objFilters) => {
        this.setState(objFilters, () => {this.handleSearchMultipleFieldInput();})
    }

    handleMenuRecetaModalBody = (recipeId) => {
        RecipesAPI.getMenu(recipeId)
        .then(result => {
            let tablaMenus = <TableTemplate 
            columns={["MenuId", "Description", "Active"]}
            columnsNames={["ID", "Descripción", "Activo"]}
            data={result.data}
            noActions={true}
            disableCheck={true}
            />
            this.setState({menuRecetaModalBody:tablaMenus, isLoading: false})
        })
        .catch(error => {
            if(error){
                toast.error(error.response ? error.response.data.Message : "Error al cargar los menús.");
            }
            this.setState({menuRecetaModalBody:"Error al cargar los menús.", isLoading: false})
        })
    }    

    remove = () => {
        this.setState({isLoading: true});
        RecipesAPI.delete(this.state.recipe.RecipeId).then(response => {
            let state = {...this.state};

            state.recipes.splice(state.recipes.findIndex(c => c.RecipeId === state.recipe.RecipeId), 1);
            state.pageRecipes.splice(state.pageRecipes.findIndex(c => c.RecipeId === state.recipe.RecipeId), 1);
            state.isLoading = false;
            state.removeModal = false;
            state.recipe = null;

            this.setState(state, () => toast.success("Receta eliminada"));
        }).catch(err => {
            if(err){
                this.setState({isLoading: false, recipe: null, removeModal: false});
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            }
        });
    }

    /**
     * Genera un movimiento de sincronizacion a los drives que tienen la receta seleccionada en su menu
     */
    syncRecipe = () => {
        this.setState({isLoading: true});
        RecipesAPI.sync(this.state.recipe.RecipeId).then(response => {
            this.setState({syncModal: false, isLoading: false, recipe: null}, () => toast.success("Orden de sincronización generado"));
        }).catch(err => {
            if(err){
                this.setState({isLoading: false, recipe: null, syncModal: false});
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            }
        });
    }

    /**
     * Genera un movimiento de sincronizacion a los drives que tienen almenos una de las recetas seleccionadas en su menu
     */
    syncRecipeMulti = () => {
        this.setState({isLoading: true});
        RecipesAPI.syncMulti(this.state.selectedRecipes).then(response => {
            let _pageRecipes = [...this.state.pageRecipes]
            _pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false)
            this.setState({syncMultiModal: false, isLoading: false, selectedRecipes: [], pageRecipes: _pageRecipes}, () => toast.success("Orden de sincronización generado"));
        }).catch(err => {
            if(err){
                this.setState({isLoading: false, syncMultiModal: false});
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            }
        });
    }

    /**
     * Funcion que se ejecuta al darle click al checkbox del header
     */
    selectAllCheck = () => {
        let _checkAll = !this.state.checkAll
        let _selectedRecipes = [...this.state.selectedRecipes]
        let _pageRecipes = this.state.pageRecipes
        _pageRecipes.forEach(recipe => {
            let indexRecipe = _selectedRecipes.findIndex(item => item === recipe.RecipeId)
            recipe.statusCheck = _checkAll
            if(_checkAll){
                if(indexRecipe === -1) _selectedRecipes.push(recipe.RecipeId)
            }else{
                if(indexRecipe !== -1) _selectedRecipes.splice(indexRecipe, 1)
            }

        })

        this.setState({checkAll: _checkAll, selectedRecipes: _selectedRecipes})
    }

    /**
     * Funcion que se ejecuta al cambiar la pagina del tablero
     */
    changeRecipePage = (recipes) => {
        let recipesChecked = 0
        let checkAll = false
        recipes.forEach(item => {
            if(this.state.selectedRecipes.findIndex(id => id === item.RecipeId) !== -1)
                recipesChecked++
        })
        if(recipesChecked === recipes.length) checkAll = true 

        this.setState({ pageRecipes: recipes, checkAll: checkAll })
    }

    /*
    * Funcion que selecciona o des selecciona todas las 
    * recetas de todas las paginas.
    */
    selectAllCheckBox = () => {
        let state = {...this.state};
        let elementsPage = state.recipes
        let selectedRecipes = []
        // Le asignamos el valor inverso de checkAll a todas las recetas actuales
        elementsPage.map(element=>{
            if(state.checkAll){
                element.statusCheck = false 
            }else{
                element.statusCheck = true
                selectedRecipes.push(element.RecipeId)
            }

            return element
        })
        state.checkAll = !state.checkAll;
        state.recipes = elementsPage;
        state.selectedRecipes = selectedRecipes
        this.setState(state);
    }

    /**
     * Función que checa que las cantidades de los toppings esten correctas.
     */
    checkForToppingErrors = () => {
        let errorTopping = false;
        this.state.toppings.findIndex(topping => {
            if(topping.MinQuantity > topping.MaxQuantity){
                errorTopping = true
                return true
            }
            return false
        })
        this.setState({toppingError: errorTopping})
    }

    /**
     * Función que realiza petición al server para añadir los toppings que estan en this.state.toppings a los toppings seleccionados
     */
    addToppingsToRecipes = () => {
        // Primero hay que crear el payload. En este caso sera una coleccion de {RecipeId: X, Toppings: [toppings en el estado]}
        let payloadRecipes = this.state.selectedRecipes.map(recipeId => {
            return {RecipeId: recipeId, Toppings: [...this.state.toppings]}
        })

        // Hacer la peticion
        RecipesAPI.addToppings(payloadRecipes)
        .then(result => {
            this.setState({manageToppingModal: false, toppings: [], toppingError: false, isLoading: false}, () => toast.success("Topping(s) agregado(s) exitosamente."))
        })
        .catch(error => {
            if(error){
                toast.error(error.response ? error.response.data.Message : "Error al agregar topping(s).");
                this.setState({isLoading: false});
            }
        })
    }

    /**Función para agregar artículos  */
    addInventoryItemsToRecipes = () => {
        let recipesToAddItems = this.state.selectedRecipes.map(recipeId => {
            return {RecipeId: recipeId, Details: [...this.state.inventoryItems.filter(i => (i.Amount && i.Amount > 0) || !i.Activo )]}
        });

        RecipesAPI.addInventoryItems(recipesToAddItems)
        .then(result => {
            this.setState({manageInventoryItemsModal: false, inventoryItems: [], inventoryItemsError: false, isLoading: false, inventoryItemsTemp:[]}, () => toast.success("Artículo(s) agregado(s) y/o actualizados exitosamente."))
        })
        .catch(error => {
            if(error){
                toast.error(error.response ? error.response.data.Message : "Error al agregar topping(s).");
                this.setState({isLoading: false});
            }
        });
    }

    /** Obtiene los artículos inventarios que comparten
     *  las recetas seleccionadas */
    getInventoryItemsCommon = () => {
        this.setState({isLoading: true, inventoryItems: [], inventoryItemsTemp:[]}, () => {
            //por cada receta se trae los artículos 
            let lstInventoryItems = [];
            let selectedRecipes = this.state.recipes.filter(r => {                
                if(r.statusCheck){
                    r.completed = false;
                    return r;
                }
            });            
            let findCommon = true;
            selectedRecipes.forEach((recipe) => {
                if(findCommon){
                    RecipesAPI.get(recipe.RecipeId).then(response => {  
                        if(response.data && response.data.Details && response.data.Details.length > 0){    
                            recipe.completed = true;
                            let arrayUnion = [];                 
                            let items = response.data.Details.reduce((lista, detail) => {
                                if(!lista.some(l => l.InventoryItemId === detail.InventoryItem.InventoryItemId)){
                                    detail.InventoryItem.Amount = undefined;
                                    detail.InventoryItem.Activo = true;
                                    detail.InventoryItem.idDrive = [];
                                    lista.push(detail.InventoryItem);  
                                }
                                return lista;
                                                     
                            }, []);
                            arrayUnion = lstInventoryItems.length === 0 
                                ? items 
                                : lstInventoryItems.filter(x => {
                                    if(items.some(l => l.InventoryItemId === x.InventoryItemId)){
                                        return x;
                                    }
                                });
                               
                            if(arrayUnion.length > 0){
                                lstInventoryItems = arrayUnion;
                            } else{
                                //resetea listado ya que no hay ningura coicidencia 
                                lstInventoryItems = [];
                                findCommon = false; 
                            }
                            let anyUncomplete = selectedRecipes.some(s => !s.completed);
                            this.setState({  
                                isLoading: anyUncomplete,     
                                manageInventoryItemsModal: !anyUncomplete,
                                inventoryItems: lstInventoryItems , 
                                inventoryItemsTemp: lstInventoryItems
                            }); 
                        } 
                    })
                    .catch(error => {     
                        this.setState({  isLoading: false, }, () => {                   
                            toast.error("Ocurrió un error al cargar artículos en común.");                   
                        });
                    });
                }                
            }); 
              
        });

    }

    /**
     * Función que analiza el nombre dado y determina si representa a la clasificacion sin tipo
     * @param nombre String. Nombre que se va analizar
     * @returns True si el nombre representa a la clasificación sin tipo. False en caso contrario
     */
     nombreEsSinTipo = (nombre) => {    
        return nombresSinTipo.indexOf((nombre.trim().toLowerCase())) !== -1
    }

    render() {
        return (
            <div>

                <ModoGuiado 
                    ruta="recetas"
                    tipoModoGuiado="receta"
                    anterior="toppings"
                    mensaje="Configuración de un producto terminado agrupado por diferentes variables" />

                <h1 className="title is-2">Recetas</h1>
                <div className="columns is-vcentered">
                    <div className="column is-5">
{/*                         <div className="card">
                            <div className="card-content">
                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'Receta'}
                                            placeholderText={"Ingrese descripción y nombre corto de receta"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="column is-7">
                        <div className="field">
                            <div className="control has-text-right">
                                {lodash.intersection(localStorage.getItem('role').split(","), [roles.ADMIN, roles.ADMINDRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES]).length > 0 &&
                                    <a className="button is-success" onClick={(e) => this.create()}>
                                        <span className="icon is-small">
                                            <i className="fa fa-plus"></i>
                                        </span>
                                        <span>Agregar Receta</span>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* Filtro multiple para recetas */}
                <div className="card">
                    <div className="card-content">
                        <div className="columns is-multiline">
                            
                            <RecipeFilters
                                onChange = {(objFilters) => {this.handleSelectChange(objFilters)}}
                            />

                        </div>
                    </div>
                </div>

                <br />
                
                <div className="card">
                    <div className="card-content">
                        {/* Botones del header de la tabla */}
                        <div className="columns">
                            {/* Todos los resultados */}
                            <div className="column" style={{display: "flex"}}>
                                <a className="button is-link" onClick={this.selectAllCheckBox}>
                                    <span className="icon is-small">
                                        <i className={!this.state.checkAll ? "fa fa-check-square" : "fa fa-square"}></i>
                                    </span>
                                    <span>{!this.state.checkAll ? "Seleccionar todos los resultados" : "Deseleccionar todos los resultados"}</span>
                                </a>
                            </div>

                        {this.state.selectedRecipes.length > 0 &&
                            <div className="column " style={{display: "flex", justifyContent: "flex-end", flexWrap: "wrap"}}>
                               
                                 {/* Agregar artículos */}
                                 {this.state.selectedClassificationList.length === 1 && this.state.selectedPresentationsList.length > 0
                                    && <a className="button is-danger tooltip" style={{marginRight: "0.5rem"}}
                                            onClick={(e) => {
                                                this.getInventoryItemsCommon()
                                            }}
                                            data-tooltip="+ Agregar Artículos"
                                        >
                                            <span className="icon is-small">
                                                <i className="fa fa-plus"></i>
                                            </span>
                                            <span>Artículos</span>
                                        </a>
                                 }
                                 

                                {/* Agregar toppings */}
                                <a className="button is-warning tooltip" style={{marginRight: "0.5rem"}}
                                    onClick={(e) => this.setState({manageToppingModal: true})}
                                    data-tooltip="+ Agregar Toppings"
                                >
                                    <span className="icon is-small">
                                        <i className="fa fa-plus"></i>
                                    </span>
                                    <span>Toppings</span>
                                </a>

                                {/* Sincronizar seleccionados */}
                                <a className="button is-success tooltip" onClick={(e) => this.setState({syncMultiModal: true})}
                                    data-tooltip="Sincronizar seleccionados">
                                    <span className="icon is-small">
                                        <i className="fa fa-refresh"></i>
                                    </span>
                                    <span>Sincronizar</span>
                                </a>
                            </div>
                        }
                        </div>
                        {this.state.recipes.length !== 0 
                        ? <TableTemplate
                            columns={["RecipeId", "Description", "ShortName", "NombreSegmento"]}
                            columnsNames={["ID", "Descripción", "Nombre Corto", "Segmento"]}
                            data={this.state.pageRecipes}
                            canEdit canDelete canSync checkbox
                            //edit={(recipe) => this.setState({showModal: true, recipe: recipe})} 
                            edit={(recipe) => this.edit(recipe)}
                            remove={(recipe) => this.setState({removeModal: true, recipe: recipe})}
                            sync={(recipe) => this.setState({syncModal: true, recipe: recipe})}
                            //Controlar checkboxes 
                            showView={(index) => {
                                let _selectedRecipes = [...this.state.selectedRecipes]
                                let indexRecipe = this.state.selectedRecipes.findIndex(id => id === this.state.pageRecipes[index].RecipeId)
                                if( indexRecipe === -1)
                                _selectedRecipes.push(this.state.pageRecipes[index].RecipeId);
                                else
                                _selectedRecipes.splice(indexRecipe, 1)
                                this.setState({selectedRecipes: _selectedRecipes})
                            }}
                            checkAll={this.state.checkAll}
                            //Controlador del checkbox del header
                            selectAllCheck={this.selectAllCheck}
                            //Función que se ejecuta al renderizar un renglon de la tabla. La tabla te devuelve el objeto que representa al renglon.
                            extraButtons={(row) => {
                                // Creamos un botón que lanza un modal con información de que menus esta la receta seleccionada
                                return <div>
                                    <button className="button tableAction is-info tooltip" data-tooltip="Consultar menús"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                            menuRecetaModal: true,
                                            isLoading: true,
                                            menuRecetaModalBody: "Cargando menús que contienen '" + row.Description + "'...",
                                            menuRecetaModalTitle: "Menús que contienen '" + row.Description + "'",
                                        }, this.handleMenuRecetaModalBody(row.RecipeId))
                                    }}
                                    >
                                        <i className="fa fa-info"></i>
                                    </button>
                                    

                                    { row.FlavorType !== null && !this.nombreEsSinTipo(row.FlavorType.Name) && <button className="button tableAction is-default tooltip" data-tooltip="Cambiar imagen"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        console.log(" ROW >>> " , row)
                                        this.setState({
                                            imageFormModal: true,                                           
                                            selectedRecipe: {...row}                                          
                                        })
                                    }}
                                    >
                                        <i className="fa fa-image"></i>
                                    </button>
                                    }
                                        
                                </div>
                            }}
                            rowClickable={true}
                            details={(recipe) => this.edit(recipe)}
                        /> 
                        : <NoItems itemName="recetas"/>}
                        <Pagination items={this.state.recipes} onChangePage={(recipes) => this.changeRecipePage(recipes) } />
                    </div>
                </div>
                
                {/* Modales */}
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove()}
                    cancel={() => this.setState({removeModal: false})}
                    modalTitle={"Eliminar Receta"}
                />}

                {this.state.syncModal && <AcceptModal 
                    isActive={this.state.syncModal}
                    done={() => this.syncRecipe()}
                    cancel={() => this.setState({syncModal: false})}
                    modalTitle={"Sincronizar " + this.state.recipe.Description + "?"}
                />}

                {this.state.syncMultiModal && <AcceptModal 
                    isActive={this.state.syncMultiModal}
                    done={() => this.syncRecipeMulti()}
                    cancel={() => this.setState({syncMultiModal: false})}
                    modalTitle={"Sincronizar recetas seleccionadas?"}
                />}

                {/* Modal para consultar menus */}
                {this.state.menuRecetaModal && <Modal 
                    showModal={this.state.menuRecetaModal}
                    cancel={() => this.setState({menuRecetaModal: false})}
                    modalTitle={this.state.menuRecetaModalTitle}
                >
                    {this.state.menuRecetaModalBody}
                </Modal>}

                {/* Modal de gestion de toppings */}
                {this.state.manageToppingModal && <Modal
                    showModal={this.state.manageToppingModal}
                    modalTitle={"Gestionar toppings"}
                    cancel={() => this.setState({manageToppingModal: false, toppings: [], toppingError: false})}
                    footer={true}
                    footerButtons={
                        <div style={{display: "flex", width: "100%", justifyContent: "flex-end"}}>
                            <button 
                                className="button is-success"
                                disabled={this.state.toppings.length === 0 || this.state.toppingError}
                                onClick={() => {
                                    this.setState({isLoading: true}, this.addToppingsToRecipes())
                                }}>
                                Confirmar
                            </button>
                        </div>
                    }
                    width="50%"
                >
                    <div>
                        <h1 className="subtitle has-text-weight-bold">
                            Agregar topping
                            &nbsp;
                            <a className="has-text-black" onClick={() => this.setState({addToppingModal: true})}>
                                <span className="icon is-small">
                                    <i className="fa fa-plus"></i>
                                </span>
                            </a>
                        </h1>
                    </div>
                    <RecipeToppings 
                        details={this.state.toppings}
                        quantityChanged={(value, index, labelQuantity) => {    
                            let toppings = [...this.state.toppings];
                            toppings[index][labelQuantity] = value;
                            this.setState({toppings: toppings}, this.checkForToppingErrors());
                        }}
                        removeDetail={(indexTopping) => {
                            let toppings = [...this.state.toppings];
                            toppings.splice(indexTopping,1);
                            this.setState({toppings: toppings}, () => {
                                toast.success("Topping eliminado");
                                this.checkForToppingErrors()
                            });
                        }}
                    />
                </Modal>}

                {/* Modal para agregar toppings */}
                {this.state.addToppingModal && 
                    <Modal
                        showModal={this.state.addToppingModal}
                        modalTitle={"Agregar topping"}
                        cancel={() => this.setState({ addToppingModal: false })}
                        width="75%"
                    >
                        <NewRecipeDetail
                            addDetail={(detail) => {
                                let toppings = [...this.state.toppings];
                                
                                toppings.push({
                                    CodeProvider: detail.CodeProvider,
                                    Name: detail.Name,
                                    InventoryItemId: detail.InventoryItemId,
                                    MinQuantity: detail.MinQuantity || 0,
                                    MaxQuantity: detail.MaxQuantity || 0
                                });
                                this.setState({toppings: toppings});
                            }}
                            currentDetails = {this.state.toppings.map(det => det.InventoryItemId)}
                            soloToppings={true}
                            isTopping={true}
                        />
                    </Modal>
                }

                {/* Modal de gestion de artículos */}
                {this.state.manageInventoryItemsModal && <Modal
                    showModal={this.state.manageInventoryItemsModal}
                    modalTitle={
                    <div>
                        Gestionar artículos
                        <i className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }}
                             onClick={() => this.setState({ showInventoryItemsInfo: !this.state.showInventoryItemsInfo })} className="fa fa-question-circle"></i>
                        
                    </div>}
                    cancel={() => this.setState({manageInventoryItemsModal: false, 
                        inventoryItems: [], 
                        inventoryItemsTemp:[],
                        inventoryItemsError:false, 
                        addInventoryItems: false})}
                    footer={true}
                    footerButtons={
                        <div style={{display: "flex", width: "100%", justifyContent: "flex-end"}}>
                            <button 
                                className="button is-success"
                                disabled={this.state.inventoryItems.length === 0 || this.state.inventoryItemsError}
                                onClick={() => {
                                    this.setState({isLoading: true}, this.addInventoryItemsToRecipes())
                                }}>
                                Confirmar
                            </button>
                        </div>
                    }
                    width="50%"
                >
                    <div>                    
                        {this.state.showInventoryItemsInfo &&
                        <div className="has-text-justified" style={{margin:"10px"}}>
                            <p className="has-text-weight-bold">INSTRUCCIONES</p>
                            <ol  type="1">
                                <li>Los artículos cargados son los que las recetas seleccionadas tienen en común.</li>
                                <li>Al editarse y confirmar, la cantidad, será actualizada en cada Receta.</li>
                                <li>Si el artículo no existe será agregado a las recetas.</li>
                                <li>Para no modificar el artículo no modifique la cantidad.</li>
                                <li>Para eliminar el artículo de todas las recetas presione el icono eliminar.</li>
                            </ol>
                        </div>
                         }
                        <h1 className="subtitle has-text-weight-bold">
                            Agregar artículos
                            &nbsp;
                            <a className="has-text-black" onClick={() => this.setState({addInventoryItems: true})}>
                                <span className="icon is-small">
                                    <i className="fa fa-plus"></i>
                                </span>
                            </a>
                        </h1>
                        
                        
                    </div>
                    <RecipeDetails
                        details={this.state.inventoryItemsTemp}
                        quantityChanged={(value, index) => {
                            if (value > 0) {
                                let state =  {...this.state};
                                let itemToUpdate = state.inventoryItemsTemp[index];

                                state.inventoryItems.map(item => {
                                    if(item.InventoryItemId === itemToUpdate.InventoryItemId){
                                        item.Amount = value;
                                    }
                                    return item;
                                });

                                state.inventoryItemsTemp[index].Amount = value;
                                this.setState(state);
                            } else {
                                toast.warn("La cantidad debe ser mayor a cero.")
                            }

                        }}
                        removeDetail={(index) => {
                            let state = {...this.state};
                            let itemToRm = state.inventoryItemsTemp[index];

                            state.inventoryItems = state.inventoryItems.map(item => {
                                if(item.InventoryItemId === itemToRm.InventoryItemId){
                                    item.Activo = false;
                                }
                                return item;
                            });

                            state.inventoryItemsTemp.splice(index, 1);                              
                            this.setState(state);
                        }}
                        mostrarDrives={false}
                        
                    />
                </Modal>}

                {/**modal para agregar artículos */}
                {this.state.addInventoryItems &&
                    <Modal
                        showModal={this.state.addInventoryItems}
                        modalTitle={"Agregar artículo inventario"}
                        customclassName="modalDrives"
                        cancel={() => this.setState({ addInventoryItems: false })}
                        width="75%"
                    >
                        <div className="columns is-multiline is-centered">
                            
                            <NewRecipeDetail
                                addDetail={(detail) => {
                                    if(!this.state.inventoryItems.some(i=> i.InventoryItemId === detail.InventoryItemId )){
                                        //Actualiza inventario
                                        let state = {...this.state};
                                        detail.Activo = true;
                                        detail.idDrive = detail.idDrive ? [...detail.idDrive] : [];

                                        if(!state.inventoryItems.some(i => i.InventoryItemId === detail.InventoryItemId)){
                                            state.inventoryItems.push(detail);
                                        }
                                        if(!state.inventoryItemsTemp.some(i => i.InventoryItemId === detail.InventoryItemId)){
                                            state.inventoryItemsTemp.push(detail);
                                        }

                                        this.setState(state);                                    
                                    }else{
                                        toast.dismiss()
                                        toast.warn("El artículo ya existe, fue removido.")
                                    }
                                }}
                                currentDetails={[]}
                                isDetail={true}
                            />
                        </div>
                    </Modal>
                }

                {/* Modal para editar imagen del sabor de la receta y el nombre corto */}
                {this.state.imageFormModal && 
                    <Modal
                        showModal={this.state.imageFormModal}
                        modalTitle={"Editar receta"}
                        cancel={() => this.setState({ imageFormModal: false })}
                        width="50%"
                    >
                        <RecipeImageForm
                            recipe={this.state.selectedRecipe}  
                            back={()=> this.setState({imageFormModal: false, selectedRecipe:null, isLoading: false})}                          
                            save = {(values) => {
                                this.setState({isLoading:true},() => {
                                    RecipesAPI.editImageRecipe({...values})
                                        .then(response => {
                                            toast.dismiss();
                                            toast.success("Receta actualizada correctamente");
                                            
                                        })
                                        .catch(err => {
                                            toast.dismiss();
                                            if (err.response)
                                                toast.error(err.response.data.Message)
                                            else
                                                toast.error(ErrorMessages.SinConexion)

                                        })
                                        .finally(() => {
                                            this.setState({ selectedRecipe: null, imageFormModal: false, isLoading: false })
                                            window.location.reload();

                                        })

                                })
                                

                            }} 
                        />
                    </Modal>
                }
                
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />                

            </div>
        );
    }
}

export default Recipes;
