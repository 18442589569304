import React, { Component } from 'react';
import Field from '../../util/field/Field';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorMessages from '../../../util/validation-error-messages';

const SUPPORTED_FORMATS = [
    "image/png"
];
/**
 * Formulario para cargar y validar PT asignado a artículo inventario
 */
class RecipeImageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipe: props.recipe,
            columna: props.columna, 
            initialValues:{Image:null}
        }

    }

    /**Esquema para validar al editar receta con imagen*/
    VALIDATION_SCHEMA_EDIT_WITH_IMG = Yup.object({
        Image: Yup.mixed()        
            .required("Campo Imagen es requerido.")    
            .test(
                "fileFormat",
                ErrorMessages.PngFormatError,
                value => value && SUPPORTED_FORMATS.includes(value.type) 

            )
            ,
        
    });

    render() {
        return (
            <Formik
                initialValues={this.state.initialValues}
                validationSchema={this.VALIDATION_SCHEMA_EDIT_WITH_IMG}
                onSubmit={(values) => {                    
                    this.props.save(values.Image)
                }}>
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                    <form onSubmit={handleSubmit} id="frmImageRecipeItem">
                        <div className="columns is-multiline">
                            <div className="column is-12 ">
                            <section className="accordions">
                                <article className="accordion is-info is-active" role="alert">                                    
                                    <div className="accordion-body">
                                        <div className="accordion-content">
                                            <p className="has-text-justified">
                                                {`Ingresa la imagen de ${this.state.columna}, que aparecerá en el PDV.`}
                                            </p>
                                        </div>
                                    </div>
                                </article>
                                </section>
                            </div>                       
                        
                            <div className="column is-4">
                                <Field label="Icono/Imagen" isHorizontal
                                    hasError={errors.Image || touched.Image}
                                    msgError={errors.Image || touched.Image  ? errors.Image : ""}
                                >
                                    <div className={`file ${errors.Image && touched.Image ? "is-danger" : "is-info"} has-name`}>
                                        <label className="file-label">
                                            <input
                                                className={`input file-input ${errors.Image || touched.Image ? "is-danger" : ""}`}
                                                id="fileRecipeItem"
                                                type="file"
                                                name="Image"
                                                accept="image/x-png"
                                                name="Image"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    let files = e.target.files
                                                    let file = files[0]
                                                    e.stopPropagation();
                                                    e.target.value = null;
                                                    if (file) {
                                                        setFieldValue('Image', file);
                                                        setFieldValue('UrlImage', "");
                                                        var reader = new FileReader();
                                                        reader.readAsDataURL(file);
                                                        var reader = new FileReader();
                                                        reader.onload = function (e) {
                                                            let previewControl = document.getElementById("imgPreview");
                                                            if (previewControl) {
                                                                document.getElementById("imgPreview").setAttribute('src', e.target.result);
                                                            }
                                                        }
                                                        reader.readAsDataURL(file);

                                                    } else {
                                                        setFieldValue('UrlImage', "")
                                                        setFieldValue('Image', null)

                                                    }
                                                }}
                                            />
                                            <span className="file-cta">
                                                <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo .PNG</i></span>
                                            </span>
                                            <span className="file-name">{values.Image == null ? "Archivo" : values.Image.name}</span>
                                        </label>
                                    </div>
                                    {values.Image !== null &&
                                        <Field>
                                            <button className="button tableAction is-danger trash-right"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    e.target.value = null;
                                                    setFieldValue('Image', null)
                                                    setFieldValue('UrlImage', "")
                                                }
                                                }>
                                                <span className="icon">
                                                    <i className="fa fa-trash" />
                                                </span>
                                            </button>

                                        </Field>
                                    }
                                </Field>
                            </div>
                            <div className="column is-8"></div>
                            <div className="column is-4">
                            {values.Image !== null
                                && <Field>
                                    <div className="column">
                                        <figure className="image container-image " >
                                            <img id={"imgPreview"}
                                                src="#"
                                                style={{ width: 'auto', height: '50px' }}
                                                alt="" />
                                        </figure>
                                    </div>
                                </Field>

                            }
                            {values.UrlImage && <Field>
                                <div className="column">
                                    <figure className="image container-image ">
                                        <img src={values.UrlImage.toString() + "?" + new Date().getTime()}
                                            style={{ width: 'auto', height: '50px' }}
                                            alt="" />
                                    </figure>
                                </div>
                            </Field>}

                            </div>
                            



                        </div>

                        <br />
                        <div className="has-text-left buttons-modal" id="stepActions">
                            <div className="pull-left">
                                <button id="btnBackImageRecipeForm" type="button" className="button is-light" onClick={() => this.props.back()} >Cancelar</button>

                            </div>
                        </div>
                        <div className="has-text-right buttons-modal" id="stepActions">
                            <div className="pull-right">
                                <button id="btnSaveImageRecipeForm" type="submit" className="button is-success"  >Guardar</button>
                            </div>
                        </div>

                    </form>
                )}

            </Formik>

        )
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
RecipeImageForm.defaultProps = {

    recipe: {
        ShortName: "",
        Description: "",
        ClassificationId: null,
        PresentationId: null,
        FlavorTypeId: null,
        RecipeTypeId: null,
        MilkTypeId: null,
        GrainTypeId: null,
        IVAApply: true,
        KiwiId: 0,
        Active: true,
        MobileApply: true,
        WelcomeGiftApply: true,
        ClassificationSatId: 0,
        CodeSatId: 0,
        Details: [],
        GiftWelcomeActive: true,
        Image: null,
        UrlImage: ""
    }
}

export default RecipeImageForm;