import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import HoverCard from '../../util/HoverCard/HoverCard';
import HasAccess from '../util/hasAccess/HasAccess';

class Home extends Component {
    constructor(props){
        super(props)
        this.state={
            nombre:undefined,
            rol:undefined
        }
    }
    render() {
        return (
            <div>
                <HasAccess roles={['Administrador']}>
                    <div className="columns">
                        <div className="column">
                            <Link to={'/unidades'} onClick={
                                // Establecer parametros para el modod guiado
                                //
                                function() {
                                    localStorage.setItem("modoGuiado", true)
                                    localStorage.setItem("tipoModoGuiado", "articulo")
                                }
                            }>
                                <HoverCard
                                    titulo={
                                        <div>
                                            <i className="fa fa-list-ol fa-2x" aria-hidden="true"></i><br/>
                                            Crear Artículo
                                        </div>
                                    }
                                    descripcion="Comienza el modo guiado para dar de alta nuevos artículos."
                                />
                            </Link>
                        </div>
                        <div className="column">
                            <Link to={'/clasificaciones'} onClick={
                                // Establecer parametros para el modo guiado
                                //
                                function() {
                                    localStorage.setItem("modoGuiado", true)
                                    localStorage.setItem("tipoModoGuiado", "receta")
                                }
                            }>
                                <HoverCard
                                    titulo={
                                        <div>
                                            {/*<Icon type="user" style={{fontSize: "6rem"}}/> <br/>*/}
                                            <i className="fa fa-coffee fa-2x" aria-hidden="true"></i><br/>
                                            Crear Receta 
                                        </div>
                                    }
                                    descripcion="Comienza el modo guiado para dar de alta nuevas recetas."
                                />
                            </Link>
                        </div>
                    </div>
                </HasAccess>
            </div>
        );
    }
}

export default Home;