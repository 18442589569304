import React, { Component } from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip'
import Field from '../../util/field/Field';
/**
 * Componente para pintar el detalle de una promoción de tipo reembolso por recarga 
 * @param {*} props 
 * @returns componente
 */
export const PromoDetailReembolsoRecarga = (props) => {

    return (
        <>
            <div className='row'>
                <div className='columns'>
                    
                    <div className='column is-6'>
                        <label className='label'><div>Recursividad
                                                <i data-tip data-for="isRecursiveTip" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                                            </div></label>
                        <div className='control' >
                            <Select
                                id="isRecursiveSelected"
                                name="isRecursiveSelected"
                                value={props.values.isRecursiveSelected}
                                placeholder="Seleccionar..."
                                noOptionsMessage={() => "No hay opciones"}
                                options={props.Recursividad}
                                onChange={(val) => {
                                    props.setFieldValue('isRecursive', val.value)
                                    props.setFieldValue('isRecursiveSelected', val)
                                }}
                                onBlur={() => props.setFieldTouched('isRecursiveSelected', true)}
                            />
                            <div className="is-centered">
                                {(props.errors.isRecursiveSelected) &&
                                    <div style={{ 'fontSize': '12px', 'color': '#cc0b19' }}>
                                        Campo Recursividad es requerido
                                    </div>
                                }
                            </div>
                        </div>

                        <ReactTooltip id="isRecursiveTip" place="top" effect="solid">
                            <strong style={{ color: 'white' }}>Multiplos -</strong> El reembolso es acumulable de acuerdo a la cantidad recargada <br />
                            <strong style={{ color: 'white' }}>Mayor igual a -</strong> El reembolso se aplica una sola vez
                        </ReactTooltip>
                    </div>

                    <div className='column is-6'>
                        <label className='label'><div>Cantidad a recargar
                                                <i data-tip data-for="quantityToRechargeI" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                                            </div></label>
                        <Field
                            alingLefth={true}
                            hasError={props.errors.quantityToRecharge || props.touched.quantityToRecharge}
                            msgError={props.errors.quantityToRecharge ? props.errors.quantityToRecharge : ""}>
                            <input
                                className="input"
                                type="number"
                                label="quantityToRecharge"
                                name="quantityToRecharge"
                                maxLength="10"
                                min="1"
                                value={props.values.quantityToRecharge}
                                onChange={(e) => props.handleChange(e)}
                                onKeyPress={props.onKeyPressNumber}
                                placeholder={"Monto de recarga"}
                            />
                        </Field>
                        <ReactTooltip id="quantityToRechargeI" place="top" effect="solid"> Monto de la recarga </ReactTooltip> 
                    </div>
                    
                </div>
            </div>
            <div className='row'>
                <div className='columns'>
                    <div className='column is-6'>
                        <label className='label'><div>Tipo de cantidad a reembolsar
                                                <i data-tip data-for="IsPercentageTip" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                                            </div></label>
                        <div className='control'>
                            <Select
                                id="isPercentageSelected"
                                name="isPercentageSelected"
                                value={props.values.isPercentageSelected}
                                placeholder="Seleccionar..."
                                noOptionsMessage={() => "No hay opciones"}
                                options={props.optionsTipoCantidad}
                                onChange={(val) => {
                                    props.setFieldValue('isPercentage', val.value)
                                    props.setFieldValue('isPercentageSelected', val)
                                }}
                                onBlur={() => props.setFieldTouched('isPercentageSelected', true)}
                            />
                        </div>
                        <div className="is-centered">
                            {(props.errors.isPercentageSelected) &&
                                <div style={{ 'fontSize': '12px', 'color': '#cc0b19' }}>
                                    Campo tipo de cantidad a reembolsar es requerido
                                </div>
                            }
                        </div>
                        <ReactTooltip id="IsPercentageTip" place="top" effect="solid">
                           ¿Es porcentaje?
                        </ReactTooltip>
                    </div>
                    <div className='column is-6'>
                        <label className='label'>
                            <div>
                                {`${ props.values.isPercentageSelected && props.values.isPercentageSelected.value  ? 'Porcentaje a reembolsar' : 'Cantidad a reembolsar'}`}
                                <i data-tip data-for="QuantityTip" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                            </div>
                            
                        </label>
                        <Field
                            alingLefth={true}
                            hasError={props.errors.quantity || props.touched.quantity}
                            msgError={props.errors.quantity ? props.errors.quantity : ""}>
                            <input
                                className="input"
                                type="number"
                                label="quantity"
                                name="quantity"
                                maxLength="10"
                                min="1"
                                value={props.values.quantity}
                                onChange={(e) => props.handleChange(e)}
                                onKeyPress={props.onKeyPressNumber}

                            />
                        </Field>
                        <ReactTooltip id="QuantityTip" place="top" effect="solid">
                        {`${ props.values.IsPercentage  ? 'Porcentaje de la recarga a reembolsar en monedero' : 'Cantidad de monedero a reembolsar'}`}
                        </ReactTooltip>
                    </div>
                    
                </div>
            </div>
        </>
    )
}