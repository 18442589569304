import React, { useState, useEffect } from 'react';
import NoItems from '../../../../util/noitems/NoItems';
import ServerPagination from '../../../../util/pagination/ServerPagination';
import Loading from '../../../../util/loading/Loading';

export default function TablaPromociones(props) {
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const columnas = ['Cliente', 'Nombre', 'Código','Código en app', 'Redimido', 'Reutilizable', 'Cuenta lealtad', 'Tipo cuenta']

    useEffect(() => {
        if (props.currentPage !== currentPage || props.totalPages !== totalPages) {
            setCurrentPage(props.currentPage);
            setTotalPages(props.totalPages);
        }

        if (props.loading !== loading) setLoading(props.loading);
    }, [props.totalPages, props.currentPage])

    return (
        <div className="card">
            <div className="card-content">
                {props.codesPromotion.length > 0 ?
                    <div className="table-container">
                        <table className="table is-fullwidth">
                            <thead>
                                {columnas.map(c => <th key={c}>{c}</th>)}
                            </thead>
                            <tbody>
                                {props.codesPromotion.map((row, index) => {
                                    return (
                                        <tr className={`${row.PathFile && `trClickable`}`} key={`code${index}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (row.PathFile) {
                                                    var popupQR = window.open(`${row.PathFile}`, `QR ${row.PromoQRDetailCodeId}`, "width=300, height=300");
                                                    
                                                }
                                            }
                                            }
                                        >
                                            <td>{row.IdClient ? row.IdClient : ''}</td>
                                            <td>{row.Name ? row.Name + ' ' + row.LastName : ''}</td>
                                            <td>{row.Code ? row.Code : ''}</td>
                                            <td>{row.CodeCampaignApp ? row.CodeCampaignApp : ''}</td>
                                            <td>{row.Redeem ? 'Si' : 'No'}</td>
                                            <td>{!row.Expires ? 'Si' : 'No'}</td>
                                            <td>{row.LoyaltyAccount ? row.LoyaltyAccount : ''}</td>
                                            <td>{row.LoyaltyAccountType ? row.LoyaltyAccountType : ''}</td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table></div>
                    : props.loading ? <Loading isLoading={props.loading} width='100px' height='100px' /> : <NoItems itemName="promociones" />}
                <ServerPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    goToPage={props.goToPage}
                />
            </div>
        </div>
    )
}