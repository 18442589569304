import React from 'react';
import Select from 'react-select';

export default function TipoQRDataForm({typesQR, inputs, errors, handleSelectChange}){
    return(
        <div className="columns is-multiline">
            <div className="column is-6">
                <label className="label">Tipo de QR</label>
                <div className={`control`}>
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"QRType"}
                        value={inputs.QRType}
                        isClearable
                        onChange={(value, event) => handleSelectChange(value, event, true)}
                        noOptionsMessage={() => "No hay opciones"}
                        options={typesQR.map(Qr => {return {value: Qr.QRTypeId, label: Qr.Name}})}
                    />
                </div>
                {errors.QRType && <p className="help title has-text-left is-6 is-danger">Tipo de QR requerido</p>}
            </div>
        </div>
    );
}