import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
});

var requestfile = axios.create({
    responseType: 'blob',
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class PromocionAPI{
    static getPromoQR(name = "", page = 1, active = 1){
        return middleware.hasAuth(() => request.get(`/PromoQR?name=${name}&page=${page}&active=${active}`));
    }

    static getPromotions(){
        return middleware.hasAuth(() => request.get(`/PromoQR/Promotions`));
    }

    static getPromoType(){
        return middleware.hasAuth(() => request.get(`/PromoQR/PromoType`));
    }

    static getQRType(){
        return middleware.hasAuth(() => request.get(`/PromoQR/QRType`));
    }

    static getPromoTypeSegmentation(){
        return middleware.hasAuth(() => request.get(`/PromoQR/PromoTypeSegmentation`));
    }

    static getSegmentations(){
        return middleware.hasAuth(() => request.get(`/PromoQR/Segmentations`));
    }

    static getPromotionDetails(idPromotion){
        return middleware.hasAuth(() => request.get(`/PromoQR/${idPromotion}`));
    }

    static getCodes(idPromotion, page = 1){
        return middleware.hasAuth(() => request.get(`/PromoQR/Codes/${idPromotion}?page=${page}`));
    }

    static savePromotion(body){
        return middleware.hasAuth(() => request.post(`/PromoQR/`,body));
    }

    static validateExcel(file){
        var fd = new FormData();
        fd.append("Excel", file);
        return middleware.hasAuth(() => request.post(`/Segmentacion/ValidateExcel`, fd));
    }

    static templateExcel(){
        return middleware.hasAuth(() => requestfile.get(`/PromoQR/TemplateExcel`));
    }

    static downloadQrXlsx(idPromotion){
        return middleware.hasAuth(() => requestfile.get(`/PromoQR/${idPromotion}/download/excel`));
    }

    static downloadQrZip(idPromotion){
        return middleware.hasAuth(() => requestfile.get(`/PromoQR/${idPromotion}/download/zip`));
    }

    static uploadImage(file){
        var fd = new FormData();
        fd.append("Image", file);
        return middleware.hasAuth(() => request.post(`/PromoQR/Upload/Image`, fd));
    }

    static deletePromotion(idPromotion){
        return middleware.hasAuth(() => request.delete(`/PromoQR/${idPromotion}`));
    }

    static savePrefix(body){
        return middleware.hasAuth(() => request.post(`/PromoQR/Prefix`,body));
    }
    
}