import React from 'react';
import Select from 'react-select';

export default function TipoPromocionDataForm({typesPromotion, inputs, errors, handleSelectChange}){
    return(
        <div className="columns is-multiline">
            <div className="column is-6">
                <label className="label">Tipo de promoción</label>
                <div className={`control`}>
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"typePromotion"}
                        value={inputs.typePromotion}
                        isClearable
                        onChange={(value, event) => handleSelectChange(value, event, true)}
                        noOptionsMessage={() => "No hay opciones"}
                        options={typesPromotion.map(p => {return {value: p.PromoTypeId, label: p.Description}})}
                    />
                </div>
                {errors.typePromotion && <p className="help title has-text-left is-6 is-danger">Tipo de promoción requerida</p>}
            </div>
        </div>
    );
}