import React from 'react';
import './Menu.css';
import MenuInterior from '../menuInterior/MenuInterior';

/**
 * Componente que forma la barra lateral izquierda del sistema, donde está situado el menú
 */
export default class Menu extends React.Component{
    render(){
        return(
            <aside className="column is-2 aside hero is-fullheight is-hidden-mobile menu">
                <MenuInterior />
            </aside>
        )
    }
}