import React from 'react'
import Select from 'react-select';
import PagosBancosAPI from '../../services/PagosBancos';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Loading from '../util/loading/Loading';
import ErrorMessages from '../../util/validation-error-messages';
import DatePicker from "react-datepicker/dist/es"
import { format } from 'date-fns';
import Pagination from '../util/pagination/Pagination';
import NoItems from '../util/noitems/NoItems';
import ReactExport from "react-export-excel";

// Creamos instancias para el package export excel.
// Variable que indica el archivo de excel que se crea.
const ExcelFile = ReactExport.ExcelFile;
// Variable que indica una hoja de excel.
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// Variable que indica una columna de excel.
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const formatoPrecio = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

const listaTipoPago = [
    {'idTipoPago': 1, 'nombre': 'Pago'},
    {'idTipoPago': 2, 'nombre': 'Comisión'},
    //{'idTipoPago': 3, 'nombre': 'Comisión Proveedor'}
]

const listaEstatusPago = [
    { 'idEstatus': 1, 'nombre': 'Sin folio' }
]

export const PagosBancos = () => {

    const [state, setState] = useState({
        //Lista datos
        listaPagosNetPay: [],
        paginadoPagosNetPay: [],
        //Filtros
        listaMunicipioFiltro: [],
        municipioFiltro: [],
        listaDriveFiltro: [],
        driveFiltro: [],
        tipoPagoFiltro: null,
        tipoEstatusFiltro: null,
        fecha: null,
        //Etc
        isLoading: true
    })

    useEffect(() => {
        window.scrollTo(0, 0);
        let listaMunicipioFiltroAux = []
        let listaDriveFiltroAux = []

        PagosBancosAPI.GetSucursales()
            .then(res => {
                listaMunicipioFiltroAux = res.data.filter((value, index, array) =>
                    array.findIndex(value2 => (value2.idMunicipio === value.idMunicipio)) === index)
                listaDriveFiltroAux = res.data.filter((value, index, array) =>
                    array.findIndex(value2 => (value2.idCaffenio === value.idCaffenio)) === index)
                return PagosBancosAPI.GetPagosNetPay()
            })
            .then(res => {
                setState({
                    ...state,
                    listaPagosNetPay: res.data,
                    paginadoPagosNetPay: res.data,
                    listaMunicipioFiltro: listaMunicipioFiltroAux,
                    listaDriveFiltro: listaDriveFiltroAux,
                    isLoading: false
                })
            })
            .catch(err => {
                setState({ ...state, isLoading: false })
                if (err.response) {
                    toast.error(err.response.data.Message)
                } else {
                    toast.error(ErrorMessages.SinConexion)
                }
            })
    }, [])

    const limpiarFiltros = () => {
        let stateAux = { ...state }
        stateAux.municipioFiltro = []
        stateAux.driveFiltro = []
        stateAux.fecha = null
        stateAux.tipoPagoFiltro = null
        stateAux.tipoEstatusFiltro = null
        PagosBancosAPI.GetPagosNetPay()
            .then(res => {
                stateAux.listaPagosNetPay = res.data
                stateAux.paginadoPagosNetPay = res.data
                setState(stateAux)
            })
    }

    const handleFiltros = () => {
        if(state.fecha === null || state.fecha === undefined){
            toast.error('Debe seleccionar una fecha')
            return
        }

        let fechaConvertida = null
        let listaMunicipios = []
        let listaDrives = []
        let tipoPago = null
        let tipoEstatus = null

        if (state.fecha !== null && state.fecha !== undefined) {
            fechaConvertida = state.fecha.toISOString()
        }
        if (state.municipioFiltro.length !== 0) {
            listaMunicipios = state.municipioFiltro.map(x => x.idMunicipio)
        }
        if (state.driveFiltro.length !== 0) {
            listaDrives = state.driveFiltro.map(x => x.idCaffenio)
        }
        if(state.tipoPagoFiltro != null){
            tipoPago = state.tipoPagoFiltro.idTipoPago
        }
        if(state.tipoEstatusFiltro != null){
            tipoEstatus = state.tipoEstatusFiltro.idEstatus
        }

        let filtro = {
            fecha: fechaConvertida,
            listaMunicipios: listaMunicipios,
            listaDrives: listaDrives,
            tipoPago: tipoPago,
            tipoEstatus: tipoEstatus
        }

        setState({ ...state, isLoading: true })
        PagosBancosAPI.GetFilterPagoNetPay(filtro)
            .then(res => {
                setState({
                    ...state,
                    listaPagosNetPay: res.data,
                    paginadoPagosNetPay: res.data,
                    isLoading: false
                })
            })
            .catch(err => {
                toast.error('Error al consultar los pagos filtrados')
                setState({ ...state, isLoading: false })
            })
    }

    const reintentarPago = (pago) => {
        if (pago.MontoTotal === 0) {
            toast.error('No es posible mandar un pago con monto en ceros')
            return
        }
        setState({ ...state, isLoading: true })
        let paginadoPagosNetPayAux = [...state.paginadoPagosNetPay]
        PagosBancosAPI.ReintentarFolioPago(pago)
            .then(res => {
                if (res.data !== null) {
                    pago.Folio = res.data
                    paginadoPagosNetPayAux[paginadoPagosNetPayAux.findIndex(x =>
                        x.IdPagoNetPayGP === pago.IdPagoNetPayGP)] = pago
                    toast.success('Folio generado y asignado correctamente')
                } else {
                    toast.error('Error al generar folio del pago')
                }
                setState({
                    ...state,
                    paginadoPagosNetPay: paginadoPagosNetPayAux,
                    isLoading: false
                })
            })
            .catch(err => {
                if (err.response) {
                    toast.error(err.response.data.Message)
                } else {
                    toast.error('Error al generar folio del pago')
                }
                setState({ ...state, isLoading: false })
            })
    }

    const conversionTipoPago = (tipoPago) => {
        switch (tipoPago) {
            case 1:
                return "Pago"
            case 2:
                return "Comisión"
            default:
                return "N/A"
        }
    }

    return (
        <div>
            <h1 className='title is-2'>Pagos de Bancos</h1>

            <div className='card'>
                <div className='card-content'>
                    <div className="row">
                        <div className='columns is-multiline'>

                            <div className='column is-4'>
                                <label className='label'>Sucursal</label>
                                <div className='control'>
                                    <Select
                                        isMulti
                                        isClearable
                                        value={state.municipioFiltro}
                                        placeholder={"Seleccionar..."}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={value => setState({ ...state, municipioFiltro: value })}
                                        options={state.listaMunicipioFiltro}
                                        getOptionLabel={(municipio) => municipio.Municipio}
                                        getOptionValue={(municipio) => municipio.idMunicipio}
                                    />
                                </div>
                            </div>

                            <div className='column is-4'>
                                <label className='label'>Drive</label>
                                <div className='control'>
                                    <Select
                                        isMulti
                                        isClearable
                                        value={state.driveFiltro}
                                        placeholder={"Seleccionar..."}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={value => setState({ ...state, driveFiltro: value })}
                                        options={state.listaDriveFiltro}
                                        getOptionLabel={(drive) => drive.Drive}
                                        getOptionValue={(drive) => drive.idCaffenio}
                                    />
                                </div>
                            </div>

                            <div className="column is-4">
                                <span className="tooltip" data-tooltip="Seleccionar fecha del pago">
                                    <label className='label'>Fecha</label>
                                    <DatePicker
                                        isClearable
                                        locale="es"
                                        name={"FechaPago"}
                                        placeholderText="dd/MM/yyyy"
                                        selected={state.fecha}
                                        onChange={fecha => setState({ ...state, fecha: fecha })}
                                        dateFormat="dd/MM/yyyy"
                                        className="input"
                                        todayButton="Hoy"
                                    />
                                </span>
                            </div>

                            <div className="column is-4">
                                <label className='label'>Tipo</label>
                                <Select
                                    isClearable
                                    value={state.tipoPagoFiltro}
                                    placeholder={"Seleccionar..."}
                                    noOptionsMessage={() => "No hay opciones"}
                                    onChange={value => setState({ ...state, tipoPagoFiltro: value })}
                                    options={listaTipoPago}
                                    getOptionLabel={(tipoPago) => tipoPago.nombre}
                                    getOptionValue={(tipoPago) => tipoPago.idTipoPago}
                                />
                            </div>

                            <div className="column is-4">
                                <label className='label'>Estatus</label>
                                <Select
                                    isClearable
                                    value={state.tipoEstatusFiltro}
                                    placeholder={"Seleccionar..."}
                                    noOptionsMessage={() => "No hay opciones"}
                                    onChange={value => setState({ ...state, tipoEstatusFiltro: value })}
                                    options={listaEstatusPago}
                                    getOptionLabel={(estatus) => estatus.nombre}
                                    getOptionValue={(estatus) => estatus.idEstatus}
                                />
                            </div>

                            <div className="column is-4"></div>

                            <div className="has-text-middle buttons-modal column">
                                <button
                                    className="button is-success"
                                    onClick={limpiarFiltros}
                                    style={{ marginRight: "5px" }}>
                                    <span className="icon is-small">
                                        <i className="fa fa-times"></i>
                                    </span>
                                    <span>Limpiar filtros</span>
                                </button>
                                <button
                                    className="button is-success"
                                    onClick={handleFiltros}
                                >
                                    <span className="icon is-small">
                                        <i className="fa fa-search"></i>
                                    </span>
                                    <span>Buscar</span>
                                </button>

                                {state.listaPagosNetPay.length > 0 &&
                                    <div id="buttonExportPDFAndExcel" className="column is-12">
                                        <ExcelFile
                                            filename={"DepositosNetPay_" + format(Date.parse(state.listaPagosNetPay[0].FechaRegistro), "dd/MM/yyyy")}
                                            element={
                                                <button className="button is-success">
                                                    <span className="icon">
                                                        <i className="fa fa-file" />
                                                    </span>
                                                    <span>Exportar a EXCEL</span>
                                                </button>
                                            }
                                        >
                                            <ExcelSheet data={state.listaPagosNetPay} name="PagosNetPay" >
                                                <ExcelColumn label="Afiliación" value={(pago) => pago.NumTerminal} />
                                                <ExcelColumn label="Sucursal" value={(pago) => pago.NombreCaffenio} />
                                                <ExcelColumn label="Monto" value={(pago) => pago.MontoTotal} />
                                                <ExcelColumn label="Fecha" value={(pago) => format(Date.parse(pago.FechaRegistro), "dd/MM/yyyy")} />
                                                <ExcelColumn label="Tipo" value={(pago) => conversionTipoPago(pago.TipoPago)} />
                                                <ExcelColumn label="Folio" value={(pago) => pago.Folio || pago.MensajeError} />
                                                <ExcelColumn label="Batch" value={(pago) => pago.Batch} />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </div>
                                }
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className='card'>
                <div className='card-content'>
                    <div>
                        {state.listaPagosNetPay.length !== 0 ?
                            <table className='table is-fullwidth'>
                                <thead>
                                    <tr>
                                        <th>Afiliación</th>
                                        <th>Sucursal</th>
                                        <th>Monto</th>
                                        <th>Fecha</th>
                                        <th>Tipo</th>
                                        <th>Folio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.paginadoPagosNetPay.map(pago =>
                                            <tr key={pago.IdPagoNetPayGP}>
                                                <td>{pago.NumTerminal}</td>
                                                <td>{pago.NombreCaffenio}</td>
                                                <td>{formatoPrecio.format(pago.MontoTotal)}</td>
                                                <td>{format(Date.parse(pago.FechaRegistro), "dd/MM/yyyy")}</td>
                                                <td>{conversionTipoPago(pago.TipoPago)}</td>
                                                <td>
                                                    {pago.Folio ?
                                                        pago.Folio
                                                        :
                                                        pago.MontoTotal !== 0 ?
                                                            <button
                                                                data-tooltip="Volver a generar folio"
                                                                className="button is-warning tooltip"
                                                                onClick={() => reintentarPago(pago)}
                                                                style={{ marginRight: "5px" }}>
                                                                <span className="icon is-small">
                                                                    <i className="fa fa-refresh"></i>
                                                                </span>
                                                            </button>
                                                            :
                                                            <></>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table> : <NoItems itemName="pagos" />
                        }
                    </div>
                    <Pagination
                        pageSize={20}
                        items={state.listaPagosNetPay}
                        onChangePage={(listaPagosNetPay) => setState({ ...state, paginadoPagosNetPay: listaPagosNetPay })}
                    />
                </div>
            </div>


            <Loading isFullscreen={true} isLoading={state.isLoading} width='100px' height='100px' />
        </div>
    )
}