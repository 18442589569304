import React, { useEffect, useState } from 'react';

// Components
import Modal from '../util/modal/Modal';
import Loader from '../util/loading/Loading';
import Field from '../util/field/Field';
import Select from 'react-select';

// Utils
import { toast } from 'react-toastify';

// Services
import PlazasAPI from '../../services/Plazas';
import SucursalesAPI from '../../services/Sucursales';

/**
 * Componente modal para editar o crear plazas.
 * @param { Function } onClose - Funcion para cerrar el modal 
 * @returns 
 */
export const ModalPlaza = ({ plaza, onClose, onUpdate, onInsert, sucursales, titleModal }) => {

    const [ state, setState ] = useState({
        nombre: '',
        estatus: null,
        isLoading: false,
        sucursal: null
    });

    useEffect(() => {
        if ( plaza !== undefined && JSON.stringify(plaza) !== '{}' ) {
            setState({
                ...state,
                nombre: plaza.Nombre,
                estatus: plaza.Estatus,
                sucursal: sucursales.find(suc => suc.value ===  plaza.Sucursal)
            });
        } else {
            setState({ ...state, estatus: true });
        }
    }, [ plaza ]);

    /**
     * Guarda la información de la sucursal.
     */
    const handleSaveInfo = () => {
        if ( state.nombre === '' || state.sucursal === null || state.estatus === null)
            return toast.warn('Se deben llenar todos los campos.');

        const dataToSave = {
            Nombre: state.nombre.trim(),
            Estatus: state.estatus,
            Sucursal: state.sucursal ? state.sucursal.value : null
        };

        setState({ ...state, isLoading: true });
        if ( plaza.idPlaza !== undefined ) {
            PlazasAPI.updatePlaza({ ...plaza, ...dataToSave })
                .then( resp => onUpdate( resp.data ) )
                .catch( err => {
                    let message = 'Ocurrio un error';
                    try {
                        message = err.response.data.Message;
                    } catch (error) {
                        message = 'No se pudo actualizar la plaza';
                    } finally {
                        toast.error(message);
                        setState({ ...state, isLoading: false });
                    }
                });
        } else {
            PlazasAPI.createPlaza({ ...dataToSave })
                .then( resp => onInsert() )
                .catch( err => {
                    let message = 'Ocurrio un error';
                    try {
                        message = err.response.data.Message;
                    } catch (error) {
                        message = 'No se pudo crear la plaza';
                    } finally {
                        toast.error(message);
                        setState({ ...state, isLoading: false });
                    }
                });
        }
    }

    /**
     * Maneja el cambio de la sucursal.
     * @param {Object} suc - Sucursal seleccionada
     */
    const handleChangeSucursal = suc => {
        setState({
            ...state,
            sucursal: suc
        });
    }

    return (
        <Modal
            showModal={true}
            modalTitle={ titleModal }
            cancel={() => onClose()}
        >
            { state.isLoading &&
                <Loader
                    isFullscreen={true}
                    isLoading={state.isLoading}
                    width='100px'
                    height='100px'
                />
            }
            <br />
            <div className='row'>
                <div className='columns'>
                    <div className='column is-12'>
                        <label className='label' for='nombre'>Nombre de la plaza</label>
                        <input
                            className='input'
                            type='text'
                            maxLength='50'
                            value={state.nombre}
                            placeholder='Nombre...'
                            name='nombre'
                            onChange={({ target }) => setState({ ...state, nombre: target.value })}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='column is-12'>
                    <label className='label'>Sucursal</label>
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"selectedOption"}
                        value={state.sucursal}
                        onChange={item => handleChangeSucursal(item)}
                        noOptionsMessage={() => "No hay opciones"}
                        options={sucursales}
                    />
                </div>
                <div className='column is-6'></div>
            </div>
            <div className='row'>
                {
                   titleModal !== 'Agregar Plaza' && 
                    <div className='columns'>
                        <div className='column is-12'>
                            <Field label='Estatus' isHorizontal={true}>
                                <input
                                    className='switch'
                                    type='checkbox'
                                    checked={state.estatus}
                                    name='estatus'
                                    onChange={() => {}}
                                    disabled={ titleModal === 'Agregar Plaza' ? true : false }
                                />
                                <label for='estatus' onClick={() => setState({...state, estatus: !state.estatus})} />
                            </Field>
                        </div>
                    </div>
                }
            </div>
            <div className='row'>
                <div className='columns'>
                    <div className='column is-12 is-flex' style={{justifyContent: 'flex-end'}}>
                        <button onClick={handleSaveInfo} className='button is-success'>
                            Guardar Plaza
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}