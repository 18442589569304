import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import UnitForm from './UnitForm';
import UnitsAPI from '../../../services/Units';
import '../../cssComponents.css';
import ErrorMessages from '../../../util/validation-error-messages';
import Loading from '../../util/loading/Loading';
import Modal from '../../util/modal/Modal';

class NewUnit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unit: { ...this.props.unit },
            units: [],
            isLoading: false
        };
    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push(`/unidades`);
            return;
        }
        this.setState({ units: this.props.location.state.units })
    }

    save = (unit) => {

        this.setState({ isLoading: true })

        UnitsAPI.create(unit).then(response => {
            this.setState({ isLoading: false })
            toast.success("Unidad creada");
            this.props.history.goBack();
        }).catch(err => {
            this.setState({ isLoading: false })
            if (err.response) {
                console.log(err.response.data.Message)
                toast.error(ErrorMessages.ErrorInesperado)
            } else {
                toast.error(ErrorMessages.SinConexion);
            }
        })
    }



    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{ position: "absolute", left: 25 }} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{ cursor: "pointer", color: "#91BA43" }} onClick={() => { this.props.history.goBack() }} className="fa fa-arrow-left"></i>
                            </span>
                            <div >Nueva Unidad
                                <i className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }} onClick={() => this.setState({ showModal: true })} className="fa fa-question-circle"></i>
                            </div>
                        </div>
                    </div>
                    <div className="card-content">
                        <UnitForm
                            save={this.save}
                            units={this.state.units}
                        />
                    </div>
                </div>
                {this.state.showModal &&
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Información de Unidad"}
                        cancel={() => this.setState({ showModal: false })}
                    >
                        <div className="has-text-justified">
                        
                            Las unidades de medida están directamente relacionadas con artículos, ya que se especifica para cada
                            artículo inventario su: unidad de pedido a sucursal, unidad de almacenamiento externo, y
                            su unidad de medida para la venta.

                            La unidad de medida solo se podrá eliminar en caso de no estar vinculada con ningún artículo.

                            <hr/>
                            <p className="has-text-justified">
                                <strong>Nombre</strong>: es un campo requerido, 
                                debe tener una longitud mínima de 2 a 20 caracteres máximo
                                 y no se debe duplicar                        
                            </p>
                            <br/>
                            <p className="has-text-justified">
                                <strong>Nombre corto</strong>: es un campo requerido, 
                                debe tener una longitud mínima de 2 a 10 caracteres máximo 
                                y no se debe duplicar.
                            </p>
                            <br/>
                            <p className="has-text-justified">
                                <strong>Código SAT</strong>: es el código de facturación y es un campo requerido.
                            </p>
                        </div>
                       

                    </Modal>
                }
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
            </div>
        );
    }
}

// Definición de los tipos y su dependencia de las distintas props que recibe el componente.
NewUnit.propTypes = {
    unit: PropTypes.object,
    isEditing: PropTypes.bool
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
NewUnit.defaultProps = {
    isEditing: false,
    unit: {
        ShortName: "",
        Name: "",
        SatCode: ""
    }
}

export default NewUnit;
