import React, { useState } from 'react';
import Modal from '../../../../../util/modal/Modal';
import NotificacionAPI from '../../../../../../services/Caffenio App/Notificacion';
import { toast } from 'react-toastify';

export default function PruebaNotificacion({notification, probando, setProbando}){
    const [identifier, setIdentifier] = useState("");
    const [formErr, setFormErr] = useState(false);
    const [working, setWorking] = useState(false);

    const sendNotification = () => {
        if(!working){
            setWorking(true);
        
            NotificacionAPI.SendTestNotification(notification.NotificationId, identifier).then(response => {
                toast.success(response.data.Message);
                setProbando(false);
            }).catch(err => {
                toast.error(err.response.data.Message);
                setProbando(false);
            })
        }
    }

    return (
        <Modal
            footer
            footerButtons={                        
                <div className="has-text-right modal-detalle-buttons">
                    <button className="button" onClick={() => setProbando(false)}>Cancelar</button>
                    <button className={`button is-success ${working ? 'is-loading' : ''}`} disabled={identifier.length < 14} onClick={() => {
                        sendNotification()
                    }}>Enviar</button>
                </div>
            }
            cancel={() => setProbando(false)}
            showModal={probando}
            modalTitle={`Mandar notificación de prueba`}
        >
            <div className="columns">
                <div className="column is-6 has-text-left">
                    <label className="label">Cuenta lealtad</label>
                    <div className={`control`}>
                        <input
                            type="number"
                            maxLength="14"
                            name="identifier"
                            className={`input ${formErr && "is-danger"}`}
                            onBlur={() => setFormErr(identifier.length < 1)}
                            placeholder={`12410000000000`}
                            value={identifier}
                            onChange={(e) => {
                                if(e.currentTarget.value.length <= 14)
                                    setIdentifier(e.currentTarget.value)
                            }}
                            required
                        />
                    </div>
                    {formErr && <p className="help title has-text-left is-6 is-danger">Identificador de cliente requerido.</p>}
                </div>
            </div>
        </Modal>
    )
}