import React, { Component } from 'react';
import Field from '../../util/field/Field';
import ErrorMessages from '../../../util/validation-error-messages';
import ExpresionesRegulares from '../../../util/expresiones-regulares';
import '../../cssComponents.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import lodash from 'lodash';


class PromoCopyForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            promo: { ...this.props.promo },
           
        }
    }

    

    VALIDATION_SCHEMA = Yup.object({
        Name: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(100, "Ha excedido el límite de 100 caracteres.")
            .required("Campo Nombre es requerido.")
            .test("validate-name", "El Nombre ya existe, favor de ingresar otro.", (value) => {
                let isValid = true;
                let name = value ? value.toLowerCase().trim() : "";
                if (this.props.promotions.some(u => u.Name.toLowerCase().trim() === name && u.IdPromocion !== this.state.promo.IdPromocion)) {
                    isValid = false;
                }
                return isValid;
            }),
        
        
    });



    render() {
        return (
            <Formik
                initialValues={this.state.promo}
                validationSchema={this.VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    let formatValues = {...values}
                    formatValues.Name = formatValues.Name.trim();
                    formatValues.ShortName = formatValues.ShortName.substring(0,40);
                    formatValues.Description = formatValues.Name.trim();
                    formatValues.UserId = localStorage.getItem("user");
                    this.props.save(formatValues)
                }}
            >
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }) => (
                    <form onSubmit={handleSubmit} id={"frmPromoCopy"}>
                        <Field
                            label="Promoción basada en: "
                            isHorizontal
                            hasError={errors.Name && touched.Name}
                            msgError={errors.Name && touched.Name ? errors.Name : ""}
                            showLeftSpaces={true}
                            childInfo={values.Name && !errors.Name
                                ? `Te quedan ${100 - values.Name.trim().length} caracteres para el nombre.`
                                : ""}
                        >
                            <input
                                id="txtNamePromo"
                                name={"Name"}
                                className={`input ${errors.Name && touched.Name ? "is-danger" : ""}`}
                                placeholder={"Ingrese nombre de Promoción"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Name}

                            />
                        </Field>
                        <br />                        
                        <div className="has-text-right buttons-modal">
                            <div className="column">
                                <button id={"btnSubmitCopyPromo"} 
                                type="submit" className="button is-success">
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                )}

            </Formik>
        );
    }
}



export default PromoCopyForm;
