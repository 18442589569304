import React, { Component } from 'react';
import ProyeccionesAPI from '../../services/ProductionProyection';
import  TableTemplate  from "../util/tabletemplate/TableTemplate";
import  NoItems  from "../util/noitems/NoItems";
import Pagination from '../util/pagination/Pagination';
import { toast } from 'react-toastify';
import lodash from 'lodash';
import ErrorMessages from '../../util/validation-error-messages';
import Select from 'react-select';
import Loading from '../util/loading/Loading';

class DrivesProyeccion extends Component {
    drives = [];

    constructor(props){
        super(props);
        this.state = {
            drives: [],
            filterText: "",
            drive: null,
            pageDrives: [],
            isLoadingP: false,

            selectedDrives: [...this.props.selectedDrives],
            selectedOptionState: [],
            selectedOptionCity: [],
            optionState: [],
            optionCity: [],
            checkAll: false
        }
    }

    componentDidMount(){
        this.setState({isLoadingP: true})
        let state = {...this.state};
        ProyeccionesAPI.GetActiveCaffenios().then(response => {

            state.drives = response.data
            let auxCount = 0;
            state.drives.findIndex(obj => {
                let isSelected = this.state.selectedDrives.findIndex(selectedDrive => selectedDrive.idCaffenio === obj.idCaffenio)
                if (isSelected != -1) {
                    obj.statusCheck = true;
                    auxCount++;
                }
                return auxCount == this.state.selectedDrives.length;
            })
            this.drives = lodash.cloneDeep(response.data);

            state.optionState = this.select_state(state.drives);
            state.optionCity = this.select_city(state.drives);
            
            console.log("El state drives proyección >> ", state);
            // Guardar el estado
            this.setState(state);
        }).catch(err => {
            this.setState({isLoadingP: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    }

    select_state(Caffenios) { // Llenar el combo 'Estados' con todas las opciones 
        var newArray = [];
        let OptionSelect = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelect.push({value:item.StateId,label:item.State});
        });

        for(var i in OptionSelect) {
            lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        
        return newArray;
    }

    select_city(Caffenios) { // Llenar el combo 'Ciudades' con todas las opciones 
        var newArray = [];
        let OptionSelectCity = [];
        var lookupObject  = {};
        Caffenios.map((item) => {
            OptionSelectCity.push({value:item.Municipalityid,label:item.Municipality});
        });

        for(var i in OptionSelectCity) {
            lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    changeSelectEstado = (obj) => {
        let state = {...this.state}
        state["selectedOptionState"] = obj;
        let idEstado = obj.map(estado => {return estado.value});
        let objCaffenios = [...this.drives];
        let listaCiudades = [];

        // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
        objCaffenios.map(item =>{
            for(var i=0; i<idEstado.length; i++){
                if(item.StateId===idEstado[i]){
                    listaCiudades.push({"value":item.Municipalityid,"label":item.Municipality});
                }
            }
        });

        //Se eliminan las ciudades duplicadas
        let eliminarDuplicados = this.eliminarObjDuplicados(listaCiudades,"value");
        console.log("lista >>", listaCiudades);
        console.log("sin duplicados >>", eliminarDuplicados);
        //Se limpian los combos 'Ciudades' y 'Drives'
        state["selectedOptionCity"] = [];

        //Se valida que haya una opción seleccionada en combo 'Estados'
        if(obj.length===0){
            state["optionCity"] = this.select_city(objCaffenios);
        } else{
            state["optionCity"] = eliminarDuplicados;
        }

        this.setState(state, () => this.handleSearchMultipleFieldInput());
    }

    changeSelectCiudad = (obj) =>{
        let state = {...this.state}
        // let idCiudad = obj.map(ciudad => {return ciudad.value});
        // let objCaffenios = [...state.Caffenios];
        // let listaDrives = [];

        // //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        // objCaffenios.map(item =>{
        //     for(var i=0; i<idCiudad.length; i++){
        //         if(item.Municipalityid===idCiudad[i]){
        //             listaDrives.push({"value":item.idCaffenio,"label":item.NombreNuevo});
        //         }
        //     }
        // })

        //Se valida que haya una opción seleccionada en combo 'Ciudades'
        if(obj.length>0){
            state["selectedOptionCity"] = obj;
            this.setState(state, () => this.handleSearchMultipleFieldInput());
        } else{
            this.setState(state, () => this.handleSearchMultipleFieldInput());
            this.changeSelectEstado(state["selectedOptionState"]);
        }
    }

    eliminarObjDuplicados = (arr,prop) => {
        var nuevoArray = [];
        var lookup  = {};
        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }
        for (i in lookup) {
            nuevoArray.push(lookup[i]);
        }
        return nuevoArray;
    }

    /**
     * Funcion para ordenar lexicograficamente
     * @param objA. primer operando
     * @param objB. segundo operando
     */
    compare = (objA, objB) => {
        // Use toUpperCase() to ignore character casing
        const labelA = objA.label.toUpperCase();
        const labelB = objB.label.toUpperCase();

        let comparison = 0;
        if (labelA > labelB) {
            comparison = 1;
        } else if (labelA < labelB) {
            comparison = -1;
        }
        return comparison;
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let state = this.state;
        state.filterText = text;
        this.setState(state, this.handleSearchMultipleFieldInput);
    }

    /**
     *Realizar el filtrado sobre las recetas para desplegar solo los elementos
     *que cumplen con las caracteristicas que el usuario ingreso.
     * 
     *@param: nada
     *@return: nada
     */
    handleSearchMultipleFieldInput = () => {

        console.log("Estados >> ", this.state.selectedOptionState);
        console.log("Ciudades >> ", this.state.selectedOptionCity);
        let drives = this.drives.filter(
            drive => (
                (this.state.selectedOptionState.length > 0 ? 
                (this.state.selectedOptionState.findIndex( x => x.value == drive.StateId) != -1) : 
                true) &&
                (this.state.selectedOptionCity.length > 0 ? 
                (this.state.selectedOptionCity.findIndex(y => y.value == drive.Municipalityid) != -1 ): 
                true) &&
                (this.state.filterText.trim() !== '' ?
                ( (drive.Name 
                    ? drive.Name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) 
                    : false) 
                    || drive.NombreNuevo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                : true)
            )
        );
        
        let state = {...this.state};
        state.drives = lodash.cloneDeep(drives);
        let auxCount = 0;
        state.drives.findIndex(obj => {
            let isSelected = this.state.selectedDrives.findIndex(selectedDrive => selectedDrive.idCaffenio === obj.idCaffenio)
            if (isSelected != -1) {
                obj.statusCheck = true;
                auxCount++;
            }
            return auxCount == this.state.selectedDrives.length;
        })
        state.checkAll = false;
        this.setState(state);
    }

    /**
     * Funcion que se ejecuta al darle click al checkbox del header
     */
    selectAllCheck = () => {
        let _checkAll = !this.state.checkAll
        let _selectedDrives = [...this.state.selectedDrives]
        let _pageDrives = this.state.pageDrives
        _pageDrives.forEach(drive => {
            let indexDrive = _selectedDrives.findIndex(item => item.idCaffenio === drive.idCaffenio)
            drive.statusCheck = _checkAll
            if(_checkAll){
                if(indexDrive === -1) _selectedDrives.push(drive)
            }else{
                if(indexDrive !== -1) _selectedDrives.splice(indexDrive, 1)
            }
        });

        this.setState({checkAll: _checkAll, selectedDrives: _selectedDrives})
    }

    /**
     * Funcion que se ejecuta al cambiar la pagina del tablero
     */
    changeDrivePage = (drives) => {
        let drivesChecked = 0;
        let checkAll = false;
        drives.forEach(item => {
            if(this.state.selectedDrives.findIndex(id => id.idCaffenio === item.idCaffenio) !== -1){
                drivesChecked++
                item.statusCheck = true;
            }
            else{
                item.statusCheck = false;
            }
        });
        if(drivesChecked === drives.length) checkAll = true;

        this.setState({ pageDrives: drives, checkAll: checkAll });
    }

    /*
    * Funcion que selecciona o des selecciona todas las 
    * recetas de todas las paginas.
    */
    selectAllCheckBox = (e) => {
        e.stopPropagation();
        let state = {...this.state};
        let elementsPage = state.drives
        let selectedDrives = []
        // Le asignamos el valor inverso de checkAll a todas las recetas actuales
        elementsPage.map(element=>{
            if(state.checkAll){
                element.statusCheck = false 
            }else{
                element.statusCheck = true
                selectedDrives.push(element)
            }
            return element
        })
        state.checkAll = !state.checkAll;
        state.drives = elementsPage;
        state.selectedDrives = selectedDrives
        this.setState(state);
    }


    render() {
        return (
            <div>

                {/* Filtro multiple para drives */}
                <div className="card">
                    <div className="card-content">
                        <div className="columns is-multiline">

                            <div className="column is-6">
                                <div className="control">
                                    <input
                                        type="text"
                                        className="input"
                                        placeholder={`Ingrese nombre de drive`}
                                        value={this.state.filterText}
                                        autoFocus={true}
                                        onChange={(e) => { e.stopPropagation(); this.handleSearchInput(e.currentTarget.value);}}
                                    />
                                </div>
                            </div>
                            <div className="column is-6">
                                {/*  */}
                            </div>
                            <div className="column is-4">
                                <label className="label">Estados</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOptionState"}
                                        value={this.state.selectedOptionState}
                                        onChange={this.changeSelectEstado}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.optionState}
                                    />
                                </div>
                            </div>
                            <div className="column is-4">
                                <label className="label">Ciudades</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOptionCity"}
                                        value={this.state.selectedOptionCity}
                                        onChange={this.changeSelectCiudad}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.optionCity}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                
                <div className="card">
                    <div className="card-content">
                        {/* Botones del header de la tabla */}
                        <div className="columns">
                            {/* Todos los resultados */}
                            <div className="column" style={{display: "flex"}}>
                                <a className="button is-link" onClick={this.selectAllCheckBox}>
                                    <span className="icon is-small">
                                        <i className={!this.state.checkAll ? "fa fa-check-square" : "fa fa-square"}></i>
                                    </span>
                                    <span>{!this.state.checkAll ? "Seleccionar todos los resultados" : "Deseleccionar todos los resultados"}</span>
                                </a>
                            </div>

                        </div>
                        {this.state.drives.length !== 0 && this.state.pageDrives.length !== 0
                        ? <TableTemplate
                            columns={["idCaffenio", "NombreNuevo"]}
                            columnsNames={["Id", "Nombre"]}
                            noActions={true}
                            data={this.state.pageDrives}
                            checkbox={true}
                            //Controlar checkboxes 
                            showView={(index) => {
                                let _selectedDrives = [...this.state.selectedDrives]
                                let indexDrive = this.state.selectedDrives.findIndex(id => id.idCaffenio === this.state.pageDrives[index].idCaffenio);
                                if( indexDrive === -1)
                                _selectedDrives.push(this.state.pageDrives[index]);
                                else
                                _selectedDrives.splice(indexDrive, 1)
                                this.setState({selectedDrives: _selectedDrives})
                            }}
                            checkAll={this.state.checkAll}
                            //Controlador del checkbox del header
                            selectAllCheck={this.selectAllCheck}
                            rowClickable={false}
                        /> 
                        : <NoItems itemName="drives"/>}
                        <Pagination items={this.state.drives} onChangePage={(drives) => this.changeDrivePage(drives) } />
                    </div>
                </div>

                <footer className="modal-card-foot is-justify-end">

                         <button
                            className='button is-grey'
                            onClick={() =>  this.props.cancelDrivesModal()}
                        >
                            Cancelar
                        </button>

                        <button 
                            disabled={false}
                            className='button is-success' 
                            onClick={(e) => {
                                this.props.saveDrives(this.state.selectedDrives.map(e => {
                                return {
                                    idCaffenio : e.idCaffenio,
                                    NombreNuevo: e.NombreNuevo,
                                    statusCheck: e.statusCheck
                                }
                            }));}}
                        >
                            Guardar cambios
                        </button>


                        
                    </footer>                  
                
                <Loading isFullscreen={true} isLoading={this.state.isLoadingP} width='100px' height='100px'/>
            </div>
        );
    }
}

export default DrivesProyeccion;
