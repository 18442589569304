import React from 'react';
import ContentLoader from 'react-content-loader';

// Componente para mostrar un cargando con dos barras svg, simulando una titulo/descipción de una tarjeta
export default class FilterLoading extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            !this.props.loading ?
            this.props.children
            : <ContentLoader 
            height={160}
            width={400}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="70" y="34" rx="3" ry="3" width="100%" height="45" />
            <rect x="70" y="0" rx="4" ry="4" width="50%" height="16" /> 
        </ContentLoader>
        )
    }
}