import React from 'react'
import Select from 'react-select';
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker/dist/es"
import NoItems from '../util/noitems/NoItems';
import { format } from 'date-fns';
import ErrorMessages from '../../util/validation-error-messages';
import { toast } from 'react-toastify';
import ReactExport from "react-export-excel";
import WarehouseAPI from '../../services/Warehouse';
import lodash from 'lodash';
import Pagination from '../util/pagination/Pagination';
import CierreDiaAPI from '../../services/CierreDia';
import Loading from '../util/loading/Loading';

// Creamos instancias para el package export excel.
// Variable que indica el archivo de excel que se crea.
const ExcelFile = ReactExport.ExcelFile;
// Variable que indica una hoja de excel.
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// Variable que indica una columna de excel.
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const formatoPrecio = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

export const CierreDia = () => {

    const [state, setState] = useState({
        listaCierresDia: [],
        paginadoCierresDia: [],
        listaSucursales: [],
        listaSegmentos: [],
        listaEstados: [],
        listaCiudades: [],
        FiltroFechaCierreInicio: null,
        FiltroFechaCierreFin: null,
        FiltroSegmento: null,
        FiltroEstado: null,
        FiltroCiudad: null,
        FiltroDrive: null,
        isLoading: false
    })

    useEffect(() => {
        window.scrollTo(0, 0)
        setState({ ...state, isLoading: true })
        let listaCierresDiaAux = []
        let listaSucursalesAux = []
        CierreDiaAPI.FiltrosBusquedaCierreDia()
            .then(res => {
                listaCierresDiaAux = res.data
                return WarehouseAPI.getCaffenios()
            })
            .then(res => {
                listaSucursalesAux = res.data.filter(x => x.Active === true)
                setState({
                    ...state,
                    listaCierresDia: asignarCaffenio(listaCierresDiaAux, listaSucursalesAux),
                    listaSucursales: listaSucursalesAux,
                    listaSegmentos: rellenarSegmentos(listaSucursalesAux),
                    listaEstados: rellenarEstados(listaSucursalesAux),
                    listaCiudades: rellenarCiudades(listaSucursalesAux),
                    isLoading: false
                })
            })
            .catch(err => {
                setState({ ...state, isLoading: false })
                if (err.response) {
                    toast.error(err.response.data.Message)
                } else {
                    toast.error(ErrorMessages.SinConexion)
                }
            })
    }, [])

    const addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const rellenarSegmentos = (Caffenios) => {
        var newArray = [];
        let OptionSelectState = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectState.push({ value: item.idSegmento, label: item.Segmento });
        });

        for (var i in OptionSelectState) {
            lookupObject[OptionSelectState[i]["value"]] = OptionSelectState[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label.toLowerCase()], ['asc']);
    }

    const rellenarEstados = (Caffenios) => {
        var newArray = [];
        let OptionSelectState = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectState.push({ value: item.StateId, label: item.State });
        });

        for (var i in OptionSelectState) {
            lookupObject[OptionSelectState[i]["value"]] = OptionSelectState[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label.toLowerCase()], ['asc']);
    }

    const rellenarCiudades = (Caffenios) => {
        var newArray = [];
        let OptionSelectState = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectState.push({ value: item.Municipalityid, label: item.Municipality });
        });

        for (var i in OptionSelectState) {
            lookupObject[OptionSelectState[i]["value"]] = OptionSelectState[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label.toLowerCase()], ['asc']);
    }

    const asignarCaffenio = (listaCierresDia, listaSucursalesAux) => {
        let listaCierresDiaAux = listaCierresDia.map(cierreDia => {
            cierreDia.Caffenio = listaSucursalesAux.find(sucursal => sucursal.idCaffenio === cierreDia.IdCaffenio)
            return cierreDia
        })
        return listaCierresDiaAux
    }

    const filtrarDatos = () => {
        if (state.FiltroFechaCierreInicio === null || state.FiltroFechaCierreFin === null) {
            toast.error('Se debe establecer fecha de inicio y fecha fin')
            return
        }

        let filtros = {
            FechaCierreInicio: state.FiltroFechaCierreInicio,
            FechaCierreFin: state.FiltroFechaCierreFin
        }
        setState({...state, isLoading: true})
        CierreDiaAPI.FiltrosBusquedaCierreDia(filtros)
            .then(res => {
                let listaCierresDiaFiltrados = []
                //Se obtiene el Caffenio al que pertenece con sus datos de ubicación
                listaCierresDiaFiltrados = asignarCaffenio(res.data, state.listaSucursales)
                //Se eliminan los que tienen una sucursal inactiva
                listaCierresDiaFiltrados = listaCierresDiaFiltrados.filter(cierreDia => cierreDia.Caffenio !== undefined)
                //Se filtra por segmento
                if (state.FiltroSegmento !== null && state.FiltroSegmento.length > 0) {
                    listaCierresDiaFiltrados = listaCierresDiaFiltrados.filter(cierreDia =>
                        state.FiltroSegmento.some(segmento => segmento.value === cierreDia.Caffenio.idSegmento))
                }
                //Se filtra por estado
                if (state.FiltroEstado !== null && state.FiltroEstado.length > 0) {
                    listaCierresDiaFiltrados = listaCierresDiaFiltrados.filter(cierreDia =>
                        state.FiltroEstado.some(estado => estado.value === cierreDia.Caffenio.StateId))
                }
                //Se filtra por ciudad
                if (state.FiltroCiudad !== null && state.FiltroCiudad.length > 0) {
                    listaCierresDiaFiltrados = listaCierresDiaFiltrados.filter(cierreDia =>
                        state.FiltroCiudad.some(ciudad => ciudad.value === cierreDia.Caffenio.Municipalityid))
                }
                //Se filtra por drive
                if (state.FiltroDrive !== null && state.FiltroDrive.length > 0) {
                    listaCierresDiaFiltrados = listaCierresDiaFiltrados.filter(cierreDia =>
                        state.FiltroDrive.some(drive => drive.idCaffenio === cierreDia.Caffenio.idCaffenio))
                }
                setState({
                    ...state,
                    listaCierresDia: listaCierresDiaFiltrados,
                    isLoading: false
                })
            })
            .catch(err => {
                setState({ ...state, isLoading: false })
                if (err.response) {
                    toast.error(err.response.data.Message)
                } else {
                    toast.error(ErrorMessages.SinConexion)
                }
            })

    }

    const limpiarFiltros = () => {
        setState({ ...state, isLoading: true })
        CierreDiaAPI.FiltrosBusquedaCierreDia()
            .then(res => {
                setState({
                    ...state,
                    FiltroFechaCierreInicio: null,
                    FiltroFechaCierreFin: null,
                    FiltroSegmento: null,
                    FiltroEstado: null,
                    FiltroCiudad: null,
                    FiltroDrive: null,
                    listaCierresDia: asignarCaffenio(res.data, state.listaSucursales),
                    isLoading: false
                })
            })
            .catch(err => {
                setState({ ...state, isLoading: false })
                if (err.response) {
                    toast.error(err.response.data.Message)
                } else {
                    toast.error(ErrorMessages.SinConexion)
                }
            })
    }

    const descargarArchivo = (nombreArchivo) => {
        let archivoUno = {
            nombreArchivo: nombreArchivo,
            extension: 'pdf'
        }
        let archivoDos = {
            nombreArchivo: nombreArchivo,
            extension: 'xml'
        }
        CierreDiaAPI.DescargarArchivo(archivoUno)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${archivoUno.nombreArchivo}.${archivoUno.extension}`);
                document.body.appendChild(link);
                link.click();

                return CierreDiaAPI.DescargarArchivo(archivoDos)
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${archivoDos.nombreArchivo}.${archivoDos.extension}`);
                document.body.appendChild(link);
                link.click();
            }).catch(err => {
                if (err.response) {
                    toast.error('No se encontró el archivo seleccionado')
                } else {
                    toast.error(ErrorMessages.SinConexion)
                }
            })
    }

    return (
        <div>
            <h1 className='title is-2'>Cierre día</h1>

            <div className='card'>
                <div className='card-content'>
                    <div className="row">
                        <div className='columns is-multiline'>

                            <div className="column is-6">
                                <label className='label'>Fecha Cierre Inicio</label>
                                <DatePicker
                                    isClearable
                                    locale="es"
                                    name={"FiltroFechaCierreInicio"}
                                    placeholderText="dd/MM/yyyy"
                                    selected={state.FiltroFechaCierreInicio}
                                    onChange={fecha => {
                                        setState({ ...state, FiltroFechaCierreInicio: fecha, FiltroFechaCierreFin: null })
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    className="input"
                                    todayButton="Hoy"
                                />
                            </div>

                            <div className="column is-6">
                                <label className='label'>Fecha Cierre Fin</label>
                                <DatePicker
                                    isClearable
                                    locale="es"
                                    name={"FiltroFechaCierreFin"}
                                    placeholderText="dd/MM/yyyy"
                                    selected={state.FiltroFechaCierreFin}
                                    onChange={fecha => setState({ ...state, FiltroFechaCierreFin: fecha })}
                                    dateFormat="dd/MM/yyyy"
                                    className="input"
                                    todayButton="Hoy"
                                    minDate={state.FiltroFechaCierreInicio}
                                    maxDate={addDays(state.FiltroFechaCierreInicio, 30)}
                                    disabled={state.FiltroFechaCierreInicio === null}
                                />
                            </div>

                            <div className="column is-3">
                                <label className="label">Segmentación</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"FiltroSegmento"}
                                        value={state.FiltroSegmento}
                                        onChange={value => {
                                            setState({ ...state, FiltroSegmento: value })
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={state.listaSegmentos}
                                    />
                                </div>
                            </div>
                            <div className="column is-3">
                                <label className="label">Estados</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"FiltroEstado"}
                                        value={state.FiltroEstado}
                                        onChange={value => {
                                            setState({ ...state, FiltroEstado: value })
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={state.listaEstados}
                                    />
                                </div>
                            </div>
                            <div className="column is-3">
                                <label className="label">Ciudades</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOptionC"}
                                        value={state.FiltroCiudad}
                                        onChange={value => {
                                            setState({ ...state, FiltroCiudad: value })
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={state.listaCiudades}
                                    />
                                </div>
                            </div>
                            <div className="column is-3">
                                <label className="label">{'Drive(s)'}</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"FiltroDrive"}
                                        value={state.FiltroDrive}
                                        onChange={value => {
                                            setState({ ...state, FiltroDrive: value })
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={state.listaSucursales}
                                        getOptionLabel={(sucursal) => sucursal.Name}
                                        getOptionValue={(sucursal) => sucursal.idCaffenio}
                                    />
                                </div>
                            </div>

                            <div className="column is-4"></div>

                            <div className="has-text-left buttons-modal column">
                                <button
                                    className="button is-success"
                                    style={{ marginRight: "5px" }}
                                    onClick={filtrarDatos}
                                >
                                    <span className="icon is-small">
                                        <i className="fa fa-search"></i>
                                    </span>
                                    <span>Buscar</span>
                                </button>
                                <button
                                    className="button is-success"
                                    onClick={limpiarFiltros}
                                    style={{ marginRight: "5px" }}>
                                    <span className="icon is-small">
                                        <i className="fa fa-times"></i>
                                    </span>
                                    <span>Limpiar filtros</span>
                                </button>
                                {state.listaCierresDia.length > 0 &&
                                    <ExcelFile
                                        filename={`CierresDia-${new Date().toLocaleDateString('es-MX')}`}
                                        element={
                                            <button className="button is-success">
                                                <span className="icon">
                                                    <i className="fa fa-file" />
                                                </span>
                                                <span>Exportar a EXCEL</span>
                                            </button>
                                        }
                                    >
                                        <ExcelSheet data={state.listaCierresDia} name="CierresDia" >
                                            <ExcelColumn label="IdCaffenio" value={(cierreDia) => cierreDia.IdCaffenio} />
                                            <ExcelColumn label="Drive" value={(cierreDia) => cierreDia.Caffenio === undefined || cierreDia.Caffenio === null ? 'N/A' : cierreDia.Caffenio.Name} />
                                            <ExcelColumn label="Consumo" value={(cierreDia) => cierreDia.Consumo} />
                                            <ExcelColumn label="Venta16" value={(cierreDia) => cierreDia.Venta16} />
                                            <ExcelColumn label="Venta0" value={(cierreDia) => cierreDia.Venta0} />
                                            <ExcelColumn label="Recarga" value={(cierreDia) => cierreDia.Recarga} />
                                            <ExcelColumn label="Giftcard" value={(cierreDia) => cierreDia.Giftcard} />
                                            <ExcelColumn label="Descuento Promos" value={(cierreDia) => cierreDia.DescuentoPromos} />
                                            <ExcelColumn label="Descuento Monedero" value={(cierreDia) => cierreDia.DescuentoMonedero} />
                                            <ExcelColumn label="Prepago" value={(cierreDia) => cierreDia.Prepago} />
                                            <ExcelColumn label="Tarjeta Regalo" value={(cierreDia) => cierreDia.TarjetaRegalo} />
                                            <ExcelColumn label="Factura SAT" value={(cierreDia) => cierreDia.FacturaSAT} />
                                            <ExcelColumn label="Fecha Cierre" value={(cierreDia) => format(Date.parse(cierreDia.FechaCierre), "dd/MM/yyyy HH:mm")} />
                                            <ExcelColumn label="Factura SAP" value={(cierreDia) => cierreDia.FacturaSAP} />
                                            <ExcelColumn label="Salida Material" value={(cierreDia) => cierreDia.AjusteSAP} />
                                            <ExcelColumn label="Venta Facturada" value={(cierreDia) => cierreDia.VentaFacturada} />
                                            <ExcelColumn label="Folio Prepago" value={(cierreDia) => cierreDia.FolioPrepago} />
                                            <ExcelColumn label="UUIDPrepago" value={(cierreDia) => cierreDia.UUIDPrepago} />
                                        </ExcelSheet>
                                    </ExcelFile>
                                }
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className='card'>
                <div className='card-content'>
                    <div>
                        {state.listaCierresDia.length !== 0 ?
                            <table className='table is-fullwidth'>
                                <thead>
                                    <tr>
                                        <th>Drive</th>
                                        <th>Factura SAP</th>
                                        <th>Venta Facturada</th>
                                        <th>Fecha Cierre</th>
                                        <th>Salida Material</th>
                                        <th>Folio prepago</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.paginadoCierresDia.map(cierreDia =>
                                            <tr key={cierreDia.FacturaSAT}>
                                                <td>{cierreDia.Caffenio === undefined || cierreDia.Caffenio === null ? 'N/A' : cierreDia.Caffenio.Name}</td>
                                                <td>{cierreDia.FacturaSAP ? cierreDia.FacturaSAP : 'N/A'}</td>
                                                <td>{formatoPrecio.format(cierreDia.VentaFacturada)}</td>
                                                <td>{cierreDia.FechaCierre ? format(Date.parse(cierreDia.FechaCierre), "dd/MM/yyyy HH:mm") : 'N/A'}</td>
                                                <td>{cierreDia.AjusteSAP ? cierreDia.AjusteSAP : 'N/A'}</td>
                                                <td>{cierreDia.FolioPrepago ? cierreDia.FolioPrepago : 'N/A'}</td>
                                                <td>
                                                    <div className="has-text-left buttons-modal row">
                                                        {cierreDia.FacturaSAT !== null && 
                                                        <button
                                                                className="button is-success"
                                                                style={{ marginRight: "5px" }}
                                                                onClick={() => descargarArchivo(cierreDia.FacturaSAT)}
                                                            >
                                                                <span>Factura</span>
                                                            </button>
                                                        }
                                                        {cierreDia.UUIDPrepago !== null && 
                                                        <button
                                                                className="button is-success"
                                                                style={{ marginRight: "5px" }}
                                                                onClick={() => descargarArchivo(cierreDia.UUIDPrepago)}
                                                            >
                                                                <span>Prepago</span>
                                                            </button>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table> : <NoItems itemName="movimientos" />
                        }
                    </div>
                    <Pagination
                        items={state.listaCierresDia}
                        onChangePage={listaCierresDia => setState({ ...state, paginadoCierresDia: listaCierresDia })}
                    />
                </div>
            </div>

            <Loading isFullscreen={true} isLoading={state.isLoading} width='100px' height='100px' />
        </div>
    )
}
