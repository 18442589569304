import * as React from 'react'

/**
 * Componente de utilería encargado de desplegar un modal con la única funcionalidad de
 * confirmar si desea o no realizar alguna acción especificada por el título del mismo.
 */

class AcceptModal extends React.Component {
    constructor(props){
        super(props);
        this.state={
            showAccept:true,
            showCancel:true
        }
        console.log(this.props.hideAccept)
    }

    render(){
        return (
            <div className={this.props.isActive ? "modal is-active " : "modal"}>
                <div className="modal-background" onClick={() => this.props.cancel(false)}/>
                <div className="modal-card">
                    <div className="modal-card-head">
                        <p className="modal-card-title">{this.props.modalTitle}</p>
                    </div>
                    {
                        this.props.modalBody &&
                        <div className="modal-card-body">
                            {this.props.modalBody}
                        </div>
                    }
                    <div className="modal-card-foot">
                        <div className="has-text-right" style={{width: "100%"}}>
                            <button
                                className="button"
                                onClick={(e) => { e.preventDefault(); this.props.cancel(false);}}
                            >
                                Cancelar
                            </button>
                            {this.props.hideAccept==true ? false : true &&<button
                                className={`${this.props.willSave ? "is-success" : "is-danger"} button`}
                                onClick={(e) => { e.preventDefault(); this.props.done(true);}}
                            >
                                Aceptar
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AcceptModal