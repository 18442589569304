import React, { useState, useEffect } from 'react';
import NuevaPromocionForm from './components/NuevaPromocionForm';
import PromocionesAPI from '../../../services/Promotions';
import { toast } from 'react-toastify';

function NuevaPromocion(props){
    const [data, setData] = useState({promotions: [],typesPromotion:[],typesQR:[]});
    const [promocion,setPromocion] = useState(null);
    const [isLoadingPromotions,setIsLoadingPromotions] = useState(false);

    const fetchData = async () => {
            var promotions=[],typesPromotion=[],typesQR=[];
            var getPromoFinished = false,getTypesPromoFinished = false,getQrTypeFinished = false;

            setIsLoadingPromotions(true);
            var responsePromotions = PromocionesAPI.getPromotions();
            responsePromotions.then( res =>{
                if(res.status === 200){
                    promotions = res.data;
                }else{
                    toast.error('Error '+res.status);
                }
            }).catch(err => {
                toast.error(err.message);
            }).finally(() => {
                setIsLoadingPromotions(false);
                getPromoFinished=true;
                if(validateMultipleCalls(getPromoFinished,getTypesPromoFinished,getQrTypeFinished)) setData({promotions: promotions,typesPromotion:typesPromotion,typesQR:typesQR});
            });

            var responsePromoType = PromocionesAPI.getPromoType();
            responsePromoType.then( res =>{
                if(res.status === 200){
                    typesPromotion = res.data;
                }else{
                    toast.error('Error '+res.status);
                }
            }).catch(err => {
                toast.error(err.message);
            }).finally(() =>{
                getTypesPromoFinished=true;
                if(validateMultipleCalls(getPromoFinished,getTypesPromoFinished,getQrTypeFinished)) setData({promotions: promotions,typesPromotion:typesPromotion,typesQR:typesQR});
            });

            var responseQRType = PromocionesAPI.getQRType();
            responseQRType.then( res =>{
                if(res.status === 200){
                    typesQR = res.data;
                }else{
                    toast.error('Error '+res.status);
                }
            }).catch(err => {
                toast.error(err.message);
            }).finally(()=>{
                getQrTypeFinished=true;
                if(validateMultipleCalls(getPromoFinished,getTypesPromoFinished,getQrTypeFinished)) setData({promotions: promotions,typesPromotion:typesPromotion,typesQR:typesQR});
            });
    }

    function validateMultipleCalls (getPromoFinished,getTypesPromoFinished,getQrTypeFinished){
        if(getPromoFinished && getTypesPromoFinished && getQrTypeFinished){
            return true;
        }else{
            return false;
        }
    }

    useEffect(() => {
        fetchData();

        if(props.match.params.id){
            // PromocionesAPI.getPromocion(props.match.params.id).then(res => {
            //     setPromocion(res.data);
            // }).catch(err => toast.error(toast.response.data.Message));
        }
    }, [])

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="card-header-title is-size-4 center-text">
                        <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                            <i className="fa fa-arrow-left" style={{cursor: "pointer", color: "#000000" }} onClick={() => {
                                props.history.push({
                                    pathname: '/app/promociones',
                                    state: props.location.state ? {currentPage: props.location.state.currentPage || 0, name: props.location.state.name || ""} : {currentPage: 1, name: ""}
                                });
                                }}></i>
                        </span>
                        <p style={{marginLeft:50,marginRight:50, wordBreak:'break-word'}}>{props.match.params.id ? "Editar promoción" : "Nueva promoción"}</p>
                    </div>
                </div>
                <div className="card-content">
                    <NuevaPromocionForm
                        {...data}
                        {...props}
                        isLoadingPromotions={isLoadingPromotions}
                    />
                </div>
            </div>
            {/* <Loading isFullscreen={true} isLoading={this.state.loading} width='100px' height='100px'/> */}
        </div>
    )
}

export default NuevaPromocion;