import React, { useEffect, useState } from 'react';

// Components
import NoItems from '../util/noitems/NoItems';
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading';
import { ModalTasaImpuesto } from './ModalTasaImpuesto';

// Utils
import { toast } from 'react-toastify';

// Services
import TaxRateAPI from '../../services/TaxRate';


/**
 * Componente para modulo de Tasa de impuestos.
 * @returns Component
 */
export const CatalogoImpuestos = () => {
    const [state, setState] = useState({
        pages: [],
        isLoading: false,
        listImpuestos: [],
        impuestoSelected: null,
        filterText: '',
        modalVisible: false,
        isEditing: false
    });

    useEffect(() => {
        setState({ ...state, isLoading: true });
        let impuestos = [];
        let allPages = [];

        TaxRateAPI.getAll()
            .then(res => {
                allPages = res.data;
                impuestos = res.data;
            })
            .catch(err => {
                toast.error('Ocurrio un error al consulatar las tasas de impuesto.');
                console.error(err);
            })
            .finally(() => setState({
                ...state,
                isLoading: false,
                pages: allPages,
                listImpuestos: impuestos,
            }));

        setState({
            ...state,
            isLoading: false,
            pages: [],
            listImpuestos: [],
        })
    }, []);

    /***
     * Filtra tasa de impuestos
     */
    const handleSearchInput = (text) => {
        let filterText = text;
        let pages = [];
        if (text && text.length > 0) {
            pages = state.listImpuestos.filter(p => {
                if (p.Description.includes(filterText)) {
                    return p;
                }
            })
        } else {
            pages = state.listImpuestos;
        }
        setState({ ...state, filterText, pages });
    }

    /**
     * Edita o actualiza tasa de impuesto
     * @param {*} isEditing  bandera para saber si el regitro se esta actualizando o es nuevo
     * @param {*} obj objeto a guardar
     */
    const handledSave = (isEditing, obj) => {
        setState({ ...state, isLoading: true });

        if (isEditing) {
            update(obj);
        } else {
            create(obj);
        }
    }

    /**
     * Crea tasa de impuesto
     * @param {*} obj 
     */
    const create = (obj) => {
        let allPages = state.allPages;
        let listImpuestos = state.listImpuestos;

        TaxRateAPI.create(obj)
            .then(response => {
                toast.success('Tasa de impuesto guardado.');
                // actualiza listado
                allPages = response.data;
                listImpuestos = response.data;
            })
            .catch(err => {
                let message = '';
                try {
                    message = err.status === 500 ? 'Ocurrio un error al guardar tasa de impuesto.' : err.response.data;
                } catch (error) {
                    message = 'Ocurrio un error al guardar tasa de impuesto.' ;
                } finally {
                    toast.error(message);
                    setState({ ...state, isLoading: false });
                }
            }).finally(() => {
                setState({ ...state, isLoading: false, listImpuestos, allPages, modalVisible:false })
            })
    }

    /**
     * Actualiza tasa de impuesto
     * @param {*} obj 
     */
    const update = (obj) => {
        let allPages = state.allPages;
        let listImpuestos = state.listImpuestos;

        TaxRateAPI.update(obj.TaxRateId, obj)
            .then(response => {
                toast.success('Tasa de impuesto actualizado.');
                allPages = response.data;
                listImpuestos = response.data;
            })
            .catch(err => {
                let message = '';
                try {
                    message = err.status === 500 ? 'Ocurrio un error al actualizar tasa de impuesto.' : err.response.data;
                } catch (error) {
                    message = 'Ocurrio un error al actualizar tasa de impuesto.' ;
                } finally {
                    toast.error(message);
                    setState({ ...state, isLoading: false });
                }
            })
            .finally(() => {
                setState({ ...state, isLoading: false, listImpuestos, allPages,modalVisible:false, impuestoSelected:null, isEditing:false })
            })
    }

    return (
        <div>
            {
                state.isLoading &&
                <Loading
                    isFullscreen={true}
                    isLoading={state.isLoading}
                    width='100px'
                    height='100px'
                />
            }
            <h1 className="title is-2">Catálogo de Tasa de impuesto</h1>
            <div className="columns">
                <div className="column is-12">
                    <div className="field">
                        <div className="control has-text-right">
                            <a className="button is-success" onClick={(e) => setState({ ...state, modalVisible: true, impuestoSelected: null, isEditing: false })}>
                                <span className="icon is-small">
                                    <i className="fa fa-plus"></i>
                                </span>
                                <span>Agregar Tasa de impuesto</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Filtros */}
            <div className="card">
                <div className="card-content">
                    <div className="columns is-multiline">
                        <div className="column is-6">
                            <div className="control">
                                <input
                                    type="text"
                                    className="input"
                                    placeholder={`Ingrese nombre de impuesto`}
                                    value={state.filterText}
                                    autoFocus={true}
                                    onChange={(e) => handleSearchInput(e.currentTarget.value || '')}
                                />
                            </div>
                        </div>
                        <div className="column is-6">
                            {/*  */}
                        </div>
                    </div>
                </div>
            </div>
            <br />


            {/* Tabla de resultados */}
            <div className='card'>
                <div className='card-content'>
                    {
                        state.listImpuestos.length !== 0 ?
                            <table className='table is-fullwidth'>
                                <thead>
                                    <tr>
                                        <th>Impuesto</th>
                                        <th>Porcentaje</th>
                                        <th>GP</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.pages.map(suc =>
                                            <tr key={suc.TaxRateId}>
                                                <td>{suc.Description}</td>
                                                <td>{suc.Percentage}</td>
                                                <td>{suc.TaxGP}</td>

                                                <td>
                                                    <button
                                                        className='button is-warning'
                                                        onClick={() => setState({ ...state, modalVisible: true, impuestoSelected: suc, isEditing: true })}
                                                    >
                                                        Editar
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            : <NoItems itemName='Tasa de impuetos' />
                    }
                    <Pagination
                        items={state.listImpuestos}
                        pageSize={10}
                        onChangePage={suc => setState({ ...state, pages: suc })}
                    />
                </div>
            </div>
            {state.modalVisible &&
                <ModalTasaImpuesto
                    onSave={(isEditing, data) => handledSave(isEditing, data)}
                    impuesto={state.impuestoSelected}
                    onClose={() => setState({ ...state, modalVisible: false, impuestoSelected: null, isLoading: false })}
                    isEditing={state.isEditing}
                />
            }
        </div>
    )
}