import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Field from '../util/field/Field';

import DrivePromotionRestrictionsAPI from '../../services/DrivePromotionRestrictions';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import ErrorMessages from '../../util/validation-error-messages';
import { element } from 'prop-types';

const subtitle = {
    textAlign: 'initial',
    padding: '10px 10px 10px 0',
    fontSize: '16px',
    color: 'gray'
}

const optionActive = {
    backgroundColor: '#f42434',
    color: '#fff'
}

export const RestriccionesPromo = ({values, handleNextStep, handleLastStep }) => {

    const [ state, setState ] = useState({        
        listRestriccionTipo : [],
        selected : values.restrictionsSelected,
        tipoRSelected:null,
        endDateRestriction : values.endDateRestriction,
        initDateRestriction :values.initDateRestriction
    });

    useEffect(() => {
        let tipoRestriccionData = [];

        DrivePromotionRestrictionsAPI.getAll()            
            .then(response => {
                tipoRestriccionData = response.data.filter(res=>res.Active).sort(function (a, b) {
                    if (a.Order > b.Order) {
                      return 1;
                    }
                    if (a.Order < b.Order) {
                      return -1;
                    }
                    return 0;
                  })
                //Es PromoRestrictionTypeId=1 para hacer referencia a las promos basicas 
                let index = tipoRestriccionData.findIndex(x => x.PromoRestrictionTypeId === 1)
                //Es idPromoRestriction=1 para solo mostrar la promo "No aplica con otras promociones" de las promos basicas
                tipoRestriccionData[index].PromoRestrictions = tipoRestriccionData[index].PromoRestrictions.filter(x => x.idPromoRestriction === 1)
                if(state.selected && state.selected.length > 0){
                    state.selected.map(s => {
                        let tipoR = tipoRestriccionData.filter(tr => tr.PromoRestrictionTypeId === s.type);
                        if(tipoR && tipoR.length > 0){
                            let restriction = tipoR[0].PromoRestrictions.filter(r=> r.idPromoRestriction === s.restriction);
                            s.title= tipoR[0].Title ;
                            s.description = restriction && restriction.length > 0  ? restriction[0].Description : '';
                        }else{
                            s.title ='';
                            s.description='';
                        }
                        
                        return s;
                    })  
                }
            })
            .catch(err => {
                if (err.response)
                    toast.error(err.response.code !== 500 ? err.response.data : ErrorMessages.ErrorInesperado)
                else
                    toast.error(ErrorMessages.SinConexion)
            }).finally(() => setState({
                ...state,
                listRestriccionTipo : tipoRestriccionData,
                tipoRSelected:tipoRestriccionData ? tipoRestriccionData[0]: null
            }))
    },[]); 

    const addRestriction = (type,restriction, description, title) => {

        var index = -1; // -1 if not found
        for (var i = 0; i < values.restrictionsSelected.length; ++i)
        {
            var element = values.restrictionsSelected[i];
            if (element.type === type && element.restriction === restriction)
            {
                index = i;
                break;
            }
        }

        if (index > -1){
            values.restrictionsSelected.splice(index, 1);
        }
        else{
            values.restrictionsSelected.push({
                type : type,
                restriction : restriction,
                description : description,
                title: title
            });
        }
            
        setState({
            ...state,
            selected : values.restrictionsSelected
        })
        

    }

    return (
        <div className='form-group'>
            <div className='row'>
                <div className="columns ">
                    <div className="column is-3">
                        <p className="menu-label"> Tipos de reestricciones </p>
                        <ul className="menu-list has-text-left ">
                            {state.listRestriccionTipo.map(tipoR => {
                                return (                                    
                                    <li >

                                        <a className='is-primary' style={state.tipoRSelected && state.tipoRSelected.Name ===tipoR.Name ? optionActive :{}} onClick={(e) => {
                                            e.preventDefault();
                                            state.tipoRSelected = tipoR
                                            setState({ ...state, tipoRSelected: tipoR })

                                        }}><i className={tipoR.Icon}></i> {tipoR.Name}</a>
                                    </li>

                                )
                            })}

                        </ul>
                    </div>
                    <div className="column is-1"></div>
                    <div className="column is-8">
                        <p className="menu-label"> {`Reestricciones ${state.tipoRSelected ? state.tipoRSelected.Name :''} disponibles`} </p>
                        <div className="columns is-multiline">
                            {state.tipoRSelected && state.tipoRSelected.Name !== "Horario"
                                && state.tipoRSelected.PromoRestrictions.map((res, index) => {
                                    return (
                                        <div className='column is-10'>
                                            <div className="FieldHorizontal field is-horizontal">
                                                <div className="field">
                                                    <input
                                                        type="checkbox"
                                                        name={res.Description}
                                                        checked={state.selected.some(el => el.type === state.tipoRSelected.PromoRestrictionTypeId && el.restriction === res.idPromoRestriction)}
                                                        className="switch"
                                                        onChange={() => { }}
                                                    />
                                                    <label disabled htmlFor={res.Description}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            addRestriction(state.tipoRSelected.PromoRestrictionTypeId, res.idPromoRestriction, res.Description, state.tipoRSelected.Title)
                                                        }}
                                                    />

                                                </div>
                                                <label className="label">{res.Description}</label>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {state.tipoRSelected && state.tipoRSelected.Name === "Horario" && <div className='row'>
                                <div className='columns' style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                    <div className='column is-6'>

                                        <Field label="Hora inicio"
                                            alingLefth={true}
                                        >
                                            <input
                                                id="initDateRestriction"
                                                name={"initDateRestriction"}
                                                onChange={(time) => {
                                                    values.initDateRestriction = time.target.value
                                                    setState({
                                                        ...state,
                                                        initDateRestriction: time.target.value
                                                    })
                                                }}
                                                type='time'
                                                className={"input"}
                                                placeholder={"Hora inicio"}
                                                value={state.initDateRestriction}

                                            />
                                        </Field>
                                    </div>
                                    <div className='column is-6'>

                                        <Field label="Hora fin"
                                            alingLefth={true}
                                        >
                                            <input
                                                id="endDateRestriction"
                                                name={"endDateRestriction"}
                                                type='time'
                                                className={"input"}
                                                placeholder={"Hora fin"}
                                                onChange={(time) => {
                                                    values.endDateRestriction = time.target.value
                                                    setState({
                                                        ...state,
                                                        endDateRestriction: time.target.value
                                                    })
                                                }}
                                                value={state.endDateRestriction}

                                            />
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>


                    </div>
                </div>


            </div>
            <br />
            <div className='row' style={{ padding: '10px' }}>
                <div className='columns'>
                    <div className='column is-12'>
                        <div className="has-text-left buttons-modal" id="stepActions">
                            <div className="pull-left">
                            <button
                                    className='button'
                                    style={{ marginRight: 10 }}
                                    onClick={handleLastStep}
                                >
                                    Anterior
                                </button>

                            </div>
                        </div>
                        <div className="has-text-right buttons-modal" id="stepActions">
                            <div className="pull-right">
                            
                                <button
                                    style={{ marginRight: 10 }}
                                    className="button is-success"
                                    onClick={() => handleNextStep(true)}
                                    >
                                    Terminar y Enviar
                                </button>
                            
                            
                        
                            <button
                                    className='button  is-success'
                                    style={{ marginLeft: 10 }}
                                    onClick={()=> handleNextStep(false)}
                                >
                                    Terminar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>



    )
}
