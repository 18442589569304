import React from 'react';
import './Chip.css';
import PropTypes from 'prop-types';

/**
 * Componente de utilería encargado de dibujar algún elemento dentro de un Chip
 * incluyendo un botón para eliminar y ser capaz de realizar una acción al hacerle click.
 */
class Chip extends React.Component{
    render(){
        return (
            <div className={`${this.props.className} ${this.props.small ? "chip-small" : "chip"}`} data-tooltip={this.props.dataTooltip}>
                <span onClick={this.props.onClick}>{this.props.label}</span>
                {this.props.closeable && <span className="closebtn" onClick={this.props.onDelete}>&times;</span>}
            </div>
        )
    }
}

// Definición de los tipos y su dependencia de las distintas props que recibe el componente.
Chip.propTypes = {
    closeable: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
Chip.defaultProps = {
    closeable: true,
    className: "",
    dataTooltip: "",
    onClick: () => {},
    
}

export default Chip;