import React from 'react';
import NoItems from '../../../util/noitems/NoItems';
import './style.css';

export default function TablaValidarCliente(props){
    //Objeto de la lista de validation
// ClientId:'691430'
// IsValid:true
// LastName:'Chávez Daniel'
// LoyaltyAccount:'12419000925486'
// Name:'Andrea Gisel'
// Reason:'Cliente válido'
// Surname:''
// TypeLoyaltyAccount:'Monedero AMIGO'
    const columnas = ["ID","Nombre","Cuenta lealtad","Tipo cuenta","Razón"];

    return (
        <div className="card">
            <div className="card-content">
                {props.validClients.length > 0 ?
                <table className="table is-fullwidth">
                    <thead>
                        {columnas.map(c => <th key={c}>{c}</th>)}
                    </thead>
                    <tbody>
                    {props.validClients.map((row, index) => {
                        return (
                            <tr>
                                <td>{row.ClientId}</td>
                                <td>{row.Name +' '+ row.LastName}</td>
                                <td>{row.LoyaltyAccount}</td>
                                <td>{row.TypeLoyaltyAccount}</td>
                                <td>{row.Reason}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                : <NoItems itemName="promociones"/>}
                {/* <ServerPagination 
                        totalPages={totalPages} 
                        currentPage={currentPage}
                        goToPage={props.goToPage}
                    /> */}
            </div>
        </div>
    )
}