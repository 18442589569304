import React, { Component } from 'react';
import UnitsAPI from '../../../services/Units';
import { toast } from 'react-toastify';
import Loading from '../../util/loading/Loading';
import SatAPI from '../../../services/Sat';
import InventoryItemsAPI from '../../../services/InventoryItem';
import PriceDriveAPI from '../../../services/PriceDrive';
import lodash from 'lodash';
import ErrorMessages from '../../../util/validation-error-messages';

//Steps
import InventoryItemPTForm from './stepsForms/InventoryItemPTForm';
import InventoryItemUnitsForm from './stepsForms/InventoryItemUnitsForm';
import InventoryItemTypeForm from './stepsForms/InventoryItemTypeForm';
import InventoryItemEndForm from './stepsForms/InventoryItemEndForm';
import EmpresasAPI from '../../../services/CaffenioEmpresas/Empresas';



class InventoryItemForm extends Component {

    listaPrecios = [];

    constructor(props) {
        super(props);
        this.state = {
            segmentos: [],
            inventoryItem: {},
            inventoryItems: [],
            inventoryTypes: [],
            isEditing: false,
            isLoading: false,
            units: [],
            satCodes: [],
            orderTypes: [],
            listaPrecios: [],
            selectedPricesList: [],
            getUnitsGP: this.getUnitsGP.bind(this),
            unitsGP: [],
            disableConvFactor: false,
            alertConvFactor: null,

            stepPtProgress: true,
            stepUnitsProgress: false,
            stepTypeProgress: false,
            stepEndProgress: false,

            stepPtCompleted:false,
            stepUnitsCompleted: false,
            stepTypeCompleted: false,
            
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.inventoryItem.InventoryItemId
            && prevState.inventoryItem.InventoryItemId !== nextProps.inventoryItem.InventoryItemId) {
            return { inventoryItem: nextProps.inventoryItem, isEditing: true, getUnitsGP: prevState.getUnitsGP(), isLoading: true };
        } else {
            return null;
        }

    }

    componentDidMount() {
        this.setState({ isLoading: true, inventoryItems: this.props.inventoryItems, inventoryItem: this.props.inventoryItem}, () => {
            let state = { ...this.state };
            
            UnitsAPI.getAll().then(response => {
                let formatUnits= lodash.sortBy(response.data, [ts => ts.Name.toLowerCase()], ['asc'] );
                state.units = formatUnits;
                return SatAPI.getCodes();
            }).then(response => {
                let formatCodes= lodash.sortBy(response.data, [ts => ts.Description.toLowerCase()], ['asc'] );
                state.satCodes = formatCodes;
                state.isLoading = false;
                return InventoryItemsAPI.getInventoryTypes();
            }).then(response => {
                let formatTypes = lodash.sortBy(response.data, [ts => ts.Description.toLowerCase()], ['asc'] );
                state.inventoryTypes = formatTypes;
                return InventoryItemsAPI.getOrderTypes();
            }).then(response => {
                let formatOrTypes = lodash.sortBy(response.data, [ts => ts.Description.toLowerCase()], ['asc'] );
                state.orderTypes = formatOrTypes;
                return EmpresasAPI.getAllSegments()
            }).then(response => {
                state.segmentos = response.data
                this.setState(state);
            }).catch(err => {
                this.setState({ isLoading: false })
                if (err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            })

            // Obtener las listas de precios para las cuales sera asignado
            // el articulo en caso de ser topping
            PriceDriveAPI.get().then(response => {
                response.data.map(obj => {
                    this.listaPrecios.push({ value: obj.PriceListId, label: obj.Description })
                })
            }).catch(err => {
                this.setState({ isLoading: false })
                if (err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        })
        let state = { ...this.state }

        // Variable para mostrar el campo de precio en caso que sea un nuevo articulo
        // establecido como topping
        if (typeof state.inventoryItem.ShowToppingPrice === 'undefined') {
            state.inventoryItem.ShowToppingPrice = true;
        }
        this.setState(state);
    }

    /**
     * Funcion para el multi select de listas de precios.
     * @param: arr: entidad que recibe del select multi con las opciones seleccionadas
     */
    handlePriceList = (arr) => {
        let state = { ...this.state }
        let actualPricesList = [];

        arr.map(obj => {
            actualPricesList.push(obj.value);
        })

        state.inventoryItem.selectedPricesList = actualPricesList;
        this.setState(state);
    }

    /**
     * Obtiene las unidades que estan relacionados con el codigo de proveedor
     */
    getUnitsGP = () => {
        if (this.state.inventoryItem.CodeProvider && this.state.inventoryItem.CodeProvider.trim() != "") {
            //console.log("Cargando unidades de GP => ", this.state.inventoryItem.CodeProvider)
            UnitsAPI.getByCodeProvider(this.state.inventoryItem.CodeProvider).then(response => {
                let units = response.data;
                this.setState({ unitsGP: units, isLoading: false }) 
            })
        }
    }

    /**Obtiene listado de artículos */
    getInventoryItems = () => {        
        return this.props.inventoryItems;
    }

    /** Asigna las unidades de PT o PE aasignadas en GP */
    setUnits = (units) =>{
        this.setState({ unitsGP: units})//, () => this.checkUnitsGP())
    }

    render() {
        return (
            <div>
                <div className="steps" id="stepsArticuloInventario">
                    <div className={`step-item ${this.state.stepPtProgress ? "is-active" : ""} ${this.state.stepPtCompleted ? "is-success" : ""}`} id="stepGeneralInfo">
                        <div className="step-marker">1</div>
                        <div className="step-details">
                            <p className="step-title">Datos generales</p>
                            <p>Información general del artículo.</p>
                        </div>
                    </div>
                    <div className={`step-item ${this.state.stepUnitsProgress ? "is-active" : ""} ${this.state.stepUnitsCompleted ? "is-success" : ""}`} id="stepEquivalencias">
                        <div className="step-marker">2</div>
                        <div className="step-details">
                            <p className="step-title">Equivalencias</p>
                            <p>Configuración de equivalencias del artículo para su venta, almacenamiento y pedido.</p>
                        </div>
                    </div>
                    <div className={`step-item ${this.state.stepTypeProgress ? "is-active" : ""} ${this.state.stepTypeCompleted ? " is-success" : ""}`} id="stepInventario">
                        <div className="step-marker">3</div>
                        <div className="step-details">
                            <p className="step-title">Tipo Inventario</p>
                            <p>Identifica la clasificación del artículo para el sistema de pedido sugerido e inventario.</p>
                        </div>
                    </div>
                    <div className={`step-item ${this.state.stepEndProgress ? "is-active" : ""} ${this.state.stepEndCompleted ? "is-success" : ""}`} id="stepFinish">
                        <div className="step-marker">4</div>
                        <div className="step-details">
                            <p className="step-title">Finalizar</p>
                            <p>Información complementaria del artículo.</p>
                        </div>
                    </div>
                    <div className="steps-content">
                        <div className={`step-content has-text-centered ${this.state.stepPtProgress ? "is-active" : ""}`} id="stepContentGeneral">
                            <InventoryItemPTForm
                                segmentos = {this.state.segmentos} 
                                inventoryItem ={this.state.inventoryItem}                                
                                units = {this.state.unitsGP}
                                save = {(values) => {
                                    let currentItem = {...this.state.inventoryItem}
                                    currentItem.CodeProvider = values.CodeProvider;
                                    currentItem.Name = values.Name;
                                    currentItem.ShortName = values.ShortName;
                                    currentItem.Segmentos = values.Segmentos
                                    this.setState({inventoryItem:currentItem, stepPtCompleted:true, stepPtProgress: false, stepUnitsProgress: true })
                                }}
                                setUnits={(units) => this.setUnits(units)}
                                getInventoryItems ={() => this.getInventoryItems()}
                                
                            />
                        </div>
                        <div className={`step-content has-text-centered ${this.state.stepUnitsProgress ? "is-active" : ""}`} id="stepContentEquivalencias">
                            <InventoryItemUnitsForm
                                inventoryItem ={this.state.inventoryItem}
                                save = {(values) => {
                                    let currentItem = {...this.state.inventoryItem}
                                    currentItem.UnitPurchase = values.UnitPurchase;
                                    currentItem.FactorBuyMin = values.FactorBuyMin;
                                    currentItem.StorageUnit = values.StorageUnit;
                                    currentItem.ConvFactorBuy=values.ConvFactorBuy;
                                    currentItem.UnitSale= values.UnitSale;
                                    currentItem.ConvFactorSale=values.ConvFactorSale;

                                    this.setState({inventoryItem:currentItem, stepUnitsCompleted:true, stepUnitsProgress: false, stepTypeProgress: true })
                                }}
                                back = {() => {
                                    this.setState({stepUnitsProgress: false, stepPtProgress: true })
                                }}
                                units = {this.state.units}
                                unitsGP = {this.state.unitsGP}
                            
                            />
                        </div>
                        <div className={`step-content has-text-centered ${this.state.stepTypeProgress ? "is-active" : ""}`} id="stepContentInventario">
                            <InventoryItemTypeForm
                                inventoryItem = {this.state.inventoryItem}
                                orderTypes = {this.state.orderTypes}
                                inventoryTypes = {this.state.inventoryTypes}                                
                                save = {(values) => {
                                    let currentItem = {...this.state.inventoryItem}
                                    currentItem.AmountMax= values.AmountMax;
                                    currentItem.AmountMin=values.AmountMin;
                                    currentItem.OrderTypeId=values.OrderTypeId;
                                    currentItem.InventoryTypeId = values.InventoryTypeId;

                                    this.setState({inventoryItem:currentItem, stepTypeCompleted:true, stepTypeProgress: false, stepEndProgress: true })
                                }}
                                back = {() => {
                                    this.setState({stepUnitsProgress: true, stepTypeProgress: false })
                                }}
                            />
                        </div>
                        <div className={`step-content has-text-centered ${this.state.stepEndProgress ? "is-active" : ""}`} id="stepContentEnd">
                            <InventoryItemEndForm 
                                inventoryItem = {this.state.inventoryItem}
                                satCodes = {this.state.satCodes}
                                units = {this.state.units}
                                save = {(values) => {
                                    let currentItem = {...this.state.inventoryItem} 
                                    currentItem.IsPerecedero = values.IsPerecedero ? values.IsPerecedero : false;
                                    currentItem.IsTopping = values.IsTopping ? values.IsTopping : false;   
                                    currentItem.ToppingPrice = values.ToppingPrice; 
                                    currentItem.ToppingWeight = values.ToppingWeight;
                                    currentItem.CodeSat = values.CodeSat; 
                                    currentItem.Image = values.Image; 

                                    this.setState({inventoryItem:currentItem, stepEndCompleted:true, stepEndProgress: false }, () => this.props.save(this.state.inventoryItem))
                                }}
                                back = {() => {
                                    this.setState({stepTypeProgress: true, stepEndProgress: false })
                                }}
                            />
                        </div>
                    </div>                    
                </div>
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
            </div>
        );
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
InventoryItemForm.defaultProps = {
    isEditing: false,
    inventoryItem: {
        CodeProvider: "",
        InventoryCode: "",
        Name: "",
        ShortName: "",
        UnitPurchase: 0,
        StorageUnit: 0,
        UnitSale: 0,
        ConvFactorBuy: "",
        ConvFactorSale: "",
        FactorBuyMin: 0.95,
        AmountMin: 0,
        AmountMax: 0,
        InventoryTypeId: 0,
        CodeSat: null,
        IsTopping: false,
        ToppingPrice: 0,
        ToppingWeight: 0
    }
}

export default InventoryItemForm;



