
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import ComunicadosVPAPI from "../../services/ComunicadosVP";
import '../cssComponents.css';
import ErrorMessages from '../../util/validation-error-messages';
import Loading from '../util/loading/Loading';
import ComunicadoForm from './ComunicadoForm';
import { format } from 'date-fns'

class NewComunicado extends Component {
    constructor(props){
        super(props);
        this.state = {
            comunicadoList:[],
            comunicado: null,
            isLoading: false
        };
    
    }

    componentDidMount(){
        if (!this.props.location.state) {
            this.props.history.push(`/comunicados-vp`);
            return;
        }
        let state = {...this.state};
        state.comunicadoList = this.props.location.state.comunicadoList;
        state.isEditing = this.props.location.state.isEditing;
        if(state.isEditing){
            state.comunicado = this.props.location.state.comunicado;
            this.getDrivesComunicado();
        }
        else{
            state.comunicado = {...this.props.comunicado}
        }
        this.setState( state, () =>{ console.log("El state de comunicado >> ", this.state); });
    }

    getDrivesComunicado = () =>{
        this.setState({isLoading: true}, () =>{
            ComunicadosVPAPI.GetDrivesComunicado(this.state.comunicado.IdComunicado).then( response => {
                console.log("Response drives : ", response);
                let state = {...this.state}
                state.comunicado.Drives = response.data;
                state.isLoading = false;
                this.setState(state);
            }).catch(err =>{
                console.error("Error response > ", err);
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            })
        })
    };

    save = (comunicado, imagen = null, mensaje = "Comunicado actualizado") => {
        console.log("La comunicado a guardar >>", comunicado);
        console.log("isEditing >>", this.state.isEditing);
        
        this.setState({isLoading: true}, () => {
            if(this.state.isEditing){
                ComunicadosVPAPI.UpdateComunicadoData(comunicado, imagen).then( response => {
                    let state = {...this.state};
                    state.isLoading = false;
                    this.setState(state, () => {
                        toast.success(mensaje);
                    });
                    this.props.history.goBack();
                }).catch(err => {
                    this.setState({isLoading: false})
                    if(err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                });
            }
            else{
                ComunicadosVPAPI.CreateComunicadoData(comunicado, imagen).then( response => {
                    this.setState({isLoading: false})
                    toast.success("Comunicado creado");
                    this.props.history.goBack();
                }).catch(err => {
                    this.setState({isLoading: false})
                    if(err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                });
            }
        })
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i style={{cursor: "pointer", color: "#91BA43" }} onClick={(e) => { e.preventDefault(); this.props.history.goBack();}} className="fa fa-arrow-left"></i>
                            </span>
                            {this.state.isEditing ? "Actualizar comunicado" : "Nuevo comunicado"}
                            {(this.state.isEditing && this.state.comunicado.Pausado == 0 && ComunicadosVPAPI.GetEstatus(this.state.comunicado) == "Vigente") &&
                                <button style={{position:"absolute", right: 25}} className="button is-warning tooltip" data-tooltip="Pausar comunicado" 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // console.log(item);
                                    // let newState = update(this.state, {
                                    //     comunicadoToEdit: {$set: item}
                                    // })
                                    // this.setState(newState, () =>{
                                    //     this.pauseComunicadoFromList();
                                    // });
                                    let com = {...this.state.comunicado}
                                    com.Pausado = 1;
                                    com.Posicion = com.Posicion.value;
                                    com.FechaInicio = format(new Date(com.FechaInicio),"yyyy/MM/dd");
                                    com.FechaFin = format(new Date(com.FechaFin),"yyyy/MM/dd");
                                    this.save(com);
                                }}
                            >
                                <span className="icon">
                                    <i className="fa fa-pause" />
                                </span>
                                &nbsp;
                                Pausar
                            </button>
                            }
                            {(this.state.isEditing && this.state.comunicado.Pausado == 1 && ComunicadosVPAPI.GetEstatus(this.state.comunicado) == "Vigente") &&
                                <button style={{position:"absolute", right: 25}} className="button is-info tooltip" data-tooltip="Reactivar comunicado" 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // console.log(item);
                                    // let newState = update(this.state, {
                                    //     comunicadoToEdit: {$set: item}
                                    // })
                                    // this.setState(newState, () =>{
                                    //     this.pauseComunicadoFromList();
                                    // });
                                    let com = {...this.state.comunicado}
                                    com.Pausado = 0;
                                    com.Posicion = com.Posicion.value;
                                    com.FechaInicio = format(new Date(com.FechaInicio),"yyyy/MM/dd");
                                    com.FechaFin = format(new Date(com.FechaFin),"yyyy/MM/dd");
                                    this.save(com);
                                }}
                            >
                                <span className="icon">
                                    <i className="fa fa-play" />
                                </span>
                                &nbsp;
                                Reactivar
                            </button>
                            }
                        </div>
                    </div>
                    <div className="card-content">
                        { (this.state.comunicado && this.state.comunicado.Drives) &&
                            <ComunicadoForm save={this.save} comunicadoList={this.state.comunicadoList} comunicado={this.state.comunicado} isEditing={this.state.isEditing} history={this.props.history}/>
                        }
                    </div>
                </div>

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
            </div>
        );
    }
}

// Definición de los tipos y su dependencia de las distintas props que recibe el componente.
NewComunicado.propTypes = {
    comunicado: PropTypes.object,
    isEditing: PropTypes.bool
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
NewComunicado.defaultProps = {
    isEditing: false,
    comunicado: {
        IdComunicado: 0,
        Titulo: "",
        UrlImagen: "",
        FechaInicio: "",
        FechaFin: "",
        Posicion: 0,
        Drives: []
    }
}

export default NewComunicado;