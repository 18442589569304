import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
import { ETIME } from 'constants';

export default class SaldoRegaloFormFile extends Component {
    static propTypes = {
        save: PropTypes.func.isRequired
    }

    static defaultProps = {

        IsActive: true,
    }

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            accountSelection: -1,
            isValidFile: false,
            fileName: "Archivo",
        };
    }

    handleChange = (e) => {
        let state = { ...this.state };
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    handleDateChange = (date, attr) => {
        let state = { ...this.state };
        state[attr] = date;
        this.setState(state);
    }

    //////// VALIDATIONS EXCEL FILE /////////
    validateFile = (sheet) => {
        let is_valid_file = true;
        const columns = ['A', 'B', 'C', 'D', 'E', 'F'];
        let rows = [];
        let file_index = 2;
        const validations_header = this.validationsHeader(columns, sheet);

        if (validations_header.is_valid === false) {
            return { sheet: validations_header.sheet, is_valid_file: false }
        }

        const byLoyaltyAccount = sheet.cell(`A1`).value() === 'NumeroCliente' ? false : true;

        sheet._rows.forEach(row => {
            const temporary_row = this.getRowsFromFile(row, sheet, columns, file_index);
            if (temporary_row.estatus === "") {
                rows.push(temporary_row);
            }
            file_index++;
        });

        for (let row_index = 0; row_index < rows.length; row_index++) {
            const row_validation = this.validationsRow(rows[row_index], byLoyaltyAccount);
            rows[row_index] = row_validation;

            if (row_validation.is_valid === false) {
                is_valid_file = false;
            }
        }

        if (is_valid_file === false) { sheet = this.updateSheet(sheet, rows); }
        return { sheet: sheet, is_valid_file: is_valid_file, rows: rows }
    }

    validationsLoyaltyAccount = (account) => {
        const validRegEx = /((1)|(7))(2419)([0-9]{9})$/g;
        if (account.toString().match(validRegEx) === null || account.toString().length > 30) {
            return false;
        }
        return true;
    }

    ///////<HEADERS>///////
    updateSheet = (sheet, rows) => {
        for (let index = 0; index < rows.length; index++) {
            const row = rows[index];
            if (row.is_valid === true) { continue; }
            sheet.cell(`F${row.index}`).value(row.estatus);
            sheet.cell(`F${row.index}`).style({
                bold: true,
                fontColor: {
                    rgb: "ff1a1a"
                }
            });
        }

        return sheet;
    }

    validationsHeader = (columns, sheet) => {
        let is_valid_headers = true;
        let errors = "";
        const correct_headers = [
            ["NumeroCliente", "CuentaLealtad"],
            ["FechaInicio"],
            ["FechaFinal"],
            ["Monto"],
            ["Motivo"],
            ["Estatus"]
        ];

        for (let index = 0; index <= 5; index++) {
            const header_validation = this.validateHeaderName(sheet.cell(`${columns[index]}1`).value(), correct_headers[index]);
            if (header_validation.is_valid === false) {
                is_valid_headers = false;
                errors += header_validation.error;
            }
        }

        if (is_valid_headers === false) {
            sheet.cell(`G1`).value(errors);
            sheet.cell(`G1`).style({
                bold: true,
                fontColor: {
                    rgb: "ff1a1a"
                }
            });

        }

        return { is_valid: is_valid_headers, sheet: sheet };
    }

    validateHeaderName = (file_header, correct_header) => {
        let is_valid = true;
        let error = ""

        for (let file_index = 0; file_index <= correct_header.length - 1; file_index++) {
            const correct_name = correct_header[file_index];
            if (typeof file_header === "undefined" || file_header.replace(/ /g, '') === '' || file_header !== correct_name) {
                error += ` | No se encontro la cabecera ${correct_name}`;
                is_valid = false;
            } else {
                is_valid = true;
                error = "";
                break;
            }

        }

        return { is_valid: is_valid, error: error };
    }

    getRowsFromFile = (rows, sheet, columns, index) => {
        let row = {
            index: 0,
            Identifier: 0,
            StarDate: '',
            EndDate: '',
            Deposit: 0,
            ReasonDeposit: '',
            estatus: ''
        };

        const properties = [
            'Identifier',
            'StarDate',
            'EndDate',
            'Deposit',
            'ReasonDeposit',
            'estatus',
            'is_valid'
        ];
        let errors = 0;
        row.index = index;
        row.is_valid = true;

        for (let column_index = 0; column_index < columns.length - 1; column_index++) {
            const cell = sheet.cell(`${columns[column_index]}${index}`);
            if (typeof cell._value === "undefined" || cell._value.toString().replace(/ /g, '') === '') {
                errors++;
                row[properties[column_index + 1]] = '';
                row.is_valid = false;
            } else {
                row[properties[column_index]] = column_index > 1 && column_index < 4 ? cell._value.toString().replace(/ /g, '') : cell._value;

                if (column_index == 3) {
                    row[properties[column_index]] = parseFloat(row[properties[column_index]]).toFixed(2);
                }

            }
        }

        if (errors === 5) { row.estatus = -1; }
        return row;
    }

    validationsRow = (row, byLoyaltyAccount) => {
        let results = [];
        results.push(this.validationsIdentifier(row.Identifier, byLoyaltyAccount));//0

        results.push(this.validationsDate(row.StarDate));//1
        results.push(this.validationsDate(row.EndDate, true));//2
        results.push(this.validationsDeposit(row.Deposit));//3
        results.push(this.validationsReason(row.ReasonDeposit));//4

        if (results[1].is_valid === true && results[2].is_valid === true) {
            row.StarDate = results[1].date.format("DD/MM/YYYY");
            row.EndDate = results[2].date.format("DD/MM/YYYY");
            if (results[2].date.isBefore(results[1].date)) {
                row.estatus += ` La FechaInicio no debe ser mayor o igual a la FechaFinal`;
                row.is_valid = false;
            }
        }

        for (let index = 0; index < results.length; index++) {
            const result = results[index];
            if (result.is_valid === false) {
                row.estatus += ` ${result.error}`;
                row.is_valid = false;
            }
        }

        return row;
    }

    //////</ROWS>/////////
    validationsIdentifier = (Identifier, byLoyaltyAccount) => {
        const regex = /\d+$/;

        if (typeof Identifier === "undefined" || Identifier.toString().replace(/ /g, '') === '' || Identifier.toString().match(regex) === null) {
            return { error: `El campo CuentaLealtad/Cliente no debe estar vacío y debe ser un valor tipo numérico`, is_valid: false }
        }

        if (typeof Identifier !== 'number' || Identifier <= 0) {
            return { error: ` El campo CuentaLealtad/Cliente debe ser un valor tipo numérico y mayor a cero.`, is_valid: false }
        }


        if (byLoyaltyAccount) {
            if (this.validationsLoyaltyAccount(Identifier) === false) {
                return { error: `El campo CuentaLealtad no valida: formato [ 12419xxxxxxxxxx] donde x es un numero`, is_valid: false }
            };
        }
        return { is_valid_row: true };
    }

    validationsDate = (date, is_date_end = false) => {
        const text_date = is_date_end === false ? "FechaInicial" : "FechaFinal";
        const current_date = moment();
        const regex = /(?:0[1-9]|[12][0-9]|3[01])\/(?:0[1-9]|1[0-2])\/(?:19|20\d{2})/;
        /*if(date){
            console.log(date)
            const splitDate = date.split("/")
            if(splitDate.length > 1){
                let checkYear = splitDate[2].length;
                let checkDay = splitDate[0].length;  

                if (checkYear !== 4 || checkDay !== 2) {
                    return { error: ` | El campo ${text_date} no debe estar vacío y debe ser un formato de fecha dd/mm/yyyy.`, is_valid: false };
                }

            }
        }*/

        //console.log(date)
        //let dateConverted = new Date((date - (25567 + 1))*86400*1000);
        //console.log(dateConverted)
        

        if (typeof date === "undefined" || date.toString().replace(/ /g, '') === '') {
            return { error: ` | El campo ${text_date} no debe estar vacío y debe ser un formato de fecha dd/mm/yyyy.`, is_valid: false };
        }

        //console.log(date)
        const checkDateFormat = (date) => {
            const moment_date = moment(XlsxPopulate.numberToDate(date));
            if (moment_date.isValid()) {
                //console.log(moment_date)
                return (moment_date);
            } else if (date.toString().match(regex) !== null) {
                //console.log(moment(date, 'DD/MM/YYYY')._i)
                return (moment(date, 'DD/MM/YYYY'));
            } else {
                return (false);
            }
        };


        //console.log(date)
        date = checkDateFormat(date);
        //console.log(date)
        if(date){
            if(typeof date._i.length == "undefined"){
                date._i.length = 10;
            }
        }
        
        //console.log(date._i.length)

        if (date === false || date._i.length !== 10) {
            return { error: ` | El campo ${text_date} no debe estar vacío y debe ser un formato de fecha dd/mm/yyyy.`, is_valid: false };
        }

        if (date.isBefore(current_date)) {
            return { error: `| El campo de ${text_date} debe ser mayor al día de hoy`, is_valid: false }
        }

        return { is_valid: true, date: date };
    }


    validationsDeposit = (Deposit) => {
        const regex = /\d+$/;
        if (typeof Deposit === "undefined" || Deposit.toString().replace(/ /g, '') === '' || Deposit.toString().match(regex) === null) {
            return { error: `| El campo de monto no debe estar vacío y/o debe ser un valor numérico.`, is_valid: false };
        }

        if (parseFloat(Deposit).toFixed(2) <= 0) {
            return { error: `| El monto debe ser un valor tipo numérico y mayor de cero.`, is_valid: false }
        }

        return { is_valid: true }
    }

    validationsReason = (ReasonDeposit) => {
        if (typeof ReasonDeposit === "undefined" || ReasonDeposit.toString().replace(/ /g, '') === '') {
            return { error: `| El  motivo no debe estar vacio.`, is_valid: false };
        }

        if (typeof ReasonDeposit !== 'string') {
            return { error: `| El motivo debe ser un valor tipo texto`, is_valid: false }
        }

        const validRegEx = /[A-Za-zñÑáéíóúÁÉÍÓÚ]{4,30}/g
        if (ReasonDeposit.match(validRegEx) === null || ReasonDeposit.length > 30) {
            return { error: ` | El motivo debe contener mínimo 4 letras y no ser mayor a 30`, is_valid: false };
        }

        return { is_valid: true };
    }

    downloadCheckedFile = (workbook, file) => {
        workbook.outputAsync()
            .then(function (blob) {
                file = file.split(".");
                const file_name = file[0];
                const extension = file[1];
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, `${file_name}_revisado.${extension}`);
                } else {
                    let temporary_url_download = window.URL.createObjectURL(blob);
                    let link_download_file = document.createElement("a");
                    document.body.appendChild(link_download_file);
                    link_download_file.href = temporary_url_download;
                    link_download_file.download = `${file_name}_revisado.${extension}`;
                    link_download_file.click();
                    window.URL.revokeObjectURL(temporary_url_download);
                    document.body.removeChild(link_download_file);
                }
            });
    }

    validateExtensionFile = (extension) => {
        const EXTENSIONS_ALLOWED = ["XLS", "XLSX"];
        for (let index = 0; index < EXTENSIONS_ALLOWED.length; index++) {
            const ext = EXTENSIONS_ALLOWED[index];
            if (extension === ext) {
                return true;
            }
        }
        return false;
    }


    /**
     * handleSelectedFile
     * @param {Object}
     */
    handleSelectedFile = (e) => {
        let files = e.target.files
        let file = files[0]
        e.target.value = "";
        if (typeof file === 'undefined') {
            e.target.value = null;
            return false;
        }
        const EXTENSION = (file.name.split('.')[1]).toUpperCase();

        if (this.validateExtensionFile(EXTENSION) === false) {
            toast.error("Es necesario seleccionar un archivo validado.");
            e.target.value = null;
            return false;
        }

        // TODO validar la extension del archivo usando substring [.xls, .xlsx]
        XlsxPopulate.fromDataAsync(file)
            .then((workbook) => {
                const sheet = workbook.sheet(0).name('Lista Revisada');
                const validation = this.validateFile(sheet);
                let state = this.state
                if (validation.is_valid_file === false) {
                    this.downloadCheckedFile(workbook, file.name);    
                    state.rows = "";
                    state.isValidFile = false;
                    state.fileName = "Archivo";
                } else {
                    state.rows = validation.rows;
                    state.isValidFile = true;
                    state.fileName = file.name;
                }
                
                this.setState(state)
            })

        e.target.value = null;

    }

    /// NEW FUNCTIONS////////
    validate = () => {
        if (this.state.rows === '') {
            toast.error("Es necesario seleccionar un archivo validado.");
            return false;
        }
        return true;
    }

    render() {
        let accountOptions = [{ name: "Por número de cliente" }, { name: "Por cuenta lealtad" }];
        return (
            <div>
                <div className="columns is-multiline">
                    <div className="column is-full">
                        <div className="FieldHorizontal field is-horizontal">
                            <div className="field-label">
                                <label className="label">Cargar archivo</label>
                            </div>

                            <div className="field-body">
                                <div className="field">
                                    <div className="file is-info has-name">
                                        <label className="file-label">
                                            <input
                                                className="input file-input"
                                                id="file"
                                                type="file"
                                                name="ClientList"
                                                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                name="ExcelFile"
                                                onChange={(e) => this.handleSelectedFile(e)}
                                            />
                                            <span className="file-cta">
                                                <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo .xls o .xlsx</i></span>
                                            </span>
                                            <span className="file-name">{this.state.fileName}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-full">
                        <div className="FieldHorizontal field is-horizontal">
                            <div className="field-label">
                                <label className="label">Archivo valido</label>
                            </div>

                            <div className="field-body">
                                <div className="field">
                                    <div className="file is-info has-name">
                                        <label className="file-label">
                                            {this.state.isValidFile ? "Archivo validado correctamente" : "Es necesario cargar un archivo válido"}
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="column is half">
                        <div className="has-text-right buttons-modal">
                            <button
                                disabled={!this.state.isValidFile}
                                className="button is-success is-full"
                                onClick={() => {
                                    if (this.validate()) {
                                        let { save, ...saldo } = { ...this.state };
                                        this.props.save(saldo.rows, this.state.accountSelection === 1);
                                    }else{

                                    }
                                }}>
                                Guardar
                            </button>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}