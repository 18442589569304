import React, { Component } from "react";
import  NoItems  from "../util/noitems/NoItems";
import Pagination from '../util/pagination/Pagination';
import { toast } from 'react-toastify';
import ComunicadosVPAPI from "../../services/ComunicadosVP";
import ErrorMessages from '../../util/validation-error-messages';
import update from 'immutability-helper';
import AcceptModal from '../util/acceptModal/AcceptModal';
import { format } from 'date-fns';
import  SearchForm  from "../util/searchform/SearchForm";
import Select from 'react-select';
import { debounce } from "lodash";
import Loading from '../util/loading/Loading';

class ComunicadosVP extends Component {
    styleAlert = {
        "display": "flex",
        "justifyContent": "flex-end",
        "alignItems": "center"
    }
    Comunicados = [];

    state={
        isLoading: false,
        pageComunicados: [],
        Comunicados: [],
        ComunicadosFilter: [],
        ComunicadosFilterBack: [],
        proyeccion: null,
        showModal: false,
        showModalRemoveComunicado: false,
        comunicadoToEdit: {},
        messageRemove: "¿Está seguro que desea eliminar el comunicado?",
        filterText: "",
        caffenios: [],
        caffeniosFilter: [],
        optionDrive: [],
        selectedOptionDrive: [],
        optionEstatus: [],
        selectedOptionEstatus: [],
    }

    componentDidMount(){
        this.setState({isLoading: true}, () => {
            ComunicadosVPAPI.GetActiveCaffenios().then(resDrives =>{
                let state = {...this.state}
                state.caffenios = resDrives.data;
                state.caffeniosFilter = resDrives.data;
                state.optionDrive = this.select_Drive(state.caffenios);
                state.optionEstatus = this.select_Estatus(ComunicadosVPAPI.Estatus);
                this.setState(state, ()=> this.filterProcess());
            })
            .catch(err => {
                this.setState({isLoading: false})
                console.log("Error Drives >> ", err);
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        });
    }

    select_Drive(caffenios) { // Llenar el combo 'Drives' con todas las opciones 
        var newArray = [];
        let OptionSelectDrive = [];
        var lookupObject  = {};
        caffenios.map((item) => {
            OptionSelectDrive.push({value:item.idCaffenio,label:item.NombreNuevo});
        });

        for(var i in OptionSelectDrive) {
            lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
        }
    
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    changeSelectDrive = (obj) =>{
        let state = {...this.state};
        state["selectedOptionDrive"] = obj;
        this.setState(state, () => this.debouncedFilterProcess());
    }

    select_Estatus(estatus) { // Llenar el combo 'Estatus' con todas las opciones 
        var newArray = [];

        estatus.map((item) => {
            newArray.push(item);
        });

        return newArray;
    }

    changeSelectEstatus = (obj) =>{
        let state = {...this.state};
        state["selectedOptionEstatus"] = obj;
        this.setState(state, () => this.debouncedFilterProcess());
    }

    create = () => {        
        this.props.history.push({pathname: '/comunicado/nuevo', state:{ 
            comunicadoList: this.state.Comunicados,
            isEditing : false
        }});
    }

    editComunicado = (comunicado) =>{
        this.props.history.push({pathname: '/comunicado/nuevo', state:{ 
            comunicadoList: this.state.Comunicados,
            comunicado: comunicado,
            isEditing : true
        }});
    }

    /**
     * Función que muestra el modal de confirmación para remover un comunicado de la lista
     * @param comunicado comunicado que se quiere eliminar de la lista
     */
    toggleModalRemoveComunicado = (comunicado) => {
        let msg = ComunicadosVPAPI.GetEstatus(comunicado) == "Vigente" ? "El comunicado se encuentra vigente, este se dejará de publicar y se eliminará del listado de comunicados. ¿Está seguro que desea eliminarlo?" : "El comunicado se eliminará del listado de comunicados. ¿Está seguro que desea eliminarlo?";
        let newState = update(this.state, {
            showModalRemoveComunicado: {$set: true},
            comunicadoToEdit: {$set: comunicado},
            messageRemove : {$set: msg}
        })
        this.setState(newState);
    }

    /**
     * Funcion que cierra el modal de confirmación para eliminar un comunicado de la lista
     */
     closeModalRemoveComunicado = () => {
        let newState = update(this.state, {
            showModalRemoveComunicado: {$set: false},
        })
        this.setState(newState);
    }

    /**
    * Funcion que elimina un elemento de la lista de comunicados
    */
    removeComunicadoFromList = () => {
        this.setState({isLoading: true, showModalRemoveComunicado: false }, () => {
            this.state.comunicadoToEdit.Eliminado = true;
            this.state.comunicadoToEdit.FechaInicio = new Date(this.state.comunicadoToEdit.FechaInicio);
            this.state.comunicadoToEdit.FechaFin = new Date(this.state.comunicadoToEdit.FechaFin);
            this.state.comunicadoToEdit.Posicion = this.state.comunicadoToEdit.Posicion.value;
            ComunicadosVPAPI.UpdateEncComunicado(this.state.comunicadoToEdit, null).then( response => {
                let state = {...this.state};
                state.isLoading = false;
                state.ComunicadosFilter = this.state.ComunicadosFilter.filter( d => {
                    return d.IdComunicado !== this.state.comunicadoToEdit.IdComunicado
                });
                state.ComunicadosFilterBack = this.state.ComunicadosFilterBack.filter( d => {
                    return d.IdComunicado !== this.state.comunicadoToEdit.IdComunicado
                });
                state.Comunicados = this.state.Comunicados.filter( d => {
                    return d.IdComunicado !== this.state.comunicadoToEdit.IdComunicado
                });
        
                this.setState(state, () => {
                    toast.success(`Comunicado eliminado con éxito.`);
                });

            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        });
    }

    pauseComunicadoFromList = () => {
        this.setState({isLoading: true}, () => {
            let comunicadoEdit = {...this.state.comunicadoToEdit}
            comunicadoEdit.Pausado = true;
            comunicadoEdit.FechaInicio = new Date(comunicadoEdit.FechaInicio);
            comunicadoEdit.FechaFin = new Date(comunicadoEdit.FechaFin);
            comunicadoEdit.Posicion = comunicadoEdit.Posicion.value;
            ComunicadosVPAPI.UpdateEncComunicado(comunicadoEdit, null).then( response => {
                let state = {...this.state};
                state.isLoading = false;
                
                state.ComunicadosFilter[state.ComunicadosFilter.findIndex(x => x.IdComunicado == this.state.comunicadoToEdit.IdComunicado)].Pausado = 1;
                state.ComunicadosFilterBack[state.ComunicadosFilterBack.findIndex(x => x.IdComunicado == this.state.comunicadoToEdit.IdComunicado)].Pausado = 1; 
                state.Comunicados[state.Comunicados.findIndex(x => x.IdComunicado == this.state.comunicadoToEdit.IdComunicado)].Pausado = 1; 
                this.setState(state, () => {
                    toast.success(`El comunicado se pausó.`);
                });
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        });
    }

    activateComunicadoFromList = () => {
        this.setState({isLoading: true}, () => {
            let comunicadoEdit = {...this.state.comunicadoToEdit}
            comunicadoEdit.Pausado = false;
            comunicadoEdit.FechaInicio = new Date(comunicadoEdit.FechaInicio);
            comunicadoEdit.FechaFin = new Date(comunicadoEdit.FechaFin);
            comunicadoEdit.Posicion = comunicadoEdit.Posicion.value;

            ComunicadosVPAPI.UpdateEncComunicado(comunicadoEdit, null).then( response => {
                let state = {...this.state};
                state.isLoading = false;
                
                state.ComunicadosFilter[state.ComunicadosFilter.findIndex(x => x.IdComunicado == this.state.comunicadoToEdit.IdComunicado)].Pausado = 0;
                state.ComunicadosFilterBack[state.ComunicadosFilterBack.findIndex(x => x.IdComunicado == this.state.comunicadoToEdit.IdComunicado)].Pausado = 0; 
                state.Comunicados[state.Comunicados.findIndex(x => x.IdComunicado == this.state.comunicadoToEdit.IdComunicado)].Pausado = 0; 
                this.setState(state, () => {
                    toast.success(`El comunicado se reactivó.`);
                });
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        });
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let state = {...this.state};
        state.filterText = text;
        this.setState(state, () => this.debouncedFilterProcess());
    }

    debouncedFilterProcess = debounce(fun => this.filterProcess(), 1000);

    filterProcess = () => {
        console.log("filtrando...")

        let driveIds = this.state["selectedOptionDrive"].map(obj => {
            return obj.value;
        });

        let estatusList = this.state["selectedOptionEstatus"].map(obj => {
            return obj.label;
        });

        this.setState({isLoading:true}, ()=>{
            ComunicadosVPAPI.GetComunicadosData(this.state.filterText,estatusList,driveIds).then(response => {
                let state = {...this.state};
                state.Comunicados = response.data;
                state.Comunicados.forEach( x =>{
                    x.FechaInicio =  format(Date.parse(x.FechaInicio),"yyyy/MM/dd");//ComunicadosVPAPI.FormatDate(x.FechaInicio);
                    x.FechaFin = format(Date.parse(x.FechaFin),"yyyy/MM/dd");//ComunicadosVPAPI.FormatDate(x.FechaFin);
                    x.Posicion = ComunicadosVPAPI.GetPosiciones().find( y => y.value == x.Posicion);
                    x.Estatus = ComunicadosVPAPI.GetEstatus(x);
                })
                state.ComunicadosFilter = response.data;
                state.ComunicadosFilterBack = response.data;
                console.log("Resultado obtener comunicados state",state);
                // console.log("Resultado drives > ", resDrives);
                state.isLoading = false;
                this.setState(state)
            }).catch(err => {
                this.setState({isLoading: false});
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        });
    }


    render(){
        return(
            <div>
                <h1 className="title is-2">Comunicados</h1>
                <div className="columns is-multiline">
                    <div className="column is-12" style={this.styleAlert}>
                        <div className="card" style={{"marginRight": "5px", "flexGrow": "1"}}>
                            <div className="card-content is-warning" style={{"backgroundColor": "#ffdd57", "padding" : ".5rem"}}>
                                <span className="icon is-small">
                                    <i className="fa fa-exclamation-triangle"></i>
                                </span>
                                <b>Importante</b>: Cualquier cambio realizado se verá reflejado en la pantalla de producción (<b>VP</b>) en aproximadamente 2 horas.
                            </div>
                        </div>
                        <a className="button is-success" onClick={(e) => this.create()}>
                            <span className="icon is-small">
                                <i className="fa fa-plus"></i>
                            </span>
                            <span>Agregar</span>
                        </a>

                    </div>
                </div>
                <div className="columns is-multiline">
                    <div className="column is-4">
                        <label className="label">Título</label>
                        <div className="control">
                            <SearchForm
                                filterText={this.state.filterText}
                                onInput={(t) => this.handleSearchInput(t)}
                                itemName={'Comunicado'}
                                placeholderText = {"Ingrese título del comunicado"}
                            />
                        </div>
                    </div>
                    <div className="column is-4">
                        <label className="label">Estatus</label>
                        <div className="control">
                            <Select
                                isMulti
                                placeholder={"Seleccionar..."}
                                name={"selectedOptionEstatus"}
                                value={this.state.selectedOptionEstatus}
                                onChange={(e) => this.changeSelectEstatus(e)}
                                noOptionsMessage={() => "No hay opciones"}
                                options={this.state.optionEstatus}
                            />
                        </div>
                    </div>
                    <div className="column is-4">
                        <label className="label">Drives</label>
                        <div className="control">
                            <Select
                                isMulti
                                placeholder={"Seleccionar..."}
                                name={"selectedOptionDrive"}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={this.state.selectedOptionDrive}
                                onChange={(e) => this.changeSelectDrive(e)}
                                noOptionsMessage={() => "No hay opciones"}
                                options={this.state.optionDrive}
                            />
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-content">
                    {this.state.ComunicadosFilter.length !== 0 
                        ? 
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Título</th>
                                    <th>Fecha inicio</th>
                                    <th>Fecha fin</th>
                                    <th>Estatus</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.pageComunicados.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            
                                            <td>{item.Titulo}</td>

                                            <td>{ComunicadosVPAPI.FormatDate(item.FechaInicio)}</td>

                                            <td>{ComunicadosVPAPI.FormatDate(item.FechaFin)}</td>

                                            <td>{ComunicadosVPAPI.GetEstatus(item)}</td>

                                            <td>
                                                    <button className="button is-small tableAction is-success tooltip" data-tooltip="Editar" 
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            console.log(item);
                                                            this.editComunicado(item);
                                                        }}
                                                    >
                                                        <span className="icon">
                                                            <i className="fa fa-edit" />
                                                        </span>
                                                    </button>
                                                    {(ComunicadosVPAPI.GetEstatus(item) == "Vigente" && item.Pausado == 0) &&
                                                        <button className="button is-small tableAction is-warning tooltip" data-tooltip="Pausar comunicado" 
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                console.log(item);
                                                                let newState = update(this.state, {
                                                                    comunicadoToEdit: {$set: item}
                                                                })
                                                                this.setState(newState, () =>{
                                                                    this.pauseComunicadoFromList();
                                                                });
                                                            }}
                                                        >
                                                            <span className="icon">
                                                                <i className="fa fa-pause" />
                                                            </span>
                                                        </button>

                                                    }
                                                    {(ComunicadosVPAPI.GetEstatus(item) == "Vigente" && item.Pausado == 1) &&
                                                        <button className="button is-small tableAction is-info tooltip" data-tooltip="Reactivar comunicado" 
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                console.log(item);
                                                                let newState = update(this.state, {
                                                                    comunicadoToEdit: {$set: item}
                                                                })
                                                                this.setState(newState, () =>{
                                                                    this.activateComunicadoFromList();
                                                                });
                                                            }}
                                                        >
                                                            <span className="icon">
                                                                <i className="fa fa-play" />
                                                            </span>
                                                        </button>

                                                    }
                                                    <button className="button is-small tableAction is-danger tooltip" data-tooltip="Eliminar comunicado" 
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            console.log(item);
                                                            this.toggleModalRemoveComunicado(item);
                                                        }}
                                                    >
                                                        <span className="icon">
                                                            <i className="fa fa-trash" />
                                                        </span>
                                                    </button>

                                            </td>
                                            
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table> 

                        : <NoItems itemName="comunicados"/>}
                        <Pagination items={this.state.ComunicadosFilter} onChangePage={(comunicados) => this.setState({ pageComunicados: comunicados })} /*onChangePage={(items) => this.handlePageChange(items)}*/ />
                        
                        {(this.state.showModalRemoveComunicado) &&
                            <AcceptModal 
                            modalTitle="Confirmar"
                            modalBody={<p>{this.state.messageRemove}</p>}
                            isActive={this.state.showModalRemoveComunicado}
                            willSave={true}
                            done={() => this.removeComunicadoFromList()}
                            cancel={() => this.closeModalRemoveComunicado()}
                            />
                        }
                    </div>
                </div>
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
            </div>

        );
    }

}

export default ComunicadosVP;