import React, { Component } from 'react';
import ErrorMessages from '../../util/validation-error-messages';
import Field from '../util/field/Field';
import DatePicker, {registerLocale} from 'react-datepicker';
import es from 'date-fns/locale/es';

import Select from 'react-select';
import ExpresionesRegulares from '../../util/expresiones-regulares';
import ReactTooltip from 'react-tooltip'

import { Formik } from 'formik';
import * as Yup from 'yup';


registerLocale("es", es); 
class BasePromo extends Component {

    constructor(props) {
        super(props);
        
        if(!isNaN(props.values.idTipoPromo)){
            this.props.values.idTipoPromo = this.props.listTipoPromos.filter(item => item.value === this.props.values.idTipoPromo)[0]
        }
        this.state = this.props
        this.disableCheck = this.props.values.idTipoPromo && this.props.values.idTipoPromo.label == 'Reembolso' ? true : false
    }

    
    VALIDATION_SCHEMA = Yup.object({        
        Name: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoConCaracteres, { message: ErrorMessages.AlfanumericoConCaracteresError })
            .min(3, "Nombre POS debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .test("validate-name", "El Nombre POS ya existe, favor de ingresar otro.", (value) => {
                let isValid = true;
                //ToDo: Validar que no exista
                return isValid;
            })
            .required("Campo Nombre POS es requerido."),
        ShortName: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoConCaracteres, { message: ErrorMessages.AlfanumericoConCaracteresError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .test("validate-name", "El Nombre ya existe, favor de ingresar otro.", (value) => {
                let isValid = true;
                //ToDo: Validar que no exista
                return isValid;
            })
            .required("Campo Nombre es requerido."),
        Description: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoConCaracteres, { message: ErrorMessages.AlfanumericoConCaracteresError })
            .min(1, "description debe tener más de un caracter.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .required("Campo description es requerido."),
        InitDate: Yup.string()
            .required("Campo Fecha lanzamiento es requerido.").nullable(),
        EndDate: Yup.string()
            .required("Campo Fecha fin es requerido.").nullable(),
        idTipoPromo: Yup.string().required("Campo Tipo de promoción es requerido.").nullable(),
        Auto: Yup.bool()
    });

    getMinDate = (initDate) => {
        let copyDate = new Date(initDate.valueOf()) 
        return copyDate.setDate(copyDate.getDate() + 1)
    }

    render() {
        return (

            <Formik
                enableReinitialize={false}
                initialValues={this.state.values}
                validationSchema={this.VALIDATION_SCHEMA}
                onSubmit={(values) => { 
                    let formatValues = {...values};
                    formatValues.Name = values.Name.trim();
                    formatValues.ShortName = values.ShortName.trim();
                    this.state.setValues(formatValues);
                    this.state.handleNextStep()        ;                      
                }}>

                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }) => (

                <form onSubmit={handleSubmit} id="frmBase" className="has-text-left" style={{ width: '100%' }}>
                <div className='form-group'>                    
                    <div className='row'>
                        <div className='columns'>
                            <div className='column is-6'>
                                <Field
                                    label={<div>
                                            Nombre POS
                                            <i data-tip data-for="NameTip" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                                            </div>}                            
                                    alingLefth = {true}
                                    hasError={errors.Name && touched.Name}
                                    msgError={errors.Name && touched.Name ? errors.Name : ""}
                                    showLeftSpaces={true}
                                    childInfo={values.Name && !errors.Name
                                        ? `Te quedan ${50 - values.Name.trim().length} caracteres para el nombre.`
                                        : ""}
                                >
                                    
                                    <input
                                        id="txtNamePOS"
                                        name={"Name"}
                                        className={`input ${errors.Name && touched.Name ? "is-danger" : ""}`}
                                        placeholder={"Nombre de la promoción que aparece en POS"}
                                        maxLength="50"
                                        onChange={(val) =>{
                                            setFieldValue('Name', val.target.value)
                                            setFieldValue('Description', val.target.value)
                                            }
                                        }
                                        onBlur={handleBlur}
                                        value={values.Name}

                                    />                                    
                                </Field>
                                
                                <ReactTooltip id="NameTip" place="top" effect="solid">
                                    Nombre que aparece en los botones del POS para las operadoras
                                </ReactTooltip>                            
                            </div>
                            <div className='column is-6'>
                                <Field 
                                    label={<div>
                                        Nombre ticket
                                        <i data-tip data-for="ShortNameTip" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                                        </div>}   
                                    alingLefth = {true}                                                                  
                                    hasError={errors.ShortName && touched.ShortName}
                                    msgError={errors.ShortName && touched.ShortName ? errors.ShortName : ""}
                                    showLeftSpaces={true}
                                    childInfo={values.ShortName && !errors.ShortName ? `Te quedan ${50 - values.ShortName.trim().length} caracteres para el nombre de la promoción.` : ""}
                                >
                                    <input
                                        id="txtShortName"
                                        name={"ShortName"}                                        
                                        className={`input ${errors.ShortName && touched.ShortName ? "is-danger" : ""}`}
                                        placeholder={"Nombre de la promoción"}
                                        maxLength="50"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ShortName}

                                    />
                                </Field>
                                <ReactTooltip id="ShortNameTip" place="top" effect="solid">
                                    Nombre que aparece en el ticket
                                </ReactTooltip>                              
                            </div>                        
                        </div>
                    </div>                    
                    
                    <div className='row'>
                        <div className='columns'>
                            <div className='column is-6'>

                                <Field label="Fecha lanzamiento" 
                                    alingLefth = {true}
                                    hasError={errors.InitDate === ""  || touched.InitDate}                   
                                    msgError={errors.InitDate || touched.InitDate ? errors.InitDate : ""}
                                    showLeftSpaces={true}
                                >
                                    <DatePicker
                                        id={"dtInitDate"}
                                        name={"InitDate"}
                                        selected={values.InitDate ? new Date(values.InitDate): ''}
                                        onChange={date => {
                                             setFieldValue("InitDate", date)
                                             if(!date){ setFieldValue('EndDate', '')}

                                            }}
                                        popperPlacement="bottom"
                                        locale="es"
                                        todayButton="Hoy"
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        className="input"
                                        autoComplete={"off"}
                                        isClearable={true}
                                        showTimeSelect
                                        placeholderText="dd/MM/yyyy HH:mm"
                                        timeCaption='Hora'
                                    />
                                    
                                </Field> 
                            </div>
                            <div className='column is-6'>
                                <Field label="Fecha fin"  
                                    alingLefth = {true}  
                                    hasError={errors.EndDate === ""  || touched.EndDate}                           
                                    msgError={errors.EndDate || touched.EndDate ? errors.EndDate : ""}
                                    showLeftSpaces={true}
                                >
                                    <DatePicker
                                        id={"dtEndDate"}
                                        name={"EndDate"}
                                        selected={values.EndDate ?  new Date(values.EndDate) : ''}
                                        onChange={date => setFieldValue("EndDate", date)}
                                        popperPlacement="bottom"
                                        locale="es"
                                        todayButton="Hoy"
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        className="input"
                                        autoComplete={"off"}
                                        isClearable={true}
                                        showTimeSelect
                                        placeholderText="dd/MM/yyyy HH:mm"
                                        disabled={!values.InitDate}
                                        minDate={values.InitDate ? this.getMinDate(values.InitDate) : values.InitDate}                                      
                                        // minDate={new Date(values.InitDate)}
                                        timeCaption='Hora'
                                    />
                                    
                                </Field> 
                            </div>
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='columns'>
                            <div className='column is-6'>

                                <label className='label'><div>
                                Tipo promoción
                                        <i data-tip data-for="idTipoPromoTip" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                                        </div></label>
                                <div className={'control'} >
                                    <Select
                                        id="idTipoPromo"
                                        name="idTipoPromo"
                                        value={values.idTipoPromo}
                                        placeholder="Seleccionar..."
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={this.state.listTipoPromos}
                                        onChange={(val) => {
                                            if(val.label == 'Reembolso'){
                                                setFieldValue('disableCheck', true)
                                                setFieldValue('Auto', true)
                                            }else{
                                                setFieldValue('disableCheck', false)
                                            }
                                                
                                            this.state.changeTypePromo()
                                            setFieldValue('idTipoPromo', val)
                                        }}
                                        onBlur={() => setFieldTouched('idTipoPromo', true)}
                                    />
                                    <div className="is-centered">
                                        {(touched.idTipoPromo) &&
                                            <div style={{ 'fontSize': '12px', 'color': '#cc0b19' }}>
                                                {errors.idTipoPromo ? errors.idTipoPromo : ""}
                                            </div>
                                        }
                                    </div>
                                </div> 
                                
                                <ReactTooltip id="idTipoPromoTip" place="top" effect="solid">
                                    Define el tipo de promoción. 
                                </ReactTooltip> 
                            </div>
                            <div className='column is-6'>
                                <Field label={<div>
                                    Promoción automática
                                        <i data-tip data-for="autoTip" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                                        </div>} isVertical={true}  
                                    alingLefth = {true} >
                                    <input
                                        className='switch'
                                        type='checkbox'
                                        checked={ values.Auto}
                                        
                                        name='auto'
                                        onChange={() => {}}
                                    />
                                    <label
                                        htmlFor='Promoción automática'
                                        
                                        onClick={ () => {
                                            if( !values.disableCheck)
                                                setFieldValue('Auto', !values.Auto)
                                        }}
                                    />
                                </Field>

                                <ReactTooltip id="autoTip" place="top" effect="solid">
                                    Si la promoción cumple las restricciones establecidas <br />
                                    se aplicará automáticamente. 
                                </ReactTooltip>
                                
                            </div>
                        </div>
                    </div>
                      
                    <br/>
                   
                            <div className='row' style={{ padding: '10px' }}>
                                <div className='columns'>
                                    <div className='column is-12'>
                                        {/* <div className="has-text-left buttons-modal" id="stepActions">
                                <div className="pull-left">
                                    <button id="btnBackStepRecipesConfig" type="button" className="button is-light" onClick={() => this.props.back()} >Anterior</button>

                                </div>
                            </div> */}
                                        <div className="has-text-right buttons-modal" id="stepActions">
                                            <div className="pull-right">
                                                <button className='button is-success' type="submit">
                                                    Continuar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                    
                
                </div>
                </form>
                
                )}
            </Formik>
        );
    }
}


export default BasePromo;
