import React from "react";
import "./BtnModoGuiado.css"
import {Link} from 'react-router-dom'

class BtnModoGuiado extends React.Component {
    constructor() {
        super();
        this.state = {
            display: null,
            ultimaRuta: null
        }
    }

    componentDidMount() {
        this.setState({
            display: localStorage.getItem("modoGuiado"),
            ultimaRuta: localStorage.getItem("ultimaRuta")
        })
    }

    handleClickExit() {
        localStorage.removeItem("modoGuiado");
        localStorage.removeItem("tipoModoGuiado");

        // Esconder los componentes del modo guiado al presionar la x
        //
        let divContenedor = document.getElementsByClassName("componenteModoGuiado");
        for(var i = 0 ; i < divContenedor.length ; i++) {
            divContenedor[i].style.display = "none";
        }
    }

    render() {
        if(this.state.display === 'true') {
            return (
                <div className="componenteModoGuiado">
                    <Link to={this.state.ultimaRuta}>
                        <a href="#" className="float" title="Modo guiado activado">
                            <i className="fa fa-thumb-tack my-float"></i>
                        </a>
                    </Link>
                    

                    <a href="#" className="float2" title="Salir del modo guiado" onClick={this.handleClickExit}>
                        <i className="fa fa-times  my-float"></i>
                    </a>
                </div>
            )
        }else {
            return(null)
        }
    }
}

export default BtnModoGuiado;