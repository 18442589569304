import React, { Component } from 'react'
import PropTypes from 'prop-types';

export default class FieldAddons extends Component {

    static propTypes = {
        isHorizontal: PropTypes.bool,
        label: PropTypes.string,
        showTooltip: PropTypes.bool,
        textTooltip: PropTypes.string,
        addons: PropTypes.arrayOf(PropTypes.object)
    }

    static defaultProps = {
        isHorizontal: false,
        label: "",
        showTooltip: false,
        textTooltip: '',
        addons: [{}]
    }

    render() {  
        return (
            <div className={`field ${this.props.addons.length > 0 ? "has-addons" : ""} ${this.props.isHorizontal ? "FieldHorizontal is-horizontal" : "has-text-centered"} ${this.props.showTooltip ? 'tooltip' : '' }  `} data-tooltip= {this.props.textTooltip}>      
                {this.props.label !== "" &&
                    
                    <div className={`${this.props.isHorizontal ? "field-label" : ""}`}>
                        <label className="label">{this.props.label}</label>
                    </div>
                }
                
                <p className="is-expanded control">
                        {this.props.children}
                </p>
                <p className="control">
                    <a className="button is-static">
                        <span className="icon">
                            <i className="fa fa-search" />
                        </span>
                    </a>
                </p>
                
            </div>
        )
    }
}
