import React, { useEffect, useState } from 'react';

// Components
import Modal from '../util/modal/Modal';
import Select from 'react-select';

import NoItems from '../util/noitems/NoItems';
import Loader from '../util/loading/Loading';
import { toast } from 'react-toastify';

// Services
import PlazasAPI from '../../services/Plazas';
import Pagination from '../util/pagination/Pagination';


/**
 * 
 * @param {Object} userInfo - Datos del usuario seleccionado 
 * @param {Array} listPlazas - Lista de plazas
 * @param {Function} onClose - Cierre del modal
 * @param {Function} onSave - Guardado de la info
 * @returns Component
 */
export const ModalEditUsuario = ({
    userInfo = {},
    onClose,
    onSave
}) => {

    const [ state, setState ] = useState({
        listPlazas: [],
        initialListPlazas: [],
        userPlazas: [],
        pageUserPlazas: [],
        listPlazasSelected: [],
        pagePlazasSelected: [],
        isLoading: false
    });

    useEffect(() => {
        setState({ ...state, isLoading: true });
        let userPlazas = [];
        let listPlazas = [];
        let tempListPlazas = [];

        PlazasAPI.getUserPlazas( userInfo.UserId )
        .then(res => {
            tempListPlazas = res.data.map(pla => pla.idPlaza);
            userPlazas = res.data;
            return PlazasAPI.getUserPlazas( userInfo.UserId, true );
        }).then(res => {
            let tempPlazas = res.data.map( item => {
                return {
                    value: item.idPlaza,
                    label: item.Nombre
                }
            });
            listPlazas = tempPlazas.filter(pla => !tempListPlazas.includes(pla.value));
        }).catch( err => {
            let message = 'Ocurrio un error';
            try {
                message = err.response.data.Message;
            } catch (error) {
                message = 'Ocurrio un error al obtener a los empleados';
            } finally {
                toast.error(message);
            }
        })
        .finally(() => setState({
            ...state,
            userPlazas,
            pageUserPlazas: userPlazas,
            isLoading: false,
            listPlazas: listPlazas,
            initialListPlazas: listPlazas
        }));
    }, []);

    /**
     * Guarda la información con las plazas asignadas.
     */
    const handleSaveInfo = () => {
        if (
            state.listPlazasSelected === null ||
            !state.listPlazasSelected
        )
            return toast.warn("No se han asignado plazas.");
        const data = {
            UserId: userInfo.UserId,
            Plazas: state.listPlazasSelected.map(pla => pla.value)
        }
        setState({ ...state, isLoading: true });
        PlazasAPI.updateUserPlazas( data )
            .then(res => {
                setState({...state, isLoading: false});
                onSave( res.data );
            })
            .catch(() => toast.error('Ocurrio un error al guardar la información'));
    }

    /**
     * Manejo de la plaza seleccionada.
     * @param {Object} items - Plaza seleccionada.
     */
    const handleChangePlaza = items => {

        state.listPlazasSelected = [];
        let listTempPlazas = state.listPlazas;

        items.map((item) =>
            {
                if ( !state.listPlazasSelected.find( pla => pla.value === item.value ) ) {
                    listTempPlazas = listTempPlazas.filter( pla => pla.value !== item.value );
                    state.listPlazasSelected = [ ...state.listPlazasSelected, item ];
                }
            }
        );

        setState({
            ...state,
            listPlazas: listTempPlazas,
            listPlazasSelected: state.listPlazasSelected
        });
        
    }

    /**
     * Elimina la relacion de usuario plaza.
     * @param { Number } idPlaza - Id de la plaza a eliminar.
     * @param { Boolean } notAsignated - Lista de no asignadas.
     */
    const handleDeletePlaza = ( idPlaza, notAsignated = false ) => {
        if ( notAsignated ) {
            let finalListPlazas = state.listPlazasSelected.filter( pla => pla.value !== idPlaza);
            let listTempPlazas = state.initialListPlazas.find( pla => pla.value === idPlaza );
            let plazas = [];
            if ( listTempPlazas )
                plazas = [ { ...listTempPlazas }, ...state.listPlazas ];
            else
                plazas = [ ...state.listPlazas ];
            setState({
                ...state,
                listPlazasSelected: finalListPlazas,
                listPlazas: plazas
            });
        } else {
            setState({ ...state, isLoading: true });
            let listPlazas = [ ...state.userPlazas ];
            const data = {
                UserId: userInfo.UserId,
                PlazaId: idPlaza
            }
            PlazasAPI.deleteUserPlaza( data )
                .then(() => {
                    listPlazas = state.userPlazas.filter( pla => pla.idPlaza !== idPlaza )
                    toast.success("Información de plazas actualizada");
                })
                .catch(() => toast.error('Ocurrio un error al guardar la información'))
                .finally(() => setState({
                    ...state,
                    isLoading: false,
                    userPlazas: listPlazas
                }));
        }

    }

    return (
        <Modal
            showModal={true}
            modalTitle="Editar Usuario"
            width="50%"
            cancel={() => onClose()}
            >
            { state.isLoading &&
                <Loader
                    isFullscreen={true}
                    isLoading={state.isLoading}
                    width='100px'
                    height='100px'
                />
            }
            <div className='row'>
                <div className='columns'>
                    <div className='column is-4'>
                        <label className='label'>Nombre</label>
                        <p>{`${userInfo.Name} ${userInfo.LastName}`}</p>
                    </div>
                    <div className='column is-4'>
                        <label className='label'>Correo</label>
                        <p>{userInfo.Email}</p>
                    </div>
                    <div className='column is-4'>
                        <label className='label'>Rol</label>
                        <p>{userInfo.Roles ? userInfo.Roles : 'Sin Roles'}</p>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='columns'>
                    <div className='column is-4'>
                        <label className='label'>Plazas para asignar</label>
                        <Select
                            placeholder={"Seleccionar plazas"}
                            isMulti
                            name={"selectedOption"}
                            onChange={item => handleChangePlaza(item)}
                            noOptionsMessage={() => "No hay opciones"}
                            options={ state.listPlazas }
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='columns'>
                    <div className='column is-4'>
                    <label className='label'>Plazas asignadas</label>
                    </div>
                </div>
            </div>
            <br />
            <div className='row'>
                
                {/* Tabla de Usuarios */}
                <div className='card'>
                    <div className='card-content'>
                    {
                        state.pageUserPlazas.length !== 0 ?
                        <table className='table is-fullwidth'>
                            <thead>
                                <tr>
                                    <th style={{width: "80%"}}>Plaza</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    state.pageUserPlazas.map(plaza =>
                                        <tr key={ plaza.idPlaza }>
                                            <td>{`${plaza.Nombre}` }</td>
                                            <td>
                                            <button className="button is-small tableAction is-danger tooltip is-tooltip-left" data-tooltip="Eliminar plaza" onClick={ () => handleDeletePlaza( plaza.idPlaza ) }>
                                                <span className="icon is-small">
                                                    <i className="fa fa-trash" />
                                                </span>
                                            </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        :  <NoItems itemName="plazas" />
                    } 
                    <Pagination
                        items={ state.userPlazas }
                        pageSize={ 5 }
                        onChangePage={ userPlazas => setState({ ...state, pageUserPlazas: userPlazas })}
                    />
                    
                    </div>
                </div>                        
            
            </div>
            <div className='row'>
            </div>
            <br />
            <div className='row'>
                <div className='columns'>
                    <div className='column is-12 is-flex' style={{justifyContent: 'flex-end'}}>
                        <button
                            onClick={ onClose }
                            className='button is-danger'
                            style={{ marginRight: 20 }}
                        >
                            Cerrar
                        </button>
                        <button
                            onClick={ handleSaveInfo }
                            className='button is-success'
                            disabled={ state.listPlazasSelected.length === 0 }
                        >
                            Aplicar Asignación
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
};