import React, { Component } from 'react';
import Field from '../../util/field/Field';
import ErrorMessages from '../../../util/validation-error-messages';
import ExpresionesRegulares from '../../../util/expresiones-regulares';
import '../../cssComponents.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import lodash from 'lodash';
import Select from 'react-select';
import EmpresasAPI from '../../../services/CaffenioEmpresas/Empresas';
import { toast } from 'react-toastify';
import ClasificacionesAPI from '../../../services/Classification';
import Loading from '../../util/loading/Loading';

const SUPPORTED_FORMATS = [
    "image/png",
];


class ClassificationForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            classification: { ...this.props.classification },
            isEditing: this.props.isEditing,
            fileName: "Archivo"
        }
    }

    componentWillMount() {
        this.setState({ isLoading: true }, () => {
            let tempSegmentsList = []
            EmpresasAPI.getAllFilterSegments().then(response => {
                response.data.map(obj => {
                    tempSegmentsList.push(obj)
                })
                return ClasificacionesAPI.getAllClasificacionGrupo()
            })
            .then(response => {
                let clasificacionesGruposAux = []
                response.data.map(obj => {
                    if(this.state.classification.idClasificacionGrupo === obj.idClasificacionGrupo){
                        this.state.classification.clasificacionGrupo = {value: obj.idClasificacionGrupo, label: obj.Nombre}
                    }
                    clasificacionesGruposAux.push({value: obj.idClasificacionGrupo, label: obj.Nombre})
                })
                this.setState({ segments: tempSegmentsList, clasificacionesGrupos: clasificacionesGruposAux, isLoading: false });
            })
            .catch(err => {
                this.setState({ isLoading: false })
                if (err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            })
        })
    }

    /**Obtiene la posición máxima para recomendar la siguiente */
    getNextMaxPosition = () => {
        let maxPosition = lodash.maxBy(this.props.classifications, function (o) { return o.Position; });

        return maxPosition ? maxPosition.Position : 1;
    }

    VALIDATION_SCHEMA = Yup.object({
        Name: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .required("Campo Nombre es requerido."),
            // .test("validate-name", "El Nombre de Familia ya existe, favor de ingresar otro.", (value) => {
            //     let isValid = true;
            //     let name = value ? value.toLowerCase().trim() : "";
            //     if (this.props.classifications.some(u => u.Name.toLowerCase().trim() === name && u.ClassificationId !== this.state.classification.ClassificationId)) {
            //         isValid = false;
            //     }
            //     return isValid;
            // }),
        ShortName: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre Corto debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .required("Campo Nombre Corto es requerido."),
            // .test("validate-shorname", "El Nombre Corto de Familia ya existe, favor de ingresar otro.", (value) => {
            //     let isValid = true;
            //     let shortName = value ? value.toLowerCase().trim() : "";
            //     if (this.props.classifications.some(u => u.ShortName.toLowerCase().trim() === shortName && u.ClassificationId !== this.state.classification.ClassificationId)) {
            //         isValid = false;
            //     }
            //     return isValid;
            // }),
        Position: Yup.number()
            .min(1, "Ingrese valores mayores a 0.")
            .max(999, "Ha excedido el límite de caracteres.")
            .required("Campo Posición es requerido."),
        Image: Yup.mixed()
            .required("Campo Imagen es requerido.")
            .test(
                "fileFormat",
                "Solo se permite formato .PNG",
                value => value && SUPPORTED_FORMATS.includes(value.type)  || (this.state.classification.UrlImage !== null && this.state.classification.UrlImage.length > 0)
                
            ),
        SegmentationId: Yup.string()
        .when([], {
            is: () => !this.state.isEditing,
            then: Yup.string().required("Segmentación es requerido.").nullable(),
            otherwise: Yup.string().notRequired(),
        }),
    });

    /**
     * Previene el submit al dar enter
     * @param {*} keyEvent 
     */
    onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          keyEvent.preventDefault();
        }
    }


    render() {
        return (
            <>
            <Formik
                initialValues={this.state.classification}
                validationSchema={this.VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    let formatValues = {...values}
                    formatValues.Name = formatValues.Name.trim();
                    formatValues.ShortName = formatValues.ShortName.trim();
                    this.props.save(formatValues)
                }}
            >
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }) => (
                    <form onSubmit={handleSubmit} id={"frmClassifications"} onKeyDown={this.onKeyDown}>
                        {!this.state.isEditing &&
                            <Field
                                label="Segmentación"
                                hasError={errors.SegmentationId || touched.SegmentationId}
                                msgError={errors.SegmentationId || touched.SegmentationId ? errors.SegmentationId : ""}
                                isHorizontal
                            >
                                <div className="control" style={{ width: "100%" }}>
                                    <Select
                                        id="ddlSegmentation"
                                        name={"SegmentationId"}
                                        value={values.SegmentationId}
                                        placeholder={"Seleccionar..."}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={(val) => {
                                            setFieldValue('SegmentationId', val)
                                        }}
                                        onBlur={() => setFieldTouched('SegmentationId', true)}
                                        options={this.state.segments}
                                        getOptionLabel={(objSegment) => {
                                            switch (objSegment.Name) {
                                                case "Andatti drive":
                                                    return "Andatti"
                                                case "Drive":
                                                    return "Caffenio"
                                                case "momento espresso":
                                                    return "Momento Espresso"
                                                default:
                                                    return objSegment.Name
                                            }
                                        }}

                                        getOptionValue={(objSegment) => objSegment.SegmentId}
                                    />
                                </div>
                            </Field>
                        }
                        <br />
                        <Field
                            label="Nombre"
                            isHorizontal
                            hasError={errors.Name && touched.Name}
                            msgError={errors.Name && touched.Name ? errors.Name : ""}
                            showLeftSpaces={true}
                            childInfo={values.Name && !errors.Name
                                ? `Te quedan ${50 - values.Name.trim().length} caracteres para el nombre.`
                                : ""}
                        >
                            <input
                                id="txtNameClasificacion"
                                name={"Name"}
                                className={`input ${errors.Name && touched.Name ? "is-danger" : ""}`}
                                placeholder={"Ingrese nombre de Familia"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Name}

                            />
                        </Field>
                        <br />
                        <Field

                            label="Nombre corto"
                            isHorizontal
                            hasError={errors.ShortName && touched.ShortName}
                            msgError={errors.ShortName && touched.ShortName ? errors.ShortName : ""}
                            showLeftSpaces={true}
                            childInfo={values.ShortName && !errors.ShortName
                                ? `Te quedan ${50 - values.ShortName.trim().length} caracteres para el nombre corto.`
                                : ""}
                        >
                            <input
                                id={"txtShorNameClasification"}
                                name={"ShortName"}
                                className={`input ${errors.ShortName && touched.ShortName ? "is-danger" : ""}`}
                                placeholder={"Ingrese nombre corto de Familia"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ShortName}

                            />
                        </Field>
                        <br />
                        <Field

                            isHorizontal
                            label={"Posición en Menú"}
                            hasError={errors.Position || touched.Position}
                            msgError={errors.Position || touched.Position ? errors.Position : ""}
                        >
                            <input
                                id={"txtPositionClasificacion"}
                                className={`input ${errors.Position && touched.Position ? "is-danger" : ""}`}
                                type="number"
                                label="Posición en Menú"
                                name="Position"
                                value={values.Position}
                                onChange={handleChange}                               
                                onKeyDown={event => { 
                                    if(event.keyCode === 69 || 
                                        event.keyCode === 109 || 
                                        event.keyCode === 110 || 
                                        event.keyCode === 107 ||
                                        event.keyCode === 189 ||
                                        event.keyCode === 187||
                                        event.keyCode === 190
                                        ){
                                        event.preventDefault();
                                        setFieldValue("Position", values.Position);
                                        return false
                                    }else{
                                        return true 
                                    }
                                    }}                             
                                onBlur={handleBlur}
                                placeholder={`La última posición registrada es ${this.getNextMaxPosition()}`}
                            />
                        </Field>
                        <br />
                        <Field
                            label="Clasificación grupo"
                            isHorizontal
                        >
                            <div className="control" style={{ width: "100%" }}>
                                <Select
                                    isClearable
                                    id="ddlClasificacionGrupo"
                                    name={"ClasificacionGrupo"}
                                    value={values.clasificacionGrupo}
                                    placeholder={"Seleccionar..."}
                                    noOptionsMessage={() => "No hay opciones"}
                                    onChange={(val) => {
                                        setFieldValue('clasificacionGrupo', val)
                                    }}
                                    onBlur={() => setFieldTouched('ClasificacionGrupo', true)}
                                    options={this.state.clasificacionesGrupos}
                                />
                            </div>
                        </Field>
                        <br />
                        <Field label="Imagen" isHorizontal
                            hasError={errors.Image && touched.Image}
                            msgError={errors.Image && touched.Image ? errors.Image : ""}>
                            <div className={`file ${errors.Image && touched.Image ? "is-danger" : "is-info"} has-name`}>
                                <label className="file-label">
                                    <input
                                        className={`input file-input ${errors.Image && touched.Image ? "is-danger" : ""}`}
                                        id="fileClasification"
                                        type="file"
                                        name="Image"
                                        accept="image/x-png"
                                        name="Image"
                                        onChange={(e) => {
                                            let files = e.target.files
                                            let file = files[0]
                                            e.stopPropagation();
                                            e.target.value = null;
                                            if (file) {
                                                let itemClasificacion = {...this.state.classification}
                                                itemClasificacion.UrlImage = "";
                                                this.setState({
                                                    classification: itemClasificacion
                                                }, () => {
                                                    setFieldValue('Image', file);
                                                    setFieldValue('UrlImage', "")
                                                    var reader = new FileReader();
                                                    reader.onload = function (e) {
                                                        let previewControl = document.getElementById("imgPreview");
                                                        if(previewControl){
                                                            document.getElementById("imgPreview").setAttribute('src', e.target.result);
                                                        }                                                    
                                                    }
                                                    reader.readAsDataURL(file);
                                                })  
                                            } else {                  
                                                setFieldValue('UrlImage', "")
                                                setFieldValue('Image', null)                                                
                                            }
                                        }}
                                        onBlur={(e)=>{handleBlur(e)}}
                                    />
                                    <span className="file-cta">
                                        <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo .PNG</i></span>
                                    </span>
                                    <span className="file-name">{values.Image == null ? "Archivo" : values.Image.name}</span>
                                </label>
                            </div>
                            {values.Image !== null &&                           
                                <Field>
                                    <button className="button tableAction is-danger trash-right"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.target.value = null;
                                            let itemClasificacion = {...this.state.classification}
                                            itemClasificacion.UrlImage = "";
                                            itemClasificacion.Image = null;
                                            this.setState({ classification : itemClasificacion }, () => {                                                
                                                setFieldValue('Image', null) 
                                                setFieldValue('UrlImage', "")   
                                            });
                                        }}>
                                        <span className="icon">
                                            <i className="fa fa-trash" />
                                        </span>
                                    </button>
                                    
                                </Field>
                            }
                        </Field>
                        {values.Image !== null 
                        && (values.UrlImage === null || values.UrlImage.length === 0) 
                        && <Field>
                            <div className="column">
                                <figure className="image container-image " >
                                    <img id={"imgPreview"} 
                                    src="#"
                                    style={{ width: 'auto', height: '50px' }} 
                                    alt="" />
                                </figure>
                            </div>
                        </Field>                        
                            
                        }
                        {values.UrlImage && <Field>
                                <div className="column">
                                    <figure className="image container-image ">
                                        <img src={values.UrlImage.toString()+"?"+new Date().getTime()} 
                                        style={{ width: 'auto', height: '50px' }} 
                                        alt="" />
                                    </figure>
                                </div>
                            </Field>}

                        <div className="has-text-right buttons-modal">
                            <div className="column">
                                <button id={"btnSubmitClasification"} 
                                type="submit" className="button is-success">
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                )}

            </Formik>
            
            <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
            
            </>
        );
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
ClassificationForm.defaultProps = {
    isEditing: false,
    classification: {
        ShortName: "",
        Name: "",
        Position: "",
        IVAApply: true,
        Image: null,
        Background: null,
        Icon: null,
        RecipeName: "",
        UrlImage:null
    },
    segments: []
}

export default ClassificationForm;
