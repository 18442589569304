import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
});

/**
 * Servicio para el recurso de empleados
 */
export default class EmpleadosAPI {
    /**
     * Obtiene a los empleados por rol de empleado que se da.
     * @param {Number} idCategoriaEmp - Categoria de empleado.
     * @returns Promise
     */
    static getEmpleados(idCategoriaEmp) {
        return middleware.hasAuth(() => request.get(`/Empleados/${idCategoriaEmp}/`));
    }
}