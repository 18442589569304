import { apiUrl }  from "../../constants";
import axios from 'axios';
import middleware from '../../middleware/auth';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class MenuAppAPI {
    

    static getClasificacionesMenu() {
        return middleware.hasAuth(() => request.get(`/Classification/Order/`));
    }

    static saveMenuOrder(classifications) {
        return middleware.hasAuth(() => request.post(`/Classification/Order/`, classifications));
    }

    static getClasificacionApp(id) {
        return middleware.hasAuth(() => request.get(`/Classification/Order/${id}`));
    }

    static updateClasificacionApp(id, data) {
        let fd = new FormData();

        fd.append("Name", data.Name);
        fd.append("DescriptionApp", data.DescriptionApp);
        fd.append("ClassificationId", data.ClassificationId);
        fd.append("Featured", data.Featured);

        if (data.IconFile){
            fd.append("Icon", data.IconFile);
        }else{
            fd.append("Icon", data.Icon);
        }

        if (data.ImageFile){
            fd.append("Image", data.ImageFile);
        }else{
            fd.append("Image", data.Image);
        }
        console.log(data);
        console.log(fd)

        return middleware.hasAuth(() => request.put(`/Classification/Order/${id}`, fd));
    }

    static featureClasificacionApp(classificationId, Featured) {
        let body = {ClassificationId: classificationId, Featured: Featured};
        return middleware.hasAuth(() => request.patch(`/Classification/Featured/${classificationId}`, body));
    }
}