import React, { Component } from 'react';
import Field from '../../util/field/Field';
import Dropdown from '../../util/dropdown/Dropdown';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { value } from 'popmotion';

const SUPPORTED_FORMATS = [
    "image/png"
];
/**
 * Formulario para cargar y validar PT asignado a artículo inventario
 */
class InventoryItemImageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventoryItem: { ...this.props.inventoryItem },
            isEditing: false,
            isLoading: false
        }
    }    

    VALIDATION_SCHEMA_WITH_TOPPING = Yup.object({        
        Image: Yup.mixed()
            .required("El campo Icono/Imagen es requerido.")            
            .test(
                "fileFormat",
                "Solo se permite formato png.",
                value => value && SUPPORTED_FORMATS.includes(value.type) 
                    || (this.state.inventoryItem.URLimagen !== null && this.state.inventoryItem.URLimagen.length > 0)
            )
    });

    render() {
        return (
            <Formik
                initialValues={this.state.inventoryItem}
                validationSchema={ this.VALIDATION_SCHEMA_WITH_TOPPING }
                onSubmit={(values) => {
                    this.props.save(values)
                }}>
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                    <form onSubmit={handleSubmit} id="frmImageInventoryItem">  
                            <div className="columns is-multiline">
                                <div className="column is-12">
                                    <Field label="Icono/Imagen" isHorizontal
                                        hasError={errors.Image || touched.Image}
                                        msgError={errors.Image || touched.Image ? errors.Image : ""}
                                    >
                                        <div className={`file ${errors.Image && touched.Image ? "is-danger" : "is-info"} has-name`}>
                                            <label className="file-label">
                                                <input
                                                    className={`input file-input ${errors.Image && touched.Image ? "is-danger" : ""}`}
                                                    id="fileInventpryItem"
                                                    type="file"
                                                    name="Image"
                                                    accept="image/x-png"
                                                    name="Image"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        let files = e.target.files
                                                        let file = files[0]
                                                        e.stopPropagation();
                                                        e.target.value = null;
                                                        if (file) {
                                                            setFieldValue('Image', file);
                                                            setFieldValue('URLimagen', "");                                                          
                                                            var reader = new FileReader();
                                                            reader.readAsDataURL(file);
                                                            var reader = new FileReader();
                                                            reader.onload = function (e) {
                                                                let previewControl = document.getElementById("imgPreview");
                                                                if (previewControl) {
                                                                    document.getElementById("imgPreview").setAttribute('src', e.target.result);
                                                                }
                                                            }
                                                            reader.readAsDataURL(file);

                                                        } else {
                                                            setFieldValue('URLimagen', "")
                                                            setFieldValue('Image', null)

                                                        }
                                                    }}
                                                />
                                                <span className="file-cta">
                                                    <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo .PNG</i></span>
                                                </span>
                                                <span className="file-name">{values.Image == null ? "Archivo" : values.Image.name}</span>
                                            </label>
                                        </div>
                                        {values.Image !== null &&
                                            <Field>
                                                <button className="button tableAction is-danger trash-right"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.target.value = null;
                                                        setFieldValue('Image', null)
                                                        setFieldValue('URLimagen', "")                                                        
                                                    }
                                                    }>
                                                    <span className="icon">
                                                        <i className="fa fa-trash" />
                                                    </span>
                                                </button>

                                            </Field>
                                        }
                                    </Field>


                                </div>



                            </div>
                        
                        <br />

                        {values.Image !== null
                            //&& (values.URLimagen && (values.URLimagen === null || values.URLimagen.length === 0))
                            && <Field>
                                <div className="column">
                                    <figure className="image container-image " >
                                        <img id={"imgPreview"}
                                            src="#"
                                            style={{ width: 'auto', height: '50px' }}
                                            alt="" />
                                    </figure>
                                </div>
                            </Field>

                        }
                        {values.URLimagen && <Field>
                            <div className="column">
                                <figure className="image container-image ">
                                    <img src={values.URLimagen.toString() + "?" + new Date().getTime()}
                                        style={{ width: 'auto', height: '50px' }}
                                        alt="" />
                                </figure>
                            </div>
                        </Field>}
                        <div className="has-text-left buttons-modal" id="stepActions">
                            <div className="pull-left">
                                <button id="btnBackStepTopping" type="button" className="button is-light" onClick={() => this.props.back()} >Cancelar</button>

                            </div>
                        </div>
                        <div className="has-text-right buttons-modal" id="stepActions">
                            <div className="pull-right">
                                <button id="btnSaveInventoryItem" type="submit" className="button is-success"  >Guardar</button>
                            </div>
                        </div>

                    </form>
                )}

            </Formik>

        )
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
InventoryItemImageForm.defaultProps = {
    isEditing: false,
    inventoryItem: {
        CodeProvider: "",
        InventoryCode: "",
        Name: "",
        ShortName: "",
        UnitPurchase: 0,
        StorageUnit: 0,
        UnitSale: 0,
        ConvFactorBuy: "",
        ConvFactorSale: "",
        FactorBuyMin: 0.95,
        AmountMin: 0,
        AmountMax: 0,
        InventoryTypeId: 0,
        CodeSat: null,
        IsTopping: false,
        ToppingPrice: 0,
        ToppingWeight: 0,
        Image:null,
        URLimagen: ""
    }
}

export default InventoryItemImageForm;