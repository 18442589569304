import React from 'react';

//Componente para manejo de paginación de lado del Servidor.
class ServerPagination2 extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            totalPages: 0,
            currentPage: 0
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.currentPage !== this.state.currentPage || this.props.totalPages !== this.state.totalPages){
            this.setState({currentPage: this.props.currentPage, totalPages: this.props.totalPages});
        }
    }

    render(){
        if(this.state.totalPages <= 1) return null;

        return (
            <div>
                <nav class="pagination is-centered" role="navigation" aria-label="pagination">
                    <a disabled={this.state.currentPage === 1} onClick={() => {
                        if(this.state.currentPage !== 1) this.props.goToPage(this.state.currentPage - 1)
                        }} class="pagination-previous">Anterior</a>
                    <a disabled={this.state.currentPage === this.state.totalPages} onClick={() => {
                        if (this.state.currentPage !== this.state.totalPages)
                            this.props.goToPage(this.state.currentPage + 1)
                        }} class="pagination-next">Siguiente</a>
                    <ul class="pagination-list">
                        {this.state.currentPage > 1 && <li><a onClick={() => this.props.goToPage(1)} class="pagination-link" aria-label="Goto page 1">1</a></li>}
                        {this.state.currentPage - 2 > 1 && <li><span class="pagination-ellipsis">&hellip;</span></li>}
                        {this.state.currentPage > 1 && this.state.currentPage - 1 != 1 && <li><a onClick={() => this.props.goToPage(this.state.currentPage - 1)} class="pagination-link" aria-label="Goto page 45">{this.state.currentPage - 1}</a></li>}
                        <li><a class="pagination-link is-current has-background-primary has-border-primary" aria-label="Page 46" aria-current="page">{this.state.currentPage}</a></li>
                        {this.state.currentPage < this.state.totalPages && this.state.currentPage + 1 != this.state.totalPages && <li><a onClick={() => this.props.goToPage(this.state.currentPage + 1)} class="pagination-link" aria-label="Goto page 47">{this.state.currentPage + 1}</a></li>}
                        {this.state.currentPage+2 < this.state.totalPages && <li><span class="pagination-ellipsis">&hellip;</span></li>}
                        {this.state.currentPage < this.state.totalPages && <li><a onClick={() => this.props.goToPage(this.state.totalPages)} class="pagination-link" aria-label="Goto last page">{this.state.totalPages}</a></li>}
                    </ul>
                </nav>
            </div>
        )
    }
}

export default ServerPagination2;