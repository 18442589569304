import React, { Component } from 'react';
import { toast } from 'react-toastify';
import TextField from '../util/textfield/TextField';
import Field from '../util/field/Field';
import Loading from '../util/loading/Loading';
import ErrorMessages from '../../util/validation-error-messages';
import ExpresionesRegulares from '../../util/expresiones-regulares';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
// import lodash from 'lodash';
// import Select from 'react-select';
import update from 'immutability-helper';
import  TableTemplate  from "../util/tabletemplate/TableTemplate";
import Modal from '../util/modal/Modal';
import AcceptModal from '../util/acceptModal/AcceptModal';
import  NoItems  from "../util/noitems/NoItems";
import Pagination from '../util/pagination/Pagination';
import DrivesProyeccion from './DrivesProyeccion';

class ProyeccionForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            proyeccion: { ...this.props.proyeccion },
            isLoading: false,
            displayColorPickerFondo: false,
            displayColorPickerLetra: false,

            pageDrives: [],
            showModalEditDrives: false,
            showModalRemoveDrive: false,
            driveToRemove: {},

            showModalDrive: false,
        }
    }

    componentDidMount() {
        // console.log("Proyeccion en form >> ", this.props);
    }

    handleClickPicker = (opc) => {
        console.log("La opcion >> ", opc);
        if(opc == 1){
            this.setState({ displayColorPickerFondo: !this.state.displayColorPickerFondo })
        }
        else
        {
            this.setState({ displayColorPickerLetra: !this.state.displayColorPickerLetra })
        }
    };
    
    handleClosePicker = (opc) => {
        console.log("La opcion close >> ", opc);
        if(opc == 1){
            this.setState({ displayColorPickerFondo: false })
        }
        else
        {
            this.setState({ displayColorPickerLetra: false })
        }
    };
    
    handleChangePickerFondo = (color) => {
        let state = {...this.state}
        state.proyeccion.Color = color.hex;
        this.setState(state);
    };

    handleChangePickerLetra = (color) => {
        let state = {...this.state}
        state.proyeccion.ColorLetra = color.hex;
        this.setState(state);
    };

    /**
     * Función que se ejecuta para mostrar el modal para agregar o eliminar drives a incluir
     *  @param e event
     */
    toggleEditDrivesModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("SE EJECUTA EL ABRIR");
        let newState = update(this.state, {
            showModalEditDrives: {$set: true},
            showModalDrive: {$set: true}
        });
        this.setState(newState);
    }

    /**
     * Funcion que muestra el modal de confirmación para remover una receta de la lista de recetas a excluir
     * @param drive drive que se quiere eliminar de la lista
     */
     toggleModalRemoveDrive = (drive) => {
        let newState = update(this.state, {
            showModalRemoveDrive: {$set: true},
            driveToRemove: {$set: drive}
        })
        this.setState(newState);
    }

    /**
    * Funcion que elimina un elemento de la lista de drives a incluir
    */
    removeDriveFromList = () => {
        let state = {...this.state};
        let _drives = this.state.proyeccion.Drives.filter( d => {
            return d.idCaffenio !== this.state.driveToRemove.idCaffenio
        });

        state.proyeccion.Drives = _drives;
        state.showModalRemoveDrive = false;
        this.setState(state, () => {
            toast.success(`Drive eliminado con éxito.`);
        });
    }

    /**
     * Funcion que cierra el modal de confirmación para eliminar un drive de la lista
     */
     closeModalRemoveDrive = () => {
        let newState = update(this.state, {
            showModalRemoveDrive: {$set: false},
        })
        this.setState(newState);
    }

    /**
     * Funcion que actualiza los elementos que se muestran en la paginación de los drives a incluir
     * @param drives arreglo con drives de la página actual
     */
     changeDrivePage = (drives) => {
        this.setState({pageDrives: [...drives]});
    }

    /**
     * Función que se ejecuta para mostrar el modal para agregar/eliminar drives de la
     * lista de drives a incluir
     */
     cancelDrivesModal = () => {
        let newState = update(this.state, {
            showModalDrive: {$set: false},
            showModalEditDrives: {$set: false},
        });
        this.setState(newState);
    }

    /**
     * Funcion que guarda los drives que se quieren incluir
     * @param checkedDrives drives que fueron seleccionadas
     */

    saveDrives = (checkedDrives) => {
        let state = {...this.state};
        state.showModalDrive = false;
        state.showModalEditDrives = false;
        state.proyeccion.Drives = checkedDrives;
        this.setState(state, () => {
            toast.success(`Los drives se actualizaron`);
        });
    }

    // Solo permite números, sin signos ni exponenciales
    // onKeyPressNumber(event) {
    //     const keyCode = event.keyCode || event.which;
    //     const keyValue = String.fromCharCode(keyCode);
    //     if (/\+|-|e|E|\./.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
    //     event.preventDefault();
    //     event.stopPropagation();
    // }

    VALIDATION_SCHEMA = Yup.object({
        Nombre: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(150, "Ha excedido el límite de 150 caracteres.")
            .required("Campo Nombre es requerido.")
            .test("validate-name", "El nombre ya existe, favor de ingresar otro.", (value) => {
                let isValid = true;
                let name = value ? value.toLowerCase().trim() : "";
                if (this.props.proyeccionList.some(u => u.Nombre.toLowerCase().trim() === name && u.IdProduccionAdelantado !== this.state.proyeccion.IdProduccionAdelantado)) {
                    isValid = false;
                }
                return isValid;
            }),
        NombreCorto: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .required("Campo Nombre Corto es requerido.")
            .test("validate-name", "El nombre corto ya existe, favor de ingresar otro.", (value) => {
                let isValid = true;
                let name = value ? value.toLowerCase().trim() : "";
                if (this.props.proyeccionList.some(u => u.NombreCorto.toLowerCase().trim() === name && u.IdProduccionAdelantado !== this.state.proyeccion.IdProduccionAdelantado)) {
                    isValid = false;
                }
                return isValid;
            }),
        Orden: Yup.number().positive("Introduzca un número mayor a cero").required("Introduzca un número."),
        // Drives: Yup.array(),
        Activo: Yup.bool(),
    });

    

    render() {
        const styles = reactCSS({
            'default': {
              color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `${ this.state.proyeccion.Color }`,
              },
              colorLetra: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `${ this.state.proyeccion.ColorLetra }`,
              },
              swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
              },
              popover: {
                position: 'absolute',
                zIndex: '2',
              },
              cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
              },
            },
        });

        return (
            <div>
                <Formik
                    initialValues={this.state.proyeccion}
                    validationSchema={this.VALIDATION_SCHEMA}
                    onSubmit={(values) => {
                        let formatValues = { ...values }
                        formatValues.Nombre = formatValues.Nombre.trim();
                        formatValues.NombreCorto = formatValues.NombreCorto.trim();
                        formatValues.Drives = this.state.proyeccion.Drives;
                        formatValues.Color = this.state.proyeccion.Color;
                        formatValues.ColorLetra = this.state.proyeccion.ColorLetra;
                        this.props.save(formatValues);
                    }}
                >
                    {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }) => (
                        <form onSubmit={handleSubmit} id={"frmProyeccion"}>
                            <Field
                                isHorizontal
                                hasError={errors.Nombre && touched.Nombre}
                                msgError={errors.Nombre && touched.Nombre ? errors.Nombre : ""}
                                showLeftSpaces={true}
                                childInfo={values.Nombre && !errors.Nombre
                                    ? `Te quedan ${150 - values.Nombre.trim().length} caracteres para el nombre.`
                                    : ""}
                            >
                                <TextField
                                    autoFocus={true}
                                    required
                                    maxLength="150"
                                    label="Nombre"
                                    name="Nombre"
                                    value={values.Nombre}
                                    placeholder={"Ingrese nombre de la proyección"}
                                    onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Field>
                            <br />
                            <Field
                                isHorizontal
                                hasError={errors.NombreCorto && touched.NombreCorto}
                                msgError={errors.NombreCorto && touched.NombreCorto ? errors.NombreCorto : ""}
                                showLeftSpaces={true}
                                childInfo={values.NombreCorto && !errors.NombreCorto
                                    ? `Te quedan ${50 - values.NombreCorto.trim().length} caracteres para el nombre corto.`
                                    : ""}
                            >
                                <TextField
                                    required
                                    maxLength="150"
                                    label="Nombre corto"
                                    name="NombreCorto"
                                    value={values.NombreCorto}
                                    placeholder={"Ingrese nombre corto de la proyección"}
                                    onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Field>                 
                            <br />
                            <Field 
                                isHorizontal
                                showLeftSpaces={true}
                                hasError={errors.Orden && touched.Orden}
                                msgError={errors.Orden && touched.Orden ? errors.Orden: ""}
                                label="Orden"
                            >
                                <input
                                    className="input"
                                    type="number"
                                    minLength="1"
                                    min="1"
                                    maxLength="3"
                                    max="999"
                                    label="Orden"
                                    name="Orden"
                                    value={values.Orden}
                                    // onKeyPress={this.onKeyPressNumber}
                                    onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                    onChange={handleChange}
                                />
                            </Field>
                            <br />
                            <Field label="Color fondo" isHorizontal>
                            <div style={ styles.swatch } onClick={ (e) => { e.stopPropagation(); this.handleClickPicker(1);} }>
                                <div style={ styles.color } />
                            </div>
                            { this.state.displayColorPickerFondo ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ (e) => {e.stopPropagation(); this.handleClosePicker(1);} }/>
                            <SketchPicker color={ this.state.proyeccion.Color } onChange={ this.handleChangePickerFondo } />
                            </div> : null }

                            </Field>

                            <br />
                            <Field label="Color letra" isHorizontal>
                            <div style={ styles.swatch } onClick={ (e) => {e.stopPropagation(); this.handleClickPicker(2);} }>
                                <div style={ styles.colorLetra } />
                            </div>
                            { this.state.displayColorPickerLetra ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ (e) => {e.stopPropagation(); this.handleClosePicker(2);} }/>
                            <SketchPicker color={ this.state.proyeccion.ColorLetra } onChange={ this.handleChangePickerLetra } />
                            </div> : null }

                            </Field>

                            <br />
                            <Field label="Activo" isHorizontal>
                                <input
                                    type="checkbox"
                                    name="activo"
                                    className="switch"
                                    checked={values.Activo}
                                    onChange={(e) => { }}
                                />
                                <label htmlFor="iva" onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setFieldValue("Activo", !values.Activo)
                                }} />
                            </Field>

                            <br />
                            
                            {/* 
                                Drives
                            */}

                            <div className="columns is-multiline">
                                <div className="column is-12">

                                    <p className="card-header-title has-text-white has-background-primary title is-4">Drives a incluir</p>
                                    <div className="has-text-left">
                                            <button 
                                                type='button'
                                                id='btn-edit-drives'
                                                className="button is-info"
                                                onClick={this.toggleEditDrivesModal}>
                                                Editar drives
                                            </button>    
                                    </div>

                                    <br />

                                    <div className="card">
                                        <div className="card-header is-primary">
                                            <div className="column is-12">
                                                {/* 
                                                    TABLA DE Drives
                                                */}

                                                {(this.state.proyeccion.Drives.length !== 0) 
                                                ? <TableTemplate
                                                        columns={["idCaffenio", "NombreNuevo"]}
                                                        columnsNames={["Id", "Nombre"]}
                                                        
                                                        data={this.state.pageDrives}
                                                        //data={props.formulaData.Recetas}
                                                        //checkbox={true}
                                                        //Controlar checkboxes 
                                                        showView={(index) => {}}
                                                        //checkAll={this.state.checkAll}
                                                        //Controlador del checkbox del header
                                                        //selectAllCheck={this.selectAllCheck}
                                                        rowClickable={false}
                                                        extraButtons={(drive) => {
                                                            return <button type='button' className="button tableAction is-danger tooltip" data-tooltip="Eliminar drive"
                                                            onClick={(e) => {e.preventDefault(); this.toggleModalRemoveDrive(drive);}}
                                                            >
                                                                <span className="icon">
                                                                    <i className="fa fa-trash" />
                                                                </span>
                                                            </button>
                                                        }}
                                                /> 
                                                
                                                : <NoItems itemName="drives"/>}

                                                {(this.state.showModalRemoveDrive) &&
                                                    <AcceptModal 
                                                    modalTitle="Confirmar"
                                                    modalBody={<p>¿Está seguro que desea eliminar el drive de la lista a incluir?</p>}
                                                    isActive={this.state.showModalRemoveDrive}
                                                    willSave={true}
                                                    done={() => this.removeDriveFromList()}
                                                    cancel={() => this.closeModalRemoveDrive()}
                                                    />
                                                }  

                                                <Pagination items={this.state.proyeccion.Drives} pageSize={5} keepPage={true} onChangePage={(drives) => this.changeDrivePage(drives)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="has-text-right buttons-modal">
                                <div className="column">
                                    <button type="submit" className="button is-success" >
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
                {/* MODAL DE RECETAS */}

                {this.state.showModalEditDrives && this.state.showModalDrive && 
                    <Modal
                        showModal={this.state.showModalDrive}
                        modalTitle={"Agregar/Eliminar drives"}
                        width="90%"
                        cancel = {() => this.cancelDrivesModal()}
                    >
                        <DrivesProyeccion
                            selectedDrives = {this.state.proyeccion.Drives}
                            saveDrives = {this.saveDrives}
                            cancelDrivesModal = {this.cancelDrivesModal}
                        />
                        <br />
                    </Modal>
                }
            </div>
        );
    }
}

ProyeccionForm.defaultProps = {
    isLoading: false,
    proyeccion: {
        Nombre: "",
        Activo: true,
        NombreCorto: "",
        Color: "#FFC300",
        Orden: 1,
        ColorLetra : "#000000",
        Drives : []
    }
}

export default ProyeccionForm;
