import * as React from 'react';
import FieldAddons from '../field/Field+Addons';
/**
 * Componente de utilería para un <input> que su único fin sea la búsqueda de algún elemento en una lista
 */
export default class SearchForm3 extends React.Component{

    render(){
        return (
            <div className="field is-grouped is-grouped-right">
                <p className="control is-expanded">
                <FieldAddons isHorizontal>
                    <input
                        type="search"
                        className="input"
                        maxLength={50}
                        placeholder={`Buscar por Nombre`}
                        value={this.props.filterText}
                        autoFocus={true}
                        onChange={(e) => this.props.onInput(e.currentTarget.value)}
                    />
                </FieldAddons>
                </p>
                <p className="control">
                    <button className={"button is-success tooltip"} data-tooltip={this.props.tooltip} onClick={(e) => {
                            e.stopPropagation();
                            this.props.btnAction();
                        }}>
                            <span class="icon">
                                <i class={`fa fa-${this.props.icon}`}></i>
                            </span>
                            <span>{this.props.btnTitle}</span>
                    </button>
                </p>
            </div>
        )
    }
}