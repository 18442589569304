import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InventoryItemsAPI from '../../../../services/InventoryItem';
import { toast } from 'react-toastify';
import Loading from '../../../util/loading/Loading';
import UnitsAPI from '../../../../services/Units';
import ExpresionesRegulares from '../../../../util/expresiones-regulares';
import to from '../../../../util/to';

/**
 * Componente para buscar y agregar articulos de inventario o toppings.
 * Por default busca articulos de inventario.
 * Si quieres solo los toppings entonces pon las propiedades soloToppings y isTopping a {true}
 */
export default class NewRecipeDetail extends Component {

    state = {
        searchText: "",
        searchResultDetails: [],
        isLoading: false,
        units: [],
        showThead: false
    }

    static propTypes = {
        currentDetails: PropTypes.array.isRequired,
        addDetail: PropTypes.func.isRequired,
        soloToppings: PropTypes.bool
    }

    componentDidMount(){
        UnitsAPI.getAll().then(response => {
            this.setState({units: response.data});
        }).catch(err => toast.error(err.response.data.Message));
    }


    setUnitDetail = (id) => {
        let state = {...this.state};
        let unitString = state.units.map(unit => {
            if(unit.UnitsId===id){
                return unit.Name
            }
        })

        return unitString;
    }

    search = () => {

        let body = {
            Search: this.state.searchText,
            Total: 10,
            ItemInventoryIds: this.props.currentDetails,
            Toppings: this.props.soloToppings
        };
        this.setState({isLoading: true,showThead:true});
        InventoryItemsAPI.search(body).then(response => {
            let lista = response.data.Results.map((item)=>{
                let unidad = this.state.units.find(unit => {
                    if(unit.UnitsId===item.UnitSale){
                        return unit.Name
                    }
                    return ''
                });
                item.UnitSaleStr=unidad.Name;
                return item
            })
            this.setState({isLoading: false, searchResultDetails: lista});
        }).catch(err => toast.error(err.response.data.Message));
    }

    render() {
        return (
            <div className="column is-12">
                <div className="columns is-multiline is-centered">
                    <div className="column is-8">
                        <input
                            placeholder="Buscar por Código o Nombre"
                            className="input"
                            value={this.state.searchText}
                            onChange={(e) => this.setState({searchText: e.currentTarget.value})}
                            onKeyPress={(e) => {
                                let keyCode = e.keyCode || e.which
                                if(keyCode === 13){
                                    this.search()
                                }
                            }}
                        />
                    </div>
                    <div className="column is-2">
                        <button
                            className="button"
                            onClick={() => this.search()}>
                            Buscar
                        </button>
                    </div>
                    <div className="column is-10">
                    {!this.state.isLoading &&
                        <table className="table is-fullwidth is-striped">
                            { this.state.showThead &&
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    {this.props.isTopping && <th>Nombre Corto</th>}
                                    <th>Cantidad</th>
                                    <th>Unidad</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            }
                            <tbody>
                            {this.state.searchResultDetails.map((detail, i) => {
                                // Elementos que se muestran en el modal de detalles displonibles
                                
                                return (
                                    <tr key={i}>
                                        <td>{detail.CodeProvider}</td>
                                        <td>{detail.Name}</td>
                                        {this.props.isTopping && <td>{detail.ShortName}</td>}
                                        <td>
                                            {
                                                // Validar si el modal es para detalles y establecer el campo
                                                // para el detalle
                                                this.props.isDetail &&
                                                <input
                                                    className="input"
                                                    placeholder="Cantidad"
                                                    type="number"
                                                    maxLength="5"
                                                    value={detail.Amount || ""}
                                                    step=".1"
                                                    min="0"
                                                    max="999"
                                                    onKeyPress= {(event) => {
                                                        const keyCode = event.keyCode || event.which;
                                                        const keyValue = String.fromCharCode(keyCode);
                                                        // if (/\+|-|[a-zA-Z]|\d+(\.\d{3,})|\d{4,}+(\.\d)/.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
                                                        if (ExpresionesRegulares.ErrorInputNumDecimal.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
                                                            event.preventDefault();
                                                    }}
                                                    onChange={(e) => {
                                                        //if(e.target.value && e.target.value > 0){
                                                            detail.Amount = e.target.value;
                                                        
                                                            let details = this.state.searchResultDetails;
                                                            this.setState({searchResultDetails: details}); 
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        //}
                                                        // else if(e.target.value <= 0){
                                                        //     toast.warn("La cantidad debe ser mayor a cero.")
                                                        // }
                                                        
                                                    }}
                                                    onPaste={(e) => {
                                                        if (ExpresionesRegulares.ErrorInputNumDecimal.test(e.clipboardData.getData("Text")) 
                                                        || (/\./.test(e.target.value) && /\./.test(e.clipboardData.getData("Text")))){
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            }
                                            
                                            {
                                                // Verificar si el modal es para toppings y poner
                                                // los campos para el valor minimo y maximo
                                                this.props.isTopping &&
                                                <div className="columns">
                                                    <div className="column is-6">
                                                        <input
                                                            className="input"
                                                            placeholder="Mín."
                                                            type="number"
                                                            maxLength="5"
                                                            value={detail.MinQuantity || ""}
                                                            step="1"
                                                            min="0"
                                                            max="999"
                                                            onKeyPress= {(event) => {
                                                                const keyCode = event.keyCode || event.which;
                                                                const keyValue = String.fromCharCode(keyCode);
                                                                if (ExpresionesRegulares.ErrorInputNumDecimal.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
                                                                event.preventDefault();
                                                            }}
                                                            onChange={(e) => {
                                                                detail.MinQuantity = e.target.value;
        
                                                                let details = this.state.searchResultDetails;
                                                                this.setState({searchResultDetails: details}); 
                                                                e.preventDefault();
                                                                e.stopPropagation();
        
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="column is-6">
                                                        <input
                                                            className="input"
                                                            placeholder="Máx."
                                                            type="number"
                                                            maxLength="5"
                                                            value={detail.MaxQuantity || ""}
                                                            step="1"
                                                            min="0"
                                                            max="999"
                                                            onKeyPress= {(event) => {
                                                                const keyCode = event.keyCode || event.which;
                                                                const keyValue = String.fromCharCode(keyCode);
                                                                if (ExpresionesRegulares.ErrorInputNumDecimal.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
                                                                event.preventDefault();
                                                            }}
                                                            onChange={(e) => {
                                                                detail.MaxQuantity = e.target.value;
        
                                                                let details = this.state.searchResultDetails;
                                                                this.setState({searchResultDetails: details}); 
                                                                e.preventDefault();
                                                                e.stopPropagation();
        
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </td>
                                        {/* Unidad */}
                                        <td>{detail.UnitSaleStr}</td>
                                        <td>
                                            <a className="button tableAction is-success" onClick={(e) => {
                                                    e.stopPropagation();
                                                    let details = [...this.state.searchResultDetails];
                                                    
                                                    // Validar si se tiene un detalle o topping
                                                    // para agregar valores dependiendo el caso
                                                    if(this.props.isDetail){
                                                        detail.Amount = detail.Amount || "0"; // Verificar que el detalle lleve una cantidad
                                                        if(detail.Amount === "0"){
                                                            toast.warn("La cantidad debe ser mayor que cero.")
                                                            return;
                                                        }else{
                                                            details.splice(i, 1);
                                                            this.setState({searchResultDetails: details});
                                                        }                                                        
                                                    }else if(this.props.isTopping) {
                                                        detail.MinQuantity = Number(detail.MinQuantity) || 0;
                                                        detail.MaxQuantity = Number(detail.MaxQuantity) || 0;
                                                        if (!Number.isNaN(detail.MinQuantity) && !Number.isNaN(detail.MaxQuantity) && detail.MinQuantity > detail.MaxQuantity) {
                                                            toast.error("Límites no válidos para el topping")
                                                            return
                                                        } else if(detail.MinQuantity === 0 && detail.MaxQuantity === 0){
                                                            toast.error("Ingrese cantidad mínima y máxima.")
                                                            return;
                                                        } else{
                                                            details.splice(i, 1);
                                                            this.setState({searchResultDetails: details});
                                                        }
                                                    }else{
                                                        details.splice(i, 1);
                                                        this.setState({searchResultDetails: details});
                                                    }
                                                    
                                                    this.props.addDetail(detail)
                                                    //toast.success("Detalle agregado");
                                                    
                                                    
                                                }
                                                }>
                                                    <span className="icon">
                                                        <i className="fa fa-plus"/>
                                                    </span>
                                                </a>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    }
                    </div>
                    <div className="column is-6">
                        <Loading isLoading={this.state.isLoading} width='100px' height='100px'/>
                    </div>
                </div>
            </div>
        )
    }
}

NewRecipeDetail.defaultProps = {
    soloToppings: false
}