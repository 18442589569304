import React from 'react';
import Select from 'react-select';
import { useEffect } from 'react';
import NotificacionAPI from '../../../../../../services/Caffenio App/Notificacion';
import { useState } from 'react';

export default function AccionNotificacionFrom({inputs, errors, setInput, setError, handleSelectChange, handleInputChange, notification, idSegment, screens}){
    const [actions, setActions] = useState([]);

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        //setInput('screen', null);
    }, [inputs.segmentation])

    const fetchData = async () => {
        let res = await NotificacionAPI.getActions();
        setActions(res.data);

        /*
        //Aquí cambiar las pantallas
        let segment = (inputs.segmentation) ? inputs.segmentation.segment : "Caffenio";
        let resScreens = await NotificacionAPI.getScreensBySegment(segment);
        setScreens(resScreens.data);
        */
    }

    useEffect(() => {
        console.log(screens);
        if (notification){
            const selectedAction = actions.map(a => {return{value: a.NotificationActionId, label: a.Name}}).find(a => a.value === notification.NotificationActionId);
            setInput('action', selectedAction);
        }

        console.log(idSegment);
    },[actions, notification])

    useEffect(() => {
        if(inputs.action && inputs.action.value === 1 && notification){
            const selectedScreen = screens.map(s => {return{value: s.NotificationScreenId, label: s.Name}}).find(s => s.value === notification.NotificationScreenId);
            setInput('screen', selectedScreen);
        }

        if(inputs.action && (inputs.action.value === 1 || inputs.action.value === 2) && notification){
            setInput('link', notification.Link);
        }
    },[screens])

    useEffect(() => {
        if(inputs.link){
            const urlRegex = new RegExp(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/);
            setError('link', !inputs.link.match(urlRegex))
        }
    },[inputs.link])

    return (
        <div className="columns is-multiline">
            {inputs.category && <div className="column is-6">
                <label className="label">Acción</label>
                <div className={`control`}>
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"action"}
                        value={inputs.action}
                        isClearable
                        onChange={(value, event) => {
                            handleSelectChange(value, event, true)
                            setInput('screen', null);
                            setInput('link', "");
                        }}
                        noOptionsMessage={() => "No hay opciones"}
                        options={actions.map(a => {return{value: a.NotificationActionId, label: a.Name}})}
                    />
                </div>
                {errors.action && <p className="help title has-text-left is-6 is-danger">Acción requerida</p>}
            </div>}
            {inputs.action && inputs.action.value === 1 && <div className="column is-6">
                <span>
                    <label className="label">Pantalla</label>
                    <div className={`control`}>
                        <Select
                            placeholder={"Seleccionar..."}
                            name={"screen"}
                            value={inputs.screen}
                            isClearable
                            onChange={(value, event) => handleSelectChange(value, event, true)}
                            noOptionsMessage={() => "No hay opciones"}
                            options={screens.map(s => {return{value: s.NotificationScreenId, label: s.Name}})}
                        />
                    </div>
                    {errors.screen && <p className="help title has-text-left is-6 is-danger">Pantalla requerida</p>}
                </span>
            </div>}

            {inputs.action && [1,2].find(n => n === inputs.action.value) && <div className="column is-6">
                <label className="label">Enlace</label>
                <div className={`control`}>
                    <input
                        type="text"
                        name="link"
                        className={`input ${errors.link && "is-danger"}`}
                        placeholder={`Enlace de la notificación`}
                        value={inputs.link}
                        onChange={(e) => handleInputChange(e, true)}
                        required
                    />
                </div>
                {errors.link && <p className="help title has-text-left is-6 is-danger">Enlace inválido</p>}
            </div>}
        </div>
    )
}