import React, { Component } from 'react';
import SearchForm from "../../util/searchform/SearchForm";
import TableTemplate from "../../util/tabletemplate/TableTemplate";
import NoItems from "../../util/noitems/NoItems";
import { toast } from "react-toastify";
import Modal from '../../util/modal/Modal';
import AcceptModal from '../../util/acceptModal/AcceptModal';
import PresentationForm from './PresentationForm';
import PresentationsAPI from '../../../services/Presentations';
import Pagination from '../../util/pagination/Pagination';

import ModoGuiado from "../../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../../util/validation-error-messages';
import Loading from '../../util/loading/Loading';
import Field from '../../util/field/Field';
import lodash from 'lodash';
import roles from '../../../util/strings/roles';

class Presentations extends Component {
    presentations = [];

    constructor(props) {
        super(props);
        this.state = {
            presentations: [],
            pagePresentations: [],
            filterText: "",
            searchSwitch: true,
            presentation: null,
            showModal: false,
            editing: false,
            removeModal: false,
            modalConfirmChangeStatus: false,
            isLoading: false
        }
    }

    componentWillMount() {
        this.setState({ isLoading: true }, () => {
            PresentationsAPI.getAll().then(response => {
                this.setState({ presentations: response.data, isLoading: false });
                this.presentations = response.data;
                console.log(response)
            }).catch(err => {
                this.setState({ isLoading: false })
                if (err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            })
        })
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let presentations = this.presentations.filter(presentation =>
            presentation.Name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            && presentation.Active === this.state.searchSwitch
        );
        let state = this.state;
        state.filterText = text;
        state.presentations = presentations;
        this.setState(state);
    }

    /**Función para actualizar el filtrado de la data  */
    handleFilterData = () => {
        let presentations = this.presentations.filter(presentation =>
            presentation.Name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            && presentation.Active === this.state.searchSwitch
        );

        this.setState({ presentations: presentations });
    }

    /**Redirige a vista para crear nueva presentacion  */
    create = () => {
        this.props.history.push({ pathname: '/presentaciones/nueva', state: { presentations: this.presentations } });
    }

    /** Funcion para editar una presentacion  */
    edit = (presentation) => {
        this.setState({ isLoading: true }, () => {
            PresentationsAPI.edit(presentation, presentation.PresentationId).then(response => {
                let state = { ...this.state };
                state.presentations[state.presentations.findIndex(u => u.PresentationId === presentation.PresentationId)] = response.data;
                state.pagePresentations[state.pagePresentations.findIndex(u => u.PresentationId === presentation.PresentationId)] = response.data;
                this.presentations[this.presentations.findIndex(u => u.PresentationId === presentation.PresentationId)] = response.data;
                state.showModal = false;
                state.isLoading = false
                this.setState(state, () => {
                    this.handleFilterData();
                    toast.success("Presentación actualizada");
                });
            }).catch(err => {
                this.setState({ isLoading: false });
                if (err.response)
                    toast.error(err.response.data.Message);
                else
                    toast.error(ErrorMessages.SinConexion);
            })
        });


    }

    /**Funcion para eliminar un tipo de presentacion  */
    remove = () => {
        let presentation = this.state.presentation;
        this.setState({ isLoading: true })
        PresentationsAPI.delete(presentation.PresentationId).then(response => {
            let state = { ...this.state };
            state.presentations.splice(state.presentations.findIndex(u => u.PresentationId === presentation.PresentationId), 1);
            state.pagePresentations.splice(state.pagePresentations.findIndex(u => u.PresentationId === presentation.PresentationId), 1);
            this.presentations.splice(this.presentations.findIndex(u => u.PresentationId === presentation.PresentationId), 1);
            state.removeModal = false;
            state.isLoading = false
            this.setState(state, () => {
                toast.success("Presentación eliminada");
            })
        }).catch(err => {
            this.setState({ isLoading: false })
            if (err.response)
                toast.error(err.response.data.Message);
            else
                toast.error(ErrorMessages.SinConexion);
        })
    }

    

    changuestatus = (row) => { // Cambiar el estado activo/inactivo
        row.Active ? row.Active = false : row.Active = true;

        PresentationsAPI.edit(row).then(response => {
            let active = this.state.presentations[row];
            this.setState({ active: row },()=>this.handleFilterData());
            toast.success("Estatus actualizado");
        
        }).catch(err => toast.error(err.response.data.Message));
    }

    render() {
        return (
            <div>

                <ModoGuiado
                    ruta="presentaciones"
                    tipoModoGuiado="receta"
                    anterior="leches"
                    siguiente='toppings'
                    mensaje="Tamaño de un producto perteneciente a una familia" />

                <h1 className="title is-2">Presentación/Tamaño producto</h1>
                <div className="columns is-vcentered">
                    <div className="column is-8">
                        <div className="card">
                            <div className="card-content">
                                <div className="columns">
                                    <div className="column is-10 field">
                                        <Field label="Búsqueda">    
                                        <div className="control" style={{width:"100%"}}>
                                        <SearchForm
                                                filterText={this.state.filterText}
                                                onInput={(t) => this.handleSearchInput(t)}
                                                itemName={'Presentación'}
                                                placeholderText={"Ingrese nombre de presentación."}
                                            />
                                            </div>                                
                                            
                                        </Field>
                                        
                                    </div>
                                    <div className="column is-2">
                                        <Field label="Activo">
                                            <input
                                                id="chkSearchSwitch"
                                                type="checkbox"
                                                name="chkSearchSwitch"
                                                className="switch inputCenter"
                                                checked={this.state.searchSwitch}
                                                value={this.state.searchSwitch}
                                            />
                                            <label htmlFor="chkSearchSwitch" className="inputCenter" onClick={(e) => {
                                                this.setState({ searchSwitch: !this.state.searchSwitch }, () => this.handleFilterData())
                                            }} />
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-3">
                        <div className="field">
                            <div className="control has-text-right">
                                {lodash.intersection(localStorage.getItem('role').split(","), [roles.ADMIN, roles.ADMINDRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES]).length > 0 &&
                                    <a id="btnAddPresentation" tabIndex="0" className="button is-success" onClick={(e) => this.create()}>
                                        <span className="icon is-small">
                                            <i className="fa fa-plus"></i>
                                        </span>
                                        <span>Agregar Presentación</span>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>



                <div className="card">
                    <div className="card-content">
                        {this.state.presentations.length !== 0
                            ? <TableTemplate
                                columns={["PresentationId", "Name", "Position", "NombreSegmento", "Active"]}
                                columnsNames={["ID", "Nombre", "Posición en Menú", "Segmento", "Activo"]}
                                data={this.state.pagePresentations}
                                rowClickable={true}
                                details={(presentation) => this.setState({ showModal: true, presentation: presentation })}
                                canEdit canDelete
                                changeStatus={(row) => {
                                    if(!row.Active){
                                        this.changuestatus(row);
                                    }else{
                                        this.setState({modalConfirmChangeStatus: true, toChangeStatus: row})
                                    }
                                    
                                }}
                                edit={(presentation) => this.setState({ showModal: true, presentation: presentation })}
                                remove={(presentation) => this.setState({ removeModal: true, presentation: presentation })}
                            />
                            : <NoItems itemName="presentaciones" />}
                        <Pagination items={this.state.presentations} onChangePage={(presentations) => this.setState({ pagePresentations: presentations })} />
                    </div>
                </div>
                {this.state.showModal &&
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Presentación"}
                        cancel={() => this.setState({ showModal: false, presentation: null })}
                    >
                        <PresentationForm
                            presentation={this.state.presentation}
                            save={this.edit}
                            presentations={this.presentations}
                            isEditing={true}
                        />
                    </Modal>
                }
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove()}
                    cancel={() => this.setState({ removeModal: false })}
                    modalTitle={"Eliminar Presentación"}
                />}

                {
                    this.state.modalConfirmChangeStatus && <AcceptModal
                    isActive={this.state.modalConfirmChangeStatus}
                    done={() => {
                        this.setState({modalConfirmChangeStatus: false},() => this.changuestatus(this.state.toChangeStatus))
                        }}
                    cancel={() => this.setState({modalConfirmChangeStatus: false, toChangeStatus:null})}
                    modalTitle={"Desactivar Presentación"}
                    modalBody={"¿Está seguro(a) de desactivar la presentación?"}
                />
                }

                <BtnModoGuiado />

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
            </div>


        );
    }
}

export default Presentations;
