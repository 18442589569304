import React from 'react';
import { UserInfo } from '../UserInfo/UserInfo';
import './Header.css';

/**
 * Componente del Header de la aplicación donde podrían haber acciones o información
 */
export default class Header extends React.Component{
    render(){
        return(
            <nav className="navbar is-alpha is-fixed-top is-shadow is-primary" id="top">
                <div className="navbar-brand" style={{ width: '100%', justifyContent: 'space-between' }}>
                    <div className="navbar-item">
                        <p className="has-text-weight-bold">SAG Sistema de Autogestión - Drive</p>
                    </div>
                    <div className='navbar-item'>
                        <UserInfo />
                    </div>
                    <div id="navbarBurger" className="navbar-burger burger" data-target="navMenu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="navbar-end">
                </div>
            </nav>
        )
    }
}