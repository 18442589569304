import React from "react";
import Field from "../util/field/Field";
import Loading from "../util/loading/Loading";
import Modal from '../util/modal/Modal';
import AcceptModal from '../util/acceptModal/AcceptModal';
import HasAccess from '../util/hasAccess/HasAccess';
import  NoItems  from "../util/noitems/NoItems";
import Select from 'react-select';
import Pagination from '../util/pagination/Pagination';

import BtnModoGuiado from '../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../util/validation-error-messages';
import { size, truncate } from "lodash";

import RecipeExtractos from "../Recetas/recipes/RecipeExtractos"
import ToppingsExtractos from "../Recetas/toppings/ToppingsExtractos";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import  TableTemplate  from "../util/tabletemplate/TableTemplate";

export function ExtraccionesCafeComponent({
    checkHasInvalidFields,
    onKeyPressNumber,
    onKeyDown,
    handleChange,
    submit,
    cancelEditFormulaModal,
    cancelRecipesModal,
    cancelToppingsModal,
    toggleEditFormulaModal,
    toggleEditRecipesModal,
    toggleEditToppingsModal,
    reorder,
    getItemStyle,
    getListStyle,
    onDragEnd,
    checkFormula,
    checkDraggableStatus,
    handleChangeFormula,
    saveFormula,
    saveModule,
    saveRecipes,
    saveToppings,
    handleChangeTiempoVida,
    handleChangeFrecuencia,
    addElementFormula,
    removeElementFormula,
    changeRecipePage,
    changeToppingPage,
    removeToppingFromList,
    removeRecipeFromList,
    toggleModalRemoveRecipe,
    closeModalRemoveRecipe,
    toggleModalRemoveTopping,
    closeModalRemoveTopping,
    toggleModalRemoveElementFormula,
    cancelModalRemoveElementFormula,
    toggleModalInfo,
    cancelModalInfo,
    ...props
}) {
    return(
        <div>


            {/* Modal para visualizar la información del módulo */}
            {props.showModalInfo &&
                    <Modal
                        showModal={props.showModalInfo}
                        modalTitle={"Información de Extractos de café"}
                        cancel={() => cancelModalInfo()}
                    >
                        <div className="has-text-justified">                        
                            Módulo para configurar variables y fórmula que será utilizada para obtener la cantidad de extractos de café a producir por los operadores en el punto de venta, durante 
                            determinado periodo de tiempo.
                            <br/>
                            <br/>
                            <strong>En los apartados se podrán realizar:</strong>
                            <ol type="1">
                                <li>Recetas a excluir: Las recetas seleccionadas y agregadas a esta sección, se excluirán de la consulta de venta histórica para no ser contempladas en el pronóstico de 
                                    extractos de café.
                                    </li>                                
                                <li>
                                    Toppings a excluir: Los toppings seleccionados y agregados a esta sección, se excluirán de la consulta de venta histórica para no ser contemplados en el pronóstico de
                                    extractos de café.
                                    <br/> 
                                </li>
                                
                            </ol>                       
                        </div>
                    </Modal>
                }



            <h1 className="title is-3">Extractos Café
            
            <i className="fa-5x is-small"
                style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px", fontSize: "22px" }}
                className="fa fa-question-circle"
                onClick={(e) => toggleModalInfo(e)}
            ></i>

            
            
            </h1>
            {/* 
                VARIABLES
            */}


            <div className="columns">

                    <div className="column is-4">
                        <div className="card">
                            <div className="card-header is-primary">
                                <p className="card-header-title has-text-white has-background-primary title is-4">Variables</p>
                            </div>
                            <div className="card-content">

                                {/* 
                                     Tiempo de vida (minutos)
                                */}

                                <Field label="Tiempo de vida (minutos)" isHorizontal={false}>
                                <input
                                    className={
                                        (props.tiempoVidaIsEmpty || props.tiempoVidaIsZero || props.tiempoVidaIsMax)? "input is-danger":"input"
                                    }
                                    type="number"
                                    min="1"
                                    maxLength="3"
                                    max="999"
                                    label="Tiempo de vida (minutos)"
                                    name="TiempoVida"
                                    value={props.formulaData.TiempoVida}
                                    onKeyPress={onKeyPressNumber}
                                    onChange={(e) => handleChangeTiempoVida(e)}
                                />
                                </Field>
                                {props.tiempoVidaIsEmpty &&
                                    <p class="help is-danger">El campo es requerido</p>
                                }
                                {props.tiempoVidaIsZero &&
                                    <p class="help is-danger">El campo no puede ser 0</p>
                                }
                                {props.tiempoVidaIsMax &&
                                    <p class="help is-danger">El valor máximo para el campo es de 480</p>
                                }

                                <br />

                                {/* 
                                     Frecuencia a medir (minutos)
                                */}

                                <Field label="Frecuencia a medir (minutos)" isHorizontal={false}>
                                <input
                                    className={
                                        (props.frecuenciaIsEmpty || props.frecuenciaIsZero || props.frecuenciaIsMax)? "input is-danger":"input"
                                    }
                                    type="number"
                                    min="1"
                                    maxLength="3"
                                    max="480"
                                    label="Frecuencia a medir (minutos)"
                                    name="Frecuencia"
                                    value={props.formulaData.Frecuencia}
                                    onKeyPress={onKeyPressNumber}
                                    onChange={(e) => handleChangeFrecuencia(e)}
                                />
                                </Field>
                                {props.frecuenciaIsEmpty &&
                                    <p class="help is-danger">El campo es requerido</p>
                                }
                                {props.frecuenciaIsZero &&
                                    <p class="help is-danger">El campo no puede ser 0</p>
                                }
                                {props.frecuenciaIsMax &&
                                    <p class="help is-danger">El valor máximo para el campo es de 480</p>
                                }


                            </div>
                        </div>
                    </div>

                     {/* 
                        FORMULA
                    */}

                    <div className="column is-8">

                    <div className="card">
                            <div className="card-header is-primary">
                                <p className="card-header-title has-text-white has-background-primary title is-4">Fórmula</p>
                            </div>

                            <br />

                            <div className="card-content"> 

                            <span className="tag is-info is-light is-large">
                                {props.formula.map(e => e.text).join(' ')}
                            </span>

                            <br /> 
                            <br />
                            <br />

                            <div className="has-text-right">
                                <button
                                    disabled={false}
                                    className="button is-warning"
                                    onClick={(e) => toggleEditFormulaModal(e)}>
                                    Editar
                                </button>
                            </div>


                            </div>
                        </div>

                    </div>

            </div>   
        
            {/* 
                MODAL DE FORMULA
            */}

            {props.showModalEditFormula && props.showModal && 
                <Modal
                    showModal={props.showModal}
                    modalTitle={"Editar fórmula"}
                    width="95%"
                    cancel = {() => cancelEditFormulaModal()}
                >
                <div className="box">
                    <div className="tags">
                        <span className="tag is-white is-medium">Operadores Permitidos:</span>
                        <span className="tag is-info">suma +</span>
                        <span className="tag is-info">resta -</span>
                        <span className="tag is-info">multiplicacion *</span>
                        <span className="tag is-info">división /</span>
                        <span className="tag is-info">paréntesis ( ) </span>
                        <span className="tag is-info">campo vacío " " </span>
                    </div>
                </div>

                <div className="box">
                    <div className="tags">
                        <span className="tag is-white is-medium">Ejemplos:</span>
                        <span className="tag is-info">TiempoVida / (Frecuencia / VentaHistorica)</span>
                        <span className="tag is-info">Frecuencia * ( VentaHistorica + TiempoVida)</span>
                        <span className="tag is-info">VentaHistorica * TiempoVida - Frecuencia</span>
                    </div>

                </div>
                
                <div className="box">
                    <div class="notification has-text-justified is-info is-light">
                            <strong>- En la siguiente sección se muestran las variables (resaltadas en verde) y operaciones que se incluyen en el algortimo.
                                    Las variables son fijas y las operaciones pueden ser modificadas, intercambiando solo con otra operación permitida utilizando el teclado del dispositivo.</strong>
                    </div>

                    <div class="notification has-text-justified is-info is-light">
                            <strong>- Se puede modificar la fórmula, moviendo el orden de las variables o de las operaciones, utilizando el puntero, presionando 
                            sobre el elemento a mover y arrastrándolo hasta su nuevo lugar.</strong> 
                    </div>
                </div>

                <div className="box">
                    <div className="columns">

                        <div className="column is-1">
                            <button
                                disabled={props.addElementFormulaIsDisabled}
                                className='button is-info'
                                onClick={() => addElementFormula()}
                            >
                                Agregar elemento
                            </button>

                            
                        </div>
                        
                    </div>
                </div>

                                        
                    <DragDropContext onDragEnd= {(result) => onDragEnd(result)}>

                        <Droppable droppableId="formulaEdit" direction="horizontal">
                            { (droppableProvided, snapshot) => 
                                <div {...droppableProvided.droppableProps}
                                    ref={droppableProvided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {props.formula.map((element, index) => (
                                        // <Draggable key={element.id} draggableId={element.id} index = {index} isDragDisabled={element.isDraggableDisabled}>
                                        <Draggable key={element.id} draggableId={element.id} index = {index} isDragDisabled={false}>
                                            {(draggableProvided, snapshot) => 
                                            <div 
                                                {...draggableProvided.draggableProps}
                                                {...draggableProvided.dragHandleProps}
                                                ref = {draggableProvided.innerRef}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    draggableProvided.draggableProps.style
                                                )}  
                                                
                                                >
                                                    <div className="field has-addons">
                                                        <div className="control">

                                                            <input 
                                                                readOnly={props.formula[index].isDraggableDisabled}
                                                                className='input has-text-centered is-success'
                                                                value={props.formula[index].text} 
                                                                type="text" 
                                                                maxLength="4"
                                                                label={element.text}
                                                                onMouseDown={(e) => checkDraggableStatus(e, index)}
                                                                onChange={(e) => handleChangeFormula(e, index)}
                                                                // onKeyPress={(e) => onKeyPressNumber(e)}
                                                            />

                                                        </div>

                                                        <div className="control">
                                                            {(!props.formula[index].isDraggableDisabled) &&
                                                            <a 
                                                                disabled={props.removeElementFormulaIsDisabled}
                                                                className='button is-primary'
                                                                onClick={(e) => toggleModalRemoveElementFormula(index)}
                                                            >
                                                            X
                                                            </a>}
                                                        </div>
                                                        
                                                        
                                                    </div>

                                            </div>}

                                            


                                        </Draggable>
                                    ))} 


                                            {(props.showModalRemoveElementFormula) &&
                                                <AcceptModal 
                                                modalTitle="Confirmar"
                                                modalBody={<p>¿Está seguro que deseas eliminar este elemento de la fórmula?</p>}
                                                isActive={props.showModalRemoveElementFormula}
                                                willSave={true}
                                                done={() => removeElementFormula()}
                                                cancel={() => cancelModalRemoveElementFormula()}
                                                />
                                            }   


                                {droppableProvided.placeholder}
                                </div>
                            }
                        </Droppable>

                    </DragDropContext>

                    <br />

                    <footer className="modal-card-foot is-justify-end">

                        <button className="button"
                            className='button is-grey '
                            onClick={() =>  cancelEditFormulaModal()}
                        >
                            Cancelar
                        </button>


                        <button className="button is-success"
                            disabled={!(props.formulaIsValid)}
                            className={
                              (props.formulaIsValid === false) ? 'button is-light' : 'button is-success'
                            }
                            onClick={() => saveFormula()}
                        >
                            Guardar cambios
                        </button>


                        <button className="button is-info"
                            disabled={!props.formulaWasModified }
                            className={
                              (props.formulaWasModified === false) ? 'button is-light' : 'button is-info'
                            }
                            onClick={() => checkFormula()}
                            on
                        >
                            Verificar
                        </button>

                    </footer>

                </Modal>
            }   
            

            {/* 
                RECETAS
            */}

            <div className="columns is-multiline">
                <div className="column is-6">

                        <p className="card-header-title has-text-white has-background-primary title is-4">Recetas a excluir</p>

                        
                        <div className="has-text-left">
                                <button
                                    className="button is-info"
                                    onClick={(e) => toggleEditRecipesModal(e)}>
                                    Editar recetas
                                </button>

                                
                        </div>

                        <br />
                        


                        <div className="card">
                            <div className="card-header is-primary">

                                <div className="column is-12">
                                {/* 
                                    TABLA DE RECETAS
                                */}

                                    {(props.formulaData.Recetas.length !== 0) 
                                    ? <TableTemplate
                                            columns={["KiwiId", "Description"]}
                                            columnsNames={["SKU", "Descripción"]}
                                            
                                            data={props.pageRecipes}
                                            //data={props.formulaData.Recetas}
                                            //checkbox={true}
                                            //Controlar checkboxes 
                                            showView={(index) => {}}
                                            //checkAll={this.state.checkAll}
                                            //Controlador del checkbox del header
                                            //selectAllCheck={this.selectAllCheck}
                                            rowClickable={false}
                                            extraButtons={(recipe) => {
                                                return <button className="button tableAction is-danger tooltip" data-tooltip="Eliminar receta"
                                                onClick={(e) => toggleModalRemoveRecipe(recipe)}
                                                >
                                                    <span className="icon">
                                                        <i className="fa fa-trash" />
                                                    </span>
                                                </button>
                                            }}
                                    /> 
                                    
                                    : <NoItems itemName="recetas"/>}

                                    {(props.showModalRemoveRecipe) &&
                                        <AcceptModal 
                                        modalTitle="Confirmar"
                                        modalBody={<p>¿Está seguro que desea eliminar la receta de la lista a excluir?</p>}
                                        isActive={props.showModalRemoveRecipe}
                                        willSave={true}
                                        done={() => removeRecipeFromList()}
                                        cancel={() => closeModalRemoveRecipe()}
                                        />
                                    }  


                                    <Pagination items={props.formulaData.Recetas} pageSize={5} keepPage={true} onChangePage={(recipes) => changeRecipePage(recipes)} />
                                </div>
                            </div>
                        </div>
                </div>


                <div className="column is-6 ">

                        <p className="card-header-title has-text-white has-background-primary title is-4">Toppings a excluir</p>

                        
                        <div className="has-text-left">
                                <button
                                    className="button is-info"
                                    onClick={(e) => toggleEditToppingsModal(e)}>
                                    Editar toppings
                                </button>

                                
                        </div>

                        <br />
                       


                        <div className="card">
                            <div className="card-header is-primary">

                                <div className="column is-12">
                                {/* 
                                    TABLA DE TOPPINGS
                                */}

                                    {(props.formulaData.Toppings.length !== 0) 
                                    ? <TableTemplate
                                            columns={["InventoryCode", "Name"]}
                                            columnsNames={["Código Artículo", "Nombre"]}
                                            data={props.pageToppings}
                                            //checkbox={true}
                                            //Controlar checkboxes 
                                            showView={(index) => {}}
                                            //checkAll={this.state.checkAll}
                                            //Controlador del checkbox del header
                                            //selectAllCheck={this.selectAllCheck}
                                            rowClickable={false}
                                            extraButtons={(topping) => {
                                                return <button className="button tableAction is-danger tooltip" data-tooltip="Eliminar topping"
                                                onClick={(e) => toggleModalRemoveTopping(topping)}
                                                >
                                                    <span className="icon">
                                                        <i className="fa fa-trash" />
                                                    </span>
                                                </button>
                                            }}
                                    /> 
                                    : <NoItems itemName="toppings"/>}

                                    {(props.showModalRemoveTopping) &&
                                        <AcceptModal 
                                        modalTitle="Confirmar"
                                        modalBody={<p>¿Está seguro que desea eliminar el topping de la lista a excluir?</p>}
                                        isActive={props.showModalRemoveTopping}
                                        willSave={true}
                                        done={() => removeToppingFromList()}
                                        cancel={() => closeModalRemoveTopping()}
                                        />
                                    }  

                                    <Pagination items={props.formulaData.Toppings} pageSize = {5} keepPage={true} onChangePage={(toppings) => changeToppingPage(toppings)} />
                                    

                                </div>
                            </div>
                        </div>
                </div>

            </div>

            {/* MODAL DE RECETAS */}

            {props.showModalEditRecipes && props.showModal && 
                <Modal
                    showModal={props.showModal}
                    modalTitle={"Agregar/Eliminar recetas"}
                    width="90%"
                    cancel = {() => cancelRecipesModal()}
                >

                <RecipeExtractos
                    selectedRecipes = {props.formulaData.Recetas}
                    saveRecipes = {saveRecipes}
                    cancelRecipesModal = {cancelRecipesModal}
                />

                <br />

                </Modal>
            }

            {/* MODAL DE TOPPINGS */}

            {props.showModalEditToppings && props.showModal && 
                <Modal
                    showModal={props.showModal}
                    modalTitle={"Agregar/Eliminar toppings"}
                    width="90%"
                    cancel = {() => cancelToppingsModal()}
                >

                <ToppingsExtractos
                    selectedToppings = {props.formulaData.Toppings}
                    saveToppings = {saveToppings}
                    cancelToppingsModal = {cancelToppingsModal}
                />

                <br />

                </Modal>
            }



                <div className="has-text-right">
                    <button
                        disabled={!(checkHasInvalidFields()) || props.isSubmitting}
                        className={
                            !(checkHasInvalidFields()) ? 'button is-light' : 'button is-success'
                        }
                        onClick={() => saveModule()}
                    >
                        Guardar
                    </button>

                    
                </div>


            <Loading isFullscreen={true} isLoading={props.isLoading} width='100px' height='100px'/>
            <BtnModoGuiado />
        </div>

    );
}