import * as React from 'react';

export default class RadioButton extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div>
                <input type="radio" name={this.props.groupName} id={this.props.id} value={this.props.data[this.props.objectId]} />
                &nbsp;
                <label forhtml={this.props.id}>{this.props.data[this.props.labelKey]}</label>
            </div>
        )
    }
}