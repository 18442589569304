import React, { Component } from 'react';
import Field from '../../../util/field/Field';
import Dropdown from '../../../util/dropdown/Dropdown';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

/**
 * Formulario para cargar y validar PT asignado a artículo inventario
 */
class InventoryItemUnitsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventoryItem: { ...this.props.inventoryItem },
            convFactorBuyDisabled:false,
            isEditing: false,
            isLoading: false
        }
    }

    VALIDATION_SCHEMA = Yup.object({
        UnitPurchase: Yup.string().required("Campo Unidad pedido a sucursal es requerido.").nullable(),
        StorageUnit: Yup.string().required("Campo Unidad almacenamiento externo es requerido.").nullable(),
        UnitSale: Yup.string().required("Campo Unidad para venta es requerido.").nullable(),        
        FactorBuyMin: Yup.number()
            .min(.00001,"Ingrese valor mayor a cero.")
            .required("Campo Múltiplos para pedido sucursal es requerido."),
        ConvFactorBuy: Yup.number()
            .min(.00001,"Ingrese valor mayor a cero.")
            .required("Campo Cantidad conversión almacén es requerido."),
        ConvFactorSale: Yup.number()
            .min(.00001,"Ingrese valor mayor a cero.")
            .required("Campo Equivalencia venta es requerido."),
        
    });

    render() {
        return (
            <Formik
                
                initialValues={this.state.inventoryItem}
                validationSchema={this.VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    
                    this.props.save(values)
                    
                }}>
                {({  values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched,setFieldError }) => (
                    <form onSubmit={handleSubmit} id="frmEquivalencias" >
                        <div className="columns">
                            <div className="column is-4">
                                <Field label="Unidad pedido a sucursal:"
                                    showTooltip={true}
                                    textTooltip={"Unidad con la que envían de sucursal a pdv"}
                                    hasError={errors.UnitPurchase || touched.UnitPurchase}
                                    msgError={errors.UnitPurchase || touched.UnitPurchase ? errors.UnitPurchase : ""}>
                                    <Dropdown
                                        single
                                        items={this.props.unitsGP}
                                        value={values.UnitPurchase}
                                        id="UnitsId"                                        
                                        labelKey={"Name"}
                                        onSelect={(val) => {   
                                            if(val > 0){
                                                let selected = this.props.unitsGP[val - 1].UnitsId;
                                                setFieldValue('UnitPurchase', selected)                                              
                                                if(values.StorageUnit){                                                   
                                                    let unidadBase = this.props.unitsGP[0].UnidadBase;
                                                    let unidadAlmacenamiento = this.props.unitsGP.find(itemGP => itemGP.UnitsId === values.StorageUnit);
                                                    let unidadPedido = this.props.unitsGP.find(itemGP => itemGP.UnitsId === selected);
                                                    if (unidadAlmacenamiento && unidadPedido) {                                                        
                                                        if (unidadAlmacenamiento.Name === unidadBase) {
                                                            setFieldValue('ConvFactorBuy', unidadPedido.Equivalencia)   
                                                            this.setState({"convFactorBuyDisabled": true})                                                         
                                                        }
                                                        else if (unidadPedido.Name === unidadBase) {
                                                            setFieldValue('ConvFactorBuy', unidadAlmacenamiento.Equivalencia) 
                                                            this.setState({"convFactorBuyDisabled": true});
                                                        }
                                                        else {
                                                            this.setState({"convFactorBuyDisabled": false});
                                                            setFieldValue('ConvFactorBuy', 0)
                                                            toast.warn("No se encontró equivalencia en GP")
                                                        }
                                                    }
                                                }                                                
                                            } else{
                                                setFieldValue('UnitPurchase', null)
                                            }  
                                        }}
                                        onBlur={() => setFieldTouched('UnitPurchase', true)}
                                        hasError={errors.UnitPurchase && touched.UnitPurchase}
                                    />
                                </Field>
                                <br />
                                <Field
                                    label="Múltiplos para pedido sucursal:"
                                    showTooltip={false}
                                    textTooltip={"Múltiplos para pedido sucursal"} 
                                    hasError={errors.FactorBuyMin || touched.FactorBuyMin}
                                    msgError={errors.FactorBuyMin || touched.FactorBuyMin ? errors.FactorBuyMin : ""}                               >
                                    <input
                                        className={`input ${errors.FactorBuyMin && touched.FactorBuyMin ? "is-danger" : ""}`}
                                        type="number"
                                        label="Factor Compra"
                                        name="FactorBuyMin"
                                        value={values.FactorBuyMin}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={"Múltiplos de pedido a sucursal"}
                                    />
                                </Field>
                            </div>
                            <div className="column is-4">
                                <Field label="Unidad almacenamiento externo pdv:"
                                    showTooltip={true}
                                    textTooltip={"Unidad de almacenamiento pdv"}
                                    hasError={errors.StorageUnit || touched.StorageUnit}
                                    msgError={errors.StorageUnit || touched.StorageUnit ? errors.StorageUnit : ""}>
                                
                                    <Dropdown
                                        single
                                        items={this.props.unitsGP}
                                        value={values.StorageUnit}
                                        id="UnitsId"                                        
                                        labelKey={"Name"}
                                        onSelect={(val) => {   
                                            if(val > 0){
                                                let selected = this.props.unitsGP[val - 1].UnitsId;
                                                setFieldValue('StorageUnit', selected)
                                                if(values.UnitPurchase){                                                    
                                                    let unidadBase = this.props.unitsGP[0].UnidadBase;
                                                    let unidadAlmacenamiento = this.props.unitsGP.find(itemGP => itemGP.UnitsId ===  selected);
                                                    let unidadPedido = this.props.unitsGP.find(itemGP => itemGP.UnitsId === values.UnitPurchase);
                                                    if (unidadAlmacenamiento && unidadPedido) {                                                        
                                                        if (unidadAlmacenamiento.Name === unidadBase) {
                                                            setFieldValue('ConvFactorBuy', unidadPedido.Equivalencia)                                                            
                                                            this.setState({"convFactorBuyDisabled": true})
                                                        }
                                                        else if (unidadPedido.Name === unidadBase) {
                                                            setFieldValue('ConvFactorBuy', unidadAlmacenamiento.Equivalencia) 
                                                            this.setState({"convFactorBuyDisabled": true})
                                                        }
                                                        else {
                                                            this.setState({"convFactorBuyDisabled": false})
                                                            setFieldValue('ConvFactorBuy', 0)    
                                                            toast.warn("No se encontró equivalencia en GP")                                                        
                                                                                                                        
                                                        }
                                                    }
                                                }
                                            } else{
                                                setFieldValue('StorageUnit', null)
                                            }  
                                        }}
                                        onBlur={() => setFieldTouched('StorageUnit', true)}
                                        hasError={errors.StorageUnit && touched.StorageUnit}
                                    />
                                </Field>
                                <br />

                                <Field
                                    label="Equivalencia almacén:"
                                    showTooltip={false}
                                    textTooltip={"Cantidad conversión almacén"}
                                    hasError={errors.ConvFactorBuy || touched.ConvFactorBuy}
                                    msgError={errors.ConvFactorBuy || touched.ConvFactorBuy ? errors.ConvFactorBuy : ""}
                                >
                                    <input
                                        className={`input ${errors.ConvFactorBuy && touched.ConvFactorBuy ? "is-danger" : ""}`}
                                        type="number"                                        
                                        label="Factor Compra"
                                        name="ConvFactorBuy"                                   
                                        value={values.ConvFactorBuy}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={"Cantidad de conversión almacén"}
                                        disabled={this.state.convFactorBuyDisabled}
                                    />
                                </Field>
                            </div>
                            <div className="column is-4">
                                <Field label="Unidad para venta" 
                                    showTooltip={true} 
                                    textTooltip={"Unidad que se vende en pdv"}
                                    hasError={errors.UnitSale || touched.UnitSale}
                                    msgError={errors.UnitSale || touched.UnitSale ? errors.UnitSale : ""}>
                                    <Dropdown
                                        single
                                        items={this.props.units}
                                        value={values.UnitSale}
                                        id="UnitsId"
                                        onSelect={(val) => {   
                                            if(val > 0){
                                                let selected = this.props.units[val - 1].UnitsId;
                                                setFieldValue('UnitSale', selected)
                                            } else{
                                                setFieldValue('UnitSale', null)
                                            }  
                                        }}
                                        onBlur={() => setFieldTouched('UnitSale', true)}                                 
                                        labelKey={"Name"}
                                        hasError={errors.UnitSale && touched.UnitSale}
                                    />
                                </Field>

                                <br />
                                <Field
                                    label="Equivalencia venta"
                                    showTooltip={false}
                                    textTooltip={"Cantidad conversión venta"}
                                    hasError={errors.ConvFactorSale || touched.ConvFactorSale}
                                    msgError={errors.ConvFactorSale || touched.ConvFactorSale ? errors.ConvFactorSale : ""}
                                >
                                    <input
                                        className={`input ${errors.ConvFactorSale && touched.ConvFactorSale ? "is-danger" : ""}`}
                                        type="number"
                                        label="Factor Venta"
                                        name="ConvFactorSale" 
                                        value={values.ConvFactorSale}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={"Cantidad de conversión a la venta"}
                                        
                                    />
                                </Field>
                            </div>
                        </div>

                        <div className="has-text-left buttons-modal" id="stepActions">
                            <div className="pull-left">
                                <button id="btnBackStepUnits" type="button" className="button is-light" onClick={() => this.props.back()} >Anterior</button>
                            </div>
                        </div>
                        <div className="has-text-right buttons-modal" id="stepActions">
                            <div className="pull-right">
                                <button id="btnContinuarStepUnits" type="submit" className="button is-success">Continuar</button>
                            </div>
                        </div>

                    </form>
                )}

            </Formik>

        )
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
InventoryItemUnitsForm.defaultProps = {
    isEditing: false,
    inventoryItem: {
        CodeProvider: "",
        InventoryCode: "",
        Name: "",
        ShortName: "",
        UnitPurchase: 0,
        StorageUnit: 0,
        UnitSale: 0,
        ConvFactorBuy: "",
        ConvFactorSale: "",
        FactorBuyMin: 0.95,
        AmountMin: 0,
        AmountMax: 0,
        InventoryTypeId: 0,
        CodeSat: null,
        IsTopping: false,
        ToppingPrice: 0,
        ToppingWeight: 0
    }
}

export default InventoryItemUnitsForm;