import { apiUrl }  from "../constants";
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class SatAPI {

    static getUnitCodes(){
        return request.get(`/UnitsSat`);
    }

    static getCodes(){
        return request.get(`/CodesSat`);
    }

    static getClassifications(){
        return request.get(`/ClassificationSat`)
    }
}