import React, { Component } from 'react';
import Field from '../../../util/field/Field';
import Dropdown from '../../../util/dropdown/Dropdown';
import Loading from '../../../util/loading/Loading';
import { Formik } from 'formik';
import * as Yup from 'yup';



/**
 * Formulario para cargar y validar PT asignado a artículo inventario
 */
class InventoryItemTypeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventoryItem: { ...this.props.inventoryItem },
            isEditing: false,
            isLoading: false
        }
    }

    VALIDATION_SCHEMA = Yup.object({
        AmountMax: Yup.number()
            .min(1, "El valor mínimo es 1.")
            .required("Campo Cantidad máxima para solicitar es requerido."),
        AmountMin: Yup.number()
            .min(1, "El valor mínimo es 1.")
            .required("Campo Cantidad Mínima para solicitar es requerido.")
            .test('qyt-match', 'Cantidad mínima debe ser menor a cantidad máxima.', function (value) {
                return this.parent.AmountMax > value;
            }),
        InventoryTypeId: Yup.string().required("Campo Clasificación Inventario es requerido.").nullable(),
        OrderTypeId: Yup.string().required("Campo Clasificación Pedido es requerido.").nullable(),
    });

    render() {
        return (
            <Formik
                initialValues={this.state.inventoryItem}
                validationSchema={this.VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    this.props.save(values)
                }}>
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                    <form onSubmit={handleSubmit} id="frmClassificationInventoryItem">
                        <div className="columns is-multiline">
                            <div className="column is-4">
                                <Field label="Clasificación Pedido"
                                    showTooltip={true}
                                    textTooltip={"Clasificación del artículo para el sistema de pedidos."}
                                    hasError={errors.OrderTypeId || touched.OrderTypeId}
                                    msgError={errors.OrderTypeId || touched.OrderTypeId ? errors.OrderTypeId : ""}
                                >
                                    <Dropdown
                                        single
                                        items={this.props.orderTypes}
                                        value={values.OrderTypeId}
                                        id="OrderTypeId"
                                        onSelect={(val) => {
                                            if (val > 0) {
                                                let selected = this.props.orderTypes[val - 1].OrderTypeId;
                                                setFieldValue('OrderTypeId', selected)

                                            } else {
                                                setFieldValue('OrderTypeId', null)
                                            }
                                        }}
                                        onBlur={() => setFieldTouched('OrderTypeId', true)}
                                        labelKey={"Description"}
                                        hasError={errors.OrderTypeId && touched.OrderTypeId}
                                    />
                                </Field>
                            </div>
                            <div className="column is-4">
                                <Field label="Cantidad máxima para solicitar"
                                    showTooltip={false}
                                    textTooltip={" Cantidad máxima que le puedo pedir a sucursal"}
                                    hasError={errors.AmountMax || touched.AmountMax}
                                    msgError={errors.AmountMax || touched.AmountMax ? errors.AmountMax : ""}
                                >
                                    <input
                                        className={`input ${errors.AmountMax && touched.AmountMax ? "is-danger" : ""}`}
                                        type="number"
                                        label="Cantidad Máxima"
                                        name="AmountMax"
                                        value={values.AmountMax}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={"Ingrese cantidad máxima a solicitar"}
                                    />
                                </Field>
                            </div>
                            <div className="column is-4">
                                <Field label="Cantidad Mínima para solicitar"
                                    showTooltip={false}
                                    textTooltip={" Cantidad mínima que le puedo pedir a sucursal"}
                                    hasError={errors.AmountMin || touched.AmountMin}
                                    msgError={errors.AmountMin || touched.AmountMin ? errors.AmountMin : ""}>
                                    <input
                                        type="number"
                                        className={`input ${errors.AmountMin && touched.AmountMin ? "is-danger" : ""}`}
                                        label="Cantidad Mínima"
                                        name="AmountMin"
                                        value={values.AmountMin}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={"Cantidad mínima a solicitar"} />
                                </Field>
                            </div>

                            <div className="column is-4">
                                <Field label="Clasificación Inventario"
                                    showTooltip={true}
                                    textTooltip={"Clasificación del artículo para el sistema de inventarios."}
                                    hasError={errors.InventoryTypeId || touched.InventoryTypeId}
                                    msgError={errors.InventoryTypeId || touched.InventoryTypeId ? errors.InventoryTypeId : ""}
                                >
                                    <Dropdown
                                        single
                                        items={this.props.inventoryTypes}
                                        value={values.InventoryTypeId}
                                        id="InventoryTypeId"
                                        onSelect={(val) => {
                                            if (val > 0) {
                                                let selected = this.props.inventoryTypes[val - 1].InventoryTypeId;
                                                setFieldValue('InventoryTypeId', selected)

                                            } else {
                                                setFieldValue('InventoryTypeId', null)
                                            }
                                        }}
                                        labelKey={"Description"}
                                        hasError={errors.InventoryTypeId && touched.InventoryTypeId}
                                    />
                                </Field>
                            </div>
                        </div>


                        <br />

                        <div className="has-text-left buttons-modal" id="stepActions">
                            <div className="pull-left">
                                <button id="btnBackStepTypeInventory" type="button" className="button is-light" onClick={() => this.props.back()} >Anterior</button>
                            </div>
                        </div>
                        <div className="has-text-right buttons-modal" id="stepActions">
                            <div className="pull-right">
                                <button id="btnContinuarStepTypeInventory" type="submit" className="button is-success" >Continuar</button>
                            </div>
                        </div>

                    </form>
                )
                }

            </Formik>

        )
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
InventoryItemTypeForm.defaultProps = {
    isEditing: false,
    inventoryItem: {
        CodeProvider: "",
        InventoryCode: "",
        Name: "",
        ShortName: "",
        UnitPurchase: 0,
        StorageUnit: 0,
        UnitSale: 0,
        ConvFactorBuy: "",
        ConvFactorSale: "",
        FactorBuyMin: 0.95,
        AmountMin: 0,
        AmountMax: 0,
        InventoryTypeId: 0,
        CodeSat: null,
        IsTopping: false,
        ToppingPrice: 0,
        ToppingWeight: 0
    }
}

export default InventoryItemTypeForm;