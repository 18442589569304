import React, { Component } from 'react';
import Field from '../../../util/field/Field';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import lodash from 'lodash';


/**
 * Formulario para cargar la información general de la receta
 */
class RecipeComplementInfoStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipe: {},
            isEditing: false,
            isLoading: false
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ recipe: { ...nextProps.recipe }, isEditing: nextProps.isEditing });
    }

    VALIDATION_SCHEMA = Yup.object({
        IVAApply: Yup.bool(),
        MobileActive: Yup.bool(),
        GiftWelcomeActive: Yup.bool(),
        ClassificationSatId: Yup.string().required("El campo Clasificación del SAT es requerido.").nullable(),
        CodeSatId:  Yup.string().required("El campo Código del SAT es requerido.").nullable(),
    });  

    render() {
        return (
            <Formik
                enableReinitialize={true}
                initialValues={this.state.isEditing ? this.state.recipe : {}}
                validationSchema={this.VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    this.props.save(values)
                }}>
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                    <form onSubmit={handleSubmit} id="frmComplementInfoRecipeStep">

                        <div className="columns">
                            <div className="column is-6">
                                <label className="label">Clasificación del SAT</label>
                                <div className="control">
                                    <Select
                                        id="ddlClassificationSat"
                                        name="ClassificationSatId"
                                        value={values.ClassificationSatId}
                                        placeholder="Seleccionar..."
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={lodash.sortBy(this.props.satClassifications.map(c => {
                                            c.value = c.ClassificationSatId;
                                            c.label = `${c.CodeSat} - ${c.Description}`;
                                            return c;
                                        }), [ts => ts.label.toLowerCase()], ['asc'])}
                                        onChange={(val) => {
                                            setFieldValue('ClassificationSatId', val)
                                        }}
                                        onBlur={() => setFieldTouched('ClassificationSatId', true)}
                                    />
                                </div>
                                <div className="is-centered">
                                    {(errors.ClassificationSatId || touched.ClassificationSatId) &&
                                        <div style={{ 'fontSize': '12px', 'color': 'rgb(244, 67, 54)' }}>
                                            {errors.ClassificationSatId ? errors.ClassificationSatId : ""}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="column is-6">
                                <label className="label">Código del SAT</label>
                                <div className="control">
                                    <Select
                                        id="ddlCodeSatId"
                                        name="CodeSatId"
                                        value={values.CodeSatId}
                                        placeholder="Seleccionar..."
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={lodash.sortBy(this.props.satCodes.map(c => {
                                            c.value = c.Code;
                                            c.label = `${c.Code} - ${c.Description}`;
                                            return c;
                                        }), [ts => ts.label.toLowerCase()], ['asc'])}
                                        onChange={(val) => {
                                            setFieldValue('CodeSatId', val)
                                        }}
                                        onBlur={() => setFieldTouched('CodeSatId', true)}
                                    />
                                </div>
                                <div className="is-centered">
                                    {(errors.CodeSatId || touched.CodeSatId) &&
                                        <div style={{ 'fontSize': '12px', 'color': 'rgb(244, 67, 54)' }}>
                                            {errors.CodeSatId ? errors.CodeSatId : ""}
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="columns">
                            <div className="column is-4">
                                <Field label="Aplica IVA">
                                    <input
                                        id="chkIvaApply"
                                        type="checkbox"
                                        name="IVAApply"
                                        className="switch inputCenter"
                                        checked={values.IVAApply}
                                        value={values.IVAApply}
                                    />
                                    <label htmlFor="IVAApply" className="inputCenter" onClick={(e) => {
                                        e.stopPropagation();
                                        setFieldValue('IVAApply', !values.IVAApply)
                                    }}/>
                                </Field>
                            </div>

                            <div className="column is-4">
                                <Field label="Aplica en App Móvil">
                                    <input
                                        id="chkMobileActive"
                                        type="checkbox"
                                        name="MobileActive"
                                        className="switch"
                                        checked={values.MobileActive}
                                        value={values.MobileActive}
                                    />
                                    <label htmlFor="MobileActive" className="inputCenter"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setFieldValue('MobileActive', !values.MobileActive)
                                    }}/>
                                </Field>
                            </div>

                            <div className="column is-4">
                                <Field label="Aplica Regalo Bienvenida" className="has-text-centered">
                                    <input

                                        id="chkGiftApply"
                                        type="checkbox"
                                        name="GiftWelcomeActive"
                                        className="switch"
                                        checked={values.GiftWelcomeActive}
                                        value={values.GiftWelcomeActive}
                                    />
                                    <label htmlFor="GiftWelcomeActive" className="inputCenter" onClick={(e) => {
                                        e.stopPropagation();
                                        setFieldValue('GiftWelcomeActive', !values.GiftWelcomeActive)
                                    }}/>
                                </Field>
                            </div>
                        </div>

                        <br />

                        <div className="has-text-left buttons-modal" id="stepActions">
                            <div className="pull-left">
                                <button id="btnBackStepComplementInfoRecipe" type="button" className="button is-light" onClick={() => this.props.back()} >Anterior</button>

                            </div>
                        </div>
                        <div className="has-text-right buttons-modal" id="stepActions">
                            <div className="pull-right">
                                <button id="btnSaveStepComplementInfoRecipe" type="submit" className="button is-success"  >Continuar</button>
                            </div>
                        </div>

                    </form>
                )}

            </Formik>

        )
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
RecipeComplementInfoStep.defaultProps = {
    isEditing: false,
    recipe: {
        ShortName: "",
        Description: "",
        ClassificationId: 0,
        PresentationId: 0,
        FlavorTypeId: 0,
        RecipeTypeId: 0,
        IVAApply: true,
        KiwiId: 0,
        MilkTypeId: 0,
        GrainTypeId: 0,
        Active: true,
        MobileApply: true,
        WelcomeGiftApply: true,
        ClassificationSatId: 0,
        CodeSatId: 0,
        Details: [],
        GiftWelcomeActive: true
    }
}

export default RecipeComplementInfoStep;