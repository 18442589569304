import React, {useState} from 'react';
import { useEffect } from 'react';
import MenuAppAPI from '../../../../services/Caffenio App/Menu';
import { toast } from 'react-toastify';
import SearchFormV3 from '../../../util/searchform/SearchFormV3';
import TablaMenuApp from './components/TablaMenuApp';
import PortalModal from '../../../../util/portals/PortalModal';
import useModal from '../../../../util/hooks/useModal';
import DetalleClasificacionApp from './DetalleClasificacionApp/DetalleClasificacionApp';
import useForm from '../../../../util/hooks/useForm';

export default function OrdenMenuApp(props){
    
    const [classifications, setClassifications] = useState([])
    const [loading, setLoading] = useState(false)
    const [filterText, setFilterText] = useState("")
    const [currentClassif, setCurrentClassif] = useState(null);
    const detailModal = useModal()

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        setLoading(true);
        MenuAppAPI.getClasificacionesMenu().then(res => {
            setClassifications(res.data);
            setLoading(false);
        }).catch(err => {
            toast.error(err.response.data.Message);
            setLoading(false);
        })
    }

    const feature = (id) => {
        MenuAppAPI.featureClasificacionApp(id, true).then(res => {
            toast.success(res.data.Message);
            getData();
        }).catch(err => toast.error(err.response.data.Message));
    }

    return (
        <div>
            <h1 className="title is-2">Menú App</h1>
            <div className="card">
                <div className="card-content">
                    <SearchFormV3
                        filterText={filterText}
                        onInput={(t) => setFilterText(t)}
                        itemName={'Familia'}
                        icon={'sort'}
                        btnTitle="Ordenar menú"
                        btnAction={() => {props.history.push('/app/menu/ordenar')}}
                        tooltip="Ordenar menú de App"
                    />  
                </div>
            </div>
            <br/>
            <div className="card">
                <div className="card-content">
                    <TablaMenuApp
                        loading={loading}
                        data={classifications.filter(c => c.Name.toLowerCase().includes(filterText.toLowerCase()))}
                        details={(classif) => {
                            setCurrentClassif(classif)
                            detailModal.toggle()
                        }}
                        edit={(classif) => {
                            props.history.push(`/app/menu/clasificacion/${classif.ClassificationId}/editar`)
                        }}
                        feature={feature}
                    />
                </div>
            </div>
            <PortalModal
                isShowing={detailModal.isShowing}
                hide={detailModal.toggle}
                title="Ver detalle"
                body={() => <DetalleClasificacionApp reloadData={() => getData()} classification={currentClassif} inputs={currentClassif} editing={false}/>}
            />
        </div>
    )
}