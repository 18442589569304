import React from 'react';

export default function TipoPromocionDataForm({ inputs, setInput, errors, handleInputChange }) {
    return (
        <div className="columns is-multiline">
            <div className="column is-12">
                <label className="label">Cantidad</label>
                <div className={`control`}>
                    <input
                        type="number"
                        name="amountCodes"
                        min="0"
                        className={`input ${errors.amountCodes && "is-danger"}`}
                        onPaste={(e) => {
                            if (/[^0-9]+/.test(e.clipboardData.getData("Text"))) {
                                e.preventDefault();
                            }
                        }}
                        onKeyPress={(event) => {
                            const keyCode = event.keyCode || event.which;
                            const keyValue = String.fromCharCode(keyCode);
                            if (/[^0-9`]+/.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
                                event.preventDefault();
                        }}
                        maxLength={15}
                        placeholder={`Cantidad`}
                        value={inputs.cantidad}
                        onChange={(e) => handleInputChange(e, true)}
                        required={ inputs.QRType && inputs.QRType.value === 2}
                    />
                </div>
                {errors.amountCodes && <p className="help title has-text-left is-6 is-danger">Cantidad requerida</p>}
            </div>
        </div>
    );
}