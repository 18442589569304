import * as React from 'react';
import SegmentacionAPI from '../../../../services/Caffenio App/Segmentacion';
import { toast } from 'react-toastify';
import to from '../../../../util/to';
import Loading from '../../../util/loading/Loading';
import SegmentacionFormExcel from './components/SegmentacionFormExcel';
import SegmentoDataForm from '../../../Promociones/NuevaPromocion/components/components/SegmentoDataForm';
import useForm from '../../../../util/hooks/useForm';

class NuevaSegmentacionUsuario extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            data: {
                estados: [],
                ciudades: [],
                rangoEdades: [],
                generos: [],
                nivelesLealtad: [],
                horarios: [],
                diasSemana: [],
                clasificaciones: [],
                temperaturas: [],
                membresias: [],
            },
            segmentacion: null,
            isValid: false,
            totalClients: 0,
            loading: false
        }

        if(this.props.match.params.id){
            SegmentacionAPI.getSegmentacion(this.props.match.params.id).then(response => {
                this.prepareSegmentationForEdit(response.data);
            }).catch(err => {
                toast.error("Hubo un error al cargar la información, intente más tarde.")
            });
        }
    }

    
    componentWillMount(){
        this.loadData();
    }

    
    // Se obtiene la información para los campos de segmentaciones de manera asíncrona y se guardan en el state.
    loadData = async () => {
        let err, rangoEdad, estados, generos,horarios, nivelesLealtad, diasSemana, clasificaciones, membresias;
    }

    // Se prepara la segmentación para su edición.
    prepareSegmentationForEdit = (segmentacion) => {
        var temp_seg = segmentacion;

        temp_seg.estado = temp_seg.States.map(e => {return {value: e.StateId, label: e.Name}})

        let classif = this.state.data.clasificaciones.find(c => c.IdFilterFamilyProduct === temp_seg.IdClassification)
        temp_seg.ciudad = temp_seg.Cities.map(c => {return {value: c.CityId, label: c.Name}})
        temp_seg.rangoEdad = temp_seg.AgeRanges.map(r => {return {value: r.IdFilterAge, label: r.Description}})
        temp_seg.horario = temp_seg.PurchaseSchedules.map(h => {return {value: h.IdFilterPurchaseSchedule, label: h.Description}})
        temp_seg.clasificacion = classif ? {value: temp_seg.IdClassification, label: classif.Name} : null
        temp_seg.temperatura = temp_seg.TypesProducts.map(t => {return {value: t.IdFilterTypeProductModel, label: t.Description}});
        temp_seg.nombre = temp_seg.Name;
        temp_seg.refill = temp_seg.Refill;
        temp_seg.genero = temp_seg.Genders;
        temp_seg.nivelLealtad = temp_seg.LoyaltyAccounts;
        temp_seg.diasSemana = temp_seg.PurchaseDays;
        temp_seg.membresias = temp_seg.MembershipTypes;
        this.setState({segmentacion: temp_seg});
    }



    // Se realiza la petición al API para guardar o actualizar la segmentación recibida.
    // @param segmentacion: Objeto de segmentación a guardar o actualizar
    // @param editing: Bandera que indica si se guardará o se editará una segmentación ya existente.
    nuevaSegmentacionUsuario = (segmentacion, editing) => {
        if (!editing){
            SegmentacionAPI.createFile(segmentacion).then(response => {
                toast.success("Segmentación creada correctamente.")
                this.props.history.push({
                    pathname: '/app/segmentaciones',
                    state: this.props.location.state ? {currentPage: this.props.location.state.currentPage || 0, name: this.props.location.state.name || ""} : {currentPage: 1, name: ""}
                });
            }).catch(err =>{
                toast.error(err.response.data.Message);
            })
        }else{
            SegmentacionAPI.editSegmentacion(segmentacion).then(response => {
                toast.success("Segmentación actualizada correctamente.")
                this.props.history.push({
                    pathname: '/app/segmentaciones',
                    state: this.props.location.state ? {currentPage: this.props.location.state.currentPage || 0, name: this.props.location.state.name || ""} : {currentPage: 1, name: ""}
                });
            }).catch(err => {
                toast.error(err.response.data.Message);
            })
        }
        
    }

    // Método para consumir el API para validar la segmentación.
    validarSegmentacion = (segmentacion) => {
        this.setState({loading: true}, () =>{
            SegmentacionAPI.createFile(segmentacion).then(response => {
                toast.success("Segmentación creada correctamente.")
                this.props.history.push({
                    pathname: '/app/segmentaciones',
                    state: this.props.location.state ? {currentPage: this.props.location.state.currentPage || 0, name: this.props.location.state.name || ""} : {currentPage: 1, name: ""}
                });
            }).catch(err =>{
                toast.error(err.response.data.Message);
            })
        })
    }

    // Método para, ante cualquier cambio hecho en una segmentación ya validada, requerir una nueva validación de la segmentación 
    invalidate = () => {
        this.setState({isValid: false});
    }

    render(){
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa fa-arrow-left" style={{cursor: "pointer", color: "#000000" }} onClick={() => {
                                    this.props.history.push({
                                        pathname: '/app/segmentaciones',
                                        state: this.props.location.state ? {currentPage: this.props.location.state.currentPage || 0, name: this.props.location.state.name || ""} : {currentPage: 1, name: ""}
                                    });
                                    }}></i>
                            </span>
                            {this.props.match.params.id ? "Editar segmentación" : "Nueva segmentación"}
                        </div>
                    </div>
                    
                    <div className="card-content">
                        <SegmentacionFormExcel
                            {...this.props}
                            save={(seg, editing) => this.nuevaSegmentacionUsuario(seg, editing)}
                            validate={(seg) => this.validarSegmentacion(seg)}
                            {...this.state.data}
                            isValid ={this.state.isValid}
                            segmentacion={this.state.segmentacion}
                            editing={this.state.segmentacion !== null}
                            invalidate={this.invalidate}
                            totalClients={this.state.totalClients}
                        />
                    </div>
                </div>
                <Loading isFullscreen={true} isLoading={this.state.loading} width='100px' height='100px'/>
            </div>
        )
    }
}

export default NuevaSegmentacionUsuario;