import React, { Component } from 'react';
import Modal from '../../../util/modal/Modal';
import NewRecipeDetail from './NewRecipeDetail';
import SelectDriveForm from './SelectDriveForm';
import RecipeDrivesDetail from './RecipeDrivesDetail';
import RecipeDetails from './RecipeDetails';
import "./RecipeCardStyle.css"
import { toast } from 'react-toastify';
import RecipeToppings from './RecipeToppings';
import lodash from 'lodash';

export default class RecipeCard extends Component {
    //TODO: Hacer un copy pasterino de RecipeFormMulti caso de select de los drives
    constructor(props) {
        super(props)
        this.state = {
            Detail: [],
            detailModal: false,
            detailModalDetalle: false,
            modalDrive: false,
            currentDetailLabel: "",
            currentDetailIndex: 0,
            //Lo necesario para manejo de selects de drives
            Caffenios: { ...this.props.Caffenios },
            optionState: Object.values({ ...this.props.optionState }),
            optionCity: Object.values({ ...this.props.optionCity }),
            optionDrive: Object.values({ ...this.props.optionDrive }),
            selectedOption: [],
            selectedOptionC: [],
            selectedOptionD: [],
            mostrarDrivesModal: false,
            recipeDetail: {},
            currentTipoLabel: "",
            toppingModal: false,
            detailModalTopping: false,
            currentRecipeDetails: [],
        }
    }

    eliminarObjDuplicados = (arr, prop) => {
        var nuevoArray = [];
        var lookup = {};

        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }

        for (i in lookup) {
            nuevoArray.push(lookup[i]);
        }

        return nuevoArray;
    }
    select_state(Caffenios) { // Llenar el combo 'Estados' con todas las opciones 
        var newArray = [];
        let OptionSelect = [];
        var lookupObject = {};

        Caffenios.map((item) => {
            OptionSelect.push({ value: item.StateId, label: item.State });
        });

        for (var i in OptionSelect) {
            lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    select_city(Caffenios) { // Llenar el combo 'Ciudades' con todas las opciones 
        var newArray = [];
        let OptionSelectCity = [];
        var lookupObject = {};

        Caffenios.map((item) => {
            OptionSelectCity.push({ value: item.Municipalityid, label: item.Municipality });
        });

        for (var i in OptionSelectCity) {
            lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    select_Drive(Caffenios) { // Llenar el combo 'Drives' con todas las opciones 
        var newArray = [];
        let OptionSelectDrive = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectDrive.push({ value: item.idCaffenio, label: item.NombreNuevo });
        });

        for (var i in OptionSelectDrive) {
            lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return newArray;
    }

    changeSelectEstado = (obj) => {
        let state = { ...this.state }
        state["selectedOption"] = obj;
        let idEstado = obj.map(estado => { return estado.value });
        let objCaffenios = Object.values(state.Caffenios);
        let listaCiudades = [];
        let listaDrives = [];

        // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
        objCaffenios.map(item => {
            for (var i = 0; i < idEstado.length; i++) {
                if (item.StateId === idEstado[i]) {
                    listaCiudades.push({ "value": item.Municipalityid, "label": item.Municipality });
                }
            }
        })
        //Se eliminan las ciudades duplicadas
        let eliminarDuplicados = this.eliminarObjDuplicados(listaCiudades, "value");
        //Se obtiene un arreglo con los id de las ciudades
        let idCiudad = eliminarDuplicados.map(ciudad => { return ciudad.value });

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item => {
            for (var i = 0; i < idCiudad.length; i++) {
                if (item.Municipalityid === idCiudad[i]) {
                    listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                }
            }
        })

        //Se limpian los combos 'Ciudades' y 'Drives'
        state["selectedOptionC"] = [];
        state["selectedOptionD"] = [];

        //Se valida que haya una opción seleccionada en combo 'Estados'
        if (obj.length === 0) {
            state["optionCity"] = this.select_city(Object.values(state.Caffenios));
            state["optionDrive"] = this.select_Drive(Object.values(state.Caffenios));

        } else {
            state["optionCity"] = eliminarDuplicados;
            state["optionDrive"] = listaDrives;
        }
        this.setState(state);
    }

    changeSelectCiudad = (obj) => {
        let state = { ...this.state }
        let idCiudad = obj.map(ciudad => { return ciudad.value });
        let objCaffenios = Object.values(state.Caffenios);
        let listaDrives = [];

        //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
        objCaffenios.map(item => {
            for (var i = 0; i < idCiudad.length; i++) {
                if (item.Municipalityid === idCiudad[i]) {
                    listaDrives.push({ "value": item.idCaffenio, "label": item.NombreNuevo });
                }
            }
        })

        //Se limpia el combo 'Drives'
        state["selectedOptionD"] = [];

        //Se valida que haya una opción seleccionada en combo 'Ciudades'
        if (obj.length > 0) {
            state["selectedOptionC"] = obj;
            state["optionDrive"] = listaDrives;
            //this.setState(state, () => this.FilterProcess());
        } else {
            this.changeSelectEstado(state["selectedOption"]);
        }
        this.setState(state);

    }
    changeSelectDrive = (obj) => {
        let state = { ...this.state };
        let Caffenios = Object.values(state.Caffenios);

        //Valida cada select (Estados,Ciudades,Drives) y realiza la acción correspondiente
        if (obj.length > 0) {
            state["selectedOptionD"] = obj;
            //this.setState(state, () => this.FilterProcess());
        } else if (state["selectedOptionC"].length === 0) {
            this.changeSelectEstado(state["selectedOption"]);
            state["selectedOptionD"] = [];
        } else if (state["selectedOptionC"].length === 0 && state["selectedOption"].length === 0) {
            this.select_Drive(Caffenios);
            state["selectedOptionD"] = [];
        } else {
            this.changeSelectCiudad(state["selectedOptionC"]);
            state["selectedOptionD"] = [];
        }
        this.setState(state)
    }

    /**
     * Función que se ejecuta cuando se modifica la cantidad de un detalle en la tarjeta
     * @param value Number. Valor nuevo del detalle
     * @param indexArticulo Number. Indice en donde se encuentra el detalle en el arreglo de Details
     * @param tipoDetalleLabel String. Indica a que tipo pertence el detalle, ejemplo: TipoSabor, TipoLeche, TipoGrano, etc.
     */
    handleDetailChange = (value, indexArticulo, tipoDetalleLabel) => {
        let detalles = tipoDetalleLabel == "Details" ? [...this.props.receta.Details] : [...this.props.receta[tipoDetalleLabel][this.state.currentDetailIndex].Details]
        detalles[indexArticulo].Amount = value;
        detalles[indexArticulo].InventoryItem.Amount = value;
        this.props.onDetailChange(detalles, tipoDetalleLabel, this.state.currentDetailIndex)
    }

    // Ver firma de la función handleDetailChange
    /**
     *Función que establece valores temporales a las cantidades de detalles.
     *@param value: valor temporal para el detalle
     *@param indexArticulo: actualmente no en uso
     *@param tipoDetalleLabel: Indica si es topping, detalle, tipoLeche, tipoGrano, etc
     */
    handleDetailChangeTemporal = (value, indexArticulo, tipoDetalleLabel) => {
        let detalles = [...this.state.currentRecipeDetails]
        detalles[indexArticulo].Amount = value;
        detalles[indexArticulo].InventoryItem.Amount = value;
        this.setState({ currentRecipeDetails: detalles })
    }

    /**
     *Función que establece valores definitivos a las cantidades de detalles
     *@param indexArticulo: actualmente no en uso
     *@param tipoDetalleLabel: Indica si es topping, detalle, tipoLeche, tipoGrano, etc
     */
    handleDetailChangeSave = (indexArticulo, tipoDetalleLabel) => {
        let detalles = [...this.state.currentRecipeDetails]

        this.props.onDetailChange(detalles, tipoDetalleLabel, this.state.currentDetailIndex)
    }

    // Ver firma de la función handleDetailChange
    /**
     *Función que cancela los valores temporales si el usuario sale del modal de detalles
     *@param indexArticulo: actualmente no en uso
     *@param tipoDetalleLabel: Indica si es topping, detalle, tipoLeche, tipoGrano, etc
     */
    handleDetailChangeClose = (indexArticulo, tipoDetalleLabel) => {
        let detalles = tipoDetalleLabel === "Details" ? [...this.props.receta.Details] : [...this.props.receta[tipoDetalleLabel][this.state.currentDetailIndex].Details]

        for (let i = 0; i < detalles.length; i++) {
            if (detalles[i].AmountTemporal) {
                detalles[i].InventoryItem.Amount = detalles[i].AmountTemporal;
            } else {
                if (typeof detalles[i].InventoryItem.edicionTemporal !== 'undefined') {
                    detalles[i].InventoryItem.Amount = detalles[i].InventoryItem.edicionTemporal;
                }
            }
        }
        this.props.onDetailChange(detalles, tipoDetalleLabel, this.state.currentDetailIndex)
    }

    /**
     * Funcion que se ejecuta cuando se remueve un detalle de la tarjeta
     * @param indexDetalle Number. Indice en donde se encuentra el detalle en el arreglo de Details
     * @param tipoDetalleLabel String. Indica a que tipo pertence el detalle, ejemplo: TipoSabor, TipoLeche, TipoGrano, etc.
     */
    handleDetailRemoval = (indexDetalle, tipoDetalleLabel) => {
        let detalles = tipoDetalleLabel === "Details" ? [...this.props.receta.Details] : [...this.state.currentRecipeDetails]
        detalles.splice(indexDetalle, 1);
        if (tipoDetalleLabel === "Details") {
            this.props.onDetailChange(detalles, tipoDetalleLabel, this.state.currentDetailIndex)
        } else {
            this.setState({ currentRecipeDetails: detalles });
        }
    }

    /** Función que se ejecuta cualdo se quita un drive  del artículo inventario*/
    removeDriveDetail = (itemToRemove) => {
        let state = { ...this.state };
        let detalles = state.labelType === "Details" ? [...this.props.receta.Details] : [...this.state.currentRecipeDetails]            
       
        let detalleToEdit = detalles[state.detailIndex];
        if (detalleToEdit) {
            detalleToEdit.InventoryItem.DriveId = detalleToEdit.InventoryItem.DriveId 
            ? detalleToEdit.InventoryItem.DriveId.filter(d => d.idDrive !== itemToRemove.idCaffenio)
            : this.props.Caffenios.filter(c => {
                if(c.idCaffenio !== itemToRemove.idCaffenio){
                    c.value = c.idCaffenio;
                    c.label = c.NombreNuevo;
                    c.DriveMunicipio = c.Municipality;
                    c.DriveEstado = c.State;
                    c.idDrive = c.idCaffenio;
                    detalleToEdit.idDrive = c.idCaffenio;
                    return c;
                }
            });
            detalleToEdit.InventoryItem.driveList = detalleToEdit.InventoryItem.driveList.filter(val => val !== itemToRemove.idCaffenio);            
            
            let cities = detalleToEdit.InventoryItem.DriveId.reduce((cities, item) => {
                if (!cities.includes(item.DriveMunicipio) && !cities.includes(item.Municipality)){
                    cities.push(item.DriveMunicipio ? item.DriveMunicipio :  item.Municipality)
                }
                return cities;
            }, []);
            let estados = detalleToEdit.InventoryItem.DriveId.reduce((estados, item) => {
                if (!estados.includes(item.DriveEstado) && !estados.includes(item.State) ) {
                    estados.push(item.DriveEstado ? item.DriveEstado : item.State)
                }
                return estados;
            }, []);
            
            detalleToEdit.InventoryItem.DriveNombre = detalleToEdit.InventoryItem.driveList.length >= this.props.Caffenios.length  
                ? "Todos"
                :  detalleToEdit.InventoryItem.DriveId.length <= 4
                    ? detalleToEdit.InventoryItem.DriveId.map(d => d.Name).join(', ')
                    : cities.length > 4
                        ?estados.join(', ')
                        :cities.join(', ');

            detalles[state.detailIndex] = detalleToEdit;
        }
        this.props.onDetailChange(detalles, state.labelType, this.state.currentDetailIndex)
       
    }

    /**
     * Funcion que se ejecuta al cambiar la cantidad de un topping
     * @param value Number. Valor nuevo del topping
     * @param labelQuantity String. Etiqueta que indica si se esta modificando el MinQuantity o MaxQuantity
     * @param indexTopping Number. Indice en donde se encuentra el topping en el arreglo de Details
     * @param tipoToppingLabel String. Indica a que tipo pertence el topping, ejemplo: TipoSabor, TipoLeche, TipoGrano, etc.
     */
    handleToppingChange = (value, labelQuantity, indexTopping, tipoToppingLabel) => {
        let toppings = tipoToppingLabel === "Toppings" ? [...this.props.receta.Toppings] : [...this.props.receta[tipoToppingLabel][this.state.currentDetailIndex].Toppings];
        toppings[indexTopping][labelQuantity] = value;
        this.props.onToppingChange(toppings, tipoToppingLabel, this.state.currentDetailIndex)
    }

    /**
     * Funcion que establece valore temporales para minimos y maximos los toppings
     * @param value: Valor temporal para el minimo o maximo
     * @param labelQuantity: String. Etiqueta que indica si se esta modificando el MinQuantity o MaxQuantity
     * @param indexTopping Number. Indice en donde se encuentra el topping en el arreglo de Details
     */
    handleToppingChangeTemporal = (value, labelQuantity, indexTopping) => {
        let toppings = [...this.state.currentRecipeDetails]
        let newValue = value && value.length > 0 ? parseInt(value) : '';
        if(newValue !== ''){                            
            if(labelQuantity =='MinQuantity'){
                if(newValue > toppings[indexTopping]['MaxQuantity'] && toppings[indexTopping]['MaxQuantity'] !==''){
                    toast.dismiss()
                    toast.error("La cantidad miníma no puede ser mayor a la cantidad máxima.")
                    return;
                }
            }
            else if(labelQuantity =='MaxQuantity'){
                if(newValue < toppings[indexTopping]['MinQuantity'] && toppings[indexTopping]['MinQuantity'] !== ''){
                    toast.dismiss()
                    toast.error("La cantidad máxima no puede ser menor a la cantidad mínima.")
                    return;
                }
            } 
        }     

        toppings[indexTopping][labelQuantity] = newValue;
        this.setState({ currentRecipeDetails: toppings })
    }

    /**
     * Funcion que establece los valores temporales
     * @param tipoToppingLabel. String. Indica a que tipo pertence el topping, ejemplo: TipoSabor, TipoLeche, TipoGrano, etc.
     * @param closeModal. Funcion que se ejecuta en caso de valores válidos y cierra el modal.
     */
    handleToppingChangeSave = (tipoToppingLabel, closeModal) => {
        let toppings = [...this.state.currentRecipeDetails]

        // Iterar sobre cada topping
        for (let i = 0; i < toppings.length; i++) {
            // Ver si tiene los campos MinQuantity y MaxQuantity
            // y validar los valores.
            if(toppings[i]["MinQuantity"] === '' || toppings[i]["MaxQuantity"] ===''){
                toast.dismiss()
                toast.error("Ingrese los valores mínimos y máximos para " + toppings[i]["Name"]);
                return;
            }
            // if (!Number.isNaN(Number(toppings[i]["MinQuantity"])) && !Number.isNaN(Number(toppings[i]["MaxQuantity"]))) {
            //     if (Number(toppings[i]["MinQuantity"]) > Number(toppings[i]["MaxQuantity"])) {
            //         toast.error("Límites no válidos para el topping " + toppings[i]["Name"]);
            //         return;
            //     }
            // } else {
            //     toast.error("Límites no válidos para el topping " + toppings[i]["Name"]);
            //     return;
            // }
        }
        this.props.onToppingChange(toppings, tipoToppingLabel, this.state.currentDetailIndex)
        closeModal();
    }

    /**
     * Funcion que se ejecuta al cambiar la cantidad de un topping
     * @param indexTopping Number. Indice en donde se encuentra el topping en el arreglo de Details
     * @param tipoToppingLabel String. Indica a que tipo pertence el topping, ejemplo: TipoSabor, TipoLeche, TipoGrano, etc.
     */
    handleToppingRemoval = (indexTopping, tipoToppingLabel) => {
        if (tipoToppingLabel === "Toppings") {
            let toppings = [...this.props.receta.Toppings]
            toppings.splice(indexTopping, 1);
            this.props.onToppingChange(toppings, tipoToppingLabel, this.state.currentDetailIndex)
        } else {
            let toppings = [...this.state.currentRecipeDetails]
            toppings.splice(indexTopping, 1);
            this.setState({ currentRecipeDetails: toppings })
        }
    }

    render() {
        let tituloCard = []
        if (this.props.receta.ClassificationId != 15) tituloCard.push(this.props.receta.Nombres.Clasificacion)
        if (this.props.receta.Nombres.TipoPresentacion && this.props.receta.Nombres.TipoPresentacion.toLowerCase() != "sin presentación") tituloCard.push(this.props.receta.Nombres.TipoPresentacion)
        if (this.props.receta.Nombres.TipoReceta && this.props.receta.Nombres.TipoReceta.toLowerCase() != "sin tipo") tituloCard.push(this.props.receta.Nombres.TipoReceta)
        // if(this.props.receta.Nombres.TipoPresentacion.toLowerCase() != "sin presentación") tituloCard.push(this.props.receta.Nombres.TipoPresentacion)
        // tituloCard.push(this.props.receta.Nombres.TipoReceta)

        return (
            <div className="column is-12">
                <div className="card">
                    <header className="card-header card-header-receta">
                        <p className="card-header-title">
                            {/* Capuccinos 20oz Caliente */}
                            {
                                tituloCard.join(" ")
                            }
                        </p>
                    </header>
                    <div className="card-content">
                        {/* Detalles Generales */}
                        <div className="columns is-multiline">

                            {/* Detalles de receta */}
                            <div className="column is-12">
                                <button className="button is-rounded is-info" onClick={() => this.setState({ modalDrive: true, currentDetailLabel: "Details" })}>
                                    <span className="icon">
                                        <i className="fa fa-plus"></i>
                                    </span>
                                    <span>Artículo inventario</span>
                                </button>
                            </div>
                            <div className="column is-12">
                                <RecipeDetails
                                    details={this.props.receta.Details.map(detalle => { return { ...detalle.InventoryItem, DriveNombre: detalle.InventoryItem.DriveNombre } })}
                                    quantityChanged={(value, index) => {
                                        if (value > 0) {
                                            this.handleDetailChange(value, index, "Details")
                                        } else {
                                            toast.warn("La cantidad debe ser mayor a cero.")
                                        }                                        
                                    }}
                                    removeDetail={(recipe) => {
                                        this.handleDetailRemoval(recipe, "Details")
                                    }}
                                    viewDrivesDetail={(recipe, detailIndex) => {
                                        this.setState({ detailDrivesModal: true, recipeDetail: recipe, detailIndex: detailIndex, labelType: "Details" })
                                    }}
                                    mostrarDrives={true}
                                    onMostrarDrives={(recipe) => {
                                        this.setState({ mostrarDrivesModal: true, recipeDetail: recipe })
                                    }}
                                />
                            </div>

                            {/* Toppings de receta */}
                            <div className="column is-12">
                                <button className="button is-rounded is-info" onClick={() => this.setState({ toppingModal: true, currentDetailLabel: "Toppings" })}>
                                    <span className="icon">
                                        <i className="fa fa-plus"></i>
                                    </span>
                                    <span>Toppings generales</span>
                                </button>
                            </div>
                            <div className="column is-12">
                                <RecipeToppings
                                    details={this.props.receta.Toppings}
                                    quantityChanged={(value, index, labelQuantity) => {
                                        let toppings = [...this.props.receta.Toppings];
                                        let newValue = value && value.length > 0 ? parseInt(value) : '';
                                        if(newValue !== ''){
                                            if(labelQuantity =='MinQuantity'){
                                                if(newValue > toppings[index]['MaxQuantity'] && toppings[index]['MaxQuantity'] !==''){
                                                    toast.dismiss()
                                                    toast.error("La cantidad miníma no puede ser mayor a la cantidad máxima.")
                                                    return;
                                                }
                                            }
                                            else if(labelQuantity =='MaxQuantity'){
                                                if(newValue < toppings[index]['MinQuantity'] && toppings[index]['MinQuantity'] !== ''){
                                                    toast.dismiss()
                                                    toast.error("La cantidad máxima no puede ser menor a la cantidad mínima.")
                                                    return;
                                                }
                                            }
                                        }
                                                                              
                                        
                                        this.handleToppingChange(newValue, labelQuantity, index, "Toppings")
                                                                               
                                    }}
                                    removeDetail={(recipe) => {                                        
                                        this.handleToppingRemoval(recipe, "Toppings")
                                        
                                    }}
                                />
                            </div>

                        </div>
                        <hr/>
                        {/* Seccion Tipo Sabor */}
                        {
                            (this.props.receta.TipoSabor && (
                                (this.props.receta.TipoSabor.length == 1 && this.props.receta.TipoSabor[0].Nombre.toLowerCase() != "sin sabor")
                                || this.props.receta.TipoSabor.length > 1
                            )) ?
                                <table className="table is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>Tipo Sabor</th>
                                            <th>Acción</th>
                                            <th>Ver</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.receta.TipoSabor.map((sabor, index) => {
                                            let hayError = false;
                                            let errorTopping = sabor.Toppings.filter(topping => { return topping.MaxQuantity === undefined || topping.MinQuantity === undefined || (Number(topping.MinQuantity) === 0 && Number(topping.MaxQuantity) === 0) })
                                            let errorDetail = sabor.Details.filter(item => { return item.InventoryItem.Amount == undefined || Number(item.InventoryItem.Amount) <= 0 })
                                            let errorMsgs = []
                                            if (errorTopping.length > 0) {
                                                hayError = true
                                                errorMsgs.push("Uno o mas toppings no tienen cantidades o ambas cantidades son 0")
                                            }
                                            if (errorDetail.length > 0) {
                                                hayError = true
                                                errorMsgs.push("Uno o mas articulos inventario no tienen cantidades o tienen 0")
                                            }
                                            return <tr key={sabor.FlavorTypeId + "-" + sabor.Nombre}
                                                className={(sabor.Details.length > 0 || sabor.Toppings.length > 0) ?
                                                    (hayError ? "card-detail-warning" : "card-detail-modified")
                                                    : ""
                                                }
                                            >
                                                <td style={{ position: "relative" }}>
                                                    {
                                                        (sabor.Details.length > 0 || sabor.Toppings.length > 0) &&
                                                        <div className="card-detail-icon tooltip tooltip-multiline"
                                                            data-tooltip={hayError ? errorMsgs.join("\r\n") : "Todos los detalles estan completos"}>
                                                            <i className={hayError ? "fa fa-exclamation" : "fa fa-check"}
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                    }
                                                    {sabor.Nombre}
                                                </td>
                                                <td>
                                                    <div className="buttons">
                                                        <a className="button is-small is-success tooltip"
                                                            data-tooltip="Asignar artículos"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({
                                                                    modalDrive: true,
                                                                    currentDetailLabel: "TipoSabor",
                                                                    currentTipoLabel: sabor.Nombre,
                                                                    currentDetailIndex: index
                                                                })
                                                            }
                                                            }>
                                                            <span className="icon">
                                                                <i className="fa fa-plus" />
                                                            </span>
                                                            <span>Artículos</span>
                                                        </a>
                                                        <a className="button is-small is-info tooltip"
                                                            data-tooltip="Asignar toppings"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({
                                                                    toppingModal: true,
                                                                    currentDetailLabel: "TipoSabor",
                                                                    currentTipoLabel: sabor.Nombre,
                                                                    currentDetailIndex: index
                                                                })
                                                            }
                                                            }>
                                                            <span className="icon">
                                                                <i className="fa fa-plus" />
                                                            </span>
                                                            <span>Topping</span>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="buttons">
                                                        <a className="button is-small is-warning tooltip"
                                                            data-tooltip="Ver artículos asignados"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                let detallesTemporales = this.props.receta["TipoSabor"][index].Details != null ?
                                                                    lodash.cloneDeep(this.props.receta["TipoSabor"][index].Details)
                                                                    : []
                                                                this.setState({
                                                                    detailModalDetalle: true,
                                                                    currentDetailLabel: "TipoSabor",
                                                                    currentTipoLabel: sabor.Nombre,
                                                                    currentDetailIndex: index,
                                                                    currentRecipeDetails: detallesTemporales,
                                                                    labelType: "TipoSabor"
                                                                })
                                                            }
                                                            }>
                                                            <span className="icon">
                                                                <i className="fa fa-pencil" />
                                                            </span>
                                                            <span>Artículos</span>
                                                        </a>
                                                        <a className="button is-small is-info tooltip"
                                                            data-tooltip="Ver toppings asignados"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                let toppingsTemporal = lodash.cloneDeep(this.props.receta["TipoSabor"][index].Toppings)
                                                                this.setState({
                                                                    detailModalTopping: true,
                                                                    currentDetailLabel: "TipoSabor",
                                                                    currentTipoLabel: sabor.Nombre,
                                                                    currentDetailIndex: index,
                                                                    currentRecipeDetails: toppingsTemporal
                                                                })
                                                            }
                                                            }>
                                                            <span className="icon">
                                                                <i className="fa fa-pencil" />
                                                            </span>
                                                            <span>Toppings</span>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        })}
                                        {/* <td>{this.props.receta.Nombres.Flavor}</td> */}
                                    </tbody>
                                </table>
                                : ""
                        }

                        {/* Seccion Tipo Leche */}
                        {
                            (this.props.receta.TipoLeche && (
                                (this.props.receta.TipoLeche.length == 1 && this.props.receta.TipoLeche[0].Nombre.toLowerCase() != "sin tipo")
                                || this.props.receta.TipoLeche.length > 1
                            )) ?
                                <table className="table is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>Tipo de leche</th>
                                            <th>Acción</th>
                                            <th>Ver</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.receta.TipoLeche.map((leche, index) => {
                                            return <tr
                                                key={leche.MilkTypeId + "-" + leche.Nombre}
                                                className={leche.Details.length > 0 ?
                                                    (leche.Details.filter(item => { return item.InventoryItem.Amount == undefined || Number(item.InventoryItem.Amount) <= 0 }).length > 0 ?
                                                        "card-detail-warning" : "card-detail-modified")
                                                    : ""
                                                }
                                            >
                                                <td style={{ position: "relative" }}>
                                                    {
                                                        leche.Details.length > 0 &&
                                                        <div className="card-detail-icon tooltip"
                                                            data-tooltip={leche.Details.filter(item => { return item.InventoryItem.Amount == undefined || Number(item.InventoryItem.Amount) <= 0 }).length > 0 ? "Uno o mas detalles no tienen cantidades o tienen 0" : "Todos los detalles estan completos"}>
                                                            <i className={leche.Details.filter(item => { return item.InventoryItem.Amount == undefined || Number(item.InventoryItem.Amount) <= 0 }).length > 0 ? "fa fa-exclamation" : "fa fa-check"}
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                    }
                                                    {leche.Nombre}</td>
                                                <td>
                                                    <a className="button tableAction is-success tooltip"
                                                        data-tooltip="Asignar artículos"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.setState({
                                                                modalDrive: true,
                                                                currentDetailLabel: "TipoLeche",
                                                                currentTipoLabel: leche.Nombre,
                                                                currentDetailIndex: index
                                                            })
                                                        }
                                                        }>
                                                        <span className="icon">
                                                            <i className="fa fa-edit" />
                                                        </span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a className="button tableAction is-warning tooltip"
                                                        data-tooltip="Ver artículos asignados"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            let detallesTemporales = this.props.receta["TipoLeche"][index].Details != null ?
                                                                lodash.cloneDeep(this.props.receta["TipoLeche"][index].Details)
                                                                : []
                                                            this.setState({
                                                                detailModalDetalle: true,
                                                                currentDetailLabel: "TipoLeche",
                                                                currentTipoLabel: leche.Nombre,
                                                                currentDetailIndex: index,
                                                                currentRecipeDetails: detallesTemporales,
                                                                labelType: "TipoLeche"
                                                            })

                                                        }
                                                        }>
                                                        <span className="icon">
                                                            <i className="fa fa-pencil" />
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                                : ""
                        }

                        {/* Seccion Tipo Grano */}
                        {
                            (this.props.receta.TipoGrano && (
                                (this.props.receta.TipoGrano.length == 1 && this.props.receta.TipoGrano[0].Nombre.toLowerCase() != "sin tipo")
                                || this.props.receta.TipoGrano.length > 1)
                            ) ?
                                <table className="table is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>Tipo Grano</th>
                                            <th>Acción</th>
                                            <th>Ver</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.receta.TipoGrano.map((grano, index) => {
                                            return <tr
                                                key={grano.GrainTypeId + "-" + grano.Nombre}
                                                className={grano.Details.length > 0 ?
                                                    (grano.Details.filter(item => { return item.InventoryItem.Amount == undefined || Number(item.InventoryItem.Amount) <= 0 }).length > 0 ?
                                                        "card-detail-warning" : "card-detail-modified")
                                                    : ""
                                                }
                                            >
                                                <td style={{ position: "relative" }}>
                                                    {
                                                        grano.Details.length > 0 &&
                                                        <div className="card-detail-icon tooltip"
                                                            data-tooltip={grano.Details.filter(item => { return item.InventoryItem.Amount == undefined || Number(item.InventoryItem.Amount) <= 0 }).length > 0 ? "Uno o mas detalles no tienen cantidades o tienen 0" : "Todos los detalles estan completos"}>
                                                            <i className={grano.Details.filter(item => { return item.InventoryItem.Amount == undefined || Number(item.InventoryItem.Amount) <= 0 }).length > 0 ? "fa fa-exclamation" : "fa fa-check"}
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                    }
                                                    {grano.Nombre}
                                                </td>
                                                <td>
                                                    <a className="button tableAction is-success tooltip"
                                                        data-tooltip="Asignar artículos"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.setState({
                                                                modalDrive: true,
                                                                currentDetailLabel: "TipoGrano",
                                                                currentTipoLabel: grano.Nombre,
                                                                currentDetailIndex: index
                                                            })
                                                        }
                                                        }>
                                                        <span className="icon">
                                                            <i className="fa fa-edit" />
                                                        </span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a className="button tableAction is-warning tooltip"
                                                        data-tooltip="Ver artículos asignados"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            let detallesTemporales = this.props.receta["TipoGrano"][index].Details != null ?
                                                                lodash.cloneDeep(this.props.receta["TipoGrano"][index].Details)
                                                                : []
                                                            this.setState({
                                                                detailModalDetalle: true,
                                                                currentDetailLabel: "TipoGrano",
                                                                currentTipoLabel: grano.Nombre,
                                                                currentDetailIndex: index,
                                                                currentRecipeDetails: detallesTemporales,
                                                                labelType: "TipoGrano"
                                                            })

                                                        }
                                                        }>
                                                        <span className="icon">
                                                            <i className="fa fa-pencil" />
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                                : ""
                        }
                    </div>

                </div>

                {/* Modales */}

                {/* Modal para seleccionar articulo inventario por drive */}
                {(this.state.modalDrive || this.state.detailModal) &&
                    <Modal
                        showModal={this.state.modalDrive || this.state.detailModal}
                        modalTitle={this.state.detailModal ? "Agregar artículo inventario" : "Seleccionar Drive(s)"}
                        customclassName="modalDrives"
                        cancel={() => this.setState({
                            modalDrive: false,
                            detailModal: false,
                            selectedOption: [],
                            selectedOptionC: [],
                            selectedOptionD: [],
                        })}
                        width="75%"
                    >
                        <div className="columns is-multiline is-centered">
                            {this.state.detailModal
                                ? <NewRecipeDetail
                                    addDetail={(detail) => {
                                        let detalles = []
                                        if (this.state.currentDetailLabel === "Details") {
                                            detalles = [...this.props.receta.Details]
                                        } else {
                                            let detallesBase = this.props.receta[this.state.currentDetailLabel][this.state.currentDetailIndex].Details ? [...this.props.receta[this.state.currentDetailLabel][this.state.currentDetailIndex].Details] : []
                                            for (let i = 0; i < detallesBase.length; i++) {
                                                detalles.push({ ...detallesBase[i] })
                                            }
                                        }
                                        let exists=detalles.filter(d => d.InventoryItemId === detail.InventoryItemId && Number(d.Amount) === Number(detail.Amount));
                                        if (exists.length > 0) {
                                            toast.warn("No se puede agregar el mismo artículo con la misma cantidad.")
                                        } else {
                                            detail.DriveId = [...this.state.selectedOptionD.map(selected => {
                                                if (!selected.idDrive) {
                                                    selected.idDrive = selected.idCaffenio;
                                                }
                                                return selected;
                                            })];
                                            let totalDrives = this.props.Caffenios.length;
                                            let pushStates = [];
                                            let pushCities = [];       
                                            detail.DriveNombre = this.state.selectedOptionD.length > 4
                                                ? this.state.selectedOptionD.length === totalDrives
                                                    ? "Todos"
                                                    : this.state.selectedOptionD.reduce((list, item) => {
                                                        if (!list.some(l => l.Municipalityid === item.Municipalityid)) {
                                                            list.push(item);
                                                            pushCities.push(item.Municipality);
                                                        }
                                                        return list
                                                    }, []).length > 4
                                                        ? this.state.selectedOptionD.reduce((list, item) => {
                                                            if (!list.some(l => l.StateId === item.StateId)) {
                                                                list.push(item);
                                                                pushStates.push(item.State);
                                                            }
                                                            return list;
                                                        }, []).length > 1
                                                            ? pushStates.join(", ")
                                                            : this.state.selectedOptionD[0].State
                                                        : pushCities.join(", ")
                                                : this.state.selectedOptionD.map(d => { return d.NombreNuevo }).join(", ");
                                                                                   
                                            detail.driveList = detail.DriveId.map(d => d.idCaffenio);    
                                            detalles.push({ InventoryItem: detail, Amount: detail.Amount })
                                            this.props.onDetailChange(detalles, this.state.currentDetailLabel, this.state.currentDetailIndex);
                                            toast.success("Detalle agregado");

                                        }
                                        
                                    }}
                                    currentDetails={
                                        //TODO: checar si los drives que estamos enviando ya estan en la lista de los drives del item.Drives
                                        this.state.currentDetailLabel === "Details" ?
                                            this.props.receta.Details.concat(
                                                [].concat(...this.props.receta.TipoSabor.filter(item => item.Details != null).map(item => item.Details)),
                                                [].concat(...this.props.receta.TipoLeche.filter(item => item.Details != null).map(item => item.Details)),
                                                [].concat(...this.props.receta.TipoGrano.filter(item => item.Details != null).map(item => item.Details))
                                            ).filter(det => {
                                                let caffenios = this.state.selectedOptionD.length === 0 ? [...this.state.optionDrive] : [...this.state.selectedOptionD]

                                                return caffenios.findIndex(dri => {
                                                    return det.InventoryItem.DriveId.findIndex(id => id.value == dri.value) != -1
                                                }) != -1

                                            }).map(det => det.InventoryItem.InventoryItemId)
                                            : (this.props.receta[this.state.currentDetailLabel][this.state.currentDetailIndex].Details ?
                                                this.props.receta.Details.concat(this.props.receta[this.state.currentDetailLabel][this.state.currentDetailIndex].Details).filter(det => {
                                                    let caffenios = this.state.selectedOptionD.length === 0 ? [...this.state.optionDrive] : [...this.state.selectedOptionD]

                                                    return caffenios.findIndex(dri => {
                                                        return det.InventoryItem.DriveId.findIndex(id => id.value == dri.value) != -1
                                                    }) != -1

                                                }).map(det => det.InventoryItem.InventoryItemId)
                                                : []
                                            )
                                    }
                                    isDetail={true}
                                />
                                : <SelectDriveForm
                                    optionState={this.props.optionState}
                                    Caffenios={this.props.Caffenios}
                                    closeModal={() => this.setState({ modalDrive: false, detailModal: true })}
                                    setStateFunc={(value) => this.setState({ selectedOption: value })}
                                    setCityFunc={(value) => this.setState({ selectedOptionC: value })}
                                    setDriveFunc={(value => this.setState({ selectedOptionD: value }))}
                                />}
                        </div>
                    </Modal>
                }                

                {/* Modal para ver listado de artículos */}
                {this.state.detailModalDetalle &&
                    <Modal
                        showModal={this.state.detailModalDetalle}
                        modalTitle={"Articulo(s) inventario para " + this.state.currentTipoLabel}
                        // cancel={() => this.setState({detailModalDetalle: false})}
                        // Cerrar el modal y descartar los valores temporales
                        cancel={() => {
                            if (typeof this.state.currentDetailIndex !== "undefined") {
                                this.handleDetailChangeClose(this.state.currentDetailIndex, this.state.currentDetailLabel);
                            }
                            this.setState({ detailModalDetalle: false, currentRecipeDetails: [] });
                        }}
                        footer={true}
                        footerButtons={
                            <div className="has-text-right modal-detalle-buttons">
                                <button
                                    className="button is-success"
                                    onClick={() => {
                                        this.handleDetailChangeSave(this.state.currentDetailIndex, this.state.currentDetailLabel);
                                        this.setState({ detailModalDetalle: false, currentRecipeDetails: [] });
                                    }}>
                                    Guardar
                                </button>
                            </div>
                        }
                        width="75%"
                    >
                        <div className="column is-12">
                            <RecipeDetails
                                details={this.state.currentRecipeDetails.map(detalle => { return { ...detalle.InventoryItem, DriveNombre: detalle.InventoryItem.DriveNombre } })}
                                quantityChanged={(value, index) => {
                                    if (value > 0) {
                                        this.handleDetailChangeTemporal(value, index, this.state.currentDetailLabel)
                                    } else {
                                        toast.warn("La cantidad debe ser mayor a cero.")
                                    }                                    
                                }}
                                removeDetail={(recipe) => {
                                    this.handleDetailRemoval(recipe, this.state.currentDetailLabel)
                                }}
                                viewDrivesDetail={(recipe, index) => {
                                    this.setState({ detailDrivesModal: true, recipeDetail: recipe,  detailIndex: index  })
                                }}
                                mostrarDrives={true}
                                onMostrarDrives={(recipe) => {
                                    this.setState({ mostrarDrivesModal: true, recipeDetail: recipe })
                                }}
                            />
                        </div>
                    </Modal>
                } 

                {/**Modal para ver e listado de drives que comparten el artículo inventario*/}
                {this.state.detailDrivesModal &&
                    <Modal
                        showModal={this.state.detailDrivesModal}
                        modalTitle={"Listado de Drives"}
                        cancel={() => this.setState({
                            detailDrivesModal: false
                        })}
                        width="75%"
                    >
                        <RecipeDrivesDetail
                            detail={this.state.recipeDetail.driveList}
                            removeDetail={(caffenioId) => this.removeDriveDetail(caffenioId)}
                            isNewRecipe = {true}
                            caffenios = {this.props.Caffenios}
                        />
                    </Modal>
                }                 

                {/* Modal Toppings */}
                {this.state.toppingModal &&
                    <Modal
                        showModal={this.state.toppingModal}
                        modalTitle={this.state.currentDetailLabel != "Toppings" ? "Agregar toppings para " + this.state.currentTipoLabel : "Agregar Toppings"}
                        cancel={() => this.setState({ toppingModal: false })}
                        width="75%"
                    >
                        <NewRecipeDetail
                            addDetail={(detail) => {
                                let toppings = []
                                if (this.state.currentDetailLabel === "Toppings") {
                                    toppings = [...this.props.receta.Toppings]
                                } else {
                                    let toppingsBase = this.props.receta[this.state.currentDetailLabel][this.state.currentDetailIndex].Toppings ? [...this.props.receta[this.state.currentDetailLabel][this.state.currentDetailIndex].Toppings] : []
                                    for (let i = 0; i < toppingsBase.length; i++) {
                                        toppings.push({ ...toppingsBase[i] })
                                    }
                                }

                                toppings.push({
                                    // CodeProvider: detail.CodeProvider,
                                    // Name: detail.Name,
                                    // InventoryItemId: detail.InventoryItemId,
                                    // UnitSaleStr: detail.UnitSaleStr,
                                    ...detail,
                                    MinQuantity: detail.MinQuantity || 0,
                                    MaxQuantity: detail.MaxQuantity || 0,
                                })

                                this.props.onToppingChange(toppings, this.state.currentDetailLabel, this.state.currentDetailIndex)
                            }}

                            currentDetails={
                                this.state.currentDetailLabel == "Toppings" ? this.props.receta.Toppings.concat(
                                    [].concat(...this.props.receta.TipoSabor.filter(item => item.Toppings != null).map(item => item.Toppings)),
                                ).map(det => det.InventoryItemId)
                                    : (this.props.receta[this.state.currentDetailLabel][this.state.currentDetailIndex].Toppings ?
                                        this.props.receta.Toppings.concat(this.props.receta[this.state.currentDetailLabel][this.state.currentDetailIndex].Toppings).map(det => det.InventoryItemId)
                                        : []
                                    )
                            }
                            soloToppings={true}
                            isTopping={true}
                        />
                    </Modal>
                }

                {this.state.detailModalTopping &&
                    <Modal
                        showModal={this.state.detailModalTopping}
                        modalTitle={"Topping(s) para " + this.state.currentTipoLabel}
                        cancel={() => {
                            // Cerrar el modal y descartar valores temporales
                            this.setState({ detailModalTopping: false, currentRecipeDetails: [] })
                        }}
                        footer={true}
                        footerButtons={
                            <div className="has-text-right modal-detalle-buttons">
                                <button
                                    className="button is-success"
                                    onClick={() => {
                                        
                                        // Guardar los valores temporales para los min y max del topping
                                        this.handleToppingChangeSave(this.state.currentDetailLabel, () => {
                                            this.setState({ detailModalTopping: false, currentRecipeDetails: [] })
                                        })
                                    }}>
                                    Guardar
                                </button>
                            </div>
                        }
                        width="75%"
                    >
                        <div className="column is-12">
                            <RecipeToppings
                                details={this.state.currentRecipeDetails}
                                quantityChanged={(value, index, labelQuantity) => {
                                    
                                    this.handleToppingChangeTemporal(value, labelQuantity, index, this.state.currentDetailLabel)
                                                                       
                                }}
                                removeDetail={(recipe) => {
                                    this.handleToppingRemoval(recipe, this.state.currentDetailLabel)
                                }}
                            />
                        </div>
                    </Modal>
                }
            </div>
        )
    }
}