import {apiUrl} from '../constants';
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class ProyeccionFormulaAPI {

    // static GetFormulaData(){
    //     return request.get('FormulaExtracto/GetFormulaExtracto')
    // }

    static CreateFormulaData(formulaData){
        return request.post('/FormulaProyeccion/NewFormulaProyeccion', formulaData);
    }

    static UpdateFormulaData(formulaData){
        return request.put('/FormulaProyeccion/UpdateFormulaProyeccion', formulaData);
    }
}
