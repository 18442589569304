import { apiUrl }  from "../constants";
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class InventoryTypesAPI {
    static getAll() {
        return request.get('/inventoryType');
    }

    static get(id) {
        return request.get(`/inventoryType/${id}`);
    }

    static create(inventoryType) {
        //(inventoryType);
        return request.post('/inventoryType',inventoryType);
    }

    static edit(inventoryType){
        return request.put(`/inventoryType`,inventoryType);
    }

    static delete(inventoryType){
        return request.delete('/inventoryType',{ data: inventoryType });
    }

    /*static delete(id){
        return request.delete(`inventoryType/${id}`);
    }*/

    /*static getInventoryTypes(){
        return request.get(`/InventoryType`);
    } */

    /*static getOrderTypes(){
        return request.get(`/OrderType`);
    }

    static search(data){
        return request.post(`/inventoryItem/Search/`, data)
    }*/
}