
import React from "react";
import Field from "../util/field/Field";
import Loading from "../util/loading/Loading";
import Modal from '../util/modal/Modal';
import AcceptModal from '../util/acceptModal/AcceptModal';
import HasAccess from '../util/hasAccess/HasAccess';

export function ConfiguracionVPComponent({
  checkHasEmptyFields,
  onKeyPressNumber,
  onKeyDown,
  handleChange,
  handleTamLetra,
  handleNumVP,
  handleClickToggle,
  handleChangeTextInput,
  handleClickToggleArray,
  submit,
  cancel,
  setConfigurationVPActive,
  createDefaultConfigVP,
  toggleDosLineasProduccion,
  handleDosLineasProduccion,
  activateDosLineasProduccion,
  deactivateDosLineasProduccion,
  closeAcceptModal,
  getDefaultTamLetras,
  ...props
}) {
  return (
    <div>
      <div className="columns is-multiline">
        <div className="column is-2">
          <Field label="# Drive Suc.">
            <input
              readOnly
              className="input"
              type="number"
              label="IdGPSucursal"
              name="DrivePSucursal"
              value={props.caffenio.idCaffenio}
            />
          </Field>
        </div>
        <div className="column is-4">
          <Field label="Nombre">
            <input
              readOnly
              className="input"
              maxLength="50"
              label="Nombre"
              name="Name"
              value={props.caffenio.Name}
            />
          </Field>
        </div>
        <div className="column is-3">
          <Field label="Estado">
            <input
              readOnly
              className="input"
              maxLength="50"
              label="Estado"
              name="State"
              value={props.caffenio.State}
            />
          </Field>
        </div>
        <div className="column is-3">
          <Field label="Ciudad">
            <input
              readOnly
              className="input"
              maxLength="50"
              label="Ciudad"
              name="City"
              value={props.caffenio.Municipality}
            />
          </Field>
        </div>

        <div className="column is-5">
              <Field label="NumConfiguracionVP" isHorizontal={true}>
                <input
                  className="input"
                  type="number"
                  min="1"
                  maxLength="1"
                  max="3"
                  label="NumConfiguracionVP"
                  name="NumConfiguracionVP"
                  value={props.configurationVP.NumConfiguracionVP}
                  onKeyDown={(e) => onKeyDown(e)}
                  onChange={(e) => handleNumVP(e)}
                />
              </Field>
        </div>

        <div className="column is-3">
                <Field label="Pantalla" isHorizontal={true}>
                  <input
                    className="input"
                    type="number"
                    minLength="1"
                    min="1"
                    maxLength="1"
                    max="2"
                    label="Pantalla"
                    name="Pantalla"

                  value={props.configurationVP.Pantalla}
                  onKeyPress={onKeyPressNumber}
                  onChange={(e) => handleChange(e)}
                  
                  />
                </Field>
              </div>

        <div className="buttons-modal has-text-left">
          <div className="column">
            <button
              onClick={() => createDefaultConfigVP()}
              disabled={!(props.ConfigurationVPButtonisActive)}
              className={
                (props.ConfigurationVPButtonisActive) ? 'button is-success' : 'button is-light'
              }
            >
              Activar VP
            </button>
          </div>
        </div>

        <div className="buttons-modal has-text-left">
          <div className="column">
            <button
              onClick={() => getDefaultTamLetras()}
              disabled={props.ConfigurationVPButtonisActive}
              className={
                (!(props.ConfigurationVPButtonisActive)) ? 'button is-success' : 'button is-light'
              }
            >
              Valores por defecto
            </button>
          </div>
        </div>

        

      <div>
        <div className="column is-5"></div>

        {props.ConfigurationVPIsActive && 
        <div className="columns is-multiline">

            {/* 
              Tamaño de letra para 1-6 ordenes
          */}

          {props.configurationVP.TamLetraConfiguracionVP.map((element, index) => (
            <React.Fragment key={index}>
              <div className="column is-2">
                <Field label={`Tamaño de letra con ${index + 1} orden`}> </Field>
              </div>

              <div className="column is-3">
                <Field label="Encabezado" isHorizontal={true}>
                  <input
                    className="input"
                    type="number"
                    minLength="1"
                    min="1"
                    maxLength="3"
                    max="999"
                    label="Encabezado"
                    name="TamLetraEncabezado"
                    value={element.TamLetraEncabezado}
                    onKeyPress={onKeyPressNumber}
                    onChange={(e) => handleTamLetra(e,index)}
                  />
                </Field>
              </div>

              <div className="column is-3">
                <Field label="Receta" isHorizontal={true}>
                  <input
                    className="input"
                    type="number"
                    min="1"
                    maxLength="3"
                    max="999"
                    label="Receta"
                    name="TamLetraReceta"
                    value={element.TamLetraReceta}
                    onKeyPress={onKeyPressNumber}
                    onChange={(e) => handleTamLetra(e,index)}
                  />
                </Field>
              </div>

              <div className="column is-3">
                <Field label="Toppings" isHorizontal={true}>
                  <input
                    className="input"
                    type="number"
                    min="1"
                    maxLength="3"
                    max="999"
                    label="Toppings"
                    name="TamLetraToppings"
                    value={element.TamLetraToppings}
                    onKeyPress={onKeyPressNumber}
                    onChange={(e) => handleTamLetra(e,index)}
                  />
                </Field>
              </div>

              <div className="column is-1"></div>
            </React.Fragment>
          ))}
          
          {/* 
              Tamaño de letra del contador
          */}
            <HasAccess roles={['Soporte']}>
              
              <div className="column is-3">
                <Field label="Tamaño contador (letra)">
                  <input
                    className="input"
                    type="number"
                    min="1"
                    maxLength="3"
                    max="999"
                    label="Contador"
                    name="TamLetraContador"
                    value={props.configurationVP.TamLetraContador}
                    onKeyPress={onKeyPressNumber}
                    onChange={(e) => handleChange(e)}
                  />
                </Field>
              </div>                                  
            

            {/* 
                Timer para actualización de ordenes
            */}

            
            <div className="column is-3">
              <Field label="Timer ordenes (milisegundos)">
                <input
                  className="input"
                  type="number"
                  min="1000"
                  maxLength="10"
                  max="100000"
                  label="TimerOrdenes"
                  name="TimerActualizacionOrdenes"
                  value={props.configurationVP.TimerActualizacionOrdenes}
                  onKeyPress={onKeyPressNumber}
                  onChange={(e) => handleChange(e)}
                />
              </Field>
            </div>
                   

          {/* 
              Pedidos simultáneos
          */}

         
            <div className="column is-2">
              <Field label="Pedidos simultáneos">
                <input
                  className="input"
                  type="number"
                  min="1"
                  maxLength="1"
                  max="6"
                  label="PedidosSimultaneos"
                  name="NumPedidosSimultaneos"
                  value={props.configurationVP.NumPedidosSimultaneos}
                  onKeyDown={(e) => onKeyDown(e)}
                  onChange={(e) => handleChange(e)}
                />
              </Field>
            </div>

          {/* 
              Carriles a mostrar
          */}

            <div className="column is-2">
              <Field label="Carriles a mostrar">
                <input
                  className="input"
                  type="text"
                  maxLength="10"
                  label="CarrilesMostrar"
                  name="NumCarriles"
                  value={props.configurationVP.NumCarriles}
                  onChange={(e) => handleChangeTextInput(e)}
                />
              </Field>
            </div>
            
          </HasAccess>

          

          {/* 
              Actualización automática
          */}

          <div className="column is-3">
            <Field label="Actualización Auto." isHorizontal={true}>
              <input
                className="switch"
                type="checkbox"
                label="Actualización Auto."
                name="ActualizacionAutomatica"
                checked={props.configurationVP.ActualizacionAutomatica}
                onChange={() => {}}
              />
              <label
                htmlFor="ActualizacionAutomatica"
                className="inputCenter"
                onClick={(e) => handleClickToggle(e,"ActualizacionAutomatica")}
              />
            </Field>
          </div>


          {/* 
              Checkbox dos líneas de producción
          */}

          <div className="column is-3">
            <Field label="Dos Líneas Producción" isHorizontal={true}>
              <input
                className="switch"
                type="checkbox"
                label="Dos Líneas Producción"
                name="DosLineasProduccion"
                checked={props.configurationVP.DosLineasProduccion}
                onChange={() => {}}
              />
              <label
                htmlFor="DosLineasProduccion"
                className="inputCenter"
                onClick={(e) => toggleDosLineasProduccion(e)}
              />
            </Field>
          </div>

          {props.showModalDosLineasProduccion && props.showModal && 
                <Modal
                    showModal={props.showModal}
                    modalTitle={"Selecciona ConfiguracionVP"}
                    width="25%"
                    cancel = {() => cancel()}
                >
                  {props.listaDosLineasProduccion.map((element, index) => (
                    <React.Fragment key={index}>
                      <div className="column is-3">
                        <Field label={element.Nombre} isHorizontal={true}>
                          <input
                            className="radio"
                            type="checkbox"
                            label={element.Nombre}
                            name={element.Nombre}
                            checked={element.NumConfiguracionVP === props.selectedOptionDosLineasProduccion}
                            onChange={() => handleDosLineasProduccion(element,index)}
                          />
                          <label
                            htmlFor={element.Nombre}
                            
                            onClick={() => {}}
                          />
                         </Field>
                      
                      </div>
                    </React.Fragment>
                  ))}

                  <div className="buttons-modal has-text-right">
                      <button
                        disabled={props.selectedOptionDosLineasProduccion === 0}
                        className={
                          (props.selectedOptionDosLineasProduccion === 0) ? 'button is-light' : 'button is-success'
                        }
                        onClick={(e) => activateDosLineasProduccion(e)}
                      >
                        Activar
                      </button>
                      {/* {!checkHasEmptyFields() && <span style={{color: "red"}}>  Son requeridos todos los campos para guardar</span>} */}
                    </div>
                </Modal>
            }   
          
            {props.showModalConfirmationMessage &&
            <AcceptModal 
              modalTitle="Confirmar"
              modalBody={<p>Se desactivará el modo dos líneas de producción de todas las VPs de este Caffenio Drive ¿Desea Continuar?</p>}
              isActive={props.showModalConfirmationMessage}
              willSave={true}
              done={() => deactivateDosLineasProduccion()}
              cancel={() => closeAcceptModal()}
            />
            }

          {/* 
              Quitar pedidos auto
          */}

          <div className="column is-3">
            <Field label="Quitar Pedidos Auto." isHorizontal={true}>
              <input
                className="switch"
                type="checkbox"
                label="Quitar Pedidos Auto."
                name="PedidosAuto"
                checked={props.configurationVP.PedidosAuto}
                onChange={() => {}}
              />
              <label
                htmlFor="PedidosAuto"
                className="inputCenter"
                onClick={(e) => handleClickToggle(e,"PedidosAuto")}
              />
            </Field>
          </div>

          <div className="column is-3"></div>

          {/* {/* 
              Tipos de solicitud para ordenes
          */}

          <div className="column">
            <Field label="Tipos de Solicitud:"> </Field>
          </div> 

          {props.tipoDeSolicitudes.map((element, index) => (
            <React.Fragment key={index}>
              <div className="column">
                <Field label={element.Nombre} isHorizontal={false}>
              <input
                className="switch"
                type="checkbox"
                label={element.Nombre}
                name={element.Nombre}
                checked={element.Activo}
                onChange={() => {}}
              />
              <label
                htmlFor={element.Nombre}
                className="inputCenter"
                onClick={(e) => handleClickToggleArray(e, index)}
              />
            </Field>
          </div>
            </React.Fragment>
          ))}

          <div className="column is-10"> </div>
          <div className="column is-10"> </div>
          <div className="column is-1"> </div>
          

          <div className="buttons-modal has-text-right">
            <div className="column">
              <button
                disabled={!(checkHasEmptyFields()) || props.isSubmitting}
                className={
                  !(checkHasEmptyFields()) ? 'button is-light' : 'button is-success'
                }
                onClick={(e) => submit(e)}
              >
                Guardar
              </button>
              {/* {!checkHasEmptyFields() && <span style={{color: "red"}}>  Son requeridos todos los campos para guardar</span>} */}
            </div>
          </div>

          
        </div>}
        </div>

        {/* <HasAccess roles={['Administrador','Lider','Encargado de Turno', 'Supervisor']}>
                                                    
        </HasAccess> */}

      <Loading
          isFullscreen={true}
          isLoading={props.isLoading}
          width="100px"
          height="100px"
        />

      </div>
    </div>
  );
}
