import React, { useState } from 'react';
import Select from 'react-select';
import useForm from '../../../../../../util/hooks/useForm';
import { useEffect } from 'react';
import NotificacionAPI from '../../../../../../services/Caffenio App/Notificacion';

export default function NumVisitasForm({inputs, setInput, errors, notification}){
    const [comparators, setComparators] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [localNotif, setLocalNotif] = useState({VisitInfo: {}});

    useEffect(() => {
        setInput('VisitInfo', {});
        getData();
        return () => setInput('VisitInfo', null);
    }, [])

    useEffect(() => {
        if(notification) setLocalNotif(notification);
    }, [notification])

    useEffect(() => {
        if (inputs.NotificationId){
            const selectedComparator = comparators.map(c => ({label: c.Name, value: c.NotificationFilterTypeId})).find(c => c.value === localNotif.VisitInfo.NotificationFilterTypeId);
            setInput('VisitInfo', {...inputs.VisitInfo, NotificationFilterTypeId: selectedComparator, Visits: localNotif.VisitInfo.Visits})
        }
    }, [comparators])

    useEffect(() => {
        if (inputs.NotificationId){
            const selectedPeriod = periods.map(c => ({label: c.Description, value: c.NotificationVisitPeriodTimeId})).find(c => c.value === localNotif.VisitInfo.NotificationVisitPeriodTimeId);
            setInput('VisitInfo', {...inputs.VisitInfo, NotificationVisitPeriodTimeId: selectedPeriod})
        }
    }, [periods])

    const getData = async () => {
        let resFilters = await NotificacionAPI.getComparators();
        if (resFilters.data && resFilters.data.length){
            setComparators(resFilters.data);
        }

        let resPeriods = await NotificacionAPI.getVisitPeriods();
        if (resPeriods.data && resPeriods.data.length){
            setPeriods(resPeriods.data);
        }
    }

    return (
        <span>
            {inputs.VisitInfo ? <div className="columns">
                <div className="column is-3">Cuando el número de visitas sea</div>
                <div className="column">
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"NotificationFilterTypeId"}
                        style={{width: '50%'}}
                        value={inputs.VisitInfo.NotificationFilterTypeId}
                        onChange={(value, event) => setInput('VisitInfo', {...inputs.VisitInfo, NotificationFilterTypeId: value})}
                        noOptionsMessage={() => "No hay opciones"}
                        options={comparators.map(c => ({label: c.Name, value: c.NotificationFilterTypeId}))}
                    />
                </div>
                <div className="column">
                    <input
                        type="number"
                        name="Visits"
                        className={`input ${errors.title && "is-danger"}`}
                        min="1"
                        placeholder={`Cantidad`}
                        value={inputs.VisitInfo.Visits}
                        onChange={(e) => setInput('VisitInfo', {...inputs.VisitInfo, Visits: e.currentTarget.value})}
                    />
                </div>
                <div className="column is-1">
                    en
                </div>
                <div className="column">
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"NotificationVisitPeriodTimeId"}
                        style={{width: '50%'}}
                        value={inputs.VisitInfo.NotificationVisitPeriodTimeId}
                        onChange={(value, event) => setInput('VisitInfo', {...inputs.VisitInfo, NotificationVisitPeriodTimeId: value})}
                        noOptionsMessage={() => "No hay opciones"}
                        options={periods.map(p => ({label: p.Description, value: p.NotificationVisitPeriodTimeId}))}
                    />
                </div>
            </div> : null}
        </span>
    )
}