import React, { useEffect } from 'react';
import { validateExtensionFile } from '../../../../../../util/util';
import Field from '../../../../../util/field/Field';
import { useState } from 'react';
import Chip from '../../../../../util/chip/Chip';
import NotificacionAPI from '../../../../../../services/Caffenio App/Notificacion';

export default function DetalleDescriptionForm({inputs, setInput,setError,  errors, handleInputChange, notification}){
    const [fileURL, setFileURL] = useState("");
    const [tags, setTags] = useState([])

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        if(inputs.category && inputs.category.value || notification.NotificationCategoryId){
            let res = await NotificacionAPI.getTags(inputs.category ? inputs.category.value : notification.NotificationCategoryId);
            if(res.data){
                setTags(res.data);
            }
        }
    }

    useEffect(() => {
        if(inputs.action && inputs.action.value === 3 && notification){
            setInput('detailDescription', notification.DetailDescription);
            setFileURL(notification.UrlImageNotification);
            setInput('urlImg', notification.UrlImageNotification);
        }
    }, [notification])

    useEffect(() => {
        setInput('link', "");
        setError('link', null);
        return () => {
            setFileURL("");
            setInput('detailDescription', "");
            setInput('urlImg', null);
            setInput('image', null);
            setError('image', null);
            setError('detailDescription', null);
        }
    }, [])

    console.log(tags);

    return (
        <div className="columns">
            <div className="column is-6">
                <label className="label">Descripción Detalle</label>
                <div className={`control`}>
                    <textarea
                        name="detailDescription"
                        className={`textarea ${errors.name && "is-danger"}`}
                        rows="5"
                        maxLength={500}
                        placeholder={`Descripción que aparecerá en la aplicación al abrir la notificación.`}
                        value={inputs.detailDescription}
                        onChange={(e) => handleInputChange(e, true)}
                        required
                    />
                </div>
                {errors.detailDescription && <p className="help title is-6 is-danger">Descripción detalle requerida</p>}
                {inputs.detailDescription && <p className={`help title is-6 ${inputs.detailDescription.length > 450 ? "is-danger" : "is-link" }`}>{`${500 - inputs.detailDescription.length} caracteres restantes.`}</p>}
                <br/>
                <div className="has-text-centered">
                    {tags.map((tag, index) => 
                        <Chip 
                            small
                            onClick={() => {
                                setInput("detailDescription", `${inputs.detailDescription ? inputs.detailDescription+" " : ""}${tag.Tag}`.replace(/\s{2,}/g, ' '))
                            }}
                            label={tag.Name} 
                            closeable={false}
                        />
                    )}
                    </div>
            </div>
            <div className="column is-6">
                <Field label="Imagen">
                    <div className="file is-info has-name">
                        <label className="file-label">
                            <input
                                className="input file-input"
                                id="file"
                                type="file"
                                name="Image"
                                accept="image/x-png,image/gif,image/jpeg"
                                onChange={({target}) => {
                                    const file = target.files[0];
                                    console.log(file)
                                    if (typeof file === 'undefined'){
                                        return;
                                    }
                                    if(validateExtensionFile(file.name)){
                                        setInput('image', file);
                                        setFileURL(URL.createObjectURL(file));
                                    }
                                }}
                            />
                            <span className="file-cta">
                                <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo .PNG o .JPG</i></span>
                            </span>
                            <span className="file-name">{inputs.image == null ? "Archivo" : inputs.image.name}</span>
                        </label>
                    </div>
                    {inputs.image &&
                        <button className="button tableAction is-danger trash-right" disabled={!inputs.image} onClick={(e) => {
                            e.stopPropagation();
                            setInput('image', null);
                            setFileURL("");
                        }
                        }>
                            <span className="icon">
                                <i className="fa fa-trash" />
                            </span>
                        </button>
                    }
                </Field>
                {fileURL && <img  src={fileURL} style={{width: 400, height: 300}}/>}
            </div>
        </div>
    )
}