import React, { Component } from 'react';
import PriceDriveAPI from '../../services/PriceDrive';
import ClasificacionesAPI from '../../services/Classification';
import PresentationsAPI from '../../services/Presentations';
import FlavorsAPI from '../../services/Flavor';
import MilksAPI from '../../services/Milk';
import GrainsAPI from '../../services/Grain';
import RecipesTypesAPI from '../../services/RecipeType';
import to from '../../util/to';
import {toast} from 'react-toastify';
import Field from '../util/field/Field';
import TableTemplate from '../util/tabletemplate/TableTemplate';
import Modal from '../util/modal/Modal';
import Dropdown from '../util/dropdown/Dropdown';
import SearchForm  from "../util/searchform/SearchForm";
import NoItems  from "../util/noitems/NoItems";
import AcceptModal from '../util/acceptModal/AcceptModal';
import Pagination from '../util/pagination/Pagination';
import Loading from '../util/loading/Loading';
import PriceListFormDetail from '../pricesDrives/PriceListFormDetail';
import Select from 'react-select';
import ExpresionesRegulares from '../../util/expresiones-regulares';
import RecipeFilters from '../util/recipeFilters/recipeFilters';

class PricesDetail extends Component {
    priceListDetailsFull = [];
    priceListDetails = [];
    estatusReceta = {
        Todos: 1,
        ConPrecio: 2,
        SinPrecio: 3
    }

    // Variables donde se almacenaran los valores seleccionados en el select multi
    tempRecipeTypesList;
    tempPresentationList;
    tempMilkList;
    tempGrainList;
    tempFlavorsList;
    tempClassificationList;

    constructor(props){
        super(props);
        this.state = {
            priceList: {
                Description: "",
                NombreSegmento: ""
            },
            filters: {
                ClassificationId: 0,
                PresentationId: 0,
                FlavorTypeId: 0,
                RecipeTypeId: 0,
                MilkTypeId: 0,
                GrainTypeId: 0
            },
            priceListDetails: [],
            pagePriceListDetails: [],
            filterText: "",
            classifications: [],
            presentations: [],
            flavors: [],
            recipeTypes: [],
            milks: [],
            grains: [],
            isLoading: false,
            dataLoaded: false,
            showModal: false,
            removeModal: false,
            checkAll: false,
            updateMasive: false,
            confirmModalUpdatePrice: false,
            priceMassive: 0,
            buttonSelectAll: false,
            // Variables sonde se almacenaran los criterios de búsqueda definitivos
            recipeTypeList: [],
            selectedRecipeTypesList: [],
            presentationsList: [],
            selectedPresentationsList: [],
            milksList: [],
            selectedMilksList: [],
            grainsList: [],
            selectedGrainsList: [],
            flavorsList: [],
            selectedFlavorsList: [],
            classificationList: [],
            selectedClassificationList: [],
            selectedPricesDetail: [],
            selectedSegmentationList: [],
            selectedEstatus: this.estatusReceta.Todos, //Booleano para filtrar las recetas según su precio
        };
    }

    componentWillMount(){
        this.setState({isLoading: true});
        if(this.props.match.params.id){
            PriceDriveAPI.getPrices(this.props.match.params.id).then(response => {
                

                this.priceListDetailsFull = response.data;
                var info = response.data.map((item, index) => {
                    return { 
                        PriceDetailId: index,
                        PriceListId: item.PriceListId,
                        RecipeId: item.RecipeId,
                        SalePrice: item.SalePrice,
                        ClassificationId: item.Recipe.ClassificationId,
                        PresentationId: item.Recipe.PresentationId,
                        FlavorTypeId: item.Recipe.FlavorTypeId,
                        RecipeTypeId: item.Recipe.RecipeTypeId,
                        MilkTypeId: item.Recipe.MilkTypeId,
                        GrainTypeId: item.Recipe.GrainTypeId,
                        Description: item.Recipe.Description,
                        NombreSegmento: item.Recipe.NombreSegmento,
                        idSegmento: item.Recipe.idSegmento,
                        statusCheck:false//agregamos un estado para cada fila para saber si ha sido seleccionado no
                    };
                });

                this.priceListDetails = info;
                this.setState({priceListDetails: info}, () => this.getData());

            }).catch(err => toast.error(err.response.data));
        }else{
            this.priceListDetailsFull = [];
            this.setState({
                priceListDetails: []
            }, () => this.getData());
        }
    }


    getData = async () => {
        let err, classifications, presentations, flavors, milks, grains, recipeTypes, priceList;
        [err, classifications] = await to(ClasificacionesAPI.getAll());
        [err, presentations] = await to(PresentationsAPI.getAll());
        [err, flavors] = await to(FlavorsAPI.getAll());
        [err, milks] = await to(MilksAPI.getAll());
        [err, grains] = await to(GrainsAPI.getAll());
        [err, recipeTypes] = await to(RecipesTypesAPI.getAll());
        [err, priceList] = await to(PriceDriveAPI.getById(this.props.match.params.id));

        let tempClassificationList = [];
        classifications.map(obj => {
            tempClassificationList.push({ value: obj.ClassificationId, label: obj.ShortName })
        })
        tempClassificationList.sort(this.compare); // Ordenar los labels

        let temp = [];
        recipeTypes.map(obj => {
            if (obj.RecipeName) {
                temp.push({ value: obj.RecipeTypeId, label: obj.RecipeName })
            } else {
                temp.push({ value: obj.RecipeTypeId, label: obj.Description })
            }
        })
        temp.sort(this.compare); // Ordenar los labesl para elegir

        let tempFlavorsList = [];
        flavors.map(obj => {
            tempFlavorsList.push({ value: obj.FlavorTypeId, label: obj.Name })
        })
        tempFlavorsList.sort(this.compare); // Ordenar los labels     

        let tempGrainList = [];
        grains.map(obj => {
            tempGrainList.push({ value: obj.GrainTypeId, label: obj.Description })
        })
        tempGrainList.sort(this.compare); // Ordenar los labels

        let tempMilkList = [];
        milks.map(obj => {
            tempMilkList.push({ value: obj.MilkTypeId, label: obj.Description })
        })
        tempMilkList.sort(this.compare); // Ordenar los labels

        let tempPresentationList = [];
        presentations.map(obj => {
            tempPresentationList.push({ value: obj.PresentationId, label: obj.Name })
        })
        tempPresentationList.sort(this.compare); // Ordenar los labels

        this.setState({
            classificationList: tempClassificationList,
            recipeTypeList: temp,
            flavorsList: tempFlavorsList,
            grainsList: tempGrainList,
            milksList: tempMilkList,
            presentationsList: tempPresentationList,
            priceList: priceList,
            dataLoaded: true,
            isLoading: false,
            classifications: classifications,
            presentations: presentations,
            flavors: flavors,
            milks: milks,
            grains: grains,
            recipeTypes: recipeTypes,
        })
    }

    handleDropdownChange = (index, itemName, propName) => {
        if(index != 0){
            let item = this.state[itemName][index - 1];
            let state = {...this.state};
            state.filters[propName] = item[propName];
            this.setState(state, () => this.filterDataOnPages());
        }else{
            let state = {...this.state};
            state.filters[propName] = 0;
            this.setState(state, () => this.filterDataOnPages());
        }
    }

    filterDataOnPages = () => {
        
        let details = this.priceListDetails;

        if(this.state.filters.ClassificationId) {
            details = details.filter(d => d.ClassificationId === this.state.filters.ClassificationId);
        }

        if(this.state.filters.PresentationId) {
            details = details.filter(d => d.PresentationId === this.state.filters.PresentationId);
        }

        if(this.state.filters.FlavorTypeId) {
            details = details.filter(d => d.FlavorTypeId === this.state.filters.FlavorTypeId);
        }

        if(this.state.filters.RecipeTypeId) {
            details = details.filter(d => d.RecipeTypeId === this.state.filters.RecipeTypeId);
        }

        if(this.state.filters.MilkTypeId) {
            details = details.filter(d => d.MilkTypeId === this.state.filters.MilkTypeId);
        }

        if(this.state.filters.GrainTypeId) {
            details = details.filter(d => d.GrainTypeId === this.state.filters.GrainTypeId);
        }

        if(this.state.filterText) {
            details = details.filter(d => d.Description.toLowerCase().includes(this.state.filterText.trim().toLowerCase()));
        }

        details.map(item=>{
            if(item.statusCheck){
                item.statusCheck = false;
            } 
        });

        this.setState({priceListDetails: details, buttonSelectAll: false});
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {

        let state = {...this.state};
        state.filterText = text;
        this.setState(state, this.handleSearchMultipleFieldInput);
    }

    showDialogEditPrice = (priceDetail) => {
        let state = this.state;

        priceDetail.ClassificationName = state.classifications[state.classifications.findIndex(c => c.ClassificationId === priceDetail.ClassificationId)].Name;
        priceDetail.PresentationName = state.presentations[state.presentations.findIndex(p => p.PresentationId === priceDetail.PresentationId)].Name;
        priceDetail.FlavorTypeName = state.flavors[state.flavors.findIndex(f => f.FlavorTypeId === priceDetail.FlavorTypeId)].Name;
        priceDetail.RecipeTypeName = state.recipeTypes[state.recipeTypes.findIndex(rt => rt.RecipeTypeId === priceDetail.RecipeTypeId)].Description;
        priceDetail.GrainTypeName = state.grains[state.grains.findIndex(g => g.GrainTypeId === priceDetail.GrainTypeId)].Description;
        priceDetail.MilkTypeName = state.milks[state.milks.findIndex(m => m.MilkTypeId === priceDetail.MilkTypeId)].Description;;

        this.setState({
            showModal: true, 
            priceDetail: priceDetail
        });
    }

    edit = (priceDetail) => {

        if(!priceDetail.SalePrice) {
            toast.error("Debes indicar el Precio de la Receta");
            return;
        }

        if(isNaN(priceDetail.SalePrice)) {
            toast.error("El Precio indicado debe ser númerico");
            return;
        }

        if (priceDetail.SalePrice == Math.floor(priceDetail.SalePrice)) {
            if(priceDetail.SalePrice < 0 || priceDetail.SalePrice > 9999999) {
                toast.error("El Precio debe ser mayor a 0 y menor a 9999999");
                return;
            }
        } else {
            if ( !this.isNumericDecimal(priceDetail.SalePrice, 2) ) {
                toast.error("El Precio debe contenedor dos decimales solamente");
                return;
            }else if(priceDetail.SalePrice < 0.00 || priceDetail.SalePrice > 9999999.99) {
                toast.error("El Precio debe ser mayor a 0.00 y menor a 9999999.99");
                return;
            }
        }
        
        this.setState({isLoading: true, showModal: false});

        PriceDriveAPI.updatePrice(priceDetail).then(response => {
            
            let state = {...this.state};
            state.priceListDetails[state.priceListDetails.findIndex(pld => pld.PriceDetailId === priceDetail.PriceDetailId)] = priceDetail;
            state.pagePriceListDetails[state.pagePriceListDetails.findIndex(pld =>pld.PriceDetailId === priceDetail.PriceDetailId)] = priceDetail;
            this.priceListDetails[this.priceListDetails.findIndex(pld =>pld.PriceDetailId === priceDetail.PriceDetailId)] = priceDetail;
            state.showModal = false;
            state.isLoading = false;
            this.setState(state, () => {
                toast.success("Precio actualizado correctamente");
            });
        }).catch(err => {
            let state = {...this.state};
            state.showModal = false;
            state.isLoading = false;
            this.setState(state);            
            toast.error("Ocurrió un error al actualizar el Precio de la Receta");
        });
    }

    isNumericDecimal = (val, decimalPlaces) => {
        // If the last digit is a . then add a 0 before testing so if they type 25. it will be accepted
        var lastChar = val.substring(val.length - 1);
        if (lastChar == ".") val = val + "0";
    
        var objRegExp = new RegExp("^\\s*-?(\\d+(\\.\\d{1," + decimalPlaces + "})?|\\.\\d{1," + decimalPlaces + "})\\s*$", "g");
        if (decimalPlaces == -1)
            objRegExp = new RegExp("^\\s*-?(\\d+(\\.\\d{1,25})?|\\.\\d{1,25})\\s*$", "g");
    
        return objRegExp.test(val);        
    };

    /*
    * Funcion seleccionar o des seleccionar todos las recetas 
    * de la pagina actual.
    */
    selectAllCheckBoxPage = () => {
        let _checkAll = !this.state.checkAll
        let _selectedPricesDetail = [...this.state.selectedPricesDetail];
        let _pricesDetails = this.state.pagePriceListDetails

        _pricesDetails.forEach(priceDetail => {
            let indexPricesDetail = _selectedPricesDetail.findIndex(item => item.PriceDetailId === priceDetail.PriceDetailId)
            priceDetail.statusCheck = _checkAll
            if(_checkAll){
                if(indexPricesDetail === -1) _selectedPricesDetail.push(priceDetail)
            }else{
                if(indexPricesDetail !== -1) _selectedPricesDetail.splice(indexPricesDetail, 1)
            }
        })

        this.setState({
            selectedPricesDetail: _selectedPricesDetail, 
            updateMasive: _selectedPricesDetail.length > 0,
            checkAll: _checkAll,
            buttonSelectAll: _selectedPricesDetail.length === this.state.priceListDetails.length
        })
    }

    /*
    * Funcion para saber si hay que mostrar
    * un switch y boton para aplicar cambios a las 
    * recetas que hemos marcado. 
    */
    showView = (index) => {
        let _selectedPricesDetail = [...this.state.selectedPricesDetail];
        let indexPricesDetail = this.state.selectedPricesDetail.findIndex(id => id.PriceDetailId === this.state.pagePriceListDetails[index].PriceDetailId)
        if (indexPricesDetail === -1) {
            // Meter todo el objeto al arreglo de seleccionados
            _selectedPricesDetail.push(this.state.pagePriceListDetails[index])
        } else {
            _selectedPricesDetail.splice(indexPricesDetail, 1)
        }

        let counter = 0; // variable para comparar los elementos seleccionados en la pagina con la cantidad de elementos por pagina
        this.state.pagePriceListDetails.map(obj => {
            if (obj.statusCheck) counter++
        })
        
        this.setState({
            selectedPricesDetail: _selectedPricesDetail, 
            updateMasive: _selectedPricesDetail.length > 0,
            buttonSelectAll: _selectedPricesDetail.length === this.state.priceListDetails.length,
            checkAll: counter === this.state.pagePriceListDetails.length
        })
    }

    /*
    * Funcion que selecciona o des selecciona todas las 
    * recetas de todas las paginas.
    */
    selectAllCheckBox = () => {
        let _selectedPricesDetail = [...this.state.selectedPricesDetail]
        let _pricesDetails = this.state.priceListDetails
        let _buttonSelectAll = !this.state.buttonSelectAll;

        _pricesDetails.forEach(priceDetail => {
            // Ver si el elemento ya esta en la lista de seleccioandos
            let indexPricesDetail = _selectedPricesDetail.findIndex(item => item.PriceDetailId === priceDetail.PriceDetailId)

            // Meter el elemento a la lista si no estaba previamente
            if (_buttonSelectAll && indexPricesDetail === -1) {
                _selectedPricesDetail.push(priceDetail)
            // Sacar al elemento de la lista si ya estaba
            } else if (!_buttonSelectAll && indexPricesDetail !== -1) {
                _selectedPricesDetail.splice(indexPricesDetail, 1)
            }
            priceDetail.statusCheck = _buttonSelectAll;
        })

        let _checkAll = _buttonSelectAll;

        this.setState({
            selectedPricesDetail: _selectedPricesDetail, 
            updateMasive: _selectedPricesDetail.length > 0,
            checkAll: _checkAll,
            buttonSelectAll: _buttonSelectAll
        })
    }

    /**
     * Funcion para validar la entrada de datos en el input
     * que modifica todos los precios de las recetas 
     * selecionadas.
     */
    handleChange = (e) => {
        let state = {...this.state};
        state.priceMassive = e.target.value;
        
        if (e.target.value == Math.floor(e.target.value)) {
            if(e.target.value < 0 || e.target.value > 9999999) {
                toast.error("El Precio debe ser mayor a 0 y menor a 9999999");
                return;
            }
        } else {
            if ( !this.isNumericDecimal(e.target.value, 2) ) {
                toast.error("El Precio debe contenedor dos decimales solamente");
                return;
            }else if(e.target.value < 0.00 || e.target.value > 9999999.99) {
                toast.error("El Precio debe ser mayor a 0.00 y menor a 9999999.99");
                return;
            }
        }
        this.setState(state);
    }   

    /**
     * Funcion para actualizar masivamente el precio 
     * de las recetas seleccionadas.
     */
    updateMasive = () => {
        this.setState({isLoading: true, confirmModalUpdatePrice:false});
        let recetas  = [];

        // Se guardan las recetas seleccionadas
        this.state.selectedPricesDetail.map(obj => {
            obj.SalePrice = this.state.priceMassive;
            recetas.push(obj)
        })

        // Mandamos las recetas al endpoint    
        PriceDriveAPI.updatePrices(recetas).then(response => {
            let state = {...this.state};
            this.cleanCheckBoxs();
            state.showModal = false;
            state.isLoading = false;
            state.priceMassive = 0;
            state.updateMasive = false;
            state.checkAll = false
            if(state.buttonSelectAll) {
                state.buttonSelectAll = false;
            }
            state.selectedPricesDetail = [];
            this.setState(state, () => {
                toast.success("Precios actualizados correctamente");
            });
        }).catch(err => {
            let state = {...this.state};
            state.showModal = false;
            state.isLoading = false;
            this.setState(state);            
            toast.error("Ocurrió un error al actualizar los precios");
        });
    }
    /**
     * Funcion encargada de des seleccionar los checkbox
     */
    cleanCheckBoxs = () =>{
        this.state.priceListDetails.map(item=>{
            if(item.statusCheck){
                item.statusCheck = false
            } 
        });
    }

    /**
     * Funcion para ordenar lexicograficamente
     * @param objA. primer operando
     * @param objB. segundo operando
     */
    compare = (objA, objB) => {
        // Use toUpperCase() to ignore character casing
        const labelA = objA.label.toUpperCase();
        const labelB = objB.label.toUpperCase();

        let comparison = 0;
        if (labelA > labelB) {
            comparison = 1;
        } else if (labelA < labelB) {
            comparison = -1;
        }
        return comparison;
    }

    /**
     *Realizar el filtrado sobre las recetas para desplegar solo los elementos
     *que cumplen con las caracteristicas que el usuario ingreso.
     * 
     *@param: nada
     *@return: nada
     */
    handleSearchMultipleFieldInput = () => {
        let priceListDetails = [...this.priceListDetails]
        switch (this.state.selectedEstatus) {
            case this.estatusReceta.ConPrecio:
                priceListDetails = priceListDetails.filter(d => d.SalePrice > 0);
                break;
                
            case this.estatusReceta.SinPrecio:
                priceListDetails = priceListDetails.filter(d => d.SalePrice <= 0);
                break;
        }

        priceListDetails = priceListDetails.filter(
            priceListDetail => (
                (this.state.selectedRecipeTypesList.length > 0 ?
                    this.state.selectedRecipeTypesList.includes(priceListDetail.RecipeTypeId) :
                    true) &&
                (this.state.selectedFlavorsList.length > 0 ?
                    this.state.selectedFlavorsList.includes(priceListDetail.FlavorTypeId) :
                    true) &&
                (this.state.selectedPresentationsList.length > 0 ?
                    this.state.selectedPresentationsList.includes(priceListDetail.PresentationId) :
                    true) &&
                (this.state.selectedMilksList.length > 0 ?
                    this.state.selectedMilksList.includes(priceListDetail.MilkTypeId) :
                    true) &&
                (this.state.selectedGrainsList.length > 0 ?
                    this.state.selectedGrainsList.includes(priceListDetail.GrainTypeId) :
                    true) &&
                (this.state.selectedClassificationList.length > 0 ?
                    this.state.selectedClassificationList.includes(priceListDetail.ClassificationId) :
                    true)&&
                (this.state.selectedSegmentationList.length > 0 ?
                    this.state.selectedSegmentationList.includes(priceListDetail.idSegmento) :
                    true )&&
                (this.state.filterText.trim() !== '' ?
                    (priceListDetail.ShortName ? priceListDetail.ShortName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) : false || priceListDetail.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                : true)
            )
        );
        let state = this.state;
        state.priceListDetails = priceListDetails;
        state.priceListDetails.map(obj => {
            obj.statusCheck = false;
        })
        state.selectedPricesDetail = []
        state.buttonSelectAll = false;
        this.setState(state);
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre las clasificaciones.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleClassificationChange = (arr) => {
        let state = { ...this.state }
        let tempClassificationList = [];
        arr.map(obj => {
            tempClassificationList.push(obj.value);
        })

        state.selectedClassificationList = tempClassificationList;
        state.selectedRecipes = [];

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de receta
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleRecipeTypeChange = (arr) => {
        let state = { ...this.state }
        let tempSelectedRecipeTypesList = [];
        arr.map(obj => {
            tempSelectedRecipeTypesList.push(obj.value);
        })

        state.selectedRecipeTypesList = tempSelectedRecipeTypesList;
        state.selectedRecipes = [];

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de sabores
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleFlavorChange = (arr) => {
        let state = { ...this.state }
        let tempSelectedFlavorList = [];
        arr.map(obj => {
            tempSelectedFlavorList.push(obj.value);
        })

        state.selectedFlavorsList = tempSelectedFlavorList;
        state.selectedRecipes = [];

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de granos.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleGrainChange = (arr) => {
        let state = { ...this.state }
        let tempGrainList = [];
        arr.map(obj => {
            tempGrainList.push(obj.value);
        })

        state.selectedGrainsList = tempGrainList;
        state.selectedRecipes = [];

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de leche
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleMilkChange = (arr) => {
        let state = { ...this.state }
        let tempMilksList = [];
        arr.map(obj => {
            tempMilksList.push(obj.value);
        })

        state.selectedMilksList = tempMilksList;
        state.selectedRecipes = [];

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre las presentaciones
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handlePresentationChange = (arr) => {
        let state = { ...this.state }
        let tempPresentationList = [];
        arr.map(obj => {
            tempPresentationList.push(obj.value);
        })

        state.selectedPresentationsList = tempPresentationList;
        state.selectedRecipes = [];

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     * Función para manejar el onChange del componente de RecipeFilters
     */
    handleSelectChange = (objFilters) => {
        this.setState(objFilters, () => {this.handleSearchMultipleFieldInput();})
    }

    /**
     * Función para manejar los radiobuttons para el filtro de estatus de precio
     */
    handleFiltroEstatus= (evento) => {
        if(this.state.selectedEstatus !== Number(evento.currentTarget.value)){
            this.setState({selectedEstatus: Number(evento.currentTarget.value)}, this.handleSearchMultipleFieldInput)
        }
    }

    render() {
        return (
            <div>
                <h1 className="title is-2">Precio de recetas</h1>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{cursor: "pointer", color: "#91BA43" }} onClick={() => {this.props.history.goBack()}} className="fa fa-arrow-left"></i>
                            </span>
                            {this.state.priceList.Description}
                        </div>
                    </div>
                    <div className="card-content">

                        {/* Buscador de contenido o nombre de recetas */}
{/*                         <div className="columns">
                            <div className="column is-12">
                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'en Recetas'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* Filtrado de todo */}
                        <div className="columns is-multiline">
                            {/* Filtrar por todos/ConPrecio/SinPrecio */}
                            <div className="column is-12">
                                <label className="label">Mostrar recetas</label>
                                <div className="control" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="precioReceta"
                                            value={this.estatusReceta.Todos}
                                            checked={this.state.selectedEstatus === this.estatusReceta.Todos}
                                            onClick={this.handleFiltroEstatus}
                                        />
                                        &nbsp;Todas
                                    </label>
                                    
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="precioReceta"
                                            value={this.estatusReceta.ConPrecio}
                                            checked={this.state.selectedEstatus === this.estatusReceta.ConPrecio}
                                            onClick={this.handleFiltroEstatus}
                                        />
                                        &nbsp;Con precio
                                    </label>

                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="precioReceta"
                                            value={this.estatusReceta.SinPrecio}
                                            checked={this.state.selectedEstatus === this.estatusReceta.SinPrecio}
                                            onClick={this.handleFiltroEstatus}
                                        />
                                        &nbsp;Sin precio
                                    </label>
                                </div>
                            </div>

                            <RecipeFilters 
                                onChange={(objFilters) => {this.handleSelectChange(objFilters)}}
                            />                            
                        </div>

                    </div>
                </div>
                <br/>
                {/*Mostramos cuando el usuario ha seleccionado un checkbox*/}
                {
                    this.state.updateMasive &&
                    <div className="card">
                        <div className="card-content">
                            
                            <div className="columns is-vcentered">
                                <div className="column is-6">
                                </div>
                                <div className="column is-4">
                                    <Field label="Precio">
                                        <input 
                                            type="number"
                                            min="0"
                                            step="0.01"
                                            className="input" 
                                            label="Gramos a descontar" 
                                            maxLength="8" 
                                            name="PriceMasive" 
                                            value={this.state.priceMassive} 
                                            onChange={(e) => this.handleChange(e)}
                                            onKeyPress={(e) => {
                                                const keyCode = e.keyCode || e.which;
                                                const keyValue = String.fromCharCode(keyCode);
                                                if (ExpresionesRegulares.ErrorInputNumDecimal.test(keyValue) || e.target.value.length >= e.target.getAttribute('maxLength'))
                                                    e.preventDefault();
                                            }}
                                            onPaste={(e) => {
                                                if (ExpresionesRegulares.ErrorInputNumDecimal.test(e.clipboardData.getData("Text")) 
                                                || (/\./.test(e.target.value) && /\./.test(e.clipboardData.getData("Text")))){
                                                    e.preventDefault();
                                                }
                                            }}
                                            />
                                    </Field>
                                </div>
                                <div className="column is-2">
                                    <div className="has-text-center buttons-modal">
                                        <button
                                            className="button is-success"
                                            onClick={
                                                    () => {
                                                        this.setState({confirmModalUpdatePrice:true})
                                                    }
                                                }>
                                            Aplicar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                }
                <br/>
                <div className="card">
                    <div className="card-content">

                        <div className="columns">
                            <div className="column" style={{display: "flex"}}>
                                <a className="button is-link" onClick={() => this.selectAllCheckBox()}>
                                    <span className="icon is-small">
                                        <i className={!this.state.buttonSelectAll ? "fa fa-check-square" : "fa fa-square"}></i>
                                    </span>
                                    <span>{!this.state.buttonSelectAll ? "Seleccionar todos los resultados" : "Deseleccionar todos los resultados"}</span>
                                </a>
                            </div>
                        </div>

                        {this.state.priceListDetails.length != 0 
                        ? <TableTemplate
                            columns={["Description", "NombreSegmento", "SalePrice"]}
                            columnsNames={["Receta", "Segmento", "Precio"]}
                            disableCheck={true}
                            data={this.state.pagePriceListDetails}
                            noActions={false}
                            canEdit={true}
                            canDelete={false}
                            edit={(priceDetail) => this.showDialogEditPrice(priceDetail)}
                            checkbox = {true}
                            checkAll= {this.state.checkAll}
                            selectAllCheck={this.selectAllCheckBoxPage}
                            showView={this.showView}
                        /> 
                        : <NoItems itemName="recetas en la lista de precio"/>}
                        
                        <Pagination 
                            items={this.state.priceListDetails} 
                            onChangePage={
                                (priceListDetails) => {
                                    let checkAllTemp = true;
                                    let cheked = false;
        
                                    priceListDetails.map(item => {
                                        if(item.statusCheck){
                                            cheked = true;
                                        } else {
                                            checkAllTemp = false;
                                        }
                                    })

                                    this.setState({ pagePriceListDetails: priceListDetails, checkAll: checkAllTemp, updateMasive: this.state.selectedPricesDetail.length > 0})
                                }
                            } 
                        />
                        
                        
                    </div>
                </div>
                {this.state.showModal && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Precio"}
                        cancel={() => this.setState({showModal: false, priceDetail: null})}
                    >
                        <PriceListFormDetail
                            priceDetail={this.state.priceDetail}
                            save={this.edit}
                        />   
                    </Modal>
                }
                {
                    this.state.confirmModalUpdatePrice && 
                    <AcceptModal
                        isActive={this.state.confirmModalUpdatePrice}
                        done={() => this.updateMasive()}
                        cancel={() => this.setState({confirmModalUpdatePrice: false})}
                        modalTitle={"Desea cambiar el precio a estas recetas?"}
                    />
                }

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
            </div>
        );
    }

}

export default PricesDetail;