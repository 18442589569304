import React, { useState, useEffect } from 'react';
import NoItems from '../../util/noitems/NoItems';
import ServerPagination from '../../util/pagination/ServerPagination';
import Loading from '../../util/loading/Loading';
import moment from 'moment';

export default function TablaPromociones(props){
    const columnas = ["Nombre","Tipo","QR","App Móvil", "Fecha creación","Acciones"];
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(props.currentPage !== currentPage || props.totalPages !== totalPages){
            setCurrentPage(props.currentPage);
            setTotalPages(props.totalPages);
        }

        if(props.loading !== loading) setLoading(props.loading);
    },[props.totalPages, props.currentPage])

    const actions = (promotion) => {
        return (
            <td>
                <button disabled={!(promotion.PromoStatusId===3)} className="button is-small tableAction is-link tooltip is-tooltip-left" data-tooltip="Descargar códigos QR en excel" onClick={(e) => {
                    e.stopPropagation();
                    props.downloadQrXlsx(promotion.PromoQRId,promotion.Name)
                }}>
                    <span className="icon is-small">
                        <i className="fa fa-file-excel-o" />
                    </span>
                </button>
                <button disabled={!(promotion.PromoStatusId===3)} className="button is-small tableAction is-success tooltip is-tooltip-left" data-tooltip="Descargar códigos QR en zip" onClick={(e) => {
                    e.stopPropagation();
                    props.downloadQrZip(promotion.PromoQRId,promotion.Name)
                }}>
                    <span className="icon is-small">
                        <i className="fa fa-file-archive-o" />
                    </span>
                </button>
                <button className="button is-small tableAction is-danger tooltip is-tooltip-left" data-tooltip="Eliminar promoción" onClick={(e) => {
                    e.stopPropagation();
                    props.deletePromotion(promotion)
                }}>
                    <span className="icon is-small">
                        <i className="fa fa-trash" />
                    </span>
                </button>
                {promotion.ShowPrefixCampaign && <button className="button is-small tableAction is-danger tooltip is-tooltip-left" data-tooltip="Asignar prefijo de campaña" onClick={(e) => {
                    e.stopPropagation();
                    props.prefixCampaign(promotion.PromoQRId)
                }}>
                    <span className="icon is-small">
                        <i className="fa fa-comment-o" />
                    </span>
                </button>}
            </td>
        )
        
    }

    return (
        <div className="card">
            <div className="card-content">
                {props.promociones.length > 0 ?
                <div className="table-container" style={{'overflow':'visible'}}> 
                <table className="table is-fullwidth">
                    <thead>
                        {columnas.map(c => <th key={c}>{c}</th>)}
                    </thead>
                    <tbody>
                    {props.promociones.map((row, index) => {
                        return (
                            <tr className="trClickable" key={`promo${index}`} onClick={(e) => {
                                e.stopPropagation();
                                props.history.push(`/app/promociones/detalle/${row.PromoQRId}`)
                            }}>
                                <td className="wordBrake">{row.Name}</td>
                                <td >{row.PromoTypeDescription}</td>
                                <td >{row.QRTypeName}</td>
                                <td>{row.ShowApp?"Si":"No"}</td>
                                <td>{moment(row.DateCreated).format('DD/MM/YYYY HH:mm')}</td>
                                {/* <td className="tooltip" data-tooltip={row.Status ? "Desactivar promoción" : "Activar promoción"}>
                                    <input
                                        type="checkbox"
                                        name="status"
                                        className="switch"
                                        
                                        checked={row.Status}
                                        onChange={() => { }}
                                    />
                                    <label disabled htmlFor="status" onClick={(e) => {
                                        e.stopPropagation();
                                        props.changeStatus(row);
                                    }} />
                                </td> */}
                                {actions(row)}
                            </tr>
                        )
                    })}
                    </tbody>
                </table></div>
                : props.loading ? <Loading isLoading={props.loading} width='100px' height='100px'/> : <NoItems itemName="promociones"/>}
                <ServerPagination 
                        totalPages={totalPages} 
                        currentPage={currentPage}
                        goToPage={props.goToPage}
                    />
            </div>
        </div>
    )
}