import React from 'react'
import Select from 'react-select';
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker/dist/es"
import NoItems from '../util/noitems/NoItems';
import MenuDriveAPI from '../../services/MenuDrive';
import { format } from 'date-fns';
import ErrorMessages from '../../util/validation-error-messages';
import { toast } from 'react-toastify';
import Loading from '../util/loading/Loading';
import ReactExport from "react-export-excel";
import WarehouseAPI from '../../services/Warehouse';
import EmpresasAPI from '../../services/CaffenioEmpresas/Empresas';
import lodash from 'lodash';
import Pagination from '../util/pagination/Pagination';

// Creamos instancias para el package export excel.
// Variable que indica el archivo de excel que se crea.
const ExcelFile = ReactExport.ExcelFile;
// Variable que indica una hoja de excel.
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// Variable que indica una columna de excel.
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const MovimientosMenu = () => {

    const fechaActualizacionCampos = [
        {
            idFechaActualizacion: 1,
            descripcion: 'Aplica',
            valor: true
        },
        {
            idFechaActualizacion: 2,
            descripcion: 'No aplica',
            valor: false
        }
    ]

    const [state, setState] = useState({
        listaMovimientosMenu: [],
        paginadoMovimientosMenu: [],
        listaSucursales: [],
        listaTiposMovimientosMenu: [],
        listaSegmentos: [],
        listaEstados: [],
        listaCiudades: [],
        FiltroFechaRegistroInicio: null,
        FiltroFechaRegistroFin: null,
        FiltroFechaActualizacion: null,
        FiltroSegmento: null,
        FiltroEstado: null,
        FiltroCiudad: null,
        FiltroDrive: null
    })

    useEffect(() => {
        window.scrollTo(0, 0)
        let listaMovimientosMenuAux = []
        let listaSucursalesAux = []
        let listaTiposMovimientosMenuAux = []
        let listaSegmentosAux = []
        setState({ ...state, isLoading: true })
        MenuDriveAPI.GetRegistrosMovimientos()
            .then(res => {
                listaMovimientosMenuAux = res.data
                return WarehouseAPI.getCaffenios()
            })
            .then(res => {
                listaSucursalesAux = res.data.filter(x => x.Active === true)
                return MenuDriveAPI.GetTiposMovimiento()
            })
            .then(res => {
                listaTiposMovimientosMenuAux = res.data
                return EmpresasAPI.getAllSegments()
            })
            .then(res => {
                listaSegmentosAux = res.data
                listaMovimientosMenuAux = obtenerInfoMovimiento(listaMovimientosMenuAux, listaSucursalesAux, listaTiposMovimientosMenuAux)
                setState({
                    ...state,
                    listaMovimientosMenu: listaMovimientosMenuAux,
                    listaSucursales: listaSucursalesAux,
                    listaTiposMovimientosMenu: listaTiposMovimientosMenuAux,
                    listaSegmentos: rellenarSegmentos(listaSucursalesAux),
                    listaEstados: rellenarEstados(listaSucursalesAux),
                    listaCiudades: rellenarCiudades(listaSucursalesAux),
                    isLoading: false
                })
            })
            .catch(err => {
                setState({ ...state, isLoading: false })
                if (err.response) {
                    toast.error(err.response.data.Message)
                } else {
                    toast.error(ErrorMessages.SinConexion)
                }
            })
    }, [])

    const obtenerInfoMovimiento = (listaMovimientosMenuAux, listaSucursalesAux, listaTiposMovimientosAux) => {
        let listaMovimientosMenuInfo = listaMovimientosMenuAux.map(movimiento => {
            movimiento.Caffenio = listaSucursalesAux.find(sucursal => sucursal.idCaffenio === movimiento.idCaffenio)
            return movimiento
        })

        listaMovimientosMenuInfo = listaMovimientosMenuInfo.map(movimiento => {
            movimiento.TipoMovimiento = listaTiposMovimientosAux.find(tipoMovimiento => tipoMovimiento.idTipoMovMenu === movimiento.idTipoMovMenu)
            return movimiento
        })
        return listaMovimientosMenuInfo
    }

    const limpiarFiltros = () => {
        setState({ ...state, isLoading: true })
        MenuDriveAPI.GetRegistrosMovimientos()
            .then(res => {
                res.data = obtenerInfoMovimiento(res.data, state.listaSucursales, state.listaTiposMovimientosMenu)
                setState({
                    ...state,
                    FiltroFechaActualizacion: null,
                    FiltroFechaRegistroFin: null,
                    FiltroFechaRegistroInicio: null,
                    FiltroCiudad: null,
                    FiltroDrive: null,
                    FiltroEstado: null,
                    FiltroSegmento: null,
                    listaMovimientosMenu: res.data,
                    isLoading: false
                })
            })
            .catch(err => {
                setState({ ...state, isLoading: false })
                if (err.response) {
                    toast.error(err.response.data.Message)
                } else {
                    toast.error(ErrorMessages.SinConexion)
                }
            })
    }

    const filtrarDatos = () => {

        if (state.FiltroFechaRegistroInicio === null || state.FiltroFechaRegistroFin === null) {
            toast.error('Se debe establecer fecha de inicio y fecha fin')
            return
        }

        let filtros = {
            FechaRegistroInicio: state.FiltroFechaRegistroInicio,
            FechaRegistroFin: state.FiltroFechaRegistroFin,
            FechaActualizacion: state.FiltroFechaActualizacion ? state.FiltroFechaActualizacion.valor : null
        }

        setState({ ...state, isLoading: true })
        MenuDriveAPI.GetRegistrosMovimientos(filtros)
            .then(res => {
                let movimientosFiltradosAux = []
                //Se obtiene la sucursal y tipo de movimiento de los movimientos del menú
                movimientosFiltradosAux = obtenerInfoMovimiento(res.data, state.listaSucursales, state.listaTiposMovimientosMenu)
                //Se quitan las movimientos del menú que no tengan sucursal activa
                movimientosFiltradosAux = movimientosFiltradosAux.filter(movimiento => movimiento.Caffenio !== undefined)
                //Se filtra por segmento
                if (state.FiltroSegmento !== null && state.FiltroSegmento.length > 0) {
                    movimientosFiltradosAux = movimientosFiltradosAux.filter(movimiento =>
                        state.FiltroSegmento.some(segmento => segmento.value === movimiento.Caffenio.idSegmento))
                }
                //Se filtra por estado
                if (state.FiltroEstado !== null && state.FiltroEstado.length > 0) {
                    movimientosFiltradosAux = movimientosFiltradosAux.filter(movimiento =>
                        state.FiltroEstado.some(estado => estado.value === movimiento.Caffenio.StateId))
                }
                //Se filtra por ciudad
                if (state.FiltroCiudad !== null && state.FiltroCiudad.length > 0) {
                    movimientosFiltradosAux = movimientosFiltradosAux.filter(movimiento =>
                        state.FiltroCiudad.some(ciudad => ciudad.value === movimiento.Caffenio.MunicipalityId))
                }
                //Se filtra por drive
                if (state.FiltroDrive !== null && state.FiltroDrive.length > 0) {
                    movimientosFiltradosAux = movimientosFiltradosAux.filter(movimiento =>
                        state.FiltroDrive.some(drive => drive.idCaffenio === movimiento.Caffenio.idCaffenio))
                }
                setState({
                    ...state,
                    listaMovimientosMenu: movimientosFiltradosAux,
                    isLoading: false
                })
            })
            .catch(err => {
                console.log(err)
                setState({ ...state, isLoading: false })
                if (err.response) {
                    toast.error(err.response.data.Message)
                } else {
                    toast.error(ErrorMessages.SinConexion)
                }
            })
    }

    const addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const rellenarSegmentos = (Caffenios) => {
        var newArray = [];
        let OptionSelectState = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectState.push({ value: item.idSegmento, label: item.Segmento });
        });

        for (var i in OptionSelectState) {
            lookupObject[OptionSelectState[i]["value"]] = OptionSelectState[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label.toLowerCase()], ['asc']);
    }

    const rellenarEstados = (Caffenios) => {
        var newArray = [];
        let OptionSelectState = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectState.push({ value: item.StateId, label: item.State });
        });

        for (var i in OptionSelectState) {
            lookupObject[OptionSelectState[i]["value"]] = OptionSelectState[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label.toLowerCase()], ['asc']);
    }


    const rellenarCiudades = (Caffenios) => {
        var newArray = [];
        let OptionSelectState = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectState.push({ value: item.Municipalityid, label: item.Municipality });
        });

        for (var i in OptionSelectState) {
            lookupObject[OptionSelectState[i]["value"]] = OptionSelectState[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label.toLowerCase()], ['asc']);
    }

    return (
        <div>
            <h1 className='title is-2'>Movimientos del SAG</h1>

            <div className='card'>
                <div className='card-content'>
                    <div className="row">
                        <div className='columns is-multiline'>

                            <div className="column is-4">
                                <label className='label'>Fecha Registro Inicio</label>
                                <DatePicker
                                    isClearable
                                    locale="es"
                                    name={"FiltroFechaRegistroInicio"}
                                    placeholderText="dd/MM/yyyy"
                                    selected={state.FiltroFechaRegistroInicio}
                                    onChange={fecha => setState({ ...state, FiltroFechaRegistroInicio: fecha })}
                                    dateFormat="dd/MM/yyyy"
                                    className="input"
                                    todayButton="Hoy"
                                />
                            </div>

                            <div className="column is-4">
                                <label className='label'>Fecha Registro Fin</label>
                                <DatePicker
                                    isClearable
                                    locale="es"
                                    name={"FiltroFechaRegistroFin"}
                                    placeholderText="dd/MM/yyyy"
                                    selected={state.FiltroFechaRegistroFin}
                                    onChange={fecha => setState({ ...state, FiltroFechaRegistroFin: fecha })}
                                    dateFormat="dd/MM/yyyy"
                                    className="input"
                                    todayButton="Hoy"
                                    minDate={state.FiltroFechaRegistroInicio}
                                    maxDate={addDays(state.FiltroFechaRegistroInicio, 30)}
                                    disabled={state.FiltroFechaRegistroInicio === null}
                                />
                            </div>

                            <div className="column is-4">
                                <label className='label'>{'Fecha Actualización'}</label>
                                <Select
                                    isClearable
                                    value={state.FiltroFechaActualizacion}
                                    placeholder={"Seleccionar..."}
                                    noOptionsMessage={() => "No hay opciones"}
                                    onChange={value => setState({ ...state, FiltroFechaActualizacion: value })}
                                    options={fechaActualizacionCampos}
                                    getOptionLabel={(fecha) => fecha.descripcion}
                                    getOptionValue={(fecha) => fecha.idFechaActualizacion}
                                />
                            </div>

                            <div className="column is-3">
                                <label className="label">Segmentación</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"FiltroSegmento"}
                                        value={state.FiltroSegmento}
                                        onChange={value => {
                                            setState({ ...state, FiltroSegmento: value })
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={state.listaSegmentos}
                                    />
                                </div>
                            </div>
                            <div className="column is-3">
                                <label className="label">Estados</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"FiltroEstado"}
                                        value={state.FiltroEstado}
                                        onChange={value => {
                                            setState({ ...state, FiltroEstado: value })
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={state.listaEstados}
                                    />
                                </div>
                            </div>
                            <div className="column is-3">
                                <label className="label">Ciudades</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOptionC"}
                                        value={state.FiltroCiudad}
                                        onChange={value => {
                                            setState({ ...state, FiltroCiudad: value })
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={state.listaCiudades}
                                    />
                                </div>
                            </div>
                            <div className="column is-3">
                                <label className="label">{'Drive(s)'}</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"FiltroDrive"}
                                        value={state.FiltroDrive}
                                        onChange={value => {
                                            setState({ ...state, FiltroDrive: value })
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={state.listaSucursales}
                                        getOptionLabel={(sucursal) => sucursal.Name}
                                        getOptionValue={(sucursal) => sucursal.idCaffenio}
                                    />
                                </div>
                            </div>

                            <div className="column is-3"></div>

                            <div className="has-text-left buttons-modal column">
                                <button
                                    className="button is-success"
                                    style={{ marginRight: "5px" }}
                                    onClick={filtrarDatos}
                                >
                                    <span className="icon is-small">
                                        <i className="fa fa-search"></i>
                                    </span>
                                    <span>Buscar</span>
                                </button>
                                <button
                                    className="button is-success"
                                    onClick={limpiarFiltros}
                                    style={{ marginRight: "5px" }}>
                                    <span className="icon is-small">
                                        <i className="fa fa-times"></i>
                                    </span>
                                    <span>Limpiar filtros</span>
                                </button>
                                {state.listaMovimientosMenu.length > 0 &&
                                    <ExcelFile
                                        filename={`MovimientosSAG-${new Date().toLocaleDateString('es-MX')}`}
                                        element={
                                            <button className="button is-success">
                                                <span className="icon">
                                                    <i className="fa fa-file" />
                                                </span>
                                                <span>Exportar a EXCEL</span>
                                            </button>
                                        }
                                    >
                                        <ExcelSheet data={state.listaMovimientosMenu} name="MovimientosSAG" >
                                            <ExcelColumn label="Tipo Movimiento" value={(movimiento) => movimiento.TipoMovimiento.Descripcion} />
                                            <ExcelColumn label="Fecha Actualización" value={(movimiento) => movimiento.FechaActualizacion ? format(Date.parse(movimiento.FechaRegistro), "dd/MM/yyyy HH:mm") : 'N/A'} />
                                            <ExcelColumn label="Fecha Registro" value={(movimiento) => format(Date.parse(movimiento.FechaRegistro), "dd/MM/yyyy HH:mm")} />
                                            <ExcelColumn label="Drive" value={(movimiento) => movimiento.Caffenio ? movimiento.Caffenio.Name : 'N/A'} />
                                        </ExcelSheet>
                                    </ExcelFile>
                                }
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className='card'>
                <div className='card-content'>
                    <div>
                        {state.listaMovimientosMenu.length !== 0 ?
                            <table className='table is-fullwidth'>
                                <thead>
                                    <tr>
                                        <th>Tipo Movimiento</th>
                                        <th>Fecha Actualización</th>
                                        <th>Fecha Registro</th>
                                        <th>Drive</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.paginadoMovimientosMenu.map(movimientoMenu =>
                                            <tr key={movimientoMenu.idRegistroMovMenu}>
                                                <td>{movimientoMenu.TipoMovimiento.Descripcion}</td>
                                                <td>{movimientoMenu.FechaActualizacion ? format(Date.parse(movimientoMenu.FechaActualizacion), "dd/MM/yyyy HH:mm") : 'N/A'}</td>
                                                <td>{format(Date.parse(movimientoMenu.FechaRegistro), "dd/MM/yyyy HH:mm")}</td>
                                                <td>{movimientoMenu.Caffenio ? movimientoMenu.Caffenio.Name : 'N/A'}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table> : <NoItems itemName="movimientos" />
                        }
                    </div>
                    <Pagination
                        items={state.listaMovimientosMenu}
                        onChangePage={movimientosMenu => setState({ ...state, paginadoMovimientosMenu: movimientosMenu })}
                    />
                </div>
            </div>


            <Loading isFullscreen={true} isLoading={state.isLoading} width='100px' height='100px' />
        </div>
    )
}
