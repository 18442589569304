import React from "react"
import { ConfiguracionVPComponent } from "./ConfiguracionVPComponent"
import { toast } from 'react-toastify';
import ErrorMessages from '../../util/validation-error-messages';
import ConfigurationVPAPI from '../../services/ConfigurationVP';
import update from 'immutability-helper';

class ConfiguracionVPContainer extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            caffenio: {...this.props.caffenio},
            configurationVP: {...this.props.configurationVP},
            tipoDeSolicitudes: [...this.props.tipoDeSolicitudes],
            ConfigurationVPIsActive: false,
            ConfigurationVPButtonisActive: false,
            isEditing: false,
            isLoading: false, 
            isSubmitting: false,
            hasEmptyFields: false,
            dosLineasProduccionisActive: false,
            showModalDosLineasProduccion: false,
            showModal: false,
            numConfigsVP: [],
            listaDosLineasProduccion: [],
            selectedOptionDosLineasProduccion: 0,
            showModalConfirmationMessage: false,
            activasVPDosLineasProduccion: [],
            VPs: [],
        }
        this.baseState = this.state;

        
    }

    componentDidMount(){
        let newState = update(this.state, {
            isLoading: {$set: true},
            configurationVP: {
                idCaffenio: {$set: this.state.caffenio.idCaffenio}
            }
        });
        this.setState(newState);
        this.getConfigVP();
        this.getVPs();
    }

     /**
     * Función que llamar a un servicio para obtener
     * la información de las configuraciones VP para un idCaffenio
     */

    getVPs = async() => {
        this.state.VPs = (await ConfigurationVPAPI.GetVPs(this.state.caffenio.idCaffenio)).data;
    }


     /**
     * Función que se encarga de llamar al servicio para obtener la configuración VP dado
     * un idCaffenio y un NumConfiguraciónVP
     */

    getConfigVP = async() => {
        try{
            let configVP = await ConfigurationVPAPI.GetConfigVP(this.state.caffenio.idCaffenio, this.state.configurationVP.NumConfiguracionVP);
        
            let newState = update(this.state, {
                configurationVP: {$set: configVP.data},
                isLoading: {$set: false},
                ConfigurationVPButtonisActive: {$set: false},
                ConfigurationVPIsActive: {$set: true}
            });
            this.setState(newState);

        } catch (err) {
            let newState = update(this.state, {
                isLoading: {$set: false},
                ConfigurationVPButtonisActive: {$set: true},
            });
            this.setState(newState);
        }   

        //Se ponen como activas los tipos de solicitud que son aceptas para determinada configuracionVP
        this.state.tipoDeSolicitudes.forEach((i) => {
        this.state.configurationVP.TiposSolicitudConfiguracionVP.forEach((j) => {
                if(i.idTipoSolicitud == j.idTipoSolicitud){
                    i.Activo = true;
                }
            });
        });

        let newState = update(this.state, {
            isLoading: {$set: false}
        });
        this.setState(newState); 
        
    }

    /**
     * Función que se encarga de llamar al servicio para obtener el tamaño default de letra
     * por número de ordenes
     */

    getDefaultTamLetras = () => {
        let newState = update(this.state, {
            isLoading: {$set: true},
        });
        this.setState(newState, async() => {
            try{
                //Hacemos un getConfiguracionVP para un Caffenio que guarda una configuracion default
                let configVP = await ConfigurationVPAPI.GetConfigVP(54, 1);
                let newState = update(this.state, {
                    configurationVP: {
                        TamLetraConfiguracionVP: {$set: configVP.data.TamLetraConfiguracionVP}
                    },
                    isLoading: {$set: false},
                });
                this.setState(newState);
    
            } catch (err) {
                let newState = update(this.state, {
                    isLoading: {$set: false},
                    ConfigurationVPButtonisActive: {$set: true},
                });
                this.setState(newState);
            }   
        });
    }

    /**
     * Función que se encarga de llamar al servicio para crear una configuración VP default
     * con datos default
     */

    createDefaultConfigVP = async() => {

            //Se ponen como default todos los tipos de solicitudes que existen
            //actualizamos TiposSolicitudConfiguracionVP
            let newTipoDeSolicitudes = this.state.tipoDeSolicitudes.map(e => { return e; });

            //Se habilita el botón para si lo desea activar la configuración VP
            let newState = update(this.state, {
                isLoading: {$set: true},
                configurationVP: {
                    TiposSolicitudConfiguracionVP: {$set: newTipoDeSolicitudes}
                } 
            });
            this.setState(newState);

            try{
                let newConfig = await ConfigurationVPAPI.CreateDefaultConfigVP(this.state.configurationVP);
                let newState = update(this.state, {
                    isLoading: {$set: false},
                    ConfigurationVPButtonisActive: {$set: false},
                    ConfigurationVPIsActive: {$set: true},
                    configurationVP: {$set: newConfig.data.newIdConfigurationVP}
                });
                this.setState(newState);

            } catch (err){
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
                
            }

            //Se ponen como activas los tipos de solicitud que son aceptas para determinada configuracionVP
            this.state.tipoDeSolicitudes.forEach((i) => {
                this.state.configurationVP.TiposSolicitudConfiguracionVP.forEach((j) => {
                    if(i.idTipoSolicitud == j.idTipoSolicitud){
                        i.Activo = true;
                    }
                });
            });

            let newState2 = update(this.state, {
                isLoading: {$set: false}
            });
            this.setState(newState2); 
            
    }


    /**
     * Función que se encarga de llamar al servicio para obtener el número de configuraciones VP dado
     * un idCaffenio
     */

    getNumConfigsVP = () => {
        try{
            this.changeLoading();
            ConfigurationVPAPI.GetNumConfigsVP(this.state.caffenio.idCaffenio).then( response => {
                let newState = update(this.state, {
                    numConfigsVP: {$set: response.data},
                    isLoading: {$set: false}
                });
                this.setState(newState);
            })     
        } catch (err) {
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        }
    }


    /**
     * Función que se encarga de llamar al servicio para obtener la configuración VP dado
     * un idCaffenio y un NumConfiguraciónVP
     */

    setConfigurationVPActive = () => {
        let newState = update(this.state, {
            ConfigurationVPIsActive: {$set: true},
        });
        this.setState(newState);
    }

    /**
     * Función que se encarga de llamar al servicio que ACTIVA las dos líneas de producción
     */

    activateDosLineasProduccion = (e) => {
        e.preventDefault();
        this.changeLoading();
        try{
            ConfigurationVPAPI.ActivarDosLineasProduccion(this.state.caffenio.idCaffenio,
                this.state.configurationVP.NumConfiguracionVP, this.state.selectedOptionDosLineasProduccion).then(response =>{
                    this.closeModal();
                })
        }catch (err){
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        }
        this.changeLoading();
    }



    /**
     * Función que se encarga de llamar al servicio que DESACTIVA las dos líneas de producción
     */

    deactivateDosLineasProduccion = () => {
        this.changeLoading();
        try{
            ConfigurationVPAPI.DesactivarDosLineasProduccion(this.state.caffenio.idCaffenio).then(response =>{
                    this.closeModal();
            })
        }catch (err){
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        }
        this.changeLoading();
        
       
    }


    /**
     * Función que se encarga de manejar los checkboxes del modal de dos líneas producción
     */

    handleDosLineasProduccion = (e,index) => {
        if(this.state.listaDosLineasProduccion[index].NumConfiguracionVP === this.state.selectedOptionDosLineasProduccion){
            let newState = update(this.state, {
                selectedOptionDosLineasProduccion: {$set: 0}
            })
            this.setState(newState);
        }
        else {
            let newState = update(this.state, {
                selectedOptionDosLineasProduccion: {$set: this.state.listaDosLineasProduccion[index].NumConfiguracionVP}
            })
            this.setState(newState);
        }
    }

    /**
     * Función que se encarga de cambiar el estado para mostrar el modal de dos líneas producción
     */

    toggleDosLineasProduccion = (e) => {
        e.stopPropagation();
        if (this.state.configurationVP.DosLineasProduccion){
            let newState = update(this.state, {
                showModalConfirmationMessage: {$set: true},
                showModal: {$set: true}

            })
            this.setState(newState);
        }
        else {
            try{
                this.changeLoading();
                ConfigurationVPAPI.GetNumConfigsVP(this.state.caffenio.idCaffenio).then( response => {
    
                    let auxLista = response.data.map(obj => (
                        {NumConfiguracionVP: obj , Nombre: "Configuracion VP " + obj, Activo: false }
                    ))
    
                    let auxListaDosLineasProduccion = auxLista.filter(e => e.NumConfiguracionVP != this.state.configurationVP.NumConfiguracionVP);
    
                    let newState = update(this.state, {
                        listaDosLineasProduccion:{$set: auxListaDosLineasProduccion},
                        numConfigsVP: {$set: response.data},
                        isLoading: {$set: false}
                    });

                    let auxVPsDosLineasProduccionActiva = false;
                    this.state.VPs.forEach(element => {
                        if (element.DosLineasProduccion) auxVPsDosLineasProduccionActiva = true;
                    });

                    this.setState(newState, () => {
                        if(this.state.numConfigsVP.length < 2){
                            toast.error(`No se ha podido activar el modo "Dos Líneas Producción" ya que no se cuenta con otra Configuracion VP activa`);
                        }
                        
                        else if (auxVPsDosLineasProduccionActiva){
                            toast.error(`No se ha podido activar el modo "Dos Líneas Producción" ya que ya se encuentra activo en otro par de VPs`);
                        }
                        else {
                            let newState2 = update(this.state, {
                                showModal:{$set: true},
                                showModalDosLineasProduccion: {$set: true}
                            });
                            this.setState(newState2);
                        }
                    });
                })     
            } catch (err) {
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            }
        }

    }

    /**
     * Función que es llamada cuando se da cancel en el modal de dos líneas producción
     */

    cancel = () => {
        let newState = update(this.state, {
            showModalDosLineasProduccion: {$set: false}
        });
        this.setState(newState);
    }

    /**
     * Función que se encarga de regresar el estado a su estado inicial
     */

    resetState = () => {
        this.setState(this.baseState)
    }

    /**
     * Función que se encarga de checar si hay campos vacios en el modal
     */

    checkHasEmptyFields = () => {
        return !this.state.hasEmptyFields;
    }

    /**
     * Función que se encarga de cambiar el loading del state a su estado opuesto
     */

    changeLoading = () => {
        let newState = update(this.state, {
            isLoading: {$set : !this.state.isLoading}
        });
        this.setState(newState);  
    }

    /**
     * Función que se encarga de hacer de manejar cambios en campos del modal
     */

    handleChange = (e) => {  

        //Checar si hay campos vacios para inhabilitar campo de guardar
        let auxHasEmptyFields = false;
        if(isNaN(e.target.value) || !(e.target.value)) { auxHasEmptyFields = true;}

        let newState = update(this.state, {
            configurationVP:{
                [e.target.name] : {$set: e.target.valueAsNumber}
            },
            hasEmptyFields: {$set: auxHasEmptyFields}
        });
        this.setState(newState);
    }


    /**
     * Función que se encarga de manejar cambios en los campos de texto del modal
     */

    handleChangeTextInput = (e) => {

        //Checar si hay campos vacios para inhabilitar campo de guardar
        let auxHasEmptyFields = false;
        if(e.target.value.trim() == "") { auxHasEmptyFields = true;} 

        let newState = update(this.state, {
            configurationVP:{
                [e.target.name] : {$set: e.target.value.trim()}
            },
            hasEmptyFields: {$set: auxHasEmptyFields}
        });
        this.setState(newState);
    }

    /**
     * Función que se encarga de manejar el campo de NumConfiguracionVP
     */

    handleNumVP = (e) => {

        this.changeLoading();
        //Se ponen como inactivas los tipos de solicitud
        this.state.tipoDeSolicitudes.forEach((i) => {
            this.state.configurationVP.TiposSolicitudConfiguracionVP.forEach((j) => {
                if(i.idTipoSolicitud == j.idTipoSolicitud){
                    i.Activo = false;
                }
            });
        });

        let newState = update(this.state, {
            ConfigurationVPIsActive: {$set: false},
            configurationVP:{
                [e.target.name] : {$set: e.target.valueAsNumber}
            }
        });
        this.setState(newState, () => {
            this.changeLoading();
            this.getConfigVP();
        })
        
        
    }

    /**
     * Función que se encarga de manejar los campos que tienen el tamaño de letras para
     * el distinto número de ordenes
     */

    handleTamLetra = (e, index) => {
        
        //Checar si hay campos vacios para inhabilitar campo de guardar
        let auxHasEmptyFields = false;
        if(isNaN(e.target.value) || !(e.target.value)) { auxHasEmptyFields = true;}
        let newState = update(this.state, {
            configurationVP: {
                TamLetraConfiguracionVP: {
                    [index]: {
                        [e.target.name]: {$set: e.target.valueAsNumber}
                    }
                }
            },
            hasEmptyFields: {$set: auxHasEmptyFields}
        });
        this.setState(newState);
    }

    /**
     * Función que se encarga de manejar el activar/desactivar los checkboxes del modal
     */

    handleClickToggle = (e, checked) => {
        e.stopPropagation();
        let switchActiveTemp =
          this.state.configurationVP[checked];
        switchActiveTemp = !switchActiveTemp;
        this.setState((prevState) => ({
          configurationVP: {
            // object that we want to update
            ...prevState.configurationVP, // keep all other key-value pairs
            [checked]: switchActiveTemp, // update the value of specific key
          },
        }));
    }

    /**
     * Función que se encarga de manejar los campos de los tipos de solicitudes de la
     * configuracion VP
     */

    handleClickToggleArray = (e, index) => {
        e.stopPropagation();
        let newState = update(this.state, {
            tipoDeSolicitudes: {
                [index]: {
                    Activo: {$set: !this.state.tipoDeSolicitudes[index].Activo}
                }
            }
        });
        this.setState(newState);   
    }

    // Solo permite números, sin signos ni exponenciales
    onKeyPressNumber(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/\+|-|e|E|\./.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
        event.preventDefault();
    }

    // No permite escribir en el ccampo
    onKeyDown(event) {
        event.preventDefault();
    } 

    /**
     * Función que se ejecuta al dar guardar en el modal
     */

    submit = (e) => {
        e.preventDefault();
        if(!this.state.isSubmitting){

            this.changeLoading();
            //UPDATE a TiposSolicitudConfiguracionVP
            let newTipoDeSolicitudes = this.state.tipoDeSolicitudes.filter((e) => {
                if(e.Activo){
                    return e;
                }
            });
            let newState = update(this.state, {
                isSubmiting: {$set: true},
                configurationVP: {
                    TiposSolicitudConfiguracionVP: {$set: newTipoDeSolicitudes}
                } 
            });

            this.setState(newState, () => {
                console.log(this.state.configurationVP)
                ConfigurationVPAPI.UpdateConfigurationVP(this.state.configurationVP).then(response => {
                    this.closeModal();
                }).catch(err => {
                    if(err.response)
                        toast.error(err.response.data.Message)
                    else
                        toast.error(ErrorMessages.SinConexion)
                    let newState = update(this.state, {
                        isSubmitting: {$set: false},
                        isLoading: {$set: false}
                    });
                    this.setState(newState);
                })
                this.changeLoading();
            });
        }
        
    }

    /**
     * Función que se ejecuta al dar "cancelar" en el modal de 
     * "Confirmar desactivar dos lineas producción"
     */

    closeAcceptModal = () => {
        let newState = update(this.state, {
            showModalConfirmationMessage: {$set: false}

        })
        this.setState(newState);
    }

    /**
     * Función que se encarga de cerrar el modal de la configuraciónVP
     */

    closeModal = () => {
        toast.success("Configuración actualizada con éxito");
        this.props.cancel();
    }

    render(){
        return (
            <ConfiguracionVPComponent
                {...this.state}
                checkHasEmptyFields={this.checkHasEmptyFields}
                onKeyPressNumber={this.onKeyPressNumber}
                onKeyDown={this.onKeyDown}
                handleChange={this.handleChange}
                handleNumVP={this.handleNumVP}
                handleTamLetra={this.handleTamLetra}
                handleClickToggle={this.handleClickToggle}
                handleChangeTextInput={this.handleChangeTextInput}
                handleClickToggleArray={this.handleClickToggleArray}
                submit={this.submit}
                cancel={this.cancel}
                setConfigurationVPActive={this.setConfigurationVPActive}
                createDefaultConfigVP={this.createDefaultConfigVP}
                toggleDosLineasProduccion={this.toggleDosLineasProduccion}
                handleDosLineasProduccion={this.handleDosLineasProduccion}
                activateDosLineasProduccion={this.activateDosLineasProduccion}
                deactivateDosLineasProduccion={this.deactivateDosLineasProduccion}
                closeAcceptModal={this.closeAcceptModal}
                getDefaultTamLetras={this.getDefaultTamLetras}
            />
        );
    }
}

ConfiguracionVPContainer.defaultProps = {
    ConfigurationVPIsActive: false,
    isEditing: false,
    configurationVP: {
        TamLetraConfiguracionVP: [
            {TamLetraEncabezado: 44, TamLetraReceta: 39, TamLetraToppings: 31, NumOrdenes: 1},
            {TamLetraEncabezado: 35, TamLetraReceta: 30, TamLetraToppings: 35, NumOrdenes: 2},
            {TamLetraEncabezado: 29, TamLetraReceta: 29, TamLetraToppings: 29, NumOrdenes: 3},
            {TamLetraEncabezado: 26, TamLetraReceta: 26, TamLetraToppings: 26, NumOrdenes: 4},
            {TamLetraEncabezado: 24, TamLetraReceta: 24, TamLetraToppings: 24, NumOrdenes: 5},
            {TamLetraEncabezado: 22, TamLetraReceta: 22, TamLetraToppings: 22, NumOrdenes: 6},
        ],
        idConfiguracionVP: 0,
        idCaffenio: 0,
        NumConfiguracionVP: 1,
        Pantalla: 1,
        TamLetraContador: 30,
        TimerActualizacionOrdenes: 1000,
        NumPedidosSimultaneos: 6,
        NumCarriles: "1,2",
        ActualizacionAutomatica: true,
        PedidosAuto: true,
        DosLineasProduccion: false,
        TiposSolicitudConfiguracionVP: [
            { idConfiguracionVP: 0, idTipoSolicitud: 1, Nombre: "Tablet", Activo: true },
            { idConfiguracionVP: 0, idTipoSolicitud: 2, Nombre: "A pie", Activo: true },
            { idConfiguracionVP: 0, idTipoSolicitud: 4, Nombre: "Pantalla Drive", Activo: true },
            { idConfiguracionVP: 0, idTipoSolicitud: 5, Nombre: "Bocina", Activo: true },
            { idConfiguracionVP: 0, idTipoSolicitud: 6, Nombre: "Móvil", Activo: true },
            { idConfiguracionVP: 0, idTipoSolicitud: 7, Nombre: "Web", Activo: true },
            { idConfiguracionVP: 0, idTipoSolicitud: 8, Nombre: "Cajero", Activo: true },
            { idConfiguracionVP: 0, idTipoSolicitud: 9, Nombre: "Ecommerce", Activo: true },
            { idConfiguracionVP: 0, idTipoSolicitud: 10, Nombre: "UberEats", Activo: true },
            { idConfiguracionVP: 0, idTipoSolicitud: 12, Nombre: "Drive Kiosko", Activo: true },
            { idConfiguracionVP: 0, idTipoSolicitud: 13, Nombre: "Rappi", Activo: true },
            { idConfiguracionVP: 0, idTipoSolicitud: 14, Nombre: "Carril Peatonal", Activo: true },
        ],
        
    },

    caffenio: {
        DrivePSucursal: "",
        Name: "",
        State: "",
        City: "",
    },
}



export { ConfiguracionVPContainer };