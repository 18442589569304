import React, { useState, useEffect } from 'react';
import NuevaNotificacionForm from './components/NuevaNotificacionForm';
import SegmentacionAPI from '../../../../services/Caffenio App/Segmentacion';
import NotificacionAPI from '../../../../services/Caffenio App/Notificacion';
import { toast } from 'react-toastify';

function NuevaNotificacion(props){
    const [data, setData] = useState({segmentations: [], types: []});
    const [notification, setNotification] = useState(null);

    const fetchData = async () => {
        try {
            let seg = await NotificacionAPI.getSegmentaciones();
            let notifTypes = await NotificacionAPI.getNotificationTypes();
            setData({segmentations: seg.data, types: notifTypes.data});
        } catch(e) {
            console.log(e);
        }
        
    }

    useEffect(() => {
        fetchData();

        if(props.match.params.id){
            NotificacionAPI.getNotification(props.match.params.id).then(res => {
                setNotification(res.data);
            }).catch(err => toast.error(toast.response.data.Message));
        }
    }, [])

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="card-header-title is-size-4 center-text">
                        <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                            <i className="fa-5x" style={{cursor: "pointer", color: "#000000" }} onClick={() => {
                                props.history.push({
                                    pathname: '/app/notificaciones',
                                    state: props.location.state ? {currentPage: props.location.state.currentPage || 0, name: props.location.state.name || ""} : {currentPage: 1, name: ""}
                                });
                                }} className="fa fa-arrow-left"></i>
                        </span>
                        {props.match.params.id ? "Editar notificación" : "Nueva notificación"}
                    </div>
                </div>
                <div className="card-content">
                    <NuevaNotificacionForm
                        {...data}
                        {...props}
                        notification={notification}
                        setNotification={setNotification}
                    />
                </div>
            </div>
            {/* <Loading isFullscreen={true} isLoading={this.state.loading} width='100px' height='100px'/> */}
        </div>
    )
}

export default NuevaNotificacion;