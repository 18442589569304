import React, { Component } from "react";
import  NoItems  from "../util/noitems/NoItems";
import Pagination from '../util/pagination/Pagination';
import roles from '../../util/strings/roles';
import HasAccess from '../util/hasAccess/HasAccess';
import Field from '../util/field/Field';
import { toast } from 'react-toastify';
import ProyeccionesAPI from "../../services/ProductionProyection";
import ErrorMessages from '../../util/validation-error-messages';
import ProyeccionForm from "./ProyeccionForm";
import Modal from "../util/modal/Modal";

class ProyeccionProductos extends Component {
    state={
        isLoading: false,
        pageProyecciones: [],
        Proyecciones: [],
        ProyeccionesFilter: [],
        proyeccion: null,
        showModal: false,
    }

    componentDidMount(){
        this.setState({isLoading: true}, () => {
            let state = {...this.state}
            ProyeccionesAPI.GetProyeccionesData().then(response => {
                state.Proyecciones = response.data;
                state.ProyeccionesFilter = response.data;
                console.log("Resultado obtener proyecciones",response);
                this.setState(state)
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        })
        
    }

    create = () => {        
        this.props.history.push({pathname: '/proyeccion/nuevo', state:{ 
            proyeccionList: this.state.Proyecciones,
            isEditing : false
        }});
    }

    edit = (proyeccion) => {
        proyeccion.Nombre = proyeccion.Nombre.trim();
        if(proyeccion.Nombre.length < 3){ 
            toast.error("Nombre debe de tener más de 2 caracteres");
            return;
        }
        console.log("La proyección a actualizar >>", proyeccion);

        this.setState({isLoading: true}, () => {
            ProyeccionesAPI.UpdateProyeccionData(proyeccion).then( response => {
                let state = {...this.state};
                state.Proyecciones[state.Proyecciones.findIndex(u => u.IdProduccionAdelantado === proyeccion.IdProduccionAdelantado)] = proyeccion;
                state.pageProyecciones[state.pageProyecciones.findIndex(u => u.IdProduccionAdelantado === proyeccion.IdProduccionAdelantado)] = proyeccion;
                state.showModal = false;
                state.isLoading = false;
                this.setState(state, () => {
                    toast.success("Proyección actualizada");
                });
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        });
    }

    editProyeccion = (proyeccion) =>{
        this.props.history.push({pathname: '/proyeccion/nuevo', state:{ 
            proyeccionList: this.state.Proyecciones,
            proyeccion: proyeccion,
            isEditing : true
        }});
    }

    configure = (proyeccion) => {        
        this.props.history.push({pathname: '/proyeccion/formula', state:{ 
            proyeccion: proyeccion,
        }});
    }


    render(){
        return(
            <div>
                <h1 className="title is-2">Proyección productos</h1>
                <div className="columns">
                    <div className="column is-12">
                        <div className="field">
                            <div className="control has-text-right">
                                <a className="button is-success" onClick={(e) => this.create()}>
                                    <span className="icon is-small">
                                        <i className="fa fa-plus"></i>
                                    </span>
                                    <span>Agregar</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-content">
                    {this.state.ProyeccionesFilter.length !== 0 
                        ? 
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Orden</th>
                                    <HasAccess roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE]} >
                                        <th>Activo</th>
                                    </HasAccess>
                                    <th>Estatus</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.pageProyecciones.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            
                                            <td>{item.Nombre}</td>

                                            <td>{item.Orden}</td>

                                            <td>
                                                {/* {item.Activo ? 'Si' : 'No'} */}
                                                <Field label="" isHorizontal>
                                                        <input   
                                                            type="checkbox"
                                                            name="Activo"
                                                            className="switch"
                                                            checked={item.Activo}
                                                            onChange={() => {}}
                                                        />
                                                        <label htmlFor="isActive" onClick={(e) => {
                                                            e.stopPropagation();
                                                            item.Activo = !item.Activo;
                                                            this.edit(item);
                                                        }}/>
                                                    </Field>
                                            </td>
                                            <td>
                                                {item.Formula ? 'Configurado' : 'No configurado'}
                                            </td>

                                            <td>
                                                    <button className="button is-small tableAction is-success tooltip" data-tooltip="Editar" onClick={(e) => {
                                                        e.stopPropagation();
                                                        console.log(item);
                                                        this.editProyeccion(item);
                                                        // this.setState({showModal: true, proyeccion: item});
                                                    }
                                                    }>
                                                        <span className="icon">
                                                            <i className="fa fa-edit" />
                                                        </span>
                                                    </button>
                                                    <button className="button is-small tableAction is-warning tooltip" data-tooltip="Configurar fórmula" onClick={(e) => {
                                                        e.stopPropagation();
                                                        console.log(item);
                                                        this.configure(item);
                                                    }
                                                    }>
                                                        <span className="icon">
                                                            <i className="fa fa-desktop" />
                                                        </span>
                                                    </button>
                                            </td>
                                            
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table> 

                        : <NoItems itemName="productos"/>}
                        <Pagination items={this.state.ProyeccionesFilter} onChangePage={(proyecciones) => this.setState({ pageProyecciones: proyecciones })} /*onChangePage={(items) => this.handlePageChange(items)}*/ />
                    </div>
                </div>

                {/* {this.state.showModal && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar proyección"}
                        width="50%"
                        cancel={() => this.setState({showModal: false, proyeccion: null})}
                    >
                        <ProyeccionForm
                            proyeccion={this.state.proyeccion}
                            save={this.edit}
                            proyeccionList={this.state.Proyecciones}
                        />   
                    </Modal>
                } */}
                 
            </div>

        );
    }

}

export default ProyeccionProductos;