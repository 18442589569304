import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PresentationForm from './PresentationForm';
import PresentationsAPI from '../../../services/Presentations';
import '../../cssComponents.css';
import Loading from '../../util/loading/Loading';
import Validators from '../../../util/validators';
import ErrorMessages from '../../../util/validation-error-messages';

import Modal from '../../util/modal/Modal';

class NewPresentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            presentation: { ...this.props.presentation },
            presentations:[],
            isLoading: false
        };
    }

    componentDidMount(){
        if (!this.props.location.state) {
            this.props.history.push(`/presentaciones`);
            return;
        }
        this.setState({ presentations: this.props.location.state.presentations })
    }

    /**Funcion para crear una nueva presentacion */
    save = (presentation) => {
        presentation.Active = !presentation.Active ? false : presentation.Active;
        presentation.RecipeName = presentation.Name;
        this.setState({ isLoading: true }, () => {
            PresentationsAPI.create(presentation).then(response => {
                this.setState({ isLoading: false })
                toast.success("Presentación creada");
                this.props.history.goBack();
            }).catch(err => {
                this.setState({ isLoading: false })
                if (err.response)
                    toast.error(err.response.data.Message);
                else
                    toast.error(ErrorMessages.SinConexion);
            })
        })
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{ position: "absolute", left: 25 }} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{ cursor: "pointer", color: "#91BA43" }} onClick={() => { this.props.history.goBack() }} className="fa fa-arrow-left"></i>
                            </span>
                            <div> Nueva presentación
                                <i className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }} onClick={() => this.setState({ showModal: true })} className="fa fa-question-circle"></i>
                            </div>
                            
                        </div>
                    </div>
                    <div className="card-content">
                        <PresentationForm 
                        save={this.save} presentations={this.state.presentations}
                        isEditing={false}
                        />
                    </div>
                </div>
                {this.state.showModal &&
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Información de Presentación"}
                        cancel={() => this.setState({ showModal: false })}
                    >
                        <div className="has-text-justified">
                        
                            
                        Las presentaciones están directamente relacionadas a las recetas, se usa para identificar las 
                        presentaciones que aplican para la receta.
                        <br/>
                        En los dispositivos del punto de venta se visualizan como la selección del tamaño antes de agregar el producto a la orden.
                            <hr/>
                            <p className="has-text-justified">
                                <strong>Nombre</strong>: es un campo requerido, 
                                debe tener una longitud mínima de 2 a 30 caracteres máximo
                                 y no se debe duplicar.                     
                            </p>
                            
                            <p className="has-text-justified">
                                <strong>Posición en Menú</strong>: valor numérico que representa el orden de visualización en los dispositivos de izquierda a derecha.
                                Debe ser mayor a 0, y no se aceptan duplicados.
                            </p>

                            <p className="has-text-justified">
                                <strong>Imagen</strong>: la imagen será la que se muestre en los puntos de venta, debe ser formato png y es requerido.
                            </p>
                        </div>
                       

                    </Modal>
                }
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px' />
            </div>
        );
    }
}



export default NewPresentation;
