import React, { useEffect, useState } from "react";

// Components
import Modal from "../util/modal/Modal";
import Select from "react-select";
import Loader from "../util/loading/Loading";
import { toast } from "react-toastify";

// Services
import CaffenioDriveAPI from "../../services/caffenioDrive";

/**
 *
 * @param {Object} driveInfo - Datos del drive seleccionado.
 * @param {Array} listLideres - Lista de usuarios líderes.
 * @param {Array} listSupervisores - Lista de usuarios supervisores.
 * @param {Array} listEncargadas - Lista de encargados de turno.
 * @param {Array} listCoordinadoresDrive - Lista de coordinadores de drive.
 * @param {Function} onClose - Cierre del modal.
 * @returns Component
 */
export const ModalAsignacion = ({
  driveInfo = {},
  listLideres = [],
  listSupervisores = [],
  listEncargadas = [],
  listCoordinadoresDrive = [],
  listIdsLideresPrevSelected = [],
  onClose,
  onSave,
}) => {
  const [state, setState] = useState({
    liderSelected: null,
    supervisorSelected: null,
    encargadaSelected: null,
    coordinadoresSelect: null,
    isLoading: false,
    popupVisible: false,
    tempLider: null,
  });

  useEffect(() => {      
    let lider = listLideres.find((lid) => lid.value === driveInfo.Lider);
    let supervisor = listSupervisores.find(
      (sup) => sup.value === driveInfo.Supervisor
    );
    let encargada = listEncargadas.find(
      (enc) => enc.value === driveInfo.Encargada
    );
    let coordinador = listCoordinadoresDrive.find(
      (coor) => coor.value === driveInfo.CoordinadorDrive
    );
    setState({
      liderSelected: lider,
      supervisorSelected: supervisor,
      encargadaSelected: encargada,
      coordinadoresSelect: coordinador,
    });
  }, []);

  /**
   * Guarda la información con el líder y supervisor asignados.
   */
  const handleSaveInfo = () => {
    if (
      state.supervisorSelected === null ||
      state.liderSelected === null ||
      state.encargadaSelected === null ||
      state.coordinadoresSelect === null ||
      !state.supervisorSelected ||
      !state.liderSelected ||
      !state.encargadaSelected ||
      !state.coordinadoresSelect
    )
      return toast.warn(
        "No se ha asignado un líder, supervisor, encargado de turno y/o coordinador de drive."
      );
    const driveData = {
      idCaffenio: driveInfo.idCaffenio,
      Lider: state.liderSelected.value,
      Supervisor: state.supervisorSelected.value,
      Encargada: state.encargadaSelected.value,
      CoordinadorDrive: state.coordinadoresSelect.value,
    };
    setState({ ...state, isLoading: true });
    CaffenioDriveAPI.updateLiderSupervisor(driveData)
      .then((res) => onSave(res.data))
      .catch(() => toast.error("Ocurrio un error al guardar la información"))
      .finally(() => setState({ ...state, isLoading: false }));
  };

  /**
   * Manejo del líder seleccionado.
   * @param {Object} item - Lider seleccionado.
   */
  const handleChangeLider = (item) => {
    if (listIdsLideresPrevSelected.includes(item.value))
      setState({ ...state, popupVisible: true, tempLider: item });
    else setState({ ...state, liderSelected: item, tempLider: null });
  };

  /**
   * Manejo del supervisor seleccionado.
   * @param {Object} item - Supervisor seleccionado.
   */
  const handleChangeSupervisor = (item) => {
    setState({ ...state, supervisorSelected: item });
  };

  /**
   * Manejo del encargada de turno seleccionado.
   * @param {Object} item - Encargada seleccionado.
   */
  const handleChangeEncargada = (item) => {
    setState({ ...state, encargadaSelected: item });
  };

  /**
   * Manejo del coordinador de drive seleccionado.
   * @param {Object} item - Coordinador de Drive.
   */
  const handleChangeCoordinadorDrive = (item) => {
    setState({ ...state, coordinadoresSelect: item });
  };

  /**
   * Asigna el lider seleccionado.
   */
  const handleSetLider = () => {
    setState({
      ...state,
      tempLider: null,
      popupVisible: false,
      liderSelected: state.tempLider,
    });
  };

  return (
    <Modal
      showModal={true}
      modalTitle="Asignar Líder y Supervisor"
      width="50%"
      cancel={() => onClose()}
    >
      {state.popupVisible && (
        <Modal
          showModal={true}
          modalTitle="¿Quiere continuar con la asignación?"
          width="40%"
          cancel={() => onClose()}
        >
          <div className="row">
            <p>
              El líder que se seleccionó ya se encuentra asignado a otro Drive.
            </p>
            <p>
              Si es requerido asignarlo en este Drive también, dar clic en la
              opción <strong>Continuar</strong>.
            </p>
            <p>
              De ser el caso contrario, finalizar el proceso dando clic en{" "}
              <strong>Cancelar</strong>.
            </p>
          </div>
          <br />
          <div className="row is-flex is-justify-content-space-between">
            <button className="button is-success" onClick={handleSetLider}>
              Continuar
            </button>
            <button
              className="button is-danger"
              onClick={() => setState({ ...state, popupVisible: false })}
            >
              Cancelar
            </button>
          </div>
        </Modal>
      )}
      {state.isLoading && (
        <Loader
          isFullscreen={true}
          isLoading={state.isLoading}
          width="100px"
          height="100px"
        />
      )}
      <div className="row">
        <div className="columns">
          <div className="column is-6">
            <label className="label">Nombre de Drive</label>
            <p>{driveInfo.Nombre}</p>
          </div>
          <div className="column is-6">
            <label className="label">Estado</label>
            <p>{driveInfo.Estado}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns">
          <div className="column is-6">
            <label className="label">Municipio</label>
            <p>{driveInfo.Municipio}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns">
          <div className="column is-6">
            <label className="label">Líderes</label>
            <Select
              placeholder={"Seleccionar..."}
              name={"selectedOption"}
              value={state.liderSelected}
              onChange={(item) => handleChangeLider(item)}
              noOptionsMessage={() => "No hay opciones"}
              options={listLideres}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns">
          <div className="column is-6">
            <label className="label">Supervisores</label>
            <Select
              placeholder={"Seleccionar..."}
              name={"selectedOption"}
              value={state.supervisorSelected}
              onChange={(item) => handleChangeSupervisor(item)}
              noOptionsMessage={() => "No hay opciones"}
              options={listSupervisores}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns">
          <div className="column is-6">
            <label className="label">Encargados de Turno</label>
            <Select
              placeholder={"Seleccionar..."}
              name={"selectedOption"}
              value={state.encargadaSelected}
              onChange={(item) => handleChangeEncargada(item)}
              noOptionsMessage={() => "No hay opciones"}
              options={listEncargadas}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns">
          <div className="column is-6">
            <label className="label">Coordinador de Drive</label>
            <Select
              placeholder={"Seleccionar..."}
              name={"selectedOption"}
              value={state.coordinadoresSelect}
              onChange={(item) => handleChangeCoordinadorDrive(item)}
              noOptionsMessage={() => "No hay opciones"}
              options={listCoordinadoresDrive}
            />
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="columns">
          <div
            className="column is-12 is-flex"
            style={{ justifyContent: "flex-end" }}
          >
            <button onClick={handleSaveInfo} className="button is-success">
              Guardar Asignación
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
