import React from 'react';
import '../home/Home';
import Home from '../home/Home';
/**
 * Componente de la pantalla principal del sistema.
 */
export default class Index extends React.Component {

    render(){
        return (
            <div>
                {/* <div className="card">
                    <div className="card-content">
                        <h1>Inicio</h1>
                    </div>
                </div> */}
                <Home/>
                
            </div>
        )
    }
}