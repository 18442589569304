
import {apiUrl} from '../constants';
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class DrivePromotionRestrictionsAPI{

    static getAll(){
        return request.get('/PromotionRestrictions/');
    }

    static create( data ) {
        return request.post( '/PromotionRestrictions/', data );
    }

    static get( id) {
        return request.get( `/PromotionRestrictions/${id}` );
    }

     static update( id, newData ) {
        return request.put( `/PromotionRestrictions/${id}`,newData  );
    }
}