
import {apiUrl} from '../constants';
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class DrivePromotionsTypeAPI{

    /**
     * Obtienen todo el listado de promociones
     */
    static getAll(){
        return request.get('/PromotionTypes/');
    }

    /**
     * Crea una promoción.
     * @param { Object } data - Informacion de la promoción 
     * @returns Promise
     */
    static create( data ) {
        return request.post( '/PromotionTypes/', data );
    }

    /**
     * Obtiene promocion por id
     * @param { Object } idPromo - Informacion de la promoción
     * @returns Promise
     */
    static get( id) {
        return request.get( `/PromotionTypes/${id}` );
    }

    /**
     * Actualiza promocion id 
     * @param { Object } id - identificador de la promocion
     * @param { Object } newData - información actualizada
     * @returns Promise
     */
     static update( id, newData ) {
        return request.put( `/PromotionTypes/${id}`,newData  );
    }
}