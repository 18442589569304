import { apiUrl }  from "../constants";
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class ComboDatosAPI {
    
    static getPromoList() {
        return request.get(`/PromoList`);
    }

    static getFactData() {
        return request.get(`/FactData`);
    }

    static getTaxRate() {
        return request.get(`/TaxRate`);
    }

    static getSegment() {
        return request.get(`/Segment`);
    }

    static getDirState(pais) {
        return request.get(`/State${pais ? "?pais="+ pais : "" } `);
    }

    static getDirCity(estado) {
        return request.get(`/City${estado ? "?estado="+ estado : "" } `);
    }

    static getDirSuburb(ciudad) {
        return request.get(`/Suburb${ciudad ? "?ciudad="+ ciudad : "" }`);
    }

    static getDirPostalCode() {
        return request.get(`/PostalCode`);
    }

    static getDirCountry() {
        return request.get(`/Country`);
    }

}