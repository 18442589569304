import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';
import { stringify } from "querystring";

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
});

/**
 * Servicio para el recurso de plazas administrativas
 */
export default class PlazasAdminsAPI {
    /**
     * Obtiene a las plazas.
     * @returns Promise
     */
    static getPlazas(filters = {}) {
        return middleware.hasAuth(() => request.get(`/Plaza?${stringify(filters)}`));
    }    
}