import React, { Component } from 'react';
import Field from '../../../util/field/Field';
import UnitsAPI from '../../../../services/Units';
import ErrorMessages from '../../../../util/validation-error-messages';
import ExpresionesRegulares from '../../../../util/expresiones-regulares';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import InventoryItemsAPI from '../../../../services/InventoryItem';

/**
 * Formulario para cargar y validar PT asignado a artículo inventario
 */
class InventoryItemPTForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventoryItem: { ...this.props.inventoryItem },
            units:[],
            isEditing: false,
            isLoading: false
        }
    }

    componentDidMount(){
        if( this.state.inventoryItem.CodeProvider && this.state.inventoryItem.CodeProvider.length > 8){
            this.setState({isEditing:true}, () => {
                this.getUnits(this.state.inventoryItem.CodeProvider );
            })
        }
    }

    /** Obtiene las unidades relacionadas al código proveedor */
    getUnits = (value) => {
        UnitsAPI.getByCodeProvider(value)
            .then(response => { 
                if(response.data && response.data.length > 0){
                    this.props.setUnits(response.data) ; 
                    if(this.state.isEditing){
                        this.setState({units: response.data})
                    } 
                }else{
                    toast.error("El código de proveedor no tiene unidades relacionadas, contacte al administrador.")
                }                               
            })
            .catch(err => {
                toast.error("Ocurrió un error al cargar unidades relacionadas, contacte al administrador..")
            });
    }

    /** Obtiene unidades de medida relacionadas al código de proveedor, 
     * si hay unidades relacionadas guarda la información */
    getUnitsAnSaveData = (value, validData) => {
        UnitsAPI.getByCodeProvider(value)
            .then(response => { 
                if(response.data && response.data.length > 0){
                    this.props.setUnits(response.data) ; 
                    this.props.save(validData);
                    if(this.state.isEditing){
                        this.setState({units: response.data})
                    } 
                }else{
                    toast.error("El código de proveedor no tiene unidades relacionadas, contacte al administrador.")
                }                               
            })
            .catch(err => {
                toast.error("Ocurrió un error al cargar unidades relacionadas, contacte al administrador..")
            });
    }

    VALIDATION_SCHEMA = Yup.object({
        CodeProvider: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(9, "Código de Proveedor debe tener más de 8 caracteres.")
            .max(30, "Ha excedido el límite de 30 caracteres.")
            .required("Campo Código de Proveedor es requerido."),
            // .test("validate-pt", "El Código de Proveedor ya existe, favor de ingresar otro.", (value) => {                
            //     let  inventoryItems = this.props.getInventoryItems();
            //     let isValid = true ;
            //     if(value && value.length > 8){
            //         let name = value ? value.toLowerCase().trim() : "";
            //         if (inventoryItems.some(u => u.CodeProvider && u.CodeProvider.toLowerCase().trim() === name
            //             &&  u.InventoryItemId !== this.state.inventoryItem.InventoryItemId)){
            //             isValid = false;               
            //         }                                       
            //     }
            //     return isValid;
            // }),
        Name: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoConCaracteres, { message: ErrorMessages.AlfanumericoConCaracteresError })
            .min(1, "Nombre debe tener más de un caracter.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            // .test("validate-name", "El Nombre ya existe, favor de ingresar otro.", (value) => {
            //     let isValid = true;
            //     let  inventoryItems = this.props.getInventoryItems();
            //     let name = value ? value.toLowerCase().trim() : "";
            //     if (inventoryItems.some(u => u.Name && u.Name.toLowerCase().trim() === name
            //         && u.InventoryItemId !== this.state.inventoryItem.InventoryItemId)) {
            //         isValid = false;               
            //     }
            //     return isValid;
            // })
            .required("Campo Nombre es requerido."),
        ShortName: Yup.string()
            .trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(1, "Nombre Topping debe tener más de un caracter.")
            .max(30, "Ha excedido el límite de 30 caracteres.")            
            // .test("validate-topping-name", "El Nombre Topping ya existe, favor de ingresar otro.", (value) => {
            //     let isValid = true;
            //     let  inventoryItems = this.props.getInventoryItems();
            //     let name = value ? value.toLowerCase().trim() : "";
            //     if (inventoryItems.some(u => u.ShortName && u.ShortName.toLowerCase().trim() === name
            //         && u.InventoryItemId !== this.state.inventoryItem.InventoryItemId)) {
            //         isValid = false;               
            //     }
            //     return isValid;
                
            // })
            .required("Campo Nombre Topping es requerido."),
        Segmentos: Yup.array()
            .when([], {
                is: () => !this.state.isEditing,
                then: Yup.array().min(1, "Es necesario establecer por lo menos 1 segmento").required("Es necesario establecer por lo menos 1 segmento").nullable(),
                otherwise: Yup.array().notRequired().nullable(),
            }),
    });


    render() {
        return (
            <Formik
                initialValues={this.state.inventoryItem}
                validationSchema={this.VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    let formatValues = { ...values }
                    if (this.state.isEditing && this.state.inventoryItem.CodeProvider === formatValues.CodeProvider) {
                        let formatValues = { ...values }
                        formatValues.CodeProvider = formatValues.CodeProvider.trim()
                        formatValues.Name = formatValues.Name.trim()
                        formatValues.ShortName = formatValues.ShortName.trim()

                        this.getUnitsAnSaveData(values.CodeProvider, formatValues);
                    } else {
                        InventoryItemsAPI.getByCodeProvider(values.CodeProvider).then(value => {
                            if (value.data) {
                                toast.error("El código de proveedor ya se encuentra registrado.")
                            } else {
                                let formatValues = { ...values }
                                formatValues.CodeProvider = formatValues.CodeProvider.trim()
                                formatValues.Name = formatValues.Name.trim()
                                formatValues.ShortName = formatValues.ShortName.trim()

                                this.getUnitsAnSaveData(values.CodeProvider, formatValues);
                            }
                        }).catch(err => {
                            toast.error(ErrorMessages.SinConexion)
                        })
                    }
                }}>
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }) => (
                    <form onSubmit={handleSubmit} id="frmCodigoProveedor" >
                        {!this.state.isEditing &&
                            <Field
                                label="Segmentos"
                                hasError={errors.Segmentos || touched.Segmentos}
                                msgError={errors.Segmentos || touched.Segmentos ? errors.Segmentos : ""}
                                isHorizontal
                            >
                                <div className="control" style={{ width: "100%" }}>
                                    <Select
                                        isMulti
                                        isClearable
                                        placeholder={"Seleccionar..."}
                                        noOptionsMessage={() => "No hay opciones"}
                                        id="segmentos"
                                        name={"segmentos"}
                                        value={values.Segmentos}
                                        onChange={(val) => {
                                            setFieldValue('Segmentos', val)
                                        }}
                                        options={this.props.segmentos}
                                        getOptionLabel={(segmento) => segmento.Name}
                                        getOptionValue={(segmento) => segmento.SegmentId}
                                    />
                                </div>
                            </Field>
                        }
                        <br />
                        <Field label="Código Proveedor"
                            isHorizontal
                            hasError={errors.CodeProvider || touched.CodeProvider}
                            msgError={errors.CodeProvider || touched.CodeProvider ? errors.CodeProvider : ""}
                            showLeftSpaces={true}
                            childInfo={values.CodeProvider && !errors.CodeProvider ? `Te quedan ${30 - values.CodeProvider.trim().length} caracteres para el código de proveedor.` : ""}
                        >
                            <input
                                id="codigoProveedor"
                                name={"CodeProvider"}
                                className={`input ${errors.CodeProvider && touched.CodeProvider ? "is-danger" : ""}`}
                                placeholder={"Ingrese código de proveedor PT o PE"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.CodeProvider}
                                disabled={this.state.isEditing}
                            />
                        </Field>
                        <br/>
                        <Field label="Nombre"
                            isHorizontal
                            hasError={errors.Name || touched.Name}
                            msgError={errors.Name || touched.Name ? errors.Name : ""}
                            showLeftSpaces={true}
                            childInfo={values.Name && !errors.Name ? `Te quedan ${50 - values.Name.trim().length} caracteres para el nombre del artículo.` : ""}
                        >
                            <input
                                id="namePt"
                                name={"Name"}
                                className={`input ${errors.Name && touched.Name ? "is-danger" : ""}`}
                                placeholder={"Ingrese nombre del artículo"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Name}

                            />
                        </Field>
                        <br/>
                        <Field label="Nombre Topping"
                            isHorizontal
                            hasError = {errors.ShortName|| touched.ShortName}
                            msgError = {errors.ShortName || touched.ShortName ? errors.ShortName : ""}
                            showLeftSpaces={true}
                            childInfo={values.ShortName && !errors.ShortName ? `Te quedan ${30 - values.ShortName.trim().length} caracteres para el nombre corto o nombre topping.` : ""}
                        >
                            <input
                                id="nombreTopping"
                                name={"ShortName"}
                                className={`input ${errors.ShortName && touched.ShortName ? "is-danger" : ""}`}
                                placeholder={"Ingrese nombre de topping o nombre corto"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ShortName}

                            />
                        </Field>

                        <br />

                        <div className="has-text-right buttons-modal" id="stepActions">
                            
                            <div className="pull-right">
                                <button id="btnContinuarStepPT" type="submit" className="button is-success">Continuar</button>
                            </div>
                        </div>
                        
                    </form>
                )}
                 
            </Formik>

        )
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
InventoryItemPTForm.defaultProps = {
    isEditing: false,
    inventoryItem: {
        CodeProvider: "",
        InventoryCode: "",
        Name: "",
        ShortName: "",
        UnitPurchase: 0,
        StorageUnit: 0,
        UnitSale: 0,
        ConvFactorBuy: "",
        ConvFactorSale: "",
        FactorBuyMin: 0.95,
        AmountMin: 0,
        AmountMax: 0,
        InventoryTypeId: 0,
        CodeSat: null,
        IsTopping: false,
        ToppingPrice: 0,
        ToppingWeight: 0
    }
}

export default InventoryItemPTForm;