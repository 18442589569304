import React, { Component } from 'react'
import PropTypes from 'prop-types'

const RecipeToppings = (props) => {
    return (
        <div className={(props.details != '' && props.details.length > 0)? "" : "recipe-details-empty"}>
            <table className="table is-fullwidth is-striped">
                { props.details.length > 0 &&
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th>Nombre Corto</th>
                        <th>Mínimo</th>
                        <th>Máximo</th>
                        <th>Unidad</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                }
                <tbody>
                {props.details != '' ? props.details.map((detail, i) => {
                    let errorCantidad = Number(detail.MinQuantity) > Number(detail.MaxQuantity)

                    return (
                        <tr key={i}>
                            <td>{detail.CodeProvider}</td>
                            <td>{detail.Name}</td>
                            <td>{detail.ShortName}</td>
                            <td>
                                <input
                                    className={"input" + (errorCantidad ? " is-danger" : "")}
                                    placeholder="Mínimo"
                                    value={detail.MinQuantity}
                                    type="number"
                                    maxLength="5"
                                    step="1"
                                    min="0"
                                    max={detail.MaxQuantity === "" ? 0 : Number(detail.MaxQuantity)}
                                    onKeyPress= {(event) => {
                                        const keyCode = event.keyCode || event.which;
                                        const keyValue = String.fromCharCode(keyCode);
                                        // if (/\+|-|[a-zA-Z]|\d+(\.\d{3,})|\d{4,}+(\.\d)/.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
                                        if (/\+|-|e|E|\./.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
                                            event.preventDefault();
                                    }}
                                    onChange={(e) => props.quantityChanged(e.currentTarget.value, i, "MinQuantity")}
                                />
                            </td>
                            <td>
                                <input
                                    className={"input" + (errorCantidad ? " is-danger" : "")}
                                    placeholder="Máximo"
                                    value={detail.MaxQuantity}
                                    type="number"
                                    maxLength="5"
                                    step="1"
                                    min={(detail.MinQuantity === "") ? 0 : Number(detail.MinQuantity)}
                                    max="999"
                                    onKeyPress= {(event) => {
                                        const keyCode = event.keyCode || event.which;
                                        const keyValue = String.fromCharCode(keyCode);
                                        if (/\+|-|e|E|\./.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
                                            event.preventDefault();
                                    }}
                                    onChange={(e) => props.quantityChanged(e.currentTarget.value, i, "MaxQuantity")}
                                />
                            </td>
                            <td>{detail.UnitSaleStr}</td>
                            <td>
                                <a className="button tableAction is-danger tooltip"
                                    data-tooltip="Eliminar"

                                    onClick={(e) => {
                                    e.stopPropagation();
                                    props.removeDetail(i)
                                }
                                }>
                                    <span className="icon">
                                        <i className="fa fa-trash"/>
                                    </span>
                                </a>
                            </td>
                        </tr>
                    )
                }):<tr>
                    <td>No hay toppings.</td>
                </tr>}
                </tbody>
            </table>
        </div>
    )
}

RecipeToppings.propTypes = {
    details: PropTypes.array.isRequired,
    quantityChanged: PropTypes.func.isRequired,
    removeDetail: PropTypes.func.isRequired,
    mostrarDrives: PropTypes.bool,
    onMostrarDrives: PropTypes.func,
    checkForError: PropTypes.func
}

RecipeToppings.defaultProps = {
    details: [],
    quantityChanged: () => {},
    removeDetail: () => {},
    mostrarDrives: false,
    onMostrarDrives: () => {},
    checkForError: () => {}    
}

export default RecipeToppings;
