import React, { Component } from 'react';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';

import lodash from 'lodash';
import { toast } from 'react-toastify';
import ExpresionesRegulares from '../../../../util/expresiones-regulares';
import Field from '../../../util/field/Field';
import ErrorMessages from '../../../../util/validation-error-messages';
import {nombresSinTipo} from '../../../../util/strings/sin-tipo-receta';
const SUPPORTED_FORMATS = [
    "image/png",
];

/**
 * Formulario para cargar la información general de la receta
 */
class RecipeConfigurationStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipe: {},
            requiredImages: [],
            isEditing: false,
            isLoading: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ recipe: { ...nextProps.recipe }, isEditing: nextProps.isEditing });
    }

    /**Esquema para validar al crear receta */
    VALIDATION_SCHEMA = Yup.object({
        ClassificationId: Yup.string().required("Familia es requerido.").nullable(),
        RecipeTypeId: Yup.string().required("Requiere al menos un tipo de receta.").nullable(),
        FlavorTypeId: Yup.string().required("Requiere al menos un tipo de sabor.").nullable(),
        GrainTypeId: Yup.string().required("Requiere al menos un tipo de grano.").nullable(),
        MilkTypeId: Yup.string().required("Requiere al menos un tipo de leche.").nullable(),
        PresentationId: Yup.string().required("Requiere al menos una presentación.").nullable(),
        SegmentationId: Yup.string()
        .when([], {
            is: () => !this.state.isEditing,
            then: Yup.string().required("Segmentación es requerido.").nullable(),
            otherwise: Yup.string().notRequired(),
        }),
        KiwiId: Yup.string().trim().max(10, "Ha excedido el límite de 10 caracteres.").nullable()
    });

    /**Esquema para validar al editar receta con imagen*/
    VALIDATION_SCHEMA_EDIT_WITH_IMG = Yup.object({
        Description: Yup.string().trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .required("Campo Nombre es requerido."),
        ClassificationId: Yup.string().required("Familia es requerido.").nullable(),
        RecipeTypeId: Yup.string().required("Requiere al menos un tipo de receta.").nullable(),
        FlavorTypeId: Yup.string().required("Requiere al menos un tipo de sabor.").nullable(),
        GrainTypeId: Yup.string().required("Requiere al menos un tipo de grano.").nullable(),
        MilkTypeId: Yup.string().required("Requiere al menos un tipo de leche.").nullable(),
        PresentationId: Yup.string().required("Requiere al menos una presentación.").nullable(),
        /* Image: Yup.mixed()
            .required("Campo Imagen es requerido.")
            .test(
                "fileFormat",
                ErrorMessages.PngFormatError,
                value => value && SUPPORTED_FORMATS.includes(value.type) || (this.state.recipe.UrlImage !== null && this.state.recipe.UrlImage.length > 0)

            ), */
        ShortName: Yup.string().trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(40, "Ha excedido el límite de 40 caracteres.")
            .required("Campo Nombre Corto es requerido."),
        KiwiId: Yup.string().trim().max(10, "Ha excedido el límite de 10 caracteres.").nullable()
    });

     /**Esquema para validar al editar receta sin imagen*/
    VALIDATION_SCHEMA_EDIT = Yup.object({
        Description: Yup.string().trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(50, "Ha excedido el límite de 50 caracteres.")
            .required("Campo Nombre es requerido."),
        ClassificationId: Yup.string().required("Familia es requerido.").nullable(),
        RecipeTypeId: Yup.string().required("Requiere al menos un tipo de receta.").nullable(),
        FlavorTypeId: Yup.string().required("Requiere al menos un tipo de sabor.").nullable(),
        GrainTypeId: Yup.string().required("Requiere al menos un tipo de grano.").nullable(),
        MilkTypeId: Yup.string().required("Requiere al menos un tipo de leche.").nullable(),
        PresentationId: Yup.string().required("Requiere al menos una presentación.").nullable(),
        ShortName: Yup.string().trim()
            .matches(ExpresionesRegulares.AlfanumericoSinEspeciales, { message: ErrorMessages.AlfanumericoSinEspecialesError })
            .min(3, "Nombre debe tener más de 2 caracteres.")
            .max(40, "Ha excedido el límite de 40 caracteres.")
            .required("Campo Nombre Corto es requerido."),
        KiwiId: Yup.string().trim().max(10, "Ha excedido el límite de 10 caracteres.").nullable()
    });

    /**Carga el previo de la imagen */
    loadFile = (file) => {

        var reader = new FileReader();
        reader.onload = function (e) {
            let previewControl = document.getElementById("imgPreview");
            if (previewControl) {
                document.getElementById("imgPreview").setAttribute('src', e.target.result);
            }
        }
        reader.readAsDataURL(file);
    }

    /**
     * Previene el submit al dar enter
     * @param {*} keyEvent 
     */
     onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          keyEvent.preventDefault();
        }
    }

     /**
     * Función que analiza el nombre dado y determina si representa a la clasificacion sin tipo
     * @param nombre String. Nombre que se va analizar
     * @returns True si el nombre representa a la clasificación sin tipo. False en caso contrario
     */
    nombreEsSinTipo = (nombre) => {    
        return nombresSinTipo.indexOf((nombre.trim().toLowerCase())) !== -1
    }

    render() {
        return (
            <Formik
                enableReinitialize={true}
                initialValues={this.state.isEditing ? this.state.recipe : {}}
                validationSchema={this.state.isEditing 
                    ? this.state.recipe.FlavorTypeId && this.state.recipe.FlavorTypeId[0]
                    && !this.props.sintiposArray.includes(this.state.recipe.FlavorTypeId[0].Name)
                        ? this.VALIDATION_SCHEMA_EDIT_WITH_IMG 
                        : this.VALIDATION_SCHEMA_EDIT
                    : this.VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    /* if (this.state.requiredImages.some(r => r.Image === null || r.Image.length === 0)) {
                        toast.error("Debe ingresar todas las imágenes de los sabores.")
                    } else { */
                        //values.flavorsImages = this.state.requiredImages;
                        this.props.save(values)
                    //}

                }}>
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }) => (
                    <form onSubmit={handleSubmit} id="frmConfigStep" onKeyDown={this.onKeyDown}>
                        {this.state.isEditing &&
                            <div className="columns is-multiline">
                                <div className="column is-2">
                                    <label className="label">Receta</label>
                                </div>
                                <div className="column is-10">
                                    <Field
                                        hasError={errors.Description && touched.Description}
                                        msgError={errors.Description && touched.Description ? errors.Description : ""}
                                        showLeftSpaces={true}
                                        childInfo={values.Description && !errors.Description
                                            ? `Te quedan ${50 - values.Description.trim().length} caracteres para el nombre.`
                                            : ""}
                                    >
                                        <input
                                            id="txtNameRecipe"
                                            name={"Description"}
                                            className={`input ${errors.Description && touched.Description ? "is-danger" : ""}`}
                                            placeholder={"Ingrese nombre de la receta"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.Description}
                                            disabled={true}

                                        />
                                    </Field>
                                </div>
                                <div className="column is-2">
                                    <label className="label">Nombre Corto</label>
                                </div>
                                <div className="column is-10">
                                    <Field
                                        hasError={errors.ShortName && touched.ShortName}
                                        msgError={errors.ShortName && touched.ShortName ? errors.ShortName : ""}
                                        showLeftSpaces={true}
                                        childInfo={values.ShortName && !errors.ShortName
                                            ? `Te quedan ${40 - values.ShortName.trim().length} caracteres para el nombre corto.`
                                            : ""}
                                    >
                                        <input
                                            id="txtShortNameRecipe"
                                            name={"ShortName"}
                                            className={`input ${errors.ShortName && touched.ShortName ? "is-danger" : ""}`}
                                            placeholder={"Ingrese nombre corto de la receta"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ShortName}
                                        />
                                    </Field>
                                </div>

                            </div>
                        }

                        <div className="columns is-multiline">
                            <div className="column is-2">
                                <label className="label">Id Externo</label>
                            </div>
                            <div className="column is-10">
                                <Field
                                    hasError={errors.KiwiId && touched.KiwiId}
                                    msgError={errors.KiwiId && touched.KiwiId ? errors.KiwiId : ""}
                                    showLeftSpaces={true}
                                    childInfo={values.KiwiId && !errors.KiwiId
                                        ? `Te quedan ${10 - values.KiwiId.trim().length} caracteres para el nombre corto.`
                                        : ""}
                                >
                                    <input
                                        id="txtKiwiId"
                                        name={"KiwiId"}
                                        className={`input ${errors.KiwiId && touched.KiwiId ? "is-danger" : ""}`}
                                        placeholder={"Ingrese el Id Externo de la receta (Opcional)"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.KiwiId}
                                    />
                                </Field>
                            </div>
                        </div>

                        {/* Familia, tipo receta , sabor */}
                        <div className="columns">
                            <div className="column is-4">
                                <label className="label">Familia</label>
                                <div className="control">
                                    <Select
                                        id="ddlClassification"
                                        name="ClassificationId"
                                        value={values.ClassificationId}
                                        placeholder="Seleccionar..."
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={lodash.sortBy(this.props.classifications.map(c => {
                                            c.value = c.ClassificationId;
                                            c.label = c.Name;
                                            return c;
                                        }), [ts => ts.label.toLowerCase()], ['asc'])}
                                        onChange={(val) => {
                                            setFieldValue('ClassificationId', val)
                                        }}
                                        onBlur={() => setFieldTouched('ClassificationId', true)}
                                    />

                                </div>
                                <div className="is-centered">
                                    {(errors.ClassificationId || touched.ClassificationId) &&
                                        <div style={{ 'fontSize': '12px', 'color': 'rgb(244, 67, 54)' }}>
                                            {errors.ClassificationId ? errors.ClassificationId : ""}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="column is-4">
                                <label className="label">Tipo Receta</label>
                                <div className="control">
                                    <Select
                                        id="ddlTypeRecipe"
                                        name={"RecipeTypeId"}
                                        value={values.RecipeTypeId}
                                        placeholder={"Seleccionar..."}
                                        isMulti={!this.state.isEditing}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={lodash.sortBy(this.props.recipeTypes.map(rt => {
                                            //Formato al listado
                                            rt.value = rt.RecipeTypeId;
                                            rt.label = rt.Description;
                                            return rt;
                                        }), [ts => ts.label.toLowerCase()], ['asc'])}
                                        onChange={(val) => {
                                            let formatValue = []
                                            let lstRecetasSinTipo = this.props.lstRecipes.filter(l => 
                                                values.ClassificationId 
                                                && l.ClassificationId === values.ClassificationId.value 
                                            )
                                            
                                            if (this.state.isEditing) {
                                                formatValue.push(val)
                                            } else {
                                                formatValue = val;
                                            }       

                                            if(
                                                (formatValue.some(r => this.nombreEsSinTipo(r.label)) && 
                                                lstRecetasSinTipo.some(r => !this.nombreEsSinTipo(r.RecipeType.Description)) )
                                            || (formatValue.some(r =>  !this.nombreEsSinTipo(r.label)) && 
                                                lstRecetasSinTipo.some(r => this.nombreEsSinTipo(r.RecipeType.Description)))
                                            ){
                                                toast.dismiss()
                                                toast.warn("Se recomienda no combinar recetas con tipo receta y sin tipo")                                            
                                            }
                                            
                                            setFieldValue('RecipeTypeId', formatValue)
                                            
                                        }}
                                        onBlur={() => setFieldTouched('RecipeTypeId', true)}
                                    />
                                </div>
                                <div className="is-centered">
                                    {(errors.RecipeTypeId || touched.RecipeTypeId) &&
                                        <div style={{ 'fontSize': '12px', 'color': 'rgb(244, 67, 54)' }}>
                                            {errors.RecipeTypeId ? errors.RecipeTypeId : ""}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="column is-4">
                                <label className="label">Tipo Sabor</label>
                                <div className="control">
                                    <Select
                                        id="ddlFlavorType"
                                        name="FlavorTypeId"
                                        value={values.FlavorTypeId}
                                        placeholder={"Seleccionar..."}
                                        isMulti={!this.state.isEditing}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={lodash.sortBy(this.props.flavors.map(f => {
                                            f.value = f.FlavorTypeId;
                                            f.label = f.Name;
                                            return f;
                                        }), [ts => ts.label.toLowerCase()], ['asc'])}
                                        onChange={(val) => {                                            
                                            let formatValue = []
                                            let lstRecetasSinTipo = this.props.lstRecipes.filter(l => 
                                                values.ClassificationId && l.ClassificationId === values.ClassificationId.value 
                                                )
                                            if (this.state.isEditing) {
                                                toast.info('Al modificar el tipo sabor, es necesario cambiar su imagen nuevamente.')
                                                formatValue.push(val);
                                            } else {
                                                let requiredImage = [];
                                                formatValue = val;
                                                if (val) {
                                                    requiredImage = val.filter(v => {
                                                        if (!this.props.sintiposArray.includes(v.label)
                                                        ) {
                                                            return v;    
                                                        }
                                                    }).map(r => {
                                                        return { 
                                                            FlavorTypeId: r.value, 
                                                            Name: r.label, 
                                                            UrlImage:  this.state.requiredImages && this.state.requiredImages.some(s => s.FlavorTypeId === r.value) 
                                                                ? this.state.requiredImages.filter(s => s.FlavorTypeId === r.value)[0].UrlImage  
                                                                : "", 
                                                            Image: this.state.requiredImages && this.state.requiredImages.some(s => s.FlavorTypeId === r.value) 
                                                                ? this.state.requiredImages.filter(s => s.FlavorTypeId === r.value)[0].Image  
                                                                : null 
                                                        }
                                                    });
                                                }
                                                this.setState({ requiredImages: requiredImage });
                                            }
                                            if(
                                                (formatValue.some(r => this.nombreEsSinTipo(r.label)) && 
                                                lstRecetasSinTipo.some(r => !this.nombreEsSinTipo(r.FlavorType.Name)) )
                                            || (formatValue.some(r =>  !this.nombreEsSinTipo(r.label)) && 
                                                lstRecetasSinTipo.some(r => this.nombreEsSinTipo(r.FlavorType.Name)))
                                            ){
                                                toast.dismiss()
                                                toast.warn("Se recomienda no combinar recetas con tipo sabor y sin tipo")                                            
                                            }
                                            setFieldValue('FlavorTypeId', formatValue)
                                            
                                        }}
                                        onBlur={() => setFieldTouched('FlavorTypeId', true)}
                                    />
                                </div>
                                <div className="is-centered">
                                    {(errors.FlavorTypeId || touched.FlavorTypeId) &&
                                        <div style={{ 'fontSize': '12px', 'color': 'rgb(244, 67, 54)' }}>
                                            {errors.FlavorTypeId ? errors.FlavorTypeId : ""}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* grano, leche, presentación  */}
                        <div className="columns">
                            <div className="column is-4">
                                <label className="label">Tipo Grano</label>
                                <div className="control">
                                    <Select
                                        id="ddlGrainType"
                                        name={"GrainTypeId"}
                                        value={values.GrainTypeId}
                                        isMulti={!this.state.isEditing}
                                        placeholder={"Seleccionar..."}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={lodash.sortBy(this.props.grains.map(g => {
                                            //formato al listado 
                                            g.value = g.GrainTypeId;
                                            g.label = g.Description;
                                            return g;
                                        }), [ts => ts.label.toLowerCase()], ['asc'])}
                                        onChange={(val) => {
                                            let formatValue = []
                                            let lstRecetasSinTipo = this.props.lstRecipes.filter(l => 
                                                values.ClassificationId && l.ClassificationId === values.ClassificationId.value )
                                            if (this.state.isEditing) {
                                                formatValue.push(val)
                                            } else {
                                                formatValue = val;
                                            }
                                            if(
                                                (formatValue.some(r => this.nombreEsSinTipo(r.label)) && 
                                                lstRecetasSinTipo.some(r => !this.nombreEsSinTipo(r.GrainType.Description)) )
                                            || (formatValue.some(r =>  !this.nombreEsSinTipo(r.label)) && 
                                                lstRecetasSinTipo.some(r => this.nombreEsSinTipo(r.GrainType.Description)))
                                            ){
                                                toast.dismiss()
                                                toast.warn("Se recomienda no combinar recetas con tipo grano y sin tipo")                                            
                                            }
                                            setFieldValue('GrainTypeId', formatValue)
                                            
                                        }}
                                        onBlur={() => setFieldTouched('GrainTypeId', true)}
                                    />
                                </div>
                                <div className="is-centered">
                                    {(errors.GrainTypeId || touched.GrainTypeId) &&
                                        <div style={{ 'fontSize': '12px', 'color': 'rgb(244, 67, 54)' }}>
                                            {errors.GrainTypeId ? errors.GrainTypeId : ""}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="column is-4">
                                <label className="label">Tipo Leche</label>
                                <div className="control">
                                    <Select
                                        id="ddlMilkType"
                                        name={"MilkTypeId"}
                                        value={values.MilkTypeId}
                                        isMulti={!this.state.isEditing}
                                        placeholder={"Seleccionar..."}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={lodash.sortBy(this.props.milks.map(m => {
                                            m.value = m.MilkTypeId;
                                            m.label = m.Description;
                                            return m;

                                        }), [ts => ts.label.toLowerCase()], ['asc'])}
                                        onChange={(val) => {
                                            let formatValue = []
                                            let lstRecetasSinTipo = this.props.lstRecipes.filter(l => 
                                                values.ClassificationId 
                                                && l.ClassificationId === values.ClassificationId.value                                                 
                                               )
                                            if (this.state.isEditing) {
                                                formatValue.push(val)
                                            } else {
                                                formatValue = val;
                                            }
                                            if(
                                                (formatValue.some(r => this.nombreEsSinTipo(r.label)) && 
                                                lstRecetasSinTipo.some(r => !this.nombreEsSinTipo(r.MilkType.Description)) )
                                            || (formatValue.some(r =>  !this.nombreEsSinTipo(r.label)) && 
                                                lstRecetasSinTipo.some(r => this.nombreEsSinTipo(r.MilkType.Description)))
                                            ){
                                                toast.dismiss()
                                                toast.warn("Se recomienda no combinar recetas con tipo leche y sin tipo")                                            
                                            }
                                            
                                            setFieldValue('MilkTypeId', formatValue)
                                            
                                        }}
                                        onBlur={() => setFieldTouched('MilkTypeId', true)}
                                    />
                                </div>
                                <div className="is-centered">
                                    {(errors.MilkTypeId || touched.MilkTypeId) &&
                                        <div style={{ 'fontSize': '12px', 'color': 'rgb(244, 67, 54)' }}>
                                            {errors.MilkTypeId ? errors.MilkTypeId : ""}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="column is-4">
                                <label className="label">Presentación</label>
                                <div className="control">
                                    <Select
                                        id="ddlPresentationType"
                                        name={"PresentationId"}
                                        value={values.PresentationId}
                                        placeholder={"Seleccionar..."}
                                        isMulti={!this.state.isEditing}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={lodash.sortBy(this.props.presentations.map(p => {
                                            p.value = p.PresentationId;
                                            p.label = p.Name;
                                            return p;
                                        }), [ts => ts.label.toLowerCase()], ['asc'])}
                                        onChange={(val) => {
                                            let formatValue = [];
                                            let lstRecetasSinTipo = this.props.lstRecipes.filter(l => 
                                                values.ClassificationId 
                                                && l.ClassificationId === values.ClassificationId.value                                                 
                                            )

                                            if (this.state.isEditing) {
                                                formatValue.push(val)
                                            } else {
                                                formatValue = val;
                                            }
                                            if(
                                                (formatValue.some(r => this.nombreEsSinTipo(r.label)) && 
                                                lstRecetasSinTipo.some(r => !this.nombreEsSinTipo(r.Presentation.Name)) )
                                            || (formatValue.some(r =>  !this.nombreEsSinTipo(r.label)) && 
                                                lstRecetasSinTipo.some(r => this.nombreEsSinTipo(r.Presentation.Name)))
                                            ){
                                                toast.dismiss()
                                                toast.warn("Se recomienda no combinar recetas con tipo presentación y sin tipo")                                            
                                            }
                                            setFieldValue('PresentationId', formatValue)
                                            
                                        }}
                                        onBlur={() => setFieldTouched('PresentationId', true)}
                                    />
                                </div>
                                <div className="is-centered">
                                    {(errors.PresentationId || touched.PresentationId) &&
                                        <div style={{ 'fontSize': '12px', 'color': 'rgb(244, 67, 54)' }}>
                                            {errors.PresentationId ? errors.PresentationId : ""}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* Segmentación  */}
                        {!this.state.isEditing &&
                        <div className="columns">
                            <div className="column is-4">
                                <label className="label">Segmentación</label>
                                <div className="control">
                                    <Select
                                        id="ddlSegmentation"
                                        name={"SegmentationId"}
                                        value={values.SegmentationId}
                                        placeholder={"Seleccionar..."}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={(val) => {
                                            setFieldValue('SegmentationId', val)
                                        }}
                                        onBlur={() => setFieldTouched('SegmentationId', true)}
                                        options={this.props.segments}
                                        getOptionLabel={(objSegment) => {
                                            switch(objSegment.Name){
                                                case "Andatti drive":
                                                    return "Andatti"
                                                case "Drive":
                                                    return "Caffenio"
                                                case "momento espresso":
                                                    return "Momento Espresso"
                                                default:
                                                    return objSegment.Name
                                            }
                                        }}
                                        getOptionValue={(objSegment) => objSegment.SegmentId}
                                    />
                                </div>
                                <div className="is-centered">
                                    {(errors.SegmentationId || touched.SegmentationId) &&
                                        <div style={{ 'fontSize': '12px', 'color': 'rgb(244, 67, 54)' }}>
                                            {errors.SegmentationId ? errors.SegmentationId : ""}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        }
                        <br />

{/*                         {//Tabla con las imagenes requeridas por sabor
                            !this.state.isEditing && this.state.requiredImages.length > 0 &&
                            <div className="table__wrapper" id="tblImagesFlavor">
                                <table className="table is-fullwidth ">
                                    <thead>
                                        <tr>
                                            <th>Sabor</th>
                                            <th>Imagen</th>
                                            <th>Acciones</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {this.state.requiredImages.map((image, index) => {
                                            return (
                                                <tr key={`trFlavor_${image.FlavorTypeId}`}>
                                                    <th>{image.Name}</th>
                                                    <th>
                                                        {
                                                            image.Image !== null
                                                                ?
                                                                <figure className="image container-image " >
                                                                    <img id={`imgPreview_${image.FlavorTypeId}`}
                                                                        src="#"
                                                                        style={{ width: 'auto', height: '50px' }}
                                                                        alt="" />
                                                                </figure>
                                                                : <div className={`file is-info has-name`}>
                                                                    <label className="file-label">
                                                                        <input
                                                                            className={`input file-input`}
                                                                            id={`fileFlavor${image.FlavorTypeId}`}
                                                                            type="file"
                                                                            name="Image"
                                                                            accept="image/x-png"
                                                                            name="Image"
                                                                            onChange={(e) => {
                                                                                let files = e.target.files;
                                                                                let file = files[0]
                                                                                e.stopPropagation();
                                                                                e.target.value = null;
                                                                                if (file) {
                                                                                    let itemImages = this.state.requiredImages;
                                                                                    itemImages[index].Image = file;
                                                                                    this.setState({ requiredImages: itemImages }, () => {
                                                                                        var reader = new FileReader();
                                                                                        reader.onload = function (e) {
                                                                                            let previewControl = document.getElementById(`imgPreview_${image.FlavorTypeId}`);
                                                                                            if (previewControl) {
                                                                                                document.getElementById(`imgPreview_${image.FlavorTypeId}`).setAttribute('src', e.target.result);
                                                                                            }
                                                                                        }
                                                                                        reader.readAsDataURL(file);
                                                                                    });
                                                                                }

                                                                            }}
                                                                        />
                                                                        <span className="file-cta">
                                                                            <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo .PNG</i></span>
                                                                        </span>
                                                                        <span className="file-name">{values.Image == null ? "Archivo" : values.Image.name}</span>
                                                                    </label>
                                                                </div>
                                                        }
                                                    </th>
                                                    <th>{image.Image !== null &&
                                                        <button className="button tableAction is-danger tooltip" data-tooltip="Eliminar" onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.target.value = null;
                                                            let itemImage = this.state.requiredImages;
                                                            itemImage[index].Image = null;
                                                            itemImage[index].UrlImage = "";
                                                            this.setState({ requiredImage: itemImage })
                                                        }
                                                        }>
                                                            <span className="icon">
                                                                <i className="fa fa-trash" />
                                                            </span>
                                                        </button>}

                                                    </th>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>

                        } */}

                        {/* <div className="columns is-vcentered is-multiline">
                            <div className="column is-4"></div>
                            <div className="column is-4">
                                {     this.state.isEditing                           
                                        && this.state.recipe.FlavorTypeId && this.state.recipe.FlavorTypeId[0]
                                        && !this.props.sintiposArray.includes(values.FlavorTypeId && values.FlavorTypeId[0] ? values.FlavorTypeId[0].Name : "")                                         
                                        ? ( values.UrlImage || values.Image 
                                            ? <Field>
                                                <div className="column">
                                                    <figure className="image container-image " >
                                                        {values.UrlImage && values.UrlImage.length > 0 
                                                            ? <img id={"imgPreview"}
                                                                src={`${values.UrlImage.toString() + "?" + new Date().getTime()}`}
                                                                style={{ width: 'auto', height: '50px' }}
                                                                alt="" />
                                                            : <img id={"imgPreview"}
                                                                src={`${ values.Image ? this.loadFile(values.Image) : "#"}`}
                                                                style={{ width: 'auto', height: '50px' }}
                                                                alt="" />
                                                        }
                                                        
                                                    </figure>
                                                </div>
                                                <button className="button tableAction is-danger trash-right"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.target.value = null;
                                                        let tempRecipe = { ...values };
                                                        tempRecipe.UrlImage = null;
                                                        tempRecipe.Image = null;
                                                        this.setState({ recipe: tempRecipe }, () => {
                                                            setFieldValue('Image', null)
                                                            setFieldValue('UrlImage', null)
                                                        })

                                                    }
                                                    }>
                                                    <span className="icon">
                                                        <i className="fa fa-trash" />
                                                    </span>
                                                </button>
                                            </Field>
                                            :  <Field isHorizontal
                                                hasError={errors.Image || touched.Image}
                                                msgError={errors.Image || touched.Image ? errors.Image : ""}>
                                                <div className={`file ${errors.Image && touched.Image ? "is-danger" : "is-info"} has-name`}>
                                                    <label className="file-label">
                                                        <input
                                                            className={`input file-input ${errors.Image || touched.Image ? "is-danger" : ""}`}
                                                            id="fileClasification"
                                                            type="file"
                                                            name="Image"
                                                            accept="image/x-png"
                                                            name="Image"
                                                            onChange={(e) => {
                                                                
                                                                let files = e.target.files
                                                                let file = files[0]
                                                                e.stopPropagation();
                                                                e.target.value = null;
                                                                let tempRecipe = { ...this.state.recipe };
                                                                tempRecipe.UrlImage = null;
                                                                this.setState({ recipe: tempRecipe }, () => {
                                                                    if (file) {
                                                                        // var reader = new FileReader();
                                                                        // reader.onload = function (e) {
                                                                        //     let previewControl = document.getElementById("imgPreview");
                                                                        //     if(previewControl){
                                                                        //         document.getElementById("imgPreview").setAttribute('src', e.target.result);
                                                                        //     }                                                    
                                                                        // }
                                                                        // reader.readAsDataURL(file);
                                                                        setFieldValue('UrlImage', null);
                                                                        setFieldValue('Image', file);
                                                                        
                                                                    } else {
                                                                        setFieldValue('UrlImage', null)
                                                                        setFieldValue('Image', null)
                                                                    }
                                                                })
                                                            }}
                                                            onBlur={(e) => { handleBlur(e) }}
                                                        />
                                                        <span className="file-cta">
                                                            <span className="file-label"><i className="fa fa-upload"> Seleccionar archivo .PNG</i></span>
                                                        </span>
                                                        <span className="file-name">{values.Image == null ? "Archivo" : values.Image.name}</span>
                                                    </label>
                                                </div>                                               
                                            </Field> 
                                            )
                                        : ""
                                }
                                
                            </div>
                            <div className="column is-4"></div>
                            
                        </div> */}
                        
                        
                        {/* step buttons */}
                        <div className="has-text-left buttons-modal" id="stepActions">
                            <div className="pull-left"></div>
                        </div>
                        <div className="has-text-right buttons-modal" id="stepActions">
                            <div className="pull-right">
                                <button id="btnSaveStepConfig" type="submit" className="button is-success"  >Continuar</button>
                            </div>
                        </div>


                    </form>
                )}

            </Formik>

        )
    }
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
RecipeConfigurationStep.defaultProps = {
    isEditing: false,
    recipe: {
        ShortName: "",
        Description: "",
        ClassificationId: null,
        PresentationId: null,
        FlavorTypeId: null,
        RecipeTypeId: null,
        MilkTypeId: null,
        GrainTypeId: null,
        IVAApply: true,
        KiwiId: "",
        Active: true,
        MobileApply: true,
        WelcomeGiftApply: true,
        ClassificationSatId: 0,
        CodeSatId: 0,
        Details: [],
        GiftWelcomeActive: true,
        Image: null,
        UrlImage: ""
    }
}

export default RecipeConfigurationStep;