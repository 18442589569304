import {apiUrl} from '../constants';
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class ExtraccionesCafeAPI {

    static GetFormulaData(){
        return request.get('FormulaExtracto/GetFormulaExtracto')
    }

    static CreateFormulaData(formulaData){
        return request.post('/FormulaExtracto/NewFormulaExtracto', formulaData);
    }

    static UpdateFormulaData(formulaData){
        return request.put('/FormulaExtracto/UpdateFormulaExtracto', formulaData);
    }
}
