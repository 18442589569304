import moment from 'moment';
import React , {Component} from 'react';
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker';
import CaffenioDriveAPI from "../../services/caffenioDrive";
import Loading from '../util/loading/Loading';
import "./reporteCorte.css"

/**
 * Componente de la vista de un reporte de un drive
 */
class ReporteCorteView extends Component {
    state = {
        drive: null,
        fecha: null,
        isLoading: false,
        mensaje: "Cargando drive..."
    }
    
    componentDidMount = () => {
        if(this.props.match.params.id){
            this.setState({isLoading: true}, () => {
                CaffenioDriveAPI.getCaffenioById(this.props.match.params.id)
                .then(response => {
                    let state = {...this.state}
                    console.log(response)
                    state.drive = response.data
                    state.numConvenio = 123456
                    state.numRef = 789012
                    state.totalEfectivo = 98765
                    state.totalDeposito = 43210
                    state.isLoading = false
                    this.setState(state)
                })
                .catch(err => {
                    this.setState({isLoading: false, mensaje: "Hubo un error al buscar el drive"})
                })
            })
        }
    }

    handleDateChange = (date) => {
        let state = { ...this.state };
        console.log("Fecha! => ", date)
        state.fecha = date;
        this.setState(state);
    }

    render(){
        let driveInvalido = this.props.drive == undefined || this.props.drive == null || typeof(this.props.drive) != "object"
        return(
            <div>
                { this.state.drive ?
                    <React.Fragment>
                    <h1 className="title is-1">Reporte</h1>
                    <h2 className="subtitle is-2">{this.state.drive.NombreNuevo}</h2>
                    <div className="card">
                        <div className="card-content">
                            <div className="columns is-multiline">
                                <div className="column is-12">
                                    <label className="label">Fecha reporte</label>
                                    <DatePicker
                                        name={"OpeningDate"}
                                        selected={this.state.fecha}
                                        onChange={e => this.handleDateChange(e)}
                                        popperPlacement="bottom"
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: '5px, 20px',
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: 'viewport'
                                            }
                                        }}
                                        locale="es"
                                        todayButton="Hoy"
                                        dateFormat="dd/MM/yyyy"
                                        className="input"
                                        autoComplete={"off"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>

                    {this.state.fecha ?
                        <ReporteCorte
                            caffenio={this.state.drive.NombreNuevo}
                            fecha={this.state.fecha ? this.state.fecha : ""}
                            numConvenio={this.state.numConvenio}
                            numRef={this.state.numRef}
                            totalEfectivo={this.state.totalEfectivo}
                            totalDeposito={this.state.totalDeposito}
                        />
                    : <div className="card ficha-deposito">
                        <div className="card-content">
                            Introduzca una fecha para mostrar un reporte
                        </div>
                    </div>}
                    </React.Fragment>
                : <div className="card ficha-deposito">
                        <div className="card-content">
                            {this.state.mensaje}
                        </div>
                    </div>
                }
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
            </div>
        )
    }
}

/**
 * Componente que es similar a la plantilla de ficha de deposito de un drive
 */
export default class ReporteCorte extends Component{

    render(){
        var fecha = moment(this.props.fecha)
        return (
            <div className="card ficha-deposito">
                <div className="card-content">
                    <div className={"columns is-multiline" + (this.props.modoImprimir ? " is-mobile" : "")} style={{textAlign: "left"}}>
                        <div className="column is-4"><h4 className="title is-4">Banco BBVA Bancomer</h4></div>
                        <div className="column is-8"><h4 className="title is-4">FICHA DE DEPOSITO</h4></div>
                        <div className="column is-4">
                            <table className="ficha-deposito-table">
                                <tr>
                                    <th rowSpan="2" className="main"><label>FECHA</label></th>
                                    <th>Día</th>
                                    <th>Mes</th>
                                    <th>Año</th>
                                </tr>
                                <tr>
                                    <td>{fecha.date()}</td>
                                    <td>{("0" + (fecha.month() + 1)).slice(-2)}</td>
                                    <td>{fecha.year()}</td>
                                </tr>
                            </table>
                        </div>
                        <div className="column is-8">
                            <table className="ficha-deposito-table">
                                <tr>
                                    <th className="main"><label>CAFFENIO</label></th>
                                    <td className="caffenio-name">{this.props.caffenio}</td>
                                </tr>
                            </table>
                        </div>
                        <div className="column is-4">
                            <label>NUMERO DE CONVENIO</label>
                            <div className="num-container">
                                {("" + this.props.numConvenio).split("").map(num => <span className="num-convenio">{num}</span>)}
                            </div>
                        </div>
                        <div className="column is-8">
                            <label>NUMERO DE REFERENCIA</label>
                            <div className="num-container">
                                {("" + this.props.numRef).split("").map(num => <span className="num-referencia">{num}</span>)}
                            </div>
                        </div>
                        <div className="column is-4">
                            <label>IMPORTE TOTAL DE EFECTIVO</label>
                            <p className="cash-container">{this.props.totalEfectivo}</p>
                        </div>
                        {/* <div className="column is-8">
                            <label>IMPORTE TOTAL DE DEPOSITO</label>
                            <p>${this.props.totalDeposito}</p>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

ReporteCorte.propTypes = {
    fecha: PropTypes.string.isRequired,
    caffenio: PropTypes.string.isRequired,
    numConvenio: PropTypes.number.isRequired,
    numRef: PropTypes.number.isRequired,
    totalEfectivo: PropTypes.number.isRequired,
    totalDeposito: PropTypes.number.isRequired,
    modoImprimir: PropTypes.bool
}

ReporteCorte.defaultProps = {
    modoImprimir: false
}