import {apiUrl} from '../constants';
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class ConfigurationVPAPI {
    static GetConfigVP(idCaffenio, NumConfiguracionVP){
        return request.get(`/ConfigurationVP/${idCaffenio}/${NumConfiguracionVP}`);
    }

    static GetTipoSolicitudes(){
        return request.get(`/ConfigurationVP/TipoSolicitudes`);
    }

    static GetNumConfigsVP(idCaffenio){
        return request.get(`/ConfigurationVP/NumConfiguracionesVP/${idCaffenio}`);
    }
    
    static UpdateConfigurationVP(newVPConfiguration){
        return request.put('/ConfigurationVP/UpdateConfigurationVP', newVPConfiguration);
    }

    static CreateDefaultConfigVP(newVPConfiguration){
        return request.post('/ConfigurationVP/NewConfigurationVP', newVPConfiguration);
    }

    static ActivarDosLineasProduccion(idCaffenio,numVP1, numVP2){
        return request.put(`/ConfigurationVP/ActivarDosLineasProduccion/${idCaffenio}/${numVP1}/${numVP2}`);
    }

    static DesactivarDosLineasProduccion(idCaffenio){
        return request.put(`/ConfigurationVP/DesactivarDosLineasProduccion/${idCaffenio}`);
    }

    static GetVPs(idCaffenio){
        return request.get(`/ConfigurationVP/GetVPs/${idCaffenio}`);
    }
}
