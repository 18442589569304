import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';

import Field from '../../util/field/Field';
import Dropdown from '../../util/dropdown/Dropdown';
import moment from 'moment';

const SCHEMA = Yup.object({
    Name: Yup.string().trim().min(3, 'Requiere al menos 3 caracteres').max(60, 'Ha excedido el límite de 60 caracteres.').required("El campo 'Nombre' es requerido."),
    ShortName: Yup.string().trim().min(3, 'Requiere al menos 3 caracteres').max(40, 'Ha excedido el límite de 40 caracteres.').required("El campo 'Nombre Corto' es requerido."),
    GPSucursalId: Yup.number()
    .min(0, "Ingrese valores mayores a 0.")
    .max(999, 'Ha excedido el límite de 3 caracteres.')
    .required("El campo '# Drive Sucursal' es requerido."),
    WarehouseGPCode: Yup.number()
    .min(0, "Ingrese valores mayores a 0.")
    .max(999, 'Ha excedido el límite de 3 caracteres.')
    .required("El campo '# Almacén GP' es requerido."),
    RailsNumber: Yup.number()
    .min(0, "Ingrese valores mayores a 0.")
    .max(9, 'Ha excedido el límite de caracteres.')
    .required("El campo '# Carriles' es requerido."),
    MenuId: Yup.string().required("El campo 'Menu' es requerido.").nullable(),
    PriceListId: Yup.string().required("El campo 'Lista de Precio' es requerido.").nullable(),
    Active: Yup.bool(),
    MobileSales: Yup.bool(),
    RefillRestrictions: Yup.bool(),
    isClosed: Yup.bool(),
    isWorking: Yup.bool(),
    OpenDate: Yup.string().required("El campo 'Fecha de apertura' es requerido."),
    CloseDate: Yup.string(),
});

// Solo permite números, sin signos ni exponenciales
const onKeyPressNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-|e|E|\./.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
    event.preventDefault();
}

/**
 * Formulario con la información general del folio
 */
export const StepInfoDrive = ({ ...props }) => {
    return (
        <Formik
            enableReinitialize={true}
            initialValues={props.isEditing ? props.caffenio : 
                {
                    Name:'', 
                    ShortName:'', 
                    GPSucursalId:'', 
                    WarehouseGPCode:'',
                    RailsNumber:'',
                    MenuId:[],
                    PriceListId:[],
                    Active:true,
                    MobileSales:false,
                    RefillRestrictions:false,
                    isClosed:false,
                    isWorking:false,
                    OpenDate:'',
                    CloseDate:''
                }}
            validationSchema={SCHEMA}
            onSubmit={(values) => {
                let caffenioUpdated = {...props.caffenio};
                caffenioUpdated.Name = values.Name.trim();
                caffenioUpdated.ShortName = values.ShortName.trim();
                caffenioUpdated.NewName = values.ShortName.trim();
                caffenioUpdated.GPSucursalId = values.GPSucursalId;
                caffenioUpdated.WarehouseGPCode = values.WarehouseGPCode;               
                caffenioUpdated.RailsNumber=values.RailsNumber;
                caffenioUpdated.MenuId= values.MenuId;
                caffenioUpdated.PriceListId= values.PriceListId;
                caffenioUpdated.Active= values.Active;
                caffenioUpdated.MobileSales= values.MobileSales;
                caffenioUpdated.RefillRestrictions= values.RefillRestrictions;
                caffenioUpdated.isClosed= values.isClosed;
                caffenioUpdated.isWorking= values.isWorking;      
                caffenioUpdated.OpenDate = values.OpenDate ? values.OpenDate : '';
                caffenioUpdated.CloseDate = values.CloseDate ? values.CloseDate : '';
                // caffenioUpdated.OpenDate = moment(new Date(values.OpenDate)).format("YYYY-MM-DDT00:00:00.000")+"Z"; 
                
                // if (values.isClosed && values.CloseDate){
                //     caffenioUpdated.CloseDate= moment(new Date(values.CloseDate)).format("YYYY-MM-DDT00:00:00.000")+"Z";
                // }else{                    
                //     caffenioUpdated.CloseDate= '';
                // }

                props.save(caffenioUpdated);
            }}>
            {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                <form onSubmit={handleSubmit} id="frmInfoDriveStep">
                    {/* Nombre, Nombre corto */}
                    <div className="columns is-multiline">
                        <div className="column is-12">
                            <Field label="Nombre Drive" isHorizontal
                                hasError={errors.Name || touched.Name}
                                msgError={errors.Name ? errors.Name : ""}
                            >
                                <input
                                    className="input"
                                    maxLength="50"
                                    label="Nombre"
                                    name="Name"
                                    value={values.Name}
                                    onChange={(e) => handleChange(e)}

                                />
                            </Field>
                        </div>
                        <div className="column is-12" >
                            <Field label="Nombre Corto" isHorizontal
                                hasError={errors.ShortName || touched.ShortName}
                                msgError={errors.ShortName ? errors.ShortName : ""}>
                                <input
                                    className="input"
                                    label="Nombre Corto"
                                    maxLength="30"
                                    name="ShortName"
                                    value={values.ShortName}
                                    onChange={(e) => handleChange(e)}

                                />
                            </Field>
                        </div>

                    </div>

                    {/* Sucursal, almacen y carriles */}
                    <div className="columns is-multiline">
                        <div className="column is-4">
                            <Field label="# Drive Sucursal."
                                hasError={errors.GPSucursalId || touched.GPSucursalId}
                                msgError={errors.GPSucursalId ? errors.GPSucursalId : ""}>
                                <input
                                    className="input"
                                    type="number"
                                    min="0"
                                    label="GPSucursalId"
                                    name="GPSucursalId"
                                    maxLength="3"
                                    onKeyPress={onKeyPressNumber}
                                    value={values.GPSucursalId}
                                    onChange={(e) => handleChange(e)}

                                />
                            </Field>
                        </div>

                        <div className="column is-4">
                            <Field label="# Almacén GP"
                                hasError={errors.WarehouseGPCode || touched.WarehouseGPCode}
                                msgError={errors.WarehouseGPCode ? errors.WarehouseGPCode : ""}>
                                <input
                                    className="input"
                                    type="number"
                                    label="WarehouseGPCode"
                                    name="WarehouseGPCode"
                                    maxLength="3"
                                    min="0"
                                    value={values.WarehouseGPCode}
                                    onChange={(e) => handleChange(e)}
                                    onKeyPress={onKeyPressNumber}

                                />
                            </Field>
                        </div>

                        <div className="column is-4">
                            <Field label="# Carriles"
                                hasError={errors.RailsNumber || touched.RailsNumber}
                                msgError={errors.RailsNumber ? errors.RailsNumber : ""}>
                                <input
                                    className="input"
                                    type="number"
                                    label="RailsNumber"
                                    name="RailsNumber"
                                    maxLength="1"
                                    min="0"
                                    value={values.RailsNumber}
                                    onChange={(e) => handleChange(e)}
                                    onKeyPress={onKeyPressNumber}

                                />
                            </Field>
                        </div>

                    </div>

                    {/* Menus y lista de precios */}
                    <div className="columns is-multiline">
                        <div className="column is-6">
                            <Field label="Menú" isHorizontal
                                hasError={errors.MenuId || touched.MenuId}
                                msgError={errors.MenuId || touched.MenuId ? errors.MenuId : ""}>
                                {<Dropdown
                                    single
                                    items={props.menus}
                                    value={values.MenuId}
                                    disabled={props.menus && props.menus.length === 0 ? true : false}
                                    id="MenuId"
                                    onSelect={(val) => {
                                        if (val > 0) {
                                            let selected = props.menus[val - 1].MenuId;
                                            setFieldValue('MenuId', selected)

                                        } else {
                                            setFieldValue('MenuId', null)
                                        }
                                    }}
                                    labelKey={"Description"}
                                    hasError={errors.MenuId && touched.MenuId}
                                />}
                            </Field>
                        </div>
                        <div className="column is-6">
                            <Field label="Lista Precio" isHorizontal
                                hasError={errors.PriceListId || touched.PriceListId}
                                msgError={errors.PriceListId || touched.PriceListId ? errors.PriceListId : ""}>
                                {<Dropdown
                                    single
                                    items={props.priceList}
                                    value={values.PriceListId}
                                    id="PriceListId"
                                    disabled={props.priceList && props.priceList.length === 0 ? true : false}
                                    onSelect={(val) => {
                                        if (val > 0) {
                                            let selected = props.priceList[val - 1].PriceListId;
                                            setFieldValue('PriceListId', selected)

                                        } else {
                                            setFieldValue('PriceListId', null)
                                        }
                                    }}
                                    labelKey={"Description"}
                                />}
                            </Field>
                        </div>
                    </div>

                    {/* Booleans */}
                    <div className="columns is-multiline">
                        <div className="column is-4 margin-bottom">
                            <Field label="Activo" isHorizontal={false} >
                                <input
                                    type="checkbox"
                                    name="Active"
                                    className="switch"
                                    checked={values.Active}
                                    onChange={(e) => { }}
                                />
                                <label htmlFor="Active" className="inputCenter" onClick={(e) => {
                                    e.stopPropagation();                                   

                                    setFieldValue('Active', !values.Active)
                                }} />
                            </Field>
                        </div>
                        <div className="column is-4 margin-bottom">
                            <Field label="Ventas Móviles" isHorizontal={false} >
                                <input
                                    type="checkbox"
                                    name="MobileSales"
                                    className="switch"
                                    checked={values.MobileSales}
                                    onChange={() => { }}
                                />
                                <label htmlFor="MobileSales" className="inputCenter" onClick={(e) => {
                                    e.stopPropagation();
                                    setFieldValue('MobileSales', !values.MobileSales)
                                }} />
                            </Field>
                        </div>
                        <div className="column is-4 margin-bottom">
                            <Field label="Restr. Refill" isHorizontal={false} >
                                <input
                                    type="checkbox"
                                    name="RestRefill"
                                    className="switch"
                                    checked={values.RefillRestrictions}
                                    onChange={() => { }}
                                />
                                <label htmlFor="RestRefill" className="inputCenter" onClick={(e) => {
                                    e.stopPropagation();
                                    setFieldValue('RefillRestrictions', !values.RefillRestrictions)
                                }} />
                            </Field>
                        </div>
                        <div className="column is-4 margin-bottom">
                            <Field label="Cerrado permanente" isHorizontal={false} >
                                <input
                                    type="checkbox"
                                    name="isClosed"
                                    className="switch"
                                    checked={values.isClosed}
                                    onChange={() => { }}
                                />
                                <label htmlFor="isClosed" className="inputCenter" onClick={(e) => {
                                    e.stopPropagation();                                    
                                    if(!values.isClosed && !values.CloseDate){
                                        setFieldValue('CloseDate', Date.now());
                                    }
                                    setFieldValue('isClosed', !values.isClosed);
                                }} />
                            </Field>
                        </div>
                        <div className="column is-4 margin-bottom">
                            <Field label="¿Está operando?" isHorizontal={false} >
                                <input
                                    type="checkbox"
                                    name="isWorking"
                                    className="switch"
                                    checked={values.isWorking}
                                    onChange={() => { }}
                                />
                                <label htmlFor="isWorking" className="inputCenter" onClick={(e) => {
                                    e.stopPropagation();
                                    setFieldValue('isWorking', !values.isWorking)
                                }} />
                            </Field>
                        </div>

                    </div>

                    {/* dates */}
                    <div className="columns is-multiline">
                        <div className="column is-6">
                            <Field label="Fecha Apertura"
                                hasError={errors.OpenDate || touched.OpenDate}
                                msgError={errors.OpenDate || touched.OpenDate ? errors.OpenDate : ""}>
                                <DatePicker
                                    name={"OpeningDate"}
                                    selected={values.OpenDate ?  values.OpenDate : ''}
                                    onChange={date => {
                                        setFieldValue('OpenDate', date)
                                    }}
                                    popperPlacement="bottom"
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: '5px, 20px',
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: 'viewport'
                                        }
                                    }}
                                    todayButton="Hoy"
                                    dateFormat="dd/MM/yyyy"
                                    className="input"
                                    autoComplete={"off"}
                                    maxDate={values.CloseDate}
                                />
                            </Field>

                        </div>
                        {values.isClosed && <div className="column is-6">
                            <Field label="Fecha Cierre"
                                hasError={errors.CloseDate || touched.CloseDate}
                                msgError={errors.CloseDate || touched.CloseDate ? errors.CloseDate : ""}>
                                <DatePicker
                                    name={"CloseDate"}
                                    selected={values.CloseDate ? values.CloseDate : ''}
                                    onChange={date => {
                                        setFieldValue('CloseDate', date)
                                    }}
                                    disabled={true}
                                    popperPlacement="bottom"
                                    minDate={values.OpenDate}
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: '50px, 10px'
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: 'viewport'
                                        }
                                    }}
                                    todayButton="Hoy"
                                    dateFormat="dd/MM/yyyy"
                                    className="input"
                                    autoComplete={"off"}
                                />
                            </Field>

                        </div>
}
                        
                    </div>

                    <br />

                    <div className="has-text-left buttons-modal" id="stepActions">

                    </div>
                    <div className="has-text-right buttons-modal" id="stepActions">
                        <div className="pull-right">
                            <button id="btnSaveStepInfoDrive" type="submit" className="button is-success"  >Continuar</button>
                        </div>
                    </div>

                </form>
            )}

        </Formik>
    );

}

export default StepInfoDrive;