import React, { Component } from 'react';
import  SearchForm  from "../../util/searchform/SearchForm";
import  TableTemplate  from "../../util/tabletemplate/TableTemplate";
import  NoItems  from "../../util/noitems/NoItems";
import RecipeTypeForm from './RecipeTypeForm';
import Modal from '../../util/modal/Modal';
import AcceptModal from '../../util/acceptModal/AcceptModal';
import RecipeTypesAPI from '../../../services/RecipeType';
import { toast } from 'react-toastify';
import Pagination from '../../util/pagination/Pagination';
import Loading from '../../util/loading/Loading';

import ModoGuiado from "../../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../../util/validation-error-messages';
import lodash from 'lodash';
import roles from '../../../util/strings/roles';
class RecipeTypes extends Component {
    recipeTypes = [];

    constructor(props){
        super(props);
        this.state = {
            recipeTypes: [],
            filterText: "",
            pageRecipeTypes: [],
            recipeType: null,
            showModal: false,
            editing: false,
            removeModal: false,
            isLoading: false
        }
    }

    componentWillMount(){
        this.setState({isLoading: true});
        RecipeTypesAPI.getAll().then(response => {
            this.setState({recipeTypes: response.data, isLoading: false});
            this.recipeTypes = response.data;
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let recipeTypes = this.recipeTypes.filter(recipeType => recipeType.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        let state = this.state;
        state.filterText = text;
        state.recipeTypes = recipeTypes;
        this.setState(state);
    }

    /** Redirige vista a crear nuevo tipo receta  */
    create = () => {
        this.props.history.push({pathname: '/tipos-receta/nuevo', state:{recipeTypes: this.recipeTypes}});
    }

    edit = (recipeType) => {
        
            this.setState({isLoading: true}, ()=>{
                RecipeTypesAPI.edit(recipeType).then(response => {
                    let state = {...this.state};
                    
                    state.recipeTypes[state.recipeTypes.findIndex(c => c.RecipeTypeId === recipeType.RecipeTypeId)] = response.data;
                    state.pageRecipeTypes[state.pageRecipeTypes.findIndex(c => c.RecipeTypeId === recipeType.RecipeTypeId)] = response.data;
                    this.recipeTypes[this.recipeTypes.findIndex(c => c.RecipeTypeId === recipeType.RecipeTypeId)] = response.data;
                    state.isLoading = false;
                    state.showModal = false;
                    state.recipeType = null;
                    this.setState(state, () => toast.success("Tipo Receta actualizada"));
                }).catch(err => {
                    //console.log(err);
                    this.setState({isLoading: false});
                    if(err.response)
                        toast.error(err.response.data.Message);
                    else
                        toast.error(ErrorMessages.SinConexion);
                    })
            });
            
        
    }

    remove = () => {
        this.setState({isLoading: true});
        RecipeTypesAPI.delete(this.state.recipeType.RecipeTypeId).then(response => {
            let state = {...this.state};

            state.recipeTypes.splice(state.recipeTypes.findIndex(c => c.RecipeTypeId === state.recipeType.RecipeTypeId), 1);
            state.pageRecipeTypes.splice(state.pageRecipeTypes.findIndex(c => c.RecipeTypeId === state.recipeType.RecipeTypeId), 1);
            this.recipeTypes.splice(this.recipeTypes.findIndex(c => c.RecipeTypeId === state.recipeType.RecipeTypeId), 1);
            state.isLoading = false;
            state.removeModal = false;
            state.recipeType = null;

            this.setState(state, () => toast.success("Tipo Receta eliminada"));
        }).catch(err => {
            this.setState({isLoading: false, recipeType: null, removeModal: false});
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    }

    render() {
        return (
            <div>

                <ModoGuiado 
                    ruta="tipos-receta"
                    tipoModoGuiado="receta"
                    anterior="clasificaciones"
                    siguiente="sabores" 
                    mensaje="Agrupación a la que pertenece un producto terminado para distinguirlo por tipo" />

                <h1 className="title is-2">Tipo de receta /sub categoría</h1>
                <div className="columns is-vcentered">
                    <div className="column is-5">
                        <div className="card">
                            <div className="card-content">
                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'Tipo Receta'}
                                            placeholderText={"Ingrese nombre de tipo receta."}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-7">
                        <div className="field">
                            <div className="control has-text-right">
                                {lodash.intersection(localStorage.getItem('role').split(","), [roles.ADMIN, roles.ADMINDRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES]).length > 0 &&
                                    <a id="btnAddRecipeType" className="button is-success" onClick={(e) => this.create()}>
                                        <span className="icon is-small">
                                            <i className="fa fa-plus"></i>
                                        </span>
                                        <span>Agregar Tipo Receta</span>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-content">
                        {this.state.recipeTypes.length !== 0 
                        ? <TableTemplate
                            columns={["RecipeTypeId","Description","Position", "NombreSegmento"]}
                            columnsNames={["ID", "Nombre", "Posición en Menú", "Segmento"]}
                            data={this.state.pageRecipeTypes}
                            rowClickable={true}
                            details={(recipeType) => this.setState({showModal: true, recipeType: recipeType})}
                            canEdit canDelete 
                            edit={(recipeType) => this.setState({showModal: true, recipeType: recipeType})} 
                            remove={(recipeType) => this.setState({removeModal: true, recipeType: recipeType})}
                        /> 
                        : <NoItems itemName="tipos de receta"/>}
                        <Pagination items={this.state.recipeTypes} onChangePage={(recipeTypes) => this.setState({ pageRecipeTypes: recipeTypes })} />
                    </div>
                </div>
                {this.state.showModal && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Tipo Receta"}
                        cancel={() => this.setState({showModal: false, unit: null})}
                    >
                        <RecipeTypeForm
                            recipeType={this.state.recipeType}
                            recipeTypes={this.state.recipeTypes}
                            save={this.edit}
                            isEditing={true}
                        />   
                    </Modal>
                }
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove()}
                    cancel={() => this.setState({removeModal: false})}
                    modalTitle={"Eliminar Tipo Receta"}
                />}
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />                

            </div>
        );
    }
}

export default RecipeTypes;
