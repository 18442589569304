import React, { Component } from "react";
import moment from "moment";
import CaffenioDriveAPI from "../../services/caffenioDrive";
import FichaDepositoAPI from "../../services/FichaDeposito";
import NoItems from "../util/noitems/NoItems";
import PaginationReports from "../util/pagination/PaginationReports";
import Loading from "../util/loading/Loading";
import { toast } from "react-toastify";
import Select from "react-select";
import Modal from "../util/modal/Modal";
import ReporteCorte from "./reporteCorte";
import DatePicker from "react-datepicker";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ExportExcel from "react-export-excel";

// Creamos instancias para el package export excel.
// Variable que indica el archivo de excel que se crea.
const ExcelFile = ExportExcel.ExcelFile;
// Variable que indica una hoja de excel.
const ExcelSheet = ExportExcel.ExcelSheet;
// Variable que indica una columna de excel.
const ExcelColumn = ExportExcel.ExcelColumn;

class CaffenioReporteTabla extends Component {
  state = {
    isLoading: false,
    fichas: [],
    fichasFilter: [],
    fichaSelect: null,
    fechaInicio: null,
    fechaFin: null,
    pageFichas: [],
    fichasChecked: [],
    // States relacionados a los dropdowns de los drives
    selectedOption: [],
    selectedOptionC: [],
    selectedOptionB: [],
    selectedOptionD: [],
    OptionSelect: [],
    OptionSelectCity: [],
    OptionSelectBranch: [],
    OptionSelectDrive: [],
    optionState: [],
    optionCity: [],
    optionBranch: [],
    optionDrive: [],
    Caffenios: [],
    childVisible: false,
    checkAll: false,
    showModal: false,
    creatingPDF: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true }, () => {
      let state = { ...this.state };
      CaffenioDriveAPI.getCaffenios()
        .then((response) => {
          state.Caffenios = response.data;
          state.Caffenios.map((producto) => {
            producto.statusCheck = false;
            return producto;
          });
          state.optionState = this.select_state(state.Caffenios);
          state.optionCity = this.select_city(state.Caffenios);
          state.optionBranch = this.select_branch(state.Caffenios);
          state.optionDrive = this.select_Drive(state.Caffenios);
          state.isLoading = false;
          this.setState(state);
        })
        .catch((err) => toast.error(err.response.data.Message));
    });
  }

  handleDateChange = (date, tipoFecha) => {
    let state = { ...this.state };
    state[tipoFecha] = date;
    this.setState(state, () => this.ConsultarFichasPorFecha());
  };

  //#region Funciones para los dropdowns relacionados a los drives (Es copypaste de los dropdowns de la configuracion de drives)
  select_state(Caffenios) {
    // Llenar el combo 'Estados' con todas las opciones
    var newArray = [];
    let OptionSelect = [];
    var lookupObject = {};
    Caffenios.map((item) => {
      OptionSelect.push({ value: item.StateId, label: item.State });
    });

    for (var i in OptionSelect) {
      lookupObject[OptionSelect[i]["value"]] = OptionSelect[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  }

  select_city(Caffenios) {
    // Llenar el combo 'Ciudades' con todas las opciones
    var newArray = [];
    let OptionSelectCity = [];
    var lookupObject = {};
    Caffenios.map((item) => {
      OptionSelectCity.push({
        value: item.Municipalityid,
        label: item.Municipality,
      });
    });

    for (var i in OptionSelectCity) {
      lookupObject[OptionSelectCity[i]["value"]] = OptionSelectCity[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  }

  select_branch(Caffenios) {
    // Llenar el combo 'Sucursales' con todas las opciones
    var newArray = [];
    let OptionSelectBranch = [];
    var lookupObject = {};
    Caffenios.map((item) => {
      OptionSelectBranch.push({ value: item.Branch, label: item.Branch });
    });

    for (var i in OptionSelectBranch) {
      lookupObject[OptionSelectBranch[i]["value"]] = OptionSelectBranch[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  }

  select_Drive(Caffenios) {
    // Llenar el combo 'Drives' con todas las opciones
    var newArray = [];
    let OptionSelectDrive = [];
    var lookupObject = {};
    Caffenios.map((item) => {
      OptionSelectDrive.push({
        value: item.idCaffenio,
        label: item.NombreNuevo,
      });
    });

    for (var i in OptionSelectDrive) {
      lookupObject[OptionSelectDrive[i]["value"]] = OptionSelectDrive[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  }

  eliminarObjDuplicados = (arr, prop) => {
    var nuevoArray = [];
    var lookup = {};

    for (var i in arr) {
      lookup[arr[i][prop]] = arr[i];
    }

    for (i in lookup) {
      nuevoArray.push(lookup[i]);
    }

    return nuevoArray;
  };

  changeSelectEstado = (obj) => {
    let state = { ...this.state };
    state["selectedOption"] = obj;
    let idEstado = obj.map((estado) => {
      return estado.value;
    });
    let objCaffenios = [...state.Caffenios];
    let listaCiudades = [];
    let listaDrives = [];

    // Recorrer objeto Caffenios y encontrar coincidencias sin eliminar duplicados
    objCaffenios.map((item) => {
      for (var i = 0; i < idEstado.length; i++) {
        if (item.StateId === idEstado[i]) {
          listaCiudades.push({
            value: item.Municipalityid,
            label: item.Municipality,
          });
        }
      }
    });

    //Se eliminan las ciudades duplicadas
    let eliminarDuplicados = this.eliminarObjDuplicados(listaCiudades, "value");
    //Se obtiene un arreglo con los id de las ciudades
    let idCiudad = eliminarDuplicados.map((ciudad) => {
      return ciudad.value;
    });

    //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
    objCaffenios.map((item) => {
      for (var i = 0; i < idCiudad.length; i++) {
        if (item.Municipalityid === idCiudad[i]) {
          listaDrives.push({ value: item.idCaffenio, label: item.NombreNuevo });
        }
      }
    });

    //Se limpian los combos 'Ciudades' y 'Drives'
    state["selectedOptionC"] = [];
    state["selectedOptionD"] = [];

    //Se valida que haya una opción seleccionada en combo 'Estados'
    if (obj.length === 0) {
      state["optionCity"] = this.select_city(state.Caffenios);
      state["optionDrive"] = this.select_Drive(state.Caffenios);
    } else {
      state["optionCity"] = eliminarDuplicados;
      state["optionDrive"] = listaDrives;
    }

    this.setState(state, () => this.FilterProcess());
  };

  changeSelectCiudad = (obj) => {
    let state = { ...this.state };
    let idCiudad = obj.map((ciudad) => {
      return ciudad.value;
    });
    let objCaffenios = [...state.Caffenios];
    let listaDrives = [];

    //Se buscan coincidencias entre ciudad y drive y se genera la lista de drives
    objCaffenios.map((item) => {
      for (var i = 0; i < idCiudad.length; i++) {
        if (item.Municipalityid === idCiudad[i]) {
          listaDrives.push({ value: item.idCaffenio, label: item.NombreNuevo });
        }
      }
    });

    //Se limpia el combo 'Drives'
    state["selectedOptionD"] = [];

    //Se valida que haya una opción seleccionada en combo 'Ciudades'
    if (obj.length > 0) {
      state["selectedOptionC"] = obj;
      state["optionDrive"] = listaDrives;
      this.setState(state, () => this.FilterProcess());
    } else {
      this.changeSelectEstado(state["selectedOption"]);
    }
  };

  changeSelectSucursal = (objSucursal, objDrive) => {
    let state = { ...this.state };
    let idSucursal = objSucursal.map((sucursal) => {
      return sucursal.value;
    });
    let objCaffenios = [...state.Caffenios];
    let listaDrives = [];

    //Se buscan coincidencias entre sucursal y drive y se genera la lista de drives
    objCaffenios.map((item) => {
      for (var i = 0; i < idSucursal.length; i++) {
        if (item.Branch === idSucursal[i]) {
          listaDrives.push({ value: item.idCaffenio, label: item.NombreNuevo });
        }
      }
    });

    // Validar si no existen drives seleccionados, resetear el filtro de drives.
    if (objDrive.length === 0) {
      //Se limpia el combo 'Drives'
      state["selectedOptionD"] = [];
    }

    //Se valida que haya una opción seleccionada en combo 'Sucursales'
    state["selectedOptionB"] = objSucursal;
    if (objSucursal.length > 0) {
      state["optionDrive"] = listaDrives;
    } else {
      //Se limpia el combo 'Drives'
      state["selectedOptionD"] = [];
      state["optionDrive"] = this.select_Drive(state.Caffenios);
    }
    this.setState(state, () => this.FilterProcess());
  };

  changeSelectDrive = (obj) => {
    let state = { ...this.state };
    let Caffenios = [...state.Caffenios];
    //Valida cada select (Estados,Ciudades,Drives) y realiza la acción correspondiente
    if (obj.length > 0) {
      state["selectedOptionD"] = obj;
      //state["optionDrive"] = obj;
      this.setState(state, () => this.FilterProcess());
    } else if (state["selectedOptionC"].length === 0) {
      this.changeSelectEstado(state["selectedOption"]);
      // resetear lista de drives con el primer filtro.
      this.changeSelectSucursal(state["selectedOptionB"], obj);
    } else if (
      state["selectedOptionC"].length === 0 &&
      state["selectedOption"].length === 0
    ) {
      this.select_Drive(Caffenios);
      this.select_Drive(Caffenios);
      this.select_Drive(Caffenios);
    } else {
      this.changeSelectCiudad(state["selectedOptionC"]);
    }
  };

  validarCombos = (state) => {
    var status_combo = false; // Vacios

    if (state["selectedOption"].length > 0) {
      status_combo = true; // Alguno con valor
    }

    if (state["selectedOptionC"].length > 0) {
      status_combo = true; // Alguno con valor
    }

    if (state["selectedOptionD"].length > 0) {
      status_combo = true; // Alguno con valor
    }

    return status_combo;
  };
  //#endregion

  /**
   * Se ejecuta al seleccionar un rango de fechas.
   * Hace una petición a la API para obtener las fichas de deposito que están entre las fechas seleccionadas
   */
  ConsultarFichasPorFecha = () => {
    if (this.state.fechaInicio != null && this.state.fechaFin != null) {
      this.setState({ isLoading: true }, () => {
        let body = {
          FechaInicio: this.state.fechaInicio,
          FechaFin: this.state.fechaFin,
        };
        let state = { ...this.state };
        FichaDepositoAPI.getByDate(body)
          .then((response) => {
            state.fichas = response.data;
            state.fichasChecked = [];
            state.isLoading = false;
            this.setState(state, () => {
              this.FilterProcess();
            });
          })
          .catch((err) => {
            state.isLoading = false;
            state.fichasChecked = [];
            toast.error(err.response.data.Message);
            this.setState(state);
          });
      });
    }
  };

  /**
   * Se ejecuta al seleccionar un valor de los dropdowns relacionados a los drives (estados, ciudades, drives)
   * Lo que hace es filtrar los drives segun los valores de los dropdowns y tambien filtra los resultados de las fichas de deposito.
   * OJO. Esta función no hace peticiones a la API, solo filtra valores que estan en los states
   */
  FilterProcess = () => {
    let state = { ...this.state };
    let Caffenios = state.Caffenios;
    var status_combo; // Vacios
    let driveIds;
    let drivesSelected = [];

    if (state["selectedOptionD"].length === 0) {
      driveIds = state["optionDrive"].map((obj) => {
        return obj.value;
      });
    } else {
      driveIds = state["selectedOptionD"].map((obj) => {
        return obj.value;
      });
    }

    // Validar si existen drives seleccionados para comparar entre las
    // sucursales y drives y obtener la lista actual de drives seleccinados.
    if (state["selectedOptionD"].length > 0) {
      state["optionDrive"].map((obj) => {
        for (var i = 0; i < state["selectedOptionD"].length; i++) {
          if (obj.value === state["selectedOptionD"][i].value) {
            drivesSelected.push(state["selectedOptionD"][i]);
          }
        }
      });
      // Actualizar los drives seleccionados por sucursales seleccionadas.
      state["selectedOptionD"] = drivesSelected;
    }

    status_combo = this.validarCombos(state);

    Caffenios = Caffenios.filter((obj2) => {
      if (
        driveIds.filter((item) => {
          return item === obj2.idCaffenio;
        }).length > 0
      ) {
        return obj2;
      }
    });

    state.fichasFilter = state.fichas.filter((ficha) => {
      return (
        driveIds.findIndex((driveId) => {
          return driveId === ficha.DriveId;
        }) !== -1
      );
    });
    this.setState(state);
  };

  selectAllCheck = () => {
    let state = { ...this.state };

    state.checkAll = !state.checkAll;
    // Insertar o quitar los ids de la pagina actual
    state.pageFichas.findIndex((item) => {
      let indexCheck = state.fichasChecked.findIndex(
        (ficha) => item.DepositSlipId === ficha
      );
      if (state.checkAll) {
        if (indexCheck === -1) state.fichasChecked.push(item.DepositSlipId);
      } else {
        if (indexCheck !== -1) {
          state.fichasChecked.splice(indexCheck, 1);
        }
      }
    });
    state.childVisible = state.fichasChecked.length > 0;
    this.setState(state);
  };

  /**
   * Se ejecuta al darle click a un checkbox de un renglón.
   * Lo que hace es determinar si el checkbox del header se tiene que marcar o no
   */
  showView = () => {
    let state = { ...this.state };
    let cheked = false;
    let checkAllTemp = true;
    state.pageFichas.map((item) => {
      if (
        state.fichasChecked.findIndex(
          (ficha) => item.DepositSlipId === ficha
        ) !== -1
      ) {
        cheked = true;
      } else {
        checkAllTemp = false;
      }
    });

    this.setState({
      childVisible: state.fichasChecked.length > 0,
      checkAll: checkAllTemp,
    });
  };

  /**
   * Función que se ejecuta al darle click al botón de descargar PDF
   */
  onCreatePDF = () => {
    this.setState({ creatingPDF: true, isLoading: true }, () => {
      this.createPDF();
    });
  };

  /**
   * Función que realiza el proceso de convertir las fichas HTML a formato PDF
   */
  createPDF = () => {
    if (this.state.creatingPDF && this.state.fichasChecked.length > 0) {
      // Creamos PDF base
      let pdf = new jsPDF();
      let fichas = [...this.state.fichasChecked];
      this.procesarFicha(fichas, 0, pdf);
    }
  };

  procesarFicha = (fichas, index, pdf) => {
    let fichaHeight = 70.97; // El 89% de 301.4px convertidos a mm
    // El ancho de una hoja A4.
    // El tamaño de una ficha en web es de 891.33px == 235.83 mm
    // Entonces como el ancho es de 210 mm entonces se utiliza el 89% del tamaño de la ficha
    let fichaWidth = 210;
    let finalIndex = fichas.length;
    let fichasPorPagina = 4;
    let ficha = fichas[index];

    // Convertir la parte del DOM donde estan las fichas a un SVG
    html2canvas(
      document.querySelector('#reportesPDF div[data-id="ficha-' + ficha + '"]'),
      {
        // width: 891.333,
        // height: 291,
        windowWidth: 1366,
        windowHeight: 626,
        scrollX: 0,
        scrollY: 0,
        scale: 1,
        backgroundColor: "#fff",
      }
    ).then((canvas) => {
      // Convertir el SVG a una imagen
      let imgData = canvas.toDataURL("image/png");
      // Convertir la imagen a PDF
      // let heightTotal = 67 * this.state.fichasChecked.length
      let numImgPag = index % fichasPorPagina; // Numero de la imagen en la pagina actual (del 1 al fichasPorPagina)
      let posYImg = numImgPag * fichaHeight;
      if (numImgPag === 0 && index !== 0) pdf.addPage();

      pdf.addImage(imgData, "PNG", 0, posYImg, fichaWidth, fichaHeight);

      if (index == finalIndex - 1) {
        pdf.save(this.crearNombrePDF() + ".pdf");
        this.pdfCompletado();
      } else {
        this.procesarFicha(fichas, index + 1, pdf);
      }
    });
  };

  /**
   * Función que se ejecuta al terminar de convertir todas las fichas a PDF
   * Lo hice asi porque al cambiar el estado dentro de la funcion createPDF causaba errores
   */
  pdfCompletado = () => {
    this.setState({ creatingPDF: false, isLoading: false });
  };

  /**
   * Función para crear el nombre del archivo PDF dependiendo de los drives donde pertenecen las fichas seleccionadas
   * @returns El nombre con este formato: Fichas - <<Nombre Drive>/Multiples> - <Fecha>
   */
  crearNombrePDF = () => {
    let fecha = new Date();
    let nombreDrive = "Multiples";
    if (this.state.fichasChecked.length > 0) {
      let esDriveUnico = true;
      let idDrive = null;
      let fichaActual = null;
      // Determinar si todas las fichas seleccionadas pertenecen a un solo Drive o en varios
      this.state.fichasChecked.findIndex((idficha) => {
        fichaActual = this.state.fichas.find(
          (ficha) => ficha.DepositSlipId == idficha
        );
        if (fichaActual) {
          if (idDrive !== null && idDrive !== fichaActual.DriveId) {
            esDriveUnico = false;
            return true;
          } else {
            idDrive = fichaActual.DriveId;
          }
        }
        return false;
      });
      // Asignar nombre dependiendo si todas las fichas pertenecen a un solo drive o no
      if (esDriveUnico && idDrive != null) {
        let caffenio = this.state.Caffenios.find(
          (caffenio) => caffenio.idCaffenio == idDrive
        );
        if (caffenio) nombreDrive = caffenio.NombreNuevo;
      }
    }
    let nombre =
      "Fichas - " +
      nombreDrive +
      " - " +
      fecha.toLocaleDateString().replace(/\//g, "-");
    return nombre;
  };

  // Select all branch offices of the list and add to filter.
  selectAllBranchOffices = () => {
    let state = { ...this.state };
    state["selectedOptionB"] = this.state.optionBranch;
    this.setState(state, () => this.FilterProcess());
  };

  // Select all drives of the list and add to filter.
  selectAllDrives = () => {
    let state = { ...this.state };
    state["selectedOptionD"] = this.state.optionDrive;
    this.setState(state, () => this.FilterProcess());
  };

  // Function for convert to value decimal with format of value coin.
  formatCoin = (cash) => {
    const options2 = { style: "currency", currency: "MXN" };
    const cashFormated = new Intl.NumberFormat("es-MX", options2);
    return cashFormated.format(cash);
  };

  render() {
    let fechaHoy = new Date();
    return (
      <div>
        <h1 className="title is-2">Despósitos Bancarios</h1>
        <div className="card">
          <div className="card-content">
            <div className="columns is-multiline">
              <div className="column is-6">
                <label className="label">Fecha Inicio</label>
                <DatePicker
                  name={"ReportDateStart"}
                  selected={this.state.fechaInicio}
                  onChange={(e) => this.handleDateChange(e, "fechaInicio")}
                  startDate={this.state.fechaInicio}
                  endDate={this.state.fechaFin}
                  maxDate={this.state.fechaFin ? this.state.fechaFin : fechaHoy}
                  popperPlacement="bottom"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 20px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                  locale="es"
                  todayButton="Hoy"
                  dateFormat="dd/MM/yyyy"
                  className="input"
                  autoComplete={"off"}
                />
              </div>
              <div className="column is-6">
                <label className="label">Fecha Fin</label>
                <DatePicker
                  name={"ReportDateEnd"}
                  selected={this.state.fechaFin}
                  onChange={(e) => this.handleDateChange(e, "fechaFin")}
                  disabled={this.state.fechaInicio == null}
                  startDate={this.state.fechaInicio}
                  endDate={this.state.fechaFin}
                  minDate={this.state.fechaInicio}
                  maxDate={fechaHoy}
                  popperPlacement="bottom"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 20px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                  locale="es"
                  todayButton="Hoy"
                  dateFormat="dd/MM/yyyy"
                  className="input"
                  autoComplete={"off"}
                />
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <div className="card-content">
            {this.state.fichas.length > 0 ? (
              <React.Fragment>
                <div className="columns is-multiline">
                  <div className="column is-5">
                    <label className="label">Sucursales</label>
                    <div className="control">
                      <Select
                        isMulti
                        placeholder={"Seleccionar..."}
                        name={"selectedOptionB"}
                        value={this.state.selectedOptionB}
                        onChange={this.changeSelectSucursal}
                        noOptionsMessage={() => "No hay opciones"}
                        options={this.state.optionBranch}
                        closeMenuOnSelect={false}
                      />
                    </div>
                  </div>

                  <div id="buttonSelectAllFilter" className="column is-1">
                    <button
                      id="buttonSelect"
                      className="button is-info is-medium btn-apply"
                      onClick={() => {
                        this.selectAllBranchOffices();
                      }}
                    >
                      <span className="icon">
                        <i class="fa fa-check-square"></i>
                      </span>
                      {/* <span></span> */}
                    </button>
                  </div>

                  <div className="column is-5">
                    <label className="label">Drives</label>
                    <div className="control">
                      <Select
                        isMulti
                        placeholder={"Seleccionar..."}
                        name={"selectedOptionD"}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={this.state.selectedOptionD}
                        onChange={this.changeSelectDrive}
                        noOptionsMessage={() => "No hay opciones"}
                        options={this.state.optionDrive}
                        closeMenuOnSelect={false}
                      />
                    </div>
                  </div>

                  <div id="buttonSelectAllFilter" className="column is-1">
                    <button
                      id="buttonSelect"
                      className="button is-info is-medium btn-apply"
                      onClick={() => {
                        this.selectAllDrives();
                      }}
                    >
                      <span className="icon">
                        <i class="fa fa-check-square"></i>
                      </span>
                      {/* <span></span> */}
                    </button>
                  </div>

                  {this.state.childVisible && (
                    <React.Fragment>
                      {/* ====== EXPORT DATA TO PDF ======= */}
                      <div id="buttonExportPDFAndExcel" className="column is-6 is-offset-3">
                        <button
                          className="button is-danger is-medium is-fullwidth btn-apply"
                          onClick={() => this.onCreatePDF()}
                        >
                          <span className="icon">
                            <i className="fa fa-file" />
                          </span>
                          <span>Descargar PDF</span>
                        </button>
                      </div>
                      {/* ====== EXPORT DATA TO EXCEL ======= */}
                      <div id="buttonExportPDFAndExcel" className="column is-6 is-offset-3">
                        <ExcelFile
                          filename={
                            "DepositosBancarios_" +
                            fechaHoy.toLocaleDateString().replace(/\//g, "_")
                          }
                          element={
                            <button className="button is-success is-medium is-fullwidth btn-apply">
                              <span className="icon">
                                <i className="fa fa-file" />
                              </span>
                              <span>Exportar a EXCEL</span>
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.fichasFilter}
                            name="BankDeposits"
                          >
                            <ExcelColumn
                              label="Fecha"
                              value={(dateTime) =>
                                moment(dateTime.Date).format("DD/MM/YYYY")
                              }
                            />

                            <ExcelColumn
                              label="Caffenio"
                              value={(drive) => drive.DriveName}
                            />

                            <ExcelColumn
                              label="Número de Referencia"
                              value={(reference) => reference.ReferenceNumber}
                            />

                            <ExcelColumn
                              label="Número de Convenio"
                              value={(agreement) => agreement.AgreementNumber}
                            />

                            <ExcelColumn
                              label="Monto de déposito"
                              value={(row) =>
                                this.formatCoin(parseFloat(row.Cash).toFixed(2))
                              }
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      </div>
                    </React.Fragment>
                  )}
                </div>

                <table className="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>
                        <input
                          className="is-primary tooltip"
                          data-tooltip="Elementos de vista actual"
                          onChange={this.selectAllCheck}
                          checked={this.state.checkAll}
                          type="checkbox"
                        />
                      </th>
                      <th>Fecha</th>
                      <th>Drive</th>
                      <th>Efectivo</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.pageFichas.map((item, index) => {
                      let isChecked =
                        this.state.fichasChecked.findIndex(
                          (ficha) => item.DepositSlipId === ficha
                        ) !== -1;
                      return (
                        <tr key={index}>
                          <th>
                            <input
                              type="checkbox"
                              checked={isChecked}
                              onClick={(e) => {
                                e.stopPropagation();
                                // Cambiar check en fichas
                                let fichasChecked = [
                                  ...this.state.fichasChecked,
                                ];
                                let indexFicha = fichasChecked.findIndex(
                                  (ficha) => item.DepositSlipId === ficha
                                );
                                if (indexFicha === -1)
                                  fichasChecked.push(item.DepositSlipId);
                                else fichasChecked.splice(indexFicha, 1);
                                // Cambiar check en el pageFichas
                                // let pageFichas = [...this.state.pageFichas];
                                // pageFichas[index].statusCheck = !item.statusCheck;
                                this.setState(
                                  { fichasChecked: fichasChecked },
                                  () => this.showView()
                                );
                              }}
                            />
                          </th>
                          <td>{moment(item.Date).format("DD/MM/YYYY")}</td>
                          <td>{item.DriveName}</td>
                          <td>
                            {this.formatCoin(parseFloat(item.Cash).toFixed(2))}
                          </td>
                          <td>
                            <button
                              className="button tableAction is-warning"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.setState({
                                  showModal: true,
                                  fichaSelect: item,
                                });
                              }}
                            >
                              <span className="icon">
                                <i className="fa fa-eye" />
                              </span>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </React.Fragment>
            ) : this.state.fechaInicio == null ||
              this.state.fechaFin == null ? (
              "Inserte fechas para iniciar la busqueda"
            ) : (
              <NoItems itemName="fichas de deposito" />
            )}
            <PaginationReports
              items={this.state.fichasFilter}
              onChangePage={(fichasPag) =>
                this.setState({ pageFichas: fichasPag }, () => this.showView())
              }
            />
          </div>
        </div>

        {this.state.showModal && (
          <Modal
            showModal={this.state.showModal}
            modalTitle={"Vista previa"}
            width="80%"
            cancel={() => this.setState({ showModal: false })}
          >
            <ReporteCorte
              caffenio={this.state.fichaSelect.DriveName}
              fecha={this.state.fichaSelect.Date}
              numConvenio={this.state.fichaSelect.AgreementNumber}
              numRef={this.state.fichaSelect.ReferenceNumber}
              totalEfectivo={this.formatCoin(
                parseFloat(this.state.fichaSelect.Cash).toFixed(2)
              )}
              totalDeposito={this.formatCoin(
                parseFloat(this.state.fichaSelect.Cash).toFixed(2)
              )}
            />
          </Modal>
        )}

        {/* Fichas de deposito que se convertiran a PDF */}
        {/* Estas fichas son invisibles para el usuario */}
        {this.state.creatingPDF && (
          <div id="reportesPDF">
            {this.state.fichasChecked.map((fichaId) => {
              let fichaData = this.state.fichas.find(
                (itemFicha) => itemFicha.DepositSlipId === fichaId
              );
              if (fichaData) {
                return (
                  <div className="ficha-container" data-id={"ficha-" + fichaId}>
                    <ReporteCorte
                      caffenio={fichaData.DriveName}
                      fecha={fichaData.Date}
                      numConvenio={fichaData.AgreementNumber}
                      numRef={fichaData.ReferenceNumber}
                      totalEfectivo={this.formatCoin(
                        parseFloat(fichaData.Cash).toFixed(2)
                      )}
                      totalDeposito={this.formatCoin(
                        parseFloat(fichaData.Cash).toFixed(2)
                      )}
                      modoImprimir={true}
                    />
                  </div>
                );
              }
              return "";
            })}
          </div>
        )}

        <Loading
          isFullscreen={true}
          isLoading={this.state.isLoading}
          width="100px"
          height="100px"
        />
      </div>
    );
  }
}

export default CaffenioReporteTabla;
