import React, { useState, useEffect } from 'react';
import SearchFormV4 from '../util/searchform/SearchFormV4';
import TablaPromociones from './components/TablaPromociones';
import PortalModal from '../../util/portals/PortalModal';
import useModal from '../../util/hooks/useModal';
import { toast } from 'react-toastify';
import PromocionesAPI from '../../services/Promotions';
import * as moment from 'moment';
import Loading from '../util/loading/Loading';

export default function Promociones({history}){
    const statusModal = useModal();
    const deleteModal = useModal();

    const [nameSearch, setNameSearch] = useState("");
    const [activate,setActivate] = useState(false);
    const [promociones, setPromociones] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [currentPromo, setCurrentPromo] = useState(null);
    const [loadingFullScreen, setLoadingFullScreen] = useState(false);

    const getPromociones = async (name = "", page = 1, activate = false) => {
        setLoading(true);
        const res = await PromocionesAPI.getPromoQR(name, page, activate?1:0);
        console.log(res);
        if(res){
            setPromociones(res.data.Results)
            setTotalPages(res.data.TotalPages)
            setCurrentPage(res.data.CurrentPage)
        }else{
            toast.error("Hubo un error al obtener las promociones.")
        }
        setLoading(false);
    }

    // Método para descargar el Excel con la información de la promoción.
    // @param idPromotion: identificador único de la promoión
    // @param namePromotion: nombre de la promoción

    const downloadQrXlsx = async (idPromotion,namePromotion) => {
        setLoadingFullScreen(true);
        PromocionesAPI.downloadQrXlsx(idPromotion).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let name = `QR PROMOCION ${namePromotion} ${moment().format('DD MM YYYY h mm ss')}`;
            name = name.replace(/ /g,"_");
            link.setAttribute('download', `${name.toUpperCase()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            toast.error(err.response.data.Message)
        }).finally(() => {
            setLoadingFullScreen(false);
        });
    }

    // Método para descargar el Zip con la información de la promoción.
    // @param idPromotion: identificador único de la promoión
    // @param namePromotion: nombre de la promoción

    const downloadQrZip = async (idPromotion,namePromotion) => {
        setLoadingFullScreen(true);
        PromocionesAPI.downloadQrZip(idPromotion).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let name = `QR PROMOCION ${namePromotion} ${moment().format('DD MM YYYY h mm ss')}`;
            name = name.replace(/ /g,"_");
            link.setAttribute('download', `${name.toUpperCase()}.zip`);
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            toast.error(err.response.data.Message)
        }).finally(() => {
            setLoadingFullScreen(false);
        });
    }

    const deletePromotion = () => {
        setLoadingFullScreen(true);
        PromocionesAPI.deletePromotion(currentPromo.PromoQRId).then(response => {
            toast.success(response.data.Message);
            getPromociones();
        }).catch(err => {
            toast.error(err.response.data.Message);
        }).finally(() => {
            
            setLoadingFullScreen(false);
            setCurrentPromo(null);
        })
    }

    useEffect(() => {
        setPromociones([]);
        setTotalPages(0);
        getPromociones(nameSearch.trim(), currentPage,activate);
    },[nameSearch, currentPage,activate])

    return (
        <div>
            <h1 className="title is-2">Promociones</h1>
            <SearchFormV4
                create={() => {
                    history.push({
                        pathname: '/app/promociones/nueva',
                        state: {currentPage: currentPage, name: nameSearch}
                    });
                }}
                search={(text) => setNameSearch(text)}
                name={nameSearch}
                tooltip={"Crear una nueva promoción"}
                newBtnTitle={"Nueva promoción"}
                activate={activate}
                activateSearch={(value)  => setActivate(value)}
            />
            <br/>
            <TablaPromociones
                promociones={promociones}
                totalPages={totalPages}
                currentPage={currentPage}
                
                goToPage={(page) => setCurrentPage(page)}
                downloadQrXlsx={(idPromotion,namePromotion) => {
                    downloadQrXlsx(idPromotion,namePromotion);
                }}
                downloadQrZip={(idPromotion,namePromotion) => {
                    downloadQrZip(idPromotion,namePromotion);
                }}
                deletePromotion ={(promotion)=>{
                    setCurrentPromo(promotion);
                    deleteModal.toggle();
                }}
                prefixCampaign = {(idPromotion)=>{
                    history.push({
                        pathname: `/app/promociones/prefijo/${idPromotion}`,
                        state: {currentPage: currentPage, name: nameSearch}
                    })
                }}
                loading={loading}
                history={history}
            />
            <PortalModal
                isShowing={deleteModal.isShowing}
                hide={deleteModal.toggle}
                
                title="Confirmar"
                body={() => {
                    return (
                        <span>
                            <p>{`¿Estás seguro que deseas eliminar la promoción?`}</p>
                        </span>
                    )
                }}
                footer
                footerButtons={() => {
                    return (
                        <div className="has-text-right modal-detalle-buttons">
                            <button className="button is-danger" onClick={() => {
                                deleteModal.toggle();
                                setCurrentPromo(null);
                            }}>Cancelar</button>
                            <button className="button is-success" onClick={() => {deleteModal.toggle();deletePromotion();}}>Confirmar</button>
                        </div>
                    )
                }}
            />
            <Loading isFullscreen={true} isLoading={loadingFullScreen} width='100px' height='100px'/>
        </div>
    )

}