import React, { useState } from 'react';
import './style.css';

export default function ResumenPromocion({ promotion }) {
    const [_promotion, setPromotion] = useState({ ...promotion });

    return (
        <div>
            <div className="columns is-multiline">
                <div className="column is-4 has-text-left">
                    <label className="label">Promoción</label>
                    <div className="control">
                        <p className="wordBreak">{_promotion.PromotionName}</p>
                    </div>
                </div>
                <div className="column is-4 has-text-left">
                    <label className="label">Nombre de promoción</label>
                    <div className="control">
                        <p className="wordBreak">{_promotion.Name}</p>
                    </div>
                </div>
                <div className="column is-4 has-text-left">
                    <label className="label">Fecha inicio de cupón</label>
                    <div className="control">
                        <p>{_promotion.DateStartFormat}</p>
                    </div>
                </div>
                <div className="column is-12 has-text-left">
                    <label className="label">Fecha final de cupón</label>
                    <div className="control">
                        <p>{_promotion.DateEndFormat}</p>
                    </div>
                </div>
                <div className="column is-6 has-text-left">
                    <label className="label">Descripción</label>
                    <div className="control">
                        <p className="wordBreak">{_promotion.PromoDescriptionApp}</p>
                    </div>
                </div>
                <div className="column is-6 has-text-left">
                    <label className="label">Restricciones</label>
                    <div className="control">
                        <p className="wordBreak">{_promotion.PromoRestrictionApp}</p>
                    </div>
                </div>
                <div className="column is-4 has-text-left">
                    <label className="label">Tipo de promoción</label>
                    <div className="control">
                        <p className="wordBreake">{_promotion.PromoTypeDescription}</p>
                    </div>
                </div>
                <div className="column is-4 has-text-left">
                    <label className="label">Tipo de QR</label>
                    <div className="control">
                        <p className="wordBreake">{_promotion.QRTypeName}</p>
                    </div>
                </div>
                {_promotion.QRTypeId && _promotion.QRTypeId === 2 && <div className="column is-12 has-text-left">
                    <label className="label">Cantidad</label>
                    <div className="control">
                        <p>{_promotion.AmountCodes}</p>
                    </div>
                </div>}
                {_promotion.QRTypeId && _promotion.QRTypeId === 3 && <div className="column is-4 has-text-left">
                    <label className="label">Tipo de segmento</label>
                    <div className="control">
                        <p className="wordBreak">{_promotion.PromoTypeSegmentationDescription}</p>
                    </div>
                </div>}
                {_promotion.PromoTypeSegmentationId && _promotion.PromoTypeSegmentationId === 1 && <div className="column is-4 has-text-left">
                    <label className="label">Segmento</label>
                    <div className="control">
                        <p className="wordBreak">{_promotion.SegmentationName}</p>
                    </div>
                </div>}

                {_promotion.ShowApp && <div className="column is-12">
                    <h1 className="title is-4">Mostrar en app móvil</h1>
                </div>}
                {_promotion.ShowApp && <div className="column is-6 has-text-left">
                    <label className="label">Imagen</label>
                    <div className="control">
                        <img src={_promotion.ImagePathApp} />
                    </div>
                </div>}
            </div>
        </div>
    )
}