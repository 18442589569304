import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class TaxRateAPI {
    

    /**
     * Get Taxes by id
     * @returns response promise
     */
     static getById(id) {
        return request.get(`/TaxRate/${id}`);
    }
    /**
     * Get all Taxes
     * @returns response promise
     */
    static getAll() {
        return request.get(`/TaxRate`);
    }

    /**
     * Creates new taxes
     * @param {*} data data taxes
     * @returns response promise
     */
    static create(data) {
        return request.post(`/TaxRate`, data);
    }

    /**
     * Update tax
     * @param {*} data data taxes
     * @returns response promise
     */
     static update(id, data) {
        return request.put(`/TaxRate/${id}`, data);
    }
}