import React from 'react'
import Select from 'react-select';
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker/dist/es"
import NoItems from '../util/noitems/NoItems';
import MenuDriveAPI from '../../services/MenuDrive';
import { format } from 'date-fns';
import ErrorMessages from '../../util/validation-error-messages';
import { toast } from 'react-toastify';
import Loading from '../util/loading/Loading';
import ReactExport from "react-export-excel";
import WarehouseAPI from '../../services/Warehouse';
import EmpresasAPI from '../../services/CaffenioEmpresas/Empresas';
import lodash from 'lodash';
import Pagination from '../util/pagination/Pagination';
import HistorialMovimientosAPI from '../../services/HistorialMovimientos';

// Creamos instancias para el package export excel.
// Variable que indica el archivo de excel que se crea.
const ExcelFile = ReactExport.ExcelFile;
// Variable que indica una hoja de excel.
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// Variable que indica una columna de excel.
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const HistorialVersion = () => {

    const [state, setState] = useState({
        listaVersiones: [],
        isLoading: false,
        listaSucursales: [],
        listaNumVersiones: [],
        listaSegmentos: [],
        listaEstados: [],
        listaCiudades: [],
        FiltroFechaInicio: null,
        FiltroFechaFin: null,
        FiltroVersion: null,
        FiltroSegmento: null,
        FiltroEstado: null,
        FiltroCiudad: null,
        FiltroDrive: null,
        FiltroTipoSistema: null,
        listaTipoSistemas: []
    })

    useEffect(() => {
        window.scrollTo(0, 0)
        let listaVersionesAux = []
        let listaSucursalesAux = []
        let listaTipoSistemasAux = []
        setState({ ...state, isLoading: true })
        HistorialMovimientosAPI.ObtenerHistorialVersiones()
            .then(res => {
                listaVersionesAux = res.data
                return WarehouseAPI.getCaffenios()
            })
            .then(res => {
                listaSucursalesAux = res.data.filter(x => x.Active === true)
                return HistorialMovimientosAPI.ObtenerTipoSistemas()
            })
            .then(res => {
                listaTipoSistemasAux = res.data
                return HistorialMovimientosAPI.ObtenerNumVersionSync()
            })
            .then(res => {
                let listaNumVersionesAux = []
                res.data.forEach((versionSync, index) => {
                    listaNumVersionesAux.push({
                        value: versionSync,
                        label: versionSync
                    })
                });
                setState({
                    ...state,
                    listaVersiones: obtenerInfoMovimiento(listaVersionesAux, listaSucursalesAux),
                    listaSucursales: listaSucursalesAux,
                    listaNumVersiones: listaNumVersionesAux,
                    listaSegmentos: rellenarSegmentos(listaSucursalesAux),
                    listaEstados: rellenarEstados(listaSucursalesAux),
                    listaCiudades: rellenarCiudades(listaSucursalesAux),
                    listaTipoSistemas: listaTipoSistemasAux,
                    isLoading: false
                })
            })
            .catch(err => {
                console.log(err)
                setState({ ...state, isLoading: false })
                if (err.response) {
                    toast.error(err.response.data.Message)
                } else {
                    toast.error(ErrorMessages.SinConexion)
                }
            })
    }, [])

    const addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const obtenerInfoMovimiento = (listaVersionesAux, listaSucursalesAux) => {
        let listaVersionesInfo = listaVersionesAux.map(version => {
            version.Caffenio = listaSucursalesAux.find(sucursal => sucursal.idCaffenio === version.IdCaffenio)
            return version
        })
        return listaVersionesInfo
    }

    const rellenarSegmentos = (Caffenios) => {
        var newArray = [];
        let OptionSelectState = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectState.push({ value: item.idSegmento, label: item.Segmento });
        });

        for (var i in OptionSelectState) {
            lookupObject[OptionSelectState[i]["value"]] = OptionSelectState[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label.toLowerCase()], ['asc']);
    }

    const rellenarEstados = (Caffenios) => {
        var newArray = [];
        let OptionSelectState = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectState.push({ value: item.StateId, label: item.State });
        });

        for (var i in OptionSelectState) {
            lookupObject[OptionSelectState[i]["value"]] = OptionSelectState[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label.toLowerCase()], ['asc']);
    }


    const rellenarCiudades = (Caffenios) => {
        var newArray = [];
        let OptionSelectState = [];
        var lookupObject = {};
        Caffenios.map((item) => {
            OptionSelectState.push({ value: item.Municipalityid, label: item.Municipality });
        });

        for (var i in OptionSelectState) {
            lookupObject[OptionSelectState[i]["value"]] = OptionSelectState[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }

        return lodash.sortBy(newArray, [ts => ts.label.toLowerCase()], ['asc']);
    }

    const filtrarDatos = () => {

        if (state.FiltroFechaInicio === null || state.FiltroFechaFin === null) {
            toast.error('Se debe establecer fecha de inicio y fecha fin')
            return
        }

        let filtros = {
            FiltroFechaInicio: state.FiltroFechaInicio,
            FiltroFechaFin: state.FiltroFechaFin,
            IdTipoSistema: state.FiltroTipoSistema ? state.FiltroTipoSistema.idTipoSistema : null
        }

        setState({ ...state, isLoading: true })
        HistorialMovimientosAPI.ObtenerHistorialVersiones(filtros)
            .then(res => {
                let listaVersionesAux = res.data
                //Se obtiene la sucursal y tipo de movimiento de los movimientos del menú
                listaVersionesAux = obtenerInfoMovimiento(res.data, state.listaSucursales)
                //Se quitan las movimientos del menú que no tengan sucursal activa
                listaVersionesAux = listaVersionesAux.filter(movimiento => movimiento.Caffenio !== undefined)
                //Se filtra por segmento
                if (state.FiltroSegmento !== null && state.FiltroSegmento.length > 0) {
                    listaVersionesAux = listaVersionesAux.filter(movimiento =>
                        state.FiltroSegmento.some(segmento => segmento.value === movimiento.Caffenio.idSegmento))
                }
                //Se filtra por estado
                if (state.FiltroEstado !== null && state.FiltroEstado.length > 0) {
                    listaVersionesAux = listaVersionesAux.filter(movimiento =>
                        state.FiltroEstado.some(estado => estado.value === movimiento.Caffenio.StateId))
                }
                //Se filtra por ciudad
                if (state.FiltroCiudad !== null && state.FiltroCiudad.length > 0) {
                    listaVersionesAux = listaVersionesAux.filter(movimiento =>
                        state.FiltroCiudad.some(ciudad => ciudad.value === movimiento.Caffenio.MunicipalityId))
                }
                //Se filtra por drive
                if (state.FiltroDrive !== null && state.FiltroDrive.length > 0) {
                    listaVersionesAux = listaVersionesAux.filter(movimiento =>
                        state.FiltroDrive.some(drive => drive.idCaffenio === movimiento.Caffenio.idCaffenio))
                }
                setState({
                    ...state,
                    listaVersiones: listaVersionesAux,
                    isLoading: false
                })
            })
            .catch(err => {
                console.log(err)
                setState({ ...state, isLoading: false })
                if (err.response) {
                    toast.error(err.response.data.Message)
                } else {
                    toast.error(ErrorMessages.SinConexion)
                }
            })
    }

    const limpiarFiltros = () => {
        setState({ ...state, isLoading: true })
        HistorialMovimientosAPI.ObtenerHistorialVersiones()
            .then(res => {
                setState({
                    ...state,
                    FiltroFechaInicio: null,
                    FiltroFechaFin: null,
                    FiltroVersion: null,
                    FiltroSegmento: null,
                    FiltroEstado: null,
                    FiltroCiudad: null,
                    FiltroDrive: null,
                    FiltroTipoSistema: null,
                    listaVersiones: obtenerInfoMovimiento(res.data, state.listaSucursales),
                    isLoading: false
                })
            })
            .catch(err => {
                setState({ ...state, isLoading: false })
                if (err.response) {
                    toast.error(err.response.data.Message)
                } else {
                    toast.error(ErrorMessages.SinConexion)
                }
            })
    }


    return (
        <div>
            <h1 className='title is-2'>Historial de versiones</h1>

            <div className='card'>
                <div className='card-content'>
                    <div className="row">
                        <div className='columns is-multiline'>

                            <div className="column is-4">
                                <label className='label'>Fecha Actualización Inicio</label>
                                <DatePicker
                                    isClearable
                                    locale="es"
                                    name={"FiltroFechaInicio"}
                                    placeholderText="dd/MM/yyyy"
                                    selected={state.FiltroFechaInicio}
                                    onChange={fecha => setState({ ...state, FiltroFechaInicio: fecha })}
                                    dateFormat="dd/MM/yyyy"
                                    className="input"
                                    todayButton="Hoy"
                                />
                            </div>

                            <div className="column is-4">
                                <label className='label'>Fecha Actualización Fin</label>
                                <DatePicker
                                    isClearable
                                    locale="es"
                                    name={"FiltroFechaFin"}
                                    placeholderText="dd/MM/yyyy"
                                    selected={state.FiltroFechaFin}
                                    onChange={fecha => setState({ ...state, FiltroFechaFin: fecha })}
                                    dateFormat="dd/MM/yyyy"
                                    className="input"
                                    todayButton="Hoy"
                                    disabled={state.FiltroFechaInicio === null}
                                    minDate={state.FiltroFechaInicio}
                                    maxDate={addDays(state.FiltroFechaInicio, 30)}
                                />
                            </div>

                            <div className="column is-4">
                                <label className='label'>Tipo sistema</label>
                                <Select
                                    isClearable
                                    value={state.FiltroTipoSistema}
                                    placeholder={"Seleccionar..."}
                                    noOptionsMessage={() => "No hay opciones"}
                                    onChange={value => setState({ ...state, FiltroTipoSistema: value })}
                                    options={state.listaTipoSistemas}
                                    getOptionLabel={(tipoSistema) => tipoSistema.Descripcion}
                                    getOptionValue={(tipoSistema) => tipoSistema.idTipoSistema}
                                />
                            </div>

                            {/* <div className="column is-4">
                                <label className='label'>Versión</label>
                                <Select
                                    isClearable
                                    value={state.FiltroVersion}
                                    placeholder={"Seleccionar..."}
                                    noOptionsMessage={() => "No hay opciones"}
                                    onChange={value => setState({ ...state, FiltroVersion: value })}
                                    options={state.listaNumVersiones}
                                />
                            </div> */}

                            <div className="column is-3">
                                <label className="label">Segmentación</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"FiltroSegmento"}
                                        value={state.FiltroSegmento}
                                        onChange={value => {
                                            setState({ ...state, FiltroSegmento: value })
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={state.listaSegmentos}
                                    />
                                </div>
                            </div>
                            <div className="column is-3">
                                <label className="label">Estados</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"FiltroEstado"}
                                        value={state.FiltroEstado}
                                        onChange={value => {
                                            setState({ ...state, FiltroEstado: value })
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={state.listaEstados}
                                    />
                                </div>
                            </div>
                            <div className="column is-3">
                                <label className="label">Ciudades</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOptionC"}
                                        value={state.FiltroCiudad}
                                        onChange={value => {
                                            setState({ ...state, FiltroCiudad: value })
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={state.listaCiudades}
                                    />
                                </div>
                            </div>
                            <div className="column is-3">
                                <label className="label">{'Drive(s)'}</label>
                                <div className="control">
                                    <Select
                                        isMulti
                                        placeholder={"Seleccionar..."}
                                        name={"FiltroDrive"}
                                        value={state.FiltroDrive}
                                        onChange={value => {
                                            setState({ ...state, FiltroDrive: value })
                                        }}
                                        noOptionsMessage={() => "No hay opciones"}
                                        options={state.listaSucursales}
                                        getOptionLabel={(sucursal) => sucursal.Name}
                                        getOptionValue={(sucursal) => sucursal.idCaffenio}
                                    />
                                </div>
                            </div>

                            <div className="column is-3"></div>

                            <div className="has-text-left buttons-modal column">
                                <button
                                    className="button is-success"
                                    style={{ marginRight: "5px" }}
                                    onClick={filtrarDatos}
                                >
                                    <span className="icon is-small">
                                        <i className="fa fa-search"></i>
                                    </span>
                                    <span>Buscar</span>
                                </button>
                                <button
                                    className="button is-success"
                                    onClick={limpiarFiltros}
                                    style={{ marginRight: "5px" }}>
                                    <span className="icon is-small">
                                        <i className="fa fa-times"></i>
                                    </span>
                                    <span>Limpiar filtros</span>
                                </button>
                                {state.listaVersiones.length > 0 &&
                                    <ExcelFile
                                        filename={`HistorialVersiones-${new Date().toLocaleDateString('es-MX')}`}
                                        element={
                                            <button className="button is-success">
                                                <span className="icon">
                                                    <i className="fa fa-file" />
                                                </span>
                                                <span>Exportar a EXCEL</span>
                                            </button>
                                        }
                                    >
                                        <ExcelSheet data={state.listaVersiones} name="HistorialVersiones" >
                                            <ExcelColumn label="Drive" value={(version) => version.Caffenio ? version.Caffenio.Name : 'N/A'} />
                                            <ExcelColumn label="Sistema" value={(version) => version.Sistema ? version.Sistema : 'N/A'} />
                                            <ExcelColumn label="Equipo" value={(version) => version.Equipo ? version.Equipo : 'N/A'} />
                                            <ExcelColumn label="Versión" value={(version) => version.VersionSync ? version.VersionSync : 'N/A'} />
                                            <ExcelColumn label="Fecha Actualización" value={(version) => version.CreateDate ? format(Date.parse(version.CreateDate), "dd/MM/yyyy HH:mm") : 'N/A'} />
                                            <ExcelColumn label="Último Inicio" value={(version) => version.RunDate ? format(Date.parse(version.RunDate), "dd/MM/yyyy HH:mm") : 'N/A'} />
                                        </ExcelSheet>
                                    </ExcelFile>
                                }
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className='card'>
                <div className='card-content'>
                    <div>
                        {state.listaVersiones.length > 0 ?
                            <table className='table is-fullwidth'>
                                <thead>
                                    <tr>
                                        <th>Drive</th>
                                        <th>Sistema</th>
                                        <th>Equipo</th>
                                        <th>Versión</th>
                                        <th>Fecha Actualización</th>
                                        <th>Último Inicio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.listaVersiones.map((version, index) =>
                                            <tr key={index}>
                                                <td>{version.Caffenio ? version.Caffenio.Name : 'N/A'}</td>
                                                <td>{version.Sistema || 'N/A'}</td>
                                                <td>{version.Equipo || 'N/A'}</td>
                                                <td>{version.VersionSync || 'N/A'}</td>
                                                <td>{version.CreateDate ? format(Date.parse(version.CreateDate), "dd/MM/yyyy HH:mm") : 'N/A'}</td>
                                                <td>{version.RunDate ? format(Date.parse(version.RunDate), "dd/MM/yyyy HH:mm") : 'N/A'}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table> : <NoItems itemName="versiones" />
                        }
                    </div>
                    {/* <Pagination
                        items={state.listaMovimientosMenu}
                        onChangePage={movimientosMenu => setState({ ...state, paginadoMovimientosMenu: movimientosMenu })}
                    /> */}
                </div>
            </div>


            <Loading isFullscreen={true} isLoading={state.isLoading} width='100px' height='100px' />
        </div>
    )
}
