import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import Pagination from '../util/pagination/Pagination';
import NoItems from '../util/noitems/NoItems';
import Loading from '../util/loading/Loading';

import AdministrarEmpleadosAPI from '../../services/AdministrarEmpleados';
import { ModalListaSupervisors } from './ModalListaSupervisores';
import { Link } from 'react-router-dom';
import AcceptModal from '../util/acceptModal/AcceptModal';
import { toast } from 'react-toastify';
import SearchForm from '../util/searchform/SearchForm';
import { EditCoordinadoresSupervisores } from './EditCoordinadoresSupervisores';
import Field from '../util/field/Field';

// Tipos de empleado existentes
const COORDINADOR = 1
const SUPERVISOR = 2

/**
 * Modulo para administrar los coordinadores y supervisores
 */
export const CoordinadoresSupervisores = () => {

    const [state, setState] = useState({
        /* Uso general */
        paginadoEmpleados: [],
        empleadoSeleccionado: {},
        isLoading: false,
        /* Modal lista supervisores */
        modalListaSupervisores: false,
        /* Modal Eliminar */
        modalEliminar: false,
        listaSupervisoresReasignar: [],
        listaCoordinadoresReasignar: [],
        coordinadorReasignar: null,
        /* Modal Editar */
        modalEditar: false,
        //Listas de la bd
        listaEmpleadosFiltrados: [],
        listaEmpleados: [],
        //Filtros
        nombreFiltro: "",
        listaTipoEmpleadoFiltro: [],
        tipoEmpleadoFiltro: null,
        listaSegmentacionFiltro: [],
        segmentacionFiltro: null,
        listaEstadoFiltro: [],
        estadoFiltro: null,
        listaMunicipioFiltro: [],
        municipioFiltro: null,
        listaDriveFiltro: [],
        listaDriveFiltroFiltrados: [],
        driveFiltro: null,
        listaTodosDrive: [],
    })

    useEffect(() => {
        setState({ ...state, isLoading: true })
        window.scrollTo(0, 0)
        let stateAux = { ...state }
        AdministrarEmpleadosAPI.GetListaEmpleados().then(res => {
            stateAux.listaEmpleadosFiltrados = res.data
            stateAux.listaEmpleados = res.data
            return AdministrarEmpleadosAPI.GetSegmentaciones()
        })
            .then(res => {
                stateAux.listaSegmentacionFiltro = res.data
                return AdministrarEmpleadosAPI.GetTiposEmpleado()
            })
            .then(res => {
                stateAux.listaTipoEmpleadoFiltro = res.data
                return AdministrarEmpleadosAPI.GetSupervisoresDrive()
            })
            .then(res => {
                stateAux.listaTodosDrive = res.data
                stateAux.listaEstadoFiltro = res.data.filter((value, index, array) =>
                    array.findIndex(value2 => (value2.idEstado === value.idEstado)) === index)
                stateAux.listaMunicipioFiltro = res.data.filter((value, index, array) =>
                    array.findIndex(value2 => (value2.idMunicipio === value.idMunicipio)) === index)
                stateAux.listaDriveFiltro = res.data.filter((value, index, array) =>
                    array.findIndex(value2 => (value2.idCaffenio === value.idCaffenio)) === index)
                stateAux.listaDriveFiltroFiltrados = stateAux.listaDriveFiltro
                stateAux.isLoading = false
                setState(stateAux)
            })
            .catch(res => {
                setState({ ...state, isLoading: false })
            })
    }, [])

    const eliminarEmpleado = (empleado) => {
        if (state.listaSupervisoresReasignar.length === 0) {
            AdministrarEmpleadosAPI.DeleteEmpleado(empleado)
                .then(res => {
                    setState({ ...state, isLoading: true })
                    let stateAux = { ...state }
                    stateAux.listaEmpleadosFiltrados.splice(stateAux.listaEmpleadosFiltrados
                        .findIndex(e => e.idEmpleado === empleado.idEmpleado), 1)
                    stateAux.paginadoEmpleados.splice(stateAux.paginadoEmpleados
                        .findIndex(e => e.idEmpleado === empleado.idEmpleado), 1)
                    stateAux.listaEmpleados.splice(stateAux.listaEmpleados
                        .findIndex(e => e.idEmpleado === empleado.idEmpleado), 1)
                    stateAux.modalEliminar = false
                    stateAux.isLoading = false

                    setState(stateAux)
                    toast.success("Empleado eliminado correctamente")
                })
        } else {
            if (state.coordinadorReasignar != null) {
                setState({ ...state, isLoading: true })
                let stateAux = { ...state }

                stateAux.listaEmpleadosFiltrados.splice(stateAux.listaEmpleadosFiltrados
                    .findIndex(e => e.idEmpleado === empleado.idEmpleado), 1)
                stateAux.paginadoEmpleados.splice(stateAux.paginadoEmpleados
                    .findIndex(e => e.idEmpleado === empleado.idEmpleado), 1)
                stateAux.listaEmpleados.splice(stateAux.listaEmpleados
                    .findIndex(e => e.idEmpleado === empleado.idEmpleado), 1)

                stateAux.modalEliminar = false
                stateAux.isLoading = false

                AdministrarEmpleadosAPI.GetSupervisoresPorCoord(state.coordinadorReasignar.idEmpleado)
                    .then(res => {
                        let listaSupervisoresValidar = res.data
                        let coordinadorSupervisores = []

                        stateAux.listaSupervisoresReasignar.forEach(e => {
                            if (!listaSupervisoresValidar.some(x => x.idEmpleado === e.idEmpleado)) {
                                coordinadorSupervisores.push({
                                    idEmpleadoCoord: stateAux.coordinadorReasignar.idEmpleado,
                                    idEmpleadoSup: e.idEmpleado
                                })
                            }
                        })
                        return AdministrarEmpleadosAPI.PostSupervisorCoordinador(coordinadorSupervisores)
                    }).then(res => {
                        return AdministrarEmpleadosAPI.DeleteEmpleado(empleado)
                    }).then(res => {
                        setState(stateAux)
                        toast.success("Empleado eliminado correctamente")
                    })
            } else {
                toast.error('Debe seleccionar un coordinador')
            }
        }
    }

    const handleEliminarModal = (empleado) => {
        if (empleado.idTipoEmpleado === COORDINADOR) {
            AdministrarEmpleadosAPI.GetSupervisoresPorCoord(empleado.idEmpleado)
                .then(res => {
                    setState({
                        ...state,
                        modalEliminar: true,
                        empleadoSeleccionado: empleado,
                        listaSupervisoresReasignar: res.data,
                        listaCoordinadoresReasignar: state.listaEmpleados.filter(x => x.idTipoEmpleado === COORDINADOR
                            && x.idEmpleado != empleado.idEmpleado)
                    })
                })
        } else if (empleado.idTipoEmpleado === SUPERVISOR) {
            setState({ ...state, modalEliminar: true, empleadoSeleccionado: empleado })
        }
    }

    const handleEditarModal = (empleado) => {
        setState({ ...state, modalEditar: true, empleadoSeleccionado: empleado })
    }

    const handleActualizarEmpleado = (empleadoActualizado) => {
        let nuevaInformacion = { ...empleadoActualizado }
        nuevaInformacion.NombreCompleto = `${nuevaInformacion.Nombre} ${nuevaInformacion.ApellidoP} ${nuevaInformacion.ApellidoM}`
        state.paginadoEmpleados.map(emp => {
            if (emp.idEmpleado === empleadoActualizado.idEmpleado) {
                Object.assign(emp, nuevaInformacion)
                return emp
            } else {
                return emp
            }
        })
    }

    const limpiarFiltros = () => {
        let stateAux = { ...state }
        stateAux.nombreFiltro = ""
        stateAux.tipoEmpleadoFiltro = null
        stateAux.segmentacionFiltro = null
        stateAux.estadoFiltro = null
        stateAux.municipioFiltro = null
        stateAux.driveFiltro = null
        stateAux.listaEmpleadosFiltrados = stateAux.listaEmpleados
        stateAux.listaDriveFiltroFiltrados = stateAux.listaDriveFiltro
        setState(stateAux)
    }

    const handleFiltroNombre = (texto) => {
        setState({ ...state, nombreFiltro: texto })
    }

    const handleFiltros = () => {
        let listaEmpleadosAux = state.listaEmpleados
        /* Primero se filtra por nombre si es que se establecio */
        if (state.nombreFiltro.trim() !== '') {
            listaEmpleadosAux = state.listaEmpleados.filter(empleado => {
                return empleado.NombreCompleto.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(state.nombreFiltro.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                    || empleado.NombreCompleto.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(state.nombreFiltro.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            })
        }
        /* Luego se filtra por tipo de empleado si es que se establecio */
        if (state.tipoEmpleadoFiltro != null && state.tipoEmpleadoFiltro.length !== 0) {
            listaEmpleadosAux = listaEmpleadosAux.filter(empleado =>
                state.tipoEmpleadoFiltro.some(tipo => empleado.idTipoEmpleado === tipo.idTipoEmpleado))
        }
        /* Luego se filtra por segmentación si es que se establecio */
        if (state.segmentacionFiltro != null && state.segmentacionFiltro.length !== 0) {
            listaEmpleadosAux = listaEmpleadosAux.filter(empleado =>
                state.segmentacionFiltro.some(segmento => empleado.idSegmento === segmento.SegmentId))
        }
        /* Luego se filtra por estado si es que se establecio */
        if (state.estadoFiltro != null && state.estadoFiltro.length !== 0) {
            let listaTodosAux = state.listaTodosDrive.filter(x =>
                state.estadoFiltro.some(estado => x.idEstado === estado.idEstado))
            listaEmpleadosAux = listaEmpleadosAux.filter(empleado =>
                listaTodosAux.some(todosAux => empleado.idEmpleado === todosAux.idEmpleado
                    || empleado.idEmpleado === todosAux.idCoordinador))
        }
        /* Luego se filtra por municipio si es que se establecio */
        if (state.municipioFiltro != null && state.municipioFiltro.length !== 0) {
            let listaTodosAux = state.listaTodosDrive.filter(x =>
                state.municipioFiltro.some(municipio => x.idMunicipio === municipio.idMunicipio))
            listaEmpleadosAux = listaEmpleadosAux.filter(empleado =>
                listaTodosAux.some(todosAux => empleado.idEmpleado === todosAux.idEmpleado
                    || empleado.idEmpleado === todosAux.idCoordinador))
        }
        /* Luego se filtra por Drive si es que se establecio */
        if (state.driveFiltro != null && state.driveFiltro.length !== 0) {
            let listaTodosAux = state.listaTodosDrive.filter(x =>
                state.driveFiltro.some(drive => x.idCaffenio === drive.idCaffenio))
            listaEmpleadosAux = listaEmpleadosAux.filter(empleado =>
                listaTodosAux.some(todosAux => empleado.idEmpleado === todosAux.idEmpleado
                    || empleado.idEmpleado === todosAux.idCoordinador))
        }
        /* Se establece el filtrado con todas las opciones anteriores establecidas */
        setState({ ...state, listaEmpleadosFiltrados: listaEmpleadosAux })
    }

    const handleOpenLoading = () => {
        setState({ ...state, isLoading: true })
    }

    const handleCloseLoading = () => {
        setState({ ...state, isLoading: false })
    }

    const handleEmpleadoModal = (empleado) => {
        setState({ ...state, modalListaSupervisores: true, empleadoSeleccionado: empleado, });
    }

    return (
        <div>
            <h1 className='title is-2'>Coordinadores y Supervisores</h1>
            {state.isLoading &&
                <Loading
                    isFullscreen={true}
                    isLoading={state.isLoading}
                    width='100px'
                    height='100px'
                />
            }

            <div className="field">
                <div className="control has-text-right">
                    <Link
                        to='/coordinadores/nuevo'
                        className='button is-success'
                    >
                        <span className="icon is-small">
                            <i className="fa fa-plus"></i>
                        </span>
                        <span>Nuevo empleado</span>
                    </Link>
                </div>
            </div>

            {/* Filtros de busqueda*/}
            <div className='card'>
                <div className='card-content'>
                    <div className="row">
                        <div className='columns is-multiline'>
                            <div className='column is-6'>
                                <label className='label'>Nombre</label>
                                <div className="control">
                                    <input
                                        value={state.nombreFiltro}
                                        onChange={e => handleFiltroNombre(e.currentTarget.value)}
                                        className={`input`}
                                        placeholder={"Ingrese nombre del empleado"}
                                        onKeyDown={evt => {
                                            if (evt.key === "Enter") {
                                                handleFiltros()
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='column is-3'>
                                <label className='label'>Puesto</label>
                                <div className='control'>
                                    <Select
                                        isMulti
                                        isClearable
                                        placeholder={"Seleccionar..."}
                                        value={state.tipoEmpleadoFiltro}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={value => setState({ ...state, tipoEmpleadoFiltro: value })}
                                        options={state.listaTipoEmpleadoFiltro}
                                        getOptionLabel={(tipoEmpleado) => tipoEmpleado.Descripcion}
                                        getOptionValue={(tipoEmpleado) => tipoEmpleado.idTipoEmpleado}
                                    />
                                </div>
                            </div>
                            <div className='column is-3'>
                                <label className='label'>Segmento</label>
                                <div className='control'>
                                    <Select
                                        isMulti
                                        isClearable
                                        placeholder={"Seleccionar..."}
                                        value={state.segmentacionFiltro}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={value => {
                                            let filtrarDrives = state.listaDriveFiltro
                                            if (value.length !== 0) {
                                                filtrarDrives = filtrarDrives.filter(drive =>
                                                    value.some(segmento => drive.idSegmento === segmento.SegmentId))
                                            }
                                            setState({ ...state, segmentacionFiltro: value, listaDriveFiltroFiltrados: filtrarDrives })
                                        }}
                                        options={state.listaSegmentacionFiltro}
                                        getOptionLabel={(segmento) => {
                                            switch (segmento.Name) {
                                                case "Andatti drive":
                                                    return "Andatti"
                                                case "Drive":
                                                    return "Caffenio"
                                                case "momento espresso":
                                                    return "Momento Espresso"
                                                default:
                                                    return segmento.Name
                                            }
                                        }}
                                        getOptionValue={(objSegment) => objSegment.SegmentId}
                                    />
                                </div>
                            </div>
                            <div className='column is-4'>
                                <label className='label'>Estado</label>
                                <div className='control'>
                                    <Select
                                        isMulti
                                        isClearable
                                        placeholder={"Seleccionar..."}
                                        value={state.estadoFiltro}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={value => setState({ ...state, estadoFiltro: value })}
                                        options={state.listaEstadoFiltro}
                                        getOptionLabel={(estado) => estado.Estado}
                                        getOptionValue={(estado) => estado.idEstado}
                                    />
                                </div>
                            </div>
                            <div className='column is-4'>
                                <label className='label'>Municipio</label>
                                <div className='control'>
                                    <Select
                                        isMulti
                                        isClearable
                                        value={state.municipioFiltro}
                                        placeholder={"Seleccionar..."}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={value => setState({ ...state, municipioFiltro: value })}
                                        options={state.listaMunicipioFiltro}
                                        getOptionLabel={(municipio) => municipio.Municipio}
                                        getOptionValue={(municipio) => municipio.idMunicipio}
                                    />
                                </div>
                            </div>
                            <div className='column is-4'>
                                <label className='label'>Drive</label>
                                <div className='control'>
                                    <Select
                                        isMulti
                                        isClearable
                                        value={state.driveFiltro}
                                        placeholder={"Seleccionar..."}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={value => setState({ ...state, driveFiltro: value })}
                                        options={state.listaDriveFiltroFiltrados}
                                        getOptionLabel={(drive) => drive.Drive}
                                        getOptionValue={(drive) => drive.idCaffenio}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="has-text-right buttons-modal">
                            <div className="column">
                                {/* Botón limpiar filtros*/}
                                <button
                                    className="button is-success"
                                    onClick={limpiarFiltros}
                                    style={{ marginRight: "5px" }}>
                                    <span className="icon is-small">
                                        <i className="fa fa-times"></i>
                                    </span>
                                    <span>Limpiar filtros</span>
                                </button>
                                {/* Botón para filtrar*/}
                                <button
                                    className="button is-success"
                                    onClick={handleFiltros}>
                                    <span className="icon is-small">
                                        <i className="fa fa-search"></i>
                                    </span>
                                    <span>Buscar</span>
                                </button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <br />

            {/* Lista de empleados */}
            <div className='card'>
                <div className='card-content'>
                    {state.listaEmpleadosFiltrados.length !== 0
                        ?
                        <table className='table is-fullwidth'>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Segmento</th>
                                    <th>Puesto</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Tabla que contiene el nombre, segmento, puesto y botones de acción de los empleados */}
                                {
                                    state.paginadoEmpleados.map(empleado =>
                                        <tr key={empleado.idEmpleado}>
                                            <td>{empleado.NombreCompleto}</td>
                                            <td>{empleado.idSegmento === 1 ? "Caffenio"
                                                : empleado.idSegmento === 9 ? "Andatti"
                                                    : empleado.idSegmento === 10 ? "Momento Espresso"
                                                        : empleado.idSegmento === 2 ? "Kiosko"
                                                            : "N/A"}</td>
                                            <td>{empleado.Puesto}</td>
                                            <td>
                                                {/* Botón para editar un empleado */}
                                                <button className="button tableAction is-warning tooltip" data-tooltip="Editar empleado"
                                                    onClick={() => handleEditarModal(empleado)}
                                                >
                                                    <span className="icon">
                                                        <i className="fa fa-edit" />
                                                    </span>
                                                </button>
                                                {/* Botón para eliminar un empleado */}
                                                <button className="button tableAction is-danger tooltip" data-tooltip="Eliminar empleado"
                                                    onClick={() => handleEliminarModal(empleado)}
                                                >
                                                    <span className="icon">
                                                        <i className="fa fa-trash" />
                                                    </span>
                                                </button>
                                                {/* Botón para consultar lista de supervisores SOLO en caso que sea un coordinador*/}
                                                {
                                                    empleado.Puesto === "Coordinador" ?
                                                        <button
                                                            className='button tableAction is-info tooltip' data-tooltip="Consultar supervisores"
                                                            onClick={() => handleEmpleadoModal(empleado)}
                                                        >
                                                            <span className='icon'><i className='fa fa-info' /></span>
                                                        </button>
                                                        : null
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        : <NoItems itemName="empleados" />
                    }

                    <Pagination
                        items={state.listaEmpleadosFiltrados}
                        onChangePage={(listaEmpleadosFiltrados) => setState({ ...state, paginadoEmpleados: listaEmpleadosFiltrados })}
                    />

                </div>
            </div>

            {/* Modal de anuncio para eliminar un empleado */}
            <AcceptModal
                isActive={state.modalEliminar}
                done={() => eliminarEmpleado(state.empleadoSeleccionado)}
                cancel={() => setState({ ...state, modalEliminar: false })}
                modalTitle={`¿Está seguro que desea eliminar a ${state.empleadoSeleccionado.NombreCompleto}?`}
                modalBody={
                    state.empleadoSeleccionado.idTipoEmpleado === COORDINADOR &&
                    state.listaSupervisoresReasignar.length > 0 &&
                    <div>
                        <label className="label">Si elimina un coordinador con supervisores, debe reasignar sus supervisores a otro coordinador:</label>
                        <br />
                        <Field
                            isHorizontal
                        >
                            <div className="control" style={{ width: "100%" }}>
                                <Select
                                    placeholder={"Seleccionar nuevo coordinador"}
                                    noOptionsMessage={() => "No hay opciones"}
                                    options={state.listaCoordinadoresReasignar}
                                    onChange={val => {
                                        state.coordinadorReasignar = val
                                    }}
                                    getOptionLabel={(coordinador) => coordinador.NombreCompleto}
                                    getOptionValue={(coordinador) => coordinador.idEmpleado}
                                />
                            </div>
                        </Field>
                        <br />
                        <label className="label">Lista de supervisores:</label>
                        <br />
                        <div className="card">
                            <div className="card-content">
                                <table className='table is-fullwidth'>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Drive</th>
                                            <th>Municipio</th>
                                            <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            state.listaSupervisoresReasignar.map(supervisor =>
                                                <tr key={supervisor.idEmpleado}>
                                                    <td>{supervisor.NombreCompleto}</td>
                                                    <td style={{ 'width': 250 }}>
                                                        <Select
                                                            menuPlacement="top"
                                                            value={null}
                                                            isClearable={false}
                                                            options={supervisor.listaDrives}
                                                            placeholder={"Ver Drives asignados"}
                                                            getOptionLabel={(drive) => drive.Drive}
                                                            getOptionValue={(drive) => drive.idCaffenio}
                                                        />
                                                    </td>
                                                    <td>
                                                        {
                                                            supervisor.listaDrives.filter((value, index, array) =>
                                                                array.findIndex(value2 => (value2.idMunicipio === value.idMunicipio)) === index)
                                                                .map((value, index, array) => {
                                                                    if (array.length - 1 !== index) {
                                                                        return `${value.Municipio}, `
                                                                    } else {
                                                                        return value.Municipio
                                                                    }
                                                                })
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            supervisor.listaDrives.filter((value, index, array) =>
                                                                array.findIndex(value2 => (value2.idEstado === value.idEstado)) === index)
                                                                .map((value, index, array) => {
                                                                    if (array.length - 1 !== index) {
                                                                        return `${value.Estado}, `
                                                                    } else {
                                                                        return value.Estado
                                                                    }
                                                                })
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            />

            {/* Modal para mostrar la lista de supervisores de un coordinador */}
            {state.modalListaSupervisores &&
                <ModalListaSupervisors
                    coordinadorSeleccionado={state.empleadoSeleccionado}
                    onClose={() => setState({ ...state, modalListaSupervisores: false })}
                />
            }

            {/* Modal para editar la información de un empleado */}
            {state.modalEditar &&
                <EditCoordinadoresSupervisores
                    empleadoSeleccionado={state.empleadoSeleccionado}
                    onClose={() => setState({ ...state, modalEditar: false })}
                    handleOpenLoading={handleOpenLoading}
                    handleCloseLoading={handleCloseLoading}
                    handleActualizarEmpleado={handleActualizarEmpleado}
                />
            }
        </div>
    )
}