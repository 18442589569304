import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class RecipesTypesAPI {
    static getAll() {
        return middleware.hasAuth(() => request.get('/TypeRecipe'));
    }

    static get(id) {
        return middleware.hasAuth(() => request.get(`/TypeRecipe/${id}`));
    }

    static getByClasification(ids){
        return middleware.hasAuth(() => request.post(`/TypeRecipe/GetByClassification`, ids));
    }

    static create(TypeRecipe) {
        console.log(TypeRecipe)
        let fd = new FormData();
        fd.append("Description", TypeRecipe.Description);
        fd.append("Position", TypeRecipe.Position);
        fd.append("RecipeName", TypeRecipe.RecipeName); 
        fd.append("Activo", TypeRecipe.Activo);
        fd.append("SegmentId", TypeRecipe.SegmentationId.SegmentId)
        TypeRecipe.Image && fd.append("Image", TypeRecipe.Image);

        return request.post('/TypeRecipe',fd);
    }

    static edit(TypeRecipe){
        let fd = new FormData();

        fd.append("Description", TypeRecipe.Description);
        fd.append("Position", TypeRecipe.Position);
        fd.append("RecipeName", TypeRecipe.RecipeName);
        fd.append("RecipeTypeId", TypeRecipe.RecipeTypeId);
        fd.append("Activo", TypeRecipe.Activo);
        TypeRecipe.idSegmento && fd.append("SegmentId", TypeRecipe.idSegmento)

        TypeRecipe.Image instanceof File && fd.append("Image", TypeRecipe.Image);

        return request.put(`/TypeRecipe`,fd);
    }

    static delete(id){
        return middleware.hasAuth(() => request.delete(`TypeRecipe/${id}`));
    }
}