import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' + localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class UnitsAPI {
    static getAll() {
        return middleware.hasAuth(() => request.get('/units'));
    }

    static get(id) {
        return middleware.hasAuth(() => request.get(`/units/${id}`));
    }

    static getByCodeProvider(id) {
        return middleware.hasAuth(() => request.get(`/units/code/${id}`));
    }

    static create(unit) {
        return middleware.hasAuth(() => request.post('/units',unit));
    }

    static edit(unit, id){
        return middleware.hasAuth(() => request.put(`/units`,unit));
    }

    static delete(id){
        return middleware.hasAuth(() => request.delete(`units/${id}`));
    }

    static getSatUnits(){
        return request.get(`UnitsSat`);
    }
}