import React, { useState } from 'react';
import { toast } from 'react-toastify';

export default function MostrarAppDataForm({ inputs, setInput, errors, handleInputChange }) {

    const [fileURL, setFileURL] = useState("");

    const onChangeCheckbox = async () => {
        setInput('showApp', !inputs.showApp)
        if(!inputs.scheduleNotification){
            setInput('appImage',null);
            setFileURL("");        
        }
    }

    /**
     * Función que se ejecuta al subir una imagen del topping
     */
    const handleSelectedFile = (e) => {

        let files = e.target.files
        let file = files[0]
        e.target.value = "";
        if (typeof file === 'undefined') {
            e.target.value = null;
            return false;
        }

        const EXTENSION = (file.name.split('.')[1]).toLowerCase();

        if (validateExtensionFile(EXTENSION) === false) {
            toast.error("Es necesario seleccionar un archivo valido.");
            e.target.value = null;
            return false;

        } else {
            setInput(e.target.name, file);
            setFileURL(URL.createObjectURL(file));
        }
    }

    /**
     * Valida que solo permita los tipos deseados
     */
    const validateExtensionFile = (extension1) => {

        var extensiones_permitidas = ["png"];
        //extension1 = extension1.toLowerCase();
        //console.log(extension1)
        if (extensiones_permitidas.indexOf(extension1) === -1) {
            return false;
        }

        return true;
    }

    return (
        <div className="columns is-multiline">
            <div className="column is-6">
                <label className="label">Mostrar en app móvil</label>
                <input
                    type="checkbox"
                    name="showApp"
                    className="switch"
                    checked={inputs.showApp}
                    onChange={() => { }}
                />
                <label disabled htmlFor="showApp" onClick={(e) => {
                    e.stopPropagation();
                    onChangeCheckbox();
                }} />
            </div>
            {inputs.showApp && <div className="column is-12">
                <div className="columns is-multiline">
                    
                    <div className="column is-6">
                        <label className="label">Imagen</label>
                        <div className="file is-info has-name">
                            <label className="file-label">
                                <input
                                    className="input file-input"
                                    id="file"
                                    type="file"
                                    name="appImage"
                                    accept="image/x-png"
                                    onChange={(e) => handleSelectedFile(e)}
                                />
                                <span className="file-cta">
                                    <span className="file-label"><i className="fa fa-upload"> Seleccionar imagen .PNG</i></span>
                                </span>
                                <span className="file-name">{inputs.appImage && inputs.appImage !== null && inputs.appImage.name ? inputs.appImage.name : "Archivo"}</span>
                            </label>
                        </div>
                        {errors.appImage && <p className="help title has-text-left is-6 is-danger">Imagen requerida</p>}
                        {inputs.appImage != null &&
                        <button className="button tableAction is-danger trash-right" onClick={(e) => {
                            e.stopPropagation();
                            setInput('appImage', null);
                            setFileURL("");                   
                        }
                        }>
                            <span className="icon">
                                <i className="fa fa-trash" />
                            </span>
                        </button>
                    }
                        {fileURL && <img src={fileURL} style={{ width: 400, height: 300 }} />}
                    </div>
                </div>
            </div>}
        </div>
    );
}