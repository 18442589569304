import React from 'react';
import "../../components/style.css"

export default function ClassificationCard({classification}){
    return (
        <div className="card">
            <div className="imgInCard">
                <img src={classification.UrlIcon} alt="Placeholder image"/>
            </div>
            
            <div>
                {classification.Featured && <span className="icon tooltip" data-tooltip="Producto destacado">
                        <i className="fa fa-star" aria-hidden="true"></i>
                    </span>}
                <p className="title is-4">
                    {classification.Name}
                </p>
            </div>
        </div>
    )
}