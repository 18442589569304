import React, { useEffect, useState } from 'react';

// Components
import Select from 'react-select';
import Pagination from '../util/pagination/Pagination';
import NoItems from '../util/noitems/NoItems';
import Loading from '../util/loading/Loading';
import { ModalAsignacion } from './ModalAsignacion';
import { toast } from 'react-toastify';

// Servicios
import CaffenioDrivesAPI from '../../services/caffenioDrive';
import EmpleadosAPI from '../../services/Empleados';
import SucursalesAPI from '../../services/Sucursales';

// Constants
const LIDER = 6;
const SUPERVISOR = 3;
const ENCARGADA = 7;
const COORDINADOR_DRIVE = 11;

/**
 * Vista de modulo para asignación de líderes y supervisores.
 * @returns Component
 */
export const AsignacionLiderSupervisor = () => {

    const [ state, setState ] = useState({
        filters: {},
        drives: [],
        pageDrives: [],
        driveSelected: {},
        sucursales: [],
        pages: [],
        listSupervisores: [],
        listEncargadas: [],
        listLideres: [],
        listCoordinadoresDrive: [],
        listIdsLideresPrevSelected: [],
        listIdsSupervisoresPrevSelected: [],
        listIdsEncargadasPrevSelected: [],
        listIdsCoordinadoresDrivePrevSelected: [],
        detailVisible: false,
        isLoading: false
    });

    useEffect(() => {
        // Carga de datos iniciales
        setState({
            ...state,
            isLoading: true
        });
        let drives = [];
        let pagesDirves = [];
        let listSucursales = [];
        let listLideres = [];
        let listEncargadas = [];
        let listCoordinadoresDrive = [];
        let listSupervisores = [];
        let listIdsLideres = [];
        let listIdsSupervisores = [];
        let listIdsEncargadas = [];
        let listIdsCoordinadores = [];


        CaffenioDrivesAPI.getCaffenioParaAsignacion(state.filters)
            .then(res => {
                drives = res.data;
                pagesDirves = res.data;
                listIdsLideres = res.data.map(dri => dri.Lider);
                listIdsSupervisores = res.data.map(dri => dri.Supervisor);
                listIdsEncargadas = res.data.map(dri => dri.Encargada);
                listIdsCoordinadores = res.data.map(dri => dri.CoordinadorDrive);
                return EmpleadosAPI.getEmpleados(LIDER)
            })
            .then(res => {
                listLideres = res.data.map(item => {
                    return {
                        value: item.idEmpleado,
                        label: `${item.Nombre} ${item.ApellidoP} ${item.ApellidoM}`
                    }
                });
                return EmpleadosAPI.getEmpleados(SUPERVISOR)
            })
            .then(res => {
                listEncargadas = res.data.map(item => {
                    return {
                        value: item.idEmpleado,
                        label: `${item.Nombre} ${item.ApellidoP} ${item.ApellidoM}`
                    }
                });
                return EmpleadosAPI.getEmpleados(ENCARGADA)
            })
            .then(res => {
                listCoordinadoresDrive = res.data.map(item => {
                    return {
                        value: item.idEmpleado,
                        label: `${item.Nombre} ${item.ApellidoP} ${item.ApellidoM}`
                    }
                });
                return EmpleadosAPI.getEmpleados(COORDINADOR_DRIVE)
            })
            .then(res => { 
                listSupervisores = res.data.map(item => {
                    return {
                        value: item.idEmpleado,
                        label: `${item.Nombre} ${item.ApellidoP} ${item.ApellidoM}`
                    }
                });
                return SucursalesAPI.getSucursales()
            })
            .then(res => {
                let listSeted = [];
                let tempSucursales = [];
                res.data.forEach(suc => {
                    if (!listSeted.includes(suc.Nombre)) {
                        listSeted.push(suc.Nombre);
                        tempSucursales.push({
                            value: suc.idSucursal,
                            label: suc.Nombre
                        });
                    }
                });
                listSucursales = tempSucursales;
            })
            .catch(() => {
                toast.error("Ocurrio un error al cargar a los empleados.")
                setState({...state})
            }).finally(() => setState({
                ...state,
                isLoading: false,
                drives,
                pagesDirves,
                listLideres,
                listSupervisores,
                listEncargadas,
                listCoordinadoresDrive,
                sucursales: listSucursales,
                listIdsLideresPrevSelected: listIdsLideres,
                listIdsSupervisoresPrevSelected: listIdsSupervisores,
                listIdsEncargadasPrevSelected: listIdsEncargadas,
                listIdsCoordinadoresDrivePrevSelected: listIdsCoordinadores
            }))
    },[]);

    /**
     * Actualiza la informacion de los Drives con los datos nuevos
     * al asignar un líder y supervisor.
     * @param {Object} infoUpdated - Info de Drive actualizada.
     */
    const updateDrivesList = infoUpdated => {
        let listDrives = [ ...state.drives ];
        let indexDriveToReplace = null;
        state.drives.forEach((drive, indexDrive) => {
            if (drive.idCaffenio === infoUpdated.idCaffenio)
                indexDriveToReplace = indexDrive;
        });
        listDrives.splice(indexDriveToReplace, 1, {...infoUpdated});
        setState({
            ...state,
            drives: [...listDrives],
            pageDrives: [...listDrives],
            detailVisible: false
        });
        toast.success("Información actualizada");
    }

    /**
     * Muestra modal de asignación.
     * @param {Object} driveInfo - Info del drive seleccionado
     */
    const selectDrive = (driveInfo) => {
        setState({
            ...state,
            detailVisible: true,
            driveSelected: driveInfo
        });
    }

    /**
     * Manejo de cambio en el input.
     * @param {Event} e - Evento de cambio en el input.
     */
    const handleChangeDriveName = e => {
        setState({
            ...state,
            filters: {
                ...state.filters,
                driveName: e.target.value
            }
        });
    }

    /**
     * Filtra los drives segun los parametros mandados.
     */
    const handleFilterDrives = () => {
        setState({...state, isLoading: true});
        let listDrives = [];
        let listIdsLideres = [];
        let listIdsSupervisores = [];
        let listIdsEncargadas = [];
        let listIdsCoordinadoresDrive = [];

        let filters = {};

        if ( state.filters.sucursal )
            filters.sucursal = state.filters.sucursal.label
        if ( state.filters.driveName )
            filters.driveName = state.filters.driveName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
        else if ( state.filters.driveName === "" )
            delete filters.driveName

        CaffenioDrivesAPI.getCaffenioParaAsignacion({ ...filters })
            .then(res => {
                listDrives = res.data;
                listIdsLideres = res.data.map(dri => dri.Lider);
                listIdsSupervisores = res.data.map(dri => dri.Supervisor);
                listIdsEncargadas = res.data.map(dri => dri.Encargada);
                listIdsCoordinadoresDrive = res.data.map(dri => dri.CoordinadorDrive);
            })
            .catch(() => toast.error("Ocurrio un problema al filtrar los Drives."))
            .finally(() => setState({
                ...state,
                isLoading: false,
                drives: listDrives,
                pageDrives: listDrives,
                listIdsLideresPrevSelected: listIdsLideres,
                listIdsSupervisoresPrevSelected: listIdsSupervisores,
                listIdsEncargadasPrevSelected: listIdsEncargadas,
                listIdsCoordinadoresDrivePrevSelected: listIdsCoordinadoresDrive,
            }));
    }

    /**
     * Limpiado de los filtros.
     */
    const handleCleanFilters = () => {
        if (Object.toString(state.filters) === '{}')
            return;
        setState({...state, isLoading: true});
        let listDrives = [];
        let listIdsLideres = [];
        let listIdsSupervisores = [];
        let listIdsEncargadas = [];
        let listIdsCoordinadoresDrive = [];
        CaffenioDrivesAPI.getCaffenioParaAsignacion({})
            .then(res => {
                listDrives = res.data;
                listIdsLideres = res.data.map(dri => dri.Lider);
                listIdsSupervisores = res.data.map(dri => dri.Supervisor);
                listIdsEncargadas = res.data.map(dri => dri.Encargada);
                listIdsCoordinadoresDrive = res.data.map(dri => dri.CoordinadorDrive);
            })
            .catch(() => toast.error("Ocurrio un problema al filtrar los Drives."))
            .finally(() => setState({
                ...state,
                filters: {
                    driveName: '',
                    sucursal: null
                },
                isLoading: false,
                drives: listDrives,
                pageDrives: listDrives,
                listIdsLideresPrevSelected: listIdsLideres,
                listIdsSupervisoresPrevSelected: listIdsSupervisores,
                listIdsEncargadasPrevSelected: listIdsEncargadas,
                listIdsCoordinadoresDrivePrevSelected: listIdsCoordinadoresDrive,
            }));
    }

    return(
        <div>
            <h1 className='title is-2'>Asignación de Drive</h1>
            { state.isLoading &&
                <Loading
                    isFullscreen={true}
                    isLoading={state.isLoading}
                    width='100px'
                    height='100px'
                />
            }
            <br/>
            {/* Filtros */}
            <div className='card'>
                <div className='card-content'>
                    <div className="row">
                        <div className='columns is-multiline'>
                            <div className='column is-3'>
                                <label className='label'>Nombre</label>
                                <div className='control'>
                                    <input
                                        type='text'
                                        className='input'
                                        value={state.filters.driveName}
                                        placeholder='Ingrese el nombre de Drive'
                                        onChange={handleChangeDriveName}
                                    />
                                </div>
                            </div>
                            <div className='column is-3'>
                                <label className='label'>Sucursal</label>
                                <div className='control'>
                                    <Select
                                        placeholder={"Seleccionar..."}
                                        name={"selectedOption"}
                                        value={state.filters.sucursal}
                                        noOptionsMessage={() => "No hay opciones"}
                                        onChange={item => setState({
                                            ...state,
                                            filters: {
                                                ...state.filters,
                                                sucursal: item
                                            }
                                        })}
                                        options={state.sucursales}
                                    />
                                </div>
                            </div>                            
                            <div className='column is-3'>
                            </div>
                            <div className='column is-3'>
                                <button
                                    className='button is-success'
                                    onClick={handleFilterDrives}>
                                    Filtrar
                                </button>
                            </div>
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="columns">
                            <div className="column is-3"></div>
                            <div className="column is-3"></div>
                            <div className="column is-3"></div>
                            <div className="column is-3">
                                <button className="button" onClick={handleCleanFilters}>
                                    Limpiar filtros
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {/* Tabla de Drives */}
            <div className='card'>
                <div className='card-content'>
                {
                    state.drives.length !== 0 ?
                    <table className='table is-fullwidth'>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Estado</th>
                                <th>Municipio</th>
                                <th>Sucursal</th>
                                <th>Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                state.pageDrives.map(drive =>
                                    <tr key={ drive.idCaffenio }>
                                        <td>{drive.Nombre}</td>
                                        <td>{drive.Estado}</td>
                                        <td>{drive.Municipio}</td>
                                        <td>{drive.Sucursal}</td>
                                        <td>
                                            <button
                                                className='button is-warning'
                                                onClick={() => selectDrive(drive)}
                                            >
                                                <span className='icon'><i className='fa fa-edit'/></span>
                                                Editar
                                            </button>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    :  <NoItems itemName="drives" />
                }
                <Pagination
                    items={state.drives}
                    pageSize={10}
                    onChangePage={(drives) => setState({ ...state, pageDrives: drives })}
                />
                </div>
            </div>
            {state.detailVisible &&
                <ModalAsignacion
                    driveInfo={state.driveSelected}
                    listLideres={state.listLideres}
                    listSupervisores={state.listSupervisores}
                    listEncargadas={state.listEncargadas}
                    listCoordinadoresDrive={state.listCoordinadoresDrive}
                    listIdsLideresPrevSelected={state.listIdsLideresPrevSelected}
                    listIdsSupervisoresPrevSelected={state.listIdsSupervisoresPrevSelected}
                    listIdsEncargadasPrevSelected={state.listIdsEncargadasPrevSelected}
                    listIdsCoordinadoresDrivePrevSelected={state.listIdsCoordinadoresDrivePrevSelected}
                    onSave={updatedInfo => updateDrivesList(updatedInfo)}
                    onClose={() => setState({...state, detailVisible: false})}
                />
            }
        </div>
    )
}