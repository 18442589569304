import { toast } from "react-toastify";
import ErrorMessages from "./validation-error-messages";

export default class Validators {
    static SUPPORTED_FORMATS = [
        "image/png"
    ];

    static Classification(classification) {
        //console.log(classification);
        classification.Name = classification.Name.trim();
        classification.ShortName = classification.ShortName.trim();
        //classification.Position = classification.Position.trim();
        classification.RecipeName = classification.RecipeName.trim();
        if(classification.Name.length < 3 || classification.Name.length > 30){ 
            toast.error(ErrorMessages.name);
            return false;
        }
        if(classification.ShortName.length < 3){ 
            toast.error(ErrorMessages.shortName);
            return false;
        }
        if(classification.Position === "" || classification.Position < 0 || Number.isNaN(Number(classification.Position))){
            toast.error(ErrorMessages.menuPosition);
            return false;
        }
        if(classification.RecipeName.length < 3 || classification.RecipeName.length > 30){
            toast.error(ErrorMessages.recipeName);
            return false;
        }
        return true;
    }

    static Presentations(presentation) {
        
        if (presentation.Name) {
            presentation.Name = presentation.Name.trim();
        } else {
            toast.error(ErrorMessages.name);
            return false;
        }

        if (presentation.RecipeName) {
            presentation.RecipeName = presentation.RecipeName.trim();
        } else {
            toast.error(ErrorMessages.recipeName);
            return false;
        }

        if(presentation.Name.length < 3 || presentation.Name.length > 20){ 
            toast.error(ErrorMessages.smallestName);
            return false;
        }
        
        if(presentation.Position === "" || presentation.Position < 0 || Number.isNaN(Number(presentation.Position))){
            toast.error(ErrorMessages.menuPosition);
            return false;
        }
        
        if(presentation.RecipeName.length < 3 || presentation.RecipeName.length > 20){
            toast.error(ErrorMessages.smallestRecipeName);
            return false;
        }
        return true;
    }

    static Flavor(flavor){
        flavor.Name = flavor.Name.trim();
        flavor.ShortName = flavor.ShortName.trim();
        //flavor.Position = flavor.Position.trim();
        flavor.RecipeName = flavor.RecipeName.trim();
        if(flavor.Name.length < 3 || flavor.Name.length > 50 ){ 
            toast.error(ErrorMessages.smallName);
            return false;
        }
        if(flavor.ShortName.length < 3 || flavor.ShortName.length >  50){ 
            toast.error(ErrorMessages.shortName);
            return false;
        }
        if(flavor.Position === "" || flavor.Position < 0 || Number.isNaN(Number(flavor.Position))){
            toast.error(ErrorMessages.menuPosition);
            return false;
        }
        if(flavor.RecipeName.length < 3 || flavor.RecipeName.length > 50){
            toast.error(ErrorMessages.recipeName);
            return false;
        }
        return true;
    }

    static Grain(grain){
        grain.Description = grain.Description.trim();
        grain.RecipeName = grain.RecipeName.trim();
        //grain.Position = grain.Position.trim();
        if(grain.Description.length < 3 || grain.Description.length > 50){ 
            toast.error(ErrorMessages.name);
            return false;
        }
        if(grain.Position === "" || grain.Position < 0 || Number.isNaN(Number(grain.Position))){
            toast.error(ErrorMessages.menuPosition);
            return false;
        }
        if(grain.Position > 255){
            toast.error(ErrorMessages.menuPositionMax);
            return false;
        }
        if(grain.RecipeName.length < 1 || grain.RecipeName.length > 50 ){
            toast.error(ErrorMessages.recipeName);
            return false;
        }
        return true;
    }

    static Milk(milk){
        milk.Description = milk.Description.trim();
        milk.RecipeName = milk.RecipeName.trim();
        if(milk.Description.length < 3 || milk.Description.length > 25){ 
            toast.error(ErrorMessages.smallName);
            return false;
        }
        if(milk.Position === "" || milk.Position < 0 || Number.isNaN(Number(milk.Position))){
            toast.error(ErrorMessages.menuPosition);
            return false;
        }
        if(milk.RecipeName.length < 3 || milk.RecipeName.length > 25 ){
            toast.error(ErrorMessages.smallRecipeName);
            return false;
        }
        return true;
    }

    static RecipeType(recipeType){
        recipeType.Description = recipeType.Description.trim();
        //recipeType.Position = recipeType.Position.trim();
        recipeType.RecipeName = recipeType.RecipeName.trim();
        if(recipeType.Description.length < 3 || recipeType.Description.length > 25){ 
            toast.error(ErrorMessages.smallName);
            return false;
        }
        if(recipeType.Position === "" || recipeType.Position < 0 || Number.isNaN(Number(recipeType.Position))){
            toast.error(ErrorMessages.menuPosition);
            return false;
        }
        if(recipeType.RecipeName.length < 3 || recipeType.RecipeName.length > 25 ){
            toast.error(ErrorMessages.smallRecipeName);
            return false;
        }
        return true;
    }

    static InventoryItem(inventoryItem){
        inventoryItem.CodeProvider = inventoryItem.CodeProvider.trim();
        if(inventoryItem.CodeProvider.length < 9){
            toast.error(ErrorMessages.codeProvider);
            return false;
        }

        // TODO Validación de Artículo inventario.

        return true;
    }
}