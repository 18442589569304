import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import Checkbox from '../../../../../util/checkbox/Checkbox';
import to from '../../../../../../util/to';
import SegmentacionAPI from '../../../../../../services/Caffenio App/Segmentacion';
import { toast } from 'react-toastify';
import NotificacionAPI from '../../../../../../services/Caffenio App/Notificacion';
import es from "date-fns/locale/es";
require('moment/locale/es');


export default function AgendarNotificacionForm({replaceInputs, inputs, setInput, cleanInputs, errors, handleInputChange, handleSelectChange}){
    const minTime = new Date(2019,1,1,6,0,0)
    const maxTime = new Date(2019,1,1,22,0,0)
    const [days, setDays] = useState([])
    const [types, setTypes] = useState([])
    const [frequencies, setFrequencies] = useState([])
    const [monthlies, setMonthlies] = useState([])

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if(!inputs.type){
            cleanInputs();
            setInput('days', []);
        }
    },[inputs.type])

    const getData = async () => {
        let err, diasSemana, frequencyTypes, frequency, monthly;
        [err, diasSemana] = await to(SegmentacionAPI.getDiasSemana());
        [err, frequencyTypes] = await to(NotificacionAPI.getFrequencyEventTypes());
        [err, frequency] = await to(NotificacionAPI.getFrequentTypes());
        [err, monthly] = await to(NotificacionAPI.getFrequentMonthlyTypes());

        if (err){
            toast.error(err.response.data.Message);
        }else{
            setDays(diasSemana);
            setTypes(frequencyTypes);
            setFrequencies(frequency);
            setMonthlies(monthly);
        }
    }

    return (
        <div className="columns is-multiline">
            <div className="column is-6">
                <label className="label">Tipo</label>
                <div className={`control`}>
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"type"}
                        value={inputs.type}
                        isClearable
                        onChange={(value, event) => handleSelectChange(value, event, true)}
                        noOptionsMessage={() => "No hay opciones"}
                        options={types.map(t => ({value: t.NotificationScheduleTypeId, label: t.Description}))}
                    />
                </div>
                {errors.type && <p className="help title has-text-left is-6 is-danger">Tipo requerido</p>}
            </div>
            <div className="column is-6">
                {inputs.type && inputs.type.value === 2 && <span>
                    <label className="label">Frecuencia</label>
                    <div className={`control`}>
                        <Select
                            placeholder={"Seleccionar..."}
                            name={"frecuency"}
                            value={inputs.frecuency}
                            isClearable
                            onChange={(value, event) => {
                                replaceInputs({type: inputs.type, frecuency: value});
                                if(value && value.value === 2){
                                    setInput('hours', [null, null, null, null, null, null, null])
                                    setInput('days', []);
                                }
                            }}
                            noOptionsMessage={() => "No hay opciones"}
                            options={frequencies.map(f => ({value: f.NotificationScheduleFrequencyTypeId, label: f.Description}))}
                        />
                    </div>
                    {errors.frecuency && <p className="help title has-text-left is-6 is-danger">Frecuencia requerida</p>}
                </span>}
            </div>
            {inputs.type && inputs.type.value === 1 && 
            <div className="column is-12">
                <div className="columns">
                    <div className="column">
                        <label className="label">Fecha</label>
                        <div className="control has-text-centered">
                            <DatePicker
                                className="input"
                                selected={inputs.date}
                                minDate={moment().add(1, 'days').toDate()}
                                onChange={date => {
                                    setInput('date', date)
                                }}
                                onChangeRaw={(e) => e.preventDefault()}
                                dateFormat="dd/MMMM/yyyy"
                                locale={es}
                            />
                        </div>
                    </div>
                    <div className="column">
                        <label className="label">Hora</label>
                        <div className="control has-text-centered">
                            <DatePicker
                                className="input"
                                selected={inputs.date2}
                                onChange={date => {
                                    setInput('date2', date)
                                    setInput('date', new Date(inputs.date.getFullYear(), inputs.date.getMonth(), inputs.date.getDate(), date.getHours(), date.getMinutes(), 0))
                                }}
                                onChangeRaw={(e) => e.preventDefault()}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={60}
                                minTime={minTime}
                                maxTime={maxTime}
                                dateFormat="HH:mm"
                                locale={es}
                            />
                        </div>
                    </div>
                </div>
            </div>}
            {inputs.type && inputs.type.value === 2 && <div className="column is-12">
                <div className="columns">
                    <div className="column is-6">
                        <label className="label">Fecha de inicio</label>
                        <div className="control">
                            <DatePicker
                                className="input"
                                selected={inputs.startDate}
                                disabled={inputs.frecuency && inputs.frecuency.value == 3 && !inputs.dayOfMonth}
                                minDate={
                                    inputs.frecuency && inputs.frecuency.value == 3 && inputs.dayOfMonth && inputs.dayOfMonth.value === 1 
                                    ? moment().toDate()
                                    : inputs.frecuency && inputs.frecuency.value == 3 && inputs.dayOfMonth && inputs.dayOfMonth.value === 2
                                    ? moment().subtract(1, 'months').toDate()
                                    : moment().add(1, 'days').toDate()
                                }
                                maxDate={inputs.endDate}
                                onChangeRaw={(e) => e.preventDefault()}
                                onChange={date => {
                                    setInput('startDate', date)
                                }}
                                timeCaption="Fecha de inicio"
                                dateFormat={`${inputs.frecuency && inputs.frecuency.value === 3 ? 'MMMM/yyyy' : 'dd/MMMM/yyyy'}`}
                                showMonthYearPicker={inputs.frecuency && inputs.frecuency.value == 3}
                                locale={es}
                            />
                        </div>
                    </div>
                    <div className="column is-6">
                        <label className="label">Fecha de fin</label>
                        <div className="control">
                            <DatePicker
                                className="input"
                                selected={inputs.endDate}
                                disabled={!inputs.startDate}
                                minDate={inputs.frecuency && inputs.frecuency.value == 3 && inputs.dayOfMonth && inputs.dayOfMonth.value === 1 ? moment().toDate() : moment(inputs.startDate).add(1, 'days').toDate()}
                                onChangeRaw={(e) => e.preventDefault()}
                                onChange={date => {
                                    if(inputs.frecuency && inputs.frecuency.value === 3){
                                        const formattedDate = moment(date).endOf('month');
                                        const endDate = moment(formattedDate.toDate()).endOf('month')
                                        setInput('endDate', endDate.toDate())
                                    }else
                                        setInput('endDate' , moment(date).toDate());
                                }}
                                timeCaption="Fecha de fin"
                                dateFormat={`${inputs.frecuency && inputs.frecuency.value === 3 ? 'MMMM/yyyy' : 'dd/MMMM/yyyy'}`}
                                showMonthYearPicker={inputs.frecuency && inputs.frecuency.value == 3}
                                locale={es}
                            />
                        </div>
                    </div>
                </div>
            </div>}
            {inputs.frecuency && inputs.frecuency.value === 2 && <div className="column is-12">
                <div className="columns is-multiline">
                        {days.map((dia, index) => {
                            return (
                                <div className="column is-12 has-text-centered" key={`dia${index}`}>
                                    <div className="columns">
                                        <div className="column is-3 "/>
                                        <div className="column is-2">
                                            <label className="label">{dia.Name}</label>
                                            <div className="control">
                                                <Checkbox 
                                                    name={`dia${index}`}
                                                    value={inputs.days.findIndex(n => n.IdFilterPurchaseDay === dia.IdFilterPurchaseDay) !== -1}
                                                    data={dia}
                                                    onChange={(e) => {
                                                        const i = inputs.days.findIndex(d => d.IdFilterPurchaseDay === dia.IdFilterPurchaseDay);
                                                        const dayArr = [...inputs.days];
                                                        const hoursArr = [...inputs.hours];
                                                        if(i !== -1){
                                                            dayArr.splice(i, 1);
                                                            hoursArr[index] = null;
                                                        }else
                                                            dayArr.push(dia);
                                                        
                                                        setInput('days', dayArr)
                                                        setInput('hours', hoursArr);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="column is-4">
                                            <label className="label">Hora</label>
                                            <div className="control">
                                                <DatePicker
                                                    disabled={inputs.days.findIndex(d => d.IdFilterPurchaseDay === dia.IdFilterPurchaseDay) === -1}
                                                    className="input"
                                                    selected={inputs.hours[index]}
                                                    onChange={date => {
                                                        const hoursArr = [...inputs.hours];
                                                        hoursArr[index] = date;
                                                        setInput('hours', hoursArr);
                                                    }}
                                                    onChangeRaw={(e) => e.preventDefault()}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={60}
                                                    minTime={minTime}
                                                    maxTime={maxTime}
                                                    timeCaption="Hora"
                                                    dateFormat="HH:mm"
                                                    locale={es}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>}
            {inputs.frecuency && inputs.frecuency.value === 3 && <div className="column is-6">
                <label className="label">Día de lanzamiento</label>
                <div className={`control`}>
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"dayOfMonth"}
                        value={inputs.dayOfMonth}
                        isClearable
                        onChange={(value, event) => {
                            handleSelectChange(value, event, true)
                            setInput('startDate', null);
                            setInput('endDate', null);
                        }}
                        noOptionsMessage={() => "No hay opciones"}
                        options={monthlies.map(m => ({value: m.NotificationScheduleMonthlyTypeId, label: m.Description}))}
                    />
                </div>
                {errors.frecuency && <p className="help title has-text-left is-6 is-danger">Frecuencia requerida</p>}
            </div>}
            <div className="column is-6">
                {inputs.frecuency && inputs.frecuency.value !== 2 && <span>
                    <label className="label">Hora</label>
                    <div className="control">
                        <DatePicker
                            className="input"
                            selected={inputs.hour}
                            onChange={date => {
                                setInput('hour', date)
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            onChangeRaw={(e) => e.preventDefault()}
                            minTime={minTime}
                            maxTime={maxTime}
                            timeCaption="Hora"
                            dateFormat="HH:mm"
                            locale={es}
                        />
                    </div>
                </span>}
            </div>
        </div>
    )
}