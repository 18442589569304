import React, { Component } from 'react';
import  SearchForm  from "../util/searchform/SearchForm";
import  TableTemplate  from "../util/tabletemplate/TableTemplate";
import  NoItems  from "../util/noitems/NoItems";
import AcceptModal from '../util/acceptModal/AcceptModal';
import Pagination from '../util/pagination/Pagination';
import RecipesAPI from '../../services/Recipe';
import { toast } from 'react-toastify';
import Loading from '../util/loading/Loading';
import RecipeTypesAPI from '../../services/RecipeType';
import PresentationsAPI from '../../services/Presentations';
import MilksAPI from '../../services/Milk';
import GrainsAPI from '../../services/Grain';
import FlavorsAPI from '../../services/Flavor';
import ClasificacionesAPI from '../../services/Classification';

import Select from 'react-select';
import lodash from 'lodash';

import ModoGuiado from "../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../util/BtnModoGuiado/BtnModoGuiado'
import Modal from '../util/modal/Modal';
// import RecipeToppings from './components/RecipeToppings';
// import NewRecipeDetail from './components/NewRecipeDetail';
import ErrorMessages from '../../util/validation-error-messages';
import RecipeFilters from '../util/recipeFilters/recipeFilters';

class newFeatured extends Component {
    recipes = [];
    origRecipeTypesList = []; //Donde se va a guardar la lista original de tipos de receta
    origFlavorsList = []; //Donde se va a guardar la lista original de sabores
    origGrainsList = []; //Donde se va a guardar la lista original de granos
    origMilksList = []; //Donde se va a guardar la lista original de leches
    origPresentationList = []; //Donde se va a guardar la lista original de presentaciones

    // Variables donde se almacenaran los valores seleccionados en el select multi
    tempRecipeTypesList;
    tempPresentationList;
    tempMilkList;
    tempGrainList;
    tempFlavorsList;
    tempClassificationList;

    constructor(props){
        super(props);
        this.state = {
            recipes: [],
            filterText: "",
            recipe: null,
            pageRecipes: [],
            showModal: false,
            editing: false,
            removeModal: false,
            isLoading: false,
            syncModal: false,
            selectedRecipes: [],
            checkAll: false,
            syncMultiModal: false,
            // Variables sonde se almacenaran los criterios de busqueda definitivos
            recipeTypeList: [],
            selectedRecipeTypesList: [],
            presentationsList: [],
            selectedPresentationsList: [],
            milksList: [],
            selectedMilksList: [],
            grainsList: [],
            selectedGrainsList: [],
            flavorsList: [],
            selectedFlavorsList: [],
            classificationList: [],
            selectedClassificationList: [],
            manageToppingModal: false,
            addToppingModal: false,
            toppings: [],
            toppingError: false,
            menuRecetaModal: false,
            menuRecetaModalTitle: "",
            menuRecetaModalBody: "",
            menuRecetaCurrent: null
        }
    }

    componentDidMount(){
        this.setState({isLoading: true})
        let state = {...this.state};
        RecipesAPI.getAll().then(response => {
            state.recipes = response.data
            this.recipes = lodash.cloneDeep(response.data);

            // Obtener todos los tipos de recetas
            return RecipeTypesAPI.getAll()
        }).then(response => {
            let temp = [];
            
            response.data.map(obj => {
                if(obj.RecipeName) {
                    temp.push({value: obj.RecipeTypeId, label: obj.RecipeName})
                }else{
                    temp.push({value: obj.RecipeTypeId, label: obj.Description})
                }
            })
            temp.sort(this.compare); // Ordenar los labels para elegir
            state.recipeTypeList = temp;
            this.origRecipeTypesList = lodash.cloneDeep(temp)
            
            // Obtener todas las presentaciones
            return PresentationsAPI.getAll()
        }).then(response => {
            let tempPresentationList = [];
            response.data.map(obj => {
                tempPresentationList.push({value: obj.PresentationId, label: obj.Name})
            })
            tempPresentationList.sort(this.compare); // Ordenar los labels
            state.presentationsList = tempPresentationList
            this.origPresentationList = lodash.cloneDeep(tempPresentationList)

            // Obtener los tipos de leche
            return MilksAPI.getAll()
        }).then(response => {
            let tempMilkList = [];
            response.data.map(obj => {
                tempMilkList.push({value: obj.MilkTypeId, label: obj.Description})
            })
            tempMilkList.sort(this.compare); // Ordenar los labels
            state.milksList = tempMilkList
            this.origMilksList = tempMilkList
            
            // Obtener los tipos de grano
            return GrainsAPI.getAll()
        }).then(response => {
            let tempGrainList = [];
            response.data.map(obj => {
                tempGrainList.push({value: obj.GrainTypeId, label: obj.Description})
            })
            tempGrainList.sort(this.compare); // Ordenar los labels
            state.grainsList = tempGrainList
            this.origGrainsList = lodash.cloneDeep(tempGrainList)
            
            // Obtener los sabores
            return FlavorsAPI.getAll()
        }).then(response => {
            let tempFlavorsList = [];
            response.data.map(obj => {
                tempFlavorsList.push({value: obj.FlavorTypeId, label:obj.Name})
            })
            tempFlavorsList.sort(this.compare); // Ordenar los labels        
            state.flavorsList = tempFlavorsList
            this.origFlavorsList = tempFlavorsList
            
            // Obtener las clasificaciones
            return ClasificacionesAPI.getAll()
        }).then(response => {
            let tempClassificationList = [];
            response.data.map(obj => {
                tempClassificationList.push({value: obj.ClassificationId, label: obj.Name})
            })
            tempClassificationList.sort(this.compare); // Ordenar los labels
            state.classificationList = tempClassificationList
            
            // Guardar el estado
            this.setState(state)
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    }

    /**
     * Funcion para ordenar lexicograficamente
     * @param objA. primer operando
     * @param objB. segundo operando
     */
    compare = (objA, objB) => {
        // Use toUpperCase() to ignore character casing
        const labelA = objA.label.toUpperCase();
        const labelB = objB.label.toUpperCase();

        let comparison = 0;
        if (labelA > labelB) {
            comparison = 1;
        } else if (labelA < labelB) {
            comparison = -1;
        }
        return comparison;
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let state = this.state;
        state.filterText = text;
        this.setState(state, this.handleSearchMultipleFieldInput);
    }

    /**
     *Realizar el filtrado sobre las recetas para desplegar solo los elementos
     *que cumplen con las caracteristicas que el usuario ingreso.
     * 
     *@param: nada
     *@return: nada
     */
    handleSearchMultipleFieldInput = () => {
        let recipes = this.recipes.filter(
            recipe => ( 
                (this.state.selectedRecipeTypesList.length > 0 ? 
                this.state.selectedRecipeTypesList.includes(recipe.RecipeTypeId) : 
                true) &&
                (this.state.selectedFlavorsList.length > 0 ? 
                this.state.selectedFlavorsList.includes(recipe.FlavorTypeId) : 
                true) &&
                (this.state.selectedPresentationsList.length > 0 ? 
                this.state.selectedPresentationsList.includes(recipe.PresentationId) : 
                true) &&
                (this.state.selectedMilksList.length > 0 ? 
                this.state.selectedMilksList.includes(recipe.MilkTypeId) : 
                true) &&
                (this.state.selectedGrainsList.length > 0 ? 
                this.state.selectedGrainsList.includes(recipe.GrainTypeId) : 
                true) &&
                (this.state.selectedClassificationList.length > 0 ? 
                this.state.selectedClassificationList.includes(recipe.ClassificationId) : 
                true) &&
                (this.state.filterText.trim() !== '' ?
                (recipe.ShortName ? 
                    recipe.ShortName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) 
                        : false 
                    || recipe.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.filterText.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                : true)
            )
        );
        
        let state = {...this.state};
        state.recipes = lodash.cloneDeep(recipes);

        this.setState(state);
    }

    create = () => {
        this.props.history.push('/recetas/nueva');
    }

    edit = (recipe) => {
        //Editar
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de receta
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleRecipeTypeChange = (arr) => {
        let state = {...this.state}
        let tempSelectedRecipeTypesList = [];
        arr.map(obj => {
            tempSelectedRecipeTypesList.push(obj.value);
        })

        state.selectedRecipeTypesList = tempSelectedRecipeTypesList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de sabores
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleFlavorChange = (arr) => {
        let state = {...this.state}
        let tempSelectedFlavorList = [];
        arr.map(obj => {
            tempSelectedFlavorList.push(obj.value);
        })

        state.selectedFlavorsList = tempSelectedFlavorList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre las presentaciones
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handlePresentationChange = (arr) => {
        let state = {...this.state}
        let tempPresentationList = [];
        arr.map(obj => {
            tempPresentationList.push(obj.value);
        })

        state.selectedPresentationsList = tempPresentationList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de leche
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleMilkChange = (arr) => {
        let state = {...this.state}
        let tempMilksList = [];
        arr.map(obj => {
            tempMilksList.push(obj.value);
        })

        state.selectedMilksList = tempMilksList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre los tipos de granos.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleGrainChange = (arr) => {
        let state = {...this.state}
        let tempGrainList = [];
        arr.map(obj => {
            tempGrainList.push(obj.value);
        })

        state.selectedGrainsList = tempGrainList;
        state.selectedRecipes = [];
        state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
        });
    }

    /**
     *Modificar la lista de IDs con los que el usuario ha seleccionado. Aplica sobre las clasificaciones.
     * 
     *@param: arr: arreglo de valores que crea el select multi de react
     *@return: nada
     */
    handleClassificationChange = (arr) => {
        let state = {...this.state}
        let tempClassificationList = [];
        arr.map(obj => {
            tempClassificationList.push(obj.value);
        })

        state.selectedClassificationList = tempClassificationList;
        state.selectedRecipes = [];
        // state.pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false);

        this.setState(state, () => {
            this.handleSearchMultipleFieldInput();
            this.filterOptionsByClasification();
        });
    }

    /**
     * Filtra las opciones de los filtros(hehe) segun la clasificacion seleccionada.
     * Por ejemplo, seleccionó la Familia "Chocolate" entonces en el filtro de tipo de receta solo me tiene que aparecer "Caliente" y "Frio"
     */
    filterOptionsByClasification = () => {
        console.log("Filtrando filtros! (☞ﾟヮﾟ)☞")
        let clasificaciones = this.state.selectedClassificationList
        if(clasificaciones.length > 0 ){
            let stateFiltros = {}
            // Filtrar los tipos de receta
            RecipeTypesAPI.getByClasification(clasificaciones).then(response => {
                let temp = response.data.map(obj => {
                    return {value: obj.RecipeTypeId, label: obj.RecipeName ? obj.RecipeName : obj.Description}
                })
                stateFiltros.recipeTypeList = temp
                
                // Filtrar los sabores
                return FlavorsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempFlavorsList = response.data.map(obj => {
                    return {value: obj.FlavorTypeId, label:obj.Name}
                })
                tempFlavorsList.sort(this.compare); // Ordenar los labels        
                stateFiltros.flavorsList = tempFlavorsList
                
                // Filtrar los granos de cafe
                return GrainsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempGrainList = response.data.map(obj => {
                    return {value: obj.GrainTypeId, label: obj.Description}
                })
                tempGrainList.sort(this.compare); // Ordenar los labels
                stateFiltros.grainsList = tempGrainList
                
                // Filtrar los tipos de leche
                return MilksAPI.getByClasification(clasificaciones)
            })
            .then(response => {
                let tempMilkList = response.data.map(obj => {
                    return {value: obj.MilkTypeId, label: obj.Description}
                })
                tempMilkList.sort(this.compare); // Ordenar los labels
                stateFiltros.milksList = tempMilkList
                
                // Filtrar las presentaciones
                return PresentationsAPI.getByClasification(clasificaciones)
            }).then(response => {
                let tempPresentationList = response.data.map(obj => {
                    return {value: obj.PresentationId, label: obj.Name}
                })
                tempPresentationList.sort(this.compare); // Ordenar los labels
                stateFiltros.presentationsList = tempPresentationList

                this.setState(stateFiltros)
            })
        }else{
            this.setState({
                recipeTypeList: lodash.cloneDeep(this.origRecipeTypesList),
                flavorsList: lodash.cloneDeep(this.origFlavorsList),
                grainsList: lodash.cloneDeep(this.origGrainsList),//Donde se va a guardar la lista original de granos
                milksList: lodash.cloneDeep(this.origMilksList),//Donde se va a guardar la lista original de leches
                presentationList: lodash.cloneDeep(this.origPresentationList)//Donde se va a guardar la lista original de presentaciones
            })
        }
    }

    handleSelectChange = (objFilters) => {
        this.setState(objFilters, () => {this.handleSearchMultipleFieldInput();})
    }

    handleNewFeatured = (recipeId) => {
        RecipesAPI.newFeatured(recipeId)
        .then(result => {
            toast.success("Producto destacado creado correctamente");
        })
        .catch(error => {
            if(error){
                toast.error(error.response ? error.response.data.Message : "Error al guardar nuevo destacado.");
            }
            this.setState({menuRecetaModalBody:"Error al guardar nuevo destacado.", isLoading: false})
        })
    }

    remove = () => {
        this.setState({isLoading: true});
        RecipesAPI.delete(this.state.recipe.RecipeId).then(response => {
            let state = {...this.state};

            state.recipes.splice(state.recipes.findIndex(c => c.RecipeId === state.recipe.RecipeId), 1);
            state.pageRecipes.splice(state.pageRecipes.findIndex(c => c.RecipeId === state.recipe.RecipeId), 1);
            state.isLoading = false;
            state.removeModal = false;
            state.recipe = null;

            this.setState(state, () => toast.success("Receta eliminada"));
        }).catch(err => {
            if(err){
                this.setState({isLoading: false, recipe: null, removeModal: false});
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            }
        });
    }

    /**
     * Genera un movimiento de sincronizacion a los drives que tienen la receta seleccionada en su menu
     */
    syncRecipe = () => {
        this.setState({isLoading: true});
        RecipesAPI.sync(this.state.recipe.RecipeId).then(response => {
            this.setState({syncModal: false, isLoading: false, recipe: null}, () => toast.success("Orden de sincronización generado"));
        }).catch(err => {
            if(err){
                this.setState({isLoading: false, recipe: null, syncModal: false});
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            }
        });
    }

    /**
     * Genera un movimiento de sincronizacion a los drives que tienen almenos una de las recetas seleccionadas en su menu
     */
    syncRecipeMulti = () => {
        this.setState({isLoading: true});
        RecipesAPI.syncMulti(this.state.selectedRecipes).then(response => {
            let _pageRecipes = [...this.state.pageRecipes]
            _pageRecipes.forEach(pageRecipe => pageRecipe.statusCheck = false)
            this.setState({syncMultiModal: false, isLoading: false, selectedRecipes: [], pageRecipes: _pageRecipes}, () => toast.success("Orden de sincronización generado"));
        }).catch(err => {
            if(err){
                this.setState({isLoading: false, syncMultiModal: false});
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            }
        });
    }

    /**
     * Funcion que se ejecuta al darle click al checkbox del header
     */
    selectAllCheck = () => {
        let _checkAll = !this.state.checkAll
        let _selectedRecipes = [...this.state.selectedRecipes]
        let _pageRecipes = this.state.pageRecipes
        _pageRecipes.forEach(recipe => {
            let indexRecipe = _selectedRecipes.findIndex(item => item === recipe.RecipeId)
            recipe.statusCheck = _checkAll
            if(_checkAll){
                if(indexRecipe === -1) _selectedRecipes.push(recipe.RecipeId)
            }else{
                if(indexRecipe !== -1) _selectedRecipes.splice(indexRecipe, 1)
            }

        })

        this.setState({checkAll: _checkAll, selectedRecipes: _selectedRecipes})
    }

    /**
     * Funcion que se ejecuta al cambiar la pagina del tablero
     */
    changeRecipePage = (recipes) => {
        let recipesChecked = 0
        let checkAll = false
        recipes.forEach(item => {
            if(this.state.selectedRecipes.findIndex(id => id === item.RecipeId) !== -1)
                recipesChecked++
        })
        if(recipesChecked === recipes.length) checkAll = true 

        this.setState({ pageRecipes: recipes, checkAll: checkAll })
    }

    /*
    * Funcion que selecciona o des selecciona todas las 
    * recetas de todas las paginas.
    */
    selectAllCheckBox = () => {
        let state = {...this.state};
        let elementsPage = state.recipes
        let selectedRecipes = []
        // Le asignamos el valor inverso de checkAll a todas las recetas actuales
        elementsPage.map(element=>{
            if(state.checkAll){
                element.statusCheck = false 
            }else{
                element.statusCheck = true
                selectedRecipes.push(element.RecipeId)
            }

            return element
        })
        state.checkAll = !state.checkAll;
        state.recipes = elementsPage;
        state.selectedRecipes = selectedRecipes
        this.setState(state);
    }

    /**
     * Función que checa que las cantidades de los toppings esten correctas.
     */
    checkForToppingErrors = () => {
        let errorTopping = false;
        this.state.toppings.findIndex(topping => {
            if(topping.MinQuantity > topping.MaxQuantity){
                errorTopping = true
                return true
            }
            return false
        })
        this.setState({toppingError: errorTopping})
    }

    /**
     * Función que realiza petición al server para añadir los toppings que estan en this.state.toppings a los toppings seleccionados
     */
    addToppingsToRecipes = () => {
        // Primero hay que crear el payload. En este caso sera una coleccion de {RecipeId: X, Toppings: [toppings en el estado]}
        let payloadRecipes = this.state.selectedRecipes.map(recipeId => {
            return {RecipeId: recipeId, Toppings: [...this.state.toppings]}
        })

        // Hacer la peticion
        RecipesAPI.addToppings(payloadRecipes)
        .then(result => {
            this.setState({manageToppingModal: false, toppings: [], toppingError: false, isLoading: false}, () => toast.success("Topping(s) agregado(s) exitosamente."))
        })
        .catch(error => {
            if(error){
                toast.error(error.response ? error.response.data.Message : "Error al agregar topping(s).");
                this.setState({isLoading: false});
            }
        })
    }

    render() {
        return (
            <div>

                <ModoGuiado 
                    ruta="recetas"
                    tipoModoGuiado="receta"
                    anterior="toppings"
                    mensaje="Configuración de un producto terminado agrupado por diferentes variables" />

                <h1 className="title is-2">Producto Destacado</h1>


                {/* Filtro multiple para recetas */}
                <div className="card">
                    <div className="card-content">
                        <div className="columns is-multiline">
                            
                            <RecipeFilters
                                onChange = {(objFilters) => {this.handleSelectChange(objFilters)}}
                            />
                        </div>
                    </div>
                </div>

                <br />
                
                      
                <div className="card">
                    <div className="card-content">
                        {/* Botones del header de la tabla */}
                        <div className="columns">
                            {/* Todos los resultados */}
                            <div className="column" style={{display: "flex"}}>
                            </div>

                        </div>
                        {this.state.recipes.length !== 0 
                        ? <TableTemplate
                            columns={["RecipeId", "Description", "ShortName"]}
                            columnsNames={["ID", "Descripción", "Nombre Corto"]}
                            data={this.state.pageRecipes}

                        
                            //Función que se ejecuta al renderizar un renglon de la tabla. La tabla te devuelve el objeto que representa al renglon.
                            extraButtons={(row) => {
                                // Creamos un botón que lanza un modal con información de que menus esta la receta seleccionada
                                return <button className="button tableAction is-success tooltip" data-tooltip="Guardar como producto destacado"
                                onClick={(e) => {
                                    e.stopPropagation();
                                     this.handleNewFeatured(row.RecipeId)
                                }}
                                >
                                            <i className="fa fa-check-square-o"></i>
                                        </button>
                            }}
                            rowClickable={false}
                            details=''
                        /> 
                        : <NoItems itemName="recetas"/>}
                        <Pagination items={this.state.recipes} onChangePage={(recipes) => this.changeRecipePage(recipes) } />
                    </div>
                </div>
                
              
                {/* Modales */}
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove()}
                    cancel={() => this.setState({removeModal: false})}
                    modalTitle={"Eliminar Receta"}
                />}

                {this.state.syncModal && <AcceptModal 
                    isActive={this.state.syncModal}
                    done={() => this.syncRecipe()}
                    cancel={() => this.setState({syncModal: false})}
                    modalTitle={"Sincronizar " + this.state.recipe.Description + "?"}
                />}

                {this.state.syncMultiModal && <AcceptModal 
                    isActive={this.state.syncMultiModal}
                    done={() => this.syncRecipeMulti()}
                    cancel={() => this.setState({syncMultiModal: false})}
                    modalTitle={"Sincronizar recetas seleccionadas?"}
                />}

                {/* Modal para consultar menus */}
                {this.state.menuRecetaModal && <Modal 
                    showModal={this.state.menuRecetaModal}
                    cancel={() => this.setState({menuRecetaModal: false})}
                    modalTitle={this.state.menuRecetaModalTitle}
                >
                    {this.state.menuRecetaModalBody}
                </Modal>}

                {/* Modal de gestion de toppings */}
                {this.state.manageToppingModal && <Modal
                    showModal={this.state.manageToppingModal}
                    modalTitle={"Gestionar toppings"}
                    cancel={() => this.setState({manageToppingModal: false, toppings: [], toppingError: false})}
                    footer={true}
                    footerButtons={
                        <div style={{display: "flex", width: "100%", justifyContent: "flex-end"}}>
                            <button 
                                className="button is-success"
                                disabled={this.state.toppings.length === 0 || this.state.toppingError}
                                onClick={() => {
                                    this.setState({isLoading: true}, this.addToppingsToRecipes())
                                }}>
                                Confirmar
                            </button>
                        </div>
                    }
                >
                    <div>
                        <h1 className="subtitle has-text-weight-bold">
                            Agregar topping
                            &nbsp;
                            <a className="has-text-black" onClick={() => this.setState({addToppingModal: true})}>
                                <span className="icon is-small">
                                    <i className="fa fa-plus"></i>
                                </span>
                            </a>
                        </h1>
                    </div>
                    {/* <RecipeToppings 
                        details={this.state.toppings}
                        quantityChanged={(value, index, labelQuantity) => {    
                            let toppings = [...this.state.toppings];
                            toppings[index][labelQuantity] = value;
                            this.setState({toppings: toppings}, this.checkForToppingErrors());
                        }}
                        removeDetail={(indexTopping) => {
                            let toppings = [...this.state.toppings];
                            toppings.splice(indexTopping,1);
                            this.setState({toppings: toppings}, () => {
                                toast.success("Topping eliminado");
                                this.checkForToppingErrors()
                            });
                        }}
                    /> */}
                </Modal>}

                {/* Modal para agregar toppings */}
                {this.state.addToppingModal && 
                    <Modal
                        showModal={this.state.addToppingModal}
                        modalTitle={"Agregar topping"}
                        cancel={() => this.setState({ addToppingModal: false })}
                    >
                        {/* <NewRecipeDetail
                            addDetail={(detail) => {
                                let toppings = [...this.state.toppings];
                                
                                toppings.push({
                                    CodeProvider: detail.CodeProvider,
                                    Name: detail.Name,
                                    InventoryItemId: detail.InventoryItemId,
                                    MinQuantity: detail.MinQuantity || 0,
                                    MaxQuantity: detail.MaxQuantity || 0
                                });
                                this.setState({toppings: toppings});
                            }}
                            currentDetails = {this.state.toppings.map(det => det.InventoryItemId)}
                            soloToppings={true}
                            isTopping={true}
                        /> */}
                    </Modal>
                }

                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />                

            </div>
        );
    }
}

export default newFeatured;
