import React, { useState, useEffect } from 'react';
import moment from 'moment';
import NotificacionAPI from '../../../../../../services/Caffenio App/Notificacion';
import to from '../../../../../../util/to';
import Loading from '../../../../../util/loading/Loading';
import NoItems from '../../../../../util/noitems/NoItems';
import ServerPagination from '../../../../../util/pagination/ServerPagination';

export default function TablaHistorial({notification, probando}){
    const [columnNames, setColumnNames] = useState(["Fecha de envío", "Hora", "Prueba","Notificaciones generadas", "Notificaciones envíadas", "Estatus"]);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paginationInfo, setPaginationInfo] = useState({totalPages: 0, currentPage: 1})

    useEffect(() => {
        if (notification && !probando){
            getEvents();
        }
    }, [notification, probando])

    const getEvents = async () => {
        var err, data;
        [err, data] = await to(NotificacionAPI.getNotificationEventsHistory(notification.NotificationId, paginationInfo.currentPage));
        setEvents(data.Results);
        setPaginationInfo(info => ({...info, totalPages: data.TotalPages}));
    }

    useEffect(() => {
        getEvents();
    }, [paginationInfo.currentPage])

    return (
        <div className="card">
            <div className="card-content">
                {events.length > 0 ? <table className="table is-fullwidth">
                    <tr>
                        {columnNames.map(c => <th>{c}</th>)}
                    </tr>
                    {events.map(d => <tr>
                        <td>{moment(d.DateExecution).format('DD/MM/YYYY')}</td>
                        <td>{moment(d.DateExecution).format('HH:mm')}</td>
                        <td>{d.Test ? "Sí" : "No"}</td>
                        <td>{d.TotalNotifications}</td>
                        <td>{d.TotalPushSent}</td>
                        <td>{d.DescriptionStatus}</td>
                    </tr>)}
                </table>
                : loading ? <Loading/>
                : <NoItems itemName="eventos previos"/>}
                <ServerPagination
                    totalPages={paginationInfo.totalPages}
                    currentPage={paginationInfo.currentPage}
                    goToPage={(page) => setPaginationInfo((info) => ({...info, currentPage: page}))}
                />
            </div>
        </div>
    )
}