import React, { useState, useEffect } from 'react';

// Components
import Modal from '../util/modal/Modal';
import Loader from '../util/loading/Loading';
import Field from '../util/field/Field';

// Utils
import { toast } from 'react-toastify';

// Services
import SucursalAPI from '../../services/Sucursales';

/**
 * Componente modal para editar o crear sucursales.
 * @param { Function } onClose - Funcion para cerrar el modal 
 * @returns 
 */
export const ModalSucursal = ({ sucursal, onClose, onUpdate }) => {

    const [ state, setState ] = useState({
        nombre: '',
        estatus: false,
        isLoading: false,
    });

    useEffect(() => {
        if ( sucursal !== undefined && JSON.stringify(sucursal) !== '{}' ) {
            setState({
                ...state,
                nombre: sucursal.Nombre,
                estatus: sucursal.Estatus
            });
        }
    }, [ sucursal ]);

    /**
     * Guarda la información de la sucursal.
     */
    const handleSaveInfo = () => {
        if ( state.nombre === '' )
            return toast.warn('Se debe ingresar un nombre para la sucursal.');

        const dataToSave = {
            Nombre: state.nombre.trim(),
            Estatus: state.estatus
        };

        setState({ ...state, isLoading: true });
        if ( sucursal.idSucursal !== undefined ) {
            SucursalAPI.updateSucursal({ ...sucursal, ...dataToSave })
                .then( resp => onUpdate( resp.data ) )
                .catch( err => {
                    let message = 'Ocurrio un error';
                    try {
                        message = err.response.data.Message;
                    } catch (error) {
                        message = 'No se pudo actualizar la sucursal';
                    } finally {
                        toast.error(message);
                        setState({ ...state, isLoading: false });
                    }
                });
        } else {
            SucursalAPI.createSucursal({ ...dataToSave })
                .then( resp => onUpdate( resp.data ) )
                .catch( () => {
                    setState({ ...state, isLoading: false });
                    toast.error('Ocurrio un problema al actualizar la sucursal');
                });
        }

    }

    return (
        <Modal
            showModal={true}
            modalTitle="Editar Sucursal"
            cancel={() => onClose()}
        >
            { state.isLoading &&
                <Loader
                    isFullscreen={true}
                    isLoading={state.isLoading}
                    width='100px'
                    height='100px'
                />
            }
            <div className='row'>
                <div className='columns'>
                    <div className='column is-12'>
                        <label className='label' for='nombre'>Nombre de la sucursal</label>
                        <input
                            className='input'
                            type='text'
                            maxLength='50'
                            value={state.nombre}
                            placeholder='Nombre...'
                            name='nombre'
                            onChange={({ target }) => setState({ ...state, nombre: target.value })}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='columns'>
                    <div className='column is-12'>
                        <label className='label' for='nombre'>Punto de Venta</label>
                        <p>{sucursal.NombreNuevo}</p>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='columns'>
                    <div className='column is-12'>
                        <Field label='Estatus' isHorizontal={true}>
                            <input
                                className='switch'
                                type='checkbox'
                                checked={state.estatus}
                                name='estatus'
                                onChange={() => {}}
                            />
                            <label for='estatus' onClick={() => setState({...state, estatus: !state.estatus})}/>
                        </Field>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='columns'>
                    <div className='column is-12 is-flex' style={{justifyContent: 'flex-end'}}>
                        <button onClick={handleSaveInfo} className='button is-success'>
                            Guardar Sucursal
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}