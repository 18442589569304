let ErrorMessages  = {
    name: "Nombre debe de ser mayor de 2 y menor de 30 caracteres",
    smallName: "Nombre debe de ser mayor de 2 y menor de 25 caracteres",
    smallestName: "Nombre debe de ser mayor de 2 y menor de 20 caracteres",
    shortName: "Nombre Corto incorrecto, debe de ser mayor de 2 y menor de 15 caracteres",
    menuPosition: "Posición en Menú incorrecto, debe ser un valor numérico",
    menuPositionMax: "Ingrese una Posición en el Menú menor a 256",
    recipeName: "Nombre de Receta incorrecto, debe de ser mayor de 2 y menor de 30 caracteres",
    smallRecipeName: "Nombre de Receta incorrecto, debe de ser mayor de 2 y menor de 25 caracteres",
    smallestRecipeName: "Nombre de Receta incorrecto, debe de ser mayor de 2 y menor de 20 caracteres",
    codeProvider: "El Código debe de ser de al menos 9 caracteres",
    urlIcono: "URL de icono debe de ser mayor a 5 caracteres",
    SinConexion: "Error de conexión. Intente de nuevo.",
    ErrorInesperado: "Ocurrió un error inesperado, intente de nuevo."  , 
    AlfanumericoSinEspecialesError: "Ingrese solo caracteres alfanuméricos",
    AalfanumericoSinEspaciosError: "Ingrese solo caracteres alfanuméricos, sin acentos, espacios, ni caracteres especiales.",
    AlfanumericoConCaracteresError: "EL campo permite ingresar caracteres alfanuméricos, guion(-), punto(.), parentesis, punto y coma(;), coma(,) o barra(/).",
    SoloNumerosError: "Ingrese solo números enteros y mayores a cero.", 
    PngFormatError:"Solo se permite formato .PNG.",
    PngJpegFormatError:"Solo se permite formato .PNG o .JPG.",
}

export default ErrorMessages;
