import {apiUrl} from '../constants';
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class FichaDepositoAPI{
    static getByDate(fechas){
        return request.post('/FichaDeposito/GetByDate', fechas);
    }
}