import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import NotificacionAPI from '../../../services/Caffenio App/Notificacion';
import to from '../../../util/to';
import { toast } from 'react-toastify';
require('moment/locale/es');
const localizer = momentLocalizer(moment)

export default function Agenda({history}){
    const [events, setEvents] = useState([]);
    const today = moment.now();
    const [monthOffset, setMonthOffset] = useState(0)
    const navigateContants = {
        PREVIOUS: 'PREV',
        NEXT: 'NEXT',
    };

    useEffect(() => {
        let day = moment(today).add(monthOffset, 'months');
        let start = moment(day).startOf('month')
        let end = moment(day).endOf('month')
        console.log(start, end);
        getData(start, end);
    }, [monthOffset])

    const getData = async (start, end) => {
        let err, eventos;
        [err, eventos] = await to(NotificacionAPI.getEvents(start, end));
        if (err){
            toast.error(err.response.data.Message);
        }else{
            setEvents(eventos);
        }
    }

    return (
        <div>
            <h1 className="title is-2">Agenda</h1>
            <Calendar
                    localizer={localizer}
                    events={events}
                    popup
                    startAccessor="start"
                    endAccessor="end"
                    style={{height: 700}}
                    views={{ month: true }}
                    culture='es'
                    onSelectEvent={(event) => {
                        history.push(`/app/notificaciones/${event.notificationId}/detalle`);
                    }}
                    eventPropGetter={(event, start, end, isSelected) => {
                        var backgroundColor = event.hexColor;
                        var style = {
                            backgroundColor: today > moment(event.end) ? '#ccc' : backgroundColor,
                            opacity: 0.8,
                            color: event.textColor,
                            border: '1px',
                            display: 'block'
                        };
                        return {
                            style: style
                        };
                    }}
                    components={{ toolbar: (props) => {
                        return (
                            <div className="columns">
                                <div className="column is-3">
                                    <button className={"button tooltip"} data-tooltip={"Mes anterior"} onClick={(e) => {
                                            e.stopPropagation();
                                            setMonthOffset(monthOffset - 1)
                                            props.onNavigate(navigateContants.PREVIOUS);
                                        }}>
                                            <span className="icon">
                                                <i className="fa fa-arrow-left"></i>
                                            </span>
                                            <span>Anterior</span>
                                    </button>
                                </div>
                                <div className="column is-6">
                                    <p>{props.label.charAt(0).toUpperCase() + props.label.slice(1)}</p>
                                    
                                </div>
                                <div className="column is-3">
                                    <button className={"button tooltip"} data-tooltip={"Mes siguiente"} onClick={(e) => {
                                            e.stopPropagation();
                                            setMonthOffset(monthOffset + 1)
                                            props.onNavigate(navigateContants.NEXT);
                                        }}>
                                            <span>Siguiente</span>
                                            <span className="icon">
                                                <i className="fa fa-arrow-right"></i>
                                            </span>
                                    </button>
                                </div>
                            </div>
                        )
                    }, event: ({event}) => {
                        return (<span>{`${event.title} `}</span> )
                    }
                    ,dateCellWrapper: ({children, value}) => {
                        return (
                            React.cloneElement((children), {
                                style: {
                                    ...children.style,
                                    backgroundColor: value < today ? '#eeeeff' : 'white',
                                    opacity: value < today ? 0.5 : 1
                                },
                            })
                        )
                    }
                }}/>
        </div>
    )
}