import { url, caffenioAuthId }  from "../constants";
import axios from 'axios';
import * as querystring from 'querystring';

// Objecto de axios con el único fin de realizar la autentificación con CaffenioAuth
var request = axios.create({
    baseURL: url,
    headers: {'Content-Type':'application/x-www-form-urlencoded'}
})

// Llamadas a la API para la autentificación
class Auth {
    static login = (username, password) => {
        let data = {
            username: username,
            password: password,
            grant_type: "password",
            client_id: caffenioAuthId
        };
        return request.post('/login',querystring.stringify(data));
    }
}

export {Auth};