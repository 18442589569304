import { apiUrl }  from "../constants";
import axios from 'axios';
import middleware from '../middleware/auth';

var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class PresentationsAPI {
    static getAll() {
        return middleware.hasAuth(() => request.get('/Presentation'));
    }

    static get(id) {
        return middleware.hasAuth(() => request.get(`/Presentation/${id}`));
    }

    static getByClasification(ids){
        return middleware.hasAuth(() => request.post(`/Presentation/GetByClassification`, ids));
    }

    static create(Presentation) {
        let fd = new FormData();
        fd.append("Name", Presentation.Name);
        fd.append("Position", Presentation.Position);
        fd.append("RecipeName", Presentation.RecipeName);
        fd.append("Active", Presentation.Active)
        fd.append("SegmentId", Presentation.SegmentationId.SegmentId)
        fd.append("NombreApp", Presentation.NombreApp)
        Presentation.Image instanceof File && fd.append("Image", Presentation.Image);
        return middleware.hasAuth(() => request.post('/Presentation', fd));
    }

    static edit(Presentation, id){
        let fd = new FormData();
        fd.append("PresentationId", Presentation.PresentationId);
        fd.append("Name", Presentation.Name);
        fd.append("Position", Presentation.Position);
        fd.append("RecipeName", Presentation.RecipeName);
        fd.append("Active", Presentation.Active)
        fd.append("NombreApp", Presentation.NombreApp)
        Presentation.idSegmento && fd.append("SegmentId", Presentation.idSegmento)
        
        Presentation.Image instanceof File && fd.append("Image", Presentation.Image);

        return middleware.hasAuth(() => request.put(`/Presentation`, fd));
    }

    static delete(id){
        return middleware.hasAuth(() => request.delete(`Presentation/${id}`));
    }
}