import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ExpresionesRegulares from '../../../../util/expresiones-regulares'

const RecipeDetails = (props) => {
    return (
        <div className={(props.details != '' && props.details.length > 0) ? "" : "recipe-details-empty"}>
            <table className="table is-fullwidth is-striped">
                {props.details.length > 0 &&
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nombre</th>
                            <th>Cantidad</th>
                            <th>Unidad</th>
                            <th>Acción</th>
                           {props.mostrarDrives && <th>Drive</th>}
                        </tr>
                    </thead>
                }
                <tbody>
                    {props.details != '' && props.details.length > 0 
                    ? props.details.map((detail, i) => {
                        // Para el Detalle de Receta
                        return (
                            <tr key={i}>
                                <td>{detail.CodeProvider}</td>
                                <td>{detail.Name}</td>
                                <td>
                                    <input
                                        className="input"
                                        placeholder="Cantidad"
                                        value={detail.Amount}
                                        type="number"
                                        maxLength="5"
                                        step=".1"
                                        //min="0"
                                        max="999"
                                        onKeyPress={(event) => {
                                            const keyCode = event.keyCode || event.which;
                                            const keyValue = String.fromCharCode(keyCode);
                                            // if (/\+|-|[a-zA-Z]|\d+(\.\d{3,})|\d{4,}+(\.\d)/.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
                                            if (ExpresionesRegulares.ErrorInputNumDecimal.test(keyValue) || event.target.value.length >= event.target.getAttribute('maxLength'))
                                                event.preventDefault();
                                        }}
                                        onChange={(e) => props.quantityChanged(e.currentTarget.value, i)}
                                    />
                                </td>
                                <td>{detail.UnitSaleStr}</td>
                                <td>
                                    <a className="button tableAction is-danger tooltip"
                                        data-tooltip="Eliminar"

                                        onClick={(e) => {
                                            e.stopPropagation();
                                            props.removeDetail(i)
                                        }
                                        }>
                                        <span className="icon">
                                            <i className="fa fa-trash" />
                                        </span>
                                    </a>
                                    {
                                        detail.driveList && detail.driveList.length > 1 &&
                                        <a className="button tableAction is-info tooltip"
                                            data-tooltip="Consultar Drives"

                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.viewDrivesDetail(detail, i)
                                            }
                                            }>
                                            <span className="icon">
                                                <i className="fa fa-info" />
                                            </span>
                                        </a>
                                    }
                                </td>
                                {props.mostrarDrives && <td>{detail.DriveNombre ? detail.DriveNombre : ""}</td>}
                            </tr>
                        )
                    }) : <tr>
                            <td>No hay artículos inventario.</td>
                        </tr>}
                </tbody>
            </table>
        </div>
    )
}

RecipeDetails.propTypes = {
    details: PropTypes.array.isRequired,
    quantityChanged: PropTypes.func.isRequired,
    removeDetail: PropTypes.func.isRequired,
    mostrarDrives: PropTypes.bool,
    onMostrarDrives: PropTypes.func,
    viewDrivesDetail: PropTypes.func
}

RecipeDetails.defaultProps = {
    details: [],
    quantityChanged: () => { },
    removeDetail: () => { },
    mostrarDrives: false,
    onMostrarDrives: () => { },
    viewDrivesDetail: () => { }
}

export default RecipeDetails;
