import React, { useEffect, useState } from 'react';
import useForm from '../../../../util/hooks/useForm';
import { toast } from 'react-toastify';
import PromocionDataForm from './components/NuevoPrefijoCampanaDataForm';
import PromocionesAPI from '../../../../services/Promotions';

export default function NuevaPromocionForm({ history, location , id}) {
    const { inputs, setInput, errors, setError, replaceInputs, handleInputChange, handleSelectChange, handleSubmit } = useForm(() => {
        savePrefix();
    });
    const [working, setWorking] = useState(false);

    const savePrefix = async () => {
        if (!working) {
            setWorking(true);
            var response = PromocionesAPI.savePrefix({promoQRId:parseInt(id),prefix:inputs.prefixCampaign});
            response.then(res => {
                toast.success("Prefijo guardado exitosamente.");
                history.push({
                    pathname: '/app/promociones',
                    state: location.state ? { currentPage: location.state.currentPage || 0, name: location.state.name || "" } : { currentPage: 1, name: "" }
                });
            }).catch(err => {
                toast.error(err.response.data.Message)
            }).finally(() => setWorking(false))
        }
    }

    const inputsAreValid = () => {
        const prefixCampaignData = inputs.prefixCampaign && inputs.prefixCampaign.length>=3;
        return prefixCampaignData;
    } 

    return (
        <form onSubmit={handleSubmit} className="has-text-left" style={{ width: '100%' }}>
            <div className="columns is-multiline">
                <div className="column is-12">
                    <PromocionDataForm  inputs={inputs} errors={errors} setInput={setInput} setError={setError} handleInputChange={handleInputChange} />
                </div>
            </div>
            <div className="column is-12">
                <div className="field is-grouped is-grouped-right">
                    <p className="control">
                        <button
                            className="button"
                            type="button"
                            onClick={() => {
                                history.push({
                                    pathname: '/app/promociones',
                                    state: location.state ? { currentPage: location.state.currentPage || 0, name: location.state.name || "" } : { currentPage: 1, name: "" }
                                });
                            }}>
                            Regresar
                        </button>
                    </p>

                    <p className="control">
                        <button
                            type="submit"
                            className="button is-success"
                            disabled={!inputsAreValid() || working}
                        >
                            Asignar prefijo a campaña
                        </button>
                    </p>
                </div>
            </div>
        </form>
    );
}