import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import lodash from 'lodash';

/** Componente para visualizar los filtros de estado, ciudad y drives */
class SelectDriveForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //estados seleccionados
            selectedOption: [],
            //ciudades seleccionadas
            selectedOptionC: [],
            //drives seleccionados
            selectedOptionD: [],
            //options ciudades
            optionCity: [],
            //options drives
            optionDrive: []

        }
    }

    /**Función para setear os valores seleccionados por el usuario al state */
    changeSelectEstado = (value, itemName) => {
        let state = { ...this.state }
        state[itemName] = value;

        state.optionDrive = this.props.Caffenios.reduce((list, item) => {
            if (state.selectedOption.length === 0
                || state.selectedOption.some(o => o.value === item.StateId)) {
                if (state.selectedOptionC.length === 0
                    || state.selectedOptionC.some(o => o.Municipalityid === item.Municipalityid)) {
                    if (!list.some(l => l.idCaffenio === item.idCaffenio)) {
                        let newItem = { ...item };
                        newItem.value = item.idCaffenio;
                        newItem.label = item.Name;
                        list.push(newItem);
                    }
                }
            }

            return list;
        }, []);

        state.optionCity = this.props.Caffenios.reduce((list, item) => {
            if (state.selectedOption.length === 0
                || state.selectedOption.some(o => o.value === item.StateId)) {
                if (!list.some(l => l.Municipalityid === item.Municipalityid)) {
                    let newItem = { ...item };
                    newItem.value = item.Municipalityid;
                    newItem.label = item.Municipality;
                    list.push(newItem);
                }
            }
            return list;
        }, []);


        //Comparte con el padre los valores actuales de los listados
        this.setState(state, () => this.setValuesToParent());
    }

    /**Función para setear los valores al padre */
    setValuesToParent = () => {
        this.props.setStateFunc(this.state.selectedOption.length === 0
            ? this.props.optionState : this.state.selectedOption);
        this.props.setCityFunc(this.state.selectedOptionC.length === 0
            ? this.state.selectedOption.length === 0
                ? this.props.Caffenios
                : this.props.Caffenios.filter(c => this.state.selectedOption.some(o => o.value === c.StateId))
            : this.state.selectedOptionC);
        this.props.setDriveFunc(
            this.state.selectedOptionD.length === 0
                ? this.state.selectedOptionC.length === 0 && this.state.selectedOption.length === 0
                    ? this.props.Caffenios
                    : this.props.Caffenios.filter((item) => {
                        if ((this.state.selectedOption.some(o => o.value === item.StateId) || this.state.selectedOption.length === 0) &&
                            (this.state.selectedOptionC.some(o => o.Municipalityid === item.Municipalityid) || this.state.selectedOptionC.length === 0)) {
                            return item
                        }
                    })
                : this.state.selectedOptionD);
    }

    render() {
        return (
            <div className="column is-12">
                <div className="columns">
                    <div className="column is-3">
                        <label className="label">Estados</label>
                        <div className="control">
                            <Select
                                isMulti
                                id={"ddlEstados"}
                                placeholder={"seleccionar..."}
                                name={"selectedOption"}
                                value={this.selectedOption}
                                onChange={(val) => this.changeSelectEstado(val, "selectedOption")}
                                noOptionsMessage={() => "No hay opciones"}
                                options={lodash.sortBy(this.props.Caffenios.reduce((list,c)=>{
                                    if(!list.some(l=> l.value === c.StateId)){
                                        c.label = c.State;
                                        c.value = c.StateId;
                                        list.push(c);
                                    }
                                    
                                    return list;
                                },[]), [ts => ts.label.toLowerCase()], ['asc'])}
                            />
                        </div>
                    </div>
                    <div className="column is-3">
                        <label className="label">Ciudades</label>
                        <div className="control">
                            <Select
                                isMulti
                                id={"ddlCiudades"}
                                placeholder={"seleccionar..."}
                                name={"selectedOptionC"}
                                value={this.selectedOptionC}
                                onChange={(val) => this.changeSelectEstado(val, "selectedOptionC")}
                                noOptionsMessage={() => "No hay opciones"}
                                options={lodash.sortBy(this.state.optionCity, [ts => ts.label.toLowerCase()], ['asc'])}
                            />
                        </div>
                    </div>
                    <div className="column is-3">
                        <label className="label">Drives</label>
                        <div className="control">
                            <Select
                                isMulti
                                id={"ddlDrives"}
                                placeholder={"seleccionar..."}
                                name={"selectedOptionD"}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={this.state.selectedOptionD}
                                onChange={(val) => this.changeSelectEstado(val, "selectedOptionD")}
                                noOptionsMessage={() => "No hay opciones"}
                                options={lodash.sortBy(this.state.optionDrive, [ts => ts.label.toLowerCase()], ['asc'])}
                            />
                        </div>
                    </div>
                    <div className="column is 3">
                        <label className="label">&nbsp;</label>
                        <div className="field">
                            <div className="control has-text-right">
                                <a className="button is-success" onClick={(e) => this.props.create()}>
                                    <span className="icon is-small">
                                        <i className="fa fa-plus"></i>
                                    </span>
                                    <span>Agregar Lista de Precios</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SelectDriveForm.propTypes = {
    //listado de estados
    //optionState: PropTypes.arrayOf(PropTypes.object).isRequired,
    //listado de drives
    Caffenios: PropTypes.arrayOf(PropTypes.object).isRequired,
    //Funcion para compartir con el padre el estado seleccionado
    setStateFunc: PropTypes.func.isRequired,
    //Funcion para compartir con el padre las ciudades seleccionadas
    setCityFunc: PropTypes.func.isRequired,
    //Función para compartir con el padre los drives seleccionados
    setDriveFunc: PropTypes.func.isRequired,
    //Función para el create
    create: PropTypes.func.isRequired
}

export default SelectDriveForm;