import React, { useEffect, useState } from 'react';

/**
 * Componente para mostrar la información y roles de un usuario.
 * @returns Component
 */
export const UserInfo = () => {

    const [ state, setState ] = useState({
        nombre: '',
        roles: [],
        listVisible: false
    });

    /**
     * Hace el decode del token.
     * @param {String} token - Token guardado en localStorage
     * @returns Object
     */
    const parseJwt = token => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        
        return JSON.parse(jsonPayload);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const userInfo = parseJwt(token);
        setState({
            nombre: userInfo.fullname || '',
            roles: userInfo.role || []
        });
    }, []);

    return (
        <div style={{ maxWidth: '300px', marginRight: '10px' }}>
            <div className='row'>
                <h3
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '300px',
                        cursor: 'default'
                    }}
                    title={ state.nombre }
                >
                    @{ state.nombre }
                </h3>
            </div>
            <div className='row'>
                <p
                    style={{
                        textAlign: 'start',
                        cursor: 'default',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '300px',
                        cursor: 'default'
                    }}
                    title={ state.roles.toString().replace(/[,]+/g, ', ') }
                >
                    { `Roles: ${ state.roles.toString().replace(/[,]+/g, ', ') }` }
                </p>
                {
                    state.listVisible &&
                    <ul>
                        { state.roles.map( ( rol, index ) => <li key={ index }>{ rol }</li>) }
                    </ul>
                }
            </div>
        </div>
    )
}