let ExpresionesRegulares = {
    ErrorInputNumDecimal: /\+|-|[a-zA-Z]|\s/,
    ErrorInputNumero: /\+|-|[a-zA-Z]|\s|\./,
    CodigoProveedor: /(PE|PT)(\w{1,28})/i,
    // AlfanumericoSinEspeciales: /^[a-zA-Z0-9 áéíóúñÁÉÍÓÚÑ]*$/,
    AlfanumericoSinEspeciales: /^[^'"/,]*$/,
    AlfanumericoConCaracteres: /^[a-zA-Z0-9 áéíóúñÁÉÍÓÚÑ )-./(,;]*$/,
    AalfanumericoSinEspacios: /^[a-zA-Z0-9]*$/,
    SoloNumeros: /^[+]?\d*$/,
}

export default ExpresionesRegulares;