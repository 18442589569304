
import {apiUrl} from '../constants';
import axios from 'axios';
import { stringify } from 'querystring';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})

export default class CaffenioDriveAPI{
    static getCaffenios(){
        return request.get('/Caffenios/');
    }

    /**
     * Crea un nuevo Caffenio.
     * @param { Object } caffenioInfo - Informacion del Caffenio
     * @returns Promise
     */
    static createCaffenio( caffenioInfo ) {
        return request.post( '/Caffenios/', caffenioInfo );
    }

    static updateInformation(caffenio){
        return request.post('/Caffenios/UpdateInformation',caffenio);
    }

    // Actualizar muchos drives 
    static updatesInformation(caffenios){ 
        return request.post('/Caffenios/UpdatesInformation',caffenios);
    }

    /**
     * Obtiene los Drives para asignar Líder y Supervisor.
     * @returns Promise
     */
    static getCaffenioParaAsignacion(params) {
        if (Object.toString(params) !== '{}')
            return request.get(`/CaffeniosAsignacion?${stringify({...params})}`);
        else
            return request.get(`/CaffeniosAsignacion/`);
    }

    /**
     * Actualiza al líder y supervisor del drive.
     * @param {Object} infoUpdated - Lider y Supervisor asignados.
     * @returns Promise
     */
    static updateLiderSupervisor(infoUpdated) {
        return request.patch('/CaffeniosAsignacion/UpdateInfo', infoUpdated);
    }

    /**
     * Obtiene caffenio por id
     * @param { Object } caffenioInfo - Informacion del Caffenio
     * @returns Promise
     */
    static getCaffenio( idCaffenio ) {
        return request.get( `/Caffenios/${idCaffenio}` );
    }

    /**
     * Actualiza caffenio id 
     * @param { Object } idCaffenio - identificador del Caffenio
     * @param { Object } newData - información actualizada
     * @returns Promise
     */
     static updateCaffenio( idCaffenio, newData ) {
        return request.put( `/Caffenios/${idCaffenio}`,newData  );
    }
}