import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loading from '../../../util/loading/Loading';
import AcceptModal from '../../../util/acceptModal/AcceptModal';
import { toast } from 'react-toastify';



/**
 * Componente para visualizar drives donde aplica el articulo inventario. 
 */
export default class RecipeDrivesDetail extends Component {

    state = {
        searchText: "",
        searchResultDetails: [],
        allData: [],
        isLoading: false,
        showThead: true,
        checkAll: false,
        indexToRemoveArray : [],
        itemToRemove:{}
    }


    componentDidMount() {
        // Al iniciar empata el listado original al filtrado
        let detailList = this.props.detail.map(d => {
            let drive = this.props.caffenios.filter(dr=> dr.idCaffenio === d);
            return {
                idDrive: drive[0].idCaffenio,
                idCaffenio:drive[0].idCaffenio,
                checked:false,
                DriveEstado:drive[0].State,
                DriveMunicipio:drive[0].Municipality,
                DriveNombre:drive[0].Name   ,
                idEstado: drive[0].idEstado,
                idMunicipio: drive[0].idMunicipio,            
            }
        })
        this.setState({ searchResultDetails:detailList , allData: detailList, checkAll: false });
    }

    /** Función para filtrar por el texto ingresado  */
    onSearch = () => {
        let state = { ...this.state };

        state.searchResultDetails = state.searchText == null || state.searchText.trim().length === 0
            ? state.allData
            : state.allData.filter(d => {
                d.checked = false;
                let searchText = this.eliminarDiacriticos(state.searchText ? state.searchText.trim() : "");
                let driveNombre = this.eliminarDiacriticos(d.DriveNombre ? d.DriveNombre.trim() : "");
                let driveEstado = this.eliminarDiacriticos(d.DriveEstado ? d.DriveEstado.trim(): ""); 
                let driveMunicipio  = this.eliminarDiacriticos(d.DriveMunicipio ? d.DriveMunicipio.trim() : "");
                return driveNombre.includes(searchText) ||
                driveEstado.includes(searchText) ||
                driveMunicipio.includes(searchText)
            });
            
        state.checkAll = false;
        state.isLoading = false;
        state.indexToRemoveArray = [];        

        this.setState(state);
    }

    /**
     * Selecciona o des-seleccion un registro y actualiza el state
     */
    selectItem = (index) => {        
        let state = { ...this.state };
        state.searchResultDetails[index].checked = !state.searchResultDetails[index].checked;
        state.indexToRemoveArray.push(index);
        this.setState(state);
    }

    /**
     * Quita los drives seleccionados de la relacion con el articulo inventario
     */
    onRemoveSelected = () => {
        let state = {...this.state};

        let itemsToRemove = state.searchResultDetails.filter(f => f.checked);
        state.searchResultDetails = state.searchResultDetails.filter(f => !f.checked);
        // state.allData = state.allData.filter(all => {
        //     if(!itemsToRemove.some(toRm => toRm.RecipeDetailId === all.RecipeDetailId))
        //         return all;

        // });        
        
        itemsToRemove.forEach(element => {
            this.props.removeDetail(element);
        });      
        
        state.removeMany = false;
        state.removeModal = false;
        state.checkAll = false;
        state.indexToRemoveArray=[];
        this.setState(state, () => toast.success("Detalle eliminado"));       
    }

    /**
     * Quita desde la selección del action
     */
    onRemoveAction = () => { 
        let state = { ...this.state };
        state.removeModal = false;     
        let indexToRm = state.searchResultDetails.indexOf(state.itemToRemove);
        state.searchResultDetails.splice(indexToRm, 1);  

        this.setState(state, () => {
            try {
                this.props.removeDetail(state.itemToRemove);
                this.setState({itemToRemove:{}})
                toast.success("Detalle eliminado");
            } catch (error) {
                toast.error("Ocurrió un error al quitar drive de listado.")
            }
        });
    }

    /**
     * Elimina por indices, se ejecuta cuando la receta es nueva 
     */
    onRemoveByIndex = () => {
        let state = {...this.state};
        state.removeModal = false;

        state.indexToRemoveArray.forEach(index => {
            let item = this.state.searchResultDetails[index];            
            state.allData = state.allData.filter(all => all.idCaffenio !== item.idCaffenio);            
            state.searchResultDetails = state.searchResultDetails.filter(f => f.idCaffenio !== item.idCaffenio);
        
            this.props.removeDetail(item.idCaffenio); 
        });
        
        state.indexToRemoveArray = [];
        state.checkAll = false;
        this.setState(state, () => toast.success("Drive eliminado"));


    }

    eliminarDiacriticos= (texto) => {
        return texto.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }

    render() {
        return (
            <div>
                <div className="columns is-multiline is-centered">
                    <div className="column is-8">
                        <input
                            placeholder="Buscar por Estado, Ciudad o Nombre del drive"
                            className="input"
                            value={this.state.searchText}
                            onChange={(e) => this.setState({ searchText: e.currentTarget.value, isLoading: true, showThead: true }, () => this.onSearch())}
                        />
                    </div>
                    {
                        //Botón eliminar seleccionado 
                        (this.state.checkAll || this.state.searchResultDetails.filter(s => s.checked).length > 0) &&
                        <div className="column is-3">
                            <div className="has-text-center">
                                <a className="button is-danger" onClick={() => this.setState({ removeMany:true, removeModal: true }) }>
                                    <span className="icon is-small">
                                        <i className={"fa fa-trash"}></i>
                                    </span>
                                    <span>{"Eliminar seleccionados"}</span>
                                </a>
                            </div>
                        </div>
                    }


                    <div className="column is-10">
                        {!this.state.isLoading &&
                            <table className="table is-fullwidth is-striped">
                                {this.state.showThead &&
                                    <thead>
                                        <tr>
                                            <th>
                                                <input className="is-primary tooltip" data-tooltip="Elementos de vista actual"
                                                    onChange={() => this.setState({ checkAll: !this.state.checkAll }, () => {
                                                        let list = [];
                                                        let newResult = this.state.searchResultDetails.map((r, index) => {
                                                            r.checked = this.state.checkAll;   
                                                            if(r.checked){
                                                                list.push(index);
                                                            }                                                         
                                                            return r;
                                                        });

                                                        this.setState({ searchResultDetails: newResult, indexToRemoveArray: this.state.checkAll ? list : [] })
                                                    })}
                                                    checked={this.state.checkAll} type="checkbox" /></th>
                                            <th>Estado</th>
                                            <th>Ciudad</th>
                                            <th>Drive</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                }
                                <tbody>
                                    {this.state.searchResultDetails.map((detail, i) => {
                                        // Elementos que se muestran en el modal de detalles displonibles                                
                                        return (
                                            <tr key={i}>
                                                <th>
                                                    <input type="checkbox" checked={detail.checked}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.selectItem(i);
                                                        }} />
                                                </th>
                                                <td>{detail.DriveEstado}</td>
                                                <td>{detail.DriveMunicipio}</td>
                                                <td>{detail.DriveNombre}</td>
                                                <td>{
                                                    detail.checked
                                                        ? ""
                                                        : <a className="button tableAction is-danger tooltip"
                                                            data-tooltip="Eliminar"

                                                            onClick={(e) => {
                                                                e.stopPropagation();                                                                
                                                                // let new_list =  this.state.indexToRemoveArray;
                                                                // new_list.push(i);
                                                                this.setState({ removeModal: true, recipeDetailId: detail.RecipeDetailId, itemToRemove : detail, removeMany: false
                                                                    // , indexToRemoveArray: new_list 
                                                                });
                                                            }
                                                            }>
                                                            <span className="icon">
                                                                <i className="fa fa-trash" />
                                                            </span>
                                                        </a>
                                                }

                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className="column is-6">
                        <Loading isLoading={this.state.isLoading} width='100px' height='100px' />
                    </div>
                </div>

                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => (this.state.removeMany ? this.onRemoveSelected() : this.onRemoveAction())
                        // !this.props.isNewRecipe
                        // ? (this.state.removeMany ? this.onRemoveSelected() : this.onRemoveAction())
                        // : this.onRemoveByIndex()
                    }
                    cancel={() => this.setState({ removeModal: false , removeMany: false, itemToRemove:{} })}
                    modalTitle={"Eliminar artículo inventario"}
                />}
            </div>
        )
    }
}

RecipeDrivesDetail.defaultProps = {
    //listado de drives    
    detail: PropTypes.array.isRequired,
    removeDetail: PropTypes.func.isRequired,
    isNewRecipe: PropTypes.bool,
    caffenios: PropTypes.array.isRequired

}

RecipeDrivesDetail.defaultProps = {
    detail: [],
    caffenios:[],
    removeDetail: () => { },
    isNewRecipe: false
}