import React from 'react';
import PropTypes from 'prop-types';
import './SaldoRegalo.css';

export default class SaldoRegaloCheckFile extends React.Component {

    static propTypes = {
        save: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    render() {

        var tableHeaders = (
            <thead>
                <tr>
                    <th>{this.props.byLoyaltyAccount ? "CuentaLealtad" : "NumeroCliente"}</th>
                    <th>FechaInicio</th>
                    <th>FechaFinal</th>
                    <th>Monto</th>
                    <th>Motivo</th>
                </tr>
            </thead>);

        //console.log(this.props.saldo);
        var tableBody = this.props.saldo.map((row, index) => {
            return (
                <tr key={index}>
                    <td>{row.Identifier}</td>
                    <td>{row.StarDate}</td>
                    <td>{row.EndDate}</td>
                    <td>{row.Deposit}</td>
                    <td>{row.ReasonDeposit}</td>
                </tr>
            );
        });

        return (
            <div>
                <table className="table" width="100%">
                    {tableHeaders}
                    <tbody key="FileRows">
                        {tableBody}
                    </tbody>
                </table>

                <div className="has-text-right buttons-modal">
                    <button
                        className="button is-primary is-full"
                        onClick={() => {
                            let { save, ...saldo } = { ...this.state };
                            console.clear();
                            //console.log(this.props);
                            this.props.save(this.props.saldo, this.props.byLoyaltyAccount, true);
                        }}>
                        Confirmar guardado de registros
                    </button>
                </div>
            </div>

        )
    }

}
