import React from 'react';
import ReactDOM from 'react-dom';

const PortalModal = ({ isShowing, hide, title, footer, body, footerButtons }) => {return isShowing ? ReactDOM.createPortal(
    <React.Fragment>
    <div className={(isShowing ? "modal is-active has-overflow" : "modal")} >
        <div className="modal-background" onClick={() => hide()}/>
        <div className="modal-card modalWidth">
            <header className="modal-card-head">
            <p className="modal-card-title has-text-centered">{title}</p>
            <button onClick={() => hide()} className="delete" aria-label="close"></button>
            </header>
            <span className="modal-card-body" style={{borderBottomLeftRadius: footer ? "0px" : "6px", borderBottomRightRadius: footer ? "0px" : "6px"}}>
                {body()}
            </span>
            {
                footer && 
                <footer className="modal-card-foot">
                    {/* <button className="button is-success">Save changes</button>
                    <button className="button">Cancel</button> */}
                    {footerButtons()}
                </footer>
            }
        </div>
    </div>
</React.Fragment>, document.body
) : null;}

export default PortalModal;