import React, { Component} from 'react';

export default class ToppingsRestaurantTable extends Component {

    columnas = ["idTopping", "Nombre", "Status"];

    constructor(props){
        super(props);
        this.state = {
            data: props.data,
            collapsed: true
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({collapsed: true});
        }
    }

    render(){
        return (
            <div>
                <div className="columns">
                    <div className="column is-1">
                        <span className="icon" onClick={() => this.setState({collapsed: !this.state.collapsed})} style={{cursor: 'pointer'}}>
                            <i className={`fa ${ this.state.collapsed ? 'fa-arrow-down' : 'fa-arrow-up'}`}/>
                        </span>
                    </div>
                    <div className="column is-3">
                        <label className="label">Toppings Caffenio</label>
                    </div>
                </div>
                {!this.state.collapsed && <table className="table is-fullwidth is-striped">
                    <thead>
                        {this.columnas.map(c => <th key={c}>{c}</th>)}
                    </thead>
                    <tbody>
                        {this.props.data.map((row, index) => {
                            return (
                                <tr key={`recetasCaffenio${index}`}>
                                    <td>{row.ToppingId}</td>
                                    <td>{row.Name}</td>
                                    <td>{row.Status}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>}
            </div>
        )
    }
}