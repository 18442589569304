import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import RecipeTypeForm from './RecipeTypeForm';
import RecipesTypesAPI from '../../../services/RecipeType';
import '../../cssComponents.css';
import Loading from '../../util/loading/Loading';
import Modal from '../../util/modal/Modal';
import ErrorMessages from '../../../util/validation-error-messages';

class NewRecipeType extends Component {
    constructor(props){
        super(props);
        this.state = {
            recipeType: {...this.props.recipeType},
            recipeTypes:[],
            isLoading: false
        };
    }

    componentDidMount(){
        if (!this.props.location.state) {
            this.props.history.push(`/tipos-receta`);
            return;
        }
        this.setState({ recipeTypes: this.props.location.state.recipeTypes })
    }

    handleChange = (e) => {
        let state = {...this.state};
        state.recipeType[e.target.name] = e.target.value;
        this.setState(state);
    }

    save = (recipeType) => {
        this.setState({isLoading: true}, () => {
            RecipesTypesAPI.create(recipeType)
            .then(response => {
                this.props.history.goBack();
                this.setState({isLoading: false})
                toast.success("Tipo Receta creada");
            }).catch(err => {
                this.setState({isLoading: false})
                if(err.response)
                    toast.error(err.response.data.Message)
                else
                    toast.error(ErrorMessages.SinConexion)
            });
        })
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-title is-size-4 center-text">
                            <span style={{position:"absolute", left: 25}} className="is-primary tooltip" data-tooltip="Ir Atrás">
                                <i className="fa-5x" style={{cursor: "pointer", color: "#91BA43" }} onClick={() => {this.props.history.goBack()}} className="fa fa-arrow-left"></i>
                            </span>
                            <div>
                                Nuevo Tipo Receta
                                <i id={"btnInfoRecipeType"} className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "8px" }} onClick={() => this.setState({ showModal: true })} className="fa fa-question-circle"></i>
                            </div>
                            
                        </div>
                    </div>
                    <div className="card-content">
                        <RecipeTypeForm save={this.save} recipeTypes={this.state.recipeTypes}/>
                    </div>
                </div>
                {this.state.showModal &&
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Información de Tipo Receta"}
                        cancel={() => this.setState({ showModal: false })}
                    >
                        <div className="has-text-justified">
                        
                            Los tipos de receta están directamente relacionadas a las recetas, se usa para agrupar las 
                            recetas por sub-categorías en los dispositivos del punto de venta de ser necesario.
                            <br/>
                        En los dispositivos del punto de venta se visualización como una selección más antes de agregar el producto a la orden.
                            <hr/>
                            <p className="has-text-justified">
                                <strong>Nombre</strong>: es un campo requerido, 
                                debe tener una longitud mínima de 2 a 30 caracteres máximo
                                 y no se debe duplicar.                     
                            </p>

                            <p className="has-text-justified">
                                <strong>Posición en Menú</strong>: valor numérico que representa el orden de visualización en los dispositivos de izquierda a derecha.
                                Debe ser mayor a 0, y no se aceptan duplicados.
                            </p>

                            <p className="has-text-justified">
                                <strong>Imagen</strong>: la imagen será la que se muestre en los puntos de venta, debe ser formato png y es requerido.
                            </p>
                        </div>
                       

                    </Modal>
                }
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>
            </div>
        );
    }
}

// Definición de los tipos y su dependencia de las distintas props que recibe el componente.
NewRecipeType.propTypes = {
    recipeType: PropTypes.object,
    isEditing: PropTypes.bool
}

// Definición de los valores por default de las props en caso de no ser recibidas por el padre.
NewRecipeType.defaultProps = {
    isEditing: false,
    recipeType: {
        Description: "",
        Position: "",
        URLImage: "",
        RecipeName: ""
    }
}

export default NewRecipeType;
