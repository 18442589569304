import * as React from 'react';

export default class Checkbox extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div>
                {this.props.labelKey && <p>{this.props.data[this.props.labelKey]}</p>}
                <input
                    type="checkbox"
                    name={this.props.name}
                    className="switch"
                    checked={this.props.value}
                    onChange={(e) => {
                        
                    }}
                />
                
                <label disabled htmlFor={this.props.name} onClick={(e) => {
                    this.props.onChange(e)
                }}/>
            </div>
        )
    }
}