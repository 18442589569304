import React, {Component} from 'react'
import "./HoverCard.css"

export default class HoverCard extends Component{


    // handleClickClick() {
    //     localStorage.setItem("modoGuiado", true); // Establecer el modo guiado
    //     if(this.props.tipoModoGuiado) {
    //         localStorage.setItem("tipoModoGuiado", this.props.tipoModoGuiado) // Establecer el tipo de modo guiado
    //         console.log(localStorage.getItem("tipoModoGuiado"))
    //     }else {
    //         console.log("No hay tipo de modo guiado")
    //     }
    // }

    render(){
        return(
            <div className="flip-card" onClick={this.handleClickClick}>
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <div className="flip-card-icon">
                            {this.props.titulo}
                        </div>
                    </div>
                    <div className="flip-card-back">
                        <div className="flip-card-text">
                            {this.props.descripcion}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}