import React, { useState } from 'react';
import Select from 'react-select';
import useForm from '../../../../../../util/hooks/useForm';
import { useEffect } from 'react';
import NotificacionAPI from '../../../../../../services/Caffenio App/Notificacion';

export default function SaldoForm({inputs, errors, setInput,  handleInputChange, handleSelectChange, notification}){
    const [localNotif, setLocalNotif] = useState({BalanceInfo: {}});
    const [comparators, setComparators] = useState([]);
    const [balances, setBalances] = useState([]);

    useEffect(() => {
        setInput('BalanceInfo', {});
        getData();
        return () => {
            setInput('BalanceInfo', null);
        }
    }, [])

    useEffect(() => {
        if(notification) setLocalNotif(notification);
    }, [notification])

    useEffect(() => {
        if (inputs.NotificationId){
            const selectedComparator = comparators.map(c => ({label: c.Name, value: c.NotificationFilterTypeId})).find(c => c.value === localNotif.BalanceInfo.NotificationFilterTypeId);
            setInput('BalanceInfo', {...inputs.BalanceInfo, NotificationFilterTypeId: selectedComparator, Amount: localNotif.BalanceInfo.Amount})
        }
    }, [comparators])

    useEffect(() => {
        console.log("balancessss");
        if (inputs.NotificationId){
            const selectedBalance = balances.map(c => ({label: c.Name, value: c.NotificationBalanceTypeId})).find(c => c.value === localNotif.BalanceInfo.NotificationBalanceTypeId);
            setInput('BalanceInfo', {...inputs.BalanceInfo, NotificationBalanceTypeId: selectedBalance, Amount: localNotif.BalanceInfo.Amount})
        }
    }, [balances])

    const getData = async () => {
        let resFilters = await NotificacionAPI.getComparators();
        if (resFilters.data && resFilters.data.length){
            setComparators(resFilters.data);
        }

        let resBalances = await NotificacionAPI.getBalanceTypes();
        if (resBalances.data && resBalances.data.length){
            setBalances(resBalances.data);
        }
    }

    return (
        <span>
            {inputs.BalanceInfo ? <div className="columns">
                <div className="column">Cuando el saldo sea</div>
                <div className="column">
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"comparator"}
                        style={{width: '50%'}}
                        value={inputs.BalanceInfo.NotificationFilterTypeId}
                        onChange={(value, event) => setInput('BalanceInfo', {...inputs.BalanceInfo, NotificationFilterTypeId: value})}
                        noOptionsMessage={() => "No hay opciones"}
                        options={comparators.map(c => ({label: c.Name, value: c.NotificationFilterTypeId}))}
                    />
                </div>
                <div className="column is-2">
                    <input
                        type="number"
                        name="Amount"
                        className={`input`}
                        min="1"
                        placeholder={`Cantidad`}
                        value={inputs.BalanceInfo.Amount}
                        onChange={(e) => setInput('BalanceInfo', {...inputs.BalanceInfo, Amount: e.currentTarget.value})}
                    />
                </div>
                <div className="column is-1">
                    en
                </div>
                <div className="column">
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"NotificationBalanceTypeId"}
                        style={{width: '50%'}}
                        value={inputs.BalanceInfo.NotificationBalanceTypeId}
                        onChange={(value, event) => setInput('BalanceInfo', {...inputs.BalanceInfo, NotificationBalanceTypeId: value})}
                        noOptionsMessage={() => "No hay opciones"}
                        options={balances.map(b => ({value: b.NotificationBalanceTypeId, label: b.Name}))}
                    />
                </div>
            </div> : null}
        </span>
    )
}