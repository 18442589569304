import React, { useState, useEffect, useRef } from 'react';
import useForm from '../../../../../../util/hooks/useForm';
import Chip from '../../../../../util/chip/Chip';
import NotificacionAPI from '../../../../../../services/Caffenio App/Notificacion';

export default function NotificacionDataForm({inputs, setInput, errors, handleInputChange, disabled, notification}){
    const [tags, setTags] = useState([])

    // Efecto a realizar cuando cambia el valor de la prop disabled
    useEffect(() => {
        if (!disabled){
            getData();
        }
    }, [disabled])

    useEffect(() => {
        if(notification){
            setInput('Title', notification.Title);
            setInput('Description', notification.Description);
        }
    }, [notification])

    const getData = async () => {
        if(inputs.category && inputs.category.value){
            let res = await NotificacionAPI.getTags(inputs.category.value);
            if(res.data){
                setTags(res.data);
            }
        }
        
    }

    const generatePreview = (name) => {
        var preview = `${inputs[name]}`
        
        preview = preview.split('[NOMBRE_CLIENTE]').join('José');
        
        return preview;
    }

    return (
        <div disabled={disabled} className="columns is-multiline">
            <div className="column is-6 has-text-centered">
                <div className="columns is-multiline">
                    <div className="column is-12 has-text-centered">
                        <div className="field">
                            <div className={`control`} style={{width: '100%'}}>
                                <input
                                    type="text"
                                    name="Title"
                                    className={`input ${errors.Title && "is-danger"}`}
                                    placeholder={`Título de la notificación`}
                                    value={inputs.Title}
                                    onChange={(e) => handleInputChange(e, true)}
                                    required
                                />
                            </div>
                        </div>
                        {errors.Title && <p className="help title is-6 is-danger">Título requerido</p>}
                    </div>
                    <div className="column is-12 has-text-centered">
                        {tags.map((tag, index) => 
                            <Chip 
                                small
                                onClick={() => {
                                    setInput("Title", `${inputs.Title ? inputs.Title+" " : ""}${tag.Tag}`.replace(/\s{2,}/g, ' '))
                                }}
                                label={tag.Name} 
                                closeable={false}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="column is-6 has-text-centered">
                Previsualización
                {inputs.Title && inputs.Title.length > 0 && <p className="help title is-6 is-link">{generatePreview('Title')}</p>}
            </div>
            <div className="column is-6 has-text-centered">
                <div className="columns is-multiline">
                    <div className="column is-12 has-text-centered">
                        <div className="field">
                            <div className={`control`} style={{width: '100%'}}>
                                <input
                                    type="text"
                                    name="Description"
                                    className={`input ${errors.Description && "is-danger"}`}
                                    placeholder={`Descripción de la notificación`}
                                    value={inputs.Description}
                                    onChange={(e) => handleInputChange(e, true)}
                                    required
                                />
                            </div>
                        </div>
                        {errors.Description && <p className="help title is-6 is-danger">Título requerido</p>}
                    </div>
                    <div className="column is-12 has-text-centered">
                        {tags.map((tag, index) => 
                            <Chip 
                                small
                                onClick={() => {
                                    setInput("Description", `${inputs.Description ? inputs.Description+" " : ""}${tag.Tag}`.replace(/\s{2,}/g, ' '))
                                }}
                                label={tag.Name} 
                                closeable={false}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="column is-6 has-text-centered">
                Previsualización
                {inputs.Description && inputs.Description.length > 0 && <p className="help title is-6 is-link">{generatePreview('Description')}</p>}
            </div>
        </div>
    )
}