import { apiUrl }  from "../constants";
import axios from 'axios';
var request = axios.create({
    baseURL: apiUrl,
    headers: {'Authorization':'Bearer ' +localStorage.getItem('token'),'Content-Type':'application/json'}
})
export default class MenuDriveAPI {
    
    static get() {
        return request.get(`/Menu`);
    }

    static getById(id) {
        return request.get(`/Menu/${id}`);
    }

    static post(menu) {
        return request.post(`/Menu`, menu);
    }

    static postSync(menu) {
        return request.post(`/Menu/Sync`, menu);
    }

    static put(menu) {
        return request.put(`/Menu`, menu);
    }

    static delete(id) {
        return request.delete(`/Menu/${id}`);
    }
    
    static getDrives(menuId){
        return request.get(`/Menu/Drives/${menuId}`)
    }

    static getDetails(id) {
        return request.get(`/DetailMenu/${id}`)
    }

    static postDetailUpdate(detailMenu) {
        return request.post(`/DetailMenu`, detailMenu);
    }

    static getMenusByIdDrives(idDrives){
        return request.post(`/Menu/GetByDrives`, idDrives)
    }

    static getMenusWithoutDrives(){
        return request.get(`/Menu/GetMenusWithoutDrives`)
    }

    static getMenusWithSegmentation(){
        return request.get(`/Menu/MenusWithSegmentation`)
    }

    static GetRegistrosMovimientos(filtros){
        return request.post(`/Menu/RegistrosMovimientos`, filtros)
    }

    static GetTiposMovimiento(){
        return request.post(`/Menu/TiposMovimiento`)
    }

}