import React, { Component } from 'react';
import  SearchForm  from "../../util/searchform/SearchForm";
import  TableTemplate  from "../../util/tabletemplate/TableTemplate";
import  NoItems  from "../../util/noitems/NoItems";
import Modal from '../../util/modal/Modal';
import AcceptModal from '../../util/acceptModal/AcceptModal';
import MilkForm from './MilkForm';
import Pagination from '../../util/pagination/Pagination';
import Loading from '../../util/loading/Loading';
import MilksAPI from '../../../services/Milk';
import { toast } from 'react-toastify';
import Validators from '../../../util/validators';

import ModoGuiado from "../../modoGuiado/ModoGuiado"
import BtnModoGuiado from '../../util/BtnModoGuiado/BtnModoGuiado'
import ErrorMessages from '../../../util/validation-error-messages';
import lodash from 'lodash';
import roles from '../../../util/strings/roles';

class Milks extends Component {
    milks = [];

    constructor(props){
        super(props);
        this.state = {
            milks: [],
            pageMilks: [],
            filterText: "",
            milk: null,
            showModal: false,
            editing: false,
            removeModal: false,
            isLoading: false
        }
    }

    componentWillMount(){
        this.setState({isLoading: true});
        MilksAPI.getAll().then(response => {
            this.setState({isLoading: false, milks: response.data});
            this.milks = response.data;
        }).catch(err => {
            this.setState({isLoading: false})
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        });
    }

    // Método encargado de recibir el texto en el input de búsqueda y filtrar los elementos en
    // la tabla 
    handleSearchInput = (text) => {
        let milks = this.milks.filter(milk => milk.Description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        let state = this.state;
        state.filterText = text;
        state.milks = milks;
        this.setState(state);

    }

    /**Redirige a la vista para crear un nuevo tipo de leche  */
    create = () => {
        this.props.history.push({pathname: '/leches/nueva', state: {milks: this.milks}});
    }

    /** Función para editar tipo de leche */
    edit = (milk) => {        
        this.setState({isLoading: true}, () => {
            MilksAPI.edit(milk).then(response => {
                let state = {...this.state};
                state.milks[state.milks.findIndex(c => c.MilkTypeId === milk.MilkTypeId)] = response.data;
                state.pageMilks[state.pageMilks.findIndex(c => c.MilkTypeId === milk.MilkTypeId)] = response.data;
                this.milks[this.milks.findIndex(c => c.MilkTypeId === milk.MilkTypeId)] = response.data;
                state.isLoading = false;
                state.showModal = false;
                state.milk = null;
                this.setState(state, () => toast.success("Tipo Leche actualizada"));
            }).catch(err => {
                this.setState({isLoading: false});
                if(err.response)
                    toast.error(err.response.data.Message); 
                else
                    toast.error(ErrorMessages.SinConexion); 
            })
        });
        
        
    }

    remove = () => {
        this.setState({isLoading: true});
        MilksAPI.delete(this.state.milk.MilkTypeId).then(response => {
            let state = {...this.state};

            state.milks.splice(state.milks.findIndex(c => c.MilkTypeId === state.milk.MilkTypeId), 1);
            state.pageMilks.splice(state.pageMilks.findIndex(c => c.MilkTypeId === state.milk.MilkT), 1);
            this.milks.splice(this.milks.findIndex(c => c.MilkTypeId === state.milk.MilkTypeId), 1);
            
            state.isLoading = false;
            state.removeModal = false;
            state.milk = null;

            this.setState(state, () => toast.success("Tipo Leche eliminada"));
        }).catch(err => {
            this.setState({isLoading: false, milk: null, removeModal: false});
            if(err.response)
                toast.error(err.response.data.Message)
            else
                toast.error(ErrorMessages.SinConexion)
        })
    }

    render() {
        return (
            <div>

                <ModoGuiado 
                    ruta="leches"
                    tipoModoGuiado="receta"
                    anterior="granos"
                    siguiente="presentaciones" 
                    mensaje="Agrupación de leche a la que pertenece un producto terminado" />

                <h1 className="title is-2">Tipo de leche</h1>
                <div className="columns is-vcentered">
                    <div className="column is-5">
                        <div className="card">
                            <div className="card-content">
                                <div className="field">
                                    <div className="control">
                                        <SearchForm
                                            filterText={this.state.filterText}
                                            onInput={(t) => this.handleSearchInput(t)}
                                            itemName={'Tipo Leche'}
                                            placeholderText={"Ingrese nombre de tipo de leche."}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-7">
                        <div className="field">
                            <div className="control has-text-right">
                                {lodash.intersection(localStorage.getItem('role').split(","), [roles.ADMIN, roles.ADMINDRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES]).length > 0 &&
                                    <a id="btnAddMilk" className="button is-success" onClick={(e) => this.create()}>
                                        <span className="icon is-small">
                                            <i className="fa fa-plus"></i>
                                        </span>
                                        <span>Agregar Tipo Leche</span>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="card">
                    <div className="card-content">
                        {this.state.milks.length !== 0 
                        ? <TableTemplate
                            columns={["MilkTypeId","Description","Position", "NombreSegmento"]}
                            columnsNames={["ID", "Nombre", "Posición en Menú", "Segmento"]}
                            data={this.state.pageMilks}
                            rowClickable={true}
                            details={(milk) => this.setState({showModal: true, milk: milk})}
                            canEdit canDelete 
                            edit={(milk) => this.setState({showModal: true, milk: milk})} 
                            remove={(milk) => this.setState({removeModal: true, milk: milk})}
                        /> 
                        : <NoItems itemName="leches"/>}
                        <Pagination items={this.state.milks} onChangePage={(milks) => this.setState({ pageMilks: milks })} />
                    </div>
                </div>
                {this.state.showModal && 
                    <Modal
                        showModal={this.state.showModal}
                        modalTitle={"Editar Tipo Leche"}
                        cancel={() => this.setState({showModal: false, unit: null})}
                    >
                        <MilkForm
                            milk={this.state.milk}
                            milks={this.milks}
                            save={this.edit}
                            isEditing={true}
                        />   
                    </Modal>
                }
                {this.state.removeModal && <AcceptModal
                    isActive={this.state.removeModal}
                    done={() => this.remove()}
                    cancel={() => this.setState({removeModal: false})}
                    modalTitle={"Eliminar Tipo Leche"}
                />}
                <Loading isFullscreen={true} isLoading={this.state.isLoading} width='100px' height='100px'/>

                <BtnModoGuiado />                

            </div>
        );
    }
}

export default Milks;
