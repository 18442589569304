import React, { Component } from 'react'
import PropTypes from 'prop-types';
import lodash from 'lodash';

class HasAccess extends React.Component {
    render() {
        let roles = localStorage.getItem('role').split(',');
        let permissions = localStorage.getItem('permissions').split(',');
        let hasAccess = lodash.intersection(this.props.roles, roles).length > 0 || lodash.intersection(this.props.permissions, permissions).length > 0
        return (
            hasAccess ? this.props.children : null
        );
    }
}

HasAccess.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.any),
    permissions: PropTypes.arrayOf(PropTypes.any)
}

export default HasAccess;
