import React, { Component } from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip'
import Field from '../../util/field/Field';
/**
 * Componente para pintar el detalle de una promoción de tipo descuento 
 * @param {*} props 
 * @returns componente
 */
export const PromoDetailDescuento = (props) => {
    console.log("PromoDetailDescuento props >>> ", props)
    
    return (
        <>
        <div className='row'>
            <div className='columns'>
                <div className='column is-6'>
                    <label className='label'>
                        <div> Tipo de descuento
                            <i data-tip data-for="idTipoDescuentoTip" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                        </div>
                    </label>
                    <div className='control'>
                        <Select
                            id="idTipoDescuento"
                            name="idTipoDescuento"
                            value={props.values.idTipoDescuento}
                            placeholder="Seleccionar..."
                            noOptionsMessage={() => "No hay opciones"}
                            options={props.listTipoDescuento}
                            onChange={(val) => {
                                props.setFieldValue('idTipoDescuento', val)
                            }}
                            onBlur={() => props.setFieldTouched('idTipoDescuento', true)}
                        />
                    </div>
                    <div className="is-centered">
                        {(props.errors.idTipoDescuento || props.touched.idTipoDescuento) &&
                            <div style={{ 'fontSize': '12px', 'color': '#cc0b19' }}>
                                {props.errors.idTipoDescuento ? props.errors.idTipoDescuento : ""}
                            </div>
                        }
                    </div>

                    <ReactTooltip id="idTipoDescuentoTip" place="top" effect="solid">
                        Define el tipo de descuento.
                    </ReactTooltip>
                </div>

                <div className='column is-6'>
                    <label className='label'>
                        <div>Recursividad
                            <i data-tip data-for="isRecursiveDescuentoSelectedTip" className="fa-5x" style={{ cursor: "pointer", color: "#bdbdbd", marginLeft: "5px" }} className="fa fa-question-circle"></i>
                        </div></label>
                    <div>
                        <Select
                            id="isRecursiveDescuentoSelected"
                            name="isRecursiveDescuentoSelected"
                            value={props.values.isRecursiveDescuentoSelected}
                            placeholder="Seleccionar..."
                            noOptionsMessage={() => "No hay opciones"}
                            options={props.RecursividadDescuento}
                            onChange={(val) => {
                                props.setFieldValue('isRecursive', val.value)
                                props.setFieldValue('isRecursiveDescuentoSelected', val)
                            }}
                            onBlur={() => props.setFieldTouched('isRecursiveDescuentoSelected', true)}
                        />
                        <div className="is-centered">
                            {props.errors.isRecursiveDescuentoSelected &&
                                <div style={{ 'fontSize': '12px', 'color': '#cc0b19' }}>
                                    Campo recursividad es requerido
                                </div>
                            }
                        </div>
                    </div>

                    <ReactTooltip id="isRecursiveDescuentoSelectedTip" place="top" effect="solid">
                        <strong style={{ color: 'white' }}>Descuento múltiple -</strong> El descuento se aplicará una vez por cada producto válido <br />
                        <strong style={{ color: 'white' }}>Un descuento por orden -</strong> El descuento se aplicará solo una vez sin importar cuantos productos contenga la orden <br />
                    </ReactTooltip>
                </div>
                </div>
        </div>
        <div className='row'>
            <div className='columns'>
                <div className='column is-6'>
                    <Field label={props.values.idTipoDescuento ? props.values.idTipoDescuento.label + " a descontar" : "Cantidad a descontar"}
                        alingLefth={true}
                        hasError={props.errors.quantity || props.touched.quantity}
                        msgError={props.errors.quantity ? props.errors.quantity : ""}>
                        <input
                            className="input"
                            type="number"
                            label="quantity"
                            name="quantity"
                            maxLength="10"
                            min="1"
                            value={props.values.quantity}
                            onChange={(e) => props.handleChange(e)}
                            onKeyPress={props.onKeyPressNumber}
                            disabled={!props.values.idTipoDescuento}

                        />
                    </Field>
                </div>

                <div className='column is-6'>
                    <Field label='¿Cantidad mínima de producto?' isVertical={true} alingLefth={true}>
                        <input
                            className='switch'
                            type='checkbox'
                            checked={props.values.checkCantidadMinima}
                            name='checkCantidadMinima'
                            onChange={() => { }}
                        />
                        <label
                            htmlFor='Cantidad mínima'
                            onClick={() => {
                                props.values.minProducts = 0
                                props.setFieldValue('checkCantidadMinima', !props.values.checkCantidadMinima)
                            }}
                        />
                    </Field>
                </div>
            </div>
        </div>
           
        </>
    )
}

